import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { Card, Col, Row, Nav, NavItem, NavLink, TabPane, TabContent, Form, Button } from 'reactstrap';
import { CommonHelper } from "../Base/Common/CommonUIComponents";

import Demographics from "./Demographics";
import TechDashboard from "./TechDashboard";
import Engagement from "./Engagement";
import Acquisition from "./Acquisition";
import Reservations from './Reservations';
import Campaigns from './Campaigns';
import ReservationsPromotions from './ReservationsPromotions';
import Payments from './Payments';
import { getAPI } from '../Base/API';
import CustomSelect from '../Base/Common/CustomSelect';
import { CustomDateRangePicker } from '../Base/Common/CustomReactDates';
import Extras from './Extras';

class BEAnalitics extends Component {
    static displayName = BEAnalitics.name;

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: [],
            block: false,
            blockPromotions: true,
            blockPromoCodes: true,
            activeTab: '3',
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            search: false,
            promotionList: [],
            promoCodeList: [],
            colorPalette: ['#12b5cb', '#ffb703', '#7dcfb6', '#fb8500', '#007bff', '#f1d6a7'],
            helpButton: [
                { tab: '3', help: <FormattedHTMLMessage id="BEAnalitics.DemographicsHelp" />, id: 'DemographicsHelp' },
                { tab: '1', help: <FormattedHTMLMessage id="BEAnalitics.AcquisitionHelp" />, id: 'AcquisitionHelp' },
                { tab: '2', help: <FormattedHTMLMessage id="BEAnalitics.EngagementHelp" />, id: 'EngagementHelp' },
                { tab: '4', help: <FormattedHTMLMessage id="BEAnalitics.TechHelp" />, id: 'TechHelp' },
                { tab: '5', help: <FormattedHTMLMessage id="BEAnalitics.ReservationsHelp" />, id: 'ReservationsHelp' }
            ],
            extraTypeList: [{ value: 'extras', label: <FormattedHTMLMessage id="BEAnalitics.Extras" /> }, { value: 'experiencesExtras', label: <FormattedHTMLMessage id="BEAnalitics.ExperiencesExtras" /> }],
            selectedExtraType: 'extras'
        }
    }

    componentDidMount() {
        this.setDatesArray();
        this.getPromotionsList();
        this.getPromoCodesList();
    }

    getPromotionsList = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockPromotions: false });

                return;
            }
            if (data) {
                const promotionList = [
                    { label: this.props.intl.formatMessage({ id: "generic.active" }), options: [] },
                    { label: this.props.intl.formatMessage({ id: "generic.inactive" }), options: [] },
                    { label: this.props.intl.formatMessage({ id: "PromotionDashboard.Expired" }), options: [] }
                ];

                if (data.request) {
                    const today = moment(moment().format("YYYY-MM-DD"));

                    data.request.promotion.forEach(promo => {
                        const obj = { label: `${promo.name} (${promo.discount.value}%)`, value: promo.id };

                        if (promo.active && today.isSameOrBefore(moment(promo.stay_date.end))) {
                            promotionList[0].options.push(obj);
                        }
                        else if (!promo.active) {
                            promotionList[1].options.push(obj);
                        }
                        else if (promo.active && today.isAfter(moment(promo.stay_date.end))) {
                            promotionList[2].options.push(obj);
                        }
                    })
                }

                this.setState({ blockPromotions: false, promotionList });
            }
            else this.setState({ blockPromotions: false });
        }, '/api/Price/Promotion/v1/HeyBE');
    }

    getPromoCodesList = () => {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockPromoCodes: false });

                return;
            }
            if (data && data.response && data.response.length > 0) {
                const promoCodeList = [
                    { label: this.props.intl.formatMessage({ id: "generic.active" }), options: [] },
                    { label: this.props.intl.formatMessage({ id: "generic.inactive" }), options: [] }
                ];

                data.response.forEach(promoCode => {
                    const obj = { label: promoCode.code, value: promoCode.code };

                    if (promoCode.active) {
                        promoCodeList[0].options.push(obj);
                    }
                    else if (!promoCode.active) {
                        promoCodeList[1].options.push(obj);
                    }
                });

                this.setState({ blockPromoCodes: false, promoCodeList });
            }
            else {
                this.setState({ blockPromoCodes: false });
            }
        }, 'api/Rate/Promotion/v1/promoCode');
    }

    setDatesArray = () => {
        const { startDate, endDate } = this.state;

        const dates = new Array(moment(endDate.format("YYYY-MM-DD")).diff(moment(startDate.format("YYYY-MM-DD")), 'days')).fill(undefined)
            .reduce((acc, cur) => {
                acc.push(moment(startDate).add(acc.length, 'days').format("YYYY-MM-DD"));

                return acc;
            }, [moment(startDate).format("YYYY-MM-DD")])


        this.setState({ dates });
    }

    updateChartArray = (array, row, dimensionIndex, metricIndex, type) => {
        const index = array.findIndex(el => el[0] === row.dimensionValues[dimensionIndex].value);

        if (index !== -1) {
            array[index][1] += parseInt(row.metricValues[metricIndex].value);
        }
        else {
            const arr = type === 'BarChart' ? [row.dimensionValues[dimensionIndex].value, parseInt(row.metricValues[metricIndex].value), this.state.colorPalette[array.length - 1]] : [row.dimensionValues[dimensionIndex].value, parseInt(row.metricValues[metricIndex].value)]
            array.push(arr);
        }
        return array;
    }

    toggle = (id) => {
        this.setState({ activeTab: id })
    }

    toggleSearch = (e) => {
        if (e) e.preventDefault();

        this.setDatesArray();

        this.setState(prevState => ({
            search: !prevState.search
        }))
    }

    handleSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    valuesCard = (text, value) => {
        return <Card className="shadow border-0 h-100 justify-content-between" body>
            <Row className="row no-gutters align-items-center">
                <Col>
                    <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                        {text}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="text-right">
                    <h3 className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                        {value}
                    </h3>
                </Col>
            </Row>
        </Card>
    }

    render() {
        const { activeTab } = this.state;

        const userColors = [
            '#95B3F9', //Total users
        ];

        const sourceColors = [
            '#4F80F6',
            '#5FD4FA',
            '#12b5cb',
            '#ffb703',
            '#ea914f'
        ];
        
        const revenueColors = [
            '#4EEBB5', //Revenue
        ];

        return (
            <div className="container-fluid content-row p-0">
                <Row>
                    <Col className="col-2">
                        <Nav tabs vertical className="h-100 border-0">
                            <Card className="bg-white p-2 border-0 mb-3 shadow" body>
                                <h5 className='pl-2'> Menu </h5>

                                <NavItem className="pb-2">
                                    <NavLink className={activeTab === '3' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'} onClick={() => { this.toggle('3'); }} >
                                        <span><i className="fas fa-globe mr-2" /><FormattedMessage id="BEAnalitics.Demographics" /> </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="pb-2">
                                    <NavLink className={activeTab === '1'? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'} onClick={() => { this.toggle('1'); }} >
                                        <span><i className="far fa-chart-bar mr-2" /><FormattedMessage id="BEAnalitics.Acquisition" /> </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="pb-2">
                                    <NavLink className={activeTab === '2' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'} onClick={() => { this.toggle('2'); }} >
                                        <span><i className="far fa-hand-point-up mr-2" /><FormattedMessage id="BEAnalitics.Engagement" /> </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="pb-2">
                                    <NavLink className={activeTab === '4' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'} onClick={() => { this.toggle('4'); }} >
                                        <span><i className="fas fa-mobile-alt mr-2" /><FormattedMessage id="BEAnalitics.Tech" /> </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="pb-2">
                                    <NavLink className={activeTab === '5' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'} onClick={() => { this.toggle('5'); }} >
                                        <span><i className="fas fa-bed mr-2" /><FormattedMessage id="BEAnalitics.Reservations" /> </span>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="pb-2">
                                    <NavLink className={activeTab === '6' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'} onClick={() => { this.toggle('6'); }} >
                                        <span><i className="fas fa-link mr-2" /><FormattedMessage id="BEAnalitics.Campaigns" /> </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="pb-2">
                                    <NavLink className={activeTab === '7' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'} onClick={() => { this.toggle('7'); }} >
                                        <span><i className="fas fa-tag mr-2" /><FormattedMessage id="BEAnalitics.Promotions" /> </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="pb-2">
                                    <NavLink className={activeTab === '8' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'} onClick={() => { this.toggle('8'); }} >
                                        <span><i className="fas fa-hand-holding-usd mr-2" /><FormattedMessage id="BEAnalitics.ReservationPayments" /> </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem className="pb-2">
                                    <NavLink className={activeTab === '9' ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'} onClick={() => { this.toggle('9'); }} >
                                        <span><i className="fas fa-gift mr-2" /><FormattedMessage id="BEAnalitics.Extras" /> </span>
                                    </NavLink>
                                </NavItem>
                            </Card>
                        </Nav>
                    </Col>
                    <Col className="col-10">
                        <Form onSubmit={this.toggleSearch}>
                            <Row className="mb-2">
                                {activeTab === '7' ?
                                    <>
                                        <Col className="col-3 pr-0">
                                            <CustomSelect
                                                icon={'fa fa-tag fa-fw'}
                                                options={this.state.promotionList}
                                                placeholder={<FormattedMessage id="BEAnalitics.Promotions" />}
                                                onChange={(combo) => this.handleSelect(combo, 'selectedPromo')}
                                                isLoading={this.state.blockPromotions}
                                                isClearable isSearchable
                                            />
                                        </Col>
                                        <Col className="col-3 pr-0">
                                            <CustomSelect
                                                icon={'fa fa-percent fa-fw'}
                                                options={this.state.promoCodeList}
                                                placeholder={<FormattedMessage id="navbar.PromoCodes" />}
                                                onChange={(combo) => this.handleSelect(combo, 'selectedPromoCode')}
                                                isLoading={this.state.blockPromoCodes}
                                                isClearable isSearchable
                                            />
                                        </Col>
                                    </>
                                    : ''}

                                {activeTab === '9' ?
                                    <Col className="col-3 pr-0">
                                        <CustomSelect
                                            icon='fas fa-gift'
                                            options={this.state.extraTypeList}
                                            value={this.state.extraTypeList.find(el => el.value === this.state.selectedExtraType) || ''}
                                            onChange={(combo) => this.handleSelect(combo, 'selectedExtraType')}
                                        />
                                    </Col>
                                    : ''}

                                <Col>
                                    <CustomDateRangePicker
                                        required
                                        startDate={this.state.startDate}
                                        startDateId="your_unique_start_date_id_announcement"
                                        isOutsideRange={day => day > moment()}
                                        endDate={this.state.endDate}
                                        endDateId="your_unique_end_date_id_announcement"
                                        onDatesChange={(startDate, endDate) => this.setState({ startDate, endDate })}
                                        numberOfMonths={1}
                                        showYearOptions={{ pastYears: true, futureYears: false }}
                                    />
                                </Col>
                                <Col className="d-flex justify-content-end align-items-center">
                                    <Button className="btn btn-host btn-sm " type="submit">
                                        <i className="fa fa-search" />
                                    </Button>

                                    {this.state.helpButton.map((hb, key) =>
                                        <div style={hb.tab !== activeTab ? { display: 'none' } : {}} key={key}>
                                            <CommonHelper help={hb.help} id={hb.id} />
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Form>
                        <Row>
                            <Col>
                                <TabContent activeTab={activeTab} className="h-100">
                                    <TabPane tabId='1' className="border-0 my-0 p-0 h-100">
                                        {activeTab === '1' ? 
                                            <Acquisition
                                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                                endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                                updateChartArray={this.updateChartArray}
                                                toggleSearch={this.toggleSearch}
                                                search={this.state.search}
                                                valuesCard={this.valuesCard}
                                                sourceColors={sourceColors}
                                                userColors={userColors}
                                                revenueColors={revenueColors}
                                                dates={this.state.dates}
                                            />
                                        :''}
                                    </TabPane>
                                    <TabPane tabId="2" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '2' ? 
                                            <Engagement
                                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : null}
                                                endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                                updateChartArray={this.updateChartArray}
                                                toggleSearch={this.toggleSearch}
                                                search={this.state.search}
                                                valuesCard={this.valuesCard}
                                                sourceColors={sourceColors}
                                                userColors={userColors}
                                                revenueColors={revenueColors}
                                                dates={this.state.dates}
                                            />
                                        :''}
                                    </TabPane>
                                    <TabPane tabId="3" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '3' ? 
                                            <Demographics
                                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : null}
                                                endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                                updateChartArray={this.updateChartArray}
                                                toggleSearch={this.toggleSearch}
                                                search={this.state.search}
                                                sourceColors={sourceColors}
                                                userColors={userColors}
                                                revenueColors={revenueColors}
                                            />
                                        :''}
                                    </TabPane>
                                    <TabPane tabId="4" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '4' ? 
                                            <TechDashboard
                                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                                endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                                updateChartArray={this.updateChartArray}
                                                toggleSearch={this.toggleSearch}
                                                search={this.state.search}
                                                sourceColors={sourceColors}
                                                userColors={userColors}
                                                revenueColors={revenueColors}
                                            />
                                        :''}
                                    </TabPane>
                                    <TabPane tabId="5" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '5' ? 
                                            <Reservations
                                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : null}
                                                endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                                updateChartArray={this.updateChartArray}
                                                toggleSearch={this.toggleSearch}
                                                search={this.state.search}
                                                valuesCard={this.valuesCard}
                                                sourceColors={sourceColors}
                                                userColors={userColors}
                                                revenueColors={revenueColors}
                                                colorPalette={this.state.colorPalette}
                                                intl={this.props.intl}
                                            />
                                        :''}
                                    </TabPane>
                                    <TabPane tabId="6" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '6' ? 
                                            <Campaigns
                                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                                endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                                updateChartArray={this.updateChartArray}
                                                toggleSearch={this.toggleSearch}
                                                search={this.state.search}
                                                sourceColors={sourceColors}
                                                userColors={userColors}
                                                revenueColors={revenueColors}
                                            />
                                        :''}
                                    </TabPane>
                                    <TabPane tabId="7" className="border-0 my-0 p-0 h-100">
                                        {
                                            activeTab === '7' ?
                                                <ReservationsPromotions
                                                    startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                                    endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                                    toggleSearch={this.toggleSearch}
                                                    search={this.state.search}
                                                    selectedPromo={this.state.selectedPromo}
                                                    selectedPromoCode={this.state.selectedPromoCode}
                                                />
                                                :
                                                ''
                                        }                                        
                                    </TabPane>
                                    <TabPane tabId="8" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '8' ?
                                            <Payments
                                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : null}
                                                endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                                updateChartArray={this.updateChartArray}
                                                toggleSearch={this.toggleSearch}
                                                search={this.state.search}
                                                sourceColors={sourceColors}
                                                userColors={userColors}
                                                revenueColors={revenueColors}
                                                valuesCard={this.valuesCard}
                                                dates={this.state.dates}
                                            />
                                            :
                                            ''}
                                    </TabPane>
                                    <TabPane tabId="9" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '9' ?
                                            <Extras
                                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : null}
                                                endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                                updateChartArray={this.updateChartArray}
                                                toggleSearch={this.toggleSearch}
                                                search={this.state.search}
                                                sourceColors={sourceColors}
                                                valuesCard={this.valuesCard}
                                                selectedExtraType={this.state.selectedExtraType}
                                                dates={this.state.dates}
                                            />
                                        :''}
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        )
    }
}

export default injectIntl(BEAnalitics);
