import React, { Component } from 'react';
import { Row, Button, Col, Label, Form, CustomInput, FormGroup, Input, Modal, ModalBody } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import { patchAPI, postAPI } from '../Base/API';
import { ErrorAlert } from "../Common/ErrorAlert";
import Authorization from '../../components/Base/Authorization';
import CustomSelect from '../Base/Common/CustomSelect';

class EmailResWebhookModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            webhook: {
                url: this.props.selectedWebhook?.url,
                sendraw: this.props.selectedWebhook?.send_raw ? this.props.selectedWebhook.send_raw : false,
                hostname: this.props.selectedWebhook?.hostname,
                spamcheck: this.props.selectedWebhook?.spam_check ? this.props.selectedWebhook.spam_check : false,
                id: this.props.selectedWebhook?.hostname,
            },
            selectedDomain: this.props.domains && this.props.domains.length > 0 ? this.props.domains.filter(el => el.default)[0] : ''
        }
    }

    createWebhook = (e) => {
        e.preventDefault();
        const { webhook, selectedDomain } = this.state;

        if (selectedDomain && selectedDomain !== '') {
            webhook.hostname = webhook.hostname + '.' + selectedDomain.label;
        }

        if (/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(webhook.hostname)) {
            this.setState({ block: true }, () =>
                postAPI(result => {
                    const { data, error } = result;
                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState({ block: false });
                        }
                        else {
                            handleNotification(data, <FormattedMessage id="EmailResWebhooks.WebhookSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                            this.props.getWebhooks();
                        }
                    }
                }, `/api/hotel/EmailReservation/v1/webhooks?hostname=${webhook.hostname}&&spamcheck=${webhook.spamcheck}&&sendraw=${webhook.sendraw}`));
        }
        else {
            var data = { errors: [{ code: "401", message: "Invalid webhook domain" }] };
            handleNotification(data);
        }
        

    }

    editWebhook = (e) => {
        e.preventDefault();
        const { webhook } = this.state;

        this.setState({ block: true }, () =>
            patchAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="EmailResWebhooks.WebhookSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                        this.props.getWebhooks();
                    }
                }
            }, `/api/hotel/EmailReservation/v1/webhooks/${webhook.hostname}?spamcheck=${webhook.spamcheck}&&sendraw=${webhook.sendraw}`));
    }

    handleSelect = (name, combo) => {
        this.setState({
            webhook: {
                ...this.state.webhook,
                [name]: combo ? combo.value : null
            }
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            webhook: {
                ...this.state.webhook,
                [name]: value
            }
        });
    }

    handleComboChange = (e) => {
        const { name } = e.target;
        const { webhook } = this.state;

        if (name == "spamcheck") {
            webhook.spamcheck = !webhook.spamcheck
        } else {
            webhook.sendraw = !webhook.sendraw
        }

        this.setState({ webhook });
    }

    setComboStatus = (field, inputValue) => {
        var targetValue = '';
        targetValue = inputValue ? inputValue : '';

        this.setState({ [field]: targetValue })
    }

    render() {
        const { isOpen, toggleModal, domains } = this.props;
        const { block, error, webhook } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggleModal} fade={false} className="modal-small">
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm" />
                </Button>
                <ErrorAlert error={error} />
                <ModalBody>
                    <BlankCard block={block}>
                        <Form onSubmit={this.createWebhook}>
                            <FormGroup row>
                                <Col style={{ fontWeight: "500" }}>
                                    <FormattedMessage id="EmailResWebhooks.CreateWebhook" />
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Label><FormattedMessage id="EmailResWebhooks.subdomain" /></Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Input
                                                type="text"
                                                name="hostname"
                                                value={webhook.hostname || ''}
                                                onChange={(e) => this.handleChange(e)}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col style={{ paddingLeft: `${domains && domains.length > 0 && domains.length == 1 ? "0" : ''}` }}>
                                    <Row>
                                        <Col>
                                            <Label>{domains && domains.length > 1 ? <FormattedMessage id="EmailResWebhooks.domain" /> : "" } </Label>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ paddingLeft: `${domains && domains.length > 0 && domains.length == 1 ? "0" : ''}` }}>
                                            {domains && domains.length > 0 && domains.length == 1 ?
                                                <Label style={{ marginTop: "5px", paddingLeft: "0", fontWeight: "500", marginTop: "13px" }}>.{domains[0].label}</Label>
                                                : domains && domains.length > 1 ?
                                                    <CustomSelect options={domains} value={this.state.selectedDomain}
                                                       onChange={this.setComboStatus.bind(this, 'selectedDomain')} />
                                                    : ''}
                                        </Col>
                                    </Row>
                                </Col>
                            </FormGroup>
                            <Authorization
                                perform="emailReservationWebHook:edit"
                                yes={() => (
                                    <FormGroup row>
                                        <Col>
                                            <Row>
                                                <Col className="col-8">
                                                    <Label><FormattedMessage id="EmailResWebhooks.SpamCheck" /></Label>
                                                </Col>
                                                <Col>
                                                    <CustomInput type="checkbox" id="spamcheck" name="spamcheck" checked={webhook.spamcheck} onChange={(e) => this.handleComboChange(e)} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col className="col-8">
                                                    <Label><FormattedMessage id="EmailResWebhooks.SendRaw" /></Label>
                                                </Col>
                                                <Col>
                                                    <CustomInput type="checkbox" id="sendraw" name="sendraw" checked={webhook.sendraw} onChange={(e) => this.handleComboChange(e)} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </FormGroup>
                                )}
                                no={() => (
                                    ""
                                )}
                            />
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(EmailResWebhookModal);