import React, { Component } from 'react';
import { Row, Col, Input, FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export class HotelInformationForm extends Component {

    render() {
        const { hoteldata } = this.props;

        return (
            <div>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="companyname" sm={2}> <FormattedMessage id="HotelInformationForm.CompanyName" /> </Label>
                            <Col sm={10}>
                                <FormattedMessage id="HotelInformationForm.CompanyName">
                                {
                                    placeholder => <Input type="text" name="companyname" id="companyname" bsSize="sm" placeholder={placeholder} readOnly defaultValue={hoteldata.name1} />
                                }</FormattedMessage>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="fiscalNumber" sm={4}> <FormattedMessage id="HotelInformationForm.FiscalNumber" /> </Label>
                            <Col sm={8}>
                                <FormattedMessage id="HotelInformationForm.FiscalNumber">
                                {
                                    placeholder => <Input type="text" name="fiscalNumber" id="fiscalNumber" bsSize="sm" placeholder={placeholder} readOnly defaultValue={hoteldata.fiscalNumber} />
                                }</FormattedMessage>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6" />
                </Row>
                {/*
                <Row>
                    <Col className="col-6">
                        <FormGroup className="form-control-sm" row>
                            <Label for="Latitude" sm={4}> <FormattedMessage id="HotelInformationForm.Latitude" /> <span className="glyphicon glyphicon-question-sign" /></Label>
                            <Col sm={8}>
                                <InputGroup size="sm">

                                    <Input name="Latitude" />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText> <FormattedMessage id="HotelInformationForm.Latitude" /> </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup className="form-control-sm" row>
                            <Label for="Longitude" sm={4}> <FormattedMessage id="HotelInformationForm.Longitude" /> <span className="glyphicon glyphicon-question-sign" /></Label>
                            <Col sm={6}>
                                <InputGroup size="sm">
                                    <Input name="Longitude" />
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText> <FormattedMessage id="HotelInformationForm.Longitude" /> </InputGroupText>
                                    </InputGroupAddon>
                                    </InputGroup>
                            </Col>

                            <Col sm={2} className="text-right"><Button className="btn btn-host btn-sm text-white float-right"  > <FormattedMessage id="HotelInformationForm.GetPosition" /> </Button> </Col>
                                
                        </FormGroup>
                    </Col>
                </Row>
                */}
            </div>
        );
    }
}