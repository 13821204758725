import React, { useEffect, useState } from 'react';
import { Badge, Card, CardBody, CardHeader, Col, UncontrolledPopover, PopoverBody, Row, Input, InputGroup, Button, InputGroupAddon, Modal, ModalFooter, DropdownMenu, DropdownItem, DropdownToggle, Dropdown, UncontrolledTooltip, ModalHeader, ModalBody, Label, Form } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage, FormattedNumber } from 'react-intl';
import BlockUi from 'react-block-ui';
import ReactHtmlParser from 'react-html-parser';
import { ErrorAlert } from "../../Common/ErrorAlert";
import CustomSelect from "../../Base/Common/CustomSelect";
import { getHelpLink, getChannelConfig, getPaymentStatusOptions } from "./ReferenceDataFunctions";
import { getAPI, postAPI } from "../../Base/API"
import { IntlContext } from "../../../Utils/IntlProviderWrapper";
import CustomToast from './CustomToast';
import CustomToolTip from '../../../Utils/CustomToolTip';
import { HandleHelp, HandleHelpClick } from '../../Base/Common/EventGA';
import { authContext } from '../../../Utils/AdalConfig';
import AiSparkles from '../../../img/AiSparkles.png';
import { EditorState, ContentState } from 'draft-js';
import { handleNotification } from '../Notification';

export const SmallScreenUser = ({ func }) => (
    <Col className="text-uppercase" onClick={func} style={{
        height: '35px',
        width: '35px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#989898',
        color: 'white',
        borderRadius: '100%',
        fontSize: '0.9em'
    }}>
        {authContext.getCachedUser()?.profile?.given_name[0]}
    </Col>
)

export const CancelPolicyText = ({ policy }) => (    
    <div>
        {policy ? 
            <div>                
                {policy.type === 'Flexible' ?
                    <p className="mb-0">
                        <FormattedHTMLMessage id="CancelationPolicy.Flexible" values={{ daysBefore: policy.daysBefore, percentageAfter: policy.percentageAfter }} />
                    </p>
                : ''}

                {policy.type === 'NonRefundable' ?
                    <p className="mb-0">
                        <FormattedHTMLMessage id="CancelationPolicy.NonRefundable" values={{ noShowPenalty: policy.noShowPenalty }} />
                    </p>
                : ''}

                {policy.type === 'FlexibleComplete' ?
                    <p className="mb-0"> <FormattedHTMLMessage id="CancelationPolicy.FlexibleComplete" values={{ cancelUntil: policy.cancelUntil, percentageBefore: policy.percentageBefore, percentageAfter: policy.percentageAfter, daysBefore: policy.daysBefore }} />
                        </p>
                : ''}

                {policy.type === 'FlexibleDaily' ?
                    <p className="mb-0"> <FormattedHTMLMessage id="CancelationPolicy.FlexibleDaily" values={{ percentageAfter: policy.percentageAfter }} />
                        </p>
                : ''}

                {policy.type === 'FlexibleDailyComplete' ? 
                    <p className="mb-0"> <FormattedHTMLMessage id="CancelationPolicy.FlexibleDailyComplete" values={{ daysBefore: policy.daysBefore }} />
                        </p>
                :''}
                
                {policy.type === 'NonRefundableComplete' ?
                    <p className="mb-0"> <FormattedHTMLMessage id="CancelationPolicy.NonRefundableComplete" values={{  cancelUntil: policy.cancelUntil, percentageBefore: policy.percentageBefore, percentageAfter: policy.percentageAfter, daysBefore: policy.daysBefore }} />
                        </p>
                    : ''}

                {policy.type === 'DepositOnly' ?
                    <p className="mb-0">
                        <FormattedHTMLMessage id="CancelationPolicy.DepositOnly" values={{ percentageBefore: policy.percentageBefore }} />
                    </p>
                : ''}

                {policy.noShowPenalty > 0 ?
                    <p className="mb-0"> <FormattedHTMLMessage id="CancelationPolicy.NoShowPenalty" values={{ noShowPenalty: policy.noShowPenalty }} />
                        </p>
                : ''}
            </div>
        : ''}
    </div>

);


export const MealPolicyText = ({ policy }) => (
    <div>
        {policy ?
            <div>
                {policy === 'RO' ? <p className="mb-0"> Room Only  </p> : ''}
                {policy === 'BB' ? <p className="mb-0"> Bed and Breakfast</p> : ''}
                {policy === 'HB' ? <p className="mb-0"> Half Board  </p> : ''}
                {policy === 'FB' ? <p className="mb-0"> Full Board  </p> : ''}
                
            </div> : ''}
    </div>

);

export const reservationStatus = [
    {
        value: 'Created',
        label: <FormattedMessage id="CommonUIComponents.Created" />,
        badge: 'success',
        disabled: false
    },
    {
        value: 'Modified',
        label: <FormattedMessage id="CommonUIComponents.Modified" />,
        badge: 'warning'
    },
    {
        value: 'Canceled',
        label: <FormattedMessage id="CommonUIComponents.Canceled" />,
        badge: 'danger'
    },
	{
        value: 'Pending',
        label: <FormattedMessage id="CommonUIComponents.Pending" />,
        badge: "primary"
    }
];


export const ReservationStatusBadge = ({ status, resIdValue, isAbandoned }) => {
    const currentStatus = reservationStatus.find(item => item.value === status);
    return (
        <Badge className={'p-1 px-2 shadow text-white'} color={currentStatus != null ? currentStatus.badge : 'danger'}>
            {isAbandoned ?
                <span>
                    <Badge id={`ReservationAutomaticallyCancelled${resIdValue}`} className="float-right mx+1" style={{ margin: '-9px -14px' }}>
                        <i className="fas fa-stopwatch" />
                    </Badge>
                    <UncontrolledTooltip placement="bottom" target={`ReservationAutomaticallyCancelled${resIdValue}`}>
                        <FormattedMessage id="ReservationDetail.ReservationAutomaticallyCanceled" />
                    </UncontrolledTooltip>
                </span>
            :''}
            {currentStatus != null ? currentStatus.label : ''}
        </Badge>
    )
};

export function getCommunicationStatus() {
    const reservationCommStatus = [
        {
            value: 'Processed',
            label: <FormattedMessage id="CommonUIComponents.Processed" />,
            badge: 'badge-success text-white',
            icon: <i className=" text-success fas fa-check"/>
        },
        {
            value: 'Sent',
            label: <FormattedMessage id="CommonUIComponents.Sent" />,
            badge: 'badge-warning pr-3',
            icon: <i className="text-warning far fa-hourglass"/>
        },
        {
            value: 'Error',
            label: <FormattedMessage id="CommonUIComponents.Error" />,
            badge: 'badge-danger text-white',
            icon: <i className="text-danger fas fa-times"/>
        },
        {
            value: 'MappingError',
            label: <FormattedMessage id="CommonUIComponents.MapError" />,
            badge: 'badge-danger text-white',
            icon: <i className="fas fa-exclamation-triangle" style={{ color: "#fc7f43" }}/>
        },
        {
            value: 'NoSend',
            label: <FormattedMessage id="CommonUIComponents.NotSent" />,
            badge: 'badge-secondary text-white',
            icon: <i className="text-secondary fas fa-times"/>
        },
        {
            value: "Pending",
            label: <FormattedMessage id="CommonUIComponents.Pending" />,
            badge: 'badge-primary text-white',
            icon: <i className="text-secondary fas fa-spinner"/>
        },
    ];

    return reservationCommStatus;
}

export const ReservationCommStatusIcon = ({ status }) => {
    const currentStatus = getCommunicationStatus().find(item => item.value === status);
    if (currentStatus != null) {
        return (
            <Badge className={'p-1 px-2 shadow ' + (currentStatus.badge)} >{currentStatus.label}</Badge>
        );
    }
    else {
        const notSent = <FormattedMessage id="CommonUIComponents.NotSent" />;
        return (
            <Badge className={'p-1 px-2 shadow badge-danger'} >{notSent}</Badge>
        );
    }
    
};

export const ActiveInactiveStatusBadge = ({ status }) => (
    <Badge color={status ? 'primary' : 'secondary'}  className={'p-1 px-2 shadow'}>{status ? <FormattedMessage id="generic.active" /> : <FormattedMessage id="generic.inactive" />}</Badge>
);

export const ActiveInactiveStatusCombo = ({ name, onChangeFunc, required = true, value, placeholder, isDisabled, isClearable }) => {
    const statusOptions = [
        { value: true, label: <FormattedMessage id="generic.active" /> },
        { value: false, label: <FormattedMessage id="generic.inactive" /> }
    ]

    return (
        <CustomSelect
            placeholder={placeholder}
            options={statusOptions}
            onChange={onChangeFunc.bind(this, name)}
            required={required}
            value={statusOptions.find(el => el.value === value)}
            isDisabled={isDisabled}
            isSearchable
            isClearable={isClearable}
        />
    )
};

export const PaymentStatusBadge = ({ status }) => {
    const currentStatus = getPaymentStatusOptions().find(item => item.value === status);
    return (
        <Badge className={'p-1 px-2 shadow text-white'} color={currentStatus != null ? currentStatus.badge : 'danger'} >{currentStatus != null ? currentStatus.label : status}</Badge>
    )
};

export const PageMainCard = ({ block, error, children, icon, title, help, hasCard }) => (
    <BlockUi tag="div" blocking={block}>
        <Card>
            <CardBody>
                <ErrorAlert error={error} />

                <Card className="shadow border-0">
                    <CardHeader className="bg-white border-bottom ">
                        {icon ? <i className={icon + ' mr-1'}></i> : ''} {title ? <FormattedMessage id={title} /> : ''}
                        
                        {help ? <div className="float-right">
                            <span id="mainpagehelp" className="btn-sm btn-link" onClick={() => true} >
                                <i className="text-secondary fas fa-question-circle mt-2 "></i>
                            </span>
                            <UncontrolledPopover data-trigger="focus" placement="left" target="mainpagehelp">
                                <PopoverBody>
                                    {help}
                                </PopoverBody>
                            </UncontrolledPopover>

                        </div> : ''}
                       
                    </CardHeader>
                    <CardBody>
                        {children}
                    </CardBody>
                    
                </Card>
            </CardBody>
        </Card>

    </BlockUi>
);

export const CustomPopover = ({ children, target = "mainpagehelp", placement='left' }) => {
    return (
            <UncontrolledPopover data-trigger="focus" placement={placement} target={`${target}`} style={{maxWidth: '70vw'}}>
                <PopoverBody style={{ maxWidth: '300px' }}>
                    {children}
                </PopoverBody>
            </UncontrolledPopover>
    )
}

export const CustomHelpPopover = ({ help, id, helperTarget = "mainpagehelp", placement='left' }) => {
    const { locale } = React.useContext(IntlContext);
    const helpLink = getHelpLink(id, locale);

    return (
        help ? <div className="float-right">
            <span id={`${helperTarget}`} className="btn-sm btn-link" onClick={() => true} style={{ fontSize: 'large' }}>
                <i className="text-secondary fas fa-question-circle"></i>
            </span>
            <UncontrolledPopover data-trigger="focus" placement={placement} target={`${helperTarget}`} style={{maxWidth: '70vw'}}>
                <PopoverBody style={{ maxWidth: '300px' }}>
                    {help}
                    {helpLink ?
                        <a className="float-right" href={helpLink} target="_blank"> <FormattedMessage id="CommonUIComponents.MoreDetails" />  </a>
                        : ''}
                </PopoverBody>
            </UncontrolledPopover>
        </div> : ''
    )
}

export const StyledCard = ({ block, children, icon, small, title, values, help, error, id, img, helperTarget = "mainpagehelp" }) => {
    const { locale } = React.useContext(IntlContext);
    const helpLink = getHelpLink(id, locale);

    return <Card className={`shadow border-0 ${small ? '' : 'h-100'}`}>
        <ErrorAlert error={error} />
        <BlockUi tag="div" blocking={block}>
            <CardHeader className="bg-white border-bottom ">
                {img ? <img src={img.src} height={img.height} className={img.className} /> : ''}

                {icon ? <i className={icon + ' mr-1'}></i> : ''} {title ? <FormattedMessage id={title} values={values} /> : ''}

                {help ? <div className="float-right">
                    <span id={`${helperTarget}`} className="btn-sm btn-link" onClick={() => true} style={{ fontSize: 'large' }}>
                        <i className="text-secondary fas fa-question-circle"></i>
                    </span>
                    <UncontrolledPopover data-trigger="focus" placement="left" target={`${helperTarget}`} style={{maxWidth: '50vw'}}>
                        <PopoverBody style={{ maxWidth: '300px' }}>
                            {help}
                            {helpLink ?
                                <a className="float-right" href={helpLink} target="_blank"> <FormattedMessage id="CommonUIComponents.MoreDetails" />  </a>
                                : ''}
                        </PopoverBody>
                    </UncontrolledPopover>

                </div> : ''}

            </CardHeader>
            <CardBody>
                {children}
            </CardBody>
        </BlockUi>

    </Card>

};

export const EmptyCard = ({ block, children, icon, title, error }) => (

    <Card className="shadow border-0 h-100">
        <ErrorAlert error={error} />
        <BlockUi tag="div" blocking={block}>
            <CardBody>
                {children}
            </CardBody>
        </BlockUi>

    </Card>

);

export const SelectChannelConfig = ({ icon, name, onChangeFunc, isMulti = false, required, value, placeholder  }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
       
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data && data.length > 0 && Array.isArray(data)) {

                var combo = [];

                data.map((item) =>
                    combo.push({ 'value': item.id, 'label': item.code })
                )
                setData(combo);
                setLoad(false);
            }
        }, '/api/Rate/ChannelConfig/v1');
    }, []);

        return (
            <BlockUi tag="div" blocking={block}>
                
                <CustomSelect icon={icon} options={data} required={required} isClearable isMulti={isMulti} value={data ? data.filter(el => el.value == value) : null} isSearchable placeholder={placeholder} onChange={onChangeFunc.bind(this, name)} />
                 
        </BlockUi>
    )
};


export const SelectChannelInstance = ({ icon, name, onChangeFunc, value, placeholder, isMulti = true }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data && data.length > 0) {

                let active = [], inactive = [];

                data.sort((a, b) => a.channelName > b.channelName ? 1 : -1).forEach((item) => {
                    if (item.active) {
                        active.push({ 'label': `${item.channelName} (${item.propertyCode}) ${item.name}`, 'value': item.id })
                    }
                    else {
                        inactive.push({ 'label': `${item.channelName} (${item.propertyCode}) ${item.name}`, 'value': item.id })
                    }
                })

                var combo = [
                    {
                        label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="generic.active" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{active.length}</span> </div >,
                        options: active
                    },
                    {
                        label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="generic.inactive" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{inactive.length}</span> </div >,
                        options: inactive
                    },
                ];

                setData(combo);
                setLoad(false);
            }
            setLoad(false);
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance');
    }, []);

    return (
        <BlockUi tag="div" blocking={block}>
            <CustomSelect icon={icon} options={data} isClearable isMulti={isMulti} isSearchable placeholder={placeholder} value={data && data.length > 0 ? data[0].options.concat(data[1].options).filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : null} onChange={onChangeFunc.bind(this, name)}
            />
        </BlockUi>
    )
};

export const SelectChannelInstanceByChannelCode = ({ icon, name, onChangeFunc, channelCode, value, activeOnly,  placeholder = 'Choose Channel' }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data && data.length > 0) {
                let active = [], inactive = [];

                if (data.length > 0 && !value) {
                    var id = data[0].id;
                    onChangeFunc(name,{ 'value': id, 'label': data[0].name + ' (' + data[0].propertyCode + ')' });
                }

                let filterData = [];
                if (activeOnly) {
                    filterData = data.filter(el => el.active === true);
                } else {
                    filterData = data.sort((a, b) => a.name > b.name ? 1 : -1);
                }

                filterData.forEach((item) => {
                    if (item.active) {
                        active.push({ 'label': `${item.name} (${item.propertyCode}) ${item.name}`, 'value': item.id })
                    }
                    else {
                        inactive.push({ 'label': `${item.name} (${item.propertyCode}) ${item.name}`, 'value': item.id })
                    }
                })

                var combo = [
                    {
                        label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="generic.active" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{active.length}</span> </div >,
                        options: active
                    },
                    {
                        label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="generic.inactive" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{inactive.length}</span> </div >,
                        options: inactive
                    },
                ];

                setData(combo);
                setLoad(false);
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/' + channelCode);
    }, []);

    return (
        <BlockUi tag="div" blocking={block}>
            {data && data.length > 0 ?
                <CustomSelect
                    icon={icon}
                    options={data}
                    isSearchable
                    placeholder={placeholder}
                    defaultValue={data && data.length > 0 ? data[0].options.concat(data[1].options)[0] : ''}
                    value={data && data.flatMap(el => el.options).find(el => el.value == value) || ''}
                    onChange={onChangeFunc.bind(this, name)}
                />
                : ''}
        </BlockUi>
    )
};

export const SelectHotel = ({ icon, name, onChangeFunc, isMulti = false, required, value, placeholder, isDisabled }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data && data.data && data.data.length > 0) {

                var combo = [];
                data.data.map((item) =>
                    combo.push({ 'value': item.hotelId, 'label': item.name2, sendRoomRate: item.sendCMRoomRate })
                )
                setData(combo);
                setLoad(false);
            }
        }, '/api/hotel/v1?pageSize=2000&pageIndex=0');
    }, []);

    return (
        <BlockUi tag="div" blocking={block}>
            {data.length > 0 ?
                <CustomSelect icon={icon} options={data} required={required} isClearable isMulti={isMulti} value={data ? data.filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : ''} isSearchable placeholder={placeholder} onChange={onChangeFunc.bind(this, name)} isDisabled={isDisabled} />
                : ''}
        </BlockUi>
    )
};

export const SelectRoomCategory = ({ icon, name, onChangeFunc, placeholder = 'Room Category', isMulti = true, required, value }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data.response && data.response.length > 0) {

                var combo = [];

                data.response.map((item) =>
                    combo.push({
                        'label': item.description + ' (' + item.code + ')', 'value': item.id,
                        numberOfRooms: item.numberOfRooms, limitAvailability: item.limitAvailability
                    })
                )
                setData(combo);
                setLoad(false);
            }
            setLoad(false);
        }, '/api/hotel/RoomCategory/v1/Info');
    }, []);

    return (
        <BlockUi tag="div" blocking={block}>
            <CustomSelect value={data.find(rc => '' + rc.value === value)} icon={icon} options={data} required={required} isClearable isMulti={isMulti} isSearchable placeholder={placeholder} onChange={onChangeFunc.bind(this, name)} />
        </BlockUi>
    )
};

export const Pagination = ({ isPrevDisabled, isNextDisabled, currentPage, handlePrevButton, handleNextButton, total = 0 }) => {
    return (
        <Row className="mt-2">
            <Col>
                <FormattedMessage id="generic.Results" values={{ results: total }} />
            </Col>
            <Col className="text-right">
                <Button className="btn btn-host btn-sm" onClick={handlePrevButton} disabled={isPrevDisabled}>
                    <i className="fas fa-angle-left" />
                </Button>
                <span className="mx-2">
                    <FormattedMessage id="generic.Page" values={{ page: currentPage }} />
                </span>
                <Button className="btn btn-host btn-sm" onClick={handleNextButton} disabled={isNextDisabled}>
                    <i className="fas fa-angle-right" />
                </Button>
            </Col>
        </Row>
        
    )
}

export const ConfirmActionModal = ({ block, error, text, toggleModal, modal, actionFunction }) => {
    return (
        <Modal isOpen={modal} toggle={toggleModal} fade={false} className="modal-md" style={{ minWidth: 0, marginTop: '15%' }}>
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />

                <div className="text-center p-4">
                    {text}
                </div>
                <ModalFooter className="py-2 border-0">
                    <Button className="btn btn-sm btn-host mr-2" onClick={actionFunction}>
                        <FormattedMessage id="generic.confirm" />
                    </Button>
                    <Button className="btn btn-sm" onClick={toggleModal}>
                        <FormattedMessage id="generic.cancel" />
                    </Button>
                </ModalFooter>
            </BlockUi>
        </Modal>
    )
};

export const BinaryActionModal = ({ block, error, text, toggleModal, modal, affirmativeFunction, negativeFunction }) => {
    return (
        <Modal isOpen={modal} toggle={toggleModal} fade={false} className="modal-md" style={{ minWidth: 0, marginTop: '15%' }}>
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />

                <div className="text-center p-4">
                    {text}
                </div>
                <ModalFooter className="py-2 border-0">
                    <Button className="btn btn-sm btn-host mr-2" onClick={affirmativeFunction}>
                        <FormattedMessage id="generic.yes" />
                    </Button>
                    <Button className="btn btn-sm btn-host" onClick={negativeFunction}>
                        <FormattedMessage id="generic.no" />
                    </Button>
                </ModalFooter>
            </BlockUi>
        </Modal>
    )
};

export const SelectRate = ({ icon, name, onChangeFunc, placeholder = 'Choose Rate', isMulti = true, required = false, value = '' }) => {

    const [data, setData] = useState([]);
    const [block1, setLoad] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data.response && data.response.length > 0) {

                var combo = [];

                data.response.map((item) =>
                    combo.push({ 'label': item.name , 'value': item.id })
                )
                setData(combo);
                setLoad(false);
            }
        }, '/api/Rate/Rate/v1/Info');
    }, []);

    return (
        <BlockUi tag="div" blocking={block1}>
            <CustomSelect icon={icon} options={data} isClearable isMulti={isMulti} isSearchable placeholder={placeholder} onChange={onChangeFunc.bind(this, name)} required={required}
                value={data ? data.filter(el => Array.isArray(value) ? value.find(v => el.value == v) : el.value == value) : ''}
            />
        </BlockUi>
    )
};


export function prettifyXml(sourceXml) {
    var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
    var xsltDoc = new DOMParser().parseFromString([
        // describes how we want to modify the XML - indent everything
        '<xsl:stylesheet  version="1.0" xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
        '    <xsl:value-of select="normalize-space(.)"/>',
        '  </xsl:template>',
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        '  </xsl:template>',
        '  <xsl:output indent="yes"/>',
        '</xsl:stylesheet>',
    ].join('\n'), 'application/xml');

    var xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    var resultXml = new XMLSerializer().serializeToString(resultDoc);
    return resultXml;
};

export const CommonHelper = ({ help, id, placement = 'left', customFontSize = 'large' }) => {
    const { locale } = React.useContext(IntlContext);
    const helpLink = getHelpLink(id, locale);

    return (
        <div className="float-right tutorialHide">
            <span id={id} className="btn-sm btn-link" onClick={() => HandleHelp(id)} style={{ fontSize: customFontSize }}>
                <i className="text-secondary fas fa-question-circle"></i>
            </span>
            <UncontrolledPopover trigger="legacy" flip={false} placement={placement} target={id}>
                <PopoverBody style={{ maxWidth: '500px' }}>
                    <div className="mb-1">
                        {help}
                    </div>
                    {helpLink ?
                        <a className="float-right" href={helpLink} target="_blank">
                            <span onClick={_ => HandleHelpClick(id, helpLink)}>
                                <FormattedMessage id="CommonUIComponents.MoreDetails"/>
                            </span>
                        </a>
                    : ''}
                </PopoverBody>
            </UncontrolledPopover>
        </div> 
    )
};

export const BlankCard = ({ block, children, error }) => (
    <Card className="border-0 h-100">
        <ErrorAlert error={error} />
        <BlockUi tag="div" blocking={block}>
            <CardBody>
                {children}
            </CardBody>
        </BlockUi>
    </Card>
);


export const processStatusValues = [
    'MessageProcessStatusIsPending',
    'MessageProcessStatusIsResolved'
];

const isChildRate = [
    {
        value: true,
        label: <FormattedMessage id="ChannelRoomRate.IsChildRate" />
    },
    {
        value: false,
        label: <FormattedMessage id="ChannelRoomRate.IsNotChildRate" />
    },
];

export function getIsChildRateLabel(value) {
    const elem = isChildRate.find(childRate => childRate.value === value);
    return elem !== undefined ? elem.label : '';
}

export const CountryCustomSelect = ({ countryList, onChange, value }) => {
    if (countryList) {
        return (
            <CustomSelect
                options={countryList().getData()}
                isClearable
                isMulti={false}
                value={value}
                isSearchable
                onChange={onChange}
                placeholder={''}
            />
        );
    }
    else {
        return (
            <CustomSelect options={[]}/>
        );
    }
};

export const CustomAnnouncement = ({ announcement }) => {
    const { locale } = React.useContext(IntlContext);

    const text =
        announcement.announcementText && announcement.announcementText.length > 0 ?
            announcement.announcementText.find(t => t.language.includes(locale)) ?
                announcement.announcementText.find(t => t.language.includes(locale)).text :
                announcement.announcementText.find(t => t.language === 'en-GB') ?
                    announcement.announcementText.find(t => t.language === 'en-GB').text : announcement.announcementText[0].text
            : null;

    return (
        <CustomToast title={<FormattedHTMLMessage id="home.Anouncement" />} body={''} show={true} icon={'fas fa-bullhorn'}>
            <Row>
                <Col className="col-2">
                    <img alt="" src={getChannelConfig(announcement.channelConfigDetails.channelCode) ? getChannelConfig(announcement.channelConfigDetails.channelCode).image : ''} height='30px' className="mr-2" />
                </Col>
                <Col className="col-10">
                    {text ? ReactHtmlParser(text) : ''}
                </Col>
            </Row>
        </CustomToast> 
    ); 
};


const supportedLanguages = [
    'en-GB','pt-PT', 'es-ES'
];

const MultiLang = ({ toggle, lang, children }) => {
    return (
        <div>
            <Row>
                <Col style={{ maxWidth: '75px' }} className="pr-0">
                {supportedLanguages.map((country, key) =>
                    <div key={key} className={"bg-white p-2 border " + (lang === country ? 'border-left-primary ' : '')} style={{
                        position: "relative",
                        right: "-1px",
                        zIndex: "2",
                        cursor: "pointer"
                    }} onClick={() => toggle(country)}>
                        <div className={`flag-icon flag-icon-${country.slice(- 2).toLowerCase()} mb-2 px-2`} />
                    </div>
                ) }
                </Col>
                <Col className="pl-0">
                    <div className="border">
                        {children}
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export const MultiLangTitleAndDescription = ({ textList, handleChangeMultiLang }) => {
    const [lang, setLang] = useState(supportedLanguages[0]);

    const toggle = l => {
        if (lang !== l) setLang(l);
    }

    const text = textList && textList.find(t => t.culture === lang);

    return (
        <MultiLang toggle={toggle} lang={lang} >
            <div>
                <Row className="mb-2">
                    <Col>
                        <FormattedMessage id="CommonUIComponents.Title">
                            {placeholder =>
                                <Input
                                    type="text" style={{ border: 'none' }} className="px-2"
                                    name="title" id="title" placeholder={placeholder}
                                    value={text && text.title ? text.title : ''}
                                    onChange={(e) => handleChangeMultiLang(e, lang, 'title')}
                                    required
                                />
                            }
                        </FormattedMessage>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormattedMessage id="CommonUIComponents.Description">
                            {placeholder =>
                                <Input
                                    className="px-2" style={{ border: 'none' }} type="textarea"
                                    placeholder={placeholder} rows="4" max="3500" name="description"
                                    value={text && text.description ? text.description : ''}
                                    onChange={(e) => handleChangeMultiLang(e, lang, 'description')}
                                    required
                                />
                            }
                        </FormattedMessage>
                    </Col>
                </Row>
            </div>
        </MultiLang>
    );
};


export const IcalUrlButton = ({ id, rateId, getiCalSyncUrls, url, style }) => {

    return (
        <>
            <Button style={style ? style : {}} id={id} className="btn-sm btn-host" onClick={() => { if (!url) getiCalSyncUrls(rateId) }}>
                <FormattedMessage id="ChannelMapping.GetIcalUrl" />
            </Button>
            <UncontrolledPopover placement="top" target={id}>
                <PopoverBody>
                    <InputGroup>
                        <Input type="text" className="cleanInput" value={url} disabled />
                        <InputGroupAddon addonType="append">
                            <Button id={'UncontrolledTooltip' + rateId} onClick={() => { navigator.clipboard.writeText(url) }}>
                                <i className="fas fa-copy" />
                            </Button>
                            <CustomToolTip placement="right" target={id}>
                                <FormattedMessage id="ChannelMapping.CopyToClipboard" />
                            </CustomToolTip>
                        </InputGroupAddon>
                    </InputGroup>
                </PopoverBody>
            </UncontrolledPopover>
        </>
    );
};


export const FormatAmountNumber = ({ value, currency }) => {
    return <FormattedNumber
        value={value}
        maximumFractionDigits={2}
        style="currency"
        currency={currency || global.hotelCurrency}
    />
}


export const KebabMenu = ({ editFunction, deleteFunction, extraFields, icalUrl, url, small }) => {
    const [showICall, setShowICall] = useState(false);
    const [isOpen, setIsOpen] = useState(false);


    const toggleKebabMenu = (event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            setIsOpen((prevState) => !prevState);
        }
    }


    return <div>
        <Dropdown isOpen={isOpen} toggle={() => { }} onBlur={toggleKebabMenu}>
            <DropdownToggle style={{ background: 'none', border: 'none', color: 'unset', padding: 'unset', width: '10px' }} onClick={toggleKebabMenu}>
                <i className="fas fa-ellipsis-v" style={{ fontSize: small ? '0.8em' : '' }}/>
            </DropdownToggle>

            <DropdownMenu positionFixed={true}>
                {editFunction ?
                    <DropdownItem onClick={editFunction}>
                        <i className="fas fa-edit mr-2" />
                        <FormattedMessage id="RoomRateBar.Edit" />
                    </DropdownItem>
                    : ''}

                {extraFields ?
                    extraFields.map((field, key) =>
                        <DropdownItem key={key} onClick={field.function}>
                            {field.icon ?
                                <i className={field.icon} />
                            :''}
                            <FormattedMessage id={field.text} />
                        </DropdownItem>
                    )
                : ''}

                {icalUrl ?
                    <>
                        <DropdownItem onClick={() => { if (!icalUrl.url) { icalUrl.getiCalSyncUrls() } setShowICall(!showICall); setIsOpen(false) }} id={icalUrl.id} >
                            <i className="fas fa-link mr-2" />
                            <FormattedMessage id="ChannelMapping.GetIcalUrl" />
                        </DropdownItem>
                    </>
                    : ''}

                {deleteFunction ?
                    <DropdownItem onClick={deleteFunction} style={{ color: '#A80C19' }}>
                        <i className="mr-2 far fa-trash-alt" />
                        <FormattedMessage id="generic.Delete" />
                    </DropdownItem>
                    : ''}
            </DropdownMenu>
        </Dropdown>

        {showICall ?
            <Modal isOpen={showICall} toggle={() => setShowICall(!showICall)} style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <InputGroup>
                    <Input type="text" className="cleanInput" value={icalUrl.url} disabled />
                    <InputGroupAddon addonType="append">
                        <Button onClick={() => { navigator.clipboard.writeText(icalUrl.url) }}>
                            <i className="fas fa-copy" />
                        </Button>
                        <CustomToolTip placement="right" target={icalUrl.id}>
                            <FormattedMessage id="ChannelMapping.CopyToClipboard" />
                        </CustomToolTip>
                    </InputGroupAddon>
                </InputGroup>
            </Modal>
        :''}
    </div>
};


export const Crt = ({ text, value }) => {
    return <Card className="shadow h-100 py-2 px-3" body>
        <Row className="align-items-center">
            <Col>
                <div className="text-secondary mb-1">
                    {text}
                </div>
            </Col>
        </Row>
        <Row>
            <Col className="text-right">
                <b className="font-weight-bold text-secondary mb-1">
                    {value} 
                </b>
            </Col>
        </Row>
    </Card>
}

export const ChartLegend = ({ label, color, borderColor, isVisible = true, toggleIsVisible, value }) => {

    return <div className="mb-1 pl-2" onClick={toggleIsVisible}
        style={{ fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif", fontSize: '12px', fontStyle: 'normal', color: '#6f6f6f', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        <div className="align-items-center" style={{ display: 'inline-flex' }} title={label}>
            <div style={{ width: '35px', height: '9.5px', backgroundColor: color, border: `2px solid ${borderColor || color}` }} className="mr-2" />
            {value || value === 0 ? 
                <span style={isVisible ? {} : { textDecoration: 'line-through', textDecorationThickness: '2px' }}>
                    {label} - {value}
                </span>
            :
                <span style={isVisible ? {} : { textDecoration: 'line-through', textDecorationThickness: '2px' }}>
                    {label}
                </span>
            }
            
        </div>
    </div>
}

export function getColorPallete() {
    return [
        '#0867fa',
        '#59B2C2',
        '#48d7ea',
        '#8B75EA',
        '#CF53FA',
        '#FA339A',
        "#F16D84",
        '#FFBE2D',
        '#FF8A00',
        '#62a140',
        '#66E27F',
        '#8EE3C8',
        '#D7EAFF',
        '#405FC1'
    ];
}


export const StarRating = ({ value, isHostReviewGuest = false, handleChange }) => {
    const [hoverIndex, setHoverIndex] = useState(null);

    return (
        [1, 2, 3, 4, 5].map((star, key) => {
            const isSelected = star <= value;

            return <span key={key} className="mr-1"
                onMouseEnter={() => isHostReviewGuest && setHoverIndex(star)}
                onMouseLeave={() => isHostReviewGuest && setHoverIndex(null)}
            >
                <i
                    className={`${isSelected ? 'fas ' : 'far '} fa-star ${isSelected || star <= hoverIndex ? ' text-warning ' : ' text-secondary '} ${isHostReviewGuest && ' pointer '}`}
                    onClick={() => isHostReviewGuest && handleChange(star)}
                />
            </span>
        })
    )
}

export const DescriptionGpt = ({ elementDescription, currentLang, onEditorStateChange, intl, page, toggleGptConfig, isChatGptOpen }) => {
    const [descriptionTypeOptions] = useState([
        { value: 'Description', label: <FormattedMessage id="GPT.GenerateDescription" /> },
        { value: 'Translate', label: <FormattedMessage id="GPT.Translate" /> },
        { value: 'ReWrite', label: <FormattedMessage id="GPT.ReWrite" /> },
    ]);
    const [toneOptions] = useState([
        { value: 'Funny', label: <FormattedMessage id="GPT.Funny" /> },
        { value: 'Professional', label: <FormattedMessage id="GPT.Professional" /> },
        { value: 'Casual', label: <FormattedMessage id="GPT.Casual" /> },
        { value: 'Neutral', label: <FormattedMessage id="GPT.Neutral" /> }
    ]);
    const [type, setType] = useState('Description');
    const [tone, setTone] = useState('Neutral');
    const [currentDescription, setCurrentDescription] = useState('');
    const [previousDescription, setPreviousDescription] = useState('');
    const [showUndo, setShowUndo] = useState(false);
    const [showRedo, setShowRedo] = useState(false);
    const [isDescriptionGenerated, setIsDescriptionGenerated] = useState(false);
    const [bulletPoints, setBulletPoints] = useState('');
    const [block, setBlock] = useState(false);

    const generateAiResponse = () => {
        setBlock(true);
    
        const isTranslateType = type === 'Translate';
        const isDescriptionType = type === 'Description';
        const hasValidCurrentDescription = currentDescription && currentDescription !== '';
        const hasBulletPoints = bulletPoints && bulletPoints !== '';
        const hasRequiredFields = currentLang && tone && ((isDescriptionType && hasBulletPoints) || hasValidCurrentDescription || isTranslateType);
        let translateDescription = currentDescription;
        
        if (!hasRequiredFields) {
            setBlock(false);
            return;
        }
        
        if (isTranslateType && !hasValidCurrentDescription) {
            let description = null;
            if(page === 'Hotel' || page === 'Room' || page === 'RoomPolicies' || page === 'RatePolicies' || page === 'Experience'){
                description = elementDescription?.descriptions?.find(d => d.description && d.description !== '')?.editorState?.getCurrentContent()?.getPlainText() || '';
            }else if(page === 'Extra'){
                description = elementDescription?.descriptions?.find(d => d.description && d.description !== '')?.description || '';
            }else if(page === 'TermsAndConditions'){
                description = elementDescription?.termsAndConditions?.find(d => d.description && d.description !== '')?.editorState?.getCurrentContent()?.getPlainText() || '';
            }else if(page === 'PaymentPolicies'){
                description = elementDescription?.paymentPolicy?.find(d => d.description && d.description !== '')?.editorState?.getCurrentContent()?.getPlainText() || '';
            }else if(page === 'Rate'){
                description = elementDescription?.find(d => d.description && d.description !== '')?.description || '';
            }
            
            if(description){
                translateDescription = description;
            }
        }
    
        const requestBody = {
            type: isDescriptionType ? `${page}Description` : type,
            description: isDescriptionType ? bulletPoints : isTranslateType ? translateDescription : currentDescription,
            language: currentLang,
            tone: isTranslateType ? 'Neutral' : tone,
        };
    
        postAPI((result) => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [{
                    message: error.message,
                    stack: error.stack,
                    messageType: 'danger',
                    code: 'Error',
                }];
    
                handleNotification({ errors: errorMessage });
                setBlock(false);
                return;
            }
    
            if (data) {
                if(data.errors){
                    handleNotification(data);
                }else{
                    setPreviousDescription(isTranslateType ? translateDescription : currentDescription);
                    if(page === 'Extra'){
                        const desc = {target: {name: 'description', value: data}};
                        onEditorStateChange(desc, currentLang);
                    }else{
                        const newEditorState = stringToEditorState(data);
                        onEditorStateChange(newEditorState, currentLang);
                    }
                    setBulletPoints('');
                    setShowUndo(true);
                    setShowRedo(false);
                    setIsDescriptionGenerated(true);
                }
            }
    
            setBlock(false);
        }, '/api/hotel/OpenAI/v1/GetDescriptionFromOpenAI', requestBody);
    };

    const stringToEditorState = (text) => {
        const contentState = ContentState.createFromText(text);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    };

    const changeType = (value) => {
        if(value !== type){
            if(type === 'Description'){
                setBulletPoints('');
            }
            setType(value);

            if(value === 'Translate'){
                setTone('Neutral')
            }
        }
    }

    const changeTone = (value) => {
        setTone(type === 'Translate' ?'Neutral' : value);
    }

    const handleTextChanges = (undo) => {
        setPreviousDescription(currentDescription);
        const editorState = page === 'Extra' ? { target: { name: 'description', value: previousDescription } } : stringToEditorState(previousDescription);
        
        onEditorStateChange(editorState, currentLang);
        setShowUndo(undo);
        setShowRedo(!undo);
        setIsDescriptionGenerated(true);
    };
    
    const undoAiChanges = () => handleTextChanges(false);
    
    const redoAiChanges = () => handleTextChanges(true);

    useEffect(() => {
        const getDescription = (descriptions, key = 'editorState') => {
            const description = descriptions?.find(d => d.culture === currentLang);
            let plainText = '';
    
            if (description) {
                if (key === 'editorState' && description.editorState) {
                    const contentState = description.editorState.getCurrentContent();
                    plainText = contentState?.getPlainText() || '';
                } else {
                    plainText = description[key] || '';
                }
            }

            setCurrentDescription(plainText);
            if (!plainText) setType('Description');
        };
    
        if (['Hotel', 'Room', 'RoomPolicies', 'RatePolicies', 'Experience'].includes(page)) {
            getDescription(elementDescription?.descriptions);
        } else if (page === 'Extra') {
            getDescription(elementDescription?.descriptions, 'description');
        } else if (page === 'TermsAndConditions') {
            getDescription(elementDescription?.termsAndConditions);
        } else if (page === 'PaymentPolicies') {
            getDescription(elementDescription?.paymentPolicy);
        } else if (page === 'Rate') {
            getDescription(elementDescription);
        }
    
    }, [elementDescription, currentLang]);

    useEffect(() => {
        setShowUndo(false);
        setShowRedo(false);
    }, [currentLang]);

    useEffect(() => {
        if(isDescriptionGenerated){
            setIsDescriptionGenerated(false);
        }else{
            setShowUndo(false);
            setShowRedo(false);
        }
    }, [currentDescription]);
    
    return (
        <>
            <BlockUi tag="div" blocking={block}>
                {isChatGptOpen && page !== 'Extra' ? 
                    <hr className={`${page === 'Hotel' ? 'mt-0 mb-2 mr-2' : 'mx-2'} `} />
                :''}
                <div className={`m-2 ${type === 'Description' ? 'd-flex flex-column' : ''}`}>
                    <div className={`${isChatGptOpen ? 'mb-3' : ''} ${global.isMobile ? '' : 'd-flex align-items-end'}`}>
                        {isChatGptOpen ?
                            <>
                                <Col className={`${global.isMobile ? '' : 'col-4'} d-flex flex-column jusitfy-content-between pl-0`}>
                                    <Label><FormattedMessage id="GPT.Action" /></Label>
                                    <div className={`d-flex ${global.isMobile || page === 'Experience' ? 'flex-wrap' : '' } align-items-center`}>
                                        {descriptionTypeOptions.map((option, key) =>
                                            <Badge 
                                                key={key} 
                                                className={`mr-2  ${global.isMobile || page === 'Experience' ? 'mt-2' : '' } ` + 
                                                    (type === option.value ? "GPTactiveTemp" : "GPTinactiveTemp") + 
                                                    ((!currentDescription || currentDescription === '') && (option.value === 'ReWrite') ? ' disabledTemp' : '')
                                                }
                                                onClick={() => changeType(option.value)}
                                            >
                                                {option.label}
                                            </Badge>
                                        )}
                                    </div>
                                </Col>
                                <Col className={`${global.isMobile ? 'mt-2 pl-0' : page === 'Extra' ? 'col-6' : page === 'Experience' ? 'col-5' : 'col-4'} d-flex flex-column justify-content-between`}>
                                    <Label><FormattedMessage id="GPT.Tone" /></Label>
                                    <div className={`d-flex ${global.isMobile || page === 'Experience' ? 'flex-wrap' : ''} align-items-center`}>
                                        {toneOptions.map((option, key) =>
                                            <Badge
                                                key={key}
                                                className={`mr-2 ${global.isMobile || page === 'Experience' ? 'mt-2' : '' } ` + ((tone === option.value) ? "GPTactiveTemp" : "GPTinactiveTemp") + (type === 'Translate' ? ' disabledTemp' : '')} 
                                                onClick={() => changeTone(option.value)}
                                            >
                                                {option.label}
                                            </Badge>
                                        )}
                                    </div>
                                </Col>
                            </>
                        :''}
                        <Col className={`d-flex align-items-center justify-content-end ${!isChatGptOpen ? 'col-12' : global.isMobile ? 'mt-2' : page === 'Experience' ? 'col-3' : page === 'Extra' ? 'col-2' : 'col-4'}  pr-0`}>
                            {isChatGptOpen ?
                               <div className={['Experience', 'Extra'].includes(page) && (showUndo || showRedo) ? 'd-flex flex-column justify-content-center' : ''}>
                                    {showUndo ?
                                        <Button onClick={() => undoAiChanges()} className="GPTactiveTemp generate btn-host btn-sm mr-2">
                                            <div className="d-flex align-items-center justify-content-center" style={{ gap: '0.5rem', fontSize: '0.8em' }}>
                                                <i className="fas fa-undo pr-2" />
                                                <FormattedMessage id="GPT.Undo" />
                                            </div>
                                        </Button>
                                    :''}
                                    {showRedo ?
                                        <Button onClick={() => redoAiChanges()} className="GPTactiveTemp generate btn-host btn-sm mr-2">
                                            <div className="d-flex align-items-center justify-content-center" style={{ gap: '0.5rem', fontSize: '0.8em' }}>
                                                <i className="fas fa-redo pr-2" />
                                                <FormattedMessage id="GPT.Redo" />
                                            </div>
                                        </Button>
                                    :''}
                                    <Button onClick={() => generateAiResponse()} className={`GPTactiveTemp generate btn-host btn-sm mr-2 ${['Experience', 'Extra'].includes(page) && (showUndo || showRedo) ? 'mt-2' : ''}`}>
                                        <div className="d-flex align-items-center justify-content-center" style={{ gap: '0.5rem', fontSize: '0.8em' }}>
                                            <i className="fas fa-keyboard pr-2" />
                                            <FormattedMessage id="GPT.Generate" />
                                        </div>
                                    </Button>
                                </div>
                            :''}
                            <div className="text-right pointer" onClick={toggleGptConfig}>
                                <img
                                    src={AiSparkles}
                                    alt="Chat GPT"
                                    className="p-1"
                                    style={{ width: '28px', borderRadius: '5px', opacity: '90%' }}
                                />
                            </div>
                        </Col>
                    </div>
                    {isChatGptOpen &&type === 'Description' ?
                        <div>
                            <Input
                                type="textarea"
                                value={bulletPoints}
                                onChange={(e) => setBulletPoints(e.target.value)}
                                style={{ minHeight: '100px' }}
                                placeholder={intl ? intl.formatMessage({ id: 'GPT.BulletPoints' }) : 'Define the points you would like to include in your description...'}
                            />
                        </div>
                    : 
                        <div />
                    }
                </div>
            </BlockUi>
        </>
    )
}



export const ConfirmRestrictionsToChannelsModal = ({ isOpen, toggleModal, confirmFunction, cancelFunction }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggleModal} fade={false} className="modal-md" style={{ minWidth: 0 }}>
            <ModalBody>
                <div className="text-right">
                    <span className="pointer p-2" onClick={toggleModal}><i className="fas fa-times"/></span>
                </div>
                <div className="text-center pb-2 pt-3">
                    <FormattedMessage id="CommonUIComponents.ApplyRestrictionsToAllChannels" />
                </div>
            </ModalBody>
            <ModalFooter className="py-2 border-0">
                <Button className="btn btn-sm btn-host mr-2" onClick={confirmFunction}>
                    <FormattedMessage id="generic.yes" />
                </Button>
                <Button className="btn btn-sm" onClick={cancelFunction}>
                    <FormattedMessage id="generic.no" />
                </Button>
            </ModalFooter>
        </Modal>
    )
}