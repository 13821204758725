import moment from 'moment';
import React, { Component } from 'react';
import { Col, CustomInput } from 'reactstrap';

export class EditableRestrictionBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            changedData: false,
            isFocused: false,
            isSelected: false,
            original: null,
            validKeys: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
        };
    }

    componentWillReceiveProps(newProps) {
        const { restriction, day, availability, type } = this.props;
        
        if(newProps.newData !== this.props.newData && newProps.newData !== this.state.value && !newProps.wasSaved){
            const value = type === "number" ? parseInt(newProps.newData??"0") : newProps.newData??false;

            this.setState({ value, changedData: value !== this.state.original });
        }
        else{
            if(!availability) return;
            const currentDay = availability.find(({hotelDate}) => day.isSame(moment(hotelDate), 'date'));
            if(!currentDay) return;
            const value = type === "number" ? currentDay[restriction]??0 : currentDay[restriction]??false;

            if(!newProps.availability) return;
            const newDay = newProps.availability.find(({hotelDate}) => day.isSame(moment(hotelDate), 'date'));
            if(!newDay) return;
            const newValue = type === "number" ? newDay[restriction]??0 : newDay[restriction]??false;

            if(newValue !== value || newProps.wasSaved) this.setState({ original: newValue, value: newValue, changedData: false });
        }
    }

    componentDidMount(){
        const { restriction, day, availability, type } = this.props;

        if(!availability) return;
        const currentDay = availability.find(({hotelDate}) => day.isSame(moment(hotelDate), 'date'));
        if(!currentDay) return;

        const value = type === "number" ? currentDay[restriction]??0 : currentDay[restriction]??false;

        this.setState({ original: value, value });
    }

    handleChange = (e,  isCombo) => {
        const { handleDataChange, restriction, rateId, day } = this.props;
        const { original } = this.state;
        
        if(isCombo || this.numbersonly(e.target.value)){
            let obj = {
                target:{
                    value: e.target.checked,
                    name: restriction
                }
            };
            
            let changedData = (obj.target.value !== original);

            if(!isCombo){
                if(restriction === "stopSales" || restriction === "closeToDeparture" || restriction === "closeToArrival"){
                    obj.target.value = e.target.value;
                    changedData = obj.target.value !==  original;
                }
                else{
                    const numb = parseInt(e.target.value);
                    obj.target.value = (isNaN(numb) ? 0 : numb).toString();
                    changedData = obj.target.value < original || obj.target.value > original;
                }
            };

            this.setState({ value: obj.target.value, changedData }, () => handleDataChange(obj, day, rateId));
        }
    }

    numbersonly(value){
        if((value && isNaN(value) && value !== '-') || (value % 1 !== 0 && value !== '-') || (!isNaN(value) && parseInt(value) < 0)) return false;
        return true;
    }
    
    setIsFocused = () => {
        this.setState({ isFocused: true });
    }
    
    blurEl = (e) => {        
        this.setState({ isFocused: false, isSelected: false });
    }

    keyDown = (e) => {
        const { rowkey, colkey, rateId } = this.props;
        const { isFocused, changedData, validKeys } = this.state;
        let selectedCol = colkey, selectedRow = rowkey;

        if(!isFocused && !e.key?.includes("Arrow") && e.key !== "Tab" && e.key !== "Enter"){
            if(validKeys.includes(e.key)){
                const target = {
                    target: {
                        value: e.key
                    }
                };

                e.preventDefault();
                this.setState({ isFocused: true }, () => this.handleChange(target))
            };
            return;
        }
        else{
            if(e.key === "Enter") {
                if(e.shiftKey) selectedRow = selectedRow - 1;
                else if(isFocused) {
                    selectedRow = selectedRow + 1;
                }
                else {
                    this.setState({ isFocused: true });
                    return;
                };
            }
            else if(e.key === "ArrowUp") {
                if(!isFocused) selectedRow = selectedRow - 1;
                else {
                    e.preventDefault();
                    const value = parseFloat(this.state.value??0) + 1;
                    
                    if(isNaN(value)) return;
                    const target = {
                        target: {
                            value
                        }
                    };
                    this.setState({ isFocused: true }, () => this.handleChange(target));
                    return;
                };
            }
            else if(e.key === "ArrowDown") {
                if(!isFocused) selectedRow = selectedRow + 1;
                else {
                    e.preventDefault();
                    const value = parseFloat(this.state.value??0) - 1;

                    if(isNaN(value)) return;

                    const target = {
                        target: {
                            value
                        }
                    };
                    this.setState({ isFocused: true }, () => this.handleChange(target));
                    return;
                };
            }
            else if(e.key === "ArrowRight") {
                if(!isFocused) selectedCol = selectedCol + 1;
                else return;
            }
            else if(e.key === "ArrowLeft") {
                if(!isFocused) selectedCol = selectedCol - 1;
                else return;
            }
            else if(e.key === "Tab") {
                if(e.shiftKey) selectedCol = selectedCol - 1;
                else selectedCol = selectedCol + 1;
            }
            else if(e.key === "Escape"){
                if(isFocused && changedData) this.resetData();
            }
            else return;
    
            e.preventDefault();

            const element = document.getElementById(`Rule-${selectedRow}&${selectedCol}-${rateId}`);
            if(element){
                element.focus();
            }
        }
    }

    resetData = () => {
        const { original } = this.state;
        this.setState({ changedData: false, value: original, isFocused: false });
    }

    dragOver = (e) => {
        const { dragObj, draggable, rowkey } = this.props;
        if(!draggable) return;
        if(dragObj.rowkey !== rowkey) return;
        if(!dragObj.isRule) return;
        e.preventDefault();
    }
    
    dragEnd = (e) => {
        const { dragObj, draggable, rowkey, dragStart } = this.props;
        const { value } = this.state

        if(!draggable) return;
        if(!dragObj) return;
        if(dragObj.rowkey !== rowkey) return;
        if(!dragObj.isRule) return;

        this.handleChange({target:{value: value}});
        dragStart();
    }
    
    dragEnter = () => {
        const { dragObj, draggable, rowkey } = this.props;

        if(!draggable) return;
        if(!dragObj) return;
        if(dragObj.rowkey !== rowkey) return;
        if(!dragObj.isRule) return;

        this.handleChange({target:{value: dragObj.value}});
    }

    focusCheckBox = (e) => {
        const { focusedInput, rowkey, colkey, rateId } = this.props;
        this.handleChange({target:{checked: !this.state.value}}, true);
        focusedInput(e, rowkey, colkey, rateId, true)
    }

    setSelected = () => {
        const { changeRow, changeCol, rateId, rowkey, colkey, changeSelectedRate } = this.props;
        this.setState({ isSelected: true }, () => {
            changeRow(rowkey);
            changeCol(colkey);
            changeSelectedRate(rateId);
        });
    }

    render() { 
        const { type, rowkey, colkey, dragObj, draggable, dragStart, isLast, rateId, isInvalid } = this.props;
        const { value, changedData, isFocused, isSelected } = this.state;

        const getBorderClass = () => {
            let borderClass = "defaultBorder ";

            if(isSelected){
                borderClass += "inventorySelectedCollum inventorySelectedRow "
            }
            if(isInvalid){
                borderClass += "isInvalid "
            }
            else if(changedData){
                borderClass += "isValid "
            }
            if(isLast) borderClass += "lastRow"

            return borderClass;
        }
        
        return ( type !== 'number' ?
            <Col className={`position-top ${getBorderClass()}`} style={{ minWidth: '60px', backgroundColor: changedData ? isInvalid ? '#ffbebe' : '#ddeeff' : '' }} onClick={this.focusCheckBox}>
                <div className="text-center h-100 font-weight-bold text-uppercase" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div onDragEnter={this.dragEnter}
                        onDragStart={e => draggable && dragStart(e, value, rowkey, colkey, true, false)}
                        onDragOver={this.dragOver}
                        onDragEnd={this.dragEnd}
                        draggable={draggable}
                    >
                        <CustomInput
                            id={`Checkbox-${rowkey}&${colkey}-${rateId}`}
                            type='checkbox'
                            name={`Checkbox-${rowkey}&${colkey}-${rateId}`} 
                            checked={value}
                            onChange={e => this.handleChange(e, true)}
                            onKeyDown={this.keyDown}
                            onBlur={this.blurEl}
                            onClick ={e => this.focusCheckBox(e)}
                            readOnly={dragObj && (((dragObj?.rowkey || dragObj?.rowkey === 0) && dragObj.rowkey !== rowkey) || !dragObj.isRule)}
                            style={{ 'cursor': 'pointer' }}
                        />
                    </div>
                </div>
            </Col>
        :
            <Col className={`position-top ${getBorderClass()}`} style={{ minWidth: '60px', backgroundColor: changedData ? isInvalid ? '#ffbebe' : '#ddeeff' : '' }}>
                <div className="text-center h-100 font-weight-bold text-uppercase" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <input
                        type="text"
                        id={`Rule-${rowkey}&${colkey}-${rateId}`}
                        className={`hiddenNumberArrows py-2 cell ${isSelected ? 'isOpen' : ''}`}
                        value={value}
                        onDoubleClick={this.setIsFocused}
                        min={"0"}
                        onTouchStart={_ => {if(global.isMobile) this.setIsFocused(); this.setSelected()}}
                        onFocus={_ => {if(global.isMobile) this.setIsFocused(); this.setSelected()}}
                        onBlur={this.blurEl}
                        readOnly={!isFocused || (dragObj && dragObj.isRule && ((dragObj?.rowkey || dragObj?.rowkey === 0) && dragObj.rowkey !== rowkey))}
                        onChange={e => this.handleChange(e)}
                        onKeyDown={this.keyDown}
                        max="999"
                        onDragEnter={this.dragEnter}
                        onDragStart={e => draggable && dragStart(e, value, rowkey, colkey, true, false)}
                        onDragOver={this.dragOver}
                        inputMode="decimal"
                        onDragEnd={this.dragEnd}
                        style={{
                            backgroundColor: changedData ? isInvalid ? '#ffbebe' : '#ddeeff' : '',
                            width: '100%',
                            border: '0',
                            height: '100%',
                            fontWeight: '500',
                            lineHeight: '1.2',
                            fontSize: '1rem',
                            textAlign: 'center',
                            outline: 'unset',
                            color: 'inherit'
                        }}
                    />
                </div>
            </Col>
        );

    }
};