import React, { Component } from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import moment from "moment";
import { FormattedMessage } from 'react-intl';

export class RateDetailsBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showRestrictions: false,
            restrictions: [
                {
                    value: 'minStay',
                    label: <FormattedMessage id="ManualReservation.MinStay" />,
                    icon: <i className="fas fa-exclamation-circle text-warning" />
                },
                {
                    value: 'maxStay',
                    label: <FormattedMessage id="ManualReservation.MaxStay" />,
                    icon: <i className="fas fa-exclamation-circle text-danger" />
                },
                {
                    value: 'ctd',
                    label: <FormattedMessage id="ManualReservation.Ctd" />,
                    icon: <i className="fas fa-plane-departure text-primary" />
                },
                {
                    value: 'cta',
                    label: <FormattedMessage id="ManualReservation.Cta" />,
                    icon: <i className="fas fa-suitcase text-primary" />
                },
                {
                    value: 'stopSales',
                    label: <FormattedMessage id="ManualReservation.StopSales" />,
                    icon: <i className="fas fa-times-circle text-danger" />
                }
            ],
            showOccupancies: false
        }
    }

    handleAddDay(canChange, day, price, roomMappingCode, rateMappingCode, roomCategoryDesc, rateCode, color, colorValue, isEditable, checkAvailability, minStay, maxStay, priceIsDifferent) {
        if (canChange) {
            this.props.handleAddDay(day, price, roomMappingCode, rateMappingCode, roomCategoryDesc, rateCode, color, colorValue, isEditable, checkAvailability, minStay, maxStay, priceIsDifferent);
        }
    }

    render() {
        const { rate, roomCategoryDesc, roomStays, colors, confirmRoomStay, isEditable, componentPrice, componentPriceChildren, getTotalDailyPrice } = this.props;
        const { showRestrictions, restrictions, showOccupancies } = this.state;

        function getPrice(rData) {
            const price = {
                price: 0
            };
            rData.prices.forEach(p => {
                if (p.children === 0 && price.price < p.price) {
                    Object.assign(price, p);
                    const totalDailyPrice = getTotalDailyPrice(price.price, componentPrice, p.adults, componentPriceChildren, p.children);
                    price.totalDailyPrice = totalDailyPrice;
                }
            });
            return price;
        }

        function getPriceByOccupancy(price) {
            price.totalDailyPrice = getTotalDailyPrice(price.price, componentPrice, price.adults, componentPriceChildren, price.children);
            return price;
        }

        function checkAvailability(day, idx) {
            let freeRooms = 0;
            const rData = rate
                .rateDetails[0]
                .rateData
                .find(r => moment(r.day).format("YYYY-MM-DD") === day);
            if (rData) {
                freeRooms = rData.freeRooms;
                roomStays
                    .forEach((room, roomIdx) => {
                        if (room.roomCode === rate.rateDetails[0].roomMappingCode) {
                            room.roomRates
                                .filter(rate => moment(rate.effectiveDate).isSameOrBefore(moment(day)) && moment(day).isSameOrBefore(moment(rate.expireDate)) && idx !== roomIdx)
                                .forEach(_ => {
                                    freeRooms -= room.numberOfRooms;
                                });
                        }
                    });
            }
            return freeRooms > 0;
        }

        function priceIsDifferent(day, price, adults, children) {
            const dailyPrice = {
                isPriceDifferent: true,
                price: price
            };

            const rData = rate
                .rateDetails[0]
                .rateData
                .find(r => moment(r.day).format("YYYY-MM-DD") === day);
            if (rData) {
                const currPrice = rData.prices.find(p => p.adults === adults && p.children === children);
                if (currPrice) {
                    const totalDailyPrice = getTotalDailyPrice(currPrice.price, componentPrice, adults, componentPriceChildren, children);
                    dailyPrice.isPriceDifferent = totalDailyPrice !== price;
                    dailyPrice.price = totalDailyPrice;
                }
            }
            return dailyPrice;
        }

        return (
            <div>
                <CardBody className="card-header-gridview border-0 py-0">
                    <Row className="d-flex flex-row flex-nowrap py-0">
                        <Col className="col-3 position-sticky border-top bg-white ">
                            <Row>
                                <Col className="small py-1" style={{fontWeight : '500'}}>
                                    {rate.rateCode}
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-3">
                                    <span className="float-left font_size_xxs inline pointer" onClick={_ => this.setState(prevState => ({ showRestrictions: !prevState.showRestrictions }))} >
                                        <i className="fas fa-ban mr-1" />
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                        
                                </Col>
                                <Col className="col-3">
                                    <span className="float-left font_size_xxs inline pointer" onClick={_ => this.setState(prevState => ({ showOccupancies: !prevState.showOccupancies }))} >
                                        <i className="fas fa-user-friends mr-1" />
                                        <i className="fas fa-chevron-down" />
                                    </span>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row className="flex-nowrap">
                                {
                                    rate
                                        .rateDetails[0]
                                        .rateData
                                        .map((rData, key) => {
                                            const price = getPrice(rData);
                                            const day = moment(rData.day).format("YYYY-MM-DD");
                                            let freeRooms = rData.freeRooms;
                                            const roomStayColors = [];
                                            let color = null;
                                            let showCheck = false;
                                            let roomStayIdx = null;
                                            let isOnMinStay = false;
                                            let hasMinStayAvailability = false;
                                            roomStays
                                                .forEach((room, idx) => {
                                                    if (room.roomCode === rate.rateDetails[0].roomMappingCode && room.roomRates.find(roomRate => roomRate.rateCode === rate.rateDetails[0].rateMappingCode) !== undefined) {
                                                        if (room.roomRates.find((rate, rateIdx) => rateIdx === 0 && moment(rate.effectiveDate).isSame(moment(day))) !== undefined) {
                                                            freeRooms -= room.numberOfRooms;
                                                            if (!room.isPending) {
                                                                const colorsToFill = room.colorIdx - roomStayColors.length;
                                                                for (let i = 0; i < colorsToFill; i++) {
                                                                    roomStayColors.push({ color: null, days: 0 });
                                                                }
                                                                const days = moment(moment(room.roomRates[room.roomRates.length - 1].expireDate).format("YYYY-MM-DD")).diff(moment(moment(room.roomRates[0].effectiveDate).format("YYYY-MM-DD")), 'days') + 1;
                                                                roomStayColors.push({ color: room.color, days: days });
                                                            }
                                                            else {
                                                                showCheck = room.roomRates[room.roomRates.length - 1].expireDate === day;
                                                                roomStayIdx = idx;
                                                                color = room.colorValue;
                                                                if (room.minStay > 0) {
                                                                    isOnMinStay = true;
                                                                }
                                                            }
                                                        }
                                                        if (room.roomRates.find((rate, rateIdx) => (moment(rate.effectiveDate).isBefore(moment(day)) && moment(day).isSameOrBefore(moment(rate.expireDate))) || (rateIdx > 0 && moment(rate.effectiveDate).isSame(moment(day)))) !== undefined) {
                                                            freeRooms -= room.numberOfRooms;
                                                            if (room.isPending) {
                                                                showCheck = room.roomRates[room.roomRates.length - 1].expireDate === day;
                                                                roomStayIdx = idx;
                                                                color = room.colorValue;
                                                                if (room.minStay > 0) {
                                                                    isOnMinStay = moment(moment(room.roomRates[0].effectiveDate).add(room.minStay - 2, 'days').format("YYYY-MM-DD")).isSameOrAfter(moment(rData.day).format("YYYY-MM-DD"));
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            );
                                            if (rData.minStay > 0) {
                                                const idxMax = key + rData.minStay;
                                                for (let idxAux = key + 1; idxAux < idxMax && idxAux < rate.rateDetails[0].rateData.length; idxAux++) {
                                                    const dayAux = rate.rateDetails[0].rateData[idxAux].day;
                                                    function getRoomsAvailable() {
                                                        let roomsAvailable = rate.rateDetails[0].rateData[idxAux].freeRooms;
                                                        roomStays.forEach(roomStay => {
                                                            if (roomStay.roomCode === rate.rateDetails[0].roomMappingCode) {
                                                                roomStay
                                                                    .roomRates
                                                                    .filter(rate => moment(rate.effectiveDate).isSameOrBefore(moment(dayAux)) && moment(dayAux).isSameOrBefore(moment(rate.expireDate)))
                                                                    .forEach(_ => {
                                                                        roomsAvailable -= roomStay.numberOfRooms;
                                                                    });
                                                            }
                                                        });
                                                        return roomsAvailable;
                                                    }
                                                    const roomsAvailable = getRoomsAvailable();
                                                    if (roomsAvailable <= 0 && !isEditable) {
                                                        hasMinStayAvailability = true;
                                                    }
                                                }
                                            }
                                            const isUnavailable = freeRooms <= 0 || rData.stopSales || hasMinStayAvailability;
                                            const notAllowed = (!(freeRooms <= 0 && !isEditable) && !rData.stopSales) && !(rData.cta && !isEditable) && !(rData.ctd && isEditable) && !isOnMinStay && !(hasMinStayAvailability);
                                            return (
                                                <Col
                                                    style={{ minWidth: '60px', textAlign: 'center', cursor: !notAllowed && !showCheck ? 'not-allowed' : 'pointer' }}
                                                    className={`px-1 py-3 border-top ${color ? `bg-${color} text-white` : `${moment(rData.day).isoWeekday() === 6 || moment(rData.day).isoWeekday() === 7 ? `${isUnavailable ? `bg-disabled` : `bg-light-dark`}` : `${isUnavailable ? `bg-disabled` : `bg-white`}`}`}`}
                                                    key={key}
                                                >
                                                    <div className="p-0 small">
                                                        <span style={{ textAlign: 'center' }} className={isUnavailable ? 'text-muted' : ''} onClick={_ => this.handleAddDay(notAllowed, day, price, rate.rateDetails[0].roomMappingCode, rate.rateDetails[0].rateMappingCode, roomCategoryDesc, rate.rateCode, colors[roomStays.length % colors.length].color, colors[roomStays.length % colors.length].value, isEditable, checkAvailability, rData.minStay > 1 ? rData.minStay : 0, rData.maxStay, priceIsDifferent)}>
                                                            {
                                                                `${price && price.totalDailyPrice ? `${price.totalDailyPrice} €` : `-`}`
                                                            }
                                                        </span>
                                                        {
                                                            rData.stopSales || rData.cta || rData.ctd || (rData.minStay !== null && rData.minStay > 0) || (rData.maxStay !== null && rData.maxStay > 0) ?
                                                                <span style={{ position: 'absolute', top: '-2px', right: '1px' }}>
                                                                    <i className={`fas fa-exclamation-circle ${rData.stopSales ? `text-danger` : `text-warning`}`} />
                                                                </span>
                                                                :
                                                                <span />
                                                        }
                                                        {
                                                            showCheck ?
                                                                <span style={{ position: 'absolute', right: '-10px', zIndex: '1', minWidth: '20px', border: '1px solid #165C7D' }} className="rounded-circle bg-white text-success" onClick={_ => confirmRoomStay(roomStayIdx)}>
                                                                    <i className="fas fa-check" />
                                                                </span>
                                                                :
                                                                <span />
                                                        }
                                                    </div>
                                                    {
                                                        roomStayColors.map((color, key) => {
                                                            return (
                                                                <div key={key}>
                                                                    <div
                                                                        style={{ minWidth: (60 * color.days) + 'px', bottom: key * 4 + 'px', position: 'absolute', backgroundColor: color.color, left: '-4px', 'border': '2px solid ' + color.color, zIndex: '100' }}
                                                                        className=" "
                                                                    />
                                                                    {key > 1 ? <div style={{ height: '4px' }}></div> : ''}
                                                                </div>    
                                                            );
                                                        }
                                                        )
                                                    }
                                                </Col>
                                            );
                                        }
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                    {
                        showRestrictions ?
                            restrictions.map((restriction, key) =>
                                <Row key={`restriction${key}`} className="d-flex flex-row flex-nowrap border-bottom border-host py-0">
                                    <Col className="col-3 position-sticky bg-white ">
                                        <Row>
                                            <Col>
                                                {
                                                    restriction.label
                                                }
                                            </Col>
                                            <Col>
                                                {restriction.icon}
                                                </Col>
                                        </Row>
                                    </Col>
                                    <Col>
                                        <Row className="flex-nowrap">
                                            {
                                                rate
                                                    .rateDetails[0]
                                                    .rateData
                                                    .map((rData, key) => 
                                                        <Col style={{ minWidth: '60px', textAlign: 'center' }} className={` px-1 py-1 ${moment(rData.day).isoWeekday() === 6 || moment(rData.day).isoWeekday() === 7 ? `bg-light-dark` : `${rData.inventory === 0 ? `bg-light` : `bg-white`}`}`} key={key}>
                                                            <div className="p-0 small">
                                                                {
                                                                    rData[restriction.value] === true ?
                                                                        <div>
                                                                            <i className="fas fa-check text-success" />
                                                                        </div>
                                                                        :
                                                                        rData[restriction.value] !== null && rData[restriction.value] !== undefined && rData[restriction.value] !== false ?
                                                                            <div>
                                                                                {`${rData[restriction.value]}`}
                                                                            </div>
                                                                            :
                                                                            <div>
                                                                                {`-`}
                                                                            </div>
                                                                }
                                                            </div>
                                                        </Col>
                                                    )
                                            }
                                        </Row>
                                    </Col>
                                </Row>   
                            )
                            :
                            <div />
                    }
                    {
                        showOccupancies ?
                            rate
                                .occupancies
                                .map((occupancy, key) => 
                                    <Row key={`occupancy${key}`} className="d-flex flex-row flex-nowrap border-bottom border-host py-0">
                                        <Col className="col-3 position-sticky bg-white ">
                                            {occupancy.description}
                                        </Col>
                                        <Col>
                                            <Row className="flex-nowrap">
                                                {
                                                    rate
                                                        .rateDetails[0]
                                                        .rateData
                                                        .map((rData, key) =>
                                                            <Col style={{ minWidth: '60px', textAlign: 'center' }} className={` px-1 py-1 ${moment(rData.day).isoWeekday() === 6 || moment(rData.day).isoWeekday() === 7 ? `bg-light-dark` : `${rData.inventory === 0 ? `bg-light` : `bg-white`}`}`} key={key}>
                                                                <div className="p-0 small">
                                                                    {
                                                                        rData
                                                                            .prices
                                                                            .filter(price => price.adults === occupancy.adults && price.children === occupancy.children)
                                                                            .map((price, key2) =>
                                                                                <div key={key2}>
                                                                                    {`${getPriceByOccupancy(price).totalDailyPrice} €`}
                                                                                </div>
                                                                            )
                                                                    }
                                                                </div>
                                                            </Col>
                                                        )
                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                )
                            :
                            <div />
                    }
                </CardBody>
            </div>
        );
    }
}