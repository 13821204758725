import React, { Component } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { Button, Card, Row, Col } from 'reactstrap';
import { SelectChannelConfig } from "../../Base/Common/CommonUIComponents";
import { handleNotification } from '../../Base/Notification';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';
import countryList from "react-select-country-list";
import { Bar, Line } from 'react-chartjs-2';
import { getAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';

class ReservationsGlobalData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            reservationsSummary: [],
            totalAmount: 0,
            totalReservations: 0,
            avgAmount: 0,
            channelReservations: {},
            reservationsByStatus: {},
            lineChart: {}
        };
    }

    componentDidMount() {
        this.getGlobalData();
    }

    getGlobalData = () => {
        this.setState({ block: true });
        var params = '';
        params += this.state.channelConfigId ? `&channelId=${this.state.channelConfigId}` : '';
        params += this.state.country ? `&hotelCountry=${this.state.country}` : '';

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.setState({
                        reservationsSummary: data.response,
                        error: data.errors
                    }, () => this.calculateData(data.response));
                }

                this.setState(({ block: false }));
            }
        }, `/api/Rate/DashBoard/ReservationsSummary?fromDate=${moment(this.state.startDate).format('YYYY-MM-DD')}&toDate=${moment(this.state.endDate).format('YYYY-MM-DD')}` + params);
    }

    calculateData = (data) => {
        var totalReservations = 0, totalAmount = 0, avgAmount = 0, totalAmountByDay = 0;
        var colorPalette = ["#0099ff", "#00d6ff", "#00b1db", "#028db6", "#026c91", "#004c6d", "#196666", "#008081", "#009999", "#02c5c5", "#66d9dc", "#79a3f9", "#6699ff", "#6666ff"];

        //dates
        var dates = [moment(this.state.startDate).format('YYYY-MM-DD')];
        var currDate = moment(this.state.startDate);
        while (currDate.add(1, 'days').diff(this.state.endDate) < 0) {
            dates.push(moment(currDate).format('YYYY-MM-DD'));
        }

        var channelReservations = { labels: dates, datasets: [] };
        var reservationsByStatus = { labels: dates,
            datasets: [
                { label: this.props.intl.formatMessage({ id: "CommonUIComponents.Created" }), data: Array(dates.length).fill(0), backgroundColor: '#28a745' },
                { label: this.props.intl.formatMessage({ id: "CommonUIComponents.Modified" }), data: Array(dates.length).fill(0), backgroundColor: '#ffc107' },
                { label: this.props.intl.formatMessage({ id: "CommonUIComponents.Pending" }), data: Array(dates.length).fill(0), backgroundColor: '#007bff' },
                { label: this.props.intl.formatMessage({ id: "CommonUIComponents.Canceled" }), data: Array(dates.length).fill(0), backgroundColor: '#dc3545' }
            ]
        };
        var lineChart = { drawOnChartArea: false, labels: dates,
            datasets: [
                { label: this.props.intl.formatMessage({ id: "ReservationsGlobalData.NumberOfNights" }), yAxisID: 'nights', data: Array(dates.length).fill(0), fill: false, borderColor: "#02c5c5", backgroundColor: "#02c5c533", borderWidth: "1" },
                { label: this.props.intl.formatMessage({ id: "ReservationsGlobalData.TotalAmount" }), yAxisID: 'amount', data: Array(dates.length).fill(0), fill: false, borderColor: "#6699ff", backgroundColor: "#6699ff33", borderWidth: "1" },
            ]
        }

        data && data.forEach((date, i) => {
            const dateIndex = dates.indexOf(dates.find(el => el === moment(date.date).format('YYYY-MM-DD')));

            date.dateReservationsSummary && date.dateReservationsSummary.forEach(channel => {

                channel.channelReservationsSummary && channel.channelReservationsSummary.forEach(reservation => {
                    //Cards 
                    totalAmount += reservation.totalAmount;
                    totalReservations += reservation.totalReservations;

                    //Channel Reservations
                    if (!channelReservations.datasets.some(el => el.label === channel.channel)) {
                        channelReservations.datasets.push(
                            { label: channel.channel, data: Array(dates.length).fill(0), backgroundColor: colorPalette[channelReservations.datasets.length] }
                        );

                        channelReservations.datasets[channelReservations.datasets.length - 1].data[dateIndex] = reservation.totalReservations;
                    }
                    else {
                        const datasetIndex = channelReservations.datasets.indexOf(channelReservations.datasets.find(el => el.label === channel.channel));

                        channelReservations.datasets[datasetIndex].data[dateIndex] = channelReservations.datasets[datasetIndex].data[dateIndex] ? channelReservations.datasets[datasetIndex].data[dateIndex] + reservation.totalReservations : 0 + reservation.totalReservations;
                    }

                    //Reservations By Status
                    if (reservation.status === 'Created') {
                        reservationsByStatus.datasets[0].data[dateIndex] = reservationsByStatus.datasets[0].data[dateIndex] ? reservationsByStatus.datasets[0].data[dateIndex] + reservation.totalReservations : 0 + reservation.totalReservations;
                    }
                    if (reservation.status === 'Modified') {
                        reservationsByStatus.datasets[1].data[dateIndex] = reservationsByStatus.datasets[1].data[dateIndex] ? reservationsByStatus.datasets[1].data[dateIndex] + reservation.totalReservations : 0 + reservation.totalReservations;
                    }
                    if (reservation.status === 'Pending') {
                        reservationsByStatus.datasets[2].data[dateIndex] = reservationsByStatus.datasets[2].data[dateIndex] ? reservationsByStatus.datasets[2].data[dateIndex] + reservation.totalReservations : 0 + reservation.totalReservations;
                    }
                    if (reservation.status === 'Canceled') {
                        reservationsByStatus.datasets[3].data[dateIndex] = reservationsByStatus.datasets[3].data[dateIndex] ? reservationsByStatus.datasets[3].data[dateIndex] + reservation.totalReservations : 0 + reservation.totalReservations;
                    }

                    //Line Chart
                    lineChart.datasets[0].data[dateIndex] = lineChart.datasets[0].data[dateIndex] ? lineChart.datasets[0].data[dateIndex] + reservation.totalRoomStays : 0 + reservation.totalRoomStays;
                    totalAmountByDay += reservation.totalAmount;
                })
            })

            lineChart.datasets[1].data[dateIndex] = totalAmountByDay ? parseFloat(totalAmountByDay).toFixed(2) : 0;
            totalAmountByDay = 0;
        })

        avgAmount = totalAmount/totalReservations;

        this.setState({
            totalReservations, totalAmount, avgAmount,
            channelReservations, reservationsByStatus, lineChart
        })
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    render() {
        const { reservationsSummary, totalAmount, totalReservations, avgAmount } = this.state;

        const Crt = ({ text, value }) => {
            return <Card className="shadow" body>
                <Row className="row no-gutters align-items-center">
                    <Col>
                        <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                            {text}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <h3 className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                            {value}
                        </h3>
                    </Col>
                </Row>
            </Card>
        }

        let optionsLine = {
            legend: {
                position: "top"
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        drawOnChartArea: false
                    }
                }],
                yAxes: [
                    {
                        type: 'linear',
                        ticks: { beginAtZero: true, precision: 0, min: 0 },
                        display: true,
                        position: 'left',
                        id: 'nights',
                        gridLines: {
                            display: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: this.props.intl.formatMessage({ id: "ReservationsGlobalData.NumberOfNights" })
                        }
                    },
                    {
                        type: 'linear',
                        ticks: { beginAtZero: true, min: 0 },
                        display: true,
                        position: 'right',
                        id: 'amount',
                        gridLines: {
                            display: false
                        },
                        labels: {
                            show: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: this.props.intl.formatMessage({ id: "ReservationsGlobalData.TotalAmount" })
                        }
                    }

                ]
            }
        }

        const options = {
            elements: {
                point: {
                    radius: 0
                }
            },
            scales: {
                yAxes: [
                    {
                        display: true,
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
                xAxes: [
                    {
                        stacked: true,
                    },
                ],
            },
        }

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <Row className="mt-1">
                        <Col>
                            <Card className="shadow" body>
                                <Row className="mb-2">
                                    <Col className="col-3">
                                        <SelectChannelConfig name={'channelConfigId'} icon={'fas fa-plug'} onChangeFunc={this.handleChangeSelect} value={this.state.channelConfigId} placeholder={<FormattedMessage id="ReservationList.SelectChannel" />} />
                                    </Col>
                                    <Col className="col-3 px-1">
                                        <CustomSelect
                                            icon={"fas fa-globe"}
                                            options={countryList().getData()}
                                            isClearable
                                            isSearchable
                                            onChange={this.handleChangeSelect.bind(this, 'country')}
                                            placeholder={<FormattedMessage id="ReservationsGlobalData.HotelCountry" />}
                                        />
                                    </Col>
                                    <Col>
                                        <DateRangePicker
                                            startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                            startDateId="your_unique_start_date_id"
                                            endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                            endDateId="your_unique_end_date_id"
                                            isOutsideRange={day => false}
                                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            small={true}
                                            showDefaultInputIcon={true}
                                        />
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn btn-sm btn-host" onClick={this.getGlobalData}>
                                            <i className="fas fa-search" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col sm={4} className="pr-1">
                            <Crt text={<FormattedMessage id="ReservationsGlobalData.TotalReservations" />} value={totalReservations} />
                        </Col>
                        <Col sm={4} className="px-1">
                            <Crt text={<FormattedMessage id="ReservationsGlobalData.TotalReservationsAmount" />} value={<FormattedNumber value={totalAmount} maximumFractionDigits={2} />} />
                        </Col>
                        <Col sm={4} className="pl-1">
                            <Crt text={<FormattedMessage id="ReservationsGlobalData.AvgReservaionsAmount" />} value={avgAmount ? <FormattedNumber value={avgAmount} maximumFractionDigits={2} /> : '0'} />
                        </Col>
                    </Row>

                    <Card body className="mt-2">
                        <Row className="row no-gutters align-items-center">
                            <Col>
                                <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                    <FormattedMessage id="ReservationsGlobalData.NumberOfReservationsPerChannel" />                                    
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Bar data={this.state.channelReservations} options={options} height={60} />
                            </Col>
                        </Row>
                    </Card>

                    <Card body className="mt-2">
                        <Row className="row no-gutters align-items-center">
                            <Col>
                                <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                    <FormattedMessage id="ReservationsGlobalData.NumberOfReservationsPerStatus" /> 
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Bar data={this.state.reservationsByStatus} options={options} height={60} />
                            </Col>
                        </Row>
                    </Card>

                    <Card body className="mt-2">
                        <Row className="row no-gutters align-items-center">
                            <Col>
                                <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                    <FormattedMessage id="ReservationsGlobalData.DataPerNight" /> 
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Line data={this.state.lineChart} type='line' height={60} options={optionsLine}/>
                            </Col>
                        </Row>
                    </Card>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(ReservationsGlobalData)