import React, { Component } from 'react';
import { Row, Col, Input, InputGroup, FormGroup, Label, Button, Card, CardHeader, CardBody, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormattedMessage, injectIntl  } from 'react-intl';
import { getAPI, postAPI } from "../../../Base/API";
import { handleNotification } from "../../../Base/Notification";
import { ErrorAlert } from "../../../Common/ErrorAlert";
import BlockUi from 'react-block-ui';
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorTextMultiLing } from '../../../Base/Common/SupportedMultiLang';
import MapModal from './MapModal';
import { getCountries } from '../../../Base/Common/ReferenceDataFunctions';
import { CommonHelper } from '../../../Base/Common/CommonUIComponents';
import CustomSelect from '../../../Base/Common/CustomSelect';

export class ComplexHowToGet extends Component {

    constructor(props) {
        super(props);
        this.toggleHowToGet = this.toggleHowToGet.bind(this);
        this.howToGetAddNewLanguage = this.howToGetAddNewLanguage.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.form = React.createRef();
        this.changeAddress = this.changeAddress.bind(this);
        this.changeLocal = this.changeLocal.bind(this);
        this.changeZipCode = this.changeZipCode.bind(this);
        this.changeCity = this.changeCity.bind(this);
        this.changeCountryCode = this.changeCountryCode.bind(this);
        this.changeCountry = this.changeCountry.bind(this);
        this.changeLongitude = this.changeLongitude.bind(this);
        this.changeLatitude = this.changeLatitude.bind(this);
        this.changePhone = this.changePhone.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changeReservationEmail = this.changeReservationEmail.bind(this);
        this.getCoordinates = this.getCoordinates.bind(this);
        this.getMap = this.getMap.bind(this);
        this.toggleModalMap = this.toggleModalMap.bind(this);
        this.mapOnClick = this.mapOnClick.bind(this);
        this.state = {
            error: null,
            block: true,
            selectedComplex: this.props.selectedComplex,
            complexId: this.props.selectedEntity.id,
            howToGetActiveTab: 0,
            direction: {
                howToGet: []
            },
            modalMap: false,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedEntity && nextProps.selectedEntity !== this.props.selectedEntity) {
            this.setState({
                complexId: nextProps.selectedEntity.id
            }, () => this.getDirections())
        }
    }

    componentDidMount() {
        if (this.props.selectedEntity) {
            this.getDirections();
        }
    }

    getDirections = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, direction: {} });
                    return;
                }
                if (data.response) {
                    data.response.howToGet.map(howToGet => {
                        const blocksFromHtml = htmlToDraft(howToGet.description);
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        howToGet.editorState = EditorState.createWithContent(contentState);
                    });
                }

                this.setState({
                    block: false,
                    direction: data.response ? data.response : {}
                });
            }
        }, `/api/hotel/Complexes/v1/${this.state.complexId}/Direction`);
    }

    changeAddress(evt) {
        const address = evt.target ? evt.target.value : null;
        this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    address: address
                }
            }
        ));
    }

    changeLocal(evt) {
        const local = evt.target ? evt.target.value : null;
        this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    local: local
                }
            }
        ));
    }

    changeZipCode(evt) {
        const zipCode = evt.target ? evt.target.value : null;
        this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    zipCode: zipCode
                }
            }
        ));
    }

    changeCity(evt) {        
        const city = evt.target ? evt.target.value : null;
        this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    city: city
                }
            }
        ));             
    }

    changeCountryCode(evt) {
        const countryCode = evt.target ? evt.target.value : null;
        this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    countryCode: countryCode
                }
            }
        ));
    }

    changeCountry(evt) {
        const country = evt.target ? evt.target.value : null;
        this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    country: country
                }
            }
        ));
    }

    changeLongitude(evt) {
        const longitude = evt.target ? parseFloat(evt.target.value) : null;
        this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    longitude: longitude
                }
            }
        ));
    }

    changeLatitude(evt) {
        const latitude = evt.target ? parseFloat(evt.target.value) : null;
        this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    latitude: latitude
                }
            }
        ));
    }    

    changePhone(evt) {
        const phone = evt.target ? evt.target.value : null;
        this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    phone: phone
                }
            }
        ));
    }

    changeEmail(evt) {
        const email = evt.target ? evt.target.value : null;
        this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    email: email
                }
            }
        ));
    }

    changeReservationEmail(evt) {
        const reservationEmail = evt.target && evt.target.value || null;

        this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    reservationEmail: reservationEmail
                }
            }
        ));
    }

    toggleHowToGet(tab) {
        if (this.state.howToGetActiveTab !== tab) {
            this.setState(({ howToGetActiveTab: tab.key }));
        }
    }

    howToGetAddNewLanguage() {
        const e = document.getElementById("complexHowToGetDirectionsSelectNewCountryId");
        const strCountry = e.options[e.selectedIndex].value;
        const newHowToGet = {
            culture: strCountry,
            editorState: EditorState.createEmpty()
        };
        this.setState(prevState => (
        {
            howToGetActiveTab: prevState.direction.howToGet.length,
            direction: {
                ...prevState.direction,
                howToGet: [...prevState.direction.howToGet, newHowToGet]
            }
        }));
    }

    handleSave(e) {
        e.preventDefault();
        //const directionsTextEditor = document.getElementById('complexHowToGetDirectionsSelectNewCountryId');
        //if (this.state.direction && this.state.direction.howToGet.length == 0) {
        //    directionsTextEditor.setCustomValidity(this.props.intl.formatMessage({ id: "ComplexHowToGet.DirectionsAreEmpty" }));
        //} else {
        //    directionsTextEditor.setCustomValidity("");
        //}

        if (this.form.current.reportValidity()) {
            this.setState({
                block: true
            });

            const body = {
                request: {
                    ...this.state.direction,
                    howToGet: []
                }
            };
            this.state.direction.howToGet && this.state.direction.howToGet.map(howToGet => {
                const textConverted = draftToHtml(convertToRaw(howToGet.editorState.getCurrentContent()));
                const newHowToGet = {
                    culture: howToGet.culture,
                    description: textConverted
                };
                body.request.howToGet.push(newHowToGet);
            });
            
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = []
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="ComplexHowToGet.ContactsSaved" />, <FormattedMessage id="General.Success" />);
                    this.setState({ block: false });
                }
            }, `/api/hotel/Complexes/v1/${this.state.complexId}/Direction`, JSON.stringify(body));            
        }
    }

    onEditorStateChange = (newEditorState, lang) => {
        const direction = { ...this.state.direction };
        let text = direction && direction.howToGet && direction.howToGet.find(el => el.culture === lang);

        if (text) {
            text.editorState = newEditorState;
        }
        else {
            if (!Array.isArray(direction.howToGet)) {
                direction.howToGet = [];
            }

            let newItem = { };
            newItem.culture = lang;
            newItem.editorState = newEditorState;
            direction.howToGet.push(newItem);
        }

        this.setState({ direction });
    };

    getCoordinates() {
        navigator.geolocation.getCurrentPosition(position => 
        {
            this.setState(prevState => (
            {
                direction: {
                    ...prevState.direction,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }
            }));
        }, showError);

        function showError(error) {
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    handleNotification(error.code, <FormattedMessage id="GeolocationError.PermissionDenied" />, '','info');
                    break;
                case error.POSITION_UNAVAILABLE:
                    handleNotification(error.code, <FormattedMessage id="GeolocationError.PositionUnvailable" />, '', 'info');
                    break;
                case error.TIMEOUT:
                    handleNotification(error.code, <FormattedMessage id="GeolocationError.Timeout" />, '', 'info');
                    break;
                case error.UNKNOWN_ERROR:
                    handleNotification(error.code, <FormattedMessage id="ComplexHowToGet.UnknownError" />, '', 'info');
                    break;
            }
        }
    }

    getMap() {
        this.setState({ modalMap: true });
    }

    toggleModalMap() {
        this.setState(prevstate => ({
            modalMap: !prevstate.modalMap
        }));
    }

    mapOnClick(latitude, longitude) {
        this.setState(prevState => ({
            direction: {
                ...prevState.direction,
                latitude: latitude,
                longitude: longitude
            }
        }));
    }

    setFilterState = (name, combo) => {
        const val = combo ? combo.value : null;
        this.setState(prevState => ({
            ...prevState,
            direction: {
                ...prevState.direction,
                [name]: val
            }
        }));
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.state.modalMap} toggle={this.toggleModalMap} fade={false} className="modal-lg " >
                    <ModalHeader toggle={this.toggleModalMap}  ><FormattedMessage id="ComplexSetup.HowToGet" /></ModalHeader>
                    <ModalBody className="modal-lg px-0 pb-0">
                        <MapModal latitude={this.state.direction.latitude} longitude={this.state.direction.longitude} onClick={this.mapOnClick} />
                    </ModalBody>
                </Modal>
                <Card className="border-0 mb-2">
                    <CardHeader className="border-bottom bg-white" >
                        <Row>
                            <Col>
                                <span className="fas far fa-map"> </span> <FormattedMessage id="ComplexSetup.HowToGet" />
                            </Col>
                            <Col className="text-right">
                                <Button color=" btn-host btn-sm" onClick={this.handleSave}>
                                    <i className="fas fa-save"/>
                                </Button>

                                <CommonHelper help={<FormattedMessage id="ComplexHowToGet.Help" />} id="ComplexHowToGetHelp" />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div>
                            <form ref={this.form}>
                                <BlockUi tag="div" blocking={this.state.block}>
                                    <ErrorAlert error={this.state.error} />
                                    <Row>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="address" sm={4}> <FormattedMessage id="ComplexHowToGet.Address" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexHowToGet.Address">
                                                            {placeholder => <Input type="text" name="address" id="address" placeholder={placeholder} value={this.state.direction.address || ''} onChange={this.changeAddress} required />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="local" sm={4}> <FormattedMessage id="ComplexHowToGet.Local" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexHowToGet.Local">
                                                            {placeholder => <Input type="text" name="local" id="local" placeholder={placeholder} value={this.state.direction.local || ''} onChange={this.changeLocal} required />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="zipCode" sm={4}> <FormattedMessage id="ComplexHowToGet.ZipCode" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexHowToGet.ZipCode">
                                                            {placeholder => < Input type="text" name="zipCode" id="zipCode" placeholder={placeholder} value={this.state.direction.zipCode || ''} onChange={this.changeZipCode} required />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="city" sm={4}> <FormattedMessage id="ComplexHowToGet.City" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexHowToGet.City">
                                                            {placeholder => < Input type="text" name="city" id="city" placeholder={placeholder} defaultValue={this.state.direction.city || ''} onChange={this.changeCity} required />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="countryCode" sm={4}> <FormattedMessage id="ComplexHowToGet.CountryCode" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexHowToGet.CountryCode">
                                                            {placeholder => <Input maxLength="5" type="text" name="countryCode" id="countryCode" placeholder={placeholder} value={this.state.direction.countryCode || ''} onChange={this.changeCountryCode} required />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="country" sm={4}> <FormattedMessage id="ComplexHowToGet.Country" /> </Label>
                                                <Col sm={8}>
                                                    <CustomSelect name={'country'} options={getCountries()} placeholder={<FormattedMessage id="ComplexHowToGet.Country" />} value={getCountries().filter(el => el.value === this.state.direction.country)} onChange={this.setFilterState.bind(this, 'country')} required />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>                                            
                                        <Col className="col-4">
                                            <FormGroup row>
                                                <Label for="latitude" sm={4}> <FormattedMessage id="ComplexHowToGet.Latitude" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexHowToGet.Latitude">
                                                            {placeholder => <Input type="number" name="{latitude}" id="latitude" step="any" placeholder={placeholder} value={this.state.direction.latitude || ''} onChange={this.changeLatitude} />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <FormGroup row>
                                                <Label for="longitude" sm={4}> <FormattedMessage id="ComplexHowToGet.Longitude" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexHowToGet.Longitude">
                                                            {placeholder => <Input type="number" name="longitude" id="longitude" step="any" placeholder={placeholder} value={this.state.direction.longitude || ''} onChange={this.changeLongitude} />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <Button color=" btn-host btn-sm mx-1" sm={12} onClick={this.getCoordinates} > <i className="fas fa-map-marker-alt" /> </Button>
                                            <Button color=" btn-host btn-sm" sm={12} onClick={this.getMap} > <i className="far fa-map"/> </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-4">
                                            <FormGroup row>
                                                <Label for="phone" sm={4}> <FormattedMessage id="ComplexHowToGet.Phone" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexHowToGet.Phone">
                                                            {placeholder =>
                                                                <Input name="{phone}" id="phone" maxLength="15"
                                                                    placeholder={placeholder}
                                                                    value={this.state.direction.phone || ''}
                                                                    onChange={this.changePhone}
                                                                    required={this.props.fromBEConfig !== true}
                                                                />
                                                            }
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <FormGroup row>
                                                <Label for="email" sm={4}> <FormattedMessage id="ComplexHowToGet.Email" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexHowToGet.Email">
                                                            {placeholder => <Input name="{email}" id="email" placeholder={placeholder} value={this.state.direction.email || ''} onChange={this.changeEmail} required />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-4">
                                            <FormGroup row>
                                                <Label for="reservationEmail" sm={4}> <FormattedMessage id="ComplexHowToGet.ReservationEmail" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexHowToGet.ReservationEmail">
                                                            {placeholder => <Input name="{reservationEmail}" id="reservationEmail" placeholder={placeholder} value={this.state.direction.reservationEmail || ''} onChange={this.changeReservationEmail} />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Card className="border-0 shadow px-0">
                                        <CardHeader className="border-bottom bg-white">
                                            <div>
                                                <span className="fas far fa-map mr-2" />
                                                <FormattedMessage id="ComplexHowToGet.Directions" />
                                            </div>
                                        </CardHeader>
                                        <CardBody>
                                            <EditorTextMultiLing
                                                customToolbar={{
                                                    options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
                                                    inline: {inDropdown: true },
                                                    list: {inDropdown: true },
                                                    textAlign: {inDropdown: true },
                                                    link: {inDropdown: true },
                                                    history: {inDropdown: true },
                                                }}
                                                texts={this.state.direction ? this.state.direction.howToGet : []}
                                                onEditorStateChange={this.onEditorStateChange}
                                            />
                                        </CardBody>
                                    </Card>
                                </BlockUi>
                            </form>
                        </div>
                    </CardBody>
                </Card>                
            </div>
        );
    }
}
export default injectIntl(ComplexHowToGet)