import React, { useEffect, useState } from 'react';
import { Col, Row, Input, Nav, NavItem, NavLink, Label, TabContent, TabPane, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import EditorText from "../../Base/Common/EditorText";
import classnames from 'classnames';
import { DescriptionGpt } from '../../Base/Common/CommonUIComponents';

// supportedLanguages: ['af-ZA', 'ar-SA', 'cs-CZ', 'da-DK', 'de-DE', 'el-GR', 'en-GB', 'es-ES', 'fi-FI', 'fr-FR', 'he-IL', 'hu-HU', 'ja-JP', 'nb-NO', 'nl-NL', 'pt-PT', 'ru-RU', 'sv-SE', 'sv-FI', 'zh-TW'],

const supportedLanguages = [
    'en-GB', 'pt-PT', 'es-ES', 'fr-FR', 'de-DE', 'pl-PL', 'it-IT'
];

export const SupportedMultiLang = ({ children, toggle, lang, tabPaneBorder, availableLanguages }) => {
    const languages = availableLanguages || supportedLanguages;

    return (
        <div>
            <Nav tabs>
                {languages.map((country, key) =>
                    <NavItem key={key} >
                        <NavLink className={classnames({ active: lang === country })} onClick={() => toggle(country)}>
                            <span className={`flag-icon flag-icon-${country.slice(- 2).toLowerCase()} mr-1 px-2`} style={{ borderRadius: '2px' }} /> {country.substring(0, 2).toUpperCase()}
                        </NavLink>
                    </NavItem>
                )}
            </Nav>
            <TabContent activeTab={languages.findIndex(el => el === lang)}>
                {languages.map((item, key) =>
                    <TabPane tabId={key} key={key} className={tabPaneBorder}>
                        {children}
                    </TabPane>
                )}
            </TabContent>
        </div>
    );
};

export const SupportedMultiLangWithoutBody = ({ children, toggle, lang }) => {
    return (
        <div>
            <Nav tabs>
                {supportedLanguages.map((country, key) =>
                    <NavItem key={key} >
                        <NavLink className={classnames({ active: lang === country })} onClick={() => toggle(country)}>
                            <span className={`flag-icon flag-icon-${country.slice(- 2).toLowerCase()} mr-1 px-2`} style={{ borderRadius: '2px' }} /> {country.substring(0, 2).toUpperCase()}
                        </NavLink>
                    </NavItem>
                )}
            </Nav>
            <div className="border border-top-0">
                {children}
            </div>
        </div>
    );
};

export const TitleMultiLang = ({ data }) => {
    const [lang, setLang] = useState(supportedLanguages[0]);

    const toggle = l => {
        if (lang !== l) setLang(l);
    }

    const texts = data && data.texts && data.texts.find(t => t.culture === lang);

    return (
        <div className="border border-top-0" style={{ borderRadius: '5px' }}>
            <SupportedMultiLang toggle={toggle} lang={lang} tabPaneBorder='border-0' />
            <Row className="mb-2 px-2">
                <Label for="Title" sm={2}> <FormattedMessage id="ComplexDescription.Title" />:</Label>
                <Col sm={10}>
                    <FormattedMessage id="CommonUIComponents.Title">{placeholder =>
                        <Input
                            type="text" className="px-2" name="title" id="title" placeholder={placeholder}
                            value={texts && texts.titleDescription ? texts.titleDescription : ''}
                            onChange={(e) => data.handleChange(e, lang)}
                            required
                        />
                    }</FormattedMessage>
                </Col>
            </Row>
        </div>
    );
};

export const TitleAndEditorText = ({ data, customToolbar, isTitle, changeLanguage, hasGpt, toggleGptConfig, isChatGptOpen, currentLang, elementDescription, intl, page }) => { 
    const [lang, setLang] = useState(supportedLanguages[0]);

    const toggle = l => {
        if (lang !== l){ 
            setLang(l);
            if(changeLanguage){
                changeLanguage(l);
            }
        }
    }

    const texts = data && data.texts && data.texts.find(t => t.culture === lang);
    
    return (
        <div className="border border-top-0" style={{ borderRadius: '5px' }}>
            <SupportedMultiLang toggle={toggle} lang={lang} tabPaneBorder='border-0' />
            <Row className="mb-2 px-2">
                <Label for="Title" sm={12} lg={2}> <FormattedMessage id="ComplexDescription.Title" />:</Label>
                <Col sm={12} lg={10}>
                    <FormattedMessage id="CommonUIComponents.Title">{placeholder =>
                        <Input
                            type="text" className="px-2" name={isTitle ? "title" : "titleDescription"} id={isTitle ? "title" : "titleDescription"} placeholder={placeholder}
                            value={isTitle ? (texts && texts.title ? texts.title : '') : (texts && texts.titleDescription ? texts.titleDescription : '')}
                            onChange={(e) => data.handleChange(e, lang)}
                            required
                        />
                    }</FormattedMessage>
                </Col>
            </Row>

            <Row className="px-2">
                <Label for="desc" sm={12} lg={2}> <FormattedMessage id="ComplexDescription.LongDescription" /> </Label>
                <Col sm={12} lg={10} className="pb-4">
                    <EditorText customToolbar={customToolbar} editorState={texts && texts.editorState} onEditorStateChange={(newEditorState) => { data.onEditorStateChange(newEditorState, lang) }} />
                </Col>
            </Row>
            {hasGpt && global && global.modules && global.modules.some(m => m === 'OpenAI') ?
                <Row>
                    <Col className="col-2"/>
                    <Col className='col-10'>
                        <DescriptionGpt
                            currentLang={currentLang}
                            elementDescription={elementDescription}
                            onEditorStateChange={data.onEditorStateChange}
                            toggleGptConfig={toggleGptConfig}
                            isChatGptOpen={isChatGptOpen}
                            intl={intl}
                            page={page}
                        />
                    </Col>
                </Row>
            :''}
        </div>
    );
};

export const DescriptionEditorText = ({ data, customToolbar, changeLanguage, hasGpt, toggleGptConfig, isChatGptOpen, currentLang, elementDescription, intl, page }) => {
    const [lang, setLang] = useState(supportedLanguages[0]);

    const toggle = l => {
        if (lang !== l){
            setLang(l);
            if(changeLanguage){
                changeLanguage(l);
            }
        }
    }

    const texts = data && data.texts && data.texts.find(t => t.culture === lang);

    return (
        <div className="border border-top-0" style={{ borderRadius: '5px' }}>
            <SupportedMultiLang toggle={toggle} lang={lang} tabPaneBorder='border-0' />
            <Row className="px-2">
                <Label for="desc" sm={12} lg={2}> <FormattedMessage id="CommonUIComponents.Description" /> </Label>
                <Col sm={12} lg={10} className="pb-4">
                    <EditorText customToolbar={customToolbar} editorState={texts && texts.editorState} onEditorStateChange={(newEditorState) => { data.onEditorStateChange(newEditorState, lang) }} />
                </Col>
            </Row>
            {hasGpt && global && global.modules && global.modules.some(m => m === 'OpenAI') ?
                <Row>
                    <Col className="col-2"/>
                    <Col className='col-10'>
                        <DescriptionGpt
                            currentLang={currentLang}
                            elementDescription={elementDescription}
                            onEditorStateChange={data.onEditorStateChange}
                            toggleGptConfig={toggleGptConfig}
                            isChatGptOpen={isChatGptOpen}
                            intl={intl}
                            page={page}
                        />
                    </Col>
                </Row>
            :''}
        </div>
    );
};

export const TitleAndDescriptionMultiLang = ({ data, changeLanguage, toggleGptConfig, hasGpt, isChatGptOpen, currentLang, elementDescription, intl, page }) => {
    const [lang, setLang] = useState(supportedLanguages[0]);

    const toggle = l => {
        if (lang !== l){
            setLang(l);
            if(changeLanguage){
                changeLanguage(l);
            }
        };
    }

    const texts = data && data.texts && data.texts.find(t => t.culture === lang);

    return (
        <SupportedMultiLang toggle={toggle} lang={lang}>
            <Row className="mb-2">
                <Label sm={2}> <FormattedMessage id="CommonUIComponents.Title" />:</Label>
                <Col sm={10}>
                    <FormattedMessage id="CommonUIComponents.Title">{placeholder =>
                        <Input
                            type="text" className="px-2"
                            name="title" id="title"
                            placeholder={placeholder}
                            value={texts && texts.title ? texts.title : ''}
                            onChange={(e) => data.handleChange(e, lang)}
                            required
                        />
                    }</FormattedMessage>
                </Col>
            </Row>

            <Row className="mb-2">
                <Label sm={2}> <FormattedMessage id="CommonUIComponents.Description" />:</Label>
                <Col sm={10}>
                    <FormattedMessage id="CommonUIComponents.Description">{placeholder =>
                        <Input
                            className="px-2" type="textarea"
                            placeholder={placeholder}
                            rows="4" max="3500" name="description"
                            value={texts && texts.description ? texts.description : ''}
                            onChange={(e) => data.handleChange(e, lang)}
                            required
                        />
                    }</FormattedMessage>
                </Col>
            </Row>
            {hasGpt && global && global.modules && global.modules.some(m => m === 'OpenAI') ?
                <Row>
                    <Col className="col-2"/>
                    <Col className='col-10'>
                        <DescriptionGpt
                            currentLang={currentLang}
                            elementDescription={elementDescription}
                            onEditorStateChange={data.handleChange}
                            toggleGptConfig={toggleGptConfig}
                            isChatGptOpen={isChatGptOpen}
                            intl={intl}
                            page={page}
                        />
                    </Col>
                </Row>
            :''}
        </SupportedMultiLang>
    );
};

export const NameTitleAndDescriptionMultiLang = ({ data, customToolbar }) => {
    const [lang, setLang] = useState(supportedLanguages[0]);

    const toggle = l => {
        if (lang !== l) setLang(l);
    }

    const texts = data && data.texts && data.texts.find(t => t.culture === lang);
    
    return (
        <SupportedMultiLangWithoutBody toggle={toggle} lang={lang}>
            <div className="px-2">
                <Row className="mb-2">
                    <Label for="Title" sm={12} lg={2}> <FormattedMessage id="CommonUIComponents.Name" />:</Label>
                    <Col sm={12} lg={10}>
                        <FormattedMessage id="CommonUIComponents.Name">{placeholder =>
                            <Input
                                type="text" className="px-2"
                                name="nameDescription" id="title" placeholder={placeholder}
                                value={texts && texts.nameDescription ? texts.nameDescription : ''}
                                onChange={(e) => data.handleChange(e, lang)}
                                required
                            />
                        }</FormattedMessage>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Label for="Title" sm={12} lg={2}> <FormattedMessage id="CommonUIComponents.Title" />:</Label>
                    <Col sm={12} lg={10}>
                        <FormattedMessage id="CommonUIComponents.Title">{placeholder =>
                            <Input
                                type="text" className="px-2"
                                name="titleDescription" id="title" placeholder={placeholder}
                                value={texts && texts.titleDescription ? texts.titleDescription : ''}
                                onChange={(e) => data.handleChange(e, lang)}
                                required
                            />
                        }</FormattedMessage>
                    </Col>
                </Row>

                <Row className="mb-2">
                    <Label for="Title" sm={12} lg={2}> <FormattedMessage id="CommonUIComponents.Description" />:</Label>
                    <Col sm={12} lg={10} className="pb-2">
                        <FormattedMessage id="CommonUIComponents.Description">{placeholder =>
                            <EditorText
                                placeholder={placeholder}
                                customToolbar={customToolbar}
                                editorState={texts && texts.editorState}
                                onEditorStateChange={(e) => data.onEditorStateChange(e, lang)}
                            />
                        }</FormattedMessage>
                    </Col>
                </Row>
            </div>
        </SupportedMultiLangWithoutBody>
    );
};


export const PaymentGatewayMultiLang = ({ data, noButton }) => {
    const [lang, setLang] = useState(supportedLanguages[0]);

    const toggle = l => {
        if (lang !== l) setLang(l);
    }

    const text = data && data.texts && data.texts.find(t => t.cultureCode === lang);

    return (
        <SupportedMultiLangWithoutBody toggle={toggle} lang={lang}>
            {!noButton ?
                <Row className="mb-2 ml-1 mt-1">
                    <Label for="ButtonDesc" sm={2}> <FormattedMessage id="CommonUIComponents.Button" />:</Label>
                    <Col sm={10}>
                        <FormattedMessage id="CommonUIComponents.Button">{placeholder =>
                            <Input
                                type="textarea" className="px-2"
                                name="buttonDesc" id="buttonDesc" placeholder={placeholder}
                                value={text && text.buttonDesc ? text.buttonDesc : ''}
                                onChange={(e) => data.handleChange(e, data.idx, lang)}
                                required
                            />
                        }</FormattedMessage>
                    </Col>
                </Row>
            :''}
            <Row className="mb-2 ml-1">
                <Label for="PaymentDesc" sm={2}> <FormattedMessage id="CommonUIComponents.Payment" />:</Label>
                <Col sm={10}>
                    <EditorText required className="px-2" editorState={text && text.editorState} onEditorStateChange={(newEditorState) => { data.handleEditorStateChange(newEditorState, data.idx, lang) }} />
                </Col>
            </Row>
        </SupportedMultiLangWithoutBody>
    );
};

export const EditorTextMultiLing = ({ texts, onEditorStateChange, selectedLang, changeLang, customToolbar, hasGpt, toggleGptConfig, isChatGptOpen, currentLang, elementDescription, intl, page }) => {
    const [lang, setLang] = useState(supportedLanguages[0]);

    if (selectedLang && selectedLang !== lang) {
        setLang(selectedLang);
    }

    const toggle = l => {
        if (lang !== l) setLang(l);
    }

    const text = texts && texts.find(t => t.culture === lang);

    return (
        <div className="border border-top-0" style={{ borderRadius: '5px' }}>
            <SupportedMultiLang toggle={changeLang ? changeLang : toggle} lang={lang} tabPaneBorder='border-0' />
            <div className="px-2">
                <EditorText
                    customToolbar={customToolbar}
                    editorState={text && text.editorState}
                    onEditorStateChange={(newEditorState) => { onEditorStateChange(newEditorState, lang) }}
                />
            </div>
            {hasGpt && global && global.modules && global.modules.some(m => m === 'OpenAI') ?
                <Row>
                    <Col>
                        <DescriptionGpt
                            currentLang={currentLang}
                            elementDescription={elementDescription}
                            onEditorStateChange={onEditorStateChange}
                            toggleGptConfig={toggleGptConfig}
                            isChatGptOpen={isChatGptOpen}
                            intl={intl}
                            page={page}
                        />
                    </Col>
                </Row>
            :''}
        </div>
    );
};