import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, ModalHeader, Input, Form, Card, CardBody, Button, Label, FormGroup } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { postAPI } from "../../Base/API"
import moment from "moment";
import BlockUi from 'react-block-ui';
import { SingleDatePicker } from 'react-dates';
import CustomSelect from '../../Base/Common/CustomSelect';
import { ErrorAlert } from "../../Common/ErrorAlert";
import { handleNotification } from "../../Base/Notification";


class ChangeLastSendModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            block: false,
            selectedData: this.props.selectedData ? this.props.selectedData : {},
            resetHash: false
        }
    }

    handleSave = (e) => {
        e.preventDefault();
        this.setState({ block: true });
        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                if (data.data) {
                    handleNotification(data, <FormattedMessage id="ChangeLastSendModal.LastSendDateSavedSuccessfully" />, <FormattedMessage id="General.Success" />);
                    this.props.updateTable(data.data[0]);
                    this.props.toggle();
                }
                this.setState({ sendInfoStatus: data.data, block: false });
            }
        }, `/api/Price/Reservation/v1/SendInfoStatus/${this.state.selectedData.id}?lastSendDate=${moment(this.state.selectedData.lastSendDate).format("YYYY-MM-DDTHH:mm")}&resetHash=${this.state.resetHash}`);
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    render() {
        const resetOptions = [
            {
                value: false,
                label: <FormattedMessage id="ChangeLastSendModal.No" />
            },
            {
                value: true,
                label: <FormattedMessage id="ChangeLastSendModal.Yes" />
            }
        ]

        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle} size={"md"} style={{ minWidth: 0 }}>
                <ModalHeader toggle={this.props.toggle}>
                    <FormattedMessage id="ChangeLastSendModal.Title" />
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={(e) => this.handleSave(e)}>
                            <Card className="shadow border-0 p-0">
                                <CardBody className="m-0">
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={6}> <FormattedMessage id="ReservationTimerDashboard.LastSendDate" /> </Label>
                                                <Col sm={6}>
                                                    <SingleDatePicker
                                                        id="Date"
                                                        isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                                                        date={moment(this.state.selectedData.lastSendDate)}
                                                        onDateChange={date => this.setState({ selectedData: { ...this.state.selectedData, lastSendDate: date } })}
                                                        focused={this.state.focused}
                                                        onFocusChange={({ focused }) => this.setState({ focused })}
                                                        small={true}
                                                        numberOfMonths={1}
                                                        required
                                                        showDefaultInputIcon={true}
                                                        renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label sm={6}> <FormattedMessage id="ChangeLastSendModal.ResetHash" /> </Label>
                                                <Col sm={6}>
                                                    <CustomSelect
                                                        options={resetOptions}
                                                        placeholder={<FormattedMessage id="ChangeLastSendModal.ResetHash" />}
                                                        onChange={this.handleChangeSelect.bind(this, 'resetHash')}
                                                        value={resetOptions.find(r => r.value === this.state.resetHash)}
                                                        isClearable isSearchable
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row className="bg-white">
                                        <Col className="text-right">
                                            <Button type="submit" className="btn btn-sm btn-host float-right">
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(ChangeLastSendModal)