import React, { Component } from 'react';
import { FormGroup, Label, Input, Col, Card, CardBody, Table, Row, CardHeader, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getAPI, postAPI, deleteAPI} from "../Base/API";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Common/ErrorAlert";
import { CustomTable } from '../Base/Common/CustomTable'
import { handleNotification } from "../Base/Notification"
import { ActiveInactiveStatusBadge, CommonHelper } from "../Base/Common/CommonUIComponents"
import { getLanguages } from '../Base/Common/ReferenceDataFunctions';
import CustomSelect from '../Base/Common/CustomSelect';


class ManageUser extends Component {

    constructor(props) {
        super(props);


        this.form = React.createRef();
        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            hotelId: this.props.hotelId,
            block: true,
            blockUserDetails: false,
            blockProperty: false,
            modal : false,
            ComplexData: null,
            user: {},
            selectedUser: {},
            userDetails: {}
        };

    }

    componentDidMount() {
       
        this.searchUser();
        this.setState({ blockProperty: true });
        getAPI(result => {
            const { data, error } = result;
            
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage});

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ user: data, property: data.defaultProperty, blockProperty: false});
            }
        }, '/api/User/v1/User');
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    searchUser() {
        this.setState({ block: true})
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ listUsers: data.users, property: data.defaultProperty, userDetails: {}, block: false  });
            }
        }, '/api/User/v1/User/getUsers');
    }

    getAccessGroups() {
        return [
            { value: 'e2c23135-8904-4d26-93fa-d9573ee19d2d', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.e2c23135-8904-4d26-93fa-d9573ee19d2d' }) },
            { value: 'f7d0725a-bdb6-44fc-b2ce-5a74e69dd89c', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.f7d0725a-bdb6-44fc-b2ce-5a74e69dd89c' }) },
            { value: '4b836682-0839-4a47-8299-5ca9f9139eea', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.4b836682-0839-4a47-8299-5ca9f9139eea' }) },
            { value: 'b6afe5cc-2f35-4cd1-acdc-8f58ee39f82d', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.b6afe5cc-2f35-4cd1-acdc-8f58ee39f82d' }) },
            { value: '33f374b2-eae8-4c93-9e18-c9022eada62b', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.33f374b2-eae8-4c93-9e18-c9022eada62b' }) },  
            { value: '2fb7fbe0-22e9-4fa4-ab27-47b139c55999', label: this.props.intl.formatMessage({ id: 'generic.accessGroup.2fb7fbe0-22e9-4fa4-ab27-47b139c55999' }) }
        ]
    }

    getUserDetails() {
        this.setState({ blockUserDetails: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                const user = data.user;

                if (user) {
                    user.otherEmails = user.otherEmails.filter(el => el !== user.email);
                }

                this.setState({ userDetails: user || {}, selectedUser: data.user ? data.user : {}, blockUserDetails: false });
            }
        }, '/api/User/v1/User/getUser/' + this.state.selectedUser.adId);
    }

    reinviteUser() {

        let body = {
            "adId": this.state.selectedUser.adId,
            "name": this.state.selectedUser.name,
            "surname": this.state.selectedUser.surname,
            "mobile": this.state.selectedUser.mobile,
            "email": this.state.selectedUser.email,
            "language": this.state.selectedUser.language
        }

        this.setState({ blockUserDetails: true });
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'Notification Sent', 'Success');
                }
                this.setState({blockUserDetails: false });
            }
        }, '/api/User/v1/User/reInviteUser', body);
        
    }

    disableUser() {

        this.setState({  blockUserDetails: true});
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'User Disabled', 'Success');
                }
                this.setState({  blockUserDetails: false });
            }
        }, '/api/User/v1/User/disableUser/' + this.state.selectedUser.adId);
        
    }

    enableUser() {

        this.setState({ blockUserDetails: true });
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'User Enabled', 'Success');
                }
                this.setState({blockUserDetails: false });
            }
        }, '/api/User/v1/User/enableUser/' + this.state.selectedUser.adId);

    }

    deleteUser() {

        this.setState({ blockUserDetails: true });
        deleteAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'User deleted', 'Success');
                }
                this.setState({  blockUserDetails: false });
            }
        }, '/api/User/v1/User/deleteUser/' + this.state.selectedUser.adId);

    }

    AddHotel() {

    }

    createUser(event) {

        event.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'User Created', 'Success');
                }
                this.setState({ selectedUser: data.user ? data.user : {}, blockUserDetails: false });
            }
        }, '/api/User/v1/User/addUser', req);
    }

    saveUser(event) {

        event.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails;

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'User Updated', 'Success');
                }
                this.setState({ selectedUser: data.user ? data.user : {}, blockUserDetails: false });
            }
        }, '/api/User/v1/User/updateUser', req);
    }

    updateProperties() {

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails.allowedProperties
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'properties updated', 'Success');
                }
                this.setState({  blockUserDetails: false });
            }
        }, '/api/User/v1/User/' + this.state.selectedUser.adId + '/updateAllowedProperties', req);
        
    }

    updateGroups() {

        this.setState({ blockUserDetails: true });

        let req = this.state.userDetails.groups
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUserDetails: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, 'properties updated', 'Success');
                }
                this.setState({ blockUserDetails: false });
            }
        }, '/api/User/v1/User/' + this.state.selectedUser.adId + '/updateGroups', req);

    }

    manageList(el, type,event) {
        if (this.state.userDetails[type] === undefined)
            return;

        let newgroup = this.state.userDetails[type].indexOf(el) >= 0 ? this.state.userDetails[type].filter(ar => ar !== el) : [...this.state.userDetails[type], el]

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [type]: newgroup
            }
        }));

    }

    manageGroups = (el) => {
        if (this.state.userDetails.groups === undefined)
            return;


        let newgroup = this.state.userDetails.groups.filter(g => !this.getAccessGroups().some(ag => ag.value === g));

        newgroup.push(el);


        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                groups: newgroup
            }
        }));
    }

    changeUserState(event) {

        let name = event.target.name;
        let value = event.target.value;

        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [name]: value
            }
        }));
    }

    handleSelect = (combo, name) => {
        this.setState(prevState => ({
            ...prevState,
            userDetails: {
                ...prevState.userDetails,
                [name]: combo ? combo.value : null
            }
        }));
    }

    render() {
        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "ManageUser.name" }),
                sort: true
            },
            {
                dataField: 'surname',
                text: this.props.intl.formatMessage({ id: "ManageUser.surname" }),
                sort: true
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "ManageUser.email" }),
                sort: true
            },
            {
                dataField: 'mobile',
                text: this.props.intl.formatMessage({ id: "ManageUser.mobile" }),
                sort: true
            },/*
            {
                dataField: 'userPrinciplaName',
                text: this.props.intl.formatMessage({ id: "ManageUser.userPrinciplaName" }),
                sort: true
            }*/
            {
                dataField: 'accountEnabled',
                text: this.props.intl.formatMessage({ id: "ManageUser.accountStatus" }),
                sort: true,
                formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell}/>
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ selectedUser: row, blockUserDetails : true }, () => this.getUserDetails())
            }
        };

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />

                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                    <ModalHeader toggle={this.toggleModal}  > <FormattedMessage id="ConfigChannelAutoActions.Channel" />
                        Rule For channel code {this.state.modalData ? this.state.modalData.channelConfigId : ''}
                    </ModalHeader>
                    <ModalBody className="modal-lg px-0 pb-0">
                    </ModalBody>
                </Modal>

                <form ref={this.form}>
                    <div className="container-fluid content-row">
                        <Row>
                            <Col className="Col-6">   
                                <BlockUi tag="div" blocking={this.state.block}>
                                <Card className="mb-2 border-0 shadow">
                                    <CardHeader className="border-bottom bg-white">
                                        <h6>
                                            <span className="fas fa-users mr-1" />
                                            <FormattedMessage id="ManageUser.users" />
                                            <Button className="btn btn-host btn-sm float-right mx-1" onClick={this.searchUser.bind(this)}>
                                                <i className="fas fa-sync-alt" />
                                            </Button>
                                        </h6>
                                    </CardHeader>
                                    <CardBody>
                                        <CustomTable
                                            data={this.state.listUsers ? this.state.listUsers : []}
                                            columns={columns}
                                            page={this.state.currentPage}
                                            totalSize={this.state.listUsers && this.state.listUsers.length}
                                            onTableChange={this.handleTableChange}
                                            shadow={false}
                                            exportCSV={false}
                                            selectRow={selectRow}
                                            search={true}
                                            remote={false}
                                        />                            
                                    </CardBody>
                                    </Card>
                                </BlockUi>
                                <BlockUi tag="div" blocking={this.state.blockUserDetails}>
                                    <Card className="mb-2 border-0 shadow small">
                                        <CardHeader className="border-bottom bg-white">
                                            {!global.isMobile ?
                                                <h6>
                                                    <CommonHelper help={<FormattedMessage id="ManageUser.Help" />} id={'manageruserhelp'} /> 
                                                    <span className="fas fa-id-card mr-1" />
                                                    <FormattedMessage id="ManageUser.selectedUser" />
                                                    {
                                                        this.state.userDetails.id === undefined ?
                                                            <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === null} onClick={this.createUser.bind(this)}><i className="fas fa-plus"></i></Button>
                                                            :
                                                            <div>
                                                                <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.deleteUser.bind(this)}>
                                                                    <i className="fas fa-user-times" />
                                                                </Button>
                                                                {
                                                                    this.state.userDetails.accountEnabled ?
                                                                        <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.disableUser.bind(this)} ><i className="fas fa-user-slash"></i></Button>
                                                                        :
                                                                        <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.enableUser.bind(this)}>
                                                                            <i className="fas fa-user-check" />
                                                                        </Button>
                                                                }
                                                                <Button className="btn btn-host btn-sm float-right mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.saveUser.bind(this)}  >
                                                                    <i className="fas fa-save" />
                                                                </Button>
                                                            </div>
                                                    }
                                                </h6>
                                            :
                                                <Row className='d-flex align-items-center'>
                                                    <Col className={this.state.userDetails.id === undefined ? 'col-8' : 'col-6'}>
                                                        <h6>
                                                            <span className="fas fa-id-card mr-1" />
                                                            <FormattedMessage id="ManageUser.selectedUser" />
                                                        </h6>
                                                    </Col>
                                                    <Col className={`text-right ${this.state.userDetails.id === undefined ? 'col-4' : 'col-6'}`}>
                                                        {
                                                            this.state.userDetails.id === undefined ?
                                                                <>
                                                                    <Button className="btn btn-host btn-sm mx-1" disabled={this.state.userDetails.id === null} onClick={this.createUser.bind(this)}><i className="fas fa-plus"></i></Button>
                                                                    <CommonHelper customFontSize={'small'} help={<FormattedMessage id="ManageUser.Help" />} id={'manageruserhelp'} /> 
                                                                </>
                                                                :
                                                                <>
                                                                    <Button className="btn btn-host btn-sm mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.deleteUser.bind(this)}>
                                                                        <i className="fas fa-user-times" />
                                                                    </Button>
                                                                    {
                                                                        this.state.userDetails.accountEnabled ?
                                                                            <Button className="btn btn-host btn-sm mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.disableUser.bind(this)} ><i className="fas fa-user-slash"></i></Button>
                                                                            :
                                                                            <Button className="btn btn-host btn-sm mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.enableUser.bind(this)}>
                                                                                <i className="fas fa-user-check" />
                                                                            </Button>
                                                                    }
                                                                    <Button className="btn btn-host btn-sm mx-1" disabled={Object.keys(this.state.userDetails).length === 0} onClick={this.saveUser.bind(this)}  >
                                                                        <i className="fas fa-save" />
                                                                    </Button>
                                                                </>
                                                        }
                                                    </Col>
                                                </Row>
                                                
                                            }
                                        </CardHeader>
                                        <CardBody>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm small">
                                                        <Label for="User" className="font-weight-bold pr-0" sm={3}> <FormattedMessage id="ManageUser.user" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" readOnly name="userPrinciplaName" value={this.state.userDetails.userPrinciplaName ? this.state.userDetails.userPrinciplaName : ''}  id="userPrinciplaName" />                                            
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="Email" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.email" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="email" required email bsSize="sm" name="email" value={this.state.userDetails.email ? this.state.userDetails.email : ''} onChange={this.changeUserState.bind(this)} id="email" />                                            
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="FirstName" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.name" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="name" value={this.state.userDetails.name ? this.state.userDetails.name : ''} onChange={this.changeUserState.bind(this)} id="name" />                                            
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row size="sm">
                                                        <Label for="FamilyName" className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.surname" /> </Label>
                                                            <Col sm={9}>
                                                                <Input type="text" bsSize="sm" required name="surname" value={this.state.userDetails.surname ? this.state.userDetails.surname: ''} onChange={this.changeUserState.bind(this)} id="surname" />                                            
                                                            </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <FormGroup row size="sm">
                                                        <Label for="mobile" className="font-weight-bold pr-0" sm={3}> <FormattedMessage id="ManageUser.mobile" /> </Label>
                                                        <Col sm={9}>
                                                            <Input type="text" bsSize="sm" required name="mobile" value={this.state.userDetails.mobile ? this.state.userDetails.mobile : ''} onChange={this.changeUserState.bind(this)} id="mobile" />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                               
                                                <Col>
                                                    <FormGroup row size="sm">
                                                        <Label className="font-weight-bold" sm={3}> <FormattedMessage id="ManageUser.language" /> </Label>
                                                        <Col sm={9}>
                                                            <CustomSelect
                                                                placeholder={<FormattedMessage id="ManageUser.language" />}
                                                                options={getLanguages()}
                                                                onChange={(e) => this.handleSelect(e, "language")}
                                                                value={getLanguages().find(l => l.value === this.state.userDetails.language) || ''}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <FormGroup row size="sm">
                                                {!global.isMobile ?
                                                    <>
                                                        <Col className="font-weight-bold" style={{ flex: '0 0 12.6%', maxWidth: '12.6%' }}> Inv. Status </Col>
                                                        <Col style={this.state.userDetails.status === 'PendingAcceptance' ? { flex: '0 0 78.3%', maxWidth: '78.3%' } : { flex: '0 0 87.3%', maxWidth: '87.3%' }}>
                                                            <Input type="text" bsSize="sm" name="stat" defaultValue={this.state.userDetails.status ? this.state.userDetails.status : ''} disabled id="mobile" />
                                                        </Col>
                                                    </>
                                                :
                                                    <>
                                                        <Label for="stat" className="font-weight-bold pr-0" sm={3}> Inv. Status </Label>
                                                        <Col>
                                                            <Input type="text" bsSize="sm" name="stat" defaultValue={this.state.userDetails.status ? this.state.userDetails.status : ''} disabled id="mobile" />
                                                        </Col>
                                                    </>
                                                }
                                                {
                                                    this.state.userDetails.status && this.state.userDetails.status === 'PendingAcceptance' ?
                                                        <Col className="pl-0 pt-1 text-right">
                                                            <Button className="btn btn-sm btn-host float-right" onClick={this.reinviteUser.bind(this)}>
                                                                <i className="far fa-share-square" />
                                                            </Button>
                                                        </Col>
                                                        :
                                                        ''
                                                }
                                            </FormGroup>

                                            {this.state.userDetails.otherEmails?.length > 0 ?
                                                <>
                                                    <Label className="font-weight-bold" >
                                                        <FormattedMessage id="ManageUser.OtherEmails" />
                                                    </Label>

                                                    <ul>
                                                        <Row>
                                                            {this.state.userDetails.otherEmails.map((email, index) =>
                                                                <Col className="col-12 col-lg-6">
                                                                    <li>{email}</li>
                                                                </Col>
                                                            )}
                                                        </Row>
                                                    </ul>
                                                </>
                                            : ''}
                                        </CardBody>
                                    </Card>
                                </BlockUi>
                            </Col>
                            <Col>
                                <Row>
                                    <Col className={global.isMobile ? 'col-12' : 'pr-1'} >
                                        <BlockUi tag="div" blocking={this.state.blockUserDetails || this.state.blockProperty}>
                                            <Card className="mb-1 h-100  border-0 shadow">
                                                <CardHeader className="border-bottom bg-white">
                                                    <h6>
                                                        <span className="fas fa-hotel mr-1"></span> <FormattedMessage id="Profile.Property" />
                                                        <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.updateProperties.bind(this)}>
                                                            <i className="fas fa-save" />
                                                        </Button>
                                                    </h6>
                                                </CardHeader>
                                                <CardBody>
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            {
                                                                this.state.user.userAllowedProperties && this.state.user.userAllowedProperties.map((item, key) =>
                                                                    <tr key={key} className={this.state.userDetails && this.state.userDetails.allowedProperties && this.state.userDetails.allowedProperties.filter(el => el === item.property).length > 0 ? 'bg-secondary text-white' : ''} >
                                                                        <th scope="row"> {key}</th>
                                                                        <td onClick={this.manageList.bind(this, item.property, 'allowedProperties')}>{item.propertyName}</td>
                                                                    </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </BlockUi>
                                    </Col>
                                    <Col className={!global.isMobile ? "pl-1" : 'mt-1'}>
                                        <BlockUi tag="div" blocking={this.state.blockUserDetails}>
                                            <Card className="mb-1 h-100  bg-white border-bottom shadow">
                                                <CardHeader className="border-bottom bg-white">
                                                    <h6>
                                                        <span className="fas fa-users mr-1" />
                                                        <FormattedMessage id="Profile.AccessGroups" />
                                                        <Button className="btn btn-host btn-sm float-right mx-1" disabled={this.state.userDetails.id === undefined} onClick={this.updateGroups.bind(this)} >
                                                            <i className="fas fa-save" />
                                                        </Button>
                                                    </h6>
                                                </CardHeader>
                                                <CardBody>
                                                    <Table className={"table-sm table-borderless" + (this.state.userDetails.id === undefined ? '  text-secondary' : '')}>
                                                        <tbody>
                                                            { this.getAccessGroups().map((item, key) =>
                                                                <tr key={key}>
                                                                    <th scope="row"> {key}</th>
                                                                    <td className={this.state.userDetails && this.state.userDetails.groups && this.state.userDetails.groups.filter(el => el === item.value).length > 0 ? 'bg-secondary text-white pointer' : 'pointer'} onClick={() => this.manageGroups(item.value)} >
                                                                        {item.label}
                                                                    </td>
                                                                </tr>
                                                            ) }
                                                        </tbody>
                                                    </Table>
                                                </CardBody>
                                            </Card>
                                        </BlockUi>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>                         
                </form>
            </BlockUi>
        );
    }
};

export default injectIntl(ManageUser)