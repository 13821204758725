import React, { Component } from 'react';
import { Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export class RenderPaymentGatewayValidPaymentModes extends Component {

    render() {
        const { paymentMode, validPaymentModes, paymentGateway, idx, updateValidPaymentModes } = this.props;

        return (
            <div className="mb-2">
                <Row>
                    <Col>
                        {!global.isMobile ?
                            <h5>
                                <FormattedMessage id="PaymentGateway.ValidPaymentModes" />
                            </h5>
                            :
                            <h6>
                                <FormattedMessage id="PaymentGateway.ValidPaymentModes" />
                            </h6>
                        }
                    </Col>
                </Row>
                <hr className="my-1" />
                <Row className={`${global.isMobile ? 'pt-2 d-flex align-items-center' : 'mt-2'}`}>
                    {
                        paymentGateway && paymentMode === paymentGateway.code && validPaymentModes && validPaymentModes.map((mode, key) =>
                            <Col key={key} className="col-4">
                                <FormGroup className={global.isMobile ? 'm-0' : ''} row>
                                    <Col className={`${mode.value==='MbWay' && global.isMobile ? 'px-0' : ''}`}>
                                        <CustomInput type="checkbox" id={mode.value} name={mode.value} checked={paymentGateway.validPaymentModes && paymentGateway.validPaymentModes.find(pm => pm === mode.value) ? true : false} onChange={e => updateValidPaymentModes(e, idx)}>
                                            {mode.img(35)}
                                        </CustomInput>
                                    </Col>
                                </FormGroup>
                            </Col>
                        )
                    }
                </Row>
            </div>
        );
    }
}