import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Row, Col } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard, SelectHotel } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from "../../Base/Common/CustomTable";
import { handleNotification } from "../../Base/Notification";
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import CustomSelect from '../../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';

export class SyncEvents extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            activeTab: '1',
            modal: false,
            eventTypes: [
                {
                    value: '1',
                    label: this.props.intl.formatMessage({ id: "SyncEvents.Error" })
                },
                {
                    value: '0',
                    label: this.props.intl.formatMessage({ id: "SyncEvents.Warning" })
                }
            ],
            selectedEventType : null,
            syncEvents: [],
            startDate: moment().subtract(7, 'day'),
            endDate: moment(),
            pageIndex: 0,
            pageSize: 10
        };
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    componentDidMount() {
        this.getSyncEvents();    
    }

    getSyncEvents() {
        const { endDate, hotelId, pageIndex, pageSize, selectedEventType, startDate } = this.state;

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data, "search", 'Success');
                }
                else {
                    this.setState({ syncEvents: data.data, block: false, error: errorMessage });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/hotel/v1/Dashboard/SyncEvents?startDate=${startDate.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&pageSize=${pageSize}&pageIndex=${pageIndex}${selectedEventType ? `&eventType=${selectedEventType}` : ``}${hotelId ? `&hotelId=${hotelId}` : ``}`);
    }
    
    handleTableChange = (page) => {
        this.setState({ pageIndex: page }, this.getSyncEvents);
    }

    setComboStatus = (evt, inputValue, actionMeta) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value).join(',');
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    search = () => {
        this.setState({ pageIndex: 0 }, this.getSyncEvents);
    }

    render() {
        const { endDate, hotelId, pageIndex, pageSize, startDate, syncEvents } = this.state;

        const helpContent =
            <div>
                TODO
                <hr />
            </div>

        const columns = [
            {
                dataField: 'id',
                text: 'id'
            },
            {
                dataField: 'hotelId',
                text: this.props.intl.formatMessage({ id: "SyncEvents.HotelId" })
            },
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "SyncEvents.Type" }),
                formatter: cell => cell === 1 ? this.props.intl.formatMessage({ id: "SyncEvents.Error" }) : this.props.intl.formatMessage({ id: "SyncEvents.Warning" }),
                csvFormatter: cell => cell === 1 ? this.props.intl.formatMessage({ id: "SyncEvents.Error" }) : this.props.intl.formatMessage({ id: "SyncEvents.Warning" })
            },
            {
                dataField: 'code',
                text: this.props.intl.formatMessage({ id: "SyncEvents.Code" }),
                formatter: (cell, row) => <div>{cell === 0 ? 'Unknown' : cell === 1 ? 'Serialization failed' : cell === 2 ? 'Root object not found' : cell === 3 ? 'Record is older than the one on DB' : cell === 4 ? "Not a valid object" : '--'}</div>
            },
            {
                dataField: 'exceptionMessage',
                text: this.props.intl.formatMessage({ id: "SyncEvents.Exception" }),
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'objectType',
                text: this.props.intl.formatMessage({ id: "SyncEvents.ObjectType" })
            },
            {
                dataField: 'updatedAt',
                text: this.props.intl.formatMessage({ id: "SyncEvents.Received" }),
                formatter: cell => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : '',
                csvFormatter: cell => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: row  => {
                this.setState({ modal: true, val: row.message })
            }
        };

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-sync-alt'} title={'SyncEvents.title'} help={helpContent}>
                <Modal isOpen={this.state.modal} onHide={this.toggleModal} fade={false}>
                    <ModalHeader toggle={this.toggleModal}>
                        <h4 className="modal-title w-100 font-weight-bold">
                            <FormattedMessage id="SyncEvents.MessageReceived" />
                        </h4>
                    </ModalHeader>
                    <ModalBody>
                        <div> <pre >
                            {this.state.val != null ? this.state.val : ""}
                        </pre></div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-host" onClick={this.toggleModal}><FormattedMessage id="SyncEvents.Close" /></Button>
                    </ModalFooter>
                </Modal>

                <Row>
                    <Col className="col-3">
                        <CustomSelect icon={'fas fa-exclamation-circle'} isClearable isSearchable placeholder={<FormattedMessage id='SyncEvents.ChooseEventType' />} options={this.state.eventTypes} onChange={this.setComboStatus.bind(this, 'selectedEventType')} />
                    </Col>
                    <Col className="col-3 px-1">
                        <SelectHotel name={'hotelId'} icon={'fas fa-hotel'} onChangeFunc={this.setComboStatus} value={hotelId} placeholder={<FormattedMessage id="HotelLicenses.Hotel" />} />
                    </Col>
                    <Col className="col-3">
                        <DateRangePicker
                            startDate={startDate ? moment(startDate).locale(moment.locale()) : startDate}
                            startDateId="startDate"
                            isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                            endDate={endDate ? moment(endDate).locale(moment.locale()) : endDate}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-sm btn-host" onClick={this.search}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <CustomTable
                            data={syncEvents ? syncEvents : []}
                            columns={columns}
                            exportCSV={true}
                            selectRow={selectRow}
                            hasPagination={false}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-1">
                        <Button className="btn-host shadow btn-sm mr-1" disabled={!pageIndex || pageIndex < 1} onClick={() => this.handleTableChange(parseInt(pageIndex) - 1)}>
                            <i className="fas fa-angle-left" />
                        </Button>
                    </Col>
                    <Col className="text-center" style={{ fontSize: '20px' }}>
                        <FormattedMessage id="SyncEvents.Page" values={{ page: parseInt(pageIndex) + 1 }} />
                    </Col>
                    <Col className="col-1 text-right">
                        <Button className="btn-host btn-sm shadow" disabled={syncEvents.length < pageSize} onClick={() => this.handleTableChange(parseInt(pageIndex) + 1)}>
                            <i className="fas fa-angle-right" />
                        </Button>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(SyncEvents);