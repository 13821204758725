import React from "react";
import { Row, Col, Card } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import moment from 'moment';


class Calendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            firstDay: this.props.firstDay,
            weekDays: moment.weekdaysShort(),
            calendarRows: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (moment(this.props.firstDay).isSame(nextProps.firstDay, 'month') === false) {
            this.setState(nextProps, () => this.renderCalendar());
        }
    }

    componentDidMount() {
        this.renderCalendar();
    }

    renderCalendar = () => {
        let blank = this.getBlankCells();
        let daysInMonth = this.getDaysInMonth();
        let totalSlots = [...blank, ...daysInMonth];
        let cells = [], rows = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                while (cells.length < 7) {
                    cells.push(<Col className="px-1" key={`end` + i + cells.length}>{" "}</Col>)
                }
                rows.push(cells);
            }
        });

        this.setState({ calendarRows: rows })
    }

    getFirstDayOfMonth = () => {
        let dateObject = this.state.firstDay;
        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    getBlankCells = () => {
        let blanks = [];

        for (let i = 0; i < this.getFirstDayOfMonth(); i++) {
            blanks.push({ day: ''});
        }

        return blanks
    }

    getDaysInMonth = () => {
        let daysInMonth = [];

        for (let d = 1; d <= moment().daysInMonth(); d++) {
            daysInMonth.push({ day: d } );
        }

        return daysInMonth;
    }

    handleMonth = (value) => {
        this.setState({
            firstDay: moment(this.state.firstDay).add(value, 'month')
        }, this.renderCalendar);
    }

    render() {
        const { weekDays, calendarRows, firstDay, block } = this.state;
        const { divergencesByDay, divergencesByChannel, selectedChannel, totalMonths } = this.props;

        const prevMonthEnable = totalMonths.length > 0 && totalMonths[0] !== moment(firstDay).format("YYYY-MM");
        const nextMonthEnable = totalMonths.length > 0 && totalMonths[totalMonths.length - 1] !== moment(firstDay).format("YYYY-MM");

        return (
            <BlockUi tag="div" blocking={block} className="h-100">
                <div>
                    <Row className="mt-2 pl-4 text-center">
                        <Col/><Col/>
                        <Col className="text-right pr-0">
                            <i className={"fas fa-chevron-left " + (prevMonthEnable ? 'pointer text-info' : 'text-muted')} onClick={() => { if (prevMonthEnable) this.handleMonth(-1) }} />
                        </Col>
                        <Col className="px-0 mb-2">
                            <span>{moment(firstDay).locale(this.props.intl.locale).format('MMMM YYYY')}</span>
                        </Col>
                        <Col className="text-left pl-0">
                            <i className={"fas fa-chevron-right " + (nextMonthEnable ? 'pointer text-info' : 'text-muted')} onClick={() => { if (nextMonthEnable) this.handleMonth(1) }} />
                        </Col>
                        <Col/><Col/>
                    </Row>

                    <Row className="pl-3 align-items-center text-muted font_size_xxs">
                        {weekDays && weekDays.map(day =>
                            <Col key={day} className="py-1 text-center">
                                {day}
                            </Col>
                        )}
                    </Row>

                    {calendarRows && calendarRows.map((week, i) =>
                        <Row className="ml-2" key={i} style={{ overflow: 'hidden', borderTop: i === 0 && '2px solid rgb(201, 222, 254)', borderBottom: '2px solid rgb(201, 222, 254)' }}>
                            {week.map((d, key) => {
                                const availabilty = (divergencesByDay ? divergencesByDay.find(el => moment(el.hotelDate).isSame(firstDay, 'month') && (moment(el.hotelDate).format("D").toString()) === (d.day && d.day.toString())) : null);
                                const hasChannelDiff = availabilty?.channelsDifferences.some(cd => cd.channelInstanceId === selectedChannel && !(cd.availability !== 0 || cd.difference > 0));
                                
                                return <Col key={`CalendarDay${i}week${key}`} className={"calendar-border px-1 cursor-pointer overflow-hidden "}
                                    style={{
                                        borderLeft: '1px solid #c9defe', borderRight: '1px solid #c9defe', height: (calendarRows.length > 6 ? "12vh" : "15vh"),
                                        backgroundColor: key === 0 || key === 6 ? '#165C7D11' : '#FAFCFF'
                                    }}>

                                    <div style={{ fontSize: '0.8em' }}>
                                        <Row>
                                            <Col className="col-7 ml-1 pt-1">{d.day} </Col>
                                            {availabilty &&
                                                <Col>
                                                    <div className="d-flex justify-content-end">
                                                        <span className={"d-flex align-items-center justify-content-center mt-1 " + ((selectedChannel ? hasChannelDiff : availabilty.channelsDifferences.filter(({availability, difference}) => availability !== 0 || difference > 0)?.length > 0) ? 'badge-danger' : 'badge-info')}
                                                                style={{ width: '22px', height: '22px', color: 'white', borderRadius: '20px', fontSize: '11px' }}
                                                            >
                                                            <b>{availabilty?.freeRooms}</b>
                                                        </span>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>

                                        {availabilty ?
                                            <div className="py-1 mt-2 scrollableDiv" style={{ height: '11vh', marginLeft: '10%', marginRight: '4%', paddingRight: '6%', overflow: 'auto' }}>

                                                {availabilty.channelsDifferences?.map((channel, idx) => {
                                                    const curChannel = divergencesByChannel?.find(c => c.channelInstanceId === channel.channelInstanceId);
                                                    const isSelected = selectedChannel !== null ? channel.channelInstanceId === selectedChannel : availabilty;

                                                    return isSelected ?
                                                        <span key={idx}>
                                                            <div className="d-flex mb-1">
                                                                <span className="d-flex align-items-center mb-1 pl-3 pr-2 w-100" style={{ backgroundColor: curChannel?.color, height: '20px', color: 'white', borderRadius: '20px', fontSize: '12px' }} title={channel?.roomCode}>
                                                                    <div style={{ width: '20%' }}>
                                                                        <b> {channel.availability} </b>
                                                                    </div>
                                                                    <div className="ml-1" style={{ width: '80%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                                                        {channel?.roomCode}
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </span>
                                                    : ''
                                                } )}
                                            </div>
                                        : ''}
                                    </div>
                                </Col>
                            })}
                        </Row>
                    )}
                </div>
            </BlockUi>
        );
    }
}

export default injectIntl(Calendar);