import moment from 'moment';
import React, { Component } from 'react';
import { getAPI } from '../Base/API';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import MajorBulkUpdate from './MajorBulkUpdate';
import { FormattedHTMLMessage } from 'react-intl';
import { handleNotification } from "../Base/Notification";

export class BulkUpdateRestrictions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: null,
            startDate: moment(moment(), 'YYYY-MM-DD'),
            endDate: moment(moment().add(13, 'days'), 'YYYY-MM-DD'),
            roomCategories: [],
            channelInstances: [],
            heyBeAndGoogleChannels: [],
            labels: [],
            hideLabels: true,
            selectedLabels: []
        }
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const roomCategories = data.roomcategory ? Object.values(data.roomcategory).map(rc => { rc.value = rc.id; rc.label = rc.description; return rc; }) : [];
                const rateCodes = data.ratecode ? Object.values(data.ratecode).map(rc => { rc.value = rc.id; rc.label = rc.code; return rc; }) : [];
                roomCategories.forEach(rc => {
                    rc.rates = rateCodes.filter(r => r.roomCategoryId === rc.value);
                });

                const rateCodeChannels = data.ratecodechannel ? Object.values(data.ratecodechannel) : [];
                const channelinstanceList = data.channelInstance ? Object.values(data.channelInstance) : [];
                const channelInstances = channelinstanceList
                    .filter(ci => ci.active)
                    .sort((ci1, ci2) => ci1.name > ci2.name ? 1 : -1)
                    .map(ci => {
                        const rcc = rateCodeChannels.filter(rcc => rcc.channelInstanceId === ci.id);
                        const opt = {
                            value: ci.id,
                            label: `${ci.name}(${ci.propertyCode})`,
                            rateCodeChannels: rcc
                        };
                        return opt;
                    })
                    .filter(ci => ci.rateCodeChannels.length > 0);

                const beChannels = ['HEYBE', 'GOOGLEHOTELADS'];
                const heyBeAndGoogleChannels = channelinstanceList
                    .filter(ci => beChannels.some(be => ci.name.toUpperCase().includes(be)))
                    .map(ci => ci.id);

                this.setState({ error: errorMessage, block: false, roomCategories, channelInstances: channelInstances, heyBeAndGoogleChannels: heyBeAndGoogleChannels }, () => this.getLabels());
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Price/Price/Price/v1/channelCode/static`); 
    }

    searchRates = (value, isLabel, ratecodeIds) => {
        let { roomCategories } = this.state;
        var labelIds = this.state.labelIds ? this.state.labelIds : [];
        var text = this.state.text ? this.state.text : null;

        roomCategories && roomCategories.forEach(rc => {
            rc.rates && rc.rates.forEach(r => {
                var i = 0;
                if (ratecodeIds.includes(r.id)) {
                    r.hidden = false;
                }
                else if (!value) {
                    if (!isLabel) {
                        if (labelIds && !labelIds.includes(r.rateLabelId) && labelIds.length != 0) {
                            r.hidden = true;
                        } else {
                            r.hidden = false;
                        }
                        text = null;
                    }
                    else {
                        labelIds = [];
                        if (text && !r.label.toLowerCase().includes(text.toLowerCase())) {
                            r.hidden = true;
                        } else {
                            r.hidden = false;
                        }
                    }
                }
                else {
                    if (!isLabel && value && r.label.toLowerCase().includes(value.toLowerCase())) {                        
                        if (labelIds && !labelIds.includes(r.rateLabelId) && labelIds.length != 0) {
                            r.hidden = true;
                        } else { 
                            r.hidden = false;
                        }
                    }
                    else if (isLabel && value) {
                        value.forEach(x => {
                            if (x.value == r.rateLabelId || (i != 0 && r.hidden == false)) {
                                if (text && !r.label.toLowerCase().includes(text.toLowerCase())) {
                                    r.hidden = true;
                                } else {
                                    r.hidden = false;
                                }
                            } else {
                                r.hidden = true;
                            }
                            labelIds.push(x.value)
                            i++;
                        })
                    }
                    else {
                        r.hidden = true;
                        text = value;
                    }
                }
            });
            if (rc.rates.every(rate => rate.hidden === true)) {
                rc.hidden = true;
            } else {
                rc.hidden = false;
            }
        })

        this.setState({ roomCategories, labelIds: labelIds.filter((value, index, array) => array.indexOf(value) === index), text });
    }

    getLabels() {
        const { roomCategories } = this.state;
        var usedLabels = [];
        var apiLabels = [];
        roomCategories && roomCategories.forEach(rc => {
            rc.rates && rc.rates.forEach(r => {
                if (r.rateLabelId != null) {
                    usedLabels.push(r.rateLabelId)
                }
            })
        })
        usedLabels = [...new Set(usedLabels)];
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response) {
                    data.response.forEach(item => {
                        var hasAssociatedRate = usedLabels.find(rate => rate === item.id);
                        if (hasAssociatedRate) {
                            const label = { 'value': item.id, 'label': item.label };
                            apiLabels.push(label);
                        }
                    })
                    this.setState({ labels: apiLabels, hideLabels: !(apiLabels.length > 0) });
                }
            }
        }, '/api/Rate/Rate/v1/RateLabel');
    }

    clearRatesSearch = () => {
        let { roomCategories } = this.state;

        roomCategories && roomCategories.forEach(rc => {
            rc.rates && rc.rates.forEach(r => {
                r.hidden = false;
            });
        })

        this.setState({ roomCategories });
    }

    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    }

    render() {
        const { block, error, roomCategories, startDate, endDate, channelInstances, heyBeAndGoogleChannels, labels, hideLabels } = this.state;


        return (
            <StyledCard block={block} error={error} title="navbar.BulkUpdateRestrictions" help={<FormattedHTMLMessage id="BulkUpdateRestrictions.Help" />} id="BulkUpdateRestrictions">
                <MajorBulkUpdate
                    startDate={startDate}
                    endDate={endDate}
                    roomCategories={roomCategories}
                    channelInstances={channelInstances}
                    heyBeAndGoogleChannels={heyBeAndGoogleChannels}
                    onDatesChange={this.onDatesChange}
                    searchRates={this.searchRates}
                    clearRatesSearch={this.clearRatesSearch}
                    labels={labels}
                    hideLabels={hideLabels}
                />
            </StyledCard>
        );
    }
}