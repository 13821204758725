import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Popover, PopoverBody, Row, Modal, ModalBody, UncontrolledPopover } from 'reactstrap';
import CustomPopOver from '../../Utils/CustomPopOver';

export class Tutorial extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: ".input-group, .navbar, .btn, .css-2b097c-container, .col, .tutorialHide",
            step: 0
        }
    }

    componentDidMount() {
        this.toggleVisibilityPopOver();
        this.initHelp();
    }

    componentWillUnmount(){
        const { filter } = this.state;

        this.toggleVisibilityPopOver(true);
        document.querySelectorAll(filter).forEach(el => el.style["opacity"] = '1');
    }

    toggleVisibilityPopOver = (show) => {
        const classes = '.popover.show.bs-popover-left';

        //Show popover
        document.querySelectorAll(classes).forEach(el =>
            el.setAttribute("style", `display: ${show ? 'unset' : 'none'}`)
        );
        
        const unplesantInput = document.getElementById('unplesantInput');

        if(unplesantInput && show){
            unplesantInput.setAttribute("style", `opacity: '0'`)
        }
    }

    initHelp = (e) => {
        const { tutorialSteps } = this.props;
        const { filter, step } = this.state;

        if (tutorialSteps && tutorialSteps.length > 0) {
            var currel = tutorialSteps[step];
            if(!currel) return;

            document.querySelectorAll(filter).forEach(el => !el.classList.contains('tutorial') ? el.style["opacity"] = '0.1' : el.style["opacity"] = '1');

            document.querySelectorAll(`#${currel.id}, #${currel.id} *`).forEach(el => {
                if(el.style["opacity"] !== '1')
                    el.style["opacity"] = '1';
            });
        }
    }

    skipTutorial = () => {
        const { filter } = this.state;
        document.querySelectorAll(filter).forEach(el => el.style["opacity"] = '1');
        this.props.toggle();
        return;
    }

    nextStep = () => {
        const { tutorialSteps, toggle } = this.props;
        const { step } = this.state;
        
        if(tutorialSteps[step]?.action) tutorialSteps[step].action();

        const domElExists = document.getElementById(tutorialSteps[step + 1]?.id);

        if (step === tutorialSteps.length || !domElExists) {
            this.skipTutorial();
        } else {
            this.setState({ step: step + 1 }, () => this.initHelp());
        }
    }

    previousStep = () => {
        const { tutorialSteps } = this.props;
        const { step } = this.state;

        if(tutorialSteps[step]?.action) tutorialSteps[step].action();

        const domElExists = document.getElementById(tutorialSteps[step - 1]?.id);

        if (step === 0 || !domElExists) {
            this.skipTutorial();
        } else {
            this.setState({ step: step - 1 }, () => this.initHelp());
        }
    }

    render() {
        const { tutorialSteps } = this.props;
        const { step } = this.state;

        const tutorialStep = tutorialSteps && tutorialSteps[step];
        const renderPopOver = tutorialStep || !tutorialStep?.type || !tutorialStep?.id;
        const isLast = tutorialStep && (tutorialSteps[tutorialSteps.length - 1]?.id === tutorialSteps[step].id);
        const isFirst = tutorialStep && (tutorialSteps[0]?.id === tutorialSteps[step].id);

        return (
            tutorialStep ?
                tutorialSteps.map((el, key) =>
                    <div key={key}>
                        {renderPopOver ?
                            <CustomPopOver className="tutorial" placement={el.placement} trigger="block" isOpen={key === step} target={el.domEl || el.id} key="1" flip={false}>
                                <PopoverBody>
                                    <div className="p-2">
                                        <div>
                                            {el.message}
                                        </div>
                                        <div onClick={this.skipTutorial} className="cursor-pointer shadow tutorialClose">
                                            <i className="fas fa-times"/>
                                        </div>
                                    </div>
                                    <Row>
                                        <Col className="col-3 tutorial" style={{ 'alignSelf': 'center' }}>
                                            <Button className=" text-center m-2 btn-sm tutorial" onClick={isFirst ? this.skipTutorial : this.previousStep}
                                            style={{ fontSize: '0.8em', background: 'unset', color: '#165C7D', borderColor: '#165C7D' }}>
                                                {isFirst ?
                                                    <FormattedMessage id="Tutorial.Skip"/>
                                                :
                                                    <FormattedMessage id="Tutorial.Previous"/>
                                                }
                                            </Button>
                                        </Col>
                                        <Col className="tutorial col-9 text-right">
                                            <Button className=" text-center m-2 btn-sm btn-host tutorial" onClick={this.nextStep} disabled={el.blockNext}
                                            style={{ fontSize: '0.8em', background: el.blockNext ? '#6c757d' : '#165C7D', color: 'white', borderColor: el.blockNext ? '#6c757d' : '#165C7D' }}>
                                                {isLast ?
                                                    <FormattedMessage id="Tutorial.Finish"/>
                                                :
                                                    <FormattedMessage id="Tutorial.Next"/>
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </PopoverBody>
                            </CustomPopOver>
                        :
                            <Modal isOpen={key === step} size="lg" className="border-0 shadow tutorial" style={{ top: '10%' }}>
                                <ModalBody className="tutorial" style={{ backgroundColor: '#FFFFFF', borderRadius: "1.1rem" }}>
                                    <div className="text-center text-host py-4 tutorial">
                                        <h3>{el.header}</h3>
                                    </div>
                                    <div className="p-2 text-center tutorial">
                                        {el.message}
                                    </div>
                                    <Row className="mt-2">
                                        <Col className="col-2 tutorial" style={{ 'alignSelf': 'center' }}>
                                            <Button color="link" onClick={this.skipTutorial}>
                                                <FormattedMessage id="Tutorial.Skip"/>
                                            </Button>
                                        </Col>
                                        <Col className=" col-8 text-center tutorial">
                                            <Button className=" tutorial text-center m-2 btn-sm btn-host px-3" onClick={this.nextStep}>
                                                <FormattedMessage id="Tutorial.Start"/>
                                            </Button>
                                        </Col>
                                        <Col className="col-2 tutorial"/>
                                    </Row>
                                </ModalBody>
                            </Modal>
                        }
                    </div>
                )
            :''
        );
    }
}