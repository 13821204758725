import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { postAPI, putAPI } from "../Base/API";
import CustomSelect from '../Base/Common/CustomSelect';
import { handleNotification } from "../Base/Notification";
import { ErrorAlert } from "../Common/ErrorAlert";

class ClientDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            comment: undefined,
            client: this.props.client ? this.props.client : {}
        };
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            client: {
                ...this.state.client,
                [name]: combo ? combo.value : null
            }
        });
    };
    
    updateContact = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    this.props.updateTable(data.response[0])
                    handleNotification(data, <FormattedMessage id="ClientDetails.ContactUpdated" />, <FormattedMessage id="General.Success" />);
                }
                this.setState(({ block: false }));
            }
        }, `/api/Price/Reservation/v1/prospects/${this.state.client.id}/${this.state.client.status}`, );
    }

    addComment(e) {

        e.preventDefault();
        this.setState({ block: true });

        let req = { "comment": this.state.comment };

        postAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
               
                    handleNotification(data, <FormattedMessage id="ClientDetails.ContactUpdated" />, <FormattedMessage id="General.Success" />);

                if (data.response) {
                    this.props.updateTable(data.response[0]);
                    this.setState(({ client: data.response[0] }));
                }

                this.setState(({ block: false }));
            }
                
            
        }, `/api/Price/Reservation/v1/prospects/${this.state.client.id}/comment`, req);
    }

    render() {
        const statusOptions = [
            {
                value: "FollowUp",
                label: <FormattedMessage id="SAPOClient.FollowUp" />,
            },
            {
                value: "Close",
                label: <FormattedMessage id="SAPOClient.Closed" />,
            }
        ];

        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-lg">
                <ModalHeader toggle={this.props.toggle}>
                    <FormattedMessage id="ClientDetails.ClientDetails" />
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.updateContact}>
                            <Card className="shadow border-0 p-0">
                                <CardBody className="m-0">
                                    <Row className="bg-white mb-1">
                                        <Col className="text-right">
                                            <Button type="submit" className="btn btn-sm btn-host float-right" >
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="contact" sm={4}> <FormattedMessage id="SAPOClients.Contact" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="text"
                                                        name="contact"
                                                        value={this.state.client.contact}
                                                        disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="hotelName" sm={4}> <FormattedMessage id="SAPOClients.HotelName" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="text"
                                                        name="hotelName"
                                                        value={this.state.client.hotelName}
                                                        disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="email" sm={4}> <FormattedMessage id="SAPOClients.Email" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        value={this.state.client.email}
                                                        disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="phone" sm={4}> <FormattedMessage id="SAPOClients.Phone" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="number"
                                                        name="phone"
                                                        value={this.state.client.phone}
                                                        disabled
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="status" sm={2}> <FormattedMessage id="SAPOClients.Status" /> </Label>
                                                <Col sm={10}>
                                                    <CustomSelect
                                                        required
                                                        name="status"
                                                        value={this.state.client.status === "Created" ? { value: "Created", label: <FormattedMessage id="SAPOClient.Created" />} : statusOptions.find(el => el.value === this.state.client.status) }
                                                        options={statusOptions}
                                                        onChange={this.handleChangeSelect.bind(this, 'status')}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        
                                           
                                    </Row>
                                    <hr className="py-1" />
                                    <Row>
                                        <Col className="col-2">Add Comment</Col>
                                        <Col>
                                            <Input type="text" required minLength="2" maxLength="250" value={this.state.comment} onChange={(e) => this.setState({ comment: e.target.value })}></Input>
                                        </Col>
                                        <Col className=" col-2 text-right">
                                            <Button className="btn btn-sm btn-host " onClick={(e) => this.addComment(e)} >
                                                <i className="fas fa-plus" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <hr className="py-1" />
                                    {this.state.client.comments && this.state.client.comments.length > 0 ?
                                        <div>
                                            {this.state.client.comments.map((el, k) =>
                                                <Row>
                                                    <Col>
                                                        <li key={k}>

                                                            {el.comment}
                                                        </li>
                                                    </Col>
                                                    <Col className="col-2"> {el.createdAt}</Col>
                                                </Row>
                                                
                                            )}
                                        </div>
                                        : ''}
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(ClientDetails);