import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Collapse } from 'reactstrap';
import { getAPI } from "../../../Base/API";
import { CustomTable } from '../../../Base/Common/CustomTable';
import BlockUi from 'react-block-ui';
import { handleNotification } from '../../../Base/Notification';

class PriceReports extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            collapseOpen: false,
            priceReports: null,
            size: 10
        }
    }

    componentDidMount() {
    }

    getPriceReport = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let priceReports = null;

                if (data.rootElement && data.rootElement.error) {
                    handleNotification({ errors: [{ code: data.rootElement.error.code, message: data.rootElement.error.message }] });
                }
                else if (data.rootElement && data.rootElement.perItineraryPrices) {
                    //group by check-in date
                    priceReports = data.rootElement.perItineraryPrices.reduce((acc, cur) => {
                        const curDate = `${cur.checkinDate.year}-${cur.checkinDate.month}-${cur.checkinDate.day}`;

                        const dateIdx = acc && acc.findIndex(el => el.date === curDate);

                        if (dateIdx > -1) {
                            acc[dateIdx].perItineraryPrices.push(cur);
                        }
                        else {
                            acc.push({ date: curDate, perItineraryPrices: [cur] })
                        }

                        return acc;
                    }, [])
                }

                this.setState({ collapseOpen: true, priceReports, block: false });
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/hotel/MetaSearch/v1/google/price?hotelId=${this.props.hotelId}`);

    }

    toggleCollapse = () => {
        this.setState(prevState => ({
            collapseOpen: !prevState.collapseOpen
        }), () => {
            if (this.state.collapseOpen && !this.state.priceReports) {
                this.getPriceReport();
            }
        });
    }

    render() {
        const { priceReports, collapseOpen } = this.state;

        const columns = [
            {
                dataField: 'date',
                text: "Check-in",
                sort: true, headerStyle: { width: '5%'}
            },
            {
                dataField: 'LengthOfStayDays',
                text: this.props.intl.formatMessage({ id: "Google.LengthOfStayDays" }),
                sort: true, headerStyle: { width: '15%', textAlign: 'center' }
            },
            {
                dataField: 'Price',
                text: this.props.intl.formatMessage({ id: "Google.Price" }),
                sort: true, headerStyle: { width: '15%', textAlign: 'center' }
            },
            {
                dataField: 'Taxes',
                text: this.props.intl.formatMessage({ id: "Google.Taxes" }),
                sort: true, headerStyle: { width: '15%', textAlign: 'center' }
            },
            {
                dataField: 'Fees',
                text: this.props.intl.formatMessage({ id: "Google.Fees" }),
                sort: true, headerStyle: { width: '15%', textAlign: 'center' }
            },
            {
                dataField: 'CurrencyCode',
                text: this.props.intl.formatMessage({ id: "Google.CurrencyCode" }),
                sort: true, headerStyle: { width: '15%', textAlign: 'center' }
            },
            {
                dataField: 'UpdateTime',
                text: this.props.intl.formatMessage({ id: "Google.UpdateTime" }),
                sort: true, headerStyle: { width: '15%', textAlign: 'center' }
            }
        ];

        const expandRow = {
            onlyOneExpanding: true,
            showExpandColumn: true,
            renderer: row => (
                row.perItineraryPrices && row.perItineraryPrices.map((el, idx) =>
                    <div key={idx} className="d-flex">
                        <div style={{ width: '10%' }}></div>
                        <div style={{ width: '15%', textAlign: 'center' }}> {el.lengthOfStayDays}</div>
                        <div style={{ width: '15%', textAlign: 'center' }}> {el.price}</div>
                        <div style={{ width: '15%', textAlign: 'center' }}> {el.taxes}</div>
                        <div style={{ width: '15%', textAlign: 'center' }}> {el.fees}</div>
                        <div style={{ width: '15%', textAlign: 'center' }}> {el.currencyCode}</div>
                        <div style={{ width: '15%', textAlign: 'center' }}> {el.updateTime}</div>
                    </div>
                )
            )
        };

        return (
            <BlockUi tag="div" blocking={this.state.block} className="px-4 mt-3">
                <div className="mb-2 pointer" onClick={this.toggleCollapse}>
                    {collapseOpen ? <i className="fas fa-angle-down" /> : <i className="fas fa-angle-right" />}
                    <b> Price Report </b>
                </div>

                {collapseOpen ?
                    <Collapse isOpen={collapseOpen}>
                        <CustomTable
                            data={priceReports ? priceReports : []}
                            keyField={'date'}
                            columns={columns}
                            sizePerPage={10}
                            shadow={false}
                            exportCSV={false}
                            hideSizePerPage={false}
                            showTotal={true}
                            expandRow={expandRow}
                            search={true}
                        />
                    </Collapse>
                : ''}
            </BlockUi>
        );
    }

};
export default injectIntl(PriceReports)