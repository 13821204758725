import React, { Component } from 'react';
import { Row, Col, Button, Card, Form } from 'reactstrap';
import { StyledCard, SelectHotel } from '../../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../../Base/Notification";
import { FormattedMessage, injectIntl } from "react-intl";
import { getAPI, postAPI, deleteAPI } from '../../../Base/API';
import ClearAvailabilityModal from './ClearAvailabilityModal';
import CreatableSelect from 'react-select/creatable';


export class ConfigCategoriesAndAvailability extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hotelId: null,
            clearAvailModal: false
        }
    }


    getRoomCategories = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }

            if (data) {
                var roomCategories = [];

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    roomCategories = data.response.map(r => {
                        return {
                            value: r.id,
                            label: `${r.code} (${r.description})`,
                            code: r.code,
                            description: r.description,
                            pmsId: r.pmsId,
                            deleted: r.deleted
                        }
                    });
                }

                this.setState({ block: false, roomCategories, error: [] }, this.getPMSRoomCategories);
            }
            else {
                this.setState({ block: false, roomCategories: [], error: [] });
            }
        }, `/api/hotel/RoomCategory/v1/Info?hotelId=${this.state.hotelId}`);
    }

    softDeleteRoomCategories = (roomCategory) => {
        this.setState({ block: true }, () =>

            deleteAPI(result => {
                const { data, error } = result;
                if (error) {
                    const errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }

                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    this.setState({ error: [] }, this.getRoomCategories);
                }

            }, `/api/hotel/RoomCategory/v1/softDelete/${roomCategory}?hotelId=${this.state.hotelId}`));
        }

    getPMSRoomCategories = () => {
        this.setState({ blockPms: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockPms: false });
                return;
            }
            if (data) {
                var pmsRoomCategories = [];

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.response && data.response.length > 0) {
                    pmsRoomCategories = data.response.map(r => {
                        return {
                            value: r.id.toString(),
                            label: r.categoryCode,
                            isDisabled: r.isUsed
                        }
                    });
                }

                this.setState({ pmsRoomCategories, blockPms: false })
            }
            else {
                this.setState({ error: errorMessage, blockPms: false });
            }
        }, `/api/hotel/v1/pmsInvokeAction/${this.state.hotelId}/roomCategories`);
    }

    associatePmsId = (roomCategoryId, pmsId, index) => {

        if (pmsId && isNaN(parseInt(pmsId))) {
            var data = { response: null, errors: [{ code: "400", message: <FormattedMessage id="ConfigRoomCatRate.NewPmsIdErr" /> }], warnings: [] }
            handleNotification(data)
            return;
        }

        const request = {
            request: {
                roomCategoryPmsIds: [
                    {
                        roomCategoryId: roomCategoryId,
                        pmsId: pmsId
                    }
                ]
            }
        };

        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);

                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.warnings && data.warnings.length > 0) {
                    handleNotification(data);

                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    const { roomCategories } = this.state;

                    if (data.warnings && data.warnings.length > 0) {
                        handleNotification(data);
                        handleNotification({} , <FormattedMessage id="ConfigCategoriesAndAvailability.PmsIdAssociated" />, <FormattedMessage id="General.Success" />);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="ConfigCategoriesAndAvailability.PmsIdAssociated" />, <FormattedMessage id="General.Success" />);
                    }

                    roomCategories[index].pmsId = pmsId;

                    this.setState({ error: errorMessage, block: false, roomCategories });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false })
            }
        }, `/api/hotel/RoomCategory/v1/roomCategory/PostPmsWithRoomCategoryAsync`, request);
    }

    doSearch = (e) => {
        if (e) e.preventDefault();


        this.setState({ block: true }, () => this.getRoomCategories());
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    render() {
        const { roomCategories, pmsRoomCategories, clearAvailModal } = this.state;

        return (
            <StyledCard icon={'fas fa-bed'} title={'navbar.ConfigCategoriesAndAvailability'} error={this.state.error} block={this.state.block}>
                {clearAvailModal ?
                    <ClearAvailabilityModal
                        modal={clearAvailModal}
                        toggleModal={() => this.toggleModal("clearAvailModal")}
                        roomCategories={roomCategories}
                    />
                : ''}

                <Form onSubmit={this.doSearch} className="pr-2">
                    <Row className="mb-3">
                        <Col className="col-4">
                            <SelectHotel
                                name='hotelId'
                                icon='fas fa-hotel'
                                onChangeFunc={this.handleChangeSelect}
                                placeholder={<FormattedMessage id="generic.Hotel" />}
                                value={this.state.hotelId}
                            />
                        </Col>
                        <Col className="text-right">
                            {this.state.hotelId ?
                                <Button className="btn btn-host btn-sm mr-2" onClick={() => this.toggleModal("clearAvailModal")} title="Clear Availability">
                                    <span className="fas fa-broom" />
                                </Button>
                            : ''}

                            <Button className="btn btn-host btn-sm ml-1" type="submit">
                                <span className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {roomCategories && roomCategories.length > 0 ?
                    <>
                        <div className="pr-2">
                            <Card body className="mb-3 shadow py-2 text-muted ">
                                <Row>
                                    <Col> <FormattedMessage id="ConfigCategoriesAndAvailability.RoomCategoryCode" /></Col>
                                    <Col sm={6}> <FormattedMessage id="RoomCategorySetupDetails.Description" /> </Col>
                                    <Col> PMS Id</Col>
                                    <Col className="col-1 text-center "> <FormattedMessage id="generic.Status" /> </Col>
                                    <Col></Col>
                                </Row>
                            </Card>
                        </div>

                        <div className="scrollableDiv pr-2" style={{ maxHeight: '62vh', overflowY: 'auto' }}>
                            {roomCategories.map((cat, key) =>            
                                <Card body className="mb-2 py-2 shadow overbglight" key={key}>
                                    <Row key={key} className="align-items-center">
                                        <Col> {cat.code} </Col>
                                        <Col sm={6}> {cat.description} </Col>
                                        <Col>
                                            <CreatableSelect
                                                isClearable
                                                onChange={(combo) => this.associatePmsId(cat.value, combo && combo.value, key)}
                                                onInputChange={this.handleInputChange}
                                                placeholder={<FormattedMessage id="ConfigRoomCatRate.SelectPmsLabel" />}
                                                value={cat.pmsId ? pmsRoomCategories?.find(rc => rc.value == cat.pmsId) || { value: cat.pmsId, label: cat.pmsId } : ''}
                                                isLoading={this.state.blockPms}
                                                options={pmsRoomCategories}
                                            />
                                        </Col>
                                        <Col className="col-1 d-flex justify-content-center">
                                            {cat.pmsId ?
                                                <span className="d-flex align-items-center justify-content-center" style={{ backgroundColor: 'green', height: '25px', width: '25px', color: 'white', borderRadius: '20px' }}>
                                                    <i className="fas fa-check fa-xs" />
                                                </span>
                                                :
                                                <span className="d-flex align-items-center justify-content-center" style={{ backgroundColor: 'red', height: '25px', width: '25px', color: 'white', borderRadius: '20px' }}>
                                                    <i className="fas fa-times fa-xs" />
                                                </span>
                                            }
                                        </Col>
                                        <Col>
                                            <Button color="btn-host btn-sm float-right mx-1" onClick={() => this.softDeleteRoomCategories(cat.value)}>
                                                <i className="fas fa-trash-alt"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>                            
                            )}
                        </div>
                    </>
                : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(ConfigCategoriesAndAvailability);