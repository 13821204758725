import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import CustomSelect from '../../Base/Common/CustomSelect';
import { getAPI } from '../../Base/API';
import { CustomTable } from '../../Base/Common/CustomTable';
import { getCurrencyCode } from '../../Base/Common/ReferenceDataFunctions';

class CurrencyConversion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            baseCurrency: null,
            baseCurrencyValue: 0,
            baseCurrencies: [],
            targetCurrency: null,
            targetCurrencyValue: 0,
            targetCurrencyRate: null,
            targetCurrencies: {},
            data: []
        };
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {

                const baseCurrencies = data
                    .response
                    .reduce((acc, curr) => {
                        if (!acc.some(crr => crr === curr.baseCurrency)) {
                            acc.push(curr.baseCurrency);
                        }
                        return acc;
                    }, [])
                    .map(resp => {
                        const currencyCode = getCurrencyCode().find(crr => crr.value === resp);
                        const label = currencyCode ? currencyCode.label : resp;

                        const opt = {
                            value: resp,
                            label: label
                        }

                        return opt;
                    });

                const targetCurrencies = data
                    .response
                    .reduce((acc, curr) => {
                        const currencyCode = getCurrencyCode().find(crr => crr.value === curr.targetCurrency);
                        const label = currencyCode ? currencyCode.label : curr.targetCurrency;
                        const opt = {
                            value: curr.targetCurrency,
                            label: label,
                            rate: curr.rate
                        };

                        if (Object.keys(acc).some(k => k === curr.baseCurrency)) {
                            acc[curr.baseCurrency].push(opt);
                        }
                        else {
                            
                            acc[curr.baseCurrency] = [opt];
                        }
                        return acc;
                    }, {});
                    
                data.response.forEach(resp => {
                    const baseCurrencyCode = getCurrencyCode().find(crr => crr.value === resp.baseCurrency);
                    resp.baseCurrencyLabel = baseCurrencyCode ? baseCurrencyCode.label : resp.baseCurrency;

                    const targetCurrencyCode = getCurrencyCode().find(crr => crr.value === resp.targetCurrency);
                    resp.targetCurrencyLabel = targetCurrencyCode ? targetCurrencyCode.label : resp.targetCurrency;
                })

                this.setState({ error: errorMessage, block: false, baseCurrencies, targetCurrencies, data: data.response });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/Price/Price/Currency/v1/Conversion');
    }

    handleBaseCurrency = (combo) => {
        this.setState({ baseCurrency: combo.value, targetCurrencyRate: null, targetCurrency: null, targetCurrencyValue: 0 });
    }

    handleBaseCurrencyValue = (evt) => {
        if (evt && evt.target) {
            const { name, value } = evt.target;
            const { targetCurrencyRate } = this.state;
            if (targetCurrencyRate !== null) {
                const targetCurrencyValue = (targetCurrencyRate * value).toFixed(4);

                this.setState({ [name]: value, targetCurrencyValue });
            }
            else {
                this.setState({ [name]: value });
            }
        }
    }

    handleTargetCurrency = (combo) => {
        const { baseCurrency, baseCurrencyValue, targetCurrencies } = this.state;

        const targetCurrencyOpt = targetCurrencies[baseCurrency].find(crr => crr.value === combo.value);
        if (targetCurrencyOpt) {
            const targetCurrencyValue = (targetCurrencyOpt.rate * baseCurrencyValue).toFixed(4);

            this.setState({ targetCurrency: combo.value, targetCurrencyValue, targetCurrencyRate: targetCurrencyOpt.rate });
        }
        else {
            this.setState({ targetCurrency: combo.value });
        }
    }

    render() {
        const { block, error, baseCurrencies, baseCurrency, baseCurrencyValue, data, targetCurrencies, targetCurrency, targetCurrencyValue } = this.state;

        const columns = [
            {
                dataField: 'baseCurrencyLabel',
                text: this.props.intl.formatMessage({ id: "CurrencyConversion.BaseCurrency" })
            },
            {
                dataField: 'targetCurrencyLabel',
                text: this.props.intl.formatMessage({ id: "CurrencyConversion.TargetCurrency" })
            },
            {
                dataField: 'rate',
                text: this.props.intl.formatMessage({ id: "CurrencyConversion.Rate" })
            },
            {
                dataField: 'date',
                text: this.props.intl.formatMessage({ id: "CurrencyConversion.Date" })
            }
        ]

        return (
            <StyledCard block={block} error={error} title="navbar.CurrencyConversion">
                <Row className="mb-3">
                    <Col>
                        <CustomSelect options={baseCurrencies} onChange={this.handleBaseCurrency} value={baseCurrencies.filter(bs => bs.value === baseCurrency)} />
                    </Col>
                    <Col>
                        <Input text="number" id="baseCurrencyValue" name="baseCurrencyValue" onChange={this.handleBaseCurrencyValue} value={baseCurrencyValue} />
                    </Col>
                    <Col className="col-1" style={{ display: 'inline-grid', alignItems: 'center', textAlign: 'center'}}>
                        <i className="fas fa-arrow-right" />
                    </Col>
                    <Col>
                        {
                            baseCurrency ?
                                <CustomSelect options={targetCurrencies[baseCurrency]} onChange={this.handleTargetCurrency} value={targetCurrencies[baseCurrency].filter(bs => bs.value === targetCurrency)} />
                                :
                                <CustomSelect isDisabled={true} />
                        }
                    </Col>
                    <Col>
                        <Input text="number" id="targetCurrencyValue" name="targetCurrencyValue" value={targetCurrencyValue} readOnly />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomTable
                            data={data}
                            columns={columns}
                            search={true}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(CurrencyConversion);