import React, { Component } from 'react';
import { Row, Col, Card } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from "../Base/API"
import  ReservationDetail  from "./ReservationDetail"
import 'react-block-ui/style.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from "moment";
import { CustomTable } from '../Base/Common/CustomTable'
import { FormatAmountNumber, ReservationStatusBadge, StyledCard, getCommunicationStatus } from '../Base/Common/CommonUIComponents';
import { getConvertedDateToHotelTimezone, getChannelConfig } from '../Base/Common/ReferenceDataFunctions';
import ReservationListSearch from './ReservationListSearch';
import ReservationListSearchMobile from './ReservationListSearchMobile';


class ReservationList extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.clearFilter = this.clearFilter.bind(this);
        this.changeSurname = this.changeSurname.bind(this);
        this.changeReservationId = this.changeReservationId.bind(this);
        this.changeSource = this.changeSource.bind(this);
        this.submitSearch = this.submitSearch.bind(this);
        this.submitRequest = this.submitRequest.bind(this);
        this.form = React.createRef();
        this.state = {
            data: {},
            error: null,
            block: false,
            channelinstanceList: [],
            reservationid: '',
            source: '',
            surname: '',
            startDate: moment().add(-1, 'year'),
            endDate: null,
            checkInStartDate: null,
            checkInEndDate: null,
            lastUpdateStartDate: null,
            includedDate: null,
            lastUpdateEndDate: null,
            channelInstanceId: [],
            channelStatus: '',
            communicationStatus: '',
            modal: false,
            modalData: {},
            channelData: [],
            reservationStatusList: [
                {
                    'value': 'Created',
                    'label': <FormattedMessage id="ReservationList.Created" />
                },
                {
                    'value': 'Modified',
                    'label': <FormattedMessage id="ReservationList.Modified" />
                },
                {
                    'value': 'Canceled',
                    'label': <FormattedMessage id="ReservationList.Cancel" />
                },
                {
                    'value': 'Pending',
                    'label': <FormattedMessage id="ReservationList.Pending" />
                }
            ],
            communicationStatusList: getCommunicationStatus(),  
            resultsPerPage: 10,
            currentPage: 1,
            totalResults: 0,
            searchText: null,
            roomCategoryId: null,
            lastDigits: null,
            hasMappings: null,
            isNone: global.operationMode === 'None',
            isReservationDeliveryNone: global.reservationDelivery === 'None',
            url: `/api/Price/Reservation/v1/Reservations`,
            urlToExtractFile: `/api/Price/Reservation/v1/Reservations/File`,
            searchCollapsed: true,
            isMobile: false,
        }
    }

    toggleModal(item) {

        if (item != null) {
            this.setState(prevState => ({
                modalData: item, modal: !prevState.modal
            }));
        } else {
            this.setState(prevState => ({
                modalData: {}, modal: !prevState.modal
            }));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidMount() {
        this._isMounted = true;
        this.submitRequest(this.state.url);
        window.setTimeout(
            () => {
                this.setState({ isNone: global.operationMode === 'None', isReservationDeliveryNone: global.reservationDelivery === 'None' });
            },
            3000
        );

        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        const screenWidth = document.documentElement.clientWidth || document.body.clientWidth;
        this.setState({ isMobile: screenWidth <= 991});
    }

    submitRequest(url, cb) {
        const { startDate, endDate, checkInStartDate, checkInEndDate, lastUpdateStartDate, includedDate, lastUpdateEndDate, surname, roomCategoryId } = this.state;

        const reservationListDateRangePicker = document.getElementById('your_unique_start_date_id');
        const hasDates = (startDate && endDate) || (checkInStartDate && checkInEndDate) || (lastUpdateStartDate && lastUpdateEndDate) || (includedDate);
        if ((surname !== '' || roomCategoryId) && !hasDates) {
            reservationListDateRangePicker.setCustomValidity(this.props.intl.formatMessage({ id: "ReservationList.FilterWithoutDates" }));
        }
        else {
            reservationListDateRangePicker.setCustomValidity("");
        }

        if (!this.form.current.reportValidity()) {
            if (cb) {
                cb();
            }
            return;
        }

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if(data.errors && data.errors.length > 0){
                this.setState({ error: data.errors.map((error) => ({ message: error.message, stack: error.stack, messageType: 'danger' })), block: false });
            }
            else if (data && data.data) {
                this.setState({ error: errorMessage, block: false, channelData: data.data, totalResults: data.count }, () => {
                    if (cb) {
                        cb();
                    }
                });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `${url}?${this.getQueryString()}`);
    }

    getQueryString = () => {
        const { resultsPerPage, currentPage, startDate, endDate, checkInStartDate, checkInEndDate, lastUpdateStartDate, includedDate, lastUpdateEndDate, surname, channelInstanceId, reservationid, communicationStatus, channelStatus, roomCategoryId, source, hasMappings } = this.state;

        const startDateFltr = startDate ? `&startDate=${startDate.format('YYYY-MM-DD')}` : ``;
        const endDateFltr = endDate ? `&endDate=${endDate.format('YYYY-MM-DD')}` : ``;
        const checkInStartDateFltr = checkInStartDate ? `&checkInStartDate=${checkInStartDate.format('YYYY-MM-DD')}` : ``;
        const checkInEndDateFltr = checkInEndDate ? `&checkInEndDate=${checkInEndDate.format('YYYY-MM-DD')}` : ``;
        const lastUpdateStartDateFltr = lastUpdateStartDate ? `&lastUpdateStartDate=${lastUpdateStartDate.format('YYYY-MM-DD')}` : ``;
        const includedDateFltr = includedDate ? `&includedDate=${includedDate.format('YYYY-MM-DD')}` : ``;
        const lastUpdateEndDateFltr = lastUpdateEndDate ? `&lastUpdateEndDate=${lastUpdateEndDate.format('YYYY-MM-DD')}` : ``;
        const surnameFltr = surname !== '' ? `&surname=${surname}` : ``;
        const channelInstanceIdFltr = channelInstanceId.length > 0 ? `${channelInstanceId.reduce((acc, curr) => `${acc}&channelInstanceId=${curr}`, ``)}` : ``;
        const reservationidFltr = reservationid !== '' ? `&reservationid=${reservationid}` : ``;
        const communicationStatusFltr = communicationStatus !== '' ? `&pmsSendStatus=${communicationStatus}` : ``;
        const channelStatusFltr = channelStatus !== '' ? `&reservStatus=${channelStatus}` : ``;
        const roomCategoryIdFltr = roomCategoryId ? `&roomCategoryId=${roomCategoryId}` : ``;
        const sourceFltr = source ? `&source=${source}` : ``;
        const hasMappingsFltr = hasMappings !== null ? `&hasMappings=${hasMappings}` : ``;

        return `pageSize=${resultsPerPage}&pageIndex=${currentPage - 1}${startDateFltr}${endDateFltr}${checkInStartDateFltr}${checkInEndDateFltr}${includedDateFltr}${lastUpdateStartDateFltr}${lastUpdateEndDateFltr}${surnameFltr}${channelInstanceIdFltr}${reservationidFltr}${communicationStatusFltr}${channelStatusFltr}${roomCategoryIdFltr}${sourceFltr}${hasMappingsFltr}`;
    }

    handleTableChange = (_, { page, sizePerPage, searchText }) => {
        const { currentPage, resultsPerPage, url } = this.state;
        if (page === currentPage && sizePerPage === resultsPerPage) {
            this.setState({ searchText: searchText });
        }
        else {
            this.setState({ currentPage: page, resultsPerPage: sizePerPage, block: true }, _ => this.submitRequest(url) );
        }
    }

    clearFilter() {
        this.setState({ channelInstanceId: '', reservationid: '', surname: '', startDate: moment().subtract(15, 'days'), endDate: moment() });
    }

    submitSearch() {
        this.submitRequest(this.state.url);
    }

    changeSurname(evt) {
        this.setState({ surname: evt.target.value });
    }

    changeSource(evt) {
        this.setState({ source: evt.target.value });
    }

    changeReservationId(evt) {
        this.setState({ reservationid: evt.target.value });
    }

    setComboStatus = (evt, inputValue, actionMeta) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value);
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    updateTable = (data) => {
        this.setState({ block: true });

        var list = [...this.state.channelData];
        const index = this.state.channelData && this.state.channelData.indexOf(this.state.channelData.find(r => r.id === data.id));

        list[index] = data;

        this.setState({ block: false, channelData: list });
    }

    getReservationDetail = row => {
        this.setState({ block: true })
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.data?.channelConfigName === 'HeyBE') {
                    this.setState({ modalData: data.data }, this.getPayment);
                }
                else {
                    this.setState({ block: false, modal: true, modalData: data.data, lastDigits: data.data?.lastDigits});
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Price/Reservation/v1/${row.id}`);
    }

    getPayment = () => {
        const { modalData } = this.state;
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                modalData.payments = data.response;
                modalData.payments.forEach(pmt => {
                    if (!pmt.reservationPaymentRefund) {
                        pmt.reservationPaymentRefund = [];
                    }
                    pmt.reservationPaymentRefund.forEach(rfd => {
                        rfd.createdAt = getConvertedDateToHotelTimezone(rfd.createdAt).format("YYYY-MM-DD HH:mm:ss");
                    });
                });
                this.setState({ block: false, modal: true, modalData });
            }
            else {
                this.setState({ block: false, modal: true });
            }
        }, `/api/Price/Payment/v1/reservations?resIdValue=${modalData.resIdValue}`);
    }

    handleOnClick(cb) {
        const { currentPage, resultsPerPage, url, urlToExtractFile } = this.state;
        const totalResults = 1000;

        this.setState({ currentPage: 1, resultsPerPage: totalResults }, () => this.submitRequest(urlToExtractFile, () => cb(() => this.setState({ currentPage: currentPage, resultsPerPage: resultsPerPage }, () => this.submitRequest(url)))));
    }

    toggleCollapse = () => {
        this.setState(prevState => ({
            searchCollapsed: !prevState.searchCollapsed
        }));
    }

    changeDates = (startDate, endDate) => {
        this.setState({ startDate, endDate })
    }

    changeCheckInDates = (startDate, endDate) => {
        this.setState({ checkInStartDate: startDate, checkInEndDate: endDate })
    }

    changeLastUpdateDates = (startDate, endDate) => {
        this.setState({ lastUpdateStartDate: startDate, lastUpdateEndDate: endDate })
    }

    changeIncludedDate = (date) => {
        this.setState({ includedDate: date })
    }

    doSearch = () => {
        this.setState({ currentPage: 1 }, this.submitSearch)
    }

    render() {
        const { isNone, isReservationDeliveryNone, searchCollapsed, isMobile } = this.state;
        const hidePmsData = isNone && isReservationDeliveryNone;

        const columnsBase = [
            {
                dataField: 'resIdValue',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationId" }),           
                sort: true
            },
            {
                dataField: 'channelInstanceName',
                text: this.props.intl.formatMessage({ id: "ReservationList.Channel" }),
                sort: true,
                formatter: (cell, row) => {
                    const channelData = getChannelConfig(row.channelCode);

                    return <span>
                        {channelData && channelData.image ? <img alt={cell} src={channelData.image} height={channelData.iconSize}></img> : ''}
                        {row.channelInstanceName} {row.propertyCode ? '(' + row.propertyCode + ')' : ''}
                        {this.state.isNone && !row.hasMappings ? <i style={{ position: 'relative' }} className="fas fa-exclamation-triangle text-warning" /> : <span />}
                    </span>
                },
                csvFormatter: (cell, row, rowIndex) => `${row.channelInstanceName} ${row.propertyCode ? '(' + row.propertyCode + ')' : ''}`
            },
            {
                dataField: 'source',
                text: this.props.intl.formatMessage({ id: "ReservationList.Source" }),
                sort: true,
            },
            {
                dataField: 'resIdDate',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationDate" }),
                formatter: (cell, row) => cell ? <div>{moment(cell).format("YYYY-MM-DD HH:mm:ss")}</div> : '',
                sort: true,
                formatter: (cell) => cell ? getConvertedDateToHotelTimezone(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            },
            {
                dataField: 'checkIn',
                text: this.props.intl.formatMessage({ id: "ReservationList.checkIn" }),
                formatter: (cell) => <div>{cell ? moment(cell).format("YYYY-MM-DD") : ''}</div>,
                sort: true,
                csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'numberOfNights',
                text: this.props.intl.formatMessage({ id: "ReservationList.numNights" }),
                sort: true
            },
            {
                dataField: 'numberOfRooms',
                text: this.props.intl.formatMessage({ id: "ReservationList.numRooms" }),
                sort: true
            },
            {
                dataField: 'guestCount',
                text: this.props.intl.formatMessage({ id: "ReservationList.NumberOfGuests" }),
                sort: true
            },
            {
                dataField: 'surname',
                text: this.props.intl.formatMessage({ id: "ReservationList.Surname" }),
                sort: true
            },
            {
                dataField: 'amountAfterTax',
                text: this.props.intl.formatMessage({ id: "ReservationList.Amount" }),
                formatter: (cell, row) => cell !== null ?
                    <span className="d-flex align-items-center">
                        <FormatAmountNumber value={cell} currency={row.currencyCode ? row.currencyCode : global.hotelCurrency} />
                        {row.lastDigits ? <i className="far fa-credit-card ml-1" /> : ''}
                    </span>
                    : '',
                sort: true
            },
            {
                dataField: 'amountCommission',
                text: this.props.intl.formatMessage({ id: "ReservationList.Commission" }),
                formatter: (cell, row) => cell !== null ? <FormatAmountNumber value={cell} currency={row.currencyCode ? row.currencyCode : global.hotelCurrency} /> : '',
                sort: true
            },
            {
                dataField: 'cancelFee',
                text: this.props.intl.formatMessage({ id: "ReservationList.cancelFee" }),
                formatter: (cell, row) => cell !== null ? <FormatAmountNumber value={cell} currency={row.currencyCode ? row.currencyCode : global.hotelCurrency} /> : '',
                sort: true
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "ReservationList.Status" }),
                formatter: (cell) => <ReservationStatusBadge status={cell}></ReservationStatusBadge>,
                sort: true
            }
        ];

        const columnsPms = [
            {
                dataField: 'communicationStatus',
                text: this.props.intl.formatMessage({ id: "ReservationList.communicationStatus" }),
                sort: true,
                formatter: (cell) => {
                    const status = this.state.communicationStatusList.find(cs => cs.value === cell);
                    return status?.icon || '';
                }
            },
            {
                dataField: 'sentToPmsAt',
                text: this.props.intl.formatMessage({ id: "ReservationList.sentToPmsAt" }),
                sort: true,
                formatter: (cell) => cell ? getConvertedDateToHotelTimezone(cell).format("YYYY-MM-DD HH:mm:ss") : '',
            }
        ];

        const columns = hidePmsData ? columnsBase : columnsBase.concat(columnsPms);

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: this.getReservationDetail
        };

        const helpContent = (
            <div>
                <b>
                    <FormattedMessage id="ReservationList.Reservation" />
                </b>
                <hr />
                <FormattedMessage id="ReservationList.ResesrvationHelp" />
                <hr />
                <FormattedMessage id="ReservationList.ResesrvationHelp1" />
                <br />
                <i className="text-success fas fa-check"></i>
                <FormattedMessage id="ReservationList.ResesrvationHelp2" />
                <br />
                <i className="text-warning far fa-hourglass"></i>
                <FormattedMessage id="ReservationList.ResesrvationHelp3" />
                <br />
                <i className="text-danger fas fa-times"></i>
                <FormattedMessage id="ReservationList.ResesrvationHelp4" />
                <br />
                <i className="fas fa-exclamation-triangle" style={{ color: "#fc7f43" }}></i>
                <FormattedMessage id="ReservationList.ResesrvationHelp5" />
                {
                    this.state.isNone ?
                        <div>
                            <hr />
                            <i className="fas fa-exclamation-triangle text-warning"></i>
                            <FormattedMessage id="ReservationList.ResesrvationHelpHasMappings" />
                        </div>
                        :
                        <div />
                }
            </div>
        );

        let result = this.state.channelData;

        const columnsToSearch = [
            'resIdValue',
            'channelInstanceName',
            'resIdDate',
            'guestCount',
            'amountAfterTax',
            'amountCommission',
            'cancelFee',
            'status',
            'communicationStatus',
            'sentToPmsAt'
        ];

        if (this.state.searchText) {
            result = result.filter(row => {
                let valid = false;
                for (const dataField in columnsToSearch) {
                    valid = row[columnsToSearch[dataField]] ? row[columnsToSearch[dataField]].toString().includes(this.state.searchText) : false;
                    if (valid) break;
                }
                return valid;
            });
        }

        const hasMappingsOptions = [
            {
                value: true,
                label: <FormattedMessage id="generic.yes" />
            },
            {
                value: false,
                label: <FormattedMessage id="generic.no" />
            }
        ];

        const screenWidth = document.documentElement.clientWidth || document.body.clientWidth;

        return (

            <div>
                {this.state.modal ?
                    <ReservationDetail
                        modal={this.state.modal}
                        data={this.state.modalData}
                        action={this.toggleModal.bind(this)}
                        updateTable={this.updateTable}
                        rateCodes={this.state.rateCodes}
                        history={this.props.history}
                        lastDigits={this.state.lastDigits}
                    />
                :''}
                <StyledCard block={this.state.block} error={this.state.error} icon={'far fa-calendar-alt'} title={'ReservationList.Reservation'} help={helpContent} id="ReservationList">
                    {!isMobile ? 
                        <ReservationListSearch 
                            form={this.form}
                            reservationid={this.state.reservationid}
                            changeReservationId={this.changeReservationId}
                            source={this.state.source}
                            changeSource={this.changeSource}
                            surname={this.state.surname}
                            changeSurname={this.changeSurname}
                            channelInstanceId={this.state.channelInstanceId}
                            setComboStatus={this.setComboStatus}
                            reservationStatusList={this.state.reservationStatusList}
                            communicationStatusList={this.state.communicationStatusList}
                            doSearch={this.doSearch}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            changeDates={this.changeDates}
                            checkInStartDate={this.state.checkInStartDate}
                            checkInEndDate={this.state.checkInEndDate}
                            changeCheckInDates={this.changeCheckInDates}
                            lastUpdateStartDate={this.state.lastUpdateStartDate}
                            lastUpdateEndDate={this.state.lastUpdateEndDate}
                            changeLastUpdateDates={this.changeLastUpdateDates}
                            includedDate={this.state.includedDate}
                            changeIncludedDate={this.changeIncludedDate}
                            isNone={this.state.isNone}
                            hasMappingsOptions={hasMappingsOptions}
                            history={this.props.history}
                        />

                        :

                        <ReservationListSearchMobile 
                            form={this.form}
                            reservationid={this.state.reservationid}
                            changeReservationId={this.changeReservationId}
                            source={this.state.source}
                            changeSource={this.changeSource}
                            surname={this.state.surname}
                            changeSurname={this.changeSurname}
                            channelInstanceId={this.state.channelInstanceId}
                            setComboStatus={this.setComboStatus}
                            reservationStatusList={this.state.reservationStatusList}
                            communicationStatusList={this.state.communicationStatusList}
                            doSearch={this.doSearch}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            changeDates={this.changeDates}
                            checkInStartDate={this.state.checkInStartDate}
                            checkInEndDate={this.state.checkInEndDate}
                            changeCheckInDates={this.changeCheckInDates}
                            lastUpdateStartDate={this.state.lastUpdateStartDate}
                            lastUpdateEndDate={this.state.lastUpdateEndDate}
                            changeLastUpdateDates={this.changeLastUpdateDates}
                            includedDate={this.state.includedDate}
                            changeIncludedDate={this.changeIncludedDate}
                            isNone={this.state.isNone}
                            hasMappingsOptions={hasMappingsOptions}
                            searchCollapsed={searchCollapsed}
                            toggleCollapse={this.toggleCollapse}
                            history={this.props.history}
                        />
                    }

                    <hr className="border-host" />

                    <Row>
                        <Col className="col-12">
                            <Card className=" border-0 ">
                                <CustomTable
                                    data={result}
                                    columns={columns}
                                    page={this.state.currentPage}
                                    sizePerPage={this.state.resultsPerPage}
                                    totalSize={this.state.totalResults}
                                    onTableChange={this.handleTableChange}
                                    shadow={false}
                                    exportCSV={true}
                                    selectRow={selectRow}
                                    search={true}
                                    remote={true}
                                    showTotal={screenWidth > 600 ? true : false}
                                    hideSizePerPage={isMobile ? true : false}
                                    customExportCsv={true}
                                    handleOnClick={this.handleOnClick.bind(this)}
                                    tooltipMessage={<FormattedMessage id="generic.table.customexportcsv" values={{ value: '1000' }} />}
                                />
                            </Card>                            
                        </Col>                       
                    </Row>
                </StyledCard> 
            </div>
        );
    }
}

export default injectIntl(ReservationList)