import React, { Component } from 'react';
import {
    Modal, ModalBody, ModalHeader, Row, Col, Input, Button
} from 'reactstrap';
import { getAPI, postAPI } from '../../Base/API';
import { StyledCard, SelectHotel } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Notification";
import { CustomTable } from '../../Base/Common/CustomTable';
import  CustomSelect  from '../../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl } from "react-intl";
import { getisLocalPms, getOperationModes } from "../../Base/Common/ReferenceDataFunctions";

export class HotelBulkLoad extends Component {
    constructor(props) {
        super(props);

        this.form = React.createRef();

        this.state = {
            block: false,
            hotelList: [],
            productList: [],
        }
    }


    componentDidMount() {
        this.getLicenses();
        this.getProducts();
    }

    

    getProducts() {
        this.setState({ block: true });


        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ productList: data.value, block: false });
            }
        }, '/api/User/APIGateway/v1/Products');

    }

    getProductList() {
        let arr = [];
        this.state.productList.map((el, k) =>
            arr.push({ label: el.properties.displayName, value: el.id }))
        return arr;
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    getLicenses() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const licenses = data.licenses.map(item => {
                    const license = {
                        value: item.id,
                        label: item.name,
                        numChannels: item.numChannels,
                        numRooms: item.numRooms,
                        licenseLevel: item.licenseLevel
                    };
                    return license;
                })
                this.setState({ block: false, licenses: licenses });
            }
        }, '/api/hotel/License/v1/license');
    }
   

    handleFieldChange = (name, combo) => {
        const value = combo ? combo.value : null;
        this.setState(({ [name]: value }));
    }

    fileChanged = (e) => {
        e.target.value = null;
    }

    getAsText = (fileToRead) => {
        var reader = new FileReader();

        reader.readAsText(fileToRead);

        reader.onload = (e) => {
            var csv = e.target.result;
            this.processData(csv);
        }
        reader.onerror = this.errorHandler;
    }

    processData = (csv) => {
        var allTextLines = csv.split(/\r\n|\n/);
        var lines = allTextLines.map(data => data.split(/;/));
        var list = [];
        if (lines.find(l => l.length !== 6)) {
            var errorMessage = [];
            errorMessage.push({ message: <FormattedMessage id="ImportHotelConfig.MustHave7Fields" />, messageType: 'danger' });
            this.setState({ error: errorMessage, block: false });
        }
        else {
            let i = 0;
            lines.forEach((line) => {
                var object = {};

                object.comercialName = line[0];
                object.fiscalName = line[1];
                object.fiscalNumber = line[2];
                object.hotelGroupId = line[3];
                object.groupName = line[4];
                object.hotelId = line[5];
                
                if (i != 0) list.push(object);
                i++;
            })

            this.setState({
                hotelList: list,
                hotelData: allTextLines,
                error: null
            })
        }
    }

    handleFiles = (event) => {
        var files = event.target.files;

        if (window.FileReader) {
            this.getAsText(files[0]);
        }
        else {
            var errorMessage = [];
            errorMessage.push({ message: "FileReader are not supported in this browser.", messageType: 'danger' });
            this.setState({ error: errorMessage });
        }
    }

    saveBulkHotels(e) {
        e.preventDefault();


        const subscriptionName = document.getElementById('file');

        if (this.state.hotelList == null || this.state.hotelList.length === 0) {
            subscriptionName.setCustomValidity(this.props.intl.formatMessage({ id: "ApiGatewayUserDetails.InvalidSubscriptionName" }));
        }
        else {
            subscriptionName.setCustomValidity("");
        }

        if (this.form.current.reportValidity()) {
            this.setState({ block : true})
            let req = {
                "hotel": this.state.hotelList,
                "operationMode": this.state.operationMode,
                "isLocalPms": this.state.isLocalPms,
                "licenseId": this.state.licenseId,
                "subscription": this.state.subs
            }

            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="HotelBulkLoad.HotelLoaded" />, <FormattedMessage id="ApiGatewayUserDetails.Success" />);
                    this.setState({ response: data.value, block: false });
                }
            }, '/api/hotel/v1/CreateHotelBulk',req);

            
        }
    }

    render() {
        const columns = [
            {
                dataField: 'fiscalName',
                text: this.props.intl.formatMessage({ id: "HotelBulkLoad.FiscalName" }),
                sort: true
            },
            {
                dataField: 'comercialName',
                text: this.props.intl.formatMessage({ id: "HotelBulkLoad.ComercialName" }),
                sort: true
            },
            {
                dataField: 'fiscalNumber',
                text: this.props.intl.formatMessage({ id: "HotelBulkLoad.FiscalNumber" }),
                sort: true
            },
            
            {
                dataField: 'hotelGroupId',
                text: this.props.intl.formatMessage({ id: "HotelBulkLoad.HotelGroupId" }),
                sort: true
            },
            {
                dataField: 'groupName',
                text: this.props.intl.formatMessage({ id: "HotelBulkLoad.HotelGroupName" }),
                sort: true
            },
            {
                dataField: 'hotelId',
                text: this.props.intl.formatMessage({ id: "HotelBulkLoad.HotelId" }),
                sort: true
            }
        ]

       

        return (
            <StyledCard icon={'fas fa-file-upload'} title={'HotelBulkLoad.title'} help={'TODO'} error={this.state.error} block={this.state.block}>

                <form ref={this.form}>
                    <Row className="mb-2">
                        <Col className="text-right">
                            <Button type="submit" className="btn btn-sm btn-host" onClick={this.saveBulkHotels.bind(this)}>
                                <i className="fas fa-save" />
                            </Button>

                        </Col>
                    </Row>
                <Row className="mb-2">
                    <Col>
                        <CustomSelect name={'operationMode'} required options={getOperationModes()} icon={'fas fa-wrench'} onChange={this.handleFieldChange.bind(this, 'operationMode')} placeholder={<FormattedMessage id="HotelLicenses.OperationMode" />} isClearable />
                    </Col>
                    <Col>
                        <CustomSelect name={'isLocalPms'} required  options={getisLocalPms()} icon={'fas fa-check'} onChange={this.handleFieldChange.bind(this, 'isLocalPms')} placeholder={<FormattedMessage id="HotelLicenses.IsLocalPms" />} isClearable />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <CustomSelect name={'licenseId'} required  options={this.state.licenses} icon={'fas fa-file-alt'} onChange={this.handleFieldChange.bind(this, 'licenseId')} placeholder={<FormattedMessage id="HotelLicenses.License" />} isClearable />
                    </Col>
                    <Col>
                        <CustomSelect required options={this.getProductList()} isClearable value={this.getProductList().find(el => el.value === this.state.reason)} onChange={this.handleFieldChange.bind(this, 'subs')} name="scope" placeholder={<FormattedMessage id="ApiGatewayUserDetails.Type" />} />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                            <Input
                                id="file"
                            type="file"
                            onChange={(e) => this.handleFiles(e)}
                            accept=".csv"
                            required
                            onClick={(e) => this.fileChanged(e)}
                        />
                    </Col>
                    <Col className="text-right">
                    </Col>

                   
                    </Row>
                </form>
                <hr className="border-host" />
                <Row>
                    <Col className="col-12">
                        <CustomTable
                            data={this.state.hotelList ? this.state.hotelList : []}
                            columns={columns}
                            shadow={false}
                            exportCSV={false}
                            search={false}
                            remote={false}
                            keyField={'hotelId'}
                            showTotal={true}
                        />
                    </Col>
                </Row>

               
            </StyledCard>
        );
    }
}

export default injectIntl(HotelBulkLoad);