import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeader } from 'reactstrap';
import PropertyDescription from '../Common/PropertyDescription';
import { CommonHelper } from '../../../Base/Common/CommonUIComponents';


export class RoomCategoryDescription extends Component {

    state = {

    };

    render() {

        const catDescToolbar = {
            options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
        };


        return (
            <div>
                {this.props.selectedEntity ?
                    <PropertyDescription
                        selectedEntity={this.props.selectedEntity}
                        entity={'RoomCategory'}
                        url={this.props.url}
                        customToolbar={catDescToolbar}>
                    </PropertyDescription>
                    :
                    ''
                }
            </div>
        );
    }
}