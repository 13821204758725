import React, { Component } from 'react';
import { Col, Button, Modal } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CustomTable } from '../../Base/Common/CustomTable';
import { Label } from 'reactstrap';
import { CommonHelper } from '../../Base/Common/CommonUIComponents';
import BlockUi from 'react-block-ui';
import CustomSelect from '../../Base/Common/CustomSelect';
import CustomToolTip from '../../../Utils/CustomToolTip';

class FileQuestionsModal extends Component {

    downloadCSV = () => {
        const data = [
            "Question 1, Answer 1",
            "Question 2, Answer 2",
            "Question 3, Answer 3",
            "Question 4, Answer 4",
            "Question 5, Answer 5",
            "Question 6, Answer 6",
            "Question 7, Answer 7",
            "Question 8, Answer 8",
            "Question 9, Answer 9",
            "Question 10, Answer 10"
        ].join('\n');

        const blob = new Blob([data], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'FAQsExample.csv';
        link.click();

        URL.revokeObjectURL(url);
    }

    render() {
        const { fileQuestionsModal, columnsFileQuestion, fileQuestions, block, error, fileMessagesType, questionsOptions } = this.props;
        return (
            <Modal isOpen={fileQuestionsModal} fade={false} className={`xxl ${global.isMobile ? 'm-3' : ''}`}>
                <BlockUi tag="div" blocking={block}>
                    <Button onClick={() => this.props.toggleFileQuestionsModal()} className="closeModal">
                        <i className="fas fa-times fa-sm"></i>
                    </Button>
                    {global.isMobile ?
                        <>
                            <div className='d-flex justify-content-between p-3'>
                                <div style={{ maxWidth: '50%' }}>
                                    <h4>
                                        <FormattedMessage id="HeyTravelAI.ImportedCsv" />
                                    </h4>
                                </div>
                                <div style={{ maxWidth: '50%' }}>
                                    <Button id="DownloadCSV" className="btn-sm btn-host mr-2" onClick={() => this.downloadCSV()}>
                                        <i className="fas fa-file-csv"></i>
                                    </Button>
                                    <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} placement={'bottom'} target={"DownloadCSV"}>
                                        <FormattedMessage id="HeyTravelAI.DownloadCSV"/>
                                    </CustomToolTip>
                                    <Button disabled={error || !fileMessagesType} className="btn-sm btn-host" onClick={() => this.props.saveNewMessage()}>
                                        <i className="fas fa-save" />
                                    </Button>
                                    <CommonHelper help={<FormattedMessage id="HeyTravelAI.HelpSendCSV" />} id={'HelpSendCSV'} />
                                </div>
                            </div>
                            <div className='px-3 pb-3'>
                                <Label className='d-flex align-items-center' for="FileMessageType">
                                    <FormattedMessage id="HeyTravelAI.Type" />
                                </Label>
                                <div className='px-0' id="FileMessageType">
                                    <CustomSelect
                                        options={questionsOptions}
                                        placeholder={'FAQ / Review'}
                                        onChange={(e) => this.props.handleSelect('fileMessagesType', e)}
                                        value={questionsOptions && questionsOptions.find(c => c.value === fileMessagesType)}
                                        isDisabled={error}
                                    />
                                </div>
                            </div>
                        </>
                    :
                        <div className='d-flex align-items-center justify-content-between mt-3 mx-3'>
                            <div style={{ width: '14%' }}>
                                <Label className='d-flex align-items-center' for="FileMessageType">
                                    <FormattedMessage id="HeyTravelAI.Type" />
                                </Label>
                                <div className='px-0' id="FileMessageType">
                                    <CustomSelect
                                        options={questionsOptions}
                                        placeholder={'FAQ / Review'}
                                        onChange={(e) => this.props.handleSelect('fileMessagesType', e)}
                                        value={questionsOptions && questionsOptions.find(c => c.value === fileMessagesType)}
                                        isDisabled={error}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '23%' }}>
                                <h2>
                                    <FormattedMessage id="HeyTravelAI.ImportedCsv" />
                                </h2>
                            </div>
                            <div>
                                <Button id="DownloadCSV" className="btn-sm btn-host mr-2" onClick={() => this.downloadCSV()}>
                                    <i className="fas fa-file-csv"></i>
                                </Button>
                                <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} placement={'bottom'} target={"DownloadCSV"}>
                                    <FormattedMessage id="HeyTravelAI.DownloadCSV"/>
                                </CustomToolTip>
                                <Button disabled={error || !fileMessagesType} className="btn-sm btn-host" onClick={() => this.props.saveNewMessage()}>
                                    <i className="fas fa-save" />
                                </Button>
                                <CommonHelper help={<FormattedMessage id="HeyTravelAI.HelpSendCSV" />} id={'HelpSendCSV'} />
                            </div>
                        </div>
                    }
                    <div className="my-3 px-3">
                        <CustomTable
                            data={fileQuestions}
                            columns={columnsFileQuestion}
                            shadow={false}
                            sizePerPage={20}
                        />
                    </div>
                </BlockUi>
            </Modal>
        )
    }
}

export default injectIntl(FileQuestionsModal);