import React, { Component } from 'react';
import { Row, Col, Button, Card, Form, Modal, Label, CustomInput, ModalBody } from 'reactstrap';
import { handleNotification } from "../../../Base/Notification";
import { FormattedMessage, injectIntl } from "react-intl";
import { deleteAPI, postAPI, putAPI } from '../../../Base/API';
import { ErrorAlert } from '../../../Common/ErrorAlert';
import BlockUi from 'react-block-ui';
import { ConfirmActionModal } from '../../../Base/Common/CommonUIComponents';
import CustomSelect from '../../../Base/Common/CustomSelect';


export class ClearAvailabilityModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            confirmationModal: false,
            selectedCategories: []
        }
    }

    clearAvailability = () => {
        this.setState({ block: true });

        const body = this.state.selectedCategories.map(el => el.value);

        putAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ClearAvailabilityModal.AvailabilityRemoved"/>, <FormattedMessage id="General.Success" />);
                }

                this.setState({ feedback: data.response, block: false, confirmationModal: false });
            }
            else this.setState({ block: false, confirmationModal: false });
        }, '/api/hotel/RoomCategory/v1/availability', body);
    }
    
    handleSelect = (combo) => {
        this.setState({
            selectedCategories: combo || []
        });
    }

    handleAllCategories = (areAllSelected) => {
        let { selectedCategories } = this.state;

        if (areAllSelected) {
            selectedCategories = [];
        }
        else {
            selectedCategories = this.props.roomCategories;
        }

        this.setState({ selectedCategories });
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    render() {
        const { roomCategories, toggleModal, modal } = this.props;
        const { confirmationModal, selectedCategories, feedback } = this.state;

        const areAllSelected = selectedCategories.length === roomCategories.length;

        return (
            <div>
                {confirmationModal ? 
                    <ConfirmActionModal
                        block={this.state.block}
                        error={this.state.error}
                        text={this.props.intl.formatMessage({ id: "ClearAvailabilityModal.ClearConfirmationText" })}
                        toggleModal={() => this.toggleModal("confirmationModal")}
                        modal={confirmationModal}
                        actionFunction={this.clearAvailability}
                    />
                : ''}

                <Modal isOpen={modal} toggle={toggleModal} fade={false} className="modal-lg" style={{ minWidth: 0 }}>
                    <ModalBody>
                        <BlockUi tag="div" blocking={this.state.block}>
                            <ErrorAlert error={this.state.error} />

                            <Row className="mb-3">
                                <Col><h5><FormattedMessage id="ClearAvailabilityModal.ClearAvailability" /></h5></Col>
                                <Col className="text-right">
                                    <Button className="btn btn-host btn-sm" onClick={() => this.toggleModal("confirmationModal")}>
                                        <span className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>

                            <div className="mb-2"><FormattedMessage id="ClearAvailabilityModal.SelectRoomCategories" /></div>
                            <Row>
                                <Col>
                                    <CustomSelect
                                        options={roomCategories}
                                        isSearchable isMulti
                                        value={selectedCategories || ''}
                                        placeholder=""
                                        onChange={(combo) => this.handleSelect(combo)}
                                    />
                                </Col>
                                <Col className="col-2 text-right pl-0 pt-1">
                                    <Button className="btn btn-host btn-sm mr-2" onClick={() => this.handleAllCategories(areAllSelected)}>
                                        {areAllSelected ? <FormattedMessage id="generic.RemoveAll" /> : <FormattedMessage id="generic.SelectAll" />}
                                    </Button>
                                </Col>
                            </Row>

                            {feedback ? 
                                <div>
                                    <div className="mt-4">
                                        <b><FormattedMessage id="ClearAvailabilityModal.Details" />:</b>
                                    </div>

                                    <Row className="mt-3 mb-2 text-muted">
                                        <Col> <FormattedMessage id="ClearAvailabilityModal.Room" /> </Col>
                                        <Col className="col-2 pl-0 text-center"> <FormattedMessage id="ClearAvailabilityModal.TotalRemoved" /> </Col>
                                    </Row>

                                    {feedback.map((rc, key) => {
                                        const room = roomCategories.find(el => el.value === rc.roomCategoryId)

                                        return <Row className="overbglight py-1" key={key}>
                                            <Col> {room ? room.label : rc.roomCategoryId} </Col>
                                            <Col className="col-2 pl-0 text-center"> {rc.affectedRows} </Col>
                                        </Row>
                                    })}
                                </div> 
                            : ''}

                        </BlockUi>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ClearAvailabilityModal);