import React, { Component } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from 'moment';
import { Pagination } from '../../../Base/Common/CommonUIComponents';

export class BookingConnectionsList extends Component {

    render() {
        const { children, changePropertyId, cursors, disablePropertyIdFilter, getSearchConnections, handleNextButton, handlePrevButton, page, startDate, startDateId, endDate, endDateId, onDatesChange, focusedInput, onFocusChange, propertyId, total } = this.props;

        return (
            <div>
                <Row>
                    <Col className="col-3">
                        <FormattedMessage id="BookingConnections.PropertyId">
                            {
                                placeholder => <Input className="border-left-0" type="text" name="propertyId" id="propertyId" placeholder={placeholder} value={propertyId} onChange={changePropertyId} disabled={disablePropertyIdFilter} />
                            }
                        </FormattedMessage>
                    </Col>
                    <Col className="col-3">
                        <DateRangePicker
                            startDate={startDate ? moment(startDate).locale(moment.locale()) : startDate}
                            startDateId={startDateId}
                            isOutsideRange={day => day > moment()}
                            endDate={endDate ? moment(endDate).locale(moment.locale()) : endDate}
                            endDateId={endDateId}
                            focusedInput={focusedInput}
                            onFocusChange={onFocusChange}
                            small={true}
                            numberOfMonths={2}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            onDatesChange={onDatesChange}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-sm btn-host" onClick={getSearchConnections}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                <hr />
                {
                    children
                }
                <Pagination
                    isPrevDisabled={page === 1}
                    handlePrevButton={handlePrevButton}
                    currentPage={page}
                    isNextDisabled={cursors[page] === null}
                    handleNextButton={handleNextButton}
                    total={total}
                />
            </div>
        );
    }
}

export default injectIntl(BookingConnectionsList);