import React, { Component } from 'react';
import { Button, Col, Label, Form, FormGroup, Input, Modal, ModalBody } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { BlankCard } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';
import { putAPI } from '../../Base/API';
import { ErrorAlert } from "../../Common/ErrorAlert";

class AiPromptEditModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            prePrompt: {
                id: this.props.selectedPrompt?.id,
                name: this.props.selectedPrompt?.name,
                type: this.props.selectedPrompt?.type,
                description: this.props.selectedPrompt?.description,
                question: this.props.selectedPrompt?.question,
                context: this.props.selectedPrompt?.context
            }
        }
    }
    createEditWhiteList = (e) => {
        e.preventDefault();
        const { prePrompt } = this.state;

        this.setState({ block: true }, () =>
            putAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="AiPrompt.PromptSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                        this.props.toggleModal();
                    }
                }
            }, `/api/hotel/OpenAI/v1/PromptConfig`, { request: prePrompt }));

    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            prePrompt: {
                ...this.state.prePrompt,
                [name]: value
            }
        });
    }

    render() {
        const { isOpen, toggleModal } = this.props;
        const { block, error, prePrompt } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggleModal} fade={false} className="modal-medium">
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm" />
                </Button>
                <ErrorAlert error={error} />
                <ModalBody>
                    <BlankCard block={block}>
                        <Form onSubmit={this.createEditWhiteList}>
                            <FormGroup row>
                                <Col style={{ fontWeight: "500" }}>
                                    {prePrompt.id ? <FormattedMessage id="AiPrompt.EditPrompt" /> : <FormattedMessage id="AiPrompt.CreatePrompt" />}
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6}>
                                    <Label><FormattedMessage id="AiPrompt.Name" /></Label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={prePrompt.name || ''}
                                        onChange={(e) => this.handleChange(e)}
                                        required
                                    />
                                </Col>
                                <Col sm={6}>
                                    <Label><FormattedMessage id="AiPrompt.Type" /></Label>
                                    <Input
                                        type="text"
                                        name="type"
                                        value={prePrompt.type || ''}
                                        onChange={(e) => this.handleChange(e)}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Label><FormattedMessage id="AiPrompt.Description" /></Label>
                                    <Input
                                        type="textarea"
                                        name="description"
                                        value={prePrompt.description || ''}
                                        rows={2}
                                        onChange={(e) => this.handleChange(e)}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Label><FormattedMessage id="AiPrompt.Context" /></Label>
                                    <Input
                                        type="textarea"
                                        name="context"
                                        rows={4}
                                        value={prePrompt.context || ''}
                                        onChange={(e) => this.handleChange(e)}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col>
                                    <Label><FormattedMessage id="AiPrompt.Question" /></Label>
                                    <Input
                                        type="textarea"
                                        name="question"
                                        rows={4}
                                        value={prePrompt.question || ''}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(AiPromptEditModal);