import React, { Component } from 'react';
import { Badge, Button, Col, Modal, ModalHeader, ModalBody, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import * as moment from 'moment';
import CustomSelect from '../Base/Common/CustomSelect';
import { getChannelsLoadDataFromFile, getChannelsReadOnly, getChannelsWithSingleRoomRestriction, getHasAvailabilityGroupMapping, getHasContract, getIsChannelReadOnly, getIsHostelWorld, getIsHrs, getIsJumboTours, getIsJuniper, getIsNotSapo, getPriceMode, getShowInterfaceMode4, hasImportListings, getInterfaceMode } from '../Base/Common/ReferenceDataFunctions';
import { getAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import { ImportListingsModal } from '../Channel/ImportListingsModal';
import ChannelMapping from '../Channel/ChannelMapping';
import classnames from 'classnames';

export class RemapReservationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            block: true,
            selectedChannel: {},
            importListingsModal: false,
            channelsWithSingleRoomRestriction: getChannelsWithSingleRoomRestriction(),
            channelsReadOnly: getChannelsReadOnly(),
            rateList: [],
            channelDataMap: [],
            commonData: [],
            map: [],
            ratecodechannel: [],
            isNotSapo: false,
            isHostelWorld: false,
            hasAvailabilityGroupMapping: false,
            hasContract: false,
            hasChannelData: false,
            isChannelReadOnly: false,
            isJuniper: false,
            isJumboTours: false,
            showChannelInfo: false,
            priceModes: [],
            isIcal: false,
            bookingEngines: [],
            showInterfaceMode4: false,
            isHrs: false,
            hasRoomLevelRestrictions: false,
			overrideUrls: false,
			interfaceModes: getInterfaceMode()
        };
    }

    componentDidMount() {
        const { channelInstanceId } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const selectedChannel = data.find(resp => resp.id === channelInstanceId);
                if (selectedChannel) {
                    const isNotSapo = selectedChannel ? getIsNotSapo(selectedChannel.code) : false;
                    const showInterfaceMode4 = getShowInterfaceMode4(selectedChannel.code);
                    const hasRoomLevelRestrictions = selectedChannel.hasRoomLevelRestrictions;

                    let interfaceModes = showInterfaceMode4 ? getInterfaceMode() : getInterfaceMode().filter(el => el.value !== 4);
                    interfaceModes = hasRoomLevelRestrictions ? interfaceModes : interfaceModes.filter(el => el.value !== 5);

                    this.setState({
                        error: errorMessage,
                        block: false,
                        selectedChannel: selectedChannel,
                        isNotSapo: isNotSapo,
                        isHostelWorld: getIsHostelWorld(selectedChannel.code),
                        hasAvailabilityGroupMapping: getHasAvailabilityGroupMapping(selectedChannel.code),
                        hasContract: getHasContract(selectedChannel.code),
                        hasChannelData: selectedChannel.hasChannelData,
                        isChannelReadOnly: getIsChannelReadOnly(selectedChannel.code),
                        isJuniper: getIsJuniper(selectedChannel.code),
                        isJumboTours: getIsJumboTours(selectedChannel.code),
                        showChannelInfo: isNotSapo && !selectedChannel.hasChannelData && !selectedChannel.isInternalChannel && selectedChannel.code !== 'GOOGLEHOTELADS',
                        showInterfaceMode4: showInterfaceMode4,
                        isHrs: getIsHrs(selectedChannel.code),
                        hasRoomLevelRestrictions: hasRoomLevelRestrictions,
                        interfaceModes: interfaceModes
                    }, this.getData);
                }
                else {
                    this.setState({
                        error: errorMessage,
                        block: false,
                        selectedChannel: selectedChannel,
                        isNotSapo: false,
                        isHostelWorld: false,
                        hasAvailabilityGroupMapping: false,
                        hasChannelData: false,
                        isChannelReadOnly: false,
                        isJuniper: false,
                        isJumboTours: false,
                        showChannelInfo: false,
                        isHrs: false
                    });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, "/api/Rate/ChannelConfig/v1/ChannelInstance");
    }

    importListings = () => {
        const { channelName } = this.props;

        if (getChannelsLoadDataFromFile().find(channelCode => channelCode === channelName) !== undefined) {
            this.toggleImportListingsModal();
        }
        else {
            this.refreshChannelData();
        }
    }

    toggleImportListingsModal() {
        this.setState(prevState => ({ importListingsModal: !prevState.importListingsModal }));
    }

    refreshChannelData() {
        const { channelInstanceId } = this.props;

        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="ChannelConf.ChannelDataRefreshed" />, <FormattedMessage id="ChannelConf.Success" />);
                this.setState({ block: false }, this.getData );
            }
            this.setState({ block: false });

        }, `/api/Rate/ChannelConfig/v1/ChannelInstance/${channelInstanceId}/ChannelData/Refresh`);
    }

    getData = () => {
        this.getRates();
        this.getCommonData();
        this.reloadMap();
        this.reloadChannelData();
        this.getChannelConfigState();
        this.getChannelConfig();
    }

    getRates = () => {
        const { selectedChannel, channelsWithSingleRoomRestriction, channelsReadOnly } = this.state;
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false }, this.getChannelData);
                return;
            }
            if (data) {
                const isChannelReadOnly = channelsReadOnly.find(c => c === selectedChannel.code) !== undefined;
                const isChannelsWithSingleRoomRestriction = channelsWithSingleRoomRestriction.find(c => c === selectedChannel.code) !== undefined;

                if (isChannelReadOnly || isChannelsWithSingleRoomRestriction) {
                    const isDisabledToAddForChannelReadOnly = () => { return true; }
                    const isDisabledToAddForChannelsWithSingleRoomRestriction = (rate) => { return rate.rateList && rate.rateList.find(r => r.mappingList.find(m => m.roomCode !== null) !== undefined) ? true : false; }
                    const isDisabledToAdd = isChannelReadOnly ? isDisabledToAddForChannelReadOnly : isDisabledToAddForChannelsWithSingleRoomRestriction;

                    const isDisabledToEditForChannelReadOnly = () => { return true; }
                    const isDisabledToEditForChannelsWithSingleRoomRestriction = () => { return false; }
                    const isDisabledToEdit = isChannelReadOnly ? isDisabledToEditForChannelReadOnly : isDisabledToEditForChannelsWithSingleRoomRestriction;

                    data.response && data.response.forEach(rate => {
                        rate.isDisabledToAdd = isDisabledToAdd(rate);
                        rate.isDisabledToEdit = isDisabledToEdit(rate);
                        return rate;
                    });
                }
                else {
                    data.response && data.response.forEach(rate => {
                        rate.isDisabledToAdd = false;
                        rate.isDisabledToEdit = false;
                        return rate;
                    });
                }
                this.setState({ rateList: data.response, block: false }, this.getChannelData);
            }
            else {
                this.setState({ block: false }, this.getChannelData);
            }
        }, `/api/Rate/Rate/v1/RateMapping/${selectedChannel.id}`);
    }

    getChannelData() {
        const { selectedChannel } = this.state;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage });
                return;
            }
            if (data && data.length > 0) {
                let channelDataMapAirbnb = [];
                if (selectedChannel.channelName.toUpperCase() === "AIRBNB") {
                    channelDataMapAirbnb = data.reduce((acc, curr) => {
                        const channelData = acc.find(item => item.roomCode === curr.roomCode);
                        if (channelData) {
                            const cd = {
                                rateCode: curr.rateCode,
                                rateDescription: curr.rateDescription,
                                active: curr.active
                            };
                            channelData.channelData.push(cd);
                        }
                        else {
                            const item = {
                                id: curr.id,
                                roomCode: curr.roomCode,
                                roomDescription: curr.roomDescription,
                                maxPersons: curr.maxPersons,
                                syncType: curr.syncType,
                                active: curr.active,
                                channelData: []
                            };
                            if (curr.roomCode !== curr.rateCode) {
                                const cd = {
                                    rateCode: curr.rateCode,
                                    rateDescription: curr.rateDescription,
                                    active: curr.active
                                };
                                item.channelData.push(cd);
                            }
                            acc.push(item);
                        }
                        return acc;
                    }, []);
                }
                data.forEach(d => {
                    if (d.occupancies) {
                        d.occupancies = d.occupancies.map(occ => {
                            const occs = occ.split('-');
                            const item = {
                                adults: parseInt(occs[0]),
                                childrens: parseInt(occs[1])
                            }
                            return item;
                        });
                        d.occupancies.sort((occ1, occ2) => {
                            const diff = occ1.adults - occ2.adults;
                            return diff === 0 ? occ1.childrens - occ2.childrens : diff;
                        });
                    }
                });
                this.setState({
                    channelDataMapAirbnb: channelDataMapAirbnb,
                    channelDataMap: data,
                    hasHotelBedsSpecific: data.find(cData => cData.hotelBedsSpecific !== undefined) !== undefined,
                    hasDates: data.find(cData => cData.startingDate !== null || cData.endingDate !== null) !== undefined
                });
            }
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance/${selectedChannel.id}/ChannelData`);
    }

    getCommonData = () => {
        const { selectedChannel } = this.state;
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                this.setState({ commonData: data });
            }
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance/${selectedChannel.id}/CommonData`);
    }

    reloadMap = () => {
        const { selectedChannel } = this.state;
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                this.setState({ map: data });
            }
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance/${selectedChannel.id}/Map`);
    }

    reloadChannelData = () => {
        const { intl } = this.props;
        const { selectedChannel, hasAvailabilityGroupMapping, hasContract } = this.state;
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                const availabilityGroupMappingText = `${hasAvailabilityGroupMapping ? ` ${intl.formatMessage({ id: "ChannelMapping.MealPlan" })}` : `${hasContract ? ` ${intl.formatMessage({ id: "ChannelMapping.Contract" })}` : ``}`}`
                const availabilityGroupMappingLabel = (agm) => `${availabilityGroupMappingText}${agm ? ` ${agm}` : ``}`;

                this.setState({
                    ratecodechannel: data.response ?
                        data.response.map(resp => {
                            resp.value = resp.id;
                            resp.label = `${intl.formatMessage({ id: "ReservationDetail.RateCodeLabel" }, { roomCode: resp.localRoomCode, roomTypeMapping: resp.roomTypeMapping, rateCode: resp.localRateCode, rateCodeMapping: resp.rateCodeMapping })}${availabilityGroupMappingLabel(resp.availabilityGroupMapping)}`;
                            return resp;
                        })
                        :
                        []
                });
            }
        }, `/api/Rate/RateCodeChannel/v1/ChannelInstance/${selectedChannel.id}`);
    }

    getChannelConfigState = () => {
        const { selectedChannel } = this.state;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });
                return;
            }
            if (data && data.response && data.response.priceModes) {
                this.setState({ priceModes: getPriceMode().filter(priceMode => data.response.priceModes.find(value => "" + priceMode.value === "" + value)) });
            }
            else {
                this.setState({ priceModes: [] });
            }
        }, `/api/Rate/ChannelConfig/v1/ChannelConfigState/${selectedChannel.channelConfigId}`);
    }

    getChannelConfig = () => {
        const { selectedChannel } = this.state;

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                const channelConfig = data.find(d => d.id === selectedChannel.channelConfigId);
                if (channelConfig) {
                    const { showChannelInfo } = this.state;
                    this.setState({ isIcal: channelConfig.isIcal, showChannelInfo: showChannelInfo && !channelConfig.isIcal });
                }
            }
        }, `/api/Rate/ChannelConfig/v1`);
    }

    render() {
        const { channelName, channelInstanceId, handleChangeMapping, isOpen, toggle, roomStays, roomStaysWithoutMappings, roomStaysToRemap, saveNewMappings, verifyMappings, showVerifyMappings } = this.props;
        const { activeTab, block, bookingEngines, channelDataMap, commonData, error, hasAvailabilityGroupMapping, hasChannelData, importListingsModal, isChannelReadOnly, isIcal, isJumboTours, isHostelWorld, isJuniper, isNotSapo, map, priceModes, ratecodechannel, rateList, selectedChannel, showChannelInfo, isHrs, showInterfaceMode4, hasRoomLevelRestrictions, interfaceModes } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="ReservationDetail.RemapReservation" />
                </ModalHeader>
                <ModalBody>
                    <BlankCard block={this.props.block || block} error={error}>
                        <ImportListingsModal modal={importListingsModal} toggleModal={this.toggleImportListingsModal} channelInstanceId={channelInstanceId} channel={channelName} />
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '1' })} onClick={_ => this.setState({ activeTab: '1' })}>
                                    <FormattedMessage id="ReservationDetail.Mappings" />
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === '2' })} onClick={_ => this.setState({ activeTab: '2' })}>
                                    <FormattedMessage id="ReservationDetail.ManualSelect" />
                                </NavLink>
                            </NavItem>
                            {hasImportListings(selectedChannel.code) ?
                                <span className="ml-auto text-nowrap border-top-0 border-left-0 border-right-0">
                                    <Button className="btn btn-host btn-sm" onClick={this.importListings}>
                                        <FormattedMessage id="ReservationDetail.ImportListings" />
                                    </Button>
                                </span>
                            : ''}
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                <Row className="mb-2">
                                    <Col>
                                        <h6>
                                            <FormattedMessage id="ReservationDetail.RoomStaysToMap" />
                                        </h6>
                                        {
                                            roomStays.map((rs, key) =>
                                                <div key={key} className="mb-2">
                                                    <span>
                                                        <span className="mr-1">
                                                            <FormattedMessage id="ReservationDetail.RoomStay" values={{ roomCode: rs.channelRoomTypeCode, rateCode: rs.hotelRateCode[0].ratePlanCode, checkIn: moment(rs.checkIn).format("YYYY-MM-DD"), checkOut: moment(rs.checkOut).format("YYYY-MM-DD") }} />
                                                        </span>
                                                        {
                                                            roomStaysWithoutMappings.find(rstr => rs.id === rstr.id) !== undefined ?
                                                                <Badge color="warning">
                                                                    <i className="fas fa-exclamation-triangle text-white" />
                                                                </Badge>
                                                                :
                                                                <Badge color="success">
                                                                    <i className="fas fa-check" />
                                                                </Badge>
                                                        }
                                                    </span>
                                                </div>
                                            )
                                        }
                                    </Col>
                                    {showVerifyMappings ? 
                                        <Col className="col-2 text-right">
                                            <Button className="btn btn-host btn-sm mr-1" onClick={verifyMappings}>
                                                <FormattedMessage id="ReservationDetail.VerifyMappings" />
                                            </Button>
                                        </Col>
                                        :''
                                    }
                                </Row>
                                <Row>
                                    <Col>
                                        <ChannelMapping
                                            classes="smallModal"
                                            selectedChannel={selectedChannel}
                                            ratecodechannel={ratecodechannel}
                                            rateList={rateList}
                                            channelsWithSingleRoomRestriction={getChannelsWithSingleRoomRestriction()}
                                            Map={map}
                                            channelDataMap={channelDataMap}
                                            commonData={commonData}
                                            refreshChannelData={this.refreshChannelData}
                                            priceModes={priceModes}
                                            isIcal={isIcal}
                                            hotelId={global.hotel.id}
                                            showChannelInfo={showChannelInfo}
                                            isNotSapo={isNotSapo}
                                            isHostelWorld={isHostelWorld}
                                            hasAvailabilityGroupMapping={hasAvailabilityGroupMapping}
                                            mapIncommingOffice={isJumboTours || isJuniper}
                                            hasChannelData={hasChannelData}
                                            reloadChannelData={this.reloadChannelData}
                                            getRates={this.getRates}
                                            bookingEngines={bookingEngines}
                                            channelsReadOnly={getChannelsReadOnly()}
                                            isChannelReadOnly={isChannelReadOnly}
                                            isJuniper={isJuniper}
                                            showInterfaceMode4={showInterfaceMode4}
                                            isHrs={isHrs}
                                            hasRoomLevelRestrictions={hasRoomLevelRestrictions}
                                            interfaceModes={interfaceModes}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tabId="2">
                                <Row className="mb-2">
                                    <Col className="text-right">
                                        <Button className="btn btn-host btn-sm mr-1" onClick={saveNewMappings}>
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            roomStaysToRemap.map((rs, key) => {
                                                const hotelRateCode = rs.hotelRateCode.reduce((acc, curr) => curr ? { rateCode: curr.ratePlanCode, rateCodeChannelId: curr.rateCodeChannelId } : acc, { ratePlanCode: null, rateCodeChannelId: null });

                                                return (
                                                    <Row key={key} className="mb-2">
                                                        <Col>
                                                            <FormattedMessage id="ReservationDetail.RoomStay" values={{ roomCode: rs.channelRoomTypeCode, rateCode: hotelRateCode.ratePlanCode, checkIn: moment(rs.checkIn).format("YYYY-MM-DD"), checkOut: moment(rs.checkOut).format("YYYY-MM-DD") }} />
                                                        </Col>
                                                        <Col className="col-5">
                                                            <CustomSelect options={ratecodechannel} value={ratecodechannel.find(rc => rc.id === hotelRateCode.rateCodeChannelId)} onChange={(combo, evt) => handleChangeMapping(combo, evt, key)} />
                                                        </Col>
                                                    </Row>
                                                );
                                            })
                                        }
                                    </Col>
                                </Row>
                            </TabPane>
                        </TabContent>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(RemapReservationModal);