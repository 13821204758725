import React, { Component } from 'react'
import BlockUi from 'react-block-ui'
import { FormattedMessage } from 'react-intl';
import { Input } from 'reactstrap';
import CustomSelect from '../Base/Common/CustomSelect';
import { CustomSingleDatePicker } from '../Base/Common/CustomReactDates';
import moment from 'moment';
import { FormatAmountNumber } from '../Base/Common/CommonUIComponents';
import { getCurrencyCode } from '../Base/Common/ReferenceDataFunctions';
import CustomToolTip from '../../Utils/CustomToolTip';

export default class EmailInput extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            supportedTypes: [ "text", "date", "combo", "currency", "occupation", "email", "phone", "number", "dailyPrices" ],
            isEditable: false,
            requiredFields: [ "surname", "dailyPrices", "occupation", "total", "amountAfterTax", "checkOut", "checkIn", "resIdValue", "state", "resIdDate" ]
        }
    }

    componentWillReceiveProps(nextProps) {

        if(nextProps.emailId !== this.props.emailId && this.state.isEditable) {
            this.setState({ isEditable: false });
        }else if(nextProps.isReadOnly && this.props.isReadOnly === false && !this.state.isEditable) {
            this.setState({ isEditable: true });
        }
    }

    toggleEditable = () => {
        this.setState({ isEditable: !this.state.isEditable }, () => {
            const input = document.getElementById(this.props.inputId);
            if(input)
                input.focus();
        });
    }

    render() {
        const { name, inputId, type, value, onChange, isReadOnly, editCurrency, isOutsideRange, currencyValue, roomKey, onChangeCurrency, calculate, guestKey, notCurrency, min, disabled, divengency,
            format = "DD-MM-YYYY", hasLabel = true, currency = 'EUR', orientation = 'end', isRequired = false, options = [], serviceKey, copyExtractedDatatoEffective, isRoomService, isReservationService, 
            activeContentTab, inherited } = this.props;
        const { block, supportedTypes, isEditable, requiredFields } = this.state;

        const isValid = supportedTypes.includes(type);

        const opened = ((!isReadOnly && requiredFields.includes(name)) || (isEditable));

        const editButton = (
            <div className={`hoverEdit ${opened ? 'selectedEdit': ''}`} onClick={this.toggleEditable}>
                <i className="far fa-edit"/>
            </div>
        );

        const copyDataButton = (
            <div id={`copyExtractedToEffective-${inputId}`} className={`hoverEdit mr-1`} onClick={_ => copyExtractedDatatoEffective(name, roomKey, guestKey, serviceKey, isRoomService, isReservationService)} style={{ cursor: 'pointer' }}>
                <i className="fas fa-share"></i>
                <CustomToolTip placement="top" target={`copyExtractedToEffective-${inputId}`} >
                    <FormattedMessage id="EmailReservation.GetExtractedField" />
                </CustomToolTip>
            </div>
        );

        const OccButton = ({ occ, roomKey, plus = false, onChange }) => (
            <div className='d-flex cursor-pointer align-items-center justify-content-center' style={{ width: '10px', height: '10px' }} onClick={_ => onChange(occ, plus, roomKey)}>
                {plus ? '+' : '-'}
            </div>
        );

        const orientationStart = orientation === "start";

        const currenciesOptions = getCurrencyCode();

        const handleRoomServiceChange = e => onChange(e, roomKey, serviceKey);
        const handleReservationServiceChange = e => onChange(e, serviceKey);
        const handleGuestChange = e => onChange(e, roomKey, guestKey);
        const handleChange = serviceKey !== null && serviceKey !== undefined 
            ? (isRoomService ? handleRoomServiceChange : handleReservationServiceChange)
            : handleGuestChange;
        
        return (
            <BlockUi blocking={block} tag={'div'} className='w-100'>
                <div className={`d-flex align-items-center w-100 ${hasLabel && orientationStart ? 'justify-content-end' : 'justify-content-between'}`}>
                    <div className='w-100'>
                        {hasLabel ?
                            <div className={`d-flex w-100 align-items-center justify-content-between flex-row${orientationStart ? '-reverse' : ''}`}>
                                <div className='text-muted d-flex align-items-center' style={{ fontSize: '0.9em' }}>
                                    <span className='position-relative'>
                                        <FormattedMessage id={`EmailReservation.${name}`}/>
                                        {!disabled && divengency ?
                                            <>
                                                <div id={`EmailReservation-${name}-${roomKey}-${guestKey}`} style={{
                                                    color: inherited ? '#003bff' : 'red',
                                                    borderRadius: '100%',
                                                    position: 'absolute',
                                                    top: '-8px',
                                                    right: '-8px',
                                                    fontSize: '1.5em',
                                                    cursor: 'default'
                                                }}>*</div>
                                                <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={`EmailReservation-${name}-${roomKey}-${guestKey}`}>
                                                    <FormattedMessage id={inherited ? "EmailReservation.InheritedDivengency" : "EmailReservation.Divengency"}/>
                                                </CustomToolTip>
                                            </>
                                        :''}
                                    </span>

                                    {!disabled && type === "dailyPrices" ?
                                        <div className={`hoverEdit ml-2`} onClick={_ => calculate(roomKey)}>
                                            <i className="fas fa-calculator"/>
                                        </div>
                                    :''}
                                </div>
                                {!disabled ?
                                    <div className={`d-flex align-items-center ${orientationStart ? 'mr-2' : 'ml-2'}`}>
                                        {editButton}
                                    </div>
                                :
                                    activeContentTab !== 'generatedData' ?
                                        <div className={`d-flex align-items-center ${orientationStart ? 'mr-2' : 'ml-2'}`}>
                                            {copyDataButton}
                                        </div>
                                    :''
                                }
                            </div>
                        :''}
                        <div className={`d-flex w-100 align-items-center ${hasLabel && orientationStart ? 'justify-content-end' : 'justify-content-between'}`}>
                            <div className={orientationStart ? 'text-right w-100' : 'w-100'}>
                                {isValid && opened && !disabled ?
                                    (type === 'text' || type === "email" || type === "phone" || type === 'number') ?
                                        <Input
                                            id={inputId}
                                            type={type}
                                            name={name}
                                            value={value}
                                            min={min}
                                            onChange={handleChange}
                                            required={isRequired}
                                        />
                                    : type === 'currency' && editCurrency ?
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='w-50 px-2'>
                                                <Input
                                                    type={type}
                                                    id={inputId}
                                                    value={value}
                                                    onChange={serviceKey !== null && serviceKey !== undefined ? isRoomService ? e => onChange(e, roomKey, serviceKey) : e => onChange(e, serviceKey) : e => onChange(e, roomKey)}
                                                    required={isRequired}
                                                    name={name}
                                                />
                                            </div>
                                            <div className='w-50 p'>
                                                <CustomSelect
                                                    isClearable={false}
                                                    placeholder={''}
                                                    onChange={onChangeCurrency}
                                                    value={currenciesOptions?.find(op => op.value === currencyValue)}
                                                    options={currenciesOptions.map(e => {e.label = e.value; return e})}
                                                    required={isRequired}
                                                />
                                            </div>
                                        </div>
                                    : type === 'combo' ?
                                        <div style={{ minWidth: '130px' }}>
                                            <CustomSelect
                                                isClearable={false}
                                                placeholder={''}
                                                id={inputId}
                                                onChange={serviceKey !== null && serviceKey !== undefined ? isRoomService ? e => onChange(e, name, roomKey, serviceKey) : e => onChange(e, name, serviceKey) : e => onChange(e, name, roomKey, guestKey)}
                                                value={options?.find(op => op.value === value)}
                                                options={options}   
                                                required={isRequired}
                                            />
                                        </div>
                                    : type === 'date' ?
                                        <CustomSingleDatePicker
                                            id={inputId}
                                            required={isRequired}
                                            showTodaysButton={true}
                                            isOutsideRange={isOutsideRange ? isOutsideRange : () => false}
                                            date={value ? moment(value) : null}
                                            onDateChange={e => onChange(e, name, roomKey, guestKey)}
                                            numberOfMonths={1}
                                        />
                                    : type === "occupation" ?
                                        <div className='w-100 d-flex aling-items-center justify-content-between flex-wrap'>
                                            <div className='d-flex aling-items-center justify-content-between mr-4'>
                                                <div className='mr-2'>
                                                    <FormattedMessage id={`EmailReservation.Adults`}/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    {OccButton({ occ: "adults", roomKey, onChange })}
                                                    <div className='mx-2'>
                                                        {value.adults??'0'}
                                                    </div>
                                                    {OccButton({ occ: "adults", roomKey, onChange, plus: true })}
                                                </div>
                                            </div>
                                            <div className='d-flex aling-items-center justify-content-between mr-4'>
                                                <div className='mr-2'>
                                                    <FormattedMessage id={`EmailReservation.Children`}/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    {OccButton({ occ: "children", roomKey, onChange })}
                                                    <div className='mx-2'>
                                                        {value.children??'0'}
                                                    </div>
                                                    {OccButton({ occ: "children", roomKey, onChange, plus: true })}
                                                </div>
                                            </div>
                                            <div className='d-flex aling-items-center justify-content-between'>
                                                <div className='mr-2'>
                                                    <FormattedMessage id={`EmailReservation.Infants`}/>
                                                </div>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    {OccButton({ occ: "infants", roomKey, onChange })}
                                                    <div className='mx-2'>
                                                        {value.infants??'0'}
                                                    </div>
                                                    {OccButton({ occ: "infants", roomKey, onChange, plus: true })}
                                                </div>
                                            </div>
                                        </div>
                                    : type === "dailyPrices" ?
                                        <div className='pr-2 w-100' style={{ overflow: 'auto', maxHeight: '100px' }}>
                                            {value?.map((price, k) =>
                                                <div key={k} style={{ borderTop: k ? '1px solid lightgray' : '' }} className='py-1 d-flex w-100 aling-items-center justify-content-between w-100'>
                                                    <div className='d-flex align-items-center'>
                                                        {moment(price.effectiveDate).format(format)}
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <Input
                                                            type={type}
                                                            name={name}
                                                            value={price.amountAfterTax}
                                                            onChange={e => onChange(e, roomKey, k)}
                                                            required={isRequired}
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    :''
                                :
                                    <>
                                        {((type === 'number' && !notCurrency) || type === 'currency') && value ?
                                            <div>
                                                <FormatAmountNumber value={value} style="currency" currency={currency}/>
                                            </div>
                                        : type === 'date' && value ?
                                            moment(value??undefined).locale(this.props.intl?.locale) && typeof moment(value??undefined).locale(this.props.intl?.locale) === 'object' ? 
                                                moment(value??undefined).locale(this.props.intl?.locale)?.format(format) 
                                            : 
                                                moment(value??undefined).format(format)
                                        : type === 'occupation' && value ?
                                            <div className='d-flex align-items-center'>
                                                <div className='d-flex align-items-center mr-1'>
                                                    <span className='mr-1'>
                                                        {value.adults}
                                                    </span>
                                                    <FormattedMessage id={`EmailReservation.Adults`}/>
                                                </div>
                                                {value.children ?
                                                    <div className='d-flex align-items-center mr-1'>
                                                        <span className='mr-1'>,</span>
                                                        <span className='mr-1'>
                                                            {value.children}
                                                        </span>
                                                        <FormattedMessage id={`EmailReservation.Children`}/>
                                                    </div>
                                                :''}
                                                {value.infants ?
                                                    <div className='d-flex align-items-center mr-1'>
                                                        <span className='mr-1'>,</span>
                                                        <span className='mr-1'>
                                                            {value.infants}
                                                        </span>
                                                        <FormattedMessage id={`EmailReservation.Infants`}/>
                                                    </div>
                                                :''}
                                            </div>
                                        : type === 'dailyPrices' && value ?
                                            <div className='pr-2 w-100' style={{ overflow: 'auto', maxHeight: '100px' }}>
                                                {value.map((price, k) =>
                                                    <div key={k} style={{ borderTop: k ? '1px solid lightgray' : '' }} className='py-1 d-flex aling-items-center justify-content-between w-100'>
                                                        <div>
                                                            {moment(price.effectiveDate).format(format)}
                                                        </div>
                                                        <div>
                                                            <FormatAmountNumber currency={currency} value={price.amountAfterTax}/>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        : type === 'combo' && value ?
                                            <div className='text-trucante'>
                                                {options.find(o => o.value === value)?.label??value}
                                            </div>
                                        : value ?
                                            <div className='text-trucante'>{value}</div>
                                        :'-'}
                                        {isRequired ?
                                            <div style={{ position: 'relative' }}>
                                                <input
                                                    tabIndex={-1}
                                                    autoComplete="off"
                                                    style={{
                                                        opacity: 0, 
                                                        width: "100%",
                                                        height: 0,
                                                        position: "absolute",
                                                        left: '0',
                                                        padding: '0'
                                                    }}
                                                    className="unplesantInput"
                                                    value={
                                                        type === "date" ?
                                                            value && moment(value).isValid() ?
                                                                value
                                                            : null
                                                        : type === "number" ?
                                                            value && (!isNaN(value) || !isNaN(parseFloat(value))) ?
                                                                value
                                                            : null
                                                        : value ?? null
                                                    }
                                                    onChange={() => { }}
                                                    onFocus={this.toggleEditable} 
                                                    required={true}
                                                />
                                            </div>
                                        :''}
                                    </>
                                }
                            </div>
                            {!hasLabel ?
                                <div className='ml-2'>
                                    {editButton}
                                </div>
                            :''}
                        </div>
                    </div>
                </div>
            </BlockUi>
        )
    }
}
