import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form } from 'reactstrap';
import { CustomTable } from '../../../Base/Common/CustomTable';
import { getAPI } from "../../../Base/API";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import PriceHistoryFilters from './PriceHistoryFilters';

class PriceHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            day: moment(),
            priceHistory: [],
            pageSize: 10,
            count: 0,
            pageIndex: 0
        }
    }

    componentDidMount() {
        if (!this.props.isAdminPage) {
            const rates = this.props.getRates();

            this.setState({ rateCodes: rates, rateCodeId: null });
        }
    }

    getPriceHistory = () => {
        this.setState({ block: true, priceHistory: [] });

        var params = '';
        if (this.state.hotelId) params += `&hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ block: false, priceHistory: data.response, count: data.count, error: [] });
            }
        }, `/api/User/v1/History/PriceHistory?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}&day=${moment(this.state.day).format("YYYY-MM-DD")}&rateCodeId=${this.state.rateCodeId}` + params);
    }

    handleChangeSelect = (name, combo) => {
        if (name === 'hotelId' && combo) {
            var rates = this.props.getRates(combo.value);

            this.setState({ rateCodes: rates, rateCodeId: null });
        }

        this.setState({
            [name]: combo ? combo.value : null
        });
    };
    
    handleDate = (date) => {
        this.setState({
            day: date
        })
    }


    render() {
        const { priceHistory, pageSize, block, rateCodes } = this.state;

        const priceColumns = [
            {
                dataField: 'pricelistId',
                text: <FormattedMessage id="EditRate.PriceList" />,
                sort: true
            },
            {
                dataField: 'packageId',
                text: <FormattedMessage id="PMSHistory.PackageId" />,
                sort: true
            },
            {
                dataField: 'roomCategoryId',
                text: <FormattedMessage id="PMSHistory.RoomCategory" />,
                sort: true
            },
            {
                dataField: 'mode',
                text: <FormattedMessage id="PMSHistory.Mode" />,
                sort: true
            },
            {
                dataField: 'p1',
                text: "P1",
                sort: true
            },
            {
                dataField: 'p2',
                text: "P2",
                sort: true
            },
            {
                dataField: 'p3',
                text: "P3",
                sort: true
            },
            {
                dataField: 'p4',
                text: "P4",
                sort: true
            },
            {
                dataField: 'p5',
                text: "P5",
                sort: true
            },
            {
                dataField: 'p6',
                text: "P6",
                sort: true
            },
            {
                dataField: 'p7',
                text: "P7",
                sort: true
            },
            {
                dataField: 'p8',
                text: "P8",
                sort: true
            },
            {
                dataField: 'p9',
                text: "P9",
                sort: true
            },
            {
                dataField: 'p10',
                text: "P10",
                sort: true
            },
            {
                dataField: 'd1',
                text: "D1",
                sort: true
            },
            {
                dataField: 'd2',
                text: "D2",
                sort: true
            },
            {
                dataField: 'd3',
                text: "D3",
                sort: true
            },
            {
                dataField: 'd4',
                text: "D4",
                sort: true
            },
            {
                dataField: 'd5',
                text: "D5",
                sort: true
            },
            {
                dataField: 'version',
                text: <FormattedMessage id="PMSHistory.Version" />,
                sort: true
            },
            {
                dataField: 'createdAt',
                text: <FormattedMessage id="PMSHistory.CreatedAt" />,
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            },
            {
                dataField: 'updatedAt',
                text: <FormattedMessage id="PMSHistory.UpdatedAt" />,
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            },
            {
                dataField: 'updatedBy',
                text: <FormattedMessage id="PMSHistory.UpdatedBy" />,
                sort: true
            },
            {
                dataField: 'pmsTimestamp',
                text: "Pms Timestamp",
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : '',
                hidden: this.props.isOpModeNone
            }
        ]

        return (
            <BlockUi tag="div" blocking={block}>
                <Form onSubmit={(e) => { e.preventDefault(); this.setState({ pageIndex: 0 }, () => this.getPriceHistory()); }}>
                    <PriceHistoryFilters
                        isAdminPage={this.props.isAdminPage}
                        hotelList={this.props.hotelList}
                        handleChangeSelect={this.handleChangeSelect}
                        rateCodes={rateCodes}
                        rateCodeId={this.state.rateCodeId}
                        hotelId={this.state.hotelId}
                        day={this.state.day}
                        handleDate={this.handleDate}
                    />
                </Form>

                <div className="my-3">
                    <CustomTable
                        keyField="version"
                        data={priceHistory}
                        columns={priceColumns}
                        shadow={false}
                        exportCSV={false}
                        showTotal={true}
                        remote={true}
                        search={false}
                        hideSizePerPage={true}
                        page={this.state.pageIndex + 1}
                        sizePerPage={pageSize}
                        totalSize={this.state.count}
                        onTableChange={(_, { page }) => { this.setState({ pageIndex: page - 1 }, () => this.getPriceHistory()) }}
                    />
                </div>
            </BlockUi>
        );
    }

};
export default injectIntl(PriceHistory);