import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../../../Base/Notification";
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { putAPI } from "../../../Base/API";
import BlockUi from 'react-block-ui';
import PackageComponentDetailDesk from './PackageComponentDetailDesk';
import PackageComponentDetailMobile from './PackageComponentDetailMobile';

export class PackageComponentDetail extends Component {
	constructor(props) {
		super(props);

		this.state = {
			block: false,
			packageComponent: this.props.packageComponent ? this.props.packageComponent : {},
		};
	}

	handleChangeSelect = (name, combo) => {
		this.setState({
			packageComponent: {
				...this.state.packageComponent,
				[name]: combo ? combo.value : null
			}
		});
	};

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({
			packageComponent: {
				...this.state.packageComponent,
				[name]: value
			}
		})
	}

	savePackageComponent = (e) => {
		e.preventDefault();
		this.setState({ block: true });
		var body = { request: { ...this.state.packageComponent } };
		body.request.packageId = body.request.packageId ? body.request.packageId : this.props.packageId;

		putAPI(result => {
			const { data, error } = result;
			var errorMessage = [];

			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0) {
					handleNotification(data);
					this.setState(({ block: false, error: errorMessage }));
					return;
				}
				if (data.response && data.response.length > 0) {
					handleNotification(data, <FormattedMessage id="PackageComponentDetail.PackageComponentSaved" />, <FormattedMessage id="General.Success" />);
					this.props.getPackageData();
					this.props.toggleModal();
				}
				this.setState(({ block: false }));
			}
		}, `/api/Rate/Package/v1/PackageComponent`, body);
	}

	render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} fade={false} className="modal-lg">
                <ModalHeader toggle={this.props.toggleModal}>
					{this.props.packageComponent ? <FormattedMessage id="PackageComponentDetail.EditPackageComponent" /> : <FormattedMessage id="PackageComponentDetail.CreatePackageComponent" /> }
                </ModalHeader>
                <ModalBody className="modal-lg p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
						{!global.isMobile ?
							<PackageComponentDetailDesk 
								savePackageComponent={this.savePackageComponent}
								handleChange={this.handleChange}
								packageComponent={this.state.packageComponent}
								handleChangeSelect={this.handleChangeSelect}
							/>
						:
							<PackageComponentDetailMobile 
								savePackageComponent={this.savePackageComponent}
								handleChange={this.handleChange}
								packageComponent={this.state.packageComponent}
								handleChangeSelect={this.handleChangeSelect}
							/>
						}
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
	}
}

export default injectIntl(PackageComponentDetail);