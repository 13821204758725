import React, { Component } from 'react';
import { Badge, Row, Col } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { BlankCard } from '../../../Base/Common/CommonUIComponents';
import { getAPI } from '../../../Base/API';
import { handleNotification } from '../../../Base/Notification';
import BookingConnectionsList from './BookingConnectionsList';

export class BookingDisconnetedConnectionsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            properties: [],
            page: 1,
            pageSize: 10,
            cursors: [null],
            totalItems: 0
        };
    }

    componentDidMount() {
        this.getConnections();
    }

    getConnections = () => {
        const { url } = this.props;
        const { cursors, page, pageSize, endDate, startDate } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (Array.isArray(data.data)) {
                    if (cursors[page] === undefined) {
                        if (data.meta.next_page) {
                            cursors.push(new URLSearchParams(new URL(data.meta.next_page).search).get("cursor"));
                        }
                        else {
                            cursors.push(null);
                        }
                    }
                    const totalItems = page === 1 ? (data.data.length + data.meta.next_count) : this.state.totalItems;
                    this.setState({ error: errorMessage, block: false, properties: data.data, cursors, totalItems });
                }
                else {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `${url}?pageSize=${pageSize}${cursors[page - 1] ? `&cursor=${cursors[page - 1]}` : ``}${startDate ? `&startDate=${moment(startDate).format("YYYY-MM-DD")}` : ``}${endDate ? `&endDate=${moment(endDate).format("YYYY-MM-DD")}` : ``}`);
    }

    getSearchConnections = () => {
        this.setState({ block: true, page: 1, cursors: [null] }, this.getConnections);
    }

    render() {
        const { block, cursors, endDate, error, focusedInput, page, properties, startDate, totalItems } = this.state;

        return (
            <BlankCard block={block} error={error}>
                <BookingConnectionsList
                    cursors={cursors}
                    getSearchConnections={this.getSearchConnections}
                    handleNextButton={_ => this.setState({ page: page + 1, block: true }, this.getConnections)}
                    handlePrevButton={_ => this.setState({ page: page - 1, block: true }, this.getConnections)}
                    page={page}
                    properties={properties}
                    startDate={startDate}
                    startDateId="deactiveStartDate"
                    endDate={endDate}
                    endDateId="deactiveEndDate"
                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                    focusedInput={focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    total={totalItems}
                    disablePropertyIdFilter={true}
                >
                    <Row>
                        <Col>
                            {properties.map((prp, key) =>
                                <div key={key}>
                                    <Row>
                                        <Col>
                                            <div>
                                                <h5 style={{ display: "inline-block" }}>
                                                    <FormattedMessage id="BookingConnections.PropertyId" />:
                                                </h5>
                                                <span className="ml-2">{prp.property_id}</span>
                                            </div>
                                            {
                                                Object.keys(prp.connection_types).map((conn, key) => <Badge key={key} className="text-white mr-1" color="primary">{conn}</Badge>)
                                            }
                                        </Col>
                                        <Col className="col-2 text-right">
                                            {moment(prp.last_disconnected_at).format("YYYY-MM-DD")}
                                        </Col>
                                    </Row>
                                    <hr />
                                </div>
                            )}
                        </Col>
                    </Row>
                </BookingConnectionsList>                
            </BlankCard>
        );
    }
}

export default injectIntl(BookingDisconnetedConnectionsList);