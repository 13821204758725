import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import { Button, Card, Row, Col, Label } from 'reactstrap';
import { getCountryISO3 } from "../../Base/Common/ReferenceDataFunctions";
import { handleNotification } from '../../Base/Notification';
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { getAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';
import Datamap from 'datamaps';
import moment from 'moment';

class ClientReservationsSummary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            clientReservationsSummary: [],
            dataMap: null,
            totalClients: 0,
            totalReservations: 0,
            totalAmount: 0,
            tableData: [],
            clientsByCountry: []
        };

        this.element = React.createRef()
    }

    componentDidMount() {
        this.getClientReservationsSummary();
    }

    getClientReservationsSummary = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.setState({
                        clientReservationsSummary: data.response,
                        error: data.errors
                    }, () => this.calculateData(data.response));
                }
                this.setState(({ block: false, error: errorMessage }));
            }
        }, `/api/Rate/DashBoard/CountryClientReservationsSummary?fromDate=${moment(this.state.startDate).format('YYYY-MM-DD')}&toDate=${moment(this.state.endDate).format('YYYY-MM-DD')}`);
    }

    calculateData = (data) => {
        const map = [], tableData = [], clientsByCountry = [];
        var totalClients = 0, totalReservations = 0, totalAmount = 0;

        data && data.map((el, i) => {
            //Map
            var iso3 = el.country ? getCountryISO3(el.country) : null;
            let cc = iso3 ? iso3 : 'None'

            if (map[cc] === undefined)
                map[cc] = {
                    fillKey: "HIGH", 'totalReservations': el.totalReservations, 'totalClients': el.totalClients
                }
            else {
                map[cc].totalReservations = map[cc].totalReservations + el.totalReservations;
            }


            //Total Clients
            totalClients += el.totalClientsByStateSummary.reduce((acc, curr) => acc + curr.totalClients, 0);

            //Total Reservations
            totalReservations += el.totalReservations;

            //Total Amount
            totalAmount += el.amountByCurrencySummary.reduce((acc, curr) => acc + curr.amount, 0);

            //TableData
            el.amountByCurrencySummary && el.amountByCurrencySummary.forEach((currency, index) => {
                const row = currency.currency ? tableData.find(r => r.currency.toLowerCase() === currency.currency.toLowerCase()) : tableData.find(r => r.currency === 'Not Defined');

                if (row && row.country === el.country) {
                    row.totalReservations += currency.totalReservations;
                    row.amount += currency.amount;
                }
                else {
                    tableData.push({
                        id: `dataIdx${i}currency${index}`,
                        country: el.country,
                        totalReservations: currency.totalReservations,
                        amount: currency.amount,
                        currency: currency.currency ? currency.currency : 'Not Defined' 
                    });
                }
            })

            //Clients By Country
            const cbc = el.country ? clientsByCountry.find(r => r.country.toLowerCase() === el.country.toLowerCase()) : clientsByCountry.find(r => r.country === 'Not Defined');

            if (cbc && cbc.country === el.country) {
                cbc.totalClients += el.totalClients;
            }
            else {
                clientsByCountry.push({ country: el.country ? el.country : 'Not Defined', totalClients: el.totalClients });
            }
        })

        this.setState({ dataMap: map, totalClients, totalReservations, totalAmount, tableData, clientsByCountry }, () => this.renderDataMaps('world'))
    }

    renderDataMaps = (scope) => {
        let existing = document.querySelectorAll('.datamap')

        existing.forEach(node => node.parentNode.removeChild(node))
        this.setState({
            datamap: new Datamap(
                {
                    projection: 'mercator',
                    responsive: true,
                    geographyConfig: {
                        borderColor: '#DEDEDE',
                        highlightBorderWidth: 2,
                        highlightBorderColor: '#B7B7B7',
                        popupTemplate: function (geography, data) {
                            return '<div className="hoverinfo">' + geography.properties.name + '<br/> Reservations: ' + data.totalReservations + 
                                '<br/> Clients: ' + data.totalClients
                        },
                    },
                    fills: {
                        LOW: '#afafaf',
                        HIGH: '#123456',
                        MEDIUM: 'blue',
                        defaultFill: '#F5F5F5'
                    },
                    element: this.element.current,
                    scope,
                    data: this.state.dataMap
                })
        })
    }

    render() {
        const { clientReservationsSummary, totalClients, totalReservations, totalAmount, tableData, clientsByCountry } = this.state;

        const columns = [
            {
                dataField: 'country',
                text: this.props.intl.formatMessage({ id: "ClientReservationsSummary.Country" }),
                formatter: (cell, row) => cell ? <div><span className={' mb-1 mr-1 flag-icon flag-icon-' + cell.toLowerCase()} /> {cell} </div> : '',
                headerStyle: { width: "25%" }
            },
            {
                dataField: 'totalReservations',
                text: this.props.intl.formatMessage({ id: "ClientReservationsSummary.TotalReservations" }),
                headerStyle: { width: "25%" }
            },
            {
                dataField: 'amount',
                text: this.props.intl.formatMessage({ id: "ClientReservationsSummary.Amount" }),
                formatter: (cell) => cell ? <FormattedNumber value={cell} maximumFractionDigits={2} /> : '',
                headerStyle: { width: "25%" }
            },
            {
                dataField: 'currency',
                text: this.props.intl.formatMessage({ id: "ClientReservationsSummary.Currency" }),
                headerStyle: { width: "25%" },
                formatter: (cell) => cell && cell === 'Not Defined' ? <i> <FormattedMessage id="ClientReservationsSummary.NotDefined"/> </i> : <span> {cell} </span>
            }
        ]

        const Crt = ({ text, value }) => {
            return <Card className="shadow" body>
                <Row className="row no-gutters align-items-center">
                    <Col>
                        <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                            {text}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <h3 className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                            {value}
                        </h3>
                    </Col>
                </Row>
            </Card>
        }

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <Row className="my-1">
                        <Col>
                            <Card className="shadow" body>
                                <Row className="mb-2">
                                    <Col>
                                        <DateRangePicker
                                            startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                            startDateId="your_unique_start_date_id"
                                            endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                            endDateId="your_unique_end_date_id"
                                            isOutsideRange={day => false}
                                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            small={true}
                                            showDefaultInputIcon={true}
                                        />
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn btn-sm btn-host" onClick={this.getClientReservationsSummary}>
                                            <i className="fas fa-search" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={4} className="pr-1">
                            <Crt text={<FormattedMessage id="ClientReservationsSummary.TotalClients" />} value={totalClients} />
                        </Col>
                        <Col sm={4} className="px-1">
                            <Crt text={<FormattedMessage id="ClientReservationsSummary.TotalReservations" />} value={totalReservations} />
                        </Col>
                        <Col sm={4} className="pl-1">
                            <Crt text={<FormattedMessage id="ClientReservationsSummary.TotalAmount" />} value={<FormattedNumber value={totalAmount} maximumFractionDigits={2} />} />
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col>
                            <Card className="shadow h-100 my-0 py-0" body>
                                <Row>
                                    <Col className="col-7">
                                        <div ref={this.element} style={{ height: '100%' }} />
                                    </Col>
                                    <Col className="col-5 mt-2">
                                        <div style={{ fontSize: '12.8px' }}>
                                            <Row>
                                                <Col>
                                                    <b><FormattedMessage id="ClientReservationsSummary.Country" /></b>
                                                </Col>
                                                <Col>
                                                    <b><FormattedMessage id="ClientReservationsSummary.TotalClients" /></b>
                                                </Col>
                                            </Row>
                                            <hr className="mx-0 my-1" />
                                            {clientsByCountry && clientsByCountry.map((data, key) =>
                                                <div key={key}>
                                                    <Row>
                                                        <Col>
                                                            {data.country && data.country !== 'Not Defined' ?
                                                                <div><span className={' mb-1 mr-1 flag-icon flag-icon-' + data.country.toLowerCase()} /> {data.country} </div>
                                                            : <i> <FormattedMessage id="ClientReservationsSummary.NotDefined" /> </i>}
                                                        </Col>
                                                        <Col>
                                                            {data.totalClients}
                                                        </Col>
                                                    </Row>  
                                                    <hr className="mx-0 my-1" />
                                                </div>
                                            )}
                                        </div>

                                        <Row className="mt-4">
                                            <Col>
                                                <CustomTable
                                                    data={tableData}
                                                    columns={columns}
                                                    showTotal={true}
                                                    sizePerPage={10}
                                                    totalSize={tableData && tableData.length}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(ClientReservationsSummary)