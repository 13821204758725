import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row, Card } from 'reactstrap';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import RenderHtmlAsImage from './HtmlToImage';
import { getAPI } from '../../Base/API';
import PreviewTemplates from './PreviewTemplates';

class ConfirmationLetters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: false,
            previewModal: false
        }
    }
    componentDidMount() {
        this.getConfirmarionLetters();
    }

    getConfirmarionLetters = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {

                    this.setState({ confirmationLetters: data.response, block: false });
                }
                else {
                    this.setState(({ block: false }));
                }
            }
            else {
                this.setState(({ block: false }));
            }
        }, '/api/notification/Template/v1/GetHeyBeTemplates');
    }

    togglePreview = (selectedEvent) => {
        this.setState(prevState => ({
            previewModal: !prevState.previewModal,
            selectedEvent
        }))
    }

    render() {
        const { block, error, confirmationLetters, previewModal } = this.state;

        return (
            <StyledCard block={block} error={error} icon="far fa-envelope" title="navbar.ConfirmationLetter">
                {previewModal ?
                    <PreviewTemplates
                        modal={previewModal}
                        toggleModal={this.togglePreview}
                        selectedEvent={this.state.selectedEvent}
                    />
                : ''}

                {confirmationLetters?.length > 0 ?
                    <Row>
                        {confirmationLetters.map((letter, idx) => 
                            <Col className="col-3 mb-3" key={idx}>
                                <Card className="shadow py-2 px-3 pointer" body style={{ height: '430px', overflow: 'hidden' }} onClick={() => this.togglePreview(letter)}>
                                    <Row className="mb-2">
                                        <Col>
                                            <span className="my-2 text-ellipsis" title={letter.name} style={{ fontSize: '18px' }}>
                                                {letter.name}
                                            </span>
                                        </Col>
                                    </Row>

                                    {letter.templates?.length > 0 ?
                                        <>
                                            <Row className="mb-2">
                                                <Col>
                                                    {letter.templates.map((el, key) => 
                                                        <span key={key} className={`flag-icon flag-icon-${el.language.slice(- 2).toLowerCase()} mr-2`}></span>
                                                    )}
                                                </Col>
                                            </Row>

                                            <RenderHtmlAsImage
                                                html={letter.templates[0].previewHtml}
                                                id={`template${idx}`}
                                            />
                                        </>
                                    : ''}
                                </Card>
                            </Col>
                        )}
                    </Row>
                : ''}
            </StyledCard>
        )
    }
}

export default injectIntl(ConfirmationLetters);
