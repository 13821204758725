import React, { Component } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../Base/Common/CustomSelect';

export class RenderIfThenPayForm extends Component {

    render() {
        const { paymentGateway, handleComboData, handleData, idx, mbValidatyDaysOptions } = this.props;

        return (
            <div>
                <FormGroup row>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.BackOfficeKey" />
                    </Label>
                    <Col sm={9}>
                        <Input type="text" maxLength="100" name="accessToken" id="accessToken" value={paymentGateway.accessToken ? paymentGateway.accessToken : ''} onChange={evt => handleData(evt, idx)} required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.CcKey" />
                    </Label>
                    <Col sm={9}>
                        <Input type="text" maxLength="10" name="ccKey" id="ccKey" value={paymentGateway.ccKey ? paymentGateway.ccKey : ''} onChange={evt => handleData(evt, idx)} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.MbwayKey" />
                    </Label>
                    <Col sm={9}>
                        <Input type="text" maxLength="10" name="mbwayKey" id="mbwayKey" value={paymentGateway.mbwayKey ? paymentGateway.mbwayKey : ''} onChange={evt => handleData(evt, idx)} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.MbKey" />
                    </Label>
                    <Col sm={3}>
                        <Input type="text" maxLength="10" name="mbKey" id="mbKey" value={paymentGateway.mbKey ? paymentGateway.mbKey : ''} onChange={evt => handleData(evt, idx)} />
                    </Col>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.MbValidityDays" />
                    </Label>
                    <Col sm={3}>
                        <CustomSelect options={mbValidatyDaysOptions} value={mbValidatyDaysOptions.find(opt => opt.value === paymentGateway.mbValidatyDays)} onChange={evt => handleComboData(evt, idx, 'mbValidatyDays')} required={paymentGateway.validPaymentModes.some(md => md === 'SibsMultibanco')} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.DaysBeforeCheckIn" />
                    </Label>
                    <Col sm={9}>
                        <Input disabled={paymentGateway && paymentGateway.validPaymentModes && !paymentGateway.validPaymentModes.some(md => md === 'SibsMultibanco')} type="number" max="240" min="0" name="daysBeforeCheckIn" id="daysBeforeCheckIn" value={paymentGateway.daysBeforeCheckIn ? paymentGateway.daysBeforeCheckIn : ''} onChange={evt => handleData(evt, idx)} required={paymentGateway.validPaymentModes.some(md => md === 'SibsMultibanco')} />
                    </Col>
                </FormGroup>
            </div>
        );
    }
}