import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Button, Label, Input, Modal, ModalBody } from 'reactstrap';
import CustomSelect from '../../Base/Common/CustomSelect';
import BlockUi from 'react-block-ui';

class QuestionDetailModal extends Component {
    render() {
        const { faqToEdit, editQuestionTxt, editAnswerTxt, questionType, createNewMessage, newQuestion, newAnswer, questionsOptions, newMessageType, block } = this.props;
        
        return (
            <Modal isOpen={this.props.isOpen} toggle={createNewMessage ? () => this.props.toggleCreateNewMessage(false) : () => this.props.toggleEditQuestion(faqToEdit, null, null)} className="modal-lg p-3">
                <BlockUi tag="div" blocking={block}>
                    <ModalBody className='d-flex flex-column w-100'>
                        <Button onClick={createNewMessage ? () => this.props.toggleCreateNewMessage(false) : () => this.props.toggleEditQuestion(faqToEdit, null, null)} className="closeModal">
                            <i className="fas fa-times fa-sm"></i>
                        </Button>
                        <div className='d-flex w-100 justify-content-between align-items-center p-3'>
                            {createNewMessage ? 
                                <div>
                                    {questionType === 'FAQ' ?
                                        <h6><FormattedMessage id="HeyTravelAI.CreateFaq" /></h6>
                                    :
                                        <h6><FormattedMessage id="HeyTravelAI.CreateReview" /></h6>
                                    }
                                </div>
                            :
                                <div>
                                    {questionType === 'FAQ' ?
                                        <h6><FormattedMessage id="HeyTravelAI.EditFaq" /></h6>
                                    :
                                        <h6><FormattedMessage id="HeyTravelAI.EditReview" /></h6>
                                    }
                                </div>
                            }
                            <div>
                                <Button disabled={createNewMessage ? (!newQuestion || !newAnswer) : (editQuestionTxt === '' || editAnswerTxt === '')} className={`btn btn-sm btn-host ${createNewMessage ? '' : 'mr-2'}`} onClick={createNewMessage ? () => this.props.saveNewMessage() : () => this.props.saveEditQuestion()}>
                                    <i className="fas fa-save" />
                                </Button>
                               {!createNewMessage ?
                                    <Button className="btn btn-sm bg-danger border-danger" onClick={() => this.props.toggleDeleteFAQModal(faqToEdit)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </Button>
                                :''}
                            </div>
                        </div>
                        {createNewMessage ?
                            <>
                                <div className={global.isMobile ? 'w-100' : 'w-50'}>
                                    <Label className='d-flex align-items-center' for="MessageType" sm={4}>
                                        <FormattedMessage id="HeyTravelAI.CreateMessageType" />
                                    </Label>
                                    <div id="MessageType" className='px-3' style={{ maxWidth: global.isMobile ? '50%' : '35%' }}>
                                        <CustomSelect
                                            options={questionsOptions}
                                            placeholder={<FormattedMessage id="HeyTravelAI.Type" />}
                                            onChange={(e) => this.props.handleSelect('newMessageType', e)}
                                            value={questionsOptions && questionsOptions.find(c => c.value === newMessageType)}
                                        />
                                    </div>
                                </div>
                            </>
                        :''}
                        <div className='d-flex w-100 justify-content-between align-items-center px-3'>
                            <div className='d-flex flex-column w-100'>
                                <div className='d-flex w-100 justify-content-between align-items-center mb-2'>  
                                    <div>
                                        {createNewMessage ?
                                            <Label className='pl-0 d-flex' for="MessageQuestion" sm={4}>
                                                <FormattedMessage id="HeyTravelAI.CreateMessageQuestion" />
                                            </Label>    
                                        :
                                            <Label className='pl-0 d-flex w-100' for="EditMessageQuestion"> 
                                                <FormattedMessage id="HeyTravelAI.EditMessageQuestion" />
                                            </Label>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <Input 
                                        type="textarea" 
                                        name="MessageQuestion"
                                        id="MessageQuestion" 
                                        bsSize="sm" 
                                        className="text-sm" 
                                        value={createNewMessage ? newQuestion : editQuestionTxt} 
                                        onChange={createNewMessage ? (e) => this.props.setNewMessage('newQuestion', e.target.value) : (e) => this.props.editFaq('editQuestionTxt', e.target.value)} 
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='d-flex w-100 justify-content-between align-items-center p-3'>
                            <div className='d-flex flex-column w-100'>
                                <div className='d-flex w-100 justify-content-between align-items-center mb-2'>
                                    <div className='w-100'>
                                        {createNewMessage ?
                                            <Label className='d-flex align-items-center pl-0' for="MessageAnswer" sm={4}>
                                                <FormattedMessage id="HeyTravelAI.CreateMessageAnswer" />
                                            </Label>
                                        :
                                            <Label className='d-flex align-items-center pl-0' for="MessageAnswer" sm={4}>
                                                <FormattedMessage id="HeyTravelAI.EditMessageAnswer" />
                                            </Label>
                                        }
                                        
                                    </div>
                                </div>
                                <div>
                                    <Input 
                                        type="textarea" 
                                        name="MessageAnswer" 
                                        id="MessageAnswer" 
                                        bsSize="sm" 
                                        className="text-sm" 
                                        value={createNewMessage ? newAnswer : editAnswerTxt} 
                                        onChange={createNewMessage ? (e) => this.props.setNewMessage('newAnswer', e.target.value) : (e) => this.props.editFaq('editAnswerTxt', e.target.value)} 
                                    />
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </BlockUi>
            </Modal>
        )
    }
}

export default injectIntl(QuestionDetailModal)