import React, { Component } from 'react';
import { Badge, Button, Modal, ModalBody, ModalHeader, ModalFooter, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, Card } from 'reactstrap';
import classnames from 'classnames';
import { deleteAPI, getAPI, putAPI } from "../../Base/API";
import BEPromotionCard from './BEPromotionCard';
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui'
import { ErrorAlert } from "../../Common/ErrorAlert";
import CustomSelect from '../../Base/Common/CustomSelect';
import { PromotionSummaryCard } from './PromotionSummaryCard';
import { handleNotification } from '../../Base/Notification';
import * as moment from 'moment';
import CustomToolTip from '../../../Utils/CustomToolTip';
import { CheckAuthorization } from '../../Base/Authorization';

class BEPromotionsDashboard extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.togglePromotionModal = this.togglePromotionModal.bind(this);
        this.state = {
            activeTab: '1',
            block: true,
            promotionModal: false,
            syncModal: false,
            Alerts: [],
            activePromotions: [],
            deactivePromotions: [],
            deactivePromotions: [],
            expiredPromotions: [],
            rateList: [],
            rateCodeChannels: [],
            channelInstances: [],
            selectedChannel: null,
            promotionType: [
                { name: this.props.intl.formatMessage({ id: "PromotionDashboard.Basic" }), icon: "fa-tag", code: "basic", active: false },
                { name: this.props.intl.formatMessage({ id: "PromotionDashboard.LastMinute" }), icon: "fa-clock", code: "last_minute", active: false },
                { name: this.props.intl.formatMessage({ id: "PromotionDashboard.EarlyBooker" }), icon: "fa-coffee", code: "early_booker", active: false },
                { name: this.props.intl.formatMessage({ id: "PromotionDashboard.SecretPromo" }), icon: "fa-user-secret", code: "secret_promo", active: false }
            ],
            selectedPromotion: null,
            disableModal: false
        };
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let activePromotions = [];
                let deactivePromotions = [];
                let expiredPromotions = [];
                if (data.request) {
                    const today = moment(moment().format("YYYY-MM-DD"));
                    activePromotions = data.request.promotion.filter(item => item.active && today.isSameOrBefore(moment(item.stay_date.end)));
                    deactivePromotions = data.request.promotion.filter(item => !item.active);
                    expiredPromotions = data.request.promotion.filter(item => item.active && today.isAfter(moment(item.stay_date.end)));
                }

                this.setState({ block: false, activePromotions: activePromotions, deactivePromotions: deactivePromotions, expiredPromotions: expiredPromotions });
            }
        }, '/api/Price/Promotion/v1/HeyBE');

        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                data.map(item => {
                    item.value = item.id;
                    item.label = `${item.name} (${item.propertyCode})`;
                    this.getRateCodeChannelsByChannelInstance(item);
                    return item;
                });
                this.setState({ channelInstances: data, block: false, selectedChannel: data.length > 0 ? data[0].value : null });
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/HeyBE/active');
    }

    getRateCodeChannelsByChannelInstance(channelInstance) {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                channelInstance.rateCodeChannels = data;
            }
            this.setState({ block: false });
        }, `/api/Rate/RateCodeChannel/v1/ChannelInstance/${channelInstance.id}`);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    togglePromotionModal = (item) => {
        this.setState(prevState => ({
            promotionModal: !prevState.promotionModal,
            selectedPromotion: item
        }));
    }

    setComboStatus = (field, inputValue) => {
        var targetValue = '';
        targetValue = inputValue && inputValue.value;

        this.setState({ [field]: targetValue })
    }

    addActivePromotion = (promotion) => {
        const activePromotions = [...this.state.activePromotions];
        const index = activePromotions.findIndex(promo => promo.id === promotion.id);

        if (index !== -1) {
            activePromotions[index] = promotion;
        }
        else {
            activePromotions.push(promotion);
        }

        this.setState({ activePromotions });
    }

    enablePromotion = (promotionId) => {
        const activePromotions = [...this.state.activePromotions];
        const deactivePromotions = [...this.state.deactivePromotions];
        const index = deactivePromotions.findIndex(el => el.id === promotionId);

        if (index !== -1) {
            var removed = deactivePromotions.splice(index, 1);
            removed[0].active = false;
            activePromotions.push(removed[0]);
        }

        this.setState({ activePromotions, deactivePromotions });
    }

    disablePromotion = (promotionId) => {
        const activePromotions = [...this.state.activePromotions];
        const deactivePromotions = [...this.state.deactivePromotions];
        const index = activePromotions.findIndex(el => el.id === promotionId);

        if (index !== -1) {
            var removed = activePromotions.splice(index, 1);
            removed[0].active = false;
            deactivePromotions.push(removed[0]);
        }

        this.setState({ activePromotions, deactivePromotions });
    }

    isEditDisabled = (item) => {
        return !CheckAuthorization("promotions:edit", null) || this.state.promotionType && this.state.promotionType.find(promotionType => promotionType.code == item.type) == null;
    }

    setPromotionStatus = () => {
        const { selectedPromotion } = this.state;
        const status = !selectedPromotion.active;

        this.setState({ block: true });
            
        putAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    if (status) {
                        handleNotification(data, <FormattedMessage id="BookingPromotionList.EnablePromotionSuccess" />, <FormattedMessage id="BookingPromotionList.Success" />);
                        this.enablePromotion(selectedPromotion.id);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="BookingPromotionList.DisablePromotionSuccess" />, <FormattedMessage id="BookingPromotionList.Success" />);
                        this.disablePromotion(selectedPromotion.id);
                    }
                    selectedPromotion.active = status;
                    this.setState({ error: errorMessage, block: false }, this.toggleDisableModal.bind(this, null));
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Price/Promotion/v1/${selectedPromotion.id}/status/${status ? `enable` : `disable`}`);
    }

    toggleDisableModal(item) {
        this.setState(prevState => ({
            disableModal: !prevState.disableModal,
            selectedPromotion: item
        }));
    }

    removePromotion(item, idx) {
        const { deactivePromotions } = this.state;

        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="BookingPromotionList.DeletePromotionSuccess" />, <FormattedMessage id="BookingPromotionList.Success" />);
                    deactivePromotions.splice(idx, 1);
                }
            }
            this.setState({ error: errorMessage, block: false, deactivePromotions });
        }, `/api/Price/Promotion/v1/${item.id}/HeyBE`);
    }

    render() {
        const { channelInstances, promotionType, selectedChannel, selectedPromotion } = this.state;

        const activePromotions = this.state.activePromotions.filter(p => p.channelInstanceId === selectedChannel);
        const deactivePromotions = this.state.deactivePromotions.filter(p => p.channelInstanceId === selectedChannel);
        const expiredPromotions = this.state.expiredPromotions.filter(p => p.channelInstanceId === selectedChannel);

        return (
            <Card className="border-0">
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Modal isOpen={this.state.promotionModal} toggle={this.togglePromotionModal} fade={false} className="modal-lg " >
                        <ModalHeader toggle={this.togglePromotionModal.bind(this, null)}>
                            <FormattedMessage id="BookingReviewDetail.CreateNew" />
                        </ModalHeader>
                        <ModalBody className="modal-lg px-2 pb-2">
                            <BEPromotionCard
                                editMode={true}
                                selectedPromotion={selectedPromotion}
                                promotionType={promotionType}
                                channelInstances={channelInstances}
                                activePromotions={activePromotions}
                                toggleModal={this.togglePromotionModal.bind(this, null)}
                                addActivePromotion={this.addActivePromotion}
                            />
                        </ModalBody>
                    </Modal>

                    <Modal isOpen={this.state.disableModal} toggle={this.toggleDisableModal.bind(this, null)} fade={false} className="modal-lg " >
                        <ErrorAlert error={this.state.errorDisable} />
                        <BlockUi tag="div" blocking={this.state.block}>
                            <ModalBody className="modal-lg px-2 pb-2 text-center">
                            {!global.isMobile ?
                                    <h4>
                                        <FormattedMessage id={`PromotionList.Confirm${selectedPromotion && !selectedPromotion.active ? `En` : `Dis` }ableFor`} />
                                        <strong>
                                            {this.state.selectedPromotion ? (this.state.selectedPromotion.name == '-1' ? this.state.selectedPromotion.type : this.state.selectedPromotion.name) : ''}
                                        </strong>?
                                    </h4>
                                :
                                    <h5>
                                        <FormattedMessage id={`PromotionList.Confirm${selectedPromotion && !selectedPromotion.active ? `En` : `Dis` }ableFor`} />
                                        <strong>
                                            {this.state.selectedPromotion ? (this.state.selectedPromotion.name == '-1' ? this.state.selectedPromotion.type : this.state.selectedPromotion.name) : ''}
                                        </strong>?
                                    </h5>
                                }
                                
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary btn-host" onClick={this.setPromotionStatus}> <FormattedMessage id="PromotionList.Ok" /></Button>{' '}
                                <Button color="secondary" onClick={this.toggleDisableModal.bind(this, null)}> <FormattedMessage id="PromotionList.Close" /></Button>
                            </ModalFooter>
                        </BlockUi>
                    </Modal>

                    <Row className={`mb-2 ${global.isMobile ? 'w-100' : ''}`}>
                        <Col className="col-lg-3 col-10">
                            <CustomSelect icon={'fa fa-plug fa-fw'} options={channelInstances} placeholder={<FormattedMessage id="PromotionDashboard.Channel" />} value={channelInstances.find(c => c.value === selectedChannel)} onChange={this.setComboStatus.bind(this, 'selectedChannel')} isClearable isSearchable />
                        </Col>
                        <Col className={`${global.isMobile ? 'col-2 d-flex align-items-center' : ''} text-right`}>
                            <Button className="btn-host btn-sm mr-1 float-right" id="addpromotiontooptip" onClick={this.togglePromotionModal.bind(this, null)} disabled={!CheckAuthorization("promotions:add", null)}>
                                <i className="fas fa-plus-square"/>
                            </Button>
                            <CustomToolTip placement="bottom" target="addpromotiontooptip">
                                <FormattedMessage id="PromotionDashBoard.CreateNewPromotion" />
                            </CustomToolTip>
                        </Col>
                    </Row>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                <FormattedMessage id="PromotionDashboard.Active" />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                <FormattedMessage id="PromotionDashboard.Inactive" />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                <FormattedMessage id="PromotionDashboard.Expired" />
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            {
                                activePromotions.length > 0 ?
                                    activePromotions
                                        .map((promotion, key) =>
                                            <PromotionSummaryCard key={key} idx={key} channelInstances={channelInstances} item={promotion} promotionType={promotionType} togglePromotionModal={this.togglePromotionModal} statusBadge={<Badge color="primary" pill> <FormattedMessage id="PromotionDashboard.Active" /> </Badge>}>
                                                <Button
                                                    className="btn-host shadow btn-sm mr-1" id={'prmotiondisabeltooptip' + promotion.channelInstanceId + key}
                                                    onClick={this.toggleDisableModal.bind(this, promotion)}
                                                    disabled={this.isEditDisabled(promotion)}>
                                                    <i className="fas fa-power-off" />
                                                </Button>
                                                <CustomToolTip placement="bottom" target={'prmotiondisabeltooptip' + promotion.channelInstanceId + key}>
                                                    <FormattedMessage id="PromotionList.DisablePromotion" />
                                                </CustomToolTip>
                                                <Button
                                                    className="btn-host shadow btn-sm" id={'prmotionedittooptip' + promotion.channelInstanceId + key} onClick={this.togglePromotionModal.bind(this, promotion)} disabled={this.isEditDisabled(promotion)}>
                                                    <i className="fas fa-edit" />
                                                </Button>
                                                <CustomToolTip placement="bottom" target={'prmotionedittooptip' + promotion.channelInstanceId + key}>
                                                    <FormattedMessage id="PromotionList.EditPromotion" />
                                                </CustomToolTip>
                                            </PromotionSummaryCard>
                                        )
                                    :
                                    <div>
                                        <FormattedMessage id="PromotionDashBoard.NoPromotionsAvailable" />
                                    </div>
                            }   
                        </TabPane>
                        <TabPane tabId="2">
                            {
                                deactivePromotions.length > 0 ?
                                    deactivePromotions
                                        .map((promotion, key) =>
                                            <PromotionSummaryCard key={key} idx={key} channelInstances={channelInstances} item={promotion} promotionType={promotionType} togglePromotionModal={this.togglePromotionModal} statusBadge={<Badge color="primary" pill> <FormattedMessage id="PromotionDashboard.Inactive" /> </Badge>}>
                                                <Button
                                                    className="btn-host shadow btn-sm mr-1" id={`prmotiondeletetooptip${promotion.channelInstanceId}${key}`}
                                                    onClick={this.removePromotion.bind(this, promotion, key)}>
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                                <CustomToolTip placement="bottom" target={`prmotiondeletetooptip${promotion.channelInstanceId}${key}`}>
                                                    <FormattedMessage id="PromotionList.DeletePromotion" />
                                                </CustomToolTip>
                                                <Button
                                                    className="btn-host shadow btn-sm mr-1" id={'prmotiondisabeltooptip' + promotion.channelInstanceId + key}
                                                    onClick={this.toggleDisableModal.bind(this, promotion)}
                                                    disabled={this.isEditDisabled(promotion)}>
                                                    <i className="fas fa-power-off" />
                                                </Button>
                                                <CustomToolTip placement="bottom" target={'prmotiondisabeltooptip' + promotion.channelInstanceId + key}>
                                                    <FormattedMessage id="PromotionList.DisablePromotion" />
                                                </CustomToolTip>
                                                <Button
                                                    className="btn-host shadow btn-sm" id={'prmotionedittooptip' + promotion.channelInstanceId + key} onClick={this.togglePromotionModal.bind(this, promotion)} disabled={this.isEditDisabled(promotion)}>
                                                    <i className="fas fa-edit" />
                                                </Button>
                                                <CustomToolTip placement="bottom" target={'prmotionedittooptip' + promotion.channelInstanceId + key}>
                                                    <FormattedMessage id="PromotionList.EditPromotion" />
                                                </CustomToolTip>
                                            </PromotionSummaryCard>
                                        )
                                    :
                                    <div>
                                        <FormattedMessage id="PromotionDashBoard.NoPromotionsAvailable" />
                                    </div>
                            }
                        </TabPane>
                        <TabPane tabId="3">
                            {
                                expiredPromotions.length > 0 ?
                                    expiredPromotions
                                        .map((promotion, key) =>
                                            <PromotionSummaryCard key={key} idx={key} channelInstances={channelInstances} item={promotion} promotionType={promotionType} togglePromotionModal={this.togglePromotionModal} statusBadge={<Badge color="secondary" pill> <FormattedMessage id="PromotionDashboard.Expired" /> </Badge>}>
                                                <Button
                                                    className="btn-host shadow btn-sm" id={'prmotionedittooptip' + promotion.channelInstanceId + key} onClick={this.togglePromotionModal.bind(this, promotion)} disabled={this.isEditDisabled(promotion)}>
                                                    <i className="fas fa-edit" />
                                                </Button>
                                                <CustomToolTip placement="bottom" target={'prmotionedittooptip' + promotion.channelInstanceId + key}>
                                                    <FormattedMessage id="PromotionList.EditPromotion" />
                                                </CustomToolTip>
                                            </PromotionSummaryCard>
                                        )
                                    :
                                    <div>
                                        <FormattedMessage id="PromotionDashBoard.NoPromotionsAvailable" />
                                    </div>
                            }
                        </TabPane>
                    </TabContent>
                </BlockUi>
            </Card>
        );
    }
}

export default injectIntl(BEPromotionsDashboard)