import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Form, Card } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../../Base/Notification";
import { postAPI, getAPI } from "../../Base/API";
import { StyledCard } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';

class RunningProcesses extends Component {
	constructor(props) {
		super(props);

        this.state = {
            runningProcesses: []
		};
	}

	componentDidMount() {
		this.getRunngProcesses();
    }

	getRunngProcesses = () => {
        this.setState({ block: true });

		getAPI(result => {
			const { data, error } = result;
			var errorMessage = [];

			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
            if (data) {
                this.setState(({ runningProcesses: data.response || [], block: false }));
            }
            else this.setState(({ block: false }));
		}, `/api/User/Monitor/v1/DbProcesses`);

    }

    killDBProcess = (pid) => {
        this.setState({ block: true });

		getAPI(result => {
			const { data, error } = result;
			var errorMessage = [];

			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
            if (data) {
                handleNotification(data, <FormattedMessage id="RunningProcesses.ProcessKilled" />, <FormattedMessage id="General.Success" />);
                this.setState(({ block: false }));
            }
            else this.setState(({ block: false }));
		}, `/api/User/Monitor/v1/killDbProcess/${pid}`);
    }

    killAllDBProcess = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="RunningProcesses.AllProcessesKilled" />, <FormattedMessage id="General.Success" />);
                this.setState(({ block: false }));
            }
            else this.setState(({ block: false }));
        }, `/api/User/Monitor/v1/DbProcessesAndKill`);
    }

    render() {
        const { runningProcesses } = this.state;

        const columns = [
            {
                dataField: 'pid',
                text: this.props.intl.formatMessage({ id: "RunningProcesses.pid" }),
                sort: true,
                headerStyle: { width: '8%' }
            },
            {
                dataField: 'duration',
                text: this.props.intl.formatMessage({ id: "RunningProcesses.Duration" }),
                sort: true,
                headerStyle: { width: '10%' }
            },
            {
                dataField: 'query',
                text: this.props.intl.formatMessage({ id: "RunningProcesses.Query" }),
                sort: true,
                headerStyle: { width: '72%' }
            },
            {
                dataField: '',
                text: this.props.intl.formatMessage({ id: "RunningProcesses.KillProcess" }),
                sort: true,
                headerStyle: { width: '10%' },
                formatter: (cell, row) => <Row className="text-center">
                    <Col>
                        <Button className="btn-host btn-sm ml-3" onClick={() => this.killDBProcess(row.pid)}>
                            <i className="fas fa-trash-alt" />
                        </Button>
                    </Col>
                </Row>
            }
        ];

		return (
            <StyledCard block={this.state.block} error={this.state.error} icon='fas fa-database' title='navbar.RunningProcesses' >
                {runningProcesses.length > 0 ?
                    <Row>
                        <Col className="mb-3">
                            <Button className="btn-host btn-sm" onClick={this.killAllDBProcess}>
                                <FormattedMessage id="RunningProcesses.KillAllProcesses" />
                            </Button>
                        </Col>
                    </Row>
                : ''}

                <Row>
                    <Col>
                        <CustomTable
                            data={runningProcesses}
                            columns={columns}
                            showTotal={true}
                            remote={false}
                            hideSizePerPage={false}
                        />
                    </Col>
                </Row>
			</StyledCard>
		);
	}
}

export default injectIntl(RunningProcesses);