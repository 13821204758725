import React, { Component } from 'react';
import { Button, Card, Col, InputGroup, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI, postAPI, putAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';
import { handleNotification } from '../../Base/Notification';
import { ErrorAlert } from '../../Common/ErrorAlert';
import CustomSelect from '../../Base/Common/CustomSelect';
import { CommonHelper } from "./../../Base/Common/CommonUIComponents";
import { ExpediaPromotionDetail } from './ExpediaPromotionDetail';
import ExpediaPromotionModal from './ExpediaPromotionModal';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from "moment";
import CustomToolTip from '../../../Utils/CustomToolTip';
import { CheckAuthorization } from '../../Base/Authorization';
import { MobileFilters } from '../../Base/Common/MobileComponents';

export class ExpediaPromotionDashboard extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.getAllPromotions = this.getAllPromotions.bind(this);
        this.getGraphQLRequest = this.getGraphQLRequest.bind(this);
        this.getPrevPage = this.getPrevPage.bind(this);
        this.getNextPage = this.getNextPage.bind(this);
        this.createPromotion = this.createPromotion.bind(this);
        this.editPromotion = this.editPromotion.bind(this);
        this.togglePromotionModal = this.togglePromotionModal.bind(this);
        this.getRateCodeChannelsByChannelInstance = this.getRateCodeChannelsByChannelInstance.bind(this);
        this.disabledPromotion = this.disabledPromotion.bind(this);
        this.getExpediaChannelData = this.getExpediaChannelData.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.state = {
            block: false,
            blockAllPromotions: false,
            blockExpediaChannelData: false,
            activeTab: '1',
            channels: [],
            selectedChannelId: null,
            activePromotions: [],
            deactivePromotions: [],
            promotionTypes: [
                { type: "SINGLE_DISCOUNT", name: <FormattedMessage id="ExpediaPromotionDashboard.Single" />, icon: "fa-tag", create: 'createSingleDiscountPromotion', update: 'updateSingleDiscountPromotion' },
                { type: "DAY_OF_WEEK_DISCOUNT", name: <FormattedMessage id="ExpediaPromotionDashboard.DayOfWeek" />, icon: "fa-calendar-alt", create: 'createDayOfWeekDiscountPromotion', update: 'updateDayOfWeekDiscountPromotion' },
                { type: "MULTI_NIGHT_DISCOUNT", name: <FormattedMessage id="ExpediaPromotionDashboard.MultiNight" />, icon: "fa-moon", create: 'createMultiNightDiscountPromotion', update: 'updateMultiNightDiscountPromotion' }
            ],
            promotionNames: [
                { type: "BASIC_PROMOTION", name: < FormattedMessage id="ExpediaPromotionDashboard.Basic" /> },
                { type: "EARLY_BOOKING_PROMOTION", name: <FormattedMessage id="ExpediaPromotionDashboard.EarlyBooking" /> },
                { type: "SAME_DAY_PROMOTION", name: <FormattedMessage id="ExpediaPromotionDashboard.SameDay" /> }
            ],
            currPage: 0,
            cursors: [null],
            amount: 0,
            total: 0,
            promotionModal: false,
            selectedPromotion: null,
            isCreate: false,
            channelData: [],
            promotionOption: null,
            bookingLocalDateTimeFrom: null,
            bookingLocalDateTimeTo: null
        };
    }

    componentDidMount() {
        this.getExpediaChannels();
    }

    getExpediaChannelData() {
        const { selectedChannelId, channels } = this.state;

        this.setState({ blockExpediaChannelData: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockExpediaChannelData: false, channelData: [] });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, blockExpediaChannelData: false, channelData: [] });
                }
                if (data && data.length > 0) {
                    const channel = channels.find(c => c.value === selectedChannelId);

                    const cd = data
                        .filter(d => channel?.rateCodeChannels?.some(rateCode => d.rateCode === rateCode.rateCodeMapping))
                        .map(d => {
                            const item = {
                                value: d.rateCode,
                                label: d.rateDescription
                            };
                            return item;
                        })
                        .reduce((acc, curr) => {
                            if (!acc?.some(c => (c.value === curr.value || `${c.value}A` === curr.value) && c.label === curr.label)) {
                                acc.push(curr);
                            }
                            return acc;
                        }, []);

                    this.setState({ error: errorMessage, blockExpediaChannelData: false, channelData: cd });
                }
            }
            else {
                this.setState({ error: errorMessage, blockExpediaChannelData: false });
            }
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance/${selectedChannelId}/ChannelData`);
    }

    getExpediaChannels() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const channels = data
                    .filter(item => item.active)
                    .map(item => {
                        item.value = item.id;
                        item.label = `${item.name} (${item.propertyCode})`;
                        this.getRateCodeChannelsByChannelInstance(item);
                        return item;
                    });
                const selectedChannelId = channels.length > 0 ? channels[0].id : null;
                this.setState({ channels: channels, selectedChannelId: selectedChannelId }, () => {
                    this.getExpediaChannelData();
                    this.getAllPromotions();
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/Expedia`);
    }

    getRateCodeChannelsByChannelInstance(channelInstance) {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                channelInstance.rateCodeChannels = data.response;
            }
            this.setState({ block: false });
        }, `/api/Rate/RateCodeChannel/v1/ChannelInstance/${channelInstance.id}`);
    }

    getAllPromotions(cursor) {
        const { selectedChannelId } = this.state;
        if (selectedChannelId) {
            const request = {
                request: this.getGraphQLRequest(cursor)
            };
            this.setState({ blockAllPromotions: true });
            putAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockAllPromotions: false, activePromotions: [], deactivePromotions: [], cursors: [null], amount: 0, total: 0  });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, blockAllPromotions: false, activePromotions: [], deactivePromotions: [], cursors: [null], amount: 0, total: 0 });
                    }
                    if (data.response && data.response.length > 0) {
                        let { currPage, cursors } = this.state;
                        const pageInfo = data.response[0].property.promotions.pageInfo;
                        if (currPage >= cursors.length - 1 && pageInfo.hasNextPage) {
                            cursors.push(pageInfo.endCursor);
                        }
                        data.response[0].property.promotions.edges.map(edge => {
                            if (edge.node.blackoutDates === null) {
                                edge.node.blackoutDates = [];
                            }
                            if (!edge.node.eligibleRatePlans) {
                                edge.node.eligibleRatePlans = [];
                            }
                            if (edge.node.restrictions.isMemberOnly === null) {
                                edge.node.restrictions.isMemberOnly = false;
                            }
                            if (edge.node.restrictions.isMobileUserOnly === null) {
                                edge.node.restrictions.isMobileUserOnly = false;
                            }
                            return edge;
                        });

                        const activePromotions = data.response[0].property.promotions.edges.filter(p => p.node.status === "ACTIVE" && p.node.sellStatus !== "EXPIRED");
                        const deactivePromotions = data.response[0].property.promotions.edges.filter(p => p.node.status === "INACTIVE" || p.node.sellStatus === "EXPIRED")
                            .sort((a, b) => (a.node.status).localeCompare(b.node.status));

                        this.setState({
                            error: errorMessage,
                            blockAllPromotions: false,
                            activePromotions: activePromotions,
                            deactivePromotions: deactivePromotions,
                            cursors,
                            amount: currPage === 0 ? data.response[0].property.promotions.edges.length : (currPage * 10) + data.response[0].property.promotions.edges.length,
                            total: data.response[0].property.promotions.totalCount
                        });
                    }
                }
                else {
                    this.setState({ error: errorMessage, blockAllPromotions: false });
                }
            }, `/api/Rate/Promotion/v1/ExpediaPromotions/${selectedChannelId}/Retrieve`, request);
        }
    }

    getGraphQLRequest(cursor) {
        const { promotionOption, bookingLocalDateTimeFrom, bookingLocalDateTimeTo } = this.state;
        return `query MyQuery($propertyID: String!) {property(id: $propertyID, idSource: EXPEDIA) {promotions(pageSize: 10, filter:{${promotionOption !== null ? `discountTypes: ${promotionOption},` : ``} ${bookingLocalDateTimeFrom !== null ? `bookingLocalDateTime: { from: "${moment(bookingLocalDateTimeFrom).format("YYYY-MM-DDT00:00:00")}", to: "${moment(bookingLocalDateTimeTo).format("YYYY-MM-DDT23:59:00")}"}` : ``}}, after: ${cursor ? `"${cursor}"` : null}) {totalCount pageInfo { hasNextPage endCursor } edges { cursor node { __typename id isContractedPromotion name sellStatus status category ... on DiscountPromotion { code restrictions { isMemberOnly isMobileUserOnly minLengthOfStay maxLengthOfStay minAdvanceBookingDays maxAdvanceBookingDays bookingLocalDateTimeFrom bookingLocalDateTimeTo travelDateFrom travelDateTo sameDayBookingStartTime } blackoutDates { travelDateFrom travelDateTo } discount { __typename type unit ... on SingleDiscount { value memberOnlyAdditionalValue } ... on DayOfWeekDiscount { monday tuesday wednesday thursday friday saturday sunday } ... on MultiNightDiscount { value applicableNight isRecurring memberOnlyAdditionalValue } } } } } }  }}`;
        //return `query MyQuery($propertyID: String!) {property(id: $propertyID, idSource: EXPEDIA) {promotions(pageSize: 10, filter:{${promotionOption !== null ? `discountTypes: ${promotionOption},` : ``} ${bookingLocalDateTimeFrom !== null ? `bookingLocalDateTime: { from: "${moment(bookingLocalDateTimeFrom).format("YYYY-MM-DDT00:00:00")}", to: "${moment(bookingLocalDateTimeTo).format("YYYY-MM-DDT23:59:00")}"}` : ``}}, after: ${cursor ? `"${cursor}"` : null}) {totalCount pageInfo { hasNextPage endCursor } edges { cursor node { __typename id name status category ... on DiscountPromotion { code restrictions { isMemberOnly isMobileUserOnly minLengthOfStay maxLengthOfStay minAdvanceBookingDays maxAdvanceBookingDays bookingLocalDateTimeFrom bookingLocalDateTimeTo travelDateFrom travelDateTo sameDayBookingStartTime } eligibleRatePlans { id } blackoutDates { travelDateFrom travelDateTo } discount { __typename type unit ... on SingleDiscount { value memberOnlyAdditionalValue } ... on DayOfWeekDiscount { monday tuesday wednesday thursday friday saturday sunday } ... on MultiNightDiscount { value applicableNight isRecurring memberOnlyAdditionalValue } } } } } }  }}`;
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    setChannel = (name, combo, evt) => {
        if (evt && combo) {
            const { value } = combo;
            this.setState({ [name]: value, currPage: 0 }, () => {
                this.getExpediaChannelData();
                this.getAllPromotions();
            });
        }
    }

    setPromotionOption = (name, combo) => {
        const targetValue = combo && combo.value;
        this.setState({ [name]: targetValue });
    }

    getNextPage() {
        const { cursors, currPage } = this.state;
        this.setState({ currPage: currPage + 1 }, () => this.getAllPromotions(cursors[this.state.currPage]));
    }

    getPrevPage() {
        const { cursors, currPage } = this.state;
        this.setState({ currPage: currPage - 1 }, () => this.getAllPromotions(cursors[this.state.currPage - 1 > 0 ? this.state.currPage-1 : 0]));
    }

    createPromotion() {
        this.setState({ promotionModal: true, title: <FormattedMessage id="ExpediaPromotionModal.CreatePromotion" />, isCreate: true });
    }

    editPromotion(promotion) {
        this.setState({ promotionModal: true, selectedPromotion: promotion, title: <FormattedMessage id="ExpediaPromotionModal.EditPromotion" /> });
    }

    togglePromotionModal() {
        this.setState(prevState => ({
            promotionModal: !prevState.promotionModal,
            selectedPromotion: null,
            isCreate: false
        }));
    }

    disabledPromotion(promotion) {
        const { promotionTypes, channels, selectedChannelId, cursors, currPage } = this.state;
        const promoType = promotionTypes.find(pt => pt.type === promotion.discount.type);
        if (promoType) {
            const channel = channels.find(c => c.value === selectedChannelId);
            const mutation = `mutation { ${promoType.update}( propertyId: "${channel && channel.propertyCode}", propertyIdSource: EXPEDIA, promotion: { id: "${promotion.id}", name: ${promotion.name}, status: ${promotion.status === `ACTIVE` ? `INACTIVE` : `ACTIVE`}, category: ${promotion.category}, code: "${promotion.code}", discount: { unit: ${promotion.discount.unit}, ${promotion.discount.type === promotionTypes[2].type ? `applicableNight: ${promotion.discount.applicableNight}, isRecurring: ${promotion.discount.isRecurring}, memberOnlyAdditionalValue: ${promotion.discount.memberOnlyAdditionalValue},` : ``} ${promotion.discount.type === promotionTypes[1].type ? `monday: ${promotion.discount.monday}, tuesday: ${promotion.discount.tuesday}, wednesday: ${promotion.discount.wednesday}, thursday: ${promotion.discount.thursday}, friday: ${promotion.discount.friday}, saturday: ${promotion.discount.saturday}, sunday: ${promotion.discount.sunday}` : `value: ${promotion.discount.value}`} } } ) { __typename id name category status ... on DiscountPromotion { code restrictions { isMobileUserOnly isMemberOnly minLengthOfStay maxLengthOfStay minAdvanceBookingDays maxAdvanceBookingDays bookingLocalDateTimeFrom bookingLocalDateTimeTo travelDateFrom travelDateTo sameDayBookingStartTime } eligibleRatePlans { id } blackoutDates { travelDateFrom travelDateTo } discount { __typename type unit ... on ${promotion.discount.type === promotionTypes[0].type ? `SingleDiscount { value }` : (promotion.discount.type === promotionTypes[1].type ? `DayOfWeekDiscount { monday tuesday wednesday thursday friday saturday sunday }` : `MultiNightDiscount { value applicableNight isRecurring memberOnlyAdditionalValue }`)} } } } }`;
            const req = {
                request: mutation
            }
            this.setState({ block: true });
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="ExpediaPromotionDashboard.UpdateSatusSuccess" />, <FormattedMessage id="BookingPromotionCard.Success" />);
                        this.getAllPromotions(cursors[currPage]);
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/Rate/Promotion/v1/ExpediaPromotions/${selectedChannelId}`, req);
        }
    }

    doSearch() {
        this.setState({ currPage: 0, cursors: [null] }, () => this.getAllPromotions());
    }

    renderMainFilter = () => {
        const { channels, selectedChannelId } = this.state;
        const channel = channels.find(c => c.value === selectedChannelId);

        return (
            <Col>
                <CustomSelect icon={'fa fa-plug fa-fw'} options={channels} placeholder={<FormattedMessage id="PromotionDashboard.Channel" />} value={channel} onChange={this.setChannel.bind(this, 'selectedChannelId')} isSearchable />
            </Col>
        )
    }

    renderSecFilters = () => {
        const { promotionOption } = this.state;

        const promotionOptions = [
            { value: "SINGLE_DISCOUNT", label: <FormattedMessage id="ExpediaPromotionDashboard.Single" /> },
            { value: "DAY_OF_WEEK_DISCOUNT", label: <FormattedMessage id="ExpediaPromotionDashboard.DayOfWeek" /> },
            { value: "MULTI_NIGHT_DISCOUNT", label: <FormattedMessage id="ExpediaPromotionDashboard.MultiNight" /> }
        ];

        return (
            <>
                <Col className="col-12 p-0">
                    <FormattedMessage id="ExpediaPromotionDashboard.Availability" />
                </Col>
                <Col className="col-12 mb-2 p-0 ">
                    <InputGroup size="sm text-right" >
                        <DateRangePicker
                            startDate={this.state.bookingLocalDateTimeFrom ? moment(this.state.bookingLocalDateTimeFrom).locale(moment.locale()) : this.state.bookingLocalDateTimeFrom}
                            startDateId="your_unique_bookingLocalDateTimeFrom_id"
                            isOutsideRange={day => day <= moment().subtract(12, 'month') || day > moment().add(12, 'month')}
                            endDate={this.state.bookingLocalDateTimeTo ? moment(this.state.bookingLocalDateTimeTo).locale(moment.locale()) : this.state.bookingLocalDateTimeTo}
                            endDateId="your_unique_bookingLocalDateTimeTo_id"
                            onDatesChange={({ startDate, endDate }) => this.setState({ bookingLocalDateTimeFrom: startDate, bookingLocalDateTimeTo: endDate })}
                            focusedInput={this.state.checkInFocusedInput}
                            onFocusChange={checkInFocusedInput => this.setState({ checkInFocusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </InputGroup>
                </Col>
                <Col className="col-12 mb-3 p-0">
                    <CustomSelect options={promotionOptions} placeholder={<FormattedMessage id="ExpediaPromotionDashboard.SelectType" />} value={promotionOptions.find(c => c.value === promotionOption)} onChange={this.setPromotionOption.bind(this, 'promotionOption')} isClearable isSearchable />
                </Col>
            </>
        );
    }

    render() {
        const { block, blockAllPromotions, activeTab, activePromotions, deactivePromotions, promotionTypes, channels, selectedChannelId, promotionNames, cursors, currPage, amount, total, promotionModal, title, selectedPromotion, isCreate, channelData, promotionOption } = this.state;

        const channel = channels.find(c => c.value === selectedChannelId);

        const promotionOptions = [
            { value: "SINGLE_DISCOUNT", label: <FormattedMessage id="ExpediaPromotionDashboard.Single" /> },
            { value: "DAY_OF_WEEK_DISCOUNT", label: <FormattedMessage id="ExpediaPromotionDashboard.DayOfWeek" /> },
            { value: "MULTI_NIGHT_DISCOUNT", label: <FormattedMessage id="ExpediaPromotionDashboard.MultiNight" /> }
        ];

        return (
            <Card className="border-0">
                <BlockUi tag="div" blocking={block || blockAllPromotions}>
                    <ErrorAlert error={this.state.error} />
                    {
                        promotionModal ?
                            <ExpediaPromotionModal
                                modal={promotionModal}
                                toggle={this.togglePromotionModal}
                                title={title}
                                promotionTypes={promotionTypes}
                                promotionNames={promotionNames}
                                rateCodeChannels={channel && channel.rateCodeChannels}
                                promotion={selectedPromotion}
                                propertyCode={channel && channel.propertyCode}
                                channelInstanceId={channel && channel.id}
                                isCreate={isCreate}
                                channelData={channelData}
                            />
                            :
                            <div />
                    }
                    {!global.isMobile ? 
                    
                        <Row className="mb-2 align-items-center">
                            <Col className="col-3">
                                <CustomSelect icon={'fa fa-plug fa-fw'} options={channels} placeholder={<FormattedMessage id="PromotionDashboard.Channel" />} value={channel} onChange={this.setChannel.bind(this, 'selectedChannelId')} isSearchable />
                            </Col>
                            <Col className="col-2">
                                <CustomSelect options={promotionOptions} placeholder={<FormattedMessage id="ExpediaPromotionDashboard.SelectType" />} value={promotionOptions.find(c => c.value === promotionOption)} onChange={this.setPromotionOption.bind(this, 'promotionOption')} isClearable isSearchable />
                            </Col>
                            <Col className="col-1 px-1">
                                <FormattedMessage id="ExpediaPromotionDashboard.Availability" />
                            </Col>
                            <Col className="col-2 px-1">
                                <InputGroup size="sm text-right" >
                                    <DateRangePicker
                                        startDate={this.state.bookingLocalDateTimeFrom ? moment(this.state.bookingLocalDateTimeFrom).locale(moment.locale()) : this.state.bookingLocalDateTimeFrom}
                                        startDateId="your_unique_bookingLocalDateTimeFrom_id"
                                        isOutsideRange={day => day <= moment().subtract(12, 'month') || day > moment().add(12, 'month')}
                                        endDate={this.state.bookingLocalDateTimeTo ? moment(this.state.bookingLocalDateTimeTo).locale(moment.locale()) : this.state.bookingLocalDateTimeTo}
                                        endDateId="your_unique_bookingLocalDateTimeTo_id"
                                        onDatesChange={({ startDate, endDate }) => this.setState({ bookingLocalDateTimeFrom: startDate, bookingLocalDateTimeTo: endDate })}
                                        focusedInput={this.state.checkInFocusedInput}
                                        onFocusChange={checkInFocusedInput => this.setState({ checkInFocusedInput })}
                                        small={true}
                                        numberOfMonths={2}
                                        showDefaultInputIcon={true}
                                        renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="text-right">
                                <span style={{ marginRight: '10px' }}>
                                    <FormattedMessage id="ExpediaPromotionDashboard.PaginationText" values={{ amount: amount, total: total }} />
                                </span>
                                <span>
                                    <CommonHelper help={<FormattedMessage id="ExpediaPromotionDashboard.Help" />} id={'ExpediaPromotionsHelp'} />
                                    <Button id="searchexpediapromotiontooptip" className="btn-host btn-sm float-right" onClick={this.doSearch}>
                                        <i className="fas fa-search" />
                                    </Button>
                                    <CustomToolTip placement="bottom" target="searchexpediapromotiontooptip">
                                        <FormattedMessage id="PromotionDashBoard.SearchPromotions" />
                                    </CustomToolTip>
                                    <Button id="addexpediapromotiontooptip" className="btn-host btn-sm mr-1 float-right" onClick={this.createPromotion} disabled={!CheckAuthorization("promotions:add", null)}>
                                        <i className="fas fa-plus-square" />
                                    </Button>
                                    <CustomToolTip placement="bottom" target="addexpediapromotiontooptip">
                                        <FormattedMessage id="PromotionDashBoard.CreateNewPromotion" />
                                    </CustomToolTip>
                                    <Button className="btn btn-host btn-sm mr-1 float-right" onClick={this.getNextPage} disabled={currPage === cursors.length - 1}>
                                        <i className="fas fa-angle-right" />
                                    </Button>
                                    <Button className="btn btn-host btn-sm mr-1 float-right" onClick={this.getPrevPage} disabled={currPage === 0}>
                                        <i className="fas fa-angle-left" />
                                    </Button>
                                </span>
                            </Col>
                        </Row>
                    :
                        <MobileFilters
                            doSearch={this.doSearch}
                            renderMainFilter={this.renderMainFilter}
                            renderSecFilters={this.renderSecFilters}
                        >
                            <Row className="mb-2 align-items-center">
                                <Col className="col-12 mb-2">
                                    <span>
                                        <FormattedMessage id="ExpediaPromotionDashboard.PaginationText" values={{ amount: amount, total: total }} />
                                    </span>
                                    <span>
                                        <CommonHelper help={<FormattedMessage id="ExpediaPromotionDashboard.Help" />} id={'ExpediaPromotionsHelp'} />
                                        <CustomToolTip placement="bottom" target="searchexpediapromotiontooptip">
                                            <FormattedMessage id="PromotionDashBoard.SearchPromotions" />
                                        </CustomToolTip>
                                        <Button id="addexpediapromotiontooptip" className="btn-host btn-sm mr-1 float-right" onClick={this.createPromotion} disabled={!CheckAuthorization("promotions:add", null)}>
                                            <i className="fas fa-plus-square" />
                                        </Button>
                                        <CustomToolTip placement="bottom" target="addexpediapromotiontooptip">
                                            <FormattedMessage id="PromotionDashBoard.CreateNewPromotion" />
                                        </CustomToolTip>
                                        <Button className="btn btn-host btn-sm mr-1 float-right" onClick={this.getNextPage} disabled={currPage === cursors.length - 1}>
                                            <i className="fas fa-angle-right" />
                                        </Button>
                                        <Button className="btn btn-host btn-sm mr-1 float-right" onClick={this.getPrevPage} disabled={currPage === 0}>
                                            <i className="fas fa-angle-left" />
                                        </Button>
                                    </span>
                                </Col>
                            </Row>
                        </MobileFilters>
                    }
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                <FormattedMessage id="PromotionDashboard.Active" />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                <FormattedMessage id="PromotionDashboard.Inactive/Expired" />
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                            {
                                activePromotions.length > 0 ?
                                    activePromotions.map((promotion, key) => <ExpediaPromotionDetail promotion={promotion} key={key} idx={key} promotionTypes={promotionTypes} promotionNames={promotionNames} channel={channel} editPromotion={this.editPromotion} disabledPromotion={this.disabledPromotion}/>)
                                    :
                                    <div>
                                        <FormattedMessage id="PromotionDashBoard.NoPromotionsAvailable" />
                                    </div>
                            }
                        </TabPane>
                        <TabPane tabId="2">
                            {
                                deactivePromotions.length > 0 ?
                                    deactivePromotions.map((promotion, key) => <ExpediaPromotionDetail promotion={promotion} key={key} idx={key} promotionTypes={promotionTypes} promotionNames={promotionNames} channel={channel} editPromotion={this.editPromotion} disabledPromotion={this.disabledPromotion}/>)
                                    :
                                    <div>
                                        <FormattedMessage id="PromotionDashBoard.NoPromotionsAvailable" />
                                    </div>
                            }
                        </TabPane>
                    </TabContent>
                </BlockUi>
            </Card>    
        );
    }
}
export default injectIntl(ExpediaPromotionDashboard);
