import React, { Component } from 'react';
import { Col } from 'reactstrap';
import CustomSelect from '../Base/Common/CustomSelect';

export class ComboBoxWithTitle extends Component {
    render() {
        const { name, onChangeFunc, options, title } = this.props;

        return (
            <Col className="mb-5 col-4">
                <h5 className="mb-3">{title}</h5>

                <CustomSelect
                    id={name} name={name}
                    options={options}
                    required={true}
                    placeholder={title}
                    onChange={onChangeFunc}
                />
            </Col>
        );
    }
}