import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Input, Button } from 'reactstrap';
import BlockUi from 'react-block-ui';
import CustomSelect from '../Base/Common/CustomSelect';
import { ErrorAlert } from "../Common/ErrorAlert";
import { FormattedMessage } from 'react-intl';

export class ManualReservationOthers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            comment: null,
            index: null,
            source: null
        };
    }

    registerComment = () => {

        if (this.state.index == null) {
            this.props.addComment(this.state.comment);
        } else {
            this.props.updateComment(this.state.comment, this.state.index);
        }
        this.setState({ comment: null, index: null });
    }

    editComment = (k) => {
        this.setState({ comment: this.props.reservation.comments[k], index: k });
    }
 
    render() {
        const { reservation } = this.props;
        const { block, error } = this.state;

        const sourceOptions = [
            {
                value: 'FrontDesk',
                label: <FormattedMessage id="ManualReservation.FrontDesk" />
            },
            {
                value: 'Phone',
                label: <FormattedMessage id="ManualReservation.Phone" />
            },
            {
                value: 'Email',
                label: <FormattedMessage id="ManualReservation.Email" />
            }
        ]

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Row className="mb-1">
                    <Col className="col-2">
                        <FormattedMessage id="ManualReservation.Source" />:
                    </Col>
                    <Col className="col-2">
                        <CustomSelect
                            isSearchable
                            placeholder=''
                            options={sourceOptions}
                            value={sourceOptions.find(opt => opt.value === reservation.source)}
                            onChange={this.props.setSource.bind(this)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-10">
                        <h5 className="my-3  text-muted"><FormattedMessage id="ManualReservation.Comments" /></h5>
                        <Card className="border-0">
                            <CardBody className="shadow">
                                <FormattedMessage id="ManualReservation.TypeSomething">
                                    {
                                        placeholder => <Input type="textarea" style={{ 'border': 'none' }} value={this.state.comment ? this.state.comment.text : ''} onChange={(evt) => this.setState({ comment: { text: evt.target.value } })} placeholder={placeholder} />
                                    }
                                </FormattedMessage>
                                
                            </CardBody>
                        </Card>
                        <h5 className="pt-3 text-muted"><FormattedMessage id="ManualReservation.RegistedComments" /></h5>
                        <Card className="border-0">
                            <CardBody className="shadow" style={{ 'minHeight': '100px' }}>
                                {this.props.reservation.comments && this.props.reservation.comments.length > 0 ? 
                                    <div>
                                        {this.props.reservation.comments.map((el, k) =>
                                            <Row key={k} className=" bg-host-hover p-2 mx-1">
                                                <Col className="text-host">
                                                    {el.text}
                                                </Col>
                                                <Col className="col-2 text-right">
                                                    <i className="far fa-edit mr-2 show-on-hover text-white" onClick={() => this.editComment(k)} style={{'cursor' : 'pointer'}}></i>
                                                    <i className="far fa-times-circle  show-on-hover text-white" onClick={() => this.props.removeComment(k)} style={{ 'cursor': 'pointer' }}></i>
                                                </Col>
                                            </Row>
                                            )}
                                    </div> 
                                    :
                                    <Row className="p-2 mx-1">
                                        <Col>
                                            <i className="fas fa-info mr-2 color-host"></i> <span className="text-muted"> <FormattedMessage id="ManualReservation.AddComments" /></span>
                                        </Col>
                                    </Row>
                                    
                                    }
                                
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="text-center">
                        <h5 className="my-3 "><br /></h5>
                        <Button className="btn-host btn btn-sm" onClick={this.registerComment.bind(this)} disabled={this.state.comment === null || this.state.comment === ""} >
                            <FormattedMessage id="ManualReservation.RegisterComment" />
                        </Button>
                    </Col>
                </Row>
                
            </BlockUi>
        );
    }
}