import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { getOffersStatus, getOffersServicePricingType } from '../../Base/Common/ReferenceDataFunctions';
import { getAPI } from "../../Base/API";
import OffersDesk from './OffersDesk';
import OffersMobile from './OffersMobile';

class Offers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            block: true,
            items: [],
            pageIndex: 0,
            pageSize: 12,
            totalItems: 0,
            selectedRates: [],
            status: getOffersStatus()[0].value
        }
    }

    componentDidMount() {
        this.getSellItems();
    }

    getSellItems = () => {
        this.setState({ block: true });
        var params = this.state.status ? `&status=${this.state.status}` : '';
        
        if(this.state.selectedRates && this.state.selectedRates.length > 0){
            this.state.selectedRates.forEach(rateId => params += `&RateCodeId=${rateId}`);
        }

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response) {
                    this.setState({ items: data.response, totalItems: data.count }, () => {
                        if(!this.props.rates || !this.props.rates.length === 0){
                            this.getRates();
                            return;
                        }
                    });
                };
                this.setState(({ block: false }));
            }
        }, `/api/hotel/UpSellAndCrossSell/v1/sellItem?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + params);
    }

    getRates = () => {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data && data.length > 0) {
                const channelId = data[0]?.id;
                
                getAPI(result => {
                    const { data, error } = result;
        
                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.response && data.response.length > 0) {
                            var rates = [];
        
                            data.response.forEach(room => {
                                room.rateList.forEach(rate => {
                                    if (rate.mappingList && rate.mappingList.length > 0 && rate.mappingList[0].id !== 0) {
                                        rates.push({ id: rate.id, code: rate.code });
                                    }
                                })
                            });
                        }
                        this.setState({ rates, block: false });
                        return;
                    }
                }, '/api/Rate/Rate/v1/RateMapping/' + channelId);
            }
            this.setState(({ block: false }));
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance?channelCode=HEYBE`);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    doSearch = () => {
        this.setState({
            pageIndex: 0
        }, () => this.getSellItems())
    }

    handlePagination = (value) => {
        this.setState({
            block: true,
            pageIndex: this.state.pageIndex + value
        }, () => this.getSellItems());
    }

    handleRates = (combo) =>{
        this.setState({ selectedRates: combo?.map(({value}) => value)});
    }

    render() {
        const { items, pageIndex, pageSize, totalItems, status } = this.state;
        const { intl, rates } = this.props;

        const statusOptions = getOffersStatus();
        const pricingType = getOffersServicePricingType();

        const ratesOptions = rates ? rates.map(({code, id}) => {return({label: code, value:id})}) : this.state.rates?.map(({code, id}) => {return({label: code, value:id})});
        
        return (
            <div>
                {!global.isMobile ? 
                    <OffersDesk 
                        items={items} 
                        pageIndex={pageIndex} 
                        pageSize={pageSize} 
                        totalItems={totalItems} 
                        statusOptions={statusOptions} 
                        pricingType={pricingType} 
                        status={status} 
                        ratesOptions={ratesOptions}
                        handleSelect={this.handleSelect} 
                        doSearch={this.doSearch} 
                        handlePagination={this.handlePagination} 
                        handleRates={this.handleRates} 
                        intl={intl}
                        block={this.state.block}
                        error={this.state.error}
                        fromBEConfigDashboard={this.props.fromBEConfigDashboard}
                        toggleDetails={this.props.toggleDetails}
                        history={this.props.history}
                    />
                :
                    <OffersMobile 
                        items={items} 
                        pageIndex={pageIndex} 
                        pageSize={pageSize} 
                        totalItems={totalItems} 
                        statusOptions={statusOptions} 
                        pricingType={pricingType} 
                        status={status} 
                        ratesOptions={ratesOptions}
                        handleSelect={this.handleSelect} 
                        doSearch={this.doSearch} 
                        handlePagination={this.handlePagination} 
                        handleRates={this.handleRates} 
                        intl={intl}
                        block={this.state.block}
                        error={this.state.error}
                        fromBEConfigDashboard={this.props.fromBEConfigDashboard}
                        toggleDetails={this.props.toggleDetails}
                        history={this.props.history}
                    />
                }
            </div>
        );
    }
}

export default injectIntl(Offers)