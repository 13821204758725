import moment from 'moment';
import React, { Component } from 'react';
import { Button, Card, Col, Row, Modal, ModalBody, ModalHeader, ModalFooter, FormGroup, Label } from 'reactstrap';
import { getAPI, postAPI, putAPI } from "../../Base/API";
import { StyledCard, SelectHotel, ActiveInactiveStatusBadge, SelectChannelConfig } from "../../Base/Common/CommonUIComponents";
import CustomSelect from '../../Base/Common/CustomSelect';
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';

export class DirtyDataDashboard extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.enableSendInfoControl = this.enableSendInfoControl.bind(this);
        this.enableOneSendInfoControl = this.enableOneSendInfoControl.bind(this);
        this.disableSendInfoControl = this.disableSendInfoControl.bind(this);
        this.disableOneSendInfoControl = this.disableOneSendInfoControl.bind(this);
        this.putSendInfoControl = this.putSendInfoControl.bind(this);
        this.state = {
            block: true,
            blockPowerOff: false,
            modal: false,
            // Search combo default values
            status: { value: true },
            minDeltaTime: { value: '00:05:00' },
            selectedSendInforControl: []
        }
    }

    componentDidMount() {
        this.getDirtyData();

    }

    getDirtyData() {
        this.setState({ block: true });

        let url = '?OnlyActiveChannelInstances=' + this.state.status.value;

        if (this.state.selectedHotel)
            url += '&HotelId=' + this.state.selectedHotel.value;

        if (this.state.minDeltaTime)
            url += '&MinDeltaTime=' + this.state.minDeltaTime.value;

        if (this.state.minNumberOfTries)
            url += '&MinNumberOfTries=' + this.state.minNumberOfTries.value;
        if (this.state.active)
            url += '&Active=' + this.state.active.value; 

        if (this.state.selectedChannelConfig)
            url += '&ChannelConfigId=' + this.state.selectedChannelConfig.value;

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ DirtyData: data, block: false });
            }
        }, '/api/Rate/DashBoard/GetHotelDirtyData' + url);
    }

    setFilterState = (name, combo) => {
        this.setState({[name]: combo});
    }

    
    getStatusComboValues() {
        return [
            { value: false, label: <FormattedMessage id="DirtyDataDashboard.All" /> },
            { value: true, label: <FormattedMessage id="DirtyDataDashboard.OnlyActiveChannels" /> }
            
        ];
    }

    getTimeSpanComboValues() {
        return [
            { value: '00:05:00', label: `5 ${this.props.intl.formatMessage({ id: "DirtyDataDashboard.Minutes" })}` },
            { value: '00:15:00', label: `15 ${this.props.intl.formatMessage({ id: "DirtyDataDashboard.Minutes" })}` },
            { value: '00:30:00', label: `30 ${this.props.intl.formatMessage({ id: "DirtyDataDashboard.Minutes" })}` },
            { value: '01:00:00', label: `1 ${this.props.intl.formatMessage({ id: "DirtyDataDashboard.Hour" })}` },
            { value: '02:00:00', label: `2 ${this.props.intl.formatMessage({ id: "DirtyDataDashboard.Hours" })}` }

        ];
    }
    getMinRetries() {
        return [
            { value: '1', label: '> 1' },
            { value: '2', label: '> 2' },
            { value: '3', label: '> 3' },
            { value: '4', label: '> 4' },
            { value: '5', label: '> 5' }

        ];
    }

    getStatus() {
        return [
            { value: true, label: 'Active' },
            { value: false, label: 'Inactive' },

        ];
    }

    resetRetries() {

        if (this.state.selectedSendInforControl.length === 0) {
            return;
        }

        let req = this.state.selectedSendInforControl.map(el => el.id);

        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="DirtyDataDashboard.RetriesResetSucessfuly" />, <FormattedMessage id="General.Success" />); 
                this.setState({ selectedSendInforControl: [], block: false }, () => this.getDirtyData());
               
            }
        }, '/api/Rate/DashBoard/ResetSendInfoRetries', req);


    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    updateStatus() { }

    enableOneSendInfoControl(id) {
        const url = `/api/Rate/DashBoard/EnableSendInfoControl?ids=${id}`;
        this.putSendInfoControl(url, "DirtyDataDashboard.EnableOneSendInfoControlSuccess");
    }

    enableSendInfoControl() {
        const { selectedHotel, selectedChannel } = this.state;
        const url = `/api/Rate/DashBoard/EnableSendInfoControl?hotelId=${selectedHotel && selectedHotel.value}&channelConfigId=${selectedChannel && selectedChannel.value}`;
        this.putSendInfoControl(url, "DirtyDataDashboard.EnableSendInfoControlSuccess");
    }

    disableOneSendInfoControl(id) {
        const url = `/api/Rate/DashBoard/DisableSendInfoControl?ids=${id}`;
        this.putSendInfoControl(url, "DirtyDataDashboard.DisableOneSendInfoControlSuccess");
    }

    disableSendInfoControl() {
        const { selectedHotel, selectedChannel } = this.state;
        const url = `/api/Rate/DashBoard/DisableSendInfoControl?hotelId=${selectedHotel && selectedHotel.value}&channelConfigId=${selectedChannel && selectedChannel.value}`;
        this.putSendInfoControl(url, "DirtyDataDashboard.DisableSendInfoControlSuccess");
    }

    putSendInfoControl(url, msg) {
        this.setState({ blockPowerOff: true });
        putAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockPowerOff: false });
                return;
            }
            handleNotification(data, <FormattedMessage id={msg} />, <FormattedMessage id="DirtyDataDashboard.Success" />);
            this.setState({ blockPowerOff: false });
        }, url);
    }

    render() {
        const { blockPowerOff, selectedHotel, selectedChannel, selectedSendInforControl } = this.state;

        const helpContent =
            <div>
                TODO
                <hr />
            </div>

        const columns = [
            {
            dataField: 'hotelName2',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.HotelName" }),
            sort: true
            },
            {
                dataField: 'channelName',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.ChannelName" }),
                sort: true
            },
            {
                dataField: 'roomDescription',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.RoomDescription" }),
                sort: true
            },
            {
                dataField: 'priceLastSendDate',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.LastPriceSent" }),
                sort: true,
                formatter: (cell, row) => {
                    return <div>{!moment(row.priceLastSendDate, 'YYYY-MM-DDTHH:mm:ss.SSS').isAfter(moment(row.priceLastUpdate, 'YYYY-MM-DDTHH:mm:ss.SSS')) ?
                        <i className="text-warning  mr-1 fas fa-exclamation-triangle "></i>
                        : ''
                    }
                        {moment(row.priceLastSendDate, 'YYYY-MM-DDTHH:mm:ss.SSS').fromNow()}
                    </div>
                }
            },
            {
                dataField: 'availabilityLastSendDate',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.LastAvailabilitySent" }),
                sort: true,
                formatter: (cell,row) => {
                    return <div>  {
                        !moment(row.availabilityLastSendDate, 'YYYY-MM-DDTHH:mm:ss.SSS').isAfter(moment(row.availabilityLastUpdate, 'YYYY-MM-DDTHH:mm:ss.SSS')) ?
                            <i className=" text-warning  mr-1  fas fa-exclamation-triangle"></i>
                            : ''
                    }
                        {moment(row.availabilityLastSendDate, 'YYYY-MM-DDTHH:mm:ss.SSS').fromNow()}

                    </div>
                }
            },
            {
                dataField: 'restrictionsLastSendDate',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.LastRestrictionsSent" }),
                sort: true,
                formatter: (cell, row) => {
                    return  <div>
                        {
                            !moment(row.restrictionsLastSendDate, 'YYYY-MM-DDTHH:mm:ss.SSS').isAfter(moment(row.restrictionsLastUpdate, 'YYYY-MM-DDTHH:mm:ss.SSS')) ?
                                <i className="text-warning  mr-1  fas fa-exclamation-triangle"></i>
                                : ''
                        }
                        {moment(row.restrictionsLastSendDate, 'YYYY-MM-DDTHH:mm:ss.SSS').fromNow()}
                    </div>
                }
            },
            {
                dataField: 'priceLastTry',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.PriceLastTry" }),
                sort: true,
                formatter: (cell, row) => {
                    return <div>{moment(cell, 'YYYY-MM-DDTHH:mm:ss.SSS').fromNow()}</div>
                }
            },
            {
                dataField: 'availabilityLastTry',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.AvailabilityLastTry" }),
                sort: true,
                formatter: (cell, row) => {
                    return <div>{moment(cell, 'YYYY-MM-DDTHH:mm:ss.SSS').fromNow()}</div>
                }
            },
            {
                dataField: 'restrictionsLastTry',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.RestricitonsLastTry" }),
                sort: true,
                formatter: (cell, row) => {
                    return <div>{moment(cell, 'YYYY-MM-DDTHH:mm:ss.SSS').fromNow()}</div>
                }
            },
            {
                dataField: 'priceTries',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.PriceRetries" }),
                sort: true,
            },
            {
                dataField: 'availabilityTries',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.AvailabilityRetries" }),
                sort: true
            },
            {
                dataField: 'restrictionsTries',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.RestricitonsRetries" }),
                sort: true
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.Status" }),
                formatter: (cell) => {
                    return <ActiveInactiveStatusBadge status={cell} />
                },
                sort: true
            }
        ];

        const selectRow = {
            mode: 'checkbox',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                if (isSelect) this.setState({ selectedSendInforControl: [...this.state.selectedSendInforControl, row] });
                else this.setState({ selectedSendInforControl: this.state.selectedSendInforControl.filter(el => el !== row.id) }); 
            }
        };

        const columnsPowerOff = [
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.HotelName" }),
                sort: true
            },
            {
                dataField: 'channelName',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.ChannelName" }),
                sort: true
            },
            {
                dataField: 'roomDescription',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.RoomDescription" }),
                sort: true
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "DirtyDataDashboard.Status" }),
                formatter: (cell) => {
                    return <ActiveInactiveStatusBadge status={cell} />
                },
                sort: true
            },
            {
                formatter: (_, row) => {
                    return <div>
                        <Button className="btn btn-host btn-sm mr-1" onClick={() => this.enableOneSendInfoControl(row.id)}>
                            <FormattedMessage id="DirtyDataDashboard.Activate"/>
                        </Button>
                        <Button className="btn btn-host btn-sm" onClick={() => this.disableOneSendInfoControl(row.id)}>
                            <FormattedMessage id="DirtyDataDashboard.Deactivate" />
                        </Button>
                    </div>
                },
                sort: true
            }
        ];

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-hourglass-end'} title={'DirtyDataDashboard.title'} help={helpContent}>
                <Modal isOpen={this.state.modal} onHide={this.toggleModal} fade={false}>
                    <ModalHeader toggle={this.toggleModal}>
                        <p className="modal-title w-100 font-weight-bold">
                            <FormattedMessage id="SyncEvents.MessageReceived" />
                        </p>
                    </ModalHeader>
                    <ModalBody>
                        <BlockUi tag="div" blocking={blockPowerOff}>
                            {
                                selectedSendInforControl && selectedSendInforControl.length > 0 ?
                                    <CustomTable
                                        data={selectedSendInforControl}
                                        columns={columnsPowerOff}
                                        shadow={false}
                                        search={false}
                                        remote={false}
                                    />
                                    :
                                    <div>
                                        <FormGroup row>
                                            <Col className="text-right">
                                                <Button className="btn btn-host mr-1" onClick={this.enableSendInfoControl} disabled={!selectedHotel && !selectedChannel}>
                                                    <FormattedMessage id="DirtyDataDashboard.Activate" />
                                                </Button>
                                                <Button className="btn btn-host" onClick={this.disableSendInfoControl} disabled={!selectedHotel && !selectedChannel}>
                                                    <FormattedMessage id="DirtyDataDashboard.Deactivate" />
                                                </Button>
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="hotel" sm={4}>
                                                <FormattedMessage id="DirtyDataDashboard.Hotel" />
                                            </Label>
                                            <Col sm={8}>
                                                <SelectHotel name={'selectedHotel'} isMulti={false} icon={'fas fa-hotel'} value={selectedHotel ? selectedHotel.value : ''} onChangeFunc={this.setFilterState.bind(this)} />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label for="hotel" sm={4}>
                                                <FormattedMessage id="DirtyDataDashboard.Channel" />
                                            </Label>
                                            <Col sm={8}>
                                                <SelectChannelConfig name={'selectedChannel'} isMulti={false} value={selectedChannel ? selectedChannel.value : ''} onChangeFunc={this.setFilterState.bind(this)} />
                                            </Col>
                                        </FormGroup>
                                    </div>
                            }
                        </BlockUi>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn btn-host" onClick={this.toggleModal}><FormattedMessage id="SyncEvents.Close" /></Button>
                    </ModalFooter>
                </Modal>
                    
                <Row>
                    <Col className="col-2">
                        <SelectChannelConfig name={'selectedChannelConfig'} icon={'fas fa-plug'} value={this.state.selectedChannelConfig ? this.state.selectedChannelConfig.value : ''} onChangeFunc={this.setFilterState.bind(this)} />
                    </Col>
                    <Col className="col-2">
                        <SelectHotel name={'selectedHotel'} isMulti={false} icon={'fas fa-hotel'} value={this.state.selectedHotel ? this.state.selectedHotel.value : ''} onChangeFunc={this.setFilterState.bind(this)} />
                    </Col>
                    <Col className="col-2">
                        <CustomSelect required={true} icon={'fas fa-microphone-slash'} isSearchable placeholder={<FormattedMessage id="DirtyDataDashboard.ChooseStatus"/>} defaultValue={this.getStatusComboValues()[1]} options={this.getStatusComboValues()} onChange={this.setFilterState.bind(this, "status")} />
                    </Col>
                    <Col className="col-2">
                        <CustomSelect required={false} icon={'fas fa-stopwatch'} isSearchable defaultValue={this.getTimeSpanComboValues()[0]} options={this.getTimeSpanComboValues()} onChange={this.setFilterState.bind(this, "minDeltaTime")} />
                    </Col>
                    <Col className="col-2">
                        <CustomSelect required={false} isClearable icon={'fas fa-stopwatch'} isSearchable placeholder={<FormattedMessage id="DirtyDataDashboard.ChooseMinRetries"/>} options={this.getMinRetries()} onChange={this.setFilterState.bind(this, "minNumberOfTries")} />
                    </Col>
                    <Col className="col-1 pr-0">
                        <CustomSelect required={false} isClearable icon={'fas fa-power-off'} isSearchable placeholder={<FormattedMessage id="DirtyDataDashboard.Status" />} options={this.getStatus()} onChange={this.setFilterState.bind(this, "active")} />
                    </Col> 
                    <Col className="text-right col-1">
                        <Button className="btn btn-sm btn-host" onClick={this.getDirtyData.bind(this)}><i className="fas fa-search"></i></Button>
                    </Col>
                </Row>
                <hr />
                <Row className="mb-3">
                    <Col className="text-right">
                        <Button className="btn btn-sm btn-host mr-2" onClick={this.toggleModal}><i className="fas fa-power-off"></i></Button>

                        <Button className="btn btn-sm btn-host " onClick={this.resetRetries.bind(this)} disabled={this.state.selectedSendInforControl.length===0}  ><i className="fas fa-redo"></i></Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">
                            <CustomTable
                                data={this.state.DirtyData ? this.state.DirtyData : []}
                                columns={columns}
                                page={this.state.currentPage}
                                sizePerPage={this.state.resultsPerPage}
                                totalSize={this.state.DirtyData && this.state.DirtyData.length}
                                shadow={false}
                                search={false}
                                remote={false}
                                selectRow={selectRow}
                                showTotal={true}
                                hideSizePerPage={false}
                            />
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(DirtyDataDashboard)