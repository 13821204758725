import React, { Component } from 'react';
import { Row, Col, Button, CustomInput, Card, CardBody, CardHeader } from 'reactstrap';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import { postAPI } from "../../Base/API";
import Authorization from "../../Base/Authorization";
import { ErrorAlert } from '../../Common/ErrorAlert';
import BlockUi from 'react-block-ui';

class SelectRates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            block: false,
            sellItemRates: this.props.sellItemRates || []
        }
    }

    saveRates = (e) => {
        e.preventDefault();
        this.setState(({ block: true }));

        const body = this.state.sellItemRates.map(el => (el.id));

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="Offers.OfferSaved" />, <FormattedMessage id="General.Success" />);

                    this.setState({ block: false, error: errorMessage });
                }
            }
        }, `/api/hotel/UpSellAndCrossSell/v1/sellItem/${this.props.sellItemId}/Rate`, body );
    }

    handleRates = (e, rateId) => {
        const { checked } = e.target;
        let { sellItemRates } = this.state;

        if (!checked) {
            sellItemRates = sellItemRates.filter(el => el.id !== rateId);
        }
        else {
            sellItemRates.push({ id: rateId });
        }

        this.setState({ sellItemRates })
    }

    handleAllRates = (isToRemove) => {
        let { sellItemRates } = this.state;

        if (isToRemove) {
            sellItemRates = [];
        }
        else {
            sellItemRates = this.props.rates.map(el => ({ ...el }));
        }

        this.setState({ sellItemRates });
    }

    render() {
        const { sellItemRates, error, block } = this.state;
        const { rates } = this.props;

        const allRatesSelected = sellItemRates && rates.every(r => sellItemRates.some(rc => rc.id === r.id));

        return (
            <Card>
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block}>
                    <CardHeader className="border-bottom bg-white py-2" style={{ display:'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div>
                            <i className='fas fa-gift mr-1'/>
                            <FormattedMessage id='Offers.Rates'/>
                        </div>
                        <Authorization
                            perform="offers:save"
                            yes={() => (
                                <Col className="text-right">
                                    <Button className="btn btn-host btn-sm" onClick={this.saveRates}>
                                        <span className="fas fa-save" />
                                    </Button>
                                </Col>
                            )}
                            no={() => ""}
                        />
                    </CardHeader>
                    <CardBody>
                        <Row className="mb-3">
                            <Col className="text-right">
                                <Button className="btn btn-host btn-sm" onClick={() => this.handleAllRates(allRatesSelected)}>
                                    {allRatesSelected ? <FormattedMessage id="generic.RemoveAll" /> : <FormattedMessage id="generic.SelectAll" />}
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            {rates && rates.map((rate, key) =>
                                <Col key={key} className="col-4 pb-2 small">
                                    <CustomInput
                                        type="checkbox"
                                        name={rate.id}
                                        id={rate.id}
                                        label={rate.code}
                                        checked={sellItemRates && sellItemRates.some(elem => elem.id === rate.id)}
                                        onChange={(e) => this.handleRates(e, rate.id)}
                                    />
                                </Col>
                            )}
                        </Row>
                    </CardBody>
                </BlockUi>
            </Card>
        );
    }
}

export default injectIntl(SelectRates)