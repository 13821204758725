import React, { Component } from 'react';
import { CardHeader, Card, CardBody, ModalBody, Modal, ModalHeader } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { RoomBar } from './RoomBar';
import { RoomRateBar } from './RoomRateBar';
import EditRate from '../Rate/EditRate';
import RoomSetupFilters from './RoomSetupFilters';
import InfiniteScroll from 'react-infinite-scroller';
import BlockUi from 'react-block-ui';
import RateBulkOperations from '../Rate/RateBulkOperations';

export class RoomSetup extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
       
        this.state = {
            activeTab: '1',
            modal: false,
            selectedRoom: {},
            selectedChannel: null,
            selectedRates: [],
            selectedStatus: null,
            selectedLabel: null,
            idx: 0,
            idxAdder: 1,
            bulkOperationsModal: false
        };
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            copiedRate: null
        }));
    }

    getRoomCategoriesList = () => {
        let res = [];
        
        this.props.RoomCategories && this.props.RoomCategories.map(room =>
            res.push({ "label": room.description, "value": room.id})
        )
        return res;
    }

    getRateCodeList = () => {
        let res = [];
        this.props.ratecode && this.props.ratecode.map(rate =>
            res.push({ "label": rate.code, "value": rate.id })
        )
        return res;
    }

    getChannelsList = () => {
        let res = [];
        this.props.channelinstance && Object.keys(this.props.channelinstance).map(key =>
            res.push({ "label": this.props.channelinstance[key].name + ' (' + this.props.channelinstance[key].propertyCode + ')', "value": key })
        )
        return res;
    }

    copyRate = (rate) => {
        this.setState({
            modal: true,
            copiedRate: {
                ...rate,
            }
        });
    }

    handleRateFilters = (combo, field) => {
        this.setState({
            [field]: combo || []
        }, () => this.props.filterRate(this.state.selectedRates, this.state.selectedChannel, this.state.selectedInv, this.state.selectedStatus, this.state.selectedLabel))
    }

    showRoom = (roomCategory) => {
        return this.props.ratecode.some(el => el.roomCategoryId === roomCategory.id && el.visible)
    }
    
    canLoad = () => {
        const { idx, idxAdder } = this.state;
        this.setState({ idx: idx + idxAdder });
    }

    toggleBulkOperations = () => {
        this.setState(prevState => ({
            bulkOperationsModal: !prevState.bulkOperationsModal
        }))
    }

    getCsvData = () => {
        const csvData = [['Room Code', 'Room Description', 'Rate Code', 'Rate Description', 'Dependency', 'Occupations', 'Channel', 'Property Code']];

        this.props.RoomCategories && this.props.ratecode && this.props.pricelist && this.props.ratecodepax && this.props.RoomCategories.forEach(room => {

            if (room.visible) {
                this.props.ratecode.forEach(rate => {
                    if (rate.roomCategoryId === room.id && rate.visible) {
                        let dependency = '';
                        const priceList = this.props.pricelist.find(prl => prl.id === rate.pricelistId);
                        if (priceList) {
                            function getDependentRateCode(ratecodes, priceList) {
                                return ratecodes.reduce((acc, curr) => {
                                    if (acc !== ``) {
                                        return acc;
                                    }
                                    if (curr.pricelistId === priceList.dependencyMasterId && curr.roomCategoryId === priceList.dependencyReferenceRoomCategory && curr.packageId === priceList.dependencyReferencePackage) {
                                        return curr.code;
                                    }
                                    else {
                                        return acc;
                                    }
                                }, ``)
                            }

                            dependency = priceList.dependencyMasterId === null ? `Main` : `Derived Price ${priceList.dependencyPercentage !== 0 ? `${priceList.dependencyPercentage}%` : `${priceList.dependencyAmount}`} From ${getDependentRateCode(this.props.ratecode, priceList)}`;
                        }

                        const occupations = this.props.ratecodepax
                            .filter(pax => pax.active && pax.rateCodeId === rate.id)
                            .map(pax => pax.description)
                            .join(',');


                        if (rate.channels && rate.channels.length > 0) {
                            rate.channels.forEach(channel => {
                                const channelInstance = this.props.channelinstance[channel.channelInstanceId];

                                if (channelInstance) {
                                    const isSameChannel = csvData.some(el => el[0] === room.code && el[2] === rate.code && el[6] === channelInstance.channelConfigCode && el[7] === channelInstance.propertyCode);

                                    if (!isSameChannel) {
                                        csvData.push([room.code, room.description, rate.code, rate.description, dependency, occupations, channelInstance.channelConfigCode, channelInstance.propertyCode]);
                                    }
                                }
                            })
                        }
                        else {
                            csvData.push([room.code, room.description, rate.code, rate.description, dependency, occupations]);
                        }
                    }
                });
            }
        });

        return csvData;
    }

    render() {
        const inventoryList = [
            {
                'label': <FormattedMessage id="EditRate.InventoryOwn" />,
                'value': 1
            },
            {
                'label': <FormattedMessage id="EditRate.InventoryReal" />,
                'value': 0
            }
        ];

        const rateStatusOptions = [
            { value: true, label: <FormattedMessage id="generic.active" /> },
            { value: false, label: <FormattedMessage id="generic.inactive" /> }
        ]

        const pricelistWithNoDependencies = this.props.pricelist && this.props.pricelist.filter(pricelist => pricelist.dependencyMasterId == null);
        const ratesToDepend = this.props.ratecode && this.props.ratecode
            .filter(ratecode => pricelistWithNoDependencies && pricelistWithNoDependencies.find(pricelist => ratecode.pricelistId === pricelist.id))
            .map(ratecode => ({ value: ratecode.id, label: ratecode.description, currencyCode: ratecode.currencyCode }));


        const visibleRates = this.props.ratecode?.filter(({visible}) => visible).map(({roomCategoryId}) => roomCategoryId).sort().filter((item, pos, ary) => !pos || item != ary[pos - 1])
        const maxRooms = this.props.RoomCategories?.filter(({ visible, id }) => visible && visibleRates.includes(id))?.length;

        return (
            <div>
                {this.state.modal ?
                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                        <ModalHeader toggle={this.toggleModal}  > <FormattedMessage id="AddRate.Rate" /> </ModalHeader>
                        <ModalBody className="modal-lg p-0">
                            <EditRate
                                onCreateRoomRate={() => this.props.onCreateRoomRate(this.state.selectedRates, this.state.selectedChannel, this.state.selectedInv, this.state.selectedStatus)}
                                onCloseModal={() => this.setState({ modal: false, copiedRate: null })}
                                ratesToDepend={ratesToDepend}
                                updateRateCode={this.props.updateRateCode}
                                copiedRate={this.state.copiedRate}
                                labels={this.props.labels}
                                updateLabels={this.props.updateLabels}
                                getLabels={this.props.getLabels}
                                deleteLabelModal={this.props.deleteLabelModal}
                                selectedLabelDelete={this.props.selectedLabelDelete}
                                toggleDeleteLabelModal={this.props.toggleDeleteLabelModal}
                                deleteLabel={this.props.deleteLabel}
                                updateLabelsOnChangeSelect={this.props.updateLabelsOnChangeSelect}
                            />
                        </ModalBody>
                    </Modal>
                    : ''}

                {this.state.bulkOperationsModal ?
                    <RateBulkOperations
                        rateList={this.props.ratecode}
                        modal={this.state.bulkOperationsModal}
                        toggleModal={this.toggleBulkOperations}
                        labels={this.props.labels ? this.props.labels.filter(el => el.isToCreate !== true).map(el => ({ value: el.value, label: el.label })) : []}
                        updateRateCodeInBulk={this.props.updateRateCodeInBulk}
                    />
                : ''}

                <RoomSetupFilters
                    getRoomCategoriesList={this.getRoomCategoriesList}
                    filterRoom={this.props.filterRoom.bind(this)}
                    getRateCodeList={this.getRateCodeList}
                    handleRateFilters={this.handleRateFilters}
                    getChannelsList={this.getChannelsList}
                    inventoryList={inventoryList}
                    csvData={this.getCsvData()}
                    addFunction={this.toggleModal}
                    labelOptions={this.props.filteredLabels}
                    hideLabels={this.props.hideLabels}
                    rateStatusOptions={rateStatusOptions}
                    toggleBulkOperations={this.toggleBulkOperations}
                />

                <div className="my-4 h-100 overflow-auto">
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.canLoad}
                        element='div'
                        hasMore={this.state.idx < (maxRooms - 1)}
                        loader={<div style={{ width: '100%' }} key="loader"><BlockUi tag="div" blocking={true} /></div>}
                        threshold={'100'}
                    >
                        {this.props.RoomCategories && this.props.RoomCategories.filter((el) => (el.visible && visibleRates?.includes(el.id))).filter((el, k) => this.state.idx >= k).map((roomCategory, key) =>
                            roomCategory.visible && this.showRoom(roomCategory) ?
                                <div key={key} className="pb-2 ">
                                    <Card className="bg-white shadow border-0" >
                                        <CardHeader className="card-header-gridview border-bottom-1 border-host bg-white py-2">
                                            <RoomBar roomType={roomCategory} />
                                        </CardHeader>
                                        <CardBody className="card-header-gridview border-0 pt-0" style={{ fontSize: '13px' }}>
                                            {this.props.ratecode && this.props.ratecode.map((rate, key2) => {

                                                return rate.visible && rate.roomCategoryId === roomCategory.id ?
                                                    <RoomRateBar
                                                        room={roomCategory}
                                                        rate={rate}
                                                        channels={rate.channels}
                                                        channelinstance={this.props.channelinstance}
                                                        pricelist={this.props.pricelist}
                                                        action={this.changeChannelVisibility}
                                                        key={key2}
                                                        ratecodepax={this.props.ratecodepax}
                                                        ratecode={this.props.ratecode}
                                                        ratesToDepend={ratesToDepend}
                                                        updateRateCode={this.props.updateRateCode}
                                                        copyRate={this.copyRate}
                                                        labels={this.props.labels}
                                                        updateLabels={this.props.updateLabels}
                                                        getLabels={this.props.getLabels}
                                                        deleteLabelModal={this.props.deleteLabelModal}
                                                        selectedLabelDelete={this.props.selectedLabelDelete}
                                                        toggleDeleteLabelModal={this.props.toggleDeleteLabelModal}
                                                        deleteLabel={this.props.deleteLabel}
                                                        updateLabelsOnChangeSelect={this.props.updateLabelsOnChangeSelect}
                                                    />
                                                    : ''
                                            })}
                                        </CardBody>
                                    </Card>
                                </div>
                            : ''
                        )}
                    </InfiniteScroll>
                </div>
            </div>          
        );
    }
}