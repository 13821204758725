import React, { Component } from 'react';
import { Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import moment from 'moment';

export class CcModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            cc: {}
        };
    }

    componentDidMount() {
        const { toggle, reservationId } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);

                    this.setState({ error: errorMessage, block: false }, _ => toggle('ccModal', null));
                }
                else if (data.response && data.response.length > 0) {
                    this.setState({ error: errorMessage, block: false, cc: data.response[0] });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/Reservation/v1/reservationCCView/${reservationId}`);
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { block, error, cc } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={_ => toggle('ccModal', null)}>
                <ModalHeader toggle={_ => toggle('ccModal', null)}>
                    <FormattedMessage id="ReservationDetail.CcData" />
                </ModalHeader>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <h5>
                            {
                                cc.accesses ?
                                    <FormattedMessage id="ReservationDetail.AccessesDisplayMessage" values={{ accesses: cc.accesses + 1 }} />
                                    :
                                    ''
                            }
                        </h5>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <b>
                                    <FormattedMessage id="Payments.Brand" />
                                </b>
                            </Col>
                            <Col className="col-4">
                                <span>
                                    {cc.cardCode}
                                </span>
                            </Col>
                            <Col className="col-2">
                                <b>
                                    <FormattedMessage id="Payments.CardNumber" />
                                </b>
                            </Col>
                            <Col className="col-4">
                                <span>
                                    {cc.cardNumber}
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <b>
                                    <FormattedMessage id="Payments.Last4" />
                                </b>
                            </Col>
                            <Col className="col-4">
                                <span>
                                    {cc.lastDigits}
                                </span>
                            </Col>
                            <Col className="col-2">
                                <b>
                                    <FormattedMessage id="Payments.ExpYear" />/<FormattedMessage id="Payments.ExpMonth" />
                                </b>
                            </Col>
                            <Col className="col-4">
                                <span>
                                    {cc.expireDate}
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <b>
                                    <FormattedMessage id="Payments.EffectiveDate" />
                                </b>
                            </Col>
                            <Col className="col-4">
                                <span>
                                    {
                                        cc.effectiveDate ?
                                            moment(cc.effectiveDate).format("YYYY-MM-DD HH:mm:ss")
                                            :
                                            ''
                                    }
                                </span>
                            </Col>
                            <Col className="col-2">
                                <b>
                                    <FormattedMessage id="Payments.SeriesCode" />
                                </b>
                            </Col>
                            <Col className="col-4">
                                <span>
                                    {cc.seriesCode}
                                </span>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <b>
                                    
                                </b>
                            </Col>
                            <Col className="col-4">
                                <span>

                                </span>
                            </Col>
                            <Col className="col-2">
                                <b>
                                    <FormattedMessage id="Payments.IsVirtualCc" />
                                </b>
                            </Col>
                            <Col className="col-4">
                                <span>
                                    {
                                        cc.isVirtualCc === undefined ?
                                            ''
                                            :
                                            cc.isVirtualCc ?
                                                <i className=" text-success fas fa-check"/>
                                                :
                                                <i className="text-danger fas fa-times" />
                                    }
                                </span>
                            </Col>
                        </Row>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}