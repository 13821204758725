import moment, { relativeTimeRounding } from 'moment';
import React, { Component } from 'react'
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, CustomInput, Input, Nav, NavItem, NavLink, PopoverBody, Row, TabContent, TabPane } from 'reactstrap';
import { CommonHelper } from '../../Base/Common/CommonUIComponents';
import CustomSelect from '../../Base/Common/CustomSelect';
import classnames from "classnames";

class BulkUpdateCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rateCodes: [],
            dates: [],
            value: '',
            startDate: null,
            endDate: null,
            focusedInput: "startDate",
            priceType: 'modify',
            priceModify: 'increment', 
            occupationOptions: [],
            results: [],
            selectedOcc: [],
            priceModifyOptions: [
                {
                    value: 'increment',
                    label: '€'
                },
                {
                    value: 'percentage',
                    label: '%'
                }
            ],
            priceTypeOptions: [
                {
                    value: 'amount',
                    label: <FormattedMessage id="Inventory.Amount" />
                },
                {
                    value: 'modify',
                    label: <FormattedMessage id="BulkUpdate.Modify" />
                }
            ],
            activeTab: '1',
            restrictionsList: [
                { name: 'stopSales', type: 'bool' },
                { name: 'closeToDeparture', type: 'bool' },
                { name: 'closeToArrival', type: 'bool' },
                { name: 'minimumStayLen', type: 'number' },
                { name: 'maximumStayLen', type: 'number' },
                { name: 'minAdvancedBookingOffset', type: 'number' },
                { name: 'maxAdvancedBookingOffset', type: 'number' }
            ],
            selectedRest: 'stopSales',
            appliedRestrictions: []
        }
    }

    componentDidMount(){
        const { selectedRates, rateList, intl,hasFlexChildPrices } = this.props;
        const { occupationOptions } = this.state;

        const rateCodes = selectedRates.map(({label, value}) => {
            const currentRate = rateList?.find(({id}) => id === value);
            const isDependent = currentRate?.dependencyParent && currentRate?.dependencyParent > 0;
            return({
                active: !isDependent,
                name: label,
                id: value,
                disabled: isDependent
            })
        });

        rateList.filter(({id}) => rateCodes.filter((r) => r.id === id).length > 0).forEach((r) => {
            const child = r.defaultPax.find(({active, childrens}) => active && childrens);

            if(child && !occupationOptions.find(({value}) => value === "d2")){
                occupationOptions.push({
                    value: "d2",
                    label: intl.formatMessage({ id: hasFlexChildPrices && r.canD3 ? "BulkUpdate.FirstChildren" : "BulkUpdate.Children" })
                });

                if(hasFlexChildPrices && r.canD3) {
                    occupationOptions.push({
                        value: "d3",
                        label: intl.formatMessage({ id: "BulkUpdate.OtherChild" })
                    });
                }
            };
            
            const filteredPax = r.defaultPax.filter(({active, derivedAmount, adults}) => active && derivedAmount === null && adults > 0);

            filteredPax.forEach(pax => {
                const currentIndex = occupationOptions.findIndex(({value}) => value === pax.adults);
                if(currentIndex === -1) {
                    occupationOptions.push({
                        value: pax.adults,
                        label: `${pax.adults} ${intl.formatMessage({ id: "BulkUpdate.Adults" })}`
                    });
                }
            }); 
        });
        
        const selectedOcc = [occupationOptions[0]?.value === "d2" ? occupationOptions[1]?.value === "d3" ? occupationOptions[2] : occupationOptions[1] : occupationOptions[0]];

        this.setState({ rateCodes, occupationOptions, selectedOcc });
    }

    handleCheckBox = (e, key) => {
        const { rateCodes } = this.state;
        const { checked } = e.target;

        rateCodes[key].active = checked;

        this.setState({ rateCodes });
    }

    selectChange = (e, name) => {
        this.setState({ [name]: e.value });
    }

    isOutsideRange = (day) => {
        const { next14Days } = this.props;

        if(day.isBefore(moment(), 'date')) return true;
        if(day.isAfter(moment().add(2, 'years'), 'date')) return true;
        if(day.isBefore(next14Days[0], 'date')) return true;
        if(day.isAfter(next14Days[next14Days.length - 1], 'date')) return true;
        return false;
    }

    isDayBlocked = (day) => {
        const { dates } = this.state;
        const val = dates.find(date => date.isSame(day, 'date')) ? true : false;
        return val;
    }

    handlePriceType = (e) => {
        const { priceType } = this.state;
        if(priceType !== e) this.setState({ priceType: e }, () =>{
            if(this.state.value) this.onChange({target:{value: this.state.value}})
        });
    }

    onDayClick = (day) => {
        const { dates, startDate, value, activeTab } = this.state;

        if(this.isOutsideRange(day)) return;
        
        const current = dates.findIndex((el) => el.isSame(day, 'date'));
        if(current > -1) {
            dates.splice(current, 1);
            this.setState({ dates });
        }
        else if(startDate !== null) {
            if(day.isBefore(startDate, 'date')){
                this.setState({ startDate: day });
            }
            else{
                const endDate = day.clone();
                const diff = endDate.diff(startDate, 'days');

                for (var i = 0; i <= diff; i++) {
                    const day = startDate.clone().add(i, 'days');
                    if(!dates.find((d) => d.isSame(day, 'date'))) dates.push(day);
                };
                this.setState({ dates, startDate: null, endDate: null }, () => {
                    const e = {target:{value}};
                    if(value && activeTab === "1") this.onChange(e);
                    if(value !== '' && value !== null && activeTab === "2") this.onChangeRestriction(e);
                });
            }
        }
        else {
            this.setState({ startDate: day });
        }
    }

    renderDayContents = (day) => {
        return(<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', cursor: 'pointer' }} onClick={e => this.onDayClick(day)}>{day.format('D')}</div>)
    }

    void = () => {}

    changeOcc = (e) => {
        const { value, activeTab } = this.state;
        this.setState({ selectedOcc: e}, () => {
            const e = {target:{value: value}};
            if(value && activeTab === "1") this.onChange(e);
        });
    }

    submitBulk = (e) => {
        e.preventDefault();
        const { results, appliedRestrictions, activeTab } = this.state;
        const { submitBulk } = this.props;

        this.setState({ value: '', selectedOcc: [], results: [], appliedRestrictions: [] }, () => {
            if(activeTab === "1") submitBulk(results);
            else submitBulk(appliedRestrictions, true);
        });
    }

    onChange = (e) => {
        const { selectedOcc, dates, results, priceType, rateCodes, priceModify } = this.state;
        const { rateList } = this.props;
        const { value } = e.target;
        const activeRates = rateCodes?.filter(({active}) => active);

        if(!activeRates || !dates || !selectedOcc) return;

        activeRates.forEach(rate => {
            dates.forEach(date => {
                selectedOcc.forEach(occ => {
                    const position = (occ.value !== "d2" && occ.value !== "d3") ? (occ.value - 1) : occ.value;
                    const currentRate = rateList && rateList.find(({id}) => id === rate.id);

                    if(!currentRate?.dependencyParent && currentRate?.defaultPax?.filter((p) => p.active && ((occ.value === "d2" || occ.value === "d3") ? p.childrens > 0 : p.adults === occ.value)).length > 0) {
                        let current = results.find(({rateId, pPos, day}) => rateId === rate.id && pPos === position && date.isSame(day, 'date'));
                        if(!current){
                            const newChange = {
                                target:{
                                    value : parseFloat(value).toFixed(2).toString(),
                                    priceType,
                                    priceModify,
                                    name: (position === "d2" || position === "d3") ? "childPrice" : "adultPrices",
                                    isD3: position === "d3"
                                },
                                rateId: rate.id,
                                pPos: position,
                                day: date
                            };
                            results.push(newChange);
                        }
                        else{
                            current.target.value = parseFloat(value).toFixed(2).toString();
                            current.target.priceType = priceType;
                            current.target.priceModify = priceModify;
                        }
                    }
                });
            });
        });
        
        this.setState({ results, value: value });
    }

    toggleTab = (val) => {
        this.setState({ activeTab: val, results: [], appliedRestrictions: [], rateCodes: this.state.rateCodes.map((r) => {
            if(r.disabled)
                r.active = false;
            return(r)
        }) });
    }

    onChangeRestriction = (e) => {
        const { selectedRest, dates, appliedRestrictions, rateCodes, restrictionsList } = this.state;
        const activeRates = rateCodes?.filter(({active}) => active);
        const { value } = e?.target;

        const type = restrictionsList.find(({name}) => name === selectedRest);
        
        if(!activeRates || !dates || !type) return;

        activeRates.forEach(rate => {
            dates.forEach(date => {
                let current = appliedRestrictions.find(({target, rateId, day}) => rateId === rate.id && date.isSame(day, 'date') && target.name === selectedRest);
                if(!current){
                    appliedRestrictions.push({
                        target:{
                            value,
                            name: selectedRest
                        },
                        rateId: rate.id,
                        day: date
                    });
                    current = appliedRestrictions.find(({target, rateId, day}) => rateId === rate.id && date.isSame(day, 'date') && target.name === selectedRest);
                };
                current.target.value = value;
            });
        });
        
        this.setState({ appliedRestrictions, value: value });   
    }
    
    render() {
        const { rateCodes, priceTypeOptions, priceType, priceModifyOptions, priceModify, occupationOptions, selectedOcc, dates, startDate, endDate, focusedInput, value,
        activeTab, selectedRest, restrictionsList } = this.state;
        const { toggleModal, intl, currentDay } = this.props;

        const help = <div style={{ maxWidth: '20rem' }}>
            <strong>
                <FormattedMessage id="Inventory.BulkHelpTitle"/>
            </strong>
            <hr className='my-1'/>
            <FormattedHTMLMessage id="Inventory.BulkHelp"/>
        </div>

        const restrictionsOptions = restrictionsList.map(({name}) => {
            return({
                label: intl.formatMessage({ id: `ChannelBar.${name}` }),
                value: name,
            });
        });

        const booleanOptions = [
            {
                value: true,
                label: <FormattedMessage id="BulkUpdate.True" />
            },
            {
                value: false,
                label: <FormattedMessage id="BulkUpdate.False" />
            }
        ];

        return (
            <PopoverBody>
                <div style={{ position: 'absolute', zIndex: '100', left: '1rem', cursor: 'pointer', top: '0.8rem' }} onClick={toggleModal}>
                    <i className="fas fa-times"/>
                </div>
                <form className='px-2' onSubmit={this.submitBulk}>
                    <Row>
                        <Col id="MicroMassCalendar" className={!global.isMobile ? "col-8" : "col-12 d-flex aling-items-center justify-content-center MobileCalendarBulk"}>
                            {dates ?
                                <DateRangePicker
                                    startDate={startDate ? moment(startDate).locale(moment.locale()) : startDate}
                                    startDateId="startDate"
                                    initialVisibleMonth={() => moment(currentDay)}
                                    isOutsideRange={this.isOutsideRange}
                                    endDate={endDate ? moment(endDate).locale(moment.locale()) : endDate}
                                    endDateId="endDate"
                                    focusedInput={focusedInput || "startDate"}
                                    small={true}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    numberOfMonths={2}
                                    minimumNights={0}
                                    isDayBlocked={e => this.isDayBlocked(e)}
                                    renderDayContents={this.renderDayContents}
                                    onDatesChange={this.void}
                                    orientation={global.isMobile ? "vertical" : undefined}
                                />
                            :''}
                        </Col>
                        <Col className={!global.isMobile ? "col-4" : "col-12"} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingBottom: '20px' }}>
                            <div>
                                <div>
                                    <div className='mt-3'>
                                        <CommonHelper help={help} id={'BulkMicroUpdateHelp'}/>
                                    </div>
                                    <Nav tabs style={{ border: '0' }}>
                                        <NavItem style={{ border: '0', fontWeight: '500', borderBottom: activeTab === "1" ? '2px solid #165c7d' : '' }}>
                                            <NavLink style={{ cursor: 'pointer', border: '0', color: activeTab === "1" ? '#165c7d' : '' }} onClick={() => this.toggleTab("1")}>
                                                <FormattedMessage id="Inventory.BulkPrices"/>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem style={{ border: '0', fontWeight: '500', borderBottom: activeTab === "2" ? '2px solid #165c7d' : '' }}>
                                            <NavLink style={{ cursor: 'pointer', border: '0', color: activeTab === "2" ? '#165c7d' : '' }} onClick={() => this.toggleTab("2")}>
                                                <FormattedMessage id="Inventory.BulkRestrictions"/>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                                {activeTab === '1' ?
                                    <div>
                                        <Row className="mt-3">
                                            <Col style={{ display: 'flex', justifyContent: 'space-between', }}>
                                                {priceTypeOptions.map((option, key) =>
                                                    <Button
                                                        key={key}
                                                        className="btn btn-inventory shadow"
                                                        style={{ padding: '5px 10px', fontSize: '12px', lineHeight: '1.5', opacity: '1' }}
                                                        onClick={e => this.handlePriceType(option.value)}
                                                        disabled={priceType === option.value}
                                                    >
                                                        {option.label}
                                                    </Button>
                                                )}
                                            </Col>
                                        </Row>
                                        <div className="mt-3">
                                            <Row>
                                                <Col>
                                                    <CustomSelect
                                                        options={occupationOptions}
                                                        value={selectedOcc}
                                                        isMulti={true}
                                                        required={true}
                                                        placeholder=""
                                                        isSearchable={false}
                                                        isClearable={false}
                                                        onChange={e => this.changeOcc(e, "selectedOcc")}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col className="pb-1">
                                                    <Input
                                                        className="hiddenNumberArrows"
                                                        type="number"
                                                        max='999999999'
                                                        onChange={this.onChange}
                                                        value={value}
                                                    />
                                                </Col>
                                                {priceType === "modify" ?
                                                    <Col>
                                                        <CustomSelect
                                                            options={priceModifyOptions}
                                                            value={priceModifyOptions.find(({value}) => value === priceModify)}
                                                            isMulti={false}
                                                            required={true}
                                                            isSearchable={false}
                                                            isClearable={false}
                                                            onChange={e => this.selectChange(e, "priceModify")}
                                                        />
                                                    </Col>
                                                :''}
                                            </Row>
                                        </div>
                                    </div>
                                : activeTab === '2' ?
                                    <div>
                                        <Row className="mt-3">
                                            <Col>
                                                <CustomSelect
                                                    options={restrictionsOptions}
                                                    value={restrictionsOptions.find(({value}) => value === selectedRest)}
                                                    isMulti={false}
                                                    required={true}
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    onChange={e => this.selectChange(e, "selectedRest")}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-3">
                                            <Col>
                                                {restrictionsList?.find(({name}) => name === selectedRest)?.type === "number" ? 
                                                    <Input
                                                        className="hiddenNumberArrows"
                                                        type="number"
                                                        max='999999999'
                                                        onChange={this.onChangeRestriction}
                                                    />
                                                :
                                                    <CustomSelect
                                                        options={booleanOptions}
                                                        isMulti={false}
                                                        required={true}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                        onChange={e => this.onChangeRestriction({target:{value: e?.value}})}
                                                    />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                :''}
                            </div>
                            <div>
                                <Row className="mt-2">
                                    <Col>
                                        {rateCodes.map((r, key) =>
                                            <Row key={key} className="pt-2">
                                                <Col style={{ display: 'flex' }}>
                                                    <CustomInput
                                                        type='checkbox'
                                                        id={`CheckBox-${key}`}
                                                        name={`CheckBox-${key}`}
                                                        checked={r.active}
                                                        onChange={e => this.handleCheckBox(e, key)}
                                                        style={{ 'cursor': 'pointer' }}
                                                        disabled={activeTab === '1' && r.disabled}
                                                    />
                                                    <div>
                                                        {r.name}
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                    </Col>
                                </Row>
                                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                    <Button disabled={!dates || dates.length === 0 || !rateCodes || !rateCodes.find(({active}) => active)} id="toogleModal" className="btn btn-host btn-sm text-white shadow">
                                        <FormattedMessage id="Inventory.Apply"/>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>
            </PopoverBody>
        )
    }
}

export default injectIntl(BulkUpdateCalendar);