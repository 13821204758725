import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard, SelectHotel, ActiveInactiveStatusBadge } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Notification";
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from "react-intl";
import HotelDetails from './HotelDetails';
import CustomSelect from '../../Base/Common/CustomSelect';
import Authorization from '../../Base/Authorization';

export class HotelList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hotelList: [],
            hotelSelected: null,
            pmsType: null,
            currentVersion: null,
            lastServicePackFileOrdinal: null,
            lastServicePackSqlOrdinal: null,
            apiEndPoint: null,
            hasLicenseCode: null,
            active: null,
            licenseFeature: null,
            modal: false
        }
    }


    componentDidMount() {
        this.getHotels();
    }

    getHotels = () => {
        const { hotelId, pmsType, currentVersion, lastServicePackFileOrdinal, lastServicePackSqlOrdinal, apiEndPoint, hasLicenseCode, active, licenseFeature } = this.state;
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.data) {
                    this.setState({ hotelList: data.data, block: false, error: data.erros });
                } else {
                    this.setState({ block: false});
                }
            }
        }, `/api/hotel/v1?hotelId=${hotelId ? hotelId : ''}&pmsType=${pmsType ? pmsType : ''}&currentVersion=${currentVersion ? currentVersion : ''}&lastServicePackFileOrdinal=${lastServicePackFileOrdinal ? lastServicePackFileOrdinal : ''}&lastServicePackSqlOrdinal=${lastServicePackSqlOrdinal ? lastServicePackSqlOrdinal : ''}&apiEndPoint=${apiEndPoint !== null ? apiEndPoint : ''}&hasLicenseCode=${hasLicenseCode !== null ? hasLicenseCode : ''}&active=${active !== null ? active : ''}&licenseFeature=${licenseFeature !== null ? licenseFeature : ''}`);
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    toggleModal(hotel, type) {
        this.setState(prevState => ({
            modal: !prevState.modal,
            hotelSelected: hotel,
            modalType: type
        }));
    }

    updateTable = (hotel) => {
        this.setState({ block: true });
        const index = this.state.hotelList && this.state.hotelList.indexOf(this.state.hotelList.find(t => t.hotelId == hotel.hotelId));

        if (index == -1) {
            this.setState({
                hotelList: [
                    ...this.state.hotelList,
                    hotel
                ]
            });
        }
        else {
            var hotelList = [...this.state.hotelList];
            hotelList[index] = hotel;

            this.setState({ hotelList: hotelList });
        }
        this.setState({ block: false });
    }

    render() {
        const { pmsType, currentVersion, lastServicePackFileOrdinal, lastServicePackSqlOrdinal, apiEndPoint, hasLicenseCode, active, licenseFeature } = this.state;

        const columns = [
            {
                dataField: 'name1',
                text: this.props.intl.formatMessage({ id: "HotelList.HotelName" }),
                sort: true
            },
            {
                dataField: 'name2',
                text: this.props.intl.formatMessage({ id: "HotelList.HotelComercialName" }),
                sort: true
            },
            {
                dataField: 'address1',
                text: this.props.intl.formatMessage({ id: "HotelList.Address" }),
                sort: true
            },
            {
                dataField: 'city',
                text: this.props.intl.formatMessage({ id: "HotelList.City" }),
                sort: true
            },
            {
                dataField: 'contact',
                text: this.props.intl.formatMessage({ id: "HotelList.Contact" }),
                sort: true
            },
            {
                dataField: 'phone',
                text: this.props.intl.formatMessage({ id: "HotelList.Phone" }),
                sort: true
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "HotelList.Email" }),
                sort: true
            },
            {
                dataField: 'pmsType',
                text: this.props.intl.formatMessage({ id: "HotelList.PmsType" }),
                sort: true
            },
            {
                dataField: 'currentVersion',
                text: this.props.intl.formatMessage({ id: "HotelList.Version" }),
                sort: true
            },
            {
                dataField: 'lastServicePack_File_Ordinal',
                text: this.props.intl.formatMessage({ id: "HotelList.LastServicePackFileOrdinal" }),
                sort: true
            },
            {
                dataField: 'lastServicePack_Sql_Ordinal',
                text: this.props.intl.formatMessage({ id: "HotelList.LastServicePackSqlOrdinal" }),
                sort: true
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "HotelList.Status" }),
                sort: true,
                formatter: (cell) => <ActiveInactiveStatusBadge status={cell} />
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.toggleModal(row, 'edit')
            }
        };

        const pmsTypes = [
            {
                value: 'HOST',
                label: <FormattedMessage id="HotelList.Host"/>
            },
            {
                value: 'HSTAYS',
                label: <FormattedMessage id="HotelList.Hstays"/>
            },
        ];

        const trueOrFalseOptions = [
            {
                value: true,
                label: <FormattedMessage id="HotelList.Yes"/>
            },
            {
                value: false,
                label: <FormattedMessage id="HotelList.No"/>
            },
        ];

        return (
            <StyledCard icon={'fas fa-hotel'} title={'HotelList.HotelList'} help={'TODO'} error={this.state.error} block={this.state.block}>
                <Row className="mb-2">
                    <Col className="pr-1">
                        <SelectHotel
                            name='hotelId'
                            icon='fas fa-hotel'
                            onChangeFunc={this.handleChangeSelect}
                            placeholder={<FormattedMessage id="SAPOReservations.Hotel" />}
                            value={this.state.hotelId}
                        />
                    </Col>
                    <Col className="pr-1">
                        <CustomSelect options={pmsTypes} value={pmsTypes.filter(el => el.value === pmsType)} placeholder={<FormattedMessage id="HotelList.PmsType" />} onChange={this.handleChangeSelect.bind(this, 'pmsType')} isSearchable isClearable />
                    </Col>
                    <Col className="pr-1">
                        <FormattedMessage id="HotelList.Version">
                            {
                                placeholder => <Input type="number" id="currentVersion" name="currentVersion" min="0" value={currentVersion ? currentVersion : ''} placeholder={placeholder} onChange={this.handleChange} />
                            }
                        </FormattedMessage>
                    </Col>
                    <Col className="pr-1">
                        <FormattedMessage id="HotelList.LastServicePackFileOrdinal">
                            {
                                placeholder => <Input type="number" id="lastServicePackFileOrdinal" name="lastServicePackFileOrdinal" min="0" value={lastServicePackFileOrdinal ? lastServicePackFileOrdinal : ''} placeholder={placeholder} onChange={this.handleChange} /> 
                            }
                        </FormattedMessage>
                    </Col>
                    <Col className="pr-1">
                        <FormattedMessage id="HotelList.LastServicePackSqlOrdinal">
                            {
                                placeholder => <Input type="number" id="lastServicePackSqlOrdinal" name="lastServicePackSqlOrdinal" min="0" value={lastServicePackSqlOrdinal ? lastServicePackSqlOrdinal : ''} placeholder={placeholder} onChange={this.handleChange} />
                            }
                        </FormattedMessage>
                    </Col>
                    <Col className="text-right">
                        <Authorization
                            perform="hotelList:create"
                            yes={() => (
                                <button className="btn btn-host btn-sm" onClick={() => this.toggleModal(null, 'create')}>
                                    <span className="fas fa-plus" />
                                </button>
                            )}
                            no={() => <div></div>}
                        />
                        <button className="btn btn-host btn-sm ml-1" onClick={this.getHotels}>
                            <span className="fas fa-search" />
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-2 pr-1">
                        <CustomSelect options={trueOrFalseOptions} value={trueOrFalseOptions.filter(el => el.value === apiEndPoint)} placeholder={<FormattedMessage id="HotelList.ApiEndpoint" />} onChange={this.handleChangeSelect.bind(this, 'apiEndPoint')} isSearchable isClearable />
                    </Col>
                    <Col className="col-2 pr-1">
                        <CustomSelect options={trueOrFalseOptions} value={trueOrFalseOptions.filter(el => el.value === active)} placeholder={<FormattedMessage id="HotelList.Active" />} onChange={this.handleChangeSelect.bind(this, 'active')} isSearchable isClearable />
                    </Col>
                    <Col className="col-2 pr-1">
                        <CustomSelect options={trueOrFalseOptions} value={trueOrFalseOptions.filter(el => el.value === hasLicenseCode)} placeholder={<FormattedMessage id="HotelList.HasLicenseCode" />} onChange={this.handleChangeSelect.bind(this, 'hasLicenseCode')} isSearchable isClearable />
                    </Col>
                    <Col className="col-3">
                        <FormattedMessage id="HotelList.LicenseFeature">
                            {
                                placeholder => <Input type="text" id="licenseFeature" name="licenseFeature" value={licenseFeature ? licenseFeature : ''} placeholder={placeholder} onChange={this.handleChange} />
                            }
                        </FormattedMessage>
                    </Col>
                </Row>
                <hr className="border-host" />
                <Row>
                    <Col className="col-12">
                        <CustomTable
                            data={this.state.hotelList ? this.state.hotelList : []}
                            columns={columns}
                            shadow={false}
                            exportCSV={true}
                            selectRow={selectRow}
                            search={true}
                            remote={false}
                            keyField={'hotelId'}
                            showTotal={true}
                            hideSizePerPage={false}
                        />
                    </Col>
                </Row>

                {this.state.modal ?
                    <HotelDetails
                        modal={this.state.modal}
                        hotel={this.state.hotelSelected}
                        toggle={this.toggleModal.bind(this)}
                        modalType={this.state.modalType}
                        updateTable={this.updateTable}
                    />
                    : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(HotelList);