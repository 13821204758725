import React, { Component } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../Utils/CustomToolTip';
import { CheckAuthorization } from '../../Base/Authorization';

export class ExpediaPromotionDetail extends Component {

    render() {
        const { promotion, promotionTypes, promotionNames, channel, editPromotion, disabledPromotion, idx } = this.props;

        const type = promotionTypes.find(pt => pt.type === promotion.node.discount.type);
        const promotionName = promotionNames.find(pn => pn.type === promotion.node.name);

        const sellStatus = [
            {
                value: "EXPIRED",
                color: 'danger',
                label: <FormattedMessage id="ExpediaPromotionDashboard.Expired" />
            },
            {
                value: "CURRENT",
                color: 'success',
                label: <FormattedMessage id="ExpediaPromotionDashboard.Current" />
            },
            {
                value: "FUTURE",
                color: 'success',
                label: <FormattedMessage id="ExpediaPromotionDashboard.Future" />
            }
        ];

        const curSellStatus = sellStatus.find(st => st.value === promotion.node.sellStatus)

        return (
            <Card className="small mb-2 shadow border-top-0 border-right-0 border-left-0">
                <CardHeader className="bg-white ">
                    <Row className="pxy-0">
                        <Col className="col-lg-2 col-4 text-truncate">
                            <i className={`ext-secondary fas fa-1x mr-2 ${type && type.icon}`} />
                            <strong>
                                {type && type.name}
                            </strong>
                        </Col>
                        <Col className="col-lg-9 col-4 text-truncate">
                            {promotion.node.code}
                        </Col>
                        <Col className="col-lg-1 col-4 pl-1">
                            <Button id={'expediaprmotionedittooptip' + idx} className="btn-host btn-sm float-right" onClick={() => editPromotion(promotion.node)} disabled={!CheckAuthorization("promotions:edit", null) || promotion.node.isContractedPromotion}>
                                <i className="fas fa-edit" />
                            </Button>
                            <CustomToolTip placement="bottom" target={'expediaprmotionedittooptip' + idx}>
                                <FormattedMessage id="PromotionList.EditPromotion" />
                            </CustomToolTip>
                            <Button id={'expediaprmotiondisabeltooptip' + idx} className="btn-host btn-sm float-right mr-1" onClick={() => disabledPromotion(promotion.node)} disabled={!CheckAuthorization("promotions:edit", null)}>
                                <i className="fas fa-power-off" />
                            </Button>
                            <CustomToolTip placement="bottom" target={'expediaprmotiondisabeltooptip' + idx}>
                                <FormattedMessage id="PromotionList.DisablePromotion" />
                            </CustomToolTip>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        {!global.isMobile ? 
                            <Col className="col-5">
                                <h6>
                                    <FormattedMessage id="bookingPromotion.discount" />{": "}
                                    {
                                        promotion.node.discount.value ?
                                            promotion.node.discount.value
                                            :
                                            <span>
                                                <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysMon" />: {promotion.node.discount.monday}{", "}
                                                <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysTue" />: {promotion.node.discount.tuesday}{", "}
                                                <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysWed" />: {promotion.node.discount.wednesday}{", "}
                                                <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysThu" />: {promotion.node.discount.thursday}{", "}
                                                <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysFri" />: {promotion.node.discount.friday}{", "}
                                                <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSat" />: {promotion.node.discount.saturday}{", "}
                                                <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSun" />: {promotion.node.discount.sunday}
                                            </span>
                                    }
                                </h6>
                                <div>
                                    <FormattedMessage id="PromotionList.Channel" />: {channel && channel.label}
                                </div>
                                <h6>
                                    {promotion.node.status === 'ACTIVE' ?
                                        <Badge color="primary" pill> <FormattedMessage id="ChannelDashboard.Active" /> </Badge>
                                        :
                                        <Badge color="secondary" pill> <FormattedMessage id="ChannelDashboard.Inactive" /> </Badge>
                                    }
                                </h6>
                                <h6>
                                    <Badge color="primary" pill>
                                        {promotion.node.isContractedPromotion ? <FormattedMessage id="ExpediaPromotionDashboard.IsNegotioated" /> : <FormattedMessage id="ExpediaPromotionDashboard.NotNegotioated" />}
                                    </Badge>
                                </h6>
                                <h6>
                                    <Badge color={curSellStatus && curSellStatus.color} pill>
                                        {curSellStatus && curSellStatus.label}
                                    </Badge>
                                </h6>
                            </Col>
                        :
                            <>
                                <Col className="col-7">
                                    <h6>
                                        <FormattedMessage id="bookingPromotion.discount" />{": "}
                                        {
                                            promotion.node.discount.value ?
                                                promotion.node.discount.value
                                                :
                                                <span>
                                                    <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysMon" />: {promotion.node.discount.monday}{", "}
                                                    <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysTue" />: {promotion.node.discount.tuesday}{", "}
                                                    <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysWed" />: {promotion.node.discount.wednesday}{", "}
                                                    <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysThu" />: {promotion.node.discount.thursday}{", "}
                                                    <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysFri" />: {promotion.node.discount.friday}{", "}
                                                    <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSat" />: {promotion.node.discount.saturday}{", "}
                                                    <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSun" />: {promotion.node.discount.sunday}
                                                </span>
                                        }
                                    </h6>
                                    <div>
                                        <FormattedMessage id="PromotionList.Channel" />: {channel && channel.label}
                                    </div>
                                </Col>
                                <Col className='col-5 d-flex flex-column align-items-end'>
                                    <h6>
                                        {promotion.node.status === 'ACTIVE' ?
                                            <Badge color="primary" pill> <FormattedMessage id="ChannelDashboard.Active" /> </Badge>
                                            :
                                            <Badge color="secondary" pill> <FormattedMessage id="ChannelDashboard.Inactive" /> </Badge>
                                        }
                                    </h6>
                                    <h6>
                                        <Badge color="primary" pill>
                                            {promotion.node.isContractedPromotion ? <FormattedMessage id="ExpediaPromotionDashboard.IsNegotioated" /> : <FormattedMessage id="ExpediaPromotionDashboard.NotNegotioated" />}
                                        </Badge>
                                    </h6>
                                    <h6>
                                        <Badge color={curSellStatus && curSellStatus.color} pill>
                                            {curSellStatus && curSellStatus.label}
                                        </Badge>
                                    </h6>
                                </Col>
                            </>
                        }
                        <Col className={global.isMobile ? 'col-12' : ''}>
                            {!global.isMobile ?
                                <div>
                                    <strong>
                                        <FormattedMessage id="PromotionList.BookableFrom" />
                                    </strong>
                                    <span>
                                        {`: ${moment(promotion.node.restrictions.bookingLocalDateTimeFrom).format('L')} - ${moment(promotion.node.restrictions.bookingLocalDateTimeTo).format('L')}`}
                                    </span>
                                </div>
                            :
                                <>
                                    <div>
                                        <strong>
                                            <FormattedMessage id="PromotionList.BookableFrom" />:
                                        </strong>
                                    </div>
                                    <div>
                                        <span>
                                            {`${moment(promotion.node.restrictions.bookingLocalDateTimeFrom).format('L')} - ${moment(promotion.node.restrictions.bookingLocalDateTimeTo).format('L')}`}
                                        </span>
                                    </div>
                                </>
                            }
                            <div>
                                <strong>
                                    <FormattedMessage id="PromotionList.MinLos" />{": "}
                                </strong>
                                {
                                    promotion.node.restrictions.minLengthOfStay
                                }
                            </div>
                            <div>
                                <strong>
                                    <FormattedMessage id="PromotionList.MaxLos" />{": "}
                                </strong>
                                {
                                    promotion.node.restrictions.maxLengthOfStay
                                }
                            </div>
                            <div>
                                <strong>
                                    <FormattedMessage id="PromotionList.MinAdvanceBookingDays" />{": "}
                                </strong>
                                {
                                    promotion.node.restrictions.minAdvanceBookingDays
                                }
                            </div>
                            <div>
                                <strong>
                                    <FormattedMessage id="PromotionList.MaxAdvanceBookingDays" />{": "}
                                </strong>
                                {
                                    promotion.node.restrictions.maxAdvanceBookingDays
                                }
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <strong>
                                    <FormattedMessage id="PromotionList.ExcludeTheseDates" />:
                                </strong>
                                {
                                    promotion.node.blackoutDates ?
                                        promotion.node.blackoutDates.map((bd, key) => <li key={key}>{`${moment(bd.travelDateFrom).format('L')} - ${moment(bd.travelDateTo).format('L')}`}</li>)
                                        :
                                        <span />
                                }
                            </div>
                            <div>
                                <strong>
                                    <FormattedMessage id="PromotionList.Tags" />:
                                </strong>
                                {
                                    promotionName && promotionName.name ?
                                        <Badge color="success" style={{ marginLeft: '3px' }}>
                                            {promotionName && promotionName.name}
                                        </Badge>
                                        :
                                        <span />
                                }
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }
}