import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, FormGroup, Label, Row, Card, Button, Input } from 'reactstrap';
import { BlankCard } from '../../Base/Common/CommonUIComponents';
import { getAPI, putAPI } from '../../Base/API';
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from '../../Base/Notification';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { RateCodeTexts } from './RateCodeTexts';
export class RatesIntegrationsModal extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleServicesChange = this.handleServicesChange.bind(this);
        this.setComboStatus = this.setComboStatus.bind(this);        
        this.saveIncludedServices = this.saveIncludedServices.bind(this);
        this.state = {
            block: false,
            blockIncludedServices: false,
            activeTab: 0,
            texts: [],
            rateCodeDetail: {
                breakfastIncluded: false,
                internetIncluded: false,
                parkingIncluded: false,
                maxOcc: null,
                rateCodeId: this.props.rateCodeDetail?.rateCodeId
            },
            promoCodes: []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.rateCodeDetail && nextProps.rateCodeDetail.rateCodeId !== this.props.rateCodeDetail.rateCodeId) {
            this.getPromoCodes(nextProps.rateCodeDetail);
            this.getRateCodeDetails(nextProps.rateCodeDetail);
        }
    }

    componentDidMount() {
        if (this.props.rateCodeDetail) {
            this.getPromoCodes(this.props.rateCodeDetail);
            this.getRateCodeDetails(this.props.rateCodeDetail);
        }
    }

    getPromoCodes = (rateCodeDetail) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ promoCodes: data.response });
            }
            this.setState(({ block: false }));
        }, `/api/Rate/Promotion/v1/promoCode?rateId=${rateCodeDetail.rateCodeId}`);
    }

    getRateCodeDetails = (rateCodeDetail) => {
        this.setState({ blockIncludedServices: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockIncludedServices: false });

                return;
            }
            if (data && data.response && data.response.length > 0) {
                const { rateCodeDetail } = this.state;
                Object.assign(rateCodeDetail, data.response[0]);
                this.setState({ blockIncludedServices: false, rateCodeDetail });
            }
            else {
                this.setState({ blockIncludedServices: false });
            }
        }, `/api/Rate/Rate/v1/ratecode/details?rateId=${rateCodeDetail.rateCodeId}`);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab.key) {
            this.setState({
                activeTab: tab.key
            });
        }
    }

    handleServicesChange(evt) {
        if (evt && evt.target) {
            const { rateCodeDetail } = this.state;
            const { name, checked } = evt.target;
            rateCodeDetail[name] = checked;
            this.setState({ rateCodeDetail });
        }
    }

    setComboStatus(combo, evt) {
        if (evt) {
            const { rateCodeDetail } = this.state;
            const { name } = evt;
            rateCodeDetail[name] = combo && combo.value;
            this.setState({ rateCodeDetail });
        }
    }

    saveIncludedServices() {
        const { rateCodeDetail } = this.state;
        this.setState({ block: true });
        const request = {
            request: rateCodeDetail
        };
        request.request.rateCodeIds = [rateCodeDetail.rateCodeId];

        putAPI(result => {
            const { error, data } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }

            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="RatesIntegrations.SaveIncludedServicesSuccess" />, <FormattedMessage id="General.Success" />);
                    Object.assign(rateCodeDetail, data.response[0]);
                    this.setState({ rateCodeDetail, error: errorMessage, block: false }, _ => { if (!this.props.fromBEConfig) this.props.updateTable(rateCodeDetail) });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/Rate/v1/cancelPolicy/rateCode`, request);
    }

    handleMaxOcc = (e) => {
        const { rateCodeDetail } = this.state;
        rateCodeDetail.maxOcc = e?.target?.value ? parseInt(e.target.value) : null;
        this.setState({ rateCodeDetail });
    }

    render() {
        const { fromBEConfig, rateCodes, updateTable } = this.props;
        const { block, error, texts, rateCodeDetail, promoCodes } = this.state;

        return (
            <BlankCard block={block} error={error}>
                {rateCodes ?
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label sm={2}>
                                    <FormattedMessage id="RatesIntegrations.Rate" />
                                </Label>
                                <Col sm={10}>
                                    <CustomSelect
                                        name="rateCodeId"
                                        options={rateCodes}
                                        value={rateCodes.find(rateCode => rateCode.value === rateCodeDetail.rateCodeId)}
                                        onChange={this.setComboStatus}
                                        isDisabled={rateCodeDetail.id !== undefined || texts.length > 0}
                                    />
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                : ''}

                <RateCodeTexts fromBEConfig={fromBEConfig} rateCodes={rateCodes} rateCodeDetail={rateCodeDetail} rateCodeId={rateCodeDetail.rateCodeId} updateTable={updateTable}/>

                <div className="py-4">
                    <Row>
                        <Col>
                            <h5 className="mt-3"><FormattedMessage id="RatesIntegrations.RateConfig"/></h5> 
                        </Col>
                        <Col className="text-right col-3">
                            <Button color=" btn-host btn-sm float-right" onClick={this.saveIncludedServices}>
                                <i className="fas fa-save" />
                            </Button>
                        </Col>
                    </Row>
                    <hr className="mt-0" />
                    <Row>
                        <Col className="col-12 col-md-6 col-lg-4">
                            <div className='mb-2'>
                                <FormattedMessage id="RatesIntegrations.maxOcc"/>
                            </div>
                            <div>
                                <Input
                                    type="number"
                                    min="0"
                                    max="21"
                                    name="maxOcc"
                                    value={rateCodeDetail?.maxOcc || ''}
                                    onChange={this.handleMaxOcc}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>

                {promoCodes && promoCodes.length > 0 ?
                    <div>
                        <Row>
                            <Col>
                                <h5 className="mt-3"><FormattedMessage id="RatesIntegrations.ApplicablePromoCodes" /></h5> 
                            </Col>
                        </Row>
                        <hr className="mt-0" />
                        <Row>
                            {promoCodes.map((code, key) =>
                                <Col className="col-12 col-md-6 col-lg-4" key={key}>
                                    <Card className="border-0 shadow pointer" body onClick={() => this.props.history.push('/PromoCodeDetails/' + code.id)}>
                                        <h6> {code.code} </h6>
                                        <span><FormattedMessage id="PromoCodes.ValidTo" values={{ start: moment(code.bookDateStart).format('YYYY-MM-DD'), end: moment(code.bookDateEnd).format('YYYY-MM-DD') }}/> </span>
                                    </Card>
                                </Col>
                            )}
                        </Row>
                    </div>
                : ''}
            </BlankCard>
        );
    }
}
export default injectIntl(withRouter(RatesIntegrationsModal));
