import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import BlockUi from 'react-block-ui';

class ModalSendToCoWorkwer extends Component {
    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} className="modal-lg">
                <BlockUi tag="div" blocking={this.props.block}>
                    <Button onClick={this.props.toggleModal} className="closeModal">
                        <i className="fas fa-times fa-sm" />
                    </Button>
                    <ModalBody>
                        <Form onSubmit={(e) => this.props.sendEmail(e, this.props.domainId)}>
                            <div className='d-flex align-items-center justify-content-between mt-3 mb-2' style={{ fontWeight: "500" }}>
                                <Label className='m-0'> <FormattedMessage id="EmailReservation.SendToCoworker" /> </Label>
                                <Button className=" btn btn-host btn-sm" type={'submit'}>
                                    <FormattedMessage id="EmailReservation.Send" />
                                </Button>
                            </div>
                            <div>
                                <div className='d-flex align-items-center justify-content-between mb-2'>
                                    <Label className='m-0'>
                                        <FormattedMessage id="EmailReservation.EmailTo"/>
                                    </Label>
                                </div>
                                <Input
                                    type='email'
                                    required
                                    maxLength={'100'}
                                    name='emailTo'
                                    value={this.props.emailTo}
                                    onChange={this.props.handleChangeEmailTo}
                                />
                            </div>
                            <div className='mt-2'>
                                <div>
                                    <Label>
                                        <FormattedMessage id="EmailReservation.Message"/>
                                    </Label>
                                </div>
                                <div style={{  borderRadius: '5px' }}>
                                    <Input
                                        style={{ 
                                            resize: 'none', 
                                            maxHeight : '500px',
                                            minHeight: '50px',
                                            height: '100px', 
                                            borderRadius: '5px'
                                        }}
                                        onChange={this.props.handleChangeEmailTo}
                                        required={true}
                                        value={this.props.coWorkerMessage}
                                        placeholder={''}
                                        maxLength='999'
                                        type='textarea'
                                        name='coWorkerMessage'
                                    />
                                </div>
                            </div>
                        </Form>
                    </ModalBody>
                </BlockUi>
            </Modal>
        )
    }
}

export default injectIntl(ModalSendToCoWorkwer);
