import React, { Component } from 'react';
import { Card, CardBody, Modal, ModalHeader, ModalBody, CardHeader } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { getAPI } from "../../../Base/API"
import { PackageSetup } from './PackageSetup';
import { CustomTable } from '../../../Base/Common/CustomTable'
import { ActiveInactiveStatusBadge, StyledCard } from '../../../Base/Common/CommonUIComponents'

import Authorization, { CheckAuthorization } from '../../../Base/Authorization'

 class PackageList extends Component {

    state = {
        error: null,
        block: true,
        modal: false,
        activeAddressTab: '1',
        PackageData: [],
        selectedPackage :{}
    };

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.getPackages = this.getPackages.bind(this);
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    componentDidMount() {
        this.getPackages();
     }

     getPackages() {
         this.setState({ block: true, modal:false });
         getAPI(result => {
             const { data, error, isLoading } = result;
             
             if (error) {
                 var errorMessage = [];
                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errorMessage, block: false });

                 return;
             }
             if (data) {
                 this.setState({ PackageData: (data.response ? data.response : []), block: false });
             }
         }, '/api/hotel/Package/v1');

     }

     addPackage() {
         this.setState({ modal: true, selectedPackage: {} })
     }

     updatePackage = (packData) => {
         let PackageData = [ ...this.state.PackageData ];
         const idx = PackageData && PackageData.findIndex(el => el.id === packData.id);

         if (idx) {
             PackageData[idx] = packData;

             this.setState({ PackageData });
         }
     }
   
    render() {

        const columns = [{
            dataField: 'id',
            text: this.props.intl.formatMessage({ id: "PackageList.packageID" }),
            sort: true
        }, {
            dataField: 'code',
                text: this.props.intl.formatMessage({ id: "PackageList.Code" }),
            sort: true
        }, {
            dataField: 'description',
                text: this.props.intl.formatMessage({ id: "PackageList.Description" }),
            sort: true
            }, {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "PackageList.active" }),
                sort: true,
                formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell} />
            }];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, selectedPackage: row })
            }
        };

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon="fas fa-gift" title="PackageList.PackageConfiguration" id="PackageList" help={<FormattedMessage id="PackageList.HelpText" />}>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                    <ModalHeader toggle={this.toggleModal}  ><FormattedMessage id="PackageList.Package" /></ModalHeader>
                    <ModalBody className="modal-lg p-0">
                        <PackageSetup selectedPackage={this.state.selectedPackage} getPackages={this.getPackages} updatePackage={this.updatePackage} />
                    </ModalBody>
                </Modal>

                <Authorization
                    perform="package:add"
                    yes={() => (
                        <button className="btn btn-sm btn-host float-right" onClick={this.addPackage.bind(this)}><i className="fas fa-plus"></i></button>
                    )}
                    no={() => <div></div>}
                />
                                
                <CustomTable
                    data={this.state.PackageData ? this.state.PackageData : []}
                    columns={columns}
                    page={this.state.currentPage}
                    sizePerPage={this.state.resultsPerPage}
                    totalSize={this.state.ComplexData && this.state.ComplexData.length}
                    onTableChange={this.handleTableChange}
                    shadow={false}
                    selectRow={selectRow}
                    search={true}
                    remote={false}
                />
            </StyledCard>
        );
    }
}
export default injectIntl(PackageList)