import moment from 'moment';
import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, CardBody, CardHeader, Col, CustomInput, Modal, Row, UncontrolledPopover } from 'reactstrap';
import { getAPI, postAPI } from '../../Base/API';
import { CommonHelper, ConfirmRestrictionsToChannelsModal } from '../../Base/Common/CommonUIComponents';
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from '../../Base/Notification';
import { ErrorAlert } from '../../Common/ErrorAlert';
import CalendarBar from '../CalendarBar';
import { EditableRateAvailabilityBar } from './EditableRateAvailabilityBar';
import { EditableRoomAvailabilityBar } from './EditableRoomAvailabilityBar';
import BulkUpdateCalendar from './BulkUpdateCalendar';
import Authorization from '../../Base/Authorization';
import { Tutorial } from '../../Common/Tutorial';
import { getRmsTypes } from '../../Base/Common/ReferenceDataFunctions';
import { CustomSingleDatePicker } from '../../Base/Common/CustomReactDates';

class MicroInventoryUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            daysIdx: 0,
            currentDay: moment(),
            newData: [],
            error: null,
            block: true,
            bulkSelectedRates: [],
            rateId: null,
            selectedRates: [],
            rates: [],
            categories: [],
            days: [14, 30],
            next14Days: [],
            labels: [],
            hideLabels: true,
            isHost: false,
            showRestrictions: false,
            showDerived: true,
            showAvailability: true,
            modal: false,
            newCol: null,
            newRow: null,
            newColRest: null,
            newRowRest: null,
            priceVector: ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'p10', 'p11'],
            selectedCell: null,
            currentCol: null,
            wasSaved: false,
            selectedRateId: null,
            renderTutorial: false,
            restrictionsToChannels: false,
            seqRateList: [],
            tutorialSteps:[
                {
                    id: "ChooseDates",
                    message: (
                        <div>
                            <div className='text-center py-2'>
                                <b><FormattedMessage id="Tutorial.MicroInventoryUpdate.ChooseDates"/></b>
                            </div>
                            <div><FormattedMessage id="Tutorial.MicroInventoryUpdate.ChooseDates1"/></div>
                        </div>
                    ),
                    placement: 'bottom'
                },
                {
                    id: "SelectRate",
                    message: (
                        <div>
                            <div className='text-center py-2'>
                                <b><FormattedMessage id="Tutorial.MicroInventoryUpdate.SelectRate"/></b>
                            </div>
                            <Row>
                                <Col className="tutorial">
                                    <FormattedMessage id="Tutorial.MicroInventoryUpdate.SelectRate1"/>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="tutorial">
                                    <FormattedMessage id="Tutorial.MicroInventoryUpdate.SelectRate2"/>
                                </Col>
                            </Row>
                            <div className='mt-2 text-center text-muted d-flex align-items-center justify-content-center' style={{ fontSize: '0.75em' }}>
                                <i className="fas fa-exclamation-triangle fa-xs text-warning mr-1"/>
                                <FormattedMessage id="Tutorial.MicroInventoryUpdate.SelectRate3"/>
                            </div>
                        </div>
                    ),
                    placement: 'right',
                    blockNext: true
                },
                {
                    id: "YourPricesByOcc",
                    message: (
                        <div>
                            <div>
                                <div className='text-center py-2'>
                                    <b><FormattedMessage id="Tutorial.MicroInventoryUpdate.YourPricesByOcc"/></b>
                                </div>
                                <Row>
                                    <Col className="pr-0 tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourPricesByOcc1"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-1 tutorial text-right">
                                        <i className="fas fa-lock"/>
                                    </Col>
                                    <Col className="pr-0 tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourPricesByOcc2"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-1 tutorial text-right">
                                        <i className="fas fa-caret-left"/>
                                    </Col>
                                    <Col className="pr-0 tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourPricesByOcc3"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-1 tutorial d-flex justify-content-end align-items-center">
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#ddeeff' }}/>
                                    </Col>
                                    <Col className="pr-0 tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourPricesByOcc4"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-1 tutorial d-flex justify-content-end align-items-center">
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#ffbebe' }}/>
                                    </Col>
                                    <Col className="pr-0 tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourPricesByOcc5"/>
                                    </Col>
                                </Row>
                                <Row className="pl-3">
                                    <Col className="col-1 tutorial text-right">
                                    </Col>
                                    <Col className="pr-0 tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourPricesByOcc6"/>
                                    </Col>
                                </Row>
                                <Row className="pl-3">
                                    <Col className="col-1 tutorial text-right">
                                    </Col>
                                    <Col className="pr-0 tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourPricesByOcc7"/>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <div className='text-center py-2'>
                                    <b><FormattedMessage id="Tutorial.MicroInventoryUpdate.NavigateCells"/></b>
                                </div>
                                <Row>
                                    <Col className='col-1 tutorial text-right'>
                                        <i className="mr-1 fas fas fa-italic"/>
                                    </Col>
                                    <Col className='pr-0 tutorial'>
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.NavigateCells1"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-1 tutorial text-right'>
                                        <i className="fas fas fa-arrows-alt"/>
                                    </Col>
                                    <Col className='pr-0 tutorial'>
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.NavigateCells2"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-1 tutorial text-right'>
                                        <i className="mr-1 fas fas fa-keyboard"/>
                                    </Col>
                                    <Col className='pr-0 tutorial'>
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.NavigateCells3"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-1 tutorial text-right'>
                                        <i className="mr-1 fas fa-hand-rock"/>
                                    </Col>
                                    <Col className='pr-0 tutorial'>
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.NavigateCells4"/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ),
                    placement: 'bottom'
                },
                {
                    id: "ChooseVisibility",
                    domEl: "RestrictionToggle",
                    message: (
                        <div>
                            <div>
                                <div className='text-center py-2'>
                                    <b><FormattedMessage id="Tutorial.MicroInventoryUpdate.ChooseVisibility"/></b>
                                </div>
                                <div><FormattedMessage id="Tutorial.MicroInventoryUpdate.ChooseVisibility1"/></div>
                                <div><FormattedMessage id="Tutorial.MicroInventoryUpdate.ChooseVisibility2"/></div>
                                <div><FormattedMessage id="Tutorial.MicroInventoryUpdate.ChooseVisibility3"/></div>
                                <div className='mt-2 text-center text-muted d-flex align-items-center justify-content-center' style={{ fontSize: '0.75em' }}>
                                    <i className="fas fa-exclamation-triangle fa-xs text-warning mr-1"/>
                                    <FormattedMessage id="Tutorial.MicroInventoryUpdate.ChooseVisibility4"/>
                                </div>
                            </div>
                        </div>
                    ),
                    placement: 'left',
                    blockNext: true
                },
                {
                    id: "YourRestrictions",
                    message: (
                        <div>
                            <div>
                                <div className='text-center py-2'>
                                    <b><FormattedMessage id="Tutorial.MicroInventoryUpdate.YourRestrictions"/></b>
                                </div>
                                <Row>
                                    <Col className="tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourRestrictions1"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-1 tutorial d-flex justify-content-end align-items-center pr-0">
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#ddeeff' }}/>
                                    </Col>
                                    <Col className="tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourRestrictions2"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-1 tutorial d-flex justify-content-end align-items-center pr-0">
                                        <div style={{ width: '10px', height: '10px', backgroundColor: '#ffbebe' }}/>
                                    </Col>
                                    <Col className="tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourRestrictions3"/>
                                    </Col>
                                </Row>
                                <Row className="pl-3">
                                    <Col className="col-1 tutorial"></Col>
                                    <Col className="tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourRestrictions4"/>
                                    </Col>
                                </Row>
                                <Row className="pl-3">
                                    <Col className="col-1 tutorial"></Col>
                                    <Col className="tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourRestrictions5"/>
                                    </Col>
                                </Row>
                                <Row className="pl-3">
                                    <Col className="col-1 tutorial"></Col>
                                    <Col className="tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourRestrictions6"/>
                                    </Col>
                                </Row>
                                <Row className="pl-3">
                                    <Col className="col-1 tutorial"></Col>
                                    <Col className="tutorial">
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.YourRestrictions7"/>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <div className='text-center py-2'>
                                    <b><FormattedMessage id="Tutorial.MicroInventoryUpdate.NavigateCells"/></b>
                                </div>
                                <Row>
                                    <Col className='col-1 tutorial text-right'>
                                        <i className="mr-1 fas fas fa-italic"/>
                                    </Col>
                                    <Col className='pr-0 tutorial'>
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.NavigateCells1"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-1 tutorial text-right'>
                                        <i className="fas fas fa-arrows-alt"/>
                                    </Col>
                                    <Col className='pr-0 tutorial'>
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.NavigateCells2"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-1 tutorial text-right'>
                                        <i className="mr-1 fas fas fa-keyboard"/>
                                    </Col>
                                    <Col className='pr-0 tutorial'>
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.NavigateCells3"/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='col-1 tutorial text-right'>
                                        <i className="mr-1 fas fa-hand-rock"/>
                                    </Col>
                                    <Col className='pr-0 tutorial'>
                                        <FormattedMessage id="Tutorial.MicroInventoryUpdate.NavigateCells4"/>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    ),
                    placement: 'bottom'
                },
                {
                    id: "ControlButtons",
                    domEl:'UpdateInBulk',
                    message: (
                        <div className='w-100'>
                            <div className='text-center py-2'>
                                <b><FormattedMessage id="Tutorial.MicroInventoryUpdate.Buttons"/></b>
                            </div>
                            <div><FormattedMessage id="Tutorial.MicroInventoryUpdate.Buttons1"/></div>
                            <div><FormattedMessage id="Tutorial.MicroInventoryUpdate.Buttons2"/></div>
                            <div><FormattedMessage id="Tutorial.MicroInventoryUpdate.Buttons3"/></div>
                        </div>
                    ),
                    placement: 'bottom'
                },
            ],
            dataDump: [], //for dumping untreated data,
            displayRestrictionsByChannels: '',
            selectedLabels: [],
            filtersSection: false,
            hasFlexChildPrices: false
        }
    }
    
    componentDidMount(){
        window.addEventListener('beforeunload', (e) => {
            const { newData } = this.state;
            if(newData && newData.length > 0) {
                e.returnValue = 'Unsaved changes';
                return "Unsaved changes";
            };
        });

        if (!this.state.rateId) this.urlParamsTreatment();

        window.setTimeout(
            () => {
                this.setState({
                    displayRestrictionsByChannels: global.RMSType === getRmsTypes()[1].value || global.RMSType === getRmsTypes()[2].value ? 'none' : '',
                    hasFlexChildPrices: global.modules?.includes('FlexChildPrice')
                });
            },
            (global.RMSType && global.modules) ? 0 : 2500
        );
    }

    changeSelectedCell = (id) => {
        const old = this.state.selectedCell;
        let selectedCell;
        let dirty = false;
        if(id){
            selectedCell = {
                isPrice: id.indexOf('Price') > -1,
                rateId: id.substring(0, id.indexOf('&')),
                row: id.substring((id.indexOf('-') + 1), id.indexOf('.')),
                col: id.substring((id.indexOf('.') + 1), id.length)
            };
            
            if(old && selectedCell.rateId === old.rateId && selectedCell.row === old.row && selectedCell.col === old.col) dirty = true;
        }

        if(!dirty) this.setState({ selectedCell });
    }

    urlParamsTreatment = () => {
        const { days } = this.state;
        const urlParams = new URLSearchParams(window.location.search);
        const currentDay = urlParams.get('currentDay') ? moment(urlParams.get('currentDay')) : moment();
        const inventorySeeOneMonth = urlParams.get('inventorySeeOneMonth');
        const daysIdx = inventorySeeOneMonth && inventorySeeOneMonth === "true" ? 1 : 0;
        const rateId = urlParams.get('rateId');
        const next14Days = [];

        for (var i = 0; i < days[daysIdx]; i++) {
            next14Days.push(currentDay.clone().add(i, 'days'));
        };

        this.setState({ rateId: parseInt(rateId), currentDay, next14Days, daysIdx, showVersionToggler: rateId ? true : false }, () => {
            this.getRates();
            window.setTimeout(
                () => {
                    this.setState({ isHost: global.PMSType === 'Host' || global.PMSType === 'None' });
                },
                2500
            );
        });
    }

    getRates = () => {
        const { rateId } = this.state;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data?.response && data.response.length > 0) {
                const selectedRates = [];

                if(rateId) {
                    const currentRate = data.response.find(({id}) => id === rateId);
                    if(currentRate){
                        selectedRates.push({
                            label: currentRate.name,
                            value: currentRate.id
                        });
                    }
                };

                data.response.forEach((rate) => rate.defaultPax?.sort((d1, d2) => d1.adults - d2.adults !== 0 ? d1.adults - d2.adults : d1.childrens - d2.childrens));

                this.setState({ rates: data.response.map(rate => ({
                    ...rate,
                    canD3: rate?.defaultPax?.filter(({childrens}) => childrens > 1).length > 0
                })), selectedRates }, () =>
                    this.getLabels(rateId && data.response.filter(({id}) => id === rateId).length > 0)
                );
            }
            else {
                this.setState({ error: errorMessage, block: false });
            };
        }, `/api/Rate/Rate/v1`);
    }

    getPricesAndRestrictions = (rateId, cb) => {
        const { currentDay } = this.state;
        const endDate = moment(currentDay).clone().add('30', 'days');

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.length > 0) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data[0] && data[0].availability) {

                    const dataDump = [...this.state.dataDump];

                    const idx = dataDump.findIndex((el) => el.rateId === rateId);

                    if(idx > -1) dataDump.splice(idx, 1, data[0]);
                    else dataDump.push(data[0]);

                    const tutorialSteps = [...this.state.tutorialSteps ];
                    tutorialSteps.find(({id}) => id === "SelectRate").blockNext = false;

                    if(cb){
                        this.setState({ categories: this.catTreatment(dataDump), dataDump, tutorialSteps }, () => cb(rateId));
                    }
                    else{
                        this.setState({ categories: this.catTreatment(dataDump), dataDump, tutorialSteps, block: false });
                    }
                }
                else {
                    this.setState({ block: false });
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Price/Availability/v1/Availability/${rateId}?startDate=${currentDay.format('YYYY-MM-DD')}&endDate=${endDate.format('YYYY-MM-DD')}&includePrices=true&includeRestrictions=true`);
    }

    catTreatment = (dataDump) => {
        const { rates } = this.state;
        //Join rates by category
        const array = [];

        dataDump.forEach((rate) => {
            const currentCategory = array.find((el) => el.roomCatId === rate.roomCatId && el.roomBlock === rate.roomBlock);
            const rateData = rates.find(({id}) => id === rate.rateId);

            const rateObj = {
                availability: rate.availability?.map(av => {
                    const ava = av;
                    ava.blockId = rate.roomBlock;
                    ava.id = Math.floor(Math.random() * 10000) + 1;

                    return (ava);
                }),
                packageId: rate.packageId,
                pricelistId: rate.pricelistId,
                rateId: rate.rateId
            };

            if(rate.displayPricesFrom){
                rateObj.positionToDisplay = rate.displayPricesFrom;
            }
            else if(rateData?.defaultPax) {
                const r = rateData.defaultPax.filter(({active, children, isBaseOccupancy}) => active && (!children || children === 0)
                && (!rateData.isOccupancyDerived || (rateData.isOccupancyDerived && isBaseOccupancy)));

                rateObj.positionToDisplay = r[r.length - 1]?.position
            }

            if(!currentCategory) {
                const categoryObj = {
                    roomCatId: rate.roomCatId,
                    roomBlock: rate.roomBlock,
                    roomCode: rate.roomCode,
                    roomDesc: rate.roomDesc,
                    complexId: rate.complexId,
                    rates: [rateObj]
                };

                array.push(categoryObj);
            }
            else {
                currentCategory.rates.push(rateObj);
            }
        });
        return array;
    }

    handleSeeOneMonth = (e) => {
        if (!e) return;

        if(this.hasNewData()){
            this.unsavedChangesWarning(this.changeMonthView, null, true);
        }
        else{
            this.changeMonthView();
        }
    }

    changeMonthView = () => {
        const daysIdx = this.state.daysIdx === 0 ? 1 : 0;
        this.setState({ daysIdx }, () => this.changeDates(daysIdx));
    }

    changeDates = (daysIdx, getData) => {
        const { days, currentDay } = this.state;
        const next14Days = [];
        const numberOfDays = days[daysIdx];

        for (var i = 0; i < numberOfDays; i++) {
            next14Days.push(currentDay.clone().add(i, 'days'));
        };
        if(getData){
            this.setState({ next14Days }, () => this.getAllRatesAvailabilities());
        }
        else{
            this.setState({ next14Days });
        }
    }

    getAllRatesAvailabilities = () => {
        const { categories } = this.state;

        if(!categories || categories.length === 0) return;

        const seqRateList = [];

        categories.forEach((cat) => {
            cat.rates.forEach(rate => {
                seqRateList.push(rate.rateId);
            });
        });

        this.setState({ seqRateList, categories: [], block: true }, () => this.getPricesAndRestrictions(seqRateList[0],this.sequentialRateFetch));
    }

    sequentialRateFetch = (rateId) => {
        const { seqRateList } = this.state;
        const idx = seqRateList.findIndex((el) => el === rateId);
        const newIdx = idx + 1;
        if(seqRateList[newIdx]){
            this.getPricesAndRestrictions(seqRateList[newIdx], this.sequentialRateFetch);
        }
        else{
            this.setState({ block: false, seqRateList: [] });
        }
    }

    sequentialLabelRateFetch = (rateId) => {
        const { selectedRates } = this.state;
        const idx = selectedRates.findIndex((rate) => rate.value === rateId);
        const newIdx = idx - 1;
        if (selectedRates[newIdx]) {
            this.getPricesAndRestrictions(selectedRates[newIdx].value, this.sequentialLabelRateFetch);
        }
        else {
            this.setState({ block: false});
        }
    }

    onScroll = (event) => {
        if (event && event.target) {
            const { scrollTop, scrollLeft } = event.target;

            const iventoryDaysScroll = document.getElementById("IventoryDaysScroll");
            const iventoryDataScroll = document.getElementsByClassName("IventoryDataScroll");

            if (iventoryDaysScroll && iventoryDataScroll) {
                iventoryDaysScroll.scrollTo(scrollLeft, scrollTop);
                iventoryDataScroll.forEach((el) => {
                    el.scrollTo(scrollLeft, scrollTop);
                })
            }
        }
    }

    validateValue = (day, rateId, value, name, position, dailyData) => { //returns true if is invalid
        const { priceVector, categories, rates } = this.state;
        
        const existingDataDay = categories.find((c) => c.rates.filter((r) => r.rateId === rateId).length > 0)?.rates
        .find((r) => r.rateId === rateId)?.availability?.find(({hotelDate}) => moment(day).isSame(moment(hotelDate), 'date'));
        const rate = rates.find(({id}) => id === rateId);
        const highestOcc = [...rate.defaultPax.filter(({active}) => active)].sort((a, b) => a.adults > b ? 1 : -1)[0];
        
        if(value < 0 || (value === 0 && name === "adultPrices")) return true;

        if(name === "adultPrices"){
            const pricePosition = position <= 10 ? position : 10;
            for(let i = 1; i < (position <= 10 ? pricePosition : (pricePosition + 1)); ++i){
                const newDataPOcc = dailyData?.adultPrices.find(e => e.position === i);
                if(newDataPOcc && parseFloat(newDataPOcc.adultPrice) > value){
                    return true;
                }
                else if(!newDataPOcc && (!existingDataDay || parseFloat(existingDataDay[priceVector[i - 1]])) > value){
                    return true;
                }
            };

            if(position != highestOcc.adults){
                for(let i = position; i < highestOcc.position; ++i){
                    if(i < 10 || i > 18){
                        const pricePosition = i <= 10 ? i : 10;
                        const newDataPOcc = dailyData?.adultPrices.find(e => e.position === (i + 1));
                        const existing = rate.defaultPax.find((pax) => pax.position === (i + 1) && pax.active && !pax.childrens);

                        if(newDataPOcc && parseFloat(newDataPOcc.adultPrice) < value){
                            return true;
                        }
                        else if(!newDataPOcc && existing && (!existingDataDay || parseFloat(existingDataDay[priceVector[pricePosition]])) < value){
                            return true;
                        };
                    }
                };
            }
        };
        let comp;
        let isMin = false;
        if(name === "minimumStayLen"){
            isMin = true;
            comp = dailyData.maximumStayLen ? parseInt(dailyData.maximumStayLen) : parseInt(existingDataDay?.maximumStayLen??0);
        }
        if(name === "maximumStayLen"){
            comp = dailyData.minimumStayLen ? parseInt(dailyData.minimumStayLen) : parseInt(existingDataDay?.minimumStayLen??0);

        }
        if(name === "minAdvancedBookingOffset"){
            isMin = true;
            comp = dailyData.maxAdvancedBookingOffset ? parseInt(dailyData.maxAdvancedBookingOffset) : parseInt(existingDataDay?.maxAdvancedBookingOffset??0);

        }
        if(name === "maxAdvancedBookingOffset"){
            comp = dailyData.minAdvancedBookingOffset ? parseInt(dailyData.minAdvancedBookingOffset) : parseInt(existingDataDay?.minAdvancedBookingOffset??0);
        }
        if(comp && ((isMin && value && comp && value > comp) || (!isMin && value && comp && value < comp))) return true;
        
        return false;
    }

    getChangedData = (rateData, day, name, pricePosition) => {
        let dailyData = rateData.find((d) => d.day === day.format('YYYY-MM-DD'));

        let dailyDataChanged = [];
        if(dailyData){
            if(name.includes('Price')){
                const dailyPrice = dailyData.prices;

                if(dailyPrice.adultPrices && dailyPrice.adultPrices.length > 0){
                    dailyPrice.adultPrices.forEach((price) =>{
                        const obj = {
                            value: price.adultPrice,
                            position: price.position,
                            name: "adultPrices"
                        };
                        if(obj.name !== name || obj.position !== pricePosition) dailyDataChanged.push(obj);
                    });
                };
            }
            else{
                if(dailyData.restrictions[0]){
                    const restrictions = Object.keys(dailyData.restrictions[0]).filter(k => !k.includes('isInvalid'));

                    if(restrictions.length > 0){
                        restrictions.forEach(d => {
                            const obj = {
                                name: d,
                                value: dailyData.restrictions[0][d]
                            };
                            if(obj.name !== name) dailyDataChanged.push(obj);
                        });
                    };
                }
            };
        };
        return dailyDataChanged;
    }

    handleDataChange = (e, day, rateId, pPos, isD3) => {
        const { newData, rates, categories, priceVector } = this.state;
        let { value, name } = e.target;
        let revised = -1;
        let pricePosition = pPos;
        let rateData = newData.find((r) => r.rateCodeId === rateId)?.dates;
        
        if(!rateData){
            newData.push({rateCodeId: rateId, dates: []});
            rateData = newData.find((r) => r.rateCodeId === rateId)?.dates;
        }

        let dailyData = rateData.find((d) => d.day === day.format('YYYY-MM-DD'));

        if(!dailyData){
            rateData.push({
                prices: {
                    adultPrices: [],
                    childPrices: [],
                },
                restrictions: [{}],
                day: day.format('YYYY-MM-DD')
            });
            dailyData = rateData.find((d) => d.day === day.format('YYYY-MM-DD'));
        }

        const dailyDataChanged = this.getChangedData(rateData, day, name, pricePosition);

        while(revised < dailyDataChanged.length){ // To revise existing changes
            const currentVector = priceVector[pricePosition > 10 ? 10 : pricePosition - 1];
            const parsedValue = parseFloat(value);
            const currentRate = rates.find(({ id }) => rateId === id);
            const currentPax = name === "childPrice" ? currentRate?.defaultPax?.find(({ position, childrens }) => position === pricePosition && childrens > 0) : currentRate?.defaultPax?.find(({ position, childrens }) => position === pricePosition && childrens === 0);
            const currentAvailability = categories.find((ct) => ct.rates.filter((r) => r.rateId === rateId).length > 0).rates.find((r) => r.rateId === rateId)?.availability
            .find(({hotelDate}) => day.isSame(moment(hotelDate), 'date'));
            const isBaseOccupancy = currentRate?.isOccupancyDerived && currentPax?.isBaseOccupancy;
            let original;

            if(name.includes('Price')){
                if(!currentPax?.childrens || name === "childPrice"){
                    const dailyPrice = dailyData.prices;

                    if(value && name && (!currentRate?.isOccupancyDerived || isBaseOccupancy || name === "childPrice")){
                        if(name === "adultPrices"){
                            const currentAdultPrice = dailyPrice.adultPrices.find(({position}) => position === pricePosition);

                            original = currentAvailability ? parseFloat(currentAvailability[currentVector]) : null;

                            if(!currentAdultPrice) {
                                dailyPrice.adultPrices.push({
                                    position: pricePosition > 10 ? 20 : pricePosition,
                                    adultPrice: value,
                                    isInvalid: isBaseOccupancy ? false : this.validateValue(day, rateId, parsedValue, name, pricePosition, dailyPrice)
                                });
                            }
                            else{
                                if(isNaN(original) || (parsedValue < original || parsedValue > original)){
                                    currentAdultPrice.adultPrice = value;
                                    currentAdultPrice.isInvalid = isBaseOccupancy ? false : this.validateValue(day, rateId, parsedValue, name, pricePosition, dailyPrice);
                                }
                                else{
                                    dailyPrice.adultPrices.splice(dailyPrice.adultPrices.findIndex(({position}) => position === pricePosition), 1);
                                }
                            };

                            if(isBaseOccupancy) {
                                const derivedOccupations = currentRate.defaultPax?.filter(({position, active, childrens}) => position && position !== pricePosition && active && !childrens);
                                
                                derivedOccupations.forEach((occ) => {
                                    let pOcc = dailyPrice.adultPrices.find(({position}) => position === occ.position);
                                    
                                    if(!pOcc) {
                                        dailyPrice.adultPrices.push({
                                            position: occ.position,
                                            adultPrice: 0,
                                            isInvalid: false
                                        });
                                        pOcc = dailyPrice.adultPrices.find(({position}) => position === occ.position);
                                    }

                                    if(occ.adults !== currentPax.adults){
                                        if (occ.derivedType === "amount") {
                                            pOcc.adultPrice = parsedValue + occ.derivedAmount;
                                        }
                                        else {
                                            pOcc.adultPrice = parsedValue * (1 + occ.derivedAmount / 100);
                                        };
                                    }
                                    else {
                                        pOcc.adultPrice = parsedValue;
                                    };

                                    pOcc.adultPrice = pOcc.adultPrice.toString();
                                });
                            }
                        }
                        else{
                            const currentChildPos = isD3 ? 3 : 2;
                            const currentChildPrice = dailyPrice.childPrices.find(({position}) => position === currentChildPos);
                            original = isD3 ? currentAvailability?.d3 : currentAvailability?.d2;

                            
                            if(!currentChildPrice) {
                                dailyPrice.childPrices.push({
                                    position: currentChildPos,
                                    adultPrice: value,
                                    isInvalid: this.validateValue(day, rateId, parsedValue, name, pricePosition, dailyPrice) // VER
                                });
                            }
                            else{
                                if(isNaN(original) || (parsedValue < original || parsedValue > original)){
                                    currentChildPrice.adultPrice = value;
                                    currentChildPrice.isInvalid = this.validateValue(day, rateId, parsedValue, name, pricePosition, dailyPrice);
                                }
                                else{
                                    dailyPrice.childPrices.splice(dailyPrice.childPrices.findIndex(({position}) => position === currentChildPos), 1);
                                }
                            };
                        };
                    };
                }
            }
            else{
                const dailyRestrictions = dailyData.restrictions[0];
                
                original = currentAvailability ? currentAvailability[name] : null;
                if(original !== dailyRestrictions[name]) {
                    dailyRestrictions[name] = value;
                    dailyRestrictions[`isInvalid${name}`] = this.validateValue(day, rateId, parsedValue, name, pricePosition, dailyRestrictions);
                }
                else {
                    delete dailyRestrictions[name];
                }
            };
                    
            if((!dailyData.prices.childPrices?.length === 0 && dailyData.prices.adultPrices?.length === 0 && dailyData.restrictions[0] && Object.keys(dailyData.restrictions[0]).length === 0)){
                rateData.splice(rateData.findIndex((d) => day.isSame(d.day, 'date')), 1);
            }
            
            revised = revised + 1;
            const next = dailyDataChanged[revised];
            if(next){
                value = next.value;
                name = next.name;
                pricePosition = next.position;
            }
        };


        let hasInvalidNewData = false;
        
        newData.forEach(({dates}) => {
            dates.forEach(({prices, restrictions}) => {
                if (prices && (prices.isInvalidchildPrice || prices.adultPrices.filter(({isInvalid}) => isInvalid).length > 0)){
                    hasInvalidNewData = true;
                }
                if(restrictions[0]){
                    const keys = Object.keys(restrictions[0]);
                    if(restrictions && keys.filter((k) => k.includes('isInvalid') && restrictions[0][k] === true).length > 0){
                        hasInvalidNewData = true;
                    }
                }
            })
        });
        
        this.setState({ newData, hasInvalidNewData: hasInvalidNewData, wasSaved: false });
    }

    changeFilter= (combo, ratesOptions, addedLabel) => {
        const { selectedLabels } = this.state;

        const newSelectedLabels = [];
        const selectedRates = combo??[], rateId = combo && combo[combo.length - 1]?.value;
        const getAv = rateId && ((!this.state.selectedRates && selectedRates) || (selectedRates && this.state.selectedRates?.length <= selectedRates.length));

        if(selectedLabels){
            const options = ratesOptions.flatMap(({options}) => options);
            selectedLabels.forEach(l => {
                const eligleRates = options.filter(({labelId}) => labelId === l.value).map(({value}) => value);
                const selectedRateForLabel = selectedRates.filter(({value}) => eligleRates.includes(value));
                if(selectedRateForLabel.length === eligleRates.length){
                    newSelectedLabels.push(l);
                }
            });

        }
        
        this.setState({ rateId, block: getAv, selectedLabels: newSelectedLabels }, () => {
            if(getAv) {
                if (addedLabel) {
                    this.setState({ selectedRates }, () => {
                        this.removeRateData(selectedRates);
                        if(rateId)
                            this.getPricesAndRestrictions(rateId, this.sequentialLabelRateFetch);
                    });
                }
                else {
                    this.setState({ selectedRates }, () => this.getPricesAndRestrictions(rateId));
                }
            }
            else this.removeRateData(selectedRates);
        });
    }

    removeRateData = (combo) => {
        const { dataDump } = this.state;
        const selectedRates = combo;
        const removedRates = [...dataDump].filter(({rateId}) => !selectedRates || selectedRates.filter(({value}) => value  === rateId).length === 0).map(({rateId}) => {
            return({
                rateId,
                hasNewData: this.hasNewData(rateId)
            });
        });
        
        if(removedRates && removedRates.find(({hasNewData}) => hasNewData)){
            this.unsavedChangesWarning(this.removeRates, selectedRates, true);
        }
        else{
            this.removeRates(selectedRates);
        }
    }

    removeRates = (selectedRates) => {
        const { categories, dataDump } = this.state;
        
        const copy = [...categories].map(c => {
            c.rates = c.rates.filter(({rateId}) => selectedRates && selectedRates.filter(({value}) => value === rateId).length > 0);
            return c;
        }).filter(c => c.rates && c.rates.length > 0);
        
        const copyDataDump = [...dataDump].filter(({rateId}) => selectedRates && selectedRates.filter(({value}) => value  === rateId).length > 0);

        this.setState({ categories: copy, dataDump: copyDataDump, selectedRates });
    }

    handleDateChange = (e, date) => {
        if(!e || (date && date.isBefore(moment().add(-1, 'day'), 'day'))) return;
        if(this.hasNewData()){
            this.unsavedChangesWarning(this.changeDate, date, true);
        }
        else{
            this.changeDate(date);
        }
    }

    changeDate = (date) => {
        const { categories, daysIdx } = this.state;
        const block = categories && categories.length > 0;
        this.setState({ currentDay: date, block }, () => {
            this.changeDates(daysIdx, block)
        });
    }

    hasNewData = (rateId) => {
        const { newData } = this.state;
        let hasRestrictions = false;
        
        const data = rateId ? newData.filter(({rateCodeId}) => rateCodeId === rateId) : newData;

        data.forEach(({dates}) => {
            dates.forEach(({restrictions, prices}) => {
                if(restrictions.length > 0 && restrictions.filter((d) => d).length > 0) hasRestrictions = true;
                if(prices.adultPrices && prices.adultPrices.length > 0) hasRestrictions = true;
                if(prices.childPrices && prices.childPrices.length > 0) hasRestrictions = true;
            })
        });

        return hasRestrictions;
    }

    saveValidations = (e) => {
        e.preventDefault();

        const { newData, showRestrictions, restrictionsToChannels } = this.state;

        if (!newData || newData.length === 0 || !newData.find((rate) => rate.dates &&
            rate.dates.filter(({ restrictions, prices }) => (restrictions.length > 0) ||
                (prices.adultPrices && prices.adultPrices.length > 0) || prices.childPrices.length > 0).length > 0)) return;


        if (showRestrictions && !restrictionsToChannels) {
            this.setState({ applyAllChannelsModal: true });
        }
        else {
            this.saveChanges();
        }
    }
    
    saveChanges = (applyToAllChannels) => {
        const { newData, selectedRates, currentDay, restrictionsToChannels } = this.state;
        const endDate = moment(currentDay).clone().add('30', 'days');

        const req = {
            rates: newData,
            rateIds: selectedRates.map(({ value }) => value),
            applyRestrictionsToChannels: applyToAllChannels || restrictionsToChannels,
            startDate: currentDay.format('YYYY-MM-DD'),
            endDate: endDate.format('YYYY-MM-DD')
        };

        this.setState({ block: true, restrictionsToChannels: req.applyRestrictionsToChannels, applyAllChannelsModal: false }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                        this.setState(({ block: false, error: errorMessage }));
                        return;
                    }
                    else {
                        const categories = this.catTreatment(data.response);
                        this.setState({ categories, newData: [], error: errorMessage, block: false, wasSaved: true }, () => 
                            handleNotification(data, <FormattedMessage id="BulkUpdate.PriceUpdatedSucessfuly" />, <FormattedMessage id="General.Success" />)
                        );
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, '/api/Price/Price/Price/v1/UpdateAri', req);
        })
    }

    toggleBool = (e) => {
        const { name, checked } = e.target;
        
        if(name === "showRestrictions") {
            const tutorialSteps = [...this.state.tutorialSteps ];
            tutorialSteps.find(({id}) => id === "ChooseVisibility").blockNext = false;
            this.setState({ tutorialSteps });
        }

        this.setState({ [name]: checked });
    }

    focusedInput = (e, row, col, rateId, isRule) => {
        e.preventDefault();

        if(isRule){
            let newColRest = this.state.newColRest, newRowRest = this.state.newRowRest, focusedRate = this.state.focusedRate;

            if(rateId !== focusedRate) focusedRate = rateId;
            if(newRowRest !== row) newRowRest = row;
            if(newColRest !== col) newColRest = col;

            this.setState({ newColRest, newRowRest, focusedRate });
        }
        else{
            let newRow = this.state.newRow, newCol = this.state.newCol, focusedRate = this.state.focusedRate;

            if(rateId !== focusedRate) focusedRate = rateId;
            if(newRow !== row) newRow = row;
            if(newCol !== col) newCol = col;

            this.setState({ newCol, newRow, focusedRate });
        }
    }

    toggleModal = () => {
        this.setState(prev => ({ modal: !prev.modal }));
    }

    backToInventory = () => {
        const { newData } = this.state;
        if(newData && newData.length > 0) {
            if(window.confirm(this.props.intl.formatMessage({ id: "Inventory.UnsavedChangesWarning" }))){
                window.open("/Inventory", '_self');
            }
        }
        else window.open("/Inventory", '_self');
    }

    getRateOption(title, total) {
        const rateOption = {
            label: this.getTitle(title, total),
            options: []
        };
        return rateOption;
    }

    getTitle(title, total) {
        return (
            <div className="d-flex justify-content-between"><span>{title}</span><span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{total}</span></div>
        );
    }

    renderPax = (rateId) => {
        const { rates, hasFlexChildPrices } = this.state;
        const renderPax = [];
        const rateCode = rates.find(({id}) => id === rateId);
        if(!rateCode) return renderPax;
        
        const childPaxs = rateCode?.defaultPax.filter(({childrens, active}) => childrens && childrens > 0 && active);
        const withChildren = childPaxs[0];

        if(withChildren){
            withChildren.adults = 0;
            withChildren.position = 2;
            withChildren.name = null;
            withChildren.isChildren = true;
            withChildren.isBaseOccupancy = null;
            
            renderPax.push(withChildren);
            
            if(hasFlexChildPrices && rateCode.canD3 && childPaxs.length > 1){
                const otherChildren = childPaxs[1]
                otherChildren.isD3 = true;
                otherChildren.adults = 0;
                otherChildren.position = 3;
                otherChildren.name = null;
                otherChildren.isChildren = true;
                otherChildren.isBaseOccupancy = null;

                renderPax.push(otherChildren);
            }
        }

        rateCode.defaultPax.filter(({childrens, active}) => (!childrens || childrens === 0) && active).forEach((pax) => {
            renderPax.push(pax);
        });

        return renderPax;
    }

    submitBulk = (results, isRules) => {
        const { categories, newData, priceVector } = this.state;

        if(isRules){
            this.setState({ showRestrictions: true });
        };

        results.forEach((res) => {
            let { priceType, value, priceModify, name, isD3 } = res.target;

            if(!isRules){
                if(priceType === "modify"){
                    let newDataPrices = newData.find(({rateCodeId}) => rateCodeId === res.rateId)?.dates?.find(({day}) => res.day.isSame(day, 'date'))?.prices;
                    let existingValue;

                    if(newDataPrices){
                        if(name === "adultPrices"){
                            existingValue = newDataPrices.adultPrices.find(({position}) => position === (res.pPos + 1))?.adultPrice;
                        }
                        else{
                            existingValue = newDataPrices.childPrices.find(({position}) => position === (isD3 ? 3 : 2))?.adultPrice;;
                        }
                    };

                    if(!existingValue){
                        const defaultDay = categories.find(({rates}) => rates.filter(({rateId}) => rateId === res.rateId).length > 0)?.rates?.find(({rateId}) => rateId === res.rateId)
                        ?.availability.find(({hotelDate}) => res.day.isSame(hotelDate, 'date'));
                        const currentPriceVector = priceVector[res.pPos > 10 ? 10 : res.pPos];
                        if(defaultDay){
                            if(name === "adultPrices"){
                                existingValue = defaultDay[currentPriceVector];
                            }
                            else{
                                existingValue = isD3 ? defaultDay.d3 : defaultDay.d2;
                            }
                        }
                    }

                    if(!existingValue) existingValue = 0;

                    if(priceModify === "increment"){
                        res.target.value = parseFloat(existingValue) + parseFloat(value);
                    }
                    else{
                        res.target.value = parseFloat(existingValue) * parseFloat(1 + value / 100);
                    };
                };
                res.target.value = parseFloat(res.target.value).toFixed(2).toString();
            }
            
            this.handleDataChange(res, res.day, res.rateId, (res.pPos + 1), isD3);
        });
        this.toggleModal();
    }

    toggleRestrictions = (e) => {
        const { newData, renderTutorial } = this.state;
        let hasRestrictions = false;

        if(!e?.target?.checked){
            if(renderTutorial) return;

            newData.forEach(({dates}) => {
                dates.forEach(({restrictions}) => {
                    if(restrictions.length > 0 && restrictions.filter((d) => d).length > 0) hasRestrictions = true;
                })
            });
        }
        
        if(hasRestrictions){
            this.unsavedChangesWarning(this.toggleBool, {target:{checked: e.target.checkbox, name: e.target.name }});
        }
        else {
            this.toggleBool(e);
        }
    }

    toggleRestrictionsToChannels = (e) => {
        this.setState({ restrictionsToChannels: e?.target?.checked });
    }
    
    clearData = (cb, data, clearPrices) => {
        const { newData } = this.state;
        let clearData = [...newData];

        clearData = clearData.map((rate) => {
            rate.dates = rate.dates.map((date) => {
                date.restrictions = [];
                if(clearPrices){
                    date.prices = {
                        adultPrices: []
                    };
                };
                return date;
            }).filter((date) => date.prices.childPrices?.length > 0 || date.prices.adultPrices?.length > 0 || (date.restrictions.length > 0 && Object.keys(date.restrictions[0]).length > 0));
            return rate;
        });

        this.setState({ newData: clearData, wasSaved: false }, () => {
            if(cb) cb(data);
        });
    }

    unsavedChangesWarning = (cb, data, clearPrices, noClear) => {
        const { intl } = this.props;
        if(window.confirm(intl.formatMessage({ id: "Inventory.UnsavedChangesWarning" }))){
            if(!noClear) this.clearData(cb, data, clearPrices);
        }
    }

    handleDeleteData = (e) => {
        e.preventDefault();

        if(this.hasNewData()){
            this.unsavedChangesWarning(this.refresh);
        }
        else{
            this.refresh();
        }
    }

    refresh = () => {
        const { selectedRates } = this.state;
        if(!selectedRates || selectedRates.length === 0) return;
        this.deleteData();
        this.getAllRatesAvailabilities();
    }

    deleteData = () => {
        this.setState({ newData: [] });
    }
    
    changeCol = (col) => {
        const { currentCol } = this.state;
        if(currentCol !== col) this.setState({ currentCol: col });
    }

    changeSelectedRate = (rateId) => {
        const { selectedRateId } = this.state;
        if(selectedRateId !== rateId) this.setState({ selectedRateId: rateId });
    }
    
    handleChangeNewBulkVersion = () => {
        localStorage.setItem('newBulkVersion', false);
        this.props.history.push(`/Inventory`);
    }

    toggleRenderTutorial = () => {
        const { renderTutorial } = this.state;
        this.setState({ renderTutorial: !renderTutorial });
    }

    openRestrictions = () => {
        this.toggleBool({target:{
            name: "showRestrictions",
            checked: true
        }});
    }

    handleInventoryChange = (value, day, roomCatId) => {
        const { newData, categories } = this.state;
        const cat = categories.find((c) => c.roomCatId === roomCatId);
        const catRates = cat.rates.map(({rateId}) => rateId);

        let rateData = newData?.find(({rateCodeId}) => catRates.includes(rateCodeId))?.dates;

        if(!rateData) {
            newData.push({ rateCodeId: catRates[0], dates: [] });
            rateData = newData.find((r) => catRates.includes(r.rateCodeId))?.dates;
        }

        let dailyData = rateData.find((d) => d.day === moment(day).format('YYYY-MM-DD'));

        if(!dailyData){
            rateData.push({
                prices: {
                    adultPrices: []
                },
                restrictions: [{}],
                day: moment(day).format('YYYY-MM-DD')
            });
            dailyData = rateData.find((d) => d.day === moment(day).format('YYYY-MM-DD'));
        }
        
        dailyData.availability = parseInt(value);

        this.setState({ newData });
    }

    getLabels(getPrices) {
        const { rateId, rates } = this.state;
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    handleNotification(data);
                    return;
                }
                if (data.response) {
                    var labels = [];
                    data.response.forEach((item) => {
                        var hasAssociatedRate = rates.find(rate => rate.labelId === item.id);
                        if (hasAssociatedRate) {
                            var label = { 'value': item.id, 'label': item.label };
                            labels.push(label)
                        }
                    })
                    
                    if(getPrices) {
                        this.setState({ labels },() => this.getPricesAndRestrictions(rateId));
                        return;
                    }
                    this.setState({ labels, block: false, hideLabels: !(labels.length > 0) });
                }
            }
        }, '/api/Rate/Rate/v1/RateLabel');
    }

    filterRate = (e, ratesOptions) => {
        const { selectedRates } = this.state;
        const selectedLabels = e??[];

        const labelsIds = selectedLabels.map(({value}) => value);
        const eligleRates = ratesOptions.flatMap(({options}) => options).filter(({labelId}) => labelsIds.includes(labelId));
        this.setState({ selectedLabels }, () => {
            if((!selectedLabels || selectedLabels.length === 0) && (!selectedRates || selectedRates.length === 0) && (eligleRates || eligleRates.length === 0))
                return
            
            this.changeFilter(eligleRates, ratesOptions, true)
        });
    }

    handlefiltersSection = () => {
        this.setState({ filtersSection: !this.state.filtersSection });
    }

    navCalendar = (int, week) => {
        const newDate = this.state.currentDay.add(int, (week ? 'weeks' : 'days'));
        if(newDate.isSameOrAfter(moment().add(-1, 'day'), 'day'))
            this.changeDate(newDate);
    }
    
    render() {
        const { block, error, currentDay, next14Days, daysIdx, days, isHost, selectedRates, categories, rates, showRestrictions, hasInvalidNewData, newData, showDerived,
        selectedRateId, selectedLabels, showAvailability, modal, selectedCell, currentCol, wasSaved, showVersionToggler, renderTutorial, tutorialSteps,
            restrictionsToChannels, displayRestrictionsByChannels, labels, filtersSection, hideLabels, hasFlexChildPrices, applyAllChannelsModal } = this.state;
        const { intl } = this.props;

        const help = <div>
            <strong>
                <FormattedMessage id="Inventory.RateInformation" />
            </strong>
            <hr className='my-1'/>
            <b><FormattedMessage id="Inventory.AvailabilityRate" /></b><br />
            <i className="fas fa-square mr-2 text-success" /><FormattedMessage id="Inventory.LowOccupancy" /><br />
            <i className="fas fa-square mr-2 text-warning" /><FormattedMessage id="Inventory.MediumOccupancy" /><br />
            <i className="fas fa-square mr-2 text-danger" /><FormattedMessage id="Inventory.HighOccupancy" /><br />
            <i className="fas fa-square mr-2" style={{ color: 'transparent', background: 'linear-gradient(35deg, rgba(217,83,79,1) 0%, rgba(0,0,0,1) 5%, rgba(217,83,79,1) 10%, rgba(0,0,0,1) 15%, rgba(217,83,79,1) 20%, rgba(0,0,0,1) 25%, rgba(217,83,79,1) 30%, rgba(0,0,0,1) 35%, rgba(217,83,79,1) 40%, rgba(0,0,0,1) 45%, rgba(217,83,79,1) 50%, rgba(0,0,0,1) 55%, rgba(217,83,79,1) 60%, rgba(0,0,0,1) 65%, rgba(217,83,79,1) 70%, rgba(0,0,0,1) 75%, rgba(217,83,79,1) 80%, rgba(0,0,0,1) 85%, rgba(217,83,79,1) 90%, rgba(0,0,0,1) 95%, rgba(217,83,79,1) 100%)' }} /><FormattedMessage id="Inventory.Overbooking"/><br/>
            <b><FormattedMessage id="Inventory.Cells" /></b><br />
            <i className="fas fa-square mr-2" style={{ color: '#ddeeff' }}/><FormattedMessage id="Inventory.CellEdited" /><br />
            <i className="fas fa-square mr-2" style={{ color: '#ffbebe' }}/><FormattedMessage id="Inventory.CellWithError" /><br />
            <hr className='my-1'/>
            {!global.isMobile ?
                <div onClick={this.toggleRenderTutorial} className="newVersion">
                    <i className="fas fa-book mr-1"/>
                    Tutorial
                </div>
            :''}
        </div>;

        const ratesOptions = rates.reduce((acc, curr) => {
            const opt = {
                labelId: curr.labelId,
                label: curr.name,
                value: curr.id,
                isDependent: false,
                isFixed: selectedRates?.length === 1,
                isDisabled: selectedRates?.length > 5
            };

            if (curr.dependencyParent) {
                opt.isDependent = true;
                acc[1].label = this.getTitle(<FormattedMessage id="BulkUpdate.Dependent" />, acc[1].options.push(opt));
            }
            else {
                acc[0].label = this.getTitle(<FormattedMessage id="BulkUpdate.Main" />, acc[0].options.push(opt));
            }
            return acc;
        }, [this.getRateOption(<FormattedMessage id="BulkUpdate.Main" />, 0), this.getRateOption(<FormattedMessage id="BulkUpdate.Dependent" />, 0)]);

        return (
            <Authorization
                perform="Inventory:ARI:Update"
                yes={() => (
                    <BlockUi tag="div" blocking={block}>
                        {applyAllChannelsModal ?
                            <ConfirmRestrictionsToChannelsModal
                                isOpen={applyAllChannelsModal}
                                toggleModal={_ => this.setState({ applyAllChannelsModal: false })}
                                confirmFunction={_ => this.saveChanges(true)}
                                cancelFunction={_ => this.saveChanges(false)}
                            />
                        : ''}

                        {showVersionToggler ?
                            <Row className="p-2" style={{ position: 'absolute', bottom: '0', right: '2rem', fontSize: '0.8em' }}>
                                <Col>
                                    <div onClick={this.handleChangeNewBulkVersion} className="newVersion">
                                        <i className="fas fa-undo mr-1" style={{ color: '#007bff' }}/>
                                        <FormattedMessage id="Inventory.RevertVersion"/>
                                    </div>
                                </Col>
                            </Row>
                        :''}
                        {renderTutorial ?
                            <Tutorial
                                toggle={this.toggleRenderTutorial}
                                tutorialSteps={tutorialSteps}
                            />
                        :''}
                        <ErrorAlert error={error} isMulti={error?.length > 1}/>
                        <div id="MicroInvetoryUpdate">
                            <Card className="border-0">
                                {global.isMobile ?
                                    <div className='moveIt pb-2'>
                                        <div>
                                            <div className="py-2 d-flex align-items-center">
                                                <div onClick={this.handlefiltersSection} style={{ width: '10%' }} className='d-flex align-items-center justify-content-center'>
                                                    <i className="fas fa-ellipsis-v"></i>
                                                </div>
                                                <div style={{ width: '80%' }} className='px-2 py-1 h-100'>
                                                    <div className='py-1 w-100 d-flex align-items-center px-2 h-100 justify-content-between' style={{ borderRadius: '4px', border: '1px solid #e7e7e7' }}>
                                                        <div className='d-flex align-items-center'>
                                                            <div onClick={_ => this.navCalendar(-1, true)} className='px-1' style={{ fontSize: '1.2em' }}>
                                                                <i className="fas fa-angle-double-left"/>
                                                            </div>
                                                            <div onClick={_ => this.navCalendar(-1)} className='ml-1 px-1'>
                                                                <i className="fas fa-angle-left"/>
                                                            </div>
                                                        </div>
                                                        <div className='text-center CalendarMobileNav'>
                                                            <CustomSingleDatePicker
                                                                date={currentDay.locale(intl.locale)}
                                                                id="Date"
                                                                required={true}
                                                                isOutsideRange={day => day < moment().subtract(1, 'day') || day > moment().add(24, 'month')}
                                                                showTodaysButton={true}
                                                                showYearOptions={{ pastYears: false, futureYears: true }}
                                                                onDateChange={date => this.handleDateChange(this, date)}
                                                                noBorder={true}
                                                                block={true}
                                                                readOnly={true}
                                                                displayFormat="DD, MMMM, YYYY"
                                                                showDefaultInputIcon={false}
                                                            />
                                                        </div>
                                                        <div className='d-flex align-items-center'>
                                                            <div onClick={_ => this.navCalendar(1)} className='px-1'>
                                                                <i className="fas fa-angle-right"/>
                                                            </div>
                                                            <div onClick={_ => this.navCalendar(1, true)} className='ml-1 px-1' style={{ fontSize: '1.2em' }}>
                                                                <i className="fas fa-angle-double-right"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: '10%' }} className='d-flex align-items-center justify-content-center'>
                                                    <Button
                                                        title={intl.formatMessage({ id: "Inventory.SaveButton" })}
                                                        onClick={this.saveValidations}
                                                        disabled={hasInvalidNewData}
                                                        className="btn btn-host btn-sm text-white mr-2 shadow"
                                                    >
                                                        <i className="fas fa-save"/>
                                                    </Button>
                                                </div>
                                            </div>
                                            {!hideLabels ?
                                                <div className='px-2'>
                                                    <CustomSelect
                                                        icon={'fas fa-tag'}
                                                        isMulti
                                                        isClearable={false}
                                                        value={selectedLabels}
                                                        isSearchable
                                                        placeholder={<FormattedMessage id="Inventory.SelectRateLabel" />}
                                                        options={labels}
                                                        onChange={evt => this.filterRate(evt, ratesOptions)}
                                                    />
                                                </div>
                                                : ''}
                                            <div className='mt-2 px-2'>
                                                <CustomSelect
                                                    icon={'fas fa-gift'}
                                                    isMulti
                                                    isClearable={false}
                                                    isSearchable
                                                    value={selectedRates}
                                                    placeholder={<FormattedMessage id="Inventory.ChooseRate"/>}
                                                    isOptionDisabled={_ => selectedRates.length > 5}
                                                    options={ratesOptions}
                                                    name="selectedRates"
                                                    onChange={e => this.changeFilter(e, ratesOptions)}
                                                />
                                            </div>
                                        </div>
                                        {filtersSection ? 
                                            <div className='px-2 mt-4'>
                                                <div>
                                                    <div className='d-inline-flex align-items-center'>
                                                        <Button className="btn btn-host btn-sm text-white mr-2 shadow" onClick={this.handleDeleteData}>
                                                            <i className="fas fa-sync-alt"/>
                                                        </Button>
                                                        <Button onClick={this.toggleModal} id="UpdateInBulk" className="btn btn-host btn-sm text-white mr-2 shadow">
                                                            <i className="fas fa-tasks"/>
                                                        </Button>
                                                        <Modal isOpen={modal} style={{ width: '97%', marginTop: '8vh' }} toggle={this.toggleModal}>
                                                            <BulkUpdateCalendar
                                                                toggleModal={this.toggleModal}
                                                                selectedRates={selectedRates}
                                                                next14Days={next14Days}
                                                                rateList={rates}
                                                                submitBulk={this.submitBulk}
                                                                currentDay={currentDay}
                                                                hasFlexChildPrices={hasFlexChildPrices}
                                                            />
                                                        </Modal>
                                                    </div>
                                                    <CommonHelper help={help} id={'MicroUpdateHelp'} />
                                                </div>
                                                <div className='mt-3'>
                                                    <div className='d-flex h-100 align-items-center justify-content-between'>
                                                        <span className='mr-2 text-muted' onClick={_ => this.toggleRestrictions({target:{checked: !showRestrictions, name: "showRestrictions"}})}>
                                                            <FormattedMessage id="Inventory.ShowRestrictions"/>
                                                        </span>
                                                        <CustomInput
                                                            type="switch"
                                                            checked={showRestrictions}
                                                            onClick={e => e.stopPropagation()}
                                                            name="showRestrictions"
                                                            id="showRestrictions"
                                                            onChange={this.toggleRestrictions}
                                                        />
                                                    </div>
                                                </div>
                                                {showRestrictions ?
                                                    <div className='mt-2' id="RestrictionsToChannelsToggle" style={{ display: displayRestrictionsByChannels }}>
                                                        <div className='d-flex h-100 align-items-center justify-content-between'>
                                                            <span className='mr-2 text-muted' onClick={_ => this.toggleRestrictionsToChannels({target:{checked: !restrictionsToChannels}})}>
                                                                <FormattedMessage id="BulkUpdate.RestrictionsToChannels" />
                                                            </span>
                                                            <CustomInput                                                                    
                                                                type="switch"
                                                                checked={restrictionsToChannels}
                                                                name="RestrictionsToChannels"
                                                                id="RestrictionsToChannels"
                                                                onClick={e => e.stopPropagation()}
                                                                onChange={this.toggleRestrictionsToChannels}
                                                            />
                                                        </div>
                                                    </div>
                                                : ''}
                                                <div className='mt-2'>
                                                    <div className='d-flex h-100 align-items-center justify-content-between'>
                                                        <span className='mr-2 text-muted' onClick={_ => this.toggleBool({target:{checked: !showDerived, name:"showDerived"}})}>
                                                            <FormattedMessage id="Inventory.ShowDerived"/>
                                                        </span>
                                                        <CustomInput
                                                            type="switch"
                                                            checked={showDerived}
                                                            id="showDerived"
                                                            name="showDerived"
                                                            onClick={e => e.stopPropagation()}
                                                            onChange={this.toggleBool}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='mt-2'>
                                                    <div className='d-flex h-100 align-items-center justify-content-between'>
                                                        <span className='mr-2 text-muted' onClick={_ => this.toggleBool({target:{checked: !showAvailability, name:"showAvailability"}})}>
                                                            <FormattedMessage id="Inventory.showAvailability"/>
                                                        </span>
                                                        <CustomInput
                                                            type="switch"
                                                            checked={showAvailability}
                                                            id="showAvailability"
                                                            onClick={e => e.stopPropagation()}
                                                            name="showAvailability"
                                                            onChange={this.toggleBool}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        :''}
                                    </div>
                                :
                                    <Row className="my-2">
                                        {!hideLabels ? 
                                            <Col className="col-2">
                                                <CustomSelect
                                                    icon={'fas fa-tag'}
                                                    isMulti
                                                    isClearable={false}
                                                    value={selectedLabels}
                                                    isSearchable
                                                    placeholder={<FormattedMessage id="Inventory.SelectRateLabel" />}
                                                    options={labels}
                                                    onChange={evt => this.filterRate(evt, ratesOptions)}
                                                />
                                            </Col>
                                            : ''}
                                        <Col className="col-3" id="SelectRate">
                                            <CustomSelect
                                                icon={'fas fa-gift'}
                                                isMulti
                                                isClearable={false}
                                                isSearchable
                                                value={selectedRates}
                                                placeholder={<FormattedMessage id="Inventory.ChooseRate"/>}
                                                options={ratesOptions}
                                                isOptionDisabled={_ => selectedRates.length > 5}
                                                name="selectedRates"
                                                onChange={e => this.changeFilter(e, ratesOptions)}
                                            />
                                        </Col>
                                        <Col id="ChooseVisibility">
                                            <div style={{ display: 'flex', alignItems: 'center', height: '100%', gap: '1rem' }}>
                                                <div>
                                                    <FormattedMessage id="Inventory.show"/>
                                                </div>
                                                <div id="RestrictionToggle">
                                                    <div className='d-flex h-100 align-items-center'>
                                                        <span className='mr-2'>
                                                            <FormattedMessage id="Inventory.ShowRestrictions"/>
                                                        </span>
                                                        <CustomInput
                                                            type="switch"
                                                            checked={showRestrictions}
                                                            name="showRestrictions"
                                                            id="showRestrictions"
                                                            onChange={this.toggleRestrictions}
                                                        />
                                                    </div>
                                                    {showRestrictions ?
                                                        <div className='mt-1' id="RestrictionsToChannelsToggle" style={{ display: displayRestrictionsByChannels }}>
                                                            <div className='d-flex h-100 align-items-center'>
                                                                <span className='mr-2'>
                                                                    <FormattedMessage id="BulkUpdate.RestrictionsToChannels" />
                                                                </span>
                                                                <CustomInput                                                                    
                                                                    type="switch"
                                                                    checked={restrictionsToChannels}
                                                                    name="RestrictionsToChannels"
                                                                    id="RestrictionsToChannels"
                                                                    onChange={this.toggleRestrictionsToChannels}
                                                                />
                                                            </div>
                                                        </div>
                                                    : ''}
                                                </div>
                                                <div>
                                                    <div className='d-flex h-100 align-items-center'>
                                                    <span className='mr-2'>
                                                        <FormattedMessage id="Inventory.ShowDerived"/>
                                                    </span>
                                                    <CustomInput
                                                        type="switch"
                                                        checked={showDerived}
                                                        id="showDerived"
                                                        name="showDerived"
                                                        onChange={this.toggleBool}
                                                    />
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className='d-flex h-100 align-items-center'>
                                                    <span className='mr-2'>
                                                        <FormattedMessage id="Inventory.showAvailability"/>
                                                    </span>
                                                    <CustomInput
                                                        type="switch"
                                                        checked={showAvailability}
                                                        id="showAvailability"
                                                        name="showAvailability"
                                                        onChange={this.toggleBool}
                                                    />
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col className="text-right p-0 pr-3 tutorial col-2">
                                            <div id="ControlButtons" className='d-inline'>
                                                <Button title={intl.formatMessage({ id: "Inventory.Refresh" })} className="btn btn-host btn-sm text-white mr-2 shadow" onClick={this.handleDeleteData}>
                                                    <i className="fas fa-sync-alt"/>
                                                </Button>
                                                <Button title={intl.formatMessage({ id: "Inventory.MicroBulk" })} onClick={this.toggleModal} id="UpdateInBulk" className="btn btn-host btn-sm text-white mr-2 shadow">
                                                    <i className="fas fa-tasks"/>
                                                </Button>
                                                <UncontrolledPopover isOpen={modal} style={{ width: '65rem' }} data-trigger="click" trigger="click" placement={"left"} target={"UpdateInBulk"}>
                                                    <BulkUpdateCalendar
                                                        toggleModal={this.toggleModal}
                                                        selectedRates={selectedRates}
                                                        next14Days={next14Days}
                                                        rateList={rates}
                                                        submitBulk={this.submitBulk}
                                                        currentDay={currentDay}
                                                        hasFlexChildPrices={hasFlexChildPrices}
                                                    />
                                                </UncontrolledPopover>
                                                <Button title={intl.formatMessage({ id: "Inventory.SaveButton" })} onClick={this.saveValidations} disabled={hasInvalidNewData} className="btn btn-host btn-sm text-white mr-2 shadow">
                                                    <i className="fas fa-save"/>
                                                </Button>
                                            </div>
                                            <CommonHelper help={help} id={'MicroUpdateHelp'} />
                                        </Col>
                                    </Row>
                                }
                            </Card>
                            {categories.length > 0 || !global.isMobile ?
                                <div style={{ overflow: global.isMobile? 'hidden' : '' }}>
                                    <CalendarBar
                                        currentDay={currentDay}
                                        next14Days={next14Days}
                                        action={this.handleDateChange}
                                        inventorySeeOneMonth={daysIdx === 1}
                                        days={days[daysIdx]}
                                        handleSeeOneMonth={this.handleSeeOneMonth}
                                        handleScroll={this.onScroll}
                                        currentCol={currentCol}
                                    />
                                    <div style={{ overflow: 'hidden' }}>
                                        {categories.map((rc, k) => 
                                            <Card key={k} className="pt-2" style={{ border: 'unset', borderBottom: '1px solid #dee2e6' }}>
                                                {showAvailability ?
                                                    <CardHeader className="card-header-gridview border-0 pb-0 ">
                                                        <EditableRoomAvailabilityBar
                                                            handleInventoryChange={this.handleInventoryChange}
                                                            roomDesc={rc.roomDesc}
                                                            roomBlock={rc.roomBlock}
                                                            roomCatId={rc.roomCatId}
                                                            dayAvailability={rc.rates[0].availability} // Get first rate availability because rates from same cat have same avalability
                                                            next14Days={next14Days}
                                                            isHost={isHost}
                                                            onScroll={this.onScroll}
                                                            newData={newData?.find(({rateCodeId}) => rc.rates.findIndex(({id}) => id === rateCodeId) > -1)?.dates}
                                                            wasSaved={wasSaved}
                                                        />
                                                    </CardHeader>
                                                :''}
                                                {rc.rates.map((rate, key) =>
                                                    <CardBody key={key} className={`card-header-gridview pt-0 ${rc.rates.length > 1 ? 'border-bottom' : 'border-0'}`} style={{ borderTop: key > 0 ? '1px solid black' : '' }}>
                                                        <EditableRateAvailabilityBar
                                                        //functions
                                                            onScroll={this.onScroll}
                                                            focusedInput={this.focusedInput}
                                                            handleDataChange={this.handleDataChange}
                                                            changeSelectedCell={this.changeSelectedCell}
                                                            changeCol={this.changeCol}
                                                            changeSelectedRate={this.changeSelectedRate}
                                                        //bools
                                                            selectedRateId={selectedRateId}
                                                            showRestrictions={showRestrictions}
                                                            showDerived={showDerived}
                                                            wasSaved={wasSaved}
                                                            hasFlexChildPrices={hasFlexChildPrices}
                                                        //rate data
                                                            availability={rate.availability}
                                                            positionToDisplay={rate.positionToDisplay}
                                                            rateCode={rates.find(({id}) => id === rate.rateId)}
                                                        //standard data
                                                            selectedCell={selectedCell}
                                                            next14Days={next14Days}
                                                            newData={newData?.find(({rateCodeId}) => rateCodeId === rate.rateId)?.dates}
                                                            renderPax={this.renderPax(rate.rateId)}
                                                        />
                                                    </CardBody>
                                                )}
                                            </Card>
                                        )}
                                    </div>
                                </div>
                            :''}
                        </div>
                    </BlockUi>
                )}
            />
        )
    }
}

export default injectIntl(MicroInventoryUpdate)