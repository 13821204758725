import React, { Component } from 'react';
import { RatesPanel } from './RatesPanel';
import { Col, Row, Input, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../Base/Common/CustomSelect'

export class MajorBulkUpdateModalChooseRates extends Component {
    render() {
        const { selectRatesByRoomCat, selectAllRates, handleRateChange, rateCodeIds, searchRates, areAllRatesSelected, labels, hideLabels } = this.props;

        return (
            <div>
                <Row className="align-items-center mb-2">
                    <Col className="col-3 d-flex align-items-center">
                        <Input
                            type="text"
                            placeholder={this.props.intl.formatMessage({ id: "MajorBulkUpdateModal.SearchRate" })}
                            onChange={(e) => searchRates(e, false)}
                            style={{ paddingRight: '35px' }}
                        />
                        <i className="fas fa-search text-muted" style={{ position: 'absolute', right: '28px' }} />
                    </Col>
                    {global.operationMode !== 'PmsFull' && !hideLabels ?
                        <Col className="col-3 align-items-center">
                            <CustomSelect
                                icon={'fas fa-tag'}
                                isMulti
                                isClearable={false}
                                isSearchable
                                placeholder={<FormattedMessage id="Inventory.SelectRateLabel" />}
                                options={labels}
                                onChange={evt => searchRates(evt, false, true) }
                            />
                        </Col>
                        : ''}
                    <Col className="text-right">
                        <Button className="btn btn-sm btn-host" onClick={() => selectAllRates(areAllRatesSelected)}>
                            {areAllRatesSelected ? <FormattedMessage id="MajorBulkUpdateModal.RemoveAllRates" /> : <FormattedMessage id="MajorBulkUpdateModal.SelectAllRates" />}
                        </Button>
                    </Col>
                </Row>
                {this.props.roomCategories.map((rc, key) => 
                    !rc.hidden && <RatesPanel
                        rc={rc}
                        key={key}
                        selectRatesByRoomCat={selectRatesByRoomCat}
                        handleRateChange={handleRateChange}
                        rateCodeIds={rateCodeIds}
                    />
                ) }
            </div>    
        );
    }
}