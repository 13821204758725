import React, { Component } from 'react';
import { Row, Col, Input, InputGroup, FormGroup, Label, Button, Card, CardHeader, CardBody } from 'reactstrap';
import { getAPI, postAPI } from "../../../Base/API";
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { FormattedMessage } from 'react-intl';
import { handleNotification } from "../../../Base/Notification";
import { getCountries } from '../../../Base/Common/ReferenceDataFunctions';
import CustomSelect from '../../../Base/Common/CustomSelect';

export class ComplexBillingEntity extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            error: null,
            block: true,
            complexId: this.props.selectedEntity.id,
            billingInfo: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedEntity && nextProps.selectedEntity !== this.props.selectedEntity) {
            this.setState({
                complexId: nextProps.selectedEntity.id
            }, () => this.getBillingInfo())
        }
    }

    componentDidMount() {
        if (this.state.complexId) {
            this.getBillingInfo();
        }
    }

    getBillingInfo = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ blocking: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ blocking: false, error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ billingInfo: data.response });
            }
            this.setState({ block: false });
        }, `/api/hotel/Complexes/v1/${this.state.complexId}/BillingInfo`);
    }

    changeValue = (name, evt) => {
        const value = evt.target ? evt.target.value : null;
        this.setState(prevState => ({
            ...prevState,
            billingInfo: {
                ...prevState.billingInfo,
                [name]: value
            }
        }));
    }    

    changeValueToInt = (name, evt) => {
        const value = evt.target ? evt.target.value : null;
        this.setState(prevState => ({
            ...prevState,
            billingInfo: {
                ...prevState.billingInfo,
                [name]: value ? parseInt(value) : value
            }
        }));
    }

    setFilterState = (name, combo) => {        
        const val = combo ? combo.value : null;
        this.setState(prevState => ({
            ...prevState,
            billingInfo: {
                ...prevState.billingInfo,
                [name]: val
            }
        }));
    }

    handleSubmit(e) {
        e.preventDefault();

        if (this.form.current.reportValidity()) {
            this.setState({
                block: true
            });

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = []
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    this.setState({ billingInfo: data });
                }
                handleNotification(data, <FormattedMessage id="ComplexBillingEntity.SaveBillingEntitySuccess" />, 'Success');
                this.setState({ block: false });
            }, `/api/hotel/Complexes/v1/${this.state.complexId}/BillingInfo`, JSON.stringify(this.state.billingInfo));
        }
    }

    render() {
        return (
            <Card className="border-0 mb-2">
                <CardHeader className="border-bottom bg-white" >
                    <div>
                        <span className="fas far fa-file"> </span> <FormattedMessage id="ComplexBillingEntity.Title" />
                        <Button color=" btn-host btn-sm float-right" onClick={this.handleSubmit}>
                            <i className="fas fa-save" />
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <div>
                        <form ref={this.form}>
                            <BlockUi tag="div" blocking={this.state.block}>
                                <ErrorAlert error={this.state.error} />
                                <Row>
                                    <Col className="col-12">
                                        <FormGroup row>
                                            <Label for="address" sm={2}> <FormattedMessage id="ComplexBillingEntity.Name" /> </Label>
                                            <Col sm={10}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ComplexBillingEntity.Name">
                                                        {placeholder => <Input type="text" name="billingEntityName" id="billingEntityName" placeholder={placeholder} value={this.state.billingInfo ? this.state.billingInfo.billingEntityName : ''} onChange={this.changeValue.bind(this, 'billingEntityName')} required />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>                                    
                                </Row>
                                <Row>
                                    <Col className="col-12">
                                        <FormGroup row>
                                            <Label for="address" sm={2}> <FormattedMessage id="ComplexBillingEntity.FiscalNumber" /> </Label>
                                            <Col sm={10}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ComplexBillingEntity.FiscalNumber">
                                                        {placeholder => <Input type="text" name="fiscalNumber" id="fiscalNumber" placeholder={placeholder} value={this.state.billingInfo ? this.state.billingInfo.fiscalNumber : ''} onChange={this.changeValueToInt.bind(this, 'fiscalNumber')} required />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>                                    
                                </Row>
                                <Row>
                                    <Col className="col-12">
                                        <FormGroup row>
                                            <Label for="address" sm={2}> <FormattedMessage id="ComplexBillingEntity.Address" /> </Label>
                                            <Col sm={10}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ComplexBillingEntity.Address">
                                                        {placeholder => <Input type="text" name="billingAddress" id="billingAddress" placeholder={placeholder} value={this.state.billingInfo ? this.state.billingInfo.billingAddress : ''} onChange={this.changeValue.bind(this, 'billingAddress')} required />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>                                    
                                </Row>
                                <Row>
                                    <Col className="col-6">
                                        <FormGroup row>
                                            <Label for="address" sm={4}> <FormattedMessage id="ComplexBillingEntity.AddressCity" /> </Label>
                                            <Col sm={8}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ComplexBillingEntity.AddressCity">
                                                        {placeholder => <Input type="text" name="billingAddressCity" id="billingAddressCity" placeholder={placeholder} value={this.state.billingInfo ? this.state.billingInfo.billingAddressCity : ''} onChange={this.changeValue.bind(this, 'billingAddressCity')} required />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-6">
                                        <FormGroup row>
                                            <Label for="address" sm={4}> <FormattedMessage id="ComplexBillingEntity.AddressZipCode" /> </Label>
                                            <Col sm={8}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ComplexBillingEntity.AddressZipCode">
                                                        {placeholder => <Input type="text" name="billingAddressZipCode" id="billingAddressZipCode" placeholder={placeholder} value={this.state.billingInfo ? this.state.billingInfo.billingAddressZipCode : ''} onChange={this.changeValue.bind(this, 'billingAddressZipCode')} required />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>                                    
                                </Row>
                                <Row>
                                    <Col className="col-6">
                                        <FormGroup row>
                                            <Label for="address" sm={4}> <FormattedMessage id="ComplexBillingEntity.AddressCountry" /> </Label>
                                            <Col sm={8}>
                                                <CustomSelect name={'billingAddressCountry'} options={getCountries()} placeholder={<FormattedMessage id="ComplexBillingEntity.AddressCountry" />} value={this.state.billingInfo ? getCountries().filter(el => el.value === this.state.billingInfo.billingAddressCountry) : ''} onChange={this.setFilterState.bind(this, 'billingAddressCountry')} required />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-6">
                                        <FormGroup row>
                                            <Label for="address" sm={4}> <FormattedMessage id="ComplexBillingEntity.AddressPhone" /> </Label>
                                            <Col sm={8}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ComplexBillingEntity.AddressPhone">
                                                        {placeholder => <Input type="text" name="billingAddressPhone" id="billingAddressPhone" placeholder={placeholder} value={this.state.billingInfo ? this.state.billingInfo.billingAddressPhone : ''} onChange={this.changeValue.bind(this, 'billingAddressPhone')} required />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>                                    
                                </Row>                                
                                <Row>
                                    <Col className="col-12">
                                        <FormGroup row>
                                            <Label for="address" sm={2}> <FormattedMessage id="ComplexBillingEntity.Email" /> </Label>
                                            <Col sm={10}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ComplexBillingEntity.Email">
                                                        {placeholder => <Input type="text" name="billingEmail" id="billingEmail" placeholder={placeholder} value={this.state.billingInfo ? this.state.billingInfo.billingEmail : ''} onChange={this.changeValue.bind(this, 'billingEmail')} required />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>                                    
                                </Row>
                                <Row>
                                    <Col className="col-6">
                                        <FormGroup row>
                                            <Label for="address" sm={4}> <FormattedMessage id="ComplexBillingEntity.PaymentMethod" /> </Label>
                                            <Col sm={8}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ComplexBillingEntity.PaymentMethod">
                                                        {placeholder => <Input type="text" name="paymentMethod" id="paymentMethod" placeholder={placeholder} value={this.state.billingInfo ? this.state.billingInfo.paymentMethod : ''} onChange={this.changeValue.bind(this, 'paymentMethod')} required />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-6">
                                        <FormGroup row>
                                            <Label for="address" sm={4}> <FormattedMessage id="ComplexBillingEntity.BankDetails" /> </Label>
                                            <Col sm={8}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ComplexBillingEntity.BankDetails">
                                                        {placeholder => <Input type="text" name="bankDetails" id="bankDetails" placeholder={placeholder} value={this.state.billingInfo ? this.state.billingInfo.bankDetails : ''} onChange={this.changeValue.bind(this, 'bankDetails')} required />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>                                    
                                </Row>
                                <CardHeader className="border-bottom bg-white px-0" >
                                    <span className="fas far fa-user"> </span> <FormattedMessage id="ComplexBillingEntity.Responsible" />                                        
                                </CardHeader>
                                <CardBody className="px-0">
                                    <Row>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="address" sm={4}> <FormattedMessage id="ComplexBillingEntity.ContactName" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexBillingEntity.ContactName">
                                                            {placeholder => <Input type="text" name="billingContactName" id="billingContactName" placeholder={placeholder} value={this.state.billingInfo ? this.state.billingInfo.billingContactName : ''} onChange={this.changeValue.bind(this, 'billingContactName')} required />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col className="col-6">
                                            <FormGroup row>
                                                <Label for="address" sm={4}> <FormattedMessage id="ComplexBillingEntity.BillingPhone" /> </Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="ComplexBillingEntity.BillingPhone">
                                                            {placeholder => <Input type="text" name="billingPhone" id="billingPhone" placeholder={placeholder} value={this.state.billingInfo ? this.state.billingInfo.billingPhone : ''} onChange={this.changeValue.bind(this, 'billingPhone')} required />}
                                                        </FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </BlockUi>
                        </form>
                    </div>
                </CardBody>
            </Card>
        );
    }
}
