import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { EmptyCard, CommonHelper } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Notification";
import { postAPI } from "../../Base/API"
import moment from 'moment'
import CustomToolTip from '../../../Utils/CustomToolTip';

class MainPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            block: true,
            hasErrors: false,
            validationReport: null,
            promotionDatesSentAzure: [],
            paymentGatewayDatesSentAzure: [],
            datesSentAzures: [],
            descriptionsDatesSentAzure: [],
            hasDataToSent: false
        }
    }

    componentDidMount() {
        this.validateFields(null, false);
    }

    validateFields = (evt, loadToAzure) => {
        if (evt) evt.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;

            const errorMessage = [];
            if (error) {
				if (loadToAzure && error.request && (error.request.status === 504 || error.request.status === 500 || error.request.status === 404)) {
					handleNotification({}, <FormattedMessage id="BEConfigDashboard.TimeoutConsultLogs"/>, <FormattedMessage id="ChannelConf.Timeout" />, 'info');
				}
				else {
					errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				}

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                let propertyErrors = [], roomErrors = [], rateErrors = [];

                if (data.azureComplexValidationResponses && data.azureComplexValidationResponses.length > 0) {

                    data.azureComplexValidationResponses.forEach(el => {
                        if (el.complexErrors && el.complexErrors.length > 0) {
                            propertyErrors.push(data.azureComplexValidationResponses[0].complexId);
                        }

                        if (el.roomErrors && el.roomErrors.length > 0) {
                            roomErrors = roomErrors.concat(el.roomErrors.map(el => el.roomId));
                        }

                        if (el.rateErrors && el.rateErrors.length > 0) {
                            rateErrors = rateErrors.concat(el.rateErrors.map(el => el.rateId));
                        }
                    })
                }
                else {
                    if (loadToAzure) {
                        handleNotification(data, <FormattedMessage id="ComplexList.DataLoadedToOTA" />, <FormattedMessage id="General.Success" />);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="ComplexList.DataValidatedToOTA" />, <FormattedMessage id="General.Success" />);
                    }
                }

                this.props.setValidations(propertyErrors, roomErrors, rateErrors);
                this.setState({
                    block: false,
                    error: errorMessage,
                    validationReport: data.azureComplexValidationResponses,
                    promotionDatesSentAzure: data.complexReport.promotionDatesSentAzure,
                    paymentGatewayDatesSentAzure: data.complexReport.paymentGatewayDatesSentAzure,
                    datesSentAzures: data.complexReport.datesSentAzures,
                    descriptionsDatesSentAzure: data.complexReport.descriptionsDatesSentAzure,
                    hasDataToSent: data.complexReport.promotionDatesSentAzure.length > 0 || data.complexReport.paymentGatewayDatesSentAzure.length > 0 || data.complexReport.datesSentAzures.length > 0 || data.complexReport.descriptionsDatesSentAzure.length > 0
                });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/hotel/v1/LoadHotelSearchData?loadToAzure=${loadToAzure}`);
    }

    render() {
        const { validationReport, block, error, promotionDatesSentAzure, paymentGatewayDatesSentAzure, datesSentAzures, descriptionsDatesSentAzure, hasDataToSent } = this.state;

        return (
            <div>
                <EmptyCard block={block} error={error}>
                    <Row>
                        <Col>
                            {validationReport && validationReport.length > 0 ?
                                <h6>
                                    <i className="fas fa-exclamation-triangle mr-2 mb-2 text-warning" />
                                    <FormattedHTMLMessage id="BEConfigDashboard.TheFollowingRequirementsNeedToBeMet" />:
                                </h6>
                            : ''}
                        </Col>
                        <Col className="d-flex justify-content-end align-items-center mb-3">
                            <Button className="btn btn-host btn-sm mr-2" onClick={(e) => this.validateFields(e, false)} id="validateContent">
                                <i className="fas fa-clipboard-check"/>
                            </Button>
                            <CustomToolTip placement="bottom" target="validateContent">
                                <FormattedMessage id="BEConfigDashboard.ValidateContent" />
                            </CustomToolTip>

                            <Button className="btn btn-host btn-sm" onClick={(e) => this.validateFields(e, true)} id="loadData">
                                <i className="fas fa-cloud-upload-alt" />
                            </Button>
                            <CustomToolTip placement="bottom" target="loadData">
                                <FormattedMessage id="BEConfigDashboard.LoadDataToBE" />
                            </CustomToolTip>
                            {
                                hasDataToSent ?
                                    <i style={{ position: 'relative', top: '-12px', right: '10px' }} className="fas fa-exclamation-triangle text-warning" />
                                    :
                                    <span />
                            }

                            <CommonHelper help={<FormattedHTMLMessage id="BEConfigDashboard.Help" />} id='BEConfigDashboardHelp' />
                        </Col>
                    </Row>

                    {validationReport && validationReport.length > 0 ?
                        validationReport.map((item, key) =>
                            <div key={key}>
                                {item.complexErrors && item.complexErrors.length > 0 ?
                                    <div>
                                        <Row>
                                            <Col><h6 className="mb-1"><FormattedHTMLMessage id="BEConfigDashboard.Property" />:</h6> </Col>
                                        </Row>

                                        {item.complexErrors.map((complex, k1) =>
                                            <Row key={k1} className="overbglight mb-2">
                                                <Col>{complex.message}</Col>
                                                <Col className="col-2 text-right">
                                                    <Button className="btn btn-sm btn-host mb-1" onClick={() => this.props.goToMissingField('1', 'selectedProperty', { id: item.complexId }, "isPropertiesOpen", "isRatesOpen", "isRoomsOpen" )}>
                                                        <i className="fas fa-wrench"></i>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                : ''}

                                {item.roomErrors && item.roomErrors.length > 0 ?
                                    <div>
                                        <Row>
                                            <Col><h6 className="mb-1"><FormattedHTMLMessage id="BEConfigDashboard.Room" />: </h6></Col>
                                        </Row>
                                        {item.roomErrors.map((room, k2) =>
                                            <Row key={k2} className="overbglight mb-2">
                                                <Col>{room.message}</Col>
                                                <Col className="col-1 text-right">
                                                    <Button className="btn btn-sm btn-host text-right" onClick={() => this.props.goToMissingField('3', 'selectedRoom', { id: room.roomId }, "isRoomsOpen", "isRatesOpen", "isPropertiesOpen")}>
                                                        <i className="fas fa-wrench"></i>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                : ''}

                                {item.rateErrors && item.rateErrors.length > 0 ?
                                    <div>
                                        <Row>
                                            <Col><h6 className="mb-1"><FormattedHTMLMessage id="BEConfigDashboard.Rate" />: </h6></Col>
                                        </Row>
                                        {item.rateErrors.map((rate, k2) =>
                                            <Row key={k2} className="overbglight mb-2">
                                                <Col>{rate.message}</Col>
                                                <Col className="col-1 text-right">
                                                    <Button className="btn btn-sm btn-host text-right" onClick={() => this.props.goToMissingField('4', 'selectedRate', rate.rateId, "isRatesOpen", "isRoomsOpen", "isPropertiesOpen")}>
                                                        <i className="fas fa-wrench"></i>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                : ''}
                            </div>
                        )
                    : ''}
                    {
                        promotionDatesSentAzure.map((cmp, key) =>
                            <Row key={key}>
                                <Col>
                                    <FormattedMessage id="BEConfigDashboard.ModifiedDataPromotion" values={{ type: <b><FormattedMessage id="BEConfigDashboard.Promotions" /></b>, code: cmp.code, lastSendDate: cmp.dataSentAzureAt ? <FormattedMessage id="BEConfigDashboard.LastSendDate" values={{ dataSentAzureAt: moment(cmp.dataSentAzureAt).format("YYYY-MM-DD HH:mm") }} /> : '' }} />
                                </Col>
                            </Row>
                        )
                    }
                    {
                        paymentGatewayDatesSentAzure.map((cmp, key) =>
                            <Row key={key}>
                                <Col>
                                    <FormattedMessage id="BEConfigDashboard.ModifiedDataPaymentGateway" values={{ type: <b><FormattedMessage id="BEConfigDashboard.Payment" /></b>, code: cmp.code, lastSendDate: cmp.dataSentAzureAt ? <FormattedMessage id="BEConfigDashboard.LastSendDate" values={{ dataSentAzureAt: moment(cmp.dataSentAzureAt).format("YYYY-MM-DD HH:mm") }} /> : '' }} />
                                </Col>
                            </Row>
                        )
                    }
                    {
                        descriptionsDatesSentAzure.map((cmp, key) =>
                            <Row key={key}>
                                <Col>
                                    <FormattedMessage id="BEConfigDashboard.ModifiedDataPromotion" values={{ type: <b><FormattedMessage id="BEConfigDashboard.Texts" /></b>, code: cmp.code, lastSendDate: cmp.dataSentAzureAt ? <FormattedMessage id="BEConfigDashboard.LastSendDate" values={{ dataSentAzureAt: moment(cmp.dataSentAzureAt).format("YYYY-MM-DD HH:mm") }} /> : '' }} />
                                </Col>
                            </Row>
                        )
                    }
                    {
                        datesSentAzures.map((cmp, key) =>
                            <Row key={key}>
                                <Col>
                                    <FormattedMessage id="BEConfigDashboard.ModifiedData" values={{ code: cmp.code, lastSendDate: cmp.dataSentAzureAt ? <FormattedMessage id="BEConfigDashboard.LastSendDate" values={{ dataSentAzureAt: moment(cmp.dataSentAzureAt).format("YYYY-MM-DD HH:mm") }} /> : '' }} />
                                </Col>
                            </Row>
                        )
                    }
                </EmptyCard>
            </div>
        );
    }
}

export default injectIntl(MainPage)