import React, { Component } from 'react';
import { Input, Row, Col, Button, Card, CardBody, Badge } from 'reactstrap';
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';
import { StyledCard, EmptyCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import { DateRangePicker } from 'react-dates';
import { getAPI } from '../../Base/API';
import moment from "moment";

class ApiGatewayUsage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            startDate: moment().subtract(15, 'minutes').format('YYYY-MM-DDTHH:mm'),
            endDate: moment().format('YYYY-MM-DDTHH:mm'),
            usage: [],
            selectedBadge: 1,
            top: 10,
            skip: 0
        }
        this.element = React.createRef()
    }

    componentDidMount() {
        this.getUsage();
    }

    getUsage = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ usage: data.value, count: data.count });
            }
            this.setState({ block: false });
        }, `/api/User/APIGateway/v1/Subscriptions/usage?fromDate=${this.state.startDate}&toDate=${this.state.endDate}&skip=${this.state.skip}&top=${this.state.top}`)
    }

    doSearch = () => {
        this.setState({
            skip: 0
        }, () => this.getUsage());
    }


    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            skip: (page - 1) * this.state.top,
            top: sizePerPage,
        }, () => { this.getUsage() });
    }


    handleDateTime = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        });
    };

    changeBadge = (option) => {
        let startDate = moment(), endDate = moment();

        if (option.time) {
            startDate = startDate.subtract(option.time, option.type);
            endDate = option.value === 5 ? endDate.subtract(option.time, option.type) : endDate;
        }

        if (option.value === 5 || option.value === 4) {
            startDate = startDate.startOf('day');
            endDate = endDate.endOf('day');
        }

        this.setState({
            selectedBadge: option.value,
            startDate: startDate.format('YYYY-MM-DDTHH:mm'),
            endDate: endDate.format('YYYY-MM-DDTHH:mm')
        })
    }

    render() {
        const { subscriptionsUsage, block, error, selectedBadge, startDate, endDate } = this.state;

        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "ApiGatewayUsage.Name" })
            },
            {
                dataField: 'subscriptionId',
                text: this.props.intl.formatMessage({ id: "ApiGatewayUsage.Subscription" }),
                formatter: (cell) => cell ? cell.split("/").pop() : ''
            },
            {
                dataField: 'productId',
                text: this.props.intl.formatMessage({ id: "ApiGatewayUsage.Product" }),
                formatter: (cell) => cell ? cell.split("/").pop() : ''
            },
            {
                dataField: 'bandwidth', 
                text: this.props.intl.formatMessage({ id: "ApiGatewayUsage.Bandwidth" })
            },
            {
                dataField: 'callCountSuccess',
                text: this.props.intl.formatMessage({ id: "ApiGatewayUsage.CallCountSuccess" })
            },
            {
                dataField: 'callCountBlocked',
                text: this.props.intl.formatMessage({ id: "ApiGatewayUsage.CallCountBlocked" })
            },
            {
                dataField: 'callCountFailed',
                text: this.props.intl.formatMessage({ id: "ApiGatewayUsage.CallCountFailed" })
            },
            {
                dataField: 'callCountOther',
                text: this.props.intl.formatMessage({ id: "ApiGatewayUsage.CallCountOther" })
            },
            {
                dataField: 'callCountTotal',
                text: this.props.intl.formatMessage({ id: "ApiGatewayUsage.CallCountTotal" })
            },
            {
                dataField: 'apiTimeAvg',
                text: this.props.intl.formatMessage({ id: "ApiGatewayUsage.ApiTimeAvg" }),
                formatter: (cell, row) => cell ? cell.toFixed(2) : 0
            },
            {
                dataField: 'serviceTimeAvg',
                text: this.props.intl.formatMessage({ id: "ApiGatewayUsage.ServiceTimeAvg" }),
                formatter: (cell, row) => cell ? cell.toFixed(2) : 0
            }
        ];

        const options = [
            {
                value: 1, label: this.props.intl.formatMessage({ id: "ApiGatewayUsage.15Minutes" }),
                time: 15, type: 'minutes'
            },
            {
                value: 2, label: this.props.intl.formatMessage({ id: "ApiGatewayUsage.30Minutes" }),
                time: 30, type: 'minutes'
            },
            {
                value: 3, label: this.props.intl.formatMessage({ id: "ApiGatewayUsage.1Hour" }),
                time: 1, type: 'hour'
            },
            {
                value: 4, label: this.props.intl.formatMessage({ id: "ApiGatewayUsage.Today" })
            },
            {
                value: 5, label: this.props.intl.formatMessage({ id: "ApiGatewayUsage.Yesterday" }),
                time: 1, type: 'days'
            },
            {
                value: 6, label: this.props.intl.formatMessage({ id: "ApiGatewayUsage.Last7Days" }),
                time: 7, type: 'days'
            },
        ];

        return (
            <StyledCard block={block} error={error} icon="fas fa-file-signature" title="navbar.ApiGatewayUsage">
                <Row className="align-items-center mb-3">
                    <Col className="col-5 pr-1">
                        {options.map((option, key) =>
                            <Badge className="mr-2 py-2 pointer" key={key} color={selectedBadge === option.value ? 'primary' : 'secondary'} onClick={() => this.changeBadge(option)}>
                                {option.label}
                            </Badge>
                        )}
                    </Col>
                    <Col>
                        <FormattedMessage id="ApiGatewayUsage.StartDate" />
                    </Col>
                    <Col>
                        <Input
                            type="datetime-local"
                            name="startDate"
                            value={startDate}
                            max={moment().format('YYYY-MM-DDTHH:mm')}
                            onChange={(e) => this.handleDateTime(e)}
                        />
                    </Col>
                    <Col>
                        <FormattedMessage id="ApiGatewayUsage.EndDate" />
                    </Col>
                    <Col>
                        <Input
                            type="datetime-local"
                            name="endDate"
                            value={endDate}
                            max={moment().format('YYYY-MM-DDTHH:mm')}
                            min={startDate}
                            onChange={(e) => this.handleDateTime(e)}
                            disabled={!startDate}
                        />
                    </Col>

                    <Col className="text-right pl-1">
                        <Button className="btn btn-sm btn-host" onClick={this.doSearch}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>


                <Row >
                    <Col>
                        <h5> {subscriptionsUsage ? subscriptionsUsage.name : ''} </h5>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomTable
                            data={this.state.usage ? this.state.usage : []}
                            columns={columns}
                            showTotal={true}
                            remote={true}
                            sizePerPage={this.state.usageTop}
                            onTableChange={this.handleTableChange}
                            totalSize={this.state.count}
                            keyField='subscriptionId'
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}
export default injectIntl(ApiGatewayUsage)