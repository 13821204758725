import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Label, Input, Button, Form, FormFeedback } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../Base/Notification";
import { ErrorAlert } from "../Common/ErrorAlert";
import { postAPI } from "../Base/API";
import BlockUi from 'react-block-ui';

class ChangePassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			block: false,
			request: {
				newUser: null,
				newPassword: null
			},
			invalidPassword: null,
			isSamePassword: null
		};
	}

	componentDidMount() {
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({
			request: {
				...this.state.request,
				[name]: value
			}
		})
	}

	changePassword = (e) => {
		e.preventDefault();
		this.setState({ block: true });

		var body = { ...this.state.request }

		postAPI(result => {
			const { data, error } = result;
			var errorMessage = [];

			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0 || data.warnings && data.warnings.length > 0) {
					handleNotification(data);
					this.setState(({ block: false, error: errorMessage }));
					return;
				}
				if (data.status && data.status === 'Success') {
					handleNotification(data, <FormattedMessage id="ChangePassword.PasswordChanged" />, <FormattedMessage id="General.Success" />);
					this.props.toggleModal();
				}
				this.setState(({ invalidPassword: false, block: false }));
			}
		}, `/api/Rate/ChannelConfig/v1/ChannelInstance/${this.props.channelInstanceId}/ChangePassword`, JSON.stringify(body));		
	}

	validatePassword = (event) => {
		event.preventDefault();
		var notValidChar = [':', '/', '?', '#', '[', ']', '@', '&', "'", '|', ';', '%', '=', '"', '<', '>', '&'];
		const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\S]{12,}$/i;

		if (this.state.request.newPassword && (notValidChar.some(c => this.state.request.newPassword.includes(c)) || !regex.test(this.state.request.newPassword))) {
			this.setState({ invalidPassword: true, block: false });
			return;
		}
		else {
			this.setState({ invalidPassword: false, block: false });
		}

		this.confirmPassword();
	}

	confirmPassword = (event) => {
		if (event) event.preventDefault();

		let isSame = false;

		if (this.state.request.newPassword === this.state.confirmPassword) {
			isSame = true;
		}

		this.setState({ isSamePassword: isSame });
	}
	
	handleSwitch = (evt) => {
		const { name, checked } = evt.target;

		this.setState({ [name]: checked });
	}

	render() {
		return (
			<Modal isOpen={this.props.modal} toggle={this.props.toggleModal} className="modal-lg" style={{ minWidth: 0 }}>
				<ModalHeader toggle={this.props.toggleModal}>
					<FormattedMessage id="ChangePassword.Title" />
				</ModalHeader>

				<ModalBody>
					<BlockUi tag="div" blocking={this.state.block}>
						<ErrorAlert error={this.state.error} />
						<Form onSubmit={this.changePassword}>	
							<Row>
								<Col className="text-right mb-3">
									<Button className="btn btn-host btn-sm mr-1" type="submit" disabled={this.state.invalidPassword === true || this.state.isSamePassword !== true}>
										<span className="fas fa-save" />
									</Button>
								</Col>
							</Row>

							<FormGroup row>
								<Label sm={4}><FormattedMessage id="ChangePassword.NewUser" /></Label>
								<Col sm={8}>
									<Input
										type="text"
										name="newUser"
										value={this.state.request.newUser ? this.state.request.newUser : ''}
										onChange={e => this.handleChange(e)}
										required
									/>
								</Col>
							</FormGroup>

							<FormGroup row>
								<Label sm={4}><FormattedMessage id="ChangePassword.NewPassword" /></Label>
								<Col sm={8}>
									<Input
										type="password"
										name="newPassword"
										id="newPassword"
										value={this.state.request.newPassword ? this.state.request.newPassword : ''}
										onChange={e => this.handleChange(e)}
										required
										invalid={this.state.invalidPassword ? true : false}
										onBlur={(e) => this.validatePassword(e)}
									/>
									<FormFeedback><FormattedMessage id="ChangePassword.InvalidPassword" values={{ differentThan: `:/?#[]@&'|;%="<>'&` }} /></FormFeedback>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label sm={4}><FormattedMessage id="ChangePassword.ConfirmPassword" /></Label>
								<Col sm={8}>
									<Input
										type="password"
										name="confirmPassword"
										value={this.state.confirmPassword ? this.state.confirmPassword : ''}
										onChange={e => this.setState({ confirmPassword: e.target.value })}
										required
										invalid={this.state.isSamePassword === false ? true : false}
										onBlur={(e) => this.confirmPassword(e)}
									/>
									<FormFeedback><FormattedMessage id="ChangePassword.PasswordsDoNotMatch" /></FormFeedback>
								</Col>
							</FormGroup>
						</Form>
					</BlockUi>
				</ModalBody>
			</Modal>
		);
	}
}

export default injectIntl(ChangePassword);