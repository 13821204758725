import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

export class PromotionSummaryCard extends Component {

    

    render() {
        const { channelInstances, item, subtitle, statusBadge, children } = this.props;

        const validWeekDays = [
            {
                value: "Mon",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysMon" />
            },
            {
                value: "Tue",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysTue" />
            },
            {
                value: "Wed",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysWed" />
            },
            {
                value: "Thu",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysThu" />
            },
            {
                value: "Fri",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysFri" />
            },
            {
                value: "Sat",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSat" />
            },
            {
                value: "Sun",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSun" />
            }
        ];

        function getChannelLabel(item) {
            const channel = channelInstances.find(c => c.id === item.channelInstanceId);
            return channel ? ` ${channel.name} (${channel.propertyCode})` : '';
        }

        const isTypeBasic = item.type === 'basic' || item.type === 'last_minute' || item.type === 'early_booker';
        const isTypeTargetChannel = item.type === 'geo_rate' || item.type === 'mobile_rate';
        let promotionType = this.props.promotionType.filter(val => val.code === item.type);
        if (promotionType.length === 0) {
            promotionType = this.props.invalidPromotionType.filter(val => val.code === item.type);
        }

        return (
            <Card className="small mb-2 shadow border-top-0 border-right-0 border-left-0">
                <CardHeader className="bg-white">
                    <Row className="pxy-0">
                        <Col className="col-lg-2 col-4  text-truncate">
                            <i className={'text-secondary fas fa-1x mr-2 ' + (promotionType[0] ? promotionType[0].icon : '')} />
                            <strong>{promotionType[0] ? promotionType[0].name : ''}</strong>
                        </Col>
                        <Col className={`col-lg-8 col-4 ${global.isMobile ? 'text-truncate' : ''}`}>
                            {item.name == '-1' ? '' : item.name}
                            {!global.isMobile ? 
                                <span className={`float-right ${global.isMobile ? 'd-inline-block text-truncate' : ''}`} style={{maxWidth: global.isMobile ? '100%' : ''}}>
                                    <strong>
                                        {
                                            subtitle
                                        }
                                    </strong>
                                </span>
                            :''}
                            
                        </Col>
                        <Col className="col-lg-2 col-4 text-right pl-1">
                            {
                                children
                            }
                        </Col>
                    </Row>
                    {global.isMobile ? 
                        <Row className="pxy-0">
                            <Col className='col-12'>
                                    <span className='d-flex justify-content-center'>
                                        <strong>
                                            {
                                                subtitle
                                            }
                                        </strong>
                                    </span>
                                
                                
                            </Col>
                        </Row>
                    :''}
                </CardHeader>
                <CardBody>
                    {!global.isMobile ?
                        <Row>
                            <Col className="col-2">
                                <h6>
                                    <FormattedMessage id="bookingPromotion.discount" />: {item.discount?.value}
                                </h6>
                                <span>
                                    <FormattedMessage id="PromotionList.Created" />
                                    {moment(item.createdAt).format('L')}
                                </span>
                                <br />
                                <span>
                                    <FormattedMessage id="PromotionList.Updated" />
                                    {moment(item.updatedAt).format('L')}
                                </span>
                                <br />
                                <span>
                                    <FormattedMessage id="PromotionList.Channel" />:
                                    {getChannelLabel(item)}
                                </span>
                                <br />
                                <h6>
                                    {
                                        statusBadge
                                    }
                                </h6>
                            </Col>
                            <Col className="col-4">
                                <div>
                                    <strong>
                                        <FormattedMessage id="PromotionList.BookableFrom" />
                                    </strong>
                                    {item.stay_date && item.stay_date.start ? moment(item.stay_date.start).format('L') : ''}
                                    -
                                    {item.stay_date && item.stay_date.end ? moment(item.stay_date.end).format('L') : ''}
                                </div>
                                {
                                    isTypeBasic && item.min_stay_through != '-1' ?
                                        <div>
                                            <span>
                                                <strong>
                                                    <FormattedMessage id="AirbnbAvailabilitySettings.defaultminnights" />:
                                                </strong>
                                                <FormattedMessage id="PromotionList.ForGuestsStayingAtLeast" /> {item.min_stay_through} <FormattedMessage id="PromotionList.Nights" />
                                            </span>
                                            <br />
                                        </div>
                                        :
                                        <div />
                                }
                                {
                                    item.book_time && item.book_time.start && item.book_time.end ?
                                        <div>
                                            <span>
                                                <strong>
                                                    <FormattedMessage id="PromotionList.PromotionHours" />
                                                </strong>
                                                <FormattedMessage id="PromotionList.PromotionVisible" />
                                                {item.book_time.start} - {item.book_time.end}
                                                <FormattedMessage id="PromotionList.PromotionVisibleBasedOnYourLocalTime" />
                                            </span>
                                            <br />
                                        </div>
                                        : ''
                                }
                                {
                                    item.early_booker_days ?
                                        <div>
                                            <strong>
                                                <FormattedMessage id="PromotionList.Bookable" />
                                                {item.early_booker_days}
                                                <FormattedMessage id="PromotionList.Days" />
                                            </strong>
                                            <FormattedMessage id="PromotionList.OrMoreInAdvance" />
                                        </div>
                                        : ''
                                }
                                {
                                    item.no_cc_promotion ?
                                        <strong>
                                            <FormattedMessage id="PromotionList.NoCreditCardRequired" />
                                        </strong>
                                        : ''
                                }
                                {
                                    item.non_refundable ?
                                        <strong>
                                            <FormattedMessage id="BookingPromotionCard.IsNonRefundable" />
                                        </strong>
                                        : ''
                                }
                                {
                                    isTypeTargetChannel && item.target_channel ?
                                        <div>
                                            <strong>
                                                <FormattedMessage id="PromotionList.TargetChannel" />
                                            </strong>
                                            {item.target_channel}
                                        </div>
                                        :
                                        <div />
                                }
                            </Col>

                            <Col className="col-4">
                                <strong>
                                    <FormattedMessage id="PromotionList.OnWeekDays" />
                                </strong>
                                {
                                    isTypeBasic && item.stay_date && item.stay_date.active_weekdays ?
                                        item.stay_date.active_weekdays.active_weekday?.map((wd, wdkey) => <span key={wdkey}> {validWeekDays?.find(validWeekDay => validWeekDay.value == wd).label}</span>)
                                        :
                                        validWeekDays?.map((wd, wdkey) => <span key={wdkey}> {wd.label} </span>)
                                }
                                {
                                    item.stay_date && item.stay_date.excluded_dates ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    <strong>
                                                        <FormattedMessage id="PromotionList.ExcludeTheseDates" />:
                                                    </strong>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {item.stay_date && item.stay_date.excluded_dates && item.stay_date.excluded_dates.start && item.stay_date.excluded_dates.end ?
                                                        <span>
                                                            <FormattedMessage id="PromotionList.From" />{moment(item.stay_date.excluded_dates.start).format('L')} <FormattedMessage id="PromotionList.To" /> {moment(item.stay_date.excluded_dates.end).format('L')}
                                                            <hr />
                                                        </span>
                                                        : ''}
                                                </Col>
                                            </Row>
                                            <Row className="scrollableDiv" style={{ maxHeight: '100px', overflow: 'auto' }}>
                                                {item.stay_date && item.stay_date.excluded_dates && item.stay_date.excluded_dates.excluded_date && item.stay_date.excluded_dates.excluded_date.map((esd, esdkey) =>
                                                    <Col className="col-4" key={esdkey}>
                                                        <li>{moment(esd).format('L')}</li>
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                        :
                                        <div />
                                }
                            </Col>
                            <Col className="col-2">
                                {
                                    item.channels && item.channels.channel.map((itemChannel, keyChannel) =>
                                        <div key={keyChannel}> {itemChannel.channelCode} ({itemChannel.propertyCode}) -<strong> {itemChannel.channelPromotionCode}  </strong> </div>
                                    )
                                }
                            </Col>
                        </Row>
                    :
                        <>
                            <Row className='mb-1'>
                                <Col>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h6 className='m-0'><FormattedMessage id="bookingPromotion.discount" />: {item.discount ? item.discount.value : ''}</h6>
                                        <h6 className='m-0'>{statusBadge}</h6>
                                    </div>
                                    <span>
                                        <FormattedMessage id="PromotionList.Created" />
                                        {moment(item.createdAt).format('L')}
                                    </span>
                                    <br />
                                    <span>
                                        <FormattedMessage id="PromotionList.Updated" />
                                        {moment(item.updatedAt).format('L')}
                                    </span>
                                    <br />
                                    <span>
                                        <FormattedMessage id="PromotionList.Channel" />:
                                        {getChannelLabel(item)}
                                    </span>
                                    <br />
                                </Col>
                            </Row>
                            <Row className='mb-1'>
                                <Col>
                                    <div>
                                        <strong className='mr-2'>
                                            <FormattedMessage id="PromotionList.BookableFrom" />
                                        </strong>
                                        {item.stay_date && item.stay_date.start ? moment(item.stay_date.start).format('L') : ''}
                                        -
                                        {item.stay_date && item.stay_date.end ? moment(item.stay_date.end).format('L') : ''}
                                    </div>
                                    {
                                        isTypeBasic && item.min_stay_through != '-1' ?
                                            <div>
                                                <span>
                                                    <strong className='mr-2'>
                                                        <FormattedMessage id="AirbnbAvailabilitySettings.defaultminnights" />:
                                                    </strong>
                                                    <FormattedMessage id="PromotionList.ForGuestsStayingAtLeast" /> {item.min_stay_through} <FormattedMessage id="PromotionList.Nights" />
                                                </span>
                                                <br />
                                            </div>
                                            :
                                            <div />
                                    }
                                    {
                                        item.book_time && item.book_time.start && item.book_time.end?
                                            <div>
                                                <span>
                                                    <strong>
                                                        <FormattedMessage id="PromotionList.PromotionHours" />
                                                    </strong>
                                                    <FormattedMessage id="PromotionList.PromotionVisible" />
                                                    {item.book_time.start} - {item.book_time.end}
                                                    <FormattedMessage id="PromotionList.PromotionVisibleBasedOnYourLocalTime" />
                                                </span>
                                                <br />
                                            </div>
                                            : ''
                                    }
                                    {
                                        item.early_booker_days ?
                                            <div>
                                                <strong>
                                                    <FormattedMessage id="PromotionList.Bookable" />
                                                    {item.early_booker_days}
                                                    <FormattedMessage id="PromotionList.Days" />
                                                </strong>
                                                <FormattedMessage id="PromotionList.OrMoreInAdvance" />
                                            </div>
                                            : ''
                                    }
                                    {
                                        item.no_cc_promotion ?
                                            <strong>
                                                <FormattedMessage id="PromotionList.NoCreditCardRequired" />
                                            </strong>
                                            : ''
                                    }
                                    {
                                        item.non_refundable ?
                                            <strong>
                                                <FormattedMessage id="BookingPromotionCard.IsNonRefundable" />
                                            </strong>
                                            : ''
                                    }
                                    {
                                        isTypeTargetChannel && item.target_channel ?
                                            <div>
                                                <strong>
                                                    <FormattedMessage id="PromotionList.TargetChannel" />
                                                </strong>
                                                {item.target_channel}
                                            </div>
                                            :
                                            <div />
                                    }
                                </Col>
                            </Row>
                            <Row className='mb-1'>
                                <Col>
                                    <strong>
                                        <FormattedMessage id="PromotionList.OnWeekDays" />
                                    </strong>
                                    {
                                        isTypeBasic && item.stay_date && item.stay_date.active_weekdays ?
                                            item.stay_date.active_weekdays.active_weekday?.map((wd, wdkey) => <span key={wdkey}> {validWeekDays?.find(validWeekDay => validWeekDay.value == wd).label}</span>)
                                            :
                                            validWeekDays?.map((wd, wdkey) => <span key={wdkey}> {wd.label} </span>)
                                    }
                                    {
                                        item.stay_date && item.stay_date.excluded_dates ?
                                            <div>
                                                {item.stay_date && item.stay_date.excluded_dates && item.stay_date.excluded_dates.excluded_date ? 
                                                    <Row>
                                                        <Col>
                                                            
                                                            <strong>
                                                                <FormattedMessage id="PromotionList.ExcludeTheseDates" />:
                                                            </strong>
                                                            
                                                        </Col>
                                                    </Row>
                                                : ''}
                                                <Row>
                                                    <Col>
                                                        {item.stay_date.excluded_dates.start && item.stay_date.excluded_dates.end ?
                                                            <span>
                                                                <FormattedMessage id="PromotionList.From" />{moment(item.stay_date.excluded_dates.start).format('L')} <FormattedMessage id="PromotionList.To" /> {moment(item.stay_date.excluded_dates.end).format('L')}
                                                                <hr />
                                                            </span>
                                                            : ''}
                                                    </Col>
                                                </Row>
                                                <Row className="scrollableDiv" style={{ maxHeight: '100px', overflow: 'auto' }}>
                                                    {item.stay_date && item.stay_date.excluded_dates && item.stay_date.excluded_dates.excluded_date ? item.stay_date.excluded_dates.excluded_date.map((esd, esdkey) =>
                                                        <Col className="col-4" key={esdkey}>
                                                            <li>{moment(esd).format('L')}</li>
                                                        </Col>
                                                    ) : '-'}
                                                </Row>
                                            </div>
                                            :
                                            <div />
                                    }
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        item.channels && item.channels.channel && item.channels.channel.map((itemChannel, keyChannel) =>
                                            <div key={keyChannel}> {itemChannel.channelCode} ({itemChannel.propertyCode}) -<strong> {itemChannel.channelPromotionCode}  </strong> </div>
                                        )
                                    }
                                </Col>
                            </Row>
                        </>
                    }
                </CardBody>
            </Card>
        );
    }
}