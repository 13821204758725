import React, { Component } from 'react';
import { Col, Row} from 'reactstrap';
import moment from 'moment';

export class EditableRateAvailabilityBox extends Component {

    constructor(props) {
        super(props);
        this.state = {
            priceVector: ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7', 'p8', 'p9', 'p10', 'p11'],
            price: null,
            changedData: false,
            value: null,
            original: null,
            isFocused: false,
            validKeys: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
        };
    }

    componentWillReceiveProps(newProps) {
        const { newPrice, availability } = this.props;

        if(newProps.newPrice !== newPrice && !newProps.wasSaved) {
            let value = isNaN(newProps.newPrice) ? this.state.original ? parseFloat(this.state.original).toFixed(2) : null : parseFloat(newProps.newPrice).toFixed(2);

            this.setState({ value, price: value, changedData: parseFloat(value) !== this.state.original });
        }
        else if(newProps.availability !== availability) {
            this.getPrice(newProps.availability, newProps.wasSaved);
        }
    }

    getPrice = (availability, wasSaved) => {
        const { day, children, isChildren, isEditable, newPrice, isBaseOccupancy, isDerived, isD3 } = this.props;
        const { priceVector } = this.state;
        let changedData = false;
        
        if(!availability || availability.length === 0) return;

        const position = this.getPosition();

        const priceSelected = availability.find(({hotelDate}) => day.isSame(hotelDate, 'date'));

        let price = null;

        if (priceSelected) {
            if (position != null) {
                price = priceSelected[priceVector[position]];
                if (children) {
                    price += children * (priceSelected.d2 + priceSelected.componentChildrenGroup2);
                };
            }
            else if(isChildren){
                price = isD3 ? priceSelected.d3 : priceSelected.d2;
            }
            else {
                for (var i = 0; i < priceVector.length; i++) {
                    price = priceSelected[priceVector[i]] !== 0 ? priceSelected[priceVector[i]] : price;
                };
            };
            
            if (!isEditable && priceSelected.isPriceModeComponent && price) {
                price += ((position + 1) * priceSelected.componentPrice);
            };

            if (price || price === 0) {
                price = parseFloat(price).toFixed(2).toString();
            };
        }

        if(isDerived && !isBaseOccupancy && newPrice && newPrice !== price && !wasSaved) {
            price = isNaN(newPrice) ? null : newPrice;
            changedData = true;
        }
        
        const value = !price ? price : parseFloat(price).toFixed(2).toString();
        const original = !price ? price : parseFloat(price);

        this.setState({ price, value, original, changedData });
    }

    componentDidMount() {
        const { availability } = this.props;
        this.getPrice(availability);
    }

    getPosition() {
        const { position, isChildren } = this.props;

        if(isChildren) return;

        const adults = position - 1;
        return adults <= 10 ? adults : 10;
    }

    handleChange = (e, applyChanges) => {
        const { original, value } = this.state;
        let newValue = this.removeCommas(e.target.value);

        if(this.numbersonly(newValue)){
            const parsedValue = parseFloat(newValue);
            const changedData = (parsedValue < original || parsedValue > original) || (parsedValue < value || parsedValue > value);

            this.setState({ changedData, value: e.target.value }, () => {
                if(applyChanges && changedData) this.saveChanges();
            });
        }
    }

    numbersonly(value){
        if((value && isNaN(value) && value !== '-') || (!isNaN(value) && parseFloat(value) < 0)) return false;
        return true;
    }

    removeCommas = (value) => {
        if(value && value.toString().indexOf(',') > -1){
            return value.toString().substring(0, value.toString().indexOf(',')) + "." + value.toString().substring(value.toString().indexOf(',') + 1);
        }
        return value;
    }

    saveChanges = () => {
        const { day, rateCode, position, isChildren, handleDataChange, isD3 } = this.props;
        const { value } = this.state;
        
        const obj = {
            target: {
                value: value,
                name: isChildren ? 'childPrice' : 'adultPrices'
            }
        }; 

        handleDataChange(obj, day, rateCode.id, position, isD3);
    }

    keyDown = (e) => {
        const { rowkey, colkey, rateCode } = this.props;
        const { isFocused, changedData, validKeys } = this.state;
        let selectedCol = colkey, selectedRow = rowkey;
        const parsedValue = parseFloat(this.removeCommas(this.state.value)??0);
        
        if(!e?.key) return;

        if(!isFocused && !e.key?.includes("Arrow") && e.key !== "Tab" && e.key !== "Enter"){
            if(validKeys.includes(e.key)){
                const target = {
                    target: {
                        value: e.key
                    }
                };

                e.preventDefault();
                this.setState({ isFocused: true }, () => this.handleChange(target))
            };
            return;
        }
        else{
            if(e.key === "Enter") {
                if(e.shiftKey) selectedRow = selectedRow - 1;
                else if(isFocused) {
                    if(changedData) this.saveChanges();
                    selectedRow = selectedRow + 1;
                }
                else {
                    this.setState({ isFocused: true });
                    return;
                };
            }
            else if(e.key === "ArrowUp") {
                if(!isFocused) selectedRow = selectedRow - 1;
                else {
                    const target = {
                        target: {
                            value: parsedValue + 1
                        }
                    };
    
                    e.preventDefault();
                    this.setState({ isFocused: true }, () => this.handleChange(target))
                };
            }
            else if(e.key === "ArrowDown") {
                if(!isFocused) selectedRow = selectedRow + 1;
                else {
                    const target = {
                        target: {
                            value: parsedValue - 1
                        }
                    };
    
                    e.preventDefault();
                    this.setState({ isFocused: true }, () => this.handleChange(target))
                };
            }
            else if(e.key === "ArrowRight") {
                if(!isFocused) selectedCol = selectedCol + 1;
                else return;
            }
            else if(e.key === "ArrowLeft") {
                if(!isFocused) selectedCol = selectedCol - 1;
                else return;
            }
            else if(e.key === "Tab") {
                if(isFocused && changedData) this.saveChanges();
                if(e.shiftKey) selectedCol = selectedCol - 1;
                else selectedCol = selectedCol + 1;
            }
            else if(e.key === "Escape"){
                if(isFocused && changedData) this.resetData();
            }
            else return;

            e.preventDefault();
    
            const element = document.getElementById(`${rateCode.id}&Price-${selectedRow}.${selectedCol}`);
            if(element){
                element.focus();
            }
        }
    }

    resetData = () => {
        const { original } = this.state;
        this.setState({ changedData: false, value: parseFloat(original)?.toFixed(2), isFocused: false });
    }

    dragOver = (e) => {
        const { dragObj, draggable, rowkey } = this.props;
        if(!draggable) return;
        if(!dragObj) return;
        if(dragObj.rowkey !== rowkey) return;
        if(!dragObj.isPrice) return;
        e.preventDefault();
    }
    
    dragEnd = (e) => {
        const { dragObj, draggable, rowkey, dragStart } = this.props;
        const { value } = this.state

        if(!draggable) return;
        if(!dragObj) return;
        if(dragObj.rowkey !== rowkey) return;
        if(!dragObj.isPrice) return;

        this.handleChange({target:{value: parseFloat(value).toFixed(2)}}, true);
        dragStart();
    }
    
    dragEnter = () => {
        const { dragObj, draggable, rowkey } = this.props;
        if(!draggable) return;
        if(!dragObj) return;
        if(dragObj.rowkey !== rowkey) return;
        if(!dragObj.isPrice) return;

        this.handleChange({target:{value: parseFloat(dragObj.value).toFixed(2)}}, true);
    }

    setIsFocused = () => {
        this.setState({ isFocused: true });
    }
    
    blurEl = (e) => {
        const { newPrice } = this.props;
        const { changedData, original } = this.state;
        let value = parseFloat(this.removeCommas(this.state.value)).toFixed(2).toString();
        let defaultValue = false;
        if(isNaN(value)){
            value = '0.00';
            defaultValue = true;
        }

        this.setState({ isFocused: false, isSelected: false, value }, () => {
            if(defaultValue || (changedData && value !== original && value !== newPrice)) this.saveChanges();
        });
    }

    setSelected = () => {
        const { changeRow, changeCol, rowkey, colkey, changeSelectedRate, rateCode } = this.props;
        this.setState({ isSelected: true }, () => {
            changeRow(rowkey);
            changeCol(colkey);
            changeSelectedRate(rateCode.id);
        });
    }
    
    render() {
        const { isDependent, isDerived, isEditable, isBaseOccupancy, rowkey, colkey, isInvalid, dragStart, draggable, dragObj, isLastRow, rateCode } = this.props;
        const { price, changedData, value, isFocused, isSelected } = this.state;
        
        const getBorderClass = () => {
            let borderClass = "defaultBorder ";

            if(isSelected && !global.isMobile){
                borderClass += "inventorySelectedCollum inventorySelectedRow "
            }
            if(isInvalid){
                borderClass += "isInvalid "
            }
            else if(changedData){
                borderClass += "isValid "
            }
            if(rowkey === 0 || (rowkey === 1 && isDerived)) borderClass += "row0 ";
            if(colkey === 0) borderClass += "collum0 "
            if(isLastRow) borderClass += "lastRow"

            return borderClass;
        }

        return (
            <Col className={`position-top ${getBorderClass()}`} style={{ minWidth: '60px', 'cursor': !isDependent ? 'pointer' : ''}}>
                <div className="text-center font-weight-bold text-uppercase h-100">
                    <Row className="h-100">
                        <Col className="col-12 p-0">
                            <div className="w-100 h-100" style={{
                                backgroundColor: changedData ? isInvalid ? '#ffbebe' : '#ddeeff' : '',
                                color: isDerived && !isBaseOccupancy && changedData ?  '#bcbcbc' : isDerived && !isBaseOccupancy ? '#878787' : ''
                            }}>
                                {price === null || !isEditable || isDependent || (isDerived && !isBaseOccupancy) ?
                                    <input
                                        value={price && typeof price === "string" ? price.replace(',', '.') : '-'}
                                        readOnly={true}
                                        className='mb-0 py-2'
                                        style={{
                                            backgroundColor: changedData ? isInvalid ? '#ffbebe' : '#ddeeff' : '',
                                            width: '100%',
                                            border: '0',
                                            height: '100%',
                                            fontWeight: '500',
                                            lineHeight: '1.2',
                                            fontSize: '1rem',
                                            textAlign: 'center',
                                            outline: 'unset',
                                            color: 'inherit'
                                        }}/>
                                :
                                    <input
                                        onTouchStart={_ => {if(global.isMobile) this.setIsFocused(); this.setSelected()}}
                                        type={"text"}
                                        id={`${rateCode.id}&Price-${rowkey}.${colkey}`}
                                        className={`hiddenNumberArrows py-2 cell ${isSelected ? 'isOpen' : ''}`}
                                        defaultValue={price !== null ? price : '-'}
                                        value={value !== null ? value : '-'}
                                        onDoubleClick={this.setIsFocused}
                                        min={"0"}
                                        onFocus={_ => {if(global.isMobile) this.setIsFocused(); this.setSelected()}}
                                        onBlur={this.blurEl}
                                        readOnly={!isFocused || (dragObj && dragObj.isPrice && ((dragObj?.rowkey || dragObj?.rowkey === 0) && dragObj.rowkey !== rowkey))}
                                        onChange={this.handleChange}
                                        onKeyDown={this.keyDown}
                                        onDragEnter={this.dragEnter}
                                        inputMode="decimal"
                                        onDragStart={e => draggable && dragStart(e, value, rowkey, colkey, false, true)}
                                        onDragOver={this.dragOver}
                                        onDragEnd={this.dragEnd}
                                        style={{
                                            backgroundColor: changedData ? isInvalid ? '#ffbebe' : '#ddeeff' : '',
                                            width: '100%',
                                            border: '0',
                                            height: '100%',
                                            fontWeight: '500',
                                            lineHeight: '1.2',
                                            fontSize: '1rem',
                                            textAlign: 'center',
                                            outline: 'unset',
                                            color: 'inherit'
                                        }}
                                    />
                                }
                            </div>
                        </Col>
                    </Row>
                </div>
            </Col>
        );
    }
};