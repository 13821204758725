import React, { Component } from 'react';
import 'react-dates/initialize';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Card, CardBody, Col, Progress, Row } from 'reactstrap';



export class BookingGlobalReviewCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
           
        };

    }
    
    componentDidMount() {
    }
    render() {
        return (
            <div> {
                this.props.reviews.data && this.props.reviews.data.scores && this.props.reviews.data.review_score ?
                                <Row>
                                    <Col className="col-12">
                                        <Card className="border-left-primary shadow h-100 py-2">
                                            <CardBody>
                                                <Row>
                                                    <Col className="Col-6">

                                                        <Row>
                                                            <Col className="col-3"><FormattedMessage id="BookingReviewCard.ReviewScore" /></Col>
                                                            <Col className="col-7">
                                                                <Progress color={(this.props.reviews.data.scores.review_score.score) >= 7.5 ? "success" : ((this.props.reviews.data.scores.review_score.score) > 5 && (this.props.reviews.data.scores.review_score.score) < 7.5) ? "warning" : "danger"} striped value={this.props.reviews.data.scores.review_score.score * 10} />

                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.review_score.score} maximumFractionDigits={2} />
                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.review_score.review_count} maximumFractionDigits={2} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="col-3"> <FormattedMessage id="BookingReviewCard.Clean" /></Col>
                                                            <Col className="col-7">
                                                                <Progress striped color={(this.props.reviews.data.scores.clean.score) >= 7.5 ? "success" : ((this.props.reviews.data.scores.clean.score) > 5 && (this.props.reviews.data.scores.clean.score) < 7.5) ? "warning" : "danger"} value={this.props.reviews.data.scores.clean.score * 10} />
                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.clean.score} maximumFractionDigits={2} />
                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.clean.review_count} maximumFractionDigits={2} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="col-3"> <FormattedMessage id="BookingReviewCard.Location" /></Col>
                                                            <Col className="col-7">
                                                                <Progress striped color={(this.props.reviews.data.scores.location.score) >= 7.5 ? "success" : ((this.props.reviews.data.scores.location.score) > 5 && (this.props.reviews.data.scores.location.score) < 7.5) ? "warning" : "danger"} value={this.props.reviews.data.scores.location.score * 10} />
                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.location.score} maximumFractionDigits={2} />
                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.location.review_count} maximumFractionDigits={2} />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="Col-6">

                                                        <Row>
                                                            <Col className="col-3"> <FormattedMessage id="BookingReviewCard.Comfort" /></Col>
                                                            <Col className="col-7">
                                                                <Progress striped color={(this.props.reviews.data.scores.comfort.score) >= 7.5 ? "success" : ((this.props.reviews.data.scores.comfort.score) > 5 && (this.props.reviews.data.scores.comfort.score) < 7.5) ? "warning" : "danger"} value={this.props.reviews.data.scores.comfort.score * 10} />
                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.comfort.score} maximumFractionDigits={2} />
                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.comfort.review_count} maximumFractionDigits={2} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="col-3"> <FormattedMessage id="BookingReviewCard.Staff" /></Col>
                                                            <Col className="col-7">
                                                                <Progress striped color={(this.props.reviews.data.scores.staff.score) >= 7.5 ? "success" : ((this.props.reviews.data.scores.staff.score) > 5 && (this.props.reviews.data.scores.staff.score) < 7.5) ? "warning" : "danger"} value={this.props.reviews.data.scores.staff.score * 10} />
                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.staff.score} maximumFractionDigits={2} />
                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.staff.review_count} maximumFractionDigits={2} />
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col className="col-3"> <FormattedMessage id="BookingReviewCard.Value" /></Col>
                                                            <Col className="col-7">
                                                                <Progress striped color={(this.props.reviews.data.scores.value.score) >= 7.5 ? "success" : ((this.props.reviews.data.scores.value.score) > 5 && (this.props.reviews.data.scores.value.score) < 7.5) ? "warning" : "danger"} value={this.props.reviews.data.scores.value.score * 10} />
                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.value.score} maximumFractionDigits={2} />
                                                            </Col>
                                                            <Col className="col-1">
                                                                <FormattedNumber value={this.props.reviews.data.scores.value.review_count} maximumFractionDigits={2} />
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                : ''
                        }
            </div>
        );
    }
}