import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';


const supportedBrowsers = {
    Chrome: 80,
    Firefox: 75,
    Edge: 80,
    Safari: 14,
    Opera: 66,
    'Internet Explorer': 11,
};


export function isBrowserVersionSupported() {
    let isSupported = true;

    const userAgent = navigator.userAgent;

    if (userAgent.includes('Chrome')) {
        const browserInfo = userAgent.match(/Chrome\/(\d+)/);
        const majorVersion = parseInt(browserInfo[1]);

        isSupported = majorVersion >= supportedBrowsers.Chrome;

    } else if (userAgent.includes('Firefox')) {
        const browserInfo = userAgent.match(/Firefox\/(\d+)/);
        const majorVersion = parseInt(browserInfo[1]);

        isSupported = majorVersion >= supportedBrowsers.Firefox;

    } else if (userAgent.includes('Edge')) {
        const browserInfo = userAgent.match(/Edg\/(\d+)/);
        const majorVersion = parseInt(browserInfo[1]);

        isSupported = majorVersion >= supportedBrowsers.Edge;

    } else if (userAgent.includes('Safari')) {
        const browserInfo = userAgent.match(/Version\/(\d+)/);
        const majorVersion = parseInt(browserInfo[1]);

        isSupported = majorVersion >= supportedBrowsers.Safari;

    } else if (userAgent.includes('OPR')) {
        const browserInfo = userAgent.match(/OPR\/(\d+)/);
        const majorVersion = parseInt(browserInfo[1]);

        isSupported = majorVersion >= supportedBrowsers.Opera;
    }

    return isSupported;
}



export const BrowserVersionNotSupported = ({  }) => {
    return (
        <div className='h-100 overflow-hidden'>
            <div className='d-flex align-items-center justify-content-center flex-column h-100'>
                <div style={{
                        fontSize: '70px',
                        color: '#0667ff',
                        padding: '2vh 8vh',
                        borderStyle: 'solid',
                        borderColor: 'grey',
                        borderRadius: '15px',
                        borderWidth: '24px 7px 7px'
                    }}>
                        <i className="far fa-dizzy" />
                </div>
                <h1 className="text-center mt-4 mb-2">
                    <FormattedMessage id="BrowserVersion.NotSupported" />
                </h1>
                <h6>
                    <FormattedMessage id="BrowserVersion.PleaseUpdateBrowser" />
                </h6>
            </div>
        </div>
    )
}