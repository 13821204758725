import React, { Component } from 'react';
import { CustomInput } from 'reactstrap';
import { getAPI } from "../../Base/API"
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { handleNotification } from "../../Base/Notification";
import BookingReview from './BookingReview';
import BookingReviewMobile from './BookingReviewMobile';

class BookingReviewCard extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.reloadReviewInfo = this.reloadReviewInfo.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.filterReply = this.filterReply.bind(this);
        this.fetchData = this.fetchData.bind(this);

        this.state = {
            error: null,
            reviews: {},
            block: true,
            modal: false,
            date: moment().subtract(7, 'days'),
            selectedConnection: null,
            channelinstanceList: [],
            reviewsList: [],
            modalData: {},
            Alerts: [],
            selectedReplyStatus: false,
            pageSize: 10
        };

    }

    handleChange = event => {
        const md = this.state.modalData;
        md.reply = event.target.value;
        const d = this.state.reviewsList;
        d[this.state.key] = d;

        this.setState({
            modalData: md,
            reviewsList: d
        });
    };

    componentDidMount() {

        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.length > 0) {
                    var array = [];

                    data.sort((a, b) => a.active - b.active).reverse().map((item) => {
                        if (item.active) {
                            array.push({ 'label': item.name + ' (' + item.propertyCode + ')', 'value': item.id })
                        }
                        else {
                            array.push({ 'label': <div> <i class="fas fa-times"></i> {item.name} ({item.propertyCode})</div>, 'value': item.id })
                        }
                    });

                    this.setState({ channelinstanceList: array, selectedConnection: array[0].value, block: false, error: errorMessage });
                    this.reloadReviewInfo()
                }
                this.setState({ block: false });
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/Booking.com');


    }

    setselectedConnection(evt) {
        if (evt != null) {
            this.setState({
                selectedConnection: evt.target.value,
            });
        }
    }

    reloadReviewInfo(event) {

        if (event) {
            event.preventDefault();
            if (!this.form.current.reportValidity()) {
                return;
            }
        }

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                }

                if (errorMessage.length > 0)
                    this.setState({ reviews: data, error: errorMessage });
                else
                    this.setState({ reviews: data, error: data.errors });
            }
        }, '/api/Rate/Review/v1/' + this.state.selectedConnection + '/GlobalReview');


        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                }

                if (errorMessage.length > 0)
                    this.setState({ reviewsList: data, error: errorMessage });
                else
                    this.setState({ reviewsList: data, error: data.errors });
            }
        }, '/api/Rate/Review/v1/' + this.state.selectedConnection + '/Reviews?from_date=' + this.state.date.format('YYYY-MM-DD') + '&limit=' + this.state.pageSize + '&has_reply=' + this.state.selectedReplyStatus);

        this.setState({ block: false });
    }

    toggleModal(row, key, rowIndex) {
        if (row != null) {
            this.setState(prevState => ({
                modalData: this.state.reviewsList.data.reviews[rowIndex], key: key, modal: !prevState.modal
            }));
        } else {
            this.setState(prevState => ({
                modalData: {}, key: {}, modal: !prevState.modal
            }));
        }
    }

    filterReply(event) {
        this.setState({ selectedReplyStatus: event.target.value });
    }

    fetchData() {
        this.setState({ block: true });

        var currentreviewsList = this.state.reviewsList;
        const urlSearchParams = new URLSearchParams(currentreviewsList.meta.next_page.substring(this.state.reviewsList.meta.next_page.indexOf("?")));

        if (urlSearchParams.get('has_reply')) {
            urlSearchParams.set('has_reply', (urlSearchParams.get('has_reply') == 0 ? false : true))
        }

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                result = data;
                for (var i = 0; i < result.errors.length; i++) {
                    errorMessage.push({ message: result.errors[i].message, messageType: 'danger' });
                }

                for (i = 0; i < result.warnings.length; i++) {
                    errorMessage.push({ message: result.warnings[i].message, messageType: 'warning' });
                }

                for (i = 0; i < currentreviewsList.data.reviews.length; i++) {
                    result.data.reviews.push(currentreviewsList.data.reviews[i]);
                }
                this.setState({ reviewsList: result, block: false, error: errorMessage });
            }

        }, '/api/Rate/Review/v1/' + this.state.selectedConnection + '/Reviews?' + urlSearchParams)

    }

    setComboStatus = (field, inputValue) => {
        var targetValue = '';
        targetValue = inputValue ? inputValue.value : '';


        this.setState({ [field]: targetValue })
    }
    getBookingComboValues() {

        var array = [
            { 'label': <FormattedMessage id="BookingReviewCard.AllReplies" />, 'value': '' },
            { 'label': <FormattedMessage id="BookingReviewCard.Replied" />, 'value': 'true' },
            { 'label': <FormattedMessage id="BookingReviewCard.NotReplied" />, 'value': 'false', },
        ];

        return array

    }

    changeDate = (date) => {
        this.setState({ date })
    }

    


    render() {
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            hideSelectColumn: true,
            onSelect: this.toggleModal
        };

        const columns = [
            {
                dataField: 'reservation_id',
                text: this.props.intl.formatMessage({ id: "BookingReviewCard.ReservationId" }),
                sort: true
            },
            {
                dataField: 'review_score',
                text: this.props.intl.formatMessage({ id: "BookingReviewCard.ReviewScore" }),
                sort: true,
                formatter: (cell, row) => <div>{row.scoring.review_score}</div>
            },
            {
                dataField: 'clean',
                text: this.props.intl.formatMessage({ id: "BookingReviewCard.Clean" }),
                sort: true,
                formatter: (cell, row) => <div>{row.scoring.clean}</div>
            },
            {
                dataField: 'location',
                text: this.props.intl.formatMessage({ id: "BookingReviewCard.Location" }),
                sort: true,
                formatter: (cell, row) => <div>{row.scoring.location}</div>
            },
            {
                dataField: 'staff',
                text: this.props.intl.formatMessage({ id: "BookingReviewCard.Staff" }),
                sort: true,
                formatter: (cell, row) => <div>{row.scoring.staff}</div>
            },
            {
                dataField: 'value',
                text: this.props.intl.formatMessage({ id: "BookingReviewCard.Value" }),
                sort: true,
                formatter: (cell, row) => <div>{row.scoring.value}</div>
            },
            {
                dataField: 'comfort',
                text: this.props.intl.formatMessage({ id: "BookingReviewCard.Comfort" }),
                sort: true,
                formatter: (cell, row) => <div>{row.scoring.comfort}</div>
            },

            {
                dataField: 'replied',
                text: this.props.intl.formatMessage({ id: "BookingReviewCard.Replied" }),
                sort: true,
                formatter: (cell, row) => row.content !== null ? <CustomInput type="checkbox" id={Math.random()} disabled={true} checked={row.reply != null ? true : false} /> : ''
            }
        ]

        return (
            <div>
                {!global.isMobile ?
                    <BookingReview
                        block={this.state.block}
                        error={this.state.error}
                        form={this.form}
                        channelinstanceList={this.state.channelinstanceList}
                        setComboStatus={this.setComboStatus}
                        getBookingComboValues={this.getBookingComboValues}
                        date={this.state.date}
                        changeDate={this.changeDate}
                        reloadReviewInfo={this.reloadReviewInfo}
                        modal={this.state.modal}
                        modalData={this.state.modalData}
                        handleChange={this.handleChange}
                        selectedConnection={this.state.selectedConnection}
                        toggleModal={this.toggleModal}
                        reviews={this.state.reviews}
                        reviewsList={this.state.reviewsList}
                        columns={columns}
                        selectRow={selectRow}
                        fetchData={this.fetchData}
                    />
                : 
                    <BookingReviewMobile
                        block={this.state.block}
                        error={this.state.error}
                        form={this.form}
                        channelinstanceList={this.state.channelinstanceList}
                        setComboStatus={this.setComboStatus}
                        getBookingComboValues={this.getBookingComboValues}
                        date={this.state.date}
                        changeDate={this.changeDate}
                        reloadReviewInfo={this.reloadReviewInfo}
                        modal={this.state.modal}
                        modalData={this.state.modalData}
                        handleChange={this.handleChange}
                        selectedConnection={this.state.selectedConnection}
                        toggleModal={this.toggleModal}
                        reviews={this.state.reviews}
                        reviewsList={this.state.reviewsList}
                        columns={columns}
                        selectRow={selectRow}
                        fetchData={this.fetchData}
                    />
                }
            </div>
        );
    }
}

export default injectIntl(BookingReviewCard)