import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { StyledCard } from '../../../Base/Common/CommonUIComponents';
import { getAPI } from '../../../Base/API';
import { getOperationModes } from '../../../Base/Common/ReferenceDataFunctions';
import { Step1 } from './Step1';
import { StepRoomCategories } from './StepRoomCategories';
import { StepRates } from './StepRates';
import { StepRatesToNone } from './StepRatesToNone';

export class ChangeOperationMode extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            steps: [
                { enable: true, subtitle: 'Hotel&Mode', menu: (selectedHotel, hotels, operationMode, operationModes) => <Step1 handleHotel={this.handleHotel} handleOperationMode={this.handleOperationMode} hotels={hotels} operationMode={operationMode} operationModes={operationModes} selectedHotel={selectedHotel} /> }
            ],
            hotels: [],
            operationMode: null,
            operationModes: [],
            selectedHotel: null
        };
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const hotels = data.hotelLicenses
                    .map(item => {
                        const hotel = {
                            value: item.hotel.hotelId,
                            label: item.hotel.name2,
                            operationMode: item.operationMode
                        };
                        return hotel;
                    });

                this.setState({ hotels });
            }
        }, 'api/hotel/v1/hotelLicense');
    }

    next = (val) => {
        const { steps } = this.state;
        steps.forEach((step, idx) => step.enable = idx === val);

        this.setState({ steps });
    }

    handleHotel = (combo) => {
        let operationModes = [];
        if (combo?.operationMode === getOperationModes()[0].value) {
            operationModes = [getOperationModes()[2]];
        }
        else {
            operationModes = getOperationModes().filter(opt => opt.value !== combo?.operationMode);
        }

        this.setState({ selectedHotel: combo, operationModes });
    }

    handleOperationMode = (combo) => {
        const { selectedHotel } = this.state;
        let steps = [];

        if (combo.value === getOperationModes()[2].value) {
            steps = [
                { enable: true, subtitle: 'Hotel&Mode', menu: (selectedHotel, hotels, operationMode, operationModes) => <Step1 handleHotel={this.handleHotel} handleOperationMode={this.handleOperationMode} hotels={hotels} operationMode={operationMode} operationModes={operationModes} selectedHotel={selectedHotel} /> },
                { enable: false, subtitle: 'Rates', menu: (selectedHotel) => <StepRatesToNone selectedHotel={selectedHotel} /> }
            ]
        }
        else if (selectedHotel.operationMode === getOperationModes()[2].value) {
            if (combo.value === getOperationModes()[0].value) {
                steps = [
                    {
                        enable: true,
                        subtitle: 'Hotel&Mode',
                        menu: (selectedHotel, hotels, operationMode, operationModes) => <Step1 handleHotel={this.handleHotel} handleOperationMode={this.handleOperationMode} hotels={hotels} operationMode={operationMode} operationModes={operationModes} selectedHotel={selectedHotel} />
                    },
                    { enable: false, subtitle: 'RoomCategories', menu: (selectedHotel) => <StepRoomCategories selectedHotel={selectedHotel} /> },
                    { enable: false, subtitle: 'Rates', menu: (selectedHotel) => <StepRates selectedHotel={selectedHotel} /> }
                ];
            }
            else if (combo.value === getOperationModes()[1].value) {
                steps = [
                    { enable: true, subtitle: 'Hotel&Mode', menu: (selectedHotel, hotels, operationMode, operationModes) => <Step1 handleHotel={this.handleHotel} handleOperationMode={this.handleOperationMode} hotels={hotels} operationMode={operationMode} operationModes={operationModes} selectedHotel={selectedHotel} /> },
                    { enable: false, subtitle: 'RoomCategories', menu: (selectedHotel) => <StepRoomCategories selectedHotel={selectedHotel} /> }
                ];
            }
            else {
                steps = [
                    { enable: true, subtitle: 'Hotel&Mode', menu: (selectedHotel, hotels, operationMode, operationModes) => <Step1 handleHotel={this.handleHotel} handleOperationMode={this.handleOperationMode} hotels={hotels} operationMode={operationMode} operationModes={operationModes} selectedHotel={selectedHotel} /> },
                ];
            }
        }
        else if (selectedHotel.operationMode === getOperationModes()[1].value) {
            if (combo.value === getOperationModes()[0].value) {
                steps = [
                    { enable: true, subtitle: 'Hotel&Mode', menu: (selectedHotel, hotels, operationMode, operationModes) => <Step1 handleHotel={this.handleHotel} handleOperationMode={this.handleOperationMode} hotels={hotels} operationMode={operationMode} operationModes={operationModes} selectedHotel={selectedHotel} /> },
                    { enable: false, subtitle: 'Rates', menu: (selectedHotel) => <StepRates selectedHotel={selectedHotel} /> }
                ]
            }
            else {
                steps = [
                    { enable: true, subtitle: 'Hotel&Mode', menu: (selectedHotel, hotels, operationMode, operationModes) => <Step1 handleHotel={this.handleHotel} handleOperationMode={this.handleOperationMode} hotels={hotels} operationMode={operationMode} operationModes={operationModes} selectedHotel={selectedHotel} /> },
                ];
            }
        }
        else {
            steps = [
                { enable: true, subtitle: 'Hotel&Mode', menu: (selectedHotel, hotels, operationMode, operationModes) => <Step1 handleHotel={this.handleHotel} handleOperationMode={this.handleOperationMode} hotels={hotels} operationMode={operationMode} operationModes={operationModes} selectedHotel={selectedHotel} /> },
            ];
        }

        this.setState({
            operationMode: combo.value,
            steps: [{ enable: true, subtitle: 'Hotel&Mode', menu: (selectedHotel, hotels, operationMode, operationModes) => <Step1 handleHotel={this.handleHotel} handleOperationMode={this.handleOperationMode} hotels={hotels} operationMode={operationMode} operationModes={operationModes} selectedHotel={selectedHotel} /> }]
        }, _ => this.setState({ steps }, _ => this.next(1)));
    }

    render() {
        const { block, error, hotels, operationMode, operationModes, selectedHotel, steps } = this.state;

        return (
            <StyledCard block={block} error={error} title="navbar.ChangeOperationMode">
                <Row>
                    <Col className="col-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {
                            selectedHotel ?
                                <span>
                                    <b>
                                        <FormattedMessage id="ChangeOperationMode.Hotel" />
                                        {`: ${selectedHotel.label}`}
                                    </b>
                                </span>
                                :
                                <span />
                        }
                    </Col>
                    <Col className="py-1">
                        <ul className="steps">
                            {
                                steps.map((step, key) =>
                                    <li key={key} className={`step ${step.enable ? `step-active` : ``}`}>
                                        <div className="step-content">
                                            <span className="step-text small">
                                                <FormattedMessage id={`ChangeOperationMode.Step${key + 1}`} />
                                            </span>
                                            <span className="step-circle" onClick={_ => this.next(key)} />
                                            <span className="step-text small">
                                                <FormattedMessage id={`ChangeOperationMode.${step.subtitle}`} />
                                            </span>
                                        </div>
                                    </li>    
                                )
                            }
                        </ul>
                    </Col>
                    <Col className="col-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span>
                            {
                                selectedHotel ?
                                    <span>
                                        <b>
                                            {getOperationModes().find(opt => opt.value === selectedHotel.operationMode)?.label}
                                            {` -> `}
                                            {getOperationModes().find(opt => opt.value === operationMode)?.label}
                                        </b>
                                    </span>
                                    :
                                    <span />
                            }
                        </span>    
                    </Col>
                </Row>
                {
                    steps.map((step, key) =>
                        <div key={key} className="mt-3" style={{ display: step.enable ? 'block' : 'none', height: '100%' }}>
                            {step.menu(selectedHotel, hotels, operationMode, operationModes)}
                        </div>
                    )
                }
            </StyledCard>
        );
    }
}