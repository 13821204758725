import React, { Component } from 'react';
import { CardBody, Card, InputGroup, FormGroup, Label, Input, CardHeader, Row, Col, Badge, UncontrolledTooltip, Button } from 'reactstrap';
import { NotificationAlert } from "../Common/NotificationAlert";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { ErrorAlert } from "../Common/ErrorAlert";
import { postAPI } from "../Base/API";
import { CheckAuthorization } from '../Base/Authorization';
import { handleNotification } from '../Base/Notification';
import { getApplyDifferencesAsDiscounts } from '../Base/Common/ReferenceDataFunctions';

export class EmailReservationChannelInstanceDetail extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.saveChannelInstanceEmailRes = this.saveChannelInstanceEmailRes.bind(this);
        this.selectedChannelForm = React.createRef();
        this.state = {
            block: false,
            selectedChannel: this.props.selectedChannel,
            notification: {
                type: 'Success',
                message: ''
            },
            allSupportedInfo: [
                { value: "Availability", key: "sendAvailability", label: this.props.intl.formatMessage({ id: "ChannelList.SendAvailability" }) },
                { value: "Price", key: "sendPrice", label: this.props.intl.formatMessage({ id: "ChannelList.SendPrice" }) },
                { value: "CTA", key: "sendCta", label: this.props.intl.formatMessage({ id: "ChannelList.SendCta" }) },
                { value: "CTD", key: "sendCtd", label: this.props.intl.formatMessage({ id: "ChannelList.SendCtd" }) },
                { value: "MaxStays", key: "sendMaxStays", label: this.props.intl.formatMessage({ id: "ChannelList.SendMaxStay" }) },
                { value: "MinStays", key: "sendMinStay", label: this.props.intl.formatMessage({ id: "ChannelList.SendMinStay" }) },
                { value: "StopSales", key: "sendStopSales", label: this.props.intl.formatMessage({ id: "ChannelList.SendStopSales" }) },
                { value: "MinAdvancedBookingOffset", key: "sendMinAdvancedBookingOffset", label: this.props.intl.formatMessage({ id: "ChannelList.SendMinAdvancedBookingOffset" }) },
                { value: "MaxAdvancedBookingOffset", key: "sendMaxAdvancedBookingOffset", label: this.props.intl.formatMessage({ id: "ChannelList.SendMaxAdvancedBookingOffset" }) }
            ]
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        if (this.props.selectedChannel.channelName && this.props.selectedChannel.channelName.toUpperCase() === 'AIRBNB') {
            const script = document.createElement("script");

            script.src = "https://www.airbnb.com/platform_js";
            script.async = true;

            document.body.appendChild(script);
        }
    }

    changeStringField(name, evt) {
        const val = evt.target ? evt.target.value : null;
        const { selectedChannel } = this.state;

        if (this.props.selectedChannel.code === "HEYBE" && name === 'propertyCode') {
            selectedChannel[name] = val.toLowerCase();
        }
        else {
            selectedChannel[name] = val;
        }

        this.setState(({ selectedChannel }));
    }

    validateSupportedInfo = () => {
        var notSupported = [];

        if (this.props.supportedData && this.props.supportedData.length > 0) {
            this.state.allSupportedInfo.forEach(el => {
                if (!this.props.supportedData.find(sd => sd.toLowerCase() === el.value.toLowerCase()) && this.state.selectedChannel[el.key]) {
                    notSupported.push(el.label);
                }
            })
        }

        return notSupported;
    }

    saveChannelInstanceEmailRes() {
        const notSupported = this.validateSupportedInfo();
        if (notSupported && notSupported.length > 0) {
            const data = {
                errors: [
                    {
                        code: <FormattedMessage id="CommonUIComponents.Error" />,
                        message: `${notSupported.join(", ")} ${this.props.intl.formatMessage({ id: "ChannelDashboard.AreNotSupported" })}`
                    }
                ]
            }
            handleNotification(data)
            return;
        }

        if (this.selectedChannelForm.current.reportValidity()) {
            this.setState({ block: true }, () => {
                const errorMessage = [];

                postAPI(result => {
                    const { data, error } = result;
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors) {
                            data.errors.forEach(error => {
                                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                                this.setState({ error: errorMessage, block: false });
                                return;
                            });
                        }
                        if (data.response) {
                            handleNotification(data, <FormattedMessage id="ChannelList.ChannelInstanceUpdateSuccess" />, <FormattedMessage id="General.Success" />);
                            this.setState({ block: false, selectedChannel: data.response });
                            return;
                        }
                        this.setState({ block: false });
                    }
                }, "/api/Rate/ChannelConfig/v1/ChannelInstance", this.state.selectedChannel)
            });
        }
    }

    render() {
        const { selectedChannel } = this.state;

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <form ref={this.selectedChannelForm}>
                        <Card className=" shadow border-0 mb-2">
                            <ErrorAlert error={this.state.error} />
                            <CardHeader className="bg-white border-bottom" >
                                <Row>
                                    <Col>
                                        <strong>{this.props.selectedChannel.channelName}</strong>
                                    </Col>
                                    <Col className="text-right">
                                        {
                                            this.props.selectedChannel.active ?
                                                <span>
                                                    <Badge className={selectedChannel?.code !== 'MAILRES' ? "p-2" : ""} color="primary" id="channelInstanceDetailActivatedAtChannel">
                                                        <FormattedMessage id="ChannelDashboard.Active" />
                                                    </Badge>
                                                    {
                                                        this.props.selectedChannel.activatedAt ?
                                                            <UncontrolledTooltip placement="bottom" target="channelInstanceDetailActivatedAtChannel">
                                                                <FormattedMessage id="ChannelDashboard.ActivatedAtChannel" values={{ activatedAt: moment(this.props.selectedChannel.activatedAt).format("YYYY-MM-DD HH:mm:ss") }} />
                                                            </UncontrolledTooltip>
                                                            :
                                                            ''
                                                    }
                                                </span>
                                                :
                                                <Badge className={selectedChannel?.code !== 'MAILRES' ? "p-2" : ""} color="secondary">
                                                    <FormattedMessage id="ChannelDashboard.Inactive" />
                                                </Badge>
                                        }
                                        {
                                            this.props.selectedChannel.channelConfigState ?
                                                <Badge color={selectedChannel?.code !== 'MAILRES' ? 'info' : 'success'} className={`ml-2 ${selectedChannel?.code !== 'MAILRES' ? "p-2" : ""}`}>
                                                    {this.props.selectedChannel.channelConfigState}
                                                </Badge>
                                                :
                                                <span />
                                        }
                                        {CheckAuthorization("channel:update") || getApplyDifferencesAsDiscounts(this.props.selectedChannel.channelName.toUpperCase()) ?
                                            <Button className="btn-sm btn-host ml-2" onClick={this.saveChannelInstanceEmailRes}>
                                                <i className="fas fa-save" />
                                            </Button>
                                            : ''}

                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <NotificationAlert notification={this.state.notification} />
                                <Row>
                                    <Col className="col-6">
                                        <FormGroup row>
                                            <Label for="apiKey" sm={4}><FormattedMessage id="EmailReservation.ApiKey" /></Label>
                                            <Col sm={8}>
                                                <InputGroup size="sm">
                                                    <FormattedMessage id={"EmailReservation.ApiKey"}>
                                                        {placeholder => <Input type={"password"} name="password" onChange={this.changeStringField.bind(this, 'password')} placeholder={placeholder} defaultValue='sdsdasdasd' />}
                                                    </FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </form>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(EmailReservationChannelInstanceDetail);