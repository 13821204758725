import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getReasonOptions } from '../Base/Common/ReferenceDataFunctions';

export class PaymentRefunds extends Component {

    render() {
        const { reservationPaymentRefund } = this.props;
        
        return (
            <>
                <Row>
                    <Col>
                        <FormattedMessage id="Payments.Date"/>
                    </Col>
                    <Col>
                        <FormattedMessage id="Payments.Name"/>
                    </Col>
                    <Col>
                        <FormattedMessage id="Payments.Amount"/>
                    </Col>
                    <Col>
                        <FormattedMessage id="Payments.Reason"/>
                    </Col>
                    <Col>
                        <FormattedMessage id="Payments.Notes"/>
                    </Col>
                </Row>
                {reservationPaymentRefund.map((el, k) => 
                    <Row className="mt-1" key={k}>
                        <Col>
                            {el.createdAt}
                        </Col>
                        <Col>
                            {el.userName}
                        </Col>
                        <Col>
                            {el.amount}
                        </Col>
                        <Col>
                            {getReasonOptions().filter(reason => reason.value === el.reason).map(reason => reason.label)}
                        </Col>
                        <Col>
                            {el.notes}
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}