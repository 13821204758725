import React, { Component } from 'react';
import { FormattedHTMLMessage, FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { Col, Row, Card } from 'reactstrap';
import { postAPI } from "../Base/API";
import { ErrorAlert } from "../Common/ErrorAlert";
import ChartCard from "./Common/ChartCard";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import moment from 'moment';
import { FormatAmountNumber } from '../Base/Common/CommonUIComponents';

class Acquisition extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.search === true && nextProps.search !== this.props.search) {
            this.getData();
            this.props.toggleSearch();
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ block: true });

        const body = {
            startDate: moment(this.props.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
            dimensions: ["sessionSource"],
            metrics: ["totalUsers", "newUsers", "totalRevenue", "addToCarts", "totalPurchasers"],
            aggregation: []
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const json = JSON.parse(data);

                const filteredSources = json?.rows?.map(row => {
                    const result = {
                        sessionSource: row.dimensionValues[0].value,
                        totalUsers: parseInt(row.metricValues[0].value),
                        totalPurchasers: row.metricValues[4].value,
                        totalRevenue: parseInt(row.metricValues[2].value)
                    }
                    return result;
                });

                this.setState({ filteredSources }, this.getSourcesByDayData())
            }
        }, `/api/hotel/v1/googleanalytics`, body);

    }

    getSourcesByDayData = () => {

        const body = {
            startDate: moment(this.props.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
            dimensions: ["date", "firstUserSource", "sessionSource"],
            metrics: ["totalUsers", "newUsers", "totalRevenue", "addToCarts", "totalPurchasers"],
            aggregation: []
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const json = JSON.parse(data);

                this.setState({ block: false, data: json }, this.formatDataForCharts(json));
            }
        }, `/api/hotel/v1/googleanalytics`, body);
    }

    formatDataForCharts = (data) => {
        const { intl, dates } = this.props;
        
        let totalUsers = 0;
        const sources = [];

        const totalUsersOverTime = [[this.props.intl.formatMessage({ id: "BEAnalitics.Date" }), this.props.intl.formatMessage({ id: "BEAnalitics.totalUsers" })]];
        const revenueBySourceOverTime = [[intl.formatMessage({ id: "BEAnalitics.Date" })]];

        const revenueBySource = [[intl.formatMessage({ id: "BEAnalitics.Source" }), intl.formatMessage({ id: "BEAnalitics.totalRevenue" })]];
        const totalUsersBySource = [[intl.formatMessage({ id: "BEAnalitics.Source" }), intl.formatMessage({ id: "BEAnalitics.totalUsers" })]];

        const totalUsersBySourceOverTime = [[this.props.intl.formatMessage({ id: "BEAnalitics.Date" })]];

        if (data?.rows) {
            //Get static metrics
            data.rows.forEach(row => {
                //Source
                const currSource = row.dimensionValues[2]?.value;
                if (!sources.find(t => t === currSource)) {
                    const currRows = data.rows?.filter(row => currSource === row.dimensionValues[2].value);
                    sources.push(currSource);
                    revenueBySource.push([currSource, currRows.reduce((prev, row) => prev + parseInt(row.metricValues[3].value), 0)]);
                    totalUsersBySource.push([currSource, currRows.reduce((prev, row) => prev + parseInt(row.metricValues[0].value), 0)]);
                }

                //Unfiltered
                totalUsers = totalUsers + parseInt(row.metricValues[0]?.value);
            });

            sources.forEach(source => {
                revenueBySourceOverTime[0].push(source);
                totalUsersBySourceOverTime[0].push(source);
            });

            dates.forEach(day => {
                const currValues = data.rows?.filter(row => moment(row.dimensionValues[0]?.value).isSame(day));
                const formattedDay = moment(day).format('MM-DD');

                if (currValues.length > 0) {
                    totalUsersOverTime.push([formattedDay, currValues.reduce((prev, row) => prev + parseInt(row.metricValues[0]?.value), 0)]);
                }
                else {
                    totalUsersOverTime.push([formattedDay, 0]);
                }

                const row = [moment(day).format('MM-DD')];
                const users = [moment(day).format('MM-DD')];
                sources.forEach(c => {
                    const currSource = currValues?.filter(row => row.dimensionValues[2].value === c);
                    if (currSource && currSource.length > 0) {
                        row.push(currSource.reduce((prev, row) => prev + parseInt(row.metricValues[3].value), 0));
                        users.push(currSource.reduce((prev, row) => prev + parseInt(row.metricValues[0].value), 0));
                    }
                    else {
                        row.push(0);
                        users.push(0);
                    };
                });

                totalUsersBySourceOverTime.push(users);
                revenueBySourceOverTime.push(row);
            });
        }

        this.setState({
            totalUsers, totalUsersOverTime, revenueBySourceOverTime, totalUsersBySourceOverTime,
            revenueBySource, totalUsersBySource
        });
    }

    setValuesByDay = (array, row, dimension, metric) => {
        const index = array.findIndex(el => moment(el[0]).isSame(row.dimensionValues[dimension].value));

        if (index !== -1) {
            array[index][1] += parseInt(row.metricValues[metric].value);
        }

        return array;
    }


    render() {
        const { block, totalUsers, totalUsersOverTime, totalUsersBySourceOverTime, filteredSources, fullScreen,
            revenueBySourceOverTime, revenueBySource, totalUsersBySource } = this.state;
        const { sourceColors, userColors, revenueColors } = this.props;
            
        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={this.state.error} />
                <div>
                    <Row className="my-3">
                        <Col>
                            <Card body className="shadow border-0 h-100 mb-3" style={{ maxHeight: '268px', overflowX: 'auto' }}>
                                <span className="text-xs font-weight-bold text-secondary text-uppercase mb-2">
                                    <FormattedMessage id="BEAnalitics.AcquisitionOverview" />
                                </span>

                                <div className="text-center mt-2">
                                    <Row>
                                        <Col className="col-3">
                                            <FormattedMessage id="BEAnalitics.Source" />
                                        </Col>
                                        <Col className="col-3">
                                            <FormattedMessage id="BEAnalitics.TotalUsers" />
                                        </Col>
                                        <Col className="col-3">
                                            <FormattedMessage id="BEAnalitics.totalPurchasers" />
                                        </Col>
                                        <Col className="col-3">
                                            <FormattedMessage id="BEAnalitics.Revenue" />
                                        </Col>
                                    </Row>

                                    <hr className="my-2" />
                                    {filteredSources?.map((s, key) =>
                                        <Row key={key} className="overbglight py-1">
                                            <Col className="col-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <span
                                                    title={s.sessionSource}
                                                    style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                                                    {s.sessionSource}
                                                </span>
                                            </Col>
                                            <Col className="col-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {s.totalUsers}
                                            </Col>
                                            <Col className="col-3" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                {s.totalPurchasers}
                                            </Col>
                                            <Col className="col-3">
                                                <FormatAmountNumber
                                                    value={s.totalRevenue}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </Card>
                        </Col>
                        <Col className="col-6">
                            {totalUsersOverTime && totalUsersOverTime.length > 1 ?
                                <ChartCard
                                    chartType='LineChart'
                                    total={totalUsers}
                                    data={totalUsersOverTime}
                                    options={{
                                        legend: "none",
                                        colors: userColors,
                                        curveType: 'function',
                                        vAxis: { minValue: 0, viewWindow: { min: 0 }, viewWindowMode: "explicit" },
                                        intervals: { "style": "area", "color": "#D49464" }
                                    }}
                                    title={<FormattedMessage id="BEAnalitics.totalUsers" />}
                                />
                            :''}
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col className="col-6">
                            {totalUsersBySource && totalUsersBySource.length > 1 ?
                                <ChartCard
                                    chartType='PieChart'
                                    data={totalUsersBySource}
                                    options={{
                                        pieSliceText: 'none',
                                        colors: sourceColors
                                    }}
                                    title={<FormattedMessage id="BEAnalitics.totalUsersbySource" />}
                                />
                            :''}
                        </Col>
                        <Col className="col-6">
                            {revenueBySource && revenueBySource.length > 1 ?
                                <ChartCard
                                    chartType='PieChart'
                                    data={revenueBySource}
                                    options={{
                                        pieSliceText: 'none',
                                        colors: sourceColors,
                                    }}
                                    title={<FormattedMessage id="BEAnalitics.revenuebySource" />}
                                />
                            :''}
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col className="col-6"
                            style={fullScreen === 'totalUsersBySourceOverTime' ? { position: 'absolute', width: '100%', zIndex: '10', maxWidth: 'unset' } : {}}>
                            {totalUsersBySourceOverTime && totalUsersBySourceOverTime.length > 1 ?
                                <ChartCard
                                    chartType='AreaChart'
                                    data={totalUsersBySourceOverTime}
                                    options={{
                                        areaOpacity: 1,
                                        isStacked: true,
                                        chartArea: { width: '75%', left: "5%" },
                                        curveType: 'function',
                                        vAxis: { minValue: 0, viewWindow: { min: 0 }, viewWindowMode: "explicit" },
                                        colors: sourceColors
                                    }}
                                    fullScreen={true}
                                    fullScreenFunction={() => { fullScreen === 'totalUsersBySourceOverTime' ? this.setState({ fullScreen: null }) : this.setState({ fullScreen: 'totalUsersBySourceOverTime' }) }}
                                    title={<FormattedMessage id="BEAnalitics.totalUsersBySourceOverTime" />}
                                />
                            :''}
                        </Col>
                        <Col className="col-6"
                            style={fullScreen === 'revenueBySourceOverTime' ? { position: 'absolute', width: '100%', zIndex: '10', maxWidth: 'unset' } : {}}>
                            {revenueBySourceOverTime && revenueBySourceOverTime.length > 1 ?
                                <ChartCard
                                    chartType='AreaChart'
                                    data={revenueBySourceOverTime}
                                    options={{
                                        chartArea: { width: '75%', left: "5%" },
                                        areaOpacity: 1,
                                        isStacked: true,
                                        curveType: 'function',
                                        vAxis: { minValue: 0, viewWindow: { min: 0 }, viewWindowMode: "explicit" },
                                        colors: sourceColors
                                    }}
                                    fullScreen={true}
                                    fullScreenFunction={() => { fullScreen === 'revenueBySourceOverTime' ? this.setState({ fullScreen: null }) : this.setState({ fullScreen: 'revenueBySourceOverTime' }) }}
                                    title={<FormattedMessage id="BEAnalitics.revenueBySourceOverTime" />}
                                />
                            :''}
                        </Col>
                    </Row>
                </div>
            </BlockUi>
        )
    }
}

export default injectIntl(Acquisition);
