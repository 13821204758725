import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { deleteAPI, getAPI } from '../../Base/API';
import { BlankCard } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from '../../Base/Notification';

export class BeDataDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            seePrice: false,
            block: false,
            prices: 0
        }
    }

    getPrices = () => {
        const { hotelId } = this.props;

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                }
                else if (data.response && data.response.length > 0) {
                    this.setState({ block: false, prices: data.response[0].prices });
                }
                else {
                    this.setState({ block: false });
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/hotel/Complexes/v1/Hotel/${hotelId}/BE/Prices`);
    }

    deletePrices = () => {
        const { hotelId } = this.props;

        this.setState({ block: true });
        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                if (error.request && (error.request.status === 500 || error.request.status === 504)) {
                    handleNotification({}, <FormattedMessage id="ManageBeData.TimeoutRefreshPage" />, <FormattedMessage id="ChannelConf.Timeout" />, 'info');
                }
                else {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                }
                this.setState({ error: errorMessage }, this.getPrices);
                return;
            }
            if (data.errors && data.errors.length > 0) {
                handleNotification(data);
                this.setState({ error: errorMessage }, this.getPrices);
            }
            else {
                handleNotification(data, <FormattedMessage id="ManageBeData.DeletePricesData" />, <FormattedMessage id="General.Success" />);
                this.setState({ error: errorMessage }, this.getPrices);
            }
        }, `/api/hotel/Complexes/v1/Hotel/${hotelId}/BE/Prices`)
    }

    render() {
        const { block, error, seePrice, prices } = this.state;

        return (
            seePrice ?
                <BlankCard block={block} error={error}>
                    <Row className="text-center">
                        <Col>
                            Preços: {prices}
                        </Col>
                        <Col className="text-center">
                            <Button className="btn btn-host btn-sm" onClick={this.deletePrices}> <span className="fas fa-trash-alt" /> </Button>
                        </Col>
                    </Row>
                </BlankCard>
                :
                <Button className="btn btn-host btn-sm" onClick={_ => this.setState({ seePrice: true, block: true }, this.getPrices)}> <FormattedMessage id="ManageBeData.SeePrices" /> </Button>
        );
    }
}