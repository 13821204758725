import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Form, FormFeedback, Card } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../../Base/Notification";
import { postAPI, getAPI } from "../../Base/API";
import { StyledCard, SelectHotel, SelectChannelConfig, reservationStatus } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';
import CustomSelect from '../../Base/Common/CustomSelect';
import { ErrorAlert } from "../../Common/ErrorAlert";
import { DateRangePicker } from 'react-dates';
import { Line } from 'react-chartjs-2';
import BlockUi from 'react-block-ui';
import moment from 'moment';

class DeliveryTime extends Component {
	constructor(props) {
		super(props);

		this.state = {
			block: false,
			startDate: moment().subtract(7, 'days'),
			endDate: moment(),
			returnOption: 'ALL',
			hotelResults: null,
			lineChart: null
		};
	}

	componentDidMount() {
		this.getDeliveryTime();
	}

	getDeliveryTime = (e) => {
		if (e) { e.preventDefault(); }

		this.setState({ block: true });
		var params = '';
		if (this.state.status) params += '&status=' + this.state.status;
		if (this.state.hotelId) params += '&hotelId=' + this.state.hotelId;
		if (this.state.channelConfigId) params += '&channelConfigId=' + this.state.channelConfigId;
		if (this.state.returnOption) params += '&returnOption=' + this.state.returnOption;

		getAPI(result => {
			const { data, error, isLoading } = result;
			this.setState({ block: isLoading });
			if (error) {
				const errorMessage = [];
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });
				return;
			}
			if (data) {
				if (data.response && data.response.length > 0) {
					if (data.response[0].channel) { this.lineChartData(data.response[0].channel)} else this.setState({ lineChart: null});

					this.setState({ block: false, error: [], hotelResults: data.response[0].hotel ? data.response[0].hotel.sort((a, b) => b.channelTime - a.channelTime) : null });
				}
			}
		}, `/api/Rate/DashBoard/Reservation/deliveryTime?&fromDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.endDate).format("YYYY-MM-DD")}` + params);
	}

	lineChartData = (list) => {
		const orderedList = list.sort((a, b) => b.channelTime - a.channelTime)

		var lineChart = {
			drawOnChartArea: false,
			labels: [], 
			datasets: [
				{ label: this.props.intl.formatMessage({ id: "DeliveryTime.ChanelTime" }), yAxisID: 'channel', data: [], fill: false, borderColor: "#02c5c5", backgroundColor: "#02c5c533", borderWidth: "1" },
				{ label: this.props.intl.formatMessage({ id: "DeliveryTime.PmsTime" }), yAxisID: 'pms', data: [], fill: false, borderColor: "#6699ff", backgroundColor: "#6699ff33", borderWidth: "1" },
			]
		}

		orderedList && orderedList.forEach((element, i) => {
			lineChart.labels.push(element.name)
			lineChart.datasets[0].data.push(element.channelTime);
			lineChart.datasets[1].data.push(element.pmsTime);
		})

		this.setState({ lineChart })
	}

	handleChangeSelect = (name, combo) => {
		this.setState({
			[name]: combo ? combo.value : null
		});
	};

	render() {
		const columns = [
			{
				dataField: 'name',
				text: this.props.intl.formatMessage({ id: "HotelLicenses.Hotel" }),
				sort: true
			},
			{
				dataField: 'channelTime',
				text: this.props.intl.formatMessage({ id: "DeliveryTime.ChanelTime" }),
				sort: true
			},
			{
				dataField: 'pmsTime',
				text: this.props.intl.formatMessage({ id: "DeliveryTime.PmsTime" }),
				sort: true
			}
		];

		const returnOptions = [
			{ value: 'ALL', label: <FormattedMessage id="DeliveryTime.All" /> },
			{ value: 'HOTEL', label: <FormattedMessage id="HotelLicenses.Hotel" /> },
			{ value: 'CHANNEL', label: <FormattedMessage id="ReservationList.Channel" /> }
		]
		
		let optionsLine = {
			legend: {
				position: "top"
			},
			scales: {
				xAxes: [{
					gridLines: {
						drawOnChartArea: false
					}
				}],
				yAxes: [
					{
						//type: 'time',
						//time: {
						//	unit: 'second',
						//	displayFormats: {
						//		second: 'HH'
						//	}
						//},
						ticks: { beginAtZero: true, precision: 0, min: 0 },
						display: true,
						position: 'left',
						id: 'channel',
						gridLines: {
							display: false
						},
						scaleLabel: {
							display: true,
							labelString: this.props.intl.formatMessage({ id: "DeliveryTime.ChanelTime" })
						}
					},
					{
						//type: 'time',
						ticks: { beginAtZero: true, min: 0 },
						display: true,
						position: 'right',
						id: 'pms',
						gridLines: {
							display: false
						},
						labels: {
							show: true
						},
						scaleLabel: {
							display: true,
							labelString: this.props.intl.formatMessage({ id: "DeliveryTime.PmsTime" })
						}
					}

				]
			}
		}

		return (

			<BlockUi tag="div" blocking={this.state.block}>
				<ErrorAlert error={this.state.error} />

				<Row className="mt-1">
					<Col>
						<Card className="shadow" body>
							<Form onSubmit={(e) => this.getDeliveryTime(e)}>
								<Row>
									<Col>
										<SelectHotel
											name={'hotelId'}
											icon={'fas fa-hotel'}
											onChangeFunc={this.handleChangeSelect}
											value={this.state.hotelId}
											placeholder={<FormattedMessage id="HotelLicenses.Hotel" />}
										/>
									</Col>
									<Col>
										<SelectChannelConfig
											name={'channelConfigId'}
											icon={'fas fa-plug'}
											onChangeFunc={this.handleChangeSelect}
											value={this.state.channelConfigId}
											placeholder={<FormattedMessage id="ReservationList.SelectChannel" />}
										/>
									</Col>
									<Col>
										<CustomSelect
											icon={'fas fa-suitcase fa-fw'}
											placeholder={<FormattedMessage id="DeliveryTime.ReservationStatus" />}
											options={reservationStatus}
											onChange={this.handleChangeSelect.bind(this, 'status')}
											isClearable isSearchable
										/>
									</Col>
									<Col>
										<CustomSelect
											icon={'fas fa-cog fa-fw'}
											placeholder={<FormattedMessage id="ReservationList.Channel" />}
											options={returnOptions}
											onChange={this.handleChangeSelect.bind(this, 'returnOption')}
											value={returnOptions.find(ro => ro.value === this.state.returnOption)}
											isClearable isSearchable required
										/>
									</Col>
									<Col>
										<DateRangePicker
											startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
											startDateId="your_unique_start_date_id_announcement"
											isOutsideRange={() => false}
											endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
											endDateId="your_unique_end_date_id_announcement"
											onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
											focusedInput={this.state.focusedInput}
											onFocusChange={focusedInput => this.setState({ focusedInput })}
											small={true}
											numberOfMonths={1}
											showDefaultInputIcon={true}
											renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
											required
										/>
									</Col>
									<Col>
										<Button className="float-right  btn-sm btn-host btn" type="submit">
											<i className="fas fa-search" />
										</Button>
									</Col>
								</Row>
							</Form>
						</Card>
					</Col>
				</Row>
				{this.state.lineChart !== null ?
					<Row className="mt-2">
						<Col>
							<Card className="shadow" body>
								<Row>
									<Col>
										<p className="text-xs font-weight-bold text-secondary text-uppercase mb-1"><span>Tempos por canal</span></p>
									</Col>
								</Row>
								<Row>
									<Col>
										<Line data={this.state.lineChart} type='line' height={60} options={optionsLine} />
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				: ''}

				{this.state.hotelResults !== null ?
					<Row className="mt-2">
						<Col>
							<Card className="shadow" body>
								<Row className="mb-2">
									<Col>
										<p className="text-xs font-weight-bold text-secondary text-uppercase mb-1"><span>Tempos por hotel</span></p>
									</Col>
								</Row>
								<Row>
									<Col className="h-100">
										<Card className="border-0">
											<CustomTable
												data={this.state.hotelResults ? this.state.hotelResults : []}
												columns={columns}
												showTotal={true}
												exportCSV={true}
											/>
										</Card>
									</Col>
								</Row>
							</Card>
						</Col>
					</Row>
				: ''}
			</BlockUi>
		);
	}
}

export default injectIntl(DeliveryTime);