import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { putAPI } from '../Base/API';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { getPaymentTypes, getReasonOptions } from '../Base/Common/ReferenceDataFunctions';
import { handleNotification } from '../Base/Notification';
export class CreatePaymentModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.createPayment = this.createPayment.bind(this);
        this.state = {
            block: false,
            amount: null,
            type: null,
            notes: null
        };
    }

    updateFilterCombo(combo, evt) {
        if (evt) {
            const { name } = evt;
            this.setState({ [name]: combo && combo.value });
        }
    }

    createPayment() {
        const { payment, addPayment } = this.props;
        const { amount, type, notes } = this.state;

        if (!this.form.current.reportValidity()) {
            return;
        }
        const request = {
            request: {
                amount: amount,
                type: type,
                notes: notes
            }
        };

        this.setState({ block: true });
        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    handleNotification(data, <FormattedMessage id="Payments.SuccessfulCreate" />, <FormattedMessage id="General.Success" />);
                    addPayment(data.response[0]);
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Price/Payment/v1/createExtraPayment/${payment.id}`, request);
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { block, error, amount, type, notes } = this.state;  

        const typeOptions = getPaymentTypes();

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="Payments.CreatePayment" />
                </ModalHeader>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <form ref={this.form}>
                            <Row className="mb-1">
                                <Col className="text-right">
                                    <Button className="btn btn-sm btn-host" onClick={this.createPayment}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col className="col-lg-3 col-4">
                                    <FormattedMessage id="Payments.Amount" />
                                </Col>
                                <Col className="col-lg-9 col-8">
                                    <Input type="number" id="amount" name="amount" onChange={(e) => this.setState({ amount: e.target.value })} value={amount} required min="0.01" step="0.01" />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-lg-3 col-4">
                                    <FormattedMessage id="Payments.Type" />
                                </Col>
                                <Col className="col-lg-9 col-8">
                                    <CustomSelect options={typeOptions} isClearable value={typeOptions.find(opt => opt.value === type)} name="type" onChange={this.updateFilterCombo.bind(this)} placeholder={<FormattedMessage id="Payments.Type" />} />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-lg-3 col-4">
                                    <FormattedMessage id="Payments.Reason" />
                                </Col>
                                <Col className="col-lg-9 col-8">
                                    <Input type="textarea" onChange={(e) => this.setState({ notes: e.target.value })} value={notes} required maxLength="200" />
                                </Col>
                            </Row>
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}