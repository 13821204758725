import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from "../Base/API"
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Common/ErrorAlert";
import 'react-block-ui/style.css';
import moment from 'moment';
import ReservationDetail from "./ReservationDetail"
import { ReservationStatusBadge, CommonHelper } from '../Base/Common/CommonUIComponents'

import { CustomTable } from '../Base/Common/CustomTable'

class ReservationDashboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            modal: false,
            data: {},
            currentDay: moment(),
            error: null,
            blockA: true,
            blockD: true,
            reservationArrivals: [],
            reservationDepartures: [],
            modalData: {},
            filterArrivals: null,
            filterDepartures: null
        }
    }
    
    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        getAPI(result => {
            const { data, error } = result;
           
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockA: false });


                return;
            }
            if (data) {
                this.setState({ reservationArrivals: data.sort((a, b) => new Date(a.arrivalDate)-new Date(b.arrivalDate) ), blockA: false });
            }
        }, '/api/Price/Reservation/v1/Arrivals?startDate=' + this.state.currentDay.format("YYYY-MM-DD") + '&endDate=' + this.state.currentDay.clone().add(30, 'days').format("YYYY-MM-DD"));

        getAPI(result => {
            const { data, error } = result;
           
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockD: false });


                return;
            }
            if (data) {
                this.setState({ reservationDepartures: data.sort((a, b) => new Date(a.arrivalDate) - new Date(b.arrivalDate)), blockD: false });
            }
        }, '/api/Price/Reservation/v1/Departures?startDate=' + this.state.currentDay.format("YYYY-MM-DD") + '&endDate=' + this.state.currentDay.clone().add(30, 'days').format("YYYY-MM-DD"));
    }

    toggleModal(item) {

        if (item != null) {
            this.setState(prevState => ({
                modalData: item, modal: !prevState.modal
            }));
        } else {
            this.setState(prevState => ({
                modalData: {}, modal: !prevState.modal
            }));
        }
    }
    
    getReservations = (reservations, filter, prop) => {
        if (filter == null) {
            return reservations;
        }
        if (filter == 30) {
            return reservations.filter(r => moment(r[prop]) > this.state.currentDay && moment(r[prop]) < this.state.currentDay.clone().add(30, 'days'));
        }
        if (filter == 14) {
            return reservations.filter(r => moment(r[prop]) > this.state.currentDay && moment(r[prop]) < this.state.currentDay.clone().add(14, 'days'));
        }
        else {
            return reservations.filter(r => moment(r[prop]).isSame(this.state.currentDay, 'days'));
        }
    }

    render() {
        const columns = [{
            dataField: 'arrivalDate',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Arrival" }),
            formatter: (cell, row) => cell ? moment(cell).format("YYYY-MM-DD") : '',
            headerStyle: { minWidth: "90px" }
        }, {
            dataField: 'channelInstanceName',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Channel" }),
            sort: true,
            formatter: (cell, row) => `${row.channelInstanceName} ${row.propertyCode ? '(' + row.propertyCode + ')' : ''}`,
            csvFormatter: (cell, row, rowIndex) => `${row.channelInstanceName} ${row.propertyCode ? '(' + row.propertyCode + ')' : ''}`
        }, {
            dataField: 'resIdDate',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.BookingDate" }),
            formatter: (cell, row) => cell ? <div>{moment(cell).format("YYYY-MM-DD")}</div> : '',
            headerStyle: { minWidth: "90px" }
        }, {
            dataField: 'guestName',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Name" }),
            sort: true
        }, {
            dataField: 'guestCount',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Guests" }),
            sort: true
        },  {
            dataField: 'numNights',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Nights" }),
            formatter: (cell, row) => <div >{cell} </div>
        }, {
            dataField: 'status',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Status" }),
            formatter: (cell, row) => <ReservationStatusBadge status={cell} />

        }];

        const columnsDeparture = [{
            dataField: 'departureDate',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Departure" }),
            formatter: (cell, row) => cell ? <div>{moment(cell).format("YYYY-MM-DD")}</div> : '',
            headerStyle: { minWidth: "90px" }
        }, {
            dataField: 'channelInstanceName',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Channel" }),
            sort: true,
            formatter: (cell, row) => `${row.channelInstanceName} ${row.propertyCode ? '(' + row.propertyCode + ')' : ''}`,
            csvFormatter: (cell, row, rowIndex) => `${row.channelInstanceName} ${row.propertyCode ? '(' + row.propertyCode + ')' : ''}`
        }, {
            dataField: 'resIdDate',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.BookingDate" }),
            formatter: (cell, row) => cell ? <div>{moment(cell).format("YYYY-MM-DD")}</div> : '',
            headerStyle: { minWidth: "90px" }
        }, {
            dataField: 'guestName',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Name" }),
            sort: true
        }, {
            dataField: 'guestCount',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Guests" }),
            sort: true
        }, {
            dataField: 'numNights',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Nights" }),
            formatter: (cell, row) => <div >{cell} </div>
        }, {
            dataField: 'status',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Status" }),
            formatter: (cell, row) => <ReservationStatusBadge status={cell} />

        }];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {


                this.setState({ block: true })
                getAPI(result => {
                    const { data, error, isLoading } = result;
                    this.setState({ block: isLoading });
                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });


                        return;
                    }
                    if (data) {

                        //this.setState({ ChannelData: data.data });
                        this.setState({ modal: true, modalData: data.data })
                    }
                }, '/api/Price/Reservation/v1/' + row.id);
                
            }
        };


        return (
            <div className="container-fluid mb-5">  
                {this.state.modal ?
                    <ReservationDetail
                        modal={this.state.modal}
                        data={this.state.modalData}
                        action={this.toggleModal.bind(this)}
                    />
                    : ''}
                <ErrorAlert error={this.state.error} />
                <Row className="mb-4">                    
                    <Col className="mb-2 col-6 col-md-4 col-lg-2 pr-2 pr-md-2">
                        <BlockUi tag="div" cl blocking={this.state.blockA} className="h-100">
                            <Card className="border-left-primary shadow h-100 py-2" style={{ cursor: 'pointer' }} onClick={() => this.setState({ filterArrivals: 1 })}>
                                <CardBody>
                                    <Row className="row no-gutters align-items-center">
                                        <Col className="mr-1">
                                            <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1"><FormattedMessage id="Reservation.arrivalToday" /></p>
                                            <h4 className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                {this.state.reservationArrivals && this.state.reservationArrivals.filter(item => moment(item.arrivalDate).isSame(this.state.currentDay, 'day')).length}
                                            </h4>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </BlockUi>
                    </Col>
                    <Col className="mb-2 col-6 col-md-4 col-lg-2 pl-2 px-md-1">
                        <BlockUi tag="div" blocking={this.state.blockA} className="h-100">
                            <Card className="border-left-primary shadow h-100 py-2" style={{ cursor: 'pointer' }} onClick={() => this.setState({ filterArrivals: 14 })}>
                                <CardBody>
                                    <Row className="row no-gutters align-items-center">
                                        <Col className="mr-1">
                                            <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1"><FormattedMessage id="Reservation.arrivalNext15Days" /></p>
                                            <h4 className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                {this.state.reservationArrivals && this.state.reservationArrivals.filter(item => moment(item.arrivalDate) > this.state.currentDay && moment(item.arrivalDate) < this.state.currentDay.clone().add(14, 'days')).length}
                                            </h4>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </BlockUi>
                    </Col>
                    <Col className="mb-2 col-6 col-md-4 col-lg-2 pr-2 pl-md-2 pr-md-3">
                        <BlockUi tag="div" blocking={this.state.blockA} className="h-100">
                            <Card className="border-left-primary shadow h-100 py-2" style={{ cursor: 'pointer' }} onClick={() => this.setState({ filterArrivals: 30 })}>
                                <CardBody>
                                    <Row className="row no-gutters align-items-center">
                                        <Col className="mr-1">
                                            <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1"><FormattedMessage id="Reservation.arrivalNext30Days" /></p>
                                            <h4 className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                {this.state.reservationArrivals && this.state.reservationArrivals.filter(item => moment(item.arrivalDate) > this.state.currentDay && moment(item.arrivalDate) < this.state.currentDay.clone().add(30, 'days')).length}
                                            </h4>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </BlockUi>
                    </Col>
                    <Col className="mb-2 col-6 col-md-4 col-lg-2 pl-2 pr-md-2 pl-md-3">
                        <BlockUi tag="div" blocking={this.state.blockD} className="h-100">
                            <Card className="border-left-primary shadow h-100 py-2" style={{ cursor: 'pointer' }} onClick={() => this.setState({ filterDepartures: 1 })}>
                                <CardBody>
                                    <Row className="row no-gutters align-items-center">
                                        <Col className="mr-1">
                                            <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                <FormattedMessage id="Reservation.departureToday" />
                                            </p>
                                            <h4 className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                {this.state.reservationDepartures && this.state.reservationDepartures.filter(item => moment(item.departureDate).isSame(this.state.currentDay, 'day')).length}
                                            </h4>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </BlockUi>
                    </Col>
                    <Col className="mb-2 col-6 col-md-4 col-lg-2 pr-2 px-md-1">
                        <BlockUi tag="div" blocking={this.state.blockD} className="h-100">
                            <Card className="border-left-primary shadow h-100 py-2" style={{ cursor: 'pointer' }} onClick={() => this.setState({ filterDepartures: 14 })}>
                                <CardBody>
                                    <Row className="row no-gutters align-items-center">
                                        <Col className="mr-1">
                                            <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1"><FormattedMessage id="Reservation.departureNext15Days" /></p>
                                            <h4 className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                {this.state.reservationDepartures && this.state.reservationDepartures.filter(item => moment(item.departureDate) > this.state.currentDay && moment(item.departureDate) < this.state.currentDay.clone().add(14, 'days')).length}
                                            </h4>
                                         </Col>
                                     </Row>
                                </CardBody>
                            </Card>
                        </BlockUi>
                    </Col>
                    <Col className="mb-2 col-6 col-md-4 col-lg-2 pl-2 pl-md-2">
                        <BlockUi tag="div" blocking={this.state.blockD} className="h-100">
                            <Card className="border-left-primary shadow h-100 py-2" style={{ cursor: 'pointer' }} onClick={() => this.setState({ filterDepartures: 30 })}>
                                <CardBody>
                                    <Row className="row no-gutters align-items-center">
                                        <Col className="mr-1">
                                            <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1"><FormattedMessage id="Reservation.departureNext30Days" /></p>
                                            <h4 className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                                {this.state.reservationDepartures && this.state.reservationDepartures.filter(item => moment(item.departureDate) > this.state.currentDay && moment(item.departureDate) < this.state.currentDay.clone().add(30, 'days')).length}
                                            </h4>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </BlockUi>
                    </Col>                
                </Row>
                <Row>                  
                    <Col className="mb-3 col-12 col-xl-6">
                        <Card className="shadow border-0"> 
                            <CardHeader className="bg-white border-bottom-1">
                                <i className="fas fa-walking  mr-1" />
                                <FormattedMessage id="ReservationDashboard.Arrival" />
                                <CommonHelper help={<FormattedMessage id="ReservationDashboard.CheckinHelp" />} id={'reservationdashboardcheckinhelp'} /> 
                            </CardHeader>
                            <CardBody>
                                <BlockUi tag="div" blocking={this.state.blockA}>
                                    <CustomTable
                                        data={this.getReservations(this.state.reservationArrivals, this.state.filterArrivals, 'arrivalDate')}
                                        columns={columns}
                                        selectRow={selectRow}
                                        totalSize={this.state.reservationArrivals.length}
                                        exportCSV={true}
                                        search={false}
                                    />
                                </BlockUi>
                            </CardBody>
                        </Card>                            
                    </Col>                  
                    <Col className="col-12 col-xl-6">
                        <Card className=" shadow border-0">
                            <CardHeader className="bg-white border-bottom-1">
                                <i className="fas fa-walking fa-flip-horizontal mr-1" />
                                <FormattedMessage id="ReservationDashboard.Departure" />
                                <CommonHelper help={<FormattedMessage id="ReservationDashboard.CheckoutHelp" />} id={'reservationdashboardcheckouthelp'} /> 
                            </CardHeader>
                            <CardBody>
                                <BlockUi tag="div" blocking={this.state.blockA}>
                                    <CustomTable
                                        data={this.getReservations(this.state.reservationDepartures, this.state.filterDepartures, 'departureDate')}
                                        columns={columnsDeparture}
                                        selectRow={selectRow}
                                        exportCSV={true}
                                        search={false}
                                    />
                                </BlockUi>
                            </CardBody>
                        </Card>                            
                    </Col>                   
                </Row>
            </div>
        );
    }
}

export default injectIntl(ReservationDashboard)