import React, { Component } from 'react';
import { Row, Col, Input } from 'reactstrap';
import { getAPI, putAPI } from '../../Base/API';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Notification";
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from "react-intl";
import { DateRangePicker } from 'react-dates';
import moment from 'moment';

class RNTProperties extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            properties: [],
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0
        }
    }


    componentDidMount() {
        this.getProperties();
    }

    getProperties = () => {
        this.setState({ block: true });
        var params = '';
        params += this.state.tipologia ? `&tipologia=${this.state.tipologia}` : '';
        params += this.state.nrRegisto ? `&nrRegisto=${this.state.nrRegisto}` : '';
        params += this.state.nome ? `&nome=${this.state.nome}` : '';
        params += this.state.dataRegistoFrom ? `&DataRegistoFrom=${moment(this.state.dataRegistoFrom).format('YYYY-MM-DD')}` : '';
        params += this.state.dataRegistoTo ? `&DataRegistoTo=${moment(this.state.dataRegistoTo).format('YYYY-MM-DD')}` : '';
        params += this.state.categoria ? `&categoria=${this.state.categoria}` : '';
        params += this.state.distrito ? `&distrito=${this.state.distrito}` : '';
        params += this.state.concelho ? `&concelho=${this.state.concelho}` : '';
        params += this.state.freguesia ? `&freguesia=${this.state.freguesia}` : '';

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response) {
                    this.setState({
                        properties: data.response,
                        totalItems: data.count
                    });
                }

                this.setState({ block: false });
            }
        }, `/api/hotel/v1/tprnt?pageIndex=${this.state.pageIndex}&pageSize=${this.state.pageSize}&contains=true` + params);
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    
    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => this.getProperties());
    }

    refreshData = () => {        
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="RNTProperties.DataRefreshed" />, <FormattedMessage id="General.Success" />)
                }

                this.setState({ block: false }, () => this.getProperties());
            }
        }, `/api/hotel/v1/tprnt`);
    }

    render() {
        const columns = [
            {
                dataField: 'externalId',
                text: this.props.intl.formatMessage({ id: "RNTProperties.NrRegisto" }),
                sort: true
            },
            {
                dataField: 'nome',
                text: this.props.intl.formatMessage({ id: "RNTProperties.Name" }),
                sort: true
            },
            {
                dataField: 'marca',
                text: this.props.intl.formatMessage({ id: "RNTProperties.Brand" }),
                sort: true
            },
            {
                dataField: 'capacidade',
                text: this.props.intl.formatMessage({ id: "RNTProperties.Capacity" }),
                sort: true
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.history.push(`/PropertyDetails/${row.id}`)
            }
        };

        return (
            <StyledCard icon={'fas fa-building'} title={'navbar.RNTProperties'} help={'TODO'} error={this.state.error} block={this.state.block}>
                <Row className="mb-2">
                    <Col>
                        <Input
                            type="text"
                            name="tipologia"
                            min="0"
                            placeholder={this.props.intl.formatMessage({ id: "RNTProperties.Typology" })}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="number"
                            name="nrRegisto"
                            placeholder={this.props.intl.formatMessage({ id: "RNTProperties.NrRegisto" })}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="text"
                            name="nome"
                            placeholder={this.props.intl.formatMessage({ id: "RNTProperties.Name" })}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col>
                        <DateRangePicker
                            startDate={this.state.dataRegistoFrom ? moment(this.state.dataRegistoFrom).locale(moment.locale()) : null}
                            startDateId="your_unique_start_date_id_announcement"
                            isOutsideRange={day => false}
                            endDate={this.state.dataRegistoTo ? moment(this.state.dataRegistoTo).locale(moment.locale()) : null}
                            endDateId="your_unique_end_date_id_announcement"
                            onDatesChange={({ startDate, endDate }) => this.setState({ dataRegistoFrom: startDate, dataRegistoTo: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="col-1">
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input
                            type="text"
                            name="categoria"
                            min="0"
                            placeholder={this.props.intl.formatMessage({ id: "RNTProperties.Category" })}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="text"
                            name="distrito"
                            placeholder={this.props.intl.formatMessage({ id: "RNTProperties.District" })}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="text"
                            name="concelho"
                            placeholder={this.props.intl.formatMessage({ id: "RNTProperties.Concelho" })}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col>
                        <Input
                            type="text"
                            name="freguesia"
                            placeholder={this.props.intl.formatMessage({ id: "RNTProperties.Freguesia" })}
                            onChange={this.handleChange}
                        />
                    </Col>
                    <Col className="text-right col-1">
                        <button className="btn btn-host btn-sm ml-1" onClick={this.getProperties}>
                            <span className="fas fa-search" />
                        </button>
                    </Col>
                </Row>
                <hr className="border-host" />
                <Row className="mb-2">
                    <Col className="text-right">
                        <button className="btn btn-host btn-sm ml-1" onClick={this.refreshData}>
                            <span className="fas fa-sync" />
                        </button>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-12">
                        <CustomTable
                            data={this.state.properties ? this.state.properties : []}
                            columns={columns}
                            showTotal={true}
                            remote={true}
                            search={false}
                            selectRow={selectRow}
                            page={this.state.pageIndex + 1}
                            sizePerPage={this.state.pageSize}
                            totalSize={this.state.totalItems}
                            onTableChange={this.handleTableChange}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }
}

export default injectIntl(RNTProperties);