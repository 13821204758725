import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeader } from 'reactstrap';
import PropertyDescription from '../Common/PropertyDescription';
import { CommonHelper } from '../../../Base/Common/CommonUIComponents';


export class ComplexDescription extends Component {


    render() {      
        const customEditorText = {
            options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'remove', 'history'],
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
        };

        return (
            <div>
                <PropertyDescription
                    selectedEntity={this.props.selectedEntity}
                    entity={'Complexes'}
                    url={this.props.url}
                    customToolbar={customEditorText}
                >
                </PropertyDescription>
            </div>
        );
    }
}