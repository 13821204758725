import React from 'react';
import Feature from 'ol/Feature';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import Map from 'ol/Map';
import { fromLonLat, transform } from 'ol/proj';
import VectorSource from 'ol/source/Vector'
import OSM from 'ol/source/OSM'
import { Icon, Style } from 'ol/style';
import { Point } from 'ol/geom';
import View from 'ol/View';

class MapModal extends React.Component {
    constructor(props) {
        super(props);
        this.getMapDetails = this.getMapDetails.bind(this);
        this.onClick = this.onClick.bind(this);
        this.state = {};
    }
    
    componentDidMount() {
        const { latitude, longitude } = this.props;
        const mapDetails = this.getMapDetails(latitude, longitude);
        mapDetails.map.on('singleclick', this.onClick);
        mapDetails.map.addLayer(mapDetails.layer);

        this.setState({
            layer: mapDetails.layer,
            map: mapDetails.map,
            featuresLayer: this.getFeaturesLayer()
        });
    }

    getFeaturesLayer() {
        const featuresLayer = new VectorLayer({
            source: new VectorSource({
                features: []
            })
        });
        return featuresLayer;
    }
    
    onClick(evt) {
        const coordinates = transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
        const latitude = coordinates[1];
        const longitude = coordinates[0];
        this.props.onClick(latitude, longitude);
        let { layer, map } = this.state;
        const newLayer = this.getNewLayer(latitude, longitude);
        map.removeLayer(layer);
        map.addLayer(newLayer);

        this.setState({ layer: newLayer, map });
    }

    getMapDetails(latitude, longitude) {
        const map = new Map({
            target: this.refs.mapContainer,
            layers: [
                new TileLayer({
                    source: new OSM()
                }),
            ],
            view: new View({
                center: fromLonLat([longitude, latitude]),
                zoom: 17,
            })
        });

        const layer = this.getNewLayer(latitude, longitude);

        const mapDetails = {
            map: map,
            layer: layer
        };

        return mapDetails;
    }

    getNewLayer(latitude, longitude) {
        const layer = new VectorLayer({
            source: new VectorSource({
                features: [
                    new Feature({
                        geometry: new Point(fromLonLat([longitude, latitude]))
                    })
                ]
            })
        });
        layer.setStyle(new Style({
            image: new Icon(({
                src: require("../../../../img/pointer.png")
            }))
        }));
        return layer;
    }

    render() {
        return (
            <div ref="mapContainer" id="canvasContainer" style={{ width: '100%', height: '700px' }}>
            </div>
        );
    }
}

export default MapModal;