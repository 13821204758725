import React, { Component } from 'react'
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';


class CustomSingleDate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: false
        }
    }


    renderMonthElement = (toggleFocused, onDateChange, { month, onYearSelect }, showTodaysButton, showYearOptions, date, futureYears) => {

        let yearOptions = getYearOptions(showYearOptions, date, null, futureYears);

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {yearOptions.length > 0 ?
                    <Row>
                        <Col className="pr-1">
                            {moment(month).locale(this.props.intl.locale).format('MMMM')}
                        </Col>
                        <Col className="pl-1">
                            <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)} style={{ border: '0' }}>
                                {yearOptions.map((year, idx) =>
                                    <option key={idx} value={year}>{year}</option>
                                )}
                            </select>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col>
                            {moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        </Col>
                    </Row>
                }
                {showTodaysButton ?
                    <div style={{ position: 'absolute', bottom: '20px' }}>
                        <Button style={{ backgroundColor: '#165C7D' }} className="border-0" onClick={_ => { toggleFocused(); onDateChange(moment()) }}>
                            <FormattedMessage id="CalendarBar.Today" />
                        </Button>
                    </div>
                : ''}
            </div>
        );
    }

    toggleFocused = () => {
        this.setState(prevState => ({
            focused: !prevState.focused
        }));
    }

    render() {
        const {
            date, id, displayFormat, block,
            required = false, disabled = false,
            numberOfMonths = 1, noBorder = false, small = true,
            showDefaultInputIcon = true, showClearDate = false, 
            showTodaysButton = false, showYearOptions,
            isOutsideRange, onDateChange, readOnly,
            futureYears = 2
        } = this.props;

        return (
            <div className={showTodaysButton ? "CustomSingleDatePicker" : ''}>
                <SingleDatePicker
                    id={id}
                    disabled={disabled}
                    showClearDate={showClearDate}
                    isOutsideRange={isOutsideRange}
                    date={date && moment(date)?.isValid() ? moment(date) : null}
                    onDateChange={(date) => onDateChange(date)}
                    focused={this.state.focused}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    small={small}
                    numberOfMonths={numberOfMonths}
                    required={required}
                    noBorder={noBorder}
                    readOnly={readOnly}
                    block={block}
                    displayFormat={displayFormat}
                    showDefaultInputIcon={showDefaultInputIcon}
                    renderMonthElement={props => this.renderMonthElement(this.toggleFocused, this.props.onDateChange, props, showTodaysButton, showYearOptions, date, futureYears)}
                />
            </div>
        )
    }
}



class CustomDateRange extends Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: false
        }
    }


    renderMonthElement = ({ month, onYearSelect }, showYearOptions, startDate, endDate, futureYears) => {
        let yearOptions = getYearOptions(showYearOptions, startDate, endDate, futureYears);

        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                {yearOptions.length > 0 ?
                    <Row>
                        <Col className="pr-1">
                            {moment(month).locale(this.props.intl.locale).format('MMMM')}
                        </Col>
                        <Col className="pl-1">
                            <select value={month.year()} onChange={(e) => onYearSelect(month, e.target.value)} style={{ border: '0' }}>
                                {yearOptions.map((year, idx) =>
                                    <option key={idx} value={year}>{year}</option>
                                )}
                            </select>
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col>
                            {moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        </Col>
                    </Row>
                }
            </div>
        );
    }

    render() {
        const {
            startDate, endDate, startDateId, endDateId,
            displayFormat, block,
            required = false, disabled = false,
            numberOfMonths = 2, small = true,
            showDefaultInputIcon = true,
            showYearOptions,
            isOutsideRange, onDatesChange, isDayBlocked,
            minimumNights,
            onFocusChange,
            focusedInput,
            futureYears = 2
        } = this.props;

        return (
            <div style={{ whiteSpace: 'nowrap' }}>
                <DateRangePicker
                    required={required}
                    disabled={disabled}
                    startDate={startDate && moment(startDate)?.isValid() ? moment(startDate) : null}
                    startDateId={startDateId}
                    isOutsideRange={d => isOutsideRange && isOutsideRange(d?.endOf('day'))}
                    endDate={endDate && moment(startDate)?.isValid() ? moment(endDate) : null}
                    endDateId={endDateId}
                    onDatesChange={({ startDate, endDate }) => onDatesChange(startDate, endDate)}
                    focusedInput={focusedInput ? focusedInput : this.state.focusedInput}
                    onFocusChange={(focusedInput) => {onFocusChange ? onFocusChange(focusedInput) :this.setState({ focusedInput })}}
                    small={small}
                    numberOfMonths={numberOfMonths}
                    initialVisibleMonth={
                        () => {
                            if (this.state.focusedInput === 'endDate') {
                                return endDate ? moment(endDate) : moment();
                            } else {
                                return startDate ? moment(startDate) : moment();
                            }
                        }
                    }
                    isDayBlocked={d => isDayBlocked && isDayBlocked(d?.endOf('day'))}
                    showDefaultInputIcon={showDefaultInputIcon}
                    minimumNights={minimumNights}
                    renderMonthElement={props => this.renderMonthElement(props, showYearOptions, startDate, endDate, futureYears)}
                    startDatePlaceholderText={this.props.intl.formatMessage({ id: 'RuleConfig.StartDate' })}
                    endDatePlaceholderText={this.props.intl.formatMessage({ id: 'RuleConfig.EndDate' })}
                />
            </div>
        )
    }
}

const getYearOptions = (showYearOptions, startDate, endDate, futureYears) => {
    let yearOptions = [];

    if (showYearOptions && (showYearOptions.pastYears || showYearOptions.futureYears)) {
        const currentYear = moment().year();
        const startDateYear = startDate ? moment(startDate).year() : null;
        const endDateYear = endDate ? moment(endDate).year() : null;

        if (showYearOptions.pastYears) {
            const initialLength = 2;
            yearOptions = yearOptions.concat(getPastYears(initialLength, currentYear));
        }

        yearOptions.push(currentYear);

        if (showYearOptions.futureYears) {
            yearOptions = yearOptions.concat(getFutureYears(futureYears + 1, currentYear));
        }

        if (startDateYear && !yearOptions.some(el => el === startDateYear)) {
            const index = yearOptions.findIndex(el => el > startDateYear);

            yearOptions.splice((index === -1 ? 0 : index), 0, startDateYear);
        }

        if (endDateYear && !yearOptions.some(el => el === endDateYear)) {
            const index = yearOptions.findIndex(el => el > endDateYear);

            yearOptions.splice((index === -1 ? yearOptions.length : index), 0, endDateYear);
        }
    }

    return yearOptions;

    function getPastYears(initialLength, currentYear) {
        return new Array(initialLength).fill(undefined).map(_ => currentYear - initialLength--);
    }

    function getFutureYears(initialLength, currentYear) {
        return new Array(initialLength).fill(undefined).slice(1).map((_, idx) => currentYear + idx + 1);
    }
}

export const CustomSingleDatePicker = injectIntl(CustomSingleDate);
export const CustomDateRangePicker = injectIntl(CustomDateRange);