import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import moment from 'moment';

export class CalendarBoxFlex extends Component {

    render() {
        let classCol = "position-top   ";

        if (this.props.day) {
            if (this.props.day.isoWeekday() === 6 || this.props.day.isoWeekday() === 7) {
                classCol += "bg-light-dark text-secondary"//"bg-secondary text-white"
            }
            else {
                classCol += "bg-white text-secondary"
            }
        }

        return (
            <Col className={classCol} style={{ minWidth: '60px' }}>
                <div className="text-center font_size_xxxs    ">{this.props.day ? moment(this.props.day).locale(this.props.intl.locale).format('ddd') : ""}</div>
                <strong><div className="text-center text-xs font-weight-bold  text-uppercase ">{this.props.day ? this.props.day.date() : ""}</div></strong>
                <div className="text-center font_size_xxxs    ">{this.props.day ? moment(this.props.day).locale(this.props.intl.locale).format('MMM') : ""}</div>
            </Col>
        );
    }
};

export default injectIntl(CalendarBoxFlex);