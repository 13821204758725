import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Modal, ModalHeader, ModalBody, Label, Row, CustomInput, Form } from 'reactstrap';
import { deleteAPI, getAPI, putAPI } from "../../../Base/API";
import CustomSelect from '../../../Base/Common/CustomSelect';
import { handleNotification } from "../../../Base/Notification";
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { ActiveInactiveStatusBadge, CommonHelper, ConfirmActionModal } from '../../../Base/Common/CommonUIComponents';


export class RoomCategoryConfig extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            modal: false,
            deleteModal: false,
            blocks: [],
            selectedBlock: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.url !== this.props.url) {
            this.getBlocks(nextProps.url);
        }
    }

    componentDidMount() {
        this.getBlocks(this.props.url);
    }

    getBlocks = (url) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                this.setState({ blocks: data.response, block: false, error: errorMessage });
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, url);
    }

    saveBlock = (e) => {
        e.preventDefault();

        this.setState({ block: true });

        const body = { ...this.state.selectedBlock }
        body.typeValue = parseInt(body.typeValue);

        putAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ modalError: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, modalError: errorMessage }));
                    return;
                }
                else if (data.response) {
                    handleNotification(data, <FormattedMessage id="RoomCategoryBlocks.BlockSaved" />, <FormattedMessage id="General.Success" />);

                    const { blocks } = this.state;

                    if (body.id) {
                        const index = blocks.findIndex(el => el.id === body.id);

                        if (index !== -1) {
                            blocks[index] = data.response[0];
                        }
                    }
                    else {
                        blocks.push(data.response[0]);
                    }

                    this.setState(({ block: false, modal: false, modalError: errorMessage, blocks }));
                }
                else {
                    this.setState({ block: false, modalError: errorMessage });
                }
            }
        }, `/api/hotel/RoomCategory/v1/${this.props.selectedRoomId}/Blocks`, JSON.stringify({ request: body }));
    }

    deleteBlock = (e) => {
        e.preventDefault();

        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ modalError: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, modalError: errorMessage }));
                    return;
                }
                else if (data.response) {
                    handleNotification(data, <FormattedMessage id="RoomCategoryBlocks.BlockDeleted" />, <FormattedMessage id="General.Success" />);

                    const { blocks } = this.state;

                    const index = blocks.findIndex(el => el.id === this.state.selectedBlock.id);

                    if (index !== -1) {
                        blocks.splice(index, 1);
                    }

                    this.setState(({ block: false, deleteModal: false, modal: false, modalError: errorMessage, blocks }));
                }
                else {
                    this.setState({ block: false, modalError: errorMessage });
                }
            }
        }, `/api/hotel/RoomCategory/v1/Block/${this.state.selectedBlock.id}`);
    }

    handleChange = (e) => {
        const { value, name } = e.target;

        this.setState({
            selectedBlock: {
                ...this.state.selectedBlock,
                [name]: value
            }
        })
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            selectedBlock: {
                ...this.state.selectedBlock,
                [name]: combo ? combo.value : null
            }
        });
    };

    toggleModal = (selectedBlock) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedBlock,
            error: null
        }))
    }

    handleSwitch = (evt) => {
        const { checked, name } = evt.target;

        this.setState({
            selectedBlock: {
                ...this.state.selectedBlock,
                [name]: checked
            }
        })
    }

    render() {
        const { blocks, selectedBlock, block, error } = this.state;

        const blockTypes = [
            {
                label: <FormattedMessage id="RoomCategoryBlocks.Division" />,
                value: 'Division'
            },
            {
                label: <FormattedMessage id="RoomCategoryBlocks.Limit" />,
                value: 'Limit',
            }
        ];

        return (
            <div>
                <BlockUi tag="div" blocking={block}>
                    <ErrorAlert error={error} />
                    <Card className="small border-0 shadow mb-2">
                        <CardHeader className="bg-white border-bottom-1" >
                            <Row>
                                <Col>
                                    <i className="fas fa-sliders-h mr-1" />
                                    <strong>
                                        <FormattedMessage id="RoomCategorySetupDetails.Blocks" />
                                    </strong>
                                </Col>
                                <Col className="text-right">
                                    {global.operationMode === 'None' ? 
                                        <Button className="btn-host btn-sm" onClick={() => this.toggleModal({})}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    : ''}

                                    {/*<CommonHelper help={<FormattedMessage id="RoomCategoryBlocks.Help" />} id={'RoomCategoryConfigHelp'} />*/}
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                {blocks?.map((block, key) =>
                                    <Col sm={6} key={key} className="mb-2 col-12 col-lg-6">
                                        <Card className="border-0 shadow pointer py-2 px-3" onClick={() => global.operationMode === 'None' ? this.toggleModal(block) : {}}>
                                            <Row className="mb-2">
                                                <Col> <b>{block.code} </b> </Col>
                                                <Col className="text-right">
                                                    <ActiveInactiveStatusBadge status={block.active} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col> {block.description} </Col>
                                                {block.type ?
                                                    <Col sm={2} className="text-right">
                                                        <b><FormattedMessage id={`RoomCategoryBlocks.${block.type}`} /> {block.typeValue}</b>
                                                    </Col>
                                                : ''}
                                            </Row>                                            
                                        </Card>
                                    </Col>
                                )}
                            </Row>
                        </CardBody>
                    </Card>


                    <Modal isOpen={this.state.modal} toggle={() => this.toggleModal({})} fade={false} className="modal-xl" style={{ minWidth: '0%' }}>
                        <ModalHeader toggle={() => this.toggleModal({})}>
                            {selectedBlock?.id ? <FormattedMessage id="RoomCategoryBlocks.EditBlock" /> : <FormattedMessage id="RoomCategoryBlocks.AddBlock" />}
                        </ModalHeader>
                        <ModalBody className="modal-xl">
                            <ErrorAlert error={this.state.modalError} />

                            <Form onSubmit={this.saveBlock}>
                                <FormGroup row className="align-items-center">
                                    <Label for="name" className="col-12 col-lg-2 order-2 order-lg-1">
                                        <FormattedMessage id="generic.Status" />
                                    </Label>
                                    <Col className="col-12 col-lg-4 order-3 order-lg-2">
                                        <CustomInput
                                            type="switch"
                                            name="active"
                                            id="active"
                                            onChange={this.handleSwitch}
                                            checked={selectedBlock.active || false}
                                        />
                                    </Col>
                                    <Col className="text-right col-12 col-lg-6 order-1 order-lg-3">
                                        {selectedBlock?.id ?
                                            <Button className="btn-host btn-sm mr-2" onClick={() => this.setState({ deleteModal: true })}>
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                            : ''}
                                        <Button className="btn-host btn-sm" type="submit">
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label className="col-12 col-lg-2">
                                        <FormattedMessage id="RoomCategoryBlocks.Code" />
                                    </Label>
                                    <Col className="col-12 col-lg-4">
                                        <Input
                                            type="text"
                                            required
                                            name="code"
                                            placeholder={this.props.intl.formatMessage({ id: "RoomCategoryBlocks.Code" })}
                                            onChange={this.handleChange}
                                            value={selectedBlock.code || ''}
                                            maxLength={20}
                                        />
                                    </Col>
                                    <Label className="col-12 col-lg-2">
                                        <FormattedMessage id="RoomCategoryBlocks.Description" />
                                    </Label>
                                    <Col className="col-12 col-lg-4">
                                        <Input
                                            type="text"
                                            required
                                            name="description"
                                            placeholder={this.props.intl.formatMessage({ id: "RoomCategoryBlocks.Description" })}
                                            onChange={this.handleChange}
                                            value={selectedBlock.description || ''}
                                            maxLength={50}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label className="col-12 col-lg-2">
                                        <FormattedMessage id="RoomCategoryBlocks.Type" />
                                    </Label>
                                    <Col className="col-12 col-lg-4">
                                        <CustomSelect
                                            required
                                            name="type"
                                            options={blockTypes}
                                            placeholder={<FormattedMessage id="RoomCategoryBlocks.Type" />}
                                            onChange={(combo) => this.handleChangeSelect('type', combo)}
                                            value={blockTypes.find(el => el.value === selectedBlock.type) || ''}
                                        />
                                    </Col>
                                    <Label className="col-12 col-lg-2">
                                        <FormattedMessage id="RoomCategoryBlocks.TypeValue" />
                                    </Label>
                                    <Col className="col-12 col-lg-4">
                                        <Input
                                            type="number"
                                            required
                                            name="typeValue"
                                            placeholder={this.props.intl.formatMessage({ id: "RoomCategoryBlocks.TypeValue" })}
                                            onChange={this.handleChange}
                                            value={selectedBlock.typeValue || ''}
                                        />
                                    </Col>
                                </FormGroup>
                            </Form>
                        </ModalBody>
                    </Modal>
                </BlockUi>

                {this.state.deleteModal ?
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={<FormattedMessage id="RoomCategoryBlocks.ConfirmDeleteBlock" values={{ blockCode: this.state.selectedBlock?.code }} />}
                        toggleModal={() => this.setState({ deleteModal: false })}
                        modal={this.state.deleteModal}
                        actionFunction={this.deleteBlock}
                    />
                    : ''}
            </div>
        );
    }
}
export default injectIntl(RoomCategoryConfig)