import React, { Component } from 'react';
import { Col, Progress } from 'reactstrap';
import CustomToolTip from '../../Utils/CustomToolTip';

export class RoomAvailabilityBox extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            id: this.props.id,
            availabilityOrig: this.props.availability,
            availability: this.props.availability
        };
    }
    onTodoChange(value) {
        this.setState({
            availability: value
        });
    }

    render() {
        const { calendar, dayAvailability, day, blockId, isHost } = this.props;

        var classCol = "position-top border-left px-2 ";
        
        let availabilityForBlock0 = dayAvailability.filter(el => el.blockId === blockId);

        if(!calendar){
            classCol += " max-with-14Col ";
        }

        if (day) {
            if (day.isoWeekday() === 6 || day.isoWeekday() === 7) {
                if (this.state.availability !== this.state.availabilityOrig) {
                    classCol += "bg-primary";
                } else {
                    classCol += "bg-light-dark text-secondary";
                }

            }
            else {
                if (this.state.availability !== this.state.availabilityOrig) {
                    classCol += "bg-primary"
                } else {
                    classCol += "bg-light text-secondary";
                }
            }
        }

        let percentage = 100, inventory = 0, freeRooms = 0;
        const color = {};
        const overBookingGradient = 'linear-gradient(35deg, rgba(217,83,79,1) 0%, rgba(0,0,0,1) 5%, rgba(217,83,79,1) 10%, rgba(0,0,0,1) 15%, rgba(217,83,79,1) 20%, rgba(0,0,0,1) 25%, rgba(217,83,79,1) 30%, rgba(0,0,0,1) 35%, rgba(217,83,79,1) 40%, rgba(0,0,0,1) 45%, rgba(217,83,79,1) 50%, rgba(0,0,0,1) 55%, rgba(217,83,79,1) 60%, rgba(0,0,0,1) 65%, rgba(217,83,79,1) 70%, rgba(0,0,0,1) 75%, rgba(217,83,79,1) 80%, rgba(0,0,0,1) 85%, rgba(217,83,79,1) 90%, rgba(0,0,0,1) 95%, rgba(217,83,79,1) 100%)';

        if (availabilityForBlock0 && availabilityForBlock0.length > 0 && availabilityForBlock0[0].freeRooms && isHost) {
            const availability = availabilityForBlock0[0];

            inventory = availability.inventory - availability.outOfInventory - availability.outOfOrder;
            freeRooms = inventory - availability.freeRooms;
            if (availability.inventory > 0) {
                percentage = parseInt((freeRooms / inventory) * 100);
            }
        }
        if (0 <= percentage && percentage < 33) {
            color.color = 'success';
            color.hex = '#5cb85c';
        }
        else if (33 <= percentage && percentage < 66) {
            color.color = 'warning';
            color.hex = '#f0ad4e';
        }
        else if (66 <= percentage && percentage <= 100) {
            color.color = 'danger';
            color.hex = '#d9534f';
        }
        else if (percentage > 100) {
            color.hex = overBookingGradient;
        }

        return (
            <Col className={classCol} style={{ borderBottom: '1px solid #165c7d', minWidth: '60px' }}>
                <div className="text-center font-weight-bold  text-uppercase ">
                    {
                        availabilityForBlock0 && availabilityForBlock0.length > 0 ?
                            <span>
                                <h6 className={`mb-0 ${availabilityForBlock0[0].freeRooms && availabilityForBlock0[0].freeRooms < 0 ? `text-danger` : ``}`}>{availabilityForBlock0[0].freeRooms}</h6>
                                {
                                    isHost ?
                                        <div>
                                            <div id={`availabilityPercentage${availabilityForBlock0[0].id}`}>
                                                {percentage > 100 ?
                                                    <div
                                                        style={{
                                                            background: overBookingGradient,
                                                            width: '100%',
                                                            height: '5px',
                                                            borderRadius: '10px',
                                                        }}
                                                    />
                                                :
                                                    <Progress color={color.color} value={percentage + 1} style={{ maxHeight: '5px' }} />
                                                }
                                            </div>
                                            <CustomToolTip placement="right" target={`availabilityPercentage${availabilityForBlock0[0].id}`}>
                                                {inventory > 0 ?
                                                    <>
                                                        <i className={`far fa-square mr-2`} style={{ background: color.hex }} /> {percentage}%
                                                    </>
                                                :''}
                                                <div className="d-flex align-items-center"> <i className="fas fa-hashtag fa-xs mr-2 pr-1 ml-1" /> {freeRooms}/{inventory} </div>
                                            </CustomToolTip>
                                        </div>
                                        :
                                        <div />
                                }
                            </span>
                            :
                            ''
                    }
                </div>
            </Col>
        );

    }
};