import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import CustomToolTip from '../../../../Utils/CustomToolTip';
import { getAPI } from "../../../Base/API";
import { StyledCard, ActiveInactiveStatusBadge } from "../../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../../Base/Common/CustomTable';
import { handleNotification } from "../../../Base/Notification";


class PMSQueues extends Component {

    state = {
        block: true,
        pmsQueues: [],
    }

    componentDidMount() {
        this.getListeningQueues();
    }

    getListeningQueues = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    this.setState({
                        pmsQueues: data.response
                    })
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, `/heycm/api/ExternalPmsIntegration/Dashboard/v1/GetListeningQueues`);
    }

    restartListners = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            else {
                handleNotification(data, <FormattedMessage id="PMSQueues.ListnersRestarted" />, <FormattedMessage id="General.Success" />);
                this.getListeningQueues();
            }
        }, `/heycm/api/ExternalPmsIntegration/Dashboard/v1/RestartListners`);
    }

    render() {

        const columns = [
            {
                dataField: 'hotelId',
                text: <FormattedMessage id="PMSQueues.HotelId" />,
                sort: true
            },
            {
                dataField: 'name',
                text: <FormattedMessage id="PMSQueues.Name" />,
                sort: true
            },
            {
                dataField: 'active',
                text: <FormattedMessage id="PMSQueues.Status" />,
                sort: true,
                formatter: (cell) => <ActiveInactiveStatusBadge status={cell} />
            }
        ]

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-external-link-alt'} title={'navbar.PMSQueues'} >
                <Row>
                    <Col>
                        <Button className="btn btn-host btn-sm float-right mb-3" onClick={this.restartListners} id="restartQueues">
                            <i className="fas fa-sync-alt" />
                        </Button>

                        <CustomToolTip placement="right" target="restartQueues" >
                            Restart Queues
                        </CustomToolTip >
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomTable
                            data={this.state.pmsQueues ? this.state.pmsQueues : []}
                            columns={columns}
                            showTotal={true}
                            remote={false}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }

};
export default injectIntl(PMSQueues)