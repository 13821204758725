import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import AvailabilityHistory from './AvailabilityHistory';
import RestrictionHistory from './RestrictionHistory';
import { getAPI } from "../../../Base/API";
import PriceHistory from './PriceHistory';
import BlockUi from 'react-block-ui';
import classnames from 'classnames';

class PMSHistoryAdmin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hotelList: [],
            activeTab: '1'
        }
    }

    componentDidMount() {
        this.getHotelList();
    }

    getHotelList = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data && data.data && data.data.length > 0) {
                var combo = [];

                data.data.map((item) =>
                    combo.push({ 'value': item.hotelId, 'label': item.name2 })
                )

                this.setState({ block: false, hotelList: combo });
            }
        }, '/api/hotel/v1?pageSize=2000&pageIndex=0');
    }

    getRates = (hotelId) => {
        this.setState({ block: true });
        let rates = [];

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                data.response.map((item) =>
                    rates.push({ value: item.id, label: `${item.name} ${item.pmsId ? '(Pms Id: ' + item.pmsId  + ')': ''}` })
                )
            }

            this.setState({ block: false });
        }, `/api/Rate/Rate/v1/Info?hotelId=${hotelId}`);

        return rates;
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { hotelList, block } = this.state;

        return (
            <BlockUi tag="div" blocking={block}>
                <Card className="border-0 shadow">
                    <Nav tabs >
                        <NavItem className="text-sm">
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                <FormattedMessage id="PMSHistory.AvailabilityHistory" />
                            </NavLink>
                        </NavItem>
                        <NavItem className="text-sm">
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                <FormattedMessage id="PMSHistory.RestrictionHistory" />
                            </NavLink>
                        </NavItem>
                        <NavItem className="text-sm">
                            <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                <FormattedMessage id="PMSHistory.PriceHistory" />
                            </NavLink>
                        </NavItem>
                    </Nav>

                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <AvailabilityHistory
                                isAdminPage={true}
                                hotelList={hotelList}
                            />
                        </TabPane>

                        <TabPane tabId="2">
                            <RestrictionHistory
                                isAdminPage={true}
                                hotelList={hotelList}
                                getRates={this.getRates}
                            />
                        </TabPane>

                        <TabPane tabId="3">
                            <PriceHistory
                                isAdminPage={true}
                                hotelList={hotelList}
                                getRates={this.getRates}
                            />
                        </TabPane>
                    </TabContent>
                </Card>
            </BlockUi>
        );
    }

};
export default injectIntl(PMSHistoryAdmin);