import React, { Component, createRef } from 'react'
import { getAPI, postAPI } from '../Base/API';
import { ErrorAlert } from '../Common/ErrorAlert';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { Button, Col, CustomInput, Input, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import CustomSelect from '../Base/Common/CustomSelect';
import { KebabMenu, reservationStatus } from '../Base/Common/CommonUIComponents';
import { getCurrencyCode } from '../Base/Common/ReferenceDataFunctions';
import EmailInput from './EmailInput';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../Base/Notification';
import countryList from 'react-select-country-list';
import classnames from 'classnames';
import { CustomTable } from '../Base/Common/CustomTable';
import CustomToolTip from '../../Utils/CustomToolTip';
import { ReservationHist } from "../../components/Reservations/ReservationHist";

class EmailDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            emailDetail: null,
            reservationCurrency: 'EUR',
            resData: null,
            emailTabs: [
                { name: this.props.intl.formatMessage({ id: 'EmailReservation.body' }), type: 'body', key: 'body' }
            ],
            activeTab: 'body',
            emailId: null,
            reservationStatusOptions: [],
            newStatus: null,
            drag: {
                active: false,
                x: null
            },
            emailPreviewWidth: 65,
            contentTabs: [
                { name: this.props.intl.formatMessage({ id: 'EmailReservation.GeneratedData' }), key: 'generatedData' }
            ],
            activeContentTab: 'generatedData',
            readOnlyTab: null,
            trueResData: null, // effective res data
            divergentData: {}, // list of keys of the divergent fields between effective res and extrated data
            keyTranslations: {
                "room": "reservationRoomStay",
                "state": "status",
                "total": "amountAfterTax",
                "dailyPrices": "reservatioRoomRates",
            },
            dataExtracted: false,
            fieldsChanged: {},
            historyModal: false,
            hidePmsData: global.operationMode === 'None' && global.reservationDelivery === 'None'
        };
        
        this.draggerRef = createRef();
    }

    shouldComponentUpdate(nextProps) {
        if (this.props.emailId !== nextProps.emailId) {
            this.setState({
                block: true,
                emailDetail: null,
                activeTab: 'body',
                emailTabs: [
                    {
                        name: this.props.intl.formatMessage({ id: 'EmailReservation.body' }),
                        type: 'body',
                        key: 'body'
                    }
                ],
                contentTabs: [
                    { name: this.props.intl.formatMessage({ id: 'EmailReservation.GeneratedData' }), key: 'generatedData' }
                ],
                activeContentTab: 'generatedData',
                trueResData: null, // effective res data
                divergentData: {},
                readOnlyTab: null
            }, () => this.getEmailDetail(nextProps.emailId));
        }
        return true;
    }

    componentDidMount(){
        if(this.props.emailId)
            this.getEmailDetail(this.props.emailId);
    }

    getEmailDetail = (emailId) => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if(data){
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(err => errorMessage.push({ message: err.message, stack: err.stack, messageType: 'danger' }));
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                else if (data.response && data.response.length > 0) {
                    const emailDetail = data.response[0];

                    this.handleEmailDetailData(emailDetail, emailId);
                    return;
                }
            }
            this.setState({ block: false });
        }, `/api/Price/EmailReservation/EmailReservation/v1/EmailAndAttach?id=${emailId}`);
    }

    extractData = () => {
        this.setState({ block: true }, () => {

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger"
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if(data){
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach(err => errorMessage.push({ message: err.message, stack: err.stack, messageType: 'danger' }));
                        this.setState({ block: false, error: errorMessage });
                        return;
                    }
                    else if(data.response && data.response.length > 0){
                        handleNotification(data, this.props.intl.formatMessage({ id: 'EmailReservation.ExtractingDataSuccess' }), this.props.intl.formatMessage({ id: 'generic.success' }));
                        const emailDetail = data.response[0];
                        
                        this.handleEmailDetailData(emailDetail, this.props.emailId);
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/Price/EmailReservation/EmailReservation/v1/Extractor/GetExtractedData/${this.props.emailId}`);
        });
    }

    handleEmailDetailData = (emailDetail, emailId) => {
        let { emailTabs, activeTab, contentTabs, divergentData, readOnlyTab, activeContentTab, emailPreviewWidth, fieldsChanged } = this.state;

        if(emailDetail?.extractedData){
            emailDetail.extractedData = JSON.parse(emailDetail.extractedData);

            if(!emailDetail.extractedData.resIdDate)
                emailDetail.extractedData.resIdDate = moment(emailDetail?.createdAt).format('YYYY-MM-DD');

            if(emailDetail.extractedData.room){
                emailDetail.extractedData.room.filter(r => r && r.guestList).forEach((r, key) => {
                    r.roomKey = key;
                    r.guestList.forEach(g => {
                        g.id = Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1));
                        g.roomKey = key;
                    });
                    r.guestList.filter(g => g && g.isMainGuest).forEach((g, k) => {
                        if(k > 0)
                            g.isMainGuest = false;
                    });
                });
                emailDetail.extractedData.room = emailDetail.extractedData.room.map(this.roomTreatment);
                emailDetail.extractedData.total = this.calculateTotal(emailDetail.extractedData);
            }
        }

        if(emailDetail.attachments && emailDetail.attachments.length > 0){
            emailDetail.attachments.forEach((a) => {
                const extStartIndex = a.name.lastIndexOf('.');

                emailTabs.push({
                    name: a.name,
                    type: a.contentType,
                    key: a.id,
                    attachBase64: a.attachBase64,
                    file: a.contentType === "text/csv" ? this.transformCSV(atob(a.attachBase64)) : null,
                    ext: extStartIndex > -1 ? a.name.substring(extStartIndex) : null,
                    fileName: extStartIndex > -1 ? a.name.substring(0, extStartIndex) : null
                });
            });

            const pdf = emailTabs.find(e => e.type === "application/pdf");
            if(pdf)
                activeTab = pdf.key;

            const xls = emailTabs.find(e => e.type === "application/vnd.ms-excel");
            if(xls)
                activeTab = xls.key;
            
            const xlsx = emailTabs.find(e => e.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
            if(xlsx)
                activeTab = xlsx.key;
            
            const csv = emailTabs.find(e => e.type === "text/csv");
            if(csv)
                activeTab = csv.key;
        }

        let emailBody = emailDetail.bodyHtml?.replace(/\\"/g, '"');
        
        if(emailTabs.length > 0 && emailTabs.some(e => e.type.includes('image'))){
            const imgs = emailTabs.filter(i => i.type.includes('image') && emailBody.includes(i.name));
            
            imgs.forEach(i => {
                emailTabs = emailTabs.filter((e) => i.name !== e.name);
                let handBreak = 0; // PORTA DOS FUNDOS
                while (emailBody.includes(i.name) || i > 10) {
                    const nameIdx = emailBody.indexOf(i.name);
                    const srcStart = emailBody.substring(nameIdx - 20, nameIdx).indexOf('src="');
                    const from = (nameIdx - 20) + srcStart;
                    const srcEnd = emailBody.substring(from + 5).indexOf('"');
                    const to = (from + 5) + srcEnd;
                    const sub = emailBody.substring(from, to + 1);

                    emailBody = emailBody.replace(sub, `src="data:${i.type};base64, ${i.attachBase64}"`);   
                    handBreak++;
                }
            });
        }

        emailDetail.bodyHtml = emailBody;

        let reservationCurrency = emailDetail?.extractedData?.room && emailDetail.extractedData.room[0]?.currencyCode;

        if(reservationCurrency)
            reservationCurrency = global.hotelCurrency;

        reservationCurrency = reservationCurrency?.toUpperCase();

        const validCurrencies = getCurrencyCode();

        if(!validCurrencies.some(({value}) => value === reservationCurrency))
            reservationCurrency = 'EUR';

        if(emailDetail.reservation){
            emailDetail.reservation = this.effectiveResTreatment(emailDetail.reservation, emailDetail.extractedData);

            if(contentTabs && !contentTabs.some(e => e.key === 'trueResData')){
                contentTabs.push({ name: this.props.intl.formatMessage({ id: 'EmailReservation.TrueResData' }), key: 'trueResData' });
            }

            const compareData = (key, comparable, original) => {
                const value = original[key];
                const type = typeof value;

                if(type === "object" && value !== null){
                    if(!Array.isArray(value))
                        return;
                    
                    const list = value.map((item, secKey) => {
                        const comp = comparable[key];

                        if(!comp || !comp[secKey] || typeof comp[secKey] !== typeof item)
                            return;

                        const composedRep = {};

                        Object.keys(item).forEach((k) => {
                            const result = compareData(k, comp[secKey], item);

                            if(result)
                                composedRep[k] = result;
                        });

                        return Object.keys(composedRep).length ? composedRep : null;
                    }).filter(e => e);

                    return list.length ? list : null;
                }
                else{
                    const effectiveValue = comparable[key];
                    if((effectiveValue || effectiveValue === 0) && effectiveValue !== value){
                        if(moment(effectiveValue).isValid() && moment(value).isValid() && moment(effectiveValue).isSame(moment(value), 'date')){
                            if(!isNaN(effectiveValue) && !isNaN(value)){
                                return true;
                            }
                        }
                        else{
                            return true;
                        }
                    }
                }

                return;
            };

            if(!emailDetail.resIdValue){ // only show divergent data when email is related to a reservation and not a parent
                readOnlyTab = 'trueResData';
                Object.keys(emailDetail.extractedData).forEach(key => {
                    const result = compareData(key, emailDetail.reservation, emailDetail.extractedData);

                    if(result)
                        divergentData[key] = result
                });

                if(Object.keys(divergentData).length > 0){
                    const alteredData = this.updateExtractedData(emailDetail.extractedData, emailDetail.reservation, divergentData);
    
                    if(alteredData && Object.keys(alteredData).length > 0){
                        fieldsChanged = alteredData;
                    }
                }
            }
            else{
                readOnlyTab = 'generatedData';
                activeContentTab = 'trueResData';
            }
        }

        if(!emailDetail?.extractedData && activeTab === "body"){
            emailPreviewWidth = 50;
        }
        
        if(emailDetail && !emailDetail.bodyHtml){
            emailTabs = emailTabs?.filter(e => e.key !== 'body');
        }

        this.setState({
            resData: emailDetail.extractedData,
            deepCopy: JSON.parse(JSON.stringify(emailDetail.extractedData)),
            trueResData: emailDetail.reservation,
            block: false,
            emailDetail,
            divergentData,
            reservationCurrency,
            emailTabs,
            emailId,
            activeTab,
            contentTabs,
            readOnlyTab,
            activeContentTab,
            emailPreviewWidth,
            fieldsChanged,
            error: null
        }, () => {
            if(activeTab === 'body')
                this.loadHTML()
        });
    }

    updateExtractedData = (original, comparable, divergent) => {
        const alteredData = {};
    
        Object.keys(divergent).forEach(key => {
            const value = divergent[key];
            if (typeof value === 'object' && value !== null) {
                if (Array.isArray(value)) {
                    alteredData[key] = [];
                    value.forEach((item, index) => {
                        if (typeof item === 'object' && item !== null) {
                            if (!original[key][index]) {
                                original[key][index] = {};
                            }
                            const alteredSubData = this.updateExtractedData(original[key][index], comparable[key][index], item);
                            if (Object.keys(alteredSubData).length > 0) {
                                alteredData[key][index] = alteredSubData;
                            }
                        }
                    });
                    if (alteredData[key].length === 0) {
                        delete alteredData[key];
                    }
                } else {
                    if (!original[key]) {
                        original[key] = {};
                    }
                    const alteredSubData = this.updateExtractedData(original[key], comparable[key], value);
                    if (Object.keys(alteredSubData).length > 0) {
                        alteredData[key] = alteredSubData;
                    }
                }
            } else {
                if(((original[key] === undefined || original[key] === null) && (comparable[key] !== null && comparable[key] !== undefined)) || (!isNaN(original[key]) && !isNaN(comparable[key]) && original[key] === 0 && comparable[key] !== 0)){
                    original[key] = comparable[key];
                    alteredData[key] = comparable[key];
                }
            }
        });
    
        return alteredData;
    };

    effectiveResTreatment = (res, extractedData) => {
        const effKeyTranslations = {
            "reservationRoomStay": "room",
            "status": "state",
            "reservatioRoomRates": "dailyPrices",
        }
        
        const treatment = (key, obj) => {
            const value = obj[key];
            const type = typeof value;
            const trans = effKeyTranslations[key] ?? key;
            
            if(type === "object" && value !== null){
                if(Array.isArray(value)){
                    obj[trans] = value.map((item) => {
                        Object.keys(item).forEach(i => {
                            item[trans] = treatment(i, item);
                        });
                        return item;
                    });
                }
                else{
                    Object.keys(value).forEach(i => {
                        value[trans] = treatment(i, value);
                    });
                    obj[trans] = value;
                }
            }
            else{
                obj[trans] = value;
            }
        };

        Object.keys(res).forEach(key => {
            treatment(key, res);
        });

        res.total = res.amountAfterTax;
        res.source = extractedData.source;
        res.sender = extractedData.sender;
        res.reservationServices = extractedData.reservationServices;

        res.room.forEach((r, k) => {
            const extractedRoom = extractedData && extractedData.room && extractedData.room[k];

            r.totalNights = moment(r.checkOut).diff(moment(r.checkIn), 'd');
            r.rateCodeId = r.rateId;
            r.guestList = res.profile?.filter(({rph}) => r.guestsRphs && r.guestsRphs.includes(rph));
            
            if(extractedRoom){
                r.contract = extractedRoom.contract;
                r.mealPlan = extractedRoom.mealPlan;
                r.cancellationPolicy = extractedRoom.cancellationPolicy;
                r.rateCode = extractedRoom.rateCode;
                r.roomType = extractedRoom.roomType;
                r.roomServices = extractedRoom.roomServices;
            }
        });

        return res;
    }

    calculateTotal = (resData) => {
        let totalSum = 0;
        let roomSum = 0;
    
        if (resData && resData.room && Array.isArray(resData.room)) {
            totalSum = resData.room.reduce((a, b) => a + (parseFloat(b.amountAfterTax) || 0), 0);
            roomSum = totalSum;
        }

        if (resData && resData.reservationServices && Array.isArray(resData.reservationServices)) {
            resData.reservationServices
                .filter(service => service && !service.isPackage)
                .forEach(service => {
                    totalSum += parseFloat(service.feeAmount) || 0;
                });

            const extraServices = resData.reservationServices.filter(service => service && !service.isPackage).reduce((a, b) => a + parseFloat(b.feeAmount) || 0, 0);
            if (totalSum !== (roomSum + extraServices))
            {
                if (resData && resData.room && resData.room[0])
                {
                    resData.room[0].amountAfterTax += totalSum - roomSum - extraServices;
                    this.calculateDailyPrices(0);
                }
            }
        }
    
        return totalSum;
    }

    transformCSV = (csv) => {
        var allTextLines = csv.split(/\r\n|\n/).filter(t => t);
        var lines = allTextLines.map(data => data.split(/,|;/));
        
        if(!lines)
            return;

        const header = lines[0].map(field => (
            {
                dataField: field,
                text: field,
                sort: true
            })
        );

        const data = lines.slice(1).map(r => (
            Object.assign({}, ...r.map((c, k) => (JSON.parse(`{ "${lines[0][k]}": "${c}" }`)))
        )));

        return ({ data, header });
    }

    roomTreatment = (r) => {
        if(!r.dailyPrices)
            r.dailyPrices = [];

        if(r.checkIn && r.checkOut){
            const checkIn = moment(r.checkIn);
            const checkOut = moment(r.checkOut);

            const counter = checkOut.diff(checkIn, 'd');

            r.totalNights = counter;

            let i = 0;

            r.dailyPrices = r.dailyPrices.filter(({effectiveDate}) =>
                moment(effectiveDate).isBetween(checkIn, checkOut) ||
                moment(effectiveDate).isSame(checkIn, 'd') ||
                moment(effectiveDate).isSame(checkOut, 'd')
            );

            while(counter > i){
                const day = checkIn.clone().add(i, 'd');
                
                if(!r.dailyPrices.some(d => moment(d.effectiveDate).isSame(day, 'd'))){
                    r.dailyPrices.push({
                        effectiveDate: day.format('YYYY-MM-DD'),
                        amountAfterTax: null
                    });
                }
                i++;
            }

            r.dailyPrices = r.dailyPrices.sort((a, b) => moment(a.effectiveDate).isAfter(b.effectiveDate, 'd') ? 1 : -1);
        }
        return r;
    }

    handleReservationChanges = (e) => {
        if(!e || !e?.target)
            return;

        const { value, name } = e.target;
        const { resData, trueResData, activeContentTab } = this.state;

        if(activeContentTab === 'generatedData')
            this.setState({ resData: { ...resData, [name]: value } });
        else
            this.setState({ trueResData: { ...trueResData, [name]: value } });
    }

    handleRoomComboChanges = (e, name, roomKey) => {
        const value = e?.value;
        const { resData, trueResData, activeContentTab } = this.state;
        let { roomCategories, rates, roomCategoriesDump, ratesDump } = this.props;
        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];

        if(name === "roomCategoryId"){
            if(value){
                rates = ratesDump.filter(({roomCategoryId}) => roomCategoryId === value);
                room.rateCodeId = null;
            }
            else
                rates = ratesDump;
        }
        
        if(name === "rateCodeId" && (activeContentTab === 'generatedData' ? !resData.roomCategoryId : !trueResData.roomCategoryId)){
            if(value){
                const catId = ratesDump.find(o => o.value === value)?.value;
                roomCategories = roomCategoriesDump.filter(({roomCategoryId}) => roomCategoryId === catId);
            }
            else
                roomCategories = roomCategoriesDump;
        }

        room[name] = value;

        if(activeContentTab === 'generatedData')
            this.setState({ resData }, () => this.props.setRoomCateogriesOrRates(roomCategories, rates));
        else
            this.setState({ trueResData }, () => this.props.setRoomCateogriesOrRates(roomCategories, rates));
    }

    handleReservationComboChanges = (e, name, roomKey) => {
        const value = e?.value;
        const { resData, trueResData, activeContentTab } = this.state;
        let { roomCategories, rates, roomCategoriesDump, ratesDump } = this.props;
        const room = activeContentTab === 'generatedData' ? resData.room && resData.room[roomKey] : trueResData.room && trueResData.room[roomKey];

        if(name === "roomCategoryId" && room){
            room[name] = value;
            if(value){
                rates = ratesDump.filter(({roomCategoryId}) => roomCategoryId === value);
                room.rateCodeId = null;
            }
            else
                rates = ratesDump;
        }
        
        if(name === "rateCodeId" && (activeContentTab === 'generatedData' ? !resData.roomCategoryId : trueResData.room[roomKey]) && room){
            room[name] = value;
            if(value){
                const catId = ratesDump.find(o => o.value === value)?.value;
                roomCategories = roomCategoriesDump.filter(({roomCategoryId}) => roomCategoryId === catId);
            }
            else
                roomCategories = roomCategoriesDump;
        }

        if(activeContentTab === 'generatedData')
            this.setState({ resData: { ...resData, [name]: value } }, () => this.props.setRoomCateogriesOrRates(roomCategories, rates));
        else
            this.setState({ trueResData: { ...trueResData, [name]: value } }, () => this.props.setRoomCateogriesOrRates(roomCategories, rates));
    }

    handleReservationDatesChanges = (e, name) => {
        if(!e)
            return;

        const { resData, trueResData, activeContentTab } = this.state;
        
        if(activeContentTab === 'generatedData')
            this.setState({ resData: { ...resData, [name]: e.format('YYYY-MM-DD') } });
        else
            this.setState({ trueResData: { ...trueResData, [name]: e.format('YYYY-MM-DD') } });
    }

    handleReservationCurrencyChanges = (e) => {
        if(!e)
            return;

        const { value } = e;
        const { resData, trueResData, activeContentTab } = this.state;
        
        if(activeContentTab){
            resData.room.forEach(e => e.currencyCode = value) ;
            this.setState({ resData, reservationCurrency: value });
        }
        else{
            trueResData.room.forEach(e => e.currencyCode = value) ;
            this.setState({ trueResData, reservationCurrency: value });
        }
    }

    handleRoomDatesChanges = (e, name, roomKey) => {
        if(!e)
            return;
        
        const { resData, trueResData, activeContentTab } = this.state;
        let room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];

        if(!room)
            return;

        room[name] = e.format('YYYY-MM-DD');
        
        room = this.roomTreatment(room);

        this.setState({ resData, trueResData });
    }

    handleRoomChanges = (e, roomKey) => {
        if(!e)
            return;

        const { name, value } = e.target;
        const { resData, trueResData, activeContentTab } = this.state;
        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];

        if(!room)
            return;
        
        if(name === "totalNights"){
            if(!room.checkIn){
                room.checkIn = moment().add(2, 'd');
            }
            room.checkOut = moment(room.checkIn).add(parseInt(value), 'd');

            if(room.dailyPrices.length > parseInt(value)){
                room.dailyPrices.splice(value, 1);
            }
            else{
                room.dailyPrices.push({
                    effectiveDate: moment(room.checkOut).add(-1, 'd').format('YYYY-MM-DD'),
                    amountAfterTax: 0
                });                
            }
            room.amountAfterTax = room.dailyPrices.reduce((a, b) => a + parseFloat(b.amountAfterTax??0), 0);

            if (room && room.roomServices && Array.isArray(room.roomServices)) {
                room.roomServices
                    .filter(service => service && !service.isPackage)
                    .forEach(service => {
                        room.amountAfterTax += parseFloat(service.feeAmount) || 0;
                    });
            }
        }

        room[name] = value;

        if(name === "amountAfterTax"){
            room.dailyPrices.forEach((e) => {
                e.amountAfterTax = parseFloat(value) / room.dailyPrices.length;
            });
            if(activeContentTab === 'generatedData')
                resData.total = this.calculateTotal(resData);
            else
            trueResData.total = this.calculateTotal(trueResData);
        }
        
        this.setState({ resData, trueResData });
    }

    handleGuestChanges = (e, roomKey, guestKey) => {
        if(!e)
            return;
        
        const { name, value } = e.target;
        const { resData, trueResData, activeContentTab } = this.state;
        const rooms = activeContentTab === 'generatedData' ? resData.room : trueResData.room;
        const room = rooms[roomKey];
        const guest = room.guestList[guestKey];

        if(!guest)
            return;

        const teste = rooms.flatMap(({guestList}) => guestList);
        if(name === "isMainGuest"){
            const otherMains = rooms.flatMap(({guestList}) => guestList).filter((guest) => guest && guest.isMainGuest);

            if(otherMains.length > 0){
                otherMains.forEach(m => {
                    m.isMainGuest = false;
                });
            }
        }
        
        guest[name] = value;

        this.setState({ resData, trueResData });
    }

    handleGuestSwitch = (e, name, roomKey, guestKey) => {
        if(!e)
            return;
        
        const { resData, trueResData, activeContentTab } = this.state;

        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];
        const guest = room.guestList[guestKey];

        if(!guest)
            return;

        if(name === "isMainGuest"){
            room.guestList.forEach(g => {
                g[name] = false;
            });

            guest[name] = !guest[name];

            if(!room.guestList.find(g => g.isMainGuest)){
                room.guestList[0] = true;
            }
        }
        else{
            guest[name] = !guest[name];
        }

        this.setState({ resData, trueResData });
    }

    handleGuestBirthdateChange = (e, name, roomKey, guestKey) => {
        if(!e)
            return;
        
        const { resData, trueResData, activeContentTab } = this.state;
        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];
        const guest = room.guestList[guestKey];

        if(!room)
            return;

        guest[name] = e.format('YYYY-MM-DD');

        this.setState({ resData, trueResData });
    }

    handleGuestComboChanges = (e, name, roomKey, guestKey) => {
        const value = e?.value;
        const { resData, trueResData, activeContentTab } = this.state;
        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];
        const guest = room.guestList[guestKey];

        if(!guest)
            return;

        guest[name] = value;

        this.setState({ resData, trueResData });
    }

    calculateDailyPrices = (roomKey) => {
        const { resData, trueResData, activeContentTab } = this.state;
        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];

        if(!room || !room.dailyPrices){
            return;
        }

        room.dailyPrices.forEach(d => {
            d.amountAfterTax = parseFloat(room.amountAfterTax) / room.dailyPrices.length;
        });

        const extra = parseFloat(room.amountAfterTax) - room.dailyPrices.reduce((a,b) => a + b.amountAfterTax, 0);

        if(room.dailyPrices[0]){
            room.dailyPrices[0].amountAfterTax = room.dailyPrices[0].amountAfterTax + extra;
        }

        this.setState({ resData, trueResData });
    }

    handleDPChanges = (e, roomKey, priceKey) => {
        if(!e)
            return;

        const { value } = e.target;
        const { resData, trueResData, activeContentTab } = this.state;
        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];
        const price = room.dailyPrices[priceKey];

        if(!room)
            return;

        price.amountAfterTax = parseFloat(value);
        room.amountAfterTax = room.dailyPrices.reduce((a, b) => a + parseFloat(b.amountAfterTax??0), 0);

        if (room && room.roomServices && Array.isArray(room.roomServices)) {
            room.roomServices
                .filter(service => service && !service.isPackage)
                .forEach(service => {
                    room.amountAfterTax += parseFloat(service.feeAmount) || 0;
                });
        }
        
        if(activeContentTab === 'generatedData')
            resData.total = this.calculateTotal(resData);
        else
        trueResData.total = this.calculateTotal(trueResData);

        this.setState({ resData, trueResData });
    }

    handleOccChanges = (occ, toAdd, roomKey) => {
        const { resData, trueResData, activeContentTab } = this.state;
        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];

        if(!room)
            return;

        room[occ] = parseInt(room[occ]??'0') + (toAdd ? 1 : -1);

        if(room[occ] !== null && room[occ] !== undefined && room[occ] < 0){
            room[occ] = 0;
        }

        this.setState({ resData, trueResData });
    }

    addRoom = () => {
        const { resData, trueResData, activeContentTab } = this.state;

        if(activeContentTab === 'generatedData'){
            if(resData.room)
                resData.room.push({
                        roomKey: resData.room.length,
                        guestList: [],
                        checkIn: resData.room[0] && resData.room[0].checkIn,
                        checkOut: resData.room[0] && resData.room[0].checkOut,
                        totalNights: resData.room[0] && resData.room[0].totalNights,
                        currencyCode: resData.room[0] && resData.room[0].currencyCode,
                        dailyPrices: [],
                        guestList: [{}]
                });
    
            this.setState({ resData });
        }
        else{
            if(trueResData.room)
                trueResData.room.push({
                        roomKey: trueResData.room.length,
                        guestList: [],
                        checkIn: trueResData.room[0] && trueResData.room[0].checkIn,
                        checkOut: trueResData.room[0] && trueResData.room[0].checkOut,
                        totalNights: trueResData.room[0] && trueResData.room[0].totalNights,
                        currencyCode: trueResData.room[0] && trueResData.room[0].currencyCode,
                        dailyPrices: [],
                        guestList: [{}]
                });
    
            this.setState({ trueResData });
        }
    }

    addGuest = (key) => {
        const { resData, trueResData, activeContentTab } = this.state;
        const room = activeContentTab === 'generatedData' ? resData.room[key] : trueResData.room[key];
        
        if(!room.guestList)
            room.guestList = [];

        room.guestList.push({ roomKey: key, id: Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1)) });

        this.setState({ resData, trueResData });
    }

    addRoomService = (roomKey) => {
        const { resData, trueResData, activeContentTab } = this.state;
        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];

        if(room){
            if(!room.roomServices)
                room.roomServices = [];

            room.roomServices.push({ serviceCode: '', servicePricingType: '', guestCount: 0, feeAmount: 0, description: '', isPackage: false });

            this.setState({ resData, trueResData });
        }
    }

    removeRoomService = (roomKey, serviceKey) => {
        const { resData, trueResData, activeContentTab } = this.state;
        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];
        
        if(room){
            if(!room.roomServices)
                room.roomServices = [];

            room.roomServices.splice(serviceKey, 1);

            this.setState({ resData, trueResData });
        }
    }

    handleRoomServiceChange = (e, roomKey, serviceKey) => {
        if(!e)
            return;
        
        const { name, value } = e.target;
        const { resData, trueResData, activeContentTab } = this.state;
        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];
        const service = room.roomServices[serviceKey];

        if(!service)
            return;

        service[name] = value;

        if(name === "feeAmount"){
            room.amountAfterTax = room.dailyPrices.reduce((a, b) => a + parseFloat(b.amountAfterTax??0), 0);

            if (room && room.roomServices && Array.isArray(room.roomServices)) {
                room.roomServices
                    .filter(service => service && !service.isPackage)
                    .forEach(service => {
                        room.amountAfterTax += parseFloat(service.feeAmount) || 0;
                    });
            }

            if(activeContentTab === 'generatedData'){
                resData.total = this.calculateTotal(resData);
            }else{
                trueResData.total = this.calculateTotal(trueResData);
            }
        }

        this.setState({ resData, trueResData });
    }

    handleRoomServiceComboChanges = (e, name, roomKey, serviceKey) => {
        const value = e?.value;
        const { resData, trueResData, activeContentTab } = this.state;
        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];
        const service = room.roomServices[serviceKey];

        if(!service)
            return;

        service[name] = value;

        if(name === "isPackage"){
            room.amountAfterTax = room.dailyPrices ? room.dailyPrices.reduce((a, b) => a + parseFloat(b.amountAfterTax??0), 0) : 0;

            if (room && room.roomServices && Array.isArray(room.roomServices)) {
                room.roomServices
                    .filter(service => service && !service.isPackage)
                    .forEach(service => {
                        room.amountAfterTax += parseFloat(service.feeAmount) || 0;
                    });
            }

            if(activeContentTab === 'generatedData'){
                resData.total = this.calculateTotal(resData);
            }else{
                trueResData.total = this.calculateTotal(trueResData);
            }
        }

        this.setState({ resData, trueResData });
    }

    addReservationService = () => {
        const { resData, trueResData, activeContentTab } = this.state;

        if(activeContentTab === 'generatedData'){
            if(!resData.reservationServices){
                resData.reservationServices = [];
            }
            resData.reservationServices.push({
                serviceCode: '',
                servicePricingType: 'PerStay',
                guestCount: 0,
                feeAmount: 0,
                description: '',
                isPackage: false
            });
    
            this.setState({ resData });
        }
        else{
            if(!trueResData.reservationServices){
                trueResData.reservationServices = [];
            }
            trueResData.reservationServices.push({
                serviceCode: '',
                servicePricingType: 'PerStay',
                guestCount: 0,
                feeAmount: 0,
                description: '',
                isPackage: false
            });
    
            this.setState({ trueResData });
        }
    }

    removeReservationService = (serviceKey) => {
        const { resData, trueResData, activeContentTab } = this.state;
        
        if(activeContentTab === 'generatedData'){
            if(resData.reservationServices){
                resData.reservationServices.splice(serviceKey, 1);
            }   
        }
        else{
            if(trueResData.reservationServices){
                trueResData.reservationServices.splice(serviceKey, 1);
            }   
        }

        this.setState({ resData, trueResData });
    }

    handleReservationServiceChange = (e, serviceKey) => {
        if(!e)
            return;
        
        const { name, value } = e.target;
        const { resData, trueResData, activeContentTab } = this.state;
        if(activeContentTab === 'generatedData'){
            if(resData.reservationServices){
                if(!resData.reservationServices[serviceKey])
                    return;

                resData.reservationServices[serviceKey][name] = value;

                if(name === "feeAmount"){
                    resData.total = this.calculateTotal(resData);
                }
            }
        }
        else{
            if(trueResData.reservationServices){
                if(!trueResData.reservationServices[serviceKey])
                    return;

                trueResData.reservationServices[serviceKey][name] = value;

                if(name === "feeAmount"){
                    trueResData.total = this.calculateTotal(trueResData);
                }
            }
        }

        this.setState({ resData, trueResData });
    }

    handleReservationServiceSwitch = (e, name, serviceKey) => {
        const value = e?.value;
        const { resData, trueResData, activeContentTab } = this.state;
        const services = activeContentTab === 'generatedData' ? resData.reservationServices : trueResData.reservationServices;
        const service = services ? services.reservationServices[serviceKey] : null;
        
        if(!service)
            return;

        service[name] = value;

        this.setState({ resData, trueResData });
    }

    handleReservationServiceComboChanges = (e, name, serviceKey) => {
        const value = e?.value;
        const { resData, trueResData, activeContentTab } = this.state;
        if(activeContentTab === 'generatedData'){
            if(resData.reservationServices){
                if(!resData.reservationServices[serviceKey])
                    return;

                resData.reservationServices[serviceKey][name] = value;

                if(name === "feeAmount" || name === "isPackage"){
                    resData.total = this.calculateTotal(resData);
                }
            }
        }
        else{
            if(trueResData.reservationServices){
                if(!trueResData.reservationServices[serviceKey])
                    return;

                trueResData.reservationServices[serviceKey][name] = value;

                if(name === "feeAmount" || name === "isPackage"){
                    trueResData.total = this.calculateTotal(trueResData);
                }
            }
        }

        this.setState({ resData, trueResData });
    }

    removeRoom = (roomKey) => {
        const { resData, trueResData, activeContentTab } = this.state;

        if(activeContentTab === 'generatedData'){
            if(resData.room){
                resData.room = resData.room.filter(r => r.roomKey !== roomKey);
            }   
        }
        else{
            if(trueResData.room){
                trueResData.room = trueResData.room.filter(r => r.roomKey !== roomKey);
            }   
        }

        this.setState({ resData, trueResData });
    }

    removeGuest = (roomKey, guestkey) => {
        const { resData, trueResData, activeContentTab } = this.state;

        const room = activeContentTab === 'generatedData' ? resData.room[roomKey] : trueResData.room[roomKey];

        if(!room.guestList)
            room.guestList = [];

        room.guestList.splice(guestkey, 1);

        this.setState({ resData, trueResData });
    }

    handleResChanges = (e) => {
        const { name, value } = e.target;
        const { resData, trueResData, activeContentTab } = this.state;

        if(activeContentTab === 'generatedData')
            resData[name] = value;
        else
            trueResData[name] = value;

        this.setState({ resData, trueResData });
    }

    refreshReservation = () => {
        this.setState({
            block: true,
            emailDetail: null,
            activeTab: 'body',
            emailTabs: [
                {
                    name: this.props.intl.formatMessage({ id: 'EmailReservation.body' }),
                    type: 'body',
                    key: 'body'
                }
            ]
        }, () =>
            this.getEmailDetail(this.state.emailId)
        );
    }   

    saveReservation = (e) => {
        e.preventDefault();
        this.setState({ block: true }, () => {
            const { resData, trueResData, activeContentTab, emailDetail } = this.state;
            
            const payload = {... (activeContentTab === 'generatedData' ? resData : trueResData)};
            payload.emailId = emailDetail.id;

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger"
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if(data){
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach(err => errorMessage.push({ message: err.message, stack: err.stack, messageType: 'danger' }));
                        this.setState({ block: false, error: errorMessage });
                        return;
                    }
                    else {
                        this.setState({
                            resData: null,
                            trueResData: null,
                            emailDetail: null,
                            activeTab: 'body',
                            emailTabs: [
                                {
                                    name: this.props.intl.formatMessage({ id: 'EmailReservation.body' }),
                                    type: 'body',
                                    key: 'body'
                                }
                            ],
                            contentTabs: [
                                {
                                    name: this.props.intl.formatMessage({ id: 'EmailReservation.GeneratedData' }),
                                    key: 'generatedData'
                                }
                            ],
                            activeContentTab: 'generatedData',
                            trueResData: null, // effective res data
                            divergentData: {}
                        }, () => {
                            handleNotification(data, (activeContentTab === 'generatedData' ? <FormattedMessage id="EmailReservation.ReservationCreated"/> : <FormattedMessage id="EmailReservation.ReservationUpdated" />), <FormattedMessage id="General.Success" />);
                            this.getEmailDetail(payload.emailId);
                        });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/Price/EmailReservation/EmailReservation/v1/Reservation`, payload);
        });
    }

    toggleTab = (tab) => {
        if(tab === this.state.tab)
            return;

        if(tab === 'body')
            this.loadHTML();
        
        this.setState({ activeTab: tab });
    }

    ignoreEmail = () => {
        if(this.state.emailDetail?.status !== "Ignored")
            this.changeEmailStatus('Ignored');
        else
            this.changeEmailStatus('DataExtracted');
    }

    changeEmailStatus = (status) => {
        this.setState({ block: true }, () => {
            const body = [this.state.emailId]

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger"
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if(data){
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach(err => errorMessage.push({ message: err.message, stack: err.stack, messageType: 'danger' }));
                        this.setState({ block: false, error: errorMessage });
                        return;
                    }
                    else if (data.response && data.response.length > 0) {
                        this.setState({ block: false }, () => {
                            handleNotification(data, <FormattedMessage id="EmailReservation.EmailUpdated" />, <FormattedMessage id="General.Success" />);
                            this.props.clearEmailDetail();
                        });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/Price/EmailReservation/EmailReservation/v1/ChangeEmailStatus?status=${status}`, body);
        });
    }

    handleMouseDown = (e) => {
        e.preventDefault();
        document.addEventListener('mousemove', this.handleMouseMove);
        document.addEventListener('mouseup', this.handleMouseUp);
    }

    handleMouseMove = (e) => {
        if (!this.draggerRef.current) return;

        const container = this.draggerRef.current.parentElement;
        if (!container) return;

        const containerWidth = container.getBoundingClientRect().width;
        const newWidth = ((e.clientX - container.getBoundingClientRect().left) / containerWidth) * 100;
        this.setState({ 
            emailPreviewWidth: Math.max(50, Math.min(75, newWidth)), // Limite entre 50% e 75%
            drag: { active: true }
        });
    }

    handleMouseUp = () => {
        document.removeEventListener('mousemove', this.handleMouseMove);
        document.removeEventListener('mouseup', this.handleMouseUp);
        this.setState({ drag: { active: false } });
    }
    
    loadHTML = () => {
        const iframe = document.createElement('iframe');
        
        const container = document.getElementById("iframeContainer");
        
        container.innerHTML = ''; // Clear
        container.appendChild(iframe); // Append

        iframe.setAttribute("style","height:100%;width:100%;border:0;");
        iframe.contentWindow.document.open();
        iframe.contentWindow.document.write(this.state.emailDetail.bodyHtml);
        iframe.contentWindow.document.close();
    }

    toggleContentTab = (tab) => {
        if(tab === this.state.activeContentTab)
            return;

        this.setState({ activeContentTab: tab });
    }

    copyReservationEffectiveDataToExtracted = (name, roomKey = null, guestKey = null, serviceKey = null, isRoomService = false, isReservationService = false) => {
        if (!name) return;

        let { trueResData, resData } = this.state;
        if ((!trueResData) || (!resData)) return;
    
        if (isReservationService) {
            let service = resData.reservationServices ? resData.reservationServices[serviceKey] : null;
            const serviceResult = trueResData.reservationServices ? trueResData.reservationServices[serviceKey] : [];

            if(!serviceResult){
                return;
            }

            if(!service){
                service = {
                    serviceCode: '',
                    servicePricingType: 'PerStay',
                    guestCount: 0,
                    feeAmount: 0,
                    description: '',
                    isPackage: false
                };
                
                service[name] = serviceResult[name];

                if(!resData.reservationServices){
                    resData.reservationServices = [service];
                }else{
                    resData.reservationServices.push(service);
                }
            }else if (name in service) {
                service[name] = serviceResult[name];
            }
            this.setState({ resData });
            return;
        }
    
        if (roomKey !== null && trueResData.room && resData.room) {
            let room = resData.room[roomKey];
            const roomResult = trueResData.room[roomKey];

            if(!roomResult){
                return;
            }

            if(!room){
                resData.room[roomKey] = {
                    roomKey: roomKey,
                    guestList: [],
                    checkIn: null,
                    checkOut: null,
                    totalNights: 0,
                    currencyCode: null,
                    dailyPrices: []
                };

                room = resData.room[roomKey];
            }
            
            if(isRoomService){
                let service = room.roomServices ? room.roomServices[serviceKey] : null;
                const serviceResult = roomResult.roomServices ? roomResult.roomServices[serviceKey] : [];

                if(!serviceResult){
                    return;
                }

                if(!service){
                    service = {
                        serviceCode: '',
                        servicePricingType: '',
                        guestCount: 0,
                        feeAmount: 0,
                        description: '',
                        isPackage: false
                    };
                    
                    service[name] = serviceResult[name];

                    if(!room.roomServices){
                        room.roomServices = [service];
                    }else{
                        room.roomServices.push(service);
                    }
                }else if (name in service) {
                    service[name] = serviceResult[name];
                }
                this.setState({ resData });
                return;
            }

            if(room.guestList && roomResult.guestList){
                if(guestKey !== null && roomResult.guestList[guestKey]){
                    const guestResult = roomResult.guestList[guestKey];

                    if (guestKey !== null && room.guestList[guestKey]) {
                        const guest = room.guestList[guestKey];
                        if (guest && (name in guest)) {
                            if(name === 'isMainGuest'){
                                const otherMain = room.guestList.find((g) => g.isMainGuest);
                                if(otherMain){
                                    otherMain.isMainGuest = false;
                                }
    
                                if(guest.isChildren){
                                    guest.isChildren = false;
                                }
                            }

                            if(name === 'isChildren' && guest.isMainGuest){
                                guest.isMainGuest = false;
                            }

                            guest[name] = guestResult[name];
                            this.setState({ resData });
                        }
                        return;
                    }else if(guestKey !== null && !room.guestList[guestKey]){
                        const guest = { 
                            roomKey: 0, 
                            id: Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1)),
                            addressLine: null,
                            birthDate: null,
                            cityName: null,
                            countryCode: null,
                            document: null,
                            documentNumber: null,
                            email: null,
                            gender: null,
                            givenName: '',
                            id: 15511,
                            isChildren: false,
                            isMainGuest: false,
                            nationality: null,
                            phoneNumber: null,
                            postalCode: null,
                            roomKey: 0,
                            surname: '' 
                        };

                        if(name === 'isMainGuest'){
                            const otherMain = room.guestList.find((g) => g.isMainGuest);
                            if(otherMain){
                                otherMain.isMainGuest = false;
                            }

                            if(guest.isChildren){
                                guest.isChildren = false;
                            }
                        }

                        if(name === 'isChildren' && guest.isMainGuest){
                            guest.isMainGuest = false;
                        }

                        guest[name] = guestResult[name];
                        room.guestList.push(guest);
                        this.setState({ resData });
                        return;
                    }
                }
            }else{
                if (guestKey !== null && roomResult.guestList[guestKey]){
                    const guestResult = roomResult.guestList[guestKey];
                    const guest = { 
                        roomKey: 0, 
                        id: Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1)),
                        addressLine: null,
                        birthDate: null,
                        cityName: null,
                        countryCode: null,
                        document: null,
                        documentNumber: null,
                        email: null,
                        gender: null,
                        givenName: '',
                        id: 15511,
                        isChildren: false,
                        isMainGuest: false,
                        nationality: null,
                        phoneNumber: null,
                        postalCode: null,
                        roomKey: 0,
                        surname: '' 
                    };
                    guest[name] = guestResult[name];
                    room.guestList.push(guest);
                    this.setState({ resData });
                    return;
                }
            }
            
            if(room && roomResult && name === 'occupation') {
                room['adults'] = roomResult['adults'];
                room['children'] = roomResult['children'];
                room['infants'] = roomResult['infants'];

                this.setState({ resData });
            }else if (room && roomResult && (name in room) && (name in roomResult)) {
                room[name] = roomResult[name];
                this.setState({ resData });
            }
            return;
        }
    
        if(name in resData){
            resData[name] = trueResData[name];
            this.setState({ resData });
        }
    }

    toggleHistoryModal = () => {
        this.setState(prevState => ({ historyModal: !prevState.historyModal }));
    }

    render() {
        const { block, error, emailDetail, resData, reservationCurrency, emailTabs, activeTab, reservationStatusOptions, emailPreviewWidth,
            contentTabs, activeContentTab, divergentData, trueResData, readOnlyTab, fieldsChanged, historyModal, hidePmsData, drag } = this.state;
        const { rates } = this.props;

        return (
            <BlockUi id="EmailDetail" tag="div" blocking={block} style={{ fontSize: '0.9em', height: '100%' }}>
                <ErrorAlert error={error} />
                <div>
                    {emailDetail ?
                        <div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    {emailDetail.name ?
                                        <>
                                            <span style={{ fontWeight: '500' }}>
                                                {emailDetail.name}
                                            </span>
                                            <span className='mx-1'>-</span>
                                        </>
                                    :''}
                                    <div className='text-truncate' style={{ fontWeight: '500' }}>
                                        {emailDetail.from}
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <div className='text-muted text-truncate' style={{ fontSize: '0.8em' }}>
                                        <span>{moment(emailDetail.createdAt).format('dddd, DD MMM YYYY, HH:mm')}</span>
                                        <span className='ml-1'>
                                            (
                                                {moment(emailDetail.createdAt).isSame(moment(), 'd') ?
                                                    <>
                                                        <span className='mr-1'>{moment().diff(moment(emailDetail.createdAt), 'h')}</span>
                                                        <FormattedMessage id="EmailReservation.Hours"/>
                                                    </>
                                                : moment(emailDetail.createdAt).isSame(moment(), 'w') ?
                                                    <>
                                                        <span className='mr-1'>{moment().diff(moment(emailDetail.createdAt), 'd')??'1'}</span>
                                                        <FormattedMessage id="EmailReservation.Days"/>
                                                    </>
                                                : moment(emailDetail.createdAt).isSame(moment(), 'month') ?
                                                    <>
                                                        <span className='mr-1'>{moment().diff(moment(emailDetail.createdAt), 'weeks')??'1'}</span>
                                                        <FormattedMessage id="EmailReservation.Weeks"/>
                                                    </>
                                                : moment(emailDetail.createdAt).isSame(moment(), 'y') ?
                                                    <>
                                                        <span className='mr-1'>{moment().diff(moment(emailDetail.createdAt), 'month')??'1'}</span>
                                                        <FormattedMessage id="EmailReservation.Months"/>
                                                    </>
                                                :
                                                    <>
                                                        <span className='mr-1'>{moment().diff(moment(emailDetail.createdAt), 'year')??'1'}</span>
                                                        <FormattedMessage id="EmailReservation.Years"/>
                                                    </>
                                                }
                                                <span className='ml-1'>
                                                    <FormattedMessage id="EmailReservation.Ago"/>
                                                </span>
                                            )
                                        </span>
                                    </div>
                                    <div className='ml-2'>
                                    {emailDetail && emailDetail.reservation !== null ?
                                        <KebabMenu
                                            extraFields={[
                                                {
                                                    function: this.extractData,
                                                    text: "EmailReservation.ExtractData",
                                                    icon: "fas fa-file-import mr-2"
                                                },
                                                {
                                                    function: this.toggleHistoryModal,
                                                    text: "ReservationDetail.History",
                                                    icon: "fas fa-history mr-2"
                                                },
                                                {
                                                    function: this.ignoreEmail,
                                                    text: emailDetail.status !== "Ignored" ? "EmailReservation.IgnoreEmail" : "EmailReservation.MarkAsUnread",
                                                    icon: "far fa-envelope-open mr-2"
                                                }
                                            ]}
                                        />
                                    :
                                        <KebabMenu
                                            extraFields={[
                                                {
                                                    function: this.extractData,
                                                    text: "EmailReservation.ExtractData",
                                                    icon: "fas fa-file-import mr-2"
                                                },
                                                {
                                                    function: this.ignoreEmail,
                                                    text: emailDetail.status !== "Ignored" ? "EmailReservation.IgnoreEmail" : "EmailReservation.MarkAsUnread",
                                                    icon: "far fa-envelope-open mr-2"
                                                }
                                            ]}
                                        />  
                                    }
                                    </div>
                                </div>
                            </div>
                            <div style={{ fontSize: '0.9em' }}>
                                {emailDetail.subject}
                            </div>
                        </div>
                    :''}
                </div>
                <div className='d-flex align-items-center justify-content-between mt-2'>
                    <div>
                        <Nav tabs className="flex-nowrap border-0" style={{ flexDirection: 'row-reverse' }}>
                            {contentTabs.map((tab, key) =>
                                <NavItem className="cursor-pointer" key={key}>
                                    <NavLink className={classnames({ active: activeContentTab === tab.key })} onClick={_ => this.toggleContentTab(tab.key)}>
                                        <div className='ml-1 text-truncate' style={{ maxWidth: '100px' }}>
                                            {tab.name}
                                        </div>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </div>
                    <div>
                        <Nav tabs className="flex-nowrap border-0" style={{ flexDirection: 'row-reverse' }}>
                            {emailTabs.map((tab, key) =>
                                <NavItem className="cursor-pointer" key={key}>
                                    <NavLink className={classnames({ active: activeTab === tab.key })} onClick={_ => this.toggleTab(tab.key)}>
                                        <div className='d-flex align-items-center'>
                                            <div className='text-muted' id={`tab-${key}`}>
                                                {tab.type === "application/pdf" ?
                                                    <i className="fas fa-file-pdf"/>
                                                : tab.type === "text/csv" ?
                                                    <i className="fas fa-file-csv"/>
                                                : tab.type === "application/vnd.ms-excel" ?
                                                    <i className="fas fa-file-excel"/>
                                                : tab.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ?
                                                    <i className="far fa-file-excel"/>
                                                :
                                                    <i className="far fa-envelope"/>
                                                }
                                            </div>
                                            <div className='ml-1 text-truncate' style={{ maxWidth: '100px' }}>
                                                {tab.name}
                                            </div>
                                        </div>
                                    </NavLink>
                                </NavItem>
                            )}
                        </Nav>
                    </div>
                </div>
                <div>
                    <div>

                    </div>
                    <div>

                    </div>
                </div>
                <hr className='mt-0 mb-2'/>
                <div className="h-100 d-flex align-items-center justify-content-between" ref={this.draggerRef}>
                    <div style={{ width: `${emailPreviewWidth}%`, }} className={`h-100 pr-4 ${drag && drag.active ? 'iframeNotSelectable' : ''}`}>
                        {contentTabs.map((tab, key) => {
                            const disabled = activeContentTab === readOnlyTab;
                            const reservationData = activeContentTab === 'generatedData' ? resData : trueResData;
                            return (
                                tab.key === activeContentTab ?
                                    reservationData ?
                                        <form key={key} onSubmit={this.saveReservation} className='pb-2'>
                                            <div className='d-flex align-items-center justify-content-between flex-wrap' style={{ columnGap: '0.5rem', rowGap: '0.2rem' }}>
                                                <div style={{ maxWidth: '30%' }}>
                                                    <div className='text-muted' style={{ fontSize: '0.9em' }}>
                                                        <FormattedMessage id="EmailReservation.Source"/>
                                                    </div>
                                                    <div className='text-truncate'>
                                                        {reservationData.source && reservationData.sender ?
                                                            <>
                                                                <span>{reservationData.source}</span>
                                                                <span className='mx-1'>-</span>
                                                                <span className='text-muted' style={{ fontSize: '0.9em' }}>{reservationData.sender ?? '-'}</span>
                                                            </>
                                                        :'-'}
                                                    </div>
                                                </div>
                                                <div>
                                                    <EmailInput 
                                                        emailId={this.state.emailId} 
                                                        intl={this.props.intl}
                                                        activeContentTab={activeContentTab}
                                                        copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                        value={reservationData.resIdDate}
                                                        disabled={disabled}
                                                        name="resIdDate"
                                                        type="date"
                                                        inputId={`resIdDate-${key}`}
                                                        isRequired={true}
                                                        onChange={this.handleReservationDatesChanges}
                                                        isReadOnly={reservationData.resIdDate ? true : false}
                                                        format='DD MMMM YYYY'
                                                        divengency={divergentData.resIdDate}
                                                        inherited={fieldsChanged.resIdDate}
                                                    />
                                                </div>
                                                <div>
                                                    <EmailInput 
                                                        emailId={this.state.emailId} 
                                                        intl={this.props.intl}
                                                        activeContentTab={activeContentTab}
                                                        copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                        value={reservationData.state}
                                                        disabled={disabled}
                                                        name="state"
                                                        type="combo"
                                                        inputId={`state-${key}`}
                                                        isRequired={true}
                                                        options={reservationStatusOptions && reservationStatusOptions.length > 0 ? reservationStatusOptions : reservationStatus}
                                                        onChange={this.handleReservationComboChanges}
                                                        isReadOnly={reservationData.state ? true : false}
                                                        divengency={divergentData.state}
                                                        inherited={fieldsChanged.state}
                                                    />
                                                </div>
                                                <div>
                                                    <EmailInput 
                                                        emailId={this.state.emailId} 
                                                        intl={this.props.intl}
                                                        activeContentTab={activeContentTab}
                                                        copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                        value={reservationData.resIdValue}
                                                        disabled={disabled}
                                                        name="resIdValue"
                                                        inputId={`resIdValue-${key}`}
                                                        isRequired={true}
                                                        type="text"
                                                        onChange={this.handleReservationChanges}
                                                        isReadOnly={reservationData.resIdValue ? true : false}
                                                        divengency={divergentData.resIdValue}
                                                        inherited={fieldsChanged.resIdValue}
                                                    />
                                                </div>
                                                <div>
                                                    <EmailInput 
                                                        emailId={this.state.emailId} 
                                                        intl={this.props.intl}
                                                        activeContentTab={activeContentTab}
                                                        copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                        value={reservationData.amountComission ?? 0}
                                                        disabled={disabled}
                                                        name="amountComission"
                                                        type="currency"
                                                        isRequired={true}
                                                        inputId={`amountComission-${key}`}
                                                        editCurrency={true}
                                                        currencyValue={reservationCurrency}
                                                        onChangeCurrency={this.handleReservationCurrencyChanges}
                                                        onChange={this.handleReservationChanges}
                                                        isReadOnly={reservationData.amountComission ? true : false}
                                                        currency={reservationCurrency}
                                                        orientation="start"
                                                        divengency={divergentData.amountComission}
                                                        inherited={fieldsChanged.amountComission}
                                                    />
                                                </div>
                                                <div>
                                                    <EmailInput 
                                                        emailId={this.state.emailId} 
                                                        intl={this.props.intl}
                                                        activeContentTab={activeContentTab} 
                                                        copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                        value={reservationData.amountPayed ?? 0}
                                                        disabled={disabled}
                                                        name="amountPayed"
                                                        type="currency"
                                                        isRequired={true}
                                                        inputId={`amountPayed-${key}`}
                                                        editCurrency={true}
                                                        currencyValue={reservationCurrency}
                                                        onChangeCurrency={this.handleReservationCurrencyChanges}
                                                        onChange={this.handleReservationChanges}
                                                        isReadOnly={reservationData.amountPayed ? true : false}
                                                        currency={reservationCurrency}
                                                        orientation="start"
                                                        divengency={divergentData.amountPayed}
                                                        inherited={fieldsChanged.amountPayed}
                                                    />
                                                </div>
                                                <div>
                                                    <EmailInput 
                                                        emailId={this.state.emailId} 
                                                        intl={this.props.intl}
                                                        activeContentTab={activeContentTab} 
                                                        copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                        value={reservationData.total}
                                                        disabled={disabled}
                                                        name="total"
                                                        type="currency"
                                                        isRequired={true}
                                                        inputId={`total-${key}`}
                                                        editCurrency={true}
                                                        currencyValue={reservationCurrency}
                                                        onChangeCurrency={this.handleReservationCurrencyChanges}
                                                        onChange={this.handleReservationChanges}
                                                        isReadOnly={reservationData.total ? true : false}
                                                        currency={reservationCurrency}
                                                        orientation="start"
                                                        divengency={divergentData.total}
                                                        inherited={fieldsChanged.total}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className='mt-2'>
                                                    <div className='py-1 d-flex aling-items-center justify-content-between'>
                                                        <div style={{ visibility: 'hidden' }}>
                                                            <Button className="btn-host btn-sm btn-primary" style={{ fontSize: '0.8em' }}>
                                                                <i className="fas fa-plus"></i>
                                                            </Button>
                                                        </div>
                                                        <div style={{ fontWeight: '500' }} className='d-flex align-items-center'>
                                                            <FormattedMessage id="EmailReservation.Rooms"/>
                                                        </div>
                                                        <div style={{ visibility: disabled ? 'hidden' : '' }}>
                                                            <Button className="btn-host btn-sm btn-primary" onClick={this.addRoom} style={{ fontSize: '0.8em' }}>
                                                                <i className="fas fa-plus"></i>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {reservationData.room?.map((room, key) =>
                                                            <div className='px-3 py-2 mt-2' style={{ borderRadius: '4px', boxShadow: '0px 0px 15px #6f738814', border: '1px solid #e3e3e3' }} key={key}>
                                                                <Row className="mt-2">
                                                                    <Col className="" style={{ fontWeight: '500' }}>
                                                                        <FormattedMessage id="EmailReservation.Room"/>
                                                                        <span className='ml-1'>{key + 1}</span>
                                                                    </Col>
                                                                    <Col className="col-2 text-right">
                                                                        {!disabled ?
                                                                            <Button className="btn-host btn-sm btn-primary" onClick={_ => this.removeRoom(room.roomKey)} style={{ fontSize: '0.8em' }}>
                                                                                <i className="fas fa-trash-alt"/>
                                                                            </Button>
                                                                        :''}
                                                                    </Col>
                                                                </Row>
                                                                <div className='px-2 mt-2'>
                                                                    <div className='d-flex align-items-center justify-content-between'>
                                                                        <div style={{ width: '25%' }}>
                                                                            <div style={{ width: 'fit-content' }}>
                                                                                <EmailInput 
                                                                                    emailId={this.state.emailId} 
                                                                                    intl={this.props.intl}
                                                                                    activeContentTab={activeContentTab}
                                                                                    copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                    value={room.checkIn}
                                                                                    disabled={disabled}
                                                                                    name="checkIn"
                                                                                    inputId={`checkIn-${key}`}
                                                                                    isRequired={true}
                                                                                    type="date"
                                                                                    isOutsideRange={day => room.checkOut && moment(room.checkOut).isBefore(day, 'd')}
                                                                                    onChange={this.handleRoomDatesChanges}
                                                                                    isReadOnly={room.checkIn ? true : false}
                                                                                    roomKey={key}
                                                                                    format='DD MMMM YYYY'
                                                                                    divengency={divergentData.room && divergentData.room[key]?.checkIn}
                                                                                    inherited={fieldsChanged.room && fieldsChanged.room[key]?.checkIn}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ width: '25%' }}>
                                                                            <div style={{ width: 'fit-content' }}>
                                                                                <EmailInput 
                                                                                    emailId={this.state.emailId} 
                                                                                    intl={this.props.intl}
                                                                                    activeContentTab={activeContentTab}
                                                                                    copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                    value={room.checkOut}
                                                                                    disabled={disabled}
                                                                                    name="checkOut"
                                                                                    inputId={`checkOut-${key}`}
                                                                                    type="date"
                                                                                    isRequired={true}
                                                                                    isOutsideRange={day => room.checkIn && moment(room.checkIn).isAfter(day, 'd')}
                                                                                    onChange={this.handleRoomDatesChanges}
                                                                                    isReadOnly={room.checkOut ? true : false}
                                                                                    roomKey={key}
                                                                                    format='DD MMMM YYYY'
                                                                                    divengency={divergentData.room && divergentData.room[key]?.checkOut}
                                                                                    inherited={fieldsChanged.room && fieldsChanged.room[key]?.checkOut}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ width: '25%' }}>
                                                                            <div style={{ width: 'fit-content' }}>
                                                                                <EmailInput 
                                                                                    emailId={this.state.emailId} 
                                                                                    intl={this.props.intl}
                                                                                    activeContentTab={activeContentTab}
                                                                                    copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                    value={room.totalNights}
                                                                                    disabled={disabled}
                                                                                    name="totalNights"
                                                                                    inputId={`totalNights-${key}`}
                                                                                    type="number"
                                                                                    isRequired={true}
                                                                                    onChange={this.handleRoomChanges}
                                                                                    isReadOnly={room.totalNights ? true : false}
                                                                                    min={1}
                                                                                    roomKey={key}
                                                                                    notCurrency={true}
                                                                                    divengency={divergentData.room && divergentData.room[key]?.totalNights}
                                                                                    inherited={fieldsChanged.room && fieldsChanged.room[key]?.totalNights}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ width: '25%' }} className='d-flex align-items-center justify-content-end'>
                                                                            <div style={{ width: 'fit-content' }}>
                                                                                <EmailInput 
                                                                                    emailId={this.state.emailId} 
                                                                                    intl={this.props.intl}
                                                                                    activeContentTab={activeContentTab}
                                                                                    copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                    value={room.amountAfterTax}
                                                                                    disabled={disabled}
                                                                                    name="amountAfterTax"
                                                                                    inputId={`amountAfterTax-${key}`}
                                                                                    isRequired={true}
                                                                                    type="number"
                                                                                    onChange={this.handleRoomChanges}
                                                                                    isReadOnly={room.amountAfterTax ? true : false}
                                                                                    currency={reservationCurrency}
                                                                                    roomKey={key}
                                                                                    orientation="start"
                                                                                    divengency={divergentData.room && divergentData.room[key]?.amountAfterTax}
                                                                                    inherited={fieldsChanged.room && fieldsChanged.room[key]?.amountAfterTax}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='mt-2 d-flex align-items-start justify-content-between flex-wrap'>
                                                                        <div className='w-50 pr-1'>
                                                                            <div className='text-muted' style={{ fontSize: '0.9em' }}>
                                                                                <FormattedMessage id="EmailReservation.Rate"/>
                                                                            </div>
                                                                            <CustomSelect
                                                                                isClearable
                                                                                placeholder={''}
                                                                                isDisabled={disabled}
                                                                                required={true}
                                                                                onChange={e => this.handleRoomComboChanges(e, 'rateCodeId', key)}
                                                                                options={rates}
                                                                                value={rates?.find(op => op.options.find(({value}) => value === room.rateCodeId))?.options.find(({value}) => value === room.rateCodeId) ?? ''}
                                                                            />
                                                                        </div>
                                                                        <div className='w-50 pl-1' style={{ fontSize: '0.9em' }}>
                                                                            <div>
                                                                                {room.roomType ?
                                                                                    <div>
                                                                                        <span className='text-muted'><FormattedMessage id="EmailReservation.RoomType"/></span>
                                                                                        <span className='mx-1'>-</span>
                                                                                        <span>{room.roomType}</span>
                                                                                    </div>
                                                                                :''}
                                                                                {room.contract ?
                                                                                    <div>
                                                                                        <span className='text-muted'><FormattedMessage id="EmailReservation.Contract"/></span>
                                                                                        <span className='mx-1'>-</span>
                                                                                        <span>{room.contract}</span>
                                                                                    </div>
                                                                                :''}
                                                                            </div>
                                                                            <div>
                                                                                {room.rateCode ?
                                                                                    <div>
                                                                                        <span className='text-muted'><FormattedMessage id="EmailReservation.RateCode"/></span>
                                                                                        <span className='mx-1'>-</span>
                                                                                        <span>{room.rateCode}</span>
                                                                                    </div>
                                                                                :''}
                                                                                {room.mealPlan ?
                                                                                    <div>
                                                                                        <span className='text-muted'><FormattedMessage id="EmailReservation.MealPlan"/></span>
                                                                                        <span className='mx-1'>-</span>
                                                                                        <span>{room.mealPlan}</span>
                                                                                    </div>
                                                                                :''}
                                                                                {room.cancellationPolicy ?
                                                                                    <div>
                                                                                        <span className='text-muted'><FormattedMessage id="EmailReservation.CancellationPolicy"/></span>
                                                                                        <span className='mx-1'>-</span>
                                                                                        <span>{room.cancellationPolicy}</span>
                                                                                    </div>
                                                                                :''}
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-50 mt-2 pr-1'>
                                                                            <div style={{ width: 'fit-content' }}>
                                                                                <EmailInput 
                                                                                    emailId={this.state.emailId} 
                                                                                    intl={this.props.intl}
                                                                                    activeContentTab={activeContentTab}
                                                                                    copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                    value={room}
                                                                                    disabled={disabled}
                                                                                    name="occupation"
                                                                                    type="occupation"
                                                                                    isRequired={true}
                                                                                    onChange={this.handleOccChanges}
                                                                                    roomKey={key}
                                                                                    isReadOnly={room.adults ? true : false}
                                                                                    divengency={divergentData.room && (divergentData.room[key]?.adults || divergentData.room[key]?.children)}
                                                                                    inherited={fieldsChanged.room && (fieldsChanged.room[key]?.adults || fieldsChanged.room[key]?.children)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='w-50 mt-2 pl-1'>
                                                                            <EmailInput 
                                                                                emailId={this.state.emailId} 
                                                                                intl={this.props.intl}
                                                                                activeContentTab={activeContentTab}
                                                                                copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                value={room.dailyPrices}
                                                                                disabled={disabled}
                                                                                name="dailyPrices"
                                                                                isRequired={true}
                                                                                type="dailyPrices"
                                                                                roomKey={key}
                                                                                currency={reservationCurrency}
                                                                                calculate={this.calculateDailyPrices}
                                                                                onChange={this.handleDPChanges}
                                                                                isReadOnly={room.dailyPrices && room.dailyPrices.length > 0}
                                                                                format='DD MMM YYYY'
                                                                                divengency={divergentData.room && divergentData.room[key]?.dailyPrices}
                                                                                inherited={fieldsChanged.room && fieldsChanged.room[key]?.dailyPrices}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='my-2'>
                                                                        <div className='py-1 d-flex aling-items-center justify-content-between'>
                                                                            <div style={{ visibility: 'hidden' }}>
                                                                                <Button className="btn-host btn-sm btn-primary" style={{ fontSize: '0.8em' }}>
                                                                                    <i className="fas fa-plus"></i>
                                                                                </Button>
                                                                            </div>
                                                                            <div style={{ fontWeight: '500' }} className='d-flex align-items-center'>
                                                                                <FormattedMessage id="EmailReservation.Guests"/>
                                                                            </div>
                                                                            <div style={{ visibility: disabled ? 'hidden' : '' }}>
                                                                                <Button className="btn-host btn-sm btn-primary" onClick={_ => this.addGuest(key)} style={{ fontSize: '0.8em' }}>
                                                                                    <i className="fas fa-plus"></i>
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        {room.guestList?.map((guest, k) =>
                                                                            <div key={k} className='mt-2 px-3 py-2' style={{ borderRadius: '4px', boxShadow: '0px 0px 15px #6f738814', border: '1px solid #e3e3e3' }}>
                                                                                <Row>
                                                                                    <Col style={{ fontWeight: '500' }}>
                                                                                        <div style={{ width: 'fit-content' }}>
                                                                                            <div>
                                                                                                <FormattedMessage id="EmailReservation.Guest"/> {k + 1}
                                                                                            </div>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='d-flex align-items-center justify-content-between'>
                                                                                                    <div style={{ fontSize: '0.9em', fontWeight: '400' }}>
                                                                                                        <FormattedMessage id="EmailReservation.isMainGuest"/>
                                                                                                    </div>
                                                                                                    <div className='ml-2'>
                                                                                                        <CustomInput
                                                                                                            id={`isMainGuest-${key}-${k}`}
                                                                                                            name={`isMainGuest-${key}-${k}`}
                                                                                                            type="switch"
                                                                                                            disabled={disabled || guest.isChildren || guest.isMainGuest}
                                                                                                            checked={guest.isMainGuest}
                                                                                                            onChange={e => this.handleGuestSwitch(e, "isMainGuest", key, k)}
                                                                                                        />
                                                                                                    </div>
                                                                                                    {activeContentTab !== 'generatedData' && disabled ?
                                                                                                        <div id={`copyExtractedToEffective-isMainGuest-${key}-${k}`} className={`hoverEdit mr-1`} onClick={_ => this.copyReservationEffectiveDataToExtracted('isMainGuest', key, k)} style={{ cursor: 'pointer' }}>
                                                                                                            <i className="fas fa-share"></i>
                                                                                                            <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={`copyExtractedToEffective-isMainGuest-${key}-${k}`} >
                                                                                                                <FormattedMessage id="EmailReservation.GetExtractedField" />
                                                                                                            </CustomToolTip>
                                                                                                        </div>
                                                                                                    :''}
                                                                                                </div>
                                                                                                <div className='d-flex align-items-center justify-content-between ml-2'>
                                                                                                    <div style={{ fontSize: '0.9em', fontWeight: '400' }}>
                                                                                                        <FormattedMessage id="EmailReservation.isChildren"/>
                                                                                                    </div>
                                                                                                    <div className='ml-2'>
                                                                                                        <CustomInput
                                                                                                            id={`isChildren-${key}-${k}`}
                                                                                                            name={`isChildren-${key}-${k}`}
                                                                                                            type="switch"
                                                                                                            disabled={disabled || (guest.isMainGuest && !guest.isChildren)}
                                                                                                            checked={guest.isChildren}
                                                                                                            onChange={e => this.handleGuestSwitch(e, "isChildren", key, k)}
                                                                                                        />
                                                                                                    </div>
                                                                                                    {activeContentTab !== 'generatedData' && disabled ?
                                                                                                        <div id={`copyExtractedToEffective-isChildren-${key}-${k}`} className={`hoverEdit mr-1`} onClick={_ => this.copyReservationEffectiveDataToExtracted('isChildren', key, k)} style={{ cursor: 'pointer' }}>
                                                                                                            <i className="fas fa-share"></i>
                                                                                                            <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} target={`copyExtractedToEffective-isChildren-${key}-${k}`} >
                                                                                                                <FormattedMessage id="EmailReservation.GetExtractedField" />
                                                                                                            </CustomToolTip>
                                                                                                        </div>
                                                                                                    :''}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col className="col-3 text-right">
                                                                                        {!disabled ?
                                                                                            <Button className="btn-host btn-sm btn-primary" style={{ fontSize: '0.8em' }} onClick={_ => this.removeGuest(key, k)}>
                                                                                                <i className="fas fa-trash-alt"/>
                                                                                            </Button>
                                                                                        :''}
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mt-2">
                                                                                    <Col className="col-3">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.givenName}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="givenName"
                                                                                            inputId={`givenName-${key}-${k}`}
                                                                                            type="text"
                                                                                            onChange={this.handleGuestChanges}
                                                                                            isReadOnly={guest.givenName ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.givenName}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.givenName}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-3">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.surname}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="surname"
                                                                                            isRequired={true}
                                                                                            inputId={`surname-${key}-${k}`}
                                                                                            type="text"
                                                                                            onChange={this.handleGuestChanges}
                                                                                            isReadOnly={guest.surname ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.surname}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.surname}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-3">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.email}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="email"
                                                                                            inputId={`email-${key}-${k}`}
                                                                                            type="email"
                                                                                            onChange={this.handleGuestChanges}
                                                                                            isReadOnly={guest.email ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.email}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.email}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-3">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.phoneNumber}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="phoneNumber"
                                                                                            inputId={`phoneNumber-${key}-${k}`}
                                                                                            type="phone"
                                                                                            onChange={this.handleGuestChanges}
                                                                                            isReadOnly={guest.phoneNumber ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.phoneNumber}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.phoneNumber}
                                                                                        />
                                                                                    </Col>

                                                                                    <Col className="col-3 mt-1">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.birthDate}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="birthDate"
                                                                                            inputId={`birthDate-${key}-${k}`}
                                                                                            type="date"
                                                                                            onChange={this.handleGuestBirthdateChange}
                                                                                            isReadOnly={guest.birthDate ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.birthDate}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.birthDate}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-3 mt-1">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.gender}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="gender"
                                                                                            inputId={`gender-${key}-${k}`}
                                                                                            type="combo"
                                                                                            options={[
                                                                                                {
                                                                                                    value: 'Female',
                                                                                                    label: <FormattedMessage id="EmailReservation.Female" />
                                                                                                },
                                                                                                {
                                                                                                    value: 'Male',
                                                                                                    label: <FormattedMessage id="EmailReservation.Male" />
                                                                                                },
                                                                                                {
                                                                                                    value: 'Other',
                                                                                                    label: <FormattedMessage id="EmailReservation.Other" />
                                                                                                }
                                                                                            ]}
                                                                                            onChange={this.handleGuestComboChanges}
                                                                                            isReadOnly={guest.gender ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.gender}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.gender}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-3 mt-1">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab} 
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.nationality}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="nationality"
                                                                                            inputId={`nationality-${key}-${k}`}
                                                                                            type="combo"
                                                                                            options={countryList().getData()}
                                                                                            onChange={this.handleGuestComboChanges}
                                                                                            isReadOnly={guest.nationality ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.nationality}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.nationality}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-3 mt-1">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.document}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="document"
                                                                                            inputId={`document-${key}-${k}`}
                                                                                            type="combo"
                                                                                            options={[
                                                                                                {
                                                                                                    value: 'Passport',
                                                                                                    label: <FormattedMessage id="ManualReservation.Passport" />
                                                                                                },
                                                                                                {
                                                                                                    value: 'IdCart',
                                                                                                    label: <FormattedMessage id="ManualReservation.IdCard" />
                                                                                                },
                                                                                                {
                                                                                                    value: 'FiscalNumber',
                                                                                                    label: <FormattedMessage id="ManualReservation.FiscalNumber" />
                                                                                                }
                                                                                            ]}
                                                                                            onChange={this.handleGuestComboChanges}
                                                                                            isReadOnly={guest.document ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.document}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.document}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-3 mt-1">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.documentNumber}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="documentNumber"
                                                                                            inputId={`documentNumber-${key}-${k}`}
                                                                                            type="text"
                                                                                            onChange={this.handleGuestChanges}
                                                                                            isReadOnly={guest.documentNumber ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.documentNumber}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.documentNumber}
                                                                                        />
                                                                                    </Col>

                                                                                    <Col className="col-3 mt-1">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.addressLine}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="addressLine"
                                                                                            inputId={`addressLine-${key}-${k}`}
                                                                                            type="text"
                                                                                            onChange={this.handleGuestChanges}
                                                                                            isReadOnly={guest.addressLine ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.addressLine}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.addressLine}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-3 mt-1">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.cityName}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="cityName"
                                                                                            inputId={`cityName-${key}-${k}`}
                                                                                            type="text"
                                                                                            onChange={this.handleGuestChanges}
                                                                                            isReadOnly={guest.cityName ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.cityName}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.cityName}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-3 mt-1">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.postalCode}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="postalCode"
                                                                                            inputId={`postalCode-${key}-${k}`}
                                                                                            type="text"
                                                                                            onChange={this.handleGuestChanges}
                                                                                            isReadOnly={guest.postalCode ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.postalCode}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.postalCode}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-3 mt-1">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={guest.countryCode}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            guestKey={k}
                                                                                            name="countryCode"
                                                                                            inputId={`countryCode-${key}-${k}`}
                                                                                            type="combo"
                                                                                            options={countryList().getData()}
                                                                                            onChange={this.handleGuestComboChanges}
                                                                                            isReadOnly={guest.countryCode ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.guestList && divergentData.room[key]?.guestList[k]?.countryCode}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.guestList && fieldsChanged.room[key]?.guestList[k]?.countryCode}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <div className='my-2'>
                                                                        <div className='py-1 d-flex aling-items-center justify-content-between'>
                                                                            <div style={{ visibility: 'hidden' }}>
                                                                                <Button className="btn-host btn-sm btn-primary" style={{ fontSize: '0.8em' }}>
                                                                                    <i className="fas fa-plus"></i>
                                                                                </Button>
                                                                            </div>
                                                                            <div style={{ fontWeight: '500' }} className='d-flex align-items-center'>
                                                                                <FormattedMessage id="EmailReservation.RoomServices"/>
                                                                            </div>
                                                                            <div style={{ visibility: disabled ? 'hidden' : '' }}>
                                                                                <Button className="btn-host btn-sm btn-primary" onClick={_ => this.addRoomService(key)} style={{ fontSize: '0.8em' }}>
                                                                                    <i className="fas fa-plus"></i>
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                        {room.roomServices?.map((service, k) =>
                                                                            <div key={k} className='px-3 py-2 mt-2' style={{ borderRadius: '4px', boxShadow: '0px 0px 15px #6f738814', border: '1px solid #e3e3e3' }}>
                                                                                <Row>
                                                                                    <Col style={{ fontWeight: '500' }}>
                                                                                        <div style={{ width: 'fit-content' }}>
                                                                                            <div>
                                                                                                <FormattedMessage id="EmailReservation.RoomService"/> {k + 1}
                                                                                            </div>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col className="col-3 text-right">
                                                                                        {!disabled ?
                                                                                            <Button className="btn-host btn-sm btn-primary" style={{ fontSize: '0.8em' }} onClick={_ => this.removeRoomService(key, k)}>
                                                                                                <i className="fas fa-trash-alt"/>
                                                                                            </Button>
                                                                                        :''}
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row className="mt-2">
                                                                                    <Col className="col-4">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab} 
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            isRoomService={true}
                                                                                            value={service.serviceCode}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            serviceKey={k}
                                                                                            name="serviceCode"
                                                                                            inputId={`serviceCode-${key}-${k}`}
                                                                                            type="text"
                                                                                            onChange={this.handleRoomServiceChange}
                                                                                            isReadOnly={service.serviceCode ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.roomServices && divergentData.room[key]?.roomServices[k]?.serviceCode}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.roomServices && fieldsChanged.room[key]?.roomServices[k]?.serviceCode}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-4">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab} 
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            isRoomService={true}
                                                                                            value={service.description}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            serviceKey={k}
                                                                                            name="description"
                                                                                            inputId={`description-${key}-${k}`}
                                                                                            type="text"
                                                                                            onChange={this.handleRoomServiceChange}
                                                                                            isReadOnly={service.description ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.roomServices && divergentData.room[key]?.roomServices[k]?.description}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.roomServices && fieldsChanged.room[key]?.roomServices[k]?.description}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className='col-4'>
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab} 
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            isRoomService={true}
                                                                                            value={service.guestCount > 0 ? service.guestCount : 1}
                                                                                            disabled={disabled}
                                                                                            name="guestCount"
                                                                                            inputId={`guestCount-${key}`}
                                                                                            serviceKey={k}
                                                                                            type="number"
                                                                                            isRequired={true}
                                                                                            onChange={this.handleRoomServiceChange}
                                                                                            isReadOnly={service.guestCount ? true : false}
                                                                                            min={1}
                                                                                            roomKey={key}
                                                                                            notCurrency={true}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.roomServices && divergentData.room[key]?.roomServices[k]?.guestCount}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.roomServices && fieldsChanged.room[key]?.roomServices[k]?.guestCount}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-4 mt-1">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab}
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            value={service.isPackage}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            serviceKey={k}
                                                                                            name="isPackage"
                                                                                            inputId={`isPackage-${key}-${k}`}
                                                                                            type="combo"
                                                                                            isRoomService={true}
                                                                                            options={[
                                                                                                {
                                                                                                    value: true,
                                                                                                    label: <FormattedMessage id="generic.yes" />
                                                                                                },
                                                                                                {
                                                                                                    value: false,
                                                                                                    label: <FormattedMessage id="generic.no" />
                                                                                                }
                                                                                            ]}
                                                                                            onChange={this.handleRoomServiceComboChanges}
                                                                                            isReadOnly={service.isPackage ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.roomServices && divergentData.room[key]?.roomServices[k]?.isPackage}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.roomServices && fieldsChanged.room[key]?.roomServices[k]?.isPackage}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="col-4 mt-1">
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab} 
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            isRoomService={true}
                                                                                            value={service.servicePricingType}
                                                                                            disabled={disabled}
                                                                                            roomKey={key}
                                                                                            serviceKey={k}
                                                                                            name="servicePricingType"
                                                                                            inputId={`servicePricingType-${key}-${k}`}
                                                                                            type="combo"
                                                                                            options={[
                                                                                                {
                                                                                                    value: 'PerRoom',
                                                                                                    label: <FormattedMessage id="EmailReservation.PerRoom" />
                                                                                                },
                                                                                                {
                                                                                                    value: 'PerRoomPerNigth',
                                                                                                    label: <FormattedMessage id="EmailReservation.PerRoomPerNigth" />
                                                                                                },
                                                                                                {
                                                                                                    value: 'PerPerson',
                                                                                                    label: <FormattedMessage id="EmailReservation.PerPerson" />
                                                                                                },
                                                                                                {
                                                                                                    value: 'PerPersonPerNight',
                                                                                                    label: <FormattedMessage id="EmailReservation.PerPersonPerNight" />
                                                                                                }
                                                                                            ]}
                                                                                            onChange={this.handleRoomServiceComboChanges}
                                                                                            isReadOnly={service.servicePricingType ? true : false}
                                                                                            divengency={divergentData.room && divergentData.room[key]?.roomServices && divergentData.room[key]?.roomServices[k]?.servicePricingType}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.roomServices && fieldsChanged.room[key]?.roomServices[k]?.servicePricingType}
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className='col-4 mt-1'>
                                                                                        <EmailInput 
                                                                                            emailId={this.state.emailId} 
                                                                                            intl={this.props.intl}
                                                                                            activeContentTab={activeContentTab} 
                                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                                            isRoomService={true}
                                                                                            value={service.feeAmount ?? 0}
                                                                                            disabled={disabled}
                                                                                            name="feeAmount"
                                                                                            type="currency"
                                                                                            inputId={`feeAmount-${key}`}
                                                                                            editCurrency={true}
                                                                                            currencyValue={reservationCurrency}
                                                                                            onChangeCurrency={this.handleReservationCurrencyChanges}
                                                                                            onChange={this.handleRoomServiceChange}
                                                                                            roomKey={key}
                                                                                            serviceKey={k}
                                                                                            isReadOnly={service.feeAmount ? true : false}
                                                                                            currency={reservationCurrency}
                                                                                            orientation="start"
                                                                                            divengency={divergentData.room && divergentData.room[key]?.roomServices && divergentData.room[key]?.roomServices[k]?.feeAmount}
                                                                                            inherited={fieldsChanged.room && fieldsChanged.room[key]?.roomServices && fieldsChanged.room[key]?.roomServices[k]?.feeAmount}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className='mt-2'>
                                                    <div className='my-2'>
                                                        <div className='py-1 d-flex aling-items-center justify-content-between'>
                                                            <div style={{ visibility: 'hidden' }}>
                                                                <Button className="btn-host btn-sm btn-primary" style={{ fontSize: '0.8em' }}>
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                            </div>
                                                            <div style={{ fontWeight: '500' }} className='d-flex align-items-center'>
                                                                <FormattedMessage id="EmailReservation.ReservationServices"/>
                                                            </div>
                                                            <div style={{ visibility: disabled ? 'hidden' : '' }}>
                                                                <Button className="btn-host btn-sm btn-primary" onClick={_ => this.addReservationService()} style={{ fontSize: '0.8em' }}>
                                                                    <i className="fas fa-plus"></i>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        {reservationData.reservationServices?.map((service, k) =>
                                                            <div key={k} className='px-3 py-2 mt-2' style={{ borderRadius: '4px', boxShadow: '0px 0px 15px #6f738814', border: '1px solid #e3e3e3' }}>
                                                                <Row>
                                                                    <Col style={{ fontWeight: '500' }}>
                                                                        <div style={{ width: 'fit-content' }}>
                                                                            <div>
                                                                                <FormattedMessage id="EmailReservation.ReservationService"/> {k + 1}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    <Col className="col-3 text-right">
                                                                        {!disabled ?
                                                                            <Button className="btn-host btn-sm btn-primary" style={{ fontSize: '0.8em' }} onClick={_ => this.removeReservationService(k)}>
                                                                                <i className="fas fa-trash-alt"/>
                                                                            </Button>
                                                                        :''}
                                                                    </Col>
                                                                </Row>
                                                                <Row className="mt-2">
                                                                    <Col className="col-4">
                                                                        <EmailInput 
                                                                            emailId={this.state.emailId} 
                                                                            intl={this.props.intl}
                                                                            activeContentTab={activeContentTab} 
                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                            value={service.serviceCode}
                                                                            disabled={disabled}
                                                                            serviceKey={k}
                                                                            name="serviceCode"
                                                                            inputId={`serviceCode-${k}`}
                                                                            type="text"
                                                                            onChange={this.handleReservationServiceChange}
                                                                            isReadOnly={service.serviceCode ? true : false}
                                                                            divengency={divergentData && divergentData.roomServices && divergentData.roomServices[k]?.serviceCode}
                                                                            inherited={fieldsChanged && fieldsChanged.roomServices && fieldsChanged.roomServices[k]?.serviceCode}
                                                                            isReservationService={true}
                                                                        />
                                                                    </Col>
                                                                    <Col className="col-4">
                                                                        <EmailInput 
                                                                            emailId={this.state.emailId} 
                                                                            intl={this.props.intl}
                                                                            activeContentTab={activeContentTab} 
                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                            value={service.description}
                                                                            disabled={disabled}
                                                                            serviceKey={k}
                                                                            name="description"
                                                                            inputId={`description-${k}`}
                                                                            type="text"
                                                                            onChange={this.handleReservationServiceChange}
                                                                            isReadOnly={service.description ? true : false}
                                                                            divengency={divergentData && divergentData.roomServices && divergentData.roomServices[k]?.description}
                                                                            inherited={fieldsChanged && fieldsChanged.roomServices && fieldsChanged.roomServices[k]?.description}
                                                                            isReservationService={true}
                                                                        />
                                                                    </Col>
                                                                    <Col className='col-4'>
                                                                        <EmailInput 
                                                                            emailId={this.state.emailId} 
                                                                            intl={this.props.intl}
                                                                            activeContentTab={activeContentTab} 
                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                            value={service.guestCount > 0 ? service.guestCount : 1}
                                                                            disabled={disabled}
                                                                            name="guestCount"
                                                                            inputId={`guestCount-${k}`}
                                                                            serviceKey={k}
                                                                            type="number"
                                                                            isRequired={true}
                                                                            onChange={this.handleReservationServiceChange}
                                                                            isReadOnly={service.guestCount ? true : false}
                                                                            min={1}
                                                                            notCurrency={true}
                                                                            divengency={divergentData && divergentData.roomServices && divergentData.roomServices[k]?.guestCount}
                                                                            inherited={fieldsChanged && fieldsChanged.roomServices && fieldsChanged.roomServices[k]?.guestCount}
                                                                            isReservationService={true}
                                                                        />
                                                                    </Col>
                                                                    <Col className="col-4 mt-1">
                                                                        <EmailInput 
                                                                            emailId={this.state.emailId} 
                                                                            intl={this.props.intl}
                                                                            activeContentTab={activeContentTab}
                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                            value={service.isPackage}
                                                                            disabled={disabled}
                                                                            serviceKey={k}
                                                                            name="isPackage"
                                                                            inputId={`isPackage-${k}`}
                                                                            type="combo"
                                                                            isReservationService={true}
                                                                            options={[
                                                                                {
                                                                                    value: true,
                                                                                    label: <FormattedMessage id="generic.yes" />
                                                                                },
                                                                                {
                                                                                    value: false,
                                                                                    label: <FormattedMessage id="generic.no" />
                                                                                }
                                                                            ]}
                                                                            onChange={this.handleReservationServiceComboChanges}
                                                                            isReadOnly={service.isPackage ? true : false}
                                                                            divengency={divergentData && divergentData.roomServices && divergentData.roomServices[k]?.isPackage}
                                                                            inherited={fieldsChanged && fieldsChanged.roomServices && fieldsChanged.roomServices[k]?.isPackage}
                                                                        />
                                                                    </Col>
                                                                    <Col className='col-4'></Col>
                                                                    <Col className='col-4 text-right mt-1'>
                                                                        <EmailInput 
                                                                            emailId={this.state.emailId} 
                                                                            intl={this.props.intl}
                                                                            activeContentTab={activeContentTab} 
                                                                            copyExtractedDatatoEffective={this.copyReservationEffectiveDataToExtracted}
                                                                            value={service.feeAmount ?? 0}
                                                                            disabled={disabled}
                                                                            name="feeAmount"
                                                                            type="currency"
                                                                            inputId={`feeAmount-${k}`}
                                                                            editCurrency={true}
                                                                            currencyValue={reservationCurrency}
                                                                            onChangeCurrency={this.handleReservationCurrencyChanges}
                                                                            onChange={this.handleReservationServiceChange}
                                                                            serviceKey={k}
                                                                            isReadOnly={reservationData.feeAmount ? true : false}
                                                                            currency={reservationCurrency}
                                                                            orientation="start"
                                                                            divengency={divergentData && divergentData.roomServices && divergentData.roomServices[k]?.feeAmount}
                                                                            inherited={fieldsChanged && fieldsChanged.roomServices && fieldsChanged.roomServices[k]?.feeAmount}
                                                                            isReservationService={true}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='py-2 d-flex aling-items-center justify-content-between'>
                                                        <div className='w-100 text-center' style={{ fontWeight: '500' }}>
                                                            <FormattedMessage id="EmailReservation.Comment"/>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <Input
                                                            type="textarea"
                                                            name="comment"
                                                            disabled={disabled}
                                                            value={reservationData.comment ?? ''}
                                                            onChange={this.handleResChanges}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {!disabled ?
                                                <div className='w-100 text-right my-1 py-2' style={{ position: 'sticky', width: '100%', bottom: '0' }}>
                                                    <Button className="btn-host btn-sm btn-primary mr-2" type="button" onClick={this.refreshReservation}>
                                                        <i className="fas fa-sync-alt"/>
                                                    </Button>
                                                    <Button className="btn-host btn-sm btn-primary" type="submit">
                                                        {activeContentTab === 'generatedData' ?
                                                            <FormattedMessage id="EmailReservation.CreateReservation"/>
                                                        :
                                                            <FormattedMessage id="EmailReservation.UpdateReservation"/>
                                                        }
                                                    </Button>
                                                </div>
                                            :''}
                                        </form>
                                    :''
                                :''
                            )}
                        )}
                    </div>


                    
                    <div style={{ width: `${100 - emailPreviewWidth}%` }} className="d-flex align-items-center justify-content-between h-100">
                        <div
                            
                            className="dragger d-flex align-items-center justify-content-center"
                            style={{
                                cursor: 'w-resize',
                                width: '1px',
                                height: '100%',
                                backgroundColor: '#e5e5e5',
                                position: 'relative'
                            }}
                            onMouseDown={this.handleMouseDown}
                        >
                            <div className='d-flex align-items-center justify-content-center' style={{ width: '10px', height: '60px', position: 'absolute', background: '#dbdbdb', right: '0', borderRadius: '10px 0 0 10px' }}>
                                <div id="dragger" style={{ background: "#979797", height: "50%", width: "1px" }} />
                            </div>
                        </div>
                        <div className="pl-2 h-100 d-flex justify-content-between flex-column w-100" style={{position: 'relative'}}>
                            {emailDetail ?
                                <div className={`h-100 ${drag && drag.active ? 'iframeNotSelectable' : ''}`}>
                                    <TabContent activeTab={activeTab} className='h-100'>
                                        {emailTabs.map((tab, key) =>
                                            <TabPane key={key} className={'border-0 pt-0 h-100 px-0'} tabId={tab.key}>
                                                <div className='h-100'>
                                                    {tab.type !== "body" && tab.type !== "application/pdf" && tab?.attachBase64 ?
                                                        <div className='d-flex aling-items-center justify-content-between'>
                                                            <div className='text-truncate'>
                                                                {tab.name}
                                                            </div>
                                                            <div>
                                                                <a download={tab.name} href={`data:${tab.type};base64,${tab.attachBase64}`}>
                                                                    <Button className="btn-host btn-sm btn-primary" style={{ fontSize: '0.8em' }} onClick={this.downloadFile}>
                                                                        <i className="fas fa-file-download"/>
                                                                    </Button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    :''}
                                                    <div className='h-100'>
                                                        {tab.type === "body" && emailDetail.bodyHtml ?
                                                            <div id="iframeContainer" className='w-100 h-100'>
                                                            </div>
                                                        : tab.type.indexOf('application/') > -1 && tab.ext === '.pdf' && tab?.attachBase64 ?
                                                            <iframe src={`data:application/pdf;base64,${tab.attachBase64}`} width="100%" height="100%" className='border-0'></iframe>
                                                        : tab.type === "text/csv" && tab?.file ?
                                                            <div>
                                                                <CustomTable
                                                                    hasPagination={false}
                                                                    columns={tab.file.header}
                                                                    data={tab.file.data}
                                                                    sizePerPage={tab.file.data.length}
                                                                    totalSize={tab.file.data.length}
                                                                    shadow={false}
                                                                    showTotal={false}
                                                                    hideSizePerPage={false}
                                                                />
                                                            </div>
                                                        : tab.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" && tab?.attachBase64 ?
                                                            <iframe src={`data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${tab.attachBase64}`} type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" width="100%" height="100%" className='border-0'></iframe>
                                                        : tab.type === "application/vnd.ms-excel" && tab?.attachBase64 ?
                                                            <iframe className='border-0' src={`data:application/vnd.ms-excel;base64,${tab.attachBase64}`} width="100%" height="100%"></iframe>
                                                        :''}
                                                    </div>
                                                </div>
                                            </TabPane>
                                        )}
                                    </TabContent>
                                </div>
                            :''}
                            {drag && drag.active ?
                                <div className='iframeInvisibleDiv' />
                            :''}
                        </div>
                    </div>
                </div>

                {historyModal ?
                    <ReservationHist modal={historyModal} reservation={emailDetail?.reservation} action={this.toggleHistoryModal} hidePmsData={hidePmsData} />
                :''}
            </BlockUi>
        )
    }
}

export default injectIntl(EmailDetail);