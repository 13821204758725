import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { Bar } from 'react-chartjs-2';
import ReactHtmlParser from 'react-html-parser';
import { FormattedMessage, FormattedNumber, FormattedHTMLMessage,injectIntl } from 'react-intl';
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row, Toast, ToastBody, ToastHeader, UncontrolledCollapse } from 'reactstrap';
import { getAPI } from "./Base/API";
import { ActiveInactiveStatusBadge, ReservationStatusBadge, CommonHelper, ReservationCommStatusIcon, reservationStatus, CustomAnnouncement } from "./Base/Common/CommonUIComponents";
import CustomSelect from './Base/Common/CustomSelect';
import { CustomTable } from './Base/Common/CustomTable';
import { getChannelConfig } from "./Base/Common/ReferenceDataFunctions";
import { ErrorAlert } from "./Common/ErrorAlert";
import ReservationDetail from "./Reservations/ReservationDetail"
import { ChannelConf } from './Channel/ChannelConf';
import CustomToolTip from '../Utils/CustomToolTip';

 class Home extends Component {
  static displayName = Home.name;

    

    constructor(props) {
        super(props);

        this.state = {
            data: {},
            currentDay: moment(),
            modal: false,
            error: [],
            block: false,
            blockArrival: true,
            blockDeparture: true,
            blockRevenew: true,
            blockChannel: true,
            blockReview: true,
            blockMailbox: true,
            blockAnouncements: true,
            blockRates: true,
            //blockAutomatedActions: true,
            reservAmountThisMonth: JSON.parse('{ "datasets": [{"label" : "" , "type": "bar","data": [],"backgroundColor": ["#004c6d", "#346888", "#5886a5", "#9dc6e0"]}, {"label" : "", "type": "line" ,"data": [],"backgroundColor": ["#004c6d", "#346888", "#5886a5", "#9dc6e0"]}],"labels": []}'),
            reservAmountToday: JSON.parse('{ "datasets": [{"label" : "" ,"data": [],"backgroundColor": ["#004c6d", "#346888", "#5886a5", "#9dc6e0"]}],"labels": []}'),
            channelStatus: [],
            arrivalTimeline: 'Today',
            unansweredMessages: [],
            unansweredReview: [],
            events: [],
            modalData: {},
            alertRates: [],
            channelConfModal: false,
            selectedChannel: {}
        }
        this.toggleChannelConfModal = this.toggleChannelConfModal.bind(this);
    }
    
     componentDidMount() {
         this.getDepartureAndArrivals();
         
        getAPI(result => {
            const { data, error } = result;
            
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, blockRevenew: false });
                return;
            }
            if (data) {
                const dataWithoutCanceledReservations = data.filter(d => d.status !== reservationStatus[2].value);

                var reservAmountThisMonth = this.getReservationAggregatedByFieldLast30Days(dataWithoutCanceledReservations, 'code', 'amountAfterTax', 'Valor Reservas', false);
                var reservAmountToday = this.getReservationAggregatedByFieldLast30Days(dataWithoutCanceledReservations, 'code', 'amountAfterTax', 'Valor Reservas', true);
                var valueToday = 0;
                var valueThisMonth = 0;
                var countToday = 0;
                var countThisMonth = 0;
                
                if (reservAmountThisMonth && reservAmountThisMonth.datasets[0] && reservAmountThisMonth.datasets[1].data) {
                    valueThisMonth = reservAmountThisMonth.datasets[1].data.reduce((a, b) => a + b, 0)
                    countThisMonth = reservAmountThisMonth.datasets[0].data.reduce((a, b) => a + b, 0)
                }
                if (reservAmountToday && reservAmountToday.datasets[0] && reservAmountToday.datasets[1].data) {
                    valueToday = reservAmountToday.datasets[1].data.reduce((a, b) => a + b, 0)
                    countToday = reservAmountToday.datasets[0].data.reduce((a, b) => a + b, 0)
                }

                var sumReservationStatus = new Map();
                dataWithoutCanceledReservations.forEach(item => {
                    let stat = (item.communicationStatus === null || item.communicationStatus === undefined) ? 'pending' : item.communicationStatus;
                    if (sumReservationStatus.get( stat) === undefined) {
                        sumReservationStatus.set(stat,  item.count );
                    }
                    else {
                        sumReservationStatus.set(stat, item.count + sumReservationStatus.get(stat));
                    }
                });

                this.setState(
                    {
                        reservAmountThisMonth: reservAmountThisMonth,
                        reservAmountToday: reservAmountToday,
                        valueToday: parseFloat(valueToday).toFixed(2),
                        valueThisMonth: valueThisMonth,
                        countToday: countToday,
                        countThisMonth: countThisMonth,
                        sumReservationStatus: sumReservationStatus,
                        blockRevenew: false,
                        error: errorMessage
                    }
                );
            }
        }, '/api/Price/Reservation/v1/Statistics/ReservationCountByChannel?startDate=' + this.state.currentDay.startOf('month').format("YYYY-MM-DD") + '&endDate=' + this.state.currentDay.clone().endOf('month').format("YYYY-MM-DD"));

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockChannel: false });

                return;
            }
            if (data && data.channelList) {
                this.setState({ channelStatus: data.channelList.sort((a, b) => (+b.active) - (+a.active) || a.name.localeCompare(b.name)), blockChannel: false });
                return;
            }
            this.setState({ blockChannel: false });
        }, '/api/Rate/ChannelConfig/v1/ChannelStatus');

        getAPI(result => {
            const { data, error } = result;
            this.setState({ blockEvents: true });
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, blockEvents: false });
                return;
            }
            if (data) {

                var errorsByChannel = data.data;
                var errorCount = 0;
                var disabledCount = 0;

                errorsByChannel && errorsByChannel.length > 0 && errorsByChannel.map((item, key) =>
                    item.events.forEach((el, k) => {
                        errorCount += el.numEvents;
                        disabledCount += el.autoActions.length;
                    })
                );
                
                this.setState(
                    {
                        errorsByChannel: errorsByChannel,
                        blockEvents: false,
                        errorCount: errorCount,
                        disabledCount: disabledCount,
                        error: errorMessage
                    });
            }
        }, '/api/User/Events/v1/Statistics/ErrosByChannel?startDate=' + moment().format("YYYY-MM-DD") + '&endDate=' + moment().add(1,'day').format("YYYY-MM-DD"));

        getAPI(result => {
            const { data, error } = result;
            this.setState({ blockMailbox: true });
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, blockMailbox: 'danger' });

                this.setState({ error: errorMessage, blockMailbox: false });
                return;
            }
            if (data) {
        
                this.setState(
                    {
                        unansweredMessages: data,
                        blockMailbox: false
                    });
            }
        }, '/api/Rate/MailBox/v1/unansweredMessages');

        getAPI(result => {
            const { data, error } = result;
            this.setState({ blockReview: true });
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, blockReview: false });
                return;
            }
            if (data) {

                this.setState(
                    {
                        unansweredReview: data,
                        blockReview: false
                    });
            }
        }, '/api/Rate/Review/v1/unansweredMessages');

         getAPI(result => {
             const { data, error } = result;
             this.setState({ blockAnouncements: true });
             var errorMessage = []
             if (error) {

                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                 this.setState({ error: errorMessage, blockAnouncements: false });
                 return;
             }
             if (data) {

                 this.setState(
                     {
                         annoucementList: data,
                         blockAnouncements: false
                     });
             }
         }, '/api/User/v1/Announcement/valid?date=' + moment().format("YYYY-MM-DD"));
         
         getAPI(result => {
             const { data, error } = result;
             const errors = [];
             if (error) {
                 errors.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errors, blockRates: false });
                 return;
             }
             if (data) {
                 if (data.errors && data.errors.length > 0) {
                     data.errors.forEach(err => errors.push({ message: err.message, stack: err.stack, messageType: 'danger' }));
                 }
                 if (data.response && data.response.length > 0) {
                     data.response.forEach(resp => {
                         resp.occupancy = resp.occupancy?.sort((occ1, occ2) => occ1.adults - occ2.adults !== 0 ? occ1.adults - occ2.adults : occ1.children - occ2.children);
                     });
                     this.setState({ alertRates: data.response });
                 }
             }
             this.setState({ error: errors, blockRates: false });
         }, `/api/Rate/DashBoard/PriceAlert`);
     }

     toggleModal(item) {

         if (item != null) {
             this.setState(prevState => ({
                 modalData: item, modal: !prevState.modal
             }));
         } else {
             this.setState(prevState => ({
                 modalData: {}, modal: !prevState.modal
             }));
         }
     }

     getDepartureAndArrivals() {


         var startDate = moment().format("YYYY-MM-DD");
         var endDate = moment().add(0, 'days').format("YYYY-MM-DD");
         var startDateDep = moment().add(-1, 'days').format("YYYY-MM-DD");
         var endDateDep = moment().add(-1, 'days').format("YYYY-MM-DD");
         
         if (this.state.arrivalTimeline === 'Today') {
             startDate = moment().format("YYYY-MM-DD");
             endDate = moment().add(0, 'days').format("YYYY-MM-DD");
             startDateDep = moment().add(0, 'days').format("YYYY-MM-DD");
             endDateDep = moment().add(0, 'days').format("YYYY-MM-DD");
         }else if (this.state.arrivalTimeline === 'Week') {
             startDate = moment().format("YYYY-MM-DD");
             endDate = moment().add(7, 'days').format("YYYY-MM-DD");
             startDateDep = moment().add(0, 'days').format("YYYY-MM-DD");
             endDateDep = moment().add(7, 'days').format("YYYY-MM-DD");
         } else if (this.state.arrivalTimeline === 'LastWeek') {
             endDate = moment().format("YYYY-MM-DD");
             startDate = moment().add(-7, 'days').format("YYYY-MM-DD");
             startDateDep = moment().add(0, 'days').format("YYYY-MM-DD");
             endDateDep = moment().add(-7, 'days').format("YYYY-MM-DD");
         } else if (this.state.arrivalTimeline === 'Month') {
             startDate = moment().format("YYYY-MM-DD");
             endDate = moment().clone().add(30, 'days').format("YYYY-MM-DD");
             startDateDep = moment().add(0, 'days').format("YYYY-MM-DD");
             endDateDep = moment().add(30, 'days').format("YYYY-MM-DD");
         } else if (this.state.arrivalTimeline === 'LastMonth') {
             endDate = moment().format("YYYY-MM-DD");
             startDate = moment().add(-30, 'days').format("YYYY-MM-DD");
             startDateDep = moment().add(0, 'days').format("YYYY-MM-DD");
             endDateDep = moment().add(-30, 'days').format("YYYY-MM-DD");
         }
         this.setState({ blockArrival: true, blockDeparture: true });
         getAPI(result => {
             const { data, error } = result;

             if (error) {
                 var errorMessage = [];
                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errorMessage, blockArrival: false });


                 return;
             }
             if (data) {
                 this.setState({ reservationArrivals: data, blockArrival: false });
             }
         }, '/api/Price/Reservation/v1/Arrivals?startDate=' + startDate + '&endDate=' + endDate)
         getAPI(result => {
             const { data, error } = result;

             if (error) {
                 var errorMessage = [];
                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errorMessage, blockDeparture: false });

                 return;
             }
             if (data) {
                 this.setState({ reservationDepartures: data, blockDeparture: false });
             }
         }, '/api/Price/Reservation/v1/Departures?startDate=' + startDateDep + '&endDate=' + endDateDep);

     }

     getReservationAggregatedByFieldLast30Days(data, aggregatorField, sumField, label, isToday) {
         var sumValuesByChannel = new Map();
         var countValuesByChannel = new Map();
         var today = moment();
        

         data.forEach((item, key) => {
             var date = moment(item.date);
             if (!isToday) {
                 if (sumValuesByChannel.get(item[aggregatorField]) === undefined) {
                     sumValuesByChannel.set(item[aggregatorField], item[sumField]);
                     countValuesByChannel.set(item[aggregatorField], item.count);

                 } else {
                     sumValuesByChannel.set(item[aggregatorField], item[sumField] + sumValuesByChannel.get(item[aggregatorField]));
                     countValuesByChannel.set(item[aggregatorField], item.count + countValuesByChannel.get(item[aggregatorField]));
                 }
             } else {
                 if (sumValuesByChannel.get(item[aggregatorField]) === undefined) {
                     sumValuesByChannel.set(item[aggregatorField], date.isSame(today, 'day') ? item[sumField] : 0);
                     countValuesByChannel.set(item[aggregatorField], date.isSame(today, 'day') ? item.count : 0);
                 } else {
                     sumValuesByChannel.set(item[aggregatorField], date.isSame(today, 'day') ? item[sumField] + sumValuesByChannel.get(item[aggregatorField]) : sumValuesByChannel.get(item[aggregatorField]));
                     countValuesByChannel.set(item[aggregatorField], date.isSame(today, 'day') ? item.count + countValuesByChannel.get(item[aggregatorField]) : countValuesByChannel.get(item[aggregatorField]));
                 }
             }
            
         });

         let channels = [...sumValuesByChannel.keys()];
         let channelsCount = [...countValuesByChannel.keys()];
         let channelReservationSum = [];
         let channelReservationCount = [];
         channels.forEach((item, key) => {
             channelReservationSum.push(parseFloat(sumValuesByChannel.get(item)?.toFixed(2)));

         });
         channelsCount.forEach((item, key) => {
             channelReservationCount.push(countValuesByChannel.get(item));

         });
         
         var obj =
         {
             "datasets":
                 [
                     { "label": "", "type": "line", "fill": "false", "data": [], "borderColor": "#00d6ff", "backgroundColor": "#00d6ff33", "borderWidth": "1", "yAxisID": "count" },
                     { "label": "", "type": "bar", "data": [], "borderColor": "#004c6d", "backgroundColor": "#004c6d20", "borderWidth": "1", "yAxisID": "rev" }],
             "labels": []
         };
         obj.datasets[1].data = channelReservationSum
         obj.datasets[1].label = this.props.intl.formatMessage({ id: "home.revenue" })
         obj.datasets[0].data = channelReservationCount
         obj.datasets[0].label = this.props.intl.formatMessage({ id: "home.reservation" })
         obj.labels = channels
         return obj
     }

     getErrorsByTypeChannelLast30Days(data) {
         var channelList = [];

         data && data.forEach((item, key) => {
             if ((!channelList) || (channelList && !channelList.includes(item.code)))
                 channelList.push(item.code)
         });
         var obj = {
             "datasets":
                 [  { "label": "Oportunity", "data": [], "backgroundColor": ["#00d6ff"] },
                     { "label": "Promotion", "data": [], "backgroundColor": ["#346888"] },
                     { "label": "MailBox", "data": [], "backgroundColor": ["#5886a5"] },
                     { "label": "Price", "data": [], "backgroundColor": ["#9dc6e0"] },
                     { "label": "Inventory", "data": [], "backgroundColor": ["#89bdd3"] },
                     { "label": "Review", "data": [], "backgroundColor": ["#9ad3de"] },
                     { "label": "Reservation", "data": [], "backgroundColor": ["#e3e3e3"] },
                     { "label": "Availability", "data": [], "backgroundColor": ["#c9c9c9"] },
                     { "label": "Inventory", "data": [], "backgroundColor": ["#a8b6bf"] },
                     { "label": "Listing", "data": [], "backgroundColor": ["#b8b6bf"] }], "labels": []
         };
         var eventList = ["Oportunity", "Promotion", "MailBox", "Price", "Inventory", "Review", "Reservation", "Availability", "Inventory", "Listing"]
         eventList.forEach((event, key) => {

             var eventChannelCount = Array.from({ length: channelList.length }, (v, i) => 0)

             data && data.forEach((dataItem) => {
                 if (dataItem.eventType === event) {
                     eventChannelCount[channelList.indexOf(dataItem.code)] = eventChannelCount[channelList.indexOf(dataItem.code)] + dataItem.count
                 }
             });

             obj.datasets[key].data = eventChannelCount;

         });
         obj.labels = channelList
         return obj

     }

     getIntervals() {
         return [
            {
                value: 'Today',
                label: <FormattedMessage id="Home.Today"/> 
            },
            {
                 value: 'Week',
                 label: <FormattedMessage id="Home.Week" />
            },
            {
                value: 'LastWeek',
                label: <FormattedMessage id="Home.LastWeek" />
            },
            {
                value: 'Month',
                label: <FormattedMessage id="Home.Month" />
            },
            {
                value: 'LastMonth',
                label: <FormattedMessage id="Home.LastMonth" />
            },
         ]
     }

     updateTimeline = (inputValue) => {
         var targetValue = inputValue.value;

         if (this.state.arrivalTimeline !== inputValue.value) {
             this.setState({ arrivalTimeline: targetValue }, () => this.getDepartureAndArrivals())
         }
        
     }

     toggleChannelConfModal(selectedChannel) {
         this.setState(prevState => ({ channelConfModal: !prevState.channelConfModal, selectedChannel: selectedChannel }));
     }

     render() {
        const { blockRates, alertRates } = this.state;

        const columns = [{
            dataField: 'channelInstanceName',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Channel" }),
             sort: true,
             formatter: (cell, row) => `${row.channelInstanceName} ${row.propertyCode ? '(' + row.propertyCode + ')' : ''}`
        }, {
                dataField: 'resIdDate',
                text: this.props.intl.formatMessage({ id: "ReservationDashboard.BookingDate" }),
                formatter: (cell, row) => cell ? moment(cell).format("YYYY-MM-DD") : ''
        }, {
            dataField: 'guestName',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Name" }),
            sort: true
        }, {
            dataField: 'guestCount',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Guests" }),
            sort: true
        }, {
            dataField: 'arrivalDate',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Arrival" }),
            formatter: (cell, row) => cell ? moment(cell).format("YYYY-MM-DD") : ''
        }, {
            dataField: 'numNights',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Nights" }),
            formatter: (cell, row) => <div >{cell} </div>
        }, {
            dataField: 'status',
            text: this.props.intl.formatMessage({ id: "ReservationDashboard.Status" }),
            formatter: (cell, row) => <ReservationStatusBadge status={cell} />

        }];

        let options = {
            responsive: true,
            tooltips: {
                mode: 'label'
            },
            legend: {
                display: true,
                position: "top"
            },
            scales: {
                xAxes: [{
                    display: true,
                    ticks: { beginAtZero: true, min: 0 } }],
                yAxes: [
                    {
                        type: 'linear',
                        ticks: { beginAtZero: true, precision: 0,min: 0 },
                        
                        display: true,
                        position: 'right',
                        id: 'count',
                        gridLines: {
                            display: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: `# ${this.props.intl.formatMessage({ id: "home.reservation" })}`
                        }
                    },
                    {
                        ticks: { beginAtZero: true, min: 0 },
                        type: 'linear',
                        display: true,
                       
                        position: 'left',
                        id: 'rev',
                        gridLines: {
                            display: false
                        },
                        labels: {
                            show: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: this.props.intl.formatMessage({ id: "home.revenue" })
                        }
                    }
                    
                ]
            }
        }

        const selectRow = {
        mode: 'radio',
        hideSelectColumn: true,
        bgColor: '#f8f9fa',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {


            this.setState({ block: true })
            getAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ block: isLoading });
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });


                    return;
                }
                if (data) {

                    //this.setState({ ChannelData: data.data });
                    this.setState({ modal: true, modalData: data.data })
                }
            }, '/api/Price/Reservation/v1/' + row.id);
        }
        };

        return (
            <div className="container-fluid px-0">
                <ErrorAlert error={this.state.error} />

                <div style={{ zIndex: 1, right: 15, top: 58 }} className="position-fixed" >
                        {this.state.annoucementList && this.state.annoucementList.apiAnnouncements ?
                        this.state.annoucementList.apiAnnouncements.filter(el => el.highlighted === true).map((announcement, k) => {

                            return (
                                <CustomAnnouncement announcement={announcement} key={k}/>     
                        );

                                
                        }) : ''}
                </div>

                {this.state.modal ?
                    <ReservationDetail modal={this.state.modal} data={this.state.modalData} action={this.toggleModal.bind(this)} history={this.props.history}/>
                :''}
                {this.state.channelConfModal ?
                    <Modal isOpen={this.state.channelConfModal} toggle={this.toggleChannelConfModal} fade={false} className="modal-lg " >
                        <ModalHeader toggle={this.toggleChannelConfModal}  >
                        <FormattedMessage id="ChannelList.Channel" />
                        </ModalHeader>
                        <ModalBody className="modal-lg px-0 pb-0">
                            <ChannelConf
                                selectedChannel={this.state.selectedChannel}
                                toggleModalAdd={this.toggleChannelConfModal}
                                activeTab="2"
                            />
                        </ModalBody>
                    </Modal>
                :
                    <div/>
                }
                <div className={global.isMobile ? '' : 'container-fluid'}>
                    <Row className={global.isMobile ? 'w-100' : ''}>
                        <Col className="col-lg-4 col-sm-12  mb-2" style={{ paddingLeft: global.isMobile ? '30px' : '', paddingRight: global.isMobile ? '0px' : '' }}>
                            <Card className="shadow h-100">
                                <CardBody className="pt-3">
                                    <Row className="row no-gutters align-items-center">
                                        <Col className="mr-1">
                                            <Row>
                                                <Col className="col-7">
                                                    <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1" style={{ 'position': global.isMobile ? '' : 'absolute', 'bottom': 0, cursor: 'pointer' }} onClick={() => this.props.history.push('/ReservationDashboard')}> <FormattedMessage id="home.ArrivalDeparture" /></p>
                                                </Col>
                                                <Col className="col-4">
                                                    <CustomSelect icon={'fa fa-calendar-week fa-fw'} options={this.getIntervals()} defaultValue={this.getIntervals()[0]} onChange={this.updateTimeline.bind(this)} />
                                                </Col>
                                                <Col className="col-1 mt-2 p-0">
                                                    <CommonHelper help={<FormattedHTMLMessage id="Home.ArrivalDepartureHelp" />} id={'ArrivalDepartureHelp'} />                                            
                                                </Col>
                                            </Row>
                                            <hr className="py-1 border-host" />
                                            <Row>
                                                <Col className="col-12" >
                                                    <i className="fas fa-walking mr-1" />
                                                    <strong>
                                                        <FormattedMessage id="home.arriving" />
                                                    </strong>
                                                </Col>
                                                </Row>
                                            <Row style={{ minHeight: '220px', verticalAlign: 'top', overflow: 'auto' }}>
                                                <Col className="small">
                                                    <BlockUi tag="div" blocking={this.state.blockArrival}>
                                                    <CustomTable
                                                        data={this.state.reservationArrivals ? this.state.reservationArrivals: []}
                                                        columns={columns}
                                                        search={false}
                                                        small={true}
                                                        exportCSV={false}
                                                        selectRow={selectRow}
                                                        />
                                                    </BlockUi>
                                                </Col>
                                            </Row>
                                            <hr className="border-host" />
                                            <Row>
                                                <Col className="col-12">
                                                    <i className="fas fa-walking fa-flip-horizontal mr-1" />
                                                    <strong>
                                                        <FormattedMessage id="home.departing" />
                                                    </strong>
                                                </Col>
                                            </Row>
                                            <Row style={{ overflow: 'auto' }}>
                                                <Col className="small">
                                                    <BlockUi tag="div" blocking={this.state.blockDeparture}>
                                                        <CustomTable
                                                            data={this.state.reservationDepartures ? this.state.reservationDepartures : []}
                                                            columns={columns}
                                                            search={false}
                                                            small={true}
                                                            exportCSV={false}
                                                            selectRow={selectRow}
                                                        />
                                                    </BlockUi>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="col-lg-4 col-sm-12  mb-2" style={{ paddingLeft: global.isMobile ? '30px' : '', paddingRight: global.isMobile ? '0px' : '' }}>
                            <Card className="shadow h-100 py-1">
                                <CardBody>
                                    <BlockUi tag="div" blocking={this.state.blockRevenew}>
                                        <Row className="row no-gutters align-items-center">
                                            <Col className="mr-1">
                                                <Row>
                                                    <Col className="col-11">
                                                        <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1" style={{ cursor: 'pointer' }} onClick={() => this.props.history.push('/AnalyticsDashboard')}>
                                                            <FormattedMessage id="home.revenue" />
                                                        </p>
                                                    </Col>
                                                    <Col className="col-1 p-0">
                                                        <CommonHelper help={<FormattedMessage id="Home.RevenueHelp" />} id={'RevenueHelp'} />                                                
                                                    </Col>
                                                </Row>
                                                <hr className="py-1 border-host" />
                                                <Row>
                                                    <Col className="col-12 mb-2 text-center">
                                                        <strong> {<FormattedMessage id="home.today" />}</strong>
                                                        <Row>
                                                            <Col className="text-left">
                                                                <i className="fas fa-file-invoice-dollar mr-1"></i>
                                                                <FormattedMessage id="home.revenue"/>  {this.state.valueToday} {global.hotelCurrency}
                                                            </Col>
                                                            <Col className="text-right">
                                                                <i className="fas fa-suitcase mr-1 ">
                                                                    
                                                                </i>
                                                                <FormattedMessage id="home.reservation" />  {this.state.countToday}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-12">
                                                        <Bar data={this.state.reservAmountToday} options={options}  />
                                                    </Col>                                       
                                                </Row>
                                                <hr className="border-host"/>
                                                <Row>                                          
                                                    <Col className="col-12 mb-2 text-center">
                                                        <strong>
                                                            <FormattedMessage id="home.thismonth" />
                                                        </strong>
                                                        <br />
                                                        <Row>
                                                            <Col className="text-left">
                                                                <i className="fas fa-file-invoice-dollar mr-1 text-left">
                                                                    

                                                                        </i> <FormattedMessage id="home.revenue" /> {this.state.valueThisMonth ? <FormattedNumber value={this.state.valueThisMonth} maximumFractionDigits={2} /> : ''} {global.hotelCurrency}
                                                            </Col>
                                                            <Col className="text-right">
                                                                <i className="fas fa-suitcase mr-1 text-left">
                                                                    
                                                                </i>
                                                                <FormattedMessage id="home.reservation" />  {this.state.countThisMonth}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="col-12">
                                                        <Bar data={this.state.reservAmountThisMonth} options={options}  />
                                                    </Col>                                           
                                                </Row>                                   
                                            </Col>                                
                                        </Row>
                                    </BlockUi>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="col-lg-4 col-sm-12  mb-2" style={{ paddingLeft: global.isMobile ? '30px' : '', paddingRight: global.isMobile ? '0px' : '' }}>
                            <Card className="shadow h-100 py-1">
                                <CardBody>
                                    <Row>
                                        <Col className="col-11">
                                            <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1"> <FormattedMessage id="Home.LastAlerts" /></p>
                                        </Col>
                                        <Col className="col-1 p-0">
                                            <CommonHelper help={<FormattedMessage id="Home.LastAlertsHelp" />} id={'LastAlertsHelp'} />                                   
                                        </Col>
                                    </Row>
                                    <hr className="py-1 border-host" />
                                    <Row>
                                        <Col className="text-center"><strong> </strong></Col>
                                    </Row>

                                    <Row>
                                        <Col className="col-4">
                                            <i className="fas fa-exclamation-circle mr-1"></i> <strong> <FormattedMessage id="home.errors" /> </strong>
                                            
                                        </Col>
                                        <Col className="col-2">
                                            {this.state.errorCount}
                                        </Col>
                                        <Col className="col-4">
                                            <strong> <FormattedMessage id="home.autodisable" /> </strong> 
                                            
                                        </Col>
                                        <Col className="col-2">
                                            {this.state.disabledCount}
                                            <i className="fas fa-chevron-down float-right" id="togglerErrorGraph"></i>
                                        </Col>
                                    </Row>
                                    <UncontrolledCollapse toggler="#togglerErrorGraph">
                                        <Row className="small ">
                                            <Col className="mb-2 ">
                                            
                                                    {this.state.errorsByChannel && this.state.errorsByChannel.map((item, key) =>
                                                        item.channelCode && item.channelInstanceId ?
                                                            <div className="mb-1 " key={key}>
                                                                <Row>
                                                                    <Col className="col-12">
                                                                        {getChannelConfig(item.channelCode) ? <img alt="" src={getChannelConfig(item.channelCode).image} height={getChannelConfig(item.channelCode).iconSize} className="mr-2"></img> : ''}  <b>{item.channelName}</b>
                                                                    </Col>
                                                                </Row>
                                                        
                                                                {item.events.map((el, k) =>
                                                                    <Row className="border-bottom" key={k}>
                                                                        <Col className="col-1"></Col>
                                                                        <Col className="col-4">
                                                                            <a href={'/LogsDashboard?&error=2&errorType=' + el.errorType + '&channel=' + item.channelInstanceId} >{el.errorType} </a>
                                                                        </Col>
                                                                        <Col className="col-1">
                                                                            {el.numEvents} 
                                                                        </Col>
                                                                        <Col>
                                                                            {el.autoActions.length > 0 ?
                                                                                <div>
                                                                                    {el.autoActions.map((elAction, k2) => 
                                                                                        <div key={k2}>
                                                                                    
                                                                                            <a href={'/LogsDashboard?autoAction=true&error=2&channel=' + elAction.channelInstanceId} ><FormattedMessage id={"home.autoAction." + elAction.entity[0]} /></a>
                                                                                        </div>
                                                                                        )}
                                                                                </div>
                                                                                : ''}

                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                            </div>
                                                        : 
                                                        <div className="mb-1 " key={key}>
                                                            {item.events.map((el, k) =>
                                                                el.errorType === "PmsInvoke" ?
                                                                    <Row className="border-bottom py-1" key={k}>
                                                                        <Col className="col-5">
                                                                            <FormattedMessage id={"home.errorType." + el.errorType} />
                                                                        </Col>
                                                                        <Col className="col-1">
                                                                            {el.numEvents}
                                                                        </Col>
                                                                        <Col>
                                                                            {el.autoActions.length > 0 ?
                                                                                <div>
                                                                                    {el.autoActions.map((elAction, k2) =>
                                                                                        <div>
                                                                                            <FormattedMessage id={"home.autoAction." + elAction.entity[0]} />
                                                                                        </div>
                                                                                    )}
                                                                                </div>
                                                                                : ''}
                                                                        </Col>
                                                                    </Row>
                                                                : ''
                                                            )}
                                                        </div>
                                                )}
                                        </Col>
                                        </Row>
                                    </UncontrolledCollapse>
                                    <hr className="border-host" />
                                    <BlockUi tag="div" blocking={this.state.blockChannel}>
                                        <Row>
                                            <Col className="col-6">
                                            <i className="fas fa-plug mr-1"></i> <strong> <FormattedMessage id="home.channel" /> </strong>
                                        </Col>
                                            <Col className="col-4">
                                                {this.state.channelStatus && this.state.channelStatus.length}
                                                {this.state.channelStatus && this.state.channelStatus.find(el => el.rateCodeChannelList.find(el => el.priceModeDivergence === true) != undefined) !== undefined ?
                                                    <> <i className="ml-1 fas fa-exclamation-triangle text-warning" id={'pmdiver'}></i>
                                                        <CustomToolTip placement="bottom" target={'pmdiver'}>
                                                            <FormattedMessage id="home.pricemode" />
                                                        </CustomToolTip>
                                                    </>
                                                    : ''} 
                                            </Col>
                                            <Col>
                                                
                                            <i className="fas fa-chevron-down float-right" id="togglerChannels"></i>
                                        </Col>
                                        </Row>
                                        <UncontrolledCollapse toggler="#togglerChannels" className="small">
                                            

                                            {this.state.channelStatus && this.state.channelStatus.map((item, key) =>
                                                <div key={key} className="py-1 ">
                                                    <div className="d-flex">
                                                        <Col className="col-4 pl-0">
                                                        {getChannelConfig(item.code)  ? 
                                                        <img alt="" src={getChannelConfig(item.code).image} height={getChannelConfig(item.code).iconSize} className="mr-2"></img>
                                                            : '' }
                                                            {item.name}</Col>
                                                        <Col className="col-3 pl-0">{item.propertyCode}</Col>
                                                        <Col className="col-3"><ActiveInactiveStatusBadge status={item.active} />
                                                            {
                                                                item.rateCodeChannelList && item.rateCodeChannelList.filter(el => el.priceModeDivergence === true).length > 0 ?
                                                                    <span>
                                                                        <i className="ml-1 fas fa-exclamation-triangle text-warning" id={'pmdiverroot' + key} style={{ cursor: "pointer" }} onClick={this.toggleChannelConfModal.bind(this, { id: item.id, channelConfigId: item.channelConfigId, code: item.code, channelName: item.name, hasPassword: item.hasPassword, active: item.active })}/>
                                                                        <CustomToolTip placement="bottom" target={'pmdiverroot' + key}>
                                                                            <FormattedMessage id="home.pricemode" />
                                                                            {
                                                                                item.contractExpiring ?
                                                                                    <FormattedMessage id="home.pricemode" />
                                                                                    :
                                                                                    <span/>
                                                                            }
                                                                        </CustomToolTip>
                                                                    </span>
                                                                    :
                                                                    <span />
                                                            }
                                                            {
                                                                item.rateCodeChannelList && item.rateCodeChannelList.filter(el => el.contractExpiring === true).length > 0 ?
                                                                    <span>
                                                                        <i className="ml-1 fas fa-exclamation-triangle text-warning" id={'contractExpiring' + key} style={{ cursor: "pointer" }} onClick={this.toggleChannelConfModal.bind(this, { id: item.id, channelConfigId: item.channelConfigId, code: item.code, channelName: item.name })}/>
                                                                        <CustomToolTip placement="bottom" target={'contractExpiring' + key}>
                                                                            <FormattedMessage id="home.contractExpiring" />
                                                                        </CustomToolTip>
                                                                    </span>
                                                                    :
                                                                    <span />
                                                            }
                                                        </Col>
                                                        <Col className="col-2 pr-0">
                                                            
                                                            <div className="align-middle float-right" id={'togglerChannelStat' + key}>
                                                                {item.rateCodeChannelList && item.rateCodeChannelList.length}
                                                                {item.rateCodeChannelList && item.rateCodeChannelList.length > 0 ?
                                                                    <i className="fas ml-1 fa-plus-square" style={{ 'pointer': 'cursor' }}></i>
                                                                    : <i className="fas ml-1 fa-plus-square text-secondary "></i>}
                                                                </div>
                                                                
                                                        </Col>
                                                    </div>
                                                    <UncontrolledCollapse toggler={'#togglerChannelStat' + key} >
                                                        {
                                                            item.rateCodeChannelList && item.rateCodeChannelList.sort((a, b) => (+b.active) - (+a.active)).map((el, key) =>
                                                                <div className="d-flex border-bottom py-1" key={key}>
                                                                    <Col className="col-4">
                                                                        <Row>Room</Row>
                                                                        <Row>Rate</Row>
                                                                        {item.code === 'WORLD2MEET' ? <Row>Contract Id</Row> : ''}
                                                                    </Col>
                                                                    <Col className="col-3 d-flex flex-column">
                                                                        <span className='text-truncate'>{el.roomCode}</span>
                                                                        <span className='text-truncate'>{el.rateCode}</span>
                                                                        {item.code === 'WORLD2MEET' ? <span className='text-truncate'>{el.availabilityGroupMapping}</span> : ''}
                                                                    </Col>
                                                                    <Col className="col-5 d-flex align-items-center">
                                                                        <ActiveInactiveStatusBadge status={el.active} />
                                                                        {
                                                                            el.priceModeDivergence ?
                                                                                <span>
                                                                                    <i className="ml-2 fas fa-exclamation-triangle text-warning" style={{ cursor: "pointer" }} id={'pmdiver' + key} onClick={this.toggleChannelConfModal.bind(this, { id: item.id, channelConfigId: item.channelConfigId, code: item.code, channelName: item.name })} />
                                                                                    <CustomToolTip placement="bottom" target={'pmdiver' + key}>
                                                                                        <FormattedMessage id="home.pricemode" /> 
                                                                                    </CustomToolTip>
                                                                                </span>
                                                                                :
                                                                                <span/>
                                                                        }
                                                                        {
                                                                            el.contractExpiring ?
                                                                                <span>
                                                                                    <i className="ml-1 fas fa-exclamation-triangle text-warning" style={{ cursor: "pointer" }} id={'contractExpiring' + key} onClick={this.toggleChannelConfModal.bind(this, { id: item.id, channelConfigId: item.channelConfigId, code: item.code, channelName: item.name })} />
                                                                                    <CustomToolTip placement="bottom" target={'contractExpiring' + key}>
                                                                                        <FormattedMessage id="home.contractExpiring" />
                                                                                    </CustomToolTip>
                                                                                </span>
                                                                                :
                                                                                <span />
                                                                        }
                                                                    </Col>
                                                                </div>
                                                    )
                                                        }
                                                    </UncontrolledCollapse>
                                                </div>
                                                
                                                )}
                                        </UncontrolledCollapse>
                                    </BlockUi>
                                    <hr className="border-host" />
                                    <BlockUi tag="div" blocking={this.state.blockRevenew}>
                                        <Row>
                                            <Col className="col-6">
                                                <i className="fas fa-suitcase mr-1"></i> <strong> <FormattedMessage id="home.Reservation" /> </strong>
                                            </Col>
                                            <Col className="col-2">
                                                {this.state.sumReservationStatus && [...this.state.sumReservationStatus.keys()].reduce((accumulator, currentValue) => accumulator + this.state.sumReservationStatus.get(currentValue),0 ) }

                                                {this.state.sumReservationStatus && this.state.sumReservationStatus.get('Error') > 0 ?
                                                    <> <i className="ml-1 fas fa-exclamation-triangle text-warning" id={'reservationserror'}></i>
                                                        <CustomToolTip placement="bottom" target={'reservationserror'}>
                                                            <FormattedMessage id="home.ReservationsDeliveryErrros" values={{ errors: this.state.sumReservationStatus.get('Error') }}/>
                                                        </CustomToolTip>
                                                    </>
                                                : ''} 
                                            </Col>
                                            <Col>
                                                <i className="fas fa-chevron-down float-right" id="togglerReservations"></i>
                                            </Col>
                                        </Row>
                                        <UncontrolledCollapse toggler="#togglerReservations" >

                                            {this.state.sumReservationStatus && [...this.state.sumReservationStatus.keys()].map((item, key) =>
                                                <Row key={key}>
                                                    <Col className="col-6">
                                                        <ReservationCommStatusIcon status={item}/>
                                                        </Col>
                                                <Col>

                                                        {this.state.sumReservationStatus.get(item)}
                                                </Col>
                                            </Row>
                                        )}

                                        </UncontrolledCollapse>
                                    </BlockUi>
                                    
                                    {global.channelList.find(el => el === 'BOOKING' || el === 'AIRBNB' || el === 'EXPEDIA') && global.licenseLevel === 'ChannelExtras'  ?
                                        <div>
                                            <hr className="border-host" />
                                            <BlockUi tag="div" blocking={this.state.blockReview}>
                                                <Row className="mb-1">
                                                    <Col className="col-6">
                                                        <i className="fas fa-star mr-1"></i> <strong> <FormattedMessage id="home.review" /> </strong>
                                                    </Col>
                                                    <Col className="col-2">
                                                        {this.state.unansweredReview.unawserMailboxCount && this.state.unansweredReview.unawserMailboxCount.reduce((a, b) => a + b.count, 0)}
                                                    </Col>
                                                    <Col><i className="fas fa-chevron-down float-right" id="togglerReview"></i></Col>
                                                    </Row>

                                                <UncontrolledCollapse toggler="#togglerReview">
                                                    {this.state.unansweredReview.unawserMailboxCount && this.state.unansweredReview.unawserMailboxCount.map((item, key) =>

                                                        <Row key={key} className="mb-1">
                                                            <Col className="col-6">
                                                                {
                                                                    getChannelConfig(item.name) ? <img alt="" src={getChannelConfig(item.name).image} height={getChannelConfig(item.name).iconSize} className="mr-2"></img> : ''
                                                                }
                                                                {
                                                                    item.name ?
                                                                        <a href={`/OnlineRatesDashboard?channel=${item.name.toUpperCase()}`}>
                                                                            {item.name}
                                                                        </a>
                                                                        :
                                                                        item.name
                                                                }
                                                            </Col>
                                                            <Col>{item.count}</Col>
                                                        </Row>
                                                    )}
                                                </UncontrolledCollapse>
                                            </BlockUi>
                                        </div>
                                    : ''}

                                    {global.licenseLevel === 'ChannelExtras' && global.channelList.find(el => el === 'BOOKING' || el === 'AIRBNB' || el === 'EXPEDIA') ?
                                        <div><hr className="border-host" />
                                        <BlockUi tag="div" blocking={this.state.blockMailbox}>
                                            <Row className="mb-1">
                                                <Col className="col-6">
                                                    <i className="fas fa-comment mr-1"></i> <strong> <FormattedMessage id="home.mailbox" /> </strong>
                                                </Col>
                                                <Col className="col-2">
                                                    {this.state.unansweredMessages.unawserMailboxCount && this.state.unansweredMessages.unawserMailboxCount.reduce((a, b) => a + b.count, 0)}
                                                    
                                                </Col>
                                                <Col>
                                                    <i className="fas fa-chevron-down float-right" id="togglerMailbox"></i>
                                                </Col>
                                            </Row>
                                            <UncontrolledCollapse toggler="#togglerMailbox">
                                                {this.state.unansweredMessages.unawserMailboxCount && this.state.unansweredMessages.unawserMailboxCount.map((item, key) =>

                                                    <Row className="mb-1" key={key}>
                                                        <Col>
                                                            {
                                                                getChannelConfig(item.name) ?
                                                                    <img alt="" src={getChannelConfig(item.name).image} height={getChannelConfig(item.name).iconSize} className="mr-2"></img>
                                                                    :
                                                                    ''
                                                            }
                                                            {
                                                                item.name ?
                                                                    <a href={`/MailDashboard?channel=${item.name.toUpperCase()}&channelInstance=${item.id}`}>
                                                                        {item.name} ({item.propertyCode})
                                                                    </a>
                                                                    :
                                                                    item.name
                                                            }
                                                        </Col>
                                                        <Col>{item.count}</Col>
                                                    </Row>
                                                )}

                                            </UncontrolledCollapse>
                                            </BlockUi>
                                        </div>
                                    : ''}
                                    <hr className="border-host" />
                                    <BlockUi tag="div" blocking={this.state.blockAnouncements}>
                                        <Row className="mb-1">
                                            <Col className="col-6">
                                                <i className="fas fa-bullhorn"></i><strong> <FormattedMessage id="home.Anouncement" /> </strong>
                                            </Col>
                                            <Col className="col-2">
                                                {this.state.annoucementList && this.state.annoucementList.apiAnnouncements ? this.state.annoucementList.apiAnnouncements.length : ''}
                                                
                                            </Col>
                                            <Col>
                                                <i className="fas fa-chevron-down float-right" id="togglerAnouncement"></i>
                                            </Col>
                                        </Row>
                                        <UncontrolledCollapse toggler="#togglerAnouncement">
                                            <div className="mt-2"></div>
                                            {this.state.annoucementList && this.state.annoucementList.apiAnnouncements ? this.state.annoucementList.apiAnnouncements.map((item, key) =>
                                                <Toast className="" style={{ maxWidth: '100%' }} key={key}>
                                                    <ToastHeader className="bg-white">
                                                        {item.channelConfigDetails && item.channelConfigDetails.channelCode}
                                                    </ToastHeader>
                                                    <ToastBody>
                                                        <Row>
                                                            <Col className="col-2 text-center pt-1">
                                                                <img alt="" style={{ maxWidth: '100%', maxHeight: '40px', height: 'auto' }}
                                                                    src={getChannelConfig(item.channelConfigDetails.channelCode) ? getChannelConfig(item.channelConfigDetails.channelCode).image : ''}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                {ReactHtmlParser(item.announcementText[0].text)}
                                                            </Col>
                                                        </Row>
                                                    </ToastBody>
                                                </Toast> 
                                                )
                                        : ''}

                                        </UncontrolledCollapse>
                                    </BlockUi>
                                    <hr className="border-host" />
                                    <BlockUi tag="div" blocking={blockRates}>
                                        <Row className="mb-2">
                                            <Col className="col-6">
                                                <i className="fas fa-gift " />
                                                {" "}
                                                <strong>
                                                    <FormattedMessage id="home.Rates" />
                                                </strong>
                                            </Col>
                                            <Col className="col-4">
                                                {alertRates && alertRates.length}
                                            </Col>
                                            <Col>
                                                <i className="fas fa-chevron-down float-right" id="togglerRates"/>
                                            </Col>
                                        </Row>
                                        <UncontrolledCollapse toggler="#togglerRates">
                                            {
                                                alertRates && alertRates.map((rate, key) =>
                                                    <div key={key} className="small">
                                                        <Row>
                                                            <Col style={{ cursor: 'pointer' }} className="col-10" onClick={() => this.props.history.push({ pathname: `/Inventory`, state: { startDate: rate.occupancy[0].missingPrice[0].from, rateId: { value: rate.id, label:rate.code } } })}>
                                                                {rate.code}
                                                            </Col>
                                                            <Col className="col-2 text-right">
                                                                <span className="align-middle float-right" id={`togglerOccupancy${key}`}>
                                                                    {rate.occupancy && rate.occupancy.length}
                                                                    <i className="ml-1 fas fa-plus-square" />
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                        <UncontrolledCollapse toggler={`togglerOccupancy${key}`}>
                                                            <Row>
                                                                <Col>
                                                                    <b>
                                                                        <FormattedMessage id="home.Adults" />
                                                                    </b>
                                                                </Col>
                                                                <Col>
                                                                    <b>
                                                                        <FormattedMessage id="home.Children" />
                                                                    </b>
                                                                </Col>
                                                                <Col>
                                                                    <b>
                                                                        <FormattedMessage id="home.From" />
                                                                    </b>
                                                                </Col>
                                                                <Col>
                                                                    <b>
                                                                        <FormattedMessage id="home.To" />
                                                                    </b>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                rate.occupancy.map((occupancy, key) =>
                                                                    <div key={key}>
                                                                        <Row>
                                                                            <Col>
                                                                                {occupancy.adults}
                                                                            </Col>
                                                                            <Col>
                                                                                {occupancy.children}
                                                                            </Col>
                                                                            <Col>
                                                                                {moment(occupancy.missingPrice[0].from).format("YYYY-MM-DD")}
                                                                            </Col>
                                                                            <Col>
                                                                                {moment(occupancy.missingPrice[0].to).format("YYYY-MM-DD")}
                                                                            </Col>
                                                                        </Row>
                                                                    </div>        
                                                                )
                                                            }
                                                        </UncontrolledCollapse>
                                                        <hr className="my-1" />
                                                    </div>
                                                )
                                            }
                                        </UncontrolledCollapse>
                                    </BlockUi>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        );
     }
 }

Home.contextTypes = {
    state: PropTypes.any
};

export default injectIntl(Home);
