import React, { Component } from 'react';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { Col, Row, Card } from 'reactstrap';
import { postAPI } from "../Base/API";
import { ErrorAlert } from "../Common/ErrorAlert";
import ChartCard from "./Common/ChartCard";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import moment from 'moment';
import { Line } from 'react-chartjs-2';

class Campaigns extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.search === true && nextProps.search !== this.props.search) {
            this.getCampaigns();
            this.props.toggleSearch();
        }
    }

    componentDidMount() {
        this.getCampaigns();
    }

    getCampaigns = () => {
        this.setState({ block: true });

        const body = {
            startDate: moment(this.props.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
            dimensions: ["sessionCampaignId", "sessionCampaignName", "date", "sessionSource"],
            metrics: ["totalUsers", "addToCarts", "totalRevenue"],
            aggregation: []
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const json = JSON.parse(data);

                const filtered = json;
                filtered.rows = filtered?.rows?.filter(row => row.dimensionValues[1].value?.charAt(0) !== '('
                && row.dimensionValues[2].value?.charAt(row.dimensionValues[1].value?.length-1) !== ')'); //filtered default campaigns
                
                this.setState({ block: false }, () => {this.formatDataForCharts(filtered); this.aggregateCampaigns(filtered)});
            }
        }, `/api/hotel/v1/googleanalytics`, body);
    }

    formatDataForCharts = (data) => {
        const { startDate, endDate, intl } = this.props;
        let campaigns = [];
        
        //Revenue/Users by campaign
        const RevenueByCampaign = [[intl.formatMessage({ id: "BEAnalitics.Campaigns" }), intl.formatMessage({ id: "BEAnalitics.totalRevenue" })]];
        const UsersByCampaign = [[intl.formatMessage({ id: "BEAnalitics.Campaigns" }), intl.formatMessage({ id: "BEAnalitics.totalUsers" })]];
        if(data?.rows?.length > 0){
            data.rows.filter(row => {
                const currCampaign = row.dimensionValues[1]?.value;
                if(!campaigns.find(t => t === currCampaign)){
                    const currRow = data.rows.filter(row => currCampaign === row.dimensionValues[1].value);
                    campaigns.push(currCampaign);
                    
                    RevenueByCampaign.push([currCampaign, currRow.reduce((prev, row) => prev + parseInt(row.metricValues[2].value), 0)]);

                    UsersByCampaign.push([currCampaign, currRow.reduce((prev, row) => prev + parseInt(row.metricValues[0].value), 0)]);
                };
            });

            //Revenue/Users by campaign over time
            let dates = [];

            var start = moment(startDate);
            dates.push(start.format('YYYY-MM-DD'));
            while (start.add(1, 'day').diff(endDate, 'days') < 0) {
                dates.push(moment(start).format('YYYY-MM-DD'));
            };
            
            const RevenueByCampaignovertime = [[intl.formatMessage({ id: "BEAnalitics.Date" })]];
            const UsersByCampaignovertime = [[intl.formatMessage({ id: "BEAnalitics.Date" })]];

            campaigns.forEach(c => {RevenueByCampaignovertime[0].push(c); UsersByCampaignovertime[0].push(c)});

            dates.forEach(day => {
                const arrRevenue = [moment(day).format('MM-DD')];
                const arrUsers = [moment(day).format('MM-DD')];
                
                const allValues = data.rows.filter(row => moment(row.dimensionValues[2]?.value).isSame(day));

                if(allValues && allValues.length > 0){
                    campaigns.forEach(c => {
                        const currCampaigns = allValues.filter(row => row.dimensionValues[1].value === c)
                        if(currCampaigns && currCampaigns.length > 0){
                            arrRevenue.push(currCampaigns.reduce((prev, row) => prev + parseInt(row.metricValues[2].value), 0));
                            arrUsers.push(currCampaigns.reduce((prev, row) => prev + parseInt(row.metricValues[0].value), 0));
                        }
                        else{
                            arrRevenue.push(0);
                            arrUsers.push(0);
                        }
                    });
                }
                else{
                    campaigns.forEach(c => {arrRevenue.push(0); arrUsers.push(0);});
                };

                RevenueByCampaignovertime.push(arrRevenue);
                UsersByCampaignovertime.push(arrUsers);
            });
            
            this.setState({ RevenueByCampaignovertime, RevenueByCampaign, UsersByCampaign, UsersByCampaignovertime });
        }
    }

    aggregateCampaigns  = (data) => {
        if(data?.rows?.length > 0){

            const filtered = 
            data.rows.map(row => {
                const result = {
                    campaignId : row.dimensionValues[0].value,
                    campaignName : row.dimensionValues[1].value,
                    sessionSource: row.dimensionValues[3].value,
                    totalUsers : parseInt(row.metricValues[0].value),
                    totalRevenue : parseInt(row.metricValues[2].value)
                }
                return result;
            });
            const aggregatedCampaigns = [];

            filtered.filter(row => {
                const agg = aggregatedCampaigns.findIndex(({campaignName, sessionSource}) => campaignName === row.campaignName && sessionSource === row.sessionSource);
                if(agg === -1){
                    aggregatedCampaigns.push(row);
                }
                else{
                    aggregatedCampaigns[agg] = {
                        campaignId : row.campaignId,
                        campaignName : row.campaignName,
                        sessionSource: row.sessionSource,
                        totalUsers : aggregatedCampaigns[agg].totalUsers + row.totalUsers,
                        totalRevenue : aggregatedCampaigns[agg].totalRevenue + row.totalRevenue
                    }
                }
            });
            this.setState({ aggregatedCampaigns });
        }
    }
    
    render() {
        const { block, RevenueByCampaignovertime, RevenueByCampaign, UsersByCampaignovertime, UsersByCampaign, aggregatedCampaigns } = this.state;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={this.state.error} />
                {aggregatedCampaigns ?
                    <div>
                        <Row>
                            <Col className="col-6">
                                {RevenueByCampaignovertime ?
                                    <ChartCard
                                        chartType='LineChart'
                                        data={RevenueByCampaignovertime}
                                        options={{
                                            colors: ['#12b5cb', '#ffb703', '#ea914f'],
                                            chartArea: { width: '75%', left: "5%"},
                                            curveType: 'function',
                                            vAxis: { minValue: 0, viewWindow: { min: 0 }, viewWindowMode: "explicit" }
                                        }}
                                        title={<FormattedMessage id="BEAnalitics.RevenueByCampaignovertime" />}
                                    />
                                : ''}
                            </Col>
                            <Col className="col-6">
                                {RevenueByCampaign ?
                                    <ChartCard
                                        chartType='BarChart'
                                        data={RevenueByCampaign}
                                        options={{
                                            legend: "none",
                                            hAxis: { minValue: 0, viewWindow: { min: 0 }, viewWindowMode: "explicit" }
                                        }}
                                        title={<FormattedMessage id="BEAnalitics.RevenueByCampaign" />}
                                    />
                                : ''}
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="col-6">
                                {UsersByCampaignovertime ?
                                    <ChartCard
                                        chartType='LineChart'
                                        data={UsersByCampaignovertime}
                                        options={{
                                            chartArea: { width: '75%', left: "5%"},
                                            colors: ['#12b5cb', '#ffb703', '#ea914f'],
                                            curveType: 'function',
                                            vAxis: { minValue: 0, viewWindow: { min: 0 }, viewWindowMode: "explicit" }
                                            }}
                                        title={<FormattedMessage id="BEAnalitics.UsersByCampaignovertime" />}
                                    />
                                : ''}
                            </Col>
                            <Col className="col-6">
                                {UsersByCampaign ?
                                    <ChartCard
                                        chartType='BarChart'
                                        data={UsersByCampaign}
                                        options={{
                                            legend: "none",
                                            hAxis: { minValue: 0, viewWindow: { min: 0 }, viewWindowMode: "explicit" }
                                        }}
                                        title={<FormattedMessage id="BEAnalitics.UsersByCampaign" />}
                                    />
                                : ''}
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                {aggregatedCampaigns?.length > 0 ?
                                    <Card body className="shadow border-0 h-100 mb-3">
                                        <span className="text-xs font-weight-bold text-secondary text-uppercase mb-2">
                                            <FormattedMessage id="BEAnalitics.TrafficSources"/>
                                        </span>

                                        <Row>
                                            <Col sm={2} className="text-center">
                                                <FormattedMessage id="BEAnalitics.CampaignCode"/>
                                            </Col>
                                            <Col sm={3} className="text-center">
                                                <FormattedMessage id="BEAnalitics.CampaignName"/>
                                            </Col>
                                            <Col sm={3} className="text-center">
                                                <FormattedMessage id="BEAnalitics.Source"/>
                                            </Col>
                                            <Col sm={2} className="text-center">
                                                <FormattedMessage id="BEAnalitics.TotalUsers"/>
                                            </Col>
                                            <Col sm={2} className="text-center">
                                                <FormattedMessage id="BEAnalitics.Revenue"/>
                                            </Col>
                                        </Row>

                                        <hr className="my-2" />

                                        {aggregatedCampaigns.map((s, key) =>
                                            <Row key={key} className="overbglight py-1">
                                                <Col sm={2} className="text-center">
                                                    {s.campaignId}
                                                </Col>
                                                <Col sm={3} className="text-center">
                                                    {s.campaignName}
                                                </Col>
                                                <Col sm={3} className="text-center">
                                                    {s.sessionSource}
                                                </Col>
                                                <Col sm={2} className="text-center">
                                                    {s.totalUsers}
                                                </Col>
                                                <Col sm={2} className="text-center">
                                                    <FormattedNumber
                                                        value={s.totalRevenue}
                                                        style="currency"
                                                        currency="EUR"
                                                        minimumFractionDigits={2}
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                    </Card>
                                : ''}
                            </Col>
                        </Row>
                    </div>
                :
                    <Row>
                        <Col>
                            <Card body className="shadow border-0 " >
                                <Row>
                                    <Col className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        <FormattedMessage id="BEAnalitics.NoCampaigns"/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                }
            </BlockUi>
        )
    }
}

export default injectIntl(Campaigns);
