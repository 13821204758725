import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card, Col, Row } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard } from "../../Base/Common/CommonUIComponents";
import tripadvisor from '../../../img/tripadvisor.ico';

class Tripadvisor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            modal: false,
            contacts: []
        }
    }

    componentDidMount() {
        this.getTripadviserConfig();
    }

    getTripadviserConfig = () => {
        this.setState({ block: true });

        var param = '';

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ contacts: data.rootElement, block: false });
            }
            else this.setState({ block: false });
        }, '/api/hotel/MetaSearch/v1/tripadvisor/config' + param);
    }

    render() {
        const { contacts } = this.state;


        return (
            <StyledCard block={this.state.block} error={this.state.error} title={'Tripadvisor.Title'} error={this.state.error} img={{ src: tripadvisor, height: '16px', className: 'mb-1' }}>
                {contacts && contacts.configuration ?
                    <Row>
                        <Col>
                            {contacts.configuration.emergency_contacts && contacts.configuration.emergency_contacts.length > 0 ?
                                <>
                                    <b> Emergency Contacts </b>

                                    <hr className="my-2" />

                                    <Row>
                                        {contacts.configuration.emergency_contacts.map((contact, idx) => 
                                            <Col>
                                                <Card key={idx} className="border-0 shadow mb-2 p-2">
                                                    <b> {contact.full_name} </b>
                                                    <hr className="my-1" />
                                                    <Row>
                                                        <Col> <span> <b>Email: </b> {contact.email} </span> </Col>
                                                        <Col sm={4}> <span> <b>Phone: </b> {contact.phone_number} </span> </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </>
                                : 'Nenhum contacto de emergência'
                            }
                        </Col>
                        <Col>
                            {contacts.configuration.info_contacts && contacts.configuration.info_contacts.length > 0 ?
                                <div>
                                      <b> Info Contacts </b>

                                    <hr className="my-2" />

                                    <Row>
                                        {contacts.configuration.info_contacts.map((contact, idx) => 
                                            <Col>
                                                <Card key={idx} className="border-0 shadow mb-2 p-2">
                                                    <b> {contact.full_name} </b>
                                                    <hr className="my-1" />
                                                    <Row>
                                                        <Col> <span> <b>Email: </b> {contact.email} </span> </Col>
                                                        <Col sm={4}> <span> <b>Phone: </b> {contact.phone_number} </span> </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                                : 'Nenhum contacto de info'}
                        </Col>
                    </Row>
                : 'Nenhum contacto configurado'}
            </StyledCard>
        );
    }

};
export default injectIntl(Tripadvisor)