import React, { Component } from 'react'
import { Row, Col, Card, CardBody, Badge } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';

class ExperienceCard extends Component {
    render() {
        const { statusOptions, experience, getExperienceDetail, locale } = this.props;
        
        const statusBool = experience?.active ? 'Active' : 'Inactive';

        const title = experience.descriptions ? experience.descriptions.find(d => d.cultureCode === locale)?.title??
        experience.descriptions.find(d => d.cultureCode === 'en-GB')?.title??experience.descriptions[0]?.title : '';

        return (
            <Card className="shadow border-0 m-2" onClick={_ => getExperienceDetail(experience.id)}
                style={{
                    height: "230px",
                    backgroundImage: `url("${experience?.image && experience.image[0]?.url ? experience.image[0].url : ''}")`,
                    color: 'white',
                    backgroundSize: 'cover',
                    transition: 'all .2s ease-out',
                    borderRadius: '8px',
                    cursor: 'pointer',
                    overflow: 'auto'
                }}
            >
                <CardBody style={{ backgroundColor: '#0000007d', borderRadius: '8px' }}>
                    <Col className="h-100 d-flex flex-column justify-content-between">
                        <Row>
                            <Col className="col-6" align="left">
                                <div style={{ fontSize: '1.1em' }}>{title}</div>
                                <div style={{ fontSize: '0.8em' }}> {experience.type} </div>
                            </Col>
                            <Col className="col-6" align="right">
                                {statusOptions.some(el => el.value === statusBool) ?
                                    <Badge color={statusOptions.find(el => el.value === statusBool).color}> {statusOptions.find(el => el.value === statusBool).label} </Badge>
                                : ''}
                            </Col>
                        </Row>
                        {experience.startDate && experience.endDate ?
                            <Row className="mt-2 ">
                                <Col>
                                    <span>{moment(experience.startDate).format('DD-MM-YYYY')}</span>
                                    <span className='mx-1'><FormattedMessage id='AddRule.YieldRuleTo'/></span>
                                    <span>{moment(experience.endDate).format('DD-MM-YYYY')}</span>
                                </Col>
                            </Row>
                        :''}
                    </Col>
                </CardBody>
            </Card>
        )
    }
}

export default injectIntl(ExperienceCard)