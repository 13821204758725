import React, { Component } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import { getAPI, postAPI } from '../Base/API';
import CustomSelect from '../Base/Common/CustomSelect';
import { handleNotification } from '../Base/Notification';
import { HrsMapAllFields } from './HrsMapAllFields';

export class HrsExtraMappingModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: true,
            extraMapping: {
                map1: null,
                map2: null,
                map3: null,
                map4: null,
                map5: null,
                map6: null,
                map7: null,
                map8: null,
                map9: null,
                map10: null
            }
        }
    }

    componentDidMount() {
        const { mappingId } = this.props;

        getAPI(result => {
            const { error, data } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                this.setState({ error: errorMessage, block: false, extraMapping: data.response[0] });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/RateCodeChannel/v1/${mappingId}/details`);
    }

    saveExtraMapping = (evt) => {
        evt.preventDefault();
        const { mappingId } = this.props;
        const { extraMapping } = this.state;

        if (this.form.current.reportValidity()) {
            const req = {
                request: extraMapping
            };

            this.setState({ block: true });

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                    }
                    else if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="ChannelMapping.MappingSaved" />, <FormattedMessage id="EditChannelMapping.Success" />);
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/Rate/RateCodeChannel/v1/${mappingId}/details`, req);
        }
    }

    handleCombo = (name, combo) => {
        const { extraMapping } = this.state;
        extraMapping[name] = combo && combo.value;
        this.setState({ extraMapping });
    }

    handleText = (evt) => {
        if (evt && evt.target) {
            const { extraMapping } = this.state;
            const { name, value } = evt.target;
            extraMapping[name] = value;
            this.setState({ extraMapping });
        }
    }

    render() {
        const { mapHrsAllFields, isOpen, toggle, isHotDeal } = this.props;
        const { block, error, extraMapping } = this.state;

        const breakfastTypes = [
            {
                value: 'exclusive',
                label: <FormattedMessage id="ChannelMapping.Exclusive" />
            },
            {
                value: 'inclusive',
                label: <FormattedMessage id="ChannelMapping.Inclusive" />
            },
            {
                value: 'notAvailable',
                label: <FormattedMessage id="ChannelMapping.NotAvailable" />
            }
        ];

        const cancellationDeadlineOptions = [
            {
                value: "standard",
                label: "Standard"
            },
            {
                value: "1",
                label: `1 ${this.props.intl.formatMessage({ id: "ChannelMapping.daysBeforeArrival" })}`
            },
            {
                value: "2",
                label: `2 ${this.props.intl.formatMessage({ id: "ChannelMapping.daysBeforeArrival" })}`
            },
            {
                value: "3",
                label: `3 ${this.props.intl.formatMessage({ id: "ChannelMapping.daysBeforeArrival" })}`
            },
            {
                value: "7",
                label: `7 ${this.props.intl.formatMessage({ id: "ChannelMapping.daysBeforeArrival" })}`
            },
            {
                value: "15",
                label: `15 ${this.props.intl.formatMessage({ id: "ChannelMapping.daysBeforeArrival" })}`
            },
            {
                value: "30",
                label: `30 ${this.props.intl.formatMessage({ id: "ChannelMapping.daysBeforeArrival" })}`
            },
            {
                value: "45",
                label: `45 ${this.props.intl.formatMessage({ id: "ChannelMapping.daysBeforeArrival" })}`
            },
            {
                value: "90",
                label: `90 ${this.props.intl.formatMessage({ id: "ChannelMapping.daysBeforeArrival" })}`
            }
        ];

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="ChannelMapping.ExtraMapping" values={{ channel: "HRS" }} />
                </ModalHeader>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <form ref={this.form}>
                            <Row className="mb-2">
                                <Col className="text-right">
                                    <Button className="btn btn-host btn-sm" onClick={this.saveExtraMapping}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup row>
                                        <Label for="map1" sm={3}>
                                            <FormattedMessage id="ChannelMapping.Breakfast" />
                                        </Label>
                                        <Col sm={9}>
                                            <CustomSelect options={breakfastTypes} value={breakfastTypes.find(bft => bft.value === extraMapping.map1)} onChange={this.handleCombo.bind(this, 'map1')} required />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            {
                                mapHrsAllFields ?
                                    <HrsMapAllFields extraMapping={extraMapping} handleCombo={this.handleCombo} handleText={this.handleText}>
                                        {
                                            isHotDeal ?
                                                <Input type="number" min="0" name="map6" id="map6" value={extraMapping.map6} onChange={this.handleText} required />
                                                :
                                                <CustomSelect
                                                    options={cancellationDeadlineOptions}
                                                    value={cancellationDeadlineOptions.find(cnOpt => cnOpt.value === extraMapping.map6)}
                                                    onChange={this.handleCombo.bind(this, 'map6')}
                                                    required
                                                />
                                        }
                                    </HrsMapAllFields>
                                    :
                                    <div />
                            }
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(HrsExtraMappingModal);