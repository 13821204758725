import React, { Component } from 'react';
import { Card, CardBody, Badge, Table, UncontrolledTooltip, Modal, ModalHeader, ModalBody,Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Common/ErrorAlert";
import { getChannelConfig } from "../Base/Common/ReferenceDataFunctions"
import ChannelConf from './ChannelConf';
import CustomToolTip from '../../Utils/CustomToolTip';

export class ChannelList extends Component {    

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.updateChannelInstance = this.updateChannelInstance.bind(this);
        this.state = {
            error: null,
            activeTab: '1',
            block: false,
            modal: false,
            modaladd: false,
            ChannelData: [],
            selectedChannel: {}
        };
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }), () => this.props.refreshData());
    }
    
    handleRowSelect = (row, isSelected, e) => {
        if (isSelected) {
            this.setState({ modal: true, selectedChannel: row })
        }
    }

    handleEdit(key) {
        this.setState({ modal: true, selectedChannel: this.props.ChannelData.filter( s => s.id === key)[0] })
    }

    updateChannelInstance(selectedChannel) {
        this.setState({ selectedChannel });
    }

    render() {
        if (this.props.ChannelData) {
            return (
                <div>
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                            <ModalHeader toggle={this.toggleModal}  >
                                <FormattedMessage id="ChannelList.Channel" />
                            </ModalHeader>
                            <ModalBody className="modal-lg px-0 pb-0">
                                <ChannelConf isChannelList={true} selectedChannel={this.state.selectedChannel} toggleModalAdd={this.toggleModal} refreshData={this.props.refreshData} user={this.props.user} updateChannelInstance={this.updateChannelInstance} hotelId={this.props.hotelId} metaSearchChannelsCreated={this.props.metaSearchChannelsCreated}/>
                            </ModalBody>
                        </Modal>
                        <Row><Col>
                        <Card className="border-0">
                            <CardBody className="p-1 small ">                             
                                    {this.props.ChannelData && this.props.ChannelData.map((item, key) => {
                                        const mappingNeededCount = Math.abs(item.mappedChannelDataCount - item.mappedNotNeededCount);

                                        return <Row key={key} className="my-3 px-1 border-bottom" onClick={this.handleEdit.bind(this, item.id)}>
                                            <Col className="col-2">
                                                {getChannelConfig(item.code) ? <img alt="" src={getChannelConfig(item.code).image} height={getChannelConfig(item.code).iconSize}></img> : ''}<strong> {item.channelName} </strong>
                                                <span className="text-muted"> - {item.name} </span>
                                            </Col>
                                            <Col className="col-2">
                                                <Row className="mb-2">
                                                    <Col>
                                                        {!item.isIcal && !item.hasChannelData ?
                                                            item.mappedRateCodeChannelCount !== mappingNeededCount ?
                                                                <span>
                                                                    <Badge color="white" className="text-warning" pill id={'channelwarning' + key}>
                                                                        <i className="fas fa-2x fa-exclamation-triangle"></i>
                                                                    </Badge>
                                                                    <CustomToolTip placement="right" target={'channelwarning' + key} >
                                                                        <FormattedMessage id="ChannelList.NumberDoesNotMatch" />
                                                                    </CustomToolTip >
                                                                    <strong>
                                                                        <FormattedMessage id="ChannelList.Mapped" />: {item.mappedRateCodeChannelCount} / {item.mappedNotNeededCount ? <span> {mappingNeededCount} <span className="text-muted"> / {item.mappedNotNeededCount} </span></span> : item.mappedChannelDataCount }
                                                                    </strong>
                                                                </span>
                                                                :
                                                                <span>
                                                                    <strong>
                                                                        <FormattedMessage id="ChannelList.Mapped" />: {item.mappedRateCodeChannelCount} / {item.mappedNotNeededCount ? <span> {mappingNeededCount} <span className="text-muted"> / {item.mappedNotNeededCount} </span></span> : item.mappedChannelDataCount}
                                                                    </strong>
                                                                </span>
                                                            :
                                                            <span>
                                                                <strong>
                                                                    <FormattedMessage id="ChannelList.Mapped" />: {item.mappedRateCodeChannelCount}
                                                                </strong>
                                                            </span>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row className="mb-2">
                                                    <Col>
                                                        <strong><FormattedMessage id="ChannelList.PropertyCode" />: </strong> {item.propertyCode}
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="col-6">
                                                <Badge className="mr-2 p-2 mb-2" color={item.sendAvailability ? 'primary' : 'secondary'}>
                                                    <FormattedMessage id="ChannelList.SendAvailability" />
                                                </Badge>
                                                <Badge className="mr-2 p-2 mb-2" color={item.sendPrice ? 'primary' : 'secondary'}>
                                                    <FormattedMessage id="ChannelList.SendPrice" />
                                                </Badge>
                                                <Badge className="mr-2 p-2 mb-2" color={item.sendCta ? 'primary' : 'secondary'}>
                                                    <FormattedMessage id="ChannelList.SendCta" />
                                                </Badge>
                                                <Badge className="mr-2 p-2 mb-2" color={item.sendCtd ? 'primary' : 'secondary'}>
                                                    <FormattedMessage id="ChannelList.SendCtd" />
                                                </Badge>
                                                <Badge className="mr-2 p-2 mb-2" color={item.sendMaxStay ? 'primary' : 'secondary'}>
                                                    <FormattedMessage id="ChannelList.SendMaxStay" />
                                                </Badge>
                                                <Badge className="mr-2 p-2 mb-2" color={item.sendMinStay ? 'primary' : 'secondary'}>
                                                    <FormattedMessage id="ChannelList.SendMinStay" />
                                                </Badge>
                                                <Badge className="mr-2 p-2 mb-2" color={item.sendStopSales ? 'primary' : 'secondary'}>
                                                    <FormattedMessage id="ChannelList.SendStopSales" />
                                                </Badge>
                                                <Badge className="mr-2 p-2 mb-2" color={item.sendMinAdvancedBookingOffset ? 'primary' : 'secondary'}>
                                                    <FormattedMessage id="ChannelList.SendMinAdvancedBookingOffset" />
                                                </Badge>
                                                <Badge className="mr-2 p-2 mb-2" color={item.sendMaxAdvancedBookingOffset ? 'primary' : 'secondary'}>
                                                    <FormattedMessage id="ChannelList.SendMaxAdvancedBookingOffset" />
                                                </Badge>
                                                {item.code === "TRAVELCLICK" ?
                                                    <Badge className="mr-2 p-2 mb-2" color={item.sendOverbookingAvailability ? 'primary' : 'secondary'}>
                                                        <FormattedMessage id="ChannelList.SendOverbookingAvailability" />
                                                    </Badge>
                                                    : ''}
                                            </Col>
                                            <Col>
                                                <h6>
                                                    {
                                                        item.active ? <Badge color="primary" pill><FormattedMessage id="ChannelDashboard.Active" /></Badge> : <Badge color="secondary" pill><FormattedMessage id="ChannelDashboard.Inactive" /></Badge>
                                                    }
                                                </h6>
                                                {item.channelConfigState ? <h6><Badge color="info" pill> {item.channelConfigState} </Badge></h6> : ''}
                                            </Col>
                                        </Row>
                                    })}
                                   
                            </CardBody>
                            </Card>
                        </Col></Row>
                    </BlockUi>
                </div>
            );
        } else {
            return (
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                </BlockUi>
            )
        }
    }
}