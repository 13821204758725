import React, { Component } from 'react';
import { Row, Col, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CustomInput, Label } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from "moment";
import { getAPI, postAPI, putAPI } from "../Base/API"
import { handleNotification } from "../Base/Notification";
import CustomSelect from '../Base/Common/CustomSelect';
import { getBookingMisconductCategories, getBookingMisconductSubCategories, getConvertedDateToHotelTimezone, getIsHotelBeds, getPmsTypes, copyToClipboard } from '../Base/Common/ReferenceDataFunctions';
import BlockUi from 'react-block-ui';
import { ReservationHist } from "./ReservationHist";
import ReservationDetailView from './ReservationDetailView';
import ConfirmCancellation from './ConfirmCancellation';
import ReasonModal from '../SAPO/ReasonModal';
import UpdateProfile from '../SAPO/UpdateProfile';
import { ErrorAlert } from "../Common/ErrorAlert";
import { DateRangePicker } from 'react-dates';
import DepositModal from './DepositModal';
import { CheckAuthorization } from '../Base/Authorization';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import CancelAirbnbResModal from './Airbnb/CancelAirbnbResModal';
import { CcModal } from './CcModal';
import { CcAccessModal } from './CcAccessModal';
import RemapReservationModal from './RemapReservationModal';
import { ReservationCommStatusIcon, ReservationStatusBadge, reservationStatus } from '../Base/Common/CommonUIComponents';

class ReservationDetail extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.toggleOK = this.toggleOK.bind(this);
        this.toggleHistoricModal = this.toggleHistoricModal.bind(this);
        this.handleCheckInDatesChange = this.handleCheckInDatesChange.bind(this);
        this.resendToPms = this.resendToPms.bind(this);
        this.getRateCodes = this.getRateCodes.bind(this);
        this.formMisconduct = React.createRef();
        this.formNoShow = React.createRef();
        this.formChanges = React.createRef();

        const isHotelBeds = getIsHotelBeds(this.props.data?.source);
        this.state = {
            block: false,
            activeTab: '1',
            Alerts: [],
            reservation: this.props.data,
            confirmModal: false,
            waived_fees: 'no',
            waived_fees_List: [{ label: 'yes' , value: 'yes' }, { label: 'no' , value: 'no' }],
            reportAction: null,
            no_show_all: null,
            historicModal: false,
            reasonModal: false,
            updateProfileModal: false,
            modalType: null,
            depositModal: null,
            rateCodes: [],
            cancellationModal: false,
            cancelAirbnbResModal: false,
            ccModal: false,
            ccAccessModal: false,
            hidePmsData: global.operationMode === 'None' && global.reservationDelivery === 'None',
            remapReservationModal: false,
            hotelReservationToRemap: [],
            hasMappings: this.props.data?.hotelReservation && Array.isArray(this.props.data.hotelReservation) && this.props.data.hotelReservation.flatMap(hr => hr.hotelRateCode).filter(hrc => hrc.rateCodeChannelId !== null).length > 0,
            isNone: global.operationMode === 'None',
            isHotelBeds: isHotelBeds,
            verifyMappingsUrl: isHotelBeds ? `/api/Price/Reservation/v1/reservation/${this.props.data.id}/mappings/automatic/hotelbeds` : `/api/Price/Reservation/v1/reservation/${this.props.data.id}/mappings/automatic`,
            isBookingSpaceBeds: global.PMSType === getPmsTypes()[2].value,
            isSendRoomRate: global.sendRoomRate,
            isNewHotel: global.PMSType === getPmsTypes()[3].value
        };        
    }

    componentDidMount() {
        if (this.state.reservation.id !== null) {
            this.getRateCodes(this.state.reservation.channelInstanceId);
        }
    }

    getRateCodes(channelInstanceId) {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                this.setState({
                    rateCodes: data.response.map(resp => {
                        resp.value = resp.id;
                        resp.label = <FormattedMessage id="ReservationDetail.RateCodeLabel" values={{ roomCode: resp.localRoomCode, rateCode: resp.localRateCode }} />;
                        return resp;
                    })
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Rate/RateCodeChannel/v1/ChannelInstance/${channelInstanceId}`);
    }

    reportMisconduct(event) {
        var req = null;

        event.preventDefault();
        if (!this.formMisconduct.current.reportValidity()) {
            return;
        }

        if (this.state.reportAction === 'guest_misconduct') {
            req = {
                reservationId: this.props.data.resIdValue,
                report: this.state.reportAction,
                misconductDetails: {
                    category_id: this.state.misconductCategory,
                    subcategory_id: this.state.misconductSubCategory,
                    details_text: this.state.misconductText,
                    escalate_report: this.state.escalate_report ? '1' : '0',
                    rebooking_allowed: this.state.rebooking_allowed ? '1' : '0'
                }
            }
        }
        else {
            handleNotification({}, <FormattedMessage id="ReservationDetail.NoActionSelected" />, 'Error');
            return;
        }

        this.setState({ block: true });
        postAPI(result => {
            const { data, error, isLoading } = result;
             
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors.length > 0)
                    handleNotification(data, "Report", <FormattedMessage id="General.Success" />);
                else {
                    handleNotification(data, <FormattedMessage id="ReservationDetail.ReportSentToBooking" />, <FormattedMessage id="General.Success" />);
                }
                this.setState({ block: false });
            }
        }, '/api/Rate/BookingReporting/v1/guestMisconductPostReporting', req);
    }

    reportChanges(event) {
         var req = null;

         event.preventDefault();
         if (!this.formChanges.current.reportValidity()) {
             return;
         }

         if (this.state.reportAction === 'stay_changed') {
             req = {
                 reservationId: this.props.data.resIdValue,
                 stay_change: this.state.reservation.hotelReservation.map(hotelReservation => {
                     const stayChange = {
                         roomreservation_id: hotelReservation.externalId,
                         checkin: moment(hotelReservation.checkIn).format('YYYY-MM-DD'),
                         checkout: moment(hotelReservation.checkOut).format('YYYY-MM-DD'),
                         price: hotelReservation.amountAfterTax.toString()
                     };
                     return stayChange;
                 })
             };
         }
         else {
             handleNotification({}, <FormattedMessage id="ReservationDetail.NoActionSelected" />, 'Error');
             return;
         }
         this.setState({ block: true });
         postAPI(result => {
             const { data, error, isLoading } = result;
            
             if (error) {
                 var errorMessage = [];
                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errorMessage, block: false });

                 return;
             }
             if (data) {
                 if (data.errors.length > 0)
                     handleNotification(data, "Report", 'Erro');
                 else {
                     handleNotification(data, <FormattedMessage id="ReservationDetail.ReportSentToBooking" />, <FormattedMessage id="General.Success" />);
                 }
                 this.setState({ block: false });

             }
         }, '/api/Rate/BookingReporting/v1/stayChangedPostReporting', req);
    }

    reportCC(event) {

         var req = null;
        event.preventDefault();
        
         if (this.state.reportAction === 'cc_is_invalid' || this.state.reportAction === 'cancel_reservation_invalid_cc') {
             req = {
                 reservationId: this.props.data.resIdValue,
                 report: {
                     action: this.state.reportAction
                 }
             }
         } else if (this.state.reportAction === 'is_no_show') {
             if (this.state.no_show_all) {
                 var el = document.getElementById('noShowRoom');

                 if (!this.state.noShowRoomStay) {

                     el.setCustomValidity("Please Choose one of the rooms")

                 } else {
                     el.setCustomValidity("");
                 }
             }
             if (!this.formNoShow.current.reportValidity()) {
                 return;
             }
             if (this.state.noShowRoomStay) {
                 req = {
                     reservationId: this.props.data.resIdValue,
                     report: {
                         action: this.state.reportAction,
                         is_no_show: {
                             waived_fees: this.state.waived_fees,
                             roomreservation_id: this.state.noShowRoomStay
                         }
                     }
                 }
             } else {
                 req = {
                     reservationId: this.props.data.resIdValue,
                     report: {
                         action: this.state.reportAction,
                         waived_fees: this.state.waived_fees
                     }
                    }
             }
         
         
         } else {
             handleNotification({}, <FormattedMessage id="ReservationDetail.NoActionSelected" />, 'Error');
             return 
         }
         this.setState({ block: true });
         postAPI(result => {
             const { data, error } = result;
            
             if (error) {
                var errorMessage = [];
                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errorMessage, block: false });

               
                 return;
             }
             if (data) {
                 if (data.errors && data.errors.length > 0)
                     handleNotification(data, "Report", <FormattedMessage id="General.Success" />);
                 else {
                     handleNotification(data, <FormattedMessage id="ReservationDetail.ReportSentToBooking" />, <FormattedMessage id="General.Success" />);
                 }
                 this.setState({ block: false });
             }
         }, '/api/Rate/BookingReporting/v1/invalidCreditCard', req);
     }

    toggleConfirmModal(type) {
        this.setState(prevState => ({
                confirmModal: !prevState.confirmModal,
            reportAction: type,
            waived_fees: 'no',
            category_id: null,
            subcategory_id: null,
            details_text: null,
            escalate_report: null,
            rebooking_allowed: null,
            noShowRoomStay:null
        }));
    }

    toggleOK(event) {

         //this.setState({});
         if (this.state.reportAction === 'cc_is_invalid' || this.state.reportAction === 'cancel_reservation_invalid_cc' || this.state.reportAction === 'is_no_show') {
             this.reportCC(event);
         }
         else if (this.state.reportAction === 'stay_changed') {
             this.reportChanges(event);
         }
         else if (this.state.reportAction === 'guest_misconduct') {
             this.reportMisconduct(event);
         }
         else {
             handleNotification({}, <FormattedMessage id="ReservationDetail.NoActionSelected" />, 'Error');
             return;
         }
         //this.toggleConfirmModal();
    }

    setComboStatus = (evt, inputValue, actionMeta) => {
         var targetValue = '';
         if (Array.isArray(inputValue)) {
             targetValue = inputValue.map(el => el.value).join(',');
         } else {
             targetValue = inputValue ? inputValue.value : '';
         }

         this.setState({ [evt]: targetValue })
     }    

    toggleHistoricModal() {
        this.setState(prevState => ({ historicModal: !prevState.historicModal }));
    }

    resendToPms() {
        this.setState({ block: true });

        var resId = [this.state.reservation.id];

        postAPI(result => {
            const { error, data } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            handleNotification(data, <FormattedMessage id="ReservationDetail.ResendToPMSSuccessMessage" />, <FormattedMessage id="General.Success" />);
            const { reservation } = this.state;
            reservation.communicationStatus = null;
            this.setState({ block: false, errorMessage: errorMessage, reservation: reservation });
        }, `/api/Price/Reservation/v1/ReSendReservationToPMS`, resId);
    }

    handleIntFieldChange = (name, id, evt) => {
        const value = evt.target ? evt.target.value : null;
        const hotelReservations = this.state.reservation.hotelReservation;
        const idx = hotelReservations && hotelReservations.indexOf(hotelReservations.find(hotelReservation => hotelReservation.id == id));
        const hotelReservation = hotelReservations[idx];
        hotelReservation[name] = value;
        this.setState(({ hotelReservation: hotelReservations }));
    }

    handleCheckInDatesChange(checkIn, checkOut, id) {
        const hotelReservations = this.state.reservation.hotelReservation;
        const idx = hotelReservations && hotelReservations.indexOf(hotelReservations.find(hotelReservation => hotelReservation.id == id));
        const hotelReservation = hotelReservations[idx];
        hotelReservation.checkIn = checkIn;
        hotelReservation.checkOut = checkOut;
        this.setState(({ hotelReservation: hotelReservations }));
    }
    
    sendEmail = () => {
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ReservationDetail.ConfirmEmailSent" />, <FormattedMessage id="General.Success" />);
                }
                this.setState(({ block: false }));
            }
        }, `/api/Price/Reservation/v1/reservation/${this.props.data.id}/${this.props.data.channelConfigName.toUpperCase()}/notification`);
    }

    updateData = (data) => {
        this.setState({ block: true });

        this.setState({
            reservation: data
        });

        this.setState({ block: false });
    }
    
    updateReservationStatus = (status) => {
        this.setState({ block: true });

        this.setState({
            reservation: {
                ...this.state.reservation,
                status
            }
        });

        this.setState({ block: false });
    }

    toggleModalGeneric = (modal, type) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            modalType: type
        }));
    }

    checkPayment = (idx) => {
        const { reservation } = this.state;
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    handleNotification(data, <FormattedMessage id="Payments.CheckPaymentSuccess" />, <FormattedMessage id="General.Success" />);
                }
                if (data.response && data.response.length > 0) {
                    reservation.payments[idx] = data.response[0];
                    this.setState({ reservation, error: errorMessage, block: false });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState(({ error: errorMessage, block: false }));
            }
        }, `/api/Price/Payment/v1/checkPayment/${reservation.payments[idx].id}`);
    }

    addPayment = (payment) => {
        const { reservation } = this.state;
        reservation.payments.unshift(payment);
        this.setState({ reservation });
    }

    updatePayment = (payment, idx) => {
        const { reservation } = this.state;
        reservation.payments[idx] = payment;
        this.setState({ reservation });
    }

    updateRefunds = (newRefund, idx) => {
        const { reservation } = this.state;
        if (!reservation.payments[idx].reservationPaymentRefund) {
            reservation.payments[idx].reservationPaymentRefund = [];
        }
        newRefund.createdAt = moment(newRefund.createdAt).format("YYYY-MM-DD HH:mm:ss");
        reservation.payments[idx].reservationPaymentRefund.push(newRefund);
        this.setState({ reservation });
    }

    verifyMappings = () => {
        const { reservation, verifyMappingsUrl, rateCodes } = this.state;
        
        const body = {
            request: {
                reservationRoomStayIds: reservation
                    .hotelReservation
                    .filter(rs => rs.hotelRateCode !== null && rs.hotelRateCode.length > 0 && rs.hotelRateCode.some(rr => rr.rateCodeChannelId === null || !rateCodes.some(rc => rc.id === rr.rateCodeChannelId)))
                    .map(rs => rs.id)
            }
        };

        this.setState({ block: true });
        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ReservationDetail.SuccessfulRemap" />, <FormattedMessage id="General.Success" />);
                    reservation.hotelReservation = data.response;
                }
                this.setState({ block: false, reservation });
            }
            else {
                this.setState(({ block: false }));
            }
        }, verifyMappingsUrl, body);
    }

    toggleRemapReservationModal = () => {
        this.setState(prevState => ({ remapReservationModal: !prevState.remapReservationModal, hotelReservationToRemap: JSON.parse(JSON.stringify(prevState.reservation.hotelReservation)) }));
    }

    handleChangeMapping = (combo, evt, roomStayIdx) => {
        const { hotelReservationToRemap } = this.state;

        if (evt) {
            hotelReservationToRemap[roomStayIdx].hotelRateCode.forEach(rateCode => rateCode.rateCodeChannelId = combo && combo.value);

            this.setState({ hotelReservationToRemap });
        }
    }

    saveNewMappings = () => {
        const { reservation, hotelReservationToRemap } = this.state;
        const body = {
            request: reservation
        };
        body.request.hotelReservation = hotelReservationToRemap;

        this.setState({ block: true });
        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage, block: false }));
                }
                else {
                    handleNotification(data, <FormattedMessage id="ReservationDetail.SuccessfulRemap" />, <FormattedMessage id="General.Success" />);
                    this.setState(({ error: errorMessage, block: false, reservation: body.request }));
                }
            }
            else {
                this.setState(({ error: errorMessage, block: false }));
            }
        }, `/api/Price/Reservation/v1/reservation/${this.props.data.id}/mappings/manual`, body);
    }

    confirmReservation = () => {
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                }
                if (data.response && data.response.length > 0) {
                    var reservation = { ...this.props.data };
                    Object.assign(reservation, data.response[0]);

                    this.props.updateTable && this.props.updateTable(reservation, 'reservation');
                    this.updateData(reservation);
                    handleNotification(data, <FormattedMessage id="ReservationDetail.ConfirmReservation" />, <FormattedMessage id="General.Success" />);
                }
            }
            this.setState(({ block: false }));
        }, `/api/Price/Reservation/v1/reservation/${this.props.data.id}/heybe/create`);
    }

    render() {
        const { block, error, reservation, hidePmsData, rateCodes, isNone, isHotelBeds, isBookingSpaceBeds, isSendRoomRate, isNewHotel, hotelReservationToRemap, confirmModal, historicModal } = this.state;
        const { data, modal, action, history, lastDigits } = this.props;

        const mappings = reservation ? reservation.hotelReservation
            .filter(rs => rs.hotelRateCode !== null && rs.hotelRateCode.length > 0 && rs.hotelRateCode.some(rr => rr.rateCodeChannelId === null || !rateCodes.some(rc => rc.id === rr.rateCodeChannelId)))
            .map(rs => rs.id) : null;

        const showVerifyMappings = mappings && mappings.length > 0;

        const resMenu = (
            <div>
                <UncontrolledButtonDropdown>
                    <DropdownToggle tag="div" className="cursor-pointer py-1 pl-2">
                        <i className="fas fa-ellipsis-v"/>
                    </DropdownToggle>
                    {data ?
                        <DropdownMenu right>
                            <DropdownItem onClick={this.resendToPms}>
                                <i className="fas fa-cloud-upload-alt mr-1"/>
                                <FormattedMessage id="ReservationDetail.ResendToPMS"/>
                            </DropdownItem>
                            
                            <DropdownItem onClick={this.toggleHistoricModal}>
                                <i className="fas fa-history mr-1"/>
                                <FormattedMessage id="ReservationDetail.History"/>
                            </DropdownItem>
    
                            {data.channelConfigName === 'sapo' || data.channelConfigName === 'HeyBE'  ?
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={this.confirmReservation} disabled={!(reservation.status === reservationStatus[3].value) || reservation.hotelReservation && reservation.hotelReservation.length > 0 && moment(moment(reservation.hotelReservation[0].checkIn).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD"))}>
                                        <FormattedMessage id="ReservationDetail.CreateReservation" />
                                    </DropdownItem>
                                    <DropdownItem onClick={() => this.toggleModalGeneric('reasonModal', 'cancel')} disabled={reservation.status === "Canceled" || reservation.hotelReservation && reservation.hotelReservation.length > 0 && moment(moment(reservation.hotelReservation[0].checkIn).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD"))}>
                                        <FormattedMessage id="ReservationDetail.CancelReservation" />
                                    </DropdownItem>
                                    <DropdownItem onClick={() => this.toggleModalGeneric('updateProfileModal', null)} disabled={reservation.hotelReservation && reservation.hotelReservation.length > 0 && moment(moment(reservation.hotelReservation[0].checkIn).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD"))}>
                                        <FormattedMessage id="ReservationDetail.UpdateProfile" />
                                    </DropdownItem>
                                    <DropdownItem onClick={this.sendEmail} >
                                        <FormattedMessage id="ReservationDetail.SendEmail" />
                                    </DropdownItem>
                                    <DropdownItem onClick={() => this.toggleModalGeneric('reasonModal', 'noShow')} disabled={reservation.status === "Canceled" || reservation.hotelReservation && reservation.length > 0 && !moment(moment()).isBetween(moment(reservation.hotelReservation[0].checkIn), moment(reservation.hotelReservation[0].checkIn).add(2, 'days'))}>
                                        <FormattedMessage id="ReservationDetail.ChangeToNoShow" />
                                    </DropdownItem>
                                    {data && data.channelConfigName === 'sapo' && CheckAuthorization("reservation:deposit") ?
                                        <>
                                            <DropdownItem onClick={() => this.toggleModalGeneric('depositModal', 'edit')} disabled={reservation.reservationDeposit && reservation.reservationDeposit.isReturned}>
                                                <FormattedMessage id="ReservationDetail.ResgisterDeposit" />
                                            </DropdownItem>
                                            <DropdownItem onClick={() => this.toggleModalGeneric('depositModal', 'return')} disabled={reservation.reservationDeposit === null || reservation.reservationDeposit && reservation.reservationDeposit.isReturned}>
                                                <FormattedMessage id="ReservationDetail.ReturnDeposit" />
                                            </DropdownItem>
                                        </>
                                    : ""}
                                </>
                            : data.channelConfigName === 'Booking.com' ?
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        onClick={this.toggleConfirmModal.bind(this, 'cc_is_invalid')}
                                        disabled={data.hotelReservation.find(item => moment(item.checkIn).isAfter(moment(), 'day')) === undefined}
                                    >
                                        <FormattedMessage id="ReservationDetail.ReportInvalidCreditCard" />
                                    </DropdownItem>
                                    <DropdownItem onClick={this.toggleConfirmModal.bind(this, 'cancel_reservation_invalid_cc')}>
                                        <FormattedMessage id="ReservationDetail.CancelDueToInvalidCreditCard" />
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.toggleConfirmModal.bind(this, 'is_no_show')}
                                        disabled={data.hotelReservation.find(item => moment(item.checkIn).isAfter(moment(), 'day') && moment(item.checkIn).isBefore(moment().add(2, 'days'), 'day')) === undefined}
                                    >
                                        <FormattedMessage id="ReservationDetail.CancelDueToNoShow" />
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem
                                        onClick={this.toggleConfirmModal.bind(this, 'guest_misconduct')}
                                        disabled={data.hotelReservation.find(item => moment(item.checkIn).isAfter(moment(), 'day') && moment(item.checkOut).isBefore(moment().add(8, 'days'), 'day')) === undefined}
                                    >
                                        <FormattedMessage id="ReservationDetail.Misconduct" />
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={this.toggleConfirmModal.bind(this, 'stay_changed')}
                                        disabled={data.hotelReservation.find(item => moment(item.checkIn).isAfter(moment(), 'day')) !== undefined}
                                    >
                                        <FormattedMessage id="ReservationDetail.ChangesToCheckoutDateAndOrPrice" />
                                    </DropdownItem>
                                </>
                            : data.channelConfigName === 'Hey!Travel' && global.modules && global.modules.some(m => m === 'ManualReservations') ?
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => history.push(`/ManualReservation/${reservation.id}`)}
                                        disabled={reservation.status === "Canceled" || reservation.hotelReservation && moment(moment(reservation.hotelReservation[0].checkIn).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD"))}>
                                        <FormattedMessage id="ReservationDetail.EditReservation" />
                                    </DropdownItem>
                                    <DropdownItem onClick={() => this.toggleModalGeneric('cancellationModal', 'cancel')}
                                        disabled={reservation.status === "Canceled" || reservation.hotelReservation && moment(moment(reservation.hotelReservation[0].checkIn).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD"))}>
                                        <FormattedMessage id="ReservationDetail.CancelReservation" />
                                    </DropdownItem>
                                </>
                            : data.channelConfigName === 'Airbnb' && CheckAuthorization("airbnbReservation:cancel") ?
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={() => this.toggleModalGeneric('cancelAirbnbResModal', 'cancel')} disabled={reservation.status === "Canceled" || reservation.hotelReservation && moment(moment(reservation.hotelReservation[0].checkIn).format("YYYY-MM-DD")).isSameOrBefore(moment().format("YYYY-MM-DD"))}>
                                        <FormattedMessage id="ReservationDetail.CancelReservation" />
                                    </DropdownItem>
                                </>
                            : CheckAuthorization("reservation:cc") && lastDigits ?
                                <>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={_ => this.toggleModalGeneric('ccModal')}>
                                        <FormattedMessage id="ReservationDetail.CcData" />
                                    </DropdownItem>
                                    <DropdownItem onClick={() => this.toggleModalGeneric('ccAccessModal')}>
                                        <FormattedMessage id="ReservationDetail.CcHistoric" />
                                    </DropdownItem>
                                </>
                            :''}
                        </DropdownMenu>
                    :''}
                </UncontrolledButtonDropdown>
            </div>
        );
        
        return (
            <>
                <Modal isOpen={modal} toggle={action} fade={false} className="modal-lg">
                    <BlockUi tag="div" blocking={block}>
                        <ErrorAlert error={error} />
                        <ModalHeader toggle={action}>
                            <FormattedMessage id="ReservationDetail.ReservationDetails" />
                        </ModalHeader>
                        <ModalBody className="modal-lg pb-2">
                            {reservation ?
                                <CardBody className="py-0 small">
                                    <Row>
                                        <Col className={`d-flex ${global.isMobile ? 'align-items-center justify-content-between px-0' : 'pt-2'}`}>
                                            <h6 className='m-0'>
                                                <span className="fas fa-hotel mr-1" />
                                                <FormattedMessage id="ReservationDetail.Reservation" />
                                                {CheckAuthorization("reservationDetail:createdAt:view", null) && data.createdAt ?
                                                    <span className="text-muted small">
                                                        <span className='mx-1'>-</span>
                                                        <FormattedMessage id="ReservationDetail.CreatedAt"/>
                                                        <span>
                                                            {getConvertedDateToHotelTimezone(data.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                                                        </span>
                                                    </span>
                                                    :''}
                                            </h6>
                                            {global.isMobile ?
                                                resMenu
                                                :''}
                                        </Col>
                                        <Col className={`${global.isMobile ? 'my-3' : 'mb-3'} px-0 px-lg-3 col-lg-6 col-12 d-flex justify-content-between`}>
                                            <div style={{ width: (global.isMobile ? '100%' : '95%') }}>
                                                <Row className='d-flex align-items-center justify-content-between'>
                                                    <Col className='text-muted pr-0' >
                                                        <FormattedMessage id="ReservationDetail.ReservationStatus"/>
                                                    </Col>
                                                    <Col className='text-muted text-center col-5' >
                                                        <FormattedMessage id="ReservationDetail.PMSIntegrationStatus"/>
                                                    </Col>
                                                    <Col className='text-muted text-right pl-0'>
                                                        <FormattedMessage id="ReservationDetail.ReservationId"/>
                                                    </Col>
                                                </Row>
                                                <Row className='d-flex align-items-center justify-content-between'>
                                                    <Col className="pr-0">
                                                        <ReservationStatusBadge status={data.status} isAbandoned={data.isAbandoned} resIdValue={data.resIdValue}/>
                                                    </Col>
                                                    <Col className=" text-center col-5">
                                                        <ReservationCommStatusIcon status={data.communicationStatus}/>
                                                    </Col>
                                                    <Col className='text-right text-truncate pl-0 pointer' onClick={_ => copyToClipboard(reservation.resIdValue)}>
                                                        <h6 className='m-0 text-muted'>{reservation.resIdValue}</h6>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {!global.isMobile ?
                                                resMenu
                                                :''}
                                        </Col>
                                    </Row>
                                    <ReservationDetailView
                                        data={reservation}
                                        rateCodes={rateCodes}
                                        isNone={isNone}
                                        hidePmsData={hidePmsData}
                                        toggleRemapReservationModal={this.toggleRemapReservationModal}
                                        isHotelBeds={isHotelBeds}
                                        isBookingSpaceBeds={isBookingSpaceBeds}
                                        isSendRoomRate={isSendRoomRate}
                                        isNewHotel={isNewHotel}
                                        checkPayment={this.checkPayment}
                                        updatePayment={this.updatePayment}
                                        updateRefunds={this.updateRefunds}
                                        addPayment={this.addPayment}
                                    />
                                </CardBody>
                                :''}
                        </ModalBody>
                    </BlockUi>
                </Modal>

                <Modal isOpen={confirmModal} toggle={this.toggleConfirmModal.bind(this)} size="modal-sm">
                    <ModalBody >
                        <BlockUi tag="div" blocking={this.state.block}>
                            {this.state.reportAction === 'cc_is_invalid' ?
                                <div>
                                    <Row>
                                        <Col>
                                            <h5>
                                                <FormattedMessage id="ReservationDetail.ReportInvalidCreditCard" />
                                            </h5>
                                        </Col>
                                    </Row>
                                    <hr/>
                                    <Row className="lead">
                                        <Col>
                                            <p>
                                                <FormattedMessage id="ReservationDetail.ReportInvalidCreditCardParagraphOne" />
                                            </p>
                                            <p>
                                                <FormattedMessage id="ReservationDetail.ReportInvalidCreditCardParagraphTwo" />
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                                : ''}
                            {this.state.reportAction === 'cancel_reservation_invalid_cc' ?
                                <div>
                                    <Row>
                                        <Col>
                                            <h5>
                                                <FormattedMessage id="ReservationDetail.CancelDueToInvalidCreditCard" />
                                            </h5>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="lead">
                                        <Col>
                                            <p>
                                                <FormattedMessage id="ReservationDetail.CancelDueToInvalidCreditCardParagraphOne" />
                                            </p>
                                            <p>
                                                <FormattedMessage id="ReservationDetail.CancelDueToInvalidCreditCardParagraphTwo" />
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                                : ''}
                            {this.state.reportAction === 'is_no_show' ?
                                <div>
                                    <form ref={this.formNoShow}>
                                        <Row>
                                            <Col>
                                                <FormattedMessage id="ReservationDetail.CancelDueToNoShow" />
                                            </Col>
                                        </Row>
                                        <hr />
                                        <Row>
                                            <Col>
                                                <p>
                                                    <FormattedMessage id="ReservationDetail.CancelDueToNoShowParagraphOne" />
                                                </p>
                                                <p>
                                                    <FormattedMessage id="ReservationDetail.CancelDueToNoShowParagraphTwo" />
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <CustomInput type="switch" id="no_show_all" label={<FormattedMessage id="ReservationDetail.CancelDueToNoShowNoShowForSpecificRooms" />} onChange={(e) => this.setState({ no_show_all: e.target.checked  })} />
                                            </Col>
                                            <Col>
                                                <CustomInput type="switch" id="waived_fees" label={<FormattedMessage id="ReservationDetail.CancelDueToNoShowWaiveFees" />} onChange={(e) => this.setState({ waived_fees: e.target.checked })} />
                                            </Col>
                                        </Row>
                                        {
                                            this.state.no_show_all && this.props.data.hotelReservation && this.props.data.hotelReservation.map((item, key) =>
                                                <Row className="mb-1">
                                                    <Col>
                                                        <CustomInput type="radio" required id="noShowRoom" value={item.externalId} checked={this.state.noShowRoomStay === item.externalId} onChange={(e) => this.setState({ noShowRoomStay: e.currentTarget.value })} />
                                                    </Col>
                                                    <Col>{key}</Col>
                                                    <Col>{item.channelRoomTypeCode}</Col>
                                                    <Col>{item.hotelRateCode && item.hotelRateCode.length > 0 && item.hotelRateCode[0].ratePlanCode}</Col>
                                                    <Col>{item.checkIn} </Col>
                                                    <Col>{item.checkOut} </Col>
                                                    <Col>{item.amountAfterTax} </Col>
                                                </Row>
                                            )
                                        }

                                    </form>
                                </div>
                                : ''}
                            {this.state.reportAction === 'stay_changed' ?
                                <div>
                                    <form ref={this.formChanges}>
                                        <Row>
                                            <Col>
                                                <FormattedMessage id="ReservationDetail.ChangesToCheckoutDateAndOrPrice" />
                                            </Col>
                                        </Row>
                                        <hr />
                                        <p>
                                            <FormattedMessage id="ReservationDetail.ChangesToCheckoutDateAndOrPriceParagraphOne" />
                                        </p>
                                        <p>
                                            <FormattedMessage id="ReservationDetail.ChangesToCheckoutDateAndOrPriceParagraphTwo" />
                                        </p>
                                        <hr />
                                        {
                                            this.state.reservation.hotelReservation && this.state.reservation.hotelReservation.map((item, key) =>
                                                <Row className="mb-1">
                                                    <Col className="col-2">
                                                        <Label for="roomReservationId">
                                                            <FormattedMessage id="ReservationDetail.ReservationRoomId" />
                                                        </Label>
                                                    </Col>
                                                    <Col className="col-2">{item.externalId}</Col>
                                                    <Col className="col-2">
                                                        <Label for="checkInAndCheckOut">
                                                            <FormattedMessage id="ReservationDetail.CheckInAndCheckOut" />
                                                        </Label>
                                                    </Col>
                                                    <Col className="col-3">
                                                        <DateRangePicker
                                                            startDate={item.checkIn ? moment(item.checkIn).locale(moment.locale()) : null} // momentPropTypes.momentObj or null
                                                            startDateId="your_unique_start_date_id"
                                                            isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment().add(6, 'month')}
                                                            endDate={item.checkOut ? moment(item.checkOut).locale(moment.locale()) : null}
                                                            endDateId="your_unique_end_date_id"
                                                            onDatesChange={({ startDate, endDate }) => this.handleCheckInDatesChange(startDate, endDate, item.id)}
                                                            focusedInput={this.state.focusedInput}
                                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                            small={true}
                                                            required
                                                            numberOfMonths={2}
                                                            showDefaultInputIcon={true}
                                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                                        />
                                                    </Col>
                                                    <Col className="col-1">
                                                        <Label for="price">
                                                            <FormattedMessage id="ReservationDetail.Price" />
                                                        </Label>
                                                    </Col>
                                                    <Col className="col-2">
                                                        <Input type="number" name="price" className="text-sm" min="0" step="0.01" value={item.amountAfterTax ? item.amountAfterTax : ''} onChange={this.handleIntFieldChange.bind(this, 'amountAfterTax', item.id)} />
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    </form>
                                </div>
                                : ''}
                            {this.state.reportAction === 'guest_misconduct' ?
                                <form ref={this.formMisconduct}>
                                    <Row className="mb-1">
                                        <Col>
                                            <h5>
                                                <FormattedMessage id="ReservationDetail.Misconduct" />
                                            </h5>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col>
                                            <p className="lead">
                                                <FormattedMessage id="ReservationDetail.MisconductParagraphOne" />
                                            </p>
                                            <p className="lead">
                                                <FormattedMessage id="ReservationDetail.MisconductParagraphTwo" />
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col>
                                            <CustomSelect placeholder={<FormattedMessage id="ReservationDetail.ChooseCategory" />} required={true} value={getBookingMisconductCategories(this).find(el => el.value === this.state.misconductCategory)} options={getBookingMisconductCategories(this)} onChange={this.setComboStatus.bind(this, 'misconductCategory')} />
                                        </Col>
                                        <Col>
                                            {
                                                this.state.misconductCategory && getBookingMisconductSubCategories(this.state.misconductCategory, this).length > 0 ?
                                                    <CustomSelect placeholder={<FormattedMessage id="ReservationDetail.ChooseSubCategory" />} required={true} value={this.state.waived_fees_List.find(el => el.value === this.state.misconductSubCategory)} options={getBookingMisconductSubCategories(this.state.misconductCategory, this)} onChange={this.setComboStatus.bind(this, 'misconductSubCategory')} />
                                                    :
                                                    ''
                                            }
                                        </Col>
                                    </Row>
                                    <Row className="mb-1">
                                        <Col>
                                            <FormattedMessage id="ReservationDetail.Description">
                                                {
                                                    placeholder => <Input type="textarea" maxLength="240" required placeholder={placeholder} value={this.state.misconductText} onChange={(e) => this.setState({ misconductText: e.target.value })} />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <CustomInput type="switch" id="escalate_report" label="Escalate Report" onChange={(e) => this.setState({ escalate_report: (e.target.checked ? '1' : '0') })} />
                                        </Col>
                                        <Col>
                                            <CustomInput type="switch" id="rebooking_allowed" label="Reeboking Allowed" onChange={(e) => this.setState({ rebooking_allowed: (e.target.checked ? '1' : '0') })} />
                                        </Col>
                                    </Row>
                                </form>
                                :''}
                        </BlockUi>
                    </ModalBody>
                    <ModalFooter>
                        <BlockUi tag="div" blocking={this.state.block}>
                            <Button color="primary" onClick={this.toggleOK.bind(this)}>
                                <FormattedMessage id="ReservationDetail.Confirm" />
                            </Button>
                            {' '}
                            <Button color="secondary" onClick={this.toggleConfirmModal.bind(this)}>
                                <FormattedMessage id="ReservationDetail.Cancel" />
                            </Button>
                        </BlockUi>
                    </ModalFooter>
                </Modal>

                {historicModal ?
                    <ReservationHist modal={historicModal} reservation={data} action={this.toggleHistoricModal} hidePmsData={hidePmsData} />
                    :''}

                {this.state.reasonModal ?
                    <ReasonModal
                        modal={this.state.reasonModal}
                        toggle={this.toggleModalGeneric}
                        data={this.props.data}
                        updateTable={this.props.updateTable}
                        updateData={this.updateData}
                        modalType={this.state.modalType}
                    />
                    : ''}

                {this.state.updateProfileModal ?
                    <UpdateProfile
                        modal={this.state.updateProfileModal}
                        toggle={this.toggleModalGeneric}
                        data={this.props.data}
                        updateData={this.updateData}
                        updateTable={this.props.updateTable}
                    />
                    : ''}

                {this.state.depositModal ?
                    <DepositModal
                        modal={this.state.depositModal}
                        toggle={this.toggleModalGeneric}
                        data={this.state.reservation}
                        modalType={this.state.modalType}
                        updateData={this.updateData}
                        updateTable={this.props.updateTable}
                    />
                    : ''}

                {this.state.cancellationModal ?
                    <ConfirmCancellation
                        modal={this.state.cancellationModal}
                        toggle={this.toggleModalGeneric}
                        reservationId={this.state.reservation.id}
                        updateReservationStatus={this.updateReservationStatus}
                    />
                    : ''}

                {this.state.cancelAirbnbResModal ?
                    <CancelAirbnbResModal
                        modal={this.state.cancelAirbnbResModal}
                        toggle={this.toggleModalGeneric}
                        reservationId={this.state.reservation.id}
                        updateReservationStatus={this.updateReservationStatus}
                    />
                    : ''}
                {this.state.ccModal ?
                    <CcModal
                        isOpen={this.state.ccModal}
                        toggle={this.toggleModalGeneric}
                        reservationId={this.state.reservation.id}
                    />
                    :''}
                {this.state.ccAccessModal ?
                    <CcAccessModal
                        isOpen={this.state.ccAccessModal}
                        toggle={this.toggleModalGeneric}
                        reservationId={this.state.reservation.id}
                    />
                    :''}
                {this.state.remapReservationModal ?
                    <RemapReservationModal
                        block={block}
                        error={error}
                        isOpen={this.state.remapReservationModal}
                        toggle={this.toggleRemapReservationModal}
                        roomStays={[...reservation.hotelReservation]}
                        roomStaysWithoutMappings={[...reservation.hotelReservation.filter(hr => hr.hotelRateCode.length > 0 && hr.hotelRateCode.find(hrc => hrc.rateCodeChannelId === null || !rateCodes.some(rc => rc.id === hrc.rateCodeChannelId)) !== undefined)]}
                        roomStaysToRemap={[...hotelReservationToRemap]}
                        rateCodes={rateCodes}
                        channelInstanceId={reservation.channelInstanceId}
                        channelName={reservation?.channelConfigName}
                        verifyMappings={this.verifyMappings}
                        handleChangeMapping={this.handleChangeMapping}
                        saveNewMappings={this.saveNewMappings}
                        showVerifyMappings={showVerifyMappings}
                    />
                    :''}
            </>
        );
    }
}

export default injectIntl(ReservationDetail);