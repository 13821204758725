import React from "react";
import { DropdownMenu, DropdownItem, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { IntlContext } from "../../Utils/IntlProviderWrapper";
import countryList from "react-select-country-list";
import { setCountryLabelsPT, setCountryLabelsEN } from "../Base/Common/ReferenceDataFunctions";
import { FormattedMessage } from "react-intl";



const LanguageSwitch = ({mobileView}) => {
    const { locale, switchToLangDE, switchToLangFR, switchToLangES, switchToLangPT, switchToLangBR, switchToLangEN } = React.useContext(IntlContext);
    
    const cssclasses = locale === 'pt' ? 'flag-icon flag-icon-pt pr-2' : locale === 'es' ? 'flag-icon flag-icon-es mr-2' : locale === 'fr' ? 'flag-icon flag-icon-fr mr-2' :
    locale === 'de' ? 'flag-icon flag-icon-de mr-2' : locale === 'br' ? 'flag-icon flag-icon-br pr-2' : 'flag-icon flag-icon-us mr-2';
    
    if (!countryList().data.some(el => el.value === "XK")) {
        //Add kasovo country
        countryList().data.push({ value: 'XK', label: 'Kosovo' });
        countryList().labelMap.kasovo = "XK";
        countryList().valueMap.xk = "Kosovo";
    }

    if (locale === 'pt') {
        setCountryLabelsPT(countryList);
    }
    else {
        setCountryLabelsEN(countryList);
    }

    return (
        <div>
            <UncontrolledDropdown direction={mobileView ? 'Right' : 'left'} >
                <DropdownToggle tag="a" className={mobileView ? "dropdown-item pl-0" : "dropdown-item"} caret>
                    <i className={cssclasses} /> <FormattedMessage id={`lang.${locale.toUpperCase()}`}/>
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Change Language</DropdownItem>
                    <DropdownItem style={ mobileView ? { color: '#282828' } : {}} onClick={switchToLangPT} active={locale === 'pt' ? true : false} ><span className="flag-icon flag-icon-pt mr-1"/><FormattedMessage id={`lang.PT`}/></DropdownItem>
                    <DropdownItem style={ mobileView ? { color: '#282828' } : {}} onClick={switchToLangEN} active={locale === 'en' ? true : false}><span className="flag-icon flag-icon-us mr-1"/><FormattedMessage id={`lang.EN`}/></DropdownItem>
                    <DropdownItem style={ mobileView ? { color: '#282828' } : {}} onClick={switchToLangES} active={locale === 'es' ? true : false}><span className="flag-icon flag-icon-es mr-1"/><FormattedMessage id={`lang.ES`}/></DropdownItem>
                    <DropdownItem style={ mobileView ? { color: '#282828' } : {}} onClick={switchToLangFR} active={locale === 'fr' ? true : false}><span className="flag-icon flag-icon-fr mr-1"/><FormattedMessage id={`lang.FR`}/></DropdownItem>
                    <DropdownItem style={ mobileView ? { color: '#282828' } : {}} onClick={switchToLangDE} active={locale === 'de' ? true : false}><span className="flag-icon flag-icon-de mr-1"/><FormattedMessage id={`lang.DE`}/></DropdownItem>
                    <DropdownItem style={ mobileView ? { color: '#282828' } : {}} onClick={switchToLangBR} active={locale === 'br' ? true : false} ><span className="flag-icon flag-icon-br mr-1"/><FormattedMessage id={`lang.BR`}/></DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    );
};


export default LanguageSwitch;