import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import moment from 'moment';
import EditableRoomAvailabilityBox from './EditableRoomAvailabilityBox';
import { FormattedMessage } from 'react-intl';

export class EditableRoomAvailabilityBar extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            dragObj: null
        };
    }

    dragStart = (e, value, rowkey, colkey, isRule, isPrice) => {
        const dragObj = { value, rowkey, colkey, isRule, isPrice };
        this.setState({ dragObj });
    }

    render() {
        const { roomDesc, dayAvailability, next14Days, wasSaved, isHost, onScroll, handleInventoryChange, roomCatId, newData, roomBlock } = this.props;
        
        return (
            !global.isMobile ?
                <Row className="d-flex flex-row flex-nowrap">
                    <Col className="col-3 position-sticky" style={{ left: '0', zIndex: '1', background: 'white' }}>
                        <Row className="h-100">
                            <Col className="col-12 pl-2 d-flex align-items-center justify-content-between">
                                <div className="d-inline-block">
                                    <b className="pl-2 color-host">{roomDesc}</b>
                                </div>
                                {roomBlock ?
                                    <span className='color-host'><FormattedMessage id="Inventory.Virtual"/></span>
                                :''}
                            </Col>
                        </Row>
                    </Col>
                    <div className={`IventoryDataScroll`} onScroll={onScroll} style={{ width: '100%', display: 'flex', overflow: 'auto' }}>
                        {dayAvailability && next14Days && next14Days.map((item, key) => 
                            <EditableRoomAvailabilityBox
                                day={item}
                                newInv={newData?.find(({day}) => item.isSame(moment(day), 'date'))?.availability}
                                wasSaved={wasSaved}
                                handleInventoryChange={handleInventoryChange}
                                dragStart={this.dragStart}
                                roomCatId={roomCatId}
                                dragObj={this.state.dragObj}
                                colKey={key}
                                dayAvailability={dayAvailability.find(el => item.isSame(moment(el.hotelDate), 'date'))}
                                key={key}
                                isHost={isHost}
                                onScroll={onScroll}
                            />
                        )}
                    </div>
                </Row>
            :
                <div className='position-sticky'>
                    <div className="mb-2" style={{ left: '0', zIndex: '1', background: 'white' }}>
                        <div className="h-100 d-flex align-items-center justify-content-center">
                            <div className="d-inline-block">
                                <b className="pl-2 color-host">{roomDesc}</b>
                            </div>
                            {roomBlock ?
                                <span className='color-host ml-2'><FormattedMessage id="Inventory.Virtual"/></span>
                            :''}
                        </div>
                    </div>
                    <Row className="IventoryDataScroll" onScroll={onScroll} style={{ display: 'flex', overflow: 'auto' }}>
                        <Col className="col-3"/>
                        <Col className="col-9 d-flex pl-0">
                            {dayAvailability && next14Days && next14Days.map((item, key) => 
                                <EditableRoomAvailabilityBox
                                    day={item}
                                    newInv={newData?.find(({day}) => item.isSame(moment(day), 'date'))?.availability}
                                    wasSaved={wasSaved}
                                    handleInventoryChange={handleInventoryChange}
                                    dragStart={this.dragStart}
                                    roomCatId={roomCatId}
                                    dragObj={this.state.dragObj}
                                    colKey={key}
                                    dayAvailability={dayAvailability.find(el => item.isSame(moment(el.hotelDate), 'date'))}
                                    key={key}
                                    isHost={isHost}
                                    onScroll={onScroll}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
        );
    }
};