import moment from 'moment';
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, InputGroup, Row } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard, SelectChannelConfig } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Notification";
import ChangeLastSendModal from "./ChangeLastSendModal";


class ReservationTimerDashboard extends Component {

    state = {
        block: false,
        status: { value: false },
        sendInfoStatus: [],
        startDate: null,
        endDate: null,
        channelCode: null,
        startDate: moment().subtract(7, 'day'),
        endDate: moment(),
        modal: false,
        selectedData: null
    }

    componentDidMount() {
        this.getInvalidReservationList();
    }

    getInvalidReservationList() {
        this.setState({ block: true });
        var param = '';

        if (this.state.startDate)
            param += '?StartDate=' + this.state.startDate.format('YYYY-MM-DD')
        if (this.state.endDate)
            param += '&EndDate=' + this.state.endDate.format('YYYY-MM-DD')
        if (this.state.channelConfigId)
            param += '&channelConfigId=' + this.state.channelConfigId


        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0)
                    handleNotification(data);
                else
                    this.setState({ sendInfoStatus: data.data, block: false });
            }
        }, '/api/Price/Reservation/v1/SendInfoStatus' + param);
    }

    setFilterState = (name, combo) => {
        this.setState({ [name]: combo });
    }

    setFilterSelectedChannel = (name, combo) => {
        var label = combo ? combo.label : null
        var val = combo ? combo.value : null
        this.setState({
            channelCode: label,
            channelConfigId: val
        } );
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedData: null
        }));
    }

    updateTable = (data) => {
        this.setState({ block: true });
        const index = this.state.sendInfoStatus && this.state.sendInfoStatus.indexOf(this.state.sendInfoStatus.find(sis => sis.id === data.id));

        if (index === -1) {
            this.setState({
                sendInfoStatus: [
                    ...this.state.sendInfoStatus,
                    data
                ]
            });
        }
        else {
            var sendInfoStatus = [...this.state.sendInfoStatus];
            sendInfoStatus[index] = data;

            this.setState({ sendInfoStatus });
        }
        this.setState({ block: false });
    }
    
    render() {

        const helpContent =
            <div>
                Send
                <hr />
                <FormattedMessage id="InvalidReservationDashboard.Help" />
            </div>


        const columns = [
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "ReservationTimerDashboard.HotelName" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            },
            {
                dataField: 'propertyCode',
                text: this.props.intl.formatMessage({ id: "ReservationTimerDashboard.PropertyCode" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            }, {
                dataField: 'channelInstanceName',
                text: this.props.intl.formatMessage({ id: "ReservationTimerDashboard.ChannelName" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            }, {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "ReservationTimerDashboard.Type" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            },
            {
                dataField: 'lastSendDate',
                text: this.props.intl.formatMessage({ id: "ReservationTimerDashboard.LastSendDate" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            },
            {
                dataField: 'hash',
                text: "Hash",
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, selectedData: row})
            }
        };

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-exclamation-circle'} title={'ReservationTimerDashboard.title'} help={helpContent}>
                <Row>
                    <Col className="col-3">
                        <SelectChannelConfig required={true} name={'channelConfigId'} icon={'fas fa-plug'} value={this.state.channelConfigId} onChangeFunc={this.setFilterSelectedChannel} />
                    </Col>
                    <Col>
                        <InputGroup size="sm text-right" >
                            <DateRangePicker
                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : null}
                                startDateId="your_unique_start_date_id_announcement"
                                isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                                endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                endDateId="your_unique_end_date_id_announcement"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <Button className="float-right btn-sm btn-host btn" onClick={this.getInvalidReservationList.bind(this)}><i className="fas fa-search"></i></Button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">

                            <CustomTable
                                data={this.state.sendInfoStatus ? this.state.sendInfoStatus : []}
                                columns={columns}
                                page={this.state.currentPage}
                                sizePerPage={this.state.resultsPerPage}
                                onTableChange={this.handleTableChange}
                                totalSize={this.state.sendInfoStatus && this.state.sendInfoStatus.length}
                                shadow={false}
                                search={true}
                                exportCSV={true}
                                remote={false}
                                selectRow={selectRow}
                            />

                        </Card>
                    </Col>
                </Row>

                {this.state.modal ?
                    <ChangeLastSendModal
                        modal={this.state.modal}
                        toggle={this.toggleModal}
                        selectedData={this.state.selectedData}
                        updateTable={this.updateTable}
                    />
                 : ''}
            </StyledCard>


        );
    }

};
export default injectIntl(ReservationTimerDashboard)