import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getAPI, putAPI } from "../../Base/API";
import { StyledCard, SelectChannelConfig, SelectHotel, ActiveInactiveStatusBadge } from "../../Base/Common/CommonUIComponents";
import { Row, Col, Button, Form, Modal, ModalBody } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import { CustomTable } from '../../Base/Common/CustomTable';
import { ErrorAlert } from "../../Common/ErrorAlert";
import { SingleDatePicker } from 'react-dates';
import BlockUi from 'react-block-ui';
import moment from 'moment';

class HotelFullPush extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            modal: false,
            channelSendInfo: [],
            selectedIds: []
        }
    }

    getLastSendDates = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var params = '';
        if (this.state.hotelId) params += `hotelId=${this.state.hotelId}&`
        if (this.state.channelConfigId) params += `channelConfigId=${this.state.channelConfigId}`

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, error: errorMessage });
                    return;
                }

                this.setState({ channelSendInfo: data.response, block: false, error: errorMessage });
            }
            else this.setState({ block: false, error: errorMessage });
        }, '/api/hotel/v1/channelSendInfoControl?' + params );
    }

    updateLastSendDates = (evt) => {
        evt.preventDefault();
        this.setState({ block: true });

        const body = this.state.selectedIds.map(el => el.id);

        putAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="HotelFullPush.LastSendDateUpdated" />, <FormattedMessage id="General.Success" />);
                    this.setState({ block: false, lastSendDate: null, selectedIds: [] });
                }
            }
            else this.setState({ block: false });
        }, `/api/hotel/v1/channelSendInfoControl?lastSendDate=${moment(this.state.lastSendDate).format("YYYY-MM-DD")}`, body);

    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
    }

    handleOnSelectAll = (isSelected) => {
        let list = [];

        if (isSelected) {
            list = this.state.channelSendInfo;
        }

        this.setState({ selectedIds: list });
        return list.map(el => el.id);
    }

    handleOnSelect = (row, isSelect) => {
        let list = [...this.state.selectedIds];

        if (isSelect) {
            list.push(row);
        }
        else {
            list = list.filter(el => el.id !== row.id);
        }

        this.setState({ selectedIds: list });
    }

    render() {
        const { error, block, modal, channelSendInfo, hotelId, channelConfigId } = this.state;

        const columns = [
            {
                dataField: 'hotelName',
                text: "Hotel",
                sort: true,
                formatter: (cell, row) => cell ? cell : row.hotelId
            },
            {
                dataField: 'channel',
                text: this.props.intl.formatMessage({ id: "HotelFullPush.Channel" }),
                sort: true,
                formatter: (cell, row) => cell ? `${cell} (${row.channelInstanceId})` : `${row.channelId} (${row.channelInstanceId})`
            },
            {
                dataField: 'roomCategoryId',
                text: this.props.intl.formatMessage({ id: "HotelFullPush.RoomCategory" }),
                sort: true
            },
            {
                dataField: 'availabilityLastSendDate',
                text: this.props.intl.formatMessage({ id: "HotelFullPush.availabilityLastSendDate" }),
                sort: true
            },
            {
                dataField: 'priceLastSendDate',
                text: this.props.intl.formatMessage({ id: "HotelFullPush.priceLastSendDate" }),
                sort: true
            },
            {
                dataField: 'restrictionsLastSendDate',
                text: this.props.intl.formatMessage({ id: "HotelFullPush.restrictionsLastSendDate" }),
                sort: true
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "HotelFullPush.Status" }),
                sort: true,
                formatter: (cell) => <ActiveInactiveStatusBadge status={cell} /> 
            }
        ];

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
        };

        return (
            <StyledCard block={block} error={error} icon={'fas fa-hotel'} title={'navbar.HotelFullPush'}>
                <Modal isOpen={modal} toggle={this.toggleModal} className="modal-xl" style={{ minWidth: 0 }}>
                    <ModalBody>
                        <BlockUi tag="div" blocking={this.state.block}>
                            <ErrorAlert error={this.state.error} />
                            <Form onSubmit={this.updateLastSendDates}>
                                <Row className="mb-4 align-items-center">
                                    <Col sm={3}> <FormattedMessage id="HotelFullPush.UpdateLastSendDate" />: </Col>
                                    <Col>
                                        <SingleDatePicker
                                            id="Date"
                                            isOutsideRange={day => day >= moment()}
                                            date={this.state.lastSendDate}
                                            onDateChange={date => this.setState({ lastSendDate: date })}
                                            focused={this.state.focused}
                                            onFocusChange={({ focused }) => this.setState({ focused })}
                                            small={true}
                                            numberOfMonths={1}
                                            required={true}
                                            showDefaultInputIcon={true}
                                            required
                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                        />
                                    </Col>
                                    <Col className="text-right">
                                        <Button color="secondary" type="submit" className="btn-host btn-sm" disabled={this.state.lastSendDate ? false : true}>
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>

                                <b> <FormattedMessage id="HotelFullPush.Selected" />: </b>
                                <CustomTable
                                    data={this.state.selectedIds}
                                    columns={columns}
                                    sizePerPage={10}
                                    totalSize={this.state.selectedIds && this.state.selectedIds.length}
                                    shadow={false}
                                    exportCSV={false}
                                    //selectRow={selectRow}
                                    hideSizePerPage={false}
                                    showTotal={true}
                                />
                            </Form>
                        </BlockUi>
                    </ModalBody>
                </Modal>

                <Form onSubmit={this.getLastSendDates}>
                    <Row className="mb-3">
                        <Col>
                            <SelectHotel
                                name={'hotelId'}
                                icon={'fas fa-hotel'}
                                onChangeFunc={this.handleChangeSelect}
                                placeholder={<FormattedMessage id="HotelFullPush.SelectHotel" />}
                                value={hotelId || ''}
                            />
                        </Col>
                        <Col>
                            <SelectChannelConfig
                                name={'channelConfigId'}
                                icon='fa fa-plug fa-fw'
                                onChangeFunc={this.handleChangeSelect}
                                placeholder={<FormattedMessage id="HotelFullPush.SelectChannel" />}
                                value={channelConfigId}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-sm btn-host mr-2" onClick={this.toggleModal} disabled={this.state.selectedIds && this.state.selectedIds.length > 0 ? false : true}>
                                <i className="fas fa-calendar-alt" />
                            </Button>
                            <Button type="submit" className="btn btn-sm btn-host">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <CustomTable
                    data={channelSendInfo}
                    columns={columns}
                    sizePerPage={10}
                    totalSize={channelSendInfo && channelSendInfo.length}
                    shadow={false}
                    exportCSV={false}
                    selectRow={selectRow}
                    hideSizePerPage={false}
                    showTotal={true}
                />
            </StyledCard>
        );
    }
};
export default injectIntl(HotelFullPush)