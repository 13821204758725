import React, { Component } from 'react';
import { Row, Col, Input, Button, Label, FormGroup, Card, Modal, ModalBody, ModalHeader, Form, UncontrolledTooltip } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';

class MailExtractorLines extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedLine: this.props.selectedLine || {}
        };
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            selectedLine: {
                ...this.state.selectedLine,
                [name]: value
            }
        })
    }

    handleSelectChange = (combo, name) => {
        this.setState({
            selectedLine: {
                ...this.state.selectedLine,
                [name]: combo ? combo.value : null
            }
        })
    }

    addLine = (e) => {
        e.preventDefault();

        this.props.updateExtractorLines(this.state.selectedLine, false);
        this.props.toggleModal();
    }

    testRegex = (e) => {
        const { value } = e.target;

        var regex = new RegExp(this.state.selectedLine.regexString, "ig");

        const hasMatch = regex.test(value);

        if (hasMatch) {
            var matchText = value.replace(regex, function ($1) {
                return "<b>" + $1 + "</b>";
            });

            this.setState({ hasMatch, matchText })
        }
        else {
            this.setState({ hasMatch, matchText: value })
        }
    }

    render() {
        const { selectedLine, hasMatch } = this.state;
        const { extractorLinesFields, extractorLinesTypes } = this.props;

        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggleModal} >
                <ModalHeader toggle={this.props.toggleModal}>
                    <FormattedMessage id={`MailExtractor.${this.props.selectedLine ? 'AddLine' : 'EditLine'}`} />
                </ModalHeader>
                <ModalBody className="p-0">
                    <Form onSubmit={this.addLine}>
                        <Card className="shadow border-0" body>
                            <Row className="mb-3">
                                <Col className="text-right">
                                    <Button type="submit" className="btn btn-sm btn-host float-right">
                                        <i className="fas fa-plus" />
                                    </Button>
                                </Col>
                            </Row>
                            <FormGroup sm="true" row>
                                <Label className="col-2">
                                    <FormattedMessage id="MailExtractor.Field" />
                                </Label>
                                <Col className="col-10">
                                    <CustomSelect
                                        isSearchable
                                        placeholder={""}
                                        options={extractorLinesFields}
                                        value={extractorLinesFields.find(el => el.value === selectedLine.field) || ''}
                                        onChange={(combo) => this.handleSelectChange(combo, 'field')}
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup sm="true" row>
                                <Label className="col-2">
                                    <FormattedMessage id="MailExtractor.Type" />
                                </Label>
                                <Col className="col-4">
                                    <CustomSelect
                                        isSearchable
                                        placeholder={""}
                                        options={extractorLinesTypes}
                                        value={extractorLinesTypes.find(el => el.value === selectedLine.type) || ''}
                                        onChange={(combo) => this.handleSelectChange(combo, 'type')}
                                        required
                                    />
                                </Col>
                                <Label className="col-2">
                                    <FormattedMessage id="MailExtractor.Group" />
                                </Label>
                                <Col className="col-4">
                                    <Input
                                        type="number"
                                        name="group"
                                        value={selectedLine.group || ''}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup sm="true" row>
                                <Label className="col-2">
                                    <FormattedMessage id="MailExtractor.RegexString" />
                                </Label>
                                <Col className="col-10">
                                    <Input
                                        type="text"
                                        name="regexString"
                                        value={selectedLine.regexString || ''}
                                        onChange={(e) => this.handleChange(e)}
                                        required
                                    />
                                </Col>
                            </FormGroup>

                            {selectedLine.regexString ?
                                <div className="mt-5 text-muted">
                                    <FormattedMessage id="MailExtractor.RegexTester" />
                                    <hr className="my-1" />
                                    <Row className="my-3">
                                        <Col className="col-6">
                                            <Label> <FormattedMessage id="MailExtractor.FilledSample" /></Label>
                                            <Input type="textarea" rows="2" onChange={(e) => this.testRegex(e)}  />
                                        </Col>
                                        <Col className="col-6">
                                            <div>
                                                <FormattedMessage id="MailExtractor.RegexDetection" />
                                                <span className="float-right">
                                                    {hasMatch !== null && hasMatch !== undefined ?
                                                        hasMatch ?
                                                            <>
                                                                <i className="fas fa-check-circle" id="check-circle" style={{ color: 'green' }} />

                                                                <UncontrolledTooltip placement="left" target="check-circle" >
                                                                    <FormattedMessage id="MailExtractor.ValidText" />
                                                                </UncontrolledTooltip>
                                                            </>
                                                            :
                                                            <>
                                                                <i className="fas fa-times-circle" id="times-circle" style={{ color: 'red' }} />

                                                                <UncontrolledTooltip placement="left" target="times-circle" >
                                                                    <FormattedMessage id="MailExtractor.InvalidText" />
                                                                </UncontrolledTooltip>
                                                            </>
                                                        : ''}
                                                </span>
                                            </div>

                                            <div dangerouslySetInnerHTML={{ __html: this.state.matchText }} />
                                        </Col>
                                    </Row>
                                </div>
                            : ''}
                        </Card>
                    </Form>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(MailExtractorLines);