import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { RoomAvailabilityBox } from './RoomAvailabilityBox';
import moment from 'moment';

export class RoomAvailabilityBar extends Component {

    render() {
        const { roomCategory, hotelAvailability, blockId, next14Days, isHost } = this.props;

        var dayAvailability = hotelAvailability.filter((rest) => roomCategory.id === rest.roomCategoryId && rest.blockId === blockId);

        return (
            <Row className="d-flex flex-row flex-nowrap">
                <Col className="col-3 position-sticky" style={{ borderBottom: '1px solid #165c7d', left: '0', zIndex: '1', background: 'white' }}>
                    {blockId == 0 ?
                        <Row>
                            <Col className="col-12 pl-0">
                                <div className="d-inline-block">
                                    <b className="pl-2 color-host">{roomCategory.description}</b>
                                </div>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col className="col-10 pl-0" >
                                <b className="pl-2 color-host">{roomCategory.description}</b>
                            </Col>
                            <Col className="col-2 p-0 color-host">
                                <FormattedMessage id="EditRate.InventoryOwn" />
                            </Col>
                        </Row>
                    }
                </Col>
                {
                    hotelAvailability && dayAvailability && next14Days && next14Days.map((item, key) =>
                        <RoomAvailabilityBox day={item} dayAvailability={dayAvailability.filter(el => item.isSame(moment(el.hotelDate), 'date'))} key={key} blockId={blockId} isHost={isHost} />
                    )
                }
            </Row>
        );
    }
};