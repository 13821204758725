import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import CustomSelect from "../../../Base/Common/CustomSelect";
import { CustomSingleDatePicker } from '../../../Base/Common/CustomReactDates';
import { MobileFilters } from '../../../Base/Common/MobileComponents';

class AvailabilityHistoryFilters extends Component {

    renderMainFilter = () => {
        return (
            <CustomSingleDatePicker
                date={this.props.day}
                id="singleDatePicker"
                required={true}
                isOutsideRange={_ => false}
                showTodaysButton={true}
                showYearOptions={{ pastYears: true, futureYears: true }}
                onDateChange={date => this.props.handleDate(date)}
            />
        )
    }

    renderSecFilters = () => {
        return (
            <Row>
                <CommonFilters
                    isAdminPage={this.props.isAdminPage}
                    hotelList={this.props.hotelList}
                    handleChangeSelect={this.props.handleChangeSelect}
                    roomCategories={this.props.roomCategories}
                    roomCategoryId={this.props.roomCategoryId}
                    hotelId={this.props.hotelId}
                    blockList={this.props.blockList}
                    blockId={this.props.blockId}
                />
            </Row>
        )
    }

    render() {
        const { isAdminPage, hotelList, handleChangeSelect, roomCategories, roomCategoryId, hotelId, blockList, blockId, handleDate, blockCategories, blockBlocks } = this.props;

        return (
            <div>
                {global.isMobile ?
                    <MobileFilters
                        renderMainFilter={this.renderMainFilter}
                        renderSecFilters={this.renderSecFilters}
                        blockCategories={blockCategories}
                        blockBlocks={blockBlocks}
                        searchType="submit"
                    />
                :
                    <Row className="mb-4 mt-2">
                        <CommonFilters
                            isAdminPage={isAdminPage}
                            hotelList={hotelList}
                            handleChangeSelect={handleChangeSelect}
                            roomCategories={roomCategories}
                            roomCategoryId={roomCategoryId}
                            hotelId={hotelId}
                            blockList={blockList}
                            blockId={blockId}
                        />
                        <Col className="col-2 pr-1">
                            <CustomSingleDatePicker
                                date={this.props.day}
                                id="singleDatePicker"
                                required={true}
                                isOutsideRange={_ => false}
                                showTodaysButton={true}
                                showYearOptions={{ pastYears: true, futureYears: true }}
                                onDateChange={date => handleDate(date)}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-sm btn-host btn" type="submit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                }
            </div>
        );
    }

};
export default injectIntl(AvailabilityHistoryFilters);


const CommonFilters = ({ isAdminPage, hotelList, handleChangeSelect, roomCategories, roomCategoryId, hotelId, blockList, blockId, blockCategories, blockBlocks }) => {
    return (
        <>
            {isAdminPage ?
                <Col className="mb-2 col-12 col-lg-4">
                    <CustomSelect icon={'fas fa-hotel'}
                        options={hotelList}
                        required
                        onChange={(e) => handleChangeSelect('hotelId', e)}
                        placeholder={<FormattedMessage id="HotelLicenses.Hotel" />}
                        isSearchable isClearable
                    />
                </Col>
                : ''}
            <Col className="mb-2 col-12 col-lg-4">
                <CustomSelect
                    icon={'fa fa-bed fa-fw'}
                    name={'roomCategoryId'}
                    required
                    options={roomCategories}
                    onChange={(e) => handleChangeSelect('roomCategoryId', e)}
                    placeholder={<FormattedMessage id="PMSHistory.RoomCategory" />} isSearchable
                    value={roomCategoryId ? roomCategories.find(el => el.value === roomCategoryId) : ''}
                    isClearable
                    isDisabled={isAdminPage ? hotelId ? false : true : false}
                    isLoading={blockCategories}
                />
            </Col>
            <Col className="mb-2 col-12 col-lg-4">
                <CustomSelect
                    options={blockList}
                    onChange={(e) => handleChangeSelect('blockId', e)}
                    placeholder={"Block"}
                    value={blockId ? blockList.find(el => el.value === blockId) : ''}
                    isSearchable isClearable
                    isDisabled={roomCategoryId ? false : true}
                    isLoading={blockBlocks}
                />
            </Col>
        </>
    )
}