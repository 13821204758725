import React, { Component } from 'react';
import { Col, CustomInput, Label } from 'reactstrap';

export class RestrictionBoxCheck extends Component {

    render() {
        const { isDependent, toggleModal, value, fromRate } = this.props;
        const classCol = "position-top border-bottom border-right max-with-14Col ";

        return (
            <Col className={classCol} style={{ minWidth: '60px' }}>
                <div className="text-center  font-weight-bold  text-uppercase">
                    <CustomInput className={fromRate ? "fromRate" : ""} type='checkbox' style={isDependent === true ? {} : { 'cursor': 'pointer' }} checked={value} id={'resctictionCheckbox' + Math.random()} onClick={isDependent == true ? undefined : toggleModal} />
                </div>
            </Col>
        );
    }
}