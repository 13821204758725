import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { SearchButton } from './SearchButton';
import Authorization from './Base/Authorization';
import SubscribeToCM from './SubscribeToCM';
import { Route } from 'react-router-dom';
import App from '../App';
import ErrorBoundary from './ErrorBoundary';
import { BrowserVersionNotSupported, isBrowserVersionSupported } from './BrowserVersion';


export class Layout extends Component {
    static displayName = Layout.name;

    state = {
        hasBEModule: false,
        screenWidth: 0,
        hasERModule: false
    };

    updateHasBEModule = (hasBEModule) => {
        this.setState({ hasBEModule });
    }

    updateHasERModule = (hasERModule) => {
        this.setState({ hasERModule });
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);

        this.setState({ isBrowserVersionSupported: isBrowserVersionSupported() })
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        const screenWidth = document.documentElement.clientWidth || document.body.clientWidth;
        this.setState({ screenWidth});
    }

    render() {
        return (
            this.state.isBrowserVersionSupported ?
                <ErrorBoundary>
                    <div className="bg-light h-100">
                        <Authorization
                            perform="CMClient"
                            yes={() => (
                                <>
                                    <NavMenu updateHasBEModule={this.updateHasBEModule} updateHasERModule={this.updateHasERModule} />
                                    <Container className={`bg-light ${this.state.screenWidth <= 991 ? 'px-0' : ''}`}>
                                        <App
                                            hasERModule={this.state.hasERModule}
                                            hasBEModule={this.state.hasBEModule}
                                        />

                                        <SearchButton />
                                    </Container>
                                </>
                            )}
                            no={() => (
                                <Route exact path='/' component={SubscribeToCM} />
                            )}
                        />
                    </div>
                </ErrorBoundary>
                :
                <BrowserVersionNotSupported />
        );
    }
}
