import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { SelectChannelInstance, SelectRate } from "../../../Base/Common/CommonUIComponents";
import { CustomSingleDatePicker } from '../../../Base/Common/CustomReactDates';
import CustomSelect from '../../../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { MobileFilters } from '../../../Base/Common/MobileComponents';


class LogsAvailabilityPriceFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            updateData: [
                {
                    "value": "CTA",
                    "label": this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.CTA" })
                },
                {
                    "value": "CTD",
                    "label": this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.CTD" })
                },
                {
                    "value": "minStay",
                    "label": this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.MinStay" })
                },
                {
                    "value": "maxStay",
                    "label": this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.MaxStay" })
                },
                {
                    "value": "freerooms",
                    "label": this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.FreeRooms" })
                },
                {
                    "value": "price",
                    "label": this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.Price" })
                },
                {
                    "value": "stopSales",
                    "label": this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.StopSales" })
                },
                {
                    "value": "minAdvancedBookingOffset",
                    "label": this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.MinAdvancedBookingOffset" })
                },
                {
                    "value": "maxAdvancedBookingOffset",
                    "label": this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.MaxAdvancedBookingOffset" })
                }
            ],
            errors: [
                {
                    "value": "1",
                    "label": <FormattedMessage id="LogsAvailabilityPrice.Success" />
                },
                {
                    "value": "2",
                    "label": <FormattedMessage id="LogsAvailabilityPrice.Error" />
                }
            ],
        };
    }

    renderMainFilter = () => {
        return (
            <CustomSelect
                icon={'fas fa-table'}
                isClearable isMulti isSearchable
                placeholder={<FormattedMessage id="LogsAvailabilityPrice.ChooseDataChanged" />}
                options={this.state.updateData}
                onChange={this.props.setComboStatus.bind(this, 'selectedData')}
                value={this.state.updateData.filter(el => this.props.selectedData.split(',').includes(el.value))}
            />
        )
    }

    renderSecFilters = () => {
        return (
            <div className="mb-2">
                <div className="font_size_xxs ">
                    <FormattedMessage id="LogsAvailabilityPrice.AriDate" />
                </div>
                <div className="mb-2">
                    <CustomSingleDatePicker
                        date={this.props.ariDate}
                        id="ModificationData"
                        required={true}
                        isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment().add(2, 'year')}
                        showTodaysButton={true}
                        showYearOptions={{ pastYears: false, futureYears: true }}
                        onDateChange={date => this.props.handleDate('ariDate', date)}
                    />
                </div>
                <div className="font_size_xxs ">
                    <FormattedMessage id="LogsAvailabilityPrice.SendDate" />
                </div>
                <div >
                    <CustomSingleDatePicker
                        date={this.props.sendDate}
                        id="EventsDate"
                        required={true}
                        isOutsideRange={day => day <= moment().subtract(12, 'month') || day > moment()}
                        showTodaysButton={true}
                        showYearOptions={{ pastYears: true, futureYears: false }}
                        onDateChange={date => this.props.handleDate('sendDate', date)}
                    />
                </div>
            </div>
        )
    }


    render() {
        const { roomCategoryList, selectedChannel, setComboStatus, doSearch, ariDate, sendDate, handleDate, blockRooms, selectedData, selectedRoom, selectedRate } = this.props;
        

        return (
            <div>
                {global.isMobile ?
                    <MobileFilters doSearch={doSearch} renderMainFilter={this.renderMainFilter} renderSecFilters={this.renderSecFilters}>
                        <Row>
                            <CommonFilters
                                blockRooms={blockRooms}
                                roomCategoryList={roomCategoryList}
                                setComboStatus={setComboStatus}
                                selectedChannel={selectedChannel}
                                selectedRoom={selectedRoom}
                                selectedRate={selectedRate}
                            />
                        </Row>
                    </MobileFilters>
                :
                    <div>
                        <Row className="mb-2 small align-items-center">
                            <Col className="col-2 pr-1">
                                <CustomSelect
                                    icon={'fas fa-table'}
                                    isClearable isMulti isSearchable
                                    placeholder={<FormattedMessage id="LogsAvailabilityPrice.ChooseDataChanged" />}
                                    options={this.state.updateData}
                                    onChange={setComboStatus.bind(this, 'selectedData')}
                                    value={this.state.updateData.filter(el => selectedData.split(',').includes(el.value))}
                                />
                            </Col>
                            <CommonFilters
                                blockRooms={blockRooms}
                                roomCategoryList={roomCategoryList}
                                setComboStatus={setComboStatus}
                                selectedChannel={selectedChannel}
                                selectedRoom={selectedRoom}
                                selectedRate={selectedRate}
                            />
                            <Col className="text-right">
                                <Button className="btn btn-host shadow btn-sm" onClick={doSearch} disabled={!ariDate}>
                                    <span className="fas fa-search" />
                                </Button>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-2">
                                <div className="d-flex align-items-center font_size_xxs">
                                    <FormattedMessage id="LogsAvailabilityPrice.AriDate" />
                                </div>
                                <CustomSingleDatePicker
                                    date={ariDate}
                                    id="ModificationData"
                                    required={true}
                                    isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment().add(2, 'year')}
                                    showTodaysButton={true}
                                    showYearOptions={{ pastYears: false, futureYears: true }}
                                    onDateChange={date => handleDate('ariDate', date)}
                                />
                            </Col>
                            <Col className="col-2">
                                <div className="d-flex align-items-center font_size_xxs">
                                    <FormattedMessage id="LogsAvailabilityPrice.SendDate" />
                                </div>
                                <CustomSingleDatePicker
                                    date={sendDate}
                                    id="EventsDate"
                                    required={true}
                                    isOutsideRange={day => day <= moment().subtract(12, 'month') || day > moment()}
                                    showTodaysButton={true}
                                    showYearOptions={{ pastYears: true, futureYears: false }}
                                    onDateChange={date => handleDate('sendDate', date)}
                                />
                            </Col>
                        </Row>
                    </div>
                }
            </div>
        );
    }
}
export default injectIntl(LogsAvailabilityPriceFilters)


const CommonFilters = ({ blockRooms, roomCategoryList, setComboStatus, selectedChannel, selectedRoom, selectedRate }) => {
    return (
        <>
            <Col className="col-12 col-lg-2 mb-2">
                <BlockUi tag="div" blocking={blockRooms}>
                    <CustomSelect
                        icon={'fas fa-bed'}
                        isClearable isSearchable
                        placeholder={<FormattedMessage id="LogsAvailabilityPrice.ChooseRoom" />}
                        options={roomCategoryList || []}
                        onChange={setComboStatus.bind(this, 'selectedRoom')}
                        value={roomCategoryList?.find(el => el.value === selectedRoom) || ''}
                    />
                </BlockUi>
            </Col>
            <Col className="col-12 col-lg-2 mb-2">
                <SelectRate
                    icon={'fa fa-gift fa-fw'}
                    name={'selectedRate'}
                    onChangeFunc={setComboStatus}
                    placeholder={<FormattedMessage id="AnalyticsDashboard.SelectRate" />}
                    value={selectedRate.split(',')}
                />
            </Col>
            <Col className="col-12 col-lg-2 mb-2">
                <SelectChannelInstance
                    icon={'fa fa-plug fa-fw'}
                    name={'selectedChannel'}
                    placeholder={<FormattedMessage id="LogsAvailabilityPrice.SelectChannel" />}
                    onChangeFunc={setComboStatus}
                    value={selectedChannel.split(',')}
                />
            </Col>
        </>
    )
}