import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ChannelConfigDetail from './ChannelConfigDetail';
import { ChannelConfigState } from './ChannelConfigState';

export class ChannelConfigEdit extends Component {

    constructor(props) {
        super(props);
        this.addOrUpdateChannelConfig = this.addOrUpdateChannelConfig.bind(this);
        this.state = {
            activeTab: '1',
            channelConfig: this.props.modalData
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    addOrUpdateChannelConfig(newChannelConfig) {
        this.setState({ channelConfig: newChannelConfig });
        this.props.addOrUpdateChannelConfig(newChannelConfig);
    }

    render() {
        const { modal, toggleModal, title } = this.props;
        return (
            <Modal isOpen={modal} toggle={toggleModal} fade={false} className="modal-lg">
                <ModalHeader toggle={toggleModal}>
                    {title}
                </ModalHeader>
                <ModalBody className="modal-lg px-0 pb-0">
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                <FormattedMessage id="ChannelConfigEdit.Detail" />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }} disabled={!this.state.channelConfig}>
                                <FormattedMessage id="ChannelConfigEdit.State" />
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <ChannelConfigDetail modalData={this.state.channelConfig} addOrUpdateChannelConfig={this.addOrUpdateChannelConfig} />
                        </TabPane>
                        <TabPane tabId="2">
                            <ChannelConfigState modalData={this.state.channelConfig} />
                        </TabPane>
                    </TabContent>               
                </ModalBody>
            </Modal>
        )
    }
}