import moment from 'moment-timezone';
import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { Badge, Breadcrumb, BreadcrumbItem, Button, Card, CardBody, Col, CustomInput, Form, FormGroup, Input, Modal, ModalBody, Nav, NavItem, NavLink, Row} from 'reactstrap';
import { deleteAPI, getAPI, postAPI } from '../../../Base/API';
import { ConfirmActionModal, StyledCard } from '../../../Base/Common/CommonUIComponents';
import CustomSelect from '../../../Base/Common/CustomSelect';
import { handleNotification } from '../../../Base/Notification';

class EmailCreation extends Component {
    
    constructor (props) {
        super(props);
        this.state = {
            block: true,
            customTags: [],
            hotelList: [],
            error: null,
            supportedLanguages: ['af-ZA', 'ar-SA', 'cs-CZ', 'da-DK', 'de-DE', 'el-GR', 'en-GB', 'es-ES', 'fi-FI', 'fr-FR', 'he-IL', 'hu-HU', 'it-IT', 'ja-JP', 'nb-NO', 'nl-NL', 'pl-PL', 'pt-PT', 'ru-RU', 'sv-SE', 'sv-FI', 'zh-TW'],
            content: `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
            <html xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
            
            <head>
                <meta charset="UTF-8">
                <meta content="width=device-width, initial-scale=1" name="viewport">
                <meta name="x-apple-disable-message-reformatting">
                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                <meta content="telephone=no" name="format-detection">
                <title></title>
                <!--[if (mso 16)]>
                <style type="text/css">
                a {text-decoration: none;}
                </style>
                <![endif]-->
                <!--[if gte mso 9]><style>sup { font-size: 100% !important; }</style><![endif]-->
                <!--[if gte mso 9]>
            <xml>
                <o:OfficeDocumentSettings>
                <o:AllowPNG></o:AllowPNG>
                <o:PixelsPerInch>96</o:PixelsPerInch>
                </o:OfficeDocumentSettings>
            </xml>
            <![endif]-->
            </head>
            
            <body>
                <div class="es-wrapper-color">
                    <!--[if gte mso 9]>
                        <v:background xmlns:v="urn:schemas-microsoft-com:vml" fill="t">
                            <v:fill type="tile" color="#f6f6f6"></v:fill>
                        </v:background>
                    <![endif]-->
                    <table class="es-wrapper" width="100%" cellspacing="0" cellpadding="0">
                        <tbody>
                            <tr>
                                <td class="esd-email-paddings" valign="top">
                                    <table class="esd-header-popover es-header" cellspacing="0" cellpadding="0" align="center">
                                        <tbody>
                                            <tr>
                                                <td class="esd-stripe" align="center">
                                                    <table class="es-header-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center">
                                                        <tbody>
                                                            <tr>
                                                                <td class="es-p20t es-p20r es-p20l esd-structure" align="left">
                                                                    <!--[if mso]><table width="560" cellpadding="0"
                                        cellspacing="0"><tr><td width="180" valign="top"><![endif]-->
                                                                    <table class="es-left" cellspacing="0" cellpadding="0" align="left">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="es-m-p0r es-m-p20b esd-container-frame" width="180" valign="top" align="center">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="esd-empty-container" style="display: none;" align="center"></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <!--[if mso]></td><td width="20"></td><td width="360" valign="top"><![endif]-->
                                                                    <table cellspacing="0" cellpadding="0" align="right">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="esd-container-frame" width="360" align="left">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="esd-empty-container" style="display: none;" align="center"></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <!--[if mso]></td></tr></table><![endif]-->
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="es-content" cellspacing="0" cellpadding="0" align="center">
                                        <tbody>
                                            <tr>
                                                <td class="esd-stripe" align="center">
                                                    <table class="es-content-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center">
                                                        <tbody>
                                                            <tr>
                                                                <td class="es-p20t es-p20r es-p20l esd-structure" align="left">
                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="esd-container-frame" width="560" valign="top" align="center">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="esd-empty-container" style="display: none;" align="center"></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="esd-footer-popover es-footer" cellspacing="0" cellpadding="0" align="center">
                                        <tbody>
                                            <tr>
                                                <td class="esd-stripe" align="center">
                                                    <table class="es-footer-body" width="600" cellspacing="0" cellpadding="0" bgcolor="#ffffff" align="center">
                                                        <tbody>
                                                            <tr>
                                                                <td class="esd-structure es-p20t es-p20b es-p20r es-p20l" align="left">
                                                                    <!--[if mso]><table width="560" cellpadding="0" 
                                    cellspacing="0"><tr><td width="270" valign="top"><![endif]-->
                                                                    <table class="es-left" cellspacing="0" cellpadding="0" align="left">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="es-m-p20b esd-container-frame" width="270" align="left">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="esd-empty-container" style="display: none;" align="center"></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <!--[if mso]></td><td width="20"></td><td width="270" valign="top"><![endif]-->
                                                                    <table class="es-right" cellspacing="0" cellpadding="0" align="right">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td class="esd-container-frame" width="270" align="left">
                                                                                    <table width="100%" cellspacing="0" cellpadding="0">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td class="esd-empty-container" style="display: none;" align="center"></td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <!--[if mso]></td></tr></table><![endif]-->
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </body>
            
                </html>`,
            style: `ul li,
            ol li {
                margin-left: 0;
            }
            
            [data-ogsb] .es-button {
                border-width: 0 !important;
                padding: null !important;
            }`,
            master: null,
            templates: [],
            activeTab: '1',
            pageSize: 10,
            pageIndex: 0,
            currentTemplateId: null,
            activeCountryTab: 0,
            deleteModal: false,
            exportModal: false
        };
    }

    componentDidMount(){
        this.getMaster();
    }
    
    getEventTypes() {
        const { master } = this.state;
        
        const eventTypeId = master.eventTypeId;
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block : false });
                return;
            }
            if (data.response) {
                const dataStructure = data.response.find(({ events }) => events.filter(({id}) => id === eventTypeId).length > 0).dataStructure;
                if(dataStructure){
                    try{
                        JSON.parse(dataStructure);
                    }
                    catch(e){
                        console.error(e);
                        this.getEmails();
                        return;
                    };

                    const parsed = JSON.parse(dataStructure);

                    const customTags = [{
                        category: 'Default',
                        entries:[]
                    }]; //Index 0 are standard tags;
                    
                    Object.keys(parsed).forEach((key) => {
                        if(typeof (parsed[key]) === 'string' || typeof (parsed[key]) === 'number' || (typeof (parsed[key]) === 'object' && parsed[key] === null)){
                            customTags[0].entries.push({
                                "label": key,   
                                "value": `{{${key}}}`
                            });
                        }
                        else if(Array.isArray(parsed[key])){
                            const arr = {
                                category: key,
                                entries: []
                            };

                            Object.keys(parsed[key][0]).forEach(k => {
                                if(typeof (parsed[key][0][k]) === 'string' || typeof (parsed[key][0][k]) === 'number' || (typeof (parsed[key][0][k]) === 'object' && parsed[key][0][k] === null)){
                                    arr.entries.push({
                                        "label": k,
                                        "value": `{{#${key}}}
                                            {{${k}}}
                                        {{/${key}}}`
                                    });
                                }
                                else if(Array.isArray(parsed[key][0][k])){
                                    Object.keys(parsed[key][k][0]).forEach(k => {
                                        arr.entries.push({
                                            "label": k,
                                            "value": `{{#${key}}}
                                                {{#${k}}}
                                                    {{${k}}}
                                                {{/${k}}}
                                            {{/${key}}}`
                                        });
                                    });
                                    customTags.push(arr);
                                }
                                else if(typeof (parsed[key][0][k]) === 'object'){
                                    Object.keys(parsed[key][k]).forEach((el, k) => {
                                        const name = key + '.' + el;
                                        customTags[0].entries.push({
                                            "label": name,
                                            "value": `{{#${key}}}
                                                {{${name}}}
                                            {{/${key}}}`
                                        });
                                    });
                                };
                            });
                            customTags.push(arr);
                        }
                        else if(typeof (parsed[key]) === 'object'){
                            Object.keys(parsed[key]).forEach((el, k) => {
                                const name = key + '.' + el;
                                customTags[0].entries.push({
                                    "label": name,
                                    "value": `{{${name}}}`
                                });
                            });
                        };
                    });
                    this.setState({ customTags }, () => this.getEmails());
                }
                else{
                    this.getEmails();
                }
            }
        }, '/api/notification/Template/v1/NotifyEvents');
    }

    getMaster = () => {
        const { pageSize, pageIndex } = this.state;

        const id = this.props.match.params.id;
        if(id) {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    this.initializeStripo();
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.initializeStripo();
                    };
                    if(data.response){
                        this.setState({ master: data.response[0] }, () => this.getHotels());
                    }
                    else{
                        handleNotification(data);
                        this.initializeStripo();
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/hotel/Stripo/v1/templates/master?id=${id}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        }
        else{
            this.initializeStripo();
        }
    }

    getHotels = () => {
        var combo = [];

        getAPI(result => {
            const { data, error } = result;
            if(error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if(data) {
                data.data && data.data.map((item) =>
                    combo.push({ value: item.hotelId, label: item.name2 })
                );
                const defaultHotel = combo.findIndex(h => h.value === '00000000-0000-0000-0000-000000000000');

                if (defaultHotel > -1) {
                    combo.splice(defaultHotel, 1);
                };
            };

            this.setState({ hotelList: combo }, () => this.getEventTypes());
        }, '/api/hotel/v1?pageSize=2000&pageIndex=0');
    }

    getEmails = () => {
        const { pageSize, pageIndex, master } = this.state;
        const masterId = master.id;
        const copyId = this.props.match.params.duplicate;
        if(copyId){
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    this.initializeStripo();
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.initializeStripo();
                    };
                    if(data.response?.length > 0){
                        this.setState({ templates: data.response.map(t =>{
                            t.unsavedChanges = true;
                            t.templateMasterId = copyId;
                            t.id = Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1));
                            t.isNew = true;
                            return t
                        }) }, () => this.initializeStripo());
                    }
                    else{
                        this.initializeStripo();
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/hotel/Stripo/v1/templates?masterId=${copyId}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        }
        else if(masterId) {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    this.initializeStripo();
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.initializeStripo();
                    };
                    if(data.response?.length > 0){
                        this.setState({ templates: data.response }, () => this.initializeStripo());
                    }
                    else{
                        this.initializeStripo();
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/hotel/Stripo/v1/templates?masterId=${masterId}&pageSize=${pageSize}&pageIndex=${pageIndex}`);
        }
        else{
            this.initializeStripo();
        }
    }

    initializeStripo = (id) => {
        const { customTags, templates } = this.state;
        const newTemplates = [];
        
        let content = this.state.content;
        let style = this.state.style;

        let defaultTemplate = id ? templates?.find((t) => t.id === id) : templates[0];

        if(defaultTemplate){
            content = defaultTemplate.content;
            style = defaultTemplate.style;
        }
        else{
            defaultTemplate = {
                id: Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1)),
                content: content,
                style: style,
                unsavedChanges: true,
                isNew: true
            };
            newTemplates.push(defaultTemplate);
            this.setState({ templates: newTemplates });
        }

        const isSharedAccount = global.modules?.includes(m => m === 'StripoSharedAccount');

        const randomId = defaultTemplate?.id;

        window.Stripo.init({
            settingsId: 'stripoSettingsContainer',
            previewId: 'stripoPreviewContainer',
            codeEditorButtonId: 'codeEditor',
            locale: 'en',
            html: content,
            css: style,
            onTemplateLoaded: this.onTemplateLoaded,
            conditionsEnabled: true,
            customConditionsEnabled: true,
            onDataChanged: this.updateUnsavedChanges,
            mergeTags: customTags,
            apiRequestData: {
                emailId: !isSharedAccount ? 1 : randomId,
                userId: global.hotelGroupId
            },
            userFullName: 'Hey Channel Manager',
            getAuthToken:(callback) => {
                getAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        callback(data);
                    }
                    else{
                        handleNotification(data);;
                        this.initializeStripo();
                    }
                    this.setState({ block: false, currentTemplateId: randomId });
                }, `/api/hotel/Stripo/v1/authenticate`);
            }
        });
    }

    componentWillUnmount(){
        window.StripoApi.stop();
    }

    onTemplateLoaded = () => {
        this.setState({ block: false});
    }

    onSubmit = (e) => {
        e.preventDefault();
        
        this.setState({ block: true });

        const { currentTemplateId, templates, master } = this.state;

        const copy = [...templates];
        const currTemplate = copy.find(({id}) => id === currentTemplateId);

        window.StripoApi.getTemplate((html, css) => {
            const body = {
                request:{
                    id: currTemplate.isNew ? null : currentTemplateId,
                    content: html,
                    style: css,
                    language: currTemplate.language,
                    subject: currTemplate.subject,
                    templateMasterId: master?.id
                }
            };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                };

                if (data && data.response?.length > 0) {

                    currTemplate.unsavedChanges = false;
                    currTemplate.id = data.response[0]?.id;
                    currTemplate.isNew = false;
                    currTemplate.style = css;
                    currTemplate.content = html;

                    this.setState({ block: false, templates: copy, currentTemplateId: currTemplate.id },
                        () => handleNotification(data, null, <FormattedMessage id="Stripo.saved" />)
                    );
                    return;
                }
                else{
                    handleNotification(data);
                }
                this.setState({ block: false });
            }, `/api/hotel/Stripo/v1/templates`, body);
        }, false);
    }

    toggleTab = (val) => {
        this.setState({ activeTab: val });
    }

    toggleActiveCountryTab = (val) => {
        const { templates, currentTemplateId } = this.state;
        const copy = [...templates]

        const id = copy[val]?.id;
        const curr = copy.find((t) => t.id === currentTemplateId);
        
        if(currentTemplateId !== id){
            if(curr?.unsavedChanges){
                this.setState({ block: true }, () => 
                    window.StripoApi.getTemplate((html, css) => {
                        curr.content = html;
                        curr.style = css;
                        this.setState({ activeCountryTab: val, currentTemplateId: id, templates: copy }, () => this.restartStripo(id));
                    })
                );
            }
            else this.setState({ activeCountryTab: val, currentTemplateId: id, block: true }, () => this.restartStripo(id));
            
        };
    }

    restartStripo = (id) => {
        window.StripoApi.stop();
        this.initializeStripo(id);
    }

    addTab = (currId) => {
        const { templates, master, style, content } = this.state;

        const copyTemplate = currId ? templates.find(({id}) => id === currId) : null;
        
        const newTemplate =  {
            content: copyTemplate ? copyTemplate.content : content,
            language: null,
            style: copyTemplate ? copyTemplate.style : style,
            subject: copyTemplate ? copyTemplate.subject : '',
            templateMasterId: master?.id,
            id: Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1)),
            isNew: true,
            unsavedChanges: true
        }
        
        while(templates.filter(({id}) => id === newTemplate.id).length > 0){
            newTemplate.id = Math.floor(Math.random() * (Math.floor(99999) - Math.ceil(1)) + Math.ceil(1));
        };

        const copy = [...templates];

        copy.push(newTemplate);
    
        this.setState({ templates: copy, currentTemplateId: newTemplate.id, activeCountryTab: copy.findIndex(({id}) => id === newTemplate.id), block: true }, () => this.restartStripo(newTemplate.id));
    }

    handleCountry = (combo, action) => {
        const { currentTemplateId, templates } = this.state;

        const copy = [...templates];
        const emailTemplate = copy.find(({id}) => id === currentTemplateId);

        emailTemplate.language = combo?.value;
        emailTemplate.unsavedChanges = true;

        this.setState({ templates: copy });
    }

    deleteTemplate = () => {
        const { currentTemplateId, templates } = this.state;
        const isNew = this.props.match.params.id ? false : true;
        
        const copy = [...templates];
        const currTemplate = copy.find(({id}) => id === currentTemplateId);

        if(currTemplate.isNew || isNew){
            copy.splice(templates.findIndex(({id}) => id === currentTemplateId), 1);
            this.setState({ templates: copy, deleteModal: false }, () => this.toggleActiveCountryTab(0));
            return;
        };

        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                };

                copy.splice(templates.findIndex(({id}) => id === currentTemplateId), 1)
                const defaultId = copy[0].id;
                this.setState({ block: false, templates: copy, currentTemplateId: defaultId, activeCountryTab: 0, deleteModal: false }, () => {
                    handleNotification(data,<FormattedMessage id="Stripo.successfulyDeleted" />, <FormattedMessage id="AdminUserManagement.Success" />);
                    this.restartStripo(defaultId);
                });
            },`/api/hotel/Stripo/v1/templates/${currentTemplateId}`)
        );
    } 
    
    copyTemplate = () => {
        const { currentTemplateId } = this.state;

        this.addTab(currentTemplateId);
    }

    handleSubject = (e) => {
        e.preventDefault();
        const { currentTemplateId, templates } = this.state;

        const { value } = e?.target;

        const copy = [...templates];
        const emailTemplate = copy.find(({id}) => id === currentTemplateId);

        emailTemplate.subject = value;
        emailTemplate.unsavedChanges = true;

        this.setState({ templates: copy });
    }

    toggleDeleteModal = () => {
        this.setState(prev => ({ deleteModal: !prev.deleteModal }));
    }
    
    updateUnsavedChanges = (currId) => {
        const { currentTemplateId, templates } = this.state;
        let emailTemplate;

        if(currId){
            emailTemplate = templates.find(({id}) => id === currId);
        }
        else{
            emailTemplate = templates.find(({id}) => id === currentTemplateId);
        }
        
        emailTemplate.unsavedChanges = true;

        this.setState({ templates });
        return;
    }

    exportTemplate = (e) => {
        e.preventDefault();
        const { currentTemplateId, templates } = this.state;
        window.StripoApi.compileEmail((e, html) => {
            if(e){
                this.setState({ error: { message: e, messageType: 'danger' }, block: false });
            };

            const body = JSON.stringify(html);

            this.setState({ block: true }, () => {
                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
    
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false, exportModal: false });
                        return;
                    };
                    if (data.errors && data.errors.length > 0) {
                        this.setState({ block: false }, () => handleNotification(data));
                        return;
                    };

                    this.setState({ block: false, exportModal: false },
                        () => handleNotification(data,<FormattedMessage id="Stripo.successfulyExported" />, <FormattedMessage id="AdminUserManagement.Success" />)
                    );
                },`/api/hotel/Stripo/v1/templates/${currentTemplateId}/export`, body);
            });
        }, true)
    }

    toggleExportModal = () => {
        this.setState(prev => ({ exportModal: !prev.exportModal }));
    }

    handleInput = (e) => {
        const { checked, name } = e?.target;
        this.setState({ [name]: checked });
    }

    render() {
        const { block, error, currentTemplateId, master, templates, activeTab, activeCountryTab, deleteModal, exportModal, supportedLanguages, hotelList } = this.state;
        const { intl } = this.props;
        
        const emailTemplate = templates?.find(({id}) => id === currentTemplateId);
        
        const supportedLanguagesOptions = supportedLanguages.map((supLang) => {
            return({
                value: supLang,
                label: intl.formatMessage({ id: `generic.${supLang}` })
            })
        });
        
        return (
            <StyledCard block={block} error={error}>
                {deleteModal ? 
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={intl.formatMessage({ id: "Stripo.deleteConfirm" })}
                        toggleModal={this.toggleDeleteModal}
                        modal={deleteModal}
                        actionFunction={this.deleteTemplate}
                    />
                :''}
                {exportModal ?
                    <Modal style={{ display: 'flex', height: '90%', alignItems: 'center', justifyContent: 'center', padding: '5vh 15vw' }} isOpen={exportModal} toggle={this.toggleExportModal} className="smallModal">
                        <ModalBody className='p-4' style={{ borderRadius: '8px' }}>
                            <Form onSubmit={this.exportTemplate}>
                                <Row>
                                    <Col className='text-center'>
                                        <h4><FormattedMessage id="Stripo.exportTemplate"/></h4>
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col>
                                        <b><FormattedMessage id="Stripo.name"/></b>
                                    </Col>
                                    <Col>
                                        {master?.name}
                                    </Col>
                                </Row>
                                <Row className='mt-2'>
                                    <Col>
                                        <b><FormattedMessage id="Stripo.subject"/></b>
                                    </Col>
                                    <Col>
                                        {emailTemplate?.subject}
                                    </Col>
                                </Row>
                                {hotelList.find(({ value }) => value === master?.hotelId) ?
                                    <Row className='mt-2'>
                                        <Col>
                                            <b><FormattedMessage id="Stripo.hotel"/></b>
                                        </Col>
                                        <Col>
                                            {hotelList.find(({ value }) => value === master?.hotelId).label}
                                        </Col>
                                    </Row>
                                :''}
                                {emailTemplate?.exportDate ?
                                    <Row className='mt-2'>
                                        <Col>
                                            <b><FormattedMessage id="Stripo.exportDate"/></b>
                                        </Col>
                                        <Col>
                                            {moment(emailTemplate?.exportDate).format('YYYY-MM-DD')}
                                        </Col>
                                    </Row>
                                :''}
                                <Row className='mt-3'>
                                    <Col className='text-center'>
                                        <Button style={{ fontSize: '1em' }} disabled={!emailTemplate?.subject} className="btn btn-sm btn-host">
                                            <FormattedMessage id="Stripo.export"/>
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                :''}
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/CreateEmail">Emails</Link></BreadcrumbItem>
                    <BreadcrumbItem active>
                        {master ? master.name : <FormattedMessage id="Stripo.createNewTemplate"/>}
                    </BreadcrumbItem>
                </Breadcrumb>
                <Row>
                    <Col>
                        <Nav tabs className="border-0 mb-3">
                            <NavItem>
                                <NavLink className={activeTab === '0' ? 'tab-border-host text-host border-0 ' : 'border-0'} onClick={() => this.toggleTab('0')} style={{ cursor: "pointer" }}>
                                    <b><FormattedMessage id="Stripo.config"/></b>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === '1' ? 'tab-border-host text-host  border-0' : 'border-0'} onClick={() => this.toggleTab('1')} style={{ cursor: "pointer" }}>
                                    <b><FormattedMessage id="Stripo.edition" /></b>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col>
                        <Nav tabs>
                            {templates?.map((t, key) =>
                                <NavItem key={key} className="cursor-pointer">
                                    <NavLink className={activeCountryTab === key ? 'tab-border-host text-host border-0' : 'border-0'} onClick={() => this.toggleActiveCountryTab(key)}>
                                        <span className={`flag-icon flag-icon-${t.language && t.language.split('-')[1].toLowerCase()} mb-2 px-2`} />
                                        {t.unsavedChanges ? 
                                            <span className='stripoUnsavedChanges'/>
                                        :''}
                                    </NavLink>
                                </NavItem>
                            )}
                            <NavItem>
                                <NavLink className="d-flex h-100">
                                    <div>
                                        <Button onClick={this.addTab} className=" btn-host btn-sm " style={{ 'fontSize': '0.35rem' }}  >
                                            <i className="fas fa-plus"/>
                                        </Button>
                                    </div>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col className='text-right'>
                        <Button id="codeEditor" className="btn btn-sm btn-host mr-1 btn btn-secondary p-2 control-button">
                            {`</>`}
                        </Button>
                    </Col>
                </Row>
                {activeTab === '0' ? 
                    <Card style={{ height: '85%', width: '33.3333%', position: 'absolute', zIndex: '10', border: '0' }}>
                        <CardBody>
                            <Form className='py-2' onSubmit={this.onSubmit}>
                                <Row>
                                    <Col>
                                        {emailTemplate?.exportId ?
                                            <Badge color="primary"><FormattedMessage id="Stripo.exported"/></Badge>
                                        :''}
                                    </Col>
                                    <Col className='text-right'>
                                        <Button className="btn btn-sm btn-host mr-1" onClick={this.copyTemplate} title={intl.formatMessage({ id: "Stripo.duplicate" })}>
                                            <i className="fas fa-copy"/>
                                        </Button>
                                        <Button className='btn btn-host btn-sm mr-1' disabled={!emailTemplate?.subject || emailTemplate?.unsavedChanges} onClick={this.toggleExportModal} title={intl.formatMessage({ id: "Stripo.publish" })}>
                                            <i className="fas fa-envelope" />
                                        </Button>
                                        <Button className="btn btn-sm btn-delete mr-1" title={intl.formatMessage({ id: "Stripo.delete" })} disabled={emailTemplate?.isNew} onClick={this.toggleDeleteModal}>
                                            <i className="far fa-trash-alt"/>
                                        </Button>
                                        <Button type='submit' className="btn btn-host btn-sm" title={intl.formatMessage({ id: "Stripo.save" })}>
                                            <i className="fas fa-save"/>
                                            {emailTemplate?.unsavedChanges ? 
                                                <span className='stripoUnsavedChanges'/>
                                            :''}
                                        </Button>
                                    </Col>
                                </Row>
                                <div>
                                    <Row className='my-2'>
                                        <Col>
                                            <FormattedMessage id="Stripo.subject"/>
                                        </Col>
                                    </Row>
                                    <Row className='my-2'>
                                        <Col>
                                            <Input 
                                                type='text'
                                                required
                                                name='subject'
                                                value={emailTemplate?.subject ? emailTemplate.subject : ''}
                                                onChange={this.handleSubject}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row className='my-2'>
                                        <Col>
                                            <FormattedMessage id="Stripo.lang"/>
                                        </Col>
                                    </Row>
                                    <Row className='my-2'>
                                        <Col>
                                            <CustomSelect
                                                options={supportedLanguagesOptions}
                                                required
                                                isOptionDisabled={(option) => templates.filter(({language}) => language === option.value).length > 0}
                                                isSearchable
                                                placeholder={""}
                                                onChange={this.handleCountry}
                                                value={emailTemplate?.language ? supportedLanguagesOptions?.find(c => c.value === emailTemplate?.language) : null}
                                                enableRequired
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <Row className='mt-2'>
                                    <Col>
                                        <FormattedMessage id="Stripo.lastUpdate"/>:
                                    </Col>
                                    <Col className="text-right">
                                        {moment(emailTemplate?.updatedAt).format('YYYY-MM-DD')}
                                    </Col>
                                </Row>
                                {emailTemplate?.exportId ? 
                                    <>
                                        <div>
                                            <Row className='my-2'>
                                                <Col>
                                                    <FormattedMessage id="Stripo.exportDate"/>:
                                                </Col>
                                                <Col className="text-right">
                                                    {moment(emailTemplate?.exportDate).format('YYYY-MM-DD')}
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row className='my-2'>
                                                <Col>
                                                    <FormattedMessage id="Stripo.exportId"/>
                                                </Col>
                                                <Col className="text-right">
                                                    {emailTemplate?.exportId}
                                                </Col>
                                            </Row>
                                        </div>
                                    </>
                                :''}
                            </Form>
                        </CardBody>
                    </Card>
                :''}
                <Row>
                    <Col className='col-4' id="stripoSettingsContainer"></Col>
                    <Col id="stripoPreviewContainer"></Col>
                </Row>
            </StyledCard>
        )
    }
}
    
export default injectIntl(EmailCreation)
