import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form } from 'reactstrap';
import { CustomTable } from '../../../Base/Common/CustomTable';
import { getAPI } from "../../../Base/API";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import AvailabilityHistoryFilters from './AvailabilityHistoryFilters';

class AvailabilityHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            day: moment(),
            availabilityHistory: [],
            restrictionHistory: [],
            priceHistory: [],
            pageSize: 10,
            count: 0,
            pageIndex: 0,
            resCount: 0,
            resPageIndex: 0,
            priceCount: 0,
            pricePageIndex: 0,
            roomCategories: []
        }
    }

    componentDidMount() {
        if (!this.props.isAdminPage) {
            this.getRoomCategories('/api/hotel/RoomCategory/v1/Info');
        }
    }

    getAvailabilityHistory = () => {
        this.setState({ block: true, availabilityHistory: [] });

        var params = '';
        if (this.state.blockId) params += `&blockId=${this.state.blockId}`;
        if (this.state.hotelId) params += `&hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ block: false, availabilityHistory: data.response, count: data.count, error: [] });
            }
        }, `/api/User/v1/History/AvailabilityHistory?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}&day=${moment(this.state.day).format("YYYY-MM-DD")}&roomCategoryId=${this.state.roomCategoryId}` + params);
    }

    getRoomCategories = (url) => {
        this.setState({ blockCategories: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockCategories: false });
                return;
            }

            if (data) {
                var results = [];
                var combo = [];

                if (data.response && data.response.length > 0) {
                    results = data.response;
                }
                else if (data.length > 0) {
                    results = data;
                }

                results.map((item) =>
                    combo.push({ 'label': `${item.description} (${item.code}) ${item.pmsId ? '- Pms Id: ' +  item.pmsId : ''}`, 'value': item.id })
                )

                this.setState({ blockCategories: false, roomCategories: combo, error: [] });
            }
            else {
                this.setState({ blockCategories: false, roomCategories: [], error: [] });
            }
        }, url);
    }

    getBlocks = (roomCategoryId) => {
        this.setState({ blockBlocks: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockBlocks: false });
                return;
            }
            if (data) {
                if (data.response && data.response.length > 0) {
                    var list = [];

                    data.response.forEach(el => {
                        list.push({ label: el.code, value: el.blockId});
                    });

                    this.setState({ blockBlocks: false, blockList: list, error: [] });
                }
                else {
                    this.setState({ blockBlocks: false, blockList: [], error: [] });
                }
            }
        }, `/api/Rate/Rate/v1/Block?roomCategoryId=${roomCategoryId}`);
    }

    handleChangeSelect = (name, combo) => {
        if (name === 'hotelId' && combo) {
            this.getRoomCategories(`/api/hotel/RoomCategory/v1/HotelId/${combo.value}`);

            this.setState({ roomCategoryId: null });
        }

        if (name === 'roomCategoryId' && combo) {
            this.getBlocks(combo.value);

            this.setState({ blockId: null });
        }

        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleDate = (date) => {
        this.setState({
            day: date
        })
    }

    render() {
        const { availabilityHistory, pageSize, roomCategories, blockList, block } = this.state;

        const availabilityColumns = [
            {
                dataField: 'blockId',
                text: "Block",
                sort: true
            },
            {
                dataField: 'inventory',
                text: <FormattedMessage id="EditRate.Inventory" />,
                sort: true
            },
            {
                dataField: 'outOfInventory',
                text: <FormattedMessage id="PMSHistory.OutOfInventory" />,
                sort: true,
                hidden: this.props.isOpModeNone
            },
            {
                dataField: 'outOfOrder',
                text: <FormattedMessage id="PMSHistory.OutOfOrder" />,
                sort: true,
                hidden: this.props.isOpModeNone
            },
            {
                dataField: 'waitingList',
                text: <FormattedMessage id="PMSHistory.WaitingList" />,
                sort: true,
                hidden: this.props.isOpModeNone
            },
            {
                dataField: 'optional',
                text: <FormattedMessage id="PMSHistory.Optional" />,
                sort: true,
                hidden: this.props.isOpModeNone
            },
            {
                dataField: 'reserved',
                text: <FormattedMessage id="PMSHistory.Reserved" />,
                sort: true
            },
            {
                dataField: 'freeRooms',
                text: <FormattedMessage id="LogsAvailabilityPrice.FreeRooms" />,
                sort: true
            },
            {
                dataField: 'version',
                text: <FormattedMessage id="PMSHistory.Version" />,
                sort: true
            },
            {
                dataField: 'createdAt',
                text: <FormattedMessage id="PMSHistory.CreatedAt" />,
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            },
            {
                dataField: 'updatedAt',
                text: <FormattedMessage id="PMSHistory.UpdatedAt" />,
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            },
            {
                dataField: 'updatedBy',
                text: <FormattedMessage id="PMSHistory.UpdatedBy" />,
                sort: true
            },
            {
                dataField: 'pmsTimestamp',
                text: "Pms Timestamp",
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : '',
                hidden: this.props.isOpModeNone
            }
        ]

        return (
            <BlockUi tag="div" blocking={block}>
                <Form onSubmit={(e) => { e.preventDefault(); this.setState({ pageIndex: 0, block: true }, () => this.getAvailabilityHistory()); }}>
                    <AvailabilityHistoryFilters
                        isAdminPage={this.props.isAdminPage}
                        hotelList={this.props.hotelList}
                        handleChangeSelect={this.handleChangeSelect}
                        roomCategories={roomCategories}
                        roomCategoryId={this.state.roomCategoryId}
                        hotelId={this.state.hotelId}
                        blockList={blockList}
                        blockId={this.state.blockId}
                        day={this.state.day}
                        handleDate={this.handleDate}
                        hotelId={this.state.hotelId}
                        blockCategories={this.state.blockCategories}
                        blockBlocks={this.state.blockBlocks}
                    />
                </Form>

                <div className="my-3">
                    <CustomTable
                        keyField="version"
                        data={availabilityHistory}
                        columns={availabilityColumns}
                        shadow={false}
                        exportCSV={false}
                        showTotal={true}
                        remote={true}
                        search={false}
                        hideSizePerPage={true}
                        page={this.state.pageIndex + 1}
                        sizePerPage={pageSize}
                        totalSize={this.state.count}
                        onTableChange={(_, { page }) => { this.setState({ pageIndex: page - 1 }, () => this.getAvailabilityHistory()) }}
                    />
                </div>
            </BlockUi>
        );
    }

};
export default injectIntl(AvailabilityHistory);