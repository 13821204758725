import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Col, Row, Progress } from 'reactstrap';
import { getAPI } from "../Base/API";
import { ErrorAlert } from "../Common/ErrorAlert";
import { FormatAmountNumber } from '../Base/Common/CommonUIComponents';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import ChartCard from "./Common/ChartCard";
import moment from 'moment';
import { handleNotification } from '../Base/Notification';

class Payments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: false,
            paymentsAndRefunds: [],
            cancelationFees: [],
            totalPayments: 0,
            totalRefunds: 0,
            totalPotentials: 0,
            totalEffective: 0,
            paymentsAndRefundsAverages: [],
            cancelationFeesAverages: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.search === true && nextProps.search !== this.props.search) {
            this.getChartsData();
        }
    }

    componentDidMount() {
        this.getChartsData();
    }

    getChartsData = () => {
        this.setState({ block: true }, () => {

            getAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }

                    this.organizeData(data.response);
                }
                else this.setState({ block: false })
            }, `/api/Price/Analytics/v1?fromDate=${moment(this.props.startDate).format("YYYY-MM-DD")}&toDate=${moment(this.props.endDate).format("YYYY-MM-DD")}&dataKeys=17&dataKeys=18&dataKeys=6&dataKeys=5&dataKeys=19&dataKeys=20`);
        });
    }

    organizeData = (data) => {
        const { dates } = this.props;
                   
        const charts = {
            extras: {
                totalSales: 0,
                totalAmount: 0,
                extrasPerDay: [[this.props.intl.formatMessage({ id: "BEAnalitics.Date" }), this.props.intl.formatMessage({ id: "BEAnalitics.TotalSales" }), this.props.intl.formatMessage({ id: "BEAnalitics.TotalAmount" })]].concat(dates.map(date => [date, 0, 0])),
                totalAmountPerExtra: [["Extra", "Amount"]],
                totalSalesPerExtra: [["Extra", "Sales"]],
                topRates: [],
                topCategories: []
            },
            experiencesExtras: {
                totalSales: 0,
                totalAmount: 0,
                extrasPerDay: [[this.props.intl.formatMessage({ id: "BEAnalitics.Date" }), this.props.intl.formatMessage({ id: "BEAnalitics.TotalSales" }), this.props.intl.formatMessage({ id: "BEAnalitics.TotalAmount" })]].concat(dates.map(date => [date, 0, 0])),
                totalAmountPerExtra: [["Extra", "Amount"]],
                totalSalesPerExtra: [["Extra", "Sales"]],
                topRates: [],
                topCategories: []
            }
        }
                        

        if (data) {
            data.forEach(date => {
                const dateIndex = dates.findIndex(el => el === moment(date.value).format("YYYY-MM-DD")) + 1;

                date.subset.forEach(serviceCode => {
                    serviceCode.subset.forEach(roomCode => {

                        roomCode.subset.forEach(rateCode => {
                            const extraType = serviceCode.isXP && rateCode.isXP ? 'experiencesExtras' : 'extras';

                            const quantity = rateCode.subset.find(el => el.key === "TotalServiceQuantity")?.value;
                            const amount = rateCode.subset.find(el => el.key === "TotalServiceAmount")?.value;
                            
                            //Total Cards
                            charts[extraType].totalSales += quantity;
                            charts[extraType].totalAmount += amount;

                            //Extras per Day
                            charts[extraType].extrasPerDay[dateIndex][1] += quantity;
                            charts[extraType].extrasPerDay[dateIndex][2] += amount;

                            //Values per Extra
                            charts[extraType].totalSalesPerExtra = this.setValuesPerExtra(charts[extraType].totalSalesPerExtra, serviceCode.value, quantity);
                            charts[extraType].totalAmountPerExtra = this.setValuesPerExtra(charts[extraType].totalAmountPerExtra, serviceCode.value, amount);

                            //Top
                            charts[extraType].topRates = this.setTop(charts[extraType].topRates, 'rate', rateCode.value, quantity, amount);
                            charts[extraType].topCategories = this.setTop(charts[extraType].topCategories, 'roomCategory', roomCode.value, quantity, amount);
                        })
                    })
                })
            })
        }

        charts.extras.topRates.sort((a, b) => b.amount - a.amount);
        charts.extras.topCategories.sort((a, b) => b.amount - a.amount);
        charts.experiencesExtras.topRates.sort((a, b) => b.amount - a.amount);
        charts.experiencesExtras.topCategories.sort((a, b) => b.amount - a.amount);

        this.setState({
            extras: charts.extras,
            experiencesExtras: charts.experiencesExtras,
            block: false
        })
    }

    setTop = (array, type, typeValue, quantity, amount) => {
        const index = array.findIndex(el => el[type] === typeValue);

        if (index !== -1) {
            array[index].quantity += quantity;
            array[index].amount += amount;
        }
        else {
            array.push({ [type]: typeValue, quantity, amount });
        }

        return array;
    }

    setValuesPerExtra = (chartData, extra, value) => {
        const index = chartData.findIndex(el => el[0] === extra);

        if (index !== -1) {
            chartData[index][1] += value;
        }
        else {
            chartData.push([extra, value]);
        }

        return chartData;
    }


    render() {
        const { valuesCard, sourceColors } = this.props;

        const charts = this.state[this.props.selectedExtraType] || {};

        const totalAmountTopRates = charts.topRates?.reduce((acc, cur) => acc + cur.amount, 0)
        const totalAmountTopCategories = charts.topCategories?.reduce((acc, cur) => acc + cur.amount, 0)

        const perDayOptions = {
            colors: ['#12b5cb', '#ffb703'],
            legend: { 
                position: 'top',
                alignment: 'center',
            },
            series: {
                0: { axis: 'Quantity' },
                1: { axis: 'Amount' }
            },
            axes: {
                y: {
                    Quantity: { label: this.props.intl.formatMessage({ id: "BEAnalitics.#Sales" }) },
                    Amount: { label: this.props.intl.formatMessage({ id: "BEAnalitics.Amount" }) }
                }
            }
        }

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                {charts ?
                    <>
                        <Row>
                            <Col>
                                {valuesCard(<FormattedMessage id="BEAnalitics.TotalSales" />, charts.totalSales)}
                            </Col>
                            <Col>
                                {valuesCard(<FormattedMessage id="BEAnalitics.TotalAmount" />, <FormatAmountNumber value={charts.totalAmount} />)}
                            </Col>
                        </Row>
                        <Row className="py-3">
                            <Col>
                                {charts.extrasPerDay?.length > 1 ?
                                    <ChartCard
                                        chartDivStyle={{ padding: '20px 20px 0 20px' }}
                                        chartType='LineChart'
                                        data={charts.extrasPerDay}
                                        options={perDayOptions}
                                        title={<FormattedMessage id="BEAnalitics.ExtrasPerDay" />}
                                    />
                                : ''}
                            </Col>
                        </Row>

                        <Row className="pb-3">
                            <Col>
                                <Card body className="shadow border-0 h-100 mb-3">
                                    <span className="text-xs font-weight-bold text-secondary text-uppercase mb-2">
                                        <FormattedMessage id="BEAnalitics.TopRates" />
                                    </span>

                                    <div className="text-center mt-2">
                                        {charts.topRates?.slice(0, 4).map((rate, key) => {
                                            return <div key={key} className="mb-3">
                                                <Row style={{ fontSize: '14px' }}>
                                                    <Col className="text-left">{rate.rate}</Col>
                                                    <Col className="text-right"><FormatAmountNumber value={rate.amount} /></Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Progress style={{ height: '10px' }} max={totalAmountTopRates} value={rate.amount || 0} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        })}
                                    </div>
                                </Card>
                            </Col>
                            <Col>
                                <Card body className="shadow border-0 h-100 mb-3">
                                    <span className="text-xs font-weight-bold text-secondary text-uppercase mb-2">
                                        <FormattedMessage id="BEAnalitics.TopCategories" />
                                    </span>

                                    <div className="text-center mt-2">
                                        {charts.topCategories?.slice(0, 4).map((cat, key) => {
                                            return <div key={key} className="mb-3">
                                                <Row style={{ fontSize: '14px' }}>
                                                    <Col className="text-left">{cat.roomCategory}</Col>
                                                    <Col className="text-right"><FormatAmountNumber value={cat.amount} /></Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Progress style={{ height: '10px' }} max={totalAmountTopCategories} value={cat.amount} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        })}
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                        <Row className="mb-5">
                            <Col sm={6}>
                                <ChartCard
                                    chartType='PieChart'
                                    data={charts.totalSalesPerExtra}
                                    options={{
                                        pieHole: 0.6,
                                        pieSliceText: 'none',
                                        colors: sourceColors
                                    }}
                                    title={<FormattedMessage id="BEAnalitics.SalesPerExtra" />}
                                />
                            </Col>
                            <Col sm={6}>
                                <ChartCard
                                    chartType='PieChart'
                                    data={charts.totalAmountPerExtra}
                                    options={{
                                        pieHole: 0.6,
                                        pieSliceText: 'none',
                                        colors: sourceColors
                                    }}
                                    title={<FormattedMessage id="BEAnalitics.AmountPerExtra" />}
                                />
                            </Col>
                        </Row>
                    </>
                : ''}
            </BlockUi>
        )
    }
}

export default injectIntl(Payments);