import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Form, Button, Breadcrumb, BreadcrumbItem, FormGroup, Label, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { StyledCard, ActiveInactiveStatusBadge } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Notification";
import CustomSelect from '../../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI, postAPI, putAPI, deleteAPI } from "../../Base/API";
import { DateRangePicker } from 'react-dates';
import { ErrorAlert } from "../../Common/ErrorAlert";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { CustomDateRangePicker } from '../../Base/Common/CustomReactDates';
import { Link } from 'react-router-dom';

class PromoCodeDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            block: false,
            promoCode: {},
            ratesList: [],
            modal: false,
            id: this.props.match ? this.props.match.params.id : this.props.id
        }
    }

    componentDidMount() {
        if (this.state.id) {
            this.getPromoCodeDetails();
        }

        this.getRates();
    }

    getPromoCodeDetails = (id) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    this.setState({ promoCode: data.response[0] });
                }
                this.setState(({ block: false }));
            }
        }, `/api/Rate/Promotion/v1/promoCode/${this.state.id}`);
    }

    getRates = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    let list = [];
                    data.response.forEach((item) =>
                        list.push({ value: item.id, label: item.name, description: item.desctiption, active: item.active })
                    )
                    this.setState({ block: false, ratesList: list });
                }
                this.setState(({ block: false }));
            }
        }, '/api/Rate/Rate/v1/Info');
    }

    savePromoCode = (e) => {
        e.preventDefault();
        this.setState(({ block: true }));

        const body = { ...this.state.promoCode };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="PromoCodeDetails.PromoCodeSaved" />, <FormattedMessage id="General.Success" />);

                    if (this.props.fromBEConfigDashboard) {
                        this.props.toggleDetails('showPromoCodeDetails', 'promoCodeId', null);
                    }
                    else {
                        this.props.history.push('/PromoCodeDetails/' + data.response[0].id);
                    }

                    this.setState({ block: false, error: errorMessage, sellItem: data.response[0] });
                }
            }
        }, '/api/Rate/Promotion/v1/promoCode', body );
    }

    deletePromoCode = (e) => {
        e.preventDefault();
        this.setState(({ block: true }));

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="PromoCodeDetails.PromoCodeDeleted" />, <FormattedMessage id="General.Success" />);

                    if (this.props.fromBEConfigDashboard) {
                        this.props.toggleDetails('showPromoCodeDetails', 'promoCodeId', null);
                    }
                    else {
                        this.props.history.push('/PromoCodes');
                    }

                    this.setState({ block: false, error: errorMessage, sellItem: data.response[0] });
                }
            }
        }, `/api/Rate/Promotion/v1/promoCode/${this.state.id}`);
    }

    togglePromoCodeStatus = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    const msg = data.response[0].active ? <FormattedMessage id="PromoCodeDetails.PromoCodeActivated" /> : <FormattedMessage id="PromoCodeDetails.PromoCodeInactivated" />
                    handleNotification(data, msg, <FormattedMessage id="General.Success" />);

                    this.setState({ block: false, error: errorMessage, promoCode: data.response[0] });
                }
            }
            this.setState(({ block: false }));
        }, `/api/Rate/Promotion/v1/promoCode/${this.state.id}/toggle?activate=${!this.state.promoCode.active}`);
    }

    handleRate = (e, isToAdd, rateId) => {
        e.preventDefault();
        const promoCode = { ...this.state.promoCode };
        let rates = promoCode.rateIds ? promoCode.rateIds : [];

        if (isToAdd) {
            rates.push(rateId);
        }
        else {
            rates = rates.filter(el => el !== rateId);
        }

        promoCode.rateIds = rates;

        this.setState({ promoCode, modal: false });
    }

    toggleModal = (cancelId) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            cancelSelected: cancelId
        }));
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        if(name === 'code'){
            const promoCodeElement = document.getElementById("promoCodeEl");
            if (promoCodeElement) {
                promoCodeElement.setCustomValidity("");
                
                const containsSpecialCharacters = /^[a-zA-Z0-9]*$/;
    
                if (containsSpecialCharacters && !containsSpecialCharacters.test(promoCodeElement.value)) {
                    promoCodeElement.setCustomValidity(this.props.intl.formatMessage({ id: "BookingPromotionCard.CodeError" }));
                    promoCodeElement.reportValidity();
                }
            }
        }

        this.setState({
            promoCode: {
                ...this.state.promoCode,
                [name]: value
            }
        });
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    render() {
        const { promoCode, ratesList, modal } = this.state;

        return (
            <div>
                {!this.props.fromBEConfigDashboard ? 
                    <Breadcrumb>
                        <BreadcrumbItem><Link to="/promocodes">Promo Codes</Link></BreadcrumbItem>
                        <BreadcrumbItem active>
                            {promoCode ? promoCode.code : '..'}
                        </BreadcrumbItem>
                    </Breadcrumb>
                    : ''}
                <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-percent'} title={'PromoCodeDetails.PromoCodeDetails'}>
                    <Form onSubmit={this.savePromoCode}>
                        <Row>
                            {this.state.id ?
                                <>
                                    <Label sm={2}>
                                        <FormattedMessage id="home.status" />
                                    </Label>
                                    <Col>
                                        <ActiveInactiveStatusBadge status={promoCode.active} />
                                    </Col>
                                </>
                            : ''}
                            <Col className="text-right">
                                {this.state.id ?
                                    <>
                                        <Button className="btn btn-host btn-sm mr-2" onClick={this.deletePromoCode}>
                                            <span className="fas fa-trash-alt" />
                                        </Button>
                                        <Button className="btn btn-host btn-sm mr-2" onClick={(e) => this.togglePromoCodeStatus(e)}>
                                            <span className="fas fa-power-off" />
                                        </Button>
                                    </>
                                : ''}
                                <Button className="btn btn-host btn-sm" type="submit">
                                    <span className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Label sm={2}>
                                <FormattedMessage id="PromoCodeDetails.Code" />
                            </Label>
                            <Col sm={4}>
                                <Input
                                    type="text" id="promoCodeEl" required name="code"
                                    onChange={(e) => this.handleChange(e)}
                                    value={promoCode.code || ''}
                                />
                            </Col>
                            <Col sm={2}>
                                <Label><FormattedMessage id="PromoCodeDetails.Validity" /></Label>
                            </Col>
                            <Col sm={4}>
                                <CustomDateRangePicker
                                    required
                                    startDate={promoCode.bookDateStart}
                                    startDateId="your_unique_start_date_id_rule"
                                    isOutsideRange={day => false}
                                    endDate={promoCode.bookDateEnd}
                                    endDateId="your_unique_end_date_id_rule"
                                    onDatesChange={(startDate, endDate) => { this.setState({ promoCode: { ...this.state.promoCode, bookDateStart: startDate, bookDateEnd: endDate } }); }}
                                    numberOfMonths={global.isMobile ? 1 : 2}
                                    showYearOptions={{ pastYears: true, futureYears: true }}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <h5 className="mt-3">Rates Associados</h5>
                            </Col>
                            <Col className="text-right">
                                <Button className="btn btn-host btn-sm" onClick={this.toggleModal}>
                                    <span className="fas fa-plus" />
                                </Button>
                            </Col>
                        </Row>
                        <hr className="mt-0" />

                        <Row>
                            <Col>
                                <b>Rate</b>
                            </Col>
                            <Col className="col-3">
                                <b>Status</b>
                            </Col>
                            <Col className="col-1"/>
                        </Row>

                        {promoCode && promoCode.rateIds && promoCode.rateIds.map((rateId, key) => {
                            const rate = ratesList.find(r => r.value === rateId);

                            return rate ?
                                <Row key={key} className="overbglight py-1">
                                    <Col>
                                        {rate.label}
                                    </Col>
                                    <Col className="col-3">
                                        <ActiveInactiveStatusBadge status={rate.active} />
                                    </Col>
                                    <Col className="col-1 text-right">
                                        <span onClick={(e) => this.handleRate(e, false, rateId)}>
                                            <i className="fas fa-trash-alt pointer mr-2" style={{ color: 'red' }} />
                                        </span>
                                    </Col>
                                </Row>
                            : ''
                        })}

                    </Form>
                </StyledCard>

                <Modal isOpen={modal} toggle={this.toggleModal}>
                    <ModalHeader toggle={this.toggleModal}>
                        <FormattedMessage id="AddRate.AssociateRate" />
                    </ModalHeader>

                    <ModalBody className="p-0">
                        <BlockUi tag="div" blocking={this.state.block}>
                            <ErrorAlert error={this.state.error} />
                            <Form onSubmit={(e) => this.handleRate(e, true, this.state.rateId)}>
                                <Card className="shadow border-0 p-0">
                                    <CardBody className="m-0">
                                        {global.isMobile ?
                                            <Row className="bg-white">
                                                <Col className="text-right">
                                                    <Button type="submit" className="btn btn-sm btn-host float-right">
                                                        <i className="fas fa-plus" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        :''}
                                        <FormGroup sm="true" row>
                                            <Label for="assignee" sm={2}> <FormattedMessage id="AddRate.Rate" /> </Label>
                                            <Col sm={10}>
                                                <CustomSelect
                                                    options={promoCode.rateIds ? ratesList.filter(l => promoCode.rateIds.some(id => id === l.value) === false) : ratesList}
                                                    placeholder={<FormattedMessage id="AddRate.ChooseRate" />}
                                                    onChange={this.handleChangeSelect.bind(this, 'rateId')}
                                                    isClearable isSearchable
                                                    required
                                                />
                                            </Col>
                                        </FormGroup>
                                        {!global.isMobile ? 
                                            <Row className="bg-white">
                                                <Col className="text-right">
                                                    <Button type="submit" className="btn btn-sm btn-host float-right">
                                                        <i className="fas fa-plus" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        :''}
                                    </CardBody>
                                </Card>
                            </Form>
                        </BlockUi>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default injectIntl(PromoCodeDetails)