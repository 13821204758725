import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CustomInput, Input, Label, Button, FormGroup, Form } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Common/ErrorAlert";
import { getAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import CustomSelect from '../Base/Common/CustomSelect';
import countryList from "react-select-country-list";
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { CountryCustomSelect } from '../Base/Common/CommonUIComponents';
import Authorization, { CheckAuthorization } from '../Base/Authorization';

export class ManualReservationGuests extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            searchMode: false,
            searchField: null,
            pageSize: 4,
            pageIndex: 0,
            profilesList: [],
            profile: null,
            index: null,
            confirmDelete: false,
            isAgencyOrCompany: false,
            countries: countryList
        };
    }

    componentDidMount() {
        const { reservation } = this.props;
        const { profile } = this.state;
        if (profile === null) {
            const profilesLength = reservation.profiles.length;
            let rph = profilesLength > 0 ? parseInt(reservation.profiles[profilesLength - 1].rph) + 1 : '1';

            this.setState({ profile: { rph: rph.toString(), isGuest: true, isBooker: profilesLength === 0 } });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.reservation !== this.props.reservation) {
            const profilesLength = nextProps.reservation.profiles.length;
            let rph = profilesLength > 0 ? parseInt(nextProps.reservation.profiles[profilesLength - 1].rph) + 1 : '1';

            this.setState({
                profile: { rph: rph.toString(), isGuest: true, isBooker: profilesLength === 0 }
            })
        }
    }

    searchProfile = () => {
        const { pageSize, pageIndex, searchField } = this.state;
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ error: errorMessage }));
                }

                this.setState({ profilesList: data.response, totalItems: data.totalItems });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/gms/Profile/v1/profile/master/search/${searchField}?pageSize=${pageSize}&pageIndex=${pageIndex}`)
    }

    addProfile = (e) => {
        e.preventDefault();
        const { reservation } = this.props;

        if (this.state.index === null) {
            const mainGuest = document.getElementById('MainGuest');
            if (reservation.profiles.find(p => p.isBooker) && this.state.profile.isBooker) {
                mainGuest.setCustomValidity(this.props.intl.formatMessage({ id: "ManualReservation.ReservationCannotHaveMoreThanOneMainGuest" }));
            }
            else {
                mainGuest.setCustomValidity("");
            }
            if (!this.form.current.reportValidity()) {
                return;
            }
            this.props.addGuest(this.state.profile);
        } else {
            this.props.updateGuest(this.state.profile, this.state.index);
        }

        const rph = `${parseInt(this.state.profile.rph) + 1}`;
        this.setState({ profile: null, index: null }, _ => this.setState({ profile: { rph: rph, isGuest: true } }));
    }

    editGuest = (k) => {
        this.setState({
            profile: this.props.reservation.profiles[k], index: k
        });
    }

    handleChangeRadio = (evt) => {
        const { name, id } = evt.target;
        
        this.setState({
            profile: {
                ...this.state.profile,
                [name]: id
            }
        })
    }

    setGuestType = (evt) => {
        const { name, id } = evt.target;

        if (id === 'Agency' || id === 'Company') {
            this.setState(prevState => ({
                isAgencyOrCompany: true,
                profile: {
                    ...prevState.profile,
                    isGuest: false,
                    isChildren: false,
                    [name]: id
                }
            }))
        }
        else {
            this.setState(prevState => ({
                isAgencyOrCompany: false,
                profile: {
                    ...prevState.profile,
                    isGuest: true,
                    [name]: id
                }
            }))
        }
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            profile: {
                ...this.state.profile,
                [name]: combo ? combo.value : null
            }
        });
    };

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({ profile: { ...this.state.profile, [name]: value } })
    }

    handleCheckBox = (field, field2) => {
        this.setState({
            profile: {
                ...this.state.profile,
                [field]: !this.state.profile[field],
                [field2]: false
            }
        });
    }

    handlePagination = (value) => {
        this.setState({
            pageIndex: this.state.pageIndex + value
        }, () => this.searchProfile());
    }
    
    removeGuest = (index) => {
        this.setState({
            profile: null,
            confirmDelete: false
        }, () => this.props.removeGuest(index))
    }

    doSearch = (e) => {
        e.preventDefault();
        this.setState({
            pageIndex: 0
        }, () => this.searchProfile())
    }

    selectProfile = (newProfile) => {
        let { profile } = this.state;
        if (profile) {
            Object.assign(profile, newProfile);
        }
        else {
            profile = Object.assign({}, newProfile);
        }
        this.setState({ profile, searchMode: false });
    }

    render() {
        const { reservation } = this.props;
        const { block, error, profilesList, profile, confirmDelete, countries } = this.state;
        
        const documents = [
            {
                value: 'Passport',
                label: <FormattedMessage id="ManualReservation.Passport" />
            },
            {
                value: 'IdCart',
                label: <FormattedMessage id="ManualReservation.IdCard" />
            },
            {
                value: 'FiscalNumber',
                label: <FormattedMessage id="ManualReservation.FiscalNumber" />
            }
        ]

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Card className="mb-3 border-0 shadow reservationGuests">
                    <CardBody>
                        {
                            this.state.searchMode ?
                                <Row>
                                    <Col className="border-right bg-host-hover col-1" style={{ 'cursor': 'pointer' }} onClick={() => this.setState({ searchMode: false })}>
                                        <div className="my-3 text-center">
                                            <Button className="btn btn-host btn-sm ">
                                                <i className="far fa-address-card"></i>
                                            </Button>
                                        </div>

                                        <div className="mt-3 text-center text-host h-100" style={{ 'minHeight': '200px' }} >
                                            <div style={{
                                                'fontWeight': '500',
                                                'transform': 'matrix(0, -1, 1, 0, 0, 0)', 'position': 'absolute',
                                                'margin-right': '-20px',
                                                'right': '50%',
                                                'top': '50%'
                                            }}>
                                                <FormattedMessage id="ManualReservation.Profile" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <Form onSubmit={(e) => this.doSearch(e)}>
                                            <Row>
                                                <Col className="mx-5">
                                                    <Input
                                                        type="text"
                                                        onChange={(evt) => this.setState({ searchField: evt.target.value })}
                                                    />
                                                </Col>
                                                <Col className="col-1">
                                                    <Button className="btn btn-host btn-sm" type="submit" disabled={this.state.searchField === '' || this.state.searchField === null}>
                                                        <i className="fas fa-search"></i>
                                                    </Button>
                                                </Col>
                                                <Col className="col-12 mx-5">
                                                    <Label className="text-muted small" for="firstname"><FormattedMessage id="ManualReservation.SerachFor" /></Label>
                                                </Col>
                                            </Row>
                                        </Form>

                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            height: '70%'
                                        }} className="mx-5">
                                            {profilesList && profilesList.map((profile, key) =>
                                                <div key={key}>
                                                    <Row className="overbglight pointer py-3" onClick={() => this.selectProfile(profile)} >
                                                        <Col>
                                                            {profile.firstName} {profile.lastName}
                                                        </Col>
                                                        <Col>
                                                            {profile.email}
                                                        </Col>
                                                        <Col>
                                                            {profile.phoneNumber}
                                                        </Col>
                                                    </Row>
                                                    <hr className="my-1" />
                                                </div>
                                            )}
                                        </div>

                                        {this.state.totalItems > 0 ?
                                            <Row className="mt-2">
                                                <Col>
                                                    <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(-1)} disabled={this.state.pageIndex === 0}>
                                                        <i class="fas fa-angle-left" />
                                                    </Button>
                                                </Col>
                                                <Col className="text-right">
                                                    <Button className="btn- btn-host btn-small btn-sm" onClick={() => this.handlePagination(1)} disabled={Math.trunc(this.state.totalItems / this.state.pageSize) <= this.state.pageIndex}>
                                                        <i class="fas fa-angle-right" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        : ''}
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col /*className={CheckAuthorization("manualReservation:profileSearch", null) ? "col-11" : "col-12"}*/>
                                        <form ref={this.form}>
                                            <Row className="mb-2">
                                                <Col className="col-2">
                                                    <h5 className="text-muted my-0"><FormattedMessage id="ManualReservation.Profile" />*</h5>
                                                </Col>
                                                <Col>
                                                    <FormGroup>
                                                        <CustomInput type="radio" name="guestType" label={<FormattedMessage id="ManualReservation.Person" />} id="Person" inline
                                                            onChange={this.setGuestType.bind(this)}
                                                            checked={profile && profile.guestType === 'Person' ? true : false} required
                                                        />
                                                        <CustomInput type="radio" name="guestType" label={<FormattedMessage id="ManualReservation.Company" />} id="Company" inline
                                                            onChange={this.setGuestType.bind(this)}
                                                            checked={profile && profile.guestType === 'Company' ? true : false} required
                                                        />
                                                        <CustomInput type="radio" name="guestType" label={<FormattedMessage id="ManualReservation.Agency" />} id="Agency" inline
                                                            onChange={this.setGuestType.bind(this)}
                                                            checked={profile && profile.guestType === 'Agency' ? true : false} required
                                                        />
                                                        <CustomInput type="radio" name="guestType" label={<FormattedMessage id="ManualReservation.Group" />} id="Group" inline
                                                            onChange={this.setGuestType.bind(this)}
                                                            checked={profile && profile.guestType === 'Group' ? true : false} required
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col>
                                                    <CustomInput
                                                        type="checkbox"
                                                        label={<FormattedMessage id="ManualReservation.MainGuest" />}
                                                        id="MainGuest"
                                                        inline
                                                        onChange={() => this.handleCheckBox('isBooker', 'isChildren')}
                                                        checked={profile && profile.isBooker && profile.isBooker === true ? true : false}
                                                        disabled={reservation.profiles.length === 0}
                                                    />
                                                    <CustomInput
                                                        type="checkbox"
                                                        label={<FormattedMessage id="ManualReservation.Children" />}
                                                        id="Children"
                                                        inline
                                                        onChange={() => this.handleCheckBox('isChildren', 'isBooker')}
                                                        checked={profile && profile.isChildren && profile.isChildren === true ? true : false}
                                                        disabled={this.state.isAgencyOrCompany || reservation.profiles.length === 0}
                                                    />
                                                    <CustomInput
                                                        type="checkbox"
                                                        label={<FormattedMessage id="ManualReservation.isNotGuest" />}
                                                        id="Guest"
                                                        inline
                                                        onChange={() => this.handleCheckBox('isGuest', null)}
                                                        checked={profile && profile.isGuest && profile.isGuest === true ? false : true}
                                                        disabled={reservation.profiles.length === 0}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col>
                                                    <Label className="text-muted small" for="firstName"><FormattedMessage id="ManualReservation.FirstName" /></Label>
                                                    <Input type="text" id="firstName" name="firstName"
                                                        value={profile && profile.firstName ? profile.firstName : ''}
                                                        onChange={this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label className="text-muted small" for="surname"><FormattedMessage id="ManualReservation.Surname" />{"*"}</Label>
                                                    <Input type="text" id="surname" name="lastName"
                                                        value={profile && profile.lastName ? profile.lastName : ''}
                                                        onChange={this.handleChange.bind(this)}
                                                        required
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label className="text-muted small" for="gender"><FormattedMessage id="ManualReservation.Gender" /></Label>
                                                    <FormGroup>
                                                        <CustomInput type="radio" name="gender" label={<FormattedMessage id="ManualReservation.Male" />} id="Male" inline
                                                            onChange={this.handleChangeRadio.bind(this)}
                                                            checked={profile && profile.gender === 'Male' ? true : false}
                                                        />
                                                        <CustomInput type="radio" name="gender" label={<FormattedMessage id="ManualReservation.Female" />} id="Female" inline
                                                            onChange={this.handleChangeRadio.bind(this)}
                                                            checked={profile && profile.gender === 'Female' ? true : false}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col>
                                                    <Label className="text-muted small" for="Country"><FormattedMessage id="ManualReservation.Country" /></Label>
                                                    <CountryCustomSelect
                                                        countryList={countries}
                                                        value={profile && profile.countryCode ? countryList().getData().find(countryCode => countryCode.value === profile.countryCode) : ''}
                                                        onChange={this.handleChangeSelect.bind(this, 'countryCode')}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Row>
                                                        <Col className="pr-1">
                                                            <Label className="text-muted small"><FormattedMessage id="ManualReservation.Document" /></Label>
                                                        </Col>
                                                        <Col className="pl-1">
                                                            <Label className="text-muted small"><FormattedMessage id="ManualReservation.DocNumber" /></Label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="pr-1">
                                                            <CustomSelect
                                                                options={documents}
                                                                isClearable
                                                                isMulti={false}
                                                                value={profile && profile.document ? documents.find(doc => doc.value === profile.document) : ''}
                                                                isSearchable
                                                                onChange={this.handleChangeSelect.bind(this, 'document')}
                                                                placeholder={''}
                                                            />
                                                        </Col>
                                                        <Col className="pl-1">
                                                            <Input type="text" name="documentNumber"
                                                                value={profile && profile.documentNumber ? profile.documentNumber : ''}
                                                                onChange={this.handleChange.bind(this)}
                                                                required={profile && profile.document ? true : false}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Label className="text-muted small" for="nationality"><FormattedMessage id="ManualReservation.Nationality" /></Label>
                                                    <CountryCustomSelect
                                                        countryList={countries}
                                                        value={profile && profile.nationality ? countryList().getData().find(countryCode => countryCode.value === profile.nationality) : ''}
                                                        onChange={this.handleChangeSelect.bind(this, 'nationality')}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-2">
                                                <Col>
                                                    <Row>
                                                        <Col>
                                                            <Label className="text-muted small" for="birthDate"><FormattedMessage id="ManualReservation.BirthDate" /></Label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="">
                                                            <Input
                                                                type="date"
                                                                id="birthDate"
                                                                name="birthDate"
                                                                value={this.state.profile ? this.state.profile.birthDate : ''}
                                                                onChange={this.handleChange}
                                                                placeholder={"dd/mm/yyyy"}
                                                                max={moment().format("YYYY-MM-DD")}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <Label className="text-muted small" for="Phone"><FormattedMessage id="ManualReservation.PhoneNumber" /></Label>
                                                    <Input type="number" id="Phone" name="phoneNumber"
                                                        value={profile && profile.phoneNumber ? profile.phoneNumber : ''}
                                                        onChange={this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Label className="text-muted small" for="email"><FormattedMessage id="ManualReservation.Email" /></Label>
                                                    <Input type="email" id="Email" name="email"
                                                        value={profile && profile.email ? profile.email : ''}
                                                        onChange={this.handleChange.bind(this)}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <span>
                                                        {<FormattedMessage id="ManualReservation.MandatoryField"/>}
                                                    </span>
                                                </Col>
                                            </Row>
                                            <Row className="mt-5 mb-2">
                                                <Col>
                                                    <span className="text-muted"><i className="fas fa-bed mr-1" /><FormattedMessage id="ManualReservation.AssociateRoom" /> </span>
                                                </Col>
                                            </Row>
                                            {
                                                this.props.reservation.roomStays && this.props.reservation.roomStays.map((room, key) => {
                                                    const checked = profile && profile.rph && room.guestsRPHs.find(rph => rph === profile.rph) !== undefined;
                                                    return (
                                                        <CustomInput key={key}
                                                            type="checkbox" name="roomStayId" id={"RoomStay" + key}
                                                            label={<span><b> {key + 1} </b> ({room.roomCategoryDesc} | {moment(room.roomRates[0].effectiveDate).format('ddd DD/MM/YYYY')} - {moment(room.roomRates[room.roomRates.length - 1].expireDate).add(1, 'day').format('ddd DD/MM/YYYY')})</span>}
                                                            onChange={(evt) => this.props.editRoomStayRPH(profile.rph, key, evt.target.checked)}
                                                            checked={checked}
                                                            disabled={room.adults + room.children === room.guestsRPHs.length && !checked}
                                                        />       
                                                    );
                                                })
                                            }
                                            <Row className="pt-4">
                                                <Col className="text-center">
                                                    <span className="mr-3"></span>
                                                    <Button className="btn btn-sm btn-host" type="submit" disabled={this.state.profile === null || this.props.reservation.roomStays.filter(r => r.guestsRPHs.find(rph => rph === profile.rph) !== undefined).length === 0} onClick={(e) => this.addProfile(e)}>
                                                        <FormattedMessage id="ManualReservation.AddGuest" />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </form>
                                    </Col>

                                    {/*<Authorization
                                        perform="manualReservation:profileSearch"
                                        yes={() => (
                                            <Col className="border-left bg-host-hover col-1" style={{ 'cursor': 'pointer' }} onClick={() => this.setState({ searchMode: true })}>
                                                <div className="my-3 text-center">
                                                    <Button className="btn btn-host btn-sm ">
                                                        <i className="fas fa-search" />
                                                    </Button>
                                                </div>

                                                <div className="mt-3 text-center text-host h-100" style={{ 'minHeight': '200px' }} >
                                                    <div style={{
                                                        'fontWeight': '500',
                                                        'transform': 'matrix(0, -1, 1, 0, 0, 0)', 'position': 'absolute',
                                                        'top': '50%'
                                                    }}>
                                                        <FormattedMessage id="ManualReservation.ProfileSearch" />
                                                    </div>
                                                </div>
                                            </Col>
                                        )}
                                        no={() => ""}
                                    />*/}
                                </Row>
                        }

                    </CardBody>
                </Card>
                <Card className="mb-3 border-0 shadow">
                    <CardBody>
                        <h5 className="text-muted"><FormattedMessage id="ManualReservation.CurrentGuest" /></h5>
                        {this.props.reservation.profiles && this.props.reservation.profiles.length > 0 ?
                            <div className="py-5">
                                <Row>
                                    {this.props.reservation.profiles.map((el, k) =>
                                        <Col className={"col-2 text-host ml-2 " + (confirmDelete ? "bg-host-hover-delete" : " bg-host-hover")} key={k} style={{ minHeight: '60px' }}>
                                            <div className="py-2" style={{ 'fontWeight': '500' }}>
                                                {el.firstName} {el.lastName} {el.isBooker ? <i className="fas fa-star fa-xs text-host" /> : el.isChildren ? <i className="fas fa-child fa-xs text-host" /> : ''}
                                            </div>
                                            {this.props.reservation.roomStays.some(room => room.guestsRPHs && room.guestsRPHs.length > 0 && room.guestsRPHs.some(rph => rph === el.rph)) ?
                                                this.props.reservation.roomStays.map((room, key) => 
                                                room.guestsRPHs && room.guestsRPHs.find(rph => rph === el.rph) ?
                                                    <div key={key}>
                                                        {key + 1} ({room.roomCategoryDesc})
                                                            <h6>{moment(room.roomRates[0].effectiveDate).format('DD')}-{moment(room.roomRates[room.roomRates.length - 1].expireDate).add(1, 'day').format('DD MMMM')} </h6>
                                                    </div>
                                                    : ''
                                                )
                                                :
                                                <span>
                                                    <i className="fas fa-bed mr-1" /><FormattedMessage id="ManualReservation.NoRoom" />
                                                </span>
                                        }
                                            {confirmDelete ?
                                                <div className="show-on-hover text-center text-white" style={{ 'position': 'absolute', 'top': '10px', 'right': '0', 'left': '0' }}>
                                                    <Row className="small">
                                                        <Col><b> <FormattedMessage id="ManualReservation.RemoveProfile" /></b></Col>
                                                    </Row>
                                                    <Row><Col>
                                                        <i className="far fa-times-circle mr-2 pointer"
                                                            onClick={() => this.setState({ confirmDelete: false })} />
                                                        <i className="far fa-check-circle pointer"
                                                            onClick={() => this.removeGuest(k)}></i>
                                                    </Col></Row>
                                                </div>
                                                :
                                                <div>
                                                    <div className="show-on-hover text-center text-white" style={{ 'position': 'absolute', 'top': '10px', 'right': '0', 'left': '0' }}>
                                                        <div className="small">
                                                            <b><FormattedMessage id="ManualReservation.EditGuestInformation" /></b>
                                                        </div>
                                                        <i className="far fa-edit pointer" onClick={() => this.editGuest(k)} ></i>
                                                    </div>
                                                    <i className="far fa-times-circle show-on-hover text-danger pointer" style={{ 'position': 'absolute', 'top': '0px', 'right': '0px' }}
                                                        onClick={() => this.setState({ confirmDelete: true })} />
                                                </div>
                                            }
                                        </Col>
                                    )}
                                </Row>
                            </div>
                            :
                            <div className="py-2">
                                <i className="fas fa-info-circle mr-3"></i> <span className="text-muted small"> <FormattedMessage id="ManualReservation.AddNewGuests" /></span>
                            </div>
                        }

                    </CardBody>
                </Card>
            </BlockUi>
        );
    }
}
export default injectIntl(ManualReservationGuests);