import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card, CardBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import ComplexHowToGet from './ComplexHowToGet';
import { ComplexBillingEntity } from './ComplexBillingEntity';

export class ComplexContacts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <div>                
                <Card className="small border-0 shadow mb-2">
                    <CardBody className="p-0">
                        <ComplexHowToGet selectedEntity={this.props.selectedEntity} entity={'Complexes'} fromBEConfig={this.props.fromBEConfig} />

                        {/*<Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                    <FormattedMessage id="ComplexContacts.PhysicalLocation" />
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                    <FormattedMessage id="ComplexContacts.BillingEntity" />
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <ComplexHowToGet selectedEntity={this.props.selectedEntity} entity={'Complexes'} />
                            </TabPane>
                            <TabPane tabId="2">
                                <ComplexBillingEntity selectedEntity={this.props.selectedEntity} />
                            </TabPane>
                        </TabContent>   */}
                    </CardBody>
                </Card>
            </div>
        );
    }
}
