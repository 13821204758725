import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeader, CardTitle, Badge, Row, Col, Table, Button } from 'reactstrap';
import classNames from 'classnames';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { LogsRequestModal } from '../../Channel/Logs/LogsRequestModal';
import InfiniteScroll from 'react-infinite-scroller';
import RulesTooltip from './RulesTooltip';

class TimelineEventDetails extends Component {

    state = {
        error: null,
        requestContent: '',
        requestType: '',
        modal: false,
        errorDetailList: [
            'Booking.com.MISSING_RATEAMOUNTMESSAGES_ELEMENT',
            'Airbnb.7', 'Airbnb.400',
            'Expedia.3108', 'Expedia.3142', 'Expedia.7023', 'Expedia.7024', 'Expedia.3202', 'Expedia.1001', 'Expedia.7009', 'Expedia.7022', 'Expedia.3090', 'Expedia.7019',
            'Hostelworld.1016', 'Hostelworld.1062',
            'Siteminder.402',
            'Guest Centric.783'
        ]
    }

    toggle(type, evt) {
        var content = '';
        var contentType = '';
        if (type && this.props.selectedEvent !== undefined) {
            contentType = type;
            try {
                content = JSON.stringify(JSON.parse(this.props.selectedEvent[type]), null, 2)
            } catch (e) {
                content = this.props.selectedEvent[type];
            }
        }

        this.setState(prevState => ({
            requestType: contentType,
            requestContent: content,
            modal: !prevState.modal
        }));
    }

    render() {
        const {
            selectedEvent,
            block,
            selectedChannel,
            loadMoreFunc,
            displayChannelDataEntries,
            supportMobile
        } = this.props;

        const { modal, requestType, requestContent, errorDetailList } = this.state;

        const TYPE_ERROR = 1;
        const TYPE_WARNING = 2;

        return (
            <BlockUi tag="div" blocking={block}>
                <LogsRequestModal modal={modal} toggle={this.toggle.bind(this, '')} requestType={requestType} requestContent={requestContent} docTitle={selectedChannel} />

                {selectedEvent && selectedEvent.id ?
                    <Card className="small mb-2 border-bottom">
                        <div className={supportMobile ? "d-flex flex-column-reverse" : ''}>
                            <div>
                                <CardHeader style={{ backgroundColor: "#f8f9fa" }}>
                                    <i className="fas fa-wrench mr-2" />
                                    Event Details
                                </CardHeader>
                                <CardBody className="pt-2">
                                    <div className="mb-2">
                                        {selectedEvent.eventType === "MailBox" ? <FormattedMessage id={'LogsDashboard.' + selectedEvent.eventType + '.' + selectedEvent.action} values={{ 'channel': selectedEvent.channelInstanceName, 'ids': selectedEvent.ids }} /> : ''}
                                        {selectedEvent.eventType === "Review" ? <FormattedMessage id={'LogsDashboard.' + selectedEvent.eventType + '.' + selectedEvent.action} values={{ 'channel': selectedEvent.channelInstanceName, 'ids': selectedEvent.ids, 'message': selectedEvent.message }} /> : ''}
                                        {selectedEvent.eventType === "Reservation" ? <FormattedMessage id={'LogsDashboard.' + selectedEvent.eventType + '.' + selectedEvent.action} values={{ 'channel': selectedEvent.channelInstanceName, 'ids': selectedEvent.ids }} /> : ''}
                                        {selectedEvent.eventType === "Price" ? <FormattedMessage id={'LogsDashboard.' + selectedEvent.eventType + '.' + selectedEvent.action} values={{ 'channel': selectedEvent.channelInstanceName, 'listings': selectedEvent.ids }} /> : ''}
                                        {selectedEvent.eventType === "Availability" ? <FormattedMessage id={'LogsDashboard.' + selectedEvent.eventType + '.' + selectedEvent.action} values={{ 'channel': selectedEvent.channelInstanceName, 'listings': selectedEvent.ids }} /> : ''}
                                        {selectedEvent.eventType === "Oportunity" ? <FormattedMessage id={'LogsDashboard.' + selectedEvent.eventType + '.' + selectedEvent.action} values={{ 'channel': selectedEvent.channelInstanceName, 'ids': selectedEvent.ids }} /> : ''}
                                        {selectedEvent.eventType === "Connection" ? <FormattedMessage id={'LogsDashboard.' + selectedEvent.eventType + '.' + selectedEvent.action} values={{ 'channel': selectedEvent.channelInstanceName, 'ids': selectedEvent.ids }} /> : ''}
                                        {selectedEvent.eventType === "Listing" ? <FormattedMessage id={'LogsDashboard.' + selectedEvent.eventType + '.' + selectedEvent.action} values={{ 'channel': selectedEvent.channelInstanceName, 'ids': selectedEvent.ids }} /> : ''}
                                        {selectedEvent.eventType === "Reporting" ? <FormattedMessage id={'LogsDashboard.' + selectedEvent.eventType + '.' + selectedEvent.action} values={{ 'channel': selectedEvent.channelInstanceName, 'ids': selectedEvent.ids }} /> : ''}
                                        {selectedEvent.eventType === "Promotion" ? <div> <FormattedMessage id={'LogsDashboard.' + selectedEvent.eventType + '.' + selectedEvent.action} values={{ 'channel': selectedEvent.channelInstanceName, 'ids': selectedEvent.ids }} />  </div> : ''}
                                        {selectedEvent.eventType === "Automated" ? <div> <FormattedMessage id={'LogsDashboard.' + selectedEvent.eventType + '.' + selectedEvent.action} values={{ 'channel': selectedEvent.channelInstanceName, 'ids': selectedEvent.ids }} />  </div> : ''}
                                        {selectedEvent.result === 2 ? <FormattedMessage id="LogsDashboard.WithError" /> : ''}
                                    </div>
                                    {
                                        selectedEvent.channelErrors && selectedEvent.channelErrors.length > 0 ?
                                            <Table size="sm" style={supportMobile ? { overflowX: 'auto', display: 'block' } : {}} >
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '5%' }}>#</th>
                                                        <th style={{ width: '15%' }}>Code</th>
                                                        <th style={{ width: '80%' }}>Description</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        selectedEvent.channelErrors.map((item, key) =>
                                                            <React.Fragment key={key}>
                                                                <tr>
                                                                    <th scope="row">
                                                                        {
                                                                            item.type == TYPE_ERROR ?
                                                                                <i className="fas fa-exclamation-triangle text-danger" />
                                                                                :
                                                                                item.type == TYPE_WARNING ?
                                                                                    <i className="fas fa-exclamation-triangle text-warning" />
                                                                                    :
                                                                                    <span>{key}</span>
                                                                        }
                                                                    </th>
                                                                    <td >{item.code}</td>
                                                                    <td style={supportMobile ? {} : { maxWidth: '0' }}>{item.description}</td>
                                                                </tr>
                                                                {
                                                                    errorDetailList.indexOf(selectedChannel + '.' + item.code) > -1 ?
                                                                        <tr>
                                                                            <td colSpan="3">
                                                                                <Card className="shadow">
                                                                                    <CardBody>
                                                                                        <CardTitle>
                                                                                            <Badge className="mr-2"><i className="fas fa-exclamation-triangle fa-2x p-1"></i></Badge>
                                                                                            <strong>{item.code} : <FormattedMessage id={'LogsDashboard.' + selectedChannel + '.' + item.code + '.title'} /></strong>
                                                                                        </CardTitle>
                                                                                        <FormattedMessage id={'LogsDashboard.' + selectedChannel + '.' + item.code + '.detail'} values={{ inventario: (<Link to="/Inventory"> invent�rio</Link>), channellist: (<Link to="/ChannelDashboard"> here</Link>), roomCategory: (<Link to="/RoomCategorySetup"> here</Link>), missingRate: (<Link to="/"> here</Link>), breakLine: (<br />) }} />
                                                                                    </CardBody>
                                                                                </Card>
                                                                            </td>
                                                                        </tr>
                                                                        :
                                                                        null
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div />
                                    }
                                    {
                                        selectedEvent.automatedActions && selectedEvent.automatedActions.length > 0 ?
                                            <Table size="sm" style={supportMobile ? { overflowX: 'auto', display: 'block' } : {}} >
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Action</th>
                                                        <th>Error Code</th>
                                                        <th>Channel Rate Code</th>
                                                        <th>Channel Room Code</th>
                                                        <th>Entity Affected</th>
                                                        <th>Reason</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        selectedEvent.automatedActions.map((item, key) =>
                                                            <tr>
                                                                <th scope="row">{key}</th>
                                                                <td>{item.actionToPerform}</td>
                                                                <td>{item.channelErrorCode}</td>
                                                                <td>{item.channelRateCode}</td>
                                                                <td>{item.channelRoomCode}</td>
                                                                <td>{item.entity}</td>
                                                                <td>{item.reason}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                            :
                                            <div />
                                    }
                                    {
                                        selectedEvent.channelData && selectedEvent.channelData.length > 0 ?
                                            <div style={supportMobile ? { overflow: "auto" } : { height: '45vh', overflow: "auto" }}>
                                                <InfiniteScroll
                                                    pageStart={0}
                                                    loadMore={loadMoreFunc}
                                                    hasMore={displayChannelDataEntries < selectedEvent.channelData.length}
                                                    loader={<div style={{ width: '100%' }} key="loader"><BlockUi tag="div" blocking={true} /></div>}
                                                    useWindow={false}
                                                >
                                                    <Table size="sm" style={supportMobile ? { overflowX: 'auto', display: 'block' } : {}} >
                                                        <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>channelRateCode</th>
                                                                <th>pmsRateCode</th>
                                                                <th>channelRoomCode</th>
                                                                <th>pmsRoomTypeCode</th>
                                                                <th>from</th>
                                                                <th>to</th>
                                                                <th>freeRooms</th>
                                                                <th>maxStay</th>
                                                                <th>minStay</th>
                                                                <th>stopSales</th>
                                                                <th>CTD</th>
                                                                <th>CTA</th>
                                                                <th>minBW</th>
                                                                <th>maxBW</th>
                                                                <th>Price</th>
                                                                <th>Rules</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>

                                                            {
                                                                selectedEvent.channelData
                                                                    .sort((a, b) => moment(a.from.slice(0, -9), 'YYYY-MM-DD').diff(b.from.slice(0, -9), 'minutes'))
                                                                    .slice(0, displayChannelDataEntries)
                                                                    .map((item, key) =>
                                                                        <tr key={key}>
                                                                            <th scope="row">{key}</th>
                                                                            <td>{item.channelRateCode}</td>
                                                                            <td>{item.pmsRateCode}</td>
                                                                            <td>{item.channelRoomTypeCode}</td>
                                                                            <td>{item.pmsRoomTypeCode}</td>
                                                                            <td>{item.from.slice(0, -9)}</td>
                                                                            <td>{item.to.slice(0, -9)}</td>
                                                                            <td>{item.freeRooms}</td>
                                                                            <td>{item.maxStay}</td>
                                                                            <td>{item.minStay}</td>
                                                                            <td>{item.stopSales ? <i className="fas fa-ban" /> : ''}</td>
                                                                            <td>{item.ctd ? <i className="fas fa-check" /> : ''}</td>
                                                                            <td>{item.cta ? <i className="fas fa-check" /> : ''}</td>
                                                                            <td>{item.minAdvancedBookingOffset}</td>
                                                                            <td>{item.maxAdvancedBookingOffset}</td>
                                                                            <td>{item.prices ? item.prices.map((el, k) => <span key={k}>{el} ,</span>) : ''}</td>
                                                                            <td>
                                                                                {item.rules.length > 0 ?
                                                                                    <RulesTooltip
                                                                                        item={item}
                                                                                        id={key}
                                                                                    />
                                                                                    : ''}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </InfiniteScroll>
                                            </div>
                                            :
                                            <div />
                                    }

                                </CardBody>
                            </div>
                            <div>
                                <CardHeader style={{ backgroundColor: "#f8f9fa" }}>
                                    <div className="inline">
                                        <i className="far fa-envelope mr-2"></i> Raw Data
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col className="col-6">
                                            <Button className="btn-sm btn-host shadow mr-2" disabled={!(selectedEvent && selectedEvent.request)} onClick={this.toggle.bind(this, 'request')}>
                                                <i className="fas fa-file-import" />
                                            </Button>
                                            Request
                                        </Col>
                                        <Col className="col-6">
                                            <Button className="btn-sm  btn-host shadow mr-2 " disabled={!(selectedEvent && selectedEvent.response)} onClick={this.toggle.bind(this, 'response')}>
                                                <i className="fas fa-file-export" />
                                            </Button>
                                            Response
                                        </Col>
                                    </Row>
                                </CardBody>
                            </div>
                        </div>
                    </Card>
                    :
                    <Card className=" text-secondary align-middle text-center h-100 pt-5 shadow border-0">
                        <i className="fas fa-info-circle fa-8x"><br /></i> <h5><FormattedMessage id="Timeline.SelectEventToSeeDetails" /></h5>
                    </Card>
                }
            </BlockUi>
        );
    }
}

export default TimelineEventDetails;