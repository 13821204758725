import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Badge, Button, Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { deleteAPI, getAPI, postAPI } from "../../Base/API";
import { StyledCard, SelectChannelConfig } from "../../Base/Common/CommonUIComponents";
import CustomSelect from '../../Base/Common/CustomSelect';
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Notification";


export class ConfigChannelAutoActions extends Component {

    state = {
        block: true,
        configActions: [],
        modal: false,
        filterErrorCode: null,
        selectedChannel: null
    }

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.doSearch();
    }

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    setFilterState = (name, combo) => {
        let val = null;
        if (Array.isArray(combo)) {
            val = combo.map(el => el.value);
        } else {
            val = combo ? combo.value : null;
        } 

        this.setState(prevState => ({
            ...prevState,
            [name]: combo,
            modalData: {
                ...prevState.modalData,
                [name]: val
            }
        }));

    }


    doSearch = (evt) => {

        var url = "/api/User/AutomatedChannelAction/v1/Actions";
        
        if (this.state.selectedChannel)
            url += '?chCfgId=' + this.state.selectedChannel.value

        if (this.state.filterErrorCode)
            url = url+(this.state.selectedChannel ? '&' : '?') + 'errorCode=' + this.state.filterErrorCode

        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                
                this.setState({ configActions: data.response, block: false, error: errorMessage });

            }
        }, url);
        
    }

    getActionComboValues() {
        return [
            { value: 'Disable', label: this.props.intl.formatMessage({ id: "generic.channelAutoAction.action.Disable" }) }
        ];
    }

    getInstanceComboValues() {
        return [
            { value: 'ChannelInstance', label: this.props.intl.formatMessage({ id: "generic.channelAutoAction.entity.ChannelInstance" }) },
            { value: 'RateCodeChannel', label: this.props.intl.formatMessage({ id: "generic.channelAutoAction.entity.RateCodeChannel" } )}
        ];
    }

    getReasonComboValues() {
        return [
            { value: 'Password', label: this.props.intl.formatMessage({ id: "generic.channelAutoAction.reason.Password" }) },
            { value: 'Occupancy', label: this.props.intl.formatMessage({ id: "generic.channelAutoAction.reason.Occupancy" }) },
            { value: 'Mapping', label: this.props.intl.formatMessage({ id: "generic.channelAutoAction.reason.Mapping" }) },
            { value: 'Prices', label: this.props.intl.formatMessage({ id: "generic.channelAutoAction.reason.Prices" }) },
            { value: 'Contract', label: this.props.intl.formatMessage({ id: "generic.channelAutoAction.reason.Contract" }) },
            { value: 'PriceModal', label: this.props.intl.formatMessage({ id: "generic.channelAutoAction.reason.PriceModal" }) }
        ];
    }

    removeErrorCode(val,evt) {
        this.setState(prevState => ({
            ...prevState,
            modalData: {
                ...prevState.modalData,
                channelErrorCodes: [
                    ...prevState.modalData.channelErrorCodes.filter(el => el !== val)
                ]
            }
        }));

    }


    setFilterSelectedChannel = (name, combo) => {
        var val = combo ? combo.value : null
        this.setState(prevState => ({
            ...prevState,
            modalData: {
                ...prevState.modalData,
                channelConfigId : val
            }
        }));
    }

    addErrorCode(evt) {

        if (this.state.modalData.channelErrorCodes && this.state.modalData.channelErrorCodes.find(el => el === this.state.errorCode) > 0) {
            return
        }


        this.setState(prevState => ({
            ...prevState,
            errorCode : '',
            modalData: {
                ...prevState.modalData,
                channelErrorCodes: [
                    ...prevState.modalData.channelErrorCodes,
                    this.state.errorCode
                ]
            }
        }));
    }

    deleteRecord() {

        deleteAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                handleNotification(data, <FormattedMessage id="ConfigChannelAutoActions.ChannelRuleDeleted" />, <FormattedMessage id="General.Success" />);
                this.setState({ block: false, error: errorMessage }, () => { this.doSearch() });

            }
        }, '/api/User/AutomatedChannelAction/v1/Action/' + this.state.modalData.id );
        
    }

    saveChanges(event) {

        event.preventDefault();


        var el = document.getElementById('errorcode');

        if (this.state.modalData && this.state.modalData.channelErrorCodes.length === 0) {
            el.setCustomValidity("Errors are Mandatory");
        } else {
            el.setCustomValidity("");
        }


        if (!this.form.current.reportValidity()) {
            return;
        }

        let req = { request : this.state.modalData };
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="ConfigChannelAutoActions.ChannelRuleUpdated" />, <FormattedMessage id="General.Success" />); 
                this.setState({ block: false, error: errorMessage }, () => { this.doSearch() });

            }
        }, '/api/User/AutomatedChannelAction/v1/Action', req);

    }

    addRule() {
        this.setState({
            modal: true, modalData: {
                channelConfigId: null,
                channelErrorCodes: [],
                entity: [],
                reason: null,
                actionToPerform: null
            }
        })
    }


    render() {

        const columns = [ {
            dataField: 'channelConfigName',
                text: this.props.intl.formatMessage({ id: "ReservationList.Channel" }),
                sort: true
            }, {
                dataField: 'channelErrorCodes',
                text: this.props.intl.formatMessage({ id: "ReservationList.ErrorCode" }),
                sort: true,
                formatter: (cell, row) => cell ? <div>
                    {cell && cell.map((item, key) =>
                        <Badge key={key} className="mr-2">{item}</Badge>
                    )}
                </div> : '',
            }, {
                dataField: 'entity',
                text: this.props.intl.formatMessage({ id: "ReservationList.Entity" }),
                sort: true,
                formatter: (cell, row) => cell && cell.map((item, key) => <Badge key={key} className="mr-2"> {this.props.intl.formatMessage({ id: "generic.channelAutoAction.entity." + item })} </Badge> )
            }, {
                dataField: 'reason',
                text: this.props.intl.formatMessage({ id: "ReservationList.Reason" }),
                sort: true,
                formatter: (cell, row) => this.props.intl.formatMessage({ id: "generic.channelAutoAction.reason." + cell })
            }, {
                dataField: 'actionToPerform',
                text: this.props.intl.formatMessage({ id: "ReservationList.Action" }),
                sort: true,
                formatter: (cell, row) => this.props.intl.formatMessage({ id: "generic.channelAutoAction.action." + cell })
            }]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, modalData: row })
            }
        };

        const helpContent =
            <div>
                <FormattedMessage id="ConfigChannelAutoActions.ConfigAutomatedAction" />
                <hr />
                <Button className="btn btn-host btn-sm mb-1 " onClick={() => ''}><i className="fas fa-search"></i></Button><FormattedMessage id="ConfigChannelAutoActions.SearchConfiguredActions" />   <br />
                <Button className="btn btn-host btn-sm mb-1 " onClick={() => ''}><i className="fas fa-plus"></i></Button><FormattedMessage id="ConfigChannelAutoActions.AddNewAction" /> <br />
            </div>

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-robot'} title={'ConfigChannelAutoActions.title'} help={helpContent}>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                    <ModalHeader toggle={this.toggleModal}  > <FormattedMessage id="ConfigChannelAutoActions.ChannelRule" />
                        
                    </ModalHeader>
                    <ModalBody className="modal-lg px-0 pb-0">
                        <BlockUi tag="div" blocking={this.state.block}>
                        {this.state.modalData ?
                            <Card className="shadow border-0">
                                <CardHeader className="bg-white border-bottom ">
                                    <i className="fas fa-ban mr-1"></i> <FormattedMessage id="ConfigChannelAutoActions.RuleManagement" />
                                </CardHeader>
                                <CardBody>

                                        <form ref={this.form}>
                                        <div className="small">
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row>
                                                    <Label for="errorcode" sm={4}> <FormattedMessage id="ConfigChannelAutoActions.forChannel" /> </Label>
                                                    
                                                    <Col sm={8}>
                                                                <SelectChannelConfig required={true} name={'channelConfigId'} icon={'fas fa-plug'} value={this.state.modalData.channelConfigId } onChangeFunc={this.setFilterSelectedChannel} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row>
                                                        <Label for="LilicenseCode" sm={4}> <FormattedMessage id="ConfigChannelAutoActions.instance" /> </Label>
                                                        <Col sm={8}>                                                          

                                                                <CustomSelect required={true} icon={'fas fa-map-signs'} isSearchable isMulti placeholder={<FormattedMessage id="ConfigChannelAutoActions.ChooseEntity" />} value={this.getInstanceComboValues().filter(el => this.state.modalData.entity ? this.state.modalData.entity.indexOf(el.value) >= 0 : null)} options={this.getInstanceComboValues()} onChange={this.setFilterState.bind(this, "entity")} />
                                                        
                                                        </Col>
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row>
                                                        <Label for="action" sm={4}> <FormattedMessage id="ConfigChannelAutoActions.action" /> </Label>
                                                        <Col sm={8}>
                                                                <CustomSelect required={true} icon={'fas fa-unlink'} isSearchable placeholder={<FormattedMessage id="ConfigChannelAutoActions.ChooseAction" />} value={this.getActionComboValues().filter(el => this.state.modalData.actionToPerform ? this.state.modalData.actionToPerform.indexOf(el.value) >= 0 : null)} options={this.getActionComboValues()} onChange={this.setFilterState.bind(this, "actionToPerform")} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>



                                                <Col className="col-6">
                                                    <FormGroup row>
                                                        <Label for="LilicenseCode" sm={4}> <FormattedMessage id="ConfigChannelAutoActions.reason" /> </Label>
                                                        <Col sm={8}>

                                                                <CustomSelect required={true} icon={'fas fa-question'} isSearchable placeholder={<FormattedMessage id="ConfigChannelAutoActions.ChooseReason" />} value={this.getReasonComboValues().filter(el => el.value === this.state.modalData.reason)} options={this.getReasonComboValues()} onChange={this.setFilterState.bind(this, "reason")} />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        <Row>
                                            <Col className="col-6">
                                                <FormGroup row>
                                                    <Label for="errorcode" sm={4}> <FormattedMessage id="ConfigChannelAutoActions.errorCodes" /> </Label>
                                                    <Col sm={6}>
                                                        <InputGroup size="sm">
                                                                <FormattedMessage id="ConfigChannelAutoActions.errorCodes">{placeholder =>
                                                                        <Input maxLength="100" minLength="1" id="errorcode"  name="errorcode" placeholder={placeholder} value={this.state.errorCode} onChange={(evt) => this.setState({ errorCode: evt.target.value })} />
                                                            }</FormattedMessage>
                                                        </InputGroup>
                                                    </Col>

                                                        <Col className="col-2">
                                                                <Button className="btn-host btn-sm float-left" onClick={this.addErrorCode.bind(this)} disabled={this.state.errorCode === ''}   ><i className="fas fa-plus"></i></Button>
                                                        </Col>
                                                </FormGroup>
                                            </Col>
                                              
                                                <Col> <h5>
                                                        {this.state.modalData.channelErrorCodes && this.state.modalData.channelErrorCodes.map((item, key) =>
                                                        <Badge className="mr-2">{item}<i className="fas fa-times ml-3 float-right" onClick={this.removeErrorCode.bind(this, item)}></i></Badge>
                                                )}
                                                </h5>
                                            </Col>

                                        </Row>
                                   
                                        </div>
                                        
                                    </form>

                                    <Row>
                                        <Col>
                                                <Button className="btn-host btn-sm float-right mr-2" disabled={this.state.modalData.id === undefined} onClick={this.deleteRecord.bind(this)}>
                                                    <i className="fas fa-trash-alt"></i>
                                            </Button>
                                            <Button className="btn-host btn-sm float-right mr-2" onClick={this.saveChanges.bind(this)}>
                                                    <i className="fas fa-save"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>

                            </Card>
                                : ''}
                        </BlockUi>
                    </ModalBody>

                </Modal>

                <Row className="mb-2 ">
                    <Col className="small">
                       
                        <SelectChannelConfig name={'selectedChannel'} isMulti={false} icon={'fas fa-plug'} onChangeFunc={this.setFilterState} value={this.state.selectedChannel ? this.state.selectedChannel.value : null}/>
                       
                    </Col>
                    <Col>
                        <InputGroup size="sm">
                            <FormattedMessage id="ConfigChannelAutoActions.searcherrorcode">{placeholder =>
                                <Input name="name" placeholder={placeholder} style={{ height: '38px' }} value={this.state.filterErrorCode ? this.state.filterErrorCode : ''} onChange={(evt) => this.setState({ filterErrorCode: evt.target.value })} />
                            }</FormattedMessage>
                        </InputGroup>
                    </Col>
                    <Col>
                       
                    </Col>
                    <Col>
                        
                        <Button className="btn btn-host btn-sm float-right" onClick={this.doSearch.bind(this)}><i className="fas fa-search"></i></Button>
                        <Button className="btn btn-host btn-sm float-right mr-2" onClick={this.addRule.bind(this)}><i className="fas fa-plus"></i></Button>
                    </Col>
                </Row>
                <hr/>
                <CustomTable
                    data={this.state.configActions ? this.state.configActions : [] }
                    columns={columns}
                    page={this.state.currentPage}
                    sizePerPage={this.state.resultsPerPage}
                    totalSize={this.state.ChannelData && this.state.ChannelData.length}
                    onTableChange={this.handleTableChange}
                    shadow={false}
                    exportCSV={true}
                    selectRow={selectRow}
                    search={true}
                    remote={false}
                />
                
            </StyledCard>

        );
    }

}
export default injectIntl(ConfigChannelAutoActions)