import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button, UncontrolledCollapse, Badge } from 'reactstrap';
import { getAPI, postAPI } from "../../Base/API"
import { ErrorAlert } from "../../Common/ErrorAlert";
import BlockUi from 'react-block-ui'
import { handleNotification } from "../../Base/Notification";
import CustomSelect from '../../Base/Common/CustomSelect';
import { FormattedMessage } from 'react-intl';

export class BookingOportunityCard extends Component {


    constructor(props) {
        super(props);
        
        this.state = {
            error: [] ,
            reviews: null,
            block: true,
            modal: false,
            selectedConnection: '',
            channelinstanceList: [],
            modalData: {},
            opportunity: {},
            Alerts: [],
            selectedCategories: []
        };

        this.opportunityAction = this.opportunityAction.bind(this);
        
    }

    componentDidMount() {
        
        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.length > 0) {
                var id = '';
                var list = data.filter(c => c.active);
                var channels = [];

                list && list.forEach(el => {
                    channels.push({ value: el.id, label: `${el.name} (${el.propertyCode})` })
                })

                if (channels.length > 0)
                    id = channels[0].value;

                this.setState({ channelinstanceList: channels, selectedConnection: id, block: false, error: errorMessage });
                this.reloadReviewInfo();                
            }
            this.setState({ block: false });
        },  '/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/Booking.com');

    }
       
    changeChannelConnection(combo) {
        this.setState({
            selectedConnection: combo ? combo.value : null,
            block: true
        }, () => this.reloadReviewInfo());
}
    
    reloadReviewInfo() {
        if (this.state.channelinstanceList.length > 0) {
            getAPI(result => {
                const { data, error } = result;
                var errorMessage = []
                if (error) {

                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {

                    for (var i = 0; i < data.errors.length; i++) {
                        errorMessage.push({ message: data.errors[i].message, messageType: 'danger' });
                    }

                    for (i = 0; i < data.warnings.length; i++) {
                        errorMessage.push({ message: data.warnings[i].message, messageType: 'warning' });
                    }
                    this.setState({ opportunity: data, block: false, error: errorMessage });
                }
            }, '/api/Rate/Oportunity/v1/channelInstance/' + this.state.selectedConnection);
        }
        else {
            var data = {
                warnings: [{
                    message: <FormattedMessage id="PropertyScore.ThereAreNoChannels" />
                }]
            }
            handleNotification(data);
        }
    }

    opportunityAction(opportunityId, action) {
        
        this.setState({ block: true });
        const id = this.state.selectedConnection;
        const url = id + '/ChangeOpportunity?opportunityId=' + opportunityId + '&action=' + action
        
        postAPI(result => {
            const { error, data } = result;
            var errorMessage = [];
            if (error) {
               

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
            
                return;
            }
            if (data) {
                if (action === 'ENABLE') {
                    handleNotification(data, <FormattedMessage id="BookingOportunity.OpportunityActivated" />, <FormattedMessage id="BookingOportunity.Success" />); 
                    //errorMessage.push({ message: <FormattedMessage id="BookingOportunity.OpportunityActivated"/>, messageType: 'success' });
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                
                    handleNotification(data, <FormattedMessage id="BookingOportunity.OpportunityInactivated" />, <FormattedMessage id="BookingOportunity.Success" />);
                //errorMessage.push({ message: <FormattedMessage id="BookingOportunity.OpportunityInactivated" />, messageType: 'success' });
                this.setState({ error: errorMessage, block: false });
                }
            }

        },  '/api/Rate/Oportunity/v1/channelInstance/' + url);        
    }

    handleCategories = (combo) => {
        this.setState({
            selectedCategories: combo
        })
    }

    render() {
        const categoryOptions = [
            {
                label: 'Availability',
                value: 'Availability'
            },
            {
                label: 'Ranking',
                value: 'Ranking',
            },
            {
                label: 'Page views',
                value: 'Page views'
            },
            {
                label: 'Conversions',
                value: 'Conversion'
            },
            {
                label: 'Length of stay',
                value: 'Length of stay'
            },
            {
                label: 'Average daily rate',
                value: 'Average daily rate'
            },
            {
                label: 'Cancellations',
                value: 'Cancellation'
            },
            {
                label: 'Reduce workload',
                value: 'Reduce workload'
            },
            {
                label: 'Promotions',
                value: 'Promotion'
            }
        ]

        const opportunities = this.state.opportunity.data ?
            (this.state.selectedCategories && this.state.selectedCategories.length > 0 ?
                this.state.opportunity.data.opportunities.filter(o => o.categories.find(c => this.state.selectedCategories.find(sc => sc.value === c.category_name)))
                : this.state.opportunity.data.opportunities)
            : [];

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <CardBody className="small">
                        <Row className="mb-3">
                            <Col className="col-lg-3 col-6 pl-1">
                                <CustomSelect
                                    icon={'fas fa-filter'}
                                    isClearable isMulti isSearchable
                                    placeholder={<FormattedMessage id="BookingOportunity.Categories" />}
                                    options={categoryOptions}
                                    onChange={this.handleCategories.bind(this)}
                                />
                            </Col>
                            {!global.isMobile ? 
                                <Col/>
                            : ''}
                            <Col className={`col-lg-2 col-6 pr-1 ${global.isMobile ? 'pl-0' : ''}`}>
                                <CustomSelect
                                    icon={'fa fa-plug'}
                                    isSearchable
                                    placeholder={<FormattedMessage id="BookingPromotion.channelInstance" />}
                                    value={this.state.channelinstanceList.find(el => el.value === this.state.selectedConnection) || ''}
                                    options={this.state.channelinstanceList}
                                    onChange={this.changeChannelConnection.bind(this)}
                                />
                            </Col>
                        </Row>

                        <Row>
                            { opportunities.map((item, key) =>
                                item.title ?
                                    <Col key={key} className="col-lg-4 col-12 pb-2 px-1">
                                        <Card key={key} className="border-left-primary shadow h-100 p-0">

                                            <CardBody className="pb-1">                                                
                                                <Row>
                                                    <Col>
                                                        {item.categories.length > 0 && item.categories.map((category, key) =>
                                                            <Badge key={key} className='p-1 px-2 mr-1 mb-1' color="primary"> {category.category_name}</Badge>
                                                        )}
                                                    </Col>
                                                    {global.isMobile ?
                                                        <Col className="col-2"><a id={'toggle' + key} ><span className="text-secondary fas fa-info-circle fa-lg"></span></a></Col>
                                                    :''}
                                                </Row>
                                                 {item.implementation === 'TOGGLE' ?
                                                    <Row className={global.isMobile ? 'align-items-center' : ''}>
                                                        <Col className="col-lg-7 col-6 text-secondary"><strong>{item.title}</strong></Col>
                                                        <Col className="col-lg-4 col-6">
                                                            <ButtonGroup>
                                                                <Button color="host " size="sm" className="mr-1 shadow" onClick={() => this.opportunityAction(item.opportunity_id, "ENABLE")}><FormattedMessage id="BookingOportunityCard.Active" /></Button>
                                                                <Button color="host" size="sm" className="shadow" onClick={() => this.opportunityAction(item.opportunity_id, "DISMISS")}><FormattedMessage id="BookingOportunityCard.Inactive" /></Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                        {!global.isMobile ?
                                                            <Col className="col-1"><a id={'toggle' + key} ><span className="text-secondary fas fa-info-circle fa-lg"></span></a></Col>
                                                        :''}
                                                     </Row>
                                                                : 
                                                    <Row>
                                                        <Col className="col-lg-11 col-12 text-secondary"><strong>{item.title}</strong></Col>
                                                        {!global.isMobile ?
                                                            <Col className="col-1"><a id={'toggle' + key} ><span className="text-secondary fas fa-info-circle fa-lg"></span></a></Col>
                                                        :''}
                                                    </Row>
                                                }
                                                    
                                            {
                                            item.implementation !== 'TOGGLE' ?
                                                <Row>
                                                    <Col className="col-11 text-secondary"><a target="_blank" href={item.url}>{item.cta}</a></Col>
                                                    </Row>
                                                :
                                                <Row>
                                                    <Col className="col-11 text-secondary"></Col>
                                                </Row>
                                        }                                                
                                        <UncontrolledCollapse toggler={'#toggle' + key}>
                                            <Row>
                                                <Col dangerouslySetInnerHTML={{ __html: item.description }}></Col>
                                            </Row>
                                        </UncontrolledCollapse>
                                    </CardBody>
                                   
                                </Card>
                                </Col>
                               : ''
                            )
                        } 
                    </Row>

                    </CardBody>
                </BlockUi> 
            </div>
        );
    }
}