import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import CustomSelect from "../../../Base/Common/CustomSelect";
import { CustomSingleDatePicker } from '../../../Base/Common/CustomReactDates';
import { MobileFilters } from '../../../Base/Common/MobileComponents';

class PriceHistoryFilters extends Component {

    renderMainFilter = () => {
        return (
            <CustomSingleDatePicker
                date={this.props.day}
                id="singleDatePicker"
                required={true}
                isOutsideRange={_ => false}
                showTodaysButton={true}
                showYearOptions={{ pastYears: true, futureYears: true }}
                onDateChange={date => this.props.handleDate(date)}
            />
        )
    }

    renderSecFilters = () => {
        return (
            <Row>
                <CommonFilters
                    isAdminPage={this.props.isAdminPage}
                    hotelList={this.props.hotelList}
                    handleChangeSelect={this.props.handleChangeSelect}
                    rateCodes={this.props.rateCodes}
                    rateCodeId={this.props.rateCodeId}
                    hotelId={this.props.hotelId}
                />
            </Row>
        )
    }
    
    render() {
        const { isAdminPage, hotelList, handleChangeSelect, rateCodes, rateCodeId, hotelId, handleDate } = this.props;

        return (
            <div>
                {global.isMobile ?
                    <MobileFilters
                        renderMainFilter={this.renderMainFilter}
                        renderSecFilters={this.renderSecFilters}
                        searchType="submit"
                    />
                    :
                    <Row className="mb-4 mt-2">
                        <CommonFilters
                            isAdminPage={isAdminPage}
                            hotelList={hotelList}
                            handleChangeSelect={handleChangeSelect}
                            rateCodes={rateCodes}
                            rateCodeId={rateCodeId}
                            hotelId={hotelId}
                        />
                        <Col className="col-2 pr-1">
                            <CustomSingleDatePicker
                                date={this.props.day}
                                id="singleDatePicker"
                                required={true}
                                isOutsideRange={_ => false}
                                showTodaysButton={true}
                                showYearOptions={{ pastYears: true, futureYears: true }}
                                onDateChange={date => handleDate(date)}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-sm btn-host btn" type="submit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                }
            </div>
        );
    }

};
export default injectIntl(PriceHistoryFilters);


const CommonFilters = ({ isAdminPage, hotelList, handleChangeSelect, rateCodes, rateCodeId, hotelId }) => {
    return (
        <>
            {isAdminPage ?
                <Col className="mb-2 col-12 col-lg-3">
                    <CustomSelect
                        icon={'fas fa-hotel'}
                        options={hotelList}
                        required
                        onChange={handleChangeSelect.bind(this, 'hotelId')}
                        placeholder={<FormattedMessage id="HotelLicenses.Hotel" />}
                        isSearchable isClearable
                    />
                </Col>
                : ''}
            <Col className="mb-2 col-12 col-lg-3">
                <CustomSelect
                    icon={'fa fa-gift fa-fw'}
                    required
                    options={rateCodes}
                    onChange={handleChangeSelect.bind(this, 'rateCodeId')}
                    placeholder={<FormattedMessage id="PMSHistory.Rate" />}
                    isSearchable
                    value={rateCodeId ? rateCodes.find(el => el.value === rateCodeId) : ''}
                    isClearable
                    isDisabled={isAdminPage ? hotelId ? false : true : false}
                />
            </Col>
        </>
    )
}