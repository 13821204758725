import { authContext } from "../../../Utils/AdalConfig";
import { EventReportingExceptions } from '../../Base/Common/ReferenceDataFunctions';

export function EventGA(eventName, payload) {
    const gtag = window.gtag;
    if (typeof (gtag) === 'function') {
        if(payload){
            const userId = authContext.getCachedUser() && authContext.getCachedUser()?.profile && authContext.getCachedUser()?.profile.oid;
            payload.userId = userId;
        }

        gtag('event', eventName, payload);
    }
}

export function HandleHelp(id) {
    if(!id || window.location.hostname === 'localhost' || window.location.origin.includes('.dev'))
        return;
    
    EventGA('help', {
        'help_id': id,
    });
}

export function HandleHelpClick(id, helpLink) {
    if(!id || window.location.hostname === 'localhost' || window.location.origin.includes('.dev'))
        return;
    
    EventGA('help_click', {
        'help_id': id,
        'stack': helpLink
    });
}

export function HandleUIError(error, errorInfo) {
    if(!error || window.location.hostname === 'localhost' || window.location.origin.includes('.dev'))
        return;
    
    EventGA('error_ui', {
        'description': error,
        'stack': errorInfo
    });
}

export function HandleAPIError(error, errorInfo, pagePath, pageLocation) {
    const errors = EventReportingExceptions();
    if (!error || window.location.hostname === 'localhost' || window.location.origin.includes('.dev') || (errors.filter(y => (y.type === 'Error' && y.term === error) || (y.type === 'API' && errorInfo.includes(y.term))).length !== 0))
        return;
    
    EventGA('error_api', {
        'description': error,
        'stack': errorInfo,
        'page_path': pagePath,
        'page_location': pageLocation
    });
}

export function HandleExceededTimeAPIError(error, errorInfo) {
    const errors = EventReportingExceptions();
    if (!error || window.location.hostname === 'localhost' || window.location.origin.includes('.dev') || (errors.filter(y => (y.type === 'Error' && y.term === error) || (y.type === 'API' && errorInfo.includes(y.term))).length !== 0))
        return;
    
    EventGA('api_exceeded_time', {
        'description': error,
        'stack': errorInfo
    });
}