import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard, SelectChannelConfig } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Notification";


class DBCoherence extends Component {

    state = {
        block: true,
        status: { value: false },
        coherenceTableList: [],
        channelCode: null
    }

    componentDidMount() {
        this.getDBCoeherenceList();

    }

    getDBCoeherenceList() {
        this.setState({ block: true });


        var param = '';


       
        if (this.state.channelConfigId)
            param += '&ChannelCode=' + this.state.channelCode


        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0)
                    handleNotification(data, "search", 'Success');
                else
                    this.setState({ coherenceTableList: data.response, block: false });
            }
        }, '/api/hotel/v1/Dashboard/incoherences' + param);
    }



    render() {

        const helpContent =
            <div>
                TODO
                <hr />
            </div>


        const columns = [
            {
                dataField: 'parentTable',
                text: this.props.intl.formatMessage({ id: "DBCoherence.table" }),
                sort: true,
            },
            {
                dataField: 'parentHotelId',
                text: this.props.intl.formatMessage({ id: "DBCoherence.Hotel" }),
                sort: true,
            },
            {
                dataField: 'parentId',
                text: this.props.intl.formatMessage({ id: "DBCoherence.id" }),
                sort: true,
            }, {
                dataField: 'childTable',
                text: this.props.intl.formatMessage({ id: "DBCoherence.childTable" }),
                sort: true,
            },
            {
                dataField: 'childHotelId',
                text: this.props.intl.formatMessage({ id: "DBCoherence.Hotel" }),
                sort: true,
            },
            {
                dataField: 'childId',
                text: this.props.intl.formatMessage({ id: "DBCoherence.id" }),
                sort: true,
            }

        ];

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-not-equal'} title={'DBCoherence.title'} help={helpContent}>
                
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">

                            <CustomTable
                                data={this.state.coherenceTableList ? this.state.coherenceTableList : []}
                                columns={columns}
                                page={this.state.currentPage}
                                sizePerPage={this.state.resultsPerPage}
                                showTotal={true}
                                totalSize={this.state.coherenceTableList && this.state.coherenceTableList.length}
                                shadow={false}
                                search={true}
                                remote={false}
                            />



                        </Card>

                    </Col>

                </Row>

            </StyledCard>


        );
    }

};
export default injectIntl(DBCoherence)