import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Input, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { postAPI } from '../Base/API';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import CustomSelect from '../Base/Common/CustomSelect';
import { getReasonOptions } from '../Base/Common/ReferenceDataFunctions';
import { handleNotification } from '../Base/Notification';

export class PaymentRefundModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.doRefund = this.doRefund.bind(this);
        this.state = {
            block: false,
            amount: null,
            reason: null,
            notes: null
        };
    }

    updateFilterCombo(combo, evt) {
        if (evt) {
            const { name } = evt;
            this.setState({ [name]: combo && combo.value });
        }
    }

    doRefund() {
        const { payment, updateRefunds } = this.props;
        const { amount, reason, notes } = this.state;
        if (!this.form.current.reportValidity()) {
            return;
        }
        const request = {
            request: {
                amount: amount,
                reason: reason,
                notes: notes
            }
        };
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    handleNotification(data, <FormattedMessage id="Payments.SuccessfulRefund" />, <FormattedMessage id="General.Success" />);
                    updateRefunds(data.response[0]);
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Price/Payment/v1/refund/${payment.id}`, request);
    }

    render() {
        const { isOpen, toggle, payment } = this.props;
        const { block, amount, reason, notes } = this.state;        

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="Payments.Refund" />
                </ModalHeader>
                <ModalBody>
                    <BlankCard block={block}>
                        <form ref={this.form}>
                            <Row className="mb-1">
                                <Col className="text-right">
                                    <Button className="btn btn-sm btn-host" onClick={this.doRefund}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col className="col-lg-3 col-4">
                                    <FormattedMessage id="Payments.Amount" />
                                </Col>
                                <Col className="col-lg-9 col-8">
                                    <Input type="number" id="amount" name="amount" onChange={(e) => this.setState({ amount: e.target.value })} value={amount} required min="0.01" max={payment.amount} step="0.01" />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-lg-3 col-4">
                                    <FormattedMessage id="Payments.Reason" />
                                </Col>
                                <Col className="col-lg-9 col-8">
                                    <CustomSelect options={getReasonOptions()} isClearable value={getReasonOptions().find(opt => opt.value === reason)} name="reason" onChange={this.updateFilterCombo.bind(this)} placeholder={<FormattedMessage id="Payments.Reason" />} />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-lg-3 col-4">
                                    <FormattedMessage id="Payments.Notes" />
                                </Col>
                                <Col className="col-lg-9 col-8">
                                    <Input type="textarea" onChange={(e) => this.setState({ notes: e.target.value })} value={notes} required maxLength="200" />
                                </Col>
                            </Row>
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>    
        );
    }
}