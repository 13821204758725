import React, { Component } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Button } from 'reactstrap';
import { getAPI } from '../../Base/API';
import moment from 'moment';
import { handleNotification } from '../../Base/Notification';
import { CustomDateRangePicker } from '../../Base/Common/CustomReactDates';
import CustomSelect from '../../Base/Common/CustomSelect';
import { ChartLegend, getColorPallete } from '../../Base/Common/CommonUIComponents';
import { Bar, HorizontalBar, Pie } from 'react-chartjs-2';
import { MobileFilters } from '../../Base/Common/MobileComponents';

class UsageTracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: moment().subtract(15, 'days').format('YYYY-MM-DD'),
            toDate: moment().endOf('day').format('YYYY-MM-DD'),
            usageByDay: { labels: [], datasets: [] },
            callsByType: { labels: [], datasets: [] },
            callTypes: [
                {value: "Chat", label: "Chat"},
                {value: "HotelInformation", label: "Hotel Information"},
                {value: "Review", label: "Review"}
            ],
            callType: null,
        };
    }

    componentDidMount() {
        this.getOpenAIUsage();
    }

    getOpenAIUsage = () => {
        if(moment(this.state.fromDate) && moment(this.state.toDate)){
            this.setState({ block: true }, () => {
                var params = `?fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.toDate).format("YYYY-MM-DD")}`;
                if (this.state.callType) params += `&callType=${this.state.callType}`;

                getAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                        }
                        this.organizeChartData(data.response);

                    }
                    else this.setState({ block: false })
                }, `/api/hotel/OpenAI/v1/OpenAIUsageByDay` + params);
            });
        }else{
            handleNotification({}, this.props.intl.formatMessage({ id: "HeyTravelAI.SelectValidDateRange" }), this.props.intl.formatMessage({ id: "HeyTravelAI.InvalidDates" }), 'info' );
        }
    }

    organizeChartData = (data) => {
        const { callTypes } = this.state;

        if(callTypes && callTypes.length > 0 && data && data.length > 0){
            const formatDate = 'YYYY-MM-DD', type = 'days';
            const totalTypes = callTypes.length;

            var dates = new Array(moment(this.state.toDate).diff(moment(this.state.fromDate), type)).fill(undefined)
                .reduce((acc, cur) => {
                    acc.push(moment(this.state.fromDate).add(acc.length, type).format(formatDate));

                    return acc;
                }, [moment(this.state.fromDate).format(formatDate)]);

            var usageByDay = {
                labels: dates,
                datasets: callTypes
                    .map((type, idx) => ({
                        type: 'line',
                        yAxisID: 'calls',
                        label: `${type.label} ${this.props.intl.formatMessage({ id: "HeyTravelAI.Calls" }) }`,
                        data: Array(dates.length).fill(0),
                        backgroundColor: 'transparent',
                        borderColor: getColorPallete()[idx],
                        borderWidth: 1,
                        pointRadius: 3
                    }))
                    .concat(callTypes.map((type, idx) => ({
                        type: 'bar',
                        yAxisID: 'tokens',
                        label: `${type.label} Tokens`,
                        data: Array(dates.length).fill(0),
                        backgroundColor: `${getColorPallete()[idx]}98`,
                        borderColor: getColorPallete()[idx],
                        borderWidth: 2
                    }))) 
            };

            var callsByType = {
                labels: callTypes
                    .map(type => `${this.props.intl.formatMessage({ id: ("HeyTravelAI." + type.value) })}`),
                datasets: [
                    { label: `${this.props.intl.formatMessage({ id: ("HeyTravelAI.CallsByType") })}`, data: Array(totalTypes).fill(0), labels: callTypes.map(type => `${type.value}`), backgroundColor: getColorPallete().splice(0, totalTypes)  },
                    { label: `${this.props.intl.formatMessage({ id: ("HeyTravelAI.TokensByType") })}`, data: Array(totalTypes).fill(0), labels: callTypes.map(type => `${type.value}`), backgroundColor: getColorPallete().splice(0, totalTypes).map(el => `${el}20`), borderColor: getColorPallete().splice(0, totalTypes), borderWidth: 2 }
                ]
            };

            data && data.forEach(date => {
                date.usageByCallType && date.usageByCallType.length > 0 && date.usageByCallType.forEach(usage => {
                    const callTypeIndex = callTypes.findIndex(ct => ct.value === usage.callType);
                    const dateIndex = dates && moment(date.date) && dates.findIndex(d => d === moment(date.date).format(formatDate));
                    if(callTypeIndex !== -1){
                        //Bar Chart
                        if (dateIndex !== -1 && usageByDay.datasets && usageByDay.datasets[callTypeIndex] && usageByDay.datasets[callTypeIndex].data) {
                            usageByDay.datasets[callTypeIndex].data[dateIndex] += usage.totalCalls;
                            if(usageByDay.datasets[callTypeIndex + callTypes.length] && usageByDay.datasets[callTypeIndex + callTypes.length].data){
                                usageByDay.datasets[callTypeIndex + callTypes.length].data[dateIndex] += usage.totalCompletionTokens + usage.totalPromptTokens;
                            }
                        }
                        //Doughnut Chart
                        if(callsByType && callsByType.datasets && callsByType.datasets[0] && callsByType.datasets[0].data && callsByType.datasets[1] && callsByType.datasets[1].data){
                            callsByType.datasets[0].data[callTypeIndex] += usage.totalCalls;
                            callsByType.datasets[1].data[callTypeIndex] += usage.totalPromptTokens + usage.totalCompletionTokens;
                        }
                    }
                })
            })
            this.setState({ block: false, usageByDay, callsByType });
        }else{
            this.setState({ block: false, usageByDay: [], callsByType: [] });
        }
    }

    doSearch = (e) => {
        if (e) {
            e.preventDefault();
        }
        this.getOpenAIUsage();
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    renderMainFilter = () => {
        return (
            <div className='px-3'>
                <CustomDateRangePicker
                    startDate={this.state.fromDate}
                    endDate={this.state.toDate}
                    onDatesChange={(startDate, endDate) => this.setState({ fromDate: startDate, toDate: endDate })}
                    isOutsideRange={day => day.isAfter(moment(), 'days')}
                    endDateId="heyTravelAiEndDateId"
                    startDateId="heyTravelAiStartDateId"
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                    small={true}
                    numberOfMonths={1}
                    showDefaultInputIcon={true}
                />
            </div>
        )
    }

    render() {
        const { callTypes, callsByType, usageByDay } = this.state;

        const optionsBar = {
            maintainAspectRatio: false,
            legend: {
                display: true,
                position: "top"
            },
            scales: {
                xAxes: [{
                    display: true,
                    stacked: true
                }],
                yAxes: [
                    {
                        id: 'tokens',
                        stacked: true,
                        display: true,
                        position: 'left',
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Total Tokens'
                        }
                    },
                    {
                        id: 'calls',
                        type: 'linear',
                        position: 'right',
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                            min: 0
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Total Calls'
                        }
                    }
                ]
            },
            plugins: {
                datalabels: { display: false }
            }
        };

        const optionsHorizontalBar = {
            cutoutPercentage: 60,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            plugins: {
                datalabels: {
                    display: true
                }
            }
        };

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <div style={{ height: '95%', border: '1px solid #bbc1c9ab', borderRadius: global.isMobile ? '0px 5px 5px 5px' : '5px', boxShadow: '0px 3px 15px #6F73881A' }} className={`p-3 h-100 bg-white ${global.isMobile ? 'border-top-0' : ''}`}>
                    {!global.isMobile ?
                        <>
                            <div style={{ height: '5%' }} className='d-flex pb-3 mb-2 px-0 align-items-center justify-content-start'>
                                <h5 style={{ fontWeight: '500' }}>
                                    <i className="far fa-chart-bar px-1" /> <FormattedMessage id={`HeyTravelAI.UsageTracking`}/>
                                </h5>
                            </div>
                            <div className="d-flex align-items-center pb-3">
                                <Col className="col-3 px-0">
                                    <CustomSelect
                                        options={callTypes}
                                        isSearchable isClearable
                                        placeholder={<FormattedMessage id="HeyTravelAI.CallType" />}
                                        onChange={(e) => this.handleSelect('callType', e)}
                                    />
                                </Col>
                                <Col className="col-3">
                                    <CustomDateRangePicker
                                        startDate={this.state.fromDate}
                                        endDate={this.state.toDate}
                                        onDatesChange={(startDate, endDate) => this.setState({ fromDate: startDate, toDate: endDate })}
                                        isOutsideRange={day => day.isAfter(moment(), 'days')}
                                        endDateId="heyTravelAiEndDateId"
                                        startDateId="heyTravelAiStartDateId"
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                        small={true}
                                        numberOfMonths={1}
                                        showDefaultInputIcon={true}
                                    />
                                </Col>
                                <Col className="text-right" >
                                    <Button className="btn btn-sm btn-host" onClick={this.doSearch}>
                                        <i className="fa fa-search" />
                                    </Button>
                                </Col>
                            </div>
                        </>
                    :
                        <MobileFilters
                            doSearch={this.doSearch}
                            renderMainFilter={this.renderMainFilter}
                        >
                            <Col className="px-0">
                                <CustomSelect
                                    options={callTypes}
                                    isSearchable isClearable
                                    placeholder={<FormattedMessage id="HeyTravelAI.CallType" />}
                                    onChange={(e) => this.handleSelect('callType', e)}
                                />
                            </Col>
                        </MobileFilters>
                    }
                    <div className="d-flex flex-column mb-3 border-0 h-100 p-3">
                        <div className="mb-2">
                            <h6 className="text-muted">
                                <FormattedMessage id="HeyTravelAI.CallsByType" />
                            </h6>
                        </div>
                        <div className={`d-flex ${global.isMobile ? 'flex-column align-items-start mb-3' : 'align-items-center justify-content-around mt-3'} `}>
                            <div className={`d-flex flex-column ${global.isMobile ? 'pb-2' : ''}`}>
                                <div className="text-muted font_size_xxs" style={{ fontSize: '14px' }}>
                                    <FormattedMessage id="HeyTravelAI.Calls" />
                                </div>
                                <div className="d-flex flex-wrap">
                                    {callTypes && callTypes.map((type, key) =>
                                        <ChartLegend color={getColorPallete()[key]} label={type.label || 'N/A'} key={`${key}Token`} />
                                    )}
                                </div>
                                
                            </div>
                            <div className="d-flex flex-column">
                                <div className="text-muted font_size_xxs" style={{ fontSize: '14px' }}>
                                    Tokens
                                </div>
                                <div className="d-flex flex-wrap">
                                    {callTypes && callTypes.map((type, key) =>
                                        <ChartLegend color={`${getColorPallete()[key]}20`} borderColor={getColorPallete()[key]} label={type.label || 'N/A'} key={`${key}Token`} />
                                    )}
                                </div>
                            </div>
                        </div>
                        <Col className="col-12">
                            {global.isMobile ?
                                <Pie
                                    data={callsByType} height={175} options={optionsHorizontalBar}
                                />
                            :
                                <HorizontalBar id="horizontalBarChart" data={callsByType} height={350} options={optionsHorizontalBar} />
                            }
                        </Col>
                    </div>
                    <div className="d-flex flex-column border-0 h-100 p-3">
                        <div className="mb-2">
                            <h6 className="text-muted">
                                <FormattedMessage id="HeyTravelAI.UsageByDay" />
                            </h6>
                        </div>
                        <div>
                            <Bar id="lineChart" data={usageByDay} height={350} options={optionsBar} />
                        </div>
                    </div>
                </div>
            </BlockUi>
        );
    }
}

export default injectIntl(UsageTracking)