import React, { Component } from 'react';
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody, Row, Col, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { getAPI } from "../../../Base/API"
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import Timeline from '../../../Base/Common/Timeline';
import TimelineElement from '../../../Base/Common/TimelineElement';
import '../../../Base/Common/Timeline.css';
import '../../../Base/Common/TimelineElement.css';
import CustomSelect from '../../../Base/Common/CustomSelect'
import { SelectChannelInstance } from "../../../Base/Common/CommonUIComponents";
import { getConvertedDateToHotelTimezone } from '../../../Base/Common/ReferenceDataFunctions';
import { CustomDateRangePicker } from '../../../Base/Common/CustomReactDates';
import LogsEventsFilters from './LogsEventsFilters';
import LogsEventsFiltersMobile from './LogsEventsFiltersMobile';


const DisplayIcon = ({ type, result }) => {

    var badgecolor = "badge-secondary";
    if (result === 2) badgecolor = "badge-danger";
    else if (result === 3) badgecolor = "badge-warning text-white";
    var classname = "";
    if (type === 'MailBox') classname = "fas fa-2x fa-inbox"
    else if (type === 'Review') classname = "fas fa-2x fa-comment"
    else if (type === 'Promotion') classname = "fas fa-2x fa-percent"
    else if (type === 'Reservation') classname = "fas fa-2x fa-key"
    else if (type === 'Price') classname = "fas fa-2x fa-dollar-sign"
    else if (type === 'Oportunity') classname = "fas fa-2x fa-rocket"
    else if (type === 'Availability') classname = "fas fa-2x fa-calendar-alt"
    else if (type === 'Connection') classname = "fas fa-2x fa-plug"
    else if (type === 'Listing') classname = "fas fa-2x fa-list"
    else if (type === 'Inventory') classname = "fas fa-2x fa-clipboard-list"
    else if (type === 'Reporting') classname = "fas fa-2x fa-credit-card"
    else if (type === 'Automated') classname = "far fa-2x  fa-check-square"
    else if (type === 'Restriction') classname = "fas fa-2x  fa-ban"
    else if (type === 'Payment') classname = "fas fa-2x fa-credit-card"
    else if (type === 'UpSell') classname = "fas fa-2x fa-gift"

    return <span className={'badge ' + badgecolor} style={{ width: '28px' }}><i className={ classname}></i></span>;
}

export class LogsEvents extends Component {

    constructor(props) {
        super(props);
        this.viewEventDetails = this.viewEventDetails.bind(this);
        this.searchEvents = this.searchEvents.bind(this);
        this.changePage = this.changePage.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.search = this.search.bind(this);
        this.handleCurrentPage = this.handleCurrentPage.bind(this);
        this.prepareSearchEvents = this.prepareSearchEvents.bind(this);
        this.state = {
            error: null,
            block: true,
            detailBlock: false,
            modal: false,
            // pagination control
            resultsPerPage: 10,
            currentPage: 1,
            defaultResultsPerPage: 10,
            lastSelected: 0,
            totalEvents: 0,
            // Combobox information
            hasAutoErrors: [
                {
                    "value": "true",
                    "label": <FormattedMessage id="LogsEvents.WithAutomaticAction" />
                }
            ],
            errors: [
                {
                    "value": "1",
                    "label": <FormattedMessage id="LogsEvents.Success" />
                },
                {
                    "value": "2",
                    "label": <FormattedMessage id="LogsEvents.Error" />
                },
                {
                    "value": "3",
                    "label": <FormattedMessage id="LogsEvents.Warning" />
                }
            ],
            services: [
                {
                    "value": "Availability,Price,Restriction",
                    "label": <FormattedMessage id="LogsEvents.Ari" />
                },
                {
                    "value": "Oportunity",
                    "label": <FormattedMessage id="LogsEvents.Opportunity" />
                },
                {
                    "value": "Promotion",
                    "label": <FormattedMessage id="LogsEvents.Promotion" />
                },
                {
                    "value": "MailBox",
                    "label": <FormattedMessage id="LogsEvents.MailBox" />
                },
                {
                    "value": "Review",
                    "label": <FormattedMessage id="LogsEvents.Review" />
                },
                {
                    "value": "Reservation",
                    "label": <FormattedMessage id="LogsEvents.Reservation" />
                },
                {
                    "value": "Connection,Inventory",
                    "label": <FormattedMessage id="LogsEvents.Connection" />
                },
                {
                    "value": "Listing",
                    "label": <FormattedMessage id="LogsEvents.Listing" />
                },
                {
                    "value": "Reporting",
                    "label": <FormattedMessage id="LogsEvents.Reporting" />
                },                
                {
                    "value": "Automated",
                    "label": <FormattedMessage id="LogsEvents.Automated" />
                },                
                {
                    "value": "Payment",
                    "label": <FormattedMessage id="LogsEvents.Payment" />
                },                
                {
                    "value": "UpSell",
                    "label": <FormattedMessage id="LogsEvents.UpSell" />
                }
            ],
            events: [],
            selectedEvent: null,
            detailChannel: null,
            channelinstanceList: [],
            displayChannelDataEntries: 0,
            pageSize: 30,
            // Search Criteria
            filters: {
                startDate: moment().subtract(0, "days"),
                endDate: moment().add(1, "days"),
                selectedErrorType: "",
                selectedChannel: "",
                selectedServiceType: "",
                selectedhasAutoActions: "",
                reservationid: "",
            },
            defCon1: moment('2024-03-22').startOf('d').add(19, 'hour').add(45, 'm')
        };
    }
    
    viewEventDetails(id, channel, evt, archive) {
        if (this.state.selectedEvent?.id !== id) {
            this.setState({ detailBlock: true, lastSelected: id, detailChannel: channel });

            getAPI(result => {
                const { data, error } = result;
                this.setState({ detailBlock: true });
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, detailBlock: false });


                    return;
                }
                if (data && data.data) {
                    this.setState({ selectedEvent: data.data, displayChannelDataEntries: data.data.channelData?.length > this.state.pageSize ? this.state.pageSize : data.data.channelData?.length, detailBlock: false });
                }
            }, '/api/User/Events/v1/' + id);
        }
        else if (global.isMobile) {
            this.setState({ selectedEvent: null });
        }
    }

    componentDidMount() {
        const { filters } = this.state;
        moment.locale('en', {
            calendar: {
                lastDay: '[Yesterday at] LTS',
                sameDay: '[Today at] LTS',
                lastWeek: '[Last] dddd [at] LTS',
                sameElse: 'L'
            }
        });

        moment.locale('pt', {
            calendar: {
                lastDay: '[Ontem às] LTS',
                sameDay: '[Hoje às] LTS',
                lastWeek: '[Última] dddd [às] LTS',
                sameElse: 'L'
            }
        });

        let search = window.location.search;
        let params = new URLSearchParams(search);
        let channel = params.get('channel') ?? '';
        let error = params.get('error') ?? '';
        let auto = params.get('autoAction') ?? '';
        let errorType = params.get('errorType') ?? '';
        let startDate = params.get('startDate') ?? '';
        let endDate = params.get('endDate') ?? '';

        if(filters){
            filters.selectedChannel = channel;
            filters.selectedErrorType = error;
            filters.selectedServiceType = errorType;
            filters.selectedhasAutoActions = auto;
            filters.startDate = startDate ? moment(startDate) : moment().subtract(0, "days");
            filters.endDate = endDate ? moment(endDate) : moment().add(1, "days");
        }

        this.setState({ filters, selectedChannel: channel, selectedErrorType: error, selectedhasAutoActions: auto, selectedServiceType: errorType}, () => this.searchEvents());
    }

    clearFilter() {
        this.setState({
            filters: {
                startDate: moment().subtract(15, "days"),
                endDate: moment(),
                selectedErrorType: "",
                selectedChannel: "",
                selectedServiceType: "",
                selectedhasAutoActions: "",
                reservationid: "",
            }
        });
    }

    search() {
        this.setState({
            resultsPerPage: this.state.defaultResultsPerPage,
            currentPage: 1,
            selectedEvent: null,
            lastSelected: 0
        }, this.searchEvents);
    }

    changePage(evt, val) {
        this.setState({ currentPage: val }, this.searchEvents);
    }

    searchEvents() {
        const currentPage = this.state.currentPage - 1;
        const { filters, defCon1 } = this.state;
        if(filters.startDate && filters.endDate){
            this.setState({ block: true }, () =>
                getAPI(result => {
                    const { data, error } = result;
                    if (error) {
                        var errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        this.setState({ events: data.data, totalEvents: Math.ceil(data.count / this.state.resultsPerPage) , block: false });
                    }
                }, '/api/User/Events/v1?datai=' +
                filters.startDate.format('YYYY-MM-DD') +
                '&dataf=' + filters.endDate.format('YYYY-MM-DD') +
                '&channel=' + filters.selectedChannel +
                '&hasAutoAction=' + filters.selectedhasAutoActions +
                '&error=' + filters.selectedErrorType +
                '&service=' + filters.selectedServiceType +
                '&reservationid=' + filters.reservationid +
                '&pageSize=' + this.state.resultsPerPage +
                '&pageIndex=' + (currentPage * this.state.resultsPerPage) +
                '&archive=' + (filters.endDate.startOf('d').isBefore(defCon1)))
            );
        }
    }

    setComboStatus = (evt, inputValue, actionMeta) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value).join(',');
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        //this.state.services[5].value - Reservation
        //this.state.services[10].value - Payment
        if (targetValue.includes(this.state.services[5].value) || targetValue.includes(this.state.services[10].value)) {
            this.setState(prevState => ({
                filters: {
                    ...prevState.filters,
                    [evt]: targetValue
                }
            }));
        }
        else {
            this.setState(prevState => ({
                filters: {
                    ...prevState.filters,
                    [evt]: targetValue,
                    reservationid: ''
                }
            }));
        }    
    }

    setChannel = (evt, inputValue, actionMeta) => {
        var targetValue = '';

        if (Array.isArray(actionMeta)) {
            targetValue = actionMeta.map(el => el.value).join(',');
        } else {
            targetValue = actionMeta ? actionMeta.value : '';
        }

        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [evt]: targetValue
            }
        }));
    }

    handleChangeFilter = (evt) => {
        const { name, value } = evt.target;

        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [name]: value
            },
        }));
    }
    
    handleDates = (startDate, endDate) => {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                startDate, endDate
            },
        }));
    }

    handleCurrentPage(evt) {
        if (evt) {
            let { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    prepareSearchEvents() {
        const { currentPage } = this.state;
        if (!currentPage || currentPage < 1) {
            this.setState({ currentPage: 1 }, this.searchEvents);
        }
        else {
            this.searchEvents();
        }
    }

    loadMoreFunc = () => {
        const { selectedEvent, displayChannelDataEntries, pageSize } = this.state;
        const nextPage = displayChannelDataEntries + pageSize;

        this.setState({ displayChannelDataEntries: selectedEvent?.channelData?.length > nextPage ? nextPage : selectedEvent?.channelData?.length });
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block || (global.isMobile && this.state.detailBlock)}>
                    <ErrorAlert error={this.state.error} />

                    {global.isMobile ?
                        <LogsEventsFiltersMobile
                            filters={this.state.filters}
                            services={this.state.services}
                            defCon1={this.state.defCon1}
                            errors={this.state.errors}
                            hasAutoErrors={this.state.hasAutoErrors}
                            setComboStatus={this.setComboStatus}
                            handleChangeFilter={this.handleChangeFilter}
                            setChannel={this.setChannel}
                            handleDates={this.handleDates}
                            search={this.search}
                        />
                        :
                        <LogsEventsFilters
                            filters={this.state.filters}
                            defCon1={this.state.defCon1}
                            services={this.state.services}
                            errors={this.state.errors}
                            hasAutoErrors={this.state.hasAutoErrors}
                            setComboStatus={this.setComboStatus}
                            handleChangeFilter={this.handleChangeFilter}
                            setChannel={this.setChannel}
                            handleDates={this.handleDates}
                            search={this.search}
                        />
                    }

                    <div className="small mt-2">
                        <Timeline
                            layout='1-column'
                            selectedEvent={this.state.selectedEvent}
                            selectedChannel={this.state.detailChannel}
                            block={this.state.detailBlock}
                            displayChannelDataEntries={this.state.displayChannelDataEntries}
                            loadMoreFunc={this.loadMoreFunc}
                            events={this.state.events}
                            supportMobile={global.isMobile}
                        >
                            { this.state.events && this.state.events.map((item, key) =>
                                <div key={key}>
                                    <TimelineElement
                                        id={item.id}
                                        icon={<DisplayIcon type={item.eventType} result={item.result} />}
                                        date={getConvertedDateToHotelTimezone(item.createAt).format("YYYY-MM-DD HH:mm:ss")}
                                        user={item.userName}
                                        channel={item.channelConfigName}
                                        eventId={item.id}
                                        propertyCode={item.propertyCode}
                                        lastSelected={this.state.lastSelected}
                                        viewEventDetails={this.viewEventDetails}
                                        processStatus={item.processStatus}
                                        supportMobile={global.isMobile}

                                        selectedEvent={this.state.selectedEvent}
                                        selectedChannel={this.state.selectedChannel}
                                        loadMoreFunc={this.loadMoreFunc}
                                        displayChannelDataEntries={this.state.displayChannelDataEntries}
                                    >
                                        <div className="timeline-element-subtitle">
                                            {item.eventType === "MailBox" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'ids': item.ids, 'message': item.message }} />  </div> : ''}
                                            {item.eventType === "Review" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'ids': item.ids, 'message': item.message }} />  </div> : ''}
                                            {item.eventType === "Reservation" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'ids': item.ids }} />  </div> : ''}
                                            {item.eventType === "Price" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'listings': item.ids }} />  </div> : ''}
                                            {item.eventType === "Availability" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'listings': item.ids }} />  </div> : ''}
                                            {item.eventType === "Oportunity" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'ids': item.ids }} />  </div> : ''}
                                            {item.eventType === "Connection" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'ids': item.ids }} />  </div> : ''}
                                            {item.eventType === "Listing" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'ids': item.ids }} />  </div> : ''}
                                            {item.eventType === "Inventory" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'ids': item.ids }} />  </div> : ''}
                                            {item.eventType === "Reporting" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'ids': item.ids }} />  </div> : ''}
                                            {item.eventType === "Promotion" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'ids': item.ids }} />  </div> : ''}
                                            {item.eventType === "Automated" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'ids': item.ids }} />  </div> : ''}
                                            {item.eventType === "Restriction" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'channel': item.channelInstanceName, 'ids': item.ids }} />  </div> : ''}
                                            {item.eventType === "Payment" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} values={{ 'ids': item.ids }} />  </div> : ''}
                                            {item.eventType === "UpSell" ? <div> <FormattedMessage id={'LogsDashboard.' + item.eventType + '.' + item.action} />  </div> : ''}
                                        </div>

                                    </TimelineElement>
                                </div>
                            )}


                            <Row className="mt-3">
                                <Col className="text-center">
                                    <Row>
                                        <Col className="col-1">
                                            <Button className="btn-host shadow btn-sm float-left" style={{ opacity: '1' }} disabled={!this.state.currentPage || this.state.currentPage <= 1} onClick={() => this.changePage(this, parseInt(this.state.currentPage) - 1)}>
                                                <i className="fas fa-angle-left" />
                                            </Button>
                                        </Col>
                                        <Col>
                                            <span>
                                                <strong>
                                                    <Row>
                                                        <Col className="col-5 text-right" style={{ paddingRight: '0', fontSize: '20px' }}>
                                                            <FormattedMessage id="LogsEvents.Page" />
                                                        </Col>
                                                        <Col className="col-2" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                                            <Input type="number" id="currentPage" name="currentPage" min="1" max={this.state.totalEvents} onChange={this.handleCurrentPage} value={this.state.currentPage !== null ? this.state.currentPage : ''} onBlur={this.prepareSearchEvents} />
                                                        </Col>
                                                        <Col className="col-5 text-left" style={{ paddingLeft: '0', fontSize: '20px' }}>
                                                            / {this.state.totalEvents}
                                                        </Col>
                                                    </Row>
                                                </strong>
                                            </span>
                                        </Col>
                                        <Col className="col-1">
                                            <Button className="btn-host btn-sm shadow float-right" disabled={this.state.events?.length < this.state.resultsPerPage} onClick={() => this.changePage(this, parseInt(this.state.currentPage) + 1)}>
                                                <i className="fas fa-angle-right" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Timeline>
                    </div>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(LogsEvents);
