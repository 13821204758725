import React, { Component } from 'react';
import { Row, Col, Card, InputGroup } from 'reactstrap';
import { ErrorAlert } from "../../Common/ErrorAlert";
import BlockUi from 'react-block-ui'
import { FormattedMessage, injectIntl } from 'react-intl';
import { BookingReviewDetail } from './BookingReviewDetail';
import { BookingGlobalReviewCard } from './BookingGlobalReviewCard';
import moment from 'moment';
import CustomSelect from '../../Base/Common/CustomSelect'
import { CustomTable } from '../../Base/Common/CustomTable'
import { CustomSingleDatePicker } from '../../Base/Common/CustomReactDates';

class BookingReview extends Component {
    render() {
        return (
            <BlockUi tag="div" blocking={this.props.block}>
                <ErrorAlert error={this.props.error} />
                <Row>
                    <Col className="col-12  ">
                        <Card className=" bg-white border-0 mb-1 small">
                            <form ref={this.props.form}>
                                <Row>

                                    <Col className="col-2">
                                        {this.props.channelinstanceList.length > 0 ?
                                            <CustomSelect icon={'fa fa-plug fa-fw'} isClearable isSearchable placeholder={<FormattedMessage id="AirbnbReviewCard.SelectChannel" />} defaultValue={this.props.channelinstanceList && this.props.channelinstanceList[0]} options={this.props.channelinstanceList} onChange={this.props.setComboStatus.bind(this, 'selectedConnection')} />
                                            : ''}
                                    </Col>

                                    <Col className="col-2">
                                        <CustomSelect icon={'far fa-comment'} isClearable isSearchable placeholder={<FormattedMessage id="AirbnbReviewCard.SelectStatus" />} defaultValue={this.props.getBookingComboValues()[2]} options={this.props.getBookingComboValues()} onChange={this.props.setComboStatus.bind(this, 'selectedReplyStatus')} />
                                    </Col>

                                    <Col className="col-3">
                                        <InputGroup size="sm text-right" >
                                            <CustomSingleDatePicker
                                                id="Date"
                                                date={this.props.date}
                                                isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                                                showYearOptions={{ pastYears: false, futureYears: false }}
                                                showTodaysButton={true}
                                                onDateChange={date => this.props.changeDate(date)}
                                                numberOfMonths={1}
                                                required={true}
                                            />
                                        </InputGroup>
                                    </Col>


                                    <Col className="text-right">
                                        <button className="btn btn-host shadow btn-sm" type="submit" onClick={this.props.reloadReviewInfo.bind(this)}><span className="fas fa-search"></span></button>
                                    </Col>
                                </Row>
                            </form>
                        </Card>
                    </Col>
                </Row>
                <hr className="border-host" />
                <BookingReviewDetail modal={this.props.modal} data={this.props.modalData} handleChange={this.props.handleChange} connection={this.props.selectedConnection} action={this.props.toggleModal.bind(this)} />
                <BookingGlobalReviewCard reviews={this.props.reviews} />
                <br></br>

                <Row>
                    <Col>

                        <CustomTable
                            data={this.props.reviewsList.data ? this.props.reviewsList.data.reviews : []}
                            columns={this.props.columns}
                            //page={this.props.currentPage}
                            //sizePerPage={this.props.resultsPerPage}
                            //totalSize={this.props.ChannelData && this.props.ChannelData.length}
                            //onTableChange={this.handleTableChange}
                            shadow={false}
                            exportCSV={false}
                            selectRow={this.props.selectRow}

                        />

                        {this.props.reviewsList.meta && this.props.reviewsList.meta.next_page && this.props.reviewsList.data.reviews.length >= this.props.pageSize ?
                            <button className="btn btn-host mt-2 btn-sm float-right" onClick={this.props.fetchData}>
                                <FormattedMessage id="BookingReviewCard.LoadMoreData" />
                            </button>
                        : ""}
                    </Col>
                </Row>
            </BlockUi>
        )
    }
}

export default injectIntl(BookingReview)
