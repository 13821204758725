import React, { Component } from 'react';
import { Label, Input, Button, Modal, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { BlankCard } from '../../Base/Common/CommonUIComponents';
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';

export class RateLabelModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            label: null
        };
    }

    handleChange = (evt) => {
        if (evt) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    handleSave = () => {
        const { toggle, updateLabels } = this.props;
        const { label } = this.state;

        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="EditRate.SaveLabelMessageSuccess" />, <FormattedMessage id="General.Success" />);
                    updateLabels(data.response[0]);
                    this.setState(({ block: false, error: errorMessage }), toggle);
                }
                else {
                    this.setState(({ block: false, error: errorMessage }));
                }
            }
            else {
                this.setState(({ block: false, error: errorMessage }));
            }
        }, `/api/Rate/Rate/v1/RateLabel?label=${label}`);
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { block, error, label } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle} fade={false} className="modal-md" style={{ minWidth: 0, marginTop: '15%' }}>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <form ref={this.form}>
                            <div>
                                <Button color=" btn-host btn-sm float-right" onClick={this.handleSave}>
                                    <i className="fas fa-save" />
                                </Button>
                            </div>
                            <div>
                                <Label>
                                    <b><FormattedMessage id="EditRate.Label" /></b>
                                </Label>
                                <Input
                                    type="text" className="text-sm mt-2"
                                    name="label"
                                    onChange={this.handleChange}
                                    value={label ? label : ''}
                                    required={true}
                                />
                            </div>
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>  
        );
    }
}