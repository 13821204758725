import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col, FormGroup, Label, Input, Button, Form } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import CustomSelect from '../Base/Common/CustomSelect';
import { ErrorAlert } from "../Common/ErrorAlert";
import { getAPI, putAPI } from "../Base/API";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';

class ReasonModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            reasons: []
        };
    }

    componentDidMount() {
        this.getReasons();
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    getReasons = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    var list = [];

                    data.response.forEach((r) => {
                        var obj = {
                            value: r.reason, label: <FormattedMessage id={`CancelResModal.${r.reason}`} /> };
                        list.push(obj);
                    })

                    this.setState({ reasons: list, block: false });
                }
            }
        }, `/api/Price/Reservation/v1/reasons`)
    }

    noShowSAPO = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var body = {
            reason: this.state.reasonSelected,
            description: this.state.description
        }

        putAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ReservationDetail.ReservationNoShow" />, <FormattedMessage id="General.Success" />);
                    this.props.toggle('reasonModal', null);
                }
                this.setState({ block: false });
            }
        }, `/api/Price/Reservation/v1/reservation/${this.props.data.id}/${this.props.data.channelConfigName.toUpperCase()}/noshow`, body);
    }

    cancelSAPOReservation = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var body = {
            reason: this.state.reasonSelected,
            description: this.state.description
        }

        putAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    var reservation = { ...this.props.data };
                    Object.assign(reservation, data.response[0]);

                    this.props.updateTable && this.props.updateTable(reservation, 'reservation');
                    this.props.updateData(reservation);
                    handleNotification(data, <FormattedMessage id="ReservationDetail.SAPOReservationCanceled" />, <FormattedMessage id="General.Success" />);
                    this.props.toggle('reasonModal');
                }
                this.setState(({ block: false }));
            }
        }, `/api/Price/Reservation/v1/reservation/${this.props.data.id}/${this.props.data.channelConfigName.toUpperCase()}/cancel`, body);
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={() => this.props.toggle('reasonModal')} className="modal-lg">
                <ModalHeader toggle={() => this.props.toggle('reasonModal')}>
                    <FormattedMessage id="ReservationDetail.CancelReservation" />
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.props.modalType === 'cancel' ? this.cancelSAPOReservation : this.noShowSAPO}>
                            <Card className="shadow border-0 p-0">
                                <CardBody className="m-0">
                                    <Row className="bg-white mb-2">
                                        <Col className="text-right">
                                            <Button type="submit" className="btn btn-sm btn-host float-right" >
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="assignee" sm={2}> <FormattedMessage id="CancelResModal.Reason" /> </Label>
                                                <Col sm={10}>
                                                    <CustomSelect
                                                        options={this.state.reasons ? this.state.reasons : []}
                                                        placeholder={<FormattedMessage id="CancelResModal.Reason" />}
                                                        onChange={this.handleSelect.bind(this, 'reasonSelected')}
                                                        value={this.state.reasonSelected ? this.state.reasons.find(r => r.value === this.state.reasonSelected) : null}
                                                        isClearable isSearchable
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="description" sm={2}> <FormattedMessage id="CancelResModal.Description" /> </Label>
                                                <Col sm={10}>
                                                    <Input
                                                        type="textarea"
                                                        name="description"
                                                        maxLength="500"
                                                        className="text-sm"
                                                        value={this.state.description}
                                                        onChange={(e) => this.handleChange(e)}
                                                        placeholder={this.props.intl.formatMessage({ id: "CancelResModal.Description" })}
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(ReasonModal);