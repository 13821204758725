import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedDate, FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { getAPI, postAPI } from "../../../Base/API";
import { CustomTable } from '../../../Base/Common/CustomTable';
import { handleNotification } from "../../../Base/Notification";
import  Authorization from "../../../Base/Authorization";
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { RoomCategorySetupDetails } from '../../../HotelSetting/Property/Room/RoomCategorySetupDetails';
import RatesIntegrationsModal from '../../../Setup/Room/RatesIntegrationsModal';
import { ComplexSetup } from './ComplexSetup';
import { CheckAuthorization } from '../../../Base/Authorization';
import CustomToolTip from '../../../../Utils/CustomToolTip';


 class ComplexList extends Component {

    state = {
        error: null,
        block: true,
        activeTab: '1',
        activeAddressTab: '1',
        ComplexData: [],
        selectedComplex: {},
        currentPage  :1 ,
        resultsPerPage: 10,
        validationReport: [],
        creationMode: false
    };

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleModalRoom = this.toggleModalRoom.bind(this);
        this.toggleModalError = this.toggleModalError.bind(this);
        this.updateComplex = this.updateComplex.bind(this);
        this.getComplexes = this.getComplexes.bind(this);
    }

    componentDidMount() {
        this.getComplexes();
    }

    getComplexes() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ ComplexData: data });
            }
        }, '/api/hotel/Complexes/v1?pageSize=300&pageIndex=0');
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleAddress(tab) {
        if (this.state.activeAddressTab !== tab) {
            this.setState({
                activeAddressTab: tab
            });
        }
    }

    toggleModal(creationMode) {
        this.setState(prevState => ({
            modal: !prevState.modal,
            creationMode,
            selectedComplex: null
        }));
     }

     toggleModalRoom() {
         this.setState(prevState => ({
             modalRoom: !prevState.modalRoom
         }));
     }

     toggleModalRate = () => {
         this.setState(prevState => ({
             modalRate: !prevState.modalRate
         }));
     }

     toggleModalError() {
         this.setState(prevState => ({
             modalError: !prevState.modalError
         }));
     }

    onToggleDropDown = (toggleDropDown) => {
        toggleDropDown();
    }
    
    handleRowSelect = (row, isSelected, e) => {
        if (isSelected)
            this.setState({ modal: true, selectedComplex: row })
    }

    priceFormatter(cell, row) {   // String example
        return <FormattedDate value={cell} />;
    }

    loadData() {
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                

                let validationReport = [];
                let error = false
                if (data.azureComplexValidationResponses && data.azureComplexValidationResponses.length > 0) {
                    validationReport = data.azureComplexValidationResponses;
                    error = true;
                } else {
                    handleNotification(data, <FormattedMessage id="ComplexList.DataLoadedToOTA" />, <FormattedMessage id="General.Success" />);                    
                }

                this.setState({ block: false, error: errorMessage, validationReport: validationReport, modalError : error});
            }
        }, '/api/hotel/v1/LoadHotelSearchData', '');
    }

     updateComplex = (complex) => {
         const complexList = [...this.state.ComplexData];
         const idx = complexList.indexOf(complexList.find(c => c.id === complex.id));

         if (idx >= 0) {
             complexList[idx] = complex;
         }
         else {
             complexList.push(complex);
         }
         this.setState({ ComplexData: complexList });
     }

     updateComplexs = (complexs) => {
         const complexList = [...this.state.ComplexData];
         complexs.forEach(complex => {
             const idx = complexList.indexOf(complexList.find(c => c.id === complex.id));
             if (idx >= 0) {
                 Object.assign(complexList[idx], complex);
             }
             else {
                 complexList.push(complex);
             }
         });
         
         this.setState({ ComplexData: complexList });
     }

     render() {

         const columns = [
             {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "ComplexList.complex.complexID" }),
                    sort: true
             },
             {
                dataField: 'code',
                text: this.props.intl.formatMessage({ id: "ComplexList.complex.Code" }),
                sort: true
             },
             {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "ComplexList.complex.Description" }),
                sort: true
             },
             {
                dataField: 'totalDesc',
                text: this.props.intl.formatMessage({ id: "ComplexList.complex.TotalDescription" }),
                sort: true
             },
             {
                dataField: 'totalAmeneties',
                text: this.props.intl.formatMessage({ id: "ComplexList.complex.TotalAmeneties" }),
                sort: true
             },
             {
                dataField: 'totalImages',
                text: this.props.intl.formatMessage({ id: "ComplexList.complex.Totalimages" }),
                sort: true
             }
         ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                if (CheckAuthorization("HotelConfig:complexDetail:view", null)) {              
                    this.setState({
                        modal: true,
                        selectedComplex: row,
                        creationMode: false
                    })
                }
            }
        };
                 
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />

                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                        <ModalHeader toggle={this.toggleModal}  ><FormattedMessage id="ComplexList.Complex" /></ModalHeader>
                        <ModalBody className="modal-lg px-0 pb-0">
                            <ComplexSetup selectedComplex={this.state.selectedComplex} updateComplex={this.updateComplex} updateComplexs={this.updateComplexs} creationMode={this.state.creationMode} toggle={this.toggleModal} modal={this.state.modal} />
                        </ModalBody>

                    </Modal>

                    <Modal isOpen={this.state.modalRoom} toggle={this.toggleModalRoom} fade={false} className="modal-lg " >
                        <ModalHeader toggle={this.toggleModalRoom}  > <FormattedMessage id="RoomSetup.AddRoom" /> </ModalHeader>
                        <ModalBody className="modal-lg px-0 pb-0">

                            <RoomCategorySetupDetails selectedRoom={this.state.roomType} />

                        </ModalBody>

                    </Modal>

                    <Modal isOpen={this.state.modalRate} toggle={this.toggleModalRate} fade={false} className="modal-lg " >
                        <ModalHeader toggle={this.modalRate}  > <FormattedMessage id="EditRate.EditRate" /> {this.state.selectedRate ? this.state.selectedRate.rateCodeId : ''} </ModalHeader>
                        <ModalBody className="modal-lg px-0 pb-0">
                            <RatesIntegrationsModal
                                rateCodeDetail={this.state.selectedRate}
                                fromBEConfig={true}
                            />
                        </ModalBody>

                    </Modal>

                     
                    <Card className="border-0 shadow ">
                        <CardHeader className="bg-white border-bottom"><div><span className="fas fa-hotel"> </span> <FormattedMessage id="ComplexList.ComplexConfiguration" /> </div></CardHeader>
                        <CardBody>
                            <Row>
                                <Col className="text-right">
                                <Authorization
                                    perform="ota:azure:loadData"
                                    yes={() => (
                                        <div className="d-inline">
                                            <button className="btn btn-host btn-sm ml-1" onClick={this.loadData.bind(this)} id="loadDataToAzure">
                                                <i className="fas fa-cloud-upload-alt"></i>
                                            </button>
                                            <CustomToolTip placement="left" target="loadDataToAzure">
                                                <FormattedMessage id="BEConfigDashboard.LoadDataToBE" />
                                            </CustomToolTip>
                                        </div>  
                                    )}
                                    no={() => <div></div>}
                                    />

                                    <Authorization
                                        perform="complexList:add"
                                        yes={() => (
                                            <button className="btn btn-host btn-sm ml-1" onClick={() => this.toggleModal(true)}><i className="fas fa-plus"></i></button>
                                        )}
                                        no={() => <div></div>}
                                    />
                                </Col>
                            </Row>
                            <div id="complexlist" >
                                <CustomTable
                                    data={this.state.ComplexData}
                                    columns={columns}
                                    page={this.state.currentPage}
                                    sizePerPage={this.state.resultsPerPage}
                                    totalSize={this.state.ComplexData && this.state.ComplexData.length}
                                    shadow={false}
                                    selectRow={selectRow}
                                    search={true}
                                    remote={false}
                                />

                            </div>

                            <Modal isOpen={this.state.modalError} toggle={this.toggleModalError} fade={false} className="modal-lg " >
                                <ModalHeader toggle={this.toggleModalError}  > <h5><i className="fas fa-exclamation-triangle mr-1 text-warning"></i> The following requirments need to be meet before publishing:</h5> </ModalHeader>
                            {this.state.validationReport.length > 0 ?
                                <Card className="border-0">
                                        
                                    <CardBody className="">                                        
                                        
                                        {this.state.validationReport.map((item, key) =>
                                            <div key={key}>
                                                {item.complexErrors && item.complexErrors.length > 0 ?
                                                    <div>
                                                        <Row>
                                                            <Col><b>Property :</b> </Col>
                                                        </Row>
                                                        {item.complexErrors.map((complexel, k1) =>
                                                            <Row key={k1}>
                                                                <Col>{complexel.message}</Col>
                                                                <Col> <Button className="btn btn-sm btn-host mb-1" onClick={() => this.setState({ selectedComplex: this.state.ComplexData.find(e => e.id === item.complexId), modal: true })}><i className="fas fa-wrench"></i></Button> </Col>
                                                            </Row>
                                                        )}
                                                    </div>
                                                : ''}

                                                {item.roomErrors && item.roomErrors.length > 0 ?
                                                    <div>
                                                        <Row className="mb-2">
                                                            <Col><b>Room : </b></Col>
                                                        </Row>
                                                        {item.roomErrors.map((roomel, k2) =>
                                                            <Row key={k2}>
                                                                <Col>{roomel.message}</Col>
                                                                <Col> <Button className="btn btn-sm btn-host mb-1" onClick={() => this.setState({ roomType: { id: roomel.roomId}, modalRoom : true })}><i className="fas fa-wrench"></i></Button> </Col>
                                                            </Row>
                                                        )}
                                                    </div>
                                                : ''}

                                                {item.rateErrors && item.rateErrors.length > 0 ?
                                                    <div>
                                                        <Row className="mb-2">
                                                            <Col><b>Rate : </b></Col>
                                                        </Row>
                                                        {item.rateErrors.map((rate, k3) =>
                                                            <Row key={k3}>
                                                                <Col>{rate.message}</Col>
                                                                <Col> <Button className="btn btn-sm btn-host mb-1" onClick={() => this.setState({ selectedRate: { rateCodeId: rate.rateId }, modalRate: true })}><i className="fas fa-wrench"></i></Button> </Col>
                                                            </Row>
                                                        )}
                                                    </div>
                                                : ''}
                                            </div>    
                                        )}
                                    </CardBody>
                                </Card> : ''}
                            </Modal>

                        </CardBody>
                    </Card>

                </BlockUi>
            </div>
        );       
    }
}

export default injectIntl(ComplexList)