import React, { Component } from 'react'
import { injectIntl } from 'react-intl';
import { Col } from 'reactstrap';

class MessageDisplay extends Component {
    render() {
        const { questions, question, questionIndex } = this.props;
        return (
            <div
                className='d-flex w-100 pointer'
                onClick={() => this.props.toggleEditQuestion(question.id, question.question, question.answer)}
                key={questionIndex}
            >
                <div className="w-100" >
                    <div className='faqHover py-3'>
                        <Col className='d-flex align-items-center justify-content-between px-3' style={{ maxWidth: '95%' }}>
                            <span className='w-100 text-truncate'>
                                {question.question}
                            </span>
                        </Col>

                        {question.answer ? 
                            <Col className='ml-3 text-truncate' style={{ maxWidth: '95%' }}>
                                <span className='text-muted'>
                                    {question.answer}
                                </span>
                            </Col>
                        : ''}
                    </div>
                    
                    {!(questionIndex === questions.length - 1) ? <hr className='m-0' /> : ''}
                </div>
            </div>
        )
    }
}

export default injectIntl(MessageDisplay);