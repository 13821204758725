import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Alert, Card, CardBody, Col, Row } from 'reactstrap';
import { DashBoardCard } from "./DashBoardCard";

export class GlobalDashboard extends Component {

    state = {
        modal: false ,
        syncEvents: null,
        HotelDataEvents: [],
        HotelData: {
            label: "Hotel",
            sum: 0,
            maxdate: "-"
        },
        PricingEvents: [],
        PriceData: {
            label: "Price",
            sum: 0,
            maxdate: "-"
        },
        RateEvents: [],
        RateData: {
            label: "Rate",
            sum: 0,
            maxdate: "-"
        },
        val: "",
        visible :false,
        Alerts : []
        
    }

    constructor(props) {
        super(props);

       
        this.toggle = this.toggle.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        
    }

    toggle(val) {
            this.setState({
                modal: true,
                val: val
        })
    };
    


    

   

    sumTotals(recordList) {
        var total = 0;
        if (recordList != null) {
            for (var z = 0; z < recordList.length; z++) {
                if (recordList[z].recordHour) {
                for (var i = 0; i < recordList[z].recordHour.length; i++) {
                    total += parseInt(recordList[z].recordHour[i].totals, 10);
                    }
                }
            }
            return total;
        } else {
            return "-"
        }
    }

    onDismiss() {
        this.setState({ visible: false });
    }

    returnMaxDate(dates) {

        var d = null
        for (var i = 0; i < dates.length; i++) {
            if (d == null)
                d = new Date(dates[i].lastUpdate);

            if (d < new Date(dates[i].lastUpdate))
                d = new Date(dates[i].lastUpdate)
        }
        return d;
    }

    componentDidMount() {
        
    }
    
    

    render() {
        
        
        if (this.props.HotelDataEvents  && this.props.HotelDataEvents.length > 1) {
            var d = this.returnMaxDate(this.props.HotelDataEvents)
            var sumEvt = this.sumTotals(this.props.HotelDataEvents);

            if (sumEvt !== this.state.HotelData.sum) {
                this.setState({
                    HotelData: Object.assign({}, this.state.HotelData, {
                        sum: sumEvt,
                        maxdate: d
                    }),
                });
            }
        }
        if (this.props.PricingEvents != null && this.props.PricingEvents.length > 1) {
            d = this.returnMaxDate(this.props.PricingEvents)
sumEvt = this.sumTotals(this.props.PricingEvents);

            if (sumEvt !== this.state.PriceData.sum) {
                this.setState({
                    PriceData: Object.assign({}, this.state.PriceData, {
                        sum: sumEvt,
                        maxdate: d
                    }),
                });
            }
        }

        if (this.props.RateData != null && this.props.RateData.length > 1) {
            d = this.returnMaxDate(this.props.RateData)
            sumEvt = this.sumTotals(this.props.RateData);

            if (sumEvt !== this.state.RateData.sum) {
                this.setState({
                    RateData: Object.assign({}, this.state.RateData, {
                        sum: sumEvt,
                        maxdate: d
                    }),
                });
            }
        }
           

            return (
                
                    <div>
                        {
                            this.state.Alerts.map((item) =>
                        <Alert color="danger" fade={false} toggle={this.onDismiss} isOpen={this.state.visible}>
                            {item.AlertUrl} : {item.AlertText}
                                </Alert>
                           )
                        }

                        
                                   
                    <Row>
                            <Col sm="12">
                                <Row>
                                    <Col>
                                <div className="wrapper">

                                        <BlockUi tag="div" blocking={this.props.blockHotel}>
                                            <DashBoardCard name={this.state.HotelData.label} formatedDate={this.state.HotelData.maxdate} total={this.state.HotelData.sum} />
                                        </BlockUi>
                                        <BlockUi tag="div" blocking={this.props.blockPrice}>
                                            <DashBoardCard name={this.state.PriceData.label} formatedDate={this.state.PriceData.maxdate} total={this.state.PriceData.sum} />
                                        </BlockUi>
                                        <BlockUi tag="div" blocking={this.props.blockRate}>
                                            <DashBoardCard name={this.state.RateData.label} formatedDate={this.state.RateData.maxdate} total={this.state.RateData.sum} />
                                        </BlockUi>
                                    <Card className="border-left-danger shadow h-100 py-2">

                                        <CardBody>
                                            <Row className="row no-gutters align-items-center">

                                                <Col className="mr-2">
                                                    <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">PMS Outage
                                                <div className="d-inline-block float-right"><h6 ><span className=" align-right badge badge-danger" >2</span></h6></div>
                                                    </div>
                                                    <p className="h4 mb-0 font-weight-bold ">--</p>
                                                    <p className="h8 mb-0 text-black-50 ">Last received : 2019-04-21 12:30:00</p>
                                                </Col>

                                            </Row>
                                        </CardBody>
                                            </Card>
                                        </div>
                                    </Col>
                            </Row>
                           
                        </Col>

                    </Row>
                    <div>
                        
                       
                    </div>


                    </div>
            );
        
    }
};