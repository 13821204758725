import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col, FormGroup, Label, Input, Button, Form } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { ErrorAlert } from "../Common/ErrorAlert";
import { postAPI } from "../Base/API";
import { FormattedMessage, injectIntl } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import BlockUi from 'react-block-ui';
import moment from 'moment';

class DepositModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            deposit: this.props.data.reservationDeposit ? this.props.data.reservationDeposit : null,
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            deposit: {
                ...this.state.deposit,
                [name]: value
            }
        })
    }

    saveDeposit = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var body = {
            depositDate: this.state.deposit ? moment(this.state.deposit.depositDate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            depositAmount: this.state.deposit ? this.state.deposit.depositAmount : 0
        }

        postAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    var reservation = { ...this.props.data };
                    reservation.reservationDeposit = data.response[0];

                    this.props.updateData(reservation);
                    this.props.updateTable(reservation.reservationDeposit, 'deposit');
                    handleNotification(data, <FormattedMessage id="DepositModal.DepositSaved" />, <FormattedMessage id="General.Success" />);
                    this.props.toggle('depositModal', null);
                }
                this.setState(({ block: false }));
            }
        }, `/api/Price/Reservation/v1/reservation/${this.props.data.id}/Deposit`, body);
    }

    returnDeposit = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    var reservation = { ...this.props.data };
                    reservation.reservationDeposit = data.response[0];
                    reservation.reservationDeposit.isReturned = true;

                    this.props.updateData(reservation);
                    this.props.updateTable(reservation.reservationDeposit, 'deposit');
                    handleNotification(data, <FormattedMessage id="DepositModal.DepositReturned" />, <FormattedMessage id="General.Success" />);
                    this.props.toggle('depositModal', null);
                }
                this.setState(({ block: false }));
            }
        }, `/api/Price/Reservation/v1/reservation/${this.props.data.id}/ReturnDeposit`);
    }
    
    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={() => this.props.toggle('depositModal')}>
                <ModalHeader toggle={() => this.props.toggle('depositModal')}>
                    {this.props.modalType === "return" ? <FormattedMessage id="ReservationDetail.ReturnDeposit" /> : <FormattedMessage id="DepositModal.DepositDetails" />}                    
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        {this.props.modalType === "edit" ?
                            <Form onSubmit={this.saveDeposit}>
                                <Card className="shadow border-0 p-0">
                                    <CardBody className="m-0">
                                        <Row>
                                            <Col>
                                                <FormGroup sm="true" row>
                                                    <Label for="date" sm={4}> <FormattedMessage id="DepositModal.DepositDate" /> </Label>
                                                    <Col sm={8}>
                                                        <SingleDatePicker
                                                            id="depositDate"
                                                            isOutsideRange={day => day.format("YYYY-MM-DD") < moment(this.props.data.resIdDate).format("YYYY-MM-DD") || day > moment()}
                                                            date={this.state.deposit ? moment(this.state.deposit.depositDate) : moment()}
                                                            onDateChange={date => this.setState({ deposit: { ...this.state.deposit, depositDate: date } })}
                                                            focused={this.state.focused}
                                                            onFocusChange={({ focused }) => this.setState({ focused })}
                                                            small={true}
                                                            numberOfMonths={1}
                                                            required={true}
                                                            showDefaultInputIcon={true}
                                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                                            disabled={this.props.data.reservationDeposit ? true : false}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup sm="true" row>
                                                    <Label for="depositAmount" sm={4}> <FormattedMessage id="DepositModal.DepositAmount" /> </Label>
                                                    <Col sm={8}>
                                                        <Input
                                                            type="number"
                                                            name="depositAmount"
                                                            className="text-sm"
                                                            value={this.state.deposit ? this.state.deposit.depositAmount : 0}
                                                            onChange={(e) => this.handleChange(e)}
                                                            placeholder={this.props.intl.formatMessage({ id: "DepositModal.DepositAmount" })}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="bg-white mb-2 text-right">
                                            <Col>
                                                <Button type="submit" className="btn btn-sm btn-host float-right mx-1" >
                                                    <i className="fas fa-save" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Form>
                            :
                            <Form onSubmit={this.returnDeposit}>
                                <Card className="shadow border-0 p-0">
                                    <CardBody className="m-0">
                                        <Row className="d-flex justify-content-center">
                                            <FormattedMessage id="DepositModal.ConfirmReturnDeposit" values={{ 'value': this.props.data.reservationDeposit.depositAmount}}/>
                                        </Row>
                                        <Row className="bg-white mb-2 text-right">
                                            <Col>
                                                <Button type="submit" className="btn btn-sm btn-host float-right mx-1" >
                                                    <FormattedMessage id="DepositModal.Confirm" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Form>
                        }
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(DepositModal);