import moment from 'moment';
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Row, Modal, ModalBody, ModalHeader, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard, SelectChannelConfig, SelectHotel } from "../../Base/Common/CommonUIComponents";
import CustomSelect from '../../Base/Common/CustomSelect';
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Notification";
import { prettifyXml } from '../../Base/Common/CommonUIComponents';
import classnames from 'classnames';


class SystemEvents extends Component {

    state = {
        block: true,
        modal: false,
        status: { value: false },
        systemEventsList: [],
        startDate: moment().subtract(7, 'day'),
        endDate: moment(),
        channelCode: null,
        type: null,
        pageIndex: 0,
        pageSize: 10,
        totalItems: 0,
        activeTab: '1'
    }

    componentDidMount() {
        this.getHotelList();
    }

    getHotelList = () => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false }, this.getsystemEventsList);

                return;
            }
            if (data && data.data && data.data.length > 0) {

                var combo = [];

                data.data.map((item) =>
                    combo.push({ 'value': item.hotelId, 'label': item.name2 })
                )

                this.setState({ hotelList: combo }, this.getsystemEventsList);
            }
            else this.getsystemEventsList();
        }, '/api/hotel/v1?pageSize=2000&pageIndex=0');
    }

    getsystemEventsList() {
        this.setState({ block: true });

        var param = '';
        
        if (this.state.startDate)
            param += '&StartDate=' + this.state.startDate.format('YYYY-MM-DD')
        if (this.state.endDate)
            param += '&EndDate=' + this.state.endDate.format('YYYY-MM-DD')
        if (this.state.channelConfigId)
            param += '&ChannelCode=' + this.state.channelCode
        if (this.state.type)
            param += '&type=' + this.state.type;
        if (this.state.hotelId)
            param += '&hotelId=' + this.state.hotelId;


        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors)
                    handleNotification(data, "search", 'Success');
                
                    this.setState({ systemEventsList: data.data, totalItems: data ? data.count : 0, block: false, error: null });
            }
        }, `/api/User/v1/SystemEvents?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}` + param);
    }

    setFilterState = (name, combo) => {
        this.setState({ [name]: combo && combo.value });
    }

    setFilterSelectedChannel = (name, combo) => {
        var label = combo ? combo.label : null
        var val = combo ? combo.value  : null
        this.setState({
            channelCode: label,
            channelConfigId : val
            }
        );
    }

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => { this.getsystemEventsList() });
    }

    search = () => {
        this.setState({
            pageIndex: 0
        }, () => this.getsystemEventsList());
    }

    toggleModal = (description, payload) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            eventDescription: description,
            eventPayload: payload
        }));
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { eventDescription, eventPayload } = this.state;

        const helpContent =
            <div>
                TODO
                <hr />
            </div>

        const typeOptions = [
            {
                value: 'Error',
                label: <FormattedMessage id="SystemEvents.Error" />
            },
            {
                value: 'Warning',
                label: <FormattedMessage id="SystemEvents.Warning" />
            }
        ];

        const columns = [
            {
                dataField: 'channel',
                text: this.props.intl.formatMessage({ id: "SystemEvents.Channel" })
            },
            {
                dataField: 'propertyCode',
                text: this.props.intl.formatMessage({ id: "ChannelList.PropertyCode" }),
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'hotelId',
                text: this.props.intl.formatMessage({ id: "generic.Hotel" }),
                formatter: (cell, row) => {
                    const hotel = cell && cell !== "00000000-0000-0000-0000-000000000000" ? this.state.hotelList?.find(h => h.value === cell) : '';

                    return hotel?.label
                },
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'operation',
                text: this.props.intl.formatMessage({ id: "SystemEvents.Operation" })
            },
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "SystemEvents.Type" }),
                formatter: cell => {
                    const option = typeOptions.find(opt => opt.value === cell);
                    return option ? option.label : '';
                }
            },
            {
                dataField: 'code',
                text: this.props.intl.formatMessage({ id: "SystemEvents.Code" })
            },
            {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "SystemEvents.Description" }),
                formatter: cell => <div style={{ height: '90px', /*whiteSpace: 'nowrap',*/ overflow: 'hidden', textOverflow: 'ellipsis' }}> {cell} </div>
            }, 
            {
                dataField: 'timestamp',
                text: this.props.intl.formatMessage({ id: "SystemEvents.Timestamp" }),
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'payload',
                text: '',
                formatter: (cell, row) => <Button onClick={() => this.toggleModal(row.description, row.payload)} className="btn-sm btn-host btn" > <FormattedMessage id="SystemEvents.Details" /> </Button>,
                csvText: "Payload",
                csvFormatter: cell => JSON.stringify(cell)
            }
        ];

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-exclamation-circle'} title={'SystemEvents.title'} help={helpContent}>
                <Row>
                    <Col>
                        <SelectChannelConfig required={true} name={'channelConfigId'} icon={'fas fa-plug'} value={this.state.channelConfigId} onChangeFunc={this.setFilterSelectedChannel} placeholder={<FormattedMessage id="Announcement.ChooseChannel" />} />
                    </Col>
                    <Col className="px-1">
                        <CustomSelect
                            icon={'fas fa-hotel'}
                            options={this.state.hotelList}
                            value={this.state.hotelList?.find(opt => opt.value === this.state.hotelId)}
                            placeholder={<FormattedMessage id="HotelLicenses.Hotel" />}
                            onChange={this.setFilterState.bind(this, 'hotelId')}
                            isClearable
                        />
                    </Col>
                    <Col>
                        <CustomSelect
                            icon={'fa fa-exclamation-triangle fa-fw'}
                            options={typeOptions}
                            value={typeOptions.find(opt => opt.value === this.state.type)}
                            onChange={this.setFilterState.bind(this, 'type')}
                            isClearable
                            placeholder={<FormattedMessage id="Announcement.Type" />}
                        />
                    </Col>
                    <Col className="px-1">
                        <DateRangePicker
                            startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                            startDateId="your_unique_start_date_id_announcement"
                            isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                            endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : null}
                            endDateId="your_unique_end_date_id_announcement"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="col-2">
                        <Button className="float-right btn-sm btn-host btn" onClick={this.search}><i className="fas fa-search"></i></Button>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">

                            <CustomTable
                                data={this.state.systemEventsList ? this.state.systemEventsList : []}
                                columns={columns}
                                page={this.state.pageIndex + 1}
                                sizePerPage={this.state.pageSize}
                                totalSize={this.state.totalItems}
                                onTableChange={this.handleTableChange}
                                shadow={false}
                                search={false}
                                remote={true}
                                showTotal={true}
                                hideSizePerPage={false}
                                exportCSV={true}
                            />

                        </Card>

                    </Col>

                </Row>


                <Modal isOpen={this.state.modal} toggle={() => this.toggleModal(null, null)} >
                    <ModalBody>
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                    Payload
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                    <FormattedMessage id="SystemEvents.Description" />
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                                <pre>
                                    <code>
                                        {eventPayload && eventPayload.length > 0 && eventPayload.charAt(0) === '<' ?
                                            prettifyXml(eventPayload)
                                            :
                                            eventPayload
                                        }
                                    </code>
                                </pre>
                            </TabPane>
                            <TabPane tabId="2">
                                {eventDescription}
                            </TabPane>
                        </TabContent>
                    </ModalBody>
                </Modal>
            </StyledCard>
        );
    }

};
export default injectIntl(SystemEvents)