import React, { Component, createRef } from 'react'
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Button, UncontrolledTooltip, Nav, NavItem, TabContent, NavLink } from 'reactstrap';
import { getAPI, postAPI, deleteAPI, putAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { ConfirmActionModal } from '../../Base/Common/CommonUIComponents';
import { ResultsPerPage, TotalResults, Pagination } from '../../Base/Common/PaginationComponents';
import CustomToolTip from '../../../Utils/CustomToolTip';
import FileQuestionsModal from './FileQuestionsModal';
import MessageDisplay from './MessageDisplay';
import classnames from 'classnames';
import QuestionDetailModal from './QuestionDetailModal';

class FAQConfig extends Component {
    constructor(props) {
        super(props);
        this.fileInputRef = createRef();
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleClick = this.handleClick.bind(this);

        this.state = {
            questionType: 'FAQ',
            questionsOptions: [{ label: 'FAQ', value: 'FAQ' }, { label: 'Review', value: 'Review' }],
            pageSize: 10,
            pageIndex: 0,
            totalResults: 0,
            questions: [],
            block: false,
            createNewMessage: false,
            newQuestion: '',
            newAnswer: '',
            newMessageType: 'FAQ',
            fileMessagesType: null,
            deleteFAQModal: false,
            faqToDelete: null,
            answerToDelete: null,
            faqToEdit: null,
            editQuestionTxt: null,
            editAnswerTxt: null,
            fileQuestions: [],
            fileQuestionsModal: false,
            errorCsv: false,
            questionDetailModal: false,
        };
    }

    componentDidMount() {
        this.listQuestions();
    }

    listQuestions = () => {
        const { questionType, pageSize, pageIndex } = this.state;
        this.setState({ block: true }, () => {

            var params = `?type=${questionType ?? 'FAQ'}&pageSize=${pageSize}&pageIndex=${pageIndex}`;

            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                    }else if(data.response && data.response.length > 0) {
                        this.setState({ block: false, questions: data.response, totalResults: data.count, newMessageType: questionType, error: null });
                    }else{
                        this.setState({ block: false, newMessageType: questionType })
                    }
                }
                else this.setState({ block: false })
            }, `/api/hotel/FaqsManagement/v1/Faq` + params);
        });
    }

    createNewMessage = () => {
        const { newMessageType, fileMessagesType, fileQuestions } = this.state;
        this.setState({ block: true }, () => {
            var params = `?type=${fileMessagesType ? fileMessagesType : newMessageType ?? 'FAQ'}`;
            var body = [];

            if(fileQuestions && fileQuestions.length > 0){
                body = fileQuestions;
            }else{
                body = [{
                    question: this.state.newQuestion,
                    answer: this.state.newAnswer
                }];
            }

            if (body.length === 0) {
                this.setState({ block: false, fileMessagesType: null });
                return;
            }

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false, fileMessagesType: null });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false, fileMessagesType: null, fileQuestions: [] });
                    }else if(data.response && data.response.length > 0) {
                        this.setState({ block: false, newQuestion: '', newAnswer: '', fileMessagesType: null, createNewMessage: false, questionDetailModal: false, fileQuestionsModal: false, fileQuestions: [], error: null }, () => this.listQuestions());
                    }else{
                        this.setState({ block: false, fileMessagesType: null, fileQuestions: [] })
                    }
                }
                else this.setState({ block: false, fileMessagesType: null })
            }, `/api/hotel/FaqsManagement/v1/Faq/${global.defaultProperty}` + params, body);
        });
    }

    saveEditQuestion = () => {
        const { faqToEdit, editQuestionTxt, editAnswerTxt } = this.state;
        
        this.setState({ block: true }, () => {

            let body = null;

            if(editQuestionTxt && editAnswerTxt && editQuestionTxt !== '' && editAnswerTxt !== '') {
                body = {
                    questionId: faqToEdit,
                    questionText: editQuestionTxt,
                    answerText: editAnswerTxt
                };
            }
            
            if(body){
                putAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState({ block: false, faqToEdit: null, editQuestionTxt: null, editAnswerTxt: null });
                        }else if(data.response && data.response.length > 0) {
                            this.setState({ block: false, faqToEdit: null, editQuestionTxt: null, editAnswerTxt: null, questionDetailModal: false, error: null }, () => this.listQuestions());
                        }else{
                            this.setState({ block: false, faqToEdit: null, editQuestionTxt: null, editAnswerTxt: null });
                        }
                    }
                    else this.setState({ block: false, faqToEdit: null, editQuestionTxt: null, editAnswerTxt: null });
                }, `/api/hotel/FaqsManagement/v1/Faq`, body);
            }else{
                this.setState({ block: false, faqToEdit: null, editQuestionTxt: null, editAnswerTxt: null });
            }
        });
    }

    deleteFAQ = () => {
        const { faqToDelete } = this.state;
        this.setState({ block: true }, () => {

            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false, faqToDelete: null, deleteFAQModal: false });
                    }else if(data.response && data.response.length > 0) {
                        this.setState({ 
                            block: false, 
                            faqToDelete: null, 
                            answerToDelete: null, 
                            deleteFAQModal: false, 
                            questionDetailModal: false, 
                            faqToEdit: null,
                            editQuestionTxt: null, 
                            editAnswerTxt: null, 
                            error: null
                        }, () => this.listQuestions());
                    }else{
                        this.setState({ block: false, faqToDelete: null, deleteFAQModal: false })
                    }
                }
                else this.setState({ block: false, faqToDelete: null, deleteFAQModal: false })
            }, `/api/hotel/FaqsManagement/v1/Faq/${faqToDelete}`);
        });
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    setMessagesSearchType = (type) => {
        this.setState({ questionType: type }, () => this.listQuestions());
    }

    handlePagination = (value) => {
        const { pageIndex } = this.state;

        this.setState({ pageIndex: pageIndex + value }, () => this.listQuestions());
    }
    
    changePageSize = (evt) => {
        const { value } = evt.target;

        if (this.state.pageSize !== value) {
            this.setState({ pageSize: value, pageIndex: 0 }, _ => this.listQuestions());
        }
    }

    toggleCreateNewMessage = (toggle) => {
        this.setState({ createNewMessage: toggle, newQuestion: '', newAnswer: '', questionDetailModal: toggle });
    }

    setNewMessage = (field, value) => {
        this.setState({ [field]: value });
    }

    toggleDeleteFAQModal = (faqToDelete, answerToDelete) => {
        this.setState(prevState => ({
            deleteFAQModal: !prevState.deleteFAQModal,
            faqToDelete,
            answerToDelete
        }));
    }

    handleFileChange(e) {
        var files = e.target.files;

        if (window.FileReader && files && files.length > 0 && files[0]) {
            this.setState({ block: true }, () => this.importCSV(files[0]));
        }
        else {
            handleNotification({}, this.props.intl.formatMessage({ id: "HeyTravelAI.FileQuestionsError" }), 'Error', 'info');
        }
    }

    importCSV = (file) => {
        if (file && file.type === 'text/csv') {
            if(file.size < 5242880){
                const reader = new FileReader();
                reader.onload = (event) => {
                    const csvData = event.target.result;
                    const parsedData = this.parseCSV(csvData);
                    this.setState({ block: false, fileQuestions: parsedData }, () => this.toggleFileQuestionsModal());
                };
                reader.readAsText(file);
            }else{
                const errorMessage = {warnings : []};
                errorMessage.warnings.push({ message: this.props.intl.formatMessage({ id: "HeyTravelAI.FileQuestionsSize" }), code: 'File size' });
                handleNotification(errorMessage);
                this.setState({ block: false });
            }
        }else{
            const errorMessage = {warnings : []};
            errorMessage.warnings.push({ message: this.props.intl.formatMessage({ id: "HeyTravelAI.FileQuestionsNotCsv" }), code: 'Invalid Format' });
            handleNotification(errorMessage);
            this.setState({ block: false });
        }
    }
    
    parseCSV(csvData) {
        let data = [];
        let lineError = false;

        try {
            if (!csvData) {
                throw new Error(this.props.intl.formatMessage({ id: "HeyTravelAI.FileQuestionsError" }));
            }
    
            const allTextLines = csvData.split(/\r\n|\n/).filter(t => t);
    
            if (!allTextLines || allTextLines.length === 0) {
                throw new Error(this.props.intl.formatMessage({ id: "HeyTravelAI.FileQuestionsError" }));
            }
    
            for (let i = 0; i < allTextLines.length; i++) {
                const line = allTextLines[i];
                
                if (!line) {
                    const errorMessage = {warnings : []};
                    errorMessage.warnings.push({ message: this.props.intl.formatMessage({ id: "HeyTravelAI.FileQuestionsError" }), code: 'Invalid CSV' });
                    handleNotification(errorMessage);
                    this.setState({ errorCsv: true });
                }
    
                const parts = line.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
    
                if (parts && parts.length > 1 ) {
                    let question = parts[0] && parts[0] !== '' ? parts[0].replace(/^"|"$/g, '') : null;
                    let answer = parts[1]  && parts[1] !== '' ? parts[1].replace(/^"|"$/g, '') : null;
                    let validLine = true;

                    if(!question){
                        validLine = false;
                        lineError = true;
                        question = '-'
                    }

                    if(!answer){
                        validLine = false;
                        lineError = true;
                        answer = '-'
                    }

                    data.push({ question, answer, validFaq: validLine });
                } else {
                    lineError = true;
                    data.push({ question: '-', answer: '-', validFaq: false});
                }
            }

            if (lineError) {
                const errorMessage = {warnings : []};
                errorMessage.warnings.push({ message: this.props.intl.formatMessage({ id: "HeyTravelAI.FileQuestionsError" }), code: 'Invalid CSV' });
                handleNotification(errorMessage);
                this.setState({ errorCsv: true });
            }
            return data;
        } catch (e) {
            const errorMessage = {warnings : []};
            errorMessage.warnings.push({ message: e.message, code: 'Invalid CSV' });
            handleNotification(errorMessage);
            this.setState({ errorCsv: true });
            return data;
        }
    }

    getCSVStatus = (cell, row, rowIndex) => {
        return (
            <div>
                {!cell &&
                    <UncontrolledTooltip target={`Tooltip${rowIndex}`} placement="left">
                        <div>
                            <b><FormattedMessage id='Profile.Missing' /></b>
                            {row && row.Reason && row.Reason.map((miss, key) => (
                                <div key={key}>{miss}</div>
                            ))}
                        </div>
                    </UncontrolledTooltip>
                }
                <span id={`Tooltip${rowIndex}`}>
                    <i style={{ color: cell ? '#76EFAC' : '#EE6A5F' }} className={`fas fa-${cell ? 'check' : 'times'}`}></i>
                </span>
            </div>
        );
    }

    handleClick() {
        if(this.fileInputRef && this.fileInputRef.current ){
            this.fileInputRef.current.click();
        }
    }

    fileChanged = (e) => {
        e.target.value = null;
    }

    toggleFileQuestionsModal = () => {
        if(this.state.fileQuestionsModal){
            this.setState({ fileQuestionsModal: false, errorCsv: false, fileQuestions: [] });
        }else{
            this.setState({ fileQuestionsModal: true });
        }
    }

    toggleEditQuestion = (faqToEdit, editQuestionTxt, editAnswerTxt) => {
        this.setState(prevState => ({
            questionDetailModal: !prevState.questionDetailModal,
            faqToEdit,
            editQuestionTxt,
            editAnswerTxt
        }));
    }

    editFaq = (field, value) => {
        this.setState({ [field]: value });
    }

    render() {
        const { block, questionType, pageSize, pageIndex, totalResults, questions, questionsOptions, 
            createNewMessage, newQuestion, newAnswer, newMessageType, deleteFAQModal, 
            error, fileQuestionsModal, fileQuestions, errorCsv, fileMessagesType, faqToEdit, 
            editQuestionTxt, editAnswerTxt, questionDetailModal } = this.state;
        const { intl } = this.props;
        
        const columnsFileQuestion = [
            {
                dataField: 'question',
                text: this.props.intl.formatMessage({ id: "HeyTravelAI.Question" }), 
                sort: true
            },
            {
                dataField: 'answer',
                text: this.props.intl.formatMessage({ id: "HeyTravelAI.Answer" }),
                sort: true
            },
            {
                dataField: 'validFaq',
                text: this.props.intl.formatMessage({ id: "HeyTravelAI.ValidFAQ/Review" }),
                sort: true,
                formatter: (cell, row, idx) => this.getCSVStatus(cell, row, idx)
            }
        ]

        return (
            <BlockUi tag="div" blocking={block}>
                <div style={{ height: '95%', border: '1px solid #bbc1c9ab', borderRadius: global.isMobile ? '0px 5px 5px 5px' : '5px', boxShadow: '0px 3px 15px #6F73881A' }} className={`p-3 h-100 bg-white ${global.isMobile ? 'border-top-0' : ''}`}>
                    <div style={{ height: '5%' }} className={`d-flex  ${!global.isMobile ? 'pb-3 mb-2' : '' }  px-0 align-items-center justify-content-between`}>
                        {!global.isMobile ?
                            <div>
                                <h5 style={{ fontWeight: '500' }}>
                                    <i className="far fa-comments pr-2"></i> <FormattedMessage id="HeyTravelAI.FaqConfig"/>
                                </h5>
                            </div>
                        :
                            <Nav tabs className="flex-nowrap border-0">
                                <NavItem className='pointer navItemHeyTravelAi' style={{ borderBottom: questionType === 'FAQ' ? `${global.isMobile ? '5px' : '2px'} solid rgb(22, 92, 125)` : '' }}>
                                    <NavLink className={classnames({ active: questionType === 'FAQ' }) + ' border-0'}  onClick={() => this.setMessagesSearchType('FAQ')}>
                                        {questionType === 'FAQ' ?
                                            <b>FAQ's</b>
                                        :
                                            <span>FAQ's</span>
                                        }
                                        
                                    </NavLink>
                                </NavItem>
                                <NavItem className='pointer navItemHeyTravelAi' style={{ borderBottom: questionType === 'Review' ? `${global.isMobile ? '5px' : '2px'} solid rgb(22, 92, 125)` : '' }}>
                                    <NavLink className={classnames({ active: questionType === 'Review' })+ ' border-0'} onClick={() => this.setMessagesSearchType('Review')}>
                                        {questionType === 'Review' ?
                                            <b>Reviews</b>
                                        :
                                            <span>Reviews</span>
                                        }
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        }
                        <div className='d-flex'>
                            <div className='mr-2'>
                                <input
                                    type="file"
                                    ref={this.fileInputRef}
                                    style={{ display: 'none' }}
                                    accept=".csv"
                                    onChange={this.handleFileChange}
                                    onClick={(e) => this.fileChanged(e)}
                                />
                                <Button id="ImportFAQs" className="btn btn-sm btn-host" onClick={this.handleClick}>
                                    <i className="fas fa-file-import"></i>
                                </Button>
                                <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} placement={'bottom'} target={"ImportFAQs"}>
                                    <FormattedMessage id="HeyTravelAI.ImportFAQs"/>
                                </CustomToolTip>
                            </div>
                            <div>
                                <Button disabled={createNewMessage} id="CreateFAQ" className="btn btn-sm btn-host" onClick={() => this.toggleCreateNewMessage(true)}>
                                    <i className="fas fa-plus" />
                                </Button>
                                <CustomToolTip placementPrefix={"coolTooltip bs-tooltip"} placement={'bottom'} target={"CreateFAQ"}>
                                    <FormattedMessage id="HeyTravelAI.CreateMessage"/>
                                </CustomToolTip>
                            </div>
                        </div>
                    </div>
                    {!global.isMobile ?
                        <Nav tabs className="flex-nowrap border-0">
                            <NavItem className='pointer navItemHeyTravelAi' style={{ borderBottom: questionType === 'FAQ' ? `${global.isMobile ? '5px' : '2px'} solid rgb(22, 92, 125)` : '' }}>
                                <NavLink className={classnames({ active: questionType === 'FAQ' }) + ' border-0'}  onClick={() => this.setMessagesSearchType('FAQ')}>
                                    {questionType === 'FAQ' ?
                                        <b>FAQ's</b>
                                    :
                                        <span>FAQ's</span>
                                    }
                                    
                                </NavLink>
                            </NavItem>
                            <NavItem className='pointer navItemHeyTravelAi' style={{ borderBottom: questionType === 'Review' ? `${global.isMobile ? '5px' : '2px'} solid rgb(22, 92, 125)` : '' }}>
                                <NavLink className={classnames({ active: questionType === 'Review' })+ ' border-0'} onClick={() => this.setMessagesSearchType('Review')}>
                                    {questionType === 'Review' ?
                                        <b>Reviews</b>
                                    :
                                        <span>Reviews</span>
                                    }
                                </NavLink>
                            </NavItem>
                        </Nav>
                    :''}
                    {questions && questions.length > 0 ? 
                        <TabContent activeTab={true} className="h-100">
                            <div className="d-flex align-items-start flex-column pt-4 pb-3 w-100">
                                {questions.map((question, index) => {
                                    return(
                                        <>
                                            {question ?
                                                <MessageDisplay
                                                    questionIndex={index}
                                                    question={question}
                                                    questions={questions}
                                                    toggleEditQuestion={this.toggleEditQuestion}
                                                /> 
                                            :''} 
                                        </>
                                    )
                                })}
                            </div>

                            {totalResults > 0 ?
                                <div className="py-4 smallPagination d-flex align-items-center">
                                    <div className='w-50' style={{maxWidth: '50%'}}>
                                        <ResultsPerPage
                                            changeSizePerPage={this.changePageSize}
                                            pageSize={pageSize}
                                        />

                                        {!global.isMobile ?
                                            <span className="ml-2">
                                                <TotalResults
                                                    end={pageIndex * pageSize + questions.length}
                                                    start={pageIndex * pageSize + 1}
                                                    total={totalResults}
                                                />
                                            </span>
                                        :''}
                                    </div>
                                    <div className="w-50 d-flex justify-content-end" style={{maxWidth: '50%'}}>
                                        <Pagination
                                            isPrevDisabled={pageIndex === 0}
                                            isNextDisabled={totalResults <= ((pageIndex + 1) * pageSize)}
                                            currentPage={pageIndex + 1}
                                            handlePrevButton={() => this.handlePagination(-1)}
                                            handleNextButton={() => this.handlePagination(1)}
                                        />
                                    </div>
                                </div>
                            : ''}
                        </TabContent>
                    :
                        !block ?
                            <div className='d-flex align-items-center'>
                                <FormattedMessage id="HeyTravelAI.NoData" />
                            </div>
                        :''
                    }
                </div>
                {fileQuestionsModal ? 
                    <FileQuestionsModal
                        block={block}
                        fileQuestions={fileQuestions}
                        fileQuestionsModal={fileQuestionsModal}
                        columnsFileQuestion={columnsFileQuestion}
                        createNewMessage={createNewMessage}
                        toggleFileQuestionsModal={this.toggleFileQuestionsModal}
                        saveNewMessage={this.createNewMessage}
                        error={errorCsv}
                        handleSelect={this.handleSelect}
                        fileMessagesType={fileMessagesType}
                        questionsOptions={questionsOptions}
                    />
                : ""}

                {questionDetailModal ? 
                    <QuestionDetailModal
                        editQuestionTxt={editQuestionTxt}
                        editAnswerTxt={editAnswerTxt}
                        faqToEdit={faqToEdit}
                        isOpen={questionDetailModal}
                        questionType={questionType}
                        createNewMessage={createNewMessage}
                        newMessageType={newMessageType}
                        newQuestion={newQuestion}
                        newAnswer={newAnswer}
                        questionsOptions={questionsOptions}
                        block={block}
                        editFaq={this.editFaq}
                        toggleEditQuestion={this.toggleEditQuestion}
                        toggleDeleteFAQModal={this.toggleDeleteFAQModal}
                        saveEditQuestion={this.saveEditQuestion}
                        handleSelect={this.handleSelect}
                        saveNewMessage={this.createNewMessage}
                        toggleCreateNewMessage={this.toggleCreateNewMessage}
                        setNewMessage={this.setNewMessage}
                    />
                : ""}

                {deleteFAQModal ? 
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={intl.formatMessage({ id: questionType === 'FAQ' ? "HeyTravelAI.DeleteFAQ" : "HeyTravelAI.DeleteReview" })}
                        toggleModal={this.toggleDeleteFAQModal}
                        modal={deleteFAQModal}
                        actionFunction={this.deleteFAQ}
                    />
                :''}
            </BlockUi>
        )
    }
}

export default injectIntl(FAQConfig)