import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Label, Input, Button, InputGroup, CustomInput, Form } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from "../../Base/Notification";
import { ErrorAlert } from "../../Common/ErrorAlert";
import { postAPI, putAPI, getAPI } from "../../Base/API";
import { DateRangePicker } from 'react-dates';
import BlockUi from 'react-block-ui';
import moment from 'moment';

class ResendAvailability extends Component {
	constructor(props) {
		super(props);

		this.state = {
			block: false,
			fromDate: moment(),
			toDate: moment().add(1, 'month')
		};
	}

	componentDidMount() {
		this.getRoomCategories();
	}

	getRoomCategories = (hotelId) => {
		this.setState({ block: true });

		getAPI(result => {
			const { data, error } = result;
			if (error) {
				const errorMessage = [];
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });
				return;
			}
			if (data && data.length > 0) {
				var combo = [];

				data.forEach((item) => {
					if (item.pmsId)
						combo.push({ 'label': item.description + ' (' + item.code + ')', 'value': item.id })
				})

				this.setState({ block: false, roomCategories: combo, error: [] });
			}
			else {
				this.setState({ block: false, roomCategories: [], error: [] });
			}
		}, `/api/hotel/RoomCategory/v1/HotelId/${this.props.hotelId}`);
	}

	resendAvailability = (e) => {
		e.preventDefault();
		this.setState(({ block: true }));

		postAPI(result => {
			const { data, error } = result;
			var errorMessage = [];

			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0) {
					handleNotification(data);
					this.setState(({ block: false, error: errorMessage }));
					return;
				}
				else {
					handleNotification(data, <FormattedMessage id="HotelDetails.AvailabilityResentSuccessfully" />, <FormattedMessage id="General.Success" />);
				}
				this.setState(({ block: false }));
			}
		}, `/api/hotel/v1/pmsInvokeAction/${this.props.hotelId}/category/${this.state.categoryId}?fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.toDate).format("YYYY-MM-DD")}`);
	}

	handleChangeSelect = (name, combo) => {
		this.setState({
			[name]: combo ? combo.value : null
		});
	};


	render() {
		const { modal } = this.props;

		return (
			<Modal isOpen={modal} toggle={this.props.toggleModal} size={"md"} style={{ minWidth: 0 }}>
				<ModalHeader toggle={this.props.toggleModal}>
					<FormattedMessage id="HotelDetails.ResendAvailability" />
				</ModalHeader>
				<ModalBody>
					<ErrorAlert error={this.state.error} />
					<BlockUi tag="div" blocking={this.state.block}>
						<Form onSubmit={this.resendAvailability}>
							<Row className="bg-white mb-3">
								<Col className="text-right">
									<Button className="btn btn-host btn-sm" type="submit">
										<span className="fas fa-save" />
									</Button>
								</Col>
							</Row>
							<Row className="mb-3">
								<Label sm={4}>
									<FormattedMessage id="MajorBulkUpdateModal.Dates" />
								</Label>
								<Col sm={8} className="d-flex align-items-center">
									<DateRangePicker
										startDate={this.state.fromDate ? moment(this.state.fromDate).locale(moment.locale()) : this.state.fromDate}
										startDateId="your_unique_start_date_id"
										isOutsideRange={day => day < moment() || day > moment().add(2, 'year')}
										endDate={this.state.toDate ? moment(this.state.toDate).locale(moment.locale()) : this.state.toDate}
										endDateId="your_unique_end_date_id"
										onDatesChange={({ startDate, endDate }) => this.setState({ fromDate: startDate, toDate: endDate })}
										focusedInput={this.state.focusedInput}
										onFocusChange={focusedInput => this.setState(({ focusedInput }))}
										small={true}
										required
										numberOfMonths={1}
										showDefaultInputIcon={true}
										renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
									/>
								</Col>
							</Row>
							<Row>
								<Label sm={4}>
									<FormattedMessage id="PMSHistory.RoomCategory" />
								</Label>
								<Col sm={8}>
									<CustomSelect
										options={this.state.roomCategories}
										required
										isClearable isSearchable
										onChange={this.handleChangeSelect.bind(this, 'categoryId')}
										placeholder={<FormattedMessage id="PMSHistory.RoomCategory" />}
									/>
								</Col>
							</Row>
						</Form>
					</BlockUi>
				</ModalBody>
			</Modal>
		);
	}
}

export default injectIntl(ResendAvailability);