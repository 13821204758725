import React, { Component } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupAddon, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';


export class ValidateReservations extends Component {

    state = {
        activeTab: '1',
        activeAddressTab: '1',
        HotelData: [],
        error: null,
        block: true,
    };



    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

    }

    componentDidMount() {
        this.getPaymentConfirmationList();

    }

    getPaymentConfirmationList() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ paymentConfirmationList: data.reservationAccountings });
            }
        }, '/api/Price/Reservation/v1/Review');
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleAddress(tab) {
        if (this.state.activeAddressTab !== tab) {
            this.setState({
                activeAddressTab: tab
            });
        }
    }

    render() {


        const helpContent =
            <div>
                TODO
                <hr />
            </div>


        const columns = [
            {
                dataField: 'reservationId',
                text: 'Reserva',
                sort: true
            }, {
                dataField: 'checkIn',
                text: 'CheckIn',
                sort: true,
                formatter: (cell, row) => <FormattedDate value={cell} /> 
            },
            {
                dataField: 'checkOut',
                text: 'CheckOut',
                sort: true,
                formatter: (cell, row) => <FormattedDate value={cell} /> 

            },
            {
                dataField: 'name',
                text: 'Nome',
                sort: true,
            },
            {
                dataField: 'amount',
                text: 'Valor Reserva',
                sort: true,
               
            },
            {
                dataField: 'commissionAmount',
                text: 'Valor Comissão',
                sort: true,
               
            },
            {
                dataField: 'newAmount',
                text: 'Valor Reserva Corrigido',
                sort: true,
               
            },
            {
                dataField: 'Accao',
                text: 'Acção',
                sort: true,
                formatter: (cell, row) => <UncontrolledButtonDropdown size="sm" >
                    <DropdownToggle caret className="btn-host">
                        Action
      </DropdownToggle>
                    <DropdownMenu>
                       
                        <DropdownItem>No Show</DropdownItem>
                        <DropdownItem>Update Value</DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            }];

        const selectRow = {

            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, modalData: row })
            }
        };

        return (
            

            <StyledCard title="teste" icon={"fas fa-file-invoice-dollar"} help={helpContent} block={this.state.block} error={this.state.error}>
                <Row>
                    <Col className="col-2">
                        <InputGroup size="sm text-right " >
                            <InputGroupAddon addonType="prepend"><span className="input-group-text"><span className="fas fa-key fa-fw"></span></span></InputGroupAddon>
                            <FormattedMessage id="ReservationList.ReservationId">{placeholder =>
                                <Input className="border-left-0" type="text" name="reservationid" maxLength="20" id="reservationid" placeholder={placeholder} value={this.state.reservationid} onChange={this.changeReservationId}  >
                                </ Input>
                            }</FormattedMessage>
                        </InputGroup>
                    </Col>
                    <Col><Button className="btn btn-sm btn-host float-right" onClick={this.getPaymentConfirmationList.bind(this)}><i className="fas fa-search"></i></Button></Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <CustomTable
                            data={this.state.paymentConfirmationList ? this.state.paymentConfirmationList : []}
                            columns={columns}
                            page={this.state.currentPage}
                            sizePerPage={this.state.resultsPerPage}
                            totalSize={this.state.paymentConfirmationList && this.state.paymentConfirmationList.length}
                            shadow={false}
                            search={false}
                            remote={false}
                            selectRow={selectRow}
                        />
                    </Col>
                </Row>

            </StyledCard>



        );
    }
}