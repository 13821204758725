import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Badge, Button, Input, CustomInput, Row, Form, FormText, Label } from 'reactstrap';
import { CommonHelper, StyledCard } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Notification";
import { getAPI, postAPI } from '../../Base/API';
import Authorization from '../../Base/Authorization';
import { getOperationModes } from '../../Base/Common/ReferenceDataFunctions';

class GeneralSettings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: true,
            config: {
                reminderAbandonedMails: false,
                sendPendingReservationsToPms: false,
                considerReservationsPaidBankTransferOrCustomAsPending: false,
                reminderAbandonedMinutes: 0,
                sendReservationAppointment: false
            },
            spf: {},
            dkim: {},
            dmarc: {},
            noEmailConfigured: false,
            selectedProperty: null,
            properties: [],
            sendPendingReservationsToPmsLabel: <FormattedMessage id="GeneralSettings.SendPendingReservationsToPms" />
        }
    }

    componentDidMount() {
        this.getConfig();
        if (global.operationMode === getOperationModes()[2].value) {
            this.setState({ sendPendingReservationsToPmsLabel: <FormattedMessage id="GeneralSettings.ConsiderPendingReservations" /> });
        }
    }

    getContacts = () => {
        const { complexId } = this.props;
        const { spf, dkim, dmarc } = this.state;

        if(complexId){
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = []
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false, noEmailConfigured: true });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false, noEmailConfigured: true });
                        return;
                    }
                    if (data.response && data.response.email) {
                        const domain = data.response.email.split('@')[1];

                        spf.name = domain;
                        spf.type = "TXT";
                        spf.desc = "v=spf1 ip4:195.201.40.129  -all";

                        dkim.name = `s20190728319._domainkey.${domain}`;
                        dkim.type = "TXT";
                        dkim.desc = "k=rsa; p=MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC5nwir7O+ZH3ROiZ6tdUL4MtjaJ81jXTZHyACYXyTaV2fSEk5RilFlRAwnzrmMROucb+JQuquh2OFlxxq02dqeHUtxJEv+CAIxBG4tKcf9yTOCOgsBsPC3UTVZcPbEUmMG/eSiRCMqS1mt8nKxt1LroHX51mhAKuDcJzZBf+a9twIDAQAB";

                        dmarc.name = `_dmarc.${domain}`;
                        dmarc.type = "TXT";
                        dmarc.desc = `v=DMARC1; p=none; rua=mailto:dmarc-reports@${domain}`;
                    };

                    this.setState({ block: false, noEmailConfigured: false, spf, dkim, dmarc });
                }
            }, `/api/hotel/Complexes/v1/${complexId}/Direction`);
        }
    }

    getConfig = () => {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState({ error: errorMessage }, this.getContacts());
                    return;
                }

                const config = { ...this.state.config };

                if (data && data.length > 0) {
                    Object.assign(config, data[0]);
                }

                this.setState({ config }, this.getContacts());
            }
            else {
                this.getContacts();
            }
        }, `/api/hotel/v1/BEConfig`);
    }

    saveConfig = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        let body = { ...this.state.config }

        if (body.reminderAbandonedMails === false) body.reminderAbandonedMinutes = 0;
        body.reminderAbandonedMinutes = parseInt(body.reminderAbandonedMinutes);
        body.cancelAfterHours = parseInt(body.cancelAfterHours);

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="GeneralSettings.SettingsSaved" />, <FormattedMessage id="General.Success" />);

                    const config = { ...this.state.config };
                    if (data.response && data.response.length > 0 && !config.id) {
                        config.id = data.response[0].id;
                    }

                    this.setState({ block: false, config });
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/hotel/v1/BEConfig`, body);
    }

    handleChange = (evt) => {
        const { config } = this.state;
        const { name, value } = evt.target;

        if(name === 'reminderAbandonedMinutes' || name === 'cancelAfterHours'){
            const el = document.getElementById('reminderAbandonedMinutes');
            if (el && config.reminderAbandonedMinutes && config.minStay) {
                if((name === "reminderAbandonedMinutes" && parseFloat(value) < parseFloat(config.cancelAfterHours)) || (name === "cancelAfterHours" && parseFloat(config.reminderAbandonedMinutes) < parseFloat(value))){
                    el.setCustomValidity(this.props.intl.formatMessage({ id: "GeneralSettings.ReminderMustBeBeforeAutoCancel" }));
                }
                else{
                    el.setCustomValidity("");
                }
            }
        }

        this.setState(prevState => ({
            config: {
                ...prevState.config,
                [name]: value
            }
        }));
    }

    handleSwitch = (evt) => {
        const { id, checked } = evt.target;

        this.setState(prevState => ({
            config: {
                ...prevState.config,
                [id]: checked
            }
        }));
    }

    render() {
        const { block, error, config, spf, dkim, dmarc, noEmailConfigured, sendPendingReservationsToPmsLabel } = this.state;

        return (
            <>
                <StyledCard small block={block} error={error} title={'GeneralSettings.AbandonedRes'}>
                    <Form onSubmit={this.saveConfig}>
                        <Row className="align-items-center">
                            <Col sm={4}>
                                {sendPendingReservationsToPmsLabel}?
                            </Col>
                            <Col>
                                <CustomInput
                                    type="switch"
                                    id="sendPendingReservationsToPms"
                                    checked={config.sendPendingReservationsToPms || false}
                                    onChange={this.handleSwitch}
                                />
                            </Col>
                            <Col>
                                <Button type="submit" className="btn btn-sm btn-host float-right">
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-2">
                            <Col sm={5}>
                                <FormattedMessage id="GeneralSettings.ConsiderReservationsPayedWithBtOrCustomAsConfirmed" />
                            </Col>
                            <Col>
                                <CustomInput
                                    type="switch"
                                    id="considerReservationsPaidBankTransferOrCustomAsPending"
                                    checked={config.considerReservationsPaidBankTransferOrCustomAsPending || false}
                                    onChange={this.handleSwitch}
                                />
                            </Col>
                        </Row>
                        <Row className="align-items-center mt-2">
                            <Col className="d-flex align-items-center">
                                <FormattedMessage id="GeneralSettings.CancelAfterHours" />
                                <Input
                                    type="number"
                                    name="cancelAfterHours"
                                    className="mx-2" style={{ width: '10%' }}
                                    value={config.cancelAfterHours || ''}
                                    max="9999"
                                    onChange={this.handleChange}
                                    required
                                />
                                <FormattedMessage id="GeneralSettings.Hours" />.
                            </Col>
                        </Row>
                        <FormText>
                            <Badge color="white" className="text-warning mr-1">
                                <i className="fas fa-exclamation-triangle" />
                            </Badge>
                            <FormattedMessage id="GeneralSettings.CancelAfterHoursHelp" />
                        </FormText>
                        <Row className="align-items-center mt-2">
                            <Col sm={4}>
                                <FormattedMessage id="GeneralSettings.SendReservationAppointment" />
                            </Col>
                            <Col>
                                <CustomInput
                                    type="switch"
                                    id="sendReservationAppointment"
                                    checked={config.sendReservationAppointment || false}
                                    onChange={this.handleSwitch}
                                />
                            </Col>
                        </Row>
                        <Authorization
                            perform="admin:view"
                            yes={() => (
                                <Row className="my-3 align-items-center">
                                    <Col sm={3}>
                                        <FormattedMessage id="GeneralSettings.ReminderAbandonedMails" />?
                                    </Col>
                                    <Col className="col-3">
                                        <CustomInput
                                            type="switch"
                                            id="reminderAbandonedMails"
                                            checked={config.reminderAbandonedMails || false}
                                            onChange={this.handleSwitch}
                                        />
                                    </Col>
                                    {config.reminderAbandonedMails ?
                                        <Col className="d-flex align-items-center">
                                            <FormattedMessage id="GeneralSettings.SendReminderAfter" />
                                            <Input
                                                type="number"
                                                name="reminderAbandonedMinutes"
                                                className="mx-2" style={{ width: '15%' }}
                                                value={config.reminderAbandonedMinutes || ''}
                                                id="reminderAbandonedMinutes"
                                                onChange={this.handleChange}
                                                required={config.reminderAbandonedMails}
                                            />
                                            <FormattedMessage id="GeneralSettings.Hours" />.
                                        </Col>
                                    : ''}
                                </Row>
                            )}
                        />
                    </Form>
                </StyledCard>
                <div className='mt-2'>
                    <StyledCard small block={block} title={'GeneralSettings.EmailConfig'}>
                        {noEmailConfigured ? 
                            <Row>
                                <Col>
                                    <b><FormattedMessage id="GeneralSettings.ConfigEmail"/></b>
                                </Col>
                            </Row>
                        :
                            <>
                                <div className='mt-3'>
                                    <Row>
                                        <Col>
                                            <b><FormattedMessage id="GeneralSettings.SPF"/></b>
                                        </Col>
                                        <Col>
                                            <CommonHelper
                                                id="SPFHelp"
                                                help={<FormattedMessage id="GeneralSettings.SPFHelp"/>}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="GeneralSettings.Name"/>:</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        value={spf.name}
                                                        readOnly
                                                        style={{ display: 'inline-block', width: '90%', background: 'white' }}
                                                    />
                                                    <div style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#165C7D',
                                                    width:'10%', borderRadius: '0 5px 5px 0', height: '100%' }} onClick={e => {e.preventDefault(); navigator.clipboard.writeText(spf.name)}}>
                                                        <div>
                                                            <i className="far fa-clipboard" style={{ color: 'white' }}/>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="col-2">
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="GeneralSettings.Type"/>:</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-2">{spf.type}</Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="GeneralSettings.Desc"/>:</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        value={spf.desc}
                                                        readOnly
                                                        style={{ display: 'inline-block', width: '90%', background: 'white' }}
                                                    />
                                                    <div style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#165C7D',
                                                    width:'10%', borderRadius: '0 5px 5px 0', height: '100%' }} onClick={e => {e.preventDefault(); navigator.clipboard.writeText(spf.desc)}}>
                                                        <div>
                                                            <i className="far fa-clipboard" style={{ color: 'white' }}/>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='mt-3'>
                                    <Row>
                                        <Col>
                                            <b><FormattedMessage id="GeneralSettings.DKIM"/></b>
                                        </Col>
                                        <Col>
                                            <CommonHelper
                                                id="DKIMHelp"
                                                help={<FormattedMessage id="GeneralSettings.DKIMHelp"/>}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="GeneralSettings.Name"/>:</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        value={dkim.name}
                                                        readOnly
                                                        style={{ display: 'inline-block', width: '90%', background: 'white' }}
                                                    />
                                                    <div style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#165C7D',
                                                    width:'10%', borderRadius: '0 5px 5px 0', height: '100%' }} onClick={e => {e.preventDefault(); navigator.clipboard.writeText(dkim.name)}}>
                                                        <div>
                                                            <i className="far fa-clipboard" style={{ color: 'white' }}/>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="col-2">
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="GeneralSettings.Type"/>:</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-2">{dkim.type}</Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="GeneralSettings.Desc"/>:</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        value={dkim.desc}
                                                        readOnly
                                                        style={{ display: 'inline-block', width: '90%', background: 'white' }}
                                                    />
                                                    <div style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#165C7D',
                                                    width:'10%', borderRadius: '0 5px 5px 0', height: '100%' }} onClick={e => {e.preventDefault(); navigator.clipboard.writeText(dkim.desc)}}>
                                                        <div>
                                                            <i className="far fa-clipboard" style={{ color: 'white' }}/>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='mt-3'>
                                    <Row>
                                        <Col>
                                            <b><FormattedMessage id="GeneralSettings.DMARC"/></b>
                                        </Col>
                                        <Col>
                                            <CommonHelper
                                                id="DMARCHelp"
                                                help={<FormattedMessage id="GeneralSettings.DMARCHelp"/>}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="GeneralSettings.Name"/>:</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        value={dmarc.name}
                                                        readOnly
                                                        style={{ display: 'inline-block', width: '90%', background: 'white' }}
                                                    />
                                                    <div style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#165C7D',
                                                    width:'10%', borderRadius: '0 5px 5px 0', height: '100%' }} onClick={e => {e.preventDefault(); navigator.clipboard.writeText(dmarc.name)}}>
                                                        <div>
                                                            <i className="far fa-clipboard" style={{ color: 'white' }}/>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="col-2">
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="GeneralSettings.Type"/>:</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="mt-2">{dmarc.type}</Col>
                                            </Row>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label><FormattedMessage id="GeneralSettings.Desc"/>:</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Input
                                                        value={dmarc.desc}
                                                        readOnly
                                                        style={{ display: 'inline-block', width: '90%', background: 'white' }}
                                                    />
                                                    <div style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#165C7D',
                                                    width:'10%', borderRadius: '0 5px 5px 0', height: '100%' }} onClick={e => {e.preventDefault(); navigator.clipboard.writeText(dmarc.desc)}}>
                                                        <div>
                                                            <i className="far fa-clipboard" style={{ color: 'white' }}/>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </>
                        }
                    </StyledCard>
                </div>
            </>
        )
    }
}

export default injectIntl(GeneralSettings);