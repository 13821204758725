import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { getAPI, postAPI } from "../../Base/API";
import { ErrorAlert } from "../../Common/ErrorAlert";
import  AirbnbSpecialOffer  from "./AirbnbSpecialOffer";
import { handleNotification } from "../../Base/Notification"
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAirbnbMessageRole, getAirbnbMessageOrder } from '../../Base/Common/ReferenceDataFunctions'
import AirbnbMailBoxMessages from './AirbnbMailBoxMessages';
import AirbnbMailBoxMessagesMobile from './AirbnbMailBoxMessagesMobile';


export class AirbnbMailBoxCard extends Component {

    
    constructor(props) {
        super(props);
        this.reloadMailBoxInfo = this.reloadMailBoxInfo.bind(this);
        this.changeThread = this.changeThread.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.updateselectedThreadMessages = this.updateselectedThreadMessages.bind(this); 
        this.previewFile = this.previewFile.bind(this);
        this.setselectedConnection = this.setselectedConnection.bind(this);
       
        this.state = {
            error: null,
            block: false,
            actionBlock: false,
            blockSpecialOffer: false,
            modal: false,
            selectedConnection: this.props.channelInstance || '',
            channelinstanceList: [],
            pendingThreads: {},
            latestsThreads: {},
            selectedThread: {},
            selectedStatus: 'pending_property',
            reservationId: '',
            modalData: {},
            Alerts: [],
            threadData: {},
            popoverOpen: false,
            imageContent : '',
            // search 
            messageStatus: getAirbnbMessageRole(this)[0].value,
            messageOrder: getAirbnbMessageOrder(this)[0].value,
            // Special offer obj
            specialOffer: null,
            threadDetails: {},
            openGpt: false,
        }
    }

    componentDidMount() {
       
    }
    
    reloadMailBoxInfo(event) {
        if (event) {
            event.preventDefault();
        }

        var querystring = '?'
        if (this.state.messageStatus)
            querystring += 'role=' + this.state.messageStatus +'&'
        if (this.state.messageOrder)
            querystring += 'order=' + this.state.messageOrder 
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.error) {
                    handleNotification(data);
                }

                this.setState({ threadData: data.response, block: false, error: errorMessage, selectedThread: {}, selectedThreadMessages: {} });

            }
        }, '/api/Rate/MailBox/v1/airbnb/message/' + this.state.selectedConnection + '/threadall' + querystring);
        
    }

    getSpecialOffer() {
        getAPI(result => {
            const { data, error } = result;
            this.setState({ blockSpecialOffer: true });
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, blockSpecialOffer: false });
                return;
            }
            if (data) {
                if (data.error) {
                    handleNotification(data);
                }

                this.setState({ specialOffer: data, blockSpecialOffer: false, error: errorMessage});

            }
        }, '/api/Rate/MailBox/v1/airbnb/specialOffer/' + this.state.selectedConnection + '/thread/' + this.state.selectedThread);
    }

    setselectedConnection(name, evt) {
        if (evt != null) {
            this.setState({
                [name]: evt.value,
                threadDetails: {}
            });
        }
    }

    changeSearch(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    submitPostMessage = (evt) => {
        evt.preventDefault();
        this.setState({ block: true });

        let req = {
            thread_id: this.state.selectedThread,
            message: this.state.messageToSend
            
        };

        if (this.state.imageContent.length > 0) {
            req.image = this.state.imageContent.slice(this.state.imageContent.indexOf(',')+1);
            req.content_type = this.state.imageType
        } 


        postAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                if (data.error && data.error.error_id) {
                    handleNotification(data, data.error_message, 'Error'); 
                    this.setState({ block: false });
                    return;
                }
                if (data.response) {
                    handleNotification(data, <FormattedMessage id="AirbnbMailBoxCard.MessageSent" />, <FormattedMessage id="General.Success" />);

                    this.setState(prevState => ({
                        ...prevState,
                        selectedThreadMessages: {
                            ...prevState.selectedThreadMessages,
                            messages: [
                                data.response.message,
                                ...(prevState.selectedThreadMessages.messages ?? [])    
                            ]

                        },
                        threadDetails: {
                            ...prevState.threadDetails,
                            thread: {
                                ...prevState.threadDetails.thread,
                                messages: [     
                                    ...prevState.threadDetails.thread.messages,
                                    data.response.message
                                ]
                            }
                        },
                        block: false,
                        error: errorMessage,
                        messageToSend: '',
                        imageContent: '',
                        popoverOpen: false,
                        imageType: ''
                    }));
                }
                else {
                    this.setState({ block: false });
                }                
            }
        }, '/api/Rate/MailBox/v1/airbnb/message/' + this.state.selectedConnection, req);
        
    }

    updateselectedThreadMessages(el,evt ) {
        let name ='';
        let value = '';


        if (el === 'guest') {
            name = evt.target.name;
            value = evt.target.value;
        } else {
            name = evt;
            value =el.value;
        }

        if (el === 'dates') {
            this.setState(prevState => ({
                ...prevState,
                selectedThreadMessages: {
                    ...prevState.selectedThreadMessages,
                    attachment: {
                        ...prevState.selectedThreadMessages.attachment,
                        booking_details: {
                            ...prevState.selectedThreadMessages.attachment.booking_details,
                            'checkin_date': evt.startDate != null ? evt.startDate.format('YYYY-MM-DD'): null,
                            'checkout_date': evt.endDate != null ? evt.endDate.format('YYYY-MM-DD') : null


                        }
                    }

                }
            }));

        } else {
            this.setState(prevState => ({
                ...prevState,
                selectedThreadMessages: {
                    ...prevState.selectedThreadMessages,
                    attachment: {
                        ...prevState.selectedThreadMessages.attachment,
                        booking_details: {
                            ...prevState.selectedThreadMessages.attachment.booking_details,
                            [name]: value

                        }
                    }

                }
            }));

        }
        
        
    }


    changeThread(val, evt) {
        if (this.state.selectedThread !== val) {
            this.setState({
                selectedThread: val, block: true
            }, () => this.getThreadDetails());
        }
                
        this.setState({
            selectedThreadMessages: this.state.threadData.threads.filter(item => item.id === val)[0],
            //SpecialOffer: this.state.threadData.threads.filter(item => item.id === val)[0].attachment.booking_details,
            block: false
        }, () => {
            if (this.state.selectedThreadMessages.attachment && this.state.selectedThreadMessages.attachment.type !== 'Reservation') {
                this.getSpecialOffer();
            }
        });
    }

    getThreadDetails = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.response && data.response.thread && data.response.thread.messages && data.response.thread.messages.length > 1) data.response.thread.messages.reverse();
                this.setState({ threadDetails: data.response, block: false, error: errorMessage }, () => this.getGptConversation());
            }
        }, `/api/Rate/MailBox/v1/airbnb/message/${this.state.selectedConnection}/thread/${this.state.selectedThread}`);
    }
    
    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
       
    }

    togglePopover = () => {
        this.setState(prevState => ({
            popoverOpen: !prevState.popoverOpen
        }));
    }

    setAction = (action) => {
        this.setState(prevState => ({
            modal: !prevState.modal, action: action
        }));
    }

    previewFile() {
        //var preview = document.getElementById("imagePreview");
        var file = document.querySelector('input[type=file]').files[0];

        if (file) {
            const reader = new FileReader();
            this.setState({
                imageType: file.type
            })

            reader.addEventListener("load", () => {
                this.setState({
                    imageContent: reader.result
                })
            }, false);

            reader.readAsDataURL(file);
        }
    }

    changeMsg = (e) => {
        this.setState({ messageToSend: e.target.value });
    }

    resetImgPopover = () => {
        this.setState({ imageContent: '', popoverOpen: false , imageType : ''  });
    }

    changethreadDetails = () => {
        this.setState({ threadDetails: {}, selectedThread: {}, selectedThreadMessages: null});
    }

    getGptConversation = () => {
        var owner = this.state.threadDetails && this.state.threadDetails.thread && this.state.threadDetails.thread.attachment && this.state.threadDetails.thread.attachment.roles ?
            this.state.threadDetails.thread.attachment.roles.filter(el => el && el.role === 'owner')[0]?.user_ids[0] ?? '' : '';
            
        let gptConversation = this.state.threadDetails.thread.messages.map(x => { return { content: x.message, role: x.user_id == owner ? 'assistant' : 'user' } })

        this.setState({ gptConversation })
    }

    render() {
        var viewThread = {};
        if (this.state.selectedThreadMessages) { 
            viewThread = this.state.selectedThreadMessages;
        }

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    {
                        this.state.selectedConnection ? 
                            <AirbnbSpecialOffer
                                toggleModal={this.toggleModal}
                                modal={this.state.modal}
                                attachment={this.state.selectedThreadMessages && this.state.selectedThreadMessages.attachment}
                                threadId={this.state.selectedThreadMessages && this.state.selectedThreadMessages.id}
                                specialOffer={this.state.specialOffer}
                                action={this.state.action}
                                selectedConnection={this.state.selectedConnection}
                                updateselectedThreadMessages={this.updateselectedThreadMessages}
                             />
                            : ''
                    }
                    {!global.isMobile ?
                        <AirbnbMailBoxMessages
                            selectedConnection={this.state.selectedConnection}
                            selectedThread={this.state.selectedThread}
                            threadDetails={this.state.threadDetails}
                            changeThread={this.changeThread}
                            setselectedConnection={this.setselectedConnection}
                            reloadMailBoxInfo={this.reloadMailBoxInfo}
                            changeSearch={this.changeSearch}
                            submitPostMessage={this.submitPostMessage}
                            previewFile={this.previewFile}
                            togglePopover={this.togglePopover}
                            popoverOpen={this.state.popoverOpen}
                            messageToSend={this.state.messageToSend}
                            imageContent={this.state.imageContent}
                            messageStatus={this.state.messageStatus}
                            messageOrder={this.state.messageOrder}
                            setAction={this.setAction}
                            threadData={this.state.threadData}
                            viewThread={viewThread}
                            blockSpecialOffer={this.state.blockSpecialOffer}
                            specialOffer={this.state.specialOffer}
                            actionBlock={this.state.actionBlock}
                            resetImgPopover={this.resetImgPopover}
                            changeMsg={this.changeMsg}
                            openGpt={this.state.openGpt}
                            toggleGpt={this.toggleGpt}
                            gptConversation={this.state.gptConversation}
                        />
                    :
                        <AirbnbMailBoxMessagesMobile
                            selectedConnection={this.state.selectedConnection}
                            selectedThread={this.state.selectedThread}
                            threadDetails={this.state.threadDetails}
                            changeThread={this.changeThread}
                            setselectedConnection={this.setselectedConnection}
                            reloadMailBoxInfo={this.reloadMailBoxInfo}
                            changeSearch={this.changeSearch}
                            submitPostMessage={this.submitPostMessage}
                            previewFile={this.previewFile}
                            togglePopover={this.togglePopover}
                            popoverOpen={this.state.popoverOpen}
                            messageToSend={this.state.messageToSend}
                            imageContent={this.state.imageContent}
                            messageStatus={this.state.messageStatus}
                            messageOrder={this.state.messageOrder}
                            setAction={this.setAction}
                            threadData={this.state.threadData}
                            viewThread={viewThread}
                            blockSpecialOffer={this.state.blockSpecialOffer}
                            specialOffer={this.state.specialOffer}
                            actionBlock={this.state.actionBlock}
                            resetImgPopover={this.resetImgPopover}
                            changeMsg={this.changeMsg}
                            changethreadDetails={this.changethreadDetails}
                            openGpt={this.state.openGpt}
                            toggleGpt={this.toggleGpt}
                            gptConversation={this.state.gptConversation}
                        />
                    }
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(AirbnbMailBoxCard);