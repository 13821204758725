import React, { Component } from 'react';
import { Button, Row, Col, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-block-ui/style.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from "moment";
import CustomSelect from '../Base/Common/CustomSelect'
import { SelectChannelInstance, SelectRoomCategory } from '../Base/Common/CommonUIComponents';
import { CustomDateRangePicker, CustomSingleDatePicker } from '../Base/Common/CustomReactDates';

class ReservationListSearch extends Component {
    render() {
        return (
            <Row className="small ">
                <Col className="col-12">
                    <form ref={this.props.form}>
                        <Row>
                            <Col className="col-2 pr-1">
                                <InputGroup size="sm text-right">
                                    <InputGroupAddon addonType="prepend"><span className="input-group-text border-right-0"><span className="fas fa-key fa-fw"></span></span></InputGroupAddon>
                                    <FormattedMessage id="ReservationList.ReservationId">
                                        {
                                            placeholder => <Input className="border-left-0" type="text" name="reservationid" maxLength="50" id="reservationid" placeholder={placeholder} value={this.props.reservationid} onChange={this.props.changeReservationId} />
                                        }
                                    </FormattedMessage>
                                </InputGroup>
                            </Col>
                            <Col className="pr-1">
                                <InputGroup size="sm text-right">
                                    <InputGroupAddon addonType="prepend"><span className="input-group-text border-right-0"><span className="fas fa fa-plug fa-fw"></span></span></InputGroupAddon>
                                    <FormattedMessage id="ReservationList.Source">
                                        {
                                            placeholder => <Input className="border-left-0" type="text" name="source" maxLength="50" id="source" placeholder={placeholder} value={this.props.source} onChange={this.props.changeSource} />
                                        }
                                    </FormattedMessage>
                                </InputGroup>
                            </Col>
                            <Col className="px-1">
                                <InputGroup size="sm text-right">
                                    <InputGroupAddon addonType="prepend"><span className="input-group-text border-right-0"><span className="fa fa-id-card fa-fw"></span></span></InputGroupAddon>
                                    <FormattedMessage id="ReservationList.Surname">
                                        {
                                            placeholder => <Input className="border-left-0" type="text" name="Surname" id="Surname" maxLength="20" placeholder={placeholder} value={this.props.surname} onChange={this.props.changeSurname}/>
                                        }
                                    </FormattedMessage>
                                </InputGroup>
                            </Col>
                            <Col className="px-1">
                                <SelectChannelInstance icon={'fa fa-plug fa-fw'} name={'channelInstanceId'} placeholder={<FormattedMessage id="ReservationList.SelectChannel" />} value={this.props.channelInstanceId} onChangeFunc={this.props.setComboStatus} isMulti/>
                            </Col>
                            <Col className="px-1">
                                <CustomSelect icon={'fas fa-suitcase'} isClearable isSearchable placeholder={<FormattedMessage id="ReservationList.ReservationStatus" />} options={this.props.reservationStatusList} onChange={this.props.setComboStatus.bind(this, 'channelStatus')} />                                           
                            </Col>
                            <Col className="px-1">
                                <CustomSelect icon={'fas fa-share'} isClearable isSearchable placeholder={<FormattedMessage id="ReservationList.CommunicationStatus" />} options={this.props.communicationStatusList} onChange={this.props.setComboStatus.bind(this, 'communicationStatus')} />
                            </Col>
                            <Col className="col-1 text-right">  
                                {
                                    global.modules && global.modules.some(m => m === 'ManualReservations') ?
                                        <Button className="btn btn-host btn-sm mr-1" onClick={() => this.props.history.push('/ManualReservation')}>
                                            <span className="fas fa-plus" />
                                        </Button>
                                        :
                                        ''
                                }
                                <Button className="btn btn-host btn-sm" onClick={_ => this.props.doSearch()}>
                                    <span className="fas fa-search" />
                                </Button>
                            </Col>
                        </Row>
                        <Row className="my-2">
                            <Col className="col-2 pr-1">
                                <SelectRoomCategory icon={'fa fa-bed fa-fw'} isMulti={false} name={'roomCategoryId'} onChangeFunc={this.props.setComboStatus} placeholder={<FormattedMessage id="AnalyticsDashboard.SelectRoomCategory" />} />
                            </Col>
                            <Col className="col-1 d-flex align-items-center px-1">
                                <FormattedMessage id="ReservationList.ReservationDate"/>
                            </Col>
                            <Col className="col-2 px-1">
                                <InputGroup size="sm text-right" >
                                    <CustomDateRangePicker
                                        startDate={this.props.startDate}
                                        startDateId="your_unique_start_date_id"
                                        isOutsideRange={day => day <= moment().subtract(12, 'month') || day.isAfter(moment())}
                                        endDate={this.props.endDate}
                                        endDateId="your_unique_end_date_id"
                                        onDatesChange={(startDate, endDate)  => this.props.changeDates(startDate, endDate)}
                                        numberOfMonths={1}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="col-1 d-flex align-items-center">
                                <FormattedMessage id="ReservationList.CheckInDates" />
                            </Col>
                            <Col className="col-2 px-1">
                                <InputGroup size="sm text-right" >
                                    <CustomDateRangePicker
                                        startDate={this.props.checkInStartDate}
                                        startDateId="your_unique_checkInStartDate_id"
                                        isOutsideRange={day => day <= moment().subtract(12, 'month') || day > moment().add(12, 'month')}
                                        endDate={this.props.checkInEndDate}
                                        endDateId="your_unique_checkInEndDate_id"
                                        onDatesChange={(startDate, endDate) => this.props.changeCheckInDates(startDate, endDate)}
                                        numberOfMonths={1}
                                        minimumNights={0}
                                    />
                                </InputGroup>
                            </Col>
                            <Col className="col-1 px-1 d-flex align-items-center">
                                <FormattedMessage id="ReservationList.ModifyDates" />
                            </Col>
                            <Col className="col-2 px-1">
                                <InputGroup size="sm text-right" >
                                    <CustomDateRangePicker
                                        startDate={this.props.lastUpdateStartDate}
                                        startDateId="your_unique_lastUpdateStartDate_id"
                                        isOutsideRange={day => day <= moment().subtract(12, 'month') || day.isAfter(moment())}
                                        showYearOptions={{ pastYears: false, futureYears: false }}
                                        endDate={this.props.lastUpdateEndDate}
                                        endDateId="your_unique_lastUpdateEndDate_id"
                                        onDatesChange={(startDate, endDate) => this.props.changeLastUpdateDates(startDate, endDate)}
                                        numberOfMonths={1}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-1 pl-3 px-1 d-flex align-items-center">
                                <FormattedMessage id="ReservationList.IncludedDate" />
                            </Col>
                            <Col className="col-2 px-1">
                                <InputGroup size="sm text-right" >
                                    <CustomSingleDatePicker
                                        id="your_unique_includedDate_id"
                                        date={this.props.includedDate}
                                        isOutsideRange={day => day <= moment().subtract(24, 'month') || day >= moment().add(24, 'month')}
                                        showYearOptions={{ pastYears: true, futureYears: true }}
                                        onDateChange={date => this.props.changeIncludedDate(date)}
                                        numberOfMonths={1}
                                        showTodaysButton={true}
                                    />
                                </InputGroup>
                            </Col>
                            {this.props.isNone ?
                                <Col className="col-2 pr-1">
                                    <CustomSelect options={this.props.hasMappingsOptions} icon={'fa fa-plug fa-fw'} name={'hasMappings'} onChange={this.props.setComboStatus.bind(this, 'hasMappings')} placeholder={<FormattedMessage id="ReservationList.HasMappings" />} isClearable />
                                </Col>
                            :''}
                        </Row>
                    </form>
                </Col>
            </Row>
        )
    }
}

export default injectIntl(ReservationListSearch)
