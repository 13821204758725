import React, { Component } from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody, CardHeader, Label, FormGroup, Input, InputGroup, Button } from 'reactstrap';
import { postMultiPartAPI, getAPI, deleteAPI } from "../../../Base/API"
import { CommonHelper } from '../../../Base/Common/CommonUIComponents';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import '../../../../css/image-galery.css';
import { handleNotification } from "../../../Base/Notification";

export class RoomCategoryImageManagement extends Component {

    state = {
        blocking: false,
        notification: { type: 'Success', message: '' },
        error: [],
        errorModal: [],
        hotelPhotos: [],
        selectedPhoto: { 'description': '', 'order': 100 },
        selectedRoom: {},
        block: false,

        modal: false
    };

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.toggleModal = this.toggleModal.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedRoom !== this.props.selectedRoom) {
            this.getPhotos(nextProps.selectedRoom.id);
        }
    }

    componentDidMount() {
        if (this.props.selectedRoom) {
            this.getPhotos(this.props.selectedRoom.id)
        }
    }

    getPhotos = (id) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {

                this.setState({
                    hotelPhotos: data, block: false,
                    roomId: id,
                    error: errorMessage
                });

            }
        }, '/api/hotel/RoomCategory/v1/' + id + '/Photos');
    }

    handleDelete(e) {
        e.preventDefault();
        this.setState({ block: true });
        var url = '/api/hotel/RoomCategory/v1/' + this.props.selectedRoom.id + '/Photos/Upload/' + this.state.selectedPhoto.id;

        deleteAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ errorModal: errorMessage, block: false });
                return;
            }
            if (data) {
                if (this.state.roomId) this.getPhotos(this.state.roomId);

                handleNotification(data, <FormattedMessage id="ComplexPhotos.PhotoDeleted" />, <FormattedMessage id="General.Success" />);
                this.setState({ errorModal: errorMessage, modal: false, block: false, selectedPhoto: {}, imagePreviewUrl: {} });
            }
        },  url);

    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({ block: true });
        if (e) {
            e.preventDefault();
            if (!this.form.current.reportValidity()) {
                return;
            }

        }

        this.setState({
            block: true
        });

        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('description', this.state.selectedPhoto.description);
        formData.append('order', this.state.selectedPhoto.order);

        var url = '/api/hotel/RoomCategory/v1/' + this.props.selectedRoom.id + '/Photos/Upload';

        if (this.state.selectedPhoto.id)
            url = url + '/' + this.state.selectedPhoto.id;

        postMultiPartAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ errorModal: errorMessage, block: false });
                return;
            }
            if (data) {
                if (this.state.roomId) this.getPhotos(this.state.roomId);

                handleNotification(data, <FormattedMessage id="ComplexPhotos.PhotoSaved" />, <FormattedMessage id="General.Success" />);
                this.setState({ errorModal: errorMessage, modal: false, block: false, selectedPhoto: {}, imagePreviewUrl: {} });
            }
        },  url, formData);

    }

    handleImageChange(e) {
        e.preventDefault();

        let file = e.target.files[0];

        if (file) {
            if (file.size <= 1048576) { //1MB
                let reader = new FileReader();

                reader.onloadend = () => {
                    this.setState({
                        file: file,
                        imagePreviewUrl: reader.result
                    });
                }

                if (file) {
                    reader.readAsDataURL(file);
                }
            }
            else {
                document.getElementById('multi').value = "";
                handleNotification('', <FormattedMessage id="BookingChatWindow.PleaseSelectAFileLessThan" />, <FormattedMessage id="BookingChatWindow.SelectedFileIsTooBig" />, 'info');
            }
        }
        else {
            document.getElementById('multi').value = "";
            this.setState({ fileContent: '' })
        }
    }


    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedPhoto: !prevState.modal ? prevState.selectedPhoto : {},
            imagePreviewUrl: !prevState.modal ? prevState.imagePreviewUrl : {}
        }));
    }

    handleDescriptionChange(e) {
        this.setState({
            selectedPhoto: {
                ...this.state.selectedPhoto,
                description: e.target.value
            }
        })
    }
    handleOrderChange(e) {
        this.setState({
            selectedPhoto: {
                ...this.state.selectedPhoto,
                order: e.target.value
            }
        })
    }

    handleImageSelection(e) {

        var item = this.state.hotelPhotos[this._imageGallery.getCurrentIndex()];
        var _selectedPhoto = item;
        this.setState({ selectedPhoto: _selectedPhoto, modal: true });

    }

    render() {

        const images = [];
        this.state.hotelPhotos && this.state.hotelPhotos.map((item, key) =>
            images.push({ 'original': item.imageUrl, 'thumbnail': item.imageUrl, 'description': item.description })
        );
        let { imagePreviewUrl } = this.state;
        let $imagePreview = null;

        if (this.state.selectedPhoto.imageUrl) {
            $imagePreview = (<img alt="" style={{ maxHeight: '650px', maxWidth: '100%' }} src={this.state.selectedPhoto.imageUrl} />);
        } else if (imagePreviewUrl) {
            $imagePreview = (<img alt="" style={{ maxHeight: '650px', maxWidth: '100%' }} src={imagePreviewUrl} />);
        } else {
            $imagePreview = (<div ><FormattedMessage id="ComplexPhotos.SelectAnImageForPreview" /></div>);
        }



        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                        <form ref={this.form} >
                            <ModalHeader toggle={this.toggleModal}  > <FormattedMessage id="RoomCategoryImageManagement.ManagePropertyPhotos" /> </ModalHeader>
                            <ModalBody className="modal-lg">
                                <Card className="bg-white small">
                                    <Row><Col className="Col-12">
                                        <ErrorAlert error={this.state.errorModal} />
                                        <Card className="small">

                                            <CardHeader ><span className="fas fa-upload"></span> <FormattedMessage id="RoomCategoryImageManagement.CreateUpdatePhoto" /> </CardHeader>
                                            <CardBody>

                                                <FormGroup row>
                                                    <Label for="Desc" className="col-12 col-lg-2"> <FormattedMessage id="RoomCategoryImageManagement.PhotoDescription" /> </Label>
                                                    <Col className="col-12 col-lg-4">
                                                        <InputGroup size="sm">
                                                            <FormattedMessage id="RoomCategoryImageManagement.InsertPhotoDescription">{placeholder =>
                                                                <Input name="Desc" placeholder={placeholder} required value={this.state.selectedPhoto.description} onChange={this.handleDescriptionChange.bind(this)} />
                                                            }</FormattedMessage>
                                                        </InputGroup>
                                                    </Col>
                                                </FormGroup>
                                                <FormGroup row>
                                                    <Label for="Desc" className="col-12 col-lg-2"> <FormattedMessage id="RoomCategoryImageManagement.Order" /> </Label>
                                                    <Col className="col-12 col-lg-4">
                                                        <InputGroup size="sm">
                                                            <FormattedMessage id="RoomCategoryImageManagement.ListingOrder">{placeholder =>
                                                                <Input type="number" name="Desc" max='100' min='1' placeholder={placeholder} required value={this.state.selectedPhoto.order} onChange={this.handleOrderChange.bind(this)} />
                                                            }</FormattedMessage>
                                                        </InputGroup>
                                                    </Col>
                                                </FormGroup>
                                                {this.state.selectedPhoto.id ? '' :
                                                    <FormGroup row>
                                                        <Label for="Desc" className="col-12 col-lg-2"> <FormattedMessage id="RoomCategoryImageManagement.ChoosePhoto" /> </Label>
                                                        <Col className="col-12 col-lg-4">
                                                            <InputGroup size="sm">

                                                                <input id='multi' required={true} type="file" accept="image/*" onChange={this.handleImageChange} />

                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                }
                                                <Row>
                                                    <Col>
                                                        <div className="text-center mt-3">
                                                            {$imagePreview}
                                                        </div>
                                                    </Col>
                                                </Row>

                                            </CardBody>
                                        </Card>
                                    </Col>
                                    </Row>
                                </Card>
                            </ModalBody>
                            <ModalFooter>
                                {this.state.selectedPhoto.id ? <Button color="primary btn-host " onClick={this.handleDelete} > <FormattedMessage id="RoomCategoryImageManagement.Delete" /> </Button> : ''}
                                <Button color="primary btn-host " onClick={this.handleSubmit} > <FormattedMessage id="RoomCategoryImageManagement.Save" /> </Button>{' '}
                                <Button color="secondary" onClick={this.toggleModal}> <FormattedMessage id="RoomCategoryImageManagement.Cancel" /> </Button>
                            </ModalFooter>
                        </form>
                    </Modal>

                    <Card className="small shadow  border-0">
                        <CardHeader className="bg-white border-bottom-1">
                            <Row>
                                <Col><span className="fas fa-images"> </span> <FormattedMessage id="RoomCategorySetupDetails.RoomCategoryPhotos" /></Col>
                                <Col className="text-right">
                                    <Button className="btn btn-host mr-1 btn-sm" onClick={this.toggleModal}> <FormattedMessage id="RoomCategoryImageManagement.AddPhoto" /> </Button>

                                    <CommonHelper help={<FormattedMessage id="RoomCategoryImageManagement.Help" />} id={'RoomCategoryPhotosHelp'} />
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col className="col-12">
                                    <div>
                                        {images && images.length > 0 ? <ImageGallery ref={i => this._imageGallery = i} thumbnailPosition={global.isMobile ? 'top' : 'left'} lazyLoad={true} items={images} onClick={this.handleImageSelection.bind(this)} /> : ''}
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>                  
                </BlockUi>
            </div>
        );
    }
} 