import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Input, Label, Button } from 'reactstrap';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Common/ErrorAlert";
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

export class ManualReservationConfirm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false
        };
    }

    render() {
        const { reservation, handleDiscount, discount, discountType, applyDiscount, revertDiscount, totalNoDiscount, totalWithDiscount } = this.props;
        const { block, error } = this.state;

        const mainGuest = reservation.profiles && reservation.profiles.find(guest => guest.isBooker === true);
        const otherGuests = mainGuest ? reservation.profiles.filter(guest => guest !== mainGuest) : reservation.profiles;
        const total = reservation.totalAmount;

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={error} />
                <Row>
                    <Col>
                        <Card className="border-0 shadow mb-3">
                            <CardBody>
                                <h5 className="text-muted"><FormattedMessage id="ManualReservation.RoomStays" /></h5>
                                <Row className="mb-1">
                                    <Col className="text-muted small"> <FormattedMessage id="ManualReservation.RoomCategory" /></Col>
                                    <Col className="text-muted small"> <FormattedMessage id="ManualReservation.Rate" /></Col>
                                    <Col className="text-muted small"> <FormattedMessage id="ManualReservation.CheckIn" /></Col>
                                    <Col className="text-muted small"> <FormattedMessage id="ManualReservation.CheckOut" /></Col>
                                    <Col className="text-muted small"> <FormattedMessage id="ManualReservation.Guests" /></Col>
                                    <Col className="text-muted small col-1"> Total</Col>
                                </Row>
                                {this.props.reservation.roomStays && this.props.reservation.roomStays.map((room, key) =>
                                    <Row className="mb-1" key={key}>
                                        <Col className="text-host "> <h6> {room.roomCategoryDesc} </h6> </Col>
                                        <Col className="text-host "> <h6>{room.rateDesc} </h6> </Col>
                                        <Col className="text-success"> <h6>{moment(room.roomRates[0].effectiveDate).format('ddd DD/MM/YYYY')} </h6></Col>
                                        <Col className="text-danger"> <h6>{moment(room.roomRates[room.roomRates.length - 1].expireDate).add(1, 'day').format('ddd DD/MM/YYYY')} </h6></Col>
                                        <Col className="text-host "> <h6>{room.adults} <FormattedMessage id="ManualReservation.Adults" />, {room.children} <FormattedMessage id="ManualReservation.Children" /> </h6></Col>
                                        <Col className="text-host col-1"> <h6>{room.totalAmount} € </h6></Col>
                                    </Row>
                                )}
                                <Row className="mb-1">
                                   
                                    <Col className="col-11"> </Col>
                                    <Col className="text-host col-1"> <h5>{total} €</h5></Col>
                                </Row>
                            </CardBody>
                        </Card>

                        <Card className="border-0 shadow mb-3">
                            <CardBody>
                                <h5 className="text-muted"><FormattedMessage id="ManualReservation.Guests" /></h5>

                                {mainGuest ?
                                    <div>
                                        <Row>
                                            <Col>  <FormattedMessage id="ManualReservation.MainGuest" /><i className="fas fa-star fa-xs text-host ml-2" /> </Col>
                                        </Row>
                                        <Row>
                                            <Col> <Label className="text-muted small"><FormattedMessage id="ManualReservation.FirstName" /></Label> </Col>
                                            <Col> <Label className="text-muted small"><FormattedMessage id="ManualReservation.Surname" /></Label> </Col>
                                            <Col> <Label className="text-muted small"><FormattedMessage id="ManualReservation.Nationality" /></Label> </Col>
                                            <Col> <Label className="text-muted small"><FormattedMessage id="ManualReservation.Phone" /></Label> </Col>
                                            <Col> <Label className="text-muted small"><FormattedMessage id="ManualReservation.Email" /></Label> </Col>
                                            <Col className="col-3"> <Label className="text-muted small"><FormattedMessage id="ManualReservation.Room" /></Label> </Col>
                                        </Row>
                                        <Row>
                                            <Col> <h6>{mainGuest.firstName}</h6> </Col>
                                            <Col> <h6>{mainGuest.lastName}</h6> </Col>
                                            <Col> <h6>{mainGuest.nationality}</h6> </Col>
                                            <Col> <h6>{mainGuest.phoneNumber}</h6> </Col>
                                            <Col> <h6>{mainGuest.email}</h6> </Col>
                                            <Col className="col-3">
                                                <h6>{this.props.reservation.roomStays.map((room, key) =>
                                                    room.guestsRPHs && room.guestsRPHs.find(rph => rph === mainGuest.rph) ?
                                                        <div key={key}>
                                                            <FormattedMessage id="ManualReservation.Room" /> {key + 1} ({room.roomCategoryDesc} | {moment(room.roomRates[0].effectiveDate).format('DD')} - {moment(room.roomRates[room.roomRates.length - 1].expireDate).add(1, 'day').format('DD MMMM')})
                                                            </div>
                                                        : ''
                                                )}</h6>
                                            </Col>
                                        </Row>
                                    </div>
                                : ''}

                                {otherGuests && otherGuests.length > 0 ?
                                    <div>
                                        <Row className="mt-3 mb-2">
                                            <Col>
                                                <FormattedMessage id="ManualReservation.OtherGuests" />
                                            </Col>
                                        </Row>
                                        {otherGuests.map((guest, key) =>
                                            <Row key={key}>
                                                <Col>
                                                    <Row>
                                                        <Col className="col-2">
                                                            <span style={{ fontWeight: '500' }}> {guest.firstName} {guest.lastName} </span>
                                                        </Col>
                                                        <Col>
                                                            {this.props.reservation.roomStays.map((room, key) =>
                                                                room.guestsRPHs && room.guestsRPHs.find(rph => rph === guest.rph) ?
                                                                    <div key={key}>
                                                                        <span style={{ fontWeight: '500' }}> <FormattedMessage id="ManualReservation.Room" /> {key + 1} </span> ({room.roomCategoryDesc} | {moment(room.roomRates[0].effectiveDate).format('ddd DD/MM/YYYY')} - {moment(room.roomRates[room.roomRates.length - 1].expireDate).add(1, 'day').format('ddd DD/MM/YYYY')}
                                                                    </div>
                                                                : ''
                                                            )} 
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                : ''}

                            </CardBody>
                        </Card>

                        <Card className="border-0 shadow mb-3">
                            <CardBody>
                                <h5 className="text-muted pb-2"><FormattedMessage id="ManualReservation.Comments" /></h5>
                                {this.props.reservation && this.props.reservation.comments && this.props.reservation.comments.map((el, k) =>
                                    <div className="mb-1 text-host" key={k}> <h6> "{el.text}" </h6> </div>    
                                )}
                                
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="col-2">
                        <Card className="border-0 shadow">
                            <CardBody>
                                <h5 className="text-muted"><FormattedMessage id="ManualReservation.Discount" /></h5>
                                <Row className="my-2">
                                    <Col className="text-center"><b>%</b></Col>
                                    <Col className="text-center"><b>€</b></Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col className="text-center">
                                        <Input
                                            type="number"
                                            name="percentage"
                                            onChange={handleDiscount}
                                            min="1" max="100"
                                            value={discount && discountType === 'percentage' ? discount : ''}
                                        />
                                    </Col>
                                    <Col className="text-center">
                                        <Input
                                            type="number"
                                            name="number"
                                            onChange={handleDiscount}
                                            min="1"
                                            value={discount && discountType === 'number' ? this.state.discount : ''}
                                        />
                                    </Col>
                                </Row>
                                <h6 className="text-host pt-2"><FormattedMessage id="ManualReservation.PriceWithNoDiscount" /></h6>
                                <h5 className="text-host pb-2">{parseFloat(totalNoDiscount).toFixed(2)} €</h5>
                                <h6 className="text-muted pt-2"><FormattedMessage id="ManualReservation.PriceDiscount" /></h6>
                                <h5 className="text-muted pb-2">{parseFloat(totalWithDiscount).toFixed(2)} €</h5>
                                {
                                    this.props.reservationCreated === false ?
                                        <Row className="pt-2">
                                            <Col className="text-center">
                                                <Button className="btn btn-host btn-sm" onClick={applyDiscount} disabled={reservation.roomStays.find(rs => rs.manualPrices) !== undefined}>
                                                    <FormattedMessage id="ManualReservation.Apply" />
                                                </Button>
                                            </Col>
                                            <Col className="text-center">
                                                <Button className="btn btn-host btn-sm" onClick={revertDiscount} disabled={reservation.roomStays.find(rs => rs.manualPrices) === undefined}>
                                                    <FormattedMessage id="ManualReservation.Revert" />
                                                </Button>
                                            </Col>
                                        </Row>
                                        :
                                        ''
                                }
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </BlockUi>
        );
    }
}