import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export class StopSalesTooltip extends Component {

    render() {
        const { stopSalesChannels } = this.props
        return (
            <div>
                <Row>
                    <Col>
                        <span className="float-left p-0 text-white">
                            <i className="fas fa-ban mr-1" />
                            <FormattedMessage id="RateAvailability.StopSales" />
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ul className="text-left p-0 mb-0 ml-3">
                            {
                                stopSalesChannels.map((chn, k) => <li key={k}>{chn}</li>)
                            }
                        </ul>
                    </Col>
                </Row>
            </div>
        );
    }
}