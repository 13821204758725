import React, { Component } from 'react';
import { Col, Label } from 'reactstrap';

export class RestrictionBox extends Component {
   
    render() {
        const { id, isDependent, toggleModal, value, fromRate } = this.props;
        const classCol = "position-top border-bottom border-right max-with-14Col ";

        return (
            <Col className={classCol} style={{ minWidth: '60px' }}>
                <div className="text-center  font-weight-bold  text-uppercase">
                    <Label style={{ color: fromRate ? '#8f8f8f' : '', cursor: isDependent ? '' : 'pointer' }} id={`label${id}`} key={`label${id}`} className={`cleanInput`} onClick={isDependent == true ? undefined : toggleModal}>
                        {
                            value === null || value === undefined || value === '' || value === 0 ?
                                '-'
                                :
                                value
                        }
                    </Label>
                </div>
            </Col>
        );
    }
};