import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { EditableRestrictionBox } from './EditableRestrictionBox';
import moment from 'moment';

export class EditableChannelBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            restrictionsList: [
                { name: 'stopSales', type: 'checkbox' },
                { name: 'closeToDeparture', type: 'checkbox' },
                { name: 'closeToArrival', type: 'checkbox' },
                { name: 'minimumStayLen', type: 'number' },
                { name: 'maximumStayLen', type: 'number' },
                { name: 'minAdvancedBookingOffset', type: 'number' },
                { name: 'maxAdvancedBookingOffset', type: 'number' }
            ],
            currentRow: null
        };
    };

    changeRow = (row) => {
        const { currentRow } = this.state;
        if(currentRow !== row) this.setState({ currentRow: row });
    }

    render() {
        const { availability, next14Days, rateId, handleDataChange, dragStart, focusedInput, dragObj, newData, changeCol, wasSaved, changeSelectedRate, selectedRateId, onScroll } = this.props;
        const { restrictionsList, currentRow } = this.state;

        const lastKey = restrictionsList.length - 1;

		return (
            !availability ? <div/>
            :
                !global.isMobile ?
                    <div>
                        {restrictionsList.map((restriction, key) => 
                            <Row className="flex-nowrap" style={key === 0 ? { borderTop: '1px solid #787878' } : {}} key={key}>
                                <Col className={`${key === currentRow && selectedRateId === rateId ? 'inventorySelectedCollum start' : ''} ${lastKey === key ? '' : 'border-bottom'} col-3 py-2 border-right pl-2 position-sticky`} style={{ left: '0', zIndex: '2', background: 'white' }}>
                                    <Row>
                                        <Col className="col-1"/>
                                        <Col className="text-right">
                                            <FormattedMessage id={`ChannelBar.${restriction.name}`}/>
                                        </Col>
                                    </Row>
                                </Col>
                                <div className="IventoryDataScroll" onScroll={onScroll} style={{ width: '100%', display: 'flex', overflow: 'auto' }}>
                                    {next14Days && next14Days.map((item, k) => {
                                        const newDataDay = newData?.find(({day}) => item.isSame(moment(day), 'date'))??{};
                                        return (
                                            <EditableRestrictionBox
                                                rowkey={key}
                                                colkey={k}
                                                dragObj={dragObj}
                                                wasSaved={wasSaved}
                                                changeCol={changeCol}
                                                changeRow={this.changeRow}
                                                focusedInput={focusedInput}
                                                draggable={true}
                                                changeSelectedRate={changeSelectedRate}
                                                dragStart={dragStart}
                                                key={k}
                                                type={restriction.type}
                                                restriction={restriction.name}
                                                day={item}
                                                rateId={rateId}
                                                handleDataChange={handleDataChange}
                                                availability={availability}
                                                isLast={key === lastKey}
                                                isInvalid={newDataDay?.restrictions && newDataDay?.restrictions[0] && newDataDay?.restrictions[0][`isInvalid${restriction.name}`]}
                                                newData={newDataDay?.restrictions && newDataDay?.restrictions[0] && newDataDay?.restrictions[0][restriction.name]}
                                            />    
                                        );
                                    })}
                                </div>
                            </Row>
                        )}
                    </div>
                :
                    <div>
                        {restrictionsList.map((restriction, key) => 
                            <Row className="flex-nowrap IventoryDataScroll" style={key === 0 ? { borderTop: '1px solid #787878', overflow: 'auto' } : { overflow: 'auto' }} key={key} onScroll={onScroll}>
                                <Col className={`${key === currentRow && selectedRateId === rateId ? 'inventorySelectedCollum start' : ''} ${lastKey === key ? '' : 'border-bottom'} col-3 py-2 border-right pl-2`} style={{ left: '0', zIndex: '2', background: 'white' }}>
                                    <FormattedMessage id={`ChannelBar.${restriction.name}`}/>
                                </Col>
                                <div style={{ width: '100%', display: 'flex' }}>
                                    {next14Days && next14Days.map((item, k) => {
                                        const newDataDay = newData?.find(({day}) => item.isSame(moment(day), 'date'))??{};
                                        return (
                                            <EditableRestrictionBox
                                                rowkey={key}
                                                colkey={k}
                                                dragObj={dragObj}
                                                wasSaved={wasSaved}
                                                changeCol={changeCol}
                                                changeRow={this.changeRow}
                                                focusedInput={focusedInput}
                                                draggable={true}
                                                changeSelectedRate={changeSelectedRate}
                                                dragStart={dragStart}
                                                key={k}
                                                type={restriction.type}
                                                restriction={restriction.name}
                                                day={item}
                                                rateId={rateId}
                                                handleDataChange={handleDataChange}
                                                availability={availability}
                                                isLast={key === lastKey}
                                                isInvalid={newDataDay?.restrictions && newDataDay?.restrictions[0] && newDataDay?.restrictions[0][`isInvalid${restriction.name}`]}
                                                newData={newDataDay?.restrictions && newDataDay?.restrictions[0] && newDataDay?.restrictions[0][restriction.name]}
                                            />    
                                        );
                                    })}
                                </div>
                            </Row>
                        )}
                    </div>
        );
    }
};
