import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import BlockUi from 'react-block-ui'
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import { RoomCategoryImageManagement } from './RoomCategoryImageManagement';
import { RoomCategoryDescription } from './RoomCategoryDescription';
import RoomCategoryConfig from './RoomCategoryConfig';
import { Amenity } from '../Common/Amenity';
import "react-image-gallery/styles/css/image-gallery.css";
import '../../../../css/image-galery.css'
import { PolicyDescriptions } from './PolicyDescriptions';
import { handleNotification } from '../../../Base/Notification';
import { deleteAPI } from '../../../Base/API';
import Authorization from '../../../Base/Authorization';
import RoomCategoryBlocks from './RoomCategoryBlocks';

export class RoomCategorySetupDetails extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);

        this.state = {
            blocking: false,
            activeTab: '0',
            activeAirbnbTab: '1',
            activeAddressTab: '1',
            Map: [],
            roomCategoryDetails: {},
            roomAmenities: [],
            numberDataDif: 0,
            mapDataDif: 0,
            notification: { type: 'Success', message: '' },
            error: [],
            block: true,
            selectedRoom: this.props.selectedRoom,
            hasBE: false
        };
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({
                    hasBE: global.modules && global.modules.some(m => m === 'BookingEngine')
                });
            },
            global.modules ? 0 : 2500
        );
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedRoom !== this.props.selectedRoom) {
            this.setState({ selectedRoom: nextProps.selectedRoom })
        }
    }
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    
    toggleAirbnb(tab) {
        if (this.state.activeAirbnbTab !== tab) {
            if (this.state.activeAirbnbTabactiveAirbnbTab !== tab) {
                this.setState({
                    activeAirbnbTab: tab
                });
            }
        }
    }

    deleteRoom = (id) => {
        this.setState({ blocking: true });

        deleteAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blocking: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="RoomCategorySetupDetails.RoomCategoryDeleted" />, <FormattedMessage id="RoomCategorySetupDetails.Success" />);
                    this.props.removeRoom(id);
                    this.props.toggle();
                }

                this.setState({ blocking: false });
            }
        }, `/api/hotel/RoomCategory/v1/${id}`);
    }
    
    render() {
        const descriptionsUrl = `/api/hotel/RoomCategory/v1/${this.state.selectedRoom.id}/Language`;
        const descriptionsPolicyUrl = `/api/hotel/RoomCategory/v1/${this.state.selectedRoom.id}/Policy/Language`;
        const blocksUrl = `/api/Rate/Rate/v1/Block?roomCategoryId=${this.state.selectedRoom.id}`;
        const amenityUrl = `/api/hotel/RoomCategory/v1/${this.state.selectedRoom.id}/Amenities`;
        const amenityTitle = <strong>{this.state.selectedRoom.description} <FormattedMessage id="RoomCategorySetupDetails.Ameneties"/></strong>;
        const amenityTypes = [
            {
                title: <FormattedMessage id="Amenity.RMA"/>,
                url: "/api/hotel/ReferenceData/v1/RMA"
            },
            {
                title: <FormattedMessage id="Amenity.RVT"/>,
                url: "/api/hotel/ReferenceData/v1/RVT"
            },
            {
                title: <FormattedMessage id="Amenity.BED"/>,
                url: "/api/hotel/ReferenceData/v1/BED"
            }
        ];       

        const hideDetails = !this.props.creationMode && this.state.hasBE ? false : true;

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.blocking}>
                    <div className="d-flex align-items-center justify-content-between">
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '0' })} onClick={() => { this.toggle('0'); }}>
                                    <FormattedMessage id="RoomCategorySetupDetails.config" />
                                </NavLink>
                            </NavItem>
                            <NavItem hidden={hideDetails}>
                                <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                    <FormattedMessage id="RoomCategorySetupDetails.Description" /> 
                                </NavLink>
                            </NavItem>
                            <NavItem hidden={hideDetails}>
                                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                    <FormattedMessage id="RoomCategorySetupDetails.Ameneties" />                              
                                </NavLink>
                            </NavItem>
                            <NavItem hidden={hideDetails}>
                                <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                    <FormattedMessage id="RoomCategorySetupDetails.Image" /> 
                                </NavLink>
                            </NavItem>
                            <NavItem hidden={hideDetails}>
                                <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                                    <FormattedMessage id="RoomCategorySetupDetails.Policies" /> 
                                </NavLink>
                            </NavItem>
                            {!this.props.fromBEConfig ?
                                <NavItem hidden={hideDetails}>
                                    <NavLink className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                                        <FormattedMessage id="RoomCategorySetupDetails.Blocks" />
                                    </NavLink>
                                </NavItem>
                                : ''}
                        </Nav>


                        <div className=" mx-4 pr-2">
                            <Authorization
                                perform="roomCategory:delete"
                                yes={() => (
                                    <Button className=" btn-host btn-sm " onClick={() => this.deleteRoom(this.props.selectedRoom?.id)}>
                                        <i className="fas fa-trash-alt" />
                                    </Button>
                                )}
                                no={() => <div></div>}
                            />
                        </div>
                    </div>
                    <ErrorAlert error={this.state.error} />
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="0">
                            <RoomCategoryConfig selectedEntity={this.state.selectedRoom} creationMode={this.props.creationMode}
                                toggle={this.props.toggle} updateRoom={this.props.updateRoom} fromBEConfig={this.props.fromBEConfig}
                                hasBeModule={this.state.hasBE}
                            />
                        </TabPane>

                        { !this.props.creationMode && this.state.hasBE && this.props.modal ?
                            <>
                                <TabPane tabId="1">
                                    <RoomCategoryDescription selectedEntity={this.state.selectedRoom} entity={'RoomCategory'} url={descriptionsUrl} />
                                </TabPane>
                                <TabPane tabId="2">
                                    {
                                        this.state.selectedRoom ?
                                            <Amenity url={amenityUrl} title={amenityTitle} amenityTypes={amenityTypes} fromPropertyPage={false} />
                                            :
                                            ''
                                    }

                                </TabPane>
                                <TabPane tabId="3">
                                    {
                                        this.state.selectedRoom ?
                                            <RoomCategoryImageManagement selectedRoom={this.state.selectedRoom} />
                                            :
                                            ''
                                    }
                                </TabPane>
                                <TabPane tabId="4">
                                    <PolicyDescriptions policyType='RoomCategory' url={descriptionsPolicyUrl} helperText={<FormattedMessage id="RoomCategoryDescription.Help" />} helperTextId={'RoomCategoryPolicyHelp'} />
                                </TabPane>
                                {!this.props.fromBEConfig ?
                                    <TabPane tabId="5">
                                        <RoomCategoryBlocks
                                            url={blocksUrl}
                                            selectedRoomId={this.state.selectedRoom.id}
                                        />
                                    </TabPane>
                                : ''}
                            </>
                        : '' }
                    </TabContent>
                </BlockUi>
            </div>
        );
    }
}
