import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import CustomToolTip from '../../../Utils/CustomToolTip'
import { FormattedMessage, FormattedNumber } from 'react-intl'

export default class ComparePmsCellExample extends Component {

    render() {
        return (
            <Row id="comparecelltooltip" className="mb-1 mt-5 justify-content-center" >
                <Col className="col-2 p-0 ml-2 text-center">
                    <div className="calendar-border px-1 overflow-hidden  col"
                        style={{ border: '1px solid rgb(201, 222, 254)', height: '10vh', width: '10vh', backgroundColor: 'rgb(250, 252, 255)' }}>
                        <div className="w-100 h-100 d-flex align-items-center">
                            <div className="w-100 h-100">
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div id="exampleDay" style={{ fontSize: "0.8em", color: "rgb(49, 49, 49)" }}>
                                        13
                                    </div>
                                    <CustomToolTip placementPrefix="comparePmsCell white-bg bs-tooltip" placement="left" trigger={'focus'} autohide={false} defaultOpen={true} target={`exampleDay`}>
                                        <FormattedMessage id="BulkUpdate.Day" />
                                    </CustomToolTip>
                                    <div>
                                        <span id="exampleFreeRooms" className="mb-0 px-1" style={{ fontSize: "13px", color: "rgb(220, 53, 69)" }}>
                                            <b>5 | 4</b>
                                        </span>
                                        <CustomToolTip placementPrefix="comparePmsCell white-bg bs-tooltip" placement="top" trigger={'focus'} autohide={false} defaultOpen={true} target={`exampleFreeRooms`}>
                                            <FormattedMessage id="ComparePms.ExpAvail" />
                                        </CustomToolTip>
                                    </div>
                                </div>
                                <div className="text-center mt-2" style={{ width: "100%", fontSize: "13px", padding: "0.5vh 0px", alignItems: "center", justifyContent: "center" }}>
                                    <div className="mb-1" id="exampleOriginalValue" style={{ fontSize: "11px", color: "rgb(220, 53, 69)" }}>
                                        <span><FormattedNumber value={0.00} style="currency" currency={global.hotelCurrency} minimumFractionDigits={2} /> | <FormattedNumber value={90.00} style="currency" currency={global.hotelCurrency} minimumFractionDigits={2} /></span>
                                    </div>
                                    <CustomToolTip placementPrefix="comparePmsCell white-bg bs-tooltip" placement="bottom" trigger={'focus'} autohide={false} defaultOpen={true} target={`exampleOriginalValue`}>
                                        <FormattedMessage id="ComparePms.ExpPrice" />
                                    </CustomToolTip>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}
