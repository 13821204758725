import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import ReactHtmlParser from 'react-html-parser';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { getAPI } from "../../../Base/API";
import { StyledCard } from "../../../Base/Common/CommonUIComponents";
import CustomSelect from '../../../Base/Common/CustomSelect';
import { handleNotification } from "../../../Base/Notification";

 class NotificationTestEmail extends Component {

    state = {
        activeTab: '1',
        error: null,
        block: false,
        htmlResult: null,
        templateId :null,
        selectListEvents: []
    };



    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.form = React.createRef();
    }

    componentDidMount() {
        this.getEventTypes();
    }
     getEventTypes() {
         getAPI(result => {
             const { data, error } = result;

             if (error) {
                 var errorMessage = [];
                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errorMessage });

                 return;
             }
             if (data.response) {


                 let selectListEvents = [];
                 data.response.map((el, k) => {
                     selectListEvents.push({ 'value': el.id, "label": el.name });
                 })


                 this.setState({ eventTypeList: data.response, selectListEvents: selectListEvents, blockList: false });
             }
         }, '/api/notification/Template/v1/Configuration');


     }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
     setFilterSelectedChannel = (name, combo) => {
         var val = combo ? combo.value : null

         this.setState({ templateId : val});
     }

     renderTemplate(evt) {
         evt.preventDefault();
         if (!this.form.current.reportValidity()) {
             return;
         }
         this.setState({ block: true });
         let param = '';

         param += '?id=' + this.state.id;
         param += '&configId=' + this.state.templateId;

         getAPI(result => {
             const { data, error, isLoading } = result;
             this.setState({ block: isLoading });
             if (error) {
                 var errorMessage = [];
                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errorMessage, block: false });

                 return;
             }
             if (data) {
                 if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0) ) {
                     handleNotification(data, "", <FormattedMessage id="General.Success" />);
                     this.setState({ block: false });
                 }
                 else
                     this.setState({ templateList: data, block: false });
             }
         }, '/api/notification/Notification/v1/PreviewTemplate'+param);

         

     }

    render() {

        const helpContent =
            <div>
                TODO
                <hr />
            </div>

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'far fa-envelope'} title={'NotificationTestEmail.title'} help={helpContent}>
                <form ref={this.form}>
                <Row>
                    <Col className="col-5">
                        <FormGroup row>
                            <Label for="name" sm={4}><FormattedMessage id="NotificationTestEmail.id" /></Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="NotificationTestEmail.id">{placeholder =>
                                        <Input name="mailToUse" value={this.state.id} required placeholder={placeholder} onChange={(evt) => this.setState({ id: evt.target.value })} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-5">
                        <BlockUi tag="div" blocking={this.state.blockList}>
                            <FormGroup row>
                                    <Label for="name" sm={4}><FormattedMessage id="NotificationTestEmail.template" /></Label>
                                <Col sm={8}>
                                        <CustomSelect required={true} placeholder={<FormattedMessage id="NotificationTestEmail.template" />} value={this.state.selectListEvents.find(el => el.value === this.state.template)} options={this.state.selectListEvents} name="templateId" onChange={this.setFilterSelectedChannel.bind(this, 'templateId')} />
                                </Col>
                            </FormGroup>
                        </BlockUi>
                    </Col>
                    <Col className="col-2">
                        <Button className="btn btn-sm btn-host mr-1 float-right" onClick={this.renderTemplate.bind(this)}><i className="far fa-eye"></i></Button>
                    </Col>
                </Row>
                    <Row>
                        <Col>
                            <i class="fas fa-envelope-open-text"></i> Result : 
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {this.state.templateList ?  ReactHtmlParser(this.state.templateList.notifyHtml) : ''}
                    </Col>
                    </Row>
                </form>
            </StyledCard>


        );
    }
}
export default injectIntl(NotificationTestEmail)