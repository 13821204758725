import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row, Card, Badge } from 'reactstrap';
import { getAPI } from "../Base/API";
import { ErrorAlert } from "../Common/ErrorAlert";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import countryList from "react-select-country-list";
import ChartCard from "./Common/ChartCard";
import Chart from "react-google-charts";
import moment from 'moment';
import { handleNotification } from "../Base/Notification";
import { Bar } from 'react-chartjs-2';

class Reservations extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: false,
            nightsByRate: [],
            nightsByRoom: [],
            nightsByCountry: [],
            totalReservationsByRate: [],
            totalReservationsByRoom: [],
            totalRevenueByRate: [],
            totalRevenueByRoom: [],
            averageReservationByRate: [],
            averageReservationByRoom: [],
            averageRevenueByRate: JSON.parse('{ "datasets": [{ "label": "", "type": "line", "fill": "false", "data": [], "borderColor": "#00d6ff", "backgroundColor": "#00d6ff33", "borderWidth": "1", "yAxisID": "count" },{ "label": "", "type": "bar", "data": [], "borderColor": "#004c6d", "backgroundColor": "#004c6d20", "borderWidth": "1", "yAxisID": "rev" }],"labels": []}'),
            averageRevenueByRoom: JSON.parse('{ "datasets": [{ "label": "", "type": "line", "fill": "false", "data": [], "borderColor": "#00d6ff", "backgroundColor": "#00d6ff33", "borderWidth": "1", "yAxisID": "count" },{ "label": "", "type": "bar", "data": [], "borderColor": "#004c6d", "backgroundColor": "#004c6d20", "borderWidth": "1", "yAxisID": "rev" }],"labels": []}'),
            nightsByRateFiltered: [],
            nightsByRoomFiltered: [],
            nightsByCountryFiltered: [],
            totalReservationsByRateFiltered: [],
            totalReservationsByRoomFiltered: [],
            totalRevenueByRateFiltered: [],
            totalRevenueByRoomFiltered: [],
            averageReservationByRateFiltered: [],
            averageReservationByRoomFiltered: [],
            averageRevenueByRateFiltered: JSON.parse('{ "datasets": [{ "label": "", "type": "line", "fill": "false", "data": [], "borderColor": "#00d6ff", "backgroundColor": "#00d6ff33", "borderWidth": "1", "yAxisID": "count" },{ "label": "", "type": "bar", "data": [], "borderColor": "#004c6d", "backgroundColor": "#004c6d20", "borderWidth": "1", "yAxisID": "rev" }],"labels": []}'),
            averageRevenueByRoomFiltered: JSON.parse('{ "datasets": [{ "label": "", "type": "line", "fill": "false", "data": [], "borderColor": "#00d6ff", "backgroundColor": "#00d6ff33", "borderWidth": "1", "yAxisID": "count" },{ "label": "", "type": "bar", "data": [], "borderColor": "#004c6d", "backgroundColor": "#004c6d20", "borderWidth": "1", "yAxisID": "rev" }],"labels": []}'),
            generalData: [],
            filtersToApply: [],
            totalNights: 0,
            totalReservations: 0,
            totalRevenue: 0,
            countryList: countryList()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.search === true && nextProps.search !== this.props.search) {
            this.getPieData();
        }
    }

    componentDidMount() {
        this.getPieData();
    }

    getPieData = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if(data.response && data.response.length > 0){
                        this.parseData(data);
                    }else{
                        handleNotification(data)
                        this.setState({ block: false });
                    }
                }
            }, `/api/Price/Analytics/v1?fromDate=${moment(this.props.startDate).format("YYYY-MM-DD")}&toDate=${moment(this.props.endDate).format("YYYY-MM-DD")}&dataKeys=1&dataKeys=5&dataKeys=6&dataKeys=8&dataKeys=10&dataKeys=11&dataKeys=12`);
        });
    }

    parseData = (data) => {
        const { colorPalette } = this.props;

        let generalData = [];

        let nightsByRoom = [['RoomCategory', 'Nights']];
        let nightsByRate = [['Rate', 'Nights']];
        let nightsByCountry = [['Country', 'Nights']];

        let totalReservationsByRate = [['Rate', 'Reservations']];
        let totalReservationsByRoom = [['RoomCategory', 'Reservations']];

        let averageReservationByRate = [['Rate', 'Reservations', { role: 'style' }]];
        let averageReservationByRoom = [['RoomCategory', 'Reservations', { role: 'style' }]];

        let totalRevenueByRate = [['Rate', 'Revenue']];
        let totalRevenueByRoom = [['RoomCategory', 'Revenue']];
        
        let totalReservations = 0;
        let totalRevenue = 0;
        let totalNights = 0;

        data.response && data.response.forEach(date => {
            date && date.subset && date.subset.forEach((rate, rIndex) => {
                let countNights = 0;
                let countReservations = 0;
                let countRevenue = 0;
                rate && rate.subset && rate.subset.forEach(room => {
                    room && room.subset && room.subset.forEach(country => {
                        country && country.subset && country.subset.forEach(total => {
                            if(total.key === 'TotalRoomNights'){
                                totalNights += total.value;
                                countNights += total.value;
                            }
                            if(total.key === 'TotalReservations'){
                                totalReservations += total.value;
                                countReservations += total.value;
                            }
                            if(total.key === 'TotalRevenue'){
                                totalRevenue += total.value;
                                countRevenue += total.value;
                            }
                        });
                        
                        let countryLabel = null;
                        let countryCode = null;
        
                        if (country && country.value) {
                            countryLabel = this.state.countryList.getLabel(country.value);
                            countryCode = country.value;
                        }
        
                        const rateIndex = nightsByRate.findIndex(el => el[0] === rate.value);
                        const roomIndex = nightsByRoom.findIndex(el => el[0] === room.value);
                        const countryIndex = nightsByCountry.findIndex(el => el[0] && typeof el[0] === 'object' ? el[0]?.v === countryCode?.toLowerCase() : false);
                        const generalDataIndex = generalData.findIndex(el => el.rate === rate.value);
        
                        if (rateIndex > -1) {
                            nightsByRate[rateIndex][1] += countNights;
                            totalReservationsByRate[rateIndex][1] += countReservations;
                            totalRevenueByRate[rateIndex][1] += countRevenue;
                        }
                        else {
                            nightsByRate.push([!rate.isXP ? rate.value : rate.value + ` (${this.props.intl.formatMessage({ id: "BEAnalitics.Experience" })})`, countNights]);
                            totalReservationsByRate.push([!rate.isXP ? rate.value : rate.value + ` (${this.props.intl.formatMessage({ id: "BEAnalitics.Experience" })})`, countReservations]);
                            totalRevenueByRate.push([!rate.isXP ? rate.value : rate.value + ` (${this.props.intl.formatMessage({ id: "BEAnalitics.Experience" })})`, countRevenue]);

                            averageReservationByRate.push([
                                !rate.isXP ? rate.value : rate.value + ` (${this.props.intl.formatMessage({ id: "BEAnalitics.Experience" })})`,
                                0,
                                rIndex < colorPalette.length ? colorPalette[rIndex] : null
                            ]);
                        }
        
                        if (roomIndex > -1) {
                            nightsByRoom[roomIndex][1] += countNights;
                            totalReservationsByRoom[roomIndex][1] += countReservations;
                            totalRevenueByRoom[roomIndex][1] += countRevenue;
                        }
                        else {
                            nightsByRoom.push([room.value, countNights]);
                            totalReservationsByRoom.push([room.value, countReservations]);
                            totalRevenueByRoom.push([room.value, countRevenue]);

                            averageReservationByRoom.push([room.value, 0, rIndex < colorPalette.length ? colorPalette[rIndex] : null]);
                        }
        
                        if (countryIndex > -1) {
                            nightsByCountry[countryIndex][1] += countNights;
                        }
                        else {
                            nightsByCountry.push([{ v: countryCode?.toLowerCase(), f: countryLabel }, countNights]);
                        }
                        
                        if (generalDataIndex > -1) {
                            if(generalData && generalData[generalDataIndex] && generalData[generalDataIndex].roomCategories && !generalData[generalDataIndex].roomCategories.includes(room.value)){
                                generalData[generalDataIndex].roomCategories.push(room.value);
                            }

                            if(generalData && generalData[generalDataIndex] && generalData[generalDataIndex].countries && !generalData[generalDataIndex].countries.includes(countryCode?.toLowerCase())){
                                generalData[generalDataIndex].countries.push(countryCode?.toLowerCase());
                            }
                        }
                        else {
                            generalData.push({rate: !rate.isXP ? rate.value : rate.value + ` (${this.props.intl.formatMessage({ id: "BEAnalitics.Experience" })})`, roomCategories: [room.value], countries: [countryCode?.toLowerCase()]});
                        }
                    })
                })
            });
        });

        nightsByCountry = nightsByCountry.sort((a, b) => b[1] - a[1]);

        const { averageRevenueByRate, averageRevenueByRoom } = this.state;

        totalReservationsByRate && totalReservationsByRate.forEach((rate, rateIndex) => {
            if(rateIndex > 0){
                if(rate && !rate[0]){
                    rate[0] = this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" });
                    nightsByRate[rateIndex][0] = this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" });
                    averageReservationByRate[rateIndex][0] = this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" });
                    
                    totalRevenueByRate[rateIndex][0] = this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" });
                }

                averageReservationByRate[rateIndex][1] = parseFloat((totalReservationsByRate[rateIndex][1] / data.response.length)?.toFixed(2));

                averageRevenueByRate.datasets[0].data.push(totalReservationsByRate[rateIndex][1]);
                averageRevenueByRate.datasets[0].label = this.props.intl.formatMessage({ id: "home.reservation" });
                averageRevenueByRate.datasets[0].backgroundColor = colorPalette[0];
                averageRevenueByRate.datasets[1].data.push((totalRevenueByRate[rateIndex][1] / totalReservationsByRate[rateIndex][1])?.toFixed(2));
                averageRevenueByRate.datasets[1].label = this.props.intl.formatMessage({ id: "home.revenue" });
                averageRevenueByRate.datasets[1].backgroundColor = colorPalette[5];

                averageRevenueByRate.labels.push(rate[0]);
            }
        });
        
        totalReservationsByRoom && totalReservationsByRoom.forEach((room, roomIndex) => {
            if(roomIndex > 0){
                if(room && !room[0]){
                    room[0] = this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" });
                    nightsByRoom[roomIndex][0] = this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" });
                    averageReservationByRoom[roomIndex][0] = this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" });
                    
                    totalRevenueByRoom[roomIndex][0] = this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" });
                }

                averageReservationByRoom[roomIndex][1] = parseFloat((totalReservationsByRoom[roomIndex][1] / data.response.length)?.toFixed(2));

                averageRevenueByRoom.datasets[0].data.push(totalReservationsByRoom[roomIndex][1]);
                averageRevenueByRoom.datasets[0].label = this.props.intl.formatMessage({ id: "home.reservation" });
                averageRevenueByRoom.datasets[0].backgroundColor = colorPalette[0];
                averageRevenueByRoom.datasets[1].data.push((totalRevenueByRoom[roomIndex][1] / totalReservationsByRoom[roomIndex][1])?.toFixed(2));
                averageRevenueByRoom.datasets[1].label = this.props.intl.formatMessage({ id: "home.revenue" });
                averageRevenueByRoom.datasets[1].backgroundColor = colorPalette[5];

                averageRevenueByRoom.labels.push(room[0]);
            }
        });

        if(totalReservations){
            totalReservations = parseFloat(totalReservations.toFixed(2));
        }

        if(totalRevenue){
            totalRevenue = parseFloat(totalRevenue.toFixed(2));
        }
        
        if(totalNights){
            totalNights = parseFloat(totalNights.toFixed(2));
        }

        this.setState({ 
            nightsByRate, 
            nightsByRoom, 
            nightsByCountry, 
            totalReservationsByRate,
            totalReservationsByRoom,
            averageReservationByRate,
            averageReservationByRoom,
            totalRevenueByRate,
            totalRevenueByRoom,
            averageRevenueByRate,
            averageRevenueByRoom,
            generalData,
            totalReservations, 
            totalRevenue, 
            totalNights
        }, () => this.filterData(null, null));
    }

    handleLegendClick = (chartWrapper, type) => {
        if (!chartWrapper) return;
        
        const chart = chartWrapper.getChart();
        const selection = chart.getSelection();
        if (selection.length === 0) return;
    
        const selectedItem = selection[0];
        const dataTable = chartWrapper.getDataTable();
        if(!dataTable) return;
        
        const legendItem = dataTable.getValue(selectedItem.row, 0);
        const value = legendItem;
    
        this.filterData(type, value);
    };
    
    filterData = (type, value) => {
        let { generalData, filtersToApply } = this.state;
        let generalDataFiltered = generalData;

        let nightsByRateFiltered = [['Rate', 'Nights']];
        let nightsByRoomFiltered = [['RoomCategory', 'Nights']];
        let nightsByCountryFiltered = [['Country', 'Nights']];

        let totalReservationsByRateFiltered = [['Rate', 'Reservations']];
        let totalReservationsByRoomFiltered = [['RoomCategory', 'Reservations']];

        let averageReservationByRateFiltered = [['Rate', 'Reservations', { role: 'style' }]];
        let averageReservationByRoomFiltered = [['RoomCategory', 'Reservations', { role: 'style' }]];

        let totalRevenueByRateFiltered = [['Rate', 'Revenue']];
        let totalRevenueByRoomFiltered = [['RoomCategory', 'Revenue']];

        if (filtersToApply && !filtersToApply.some(el => el.type === type && el.value === value) && type !== null ) {
          filtersToApply.push({ type, value });
        }else{
            filtersToApply = filtersToApply.filter(el => el.type !== type && el.value !== value);
        }

        if(filtersToApply && filtersToApply.length > 0){
            filtersToApply.forEach(el => {
                generalDataFiltered = generalDataFiltered.filter(data => {
                    if (el.type === 'rate') {
                        return (data.rate === el.value) || (!data.rate && el.value === this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" }));
                    } else if (el.type === 'room') {
                        if(el.value === this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" })){
                            return data.roomCategories.includes(null);
                        }else{
                            return data.roomCategories.includes(el.value);
                        }
                    } else if (el.type === 'country') {
                        return data.countries.includes(el.value);
                    }
                });

                if(el.type === 'country'){
                    this.state.nightsByCountry.filter(data => data[0].v === el.value && typeof data[0] !== 'string').forEach(data => {
                        nightsByCountryFiltered.push(data);
                    });
                }
            });

            generalDataFiltered && generalDataFiltered.forEach(el => {
                if (this.state.nightsByRate) {
                    this.state.nightsByRate.filter(data => data[0] === el.rate || (data[0] === this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" }) && !el.rate)).forEach(data => {
                        nightsByRateFiltered.push(data);
                    });
                }
        
                if (this.state.totalReservationsByRate) {
                    this.state.totalReservationsByRate.filter(data => data[0] === el.rate || (data[0] === this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" }) && !el.rate)).forEach(data => {
                        totalReservationsByRateFiltered.push(data);
                    });
                }
        
                if (this.state.averageReservationByRate) {
                    this.state.averageReservationByRate.filter(data => data[0] === el.rate || (data[0] === this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" }) && !el.rate)).forEach(data => {
                        averageReservationByRateFiltered.push(data);
                    });
                }
        
                if (this.state.totalRevenueByRate) {
                    this.state.totalRevenueByRate.filter(data => data[0] === el.rate || (data[0] === this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" }) && !el.rate)).forEach(data => {
                        totalRevenueByRateFiltered.push(data);
                    });
                }
        
                if (el.roomCategories) {
                    el.roomCategories.forEach(room => {
                        if (this.state.nightsByRoom) {
                            this.state.nightsByRoom.filter(data => data[0] === room || (!room && data[0] === this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" }))).forEach(data => {
                                if(!nightsByRoomFiltered.some(el => el[0] === room)){
                                    nightsByRoomFiltered.push(data);
                                }
                            });
                        }
        
                        if (this.state.totalReservationsByRoom) {
                            this.state.totalReservationsByRoom.filter(data => data[0] === room || (!room && data[0] === this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" }))).forEach(data => {
                                if(!totalReservationsByRoomFiltered.some(el => el[0] === room)){
                                    totalReservationsByRoomFiltered.push(data);
                                }
                            });
                        }
        
                        if (this.state.averageReservationByRoom) {
                            this.state.averageReservationByRoom.filter(data => data[0] === room || (!room && data[0] === this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" }))).forEach(data => {
                                if(!averageReservationByRoomFiltered.some(el => el[0] === room)){
                                    averageReservationByRoomFiltered.push(data);
                                }
                            });
                        }
        
                        if (this.state.totalRevenueByRoom) {
                            this.state.totalRevenueByRoom.filter(data => data[0] === room || (!room && data[0] === this.props.intl.formatMessage({ id: "BEAnalitics.NotSet" }))).forEach(data => {
                                if(!totalRevenueByRoomFiltered.some(el => el[0] === room)){
                                    totalRevenueByRoomFiltered.push(data);
                                }
                            });
                        }
                    });
                }
                
                if (!(filtersToApply.some(obj => obj.type === 'country')) && el.countries) {
                    el.countries.forEach(country => {
                        if (this.state.nightsByCountry) {
                            this.state.nightsByCountry.filter(data => data[0].v === country && typeof data[0] !== 'string').forEach(data => {
                                if(!nightsByCountryFiltered.some(c => (c[0].v === country) && (c[0].f === data[0].f))){
                                    nightsByCountryFiltered.push(data);
                                }
                            });
                        }
                    });
                }
            });
        }
        else {
            nightsByRateFiltered = this.state.nightsByRate;
            nightsByRoomFiltered = this.state.nightsByRoom;
            nightsByCountryFiltered = this.state.nightsByCountry;

            totalReservationsByRateFiltered = this.state.totalReservationsByRate;
            totalReservationsByRoomFiltered = this.state.totalReservationsByRoom;
            averageReservationByRateFiltered = this.state.averageReservationByRate;
            averageReservationByRoomFiltered = this.state.averageReservationByRoom;
            totalRevenueByRateFiltered = this.state.totalRevenueByRate;
            totalRevenueByRoomFiltered = this.state.totalRevenueByRoom;
        }

        const { averageRevenueByRate, averageRevenueByRoom } = this.state;

        this.setState({
            filtersToApply,
            nightsByRateFiltered,
            nightsByRoomFiltered,
            nightsByCountryFiltered,
            totalReservationsByRateFiltered,
            totalReservationsByRoomFiltered,
            averageReservationByRateFiltered,
            averageReservationByRoomFiltered,
            totalRevenueByRateFiltered,
            totalRevenueByRoomFiltered,
            averageRevenueByRateFiltered: averageRevenueByRate,
            averageRevenueByRoomFiltered: averageRevenueByRoom,
            block: false
        });
    };

    render() {
        const { block, totalNights, totalReservations, totalRevenue, filtersToApply } = this.state;
        const { valuesCard } = this.props;
        //Falta gráfico com promotions e up/cross sell

        var worldMapOptions = {
            region: this.state.region,
            colorAxis: { colors: ['#00c4b3', '#007bff'] }
        };

        const pieOptions = {
            pieHole: 0.6,
            pieSliceText: 'none',
            slices: [{ color: '#12b5cb' }, { color: '#ffb703' }, { color: '#7dcfb6' }, { color: '#023047' }, { color: '#fb8500' }]
        };

        const averageGraphicOptions = {
            responsive: true,
            tooltips: {
                mode: 'label'
            },
            legend: {
                display: true,
                position: "top"
            },
            scales: {
                xAxes: [{
                    display: true,
                    ticks: { beginAtZero: true, min: 0 }
                }],
                yAxes: [
                    {
                        type: 'linear',
                        ticks: { beginAtZero: true, precision: 0, min: 0 },
                        display: true,
                        position: 'right',
                        id: 'count',
                        gridLines: {
                            display: false
                        },
                        scaleLabel: {
                            display: true,
                            labelString: `# ${this.props.intl.formatMessage({ id: "home.reservation" })}`
                        }
                    },
                    {
                        ticks: { beginAtZero: true, min: 0 },
                        type: 'linear',
                        display: true,
                        position: 'left',
                        id: 'rev',
                        gridLines: {
                            display: false
                        },
                        labels: {
                            show: true
                        },
                        scaleLabel: {
                            display: true,
                            labelString: this.props.intl.formatMessage({ id: "home.revenue" })
                        }
                    }
                ]
            }
        };
        
        const chartEventsRate = [
            {
                eventName: 'select',
                callback: ({ chartWrapper }) => {
                    this.handleLegendClick(chartWrapper, 'rate');
                },
            },
        ];

        const chartEventsRoomCat = [
            {
                eventName: 'select',
                callback: ({ chartWrapper }) => {
                    this.handleLegendClick(chartWrapper, 'room');
                },
            },
        ];
                
        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={this.state.error} />
                {filtersToApply && filtersToApply.length > 0 ?
                    <Row className="p-3 d-flex align-items-center">
                        {filtersToApply.map((filter, k) =>
                            <Badge key={k} color="primary" className=' d-flex align-items-center mr-2 p-2'>
                                <b className='pr-2'><FormattedMessage id={`BEAnalitics.${filter.type}`} />{filter.value ? ':' : ''}</b>
                                {filter.type === 'country' && filter.value?
                                    <span className={'mr-2 fa-md flag-icon flag-icon-' + filter.value} />
                                : 
                                    <span className='mr-2'>{filter.value ?? <FormattedMessage id="BEAnalitics.NotDefined" />}</span>
                                }

                                <i class="far fa-times-circle pointer" onClick={() => this.filterData(filter.type, filter.value)}></i>
                            </Badge>
                        )}
                    </Row>
                :""}
                <Row className='py-3'>
                    <Col>
                        {valuesCard(<FormattedMessage id="BEAnalitics.TotalReservations" />, `${totalReservations ?? '-'}`)}
                    </Col>
                    <Col>
                        {valuesCard(<FormattedMessage id="BEAnalitics.TotalRevenue" />, `${totalRevenue || totalRevenue === 0 ? totalRevenue+'€' : '-'}`)}
                    </Col>
                    <Col>
                        {valuesCard(<FormattedMessage id="BEAnalitics.TotalNights" />, `${totalNights ?? '-'}`)}
                    </Col>
                </Row>
                {this.state.nightsByCountryFiltered ?
                    <Row>
                        <Col>
                            <Card body className="shadow border-0 h-100">
                                <span className="text-xs font-weight-bold text-secondary text-uppercase mb-1"> <FormattedMessage id="BEAnalitics.NightsPerCountry" /> </span>
                                <Row>
                                    <Col className={'col-8'}>
                                        {this.state.nightsByCountryFiltered && this.state.nightsByCountryFiltered.length > 0 ?
                                            <Chart
                                                chartType={'GeoChart'}
                                                width="100%"
                                                height={'350px'}
                                                data={this.state.nightsByCountryFiltered}
                                                options={worldMapOptions}
                                            />
                                        :''}
                                    </Col>

                                    <Col className="col-4">
                                        <Row>
                                            <Col> <FormattedMessage id="BEAnalitics.Country" /> </Col>
                                            <Col> <FormattedMessage id="BEAnalitics.Users" /> </Col>
                                        </Row>
                                        <hr className="my-2" />
                                        <div className="VerticalscrollableDiv beListByCountry">

                                            {this.state.nightsByCountryFiltered && this.state.nightsByCountryFiltered.length > 0 ? 
                                                this.state.nightsByCountryFiltered.map((arr, key) => {
                                                    const country = arr[0] ? arr[0].f : null;

                                                    return key > 0 ?
                                                        <Row className="mb-2 overbglight" key={key}>
                                                            <Col className='pointer' onClick={() => this.filterData('country', arr[0].v)}>
                                                                {arr && arr[0] && arr[0].v && arr[0].f ?
                                                                    <span>
                                                                        <span className={' mb-1 mr-1 flag-icon flag-icon-' + (arr[0].v)} />
                                                                        {country ? country : arr[0].f}
                                                                    </span>
                                                                :
                                                                    <FormattedMessage id="BEAnalitics.NotDefined" />
                                                                }
                                                            </Col>
                                                            <Col>
                                                                {arr[1]}
                                                            </Col>
                                                        </Row>
                                                        : ''
                                                })
                                            : ''}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                : ''}
               
                <Row className="py-3">
                    <Col className="col-4">
                        {
                            this.state.totalReservationsByRoomFiltered && this.state.totalReservationsByRoomFiltered.length > 1 ?
                                <ChartCard
                                    chartType='PieChart'
                                    data={this.state.totalReservationsByRoomFiltered}
                                    options={pieOptions}
                                    title={<FormattedMessage id="BEAnalitics.ReservationsPerRoomCategory" />}
                                    chartEvent={chartEventsRoomCat}
                                />
                                :
                                ''
                        }
                    </Col>
                    <Col className="col-4">
                        {
                            this.state.nightsByRoomFiltered && this.state.nightsByRoomFiltered.length > 1 ?
                                <ChartCard
                                    chartType='PieChart'
                                    data={this.state.nightsByRoomFiltered}
                                    options={pieOptions}
                                    title={<FormattedMessage id="BEAnalitics.NightsPerRoomCategory" />}
                                    chartEvent={chartEventsRoomCat}
                                />
                                :
                                ''
                        }
                    </Col>
                    <Col className="col-4">
                        {
                            this.state.totalRevenueByRoomFiltered && this.state.totalRevenueByRoomFiltered.length > 1 ?
                                <ChartCard
                                    chartType='PieChart'
                                    data={this.state.totalRevenueByRoomFiltered}
                                    options={pieOptions}
                                    title={<FormattedMessage id="BEAnalitics.RevenuePerRoomCategory" />}
                                    chartEvent={chartEventsRoomCat}
                                />
                                :
                                ''
                        }
                    </Col>
                </Row>
                <Row className="py-3">
                    <Col className="col-4">
                        {
                            this.state.totalReservationsByRateFiltered && this.state.totalReservationsByRateFiltered.length > 1 ?
                                <ChartCard
                                    chartType='PieChart'
                                    data={this.state.totalReservationsByRateFiltered}
                                    options={pieOptions}
                                    title={<FormattedMessage id="BEAnalitics.ReservationsPerRate" />}
                                    chartEvent={chartEventsRate}
                                />
                                :
                                ''
                        }
                    </Col>
                    <Col className="col-4">
                        {
                            this.state.nightsByRateFiltered && this.state.nightsByRateFiltered.length > 1 ?
                                <ChartCard
                                    chartType='PieChart'
                                    data={this.state.nightsByRateFiltered}
                                    options={pieOptions}
                                    title={<FormattedMessage id="BEAnalitics.NightsPerRate" />}
                                    chartEvent={chartEventsRate}
                                />
                                :
                                ''
                        }
                    </Col>
                    <Col className="col-4">
                        {
                            this.state.totalRevenueByRateFiltered && this.state.totalRevenueByRateFiltered.length > 1 ?
                                <ChartCard
                                    chartType='PieChart'
                                    data={this.state.totalRevenueByRateFiltered}
                                    options={pieOptions}
                                    title={<FormattedMessage id="BEAnalitics.RevenuePerRate" />}
                                    chartEvent={chartEventsRate}
                                />
                                :
                                ''
                        }
                    </Col>
                </Row>
                <Row className="py-3">
                    <Col className="col-6">
                        <Card body className="shadow border-0 h-100">
                            <Row>
                                <Col className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                    <FormattedMessage id="BEAnalitics.AverageRevenuePerRoomCategory" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Bar data={this.state.averageRevenueByRoomFiltered} options={averageGraphicOptions} height={75} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="col-6">
                        <Card body className="shadow border-0 h-100">
                            <Row>
                                <Col className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                    <FormattedMessage id="BEAnalitics.AverageRevenuePerRate" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Bar data={this.state.averageRevenueByRateFiltered} options={averageGraphicOptions} height={75} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </BlockUi>
        )
    }
}

export default injectIntl(Reservations);
