import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button, Input, Form } from 'reactstrap';
import { postAPI, getAPI } from '../../Base/API';
import { StyledCard, BlankCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Notification";
import Authorization from '../../Base/Authorization';
import { FormattedMessage } from 'react-intl';

class HotelGroup extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            hoteList: [],
            selectedHotel: {},
            currentPage: 0,
            resultsPerPage: 1000,
            totalResults: 0
        }
    }

    componentDidMount() {
        this.getHotels();
    }

    getHotels = () => {
        const { currentPage, resultsPerPage } = this.state;
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ hoteList: data.data, totalResults: data.count, error: errorMessage, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/hotel/HotelGroup/v1?pageSize=${resultsPerPage}&pageIndex=${currentPage}`);
    }

    saveHotelGroup = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const req = {
            name: this.state.selectedHotel.name,
            hotelGroupId: this.state.selectedHotel.id
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="HotelGroup.HotelGroupSaved" />, <FormattedMessage id="General.Success" />);
                this.setState({ modal : false }, () => this.getHotels())
            }
        }, '/api/hotel/HotelGroup/v1/HotelGroup', req);
    }   

    toggleModal = (hotelGroup) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedHotel: hotelGroup
        }));
    }

    updateForm(f, evt) {

        let name = evt.target.name;
        let value = evt.target.value;

        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value

            },
        }));
    }  
 
    render() {
        const { block, error, hoteList, currentPage, resultsPerPage, totalResults } = this.state;

        const columns = [
            {
                dataField: 'id',
                text: <FormattedMessage id="HotelGroup.HotelGroupId" />,
                sort: true
            },
            {
                dataField: 'name',
                text: <FormattedMessage id="HotelGroup.Name" />,
                sort: true
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({modal : true, selectedHotel : row})
            }
        };

        const help = <div> </div>

        return (
            <StyledCard icon={'fas fa-hotel'} title={'HotelGroup.HotelGroup'} help={help} error={error} block={block}>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-xl" >
                    <ModalHeader toggle={this.toggleModal}>
                        <FormattedMessage id="HotelGroup.HotelGroup" />
                    </ModalHeader>
                    <ModalBody className="modal-xl p-0">                        
                        <BlankCard>
                            <Form onSubmit={this.saveHotelGroup}>
                                <Row className="mb-2">
                                    <Col className="text-right">
                                        <Authorization
                                            perform="hotelGroup:save"
                                            yes={() => (
                                                <Button className="btn-sm btn-host" type="submit">
                                                    <i className="fas fa-save" />
                                                </Button>
                                            )}
                                            no={() => <div></div>}
                                        />    
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-2"><FormattedMessage id="HotelGroup.Name" /></Col>
                                    <Col className="col-4">
                                        <FormattedMessage id="HotelGroup.Name" >
                                        {
                                                placeholder => <Input minLength="3" maxLength="100" placeholder={placeholder} value={this.state.selectedHotel && this.state.selectedHotel.name ? this.state.selectedHotel.name : ''} onChange={this.updateForm.bind(this, 'selectedHotel')} name="name" required></Input>
                                        }
                                        </FormattedMessage>
                                    </Col> 
                                    <Col className="col-2"><FormattedMessage id="HotelGroup.HotelGroupId" /></Col>
                                    <Col className="col-4">
                                        <FormattedMessage id="HotelGroup.HotelGroupId" >
                                            {
                                                placeholder => <Input pattern="^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$" name="hotelGroupId" value={this.state.selectedHotel && this.state.selectedHotel.id ? this.state.selectedHotel.id : ''} disabled={true} placeholder={placeholder} required></Input>
                                            }
                                        </FormattedMessage>
                                    </Col>                                   
                                </Row>
                            </Form>
                        </BlankCard>
                    </ModalBody>
                </Modal>

                <Row className="mb-2">
                    <Col className="text-right">
                        <Authorization
                            perform="hotelGroup:add"
                            yes={() => (
                                <Button className="btn-sm btn-host" onClick={() => this.toggleModal(null)}>
                                    <i className="fas fa-plus"></i>
                                </Button>
                            )}
                            no={() => <div></div>}
                        />                        
                    </Col>
                </Row>

                <CustomTable
                    data={hoteList ? hoteList : []}
                    columns={columns}
                    shadow={false}
                    exportCSV={false}
                    showTotal={true}
                    remote={false}
                    search={true}
                    selectRow={selectRow}
                    hideSizePerPage={false}
                    totalSize={totalResults}
                />
            </StyledCard>
        );
    }
}

export default HotelGroup;