import React, { Component } from 'react';
import { Row, Col, Input, Button, Collapse, FormGroup, CardBody, Card } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI } from "./Base/API";
import ReactHtmlParser from 'react-html-parser';
import { BlankCard } from "./Base/Common/CommonUIComponents";
import { createBrowserHistory } from "history";
import { Link } from 'react-router-dom';
import CustomToolTip from '../Utils/CustomToolTip';

export class SearchButton extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.state = {
            isOpen: false,
            searchquery: null,
            result: null,
            totalPage: 3,
            page: 0,
            maxPage: 0,
            defaultProperty: '',
        }
    }

    toggle() {
        this.setState(prevstate => ({
            isOpen: !prevstate.isOpen,
            result: null,
            page: 0
        }));
    } 

    doSearch() {
        const { searchquery, totalPage } = this.state;
        if (searchquery !== null && searchquery.length > 0) {
            this.setState({ block: true });
            getAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger"
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    const maxPage = data.results ? (data.results.length % totalPage === 0 ? data.results.length / totalPage : parseInt(data.results.length / totalPage)) : 0;
                    this.setState({ result: data, page: 0, maxPage: maxPage, block: false });
                }
            }, `/wiki/rest/api/search?cql=text~"${searchquery}"+and+type=page+and+space=CM`);
        }
    }

    nextPage() {
        const page = this.state.page + 1;
        this.setState({ page });
    }

    prevPage() {
        const page = this.state.page - 1;
        this.setState({ page });
    }

    render() {
        const begin = this.state.page * this.state.totalPage;
        const end = begin + this.state.totalPage;

        const pathnames = [
            { path: '/Inventory', category: 'InventoryRates' },
            { path: '/PromotionDashboard', category: 'InventoryRates' },
            { path: '/ChannelDashboard', category: 'Channels' },
            { path: '/LogsDashboard', category: 'Channels' },
            { path: '/ReservationDashboard', category: 'Reservations' },
            { path: '/ReservationList', category: 'Reservations' },
            { path: '/MailDashboard', category: 'Extras' },
            { path: '/OnlineRatesDashboard', category: 'Extras' },
            { path: '/OportunityDashboard', category: 'Extras' },
            { path: '/PropertyScore', category: 'Extras' },
            { path: '/ManageUser', category: 'UserManagment' },
            { path: '/RoomRateConfig', category: 'InventoryRates' },
            { path: '/SAPOReservations', category: 'Reservations' },
            { path: '/PropertySetup', category: 'InventoryRates' },
            { path: '/ComplexList', category: 'InventoryRates' },
            { path: '/RoomCategorySetup', category: 'InventoryRates' },
            { path: '/PackageList', category: 'InventoryRates' },
            { path: '/CancellationPolicy', category: 'InventoryRates' }
        ]

        const history = createBrowserHistory()
        const category2 = pathnames.find(el => el.path === history.location.pathname);
        const supportLink = process.env.REACT_APP_SUPPORT_URL + `?brand=HeyTravel&v1=HeyTravel&v2=${category2 ? category2.category : ''}&hotel=${global.defaultProperty}`;

        return (
            <div className="col-6">
                <Row style={{ position: 'fixed', bottom: '0', right: '0', zIndex: '1000' }}>
                    <Col>
                        <span id="SearchButton" className="btn-sm btn-host float-right mb-1 mr-1" onClick={this.toggle}>
                            <i className="fas fa-headset mr-1" /> <FormattedMessage id="navbar.Help" />
                        </span>
                    </Col>
                </Row>        
                
                <Collapse isOpen={this.state.isOpen}>
                    <Card className="shadow" style={{ position: 'fixed', bottom: global.isMobile ? '5%' : '0', width: global.isMobile ? '90%' : '50%', right: global.isMobile ? '3%' : '0', zIndex: '999' }}>
                        <CardBody className={global.isMobile ? "py-1" : "pt-1"}>
                            <BlankCard error={this.state.error} block={this.state.block}>
                                <Row>
                                    <Col>
                                        <FormGroup sm="true" row style={{ flexDirection: global.isMobile ? "column-reverse" : "unset" }}>
                                            <Col className={global.isMobile ? "col-12 px-0" : "col-10"}>
                                                <FormattedMessage id="SearchButton.Search">
                                                    {
                                                        placeholder => <Input id="SearchText" type="text" placeholder={placeholder} onChange={(evt) => this.setState({ searchquery: evt.target.value })} />
                                                    }
                                                </FormattedMessage>
                                            </Col>
                                            <Col className={global.isMobile ? "mb-2 col-12 px-0 d-flex align-items-center justify-content-between" : "text-right px-1 col-2"}>
                                                {global.isMobile ? 
                                                    <div className='text-xs font-weight-bold text-secondary'>Help</div>
                                                :''}
                                                <div className='d-flex align-items-center'>
                                                    <Link to={supportLink} target="_blank">
                                                        <Button className="btn-sm btn-host mr-2" id="Tooltipcreateticket" on="true" >
                                                            <i className="fas fa-headset" />
                                                        </Button>
                                                    </Link>
                                                    <CustomToolTip placement="top" target="Tooltipcreateticket" >
                                                        <FormattedMessage id="SearchButton.createTicket" />
                                                    </CustomToolTip>
                                                    <Button className="btn-sm btn-host " onClick={this.doSearch}>
                                                        <i className="fas fa-search" />
                                                    </Button>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                        {
                                            this.state.result && this.state.result.results && this.state.result.results.slice(begin, end).map((result, key) =>
                                                <CardBody className="py-1" key={key}>
                                                    <Row className="mb-1">
                                                        <Col><i className="far fa-file-alt mr-2 " /><b><a href={this.state.result._links.base + result.url} target="_blank" rel="noopener noreferrer"> {ReactHtmlParser(result.title.replace(/\@\@\@hl\@\@\@/gi, "<u>").replace(/\@\@\@endhl\@\@\@/gi, "</u>"))}</a></b></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>{ReactHtmlParser(result.excerpt.replace(/\@\@\@hl\@\@\@/gi, "<b>").replace(/\@\@\@endhl\@\@\@/gi, "</b>"))}</Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="text-secondary">{result.friendlyLastModified}</Col>
                                                    </Row>
                                                </CardBody>
                                            )
                                }
                                {this.state.result && this.state.result.results.length === 0 ? 'No Results' : ''}

                                        {
                                            this.state.result && this.state.result.results && this.state.result.results.length > 0 ?
                                                <Row>
                                            <Col>
                                                <Button className="btn-sm btn-host float-left" onClick={this.prevPage} disabled={this.state.page === 0}>
                                                            <i className="fas fa-angle-left" />
                                                </Button>
                                                <Button className="btn-sm btn-host float-right mr-1" onClick={this.nextPage} disabled={this.state.page === this.state.maxPage}>
                                                            <i className="fas fa-angle-right" />
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                :
                                                <div/>
                                }   
                                
                                    </BlankCard>
                            
                           
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        );
    }
}
