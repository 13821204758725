import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";
import Events from './Events';
import Mapping from './Mapping';

import { CommonHelper } from "../../Base/Common/CommonUIComponents";

import '../../Base/Common/Timeline.css';
import '../../Base/Common/TimelineElement.css';
import classnames from 'classnames';



export class PMSLogs extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);

        this.state = {
            error: null,
            activeTab: '1',
            block: true,
            modal: false,
            modaladd: false,
            selectedChannel: {}
        };
    }

    componentDidMount() {
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <Card className="border-0">
                <ErrorAlert error={this.state.error} />
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="LogsDashboard.ChannelEvents" />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => this.toggle('2')}>
                            <FormattedMessage id="LogsDashboard.Information" />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Events />
                    </TabPane>
                    <TabPane tabId="2">
                        <Mapping />
                    </TabPane>
                </TabContent>
            </Card>
        );
    }
}

export default PMSLogs;