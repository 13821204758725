import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { IntlProviderWrapper } from './Utils/IntlProviderWrapper';
import { addLocaleData } from "react-intl";
import registerServiceWorker from './registerServiceWorker';


import en from "react-intl/locale-data/en";
import pt from "react-intl/locale-data/pt";
import es from "react-intl/locale-data/es";
import { Layout } from './components/Layout';

addLocaleData([...en, ...pt, ...es]);


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

global.channelList = [];
global.operationMode = null;
global.modules = [];
global.hotelCurrency = '';
global.hotel = {};
global.PMSType = null;
global.sendRoomRate = null;

ReactDOM.render(    
    <IntlProviderWrapper >
        <BrowserRouter basename={baseUrl}>
            <Layout />
        </BrowserRouter>
    </IntlProviderWrapper>,
rootElement)
        
    

registerServiceWorker(); 