import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";
import LogsEvents from './Events/LogsEvents';
import LogsAvailabilityPrice from './AvailabilityPrice/LogsAvailabilityPrice';

import {  CommonHelper } from "../../Base/Common/CommonUIComponents";

import '../../Base/Common/Timeline.css';
import '../../Base/Common/TimelineElement.css';
import classnames from 'classnames';



export class LogsDashboard extends Component {

    state = {
        error: null,
        activeTab: '1',
        block: true,
        modal: false,
        modaladd: false,
        ChannelData: [],
        selectedChannel: {},
        isTabTwoClicked: false
    };

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
   
    componentDidMount() {


        
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let airBnbCode = params.get('code');

        // if page as code parameter is because it was a redirect by airbnb
        if (airBnbCode) {
            this.toggleModalAdd();
            this.setState({ airBnbCode: airBnbCode });
        }
    }

    handleRowSelect = (row, isSelected, e) => {
        if (isSelected)
            this.setState({ modal: true, selectedChannel: row })

    }

    onClickTabTwo() {
        const { isTabTwoClicked } = this.state;
        if (!isTabTwoClicked) {
            this.setState({ isTabTwoClicked: true });
        }
        this.toggle('2');
    }

    /*refreshData() {

        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ block: false, ChannelData: data });
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance');
    }*/

    render() {

        if (this.state.ChannelData) {
            return (
                <Card className="border-0 px-1">                    
                    <ErrorAlert error={this.state.error} />                    
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                <FormattedMessage id="LogsDashboard.ChannelEvents" />  
                                <CommonHelper help={<FormattedMessage id="LogsDashboard.ChannelEventsHelp" />} id={'logsdashboardchanneleventsinfo'} placement='bottom' />
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={this.onClickTabTwo.bind(this)}>
                                <FormattedMessage id="LogsDashboard.Information" />
                                <CommonHelper help={<FormattedMessage id="LogsDashboard.InformationHelp" />} id={'logsdashboardinformation'} placement='right'/>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <LogsEvents />
                        </TabPane>
                        <TabPane tabId="2">
                            {
                                this.state.isTabTwoClicked ?
                                    <LogsAvailabilityPrice />
                                    :
                                    <div />
                            }
                        </TabPane>
                    </TabContent>
                </Card>
            );
        } else {
            return (
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                </BlockUi>
            );
        }
    }
}