import React, { Component } from 'react';
import { Button, Row, Col, Card, CardBody, CardHeader, Badge } from 'reactstrap';
import CustomSelect from '../../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { getAPI, putAPI } from "../../Base/API"
import AddRate from './AddRate';
import { CancelPolicyText, SelectChannelConfig } from '../../Base/Common/CommonUIComponents'
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import NewCancelPolicy from './NewCancelPolicy';
import Authorization from '../../Base/Authorization';
import CancelationPolicyFilters from './CancelationPolicyFilters';


class CancelationPolicy extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            block: true,
            list: [],
            modal: false,
            cancelSelected: null,
            cancelPolicyId: null,
            rateCodeId: null,
            channelId: null,
            policiesNames: [],
            rateList: [],
            customPolicyFilter: false,
            newPolicyModal: false,
            emptyGuid: '00000000-0000-0000-0000-000000000000'
        }
    }

    componentDidMount() {
        this.getRoomCategory();
        this.getPackageList();

        this.getCancelationPolicy();
    }

    getCancelationPolicy = () => {
        this.setState({ block: true });
        var params = '';
        params += this.state.cancelPolicyId ? `&cancelPolicyId=${this.state.cancelPolicyId}` : '';
        params += this.state.rateCodeId ? `&rateCodeId=${this.state.rateCodeId}` : '';
        params += this.state.channelId ? `&channelId=${this.state.channelId}` : '';
        params += this.state.customPolicyFilter ? `&customPolicyFilter=${this.state.customPolicyFilter}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const { emptyGuid, policiesNames, rateList } = this.state;
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response) {
                    const policies = !policiesNames.length > 0 ? [] : policiesNames;
                    const rates = !policiesNames.length > 0 ? [
                        {
                            label: <FormattedMessage id="generic.active" />,
                            options: []
                        },
                        {
                            label: <FormattedMessage id="generic.inactive" />,
                            options: []
                        },
                    ] : rateList;

                    if (!policiesNames.length > 0) {
                        
                        data.response.forEach(p => {
                            var policy = {
                                value: p.id,
                                label: p.name
                            };
                            policies.push(policy);

                            p.rateCode && p.rateCode.forEach(r => {
                                var rate = { value: r.id, label: r.name };

                                rates[(r.active ? 0 : 1)].options.push(rate);
                            })
                        })
                    }

                    const list = data.response
                        .reduce((acc, curr) => {
                            let idx = 0;
                            if (curr.active) {
                                if (curr.rateCode.length > 0) {
                                    if (curr.hotelId === emptyGuid) {
                                        idx = 1;
                                    }
                                }
                                else {
                                    if (curr.hotelId !== emptyGuid) {
                                        idx = 2;
                                    }
                                    else {
                                        idx = 3;
                                    }
                                }
                            }
                            else {
                                idx = 4;
                            }
                            acc[idx].push(curr);
                            return acc;
                        }, [[], [], [], [], []])
                        .flat();

                    this.setState({ block: false, list: list, policiesNames: policies, rateList: rates });
                }
                else {
                    this.setState(({ block: false, error: errorMessage }));
                }
            }
        }, '/api/Rate/Rate/v1/cancelPolicy?' + params);
    }

    addOrRemoveRate = (e, cancelPolicyId, rateCodeIds, isToAdd) => {
        e.preventDefault();
        const cpId = isToAdd ? (cancelPolicyId === null ? this.state.cancelSelected : cancelPolicyId) : null;

        const body = {
            request: {
                rateCodeIds: isToAdd ? rateCodeIds.map(rc => rc.value) : rateCodeIds,
                cancelationPolicyId: cpId
            }
        };

        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response) {
                    this.getCancelationPolicy();

                    let rateList = [...this.state.rateList];

                    if (isToAdd) {
                        rateCodeIds.forEach(el => {
                            rateList[(el.active ? 0 : 1)].options.push(el);
                        })
                    }
                    else {
                        rateList[0].options = rateList[0].options.filter(el => !rateCodeIds.includes(el.value));
                        rateList[1].options = rateList[1].options.filter(el => !rateCodeIds.includes(el.value));
                    }

                    this.setState(({ block: false, rateList }));
                }
            }
            else {
                this.setState(({ block: false }));
            }
        }, `/api/Rate/Rate/v1/cancelPolicy/rateCode`, body);
    }

    getRoomCategory() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let list = [];
                data.data && data.data.map((item) => list.push({ 'value': item.id, 'label': item.description }))
                this.setState({ block: false, categoriesList: list });
            }
        }, '/api/hotel/RoomCategory/v1');
    }

    getPackageList() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let pl = [];
                data.response && data.response.map((item) => pl.push({ 'value': item.id, 'label': item.description }))
                this.setState({ block: false, packageList: pl });
            }
        }, '/api/hotel/Package/v1');
    }

    toggleModal = (cancelId) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            cancelSelected: cancelId
        }));
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    toggleNewModal = () => {
        this.setState(prevState => ({
            newPolicyModal: !prevState.newPolicyModal,
            customPolicy: null
        }));
    }

    editCustomPolicy = (cancelId) => {
        this.setState(prevState => ({
            newPolicyModal: !prevState.newPolicyModal,
            customPolicy: this.state.list.find(item => item.id === cancelId)
        }));
    }

    updateCancelPolicy = (cancelPolicy) => {
        let list = [...this.state.list];
        const idx = list.findIndex(el => el.id === cancelPolicy.id);

        if (idx !== -1) {
            list[idx] = cancelPolicy;
        }
        else list.push(cancelPolicy);



        this.setState(({ list }));
    }

    render() {
        const { newPolicyModal, customPolicy, list } = this.state;

        return (
            <div>
                <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-ban'} title={'CancelationPolicy.CancelationPolicy'} help={<FormattedHTMLMessage id="CancelationPolicy.Help" />} id="CancelationPolicies">
                    <CancelationPolicyFilters
                        fromBEConfigDashboard={this.props.fromBEConfigDashboard}
                        channelId={this.state.channelId}
                        rateList={this.state.rateList}
                        policiesNames={this.state.policiesNames}
                        handleSelect={this.handleSelect}
                        toggleNewModal={this.toggleNewModal}
                        getCancelationPolicy={this.getCancelationPolicy}
                    />

                    {
                        list.map((item, key) =>
                            <Row key={key} className="mb-2 px-0 px-md-2">
                                <Card body className="shadow-sm mb-1 pt-0">
                                    <CardHeader className="card-header-gridview border-bottom-1 border-host mb-1 bg-white py-2 pl-0">
                                        <Row className="algin-items-center">
                                            <Col><b style={{ fontSize: '15px' }}>{item.name}</b></Col>
                                            {
                                                item.hotelId !== '00000000-0000-0000-0000-000000000000' ?
                                                    <Authorization
                                                        perform="cancelPolicy:create"
                                                        yes={() => (
                                                            <Col className="col-2 pr-0">
                                                                <Button className=" float-right btn-host btn-sm" onClick={() => this.editCustomPolicy(item.id)}>
                                                                    <i className="fas fa-edit" />
                                                                </Button>
                                                            </Col>
                                                        )}
                                                        no={() => <div></div>}
                                                    />
                                                    :
                                                    ''
                                            }
                                        </Row>
                                    </CardHeader>
                                    <CardBody className="p-0 mt-1">
                                        <Row className="mb-3 mx-1">
                                            <Col className="col-8 col-lg-2 order-1 order-lg-1">
                                                <h6 className={(global.isMobile ? "d-block" : "d-inline") + " m-0"}><FormattedMessage id="CancelationPolicy.Type" /></h6> {item.type}
                                            </Col>
                                            <Col className="col-12 col-lg-8 order-3 order-lg-2 my-3 my-lg-0" style={global.isMobile ? { fontSize: '13.5px' } : {}}>
                                                <CancelPolicyText policy={item} />
                                            </Col>
                                            <Col className="col-4 col-lg-2 text-right order-2 order-lg-3">
                                                <h6 className={(global.isMobile ? "d-block" : "d-inline") + " m-0"}><FormattedMessage id="CancelationPolicy.Status" /></h6> <Badge color={item.active ? 'primary' : 'secondary'}>{item.active ? 'Active' : 'Inactive'}</Badge>
                                            </Col>
                                        </Row>
                                        <CardHeader className="card-header-gridview border-bottom-1 border-host mb-1 bg-white pb-0 pl-0">
                                            <Row className="mb-1">
                                                <Col><b><FormattedMessage id="CancelationPolicy.AssociatedRates" /></b></Col>
                                                <Authorization
                                                    perform="cancelPolicy:associateRate:edit"
                                                    yes={() => (
                                                        <Col className="text-right col-2 pr-0">
                                                            <Button className="btn btn-host btn-sm" onClick={() => this.toggleModal(item.id)}>
                                                                <i className="fas fa-plus" style={{ cursor: 'pointer' }} />
                                                            </Button>
                                                        </Col>
                                                    )}
                                                    no={() => <div></div>}
                                                />
                                            </Row>
                                        </CardHeader>
                                        {
                                            item.rateCode && item.rateCode.map((rate, key) =>
                                                <Row key={key} className="mx-3 mb-1">
                                                    <div className="border-bottom w-100">
                                                        <Row>
                                                            <Col><b><i className="fas fa-gift mt-3 mr-2" />{rate.name}</b></Col>
                                                            <Col className="text-right col-2 pr-0">
                                                                <Authorization
                                                                    perform="cancelPolicy:associateRate:edit"
                                                                    yes={() => (
                                                                        <Button className="btn btn-host btn-sm" onClick={(e) => this.addOrRemoveRate(e, item.id, [rate.id], false)}>
                                                                            <i className="fas fa-trash-alt" style={{ cursor: 'pointer' }} />
                                                                        </Button>
                                                                    )}
                                                                    no={() => <div></div>}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <Row className="w-100 my-1" style={global.isMobile ? { fontSize: '13.5px' } : {}}>
                                                        <Col className="col-12 col-lg-4 my-1 my-lg-0 pr-0">
                                                            <h6 className="d-inline"><FormattedMessage id="CancelationPolicy.Description" /></h6> {rate.description}
                                                        </Col>
                                                        <Col className="col-12 col-lg-4 my-1 my-lg-0 pr-0">
                                                            <h6 className="d-inline"><FormattedMessage id="CancelationPolicy.Category" /></h6> {this.state.categoriesList && this.state.categoriesList.find(el => el.value === rate.categoryId)?.label}
                                                        </Col>
                                                        <Col className="col-12 col-lg-4 my-1 my-lg-0 pr-0">
                                                            <h6 className="d-inline"><FormattedMessage id="CancelationPolicy.Package" /></h6> {this.state.packageList && this.state.packageList.find(el => el.value === rate.packageId)?.label}
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            )
                                        }
                                    </CardBody>
                                </Card>
                            </Row>
                        )
                    }
                </StyledCard>

                {this.state.modal ?
                    <AddRate
                        modal={this.state.modal}
                        toggle={this.toggleModal}
                        addRate={this.addOrRemoveRate}
                        usedRates={this.state.rateList.flatMap(rl => rl.options)}
                        mappedRates={this.props.mappedRates}
                    />
                : ''}
                {this.state.newPolicyModal ?
                    <NewCancelPolicy
                        modal={newPolicyModal}
                        toggle={this.toggleNewModal}
                        customPolicy={customPolicy}
                        updateCancelPolicy={this.updateCancelPolicy}
                    />
                :''}
            </div>
        );
    }
}

export default injectIntl(CancelationPolicy)