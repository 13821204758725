import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Button, Col, CustomInput, Form, Input, Label, Row, Badge } from 'reactstrap';
import { CommonHelper, ConfirmActionModal, KebabMenu } from '../Base/Common/CommonUIComponents';
import { postAPI, getAPI, deleteAPI, patchAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';
import ModalSendToCoWorkwer from './ModalSendToCoWorkwer';
import BlockUi from 'react-block-ui';

class EmailReservationDomainAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            blockDelete: false,
            error: null,
            errorDelete: null,
            domain: '',
            domainsList: null,
            defaultDomainCheck: false,
            automatic_security: true,
            custom_spf: false,
            custom_dkim_selector: false,
            emailTo: '',
            coWorkerMessage: this.props.intl.formatMessage({ id: 'EmailReservation.DefaultMessage' }),
            confirmDeleteDomainModal: false,
            selectedDomainId: null,
            sendToCoWorkerModal: false,
            blockSendTOCoWorker: false
        };
    }

    componentDidMount() {
        this.getDomains();
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const regex = new RegExp('^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$');
        const el = document.getElementById('domain');
        if(!el){ return; }
        if(!regex.test(value)){
            el.setCustomValidity(this.props.intl.formatMessage({ id: "EmailReservation.InvalidDomainFormat" }));
            el.reportValidity();
        }
        else{
            el.setCustomValidity("");
        }

        this.setState({ [name]: value });
    }

    handleSwitchChange = (evt) => {
        if (evt) {
            const { name, checked } = evt.target;

            this.setState({
                [name]: checked
            });
        }
    }

    getDomains = () => {
        this.setState({ block: true }, () =>{
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }

                    if(data.response && data.response.length > 0){
                        this.setState({ domainsList: data.response, block: false }, () => this.props.setDomains(data.response));
                    }
                }
            }, `/api/hotel/EmailReservation/v1/domain/list`)
        });
    }

    createDomain = (e) => {
        e.preventDefault();

        this.setState({ block: true }, () => {
            const { domain, defaultDomainCheck, automatic_security, custom_spf, custom_dkim_selector } = this.state;

            const body = {
                isDefault: defaultDomainCheck,
                domain,
                automaticSecurity: automatic_security,
                customSpf: custom_spf,
                customDkim: custom_dkim_selector ? 'true' : 'false'
            };
            
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }

                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="EmailReservation.DomainCreated" />, <FormattedMessage id="generic.success" />)
                        this.setState({ block: false }, () => {this.getDomains()});
                    } else {
                        data.error = ({ error_message: <FormattedMessage id="EmailReservation.DomainCreated" />, error_code: <FormattedMessage id="EmailReservation.Failed" /> });
                        handleNotification(data)
                        this.setState({ block: false });
                    }
                }
            }, `/api/hotel/EmailReservation/v1/domain`, body);
        });
    }

    verifyDomain = (domainId) => {

        if(!domainId){
            return;
        }

        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data.response && data.response.length > 0 && data.response[0].valid) {
                        handleNotification(data, <FormattedMessage id="EmailReservation.VerifyUpdate" />, <FormattedMessage id="generic.success" />)
                    } else {
                        data.error = ({ error_message: <FormattedMessage id="EmailReservation.VerifyUpdate" />, error_code: <FormattedMessage id="EmailReservation.Failed" /> });
                        handleNotification(data)
                    }
                    this.setState({ block: false });
                }
            }, `/api/hotel/EmailReservation/v1/domain/${domainId}/validate`)
        );
    }

    sendEmail = (e, domainId) => {
        e.preventDefault();
        const { emailTo, coWorkerMessage } = this.state;

        if(!domainId){
            return;
        }
        
        const body = {
            domainId: domainId,
            email: emailTo,
            message: coWorkerMessage
        };

        this.setState({ blockSendTOCoWorker: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockSendTOCoWorker: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, blockSendTOCoWorker: false });
                        return;
                    }
                    
                    this.setState({ blockSendTOCoWorker: false }, handleNotification(data, <FormattedMessage id="EmailReservation.EmailSent" />, <FormattedMessage id="generic.success" />));
                }
            }, `/api/hotel/EmailReservation/v1/email`, body)
        });
    }

    copy = (content) => {
        navigator.clipboard.writeText(content);
    }

    handleChangeEmailTo = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    toggleConfirmDeleteModal = (domainId) => {
        this.setState({ confirmDeleteDomainModal: !this.state.confirmDeleteDomainModal, selectedDomainId: domainId });
    }

    toggleSendToCoworkerModal = (domainId) => {
        this.setState({ sendToCoWorkerModal: !this.state.sendToCoWorkerModal, selectedDomainId: domainId });
    }

    removeDomain = () => {
        this.setState({ blockDelete: true }, () => {
            if(this.state.selectedDomainId){
                deleteAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ errorDelete: errorMessage, blockDelete: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState({ errorDelete: errorMessage, blockDelete: false });
                            return;
                        }
                        this.setState({ blockDelete: false, confirmDeleteDomainModal: false, selectedDomainId: null }, () => this.getDomains());
                    }
                }, `/api/hotel/EmailReservation/v1/domain/${this.state.selectedDomainId}`);
            }else{
                handleNotification('', <FormattedMessage id="EmailReservation.UnableToRemoveDomain" />, 'info');
                this.setState({ blockDelete: false, confirmDeleteDomainModal: false});
            }
        });
    }

    setDomainDefault = (domainId) => {
        this.setState({ block: true }, () => {
            patchAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    handleNotification(data, <FormattedMessage id="EmailReservation.DefaultUpdate" />, <FormattedMessage id="generic.success" />);
                    this.getDomains();
                }
            }, `/api/hotel/EmailReservation/v1/domain?domainId=${domainId}&isDefault=true`);
        });
    }

    render() {
        const { emailTo, coWorkerMessage, block, error, domain, domainsList, defaultDomainCheck, automatic_security, custom_spf, custom_dkim_selector, confirmDeleteDomainModal, sendToCoWorkerModal, blockDelete, errorDelete } = this.state;

        return (
            <div block={block} error={error} className="mb-2">
                <BlockUi tag="div" blocking={this.state.block}>
                    <Row className="mb-4 py-1">
                        <Col className=" ml-2">
                            <FormattedMessage id="EmailReservation.AuthDomain"/>
                        </Col>
                    </Row>
                    {!(domainsList && domainsList.length > 0) ?
                        <Card className='py-2 px-3 h-100 w-100 bg-white' style={{ border: '1px solid #bbc1c9ab', borderRadius: '5px', boxShadow: '0px 3px 15px #6F73881A', maxHeight: '90%' }}>
                            <Form onSubmit={this.createDomain}>
                                <Row className='d-flex align-items-center'>
                                    <Col className='text-right'>
                                        <Button className=" btn btn-host btn-sm" type='submit'>
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className='px-2 mt-2'>
                                    <Col className='col-12'>
                                        <Label className='text-muted'>
                                            <FormattedMessage id="EmailReservation.Domain"/>
                                        </Label>
                                        <Input
                                            type='text'
                                            required
                                            maxLength={'100'}
                                            id="domain"
                                            name='domain'
                                            value={domain}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                    <Col className='mt-2'>
                                        <div className='text-muted'>
                                            <FormattedMessage id="EmailReservation.AdvancedSettings"/>
                                        </div>
                                        <div className='mt-2 d-flex align-items-center'>
                                            <CustomInput
                                                type="switch"
                                                id="defaultDomainCheck"
                                                name="defaultDomainCheck"
                                                onChange={this.handleSwitchChange}
                                                checked={defaultDomainCheck}
                                            />
                                            <div className='d-flex align-items-center'>
                                                <FormattedMessage id="EmailReservation.Domain"/>
                                                <CommonHelper placement="right" help={<FormattedMessage id="EmailReservation.defaultDomainHelp" />} id={'defaultDomainHelp'} />
                                            </div>
                                        </div>
                                        <div className='mt-2 d-flex align-items-center'>
                                            <CustomInput
                                                type="switch"
                                                id="automatic_security"
                                                name="automatic_security"
                                                onChange={this.handleSwitchChange}
                                                checked={automatic_security}
                                            />
                                            <div className='d-flex align-items-center'>
                                                <FormattedMessage id="EmailReservation.automatic_security"/>
                                                <CommonHelper placement="right" help={<FormattedMessage id="EmailReservation.automatic_securityHelp" />} id={'automatic_securityHelp'} />
                                            </div>
                                        </div>
                                        <div className='mt-2 d-flex align-items-center'>
                                            <CustomInput
                                                type="switch"
                                                id="custom_spf"
                                                name="custom_spf"
                                                onChange={this.handleSwitchChange}
                                                checked={custom_spf}
                                            />
                                            <div className='d-flex align-items-center'>
                                                <FormattedMessage id="EmailReservation.custom_spf"/>
                                                <CommonHelper placement="right" help={<FormattedMessage id="EmailReservation.custom_spfHelp" />} id={'custom_spfHelp'} />
                                            </div>
                                        </div>
                                        <div className='mt-2 d-flex align-items-center'>
                                            <CustomInput
                                                type="switch"
                                                id="custom_dkim_selector"
                                                name="custom_dkim_selector"
                                                onChange={this.handleSwitchChange}
                                                checked={custom_dkim_selector}
                                            />
                                            <div className='d-flex align-items-center'>
                                                <FormattedMessage id="EmailReservation.custom_dkim_selector"/>
                                                <CommonHelper placement="right" help={<FormattedMessage id="EmailReservation.custom_dkim_selectorHelp" />} id={'custom_dkim_selectorHelp'} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    :
                        <>
                            {domainsList && domainsList.length > 0 && domainsList.map((domain, key) => {
                                const configs = domain ? Object.keys(domain.dns) : [];
                                return(
                                    <Card className='mb-3 py-2 px-3 h-100 w-100 bg-white' style={{ border: '1px solid #bbc1c9ab', borderRadius: '5px', boxShadow: '0px 3px 15px #6F73881A', maxHeight: '90%' }}>
                                        <Row className='p-2' key={key}>
                                            <Col className='col-11'>
                                                <div className='d-flex align-items-center'>
                                                    <p className='m-0' style={{fontWeight: '500'}}>{domain.subdomain}.{domain.domain}</p>
                                                    {domain.default ?
                                                        <Badge className='ml-2' color='primary'>
                                                            <FormattedMessage id="EmailReservation.Default"/>
                                                        </Badge>
                                                    :''}
                                                    {domain.valid ?
                                                        <Badge className='ml-2' color='success'>
                                                            <FormattedMessage id="EmailReservation.Verified"/>
                                                        </Badge>
                                                    : domain.last_validation_attempt_at ?
                                                        <Badge className='ml-2' color='danger'>
                                                            <FormattedMessage id="EmailReservation.Failed"/>
                                                        </Badge>
                                                    :
                                                        <Badge className='ml-2' color='secondary'>
                                                            <FormattedMessage id="EmailReservation.Pending"/>
                                                        </Badge>
                                                    }
                                                </div>
                                                
                                            </Col>
                                            <Col className='text-right'>
                                                <KebabMenu
                                                    deleteFunction={_ => this.toggleConfirmDeleteModal(domain.id)}
                                                    extraFields={domain.default ? [{
                                                        function: _ => this.verifyDomain(domain.id),
                                                        icon: "fas fa-user-check mr-2",
                                                        text: "EmailReservation.Verify"
                                                    },{
                                                        function: _ => this.toggleSendToCoworkerModal(domain.id),
                                                        icon: "fas fa-user-friends mr-2",
                                                        text: "EmailReservation.SendToCoworker"
                                                    }] 
                                                    : [{
                                                        function: _ => this.setDomainDefault(domain.id),
                                                        icon: "far fa-check-square mr-2",
                                                        text: "EmailReservation.setDefault"
                                                    }, {
                                                        function: _ => this.verifyDomain(domain.id),
                                                        icon: "fas fa-user-check mr-2",
                                                        text: "EmailReservation.Verify"
                                                    },{
                                                        function: _ => this.toggleSendToCoworkerModal(domain.id),
                                                        icon: "fas fa-user-friends mr-2",
                                                        text: "EmailReservation.SendToCoworker"
                                                    }]}
                                                />
                                            </Col>
                                        </Row>
                                        <div>
                                            <Row className='px-2 text-muted'>
                                                <Col className="col-2">
                                                    <FormattedMessage id="EmailReservation.Type"/>
                                                </Col>
                                                <Col>
                                                    <FormattedMessage id="EmailReservation.Host"/>
                                                    <CommonHelper help={<FormattedMessage id="EmailReservation.HostHelp" />} id={'HostHelp'} />
                                                </Col>
                                                <Col>
                                                    <FormattedMessage id="EmailReservation.Data"/>
                                                    <CommonHelper help={<FormattedMessage id="EmailReservation.DataHelp" />} id={'DataHelp'} />
                                                </Col>
                                            </Row>
                                            <div className='my-2'>
                                                {configs && 
                                                    configs.map((c, key) => {
                                                        const config = domain && domain.dns ? domain.dns[c] : {};
                                                        
                                                        return (
                                                            <Row key={key} className="px-2 px-4">
                                                                {config ?
                                                                    <>
                                                                        <Col className="col-2 py-2 text-truncate" style={{ border: '1px solid #e5e5e5', borderRight: 'none', borderTop: !key ? '1px solid #e5e5e5' : 'none' }}>
                                                                            {config && config.type ? config.type.toUpperCase() : ''}
                                                                        </Col>
                                                                        <Col className='py-2 text-truncate d-flex align-items-center justify-content-between' style={{ border: '1px solid #e5e5e5', borderTop: !key ? '1px solid #e5e5e5' : 'none' }}>
                                                                            <div className='text-truncate'>{config.host}</div>
                                                                            <div>
                                                                                <i className="far fa-copy cursor-pointer" onClick={_ => this.copy(config.host)}/>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className='py-2 d-flex align-items-center justify-content-between text-truncate' style={{ border: '1px solid #e5e5e5', borderLeft: 'none', borderTop: !key ? '1px solid #e5e5e5' : 'none' }}>
                                                                            <div className='text-truncate'>{config.data}</div>
                                                                            <div className='px-2'>
                                                                                <i className="far fa-copy cursor-pointer" onClick={_ => this.copy(config.data)}/>
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                :''}
                                                            </Row>
                                                        )
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </Card>
                                )
                            })}
                        </>
                    }

                    {sendToCoWorkerModal ?
                        <ModalSendToCoWorkwer
                            domainId={this.state.selectedDomainId}
                            emailTo={emailTo}
                            coWorkerMessage={coWorkerMessage}
                            handleChangeEmailTo={this.handleChangeEmailTo}
                            sendEmail={this.sendEmail}
                            isOpen={sendToCoWorkerModal}
                            toggleModal={_ => this.toggleSendToCoworkerModal()}
                            block={this.state.blockSendTOCoWorker}
                        />
                    :''}

                    {confirmDeleteDomainModal ?
                        <ConfirmActionModal
                            modal={confirmDeleteDomainModal}
                            toggleModal={_ => this.toggleConfirmDeleteModal()}
                            actionFunction={this.removeDomain}
                            text={<FormattedMessage id="EmailReservation.ConfirmRemoveDomain"/>}
                            block={blockDelete}
                            error={errorDelete}
                        />
                    :''}
                </BlockUi>
            </div>     
        )
    }
}

export default injectIntl(EmailReservationDomainAuth)
