import React, { Component } from 'react';
import { Row, Col, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import CustomSelect from '../Base/Common/CustomSelect';

export class BulkUpdatePriceForm extends Component {

    render() {
        const { formId, formIsDisabled, formPlaceholder, icon, priceType, priceActions, priceTypes, priceTypeOpt, priceObj, onChangeModifyPriceFunc, onChangeFormFunc } = this.props;

        return (
            <Row className="mb-1">
                <Col className="col-2">
                    <div>
                        {icon}
                    </div>
                </Col>
                <Col className="col-10">
                    <Row>
                        {
                            priceType != null && priceType !== priceActions[0].value ?
                                <Col className="col-4">
                                    <CustomSelect required options={priceTypes} value={priceTypeOpt} onChange={onChangeModifyPriceFunc} />
                                </Col>
                                :
                                <div />
                        }
                        <Col>
                            <InputGroup size="sm" >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className={`fas fa-${priceObj && priceObj.priceMode === priceTypes[1].value ? `percent` : `dollar-sign`}`} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    className="border-left-0"
                                    type="number"
                                    id={formId}
                                    required max='999999999'
                                    step='0.01'
                                    placeholder={formPlaceholder}
                                    value={priceObj ? priceObj.adultPrice : ''}
                                    onChange={onChangeFormFunc}
                                    disabled={formIsDisabled}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }
}