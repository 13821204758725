import 'bootstrap/dist/css/bootstrap.css';

import { runWithAdal } from 'react-adal';
import { authContext } from './Utils/AdalConfig';


const DO_NOT_LOGIN = false;

runWithAdal(authContext, () => {

    // eslint-disable-next-line
    require('./indexApp.js');

}, DO_NOT_LOGIN);
