import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ChannelBar, ChannelBarMobile } from './ChannelBar';
import { CheckAuthorization } from '../../Base/Authorization';
import EditRate from '../Rate/EditRate';
import { getAPI } from "../../Base/API";
import RateHistory from './RateHistory';
import { RoomRateRow, RoomRateRowMobile } from './RoomRateRow';

export class RoomRateBar extends Component {

    constructor(props) {
        super(props);
        this.changeChannelVisibility = this.changeChannelVisibility.bind(this);
        this.changePaxVisibility = this.changePaxVisibility.bind(this);

        
        this.state = {
            channelvisible: false,
            paxvisible: false,
            historyModal: false,
            iCalSyncUrls: []
        };
    }

    changeChannelVisibility() {

        this.setState({ channelvisible: !this.state.channelvisible })
    }
    changePaxVisibility() {

        this.setState({ paxVisibility: !this.state.paxVisibility })
    }

    getiCalSyncUrls = (id) => {
        if (!this.state.iCalSyncUrls.find(url => url.id === id)) {
            this.setState({ block: true });
            getAPI(result => {
                const { error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                const { iCalSyncUrls } = this.state;
                const idx = iCalSyncUrls.indexOf(iCalSyncUrls.find(url => url.id === id));

                if (idx === -1) {
                    const link = `${process.env.REACT_APP_RATE_APIGETWAY_URL}/api/Calendar/ICal/v1/ICal/${global.hotel.id}/${id}`;

                    iCalSyncUrls.push({ id: id, url: link });
                }
                this.setState({ iCalSyncUrls, block: false });
            }, `/api/Rate/ICal/v1/iCal/Validate/${global.hotel.id}/${id}`);
        }
    }

    toggleModal = (modal) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }), () => this.props.updateLabelsOnChangeSelect());
    }

    getDependencyRate = (applicablepricelist) => {
        let dependentOn = null;

        if (applicablepricelist.length > 0) {
            const dependencyId = applicablepricelist[0].dependencyId || applicablepricelist[0].dependencyMasterId;

            dependentOn = this.props.ratecode
                .find(ratecode =>
                    ratecode.pricelistId === dependencyId &&
                    ratecode.roomCategoryId === applicablepricelist[0].dependencyReferenceRoomCategory &&
                    ratecode.packageId === applicablepricelist[0].dependencyReferencePackage
                )
        }

        return dependentOn;
    }

    getExtraFields = (rate) => {
        var options = [];

        if (CheckAuthorization("rate:add")) {
            options.push(
                { text: "RoomRateBar.Copy", function: _ => this.props.copyRate(rate), icon: "fas fa-copy mr-2" }
            )
        }
        if (CheckAuthorization("rate:history")) {
            options.push(
                { text: "RoomRateBar.History", function: _ => this.toggleModal("historyModal"), icon: "fas fa-history mr-2" }
            )
        }
        return options;   
    }

    render() {
        const { room, rate, pricelist, channelinstance, ratesToDepend, channels, labels, updateLabels } = this.props;

        var applicablepricelist = pricelist.filter(function (pl) {
            return pl.id === rate.pricelistId
        })

        var applicableratecodepax = this.props.ratecodepax && this.props.ratecodepax.filter(function (rcp) {
            return rcp.rateCodeId === rate.id
        })

        applicableratecodepax.sort((a, b) => (a.adults + a.children) - (b.adults + b.children));

        const dependencyRate = this.getDependencyRate(applicablepricelist);

        const icalUrl = this.state.iCalSyncUrls.find(url => url.id === rate.id);

        return <div>
            <div>
                {global.isMobile ?
                    <RoomRateRowMobile
                        roomId={room.id}
                        rate={rate}
                        channels={channels}
                        changeChannelVisibility={this.changeChannelVisibility}
                        channelvisible={this.state.channelvisible}
                        applicablepricelist={applicablepricelist}
                        changePaxVisibility={this.changePaxVisibility}
                        applicableratecodepax={applicableratecodepax}
                        paxVisibility={this.state.paxVisibility}
                        dependencyRate={dependencyRate}
                        getiCalSyncUrls={this.getiCalSyncUrls}
                        icalUrl={icalUrl}
                        getExtraFields={this.getExtraFields}
                        toggleModal={this.toggleModal}
                    />
                :
                    <RoomRateRow
                        roomId={room.id}
                        rate={rate}
                        channels={channels}
                        changeChannelVisibility={this.changeChannelVisibility}
                        channelvisible={this.state.channelvisible}
                        applicablepricelist={applicablepricelist}
                        changePaxVisibility={this.changePaxVisibility}
                        applicableratecodepax={applicableratecodepax}
                        paxVisibility={this.state.paxVisibility}
                        dependencyRate={dependencyRate}
                        getiCalSyncUrls={this.getiCalSyncUrls}
                        icalUrl={icalUrl}
                        copyRate={this.props.copyRate}
                        toggleModal={this.toggleModal}
                    />
                }

                {this.state.channelvisible ?
                    global.isMobile ?
                        <ChannelBarMobile
                            room={room}
                            rate={rate}
                            RateCodeChannel={channels}
                            channelinstance={channelinstance}
                        />
                    :
                        <ChannelBar
                            room={room}
                            rate={rate}
                            RateCodeChannel={channels}
                            channelinstance={channelinstance}
                        />
                : ''}


                {this.state.historyModal ?
                    <RateHistory
                        rateId={rate.id}
                        modal={this.state.historyModal}
                        toggleModal={() => this.toggleModal("historyModal")}
                    />
                : ''}

                {this.state.modalRate ?
                    <Modal isOpen={this.state.modalRate} toggle={() => this.toggleModal("modalRate")} fade={false} className="modal-lg" >
                    <ModalHeader toggle={() => this.toggleModal("modalRate")}  > <FormattedMessage id="AddRate.Rate" /> </ModalHeader>
                        <ModalBody className="modal-lg p-0">
                            <EditRate
                                id={rate && rate.id}
                                onCreateRoomRate={this.props.onCreateRoomRate}
                                ratesToDepend={ratesToDepend}
                                updateRateCode={this.props.updateRateCode}
                                closeModalRate={() => this.toggleModal("modalRate")}
                                labels={labels}
                                updateLabels={updateLabels}
                                getLabels={this.props.getLabels}
                                deleteLabelModal={this.props.deleteLabelModal}
                                selectedLabelDelete={this.props.selectedLabelDelete}
                                toggleDeleteLabelModal={this.props.toggleDeleteLabelModal}
                                deleteLabel={this.props.deleteLabel}
                                updateLabelsOnChangeSelect={this.props.updateLabelsOnChangeSelect}
                            />
                        </ModalBody>
                    </Modal>
                :''}
            </div>
        </div>
    }
};