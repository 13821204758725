import React, { Component } from "react";
import { Row, Col, Button, Form, Badge, UncontrolledTooltip, UncontrolledCollapse, Card } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { getAPI, postAPI } from "../../Base/API";
import { CommonHelper, SelectRate, SelectRoomCategory, StyledCard } from "../../Base/Common/CommonUIComponents";
import { handleNotification } from "../../Base/Notification";
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import Calendar from "./Calendar";
import CustomToolTip from "../../../Utils/CustomToolTip";

export class CompareAvailability extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			block: false,
            startDate: moment(),
            endDate: moment().add(15, 'day'),
            firstDay: moment(),
            roomId: null,
            selectedChannel: null,
			colors: [
				'#4F80F6',
				'#4EEBB5',
				'#5FD4FA',
				'#FFCA65',
				'#95B3F9',
				'#3f66c5',
				'#3ebc91',
				'#4caac8',
				'#cca251',
				'#778fc7',
				'#203362',
				'#1f5e48',
				'#265564',
				'#665128',
				'#3c4864'
			],
			totalMonths: this.getMonths(moment(), moment().add(15, 'day'))
		};
	}


	getAvailability = (e) => {
		if (e) e.preventDefault();
		this.setState({ block: true });
        const { endDate, roomId, rateId } = this.state;
        const startDate = moment(this.state.startDate).format("YYYY-MM-DD");

		var params = `&roomId=${roomId}`;
        if (rateId) params += `&rateId=${rateId}`;


		getAPI(result => {
			const { data, error } = result;
			const errorMessage = [];

			if (error) {
				errorMessage.push({
					message: error.message,
					stack: error.stack,
					messageType: "danger"
				});
				this.setState({ error: errorMessage, block: false });
				return;
			}
			if (data) {
				var totalMonths = this.getMonths(startDate, endDate);

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
					this.setState(({ block: false, error: errorMessage, divergencesByDay: [], divergencesByChannel: [], channelReports: [], firstDay: startDate, totalMonths }));
                    return;
                }
                if (data.response && data.response.length > 0) {
					this.setState({ divergencesByDay: data.response[0].data, firstDay: startDate, selectedChannel: null, totalMonths },
						() => this.arrangeChannelDivergences(data.response[0]))
					
				}
				else {
					this.setState({ block: false, divergencesByDay: [], divergencesByChannel: [], channelReports: [], firstDay: startDate, selectedChannel: null, totalMonths })
                }
			}
			else this.setState({ error: errorMessage, block: false });
		}, `/api/Price/Availability/v1/availability/compare?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}` + params);
	}

	getMonths = (startDate, endDate) => {
		const months = [];
		var dateStart = moment(startDate);

		while (endDate > dateStart || dateStart.format('M') === endDate.format('M')) {
			months.push(dateStart.format('YYYY-MM'));
			dateStart.add(1, 'month');
		}

		return months;
	}

	arrangeChannelDivergences = (response) => {
		const { colors } = this.state;

        var divergencesByChannel = response.channels && response.channels.reduce((acc, channel) => {

            if (acc.some(c => c.channelInstanceId === channel.channelInstanceId) === false) {
                acc.push({
                    ...channel,
                    daysWithDivergences: response.data.filter(el => el.channelsDifferences.some(cd => cd.channelInstanceId === channel.channelInstanceId)).length,
					color: colors[acc.length % colors.length],
					areOptionsOpen: false
                })
            }

			return acc;
        }, [])


		var channelReports = response.channelReports?.reduce((acc, report) => {
			if (report.code !== 'NOT IMPLEMENTED') {
				divergencesByChannel.push(report);
			}
			else {
				acc.push(report);
			}

			return acc
		}, []);

        this.setState({ divergencesByChannel, channelReports, block: false });
	}

	sendPrice = (channelInstanceId, index) => {
		this.setState({ block: true });

		postAPI(result => {
			const { data, error } = result;
			this.setState({ block: true });

			if (error) {
				var errorMessage = [];
				if (error.request && error.request.status === 504) {
					handleNotification({}, <FormattedMessage id="ChannelConf.TimeoutConsultLogs" values={{ action: 'SendPrice' }} />, <FormattedMessage id="ChannelConf.Timeout" />, 'info');
				}
				else {
					errorMessage.push({ message: error.message, messageType: "danger" });
				}

				this.setState({ error: errorMessage, block: false }, () => this.toggleChannelOptions(index));
				return;
			}
			if (data) {
				handleNotification(data, <FormattedMessage id="ChannelConf.SendPrinceSuccess" />, <FormattedMessage id="ChannelConf.Success" />);

				this.setState({ block: false, divProp: false }, () => {
					this.getAvailability();
				});
			}
		}, "/api/Rate/ChannelConfig/v1/ChannelInstance/" + channelInstanceId + "/SendPrice");
	}

	toggleChannelOptions = (index) => {
		const { divergencesByChannel } = this.state;

		divergencesByChannel[index].areOptionsOpen = !divergencesByChannel[index].areOptionsOpen;

		this.setState({ divergencesByChannel });
    }

	handleSelect = (name, combo) => {
		this.setState({
			[name]: combo ? combo.value : null
        })
	}

	handleChannel = (channelInstanceId) => {
		this.setState({
			selectedChannel: (this.state.selectedChannel === channelInstanceId ? null : channelInstanceId)
		});
    }

	render() {
        const { block, error, divergencesByChannel, divergencesByDay, selectedChannel } = this.state;

		return (
            <StyledCard block={block} error={error} icon="fas fa-balance-scale" title="navbar.CompareAvailability">
				<Form onSubmit={this.getAvailability}>
					<Row className="mb-2">
						<Col>
							<SelectRoomCategory
								icon={'fa fa-bed fa-fw'}
								name={'roomId'}
								isMulti={false}
								onChangeFunc={this.handleSelect}
								placeholder={<FormattedMessage id="AnalyticsDashboard.SelectRoomCategory" />}
                                value={this.state.roomId}
								required
							/>
						</Col>
						{/*<Col>
							<SelectRate
								icon={'fa fa-gift fa-fw'}
								name={'rateId'}
								isMulti={false}
								onChangeFunc={this.handleSelect}
								placeholder={<FormattedMessage id="AnalyticsDashboard.SelectRate" />}
							/>
						</Col>*/}
						<Col>
							<DateRangePicker
								startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
								startDateId="your_unique_start_date_id_announcement"
                                isOutsideRange={day => false}
								endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
								endDateId="your_unique_end_date_id_announcement"
								onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
								focusedInput={this.state.focusedInput}
								onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
								small={true}
								numberOfMonths={1}
								showDefaultInputIcon={true}
								renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
								required
							/>
						</Col>
						<Col>
							<Button className="float-right btn btn-sm btn-host" type="submit">
								<i className="fa fa-search" />
							</Button>
						</Col>
						<CommonHelper help={<FormattedMessage id="CompareAvailability.Help" />} id={'CompareAvailabilityHelp'}/>
					</Row>
				</Form>

				<Row>
                    <Col sm={9}>
                        <Calendar
							divergencesByDay={divergencesByDay}
							divergencesByChannel={divergencesByChannel}
							firstDay={this.state.firstDay}
							selectedChannel={selectedChannel}
							totalMonths={this.state.totalMonths}
                        />
					</Col>
                    <Col sm={3} className="pl-5">
                        <FormattedMessage id="CompareAvailability.Channels" />

						<hr className="mt-2" />

						{divergencesByChannel && divergencesByChannel.map((channel, key) => {
							const channelWithError = channel.code || channel.message;

							return <div key={key}>
								<Badge color={selectedChannel && selectedChannel === channel.channelInstanceId ? "primary" : "light"} className="p-2 my-1 w-100 text-left">
									<Row>
										<Col className="pointer" onClick={_ => { if(!channelWithError) this.handleChannel(channel.channelInstanceId) }}>
											<i className="fas fa-circle fa-xs" style={{ color: channel.color }} />
											<span className="ml-2">
												{channel.channelInstanceName} ({channel.channelPropertyCode})
											</span>
										</Col>
										<Col className="col-3 text-right pl-0">
											{channel.daysWithDivergences ?
												<div>
													<span className="pointer" onClick={() => this.toggleChannelOptions(key)}>
														{channel.daysWithDivergences} <FormattedMessage id="CompareAvailability.Day(s)" />
														<i className="fas fa-exclamation-triangle text-warning ml-2"/>
													</span>

													{channel.areOptionsOpen ?
														<Card className="borde-0 shadow p-2 text-left" body
															style={{ position: 'absolute', zIndex: 1, color: 'black', right: '7px', top: '15px', fontWeight: '500' }}
														>
															<span className="py-2 px-2 text-muted">
																<FormattedMessage id="CompareAvailability.ActionsTo" /> {channel.channelInstanceName}
															</span>
															<hr className="my-2" />
															<span className="py-2 px-2 overbglight pointer" onClick={() => this.sendPrice(channel.channelInstanceId, key)}>
																<i className="fas fa-hand-holding-usd mr-2" />
																<FormattedMessage id="ChannelConfig.Push" />
															</span>
														</Card>
													: ''}
												</div>
												:
												channelWithError ?
													<>
														<i className="fas fa-times text-danger pr-1" id={`Channel-${key}`} />

														<CustomToolTip placement="right" target={`Channel-${key}`}>
															<b> <FormattedMessage id="CompareAvailability.Code" />: </b> {channel.code}
															<br />
															<b> <FormattedMessage id="CompareAvailability.Message" />: </b> {channel.message}
														</CustomToolTip>
													</>
													:
													<i className="fas fa-check text-success" />
											}
										</Col>
									</Row>
								</Badge>
							</div>
						})}

                        {this.state.channelReports ?
                            <div className="mt-5">
                                <Row className="pointer" id="ChannelsNotImplemented">
                                    <Col>
										{this.state.channelReports.length} <FormattedMessage id="CompareAvailability.ChannelsNotImplemented" />
                                    </Col>
                                    <Col className="text-right col-2">
                                        <i className="fas fa-chevron-down ml-2"></i>
                                    </Col>
                                </Row>
                                <hr className="mt-2" />

                                <UncontrolledCollapse toggler="ChannelsNotImplemented">
                                    <div className="scrollableDiv" style={{ height: '30vh', overflow: 'auto' }}>
                                        {this.state.channelReports.map((channel, key) =>
                                            <div key={key}>
                                                <Badge color="light" className="p-2 my-1 w-100 text-left" style={{ whiteSpace: 'normal', fontWeight: '500' }}>                                                    
													<div> {channel.channelInstanceName} ({channel.channelPropertyCode}) - GetAvailability not implemented </div>
                                                </Badge>
                                            </div>
                                        )}
                                    </div>
                                </UncontrolledCollapse>
                            </div>
                        : ''}
					</Col>
				</Row>
					
			</StyledCard>
		)
	}
}

export default injectIntl(CompareAvailability);