import React from "react";
import { Row, Col, Label } from 'reactstrap';
import { FormattedMessage, injectIntl, FormattedNumber, FormattedHTMLMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import CustomToolTip from '../../../Utils/CustomToolTip';
import CustomSelect from '../../Base/Common/CustomSelect';
import AvailabilityCalendar from '../../Inventory/AvailabilityCalendar';
import ComparePmsCellExample from "./ComparePmsCellExample";


class CalendarPms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            weekDays: moment.weekdaysShort(),
            calendarRows: [],
            checked: false,
            dataType: false
        }
    }

    componentWillReceiveProps(nextProps) {
        var changeDate = moment(this.props.firstDay).isSame(nextProps.firstDay, 'month') === false || moment(this.props.endDate).isSame(nextProps.endDate, 'month') === false;
        var changeOcc = !this.props.selectedOcc && nextProps.occupationSelect && nextProps.occupationSelect[0];
        if (changeOcc && !changeDate) {
            this.setState({ selectedOcc: nextProps.occupationSelect.length > 0 && nextProps.occupationSelect[0].value })
        }
        else if (changeDate) {
            this.setState({ nextProps, selectedOcc: nextProps.occupationSelect.length > 0 && nextProps.occupationSelect[0].value }, () => this.renderCalendar(this.props.firstDay));
        } else {
            this.setState({ nextProps }, () => this.renderCalendar(this.props.firstDay));
        }
    }

    renderCalendar = (startDate) => {
        let blank = this.getBlankCells(startDate);
        let daysInMonth = this.getDaysInMonth(startDate);
        let totalSlots = [...blank, ...daysInMonth];
        let cells = [], rows = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows 
                cells = []; // empty container 
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) { // when end loop we add remain date
                while (cells.length < 7) {
                    cells.push({ day: "" })
                }
                rows.push(cells);
            }
        });

        let blankSec = this.getBlankCells(startDate, true);
        let daysInMonthSec = this.getDaysInMonth(startDate, true);
        let totalSlotsSec = [...blankSec, ...daysInMonthSec];
        let cellsSec = [], rowsSec = [];

        totalSlotsSec.forEach((row, i) => {
            if (i % 7 !== 0) {
                cellsSec.push(row); // if index not equal 7 that means not go to next week
            } else {
                rowsSec.push(cellsSec); // when reach next week we contain all td in last week to rows 
                cellsSec = []; // empty container 
                cellsSec.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlotsSec.length - 1) { // when end loop we add remain date
                while (cellsSec.length < 7) {
                    cellsSec.push({ day: "" })
                }
                rowsSec.push(cellsSec);
            }
        });

        this.setState({ calendarRows: [rows, rowsSec], sumbittedStartDate: startDate });
    }

    getFirstDayOfMonth = (startDate, sec) => {
        let dateObject = startDate;

        if (sec) dateObject = moment(startDate).add(1, 'month');

        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    changeSelectedOcc = (occ) => {
        const { mapping } = this.state;
        if (!mapping || mapping.priceMode !== 1) {
            this.setState({ selectedOcc: occ });
        }
    }

    getDaysInMonth = (startDate, sec) => {
        let daysInMonth = [];

        if (sec) {
            for (let d = 1; d <= moment(startDate).add(1, 'month').daysInMonth(); d++) {
                let date = moment(startDate).add(1, 'month').format('MM') + '-' + d + '-' + moment(startDate).add(1, 'month').format('YYYY');
                daysInMonth.push({
                    day: d,
                    moment: moment(date)
                });
            }
        }
        else {
            for (let d = 1; d <= moment(startDate).daysInMonth(); d++) {
                let date = moment(startDate).format('MM') + '-' + d + '-' + moment(startDate).format('YYYY');
                daysInMonth.push({
                    day: d,
                    moment: moment(date)
                });
            }
        }

        return daysInMonth;
    }

    getBlankCells = (startDate, sec) => {
        let blanks = [];

        for (let i = 0; i < this.getFirstDayOfMonth(startDate, sec); i++) {
            blanks.push({
                day: ""
            });
        }

        return blanks
    }

    renderCell = (dayAvailability) => {
        const { selectedOcc } = this.state;
        const cm = dayAvailability && dayAvailability.cmPrice && dayAvailability.cmPrice.length > 0 && dayAvailability.cmPrice.filter(({ position }) => position == selectedOcc).length > 0
            ? dayAvailability.cmPrice.filter(({ position }) => position == selectedOcc)[0] : 0.00;
        const pms = dayAvailability && dayAvailability.pmsPrice && dayAvailability.pmsPrice.length > 0 && dayAvailability.pmsPrice.filter(({ position }) => position == selectedOcc).length > 0
            ? dayAvailability.pmsPrice.filter(({ position }) => position == selectedOcc)[0] : 0.00;
        return (
            <>
                <Row>
                    <Col className="text-center mt-4" style={{ fontSize: '13px' }} >
                        {cm.price == pms.price ?
                            <>
                                <span id={`cmprice${moment(dayAvailability.hotelDate).format("DD-MM")}`}>
                                    <FormattedNumber value={cm.price} style="currency" currency={global.hotelCurrency} minimumFractionDigits={2} />
                                </span>
                                <CustomToolTip placement="bottom" className="align-items-left" target={`cmprice${moment(dayAvailability.hotelDate).format("DD-MM")}`}>
                                    <Row>
                                        <Col>
                                            <FormattedMessage id="CalendarPms.OkComparePrice" />
                                        </Col>
                                    </Row>
                                </CustomToolTip>
                            </>
                            :
                            <>
                                <Row>
                                    <Col>
                                        <span style={{ color: '#dc3545' }} id={`cmprice${moment(dayAvailability.hotelDate).format("DD-MM")}`}>
                                            <FormattedNumber value={cm.price} style="currency" currency={global.hotelCurrency} minimumFractionDigits={2} /> |
                                        </span>
                                    </Col>
                                    <Col>
                                        <span style={{ color: '#dc3545' }} id={`cmprice${moment(dayAvailability.hotelDate).format("DD-MM")}`}>
                                            <FormattedNumber value={pms.price} style="currency" currency={global.hotelCurrency} minimumFractionDigits={2} />
                                        </span>
                                    </Col>
                                </Row>
                                <CustomToolTip placement="bottom" className="align-items-left" target={`cmprice${moment(dayAvailability.hotelDate).format("DD-MM")}`}>
                                    <Row>
                                        <Col>
                                            <FormattedMessage id="CalendarPms.PriceDifferences" />
                                        </Col>
                                    </Row>
                                </CustomToolTip>
                            </>
                        }
                    </Col>
                </Row>
            </>
        );
    }

    render() {
        const { weekDays, calendarRows, block, dataType, selectedOcc } = this.state;
        const { inventory, occupationSelect, disableButtons, diffPriceList, diffAvailList } = this.props;
        return (
            <BlockUi tag="div" blocking={block} className="h-100">
                <div>
                    {inventory ?
                        <Row>
                            <Col className="col-9 mt-2 pl-4 text-center">
                                <AvailabilityCalendar
                                    blockedWeekdays={weekDays}
                                    alwaysUpdate={true}
                                    isNavigable={true}
                                    cellHeight="11vh"
                                    startDate={this.state.sumbittedStartDate}
                                    calendarMonths={calendarRows}
                                    buildCalendarStruct={this.renderCalendar}
                                    currAvailabilities={inventory}
                                    renderCell={this.renderCell}
                                    ignorePercentage={true}
                                    dataType={dataType}
                                />
                            </Col>
                            <Col className="pl-4">
                                <Row className="mt-1">
                                    <Col>
                                        <Label style={{ fontSize: '13px' }}> <FormattedMessage id="CalendarPms.Occupation" /></Label>
                                        <CustomSelect
                                            id="cusotmselectcomparepmsdata"
                                            options={occupationSelect}
                                            value={occupationSelect.find(({ value }) => value === selectedOcc)}
                                            onChange={(combo) => this.changeSelectedOcc(combo?.value)}
                                            isDisabled={disableButtons}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="mt-4">
                                        {diffAvailList && diffAvailList.length > 0 ?
                                            <Row id="diffAvaiList" className="mt-4">
                                                <Col>
                                                    <FormattedHTMLMessage id="CalendarPms.AvailDiffDays" values={{ occ: diffAvailList.length }} />
                                                </Col>
                                            </Row>
                                            :
                                            <Row className="mt-4">
                                                <Col>
                                                    {inventory ?
                                                        <FormattedMessage id="CalendarPms.NoAvailDiffDays" />
                                                        : ''}
                                                </Col>
                                            </Row>
                                        }
                                        {diffPriceList && diffPriceList.length > 0 && diffPriceList.length > 0 ?
                                            <Row id="diffPriceList" className="mt-2">
                                                <Col>
                                                    <FormattedHTMLMessage id="CalendarPms.PriceDiffDays" values={{ occ: diffPriceList.length }} />
                                                </Col>
                                            </Row>
                                            :
                                            <Row className="mt-2">
                                                <Col>
                                                    {inventory ?
                                                        <FormattedMessage id="CalendarPms.NoPriceDiffDays" />
                                                        : ''}
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                </Row>
                                <Row style={{ position: 'absolute', top: '35vh', width: '100%' }}>
                                    <Col>
                                        {!block && inventory ?
                                            <div className='pb-5'>
                                                <ComparePmsCellExample />
                                            </div>
                                            : ''}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        :
                        ''
                    }
                </div>
            </BlockUi>
        );
    }
}

export default injectIntl(CalendarPms);