import React, { Component } from 'react';
import { Button, Col, Modal, ModalHeader, ModalBody, Row } from "reactstrap";
import { FormattedMessage } from "react-intl";
import CustomSelect from '../Base/Common/CustomSelect';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import { putAPI } from '../Base/API';
import { handleNotification } from '../Base/Notification';

export class ModalForceResendData extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            selectedChannels: []
        };
    }

    handleSave = () => {
        if (!this.form.current.reportValidity()) {
            return;
        }

        const { selectedChannels } = this.state;

        const req = {
            request: {
                channelInstanceIds: selectedChannels
            }
        }

        this.setState({ block: true });
        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ChannelDashboard.ChannelsHaveBeenScheduleToSendData" />, <FormattedMessage id="General.Success" />);
                }
            }

            this.setState({ error: errorMessage, block: false });

        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/ForceResendToChannels', req);
    }

    handleChangeChannel = (combo) => {
        if (combo) {
            this.setState({ selectedChannels: combo.map(cmb => cmb.value) });
        }
        else {
            this.setState({ selectedChannels: [] });
        }
    }

    handleSelectAllChannels = () => {
        const { channels } = this.props;
        const { selectedChannels } = this.state;

        if (channels.length === selectedChannels.length) {
            this.setState({ selectedChannels: [] });
        }
        else {
            this.setState({ selectedChannels: channels.map(cmb => cmb.value) });
        }
    }

    render() {
        const { channels, isOpen, toggle } = this.props;
        const { block, error, selectedChannels } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle} className="modal-lg">
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="ChannelDashboard.PushToChannels" />
                </ModalHeader>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <form ref={this.form}>
                            <Row className="mb-2">
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" onClick={this.handleSave}>
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-10">
                                    <CustomSelect options={channels} isMulti={true} value={channels.filter(chn => selectedChannels.some(sltChn => chn.value === sltChn))} onChange={this.handleChangeChannel} placeholder={<FormattedMessage id="ChannelDashboard.ChooseChannels" />} required/>
                                </Col>
                                <Col className="col-2 pl-0 text-right">
                                    <Button className="btn btn-sm btn-host" onClick={this.handleSelectAllChannels}>
                                        {channels.length === selectedChannels.length ? <FormattedMessage id="MajorBulkUpdateModal.RemoveAllChannels" /> : <FormattedMessage id="MajorBulkUpdateModal.SelectAllChannels" />}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}