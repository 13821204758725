import React, { Component } from 'react';
import { Col, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getAPI } from '../Base/API';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import moment from 'moment';

export class CcAccessModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            accesses: []
        };
    }

    componentDidMount() {
        const { reservationId } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    this.setState({ error: errorMessage, block: false, accesses: data.response });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/Reservation/v1/reservationCCView/${reservationId}/users`);
    }

    render() {
        const { isOpen, toggle } = this.props;
        const { block, error, accesses } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={_ => toggle('ccAccessModal', null)} style={{ minWidth: "40%" }}>
                <ModalHeader toggle={_ => toggle('ccAccessModal', null)}>
                    <FormattedMessage id="ReservationDetail.CcHistoric" />
                </ModalHeader>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        {
                            accesses.map((access, key) =>
                                <div className="mb-2" key={key}>
                                    <div>
                                        <span style={{ textAlign: 'justify', textAlignLast: 'center', maxWidth: "100%", display: 'inline' }}>
                                            <FormattedMessage id="ReservationDetail.User" />
                                            <b>{access.user}</b>
                                            <FormattedMessage id="ReservationDetail.ViewAt" />
                                            <b>{moment(access.viewAt).format("YYYY-MM-DD HH:mm:ss")}</b>
                                        </span>
                                    </div>
                                    <hr/>
                                </div>
                            )
                        }
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}