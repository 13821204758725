import React, { Component } from 'react';
import { Button, Col, Row, Card } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CreatableSelect from 'react-select/creatable';
import { BlankCard, ConfirmActionModal } from '../../../Base/Common/CommonUIComponents';
import { handleNotification } from '../../../Base/Notification';
import { getAPI, postAPI, putAPI } from '../../../Base/API';

export class StepRates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            rateCodes: [],
            pmsRateCodes: [],
            modalBan: false,
            modalPrices: false,
            modalData: false,
        }
    }

    componentDidMount() {
        this.getRateCodes();
    }

    getRateCodes = () => {
        const { selectedHotel } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }

            if (data) {
                let rateCodes = [];

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    rateCodes = data.response.map(r => {
                        return {
                            value: r.id,
                            label: `${r.name} (${r.description})`,
                            code: r.name,
                            description: r.description,
                            pmsId: r.pmsId
                        }
                    });
                }

                this.setState({ rateCodes }, this.getPMSRateCodes);
            }
            else {
                this.setState({ block: false, error: errorMessage, rateCodes: [] });
            }
        }, `/api/Rate/Rate/v1/Info?hotelId=${selectedHotel.value}`);
    }

    getPMSRateCodes = () => {
        const { selectedHotel } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockPms: false });
                return;
            }
            if (data) {
                let pmsRateCodes = [];

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.response && data.response.length > 0) {
                    pmsRateCodes = data.response.map(r => {
                        return {
                            value: r.id.toString(),
                            label: `${r.rateCodeName} (PMS Id: ${r.id})`,
                            isDisabled: r.isUsed
                        }
                    });
                }

                this.setState({ error: errorMessage, block: false, pmsRateCodes });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/hotel/v1/pmsInvokeAction/${selectedHotel.value}/rateCodes`);
    }

    associatePmsId = (combo, index) => {
        if (isNaN(parseInt(combo.value))) {
            const data = { response: null, errors: [{ code: "400", message: <FormattedMessage id="ConfigRoomCatRate.NewPmsIdErr" /> }], warnings: [] };
            handleNotification(data);
        }
        else {
            const { rateCodes } = this.state;
            rateCodes[index].pmsId = combo.value;
            this.setState({ rateCodes });
        }
    }

    save = () => {
        const { rateCodes } = this.state;

        const request = {
            request: {
                rateCodePmsIds: rateCodes
                    .filter(rateCode => rateCode.pmsId !== null)
                    .map(rateCode => {
                        const pair = {
                            rateCodeId: rateCode.value,
                            pmsId: rateCode.pmsId
                        };
                        return pair;
                    })
            }
        };

        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.warnings && data.warnings.length > 0) {
                    handleNotification(data);
                }
                else {
                    if (data.warnings && data.warnings.length > 0) {
                        handleNotification(data);
                        handleNotification({}, <FormattedMessage id="ConfigCategoriesAndAvailability.PmsIdAssociated" />, <FormattedMessage id="General.Success" />);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="ConfigCategoriesAndAvailability.PmsIdAssociated" />, <FormattedMessage id="General.Success" />);
                    }
                }
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/hotel/v1/ratecodes/PostPmsWithRateCodeAsync`, request);
    }

    removeRestrictions = () => {
        const { selectedHotel } = this.props;

        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ChangeOperationMode.RestrictionsWereRemoved" />, <FormattedMessage id="General.Success" />);
                }
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/hotel/v1/restrictions/${selectedHotel.value}`);
    }

    removePrices = () => {
        const { selectedHotel } = this.props;

        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ChangeOperationMode.PricesWereRemoved" />, <FormattedMessage id="General.Success" />);
                }
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/hotel/v1/prices/${selectedHotel.value}`);
    }

    removeData = () => {
        const { selectedHotel } = this.props;

        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification({}, <FormattedMessage id="ChangeOperationMode.DataWereRemoved" />, <FormattedMessage id="General.Success" />);
                    handleNotification({ warnings: [{ code: 'Change Operation Mode', message: 'Please change operation mode on license page' }] });
                }
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/hotel/v1/ratecodes/${selectedHotel.value}`);
    }

    toggleBan = () => {
        this.setState(prevState => ({ modalBan: !prevState.modalBan }));
    }

    togglePrices = () => {
        this.setState(prevState => ({ modalPrices: !prevState.modalPrices }));
    }

    toggleData = () => {
        this.setState(prevState => ({ modalData: !prevState.modalData }));
    }

    render() {
        const { block, error, modalBan, modalData, modalPrices, rateCodes, pmsRateCodes } = this.state;

        return (
            <BlankCard block={block} error={error}>
                {
                    modalBan ?
                        <ConfirmActionModal
                            block={block}
                            error={error}
                            text={<FormattedMessage id="ChangeOperationMode.RemoveData" values={{ data: <FormattedMessage id="BulkUpdate.Restrictions" />} } />}
                            toggleModal={this.toggleBan}
                            modal={modalBan}
                            actionFunction={this.removeRestrictions}
                        />
                        :
                        ''
                }
                {
                    modalPrices ?
                        <ConfirmActionModal
                            block={block}
                            error={error}
                            text={<FormattedMessage id="ChangeOperationMode.RemoveData" values={{ data: <FormattedMessage id="BulkUpdate.EditPrices" /> }} />}
                            toggleModal={this.togglePrices}
                            modal={modalPrices}
                            actionFunction={this.removePrices}
                        />
                        :
                        ''
                }
                {
                    modalData ?
                        <ConfirmActionModal
                            block={block}
                            error={error}
                            text={<FormattedMessage id="ChangeOperationMode.RemoveData" values={{ data: <FormattedMessage id="ChangeOperationMode.RateCodeData" /> }} />}
                            toggleModal={this.toggleData}
                            modal={modalData}
                            actionFunction={this.removeData}
                        />
                        :
                        ''
                }
                <Row className="mb-2">
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm mr-2" onClick={this.save}>
                            <span className="fas fa-save" />
                        </Button>
                        <Button className="btn btn-host btn-sm mr-2" onClick={this.toggleBan} title="Clear Restrictions">
                            <span className="fas fa-ban" />
                        </Button>
                        <Button className="btn btn-host btn-sm mr-2" onClick={this.togglePrices} title="Clear Prices">
                            <span className="fas fa-dollar-sign" />
                        </Button>
                        <Button className="btn btn-host btn-sm" onClick={this.toggleData} title="Clear Data">
                            <span className="fas fa-trash-alt" />
                        </Button>
                    </Col>
                </Row>
                <div>
                    <Card body className="mb-3 shadow py-2 text-muted ">
                        <Row>
                            <Col> <FormattedMessage id="ChangeOperationMode.RateCodeCode" /></Col>
                            <Col sm={6}> <FormattedMessage id="RoomCategorySetupDetails.Description" /> </Col>
                            <Col> PMS Id</Col>
                            <Col className="col-1 text-center "> <FormattedMessage id="generic.Status" /> </Col>
                        </Row>
                    </Card>
                </div>
                {
                    rateCodes.map((rateCode, key) =>
                        <Card body className="mb-2 py-2 shadow overbglight" key={key}>
                            <Row key={key} className="align-items-center">
                                <Col> {rateCode.code} </Col>
                                <Col sm={6}> {rateCode.description} </Col>
                                <Col>
                                    <CreatableSelect
                                        options={pmsRateCodes}
                                        value={pmsRateCodes.find(rc => rc.value == rateCode.pmsId)}
                                        onChange={(combo) => this.associatePmsId(combo, key)}
                                    />
                                </Col>
                                <Col className="col-1 d-flex justify-content-center">
                                    <span className="d-flex align-items-center justify-content-center" style={{ backgroundColor: rateCode.pmsId ? 'green' : 'red', height: '25px', width: '25px', color: 'white', borderRadius: '20px' }}>
                                        <i className={`fas ${rateCode.pmsId ? `fa-check` : `fa-times`} fa-xs`} />
                                    </span>
                                </Col>
                            </Row>
                        </Card>
                    )
                }
            </BlankCard>
        );
    }
}