import React, { Component } from 'react';
import { Row, Col, FormGroup, FormText, Label, Input, Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader, ModalFooter, CustomInput, UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";
import { getAPI, postAPI, deleteAPI, putAPI } from "../../Base/API";
import { BinaryActionModal, ConfirmActionModal, StyledCard } from '../../Base/Common/CommonUIComponents';
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from "../../Base/Notification";
import AddOccupancy from "./AddOccupancy";
import { RateLabelModal } from "./RateLabelModal";
import { getCurrencyCode, getOperationModes } from '../../Base/Common/ReferenceDataFunctions';
import Authorization from '../../Base/Authorization';

export class EditRate extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.form = React.createRef();
        this.renderWithChildren = this.renderWithChildren.bind(this);
        this.renderWithAdults = this.renderWithAdults.bind(this);
        this.addOccupancy = this.addOccupancy.bind(this);
        this.removeOccupancy = this.removeOccupancy.bind(this);
        this.state = {
            activeTab: '1',
            createOccupancy: false,
            blockPackage: true,
            blockRoomCat: true,
            blockBlockIds: false,
            packageList: [],
            packages: [],
            roomList: [],
            selectedRate: this.props.copiedRate ? this.props.copiedRate : {
                currencyCode: global.hotelCurrency ? global.hotelCurrency : getCurrencyCode()[0].value,
                defaultPax: [],
                sortOrder: 0
            },
            dependencyList: [
                { 'label': <FormattedMessage id="EditRate.DependencyMain" />, 'value': 'main' },
                { 'label': <FormattedMessage id="EditRate.DependencyDependent" />, 'value': 'dependent' }
            ],
            dependencyValueTypes: [
                {
                    value: 'amount',
                    label: '€'
                },
                {
                    value: 'percentage',
                    label: '%'
                }
            ],
            dependecyOrderType: null,
            dependecyOrderTypes: [
                {
                    value: 'onlyAmount',
                    label: <FormattedMessage id="EditRate.OnlyAmount" />,
                    dependecyOrder: null,
                },
                {
                    value: 'onlyPercentage',
                    label: <FormattedMessage id="EditRate.OnlyPercentage" />,
                    dependecyOrder: null
                },
                {
                    value: 'amountPlusPercentage',
                    label: <FormattedMessage id="EditRate.AmountPlusPercentage" />,
                    dependecyOrder: 'amount'
                },
                {
                    value: 'percentagePlusAmount',
                    label: <FormattedMessage id="EditRate.PercentagePlusAmount" />,
                    dependecyOrder: 'percentage'
                }
            ],
            dependencyRounds: [
                {
                    value: 1,
                    label: '1'
                },
                {
                    value: 2,
                    label: '2'
                },
                {
                    value: 3,
                    label: '3'
                }
            ],
            ratesToDepend: this.props.ratesToDepend,
            blockIds: [],
            canDeleteRate: false,
            isOccupancyDerived: 0,
            displayPrices: [],
            wasRateCodechanged: false,
            confirmactionModal: false,
            isCurrencyCodeDisabled: false,
            isOriginalDependent: false,
            rateLabelModal: false,
            dependencyFields: ["dependencyType", "dependencyParent", "dependecyOrder", "dependencyPercent", "dependencyValue", "excludeChild", "dependencyRound"],
            originRoomCategory: null,
            originPackage: null,
            originDependencyType: null,
            copyPricesModal: false
        };
    }

    componentDidMount() {
        this.getPackageList();
        this.getRoomCategory();
        if (this.props.id || this.props.copiedRate) {
            this.getRateById(this.props.id || this.props.copiedRate.id);
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    getRateById(id) {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ blocking: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ blocking: false, error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                }
                if (data.response) {
                    let { ratesToDepend } = this.state;
                    ratesToDepend = ratesToDepend.filter(rate => rate.value !== data.response.id);

                    let dependecyOrderType = data.response.dependecyOrder ?
                        this.state.dependecyOrderTypes.find(tp => tp.dependecyOrder === data.response.dependecyOrder)?.value
                        :
                        (data.response.dependencyPercent === 0 ? this.state.dependecyOrderTypes[0].value : this.state.dependecyOrderTypes[1].value);

                    if (!dependecyOrderType) {
                        dependecyOrderType = data.response.dependencyPercent === 0 ? this.state.dependecyOrderTypes[0].value : this.state.dependecyOrderTypes[1].value;
                    }

                    data.response.dependencyType = data.response.dependencyParent !== 0 ? this.state.dependencyList[1].value : this.state.dependencyList[0].value;

                    if (this.props.copiedRate) {
                        data.response.id = null;
                        data.response.name = null;
                    }

                    let displayPrices = [];
                    if (data.response.defaultPax) {
                        this.sortDefaultPax(data.response.defaultPax);
                        displayPrices = this.getDisplayPrices(data.response.defaultPax);
                    }

                    this.setState({
                        block: false,
                        selectedRate: data.response,
                        dependecyOrderType, ratesToDepend: ratesToDepend,
                        canDeleteRate: data.response && !data.response.active,
                        isOccupancyDerived: data.response.isOccupancyDerived ? 2 : 0,
                        displayPrices,
                        isCurrencyCodeDisabled: true,
                        isOriginalDependent: data.response && data.response?.dependencyType === this.state.dependencyList[1].value,
                        originDependencyData: this.getOriginDependencyFields(data.response),
                        originRoomCategory: data.response.categoryId,
                        originPackage: data.response.packageId,
                        originDependencyType: data.response?.dependencyType
                    }, () => this.getBlockIds(this.state.selectedRate.categoryId));
                }
                else {
                    this.setState({ block: false });
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Rate/Rate/v1/${id}`);
    }

    getPackageList() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                let active = [], inactive = [];

                data.response && data.response.forEach((item) => {
                    if (item.active)
                        active.push({ 'value': item.id, 'label': item.description })
                    else
                        inactive.push({ 'value': item.id, 'label': item.description });
                })

                var combo = [
                    {
                        label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="generic.active" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{active.length}</span> </div >,
                        options: active
                    },
                    {
                        label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="generic.inactive" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{inactive.length}</span> </div >,
                        options: inactive
                    },
                ];

                this.setState({ blockPackage: false, packages: data.response, packageList: combo });
            }
        }, '/api/hotel/Package/v1');
    }

    getRoomCategory() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });

                return;
            }
            if (data) {
                let pl = [];
                data.response && data.response.map((item) => pl.push({ 'value': item.id, 'label': item.description }))
                this.setState({ blockRoomCat: false, roomList: pl });
            }
        }, '/api/hotel/RoomCategory/v1/Info');
    }

    validateFields = (event) => {
        event.preventDefault();
        const { dependencyList, dependencyValueTypes, isOccupancyDerived, originPackage, originRoomCategory, selectedRate, wasRateCodechanged, originDependencyType } = this.state;

        const addOccupancyButton = document.getElementById('addOccupancyButton');
        if (selectedRate.defaultPax && selectedRate.defaultPax.length === 0) {
            addOccupancyButton.setCustomValidity(this.props.intl.formatMessage({ id: "EditRate.OccupanciesAreEmpty" }));
        }
        else if (isOccupancyDerived > 0) {
            const paxAdults = selectedRate.defaultPax.filter(pax => pax.derivedType !== null);
            const main = selectedRate.defaultPax.find(({ isBaseOccupancy }) => isBaseOccupancy);
            const derivedTypes = paxAdults.reduce((acc, curr) => {
                if (curr.derivedType === dependencyValueTypes[0].value) {
                    acc.amount++;
                }
                else if (curr.derivedType === dependencyValueTypes[1].value) {
                    acc.percentage++;
                }
                return acc;
            }, { amount: 0, percentage: 0 });
            if (paxAdults.length === derivedTypes.amount || paxAdults.length === derivedTypes.percentage) {
                const isOkItem = paxAdults.reduce((acc, curr) => {
                    if ((curr.derivedAmount <= 0 && (curr.adults < main.adults || (curr.adults === main.adults && curr.childrens < main.childrens))) || (curr.derivedAmount >= 0 && (curr.adults > main.adults || (curr.adults === main.adults && curr.childrens > main.childrens)))) {
                        acc.derivedAmount = curr.derivedAmount;
                    }
                    else {
                        acc.isOk = false;
                        acc.derivedAmount = Number.MAX_SAFE_INTEGER;
                    }

                    return acc;
                }, { isOk: true, derivedAmount: 0 });

                if (!isOkItem.isOk) {
                    addOccupancyButton.setCustomValidity(this.props.intl.formatMessage({ id: "EditRate.OccupanciesPricesAreIncorrect" }));
                }
                else {
                    addOccupancyButton.setCustomValidity("");
                };

                if (paxAdults.filter(({ derivedAmount, derivedType }) => derivedAmount === 0 && derivedType === "percentage").length > 0) {
                    addOccupancyButton.setCustomValidity(this.props.intl.formatMessage({ id: "EditRate.PercentageError" }));
                }
            }
            else {
                addOccupancyButton.setCustomValidity("");
            }
        }
        else {
            addOccupancyButton.setCustomValidity("");
        }

        if (this.form.current.reportValidity()) {
            if (wasRateCodechanged) {
                this.setState({ confirmactionModal: true });
            }
            else if (selectedRate.id && selectedRate?.dependencyType === dependencyList[0].value && originDependencyType === dependencyList[0].value && (selectedRate.packageId !== originPackage || selectedRate.categoryId !== originRoomCategory)) {
                this.setState({ copyPricesModal: true });
            }
            else {
                this.saveRate();
            }
        }
    }

    saveRate = (queryString) => {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(prevState => ({ block: false, modalData: { ...prevState.modalData }, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false }));
                    return;
                }
                if (data.response) {
                    if (this.state.selectedRate.id == null) {
                        this.props.onCloseModal();
                        this.props.onCreateRoomRate();
                    }

                    if (this.hasChangesInDepencies(this.state.selectedRate)) {
                        const notification = {
                            warnings: [{ code: <FormattedMessage id="EditRate.ThereAreDependencyChanges" />, message: <FormattedMessage id="EditRate.DependencyChangesMessage" /> }]
                        }
                        handleNotification(notification);
                    }

                    this.setState(({
                        block: false,
                        selectedRate: data.response,
                        canDeleteRate: data.response && !data.response.active,
                        confirmactionModal: false,
                        wasRateCodechanged: false,
                        originDependencyData: this.getOriginDependencyFields(data.response),
                        originRoomCategory: data.response?.categoryId,
                        originPackage: data.response?.packageId,
                        originDependencyType: data.response?.dependencyType,
                        copyPricesModal: false
                    }), () => this.props.updateRateCode(data.response));
                    handleNotification(data, <FormattedMessage id="EditRate.SaveRateSuccess" />, <FormattedMessage id="General.Success" />);
                }
                else {
                    this.setState(({ block: false }));
                }
            }
            else {
                this.setState(({ block: false }));
            }
        }, `/api/Rate/Rate/v1/RateCode${queryString ? `${queryString}` : ``}`, JSON.stringify(this.state.selectedRate));
    }

    getOriginDependencyFields = (rate) => {
        var obj = {}

        if (rate) {
            this.state.dependencyFields.forEach(field => {
                obj[field] = rate[field];
            })
        }

        return obj;
    }

    hasChangesInDepencies = (currentValues) => {
        return this.state.dependencyFields.some(depField => currentValues[depField] !== this.state.originDependencyData[depField]);
    }

    removeRate = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(prevState => ({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false }));
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="EditRate.RateRemoved" />, <FormattedMessage id="General.Success" />);
                    this.props.updateRateCode(data.response, true);
                    this.toggleModal('deleteRate');
                    this.props.closeModalRate();
                }
                this.setState(({ block: false }));
            }
        }, `/api/Rate/Rate/v1/RateCode/${this.state.selectedRate.id}`);
    }

    updateSelectedRate(evt) {
        const val = evt.target && evt.target.value;
        const name = evt.target && evt.target.name;
        this.setState(prevState => ({
            ...prevState,
            selectedRate: { ...prevState.selectedRate, [name]: val }
        }));
    }

    handleRateCodeChange = (evt) => {
        if (evt.target) {
            const { name, value } = evt.target;

            this.setState(prevState => ({
                ...prevState,
                selectedRate: { ...prevState.selectedRate, [name]: value },
                wasRateCodechanged: global.sendRoomRate && prevState.selectedRate.id !== null && prevState.selectedRate.id !== undefined
            }));
        }
    }

    updateCustomSelect(el, evt) {
        if (evt) {
            const val = el && el.value;
            const name = evt && evt.name;
            this.setState(prevState => ({
                ...prevState,
                selectedRate: { ...prevState.selectedRate, [name]: val }
            }));
        }
    }

    handleDependencyParent(el, evt) {
        if (evt) {
            const { selectedRate } = this.state;
            let currencyCode = selectedRate.currencyCode;
            let val = null;
            if (el) {
                currencyCode = el.currencyCode;
                val = el.value;
            }

            const name = evt && evt.name;
            this.setState(prevState => ({
                ...prevState,
                selectedRate: { ...prevState.selectedRate, [name]: val, currencyCode: currencyCode }
            }));
        }
    }

    handleDependencyRound(el, evt) {
        if (evt) {
            const { selectedRate } = this.state;
            selectedRate.dependencyRound = el && el.value;

            this.setState({ selectedRate });
        }
    }

    updateDerivedStatus(el, evt) {
        if (evt) {
            const { dependencyList, isOccupancyDerived, isOriginalDependent } = this.state;
            const val = el && el.value;
            const name = evt && evt.name;
            const isDependent = val === dependencyList[1].value; //dependencyList[1].value = "dependent" 

            if (isDependent && isOccupancyDerived > 0) { // Cannot be a dependent and have a derived occupation
                this.setState(prevState => ({
                    ...prevState,
                    selectedRate: { ...prevState.selectedRate, [name]: val },
                    isCurrencyCodeDisabled: true
                }), () => this.handleIsOccupancyDerived({ target: { checked: false } }));
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    selectedRate: { ...prevState.selectedRate, [name]: val },
                    //Disable currency code combo box if rate code is depedent or change from dependent to main
                    isCurrencyCodeDisabled: isDependent || !(!isDependent && isOriginalDependent)
                }));
            }
        }
    }

    updateCategory(el, evt) {
        if (evt) {
            const { selectedRate } = this.state;
            const val = el && el.value;
            const name = evt && evt.name;
            selectedRate[name] = val;
            selectedRate.blockId = 0;
            this.setState({ selectedRate }, () => this.getBlockIds(val));
        }
    }

    updateInventoryType(el, evt) {
        if (evt) {
            const { selectedRate } = this.state;
            const val = el && el.value;
            const name = evt && evt.name;
            selectedRate[name] = val;
            selectedRate.blockId = 0;
            this.setState({ selectedRate });
        }
    }

    renderWithChildren(adults, children) {
        return <span><i className="fas fa-user mr-1" />({adults}) + <i className="fas fa-child mr-1" />({children})</span>;
    }

    renderWithAdults(adults) {
        return <span><i className="fas fa-user mr-1" />({adults})</span>;
    }

    addOccupancy(newOccupancy) {
        const { dependencyValueTypes, isOccupancyDerived, selectedRate } = this.state;
        if (isOccupancyDerived === 2) {
            newOccupancy.derivedType = dependencyValueTypes[0].value;
            newOccupancy.derivedAmount = 0;
        }

        selectedRate.defaultPax.push(newOccupancy);
        this.sortDefaultPax(selectedRate.defaultPax);
        const displayPrices = this.getDisplayPrices(selectedRate.defaultPax);


        this.setState({ createOccupancy: false, selectedRate, displayPrices });
    }

    sortDefaultPax(defaultPax) {
        defaultPax.sort((d1, d2) => d1.adults - d2.adults !== 0 ? d1.adults - d2.adults : d1.childrens - d2.childrens);
    }

    removeOccupancy(defaultPax) {
        const newDefaultPax = this.state.selectedRate.defaultPax;
        const idxToRemove = newDefaultPax && newDefaultPax.indexOf(defaultPax);
        if (idxToRemove > -1) {
            if (this.state.selectedRate.displayPricesFrom === newDefaultPax[idxToRemove].adults) {
                this.state.selectedRate.displayPricesFrom = null;
            }

            const elementsToRemove = 1;
            newDefaultPax.splice(idxToRemove, elementsToRemove);
            let isOccupancyDerived = this.state.isOccupancyDerived;
            if (defaultPax.isBaseOccupancy) {
                isOccupancyDerived = 0;
                newDefaultPax.forEach(dp => {
                    dp.isBaseOccupancy = false;
                    dp.derivedType = null;
                    dp.derivedAmount = null;
                });
            }

            const displayPrices = this.getDisplayPrices(newDefaultPax);

            this.setState(prevState => ({
                selectedRate: {
                    ...prevState.selectedRate,
                    defaultPax: newDefaultPax
                },
                isOccupancyDerived,
                displayPrices
            }));
        }
    }

    toggleModal = (modal) => {
        this.setState(prevstate => ({
            [modal]: !prevstate[modal]
        }));
    }

    setDependecyOrder(el, evt) {
        if (evt) {
            const { selectedRate } = this.state;

            let dependecyOrderType = null;
            let dependecyOrder = null;
            if (el) {
                dependecyOrderType = el.value;
                dependecyOrder = el.dependecyOrder;
            }
            const name = evt.name;

            selectedRate[name] = dependecyOrder;
            selectedRate.dependencyValue = 0;
            selectedRate.dependencyPercent = 0;
            this.setState({ dependecyOrderType, selectedRate });
        }
    }

    handleSelectRateStatus(evt) {
        if (evt.target) {
            const { selectedRate } = this.state;
            selectedRate.active = evt.target.checked;
            this.setState({ selectedRate });
        }
    }
    updateExcludeChild(evt) {
        if (evt.target) {
            const { selectedRate } = this.state;
            selectedRate.excludeChild = evt.target.checked;
            this.setState({ selectedRate });
        }
    }

    getBlockIds(categoryId) {
        this.setState({ blockBlockIds: true });
        getAPI(result => {
            const { error, data } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ blockBlockIds: false, error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    const blockIds = data.response.map(resp => {
                        const blockId = {
                            value: resp.blockId,
                            label: resp.description
                        };
                        return blockId;
                    });
                    this.setState({ blockIds: blockIds });
                }
            }
            this.setState({ blockBlockIds: false, error: errorMessage, block: false });
        }, `/api/Rate/Rate/v1/Block?active=true&roomCategoryId=${categoryId}`);
    }

    handleIsOccupancyDerived = (evt) => {
        if (evt && evt.target) {
            const { checked } = evt.target;
            const { selectedRate } = this.state;

            let isOccupancyDerived;

            if (checked) {
                isOccupancyDerived = 1;
            }
            else {
                isOccupancyDerived = 0;
                selectedRate.defaultPax.forEach(dp => {
                    dp.isBaseOccupancy = false;
                    dp.derivedType = null;
                    dp.derivedAmount = null;
                });
            }

            this.setState({ isOccupancyDerived, selectedRate });
        }
    }

    handleIsBaseOccupancy = (evt, idx) => {
        if (evt && evt.target) {
            const { checked } = evt.target;
            const { dependencyValueTypes, selectedRate } = this.state;

            if (checked) {
                selectedRate.defaultPax
                    .filter(dp => dp.childrens === 0)
                    .forEach(dp => {
                        dp.derivedType = dependencyValueTypes[0].value;
                        dp.derivedAmount = 0;
                    });
                selectedRate.defaultPax[idx].isBaseOccupancy = checked;
                selectedRate.defaultPax[idx].derivedType = null;
                selectedRate.defaultPax[idx].derivedAmount = null;
                this.setState({ selectedRate, isOccupancyDerived: 2 });
            }
            else {
                selectedRate.defaultPax.forEach(dp => {
                    dp.isBaseOccupancy = false;
                    dp.derivedType = null;
                    dp.derivedAmount = null;
                });
                this.setState({ selectedRate, isOccupancyDerived: 0 });
            }
        }
    }

    handleDerivedType = (combo, idx) => {
        const { selectedRate } = this.state;
        selectedRate.defaultPax[idx].derivedType = combo && combo.value;
        this.setState({ selectedRate });
    }

    handleDerivedAmount = (evt, idx) => {
        if (evt && evt.target) {
            const { value } = evt.target;
            const { selectedRate } = this.state;

            selectedRate.defaultPax[idx].derivedAmount = value ? parseInt(value) : value;
            this.setState({ selectedRate });
        }
    }

    getDisplayPrices = (defaultPax) => {
        return defaultPax.filter(pax => pax.childrens === 0).map(pax => {
            const opt = {
                value: pax.adults,
                label: pax.name
            };
            return opt;
        });
    }

    handleDisplayPricesFrom = (combo, evt) => {
        if (evt) {
            const { selectedRate } = this.state;
            selectedRate.displayPricesFrom = combo && combo.value;
            this.setState({ selectedRate });
        }
    }

    recalculatePrices = () => {
        const { selectedRate } = this.state;
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="EditRate.RecalculatePricesSuccess" />, <FormattedMessage id="General.Success" />);
                }
            }
            this.setState(({ block: false, error: errorMessage }));
        }, `/api/Price/Price/Price/v1/${selectedRate.id}/RecalculatePrices`)
    }

    handleCurrency = (combo) => {
        const { selectedRate } = this.state;
        selectedRate.currencyCode = combo && combo.value;
        this.setState({ selectedRate });
    }

    handleLabel = (combo, evt) => {
        if (evt) {
            const { selectedRate } = this.state
            let rateLabelModal = false;

            if (combo) {
                if (combo.isToCreate === true) {
                    rateLabelModal = true;
                    this.setState({ rateLabelModal });
                }
                else {
                    selectedRate.rateLabelId = combo.value;
                    this.setState({ selectedRate }, () => this.props.updateLabelsOnChangeSelect(selectedRate));
                }
            }
            else {
                selectedRate.rateLabelId = null;
                this.setState({ selectedRate }, () => this.props.updateLabelsOnChangeSelect(selectedRate));
            }
        }
    }

    toggleRateLabelModal = () => {
        this.setState(prevState => ({ rateLabelModal: !prevState.rateLabelModal }));
    }

    updateLabels = (newLabel) => {
        const { selectedRate } = this.state;
        selectedRate.rateLabelId = newLabel.id;
        this.setState({ selectedRate }, _ => this.props.updateLabels(newLabel));
    }

    getDependencyOptions = () => {
        if (global.PMSType === 'NewHotel') {
            return [this.state.dependencyList[0]];
        }
        else {
            return this.state.dependencyList;
        }
    }

    getInventoryOptions = () => {
        const inventoryMode = [
            { 'label': <FormattedMessage id="EditRate.InventoryOwn" />, 'value': 1 },
            { 'label': <FormattedMessage id="EditRate.InventoryReal" />, 'value': 0 }
        ];

        if (global.PMSType === 'NewHotel') {
            return [inventoryMode[1]];
        }
        else {
            return inventoryMode;
        }
    }

    render() {
        const { selectedRate, blockIds, canDeleteRate, dependencyValueTypes, isOccupancyDerived, displayPrices, confirmactionModal, isCurrencyCodeDisabled, rateLabelModal, copyPricesModal, originRoomCategory, originPackage } = this.state;

        const selectedPackageDetails = this.state.packages && this.state.packages.find(el => el.id === this.state.selectedRate.packageId);

        const defaultPaxs = this.state.selectedRate.defaultPax;

        function checkDeleteOccupancy(defaultPax) {
            return defaultPax.childrens === 0 && defaultPaxs.filter(d => d.adults === defaultPax.adults).find(d => d.childrens > 0) != null;
        }

        return (
            <BlockUi tag="div" blocking={this.state.block || this.state.blockLabels}>
                {confirmactionModal ?
                    <ConfirmActionModal
                        block={this.state.block}
                        error={this.state.error}
                        text={<FormattedHTMLMessage id="EditRate.ConfirmUpdateRateCode" />}
                        toggleModal={() => this.setState({ confirmactionModal: false })}
                        modal={confirmactionModal}
                        actionFunction={_ => this.saveRate()}
                    />
                    : ''}
                {
                    copyPricesModal ?
                        <BinaryActionModal
                            block={this.state.block}
                            error={this.state.error}
                            text={<FormattedHTMLMessage id="EditRate.ConfirmCopyPrices" />}
                            toggleModal={() => this.setState(prevState => ({ copyPricesModal: !prevState.copyPricesModal }))}
                            negativeFunction={_ => this.saveRate(`?copyPrices=false&originPackage=${originPackage}&originRoomCategory=${originRoomCategory}`)}
                            modal={copyPricesModal}
                            affirmativeFunction={_ => this.saveRate(`?copyPrices=true&originPackage=${originPackage}&originRoomCategory=${originRoomCategory}`)}
                        />
                        :
                        ''
                }
                {
                    rateLabelModal ?
                        <RateLabelModal isOpen={rateLabelModal} toggle={this.toggleRateLabelModal} updateLabels={this.updateLabels} />
                        :
                        ''
                }

                {this.props.deleteLabelModal ?
                    <ConfirmActionModal
                        block={this.state.block}
                        error={this.state.error}
                        text={<FormattedHTMLMessage id="EditRate.ConfirmDeleteRatelabel" values={{ rateLabel: this.props.selectedLabelDelete.label }} />}
                        toggleModal={() => this.props.toggleDeleteLabelModal(null)}
                        modal={this.props.deleteLabelModal}
                        actionFunction={this.props.deleteLabel}
                    />
                    : ''}

                <Modal isOpen={this.state.createOccupancy} toggle={() => this.toggleModal('createOccupancy')} fade={false} className="modal-lg " >
                    <ModalHeader toggle={() => this.toggleModal('createOccupancy')} >
                        <FormattedMessage id="EditRate.CreateOccupancy" />
                    </ModalHeader>
                    <ModalBody className="modal-lg px-0 pb-0">
                        <AddOccupancy defaultPax={this.state.selectedRate.defaultPax} addNewOccupancy={this.addOccupancy} />
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.deleteRate} toggle={() => this.toggleModal('deleteRate')} fade={false} className="modal-md" style={{ minWidth: 0 }}>
                    <ModalBody className="text-center py-4">
                        <FormattedMessage id="EditRate.ConfirmDeleteText" values={{ rateName: selectedRate.name }} />
                    </ModalBody>
                    <ModalFooter className="py-2">
                        <Button className="btn btn-sm btn-host mr-2" onClick={this.removeRate}>
                            <FormattedMessage id="EditRate.Confirm" />
                        </Button>
                        <Button className="btn btn-sm" onClick={() => this.toggleModal('deleteRate')}>
                            <FormattedMessage id="EditRate.Cancel" />
                        </Button>
                    </ModalFooter>
                </Modal>

                <Card className="shadow border-0 pb-3">
                    <ErrorAlert error={this.state.error} />
                    <CardHeader className="bg-white border-bottom ">
                        <Row className="align-items-center">
                            <Col>
                                <i className="fas fa-gift mr-2" />
                                {this.state.selectedRate.id ? <FormattedMessage id="EditRate.EditRate" /> : <FormattedMessage id="EditRate.CreateRate" />}
                            </Col>
                            <Col className="text-right">
                                <Button className="float-right btn btn-sm btn-host" onClick={this.validateFields}>
                                    <i className="fas fa-save" />
                                </Button>

                                {
                                    //global.operationMode !== 'PmsFull'
                                    (global.operationMode !== getOperationModes()[0].value || global.PMSType === 'NewHotel') && canDeleteRate ?
                                        <Button className="float-right btn btn-sm btn-host mr-2" onClick={() => this.toggleModal('deleteRate')}>
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                        : ''
                                }
                                {selectedRate.isOccupancyDerived && selectedRate.id ?
                                    <span>
                                        <Button id="recalculatePricesBtnId" className="float-right btn btn-sm btn-host mr-2" onClick={this.recalculatePrices}>
                                            <i className="fas fa-undo" />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target="recalculatePricesBtnId">
                                            <FormattedMessage id="EditRate.RecalculatePrices" />
                                        </UncontrolledTooltip>
                                    </span>
                                    : ''}
                            </Col>
                        </Row>
                    </CardHeader>
                    <form ref={this.form} >
                        <CardBody className="py-2">
                            <Row form>
                                <Col>
                                    <FormGroup row>
                                        <Label for="name" sm={12} lg={2}>
                                            <FormattedMessage id="EditRate.Code" />
                                        </Label>
                                        <Col sm={12} lg={10}>
                                            <FormattedMessage id="EditRate.Code" >
                                                {placeholder => <Input type="text" required name="name" id="name" placeholder={placeholder} value={this.state.selectedRate.name || ''} onChange={this.handleRateCodeChange.bind(this)} />}
                                            </FormattedMessage>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col className="col-6 col-lg-5">
                                    <FormGroup row>
                                        <Label for="name" sm={12} lg={5}>
                                            <FormattedMessage id="EditRate.Label" />
                                        </Label>
                                        <Col sm={12} lg={7} className='pl-3 pl-lg-0'>
                                            <CustomSelect
                                                options={this.props.labels}
                                                onChange={this.handleLabel}
                                                value={this.props.labels.filter(lbl => lbl.value === selectedRate.rateLabelId)}
                                                placeholder={<FormattedMessage id="EditRate.Label" />}
                                                isClearable
                                                isSearchable={false}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6 col-lg-2">
                                    <FormGroup row className='d-flex align-items-center'>
                                        <Label for="name" sm={12} lg={8}>
                                            <FormattedMessage id="EditRate.Status" />
                                        </Label>
                                        <Col sm={12} lg={4}>
                                            <CustomInput type="switch" name="ratecodeactive" id="ratecodeactive" onChange={this.handleSelectRateStatus.bind(this)} checked={this.state.selectedRate.active} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6 col-lg-3">
                                    <FormGroup row>
                                        <Label for="name" sm={12} lg={4}>
                                            <FormattedMessage id="EditRate.CurrencyCode" />
                                        </Label>
                                        <Col sm={12} lg={8}>
                                            <CustomSelect
                                                icon={"fas fa-coins"}
                                                options={getCurrencyCode()}
                                                value={getCurrencyCode().find(currencyCode => currencyCode.value === this.state.selectedRate.currencyCode)}
                                                isSearchable
                                                onChange={this.handleCurrency}
                                                required={true}
                                                isDisabled={isCurrencyCodeDisabled}
                                            />
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6 col-lg-2">
                                    <FormGroup row>
                                        <Label for="name" sm={12} lg={6}>
                                            <FormattedMessage id="EditRate.SortOrder" />
                                        </Label>
                                        <Col sm={12} lg={6}>
                                            <Input type="number" required name="sortOrder" id="sortOrder" min="0" value={this.state.selectedRate.sortOrder} onChange={this.updateSelectedRate.bind(this)} />
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col>
                                    <FormGroup row>
                                        <Label for="description" sm={12} lg={2}>
                                            <FormattedMessage id="EditRate.Description" />
                                        </Label>
                                        <Col sm={12} lg={10}>
                                            <FormattedMessage id="EditRate.Description" >
                                                {placeholder => <Input type="textarea" required name="description" id="description" placeholder={placeholder} value={this.state.selectedRate.description} onChange={this.updateSelectedRate.bind(this)} />}
                                            </FormattedMessage>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>

                        <div className="container-fluid content-row">
                            <Row className="mb-2">
                                <Col className="col-12 col-lg-6 mb-2">
                                    <StyledCard icon="fas fa-user mr-2" title={"EditRate.Ocupations"}>

                                        <Row className={"mb-3 " + (global.isMobile ? ' pb-3 border-bottom ' : '')}>
                                            <Col className="pr-0">
                                                {!(global.operationMode === 'PmsFull' && global.PMSType === 'NewHotel') ?
                                                    <CustomInput type="checkbox" name="isOccupancyDerived" disabled={this.state.selectedRate?.dependencyType === "dependent"} id="isOccupancyDerived" checked={isOccupancyDerived > 0} onChange={this.handleIsOccupancyDerived}>
                                                        <FormattedMessage id="EditRate.IsOccupancyDerived" />
                                                    </CustomInput>
                                                : ''}
                                            </Col>
                                            <Col className="col-3">
                                                <Input id="addOccupancyButton" required style={{ opacity: 0, width: "100%", height: 0, position: "absolute", zIndex: -1 }} defaultValue="DummyValue" />
                                                <Button className="btn btn-sm btn-host float-right" onClick={() => this.toggleModal('createOccupancy')}>
                                                    <i className="fas fa-plus" />
                                                </Button>
                                            </Col>
                                        </Row>
                                        {
                                            this.state.selectedRate && this.state.selectedRate.defaultPax && this.state.selectedRate.defaultPax.map((defaultPax, key) =>
                                                defaultPax.childrens > 0 ?
                                                    <Row className={"mb-2" + (global.isMobile ? ' pb-3 pt-1 border-bottom ' : '')} key={key}>
                                                        <Col className="col-9 pr-0">
                                                            {this.renderWithChildren(defaultPax.adults, defaultPax.childrens)} {defaultPax.name}
                                                        </Col>
                                                        <Col className="col-3">
                                                            <Button className="btn btn-sm btn-host float-right" onClick={() => this.removeOccupancy(defaultPax)} disabled={checkDeleteOccupancy(defaultPax)}>
                                                                <i className="fas fa-trash-alt" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    <Row className={"mb-2 " + (global.isMobile ? ' pb-3 pt-1 border-bottom ' : '')} key={key}>
                                                        <Col className="order-1">
                                                            {this.renderWithAdults(defaultPax.adults)} {defaultPax.name}
                                                        </Col>
                                                        {isOccupancyDerived === 0 ?
                                                            ''
                                                            :
                                                            isOccupancyDerived === 1 ?
                                                                <Col className="col-10 col-lg-5 order-3 order-lg-2">
                                                                    <CustomInput type="checkbox" name={`isBaseOccupancy${defaultPax.adults}`} id={`isBaseOccupancy${defaultPax.adults}`} checked={defaultPax.isBaseOccupancy} onChange={evt => this.handleIsBaseOccupancy(evt, key)}>
                                                                        <FormattedMessage id="EditRate.IsBaseOccupancy" />
                                                                    </CustomInput>
                                                                </Col>
                                                                :
                                                                isOccupancyDerived === 2 ?
                                                                    <Col className="col-10 col-lg-5 order-3 order-lg-2">
                                                                        {
                                                                            defaultPax.isBaseOccupancy ?
                                                                                <CustomInput type="checkbox" name={`isBaseOccupancy${defaultPax.adults}`} id={`isBaseOccupancy${defaultPax.adults}`} checked={defaultPax.isBaseOccupancy} onChange={evt => this.handleIsBaseOccupancy(evt, key)}>
                                                                                    <FormattedMessage id="EditRate.IsBaseOccupancy" />
                                                                                </CustomInput>
                                                                                :
                                                                                <Row>
                                                                                    <Col className="pr-0">
                                                                                        <CustomSelect
                                                                                            name={`derivedType${defaultPax.adults}`} id={`derivedType${defaultPax.adults}`}
                                                                                            options={dependencyValueTypes}
                                                                                            value={dependencyValueTypes.find(type => type.value === defaultPax.derivedType)}
                                                                                            onChange={evt => this.handleDerivedType(evt, key)}
                                                                                            required
                                                                                        />
                                                                                    </Col>
                                                                                    <Col className="pl-2">
                                                                                        <Input type="number" name={`derivedAmount${defaultPax.adults}`} id={`derivedAmount${defaultPax.adults}`} value={defaultPax.derivedAmount} onChange={evt => this.handleDerivedAmount(evt, key)} required />
                                                                                    </Col>
                                                                                </Row>
                                                                        }
                                                                    </Col>
                                                                    :
                                                                    ''
                                                        }
                                                        <Col className="col-1 order-2 order-lg-3">
                                                            <Button className="btn btn-sm btn-host float-right" onClick={() => this.removeOccupancy(defaultPax)} disabled={checkDeleteOccupancy(defaultPax)}>
                                                                <i className="fas fa-trash-alt" />
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                            )
                                        }
                                        <Row className="mt-2">
                                            <Col className={"col-12 col-lg-3 " + + (global.isMobile ? ' mt-2 ' : '')}>
                                                <FormattedMessage id="EditRate.DisplayPrice" />
                                            </Col>
                                            <Col className="col-12 col-lg-9">
                                                <CustomSelect options={displayPrices} onChange={this.handleDisplayPricesFrom} value={displayPrices.filter(prc => prc.value === selectedRate.displayPricesFrom)} isClearable />
                                            </Col>
                                        </Row>
                                    </StyledCard>
                                </Col>
                                <Col className="col-12 col-lg-6 mb-2">
                                    <StyledCard icon="fas fa-boxes mr-2" block={this.state.blockRoomCat || this.state.blockBlockIds} title={"EditRate.Inventory"}>
                                        <FormGroup row>
                                            <Label for="categoryId" className="col-12 col-lg-4">
                                                <FormattedMessage id="EditRate.RoomCategory" />
                                            </Label>
                                            <Col className="col-12 col-lg-8">
                                                <BlockUi tag="div" blocking={this.state.blockRoomCat}>
                                                    <CustomSelect required name="categoryId" options={this.state.roomList} value={this.state.roomList.filter(el => el.value === this.state.selectedRate.categoryId)} placeholder={<FormattedMessage id="EditRate.RoomCategory" />} onChange={this.updateCategory.bind(this)} />
                                                </BlockUi>
                                            </Col>
                                        </FormGroup>
                                        {global.operationMode !== 'None' || global.PMSType === 'NewHotel' ?
                                            <div>
                                                <Row className="mb-1">
                                                    <Col className="col-12 col-lg-4">
                                                        <FormattedMessage id="EditRate.InventoryType" />:
                                                    </Col>
                                                    <Col className="col-12 col-lg-8">
                                                        <CustomSelect id="inventoryMode" name="inventoryMode"
                                                            required
                                                            options={this.getInventoryOptions()} 
                                                            value={this.getInventoryOptions().filter(el => el.value === this.state.selectedRate.inventoryMode)}
                                                            placeholder={<FormattedMessage id="EditRate.InventoryType" />}
                                                            onChange={this.updateInventoryType.bind(this)}
                                                        />
                                                    </Col>
                                                </Row>
                                                {
                                                    selectedRate.inventoryMode !== 0 ?
                                                        <Row className="mb-1">
                                                            <Col className="col-12 col-lg-4">
                                                                <FormattedMessage id="EditRate.Block" />:
                                                            </Col>
                                                            <Col className="col-12 col-lg-8">
                                                                <CustomSelect id="blockId" name="blockId" required options={blockIds} value={blockIds.filter(el => el.value === this.state.selectedRate.blockId)} placeholder={<FormattedMessage id="EditRate.Block" />} onChange={this.updateCustomSelect.bind(this)} />
                                                            </Col>
                                                        </Row>
                                                        :
                                                        <div />
                                                }
                                            </div>
                                            : ''}
                                    </StyledCard>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12 col-lg-6 mb-2">
                                    <StyledCard icon="fas fa-sitemap mr-2" title={"EditRate.Dependencies"}>
                                        <Row className="mb-1">
                                            <Col className="col-12 col-lg-4">
                                                <FormattedMessage id="EditRate.DependencyType" />:
                                            </Col>
                                            <Col className="col-12 col-lg-8">
                                                <CustomSelect
                                                    name="dependencyType"
                                                    placeholder={<FormattedMessage id="EditRate.DependencyType" />}
                                                    options={this.getDependencyOptions()}
                                                    value={this.state.dependencyList.find(dependency => dependency.value === this.state.selectedRate?.dependencyType)}
                                                    onChange={this.updateDerivedStatus.bind(this)}
                                                    required
                                                    isClearable
                                                />
                                            </Col>
                                        </Row>
                                        {this.state.selectedRate?.dependencyType === 'dependent' ?
                                            <>
                                                <Row className="mb-1">
                                                    <Col className="col-12 col-lg-4">
                                                        <FormattedMessage id="EditRate.DependencyParent" />:
                                                    </Col>
                                                    <Col className="col-12 col-lg-8">
                                                        <CustomSelect
                                                            name="dependencyParent"
                                                            options={this.state.ratesToDepend}
                                                            value={this.state.ratesToDepend.find(rate => rate.value === this.state.selectedRate.dependencyParent)}
                                                            onChange={this.handleDependencyParent.bind(this)}
                                                            required
                                                            isClearable
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col className="col-12 col-lg-4">
                                                        <FormattedMessage id="EditRate.DependecyOrder" />:
                                                    </Col>
                                                    <Col className="col-12 col-lg-8">
                                                        <CustomSelect
                                                            name="dependecyOrder"
                                                            options={this.state.dependecyOrderTypes}
                                                            value={this.state.dependecyOrderTypes.find(type => type.value === this.state.dependecyOrderType)}
                                                            onChange={this.setDependecyOrder.bind(this)}
                                                            isClearable
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col className="col-12 col-lg-4" />
                                                    <Col className="col-12 col-lg-8">
                                                        {
                                                            this.state.dependecyOrderType === 'onlyAmount' ?
                                                                <FormGroup row>
                                                                    <Col>
                                                                        <DependencyValueInput
                                                                            dependencyValue={this.state.selectedRate.dependencyValue}
                                                                            updateSelectedRate={this.updateSelectedRate.bind(this)}
                                                                        />
                                                                    </Col>
                                                                </FormGroup>
                                                                :
                                                                this.state.dependecyOrderType === 'onlyPercentage' ?
                                                                    <FormGroup row>
                                                                        <Col>
                                                                            <DependencyPercentageInput
                                                                                dependencyPercent={this.state.selectedRate.dependencyPercent}
                                                                                updateSelectedRate={this.updateSelectedRate.bind(this)}
                                                                            />
                                                                        </Col>
                                                                    </FormGroup>
                                                                    :
                                                                    this.state.dependecyOrderType === 'amountPlusPercentage' ?
                                                                        <FormGroup row>
                                                                            <Col className="col-12 col-sm-6">
                                                                                <DependencyValueInput
                                                                                    dependencyValue={this.state.selectedRate.dependencyValue}
                                                                                    updateSelectedRate={this.updateSelectedRate.bind(this)}
                                                                                />
                                                                            </Col>
                                                                            <Col className="col-12 col-sm-6">
                                                                                <DependencyPercentageInput
                                                                                    dependencyPercent={this.state.selectedRate.dependencyPercent}
                                                                                    updateSelectedRate={this.updateSelectedRate.bind(this)}
                                                                                />
                                                                            </Col>
                                                                        </FormGroup>
                                                                        :
                                                                        this.state.dependecyOrderType === 'percentagePlusAmount' ?
                                                                            <FormGroup row>
                                                                                <Col className="col-12 col-sm-6">
                                                                                    <DependencyPercentageInput
                                                                                        dependencyPercent={this.state.selectedRate.dependencyPercent}
                                                                                        updateSelectedRate={this.updateSelectedRate.bind(this)}
                                                                                    />
                                                                                </Col>
                                                                                <Col className="col-12 col-sm-6">
                                                                                    <DependencyValueInput
                                                                                        dependencyValue={this.state.selectedRate.dependencyValue}
                                                                                        updateSelectedRate={this.updateSelectedRate.bind(this)}
                                                                                    />
                                                                                </Col>
                                                                            </FormGroup>
                                                                            :
                                                                            <div />
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col className="col-12 col-lg-4">
                                                        <FormattedMessage id="EditRate.ExcludeChild" />:
                                                    </Col>
                                                    <Col className="col-12 col-lg-8">
                                                        <CustomInput type="switch" name="excludeChild" id="excludeChild" onChange={this.updateExcludeChild.bind(this)} checked={this.state.selectedRate.excludeChild} />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-1">
                                                    <Col className="col-12 col-lg-4">
                                                        <FormattedMessage id="EditRate.DependencyRound" />:
                                                    </Col>
                                                    <Col className="col-12 col-lg-8">
                                                        <CustomSelect
                                                            name="dependencyRound"
                                                            options={this.state.dependencyRounds}
                                                            value={this.state.dependencyRounds.find(rnd => rnd.value === this.state.selectedRate.dependencyRound)}
                                                            onChange={this.handleDependencyRound.bind(this)}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                            : ''}
                                    </StyledCard>
                                </Col>
                                <Col className="col-12 col-lg-6 mb-2">
                                    <StyledCard block={this.state.blockPackage} icon="fas fa-sitemap mr-2" title={"EditRate.Package"}>
                                        <Row className="mb-1">
                                            <Col className="col-12 col-lg-4">
                                                <FormattedMessage id="EditRate.Package" />:
                                            </Col>
                                            <Col className="col-12 col-lg-8">
                                                <CustomSelect
                                                    required
                                                    name="packageId"
                                                    options={this.state.packageList}
                                                    value={this.state.packageList?.flatMap(pl => pl.options).filter(el => el.value === this.state.selectedRate.packageId)}
                                                    placeholder={<FormattedMessage id="EditRate.Package" />}
                                                    onChange={this.updateCustomSelect.bind(this)}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col className="col-12 col-lg-4">
                                                <FormattedMessage id="EditRate.MealPlan" />:
                                            </Col>
                                            <Col className="col-12 col-lg-8">
                                                <b>{selectedPackageDetails && selectedPackageDetails.mealPlan ? selectedPackageDetails.mealPlan.description : 'N/A'}</b>
                                            </Col>
                                        </Row>
                                        {/*
                                        <Row className="mb-1">
                                            <Col className="col-5">
                                                <FormattedMessage id="EditRate.CancelationPolicy" />:
                                            </Col>
                                            <Col className="col-7">
                                                <b>{selectedPackageDetails && selectedPackageDetails.cancelationPolicy ? selectedPackageDetails.cancelationPolicy.name : 'N/A'}</b>
                                            </Col>
                                                
                                        </Row>
                                        */}
                                    </StyledCard>
                                </Col>
                            </Row>
                        </div>
                    </form>
                </Card>
            </BlockUi>
        );
    }
}
export default injectIntl(EditRate)


const DependencyValueInput = ({ dependencyValue, updateSelectedRate }) => {
    return !global.isMobile ?
        <>
            <FormattedMessage id="EditRate.Value">
                {placeholder =>
                    <Input
                        type="number"
                        required
                        name="dependencyValue"
                        id="dependencyValue"
                        placeholder={placeholder}
                        value={dependencyValue !== null || dependencyValue !== undefined ? dependencyValue : ''}
                        onChange={updateSelectedRate}
                    />
                }
            </FormattedMessage>
            <FormText>
                <FormattedMessage id="EditRate.DependencyAmount" />(€)
            </FormText>
        </>
        :
        <>
            <div className="mt-1">
                <FormattedMessage id="EditRate.DependencyAmount" />(€) :
            </div>
            <FormattedMessage id="EditRate.Value">
                {placeholder =>
                    <Input
                        type="number"
                        required
                        name="dependencyValue"
                        id="dependencyValue"
                        placeholder={placeholder}
                        value={dependencyValue !== null || dependencyValue !== undefined ? dependencyValue : ''}
                        onChange={updateSelectedRate}
                    />
                }
            </FormattedMessage>
        </>
}


const DependencyPercentageInput = ({ dependencyPercent, updateSelectedRate }) => {
    return !global.isMobile ?
        <>
            <FormattedMessage id="EditRate.Percentage">
                {placeholder =>
                    <Input
                        type="number"
                        required
                        name="dependencyPercent"
                        id="dependencyPercent"
                        placeholder={placeholder}
                        value={dependencyPercent !== null || dependencyPercent !== undefined ? dependencyPercent : ''}
                        onChange={updateSelectedRate}
                    />
                }
            </FormattedMessage>
            <FormText>
                <FormattedMessage id="EditRate.DependencyPercent" />(%)
            </FormText>
        </>
        :
        <>
            <div className="mt-1">
                <FormattedMessage id="EditRate.DependencyPercent" />(%) :
            </div>
            <FormattedMessage id="EditRate.Percentage">
                {placeholder =>
                    <Input
                        type="number"
                        required
                        name="dependencyPercent"
                        id="dependencyPercent"
                        placeholder={placeholder}
                        value={dependencyPercent !== null || dependencyPercent !== undefined ? dependencyPercent : ''}
                        onChange={updateSelectedRate}
                    />
                }
            </FormattedMessage>
        </>
}