import moment from 'moment';
import React, { Component } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FormattedMessage ,injectIntl } from 'react-intl';
import { Button, Card, Col, InputGroup, Row } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard, SelectChannelConfig } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Notification";


class InvalidReservationDashboard extends Component {

    state = {
        block: true,
        status: { value: false },
        invalidReservationList: [],
        startDate: moment().subtract(7, 'day'),
        endDate: moment(),
        channelCode: null
    }

    componentDidMount() {
        this.getInvalidReservationList();

    }

    getInvalidReservationList() {
        this.setState({ block: true });

        var param = '';
        
        if (this.state.startDate)
            param += '?StartDate=' + this.state.startDate.format('YYYY-MM-DD')
        if (this.state.endDate)
            param += '&EndDate=' + this.state.endDate.format('YYYY-MM-DD')
        if (this.state.channelConfigId)
            param += '&ChannelConfigId=' + this.state.channelConfigId


        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0)
                    handleNotification(data, "search", 'Success');
                else
                    this.setState({ invalidReservationList: data.data, block: false });
            }
        }, '/api/Price/Reservation/v1/invalidReservations' + param);
    }

    setFilterState = (name, combo) => {
        this.setState({ [name]: combo });

    }

    setFilterSelectedChannel = (name, combo) => {
        var label = combo ? combo.label : null
        var val = combo ? combo.value : null
        this.setState({
            channelCode: label,
            channelConfigId: val
        }
        );
    }




    render() {

        const helpContent =
            <div>
                <FormattedMessage id="InvalidReservationDashboard.title" />
                <hr />
                <FormattedMessage id="InvalidReservationDashboard.Help" />
            </div>


        const columns = [
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "InvalidReservationDashboard.HotelName" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            },
            {
                dataField: 'propertyCode',
                text: this.props.intl.formatMessage({ id: "InvalidReservationDashboard.PropertyCode" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            },{
                dataField: 'reservationId',
                text: this.props.intl.formatMessage({ id: "InvalidReservationDashboard.ReservationId" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            }, {
                dataField: 'channelCode',
                text: this.props.intl.formatMessage({ id: "InvalidReservationDashboard.ChannelCode" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            }, 
                {
                dataField: 'retries',
                    text: this.props.intl.formatMessage({ id: "InvalidReservationDashboard.Retries" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            }
            , {
                dataField: 'createdAt',
                text: this.props.intl.formatMessage({ id: "InvalidReservationDashboard.CreatedAt" }),
                sort: true,
                headerStyle: () => {
                    return { width: "10%" };
                }
            },

            {
                dataField: 'payload',
                text: this.props.intl.formatMessage({ id: "InvalidReservationDashboard.Payload" }),
                sort: true

            
            }];


        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-exclamation-circle'} title={'InvalidReservationDashboard.title'} help={helpContent}>
                <Row>
                    <Col className="col-3">
                        <SelectChannelConfig required={true} name={'channelConfigId'} icon={'fas fa-plug'} value={this.state.channelConfigId} onChangeFunc={this.setFilterSelectedChannel} />
                    </Col>
                    <Col>
                        <InputGroup size="sm text-right" >
                            <DateRangePicker
                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                startDateId="your_unique_start_date_id_announcement"
                                isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                                endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                endDateId="your_unique_end_date_id_announcement"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <Button className="float-right mr-2 btn-sm btn-host btn" onClick={this.getInvalidReservationList.bind(this)}><i className="fas fa-search"></i></Button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">

                            <CustomTable
                                data={this.state.invalidReservationList ? this.state.invalidReservationList : []}
                                columns={columns}
                                page={this.state.currentPage}
                                sizePerPage={this.state.resultsPerPage}
                                onTableChange={this.handleTableChange}
                                totalSize={this.state.invalidReservationList && this.state.invalidReservationList.length}
                                shadow={false}
                                search={true}
                                exportCSV={true}
                                remote={false}
                            />

                        </Card>

                    </Col>

                </Row>


            </StyledCard>


        );
    }

};
export default injectIntl(InvalidReservationDashboard)