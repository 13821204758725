import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Badge, Card, CardBody, CardHeader, Row, Col, Button } from 'reactstrap';
import { ActiveInactiveStatusBadge } from '../Base/Common/CommonUIComponents';
import Authorization from '../Base/Authorization';
import CustomSelect from "../Base/Common/CustomSelect";
import CustomToolTip from '../../Utils/CustomToolTip';

class NotMapped extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            rateList: [],
            rateCodeChannelList: [],
            rateOptions: [],
            rateLabelOptions: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;

        this.getMappings(this.props.rateList);
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.rateList) !== JSON.stringify(nextProps.rateList)) {
            this.getMappings(nextProps.rateList);
        }
    }

    getMappings = (data) => {
        let rateCodeChannelList = [];
        let roomOptions = [];
        let rateOptions = [];
        let rateLabelOptions = [];

        //saved on parent to be used in bulkOperationUnmapped
        const roomsWithUnmappedData = [];


        data && data.forEach(el => {
            let newRateOptions = [];
            el.rateCodeChannelListNotMapped = [];

            el.rateList && el.rateList.forEach(rate => {
                if (rate.mappingList.length === 0 || !rate.mappingList.find(map => map.roomCode)) {
                    el.rateCodeChannelListNotMapped.push({ ...rate, roomCodeId: el.id, isVisible: true });

                    //rates
                    newRateOptions.push({ value: rate.id, label: rate.description });
                }
            })


            if (el.rateCodeChannelListNotMapped.length > 0) {
                el.isVisibleOnNotMapped = this.state.selectedRooms?.some(c => el.id === c.value);

                //rooms
                roomOptions.push({ value: el.id, label: el.description });

                //rates
                rateOptions = rateOptions.concat(newRateOptions);

                rateCodeChannelList.push(el);


                //Fill roomsWithUnmappedData
                if (!el.isDisabledToAdd) {
                    roomsWithUnmappedData.push({
                        id: el.id,
                        code: el.code,
                        description: el.description,
                        rateCodeChannelListNotMapped: el.rateCodeChannelListNotMapped
                    });
                }
            }

            if(rateCodeChannelList.length > 0) {
                rateCodeChannelList.forEach(el => {
                    if(el.rateLabelId && el.rateLabel && !rateLabelOptions.some(rl => rl.value === el.rateLabelId)){
                        rateLabelOptions.push({ value: el.rateLabelId, label: el.rateLabel });
                    }
                })
            }
        })

        this.setState({ rateCodeChannelList, roomOptions, rateOptions, rateLabelOptions }, () => this.props.saveUnmappedData(roomsWithUnmappedData));
    }

    filterBySelect = (combo, filterType) => {
        let { rateCodeChannelList } = this.state;

        if (combo && combo.length > 0) {
            rateCodeChannelList.forEach(el => {

                if(filterType === 'filterRoom') {
                    el.isVisibleOnNotMapped = combo.some(c => el.id === c.value);
                }else if(filterType === 'filterRate') {

                    el.isVisibleOnNotMapped = combo.some(c => el.rateCodeChannelListNotMapped.some(rate => rate.id === c.value));
                    el.rateCodeChannelListNotMapped.forEach(c => {
                        if (combo.some(x => x.value === c.id)) {
                            c.isVisible = true
                        } else {
                            c.isVisible = false
                        }
                    })
                }else if(filterType === 'filterRateLabel') {
                    el.isVisibleOnNotMapped = combo.some(c => el.rateLabelId === c.value);
                }
            })
        }
        else {
            rateCodeChannelList.forEach(el => {
                el.isVisibleOnNotMapped = true;
                el.rateCodeChannelListNotMapped.forEach(c => {
                    c.isVisible = true;
                })
            })
        }

        this.setState({ rateCodeChannelList, selectedRooms: combo });
    }

    render() {
        const { rateCodeChannelList } = this.state;
        const hasRateLabel = rateCodeChannelList ? rateCodeChannelList.some(el => el.rateLabel) : false;

        return (
            <div>
                { this.props.channelDataMap && this.props.channelDataMap.length === 0 && this.props.showChannelInfo ?
                    <CardBody className="shadow">
                        <Row>
                            <Col>
                                <i className="fas fa-exclamation-triangle text-danger" />
                                <FormattedMessage id={'ChannelList.NoChannelData'} />
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" onClick={this.props.refreshChannelData}>
                                    <i className="fas fa-list" />
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                    :
                    <Row className="mb-2 align-items-center">
                        <Col className="small pr-0">
                            <FormattedMessage id="ChannelMapping.CMRoom" >{option =>
                                <CustomSelect
                                    isClearable
                                    options={this.state.roomOptions}
                                    onChange={(e) => this.filterBySelect(e, 'filterRoom')}
                                    placeholder={option}
                                    isMulti
                                />
                            }</FormattedMessage>
                        </Col>
                        <Col className="small">
                            <FormattedMessage id="ChannelMapping.CMRate" >{option =>
                                <CustomSelect
                                    isClearable
                                    options={this.state.rateOptions}
                                    onChange={(e) => this.filterBySelect(e, 'filterRate')}
                                    placeholder={option}
                                    isMulti
                                />
                            }</FormattedMessage>
                        </Col>
                        {hasRateLabel && global.operationMode !== 'PmsFull' &&
                            <Col className="small pl-0">
                                <FormattedMessage id="ChannelMapping.RateLabel" >{option =>
                                    <CustomSelect
                                        isClearable
                                        options={this.state.rateLabelOptions}
                                        onChange={(e) => this.filterBySelect(e, 'filterRateLabel')}
                                        placeholder={option}
                                        isMulti
                                    />
                                }</FormattedMessage>
                            </Col>
                        }
                    </Row>
                }

                {this.state.rateCodeChannelList && this.state.rateCodeChannelList.map((el, k) => el.isVisibleOnNotMapped !== false && 
                    <Card className="bg-white shadow border-0 mb-2" key={k}>
                        <CardHeader className="card-header-gridview border-bottom-1 border-host mb-1 bg-white pb-1">
                            <b>{el.code}: </b> {el.description}
                        </CardHeader>

                        <CardBody className="border-0 py-0" style={{ fontSize: '13px' }}>
                            {el.rateCodeChannelListNotMapped.map((rate, rate_key) => rate.isVisible !== false && 
                                <Row className="overbglight" key={rate_key} style={{ borderBottom: '2px solid #dee2e6' }}>
                                    <Col className="py-2">
                                        <Row>
                                            <Col className="col-8">
                                                <div className="mb-2"> <b>{rate.code} </b> </div>
                                                <div> {rate.description} </div>
                                            </Col>
                                            <Col className="col-4 text-right">
                                                <Authorization
                                                    perform="channel:mapping:add"
                                                    yes={() => (
                                                        <div>
                                                            <Button className="btn-host btn-sm" onClick={this.props.openMapping.bind(this, rate.id, null)} disabled={el.isDisabledToAdd}>
                                                                <i className="fas fa-plus" />
                                                            </Button>
                                                        </div>
                                                    )}
                                                    no={() => ""}
                                                />

                                                <div className="d-flex justify-content-end align-items-end">
                                                    {rate.occupancyList &&
                                                        <span>
                                                            <Badge color="primary" className="py-1 mr-2" id={'pax' + rate.id}>
                                                                <div>{rate.occupancyList.reduce((max, el) => el.adults > max ? el.adults : max, 0)} <i className="fas fa-male"></i>  {rate.occupancyList.length > 1 ? '+' : <span className="ml-2" />} </div>
                                                            </Badge>
                                                            <CustomToolTip placement="left" target={'pax' + rate.id}>
                                                                {rate.occupancyList.sort(function (a, b) { return a.adults === b.adults ? (a.adults + a.children) - (b.adults + b.children) : a.adults - b.adults }).map((p, k3) =>
                                                                    <Row key={k3}>
                                                                        <Col>
                                                                            <div className="text-left">
                                                                                {p.adults} <i className="fas fa-user mr-1 ml-1"></i>
                                                                                {p.childrens > 0 ? <span> + {p.childrens} <i className="fas fa-child mx-1"></i></span> : ''}
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                            </CustomToolTip>
                                                            {rate.isDerived ? <Badge color="primary">Derived</Badge> : ''}
                                                        </span>
                                                    }

                                                    <ActiveInactiveStatusBadge status={rate.active} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            )}
                        </CardBody>
                    </Card>
                ) }
            </div>
        );
    }
}

export default injectIntl(NotMapped);