import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import CustomSelect from '../../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SelectChannelConfig } from '../../Base/Common/CommonUIComponents'
import Authorization, { CheckAuthorization } from '../../Base/Authorization';
import { MobileFilters } from '../../Base/Common/MobileComponents';


class CancelationPolicyFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            block: true,
            list: [],
            modal: false,
            cancelSelected: null,
            cancelPolicyId: null,
            rateCodeId: null,
            channelId: null,
            policiesNames: [],
            rateList: [],
            customPolicyFilter: false,
            newPolicyModal: false,
            emptyGuid: '00000000-0000-0000-0000-000000000000'
        }
    }

    renderMainFilter = () => {
        return (
            <CustomSelect
                icon={'fas fa-ban'}
                isClearable isSearchable
                placeholder={<FormattedMessage id="CancelationPolicy.CancelationPolicy" />}
                options={this.props.policiesNames}
                onChange={(e) => this.props.handleSelect('cancelPolicyId', e)}
            />
        )
    }

    render() {
        const { fromBEConfigDashboard, channelId, rateList, policiesNames, handleSelect, toggleNewModal, getCancelationPolicy } = this.props;

        return (
            <div>
                {global.isMobile ?
                    <div className="mb-3">
                        <MobileFilters doSearch={getCancelationPolicy} renderMainFilter={this.renderMainFilter} addFunction={() => { if (CheckAuthorization("cancelPolicy:create")) toggleNewModal() }}>
                            <Row>
                                <CommonFilters
                                    fromBEConfigDashboard={fromBEConfigDashboard}
                                    channelId={channelId}
                                    rateList={rateList}
                                    handleSelect={handleSelect}
                                />
                            </Row>
                        </MobileFilters>
                    </div>
                :
                    <Row className="mb-4">
                        <Col className=" col-lg-3 pr-2">
                            <CustomSelect
                                icon={'fas fa-ban'}
                                isClearable isSearchable
                                placeholder={<FormattedMessage id="CancelationPolicy.CancelationPolicy" />}
                                options={policiesNames}
                                onChange={(e) => handleSelect('cancelPolicyId', e)}
                            />
                        </Col>

                        <CommonFilters
                            fromBEConfigDashboard={fromBEConfigDashboard}
                            channelId={channelId}
                            rateList={rateList}
                            handleSelect={handleSelect}
                        />

                        <Col className="text-right">
                            <Authorization
                                perform="cancelPolicy:create"
                                yes={() => (
                                    <button className="btn btn-host btn-sm ml-1" onClick={toggleNewModal}>
                                        <span className="fas fa-plus" />
                                    </button>
                                )}
                                no={() => <div></div>}
                            />
                            <button className="btn btn-host btn-sm ml-1" onClick={getCancelationPolicy}>
                                <span className="fas fa-search" />
                            </button>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}

export default injectIntl(CancelationPolicyFilters)


const CommonFilters = ({ fromBEConfigDashboard, channelId, rateList, handleSelect }) => {
    return (
        <>
            <Authorization
                perform="cancelPolicy:create"
                yes={() => (
                    <Col className="mb-2 col-12 col-lg-2">
                        <CustomSelect
                            icon={'fas fa-ban'}
                            isClearable isSearchable
                            placeholder={<FormattedMessage id="CancelationPolicy.CustomCancellation" />}
                            options={[
                                { value: 'custom', label: <FormattedMessage id="CancelationPolicy.CustomCancellation" /> },
                                { value: 'regular', label: <FormattedMessage id="CancelationPolicy.regularPolicy" /> }
                            ]}
                            onChange={(e) => handleSelect('customPolicyFilter', e)}
                        />
                    </Col>
                )}
                no={() => <div></div>}
            />
            <Col className="mb-2 col-12 col-lg-2">
                <CustomSelect
                    icon={'fas fa-gift'}
                    isClearable isSearchable
                    placeholder={<FormattedMessage id="CancelationPolicy.Rate" />}
                    options={rateList}
                    onChange={(e) => handleSelect('rateCodeId', e)}
                />
            </Col>
            {fromBEConfigDashboard !== true ?
                <Col className="mb-2 col-12 col-lg-2">
                    <SelectChannelConfig
                        icon={'fas fa-plug'}
                        onChangeFunc={handleSelect}
                        name={'channelId'}
                        value={channelId}
                        isClearable
                        isSearchable
                    />
                </Col>
            : ''}
        </>
    )
}