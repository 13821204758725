import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Card, Row, Col, Modal, ModalHeader, ModalBody, CardBody, Label, InputGroup, FormGroup, Input } from 'reactstrap';
import { StyledCard, SelectChannelConfig } from "../../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../../Base/Common/CustomTable';
import { getAPI, postAPI, deleteAPI } from '../../../Base/API';
import CustomSelect from '../../../Base/Common/CustomSelect';
import { handleNotification } from "../../../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';


class NotificationConfiguration extends Component {

    state = {
        block: false,
        blockList: true,
        selectedConfig: null,
        selectListEvents:[]
        
    }

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.form = React.createRef();
    }

    componentDidMount() {

        this.getConfiguration();
        this.getEventTypes();
    }
    toggleModal() {
        this.setState(prevstate => ({
            modal: !prevstate.modal
        }));
    }
    getEventTypes() {
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });

                return;
            }
            if (data.response) {


                let selectListEvents = [];
                data.response.map((el, k) => {
                        selectListEvents.push({ 'value': el.id, "label": el.name });
                })


                this.setState({ eventTypeList: data.response, selectListEvents: selectListEvents, blockList: false });
            }
        }, '/api/notification/Template/v1');


    }
    getConfiguration() {
        this.setState({block: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ configList: data.response, block: false });
            }
        }, ' /api/notification/Template/v1/Configuration' );
       
    }

    setFilterSelectedChannel = (name, combo, el) => {
        var val = combo ? combo.value : el ? el.value : null

        this.setState(prevState => ({
            ...prevState,
            selectedConfig: {
                ...prevState.selectedConfig,
                [name]: val
            }
        }));
    }

    changeState(evt) {
        let name = evt.target.name;
        let value = evt.target.value;

        this.setState(prevState => ({
            ...prevState,
            selectedConfig: {
                ...prevState.selectedConfig,
                [name]: value
            }
        }));
    }
    deleteConfig() {

        this.setState({ block: true });
        deleteAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ configList: data.response, block: false });
            }
        }, '/api/notification/Template/v1/' + this.state.selectedConfig.id + '/Configuration');
        
    }
    addConfig() {
        this.setState({ modal: true, selectedConfig: {} })
    }
    saveConfig(evt) {
        evt.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="NotificationConfiguration.ConfigurationSaved" />, <FormattedMessage id="NotificationConfiguration.Success" />);
                this.setState({ configList: data.response, block: false });
            }
        }, '/api/notification/Template/v1/Configuration', this.state.selectedConfig);
        

    }

    render() {

        const helpContent =
            <div>
                TODO
                <hr />
            </div>


        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "NotificationConfiguration.name" }),
                sort: true
            },
            {
                dataField: 'channelConfigName',
                text: this.props.intl.formatMessage({ id: "NotificationConfiguration.ChannelCode" }),
                sort: true
            },
            {
                dataField: 'mailToUse',
                text: this.props.intl.formatMessage({ id: "NotificationConfiguration.MailToUse" }),
                sort: true
            },
            {
                dataField: 'Type',
                text: this.props.intl.formatMessage({ id: "NotificationConfiguration.Type" }),
                sort: true,
                formatter: (cell, row) => <div> {row.template.notifyGenericEvent.type}</div>
            },
            {
                dataField: 'Type',
                text: this.props.intl.formatMessage({ id: "NotificationConfiguration.Type" }),
                sort: true,
                formatter: (cell, row) => <div> {row.template.notifyGenericEvent.events.map((item,key) => item.eventType)}</div>
            }];

        const selectRow = {

            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({
                    modal: true, selectedConfig: {
                        "id": row.id,
                        "name": row.name,
                        "channelConfigId": row.channelConfigId,
                        "mailToUse": row.mailToUse,
                        "templateId": row.template.id
                    } })
            }
        };

      
        return (



            <StyledCard block={this.state.block} error={this.state.error} icon={'far fa-envelope'} title={'NotificationConfiguration.title'} help={helpContent}>

                <Modal isOpen={this.state.modal} toggle={this.toggleModal} >
                    <ModalHeader toggle={this.toggleModal}>{this.state.selectedConfig && this.state.selectedConfig.name}</ModalHeader>
                    <ModalBody>
                        {this.state.selectedConfig ?
                            <Card className="border-0">
                                <form ref={this.form}>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button className="btn btn-host btn-sm mb-1 mr-1 float-right" onClick={this.saveConfig.bind(this)}><i className="fas fa-save"></i></button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label for="name" sm={4}><FormattedMessage id="NotificationConfiguration.name" /></Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="NotificationTemplate.name">{placeholder =>
                                                            <Input name="name" value={this.state.selectedConfig.name} required onChange={this.changeState.bind(this)} placeholder={placeholder} />
                                                        }</FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                                <FormGroup row>
                                                    <Label for="name" sm={4}><FormattedMessage id="NotificationConfiguration.channel" /></Label>
                                                    <Col sm={8}>
                                                        <SelectChannelConfig required={true} icon={'fas fa-plug'} value={this.state.selectedConfig.channelConfigId} onChangeFunc={this.setFilterSelectedChannel.bind(this,'channelConfigId')} />
                                                    </Col>
                                                </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label for="name" sm={4}><FormattedMessage id="NotificationConfiguration.mail" /></Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="NotificationTemplate.mail">{placeholder =>
                                                                <Input name="mailToUse" value={this.state.selectedConfig.mailToUse} required placeholder={placeholder} onChange={this.changeState.bind(this)} />
                                                        }</FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <BlockUi tag="div" blocking={this.state.blockList}>
                                                <FormGroup row>
                                                        <Label for="name" sm={4}><FormattedMessage id="NotificationConfiguration.template" /></Label>
                                                        <Col sm={8}>
                                                            <CustomSelect required={true} placeholder={<FormattedMessage id="NotificationTemplate.event" />} value={this.state.selectListEvents.find(el => el.value === this.state.selectedConfig.templateId)} options={this.state.selectListEvents} name="templateId" onChange={this.setFilterSelectedChannel.bind(this, 'templateId')} />
                                                    </Col>
                                                </FormGroup>
                                            </BlockUi>
                                        </Col>
                                    </Row>

                                    </CardBody>
                                </form>
                            </Card>
                            : ''}
                    </ModalBody>
                </Modal>

                <Row>

                    <Col className="text-right">
                        <button className="btn btn-host btn-sm mb-1 mr-1" disabled={!this.state.selectedConfig} onClick={this.deleteConfig.bind(this)}><i className="fas fa-trash-alt"></i></button>
                        <button className="btn btn-host btn-sm mb-1 mr-1" onClick={this.addConfig.bind(this)}><i className="fas fa-plus"></i></button>
                        <button className="btn btn-host btn-sm mb-1" onClick={this.getConfiguration.bind(this)}><i className="fas fa-sync-alt"></i></button>
                    </Col>
                </Row>
                <Card className="border-0">

                    <CustomTable
                        data={this.state.configList ? this.state.configList : []}
                        columns={columns}
                        page={this.state.currentPage}
                        sizePerPage={this.state.resultsPerPage}
                        totalSize={this.state.templateList && this.state.configList.length}
                        shadow={false}
                        search={false}
                        remote={false}
                        selectRow={selectRow}
                    />




                </Card>

            </StyledCard>



        );
    }

}
export default injectIntl(NotificationConfiguration)