import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, CardHeader, Badge } from 'reactstrap';
import { getAPI, postAPI } from "../../../Base/API";
import { StyledCard } from "../../../Base/Common/CommonUIComponents";
import { handleNotification } from "../../../Base/Notification";
import google from '../../../../img/Google.ico';
import HotelsSummary from './HotelsSummary';
import PriceReports from './PriceReports';

class Google extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: true,
            hotelsConfig: null,
            size: 10
        }
    }

    componentDidMount() {
        this.getGoogleHotelList();
    }

    getGoogleHotelList = (nextPage) => {
        this.setState({ block: true });

        var params = nextPage ? `&nextPage=${nextPage}` : '';

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (nextPage) {
                    const { hotelsConfig } = this.state;

                    hotelsConfig.hotelViews = hotelsConfig.hotelViews.concat(data.rootElement.hotelViews);
                    hotelsConfig.nextPageToken = data.rootElement.nextPageToken;

                    this.setState({ hotelsConfig, block: false });
                }
                else this.setState({ hotelsConfig: data.rootElement, block: false });
            }
            else this.setState({ block: false });
        }, `/api/hotel/MetaSearch/v1/google/list?size=${this.state.size}` + params);
    }

    toggleLiveOnGoogle = (hotelId, isOnLive, idx) => {
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.rootElement && data.rootElement.error) {
                    handleNotification({ errors: [data.rootElement] });
                }
                else if (data.rootElement && data.rootElement.updatedHotelIds.length > 0) {
                    handleNotification(data, <FormattedMessage id="HotelFullPush.StatusUpdated" />, <FormattedMessage id="General.Success" />);

                    const { hotelsConfig } = this.state;

                    hotelsConfig.hotelViews[idx].liveOnGoogle = isOnLive;

                    this.setState({ hotelsConfig, block: false });
                }
                else if (data.rootElement && data.rootElement.failedHotelIds.length > 0) {
                    handleNotification({ errors: [{ message: <FormattedMessage id="HotelFullPush.FailedToUpdatedStatus" /> }] });
                    this.setState({ block: false });
                }

            }
            else this.setState({ block: false });
        }, `/api/hotel/MetaSearch/v1/google/live?liveOnGoogle=${isOnLive}&hotelIds=${hotelId}`);
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
    }

    render() {
        const { hotelsConfig } = this.state;

        return (
            <StyledCard block={this.state.block} error={this.state.error} title={'Google.Title'} error={this.state.error} img={{ src: google, height: '14px', className: 'mb-1' }}>
                <HotelsSummary
                />


                {hotelsConfig ?
                    <>
                        {hotelsConfig.hotelViews.map((hotel, idx) =>
                            <Card className="shadow mb-2 p-2" key={idx}>
                                <CardHeader className="card-header-gridview border-bottom-1 border-host mb-1 bg-white pb-0 pl-0">
                                    <Row className="mb-1 align-items-center">
                                        <Col> <i className="fas fa-hotel ml-1" /> <b> {hotel.partnerHotelDisplayName} </b> - {hotel.partnerHotelId} </Col>

                                        <Col className="col-2 pl-4">
                                            <a href={hotel.propertyDetails} target="_blank"> View Property Details </a>
                                        </Col>

                                        <Col className="text-right" sm={4}>
                                            {hotel.liveOnGoogle ?
                                                <Badge color="primary" > On live </Badge>
                                                :
                                                <Badge color="secondary" > Off </Badge>
                                            }

                                            <Button className="btn-host btn-sm ml-2" onClick={() => this.toggleLiveOnGoogle(hotel.partnerHotelId, !hotel.liveOnGoogle, idx)}>
                                                <i className="fas fa-power-off" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>

                                <div className="px-4" style={{ fontSize: '15px' }}>
                                    <Row className="pt-1">
                                        <Col sm={2}> <b> Google Hotel Id: </b> </Col>
                                        <Col sm={4}> {hotel.googleHotelId} </Col>
                                        <Col sm={2}> <b> Display Name Google: </b>  </Col>
                                        <Col sm={4}> {hotel.googleHotelDisplayName} </Col>
                                    </Row>
                                    <Row className="my-1">
                                        <Col sm={2}> <b> Google Maps Id: </b>  </Col>
                                        <Col sm={4}> {hotel.googleClusterId} </Col>
                                        <Col sm={2}> <b> Match Status: </b>  </Col>
                                        <Col sm={4}> {hotel.matchStatus} </Col>
                                    </Row>
                                    {hotel.dataIssues ?
                                        <Row>
                                            <Col sm={2}> <b> Data Quality Issues: </b>  </Col>
                                            <Col> {hotel.dataIssues} </Col>
                                        </Row>
                                    : ''}
                                    <Row className="mt-1">
                                        {hotel.primaryOverclusteredPartnerHotelId ?
                                            <>
                                                <Col sm={2}> <b> Primary Overclustered Partner Hotel Id: </b>  </Col>
                                                <Col sm={4}> {hotel.primaryOverclusteredPartnerHotelId} </Col>
                                            </>
                                        : ''}
                                        {hotel.overclusteredPartnerHotelIds ?
                                            <> 
                                                <Col sm={2}> <b> Overclustered Partner Hotel Ids: </b>  </Col>
                                                <Col sm={4}> {hotel.overclusteredPartnerHotelIds} </Col>
                                            </>
                                        : ''}
                                    </Row>
                                </div>

                                <div style={{ fontSize: '15px' }}>
                                    <PriceReports
                                        hotelId={hotel.partnerHotelId}
                                    />
                                </div>
                            </Card>
                        )}

                        {hotelsConfig.nextPageToken ?
                            <div className="text-center mt-4">
                                <Button className="btn-host btn-sm ml-2" onClick={() => this.getGoogleHotelList(hotelsConfig.nextPageToken)}>
                                    Load more...
                                </Button>
                            </div>
                        : ''}
                    </>
                : 'Nenhum hotel configurado'}
            </StyledCard>
        );
    }

};
export default injectIntl(Google)