import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StyledCard, SelectHotel } from "../../../Base/Common/CommonUIComponents";
import { Button, Col, Row, Form, Card } from 'reactstrap';
import { handleNotification } from "../../../Base/Notification";
import CustomSelect from '../../../Base/Common/CustomSelect';
import { getAPI } from "../../../Base/API";
import CompareMappings from './CompareMappings';
import CompareRoomRate from './CompareRoomRate';


class CheckMappings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hotelId: null,
            channelInstanceList: [],
            channelInstanceId: null,
            channelCode: null,
            propertyCode: null,
            cmMapping: [],
            pmsMapping: []
        };
    }

    getChannelInstance = (hotelId) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                var combo = [];

                data && data.forEach((item) => combo.push({ value: item.id, label: `${item.code} (${item.propertyCode})`, channelCode: item.code, propertyCode: item.propertyCode }));

                this.setState({ channelInstanceList: combo, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance?hotelId=${hotelId}`);
    }

    getPMSMappings = (hasSendRoomRate) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                var rateMapping = [];

                if (data.response && data.response.length > 0) {
                    rateMapping = data.response[0].rateCodeMappings ? data.response[0].rateCodeMappings : [];
                }

                this.setState({ pmsMapping: rateMapping }, () => {
                    if (hasSendRoomRate) {
                        this.getCmRatesAndRooms()
                    }
                    else this.getCMapping()
                });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/hotel/v1/pmsInvokeAction/${this.state.hotelId}/channelMappings`);
    }

    getCMapping = () => {
        getAPI (result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                var list = [];

                if (data.response && data.response.length > 0) {
                    list = data.response.map(el => ({ ...el, channelCode: this.state.channelCode }));
                }

                this.setState({ cmMapping: list }, () => this.searchMappings());
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/RateCodeChannel/v1/ChannelInstance/admin/${this.state.channelInstanceId}`);
    }


    searchMappings = () => {
        this.setState({ block: true });

        let pmsMapping = [...this.state.pmsMapping].filter(el => el.channelContext === this.state.channelCode && el.hotelCodeMapping === this.state.propertyCode);
        let cmMapping = [...this.state.cmMapping];
        let mappings = [];


        pmsMapping.forEach(pms => {
            let object = { pms: pms, cm: null };

            const index = cmMapping.findIndex(cm => cm.availabilityGroupMapping === pms.availabilityGroupMapping && cm.roomTypeMapping === pms.roomTypeMapping && cm.rateCodeMapping === pms.rateCodeMapping)
            if (index > -1) {
                object.cm = cmMapping[index];

                cmMapping.splice(index, 1)
            }

            mappings.push(object);
        })

        cmMapping && cmMapping.forEach(cm => {
            mappings.push({ pms: null, cm: cm });
        })

        mappings = [...mappings.filter(x => x.cm === null || x.pms === null), ...mappings.filter(x => x.cm !== null && x.pms !== null)]

        this.setState({ block: false, allMappings: mappings });
    }

    getCmRatesAndRooms = () => {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ cmRates: data.response || [] }, () => this.organizeMappings());
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/Rate/v1/RatesAndRoomsAssociated?hotelId=${this.state.hotelId}`);
    }

    //When has sendRoomRates 
    organizeMappings = () => {
        this.setState({ block: true });

        let pmsMapping = [...this.state.pmsMapping].filter(el => el.channelContext === "HEYTRAVELCM");
        let cmRates = [...this.state.cmRates];
        let mappings = [];


        pmsMapping.forEach(pms => {
            let object = { pms: pms, cm: null };

            const index = cmRates.findIndex(cm => cm.roomCategoryCode === pms.roomTypeMapping && cm.rateCode === pms.rateCodeMapping);

            if (index !== -1) {
                object.cm = cmRates[index];

                cmRates.splice(index, 1)
            }

            mappings.push(object);
        })

        cmRates && cmRates.forEach(cm => {
            mappings.push({ pms: null, cm: cm });
        })

        mappings = [...mappings.filter(x => x.cm === null || x.pms === null), ...mappings.filter(x => x.cm !== null && x.pms !== null)]

        this.setState({ block: false, allMappings: mappings });
    }

    handleChangeSelect = (name, combo) => {
        if (name === 'hotelId' && combo) {
            this.setState({ allMappings: [] }, () => {
                if (!combo.sendRoomRate) {
                    this.getChannelInstance(combo.value);
                }
            })
        }

        this.setState({
            [name]: combo ? combo.value : null,
            hasSendRoomRate: name === 'hotelId' ? combo.sendRoomRate : this.state.hasSendRoomRate,
            channelInstanceId: null,
            channelCode: null,
            propertyCode: null
        });
    };

    handleChannelInstance = (combo) => {
        this.setState({
            channelInstanceId: combo ? combo.value : null,
            channelCode: combo ? combo.channelCode : null,
            propertyCode: combo ? combo.propertyCode : null
        });
    };

    doSearch = (e) => {
        e.preventDefault();
        const { hasSendRoomRate } = this.state;

        this.setState({
            pmsMapping: [],
            cmMapping: [],
            allMappings: []
        }, () => this.getPMSMappings(hasSendRoomRate))
    }

    render() {
        const { channelInstanceList, hotelId, allMappings, channelInstanceId, hasSendRoomRate } = this.state;

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-random'} title={'navbar.CheckMappings'} >
                <Form onSubmit={this.doSearch} >
                    <Row className="mb-2">
                        <Col className="col-4">
                            <SelectHotel
                                name={'hotelId'}
                                icon={'fas fa-hotel'}
                                onChangeFunc={this.handleChangeSelect}
                                placeholder={<FormattedMessage id="HotelLicenses.Hotel" />}
                                value={hotelId}
                                required
                            />
                        </Col>
                        {hasSendRoomRate !== true ?
                            <Col>
                                <CustomSelect
                                    icon='fa fa-plug fa-fw'
                                    options={channelInstanceList}
                                    required
                                    isClearable
                                    isDisabled={!this.state.hotelId}
                                    placeholder={<FormattedMessage id="AnalyticsDashboard.SelectChannel" />}
                                    onChange={this.handleChannelInstance.bind(this)}
                                    value={channelInstanceId ? channelInstanceList.find(ci => ci.value === channelInstanceId) : ''}
                                />
                            </Col>
                        : ''}
                        <Col>
                            <Button className="btn btn-host btn-sm float-right mx-1" type="submit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {allMappings && allMappings.length > 0 ?
                    <div>
                        <Row className="my-4">
                            <Col className="text-center">
                                <Card className="h-100 border-0 shadow p-3" style={{ backgroundColor: '#fffef6' }}>
                                    <b> PMS </b>
                                </Card>
                            </Col>
                            <Col className="col-1"/>
                            <Col className="text-center">
                                <Card className="h-100 border-0 shadow p-3" style={{ backgroundColor: '#f5feff' }}>
                                    <b> CM </b>
                                </Card>
                            </Col>
                        </Row>
                        {!hasSendRoomRate ?
                            <CompareMappings
                                allMappings={allMappings}
                            />
                        : 
                            <CompareRoomRate
                                allMappings={allMappings}
                            />
                        }
                    </div>
                : <div className="text-center mt-4"> <FormattedMessage id="CheckMapping.NoMappingFound" /> </div>}
            </StyledCard>
        );
    }

};
export default injectIntl(CheckMappings)