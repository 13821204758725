import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, ModalHeader, Input, Form, Card, CardBody, Button, CustomInput } from 'reactstrap';
import { getBookingEngines, getMarkupType, priceMarkupIncludePCOptions } from "../Base/Common/ReferenceDataFunctions";
import { handleNotification } from '../Base/Notification';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CustomTable } from '../Base/Common/CustomTable';
import { ComboBoxWithTitle } from './ComboBoxWithTitle';
import CustomSelect from '../Base/Common/CustomSelect';
import { ErrorAlert } from "../Common/ErrorAlert";
import { postAPI } from "../Base/API";
import BlockUi from 'react-block-ui';

class BulkOperationUnmapped extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            changeMarkup: false,
            changePublishedOn: false,
            changeExcludePriceComp: false,
            selectedMapping: [],
            mappingData: {},
            associatedRateCodeChannels: [],
            roomColumns: [
                {
                    dataField: 'code',
                    text: this.props.intl.formatMessage({ id: "ChannelMapping.Room" }),
                    sort: true
                },
                {
                    dataField: 'description',
                    text: this.props.intl.formatMessage({ id: "RoomCategoryBlocks.Description" }),
                    sort: true
                },
                {
                    dataField: 'id',
                    text: this.props.intl.formatMessage({ id: "EditChannelMapping.RoomCode" }),
                    sort: true,
                    headerStyle: { width: '20%' }
                },
            ],
            rateColumns: [
                {
                    dataField: 'code',
                    text: this.props.intl.formatMessage({ id: "ChannelList.Rate" }),
                    sort: true
                },
                {
                    dataField: 'description',
                    text: this.props.intl.formatMessage({ id: "EditRate.Description" }),
                    sort: true
                },
                {
                    dataField: 'id',
                    text: this.props.intl.formatMessage({ id: "EditChannelMapping.RateCode" }),
                    sort: true,
                    headerStyle: { width: '21.5%' }
                }
            ],
            operationsOptions: [
                {
                    value: 'changeMarkup',
                    label: this.props.intl.formatMessage({ id: "BulkOperation.ChangeMarkup" })
                },
                {
                    value: 'changePublishedOn',
                    label: this.props.intl.formatMessage({ id: "BulkOperation.ChangePublishedOn" })
                },
                {
                    value: 'changeExcludePriceComp',
                    label: this.props.intl.formatMessage({ id: "BulkOperation.ChangeExcludePriceComp" })
                }
            ]
        }
    }

    componentDidMount() {
    }

    handleChangeSelect = (name, combo) => {
        this.setState(prevState => ({
            mappingData: {
                ...prevState.mappingData,
                [name]: combo ? combo.value : null
            }
        }))
    }

    changeApplyMarkup = (evt) => {
        let checked = evt.target.checked;

        this.setState(prevState => ({
            mappingData: {
                ...prevState.mappingData,
                applyMarkup: checked,
                priceMarkupType: checked ? prevState.priceMarkupType : null,
                priceMarkupValue: checked ? prevState.priceMarkupValue : null
            }
        }))
    }

    selectOperations = (combo) => {
        this.setState({
            changeMarkup: combo && combo.some(el => el.value === 'changeMarkup'),
            changePublishedOn: combo && combo.some(el => el.value === 'changePublishedOn'),
            changeExcludePriceComp: combo && combo.some(el => el.value === 'changeExcludePriceComp')
        })
    }

    changeSwitch = (evt) => {
        const { checked, id } = evt.target;

        this.setState(prevState => ({
            mappingData: {
                ...prevState.mappingData,
                [id]: checked
            }
        }));
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState(prevState => ({
            mappingData: {
                ...prevState.mappingData,
                [name]: value
            }
        }));
    }

    bulkOperation = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        if (this.state.selectedMapping && this.state.selectedMapping.length < 1) {
            handleNotification({ warnings: [{ code: '', message: <FormattedMessage id="BulkOperation.ThereMustBeAtLeast1MapSelected" /> }] });
            this.setState({ block: false });
            return;
        }

        const body = {
            channelInstanceId: this.props.channelInstanceId,
            newMappings: this.state.selectedMapping,
            rateCodeChannel: this.state.mappingData
        };

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="EditChannelMapping.MappingSaved" />, <FormattedMessage id="EditChannelMapping.Success" />);

                    this.setState({ block: false });
                    this.props.getRates();
                    this.props.reloadChannelData();
                    this.props.toggle();
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Rate/RateCodeChannel/v1/ratecodechannel/bulkCreationOperation`, body);
    }

    handleAssociatedRateCodeChannels = (evt) => {
        const { associatedRateCodeChannels } = this.state;

        if (evt && evt.target) {
            const { checked, name } = evt.target;

            if (checked) {
                associatedRateCodeChannels.push({ id: null, channelCode: name });
            }
            else {
                const idx = associatedRateCodeChannels.findIndex(aRcc => aRcc.channelCode === name);

                if (idx !== -1) {
                    associatedRateCodeChannels.splice(idx, 1);
                }
            }

            this.setState(prevState => ({
                mappingData: {
                    ...prevState.mappingData,
                    associatedRateCodeChannels
                }
            }));
        }
    }

    expandRow = () => {
        return {
            className: "no-hover-bg",
            showExpandColumn: true,
            renderer: row => (
                <div className="ml-5 pl-5">
                    <CustomTable
                        data={row ? row.rateCodeChannelListNotMapped : []}
                        columns={this.state.rateColumns}
                        className="normal"
                        selectRow={this.selectRate()}
                    />
                </div>
            )
        }
    };

    selectRate = () => {
        return {
            mode: 'checkbox',
            clickToSelect: false,
            selected: [...this.state.selectedMapping]?.map(el => el.rateId),
            onSelect: (row, isSelect, rowIndex, e) => {
                let list = [...this.state.selectedMapping];

                if (isSelect) {
                    list.push({ rateId: row.id, roomId: row.roomCodeId });
                }
                else {
                    list = list.filter(el => el.rateId !== row.id);
                }

                this.setState({ selectedMapping: list });   
            },
            onSelectAll: (isSelect, rows, e) => {
                if (isSelect) {
                    this.setState({
                        selectedMapping: rows?.map(row => (
                            { rateId: row.id, roomId: row.roomCodeId }
                        ))
                    });
                }
                else {
                    this.setState({ selectedMapping: [] });
                }
            }
        }
    }

    selectRoom = () => {

        return {
            mode: 'checkbox',
            clickToSelect: false,
            selected: [...this.state.selectedMapping]?.map(el => el.roomId),
            onSelect: (row, isSelect, rowIndex, e) => {
                let list = [...this.state.selectedMapping];

                if (isSelect) {
                    row.rateCodeChannelListNotMapped.forEach(rcc => {
                        list.push({ rateId: rcc.id, roomId: row.id });
                    });
                }
                else {
                    list = list.filter(el => el.roomId !== row.id);
                }

                this.setState({ selectedMapping: list });
            },
            onSelectAll: (isSelect, rows, e) => {
                const { roomsWithUnmappedData } = this.props;

                if (isSelect) {
                    var selectAll = [];

                    roomsWithUnmappedData.forEach(room => {
                        room.rateCodeChannelListNotMapped.map(rate => (
                            selectAll.push({
                                rateId: rate.id, roomId: rate.roomCodeId
                            })
                        ))
                    })

                    this.setState({ selectedMapping: selectAll });
                }
                else {
                    this.setState({ selectedMapping: [] });
                }
            }
        }
    }

    render() {
        const { interfaceModes, priceModes, roomsWithUnmappedData } = this.props;
        const { changePublishedOn, changeExcludePriceComp } = this.state;

        const markupType = getMarkupType();

        const statusOptions = [
            { value: true, label: this.props.intl.formatMessage({ id: "generic.active" }) },
            { value: false, label: this.props.intl.formatMessage({ id: "generic.inactive" }) }
        ]


        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>
                    <FormattedMessage id="ChannelMapping.BulkOperation" />
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.bulkOperation}>
                            <Card className="shadow border-0 p-0">
                                <CardBody className="m-0">
                                    <Row>
                                        <Col>
                                            <h5><FormattedMessage id="BulkOperation.Operation" /></h5>
                                        </Col>
                                        <Col className="text-right mb-2 col-2">
                                            <Button className="btn-sm btn-host" type="submit">
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>


                                    <Row className="mb-4">
                                        <Col>
                                            <CustomSelect
                                                options={this.state.operationsOptions}
                                                placeholder={this.props.intl.formatMessage({ id: "BulkOperation.Operation" })}
                                                onChange={this.selectOperations.bind(this)}
                                                isMulti
                                            />
                                        </Col>
                                    </Row>


                                    <Row>
                                        <ComboBoxWithTitle
                                            title={<FormattedMessage id="ChannelList.status" />}
                                            name="active" options={statusOptions}
                                            onChangeFunc={this.handleChangeSelect.bind(this, 'active')}
                                            required
                                        />
                                        <ComboBoxWithTitle
                                            title={<FormattedMessage id="EditChannelMapping.Interface" />}
                                            name="interfaceMode"
                                            options={interfaceModes}
                                            onChangeFunc={this.handleChangeSelect.bind(this, 'interfaceMode')}
                                            required
                                        />
                                        <ComboBoxWithTitle
                                            title={<FormattedMessage id="EditChannelMapping.PriceMode" />}
                                            name="priceMode"
                                            options={priceModes}
                                            onChangeFunc={this.handleChangeSelect.bind(this, 'priceMode')}
                                            required
                                        />
                                    </Row>


                                    <Row>

                                        {changeExcludePriceComp &&
                                            <Col className="mb-3 col-4">
                                                <Row className="mb-2">
                                                    <Col className="col-8">
                                                        <h5> <FormattedMessage id="EditChannelMapping.ExcludePriceComponent" /> </h5>
                                                    </Col>
                                                    <Col>
                                                        <CustomInput
                                                            type="switch"
                                                            id="excludePriceComponent"
                                                            checked={this.state.mappingData?.excludePriceComponent}
                                                            onChange={this.changeSwitch}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        }

                                        {this.state.changeMarkup ?
                                            <Col className="mb-3 col-8">
                                                <Row className="mb-2">
                                                    <Col className="col-5">
                                                        <h5> <FormattedMessage id="EditChannelMapping.ApplyMarkup" /> </h5>
                                                    </Col>
                                                    <Col>
                                                        <CustomInput
                                                            type="switch" id="applyMarkup"
                                                            checked={this.state.mappingData?.applyMarkup}
                                                            onChange={this.changeApplyMarkup}
                                                        />
                                                    </Col>
                                                </Row>
                                                {this.state.mappingData?.applyMarkup === true ?
                                                    <Row className="align-items-center">
                                                        <Col className="col-1">
                                                            <FormattedMessage id="BulkOperation.Type" />
                                                        </Col>
                                                        <Col className="pl-0 col-3">
                                                            <CustomSelect name="priceMarkupType"
                                                                isClearable
                                                                options={markupType}
                                                                value={this.state.mappingData?.priceMarkupType ? markupType.filter(el => el.value === this.state.mappingData?.priceMarkupType) : ''}
                                                                onChange={this.handleChangeSelect.bind(this, 'priceMarkupType')}
                                                                placeholder={<FormattedMessage id="BulkOperation.Type" />}
                                                                required={this.state.mappingData?.applyMarkup}
                                                            />
                                                        </Col>
                                                        <Col className="col-1">
                                                            <FormattedMessage id="BulkOperation.Value" />
                                                        </Col>
                                                        <Col className="pl-0 col-2">
                                                            <Input type="number"
                                                                name="priceMarkupValue"
                                                                placeholder={this.props.intl.formatMessage({ id: "BulkOperation.Value" })}
                                                                min='0.01' max="100.00" step="0.01"
                                                                value={this.state.mappingData?.priceMarkupValue || ''}
                                                                onChange={(evt) => this.handleChange(evt)}
                                                                required={this.state.mappingData?.applyMarkup}
                                                            />
                                                        </Col>
                                                        <Col className="col-1 pl-0">
                                                            <FormattedMessage id="BulkOperation.Option" />
                                                        </Col>
                                                        <Col>
                                                            <CustomSelect
                                                                name="priceMarkupIncludePackageComponents"
                                                                options={priceMarkupIncludePCOptions}
                                                                value={priceMarkupIncludePCOptions.find(el => el.value === this.state.mappingData?.priceMarkupIncludePackageComponents)}
                                                                onChange={this.handleChangeSelect.bind(this, 'priceMarkupIncludePackageComponents')}
                                                                placeholder={<FormattedMessage id="EditChannelMapping.Select" />}
                                                                required={this.state.mappingData?.applyMarkup}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    : ''}
                                            </Col>
                                            : ''}

                                        {changePublishedOn ?
                                            <Col className="mb-5">
                                                <h5 className="mb-3 pb-1"><FormattedMessage id="EditChannelMapping.PublishOn" /></h5>
                                                <Row>
                                                    {getBookingEngines.map((bookingEngine, bookingEngineIdx) =>
                                                        <Col key={bookingEngineIdx}>
                                                            <CustomInput type="checkbox"
                                                                checked={this.state.mappingData?.associatedRateCodeChannels?.find(aRcc => aRcc.channelCode === bookingEngine.value)} id={`${bookingEngine.value}`} name={`${bookingEngine.value}`} onChange={this.handleAssociatedRateCodeChannels}>
                                                                {bookingEngine.label} <span className="mt-1">{bookingEngine.name}</span>
                                                            </CustomInput>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </Col>
                                            : ''}
                                    </Row>


                                    <h5 className="mb-2 mt-4"><FormattedMessage id="BulkOperation.SelectMapping" /> </h5>

                                    <CustomTable
                                        data={roomsWithUnmappedData ? roomsWithUnmappedData : []}
                                        columns={this.state.roomColumns}
                                        shadow={false}
                                        selectRow={this.selectRoom()}
                                        expandRow={this.expandRow()}
                                    />
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(BulkOperationUnmapped)