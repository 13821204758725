import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { Button, Card, Col, Row, Input } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard, SelectChannelConfig } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Notification";


class HotelDataUpdates extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: true,
            status: { value: false },
            hotelMissingSynsList: [],
            numberOfDays: 1,
            channelCode: null
        }
    }

    componentDidMount() {
        this.getHotelMissingUpdates();

    }


    searchHotels() {
        if (!this.form.current.reportValidity()) {
            return;
        }
        this.getHotelMissingUpdates();
    }

    getHotelMissingUpdates() {

        this.setState({ block: true });


        var param = '';


        if (this.state.numberOfDays)
            param += '?numberOfDays=' + this.state.numberOfDays
        if (this.state.channelConfigId)
            param += '&channelConfigId=' + this.state.channelConfigId


        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0)
                    handleNotification(data, "search", 'Success');
                else
                    this.setState({ hotelMissingSynsList: data.response, block: false });
            }
        }, '/api/hotel/v1/Dashboard/checkActivity' + param);
    }

    setFilterSelectedChannel = (name, combo) => {
        var label = combo ? combo.label : null
        var val = combo ? combo.value : null
        this.setState({
            channelCode: label,
            channelConfigId: val
        }
        );
    }



    render() {

        const helpContent =
            <div>
                Search for Hotels that do not send new price , restrictions ou availability information for more then X days
                <hr />
            </div>


        const columns = [
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "HotelDataUpdates.HotelName" }),
                sort: true,
                headerStyle: () => {
                    return { width: "15%" };
                }
            }, {
                dataField: 'priceLastUpdate',
                text: this.props.intl.formatMessage({ id: "HotelDataUpdates.LastPriceUpdate" }),
                sort: true,
                formatter: (cell, row) => {
                    return <div>
                        {moment(row.priceLastUpdate, 'YYYY-MM-DDTHH:mm:ss.SSS').fromNow()}
                    </div>
                }
            }, {
                dataField: 'availabilityLastUpdate',
                text: this.props.intl.formatMessage({ id: "HotelDataUpdates.LastAvailabilityUpdate" }),
                sort: true,
                formatter: (cell, row) => {
                    return <div>
                        {moment(row.availabilityLastUpdate, 'YYYY-MM-DDTHH:mm:ss.SSS').fromNow()}
                    </div>
                }
            },

            {
                dataField: 'restrictionLastUpdate',
                text: this.props.intl.formatMessage({ id: "HotelDataUpdates.LastRestrictionUpdate" }),
                sort: true,
                formatter: (cell, row) => {
                    return <div>
                        {moment(row.restrictionLastUpdate, 'YYYY-MM-DDTHH:mm:ss.SSS').fromNow()}
                    </div>
                }

            }];

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-wifi'} title={'HotelDataUpdates.title'} help={helpContent}>
                <form ref={this.form}>
                <Row>
                    <Col className="col-4">
                        <SelectChannelConfig  name={'channelConfigId'} icon={'fas fa-plug'} value={this.state.channelConfigId} onChangeFunc={this.setFilterSelectedChannel} />
                    </Col>
                    <Col className="col-1">
                        <Input type="number" min="1" max="200" required value={this.state.numberOfDays} onChange={(evt) => this.setState({ numberOfDays: evt.target.value })} placeholder="Num days" ></Input> 
                    </Col>
                    <Col>
                            <Button className="float-right mr-2 btn-sm btn-host btn" onClick={this.searchHotels.bind(this)}><i className="fas fa-search"></i></Button>
                    </Col>
                    </Row>
                    </form>
                <hr />
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">

                            <CustomTable
                                data={this.state.hotelMissingSynsList ? this.state.hotelMissingSynsList : []}
                                columns={columns}
                                page={this.state.currentPage}
                                sizePerPage={this.state.resultsPerPage}
                                onTableChange={this.handleTableChange}
                                totalSize={this.state.hotelMissingSynsList && this.state.hotelMissingSynsList.length}
                                shadow={false}
                                search={true}
                                remote={false}
                                showTotal={true}
                            />



                        </Card>

                    </Col>

                </Row>
            </StyledCard>


        );
    }

};
export default injectIntl(HotelDataUpdates)