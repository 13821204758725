import React, { Component } from 'react'
import { Button, Col, Row } from 'reactstrap'
import CustomSelect from '../../Base/Common/CustomSelect'
import { FormattedMessage, injectIntl } from 'react-intl'
import Authorization from '../../Base/Authorization';
import ExperienceCard from './ExperienceCard';

class ExperienceList extends Component {
    render() {
        const { statusOptions, handleSelect, selectedStatus, experiencesTypes, handleSelectExpType, doSearch, totalItems, pageSize, handlePagination,
        pageIndex, experiences, getExperienceDetail, newExp } = this.props;

        return (
            <div>
                <Row className="mb-4">
                    <Col className="col-3">
                        <CustomSelect
                            isClearable isSearchable
                            placeholder={<FormattedMessage id="home.status" />}
                            options={statusOptions}
                            onChange={handleSelect.bind(this, 'selectedStatus')}
                            value={statusOptions.find(opt => opt.value === selectedStatus)}
                        />
                    </Col>
                    <Col className="col-3">
                        <CustomSelect
                            isClearable isSearchable
                            placeholder={<FormattedMessage id="Experiences.ExperienceType" />}
                            options={experiencesTypes}
                            onChange={handleSelectExpType}
                            isMulti={false}
                        />
                    </Col>
                    <Col className="text-right pr-1">
                        <Authorization
                            perform="experience:add"
                            yes={() => (
                                <Button className="btn btn-host btn-sm text-white" onClick={newExp}>
                                    <i className="fas fa-plus"></i>
                                </Button>
                            )}
                            no={() => ""}
                        />
                        <Button className="btn btn-host btn-sm mx-2" onClick={doSearch}>
                            <span className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                {totalItems > pageSize ?
                    <Row>
                        <Col>
                            <Button
                                className={`btn btn-sm text-white ${pageIndex > 0 ? 'btn-host' : 'btn-secondary'}`}
                                disabled={pageIndex === 0}
                                onClick={() => pageIndex > 0 && handlePagination(-1)}
                                type="button">
                                <i className="fas fa-angle-left"/>
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button
                                className={`btn btn-sm text-white ${totalItems > ((pageIndex + 1) * pageSize) ? 'btn-host' : 'btn-secondary'}`}
                                disabled={totalItems <= ((pageIndex + 1) * pageSize)}
                                onClick={() => totalItems > ((pageIndex + 1) * pageSize) && handlePagination(1)}
                                type="button">
                                <i className="fas fa-angle-right"/>
                            </Button>
                        </Col>
                    </Row>
                :''}
                {experiences && experiences.length ?
                    <Row className="mt-2">
                        {experiences.map((experience, key) =>
                            <Col className="col-4 px-0" key={key}>
                                <ExperienceCard
                                    getExperienceDetail={getExperienceDetail}
                                    experience={experience}
                                    statusOptions={statusOptions}
                                /> 
                            </Col>
                        )}
                    </Row>
                :
                    <div className='text-center'>
                        <FormattedMessage id="Experiences.NoExperiences" />
                    </div>
                }
            </div>
        )
    }
}

export default injectIntl(ExperienceList)