import React, { Component } from 'react'
import { ErrorAlert } from '../Common/ErrorAlert';
import BlockUi from 'react-block-ui';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import { Card, Col, Row, Button, Form } from 'reactstrap';
import { DateRangePicker } from 'react-dates';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { handleNotification } from '../Base/Notification';
import { getAPI } from '../Base/API';
import { getMainStatus } from './EmailReservationFunctions';

export default class EmailReservationStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            lineChart: { labels: [], datasets: [] },
            startDate: moment().subtract(6, 'days'),
            endDate: moment(),
            offset: 0,
            cards: [
            ]
        };        
    }

    componentDidMount() {
        this.getGlobalEmailStats();
    }

    getGlobalEmailStats = (e) => {
        if (e) e.preventDefault();
        const { startDate, endDate } = this.state;

        let params = `?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}`;

        this.setState({ block: true }, () => 
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else {
                        this.buildChartsData(data.response);
                    }
                }
                this.setState({ error: errorMessage, block: false });
            }, `/api/hotel/EmailReservation/v1/stats/global` + params));
    }

    buildChartsData = (results) => {
        let { cards, lineChart } = this.state;
        const formatDate = "DD MMM";

        lineChart.datasets = [];
        lineChart.labels = [];
        cards = [];

        //datasets
        lineChart.datasets = getMainStatus().map(el => (
            {
                value: el.value,
                label: el.label,
                data: Array(results.length).fill(0),
                backgroundColor: el.color,
                borderColor: el.color,
                fill: false,
                tension: 0.1
            }
        ))

        //cards
        cards = getMainStatus().map(el => (
            { ...el, value: 0 }
        ))
        results && results.forEach(date => {
            date.stats && date.stats.forEach(stats => {
                let dataIndex = lineChart.labels.findIndex(el => el === moment(date.date).format(formatDate));
                if (stats.metrics) {
                    //Cards
                    cards[0].value += stats.metrics[0].received;

                    //Line chart
                    if (dataIndex === -1) {
                        lineChart.labels.push(moment(date.date).format(formatDate));

                        dataIndex = lineChart.labels.length - 1;
                    }

                    lineChart.datasets.forEach(dataset => {
                        dataset.data[dataIndex] += stats.metrics[0].received;
                    })

                }
            })
        })
        this.setState({ cards, lineChart, block: false });
    }

    render() {
        const { block, error, cards, lineChart } = this.state;

        const optionsLine = {
            type: 'line',
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                x: { display: true },
                y: {
                    beginAtZero: true,
                    ticks: {
                        precision: 0
                    },
                    grid: {
                        display: false
                    },
                }
            },
            plugins: {
                datalabels: { display: false }
            }
        }

        return (
            <BlockUi tag="div" blocking={block} style={{ fontSize: '0.9em', height: '100%' }}>
                <ErrorAlert error={error} />
                <Row className="h-100 overflow-auto">
                    <Col>
                        <Form onSubmit={this.getGlobalEmailStats}>
                            <Row className="align-items-center mb-3 ml-2">
                                <Col className="col-2 pl-2 pr-2">
                                    <Row>
                                        <Col>
                                            <FormattedMessage id="EmailResWebhooks.Dates" />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <DateRangePicker
                                                startDate={this.state.startDate}
                                                startDateId="startDate"
                                                isOutsideRange={day => day > moment()}
                                                endDate={this.state.endDate}
                                                endDateId="endDate"
                                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                                focusedInput={this.state.focusedInput}
                                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                                small={true}
                                                numberOfMonths={1}
                                                showDefaultInputIcon={true}
                                                renderMonthElement={({ month }) => moment(month).format('MMMM YYYY')}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn btn-host btn-sm" type="submit">
                                        <i className="fas fa-search" />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                        <Row>
                            {cards.map((card, key) =>
                                <Col key={key}>
                                    <Card className="bg-white border-0 py-2 col-2" body>
                                        <Row>
                                            <Col className="text-muted">
                                                {card.label}
                                            </Col>
                                            <Col className="text-right" >
                                                <b style={{ color: card.color }}>
                                                    <FormattedNumber value={card.value} />
                                                </b>
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                            )}
                        </Row>

                        <Row className="mt-3">
                            <Col>
                                <Card className="bg-white border-0" body style={{ height: '60vh' }}>
                                    {lineChart ? 
                                        <Line data={lineChart} options={optionsLine} type='line' />
                                        : ''} 
                                </Card>

                            </Col>
                        </Row>
                    </Col>              
                </Row>
            </BlockUi>
        )
    }
}
