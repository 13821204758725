import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { ActiveInactiveStatusBadge, StyledCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import RatesIntegrationsModal from './RatesIntegrationsModal';

export class RatesIntegrations extends Component {

    constructor(props) {
        super(props);
        this.getRateCodeDetails = this.getRateCodeDetails.bind(this);
        this.toggleRatesIntegrationsModal = this.toggleRatesIntegrationsModal.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.state = {
            block: false,
            ratesIntegrationsModal: false,
            title: null,
            rateCodeDetail: null,
            rateCodeDetails: [],
            rateCodes: []
        };
    }

    componentDidMount() {
        this.getRateCodes();
    }

    getRateCodes() {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data && data.response && data.response.length > 0) {
                const rateCodes = data.response.map(rateCode => {
                    const opt = {
                        value: rateCode.id,
                        label: rateCode.name
                    };
                    return opt;
                });
                this.setState({ rateCodes: rateCodes }, this.getRateCodeDetails);
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/Rate/v1/Info`);
    }

    getRateCodeDetails() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data && data.response && data.response.length > 0) {
                const rateCodeDetails = data.response.map(resp => {
                    const rateCode = this.state.rateCodes.find(rateCode => rateCode.value === resp.rateCodeId);
                    if (rateCode) {
                        resp.rateCodeName = rateCode.label;
                    }
                    return resp;
                })
                this.setState({ error: errorMessage, block: false, rateCodeDetails: rateCodeDetails });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/Rate/v1/ratecode/details`);
    }

    toggleRatesIntegrationsModal() {
        this.setState(prevState => ({ ratesIntegrationsModal: !prevState.ratesIntegrationsModal, rateCodeDetail: null, title: <FormattedMessage id="RatesIntegrations.CreateDetails" /> }));
    }

    updateTable(newRateCodeDetail) {
        const { rateCodeDetails } = this.state;
        const rateCodeDetail = rateCodeDetails.find(rcd => rcd.rateCodeId === newRateCodeDetail.rateCodeId);
        if (rateCodeDetail) {
            Object.assign(rateCodeDetail, newRateCodeDetail);
        }
        else {
            rateCodeDetails.push(newRateCodeDetail);
        }
        const rateCodeDetailsAux = rateCodeDetails;
        this.setState({ rateCodeDetails: [] }, _ => this.setState({ rateCodeDetails: rateCodeDetailsAux }));
    }

    render() {
        const { block, error, rateCodeDetails, ratesIntegrationsModal, title, rateCodeDetail, rateCodes } = this.state;

        const columns = [
            {
                dataField: 'rateCodeName',
                text: <FormattedMessage id="RatesIntegrations.Rate" />,
            },
            {
                dataField: 'breakfastIncluded',
                text: <FormattedMessage id="RatesIntegrations.BreakfastIncluded" />,
                formatter: cell => <ActiveInactiveStatusBadge status={cell} />
            },
            {
                dataField: 'internetIncluded',
                text: <FormattedMessage id="RatesIntegrations.InternetIncluded" />,
                formatter: cell => <ActiveInactiveStatusBadge status={cell} />
            },
            {
                dataField: 'parkingIncluded',
                text: <FormattedMessage id="RatesIntegrations.ParkingIncluded" />,
                formatter: cell => <ActiveInactiveStatusBadge status={cell} />
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row) => this.setState({ ratesIntegrationsModal: true, rateCodeDetail: row, title: <FormattedMessage id="RatesIntegrations.EditDetails" /> })
        };

        return (
            <StyledCard block={block} error={error}>
                {ratesIntegrationsModal ?
                    <Modal isOpen={ratesIntegrationsModal} toggle={this.toggleRatesIntegrationsModal}>
                        <ModalHeader toggle={this.toggleRatesIntegrationsModal}>
                            {title}
                        </ModalHeader>
                        <ModalBody className="p-0">
                            <RatesIntegrationsModal
                                rateCodeDetail={rateCodeDetail}
                                rateCodes={rateCodes.filter(rateCode => !rateCodeDetails.find(detail => rateCode.value === detail.rateCodeId) || rateCode.value === (rateCodeDetail && rateCodeDetail.rateCodeId))}
                                updateTable={this.updateTable}
                            />
                        </ModalBody>
                    </Modal>
                : '' }
                <Row className="mb-1">
                    <Col className="text-right">
                        <Button color=" btn-host btn-sm float-right" onClick={this.toggleRatesIntegrationsModal}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomTable
                            keyField="rateCodeId"
                            data={rateCodeDetails}
                            columns={columns}
                            selectRow={selectRow}
                            search={true}
                        />
                    </Col>
                </Row>
            </StyledCard>    
        );
    }
}