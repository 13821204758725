import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Button, Row, Badge, Label, Input, CustomInput, Form } from 'reactstrap';
import { postAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import { getToneOptions } from '../../Base/Common/ReferenceDataFunctions';
import BlockUi from 'react-block-ui';


class Playground extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            toneOptions: getToneOptions(),
            questionsOptions: [{ label: 'FAQ', value: 'FAQ' }, { label: 'Review', value: 'Review' }],
            answerSize: "50",
            tone: "Neutral",
            type: 'FAQ'
        };
    }

    generateAiResponse = (e) => {
        e.preventDefault();

        const { userText, answerSize, tone, type } = this.state;

        const chatCompletion = {
            input: userText,
            max_tokens: answerSize * 3, // one word is around 3 tokens
            tone: tone,
        };

        this.setState({ block: true }, () => {
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data?.errors && data.errors.length > 0) {
                    this.setState({ block: false, error: data.errors }, () => handleNotification(data));
                    return;
                };
                const generatedResponse = data?.response && data?.response[0];

                if (generatedResponse) {
                    this.setState({ block: false, error: null, generatedResponse });
                }
                else {
                    this.setState({ block: false, error: null }, () => handleNotification(data));
                }
            }, `/api/hotel/FaqsManagement/v1/${type === 'FAQ' ? 'CreateChat' : 'AnswerToReview'}`, chatCompletion);
        });
    }


    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            [name]: value
        })
    }

    handleSize = (e) => {
        this.setState({ answerSize: e?.target?.value ?? '10' });
    }

    handleOptions = (value, name) => {
        this.setState({
            [name]: value
        })
    }


    render() {
        const { block, generatedResponse, answerSize, tone, type, toneOptions, questionsOptions } = this.state;


        return (
            <BlockUi tag="div" blocking={block}>
                <div style={{ height: '95%', border: '1px solid #bbc1c9ab', borderRadius: global.isMobile ? '0px 5px 5px 5px' : '5px', boxShadow: '0px 3px 15px #6F73881A' }} className={`p-3 h-100 bg-white ${global.isMobile ? 'border-top-0' : ''}`}>
                    {!global.isMobile ?
                        <>
                            <div style={{ height: '5%' }} className='d-flex pb-3 mb-2 px-0 align-items-center justify-content-start'>
                                <h5 style={{ fontWeight: '500' }}>
                                    <i className="fas fa-brain px-1" /> <FormattedMessage id={`HeyTravelAI.Playground`} />
                                </h5>
                            </div>
                        </>
                    : ''}

                    <Form onSubmit={this.generateAiResponse}>
                        <Row className="mb-4 align-items-end">
                            <Col className="col-12">
                                <Label> <FormattedMessage id="HeyTravelAI.Question" /> </Label>
                                <Input
                                    type="textarea"
                                    name="userText"
                                    onChange={this.handleChange}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col className="col-10 col-md-3 order-1">
                                <div className="mb-1"><FormattedMessage id="HeyTravelAI.Type" /></div>

                                {questionsOptions.map((option, key) =>
                                    <Badge key={key} className={'mr-2 ' + (type === option.value ? "activeTemp" : "inactiveTemp")} onClick={_ => this.handleOptions(option.value, "type")}>
                                        {option.label}
                                    </Badge>
                                )}
                            </Col>
                            <Col className="col-12 col-md-6 mt-2 mt-md-0 order-3 order-md-2">
                                <div className='mb-1'><FormattedMessage id="HeyTravelAI.AIOptions" /></div>
                                <Row>
                                    <Col className={(!global.isMobile ? 'd-flex' : '') + ' mt-2'}>
                                        <CustomInput
                                            type="range"
                                            name="answerSize"
                                            onChange={this.handleSize}
                                            id="answerSize"
                                            className='gptRange'
                                            min="10"
                                            max="1000"
                                            step="10"
                                            value={answerSize}
                                        />
                                        <div className='ml-2 text-muted' style={{ fontSize: 'smaller' }}>
                                            <span className='mr-1'>{answerSize}</span>
                                            <FormattedMessage id="GPT.Words" />
                                        </div>
                                    </Col>
                                    <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '1rem' }}>
                                        {toneOptions.map((option, key) =>
                                            <Badge key={key} className={tone === option.value ? "activeTemp" : "inactiveTemp"} onClick={_ => this.handleOptions(option.value, "tone")}>
                                                <FormattedMessage id={`OpenAI.${option.label}`} />
                                            </Badge>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="col-2 col-md-3 d-flex align-items-end justify-content-end pb-1 pb-md-0 order-2 order-md-3">
                                <Button onClick={this.generateAiResponse} className="activeTemp generate">
                                    <div className="d-flex align-items-center justify-content-center" style={{ gap: '0.5rem', fontSize: '0.8em' }}>
                                        <i className="fas fa-keyboard"/>
                                        <FormattedMessage id="GPT.Generate" />
                                    </div>
                                </Button>
                            </Col>
                        </Row>
                    </Form>


                    <div className="mt-4 mb-2"> <FormattedMessage id="HeyTravelAI.GeneratedResponse" /> </div>
                    <div>
                        <Input
                            type="textarea"
                            readOnly
                            value={generatedResponse || ''}
                            style={{ height: '30vh' }}
                        />
                    </div>
                </div>
            </BlockUi>
        )
    }
}

export default injectIntl(Playground)