import React, { Component } from 'react';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, Modal, ModalHeader, ModalBody, UncontrolledButtonDropdown } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { BlankCard, reservationStatus } from '../../Base/Common/CommonUIComponents';
import ReservationLog from '../../Reservations/ReservationLog';

export class ReservationStatusDashboardModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };
    }

    toggleRemoveReservationModal = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    render() {
        const { block, changeStatus, deleteReservation, isOpen, modalError, resendToPms, selectedReservationId, selectedReservationStatus, toggleModal } = this.props;
        const { modal } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggleModal} fade={false} className="modal-lg">
                <ModalHeader toggle={toggleModal}>
                    <FormattedMessage id="ReservationDetail.ReservationDetails" />
                </ModalHeader>
                <ModalBody className="modal-lg px-2 pb-2">
                    <BlankCard block={block} error={modalError}>
                        {
                            selectedReservationId ?
                                <div>
                                    {
                                        modal ?
                                            <Modal isOpen={modal} toggle={this.toggleRemoveReservationModal} style={{ minWidth: 0 }}>
                                                <ModalBody>
                                                    <BlankCard block={block} error={modalError}>
                                                        <Row className="mb-1">
                                                            <Col className="text-center">
                                                                <FormattedMessage id="ReservationStatusDashboard.ConfirmRemoveReservation" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-1">
                                                            <Col className="text-center">
                                                                <Button onClick={_ => deleteReservation(this.toggleRemoveReservationModal)} className="btn-host btn-sm mr-1">
                                                                    <FormattedMessage id="ReservationStatusDashboard.Yes" />
                                                                </Button>
                                                                <Button onClick={this.toggleRemoveReservationModal} className="btn-host btn-sm">
                                                                    <FormattedMessage id="ReservationStatusDashboard.No" />
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </BlankCard>
                                                </ModalBody>
                                            </Modal>
                                            :
                                            <div />
                                    }
                                    <Row>
                                        <Col className="text-right mb-3">
                                            <Button onClick={resendToPms} className="btn-host btn-sm mr-1">
                                                <FormattedMessage id="ReservationStatusDashboard.ResendReservation" />
                                            </Button>
                                            <UncontrolledButtonDropdown>
                                                <DropdownToggle className="btn-host btn-sm mr-1" caret>
                                                    <FormattedMessage id="ReservationStatusDashboard.ChangeStatus" />
                                                </DropdownToggle>
                                                <DropdownMenu right>
                                                    {
                                                        reservationStatus.map((status, key) =>
                                                            <DropdownItem key={key} onClick={_ => changeStatus(status.value)} disabled={status.value === selectedReservationStatus}>
                                                                {status.label}
                                                            </DropdownItem>
                                                        )
                                                    }
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>
                                            <Button onClick={this.toggleRemoveReservationModal} className="btn-host btn-sm">
                                                <FormattedMessage id="ReservationStatusDashboard.DeleteReservation" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <ReservationLog reservationId={selectedReservationId} url={`/api/Price/Reservation/v1/Log/${selectedReservationId}`} />
                                </div>
                                :
                                <div />
                        }
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}