import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { Card, Col, Row } from 'reactstrap';
import Chart from "react-google-charts";

class ChartCard extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        const { title, chartType, options, data, total, fullScreen, fullScreenFunction, height, chartEvent, chartDivStyle } = this.props;

        return (
            <Card body className="shadow border-0 h-100" >
                <Row>
                    <Col className="text-xs font-weight-bold text-secondary text-uppercase mb-1">{title}</Col>
                    {total ?
                        <Col className="text-xs font-weight-bold text-secondary text-right mb-1">{total}</Col>
                    :''}
                    {fullScreen ?
                        <Col className="text-right col-1">
                            <i onClick={fullScreenFunction} className="fas fa-expand-arrows-alt"></i>
                        </Col>
                    :''}
                </Row>
                <Row style={chartDivStyle || {}}>
                    {data && data.length > 0 ?
                        <Chart
                            chartType={chartType}
                            width="100%"
                            height={height ? height : '200px'}
                            data={data}
                            options={options}
                            chartEvents={chartEvent}
                        />
                    : ''}
                </Row>
            </Card>
        )
    }
}

export default injectIntl(ChartCard);
