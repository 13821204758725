import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import moment from 'moment';

export class DashBoardCard extends Component {

    state = {
        total: this.props.total,
        name: this.props.name,
        formatedDate: this.props.formatedDate
        
    }


    static renderCard(tot, title, formatedDate) {
        const hasValues = tot != null;
        
       
        if (hasValues ) {
            
            return (
                <Card className="border-left-primary shadow h-100 py-2">
                    <CardBody>
                        <Row className="row no-gutters align-items-center">
                            <Col className="mr-2">
                                <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1">{title}</p>
                                <p className="h4 mb-0 font-weight-bold ">{tot}</p>

                                <p className=" mb-0 text-black-50 ">Last Received: {moment(formatedDate).fromNow()}</p>
                            </Col>

                            <div className="col-auto">
                                <i className="fas fa-calendar-alt fa-2x text-gray-300">
                                </i>
                            </div>
                        </Row>
                    </CardBody>
                </Card>
            );
        }
    }

    render() {
        let dashBoardCard = DashBoardCard.renderCard(this.props.total, this.props.name, this.props.formatedDate);
        return (

            <div>{dashBoardCard}</div>

        );
    }
}
