import React, { Component } from 'react';
import { Row, Col, CardBody, Tooltip, Collapse } from 'reactstrap';
import { RateAvailabilityBox } from './RateAvailabilityBox';
import { ChannelBar } from './ChannelBar';
import { FormattedMessage } from 'react-intl';
import { ActiveInactiveStatusBadge } from '../Base/Common/CommonUIComponents'
import { getDependecyOrderTypes, getMarkupType } from '../Base/Common/ReferenceDataFunctions';


export class RateAvailabilityBar extends Component {

    state = {
        channelvisible: false,
        channel: null,
        occupationTolltip: false,
        priceLock: false,
        paxCollapse: false,
        channelCollapse: false,
        restriction: []
    }

    changeChannelVisibility() {
        this.setState({ channelvisible: !this.state.channelvisible})
    }

    setChannel(combo, evt) {
        if (evt) {
            this.setState({ channel: combo && combo.value });
        }
    }

    toggleState = (stateName) => {
            this.setState(prev => ({ ...prev,  [stateName]: !prev[stateName] }));
    }

    toggleTooltip = (fieldName, isOpen) => {
        this.setState({
            [fieldName]: isOpen
        });
    }

    getDependentRate = (applicablepricelist) => {
        let dependentRate = null;

        if (applicablepricelist) {
            const dependencyId = applicablepricelist.dependencyId || applicablepricelist.dependencyMasterId;

            dependentRate = this.props.inventoryData.ratecode
                .find(drc =>
                    drc.pricelistId === dependencyId &&
                    drc.roomCategoryId == applicablepricelist.dependencyReferenceRoomCategory &&
                    drc.packageId == applicablepricelist.dependencyReferencePackage
                );
        }

        return dependentRate;
    }

    render() {
        const { paxList, maxpaxListpos, positionToDisplay, getChildrenAmount } = this.props;
        const { occupationTolltip, priceLock, channelCollapse, paxCollapse } = this.state;
        
        const restriction = this.props.inventoryData?.externalPmsRestriction.filter((rest) => this.props.rateCode?.id === rest.rateCodeId );
        
        const rateCode = this.props.rateCode;
        const priceTemplate = this.props.inventoryData.pricetemplate; 
        const ratecodechannel = this.props.inventoryData.ratecodechannel;
        
        const elementId = Math.floor((Math.random() * 1000000) + 1);
        const ratecodeChannelforRate = ratecodechannel.filter(el => el.rateCodeId === rateCode.id);

        const applicablepricelist = this.props.inventoryData.pricelist.find(pl => pl.id === rateCode.pricelistId);
        
        const depedentRateCode = this.getDependentRate(applicablepricelist);
        const depedentpaxList = depedentRateCode && this.props.inventoryData.rateCodePax.filter((px) => depedentRateCode.id === px.rateCodeId).sort(function (a, b) { return a.adults === b.adults ? (a.adults + a.children) - (b.adults + b.children) : a.adults - b.adults });
        
        var hasPaxWarn = false;
        if (depedentpaxList != null && paxList != null) {
         
            paxList.forEach(element => depedentpaxList.find(a => a.adults === element.adults) === undefined ? hasPaxWarn = true : '');
        }

        function getChannelInstanceName(channelInstance, channelInstanceId) {
            const channel = channelInstance && channelInstance.find(el => el.id === channelInstanceId);
            return channel ? channel.name : '';
        }

        return (
            <div>
            {rateCode.visible ?
                <form autoComplete="off">
                    <Row className={"d-flex flex-row flex-nowrap" + (this.props.selectedRecord.selectRate == rateCode.id ? ' border border-primary' : ' ')} >
                        <Col className="col-3 border-right pl-2 border-bottom position-sticky" style={{ left: '0', zIndex: '1', background: 'white' }}>
                            <Row>
                                <Col className="col-12">
                                    <div className=" w-100">
                                        <span className="small" style={{ 'cursor': 'pointer' }} onClick={() => this.props.selectRow(this, rateCode.id, rateCode.roomCategoryId, rateCode.blockId, applicablepricelist && applicablepricelist.dependencyMasterId)}>
                                            {rateCode.rateText}
                                        </span>
                                        
                                        <br />
                                        <a className="float-left font_size_xxs mr-1 inline pointer" onClick={() => this.toggleState('channelCollapse')} id={'toggle' + elementId}>
                                            <span className="badge badge-secondary mt-2">
                                                {ratecodeChannelforRate.length}
                                                <i className="fas fa-random ml-1"></i>
                                            </span>
                                        </a>
                                        <a className="float-left font_size_xxs inline pointer"  onClick={this.changeChannelVisibility.bind(this)} >
                                            <span className="badge badge-secondary mt-2 mr-1">
                                                <span className='fas fa-ban'/>          
                                                <span className={this.state.channelvisible ? 'fas fa-chevron-down ml-2' : 'fas fa-chevron-up ml-2'} />
                                            </span>
                                        </a>
                                        <a onMouseLeave={() => this.toggleTooltip("occupationTolltip", false)} onMouseEnter={() => this.toggleTooltip("occupationTolltip", true)}
                                         onClick={() => this.toggleState('paxCollapse')}
                                        className="float-left font_size_xxs inline pointer" id={`pax${rateCode.id}${rateCode.roomCategoryId}`}>
                                            <span className={hasPaxWarn ? "badge badge-danger text-white mt-2 mr-1" : "badge badge-secondary mt-2 mr-1"}>
                                                {maxpaxListpos}
                                                <span className={'p-0 float-right fas fa-user py-0 px-1 mr-1 ml-1 '}>
                                                    {paxList.length > 1 ? '+' : ''}
                                                </span>                                               
                                            </span>
                                        </a>
                                        
                                        {occupationTolltip ? <Tooltip isOpen={occupationTolltip}
                                        placement="right" target={`pax${rateCode.id}${rateCode.roomCategoryId}`}>
                                            {
                                                paxList.map((item, key) =>
                                                    <Row key={key}>
                                                        <Col>
                                                            <div className="text-left">
                                                                <i className="fas fa-user mx-1"></i>
                                                                {item.children > 0 ? <i className="fas fa-child mr-1"></i> : ''}
                                                                {item.description}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                            {hasPaxWarn ? 
                                                <Row >
                                                    <Col>
                                                        <div className="text-left">
                                                            <i className="fas fa-exclamation-triangle mx-1 text-warning" ></i>
                                                            <FormattedMessage id={"RateAvailability.DiferentOccupation"} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                : ''}
                                        </Tooltip>:''}
                                        {applicablepricelist && applicablepricelist.dependencyMasterId != null ? 
                                                <div>
                                                    <div onMouseLeave={() => this.toggleTooltip('priceLock', false)} onMouseEnter={() => this.toggleTooltip('priceLock', true)}
                                                    className="float-left font_size_xxs inline" id={`pricelock${rateCode.id}${rateCode.roomCategoryId}`}>
                                                        <span className="badge badge-secondary mt-2 mr-1">
                                                            <span className='fas fa-lock mr-1'/>Price
                                                        </span>
                                                    </div>
                                                    {priceLock ?
                                                        <Tooltip isOpen={priceLock} placement="right"
                                                            target={`pricelock${rateCode.id}${rateCode.roomCategoryId}`}>
                                                            {
                                                                applicablepricelist.dependecyOrder === null ?
                                                                    <span>
                                                                        {
                                                                            applicablepricelist.dependencyPercentage == 0 && applicablepricelist.dependencyAmount == 0 ? 'Same price as ' : ''
                                                                        }
                                                                        {
                                                                            applicablepricelist.dependencyPercentage != 0 ? applicablepricelist.dependencyPercentage + ' % of ' : ''
                                                                        }
                                                                        {
                                                                            applicablepricelist.dependencyAmount != 0 ? applicablepricelist.dependencyAmount + ' of ' : ''
                                                                        }
                                                                    </span>
                                                                    :
                                                                    <span>
                                                                        {
                                                                            applicablepricelist.dependecyOrder === getDependecyOrderTypes()[0] ? `${applicablepricelist.dependencyAmount} + ${applicablepricelist.dependencyPercentage} % of ` : ``
                                                                        }
                                                                        {
                                                                            applicablepricelist.dependecyOrder === getDependecyOrderTypes()[1] ? `${applicablepricelist.dependencyPercentage} % + ${applicablepricelist.dependencyAmount} of ` : ``
                                                                        }
                                                                    </span>
                                                            }
                                                            {
                                                                depedentRateCode && depedentRateCode.code
                                                            }
                                                        </Tooltip> : ''
                                                    }
                                                </div>
                                                :
                                                ''
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        {
                            this.props.next14Days.map((item, k) =>
                                <RateAvailabilityBox
                                    key={k}
                                    day={item}
                                    priceTemplate={priceTemplate}
                                    rateCode={this.props.rateCode}
                                    restriction={restriction}
                                    position={positionToDisplay}
                                    selectRow={this.props.selectRow}
                                    lineIndex={this.props.index}
                                    columnIndex={k}
                                    isDependent={applicablepricelist && applicablepricelist.dependencyMasterId != null}
                                    channelInstances={this.props.inventoryData.channelInstance}
                                    ratecodeChannelforRate={ratecodeChannelforRate}
                                    getChildrenAmount={getChildrenAmount}
                                />
                            )
                        }
                    </Row>
                    <div>
                        {
                            this.state.channelvisible ?
                                <ChannelBar
                                    restriction={restriction}
                                    next14Days={this.props.next14Days}
                                    isDependent={applicablepricelist && applicablepricelist.dependencyMasterId != null}
                                    rateCodeId={rateCode.id}
                                    roomCategoryId={rateCode.roomCategoryId}
                                    blockId={rateCode.blockId}
                                    applicablepricelist={applicablepricelist}
                                    selectRow={this.props.selectRow}
                                    channels={ratecodeChannelforRate}
                                    getChannelInstanceName={getChannelInstanceName}
                                    channelInstance={this.props.inventoryData.channelInstance}
                                    channel={this.state.channel}
                                    setChannel={this.setChannel.bind(this)}
                                />
                                :
                                ''
                        }
                    </div>
                        {paxList.length > 1 && paxCollapse?
                            <Collapse isOpen={paxCollapse} toggler={`pax${rateCode.id}${rateCode.roomCategoryId}`}>
                                <div className="border-primary">
                                    {
                                        paxList.map((el, key) =>
                                            <Row key={key} className="d-flex flex-row flex-nowrap">
                                                <Col className="col-3 border-right border-bottom pl-2 position-sticky" style={{ left: '0', zIndex: '1', background: 'white' }}>
                                                    <span>
                                                        {el.description}
                                                        <i className="fas fa-caret-left ml-2" style={{ display: el.adults === rateCode.displayPricesFrom && el.children === 0 ? 'initial' : 'none' }} />
                                                    </span>
                                                </Col>
                                                {
                                                    this.props.next14Days.map((item, k) =>
                                                        <RateAvailabilityBox key={k} day={item} priceTemplate={priceTemplate} rateCode={this.props.rateCode} restriction={[]} position={el.adults} children={el.children} selectRow={this.props.selectRow} lineIndex={this.props.index} columnIndex={k} isDependent={applicablepricelist && applicablepricelist.dependencyMasterId != null} getChildrenAmount={getChildrenAmount} />
                                                    )
                                                }
                                            </Row>
                                        )
                                    }
                                </div>
                            </Collapse> 
                            :
                            <div/>}
                    {channelCollapse ? 
                    <Collapse isOpen={channelCollapse} toggler={'#toggle' + elementId}>
                        <CardBody className="small shadow">
                            <Row className="mb-2">
                                <Col className="col-2 pr-4">
                                    <b> <FormattedMessage id={"RateAvailability.Channel"} /> </b> 
                                </Col>
                                <Col>
                                     <b> <FormattedMessage id={"RateAvailability.ChannelRoomCode"} /> </b>
                                </Col>
                                <Col>
                                    <b> <FormattedMessage id={"RateAvailability.ChannelRateCode"} /> </b>
                                </Col>
                                <Col>
                                    <b> <FormattedMessage id={"RateAvailability.PriceMode"} /> </b>
                                </Col>
                                <Col>
                                    <b> <FormattedMessage id={"RateAvailability.PricePosition"} /> </b>
                                </Col>
                                <Col>
                                    <b> <FormattedMessage id={"RateAvailability.PriceMarkupValue"} /> </b>
                                </Col>
                                <Col>
                                    <b> <FormattedMessage id={"RateAvailability.Interface"} />  </b>
                                </Col>
                                <Col sm={1}>
                                    <b> <FormattedMessage id={"RateAvailability.Status"} /> </b>
                                </Col>
                            </Row>
                            {
                                ratecodeChannelforRate.map((item, key) => {
                                    const channel = this.props.inventoryData.channelInstance?.find(el => el.id === item.channelInstanceId);
                                    
                                    return channel && <Row key={key} className="overbglight py-1">
                                        <Col className="col-2 pr-4">
                                            <span className="px-1"> {channel.name} </span>
                                            <span className="float-right"><ActiveInactiveStatusBadge status={channel.active} /></span>
                                        </Col>
                                        <Col>
                                            {item.roomTypeMapping}
                                        </Col>
                                        <Col>
                                            {item.rateCodeMapping}
                                        </Col>
                                        <Col>
                                            {item.priceMode >= 0 ? <FormattedMessage id={"generic.priceMode" + item.priceMode} /> : ''}
                                        </Col>   
                                        <Col>
                                            {item.pricePosition && item.priceMode !== 0 ? item.pricePosition : '' }
                                        </Col>
                                        <Col>
                                            {item.priceMarkupValue !== 0 ? `${item.priceMarkupValue} ${item.priceMarkupType === getMarkupType()[0].value ? `%` : ``}` : ''}
                                        </Col>
                                        <Col>
                                            {item.interfaceMode >= 0 ? <FormattedMessage id={`generic.inventoryMode${item.interfaceMode}`} /> : ''}
                                        </Col>  
                                        <Col sm={1}>
                                            <ActiveInactiveStatusBadge status={item.active}/>
                                        </Col>
                                    </Row>
                                })
                            }
                        </CardBody>
                    </Collapse> :''}
                </form>
            :''}
            </div>
        );

    }
};