import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import classnames from 'classnames';
import { BookingMailBoxCard } from './BookingMailBoxCard';
import AirbnbMailBoxCard from './AirbnbMailBoxCard';
import { FormattedMessage } from 'react-intl';
import { CommonHelper } from '../../Base/Common/CommonUIComponents'
import ExpediaMailBoxCard from './Expedia/ExpediaMailBoxCard';
import Authorization from '../../Base/Authorization';

export class MailDashboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        
        this.state = {
            activeTab: '2',
            Alerts: [],
            hasAirbnb: false,
            hasBooking: false,
            hasExpedia: false
        };
    }
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }


    componentDidMount() {
        this._isMounted = true;

        window.setTimeout(
            () => {
                const hasAirbnb = global.channelList.includes('AIRBNB');
                const hasExpedia = global.channelList.includes('EXPEDIA');

                const search = window.location.search;
                const params = new URLSearchParams(search);
                const channel = params.get('channel');
                const channelInstance = params.get('channelInstance');

                const activeTab = hasAirbnb && channel === 'AIRBNB' ? '3' : (hasExpedia && channel === 'EXPEDIA' ? '4' : '2');

                this.setState({
                    hasAirbnb,
                    activeTab,
                    channelInstance,
                    hasBooking: global.channelList.includes('BOOKING'),
                    hasExpedia
                });
            }, global.channelList && global.channelList.length > 0 ? 0 : 2500
        );
    }
    
    render() {
        const { hasBooking, hasAirbnb, hasExpedia } = this.state;
        return (
            <Card className="border-0">
                <Nav tabs>
                    {/*
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="MailDashboard.MailboxOverview" /> 
                            <CommonHelper help={<FormattedMessage id="MailDashboard.MailOverviewHelp" />} id={'maildashboardoverview'} /> 
                        </NavLink>
                    </NavItem>*/}
                    {hasBooking ?
                        <NavItem>
                            < NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                <FormattedMessage id="MailDashboard.BookingMailbox" />
                            </NavLink>
                        </NavItem>
                    : ''}

                    {hasAirbnb ?
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                <FormattedMessage id="MailDashboard.AirBnBMailBox" />
                            </NavLink>
                        </NavItem>
                    : ''}

                    {hasExpedia ?
                        <Authorization
                            perform="admin:view"
                            yes={() => (
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                                        <FormattedMessage id="MailDashboard.ExpediaMailBox" />
                                        <i className="fas fa-exclamation-circle p-1" style={{ color: 'red' }} id="EmailReservationDashboard" />
                                    </NavLink>
                                </NavItem>
                            )}
                            no={() => <div></div>}
                        />
                    : ''}

                    <NavItem className="ml-auto border-bottom mt-2 mr-2">
                        <CommonHelper help={<FormattedMessage id="MailDashboard.Help" />} id={'MailDashboard'} placement={global.isMobile ? 'bottom' : 'left'} />
                    </NavItem>              
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    {/*<TabPane tabId="1">
                        <MailOverview />
                    </TabPane>
                    */}

                    {hasBooking ?
                        <TabPane className={`${global.isMobile ? 'border-0 pt-0' : ''}`} tabId="2">
                            <BookingMailBoxCard
                                channelInstance={this.state.channelInstance}
                            />
                        </TabPane>
                        : ''}

                    {hasAirbnb ?
                        <TabPane className={`${global.isMobile ? 'border-0 pt-0' : ''}`} tabId="3">
                            <AirbnbMailBoxCard
                                channelInstance={this.state.channelInstance}
                            />
                        </TabPane>
                        : ''}

                    {hasExpedia ?
                        <TabPane className={`${global.isMobile ? 'border-0 pt-0' : ''}`} tabId="4">
                            <ExpediaMailBoxCard
                                channelInstance={this.state.channelInstance}
                            />
                        </TabPane>
                        : ''}
                </TabContent>
            </Card>
        );
    }
}