import React, { Component } from 'react';
import { Badge, Button, Modal, ModalBody, ModalHeader, ModalFooter, Col, Row, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledTooltip, Card } from 'reactstrap';
import classnames from 'classnames';
import { deleteAPI, getAPI } from "../../Base/API"; 
import BookingPromotionCard from './BookingPromotionCard';
import { FormattedMessage, injectIntl } from 'react-intl';
import { PromotionSync } from './PromotionSync';
import BlockUi from 'react-block-ui'
import { ErrorAlert } from "../../Common/ErrorAlert";
import { CommonHelper } from "./../../Base/Common/CommonUIComponents";
import CustomSelect from '../../Base/Common/CustomSelect';
import { PromotionSummaryCard } from './PromotionSummaryCard';
import { handleNotification } from '../../Base/Notification';
import CustomToolTip from '../../../Utils/CustomToolTip';
import { CheckAuthorization } from '../../Base/Authorization';

export class PromotionDashboard extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.togglePromotionModal = this.togglePromotionModal.bind(this);
        this.toggleSyncModal = this.toggleSyncModal.bind(this);
        this.disablePromotion = this.disablePromotion.bind(this);
        this.state = {
            activeTab: '1',
            block : true,
            promotionModal: false,
            syncModal: false,
            Alerts: [],
            activePromotions: [],
            deactivePromotions: [],
            rateList: [],
            rateCodeChannels: [],
            channelInstances: [],
            selectedChannel: null,
            //Promotion reference data
            promotionType: [
                { name: this.props.intl.formatMessage({ id: "PromotionDashboard.Basic" }), icon: "fa-tag", code: "basic", active: false },
                { name: this.props.intl.formatMessage({ id: "PromotionDashboard.LastMinute" }), icon: "fa-clock", code: "last_minute", active: false },
                { name: this.props.intl.formatMessage({ id: "PromotionDashboard.EarlyBooker" }), icon: "fa-coffee", code: "early_booker", active: false },
                { name: this.props.intl.formatMessage({ id: "PromotionDashboard.CountryRate" }), icon: "fa-globe-americas", code: "geo_rate", active: false },
                { name: this.props.intl.formatMessage({ id: "PromotionDashboard.MobileRate" }), icon: "fa-mobile-alt", code: "mobile_rate", active: false }
            ],
            invalidPromotionType: [
                { name: "Free Nights", icon: "fa-moon", code: "free_nights" },
                { name: "Flash", icon: "fa-bolt", code: "flash" },
                { name: "Travel Offer", icon: "fa-tag", code: "preset" },
                { name: this.props.intl.formatMessage({ id: "PromotionDashboard.BusinessRate" }), icon: "fa-briefcase", code: "business_booker" }
            ],
            disableModal: false,
            editMode: false
        };        
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                let activePromotions = [];
                let deactivePromotions = [];
                if (data.request) {
                    activePromotions = data.request.promotion.filter(item => item.active);
                    deactivePromotions = data.request.promotion.filter(item => !item.active);
                }

                this.setState({ activePromotions: activePromotions, deactivePromotions: deactivePromotions, block: false });
            }
        }, '/api/Price/Promotion/v1');

        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                data.map(item => {
                    item.value = item.id;
                    item.label = `${item.name} (${item.propertyCode})`;
                    this.getRateCodeChannelsByChannelInstance(item);
                    return item;
                });
                this.setState({ channelInstances: data, block: false, selectedChannel: data.length > 0 ? data[0].value : null });
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/Booking.com/active');
    }

    getRateCodeChannelsByChannelInstance(channelInstance) {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                channelInstance.rateCodeChannels = data;
            }
            this.setState({ block: false });
        }, `/api/Rate/RateCodeChannel/v1/ChannelInstance/${channelInstance.id}`);
    }
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    togglePromotionModal(item) {        
        this.setState(prevState => ({
            promotionModal: !prevState.promotionModal,
            selectedPromotion: item
        }));
    }

    toggleSyncModal() {
        this.setState(prevState => ({
            syncModal: !prevState.syncModal
        }));
    }

    setComboStatus = (field, inputValue) => {
        var targetValue = '';
        targetValue = inputValue && inputValue.value;

        this.setState({ [field]: targetValue })
    }

    isEditDisabled = (item) => {
        return !CheckAuthorization("promotions:edit", null) || this.state.promotionType && this.state.promotionType.find(promotionType => promotionType.code == item.type) == null;
    }

    toggleDisableModal(item) {
        this.setState(prevState => ({
            disableModal: !prevState.disableModal,
            selectedPromotion: item
        }));
    }

    disablePromotion() {
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                if (error.response.data) {
                    error.response.data.errors.map((item) => {
                        errorMessage.push({ message: item.message, stack: error.stack, messageType: 'danger' });
                        return true;
                    })
                }
                this.setState({ errorDisable: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="BookingPromotionList.DisablePromotionSuccess" />, <FormattedMessage id="BookingPromotionList.Success" />);

                this.setState({ block: false }, this.toggleDisableModal.bind(this, null));
            }
        }, `/api/Price/Promotion/v1/${this.state.selectedPromotion.id}`);
    }
    
    render() {
        const { channelInstances, selectedChannel, invalidPromotionType, promotionType, selectedPromotion, editMode } = this.state;

        const activePromotions = this.state.activePromotions.filter(p => p.channelInstanceId === selectedChannel);
        const deactivePromotions = this.state.deactivePromotions.filter(p => p.channelInstanceId === selectedChannel);

        return (
            <Card className="border-0">
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Modal isOpen={this.state.promotionModal} toggle={this.togglePromotionModal.bind(this, null)} fade={false} className="modal-lg " >
                        <ModalHeader toggle={this.togglePromotionModal.bind(this, null)}>
                            <FormattedMessage id="BookingReviewDetail.CreateNew" />
                        </ModalHeader>
                        <ModalBody className="modal-lg px-2 pb-2">
                            <BookingPromotionCard editMode={editMode} selectedPromotion={selectedPromotion} promotionType={this.state.promotionType} channelInstances={this.state.channelInstances} activePromotions={activePromotions} />
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={this.state.syncModal} toggle={this.toggleSyncModal} fade={false} className="modal-lg " >
                        <ModalHeader toggle={this.toggleSyncModal} >
                            <FormattedMessage id="BookingReviewDetail.Sync" />
                        </ModalHeader>
                        <ModalBody className="modal-lg px-2 pb-2">
                            <PromotionSync channelInstances={this.state.channelInstances} />
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </Modal>

                    <Modal isOpen={this.state.disableModal} toggle={this.toggleDisableModal.bind(this, null)} fade={false} className="modal-lg " >
                        <ErrorAlert error={this.state.errorDisable} />
                        <BlockUi tag="div" blocking={this.state.block}>
                            <ModalBody className="modal-lg px-2 pb-2 text-center">
                                {!global.isMobile ?
                                    <h4>
                                        <FormattedMessage id="PromotionList.ConfirmDisableFor" />
                                        <strong>{this.state.selectedPromotion ? (this.state.selectedPromotion.name == '-1' ? this.state.selectedPromotion.type : this.state.selectedPromotion.name) : ''}</strong>?
                                    </h4>
                                :
                                    <h5>
                                        <FormattedMessage id="PromotionList.ConfirmDisableFor" />
                                        <strong>{this.state.selectedPromotion ? (this.state.selectedPromotion.name == '-1' ? this.state.selectedPromotion.type : this.state.selectedPromotion.name) : ''}</strong>?
                                    </h5>
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary btn-host" onClick={this.disablePromotion}> <FormattedMessage id="PromotionList.Ok" /></Button>{' '}
                                <Button color="secondary" onClick={this.toggleDisableModal.bind(this, null)}> <FormattedMessage id="PromotionList.Close" /></Button>
                            </ModalFooter>
                        </BlockUi>
                    </Modal>
                    <Row className={`mb-2 d-flex align-items-center ${global.isMobile ? 'w-100' : ''}`}>
                        <Col className="col-lg-3">
                            <CustomSelect icon={'fa fa-plug fa-fw'} options={channelInstances} placeholder={<FormattedMessage id="PromotionDashboard.Channel" />} value={channelInstances.find(c => c.value === selectedChannel)} onChange={this.setComboStatus.bind(this, 'selectedChannel')} isClearable isSearchable />
                        </Col>
                        <Col className={`text-right ${global.isMobile ? 'col-4 px-0' : ''} `}>
                            <CommonHelper help={<FormattedMessage id="PromotionDashboard.Help" />} id={'BookingPromotionsHelp'} />
                            <Button className="btn-host btn-sm mr-1 float-right" id="syncpromotiontooptip" disabled={!selectedChannel} onClick={this.toggleSyncModal}>
                                <i className="fas fa-sync"/>
                            </Button>
                            <CustomToolTip placement="bottom" target="syncpromotiontooptip">
                                <FormattedMessage id="PromotionDashBoard.SyncPromotion" />
                            </CustomToolTip>
                            <Button className="btn-host btn-sm mr-1 float-right" id="addpromotiontooptip" disabled={!CheckAuthorization("promotions:add", null) || !selectedChannel} onClick={_ => this.setState({ editMode: true }, this.togglePromotionModal.bind(this, null))} >
                                <i className="fas fa-plus-square" />    
                            </Button>
                            <CustomToolTip placement="bottom" target="addpromotiontooptip">
                                <FormattedMessage id="PromotionDashBoard.CreateNewPromotion" />
                            </CustomToolTip>
                        </Col>
                    </Row>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>                            
                                <FormattedMessage id="PromotionDashboard.Active" /> 
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>                            
                                <FormattedMessage id="PromotionDashboard.Inactive" /> 
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            {
                                activePromotions.length > 0 ?
                                    activePromotions
                                        .map((promotion, key) =>
                                            <PromotionSummaryCard key={key} idx={key} channelInstances={channelInstances} item={promotion} promotionType={promotionType} togglePromotionModal={this.togglePromotionModal} invalidPromotionType={invalidPromotionType} subtitle={this.isEditDisabled(promotion) ? < FormattedMessage id="PromotionList.ManagedPromotionViaBooking" /> : ''} statusBadge={<Badge color="primary" pill> <FormattedMessage id="PromotionDashboard.Active" /> </Badge>}>
                                                <Button
                                                    className="btn-host shadow btn-sm mr-1" id={'prmotiondisabeltooptip' + promotion.channelInstanceId + key}
                                                    onClick={this.toggleDisableModal.bind(this, promotion)}
                                                    disabled={this.isEditDisabled(promotion)}>
                                                    <i className="fas fa-power-off" />
                                                </Button>
                                                <CustomToolTip placement="bottom" target={'prmotiondisabeltooptip' + promotion.channelInstanceId + key}>
                                                    <FormattedMessage id="PromotionList.DisablePromotion" />
                                                </CustomToolTip>
                                                <Button
                                                    className="btn-host shadow btn-sm" id={'prmotionedittooptip' + promotion.channelInstanceId + key} onClick={_ => this.setState({ editMode: false }, this.togglePromotionModal.bind(this, promotion))} disabled={this.isEditDisabled(promotion)}>
                                                    <i className="fas fa-edit" />
                                                </Button>
                                                <CustomToolTip placement="bottom" target={'prmotionedittooptip' + promotion.channelInstanceId + key}>
                                                    <FormattedMessage id="PromotionList.EditPromotion" />
                                                </CustomToolTip>
                                            </PromotionSummaryCard>
                                        )
                                    :
                                    <div><FormattedMessage id="PromotionDashBoard.NoPromotionsAvailable" /></div>
                            }                    
                        </TabPane>
                        <TabPane tabId="2"> 
                            {
                                deactivePromotions.length > 0 ?
                                    deactivePromotions
                                        .map((promotion, key) =>
                                            <PromotionSummaryCard key={key} idx={key} channelInstances={channelInstances} item={promotion} promotionType={promotionType} togglePromotionModal={this.togglePromotionModal} invalidPromotionType={invalidPromotionType} subtitle={this.isEditDisabled(promotion) ? < FormattedMessage id="PromotionList.ManagedPromotionViaBooking" /> : ''} statusBadge={<Badge color="primary" pill> <FormattedMessage id="PromotionDashboard.Inactive" /> </Badge>}/>
                                        )
                                    :
                                    <div><FormattedMessage id="PromotionDashBoard.NoPromotionsAvailable" /></div>
                            }
                        </TabPane>                  
                    </TabContent>               
                </BlockUi>
            </Card>
        );
    }
}

export default injectIntl(PromotionDashboard)