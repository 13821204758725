import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import classnames from 'classnames';
import { BookingOportunityCard } from './BookingOportunityCard';
import { AirbnbOportunityCard } from './AirbnbOportunityCard';
import { FormattedMessage } from 'react-intl';
import { CommonHelper } from '../../Base/Common/CommonUIComponents';

export class OportunityDashboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        
        this.state = {
            activeTab: '1',
            modal: false,
            Alerts: []
        };
        
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        window.setTimeout(
            () => {
                this.setState({
                    hasBooking: global.channelList.includes('BOOKING'),
                    hasAirbnb: global.channelList.includes('AIRBNB')
                });
            }, global.channelList && global.channelList.length > 0 ? 0 : 2500
        );
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() {
        const { hasBooking, hasAirbnb } = this.state;

        return (
            <Card className="border-0">
                <Nav tabs>
                    {hasBooking ?
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                <FormattedMessage id="OportunityDasboard.BookingOportunities" />
                            </NavLink>
                        </NavItem>
                    : ''}

                    {hasAirbnb ?
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                <FormattedMessage id="OportunityDasboard.AirBnBOportunities" />
                            </NavLink>
                        </NavItem>
                    : ''}

                    <NavItem className="ml-auto border-bottom mt-2 mr-2">
                        <CommonHelper placement={global.isMobile ? 'top' : 'left'} help={<FormattedMessage id="OportunityDasboard.Help" />} id={'opportunitiesdashboardhelp'} /> 
                    </NavItem>   
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    {hasBooking ?
                        <TabPane tabId="1">
                            <BookingOportunityCard />
                        </TabPane>
                        : ''}

                    {hasAirbnb ?
                        <TabPane tabId="2">
                            <AirbnbOportunityCard />
                        </TabPane>
                    : ''}
                </TabContent>
            </Card>
        );
    }
}