import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { BlankCard } from '../../../Base/Common/CommonUIComponents';
import { handleNotification } from '../../../Base/Notification';
import { putAPI } from '../../../Base/API';

export class StepRatesToNone extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false
        }
    }

    resetBlockIds = () => {
        const { selectedHotel } = this.props;

        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification({}, <FormattedMessage id="ChangeOperationMode.BlocksWereRemoved" />, <FormattedMessage id="General.Success" />);
                    handleNotification({ warnings: [{ code: 'Change Operation Mode', message: 'Please change operation mode on license page' }] });
                }
            }

            this.setState({ error: errorMessage, block: false });
        }, `/api/hotel/v1/ratecodes/${selectedHotel.value}/blockids`);
    }

    render() {
        const { block, error } = this.state;

        return (
            <BlankCard block={block} error={error}>
                <Row className="mb-2">
                    <Col>
                        <h6>
                            <FormattedMessage id="ChangeOperationMode.ToModeNodeResetBlockId" />
                        </h6>
                    </Col>
                    <Col className="col-2 text-right">
                        <Button className="btn btn-host btn-sm mr-2" onClick={this.resetBlockIds}>
                            <FormattedMessage id="ChangeOperationMode.ResetBlockId" />
                        </Button>
                    </Col>
                </Row>
            </BlankCard>
        );
    }
}