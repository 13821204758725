import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {  Card, CardBody, UncontrolledCollapse } from 'reactstrap';

export class ErrorAlert extends Component {
   
    render() {
        const { isMulti, error } = this.props;

        return (
            isMulti ?
                error?
                    <div className={'alert alert-' + error[0]?.messageType}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <b><FormattedMessage id="generic.MultipleErrors"/></b>
                            <div id={'togglerError'} className="h-100 cursor-pointer">
                                <i class="fas fa-chevron-down"/>
                            </div>
                        </div>
                        <UncontrolledCollapse toggler={'#togglerError'}>
                            {error.map((item, key) => 
                                <div key={key} style={{ display: item ? 'block' : 'none', marginTop: key === 0 ? '0.5rem' : '' }}>
                                    <span>
                                        <i className={this.props.icon} />
                                    </span>
                                    {item ? item.message : ''}
                                </div>
                            )}
                        </UncontrolledCollapse>
                    </div>
                :''
            :
            <div>
                {error && error.map((item, key) =>
                    item.stack ? 
                        <div className={'alert alert-' + item.messageType} style={{ display: item ? 'block' : 'none' }} key={key} >
                            <span className='glyphicon glyphicon-triangle-bottom ml-2' id={'togglerError' + key} /> {item ? item.message : ''}
                            <UncontrolledCollapse toggler={'#togglerError' + key}> 
                                <Card>
                                    <CardBody>
                                        {item ? item.stack : ''}
                                    </CardBody>
                                </Card>
                            </UncontrolledCollapse>
                        </div>
                    :
                        <div key={key} className={'alert alert-' + item.messageType} style={{ display: item ? 'block' : 'none' }}>
                            <span>
                                <i className={this.props.icon} />
                            </span>
                            {item ? item.message : ''}
                        </div>
                )}
            </div>
        )
    }
}