import React, { Component } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../Base/Common/CustomSelect';

export class HrsMapAllFields extends Component {

    render() {
        const { children, extraMapping, handleCombo, handleText } = this.props;

        const reservationModes = [
            {
                value: 'standard',
                label: <FormattedMessage id="ChannelMapping.Standard" />
            },
            {
                value: 'guaranteed',
                label: <FormattedMessage id="ChannelMapping.Guaranteed" />
            },
            {
                value: 'guaranteedCreditCard',
                label: <FormattedMessage id="ChannelMapping.GuaranteedCreditCard" />
            }
        ]

        const cancelableOptions = [
            {
                value: "true",
                label: <FormattedMessage id="generic.yes" />
            },
            {
                value: "false",
                label: <FormattedMessage id="generic.no" />
            }
        ];

        return (
            <div>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="map2" sm={3}>
                                <FormattedMessage id="ChannelMapping.ReservationMode" />
                            </Label>
                            <Col sm={9}>
                                <CustomSelect options={reservationModes} value={reservationModes.find(rm => rm.value === extraMapping.map2)} onChange={handleCombo.bind(this, 'map2')} required />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="map4" sm={3}>
                                <FormattedMessage id="ChannelMapping.PrepayFeePercent" />
                            </Label>
                            <Col sm={9}>
                                <Input type="number" min="0" name="map4" id="map4" value={extraMapping.map4} onChange={handleText} required />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="map5" sm={3}>
                                <FormattedMessage id="ChannelMapping.Cancelable" />
                            </Label>
                            <Col sm={9}>
                                <CustomSelect options={cancelableOptions} value={cancelableOptions.find(cnOpt => cnOpt.value === extraMapping.map5)} onChange={handleCombo.bind(this, 'map5')} required />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="map6" sm={3}>
                                <FormattedMessage id="ChannelMapping.CancellationDeadlineDays" />
                            </Label>
                            <Col sm={9}>
                                {children}
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup row>
                            <Label for="map7" sm={3}>
                                <FormattedMessage id="ChannelMapping.CancellationFeePercent" />
                            </Label>
                            <Col sm={9}>
                                <Input type="number" min="0" name="map7" id="map7" value={extraMapping.map7} onChange={handleText} required />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}
