import React, { Component } from 'react';
import { Col, DropdownItem, DropdownMenu, Row, UncontrolledButtonDropdown, DropdownToggle, UncontrolledCollapse, Card, Button } from 'reactstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { PaymentStatusBadge } from '../Base/Common/CommonUIComponents';
import { getPaymentGatewayType, getPaymentModes, getPaymentStatusOptions } from '../Base/Common/ReferenceDataFunctions';
import { PaymentRefundModal } from '../Payment/PaymentRefundModal';
import { PaymentCancelModal } from '../Payment/PaymentCancelModal';
import { PaymentRefunds } from '../Payment/PaymentRefunds';
import { getGatewayErrors } from '../Admin/Payments/GatewayErrors';
import { handleNotification } from '../Base/Notification';
import { postAPI } from '../Base/API';
import { ErrorAlert } from '../Common/ErrorAlert';
import { CreatePaymentModal } from '../Payment/CreatePaymentModal';

export class ReservationPayment extends Component {

    constructor(props) {
        super(props);
        this.togglePaymentRefundModal = this.togglePaymentRefundModal.bind(this);
        this.togglePaymentCancelModal = this.togglePaymentCancelModal.bind(this);
        this.toggleCreatePaymentModal = this.toggleCreatePaymentModal.bind(this);
        const isRedsys = this.props.payment.paymentGateway === getPaymentGatewayType()[6].value;
        const isSibs = this.props.payment.paymentGateway === getPaymentGatewayType()[7].value;
        const isCreditCard = this.props.payment.mode === getPaymentModes()[0].value;
        const isSuccess = this.props.payment.paymentStatus === getPaymentStatusOptions()[3].value;

        this.state = {
            block: false,
            paymentCancelModal: false,
            paymentRefundModal: false,
            createPaymentModal: false,
            isBankTransferOrCustom: this.props.payment.paymentGateway === getPaymentGatewayType()[1].value || this.props.payment.paymentGateway === getPaymentGatewayType()[4].value,
            isHeyCard: this.props.payment.paymentGateway === getPaymentGatewayType()[8].value,
            isStripe: this.props.payment.paymentGateway === getPaymentGatewayType()[0].value,
            isIfThenPay: this.props.payment.paymentGateway === getPaymentGatewayType()[5].value,
            isRedsys: isRedsys,
            isCreditCard: isCreditCard,
            isMultibanco: this.props.payment.mode === getPaymentModes()[3].value,
            logo: getPaymentGatewayType().filter(p => p.value === this.props.payment.paymentGateway).map((p, key) => p.showLogoHasText ? <h6 key={key}>{p.logo}</h6> : <img key={key} height="40" src={p.logo} />),
            mode: getPaymentModes().filter(p => p.value === this.props.payment.mode).map(p => p.label),
            canPerformRecurringPayment: isCreditCard && isSuccess && (isRedsys || isSibs)
        };
    }

    togglePaymentRefundModal() {
        this.setState(prevState => ({ paymentRefundModal: !prevState.paymentRefundModal }));
    }

    togglePaymentCancelModal() {
        this.setState(prevState => ({ paymentCancelModal: !prevState.paymentCancelModal }));
    }

    toggleCreatePaymentModal() {
        this.setState(prevState => ({ createPaymentModal: !prevState.createPaymentModal }));
    }

    getErrorMessage = (payment) => {
        const paymentErrorCodes = [
            'CardDeclined',
            'InvalidCard',
            'InvalidCVV',
            'InvalidExpiryDate',
            'InvalidName',
            'InvalidPhoneNumber',
            'InsufficientFunds',
            'RejectedPayment',
            'UnknownReason'
        ];

        //paymentErrorCodes[8] = 'UnknownReason'
        if (payment.paymentErrorCode && payment.paymentErrorCode !== paymentErrorCodes[8]) {
            if (paymentErrorCodes.some(code => code.includes(payment.paymentErrorCode))) {
                return <FormattedMessage id={`Payments.${payment.paymentErrorCode}`} />;
            }
            else {
                return payment.paymentErrorCode;
            }
        }
        else {
            const gatewayError = getGatewayErrors()[payment.gatewayErrorCode];

            return gatewayError ? gatewayError : payment.gatewayErrorCode
        }
    }

    confirmPayment = () => {
        const { idx, payment, updatePayment } = this.props;

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    handleNotification(data, <FormattedMessage id="Payments.SuccessfulConfirm" />, <FormattedMessage id="General.Success" />);
                    updatePayment(data.response[0], idx);
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Price/Payment/v1/confirm/${payment.id}`);
    }

    goToVoucher = () => {
        const url = process.env.REACT_APP_HEYCARD_URL + '/VoucherOperationDetails/' + this.props.payment.paymentIntentId;

        window.open(url, '_blank');
    }

    render() {
        const { checkPayment, currencyCode, payment, updatePayment, updateRefunds, addPayment, idx, toggleIsOpen } = this.props;
        const { block, error, paymentCancelModal, paymentRefundModal, createPaymentModal, isBankTransferOrCustom, isHeyCard, isStripe, isIfThenPay,
            isRedsys, isCreditCard, isMultibanco, logo, mode, canPerformRecurringPayment } = this.state;

        const paymentStatusOptions = getPaymentStatusOptions();

        const refundsTotalAmount = payment.reservationPaymentRefund.reduce((acc, curr) => acc + curr.amount, 0);

        return (
            <BlockUi className='mt-2' tag="div" blocking={block}>
                <ErrorAlert error={error} />
                {paymentRefundModal ?
                    <PaymentRefundModal isOpen={paymentRefundModal} toggle={this.togglePaymentRefundModal} payment={payment} updateRefunds={r => updateRefunds(r, idx)} />
                :''}
                {paymentCancelModal ?
                    <PaymentCancelModal isOpen={paymentCancelModal} toggle={this.togglePaymentCancelModal} payment={payment} updatePayment={p => updatePayment(p, idx)} />
                :''}
                {createPaymentModal ?
                    <CreatePaymentModal isOpen={createPaymentModal} toggle={this.toggleCreatePaymentModal} payment={payment} addPayment={p => addPayment(p)} />
                :''}
                <Card className="border-0 p-3 shadow" style={{ fontSize: '1em' }}>
                    <Row>
                        <Col className="col-lg-2 col-4 d-flex align-items-center">
                            {logo}
                        </Col>
                        {!global.isMobile ?
                            <Col className="col-3">
                                <div className='text-muted mb-1'>
                                    <FormattedMessage id="ReservationDetail.Mode"/>
                                </div>
                                <div>
                                    {mode}
                                </div>
                            </Col>
                        :''}
                        <Col>
                            <div className='text-muted mb-1'>
                                <FormattedMessage id="Payments.Status" />
                            </div>
                            <div>
                                <PaymentStatusBadge status={payment.paymentStatus}/>
                            </div>
                        </Col>
                        <Col>
                            <div className='text-muted mb-1'>
                                <FormattedMessage id="Payments.Amount" />
                            </div>
                            <div>
                                <FormattedNumber value={payment.amount} currency={currencyCode ? currencyCode : global.hotelCurrency} maximumFractionDigits={2} style="currency" />
                            </div>
                        </Col>
                        {!global.isMobile ?
                            <Col className="d-flex align-items-center justify-content-center">
                                {!isHeyCard ?
                                    <UncontrolledButtonDropdown>
                                        <DropdownToggle className="btn-host ml-1" caret size="sm">
                                            <FormattedMessage id="Payments.Actions" />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            {isBankTransferOrCustom ?
                                                <DropdownItem onClick={this.confirmPayment} disabled={payment.paymentStatus !== paymentStatusOptions[0].value}>
                                                    <FormattedMessage id="Payments.ConfirmPayment" />
                                                </DropdownItem>
                                            :
                                                <div>
                                                    {canPerformRecurringPayment ?
                                                        <DropdownItem onClick={this.toggleCreatePaymentModal} disabled={payment.merchantIdentifier === null && payment.merchantCofTxnid === null && payment.last4 === null}>
                                                            <FormattedMessage id="Payments.CreatePayment" />
                                                        </DropdownItem>
                                                    :''}
                                                    {isStripe ?
                                                        <DropdownItem onClick={this.togglePaymentCancelModal} disabled={payment.paymentStatus !== paymentStatusOptions[1].value}>
                                                            <FormattedMessage id="Payments.Cancel" />
                                                        </DropdownItem>
                                                    :''}
                                                    <DropdownItem onClick={this.togglePaymentRefundModal} disabled={payment.paymentStatus !== paymentStatusOptions[3].value || payment.amount === refundsTotalAmount || (isIfThenPay && isMultibanco)}>
                                                        <FormattedMessage id="Payments.Refund" />
                                                    </DropdownItem>
                                                    <DropdownItem onClick={_ => checkPayment(idx)} disabled={isRedsys || isIfThenPay && (isCreditCard || isMultibanco) || paymentStatusOptions.some(opt => opt.isFinalSate && opt.value === payment.paymentStatus)}>
                                                        <FormattedMessage id="Payments.CheckPayment" />
                                                    </DropdownItem>
                                                </div>
                                            }
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                :
                                    <Button className="btn-sm btn-host" onClick={this.goToVoucher}>
                                        <FormattedMessage id="Payments.Voucher"/>
                                    </Button>
                                }
                            </Col>
                        :''}
                        <Col className="col-1 pl-0 d-flex align-items-center justify-content-end">
                            <span id={`togglerPayment-${payment.id}`} className='cursor-pointer'>
                                <i className="fas fa-chevron-down"/>
                            </span>
                        </Col>
                    </Row>
                    <UncontrolledCollapse toggler={`togglerPayment-${payment.id}`}>
                        <hr/>
                        <Row className="mb-2">
                            {global.isMobile ?
                                <Col className="col-6 mt-lg-0 mt-2">
                                    <div className='text-muted mb-1'>
                                        <FormattedMessage id="ReservationDetail.Mode"/>
                                    </div>
                                    <div>
                                        {mode}
                                    </div>
                                </Col>
                            :''}
                            <Col className="col-lg-2 col-6 mt-lg-0 mt-2">
                                <div className='text-muted mb-1'>
                                    {isHeyCard ?
                                        <FormattedMessage id="Payments.VoucherId"/>
                                    :
                                        <FormattedMessage id="Payments.IntentId"/>
                                    }
                                </div>
                                <div>
                                    {payment.paymentIntentId??'-'}
                                </div>
                            </Col>
                            <Col className="col-lg-2 col-6 mt-lg-0 mt-2">
                                <div className='text-muted mb-1'>
                                    <FormattedMessage id="Payments.Date"/>
                                </div>
                                <div>
                                    {payment.createdAt ? moment(payment.createdAt).format("YYYY-MM-DD HH:mm:ss") : '-'}
                                </div>
                            </Col>
                            {global.isMobile ?
                                <Col className="d-flex col-6 align-items-center justify-content-end">
                                    <UncontrolledButtonDropdown className="mr-3">
                                        <DropdownToggle className="btn-host ml-1" caret size="sm">
                                            <FormattedMessage id="Payments.Actions" />
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            {isBankTransferOrCustom ?
                                                <DropdownItem onClick={this.confirmPayment} disabled={payment.paymentStatus !== paymentStatusOptions[0].value}>
                                                    <FormattedMessage id="Payments.ConfirmPayment" />
                                                </DropdownItem>
                                            :
                                                <div>
                                                    {canPerformRecurringPayment ?
                                                        <DropdownItem onClick={this.toggleCreatePaymentModal} disabled={payment.merchantIdentifier === null && payment.merchantCofTxnid === null && payment.last4 === null}>
                                                            <FormattedMessage id="Payments.CreatePayment" />
                                                        </DropdownItem>
                                                    :''}
                                                    {isStripe ?
                                                        <DropdownItem onClick={this.togglePaymentCancelModal} disabled={payment.paymentStatus !== paymentStatusOptions[1].value}>
                                                            <FormattedMessage id="Payments.Cancel" />
                                                        </DropdownItem>
                                                    :''}
                                                    <DropdownItem onClick={this.togglePaymentRefundModal} disabled={payment.paymentStatus !== paymentStatusOptions[3].value || payment.amount === refundsTotalAmount || (isIfThenPay && isMultibanco)}>
                                                        <FormattedMessage id="Payments.Refund" />
                                                    </DropdownItem>
                                                    <DropdownItem onClick={_ => checkPayment(idx)} disabled={isRedsys || isIfThenPay && (isCreditCard || isMultibanco) || paymentStatusOptions.some(opt => opt.isFinalSate && opt.value === payment.paymentStatus)}>
                                                        <FormattedMessage id="Payments.CheckPayment" />
                                                    </DropdownItem>
                                                </div>
                                            }
                                        </DropdownMenu>
                                    </UncontrolledButtonDropdown>
                                </Col>
                            :''}
                            {payment.paymentStatus === "Rejected" || payment.paymentStatus === "requires_payment_method" ?
                                <Col className="col-lg-2 col-6 mt-lg-0 mt-2">
                                    <div className='text-muted mb-1'>
                                        <FormattedMessage id="Payments.FailedReason"/>
                                    </div>
                                    <div>
                                        {this.getErrorMessage(payment)}
                                    </div>
                                </Col>
                            :''}
                        </Row>
                        {isMultibanco ?
                            <div>
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <b>
                                            <FormattedMessage id="Payments.Entity" />
                                        </b>
                                    </Col>
                                    <Col className="col-4">
                                        <span>
                                            {payment.mbEntity}
                                        </span>
                                    </Col>
                                    <Col className="col-2">
                                        <b>
                                            <FormattedMessage id="Payments.Reference" />
                                        </b>
                                    </Col>
                                    <Col className="col-4">
                                        <span>
                                            {payment.mbReference}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <b>
                                            <FormattedMessage id="Payments.MbStartDate" />
                                        </b>
                                    </Col>
                                    <Col className="col-4">
                                        <span>
                                            {moment(payment.mbStartDate).format("YYYY-MM-DD HH:mm:ss")}
                                        </span>
                                    </Col>
                                    <Col className="col-2">
                                        <b>
                                            <FormattedMessage id="Payments.MbEndDate" />
                                        </b>
                                    </Col>
                                    <Col className="col-4">
                                        <span>
                                            {moment(payment.mbEndDate).format("YYYY-MM-DD HH:mm:ss")}
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        :''}
                        {!isBankTransferOrCustom && payment.reservationPaymentRefund?.length > 0 ?
                            <div>
                                <div>
                                    <b><FormattedMessage id="Payments.Refunds"/></b>
                                </div>
                                <div className='mt-2 small'>
                                    <PaymentRefunds reservationPaymentRefund={payment.reservationPaymentRefund} />
                                </div>
                            </div>
                        :''}
                    </UncontrolledCollapse>
                </Card>
            </BlockUi>
        );
    }
}