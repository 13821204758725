import React, { Component } from 'react';
import { HandleUIError } from "./Base/Common/EventGA";
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { createBrowserHistory } from "history";

export default class ErrorBoundary extends  Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        HandleUIError(error, errorInfo?.componentStack);
    }
  
    render() {

        const pathnames = [
            { path: '/Inventory', category: 'InventoryRates' },
            { path: '/PromotionDashboard', category: 'InventoryRates' },
            { path: '/ChannelDashboard', category: 'Channels' },
            { path: '/LogsDashboard', category: 'Channels' },
            { path: '/ReservationDashboard', category: 'Reservations' },
            { path: '/ReservationList', category: 'Reservations' },
            { path: '/MailDashboard', category: 'Extras' },
            { path: '/OnlineRatesDashboard', category: 'Extras' },
            { path: '/OportunityDashboard', category: 'Extras' },
            { path: '/PropertyScore', category: 'Extras' },
            { path: '/ManageUser', category: 'UserManagment' },
            { path: '/RoomRateConfig', category: 'InventoryRates' },
            { path: '/SAPOReservations', category: 'Reservations' },
            { path: '/PropertySetup', category: 'InventoryRates' },
            { path: '/ComplexList', category: 'InventoryRates' },
            { path: '/RoomCategorySetup', category: 'InventoryRates' },
            { path: '/PackageList', category: 'InventoryRates' },
            { path: '/CancellationPolicy', category: 'InventoryRates' }
        ];

        const history = createBrowserHistory()
        const category2 = pathnames.find(el => el.path === history.location.pathname);
        const supportLink = process.env.REACT_APP_SUPPORT_URL + `?brand=HeyTravel&v1=HeyTravel&v2=${category2 ? category2.category : ''}&hotel=${global.defaultProperty}`;

        if (this.state.hasError) {
            return(
                <div className='h-100 overflow-hidden'>
                    <div className='d-flex align-items-center justify-content-center flex-column h-100'>
                        <div className='my-4'>
                            <i style={{ fontSize: '15em', color: '#0667ff' }} className="far fa-dizzy"/>
                        </div>
                        <h1>
                            OOOPS...
                        </h1>
                        <h6>
                            <FormattedMessage id="navbar.SomethingWentWrong"/>
                        </h6>
                        <div className='w-100 d-flex align-items-center justify-content-center mt-2'>
                            <div className='mr-2'>
                                <Link to={supportLink} target="_blank">
                                    <Button className="btn-sm btn-host p-2 px-3" style={{ backgroundColor: '#165c7d17', color: '#165C7D' }}>
                                        <i className="mr-1 fas fa-headset"></i>
                                        <FormattedMessage id="navbar.ContactSupport"/>
                                    </Button>
                                </Link>
                            </div>
                            <div className='ml-2'>
                                <Button className="btn-sm btn-host p-2 px-3" onClick={_ => {history.push('/');window.location.reload();}}>
                                    <FormattedMessage id="navbar.dashboard"/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
  
        return this.props.children;
    }
}