import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Input, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export class ChangeSimplePassword extends Component {

    render() {
        const { isOpen, toggle, configCredentials, handleChangeOnConfigCredentials, saveConfigCredentials, form, passwordField } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="ChangePassword.Title" />
                </ModalHeader>
                <ModalBody>
                    <form ref={form}>
                        <Row className="mb-2">
                            <Col className="text-right">
                                <Button className="btn btn-host btn-sm" onClick={saveConfigCredentials}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <FormattedMessage id="ChangePassword.Password" />
                            </Col>
                            <Col className="col-10">
                                <Input type="text" id="passwordModal" name={`${passwordField}`} value={configCredentials[passwordField]} onChange={handleChangeOnConfigCredentials} required />
                            </Col>
                        </Row>
                    </form>
                </ModalBody>
            </Modal>
        );
    }
}