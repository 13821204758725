import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form } from 'reactstrap';
import { CustomTable } from '../../../Base/Common/CustomTable';
import { getAPI } from "../../../Base/API";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import RestrictionHistoryFilters from './RestrictionHistoryFilters';

class RestrictionHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            day: moment(),
            restrictionHistory: [],
            pageSize: 10,
            count: 0,
            pageIndex: 0,
            roomCategories: []
        }
    }

    componentDidMount() {
        if (!this.props.isAdminPage) {
            this.getChannelInstance('/api/Rate/ChannelConfig/v1/ChannelInstance');
            const rates = this.props.getRates();

            this.setState({ rateCodes: rates, rateCodeId: null, channelInstanceId: null });
        }
    }

    getRestrictionHistory = () => {
        this.setState({ block: true, restrictionHistory: [] });
        var params = '';
        if (this.state.hotelId) params += `&hotelId=${this.state.hotelId}`;

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ block: false, restrictionHistory: data.response, count: data.count, error: [] });
            }
            else this.setState({ block: false });
        }, `/api/User/v1/History/RestrictionHistory?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}&day=${moment(this.state.day).format("YYYY-MM-DD")}&rateCodeId=${this.state.rateCodeId}${this.state.channelInstanceId ? `&channelInstanceId=${this.state.channelInstanceId}` : ``}` + params);
    }

    getChannelInstance = (url) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data && data.length > 0) {
                let active = [], inactive = [];

                data.sort((a, b) => a.channelName > b.channelName ? 1 : -1).forEach((item) => {
                    if (item.active) {
                        active.push({ 'label': item.channelName + ' (' + item.propertyCode + ')', 'value': item.id })
                    }
                    else {
                        inactive.push({ 'label': item.channelName + ' (' + item.propertyCode + ')', 'value': item.id })
                    }
                })

                var list = [
                    {
                        label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="generic.active" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{active.length}</span> </div >,
                        options: active
                    },
                    {
                        label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="generic.inactive" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{inactive.length}</span> </div >,
                        options: inactive
                    },
                ];

                this.setState({ block: false, channelInstance: list });
            }
            else this.setState({ block: false });
        }, url);
    }

    handleChangeSelect = (name, combo) => {
        if (name === 'hotelId' && combo) {
            this.getChannelInstance(`/api/Rate/ChannelConfig/v1/ChannelInstance?hotelId=${combo.value}`);
            var rates = this.props.getRates(combo.value);

            this.setState({ rateCodes: rates, rateCodeId: null, channelInstanceId: null });
        }

        this.setState({
            [name]: combo ? combo.value : null
        });
    };


    handleDate = (date) => {
        this.setState({
            day: date
        })
    }


    render() {
        const { restrictionHistory, pageSize, rateCodes, channelInstance, block } = this.state;

        const restrictionColumns = [
            {
                dataField: 'stopSales',
                text: <FormattedMessage id="LogsAvailabilityPrice.StopSales" />,
                sort: true
            },
            {
                dataField: 'closeToArrival',
                text: "CTA",
                sort: true
            },
            {
                dataField: 'closeToDeparture',
                text: "CTD",
                sort: true
            },
            {
                dataField: 'minimumStayLen',
                text: <FormattedMessage id="Inventory.MinStay" />,
                sort: true
            },
            {
                dataField: 'maximumStayLen',
                text: <FormattedMessage id="Inventory.MaxStay" />,
                sort: true
            },
            {
                dataField: 'minAdvancedBookingOffset',
                text: <FormattedMessage id="Inventory.MinAdvancedBookingOffset" />,
                sort: true
            },
            {
                dataField: 'maxAdvancedBookingOffset',
                text: <FormattedMessage id="Inventory.MaxAdvancedBookingOffset" />,
                sort: true
            },
            {
                dataField: 'version',
                text: <FormattedMessage id="PMSHistory.Version" />,
                sort: true
            },
            {
                dataField: 'createdAt',
                text: <FormattedMessage id="PMSHistory.CreatedAt" />,
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            },
            {
                dataField: 'updatedAt',
                text: <FormattedMessage id="PMSHistory.UpdatedAt" />,
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            },
            {
                dataField: 'updatedBy',
                text: <FormattedMessage id="PMSHistory.UpdatedBy" />,
                sort: true
            },
            {
                dataField: 'pmsTimestamp',
                text: "Pms Timestamp",
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : '',
                hidden: this.props.isOpModeNone
            }
        ]

        return (
            <BlockUi tag="div" blocking={block}>
                <Form onSubmit={(e) => { e.preventDefault(); this.setState({ pageIndex: 0 }, () => this.getRestrictionHistory()); }}>
                    <RestrictionHistoryFilters
                        isAdminPage={this.props.isAdminPage}
                        hotelList={this.props.hotelList}
                        handleChangeSelect={this.handleChangeSelect}
                        rateCodes={rateCodes}
                        rateCodeId={this.state.rateCodeId}
                        channelInstance={channelInstance}
                        channelInstanceId={this.state.channelInstanceId}
                        day={this.state.day}
                        handleDate={this.handleDate}
                        hotelId={this.state.hotelId}
                    />
                </Form>

                <div className="my-3">
                    <CustomTable
                        keyField="version"
                        data={restrictionHistory}
                        columns={restrictionColumns}
                        shadow={false}
                        exportCSV={false}
                        showTotal={true}
                        remote={true}
                        search={false}
                        hideSizePerPage={true}
                        page={this.state.pageIndex + 1}
                        sizePerPage={pageSize}
                        totalSize={this.state.count}
                        onTableChange={(_, { page }) => { this.setState({ pageIndex: page - 1 }, () => this.getRestrictionHistory()) }}
                    />
                </div>
            </BlockUi>
        );
    }

};
export default injectIntl(RestrictionHistory);