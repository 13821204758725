import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Progress, CustomInput } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../../Base/Notification';
import CustomSelect from '../../Base/Common/CustomSelect';
import { ErrorAlert } from "../../Common/ErrorAlert";
import { getAPI } from "../../Base/API"
import BlockUi from 'react-block-ui';

class BookingPropScore extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            channelinstanceList: [],
            selectedConnection: '',
            propertyScore: [],
            activeTab: '1'
        }
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var id = '';
                var list = [];

                data && data.filter(c => c.active).forEach(item => {
                    list.push({ value: item.id, label: `${item.name} (${item.propertyCode})` });
                })

                if (list.length > 0) id = list[0].value;

                this.setState({ channelinstanceList: list, selectedConnection: id, block: false, error: errorMessage });
                this.getPropertyScore();
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/Booking.com');
    }

    getPropertyScore = () => {
        if (this.state.channelinstanceList.length > 0) {
            getAPI(result => {
                const { data, error } = result;
                this.setState({ block: true });
                var errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    for (var i = 0; i < data.errors.length; i++) {
                        errorMessage.push({ message: data.errors[i].message, messageType: 'danger' });
                    }

                    for (i = 0; i < data.warnings.length; i++) {
                        errorMessage.push({ message: data.warnings[i].message, messageType: 'warning' });
                    }
                    this.setState({ error: errorMessage, propertyScore: data.data, block: false });
                }
            }, `/api/Rate/PropertyScore/v1/booking?channelInstanceId=${this.state.selectedConnection}`);
        }
        else {
            var data = {
                warnings: [{
                    message: <FormattedMessage id="PropertyScore.ThereAreNoChannels" />
                }]
            }
            handleNotification(data, null, null);
        }
    }

    changeChannelConnection(combo) {
        this.setState({
            selectedConnection: combo ? combo.value : null,
            block: true
        }, () => this.getPropertyScore());
    }


    render() {
        var score = this.state.propertyScore && this.state.propertyScore.property_scores ? this.state.propertyScore.property_scores : null;
        var reviewScore = score ? score.review_score.summary : null;
        var replyScore = score ? score.reply_score.summary : null;
        var contentScore = score ? score.content_score.summary : null;

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <div className='d-flex'>
                    {!global.isMobile ?
                        <>
                            <Col className="col-9" />
                            <Col className="col-3 mt-2 pr-0">
                                <CustomSelect
                                    icon={'fa fa-plug fa-fw'}
                                    isSearchable
                                    placeholder={<FormattedMessage id="AirbnbReviewCard.SelectChannel" />}
                                    options={this.state.channelinstanceList}
                                    onChange={this.changeChannelConnection.bind(this)}
                                    value={this.state.selectedConnection ? this.state.channelinstanceList.find(el => el.value === this.state.selectedConnection) : ''}
                                />
                            </Col>
                        </>
                    :
                        <Col className="mt-2 p-0">
                            <CustomSelect
                                icon={'fa fa-plug fa-fw'}
                                isSearchable
                                placeholder={<FormattedMessage id="AirbnbReviewCard.SelectChannel" />}
                                options={this.state.channelinstanceList}
                                onChange={this.changeChannelConnection.bind(this)}
                                value={this.state.selectedConnection ? this.state.channelinstanceList.find(el => el.value === this.state.selectedConnection) : ''}
                            />
                        </Col>
                    }
                </div>
                <br />
                <div className={`mb-4 ${!global.isMobile ? 'd-flex' : ''}`}>
                    <Col className={global.isMobile ? 'col-12 mb-3 p-0' : 'pl-0'}>
                        <Card className="border-left-primary shadow h-100 py-2">
                            <CardBody>
                                <Row className="row no-gutters align-items-center">
                                    <Col className="mr-1">
                                        <p className="text-xs font-weight-bold text-secondary text-uppercase mb-2"><FormattedMessage id="PropertyScore.ReviewScore" /></p>

                                        {reviewScore ?
                                            <div>
                                                <Row>
                                                    <Col><h5 style={reviewScore.score > 7.5 ? { color: 'green' } : reviewScore.score < 5 ? { color: 'red' } : { color: 'orange' }}>
                                                        {reviewScore.score}
                                                    </h5></Col>
                                                </Row>

                                                <Progress
                                                    style={{ backgroundColor: '#e9ecef !important' }}
                                                    className="progress-bar-sm progress-bar-animated-alt progress"
                                                    color={reviewScore.score > 7.5 ? 'success' : reviewScore.score < 5 ? 'danger' : 'warning'}
                                                    value={reviewScore.score}
                                                    max={reviewScore.max_score}>
                                                    {reviewScore.score}
                                                </Progress>
                                            </div>
                                            : ''}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className={global.isMobile ? 'col-12 mb-3 p-0' : ''}>
                        <Card className="border-left-primary shadow h-100 py-2">
                            <CardBody>
                                <Row className="row no-gutters align-items-center">
                                    <Col className="mr-1">
                                        <p className="text-xs font-weight-bold text-secondary text-uppercase mb-2"><FormattedMessage id="PropertyScore.AverageScore" /></p>

                                        {contentScore ?
                                            <div>
                                                <Row>
                                                    <Col><h5 style={contentScore.score > 75 ? { color: 'green' } : contentScore.score < 50 ? { color: 'red' } : { color: 'orange' }}>
                                                        {contentScore.score} %
                                                    </h5></Col>
                                                    <Col className="text-right d-flex align-items-end justify-content-end">
                                                        <p className="mb-1" style={{ color: '#a0a0a0', fontSize: '14px' }}><FormattedMessage id="PropertyScore.Property" /></p>
                                                    </Col>
                                                </Row>
                                                <Progress
                                                    style={{ backgroundColor: '#e9ecef !important' }}
                                                    className="progress-bar-sm progress-bar-animated-alt progress mb-3"
                                                    color={contentScore.score > 75 ? 'success' : contentScore.score < 50 ? 'danger' : 'warning'}
                                                    value={contentScore.score}
                                                    max={contentScore.max_score}>
                                                    {contentScore.score}
                                                </Progress>

                                                <Row>
                                                    <Col><h5 style={contentScore.area_average_score > 75 ? { color: 'green' } : contentScore.area_average_score < 50 ? { color: 'red' } : { color: 'orange' }}>
                                                        {contentScore.area_average_score} %
                                                    </h5></Col>
                                                    <Col className="text-right d-flex align-items-end justify-content-end">
                                                        <p className="mb-1" style={{ color: '#a0a0a0', fontSize: '14px' }}><FormattedMessage id="PropertyScore.Area" /></p>
                                                    </Col>
                                                </Row>
                                                <Progress
                                                    style={{ backgroundColor: '#e9ecef !important' }}
                                                    className="progress-bar-sm progress-bar-animated-alt progress"
                                                    color={contentScore.area_average_score > 75 ? 'success' : contentScore.area_average_score < 50 ? 'danger' : 'warning'}
                                                    value={contentScore.area_average_score}
                                                    max={contentScore.max_score}>
                                                    {contentScore.area_average_score}
                                                </Progress>
                                            </div>
                                            : ''}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className={global.isMobile ? 'col-12 p-0' : 'pr-0'}>
                        <Card className="border-left-primary shadow h-100 py-2">
                            <CardBody>
                                <Row className="row no-gutters align-items-center">
                                    <Col className="mr-1">
                                        <p className="text-xs font-weight-bold text-secondary text-uppercase mb-2"><FormattedMessage id="PropertyScore.ReplyScore" /></p>

                                        {replyScore ?
                                            <div>
                                                <Row>
                                                    <Col><h5 style={replyScore.score > 75 ? { color: 'green' } : replyScore.score < 50 ? { color: 'red' } : { color: 'orange' }}>
                                                        {replyScore.score} %
                                                    </h5></Col>
                                                    <Col className="text-right d-flex align-items-end justify-content-end">
                                                        <p className="mb-1" style={{ color: '#a0a0a0', fontSize: '14px' }}><FormattedMessage id="PropertyScore.Property" /></p>
                                                    </Col>
                                                </Row>
                                                <Progress
                                                    style={{ backgroundColor: '#e9ecef !important' }}
                                                    className="progress-bar-sm progress-bar-animated-alt progress mb-3"
                                                    color={replyScore.score > 75 ? 'success' : replyScore.score < 50 ? 'danger' : 'warning'}
                                                    value={replyScore.score}
                                                    max={replyScore.max_score}>
                                                    {replyScore.score}
                                                </Progress>

                                                <Row>
                                                    <Col><h5 style={replyScore.area_average_score > 75 ? { color: 'green' } : replyScore.area_average_score < 50 ? { color: 'red' } : { color: 'orange' }}>
                                                        {replyScore.area_average_score} %
                                                    </h5></Col>
                                                    <Col className="text-right d-flex align-items-end justify-content-end">
                                                        <p className="mb-1" style={{ color: '#a0a0a0', fontSize: '14px' }}><FormattedMessage id="PropertyScore.Area" /></p>
                                                    </Col>
                                                </Row>
                                                <Progress
                                                    style={{ backgroundColor: '#e9ecef !important' }}
                                                    className="progress-bar-sm progress-bar-animated-alt progress"
                                                    color={replyScore.area_average_score > 75 ? 'success' : replyScore.area_average_score < 50 ? 'danger' : 'warning'}
                                                    value={replyScore.area_average_score}
                                                    max={replyScore.max_score}>
                                                    {replyScore.area_average_score}
                                                </Progress>
                                            </div>
                                            : ''}
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </div>
                                            
                <Card className="small mb-2 shadow border-top-0 border-right-0 border-left-0">
                    <CardHeader className="bg-white ">
                        <Row className="pxy-0">
                            <Col className="col-lg-2 ">
                                <i className="fas fa-list-ul" /> <strong> <FormattedMessage id="PropertyScore.Actions" /></strong>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div style={{ display: !global.isMobile ? 'grid' : '', gridTemplateColumns: !global.isMobile ? 'repeat(3, auto)' : '' }}>
                            {score && score.content_score.metrics.map((action, key) =>
                                <Row className="mb-2 mx-1" style={{ fontSize: '14px' }} key={key}>
                                    <Col className='px-2'>
                                        {
                                            <CustomInput
                                                type="checkbox"
                                                className="mb-1"
                                                checked={action.done}
                                                disabled
                                                id={action.metric_id}
                                                label={action.action}
                                            />
                                        }
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </BlockUi>
        );
    }
}

export default injectIntl(BookingPropScore)