import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Badge, Button } from 'reactstrap';
import CustomSelect from '../../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl, FormattedNumber, FormattedHTMLMessage } from 'react-intl';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import Authorization, { CheckAuthorization } from "../../Base/Authorization";
import moment from 'moment';
import { MobileFilters } from '../../Base/Common/MobileComponents';

class OffersMobile extends Component {

    renderMainFilter = () => {
        const { status } = this.props;

        return (
                <Col className='pr-0'>
                    <CustomSelect
                        isClearable isSearchable
                        placeholder={<FormattedMessage id="home.status" />}
                        options={this.props.statusOptions}
                        onChange={this.props.handleSelect.bind(this, 'status')}
                        value={this.props.statusOptions.find(opt => opt.value === status)}
                    />
                </Col>
        )
    }

    render() {
        const { items, pageIndex, pageSize, totalItems, intl } = this.props;

        return (
            <StyledCard block={this.props.block} error={this.props.error} icon={'fas fa-gift'} title={'navbar.Offers'} help={<FormattedHTMLMessage id="Offers.Help" />} id="Offers">
                <MobileFilters 
                    doSearch={this.props.doSearch} 
                    renderMainFilter={this.renderMainFilter}
                    addFunction={() => { this.props.fromBEConfigDashboard ? this.props.toggleDetails('showOfferDetails', 'offerId', null) : this.props.history.push('/OfferDetails') }}
                    authorizationAdd={true}
                    performAdd="offers:add"
                >
                    <Row className="mb-4">
                        <Col>
                            <CustomSelect
                                isClearable isSearchable
                                isMulti={true}
                                placeholder={<FormattedMessage id="Offers.applicableRates" />}
                                options={this.props.ratesOptions}
                                onChange={this.props.handleRates}
                            />
                        </Col>
                        
                    </Row>
                </MobileFilters>
                
                <Row className="mt-2 justify-content-center d-flex">
                    <Col>
                        <Row className='d-flex flex-wrap justify-content-center'>
                            {items && items.map((item, key) =>{

                                const descriptions = item.descriptions?.find(({culture}) => culture?.includes(intl.locale))
                                ? item.descriptions?.find(({culture}) => culture.includes(intl.locale))
                                : item.descriptions?.find(({culture}) => culture === "en-GB");
                                
                                return(
                                <Card className="shadow border-0 m-2" key={key}
                                    style={{
                                        minWidth: '40vw',
                                        height: "220px",
                                        backgroundImage: `url("${item.imageUrl}")`,
                                        color: 'white',
                                        backgroundSize: 'cover',
                                        transition: 'all .2s ease-out',
                                        borderRadius: '8px',
                                        cursor: 'pointer',
                                        overflow: 'auto'
                                    }}
                                    onClick={() => { if (CheckAuthorization("offers:edit", null)) this.props.fromBEConfigDashboard ? this.props.toggleDetails('showOfferDetails', 'offerId', item.id) : this.props.history.push('/OfferDetails/' + item.id) }}>
                                    <CardBody style={{ backgroundColor: '#0000007d', borderRadius: '8px' }}>
                                        <Row>
                                            <Col>
                                                {this.props.statusOptions.some(el => el.value === item.status) ?
                                                    <Badge color={this.props.statusOptions.find(el => el.value === item.status).color}> {this.props.statusOptions.find(el => el.value === item.status).label} </Badge>
                                                : item.status }
                                            </Col>
                                            {item.isMandatory ?
                                                <Col className="text-right">
                                                    <span title={intl.formatMessage({ id: "Offers.MadatoryLong" })} style={{ fontSize: '14px' }}>
                                                        <b>
                                                            <FormattedMessage id="Offers.Tax"/>
                                                        </b>   
                                                    </span>
                                                </Col>
                                            :''}
                                            {!item.rateCodes || item.rateCodes.length === 0 ?
                                                <Col className="text-right col-2 pl-0">
                                                    <span title={intl.formatMessage({ id: "Offers.NoApplicableRates" })}>
                                                        <i className="fas fa-exclamation-triangle text-warning" />
                                                    </span>
                                                </Col>
                                            :''}
                                        </Row>
                                        <Row className="mt-2">
                                            <Col>
                                                <div style={{ fontSize: '1.1em' }}>{descriptions?.title ? descriptions.title : item.serviceCode}</div>
                                                <div style={{ fontSize: '0.8em' }}>
                                                    {this.props.pricingType.some(el => el.value === item.servicePricingType) ? this.props.pricingType.find(el => el.value === item.servicePricingType).label : item.servicePricingType}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className='pr-0'>
                                                <div style={{ display: 'flex', alignItems: 'center', fontSize:"18px" }} className="mt-2">
                                                    <div>
                                                        <FormattedNumber value={item.feeAmount} style="currency" currency="EUR" minimumFractionDigits="2"/>
                                                    </div>
                                                </div>
                                                {item.feeChildAmount && item.feeChildAmount > 0 ?
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ fontSize: '0.8em',  verticalAlign: 'middle', height:'100%', display: 'flex', alignItems: 'center' }}>
                                                            <div className='mr-1'><FormattedMessage id="Offers.Children"/></div>
                                                            <FormattedNumber value={item.feeChildAmount} style="currency" currency="EUR" minimumFractionDigits="2"/>
                                                        </div>
                                                    </div>
                                                :''}
                                            </Col>
                                        </Row>
                                        {item.startDate && item.endDate?
                                        <Row className="mt-2">
                                            <Col>
                                                <span style={{ fontSize: '13.1px' }}>{moment(item.startDate).format('DD-MM-YYYY')}</span>
                                                <span className='mx-1' style={{ fontSize: '13px' }}><FormattedMessage id='AddRule.YieldRuleTo'/></span>
                                                <span style={{ fontSize: '13.1px' }}>{moment(item.endDate).format('DD-MM-YYYY')}</span>
                                            </Col>
                                        </Row>
                                        :''}
                                    </CardBody>
                                </Card>)}
                            )}
                        </Row>
                    </Col>
                </Row>
                {totalItems > pageSize ?
                    <Row className='my-3'>
                        <Col>
                            <Button
                            className={`btn btn-sm text-white ${pageIndex > 0 ? 'btn-host' : 'btn-secondary'}`}
                            disabled={pageIndex === 0}
                            onClick={() => pageIndex > 0 && this.props.handlePagination(-1)}
                            type="button">
                                <i className="fas fa-angle-left"/>
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button
                            className={`btn btn-sm text-white ${totalItems > ((pageIndex + 1) * pageSize) ? 'btn-host' : 'btn-secondary'}`}
                            disabled={totalItems <= ((pageIndex + 1) * pageSize)}
                            onClick={() => totalItems > ((pageIndex + 1) * pageSize) && this.props.handlePagination(1)}
                            type="button">
                                <i className="fas fa-angle-right"/>
                            </Button>
                        </Col>
                    </Row>
                :''}
            </StyledCard>
        )
    }
}

export default injectIntl(OffersMobile);
