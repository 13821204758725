import moment from 'moment';
import React, * as react from 'react';
import BlockUi from 'react-block-ui';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, CardBody, Col, CustomInput, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAPI, postAPI, putAPI } from "../../Base/API";
import CustomSelect from '../../Base/Common/CustomSelect';
import { getAirbnbReservationDeclineReason } from "../../Base/Common/ReferenceDataFunctions";
import { handleNotification } from "../../Base/Notification";
import { ErrorAlert } from "../../Common/ErrorAlert";



export class AirbnbSpecialOffer extends react.Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        
        this.RejectReservation = React.createRef();

        this.state = {
            error: null,
            block: true,
            actionBlock : false,
            listings: [],
            //Decline 
            decline_message_to_guest: null,
            decline_message_to_airbnb: null,
            declineReason: 'dates_not_available',

        }
    }

    componentDidUpdate(prevProps) {
        
        if (prevProps.selectedConnection !== this.props.selectedConnection) {
            getAPI(result => {
                const { data, error } = result;
                this.setState({ block: true });
                var errorMessage = []
                if (error) {

                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    var arrList = [];
                    data.response && data.response.listings && data.response.listings.map((item) => {
                        arrList.push({ 'label': item.name, 'value': item.id });
                        return true;
                    })
                    this.setState({ listings: arrList, block: false });

                }
            }, '/api/Rate/Listing/v1/airbnb/' + this.props.selectedConnection + '/listingAll');
        }
    }

    sendWithdrawSpecialOffer() {
        this.setState({ actionBlock: true });
        // withdraw
        putAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, actionBlock: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AirbnbSpecialOffer.SpecialOfferWithdraw" />, <FormattedMessage id="AirbnbSpecialOffer.Success" />);
                this.props.toggleModal();
                this.setState(prevState => ({
                    modal: !prevState.modal,
                    actionBlock: false
                }), () => this.getSpecialOffer);

            }
        }, '/api/Rate/MailBox/v1/airbnb/specialoffer/' + this.props.selectedConnection + '/withdraw/' + this.props.specialOffer.specialOfferId);
    }

    handleChange(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    sendPreApprove() {

        this.setState({ actionBlock: true });
        putAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, actionBlock: false });
                return;
            }
            if (data) {

                handleNotification(data, <FormattedMessage id="AirbnbSpecialOffer.SpecialOfferPreApproved" />, <FormattedMessage id="AirbnbSpecialOffer.Success" />);
                this.props.toggleModal();
                this.setState(prevState => ({
                    modal: !prevState.modal,
                    actionBlock: false
                }), () => this.getSpecialOffer);

            }
        }, '/api/Rate/MailBox/v1/airbnb/specialoffer/' + this.props.selectedConnection + '/preapprove/' + this.props.threadId);
    }

    sendSpecialOffer() {

        if (!this.form.current.reportValidity()) {
            return;
        }


        this.setState({ actionBlock: true });
        let req = {
            thread_id: this.props.threadId,
            listing_id: this.props.attachment.booking_details.listing_id,
            start_date: this.props.attachment.booking_details.checkin_date,
            nights: moment(this.props.attachment.booking_details.checkout_date).diff(moment(this.props.attachment.booking_details.checkin_date), 'days'),
            guest_details: {number_of_adults : this.props.attachment.booking_details.number_of_guests },
            total_price: this.props.attachment.booking_details.expected_payout_amount_accurate
        };

        postAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, actionBlock: false });
                return;
            }
            if (data) {

                handleNotification(data, <FormattedMessage id="AirbnbSpecialOffer.SpecialOfferSent" />, <FormattedMessage id="AirbnbSpecialOffer.Success" />);
                this.props.toggleModal();
                this.setState(prevState => ({
                    modal: !prevState.modal,
                    actionBlock: false
                }), () => this.getSpecialOffer);

            }
        }, '/api/Rate/MailBox/v1/airbnb/specialoffer/' + this.props.selectedConnection + '/send',req);
    }

    sendAcceptReservation() {

        this.setState({ actionBlock: true });
        putAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, actionBlock: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="AirbnbSpecialOffer.ReservationAccepted" />, <FormattedMessage id="AirbnbSpecialOffer.Success" />);
                this.props.toggleModal();
                this.setState({
                    actionBlock: false
                }, () => this.getSpecialOffer);

            }
        }, '/api/Rate/MailBox/v1/airbnb/Reservation/' + this.props.selectedConnection + '/accept/' + this.props.attachment.booking_details.reservation_confirmation_code);
    }

    sendRejectReservation() {

        if (!this.RejectReservation.current.reportValidity()) {
            return;
        }

        var res = {
            'decline_reason': this.state.declineReason,
            'decline_message_to_guest': this.state.decline_message_to_guest,
            'decline_message_to_airbnb': this.state.decline_message_to_airbnb
        }; 

        this.setState({ actionBlock: true });
        putAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, actionBlock: false });
                return;
            }
            if (data) {

                handleNotification(data, <FormattedMessage id="AirbnbSpecialOffer.ReservationRejected" />, <FormattedMessage id="AirbnbSpecialOffer.Success" />);
                this.props.toggleModal();
                this.setState({
                    actionBlock: false
                }, () => this.getSpecialOffer);

            }
        }, '/api/Rate/MailBox/v1/airbnb/Reservation/' + this.props.selectedConnection + '/reject/' + this.props.attachment.booking_details.reservation_confirmation_code, res);
    }

    setListing = (evt) => {

        if (evt == null) evt = [];
        //if (evt.filter(el => el.value == 1).length > 0 || evt.length == 0) {

        //}
    }

    
    render() {
        
        return (
            
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} fade={false}  >
                <ModalHeader toggle={this.props.toggleModal} > <FormattedMessage id="AirbnbSpecialOffer.SpecialOffer" /> </ModalHeader>
                <ErrorAlert error={this.state.error} />
                <ModalBody>

               
            <Row >
                    <Col>

                        {this.props.action === 'withdraw' ?
                            <CardBody className="border-0">

                                <p className="lead">
                                    <FormattedMessage id="AirbnbSpecialOffer.ConfirmsRemovalOfSpecialOffer" />
                                </p>

                            </CardBody>
                            : ''}
                        {this.props.action === 'preapproval' ?
                            <CardBody className="border-0">

                                <p className="lead">
                                    <strong> <FormattedMessage id="AirbnbSpecialOffer.PreApproveHost" /> </strong><br/>
                                    <FormattedMessage id="AirbnbSpecialOffer.GuestWillHave24Hours" />
                            </p>

                            </CardBody> : ''
                        }

                            {this.props.action === 'AddSpecialoffer' && this.props.attachment ?
                            <Card className="border-0">

                                <form ref={this.form}>
                                    <CardBody className="">

                                        <Row>

                                            <Col className="col-12">
                                                <FormGroup sm="true" row>
                                                        <Label sm={2} for="listing"><FormattedMessage id="AirbnbSpecialOffer.Listing" /></Label>
                                                        <Col sm={10}>
                                                            {this.state.listings ?  
                                                                <CustomSelect icon={'fas fa-bed'} isSearchable placeholder={<FormattedMessage id="AirbnbSpecialOffer.ChooseRoom"/>} options={this.state.listings} defaultValue={this.state.listings.find(el => el.value === this.props.attachment.booking_details.listing_id)} onChange={this.props.updateselectedThreadMessages.bind(this, 'listing_id')} />
                                                        : ''}
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col className="col-6">
                                                <FormGroup sm="true" row>
                                                    <Label sm={4} for="numGuests"><FormattedMessage id="AirbnbSpecialOffer.NumberOfGuests" /></Label>
                                                    <Col sm={8}>
                                                        <FormattedMessage id="AirbnbSpecialOffer.NumberOfGuests">
                                                            {placeholder => <Input type="number" bsSize="sm" required name="number_of_guests" id="numGuests" placeholder={placeholder} min="1" max="10" value={this.props.attachment.booking_details.number_of_guests} onChange={this.props.updateselectedThreadMessages.bind(this, 'guest')} />}
                                                        </FormattedMessage>
                                                    </Col>
                                                </FormGroup>


                                            </Col>
                                            <Col className="col-6" >
                                                <FormGroup sm="true" row>
                                                    <Label sm={4} for="Headline"><FormattedMessage id="AirbnbSpecialOffer.Dates" /></Label>
                                                    <Col sm={8}>
                                                        <DateRangePicker
                                                                startDate={this.props.attachment.booking_details.checkin_date ? moment(this.props.attachment.booking_details.checkin_date).locale(moment.locale()) : this.props.attachment.booking_details.checkin_date}
                                                            startDateId="startBookDate"
                                                                endDate={this.props.attachment.booking_details.checkout_date ? moment(this.props.attachment.booking_details.checkout_date).locale(moment.locale()) : this.props.attachment.booking_details.checkout_date}
                                                            endDateId="endBookDate"
                                                            isOutsideRange={day => day < moment() || day > moment().add(12, 'month')}
                                                                onDatesChange={this.props.updateselectedThreadMessages.bind(this, 'dates')}
                                                            focusedInput={this.state.focusedInput}
                                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                                                small={true}
                                                                
                                                            numberOfMonths={1}
                                                            showDefaultInputIcon={true}
                                                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>



                                        </Row>
                                        <Row>

                                            <Col className="col-12">
                                                <FormGroup sm="true" row >
                                                        <Label sm={2} for="value"><FormattedMessage id="AirbnbSpecialOffer.Value" /></Label>
                                                        <Col sm={10}>
                                                            <FormattedMessage id="AirbnbSpecialOffer.Value">
                                                                {placeholder => <Input type="number" bsSize="sm" required name="expected_payout_amount_accurate" id="expected_payout_amount_accurate" placeholder={placeholder} min="1" max="25000" value={this.props.attachment.booking_details.expected_payout_amount_accurate} onChange={this.props.updateselectedThreadMessages.bind(this, 'guest')} />}
                                                            </FormattedMessage>

                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </form>

                            </Card> : ''
                        }

                            {this.props.action === 'Refuse' ?
                                <CardBody>
                                    
                                </CardBody> : ''
                            }

                            {this.props.action === 'RejectReservation' ?
                                <CardBody className="border-0">
                                    <form ref={this.RejectReservation}>
                                        <FormGroup >
                                            <Label for="exampleCheckbox"> <FormattedMessage id="AirbnbSpecialOffer.RefusalReason" /> </Label>
                                        <div>
                                            {getAirbnbReservationDeclineReason().map((item, key) =>
                                                <CustomInput type="radio" required={true} value={item.value} name="declineReason" checked={item.value === this.state.declineReason} id={'RefuseReservationRadio' + key} label={this.props.intl.formatMessage({ id: "AirbnbSpecialOffer.RefusalReason."+item.value })} onChange={this.handleChange.bind(this)}> </CustomInput>
                                            )}
                                        </div>
                                    </FormGroup>
                                    <FormGroup>
                                            <Label for="exampleCheckbox"><FormattedMessage id="AirbnbSpecialOffer.GuestMessage" /></Label>
                                            <Input type="textarea" required minLength="30" maxLength="250" id="decline_message_to_guest" name="decline_message_to_guest" value={this.state.messageToGuest} onChange={this.handleChange.bind(this)} />
                                        </FormGroup>                                                                                                              
                                    <FormGroup>
                                            <Label for="exampleCheckbox"><FormattedMessage id="AirbnbSpecialOffer.AirbnbMessage" /></Label>
                                            <Input type="textarea" id="decline_message_to_airbnb" name="decline_message_to_airbnb" value={this.state.messageToAirbnb} onChange={this.handleChange.bind(this)}/>
                                        </FormGroup>
                                    </form>
                                </CardBody> : ''
                            }

                            {this.props.action === 'AcceptReservation' ?
                                <CardBody className="border-0">

                                    <p className="lead">
                                        <strong>  <FormattedMessage id="AirbnbSpecialOffer.ConfirmAcceptanceOfOrder" /> </strong><br />
                                        
                            </p>

                                </CardBody> : ''
                            }

                   
                           
                        

                </Col>
                    </Row>

                </ModalBody>
                <ModalFooter>
                    <BlockUi tag="div" blocking={this.state.actionBlock}>
                        {this.props.action === 'withdraw' ?
                            <Button className="primary shadow btn-host mr-1" type="submit" onClick={this.sendWithdrawSpecialOffer.bind(this)}>
                                <FormattedMessage id="BookingChatWindow.Send" />

                            </Button>
                            : ''}
                        {this.props.action === 'preapproval' ?
                            <Button className="primary shadow btn-host mr-1" type="submit" onClick={this.sendPreApprove.bind(this)}>
                                <FormattedMessage id="BookingChatWindow.Send" />

                            </Button>
                            : ''}

                        {this.props.action === 'AddSpecialoffer' ?
                            <Button className="primary shadow btn-host mr-1" type="submit" onClick={this.sendSpecialOffer.bind(this)}>
                                <FormattedMessage id="BookingChatWindow.Send" />

                            </Button>
                            : ''}

                        {this.props.action === 'AcceptReservation' ?
                            <Button className="primary shadow btn-host mr-1" type="submit" onClick={this.sendAcceptReservation.bind(this)}>
                                <FormattedMessage id="BookingChatWindow.Send" />

                            </Button>
                            : ''}

                        {this.props.action === 'RejectReservation' ?
                            <Button className="primary shadow btn-host mr-1" type="submit" onClick={this.sendRejectReservation.bind(this)}>
                                <FormattedMessage id="BookingChatWindow.Send" />

                            </Button>
                            : ''}


                        <Button color="secondary" onClick={this.props.toggleModal} > <FormattedMessage id="BookingChatWindow.Cancel" /></Button>
                    </BlockUi>
                </ModalFooter>
            </Modal>
        )
    }
}
export default injectIntl(AirbnbSpecialOffer)