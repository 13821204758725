import React from "react";
import { IntlProvider, addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import pt from "react-intl/locale-data/pt";
import es from "react-intl/locale-data/es";
import fr from "react-intl/locale-data/fr";
import de from "react-intl/locale-data/de";
import br from "react-intl/locale-data/br";
import moment from 'moment';
import messages_pt from "../components/Translations/pt.json";
import messages_en from "../components/Translations/en.json";
import messages_es from "../components/Translations/es.json";
import messages_de from "../components/Translations/de.json";
import messages_fr from "../components/Translations/fr.json";
import messages_br from "../components/Translations/br.json";

addLocaleData([...en, ...pt, ...es, ...fr, ...de, ...br]);

const messagesLang = {
    'pt': messages_pt,
    'en': messages_en,
    'de': messages_de,
    'fr': messages_fr,
    'es': messages_es,
    'br': messages_br,
};

const localStorageLang = localStorage.getItem("locale") === "pt-BR" ? "br" : localStorage.getItem("locale")?.split(/[-_]/)[0];
const NavigatorLang = navigator.language.split(/[-_]/)[0];
const language = localStorageLang !== null && Object.keys(messagesLang).includes(localStorageLang) ? localStorageLang : Object.keys(messagesLang).includes(NavigatorLang) ? NavigatorLang : 'pt';  // language without region code
const Context = React.createContext();

class IntlProviderWrapper extends React.Component {
    constructor(...args) {
        super(...args);
        
        this.switchToLangEN = () =>
            this.setState({
                locale: 'en', messages: messagesLang['en']
            }, () => {
                localStorage.setItem("locale", 'en-GB');
                moment.locale('en-GB');
            });

        this.switchToLangDE = () =>
            this.setState({
                locale: 'de', messages: messagesLang['de']
            }, () => {
                localStorage.setItem("locale", 'de-DE');
                moment.locale('de-DE');
            });

        this.switchToLangFR = () =>
            this.setState({
                locale: 'fr', messages: messagesLang['fr']
            }, () => {
                localStorage.setItem("locale", 'fr-FR');
                moment.locale('fr-FR');
            });

        this.switchToLangPT = () =>
            this.setState({
                locale: 'pt', messages: messagesLang['pt']
            }, () => {
                localStorage.setItem("locale", 'pt-PT');
                moment.locale('pt-PT');
            });

        this.switchToLangBR = () =>
            this.setState({
                locale: 'br', messages: messagesLang['br']
            }, () => {
                localStorage.setItem("locale", 'pt-BR');
                moment.locale('pt-BR');
            });

        this.switchToLangES = () =>
            this.setState({
                locale: 'es', messages: messagesLang['es']
            }, () => {
                localStorage.setItem("locale", 'es-ES');
                moment.locale('es-ES');
            });

        this.currentProperty = (prop) =>
            this.setState({
                currentProperty : prop
            });
        
        this.state = {
            locale: language,
            messages: messagesLang[language],
            switchToLangEN: this.switchToLangEN,
            switchToLangPT: this.switchToLangPT,
            switchToLangBR: this.switchToLangBR,
            switchToLangES: this.switchToLangES,
            switchToLangFR: this.switchToLangFR,
            switchToLangDE: this.switchToLangDE,
            currentProperty: this.currentProperty,
        };
    }

    render() {
        const { children } = this.props;
        const { locale, messages } = this.state;
        return (
            <Context.Provider value={this.state}>
                <IntlProvider
                    key={locale}
                    locale={locale}
                    messages={messages}
                    defaultLocale="en"
                >
                    {children}
                </IntlProvider>
            </Context.Provider>
        );
    }
}

export { IntlProviderWrapper, Context as IntlContext };
