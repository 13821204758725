import React, { Component, isValidElement } from 'react';
import { injectIntl } from 'react-intl';
import { Card, Col, Row, Button } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';
import { ChannelConfigEdit } from './ChannelConfigEdit';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';

 class ManageChannelConfig extends Component {

     constructor(props) {
         super(props);
         this.toggleModal = this.toggleModal.bind(this);
         this.addOrUpdateChannelConfig = this.addOrUpdateChannelConfig.bind(this);
         this.createChannelConfig = this.createChannelConfig.bind(this);
         this.state = {
             block: true,
             status: { value: false },
             channelConfgList: [],
             modal: false,
             modalData: {},
             isCreatable: null
         }
     }    

    componentDidMount() {
        this.getChannelConfigList();
    }

     getChannelConfigList() {
         const { isCreatable } = this.state;
         this.setState({ block: true });
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({
                    channelConfgList: data.map(conf => {
                        if (conf.supported === null) {
                            conf.supported = [];
                        }
                        return conf;
                    })
                });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Rate/ChannelConfig/v1?isCreatable=${isCreatable !== null ? isCreatable : ''}`);
    }

    setFilterState = (name, combo) => {
        this.setState({ [name]: combo });
     }        

    toggleModal() {
        this.setState(prevstate => ({
            modal: !prevstate.modal,
            modalData: null
        }));
    }

    addOrUpdateChannelConfig(newChannelConfig) {
        const idx = this.state.channelConfgList && this.state.channelConfgList.indexOf(this.state.channelConfgList.find(channelConfig => channelConfig.id == newChannelConfig.id));
        if (idx == -1) {
            //Add new
            this.setState(prevState => ({ channelConfgList: [...prevState.channelConfgList, newChannelConfig] }));
        }
        else {
            //Update
            const channelConfgList = [...this.state.channelConfgList];
            channelConfgList[idx] = newChannelConfig;
            this.setState(({ channelConfgList }));
        }
    }

    createChannelConfig() {
        this.setState(prevstate => ({
            modal: !prevstate.modal,
            modalData: {
                supported:[]
            },
            title: <FormattedMessage id="ChannelConfigDetail.TitleCreate" />
        }));
    }

     setComboStatus = (field, inputValue) => {
         this.setState({ [field]: inputValue && inputValue.value })
     }

     render() {
        const { isCreatable } = this.state;
        const helpContent =
            <div>
                TODO
                <hr />
            </div>

        const columns = [
            {
                dataField: 'code',
                text: this.props.intl.formatMessage({ id: "ManageChannelConfig.Code" }),
                sort: true,
                headerStyle: () => {
                    return { width: "10%" };
                }
            },
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "ManageChannelConfig.Name" }),
                sort: true,
                headerStyle: () => {
                    return { width: "13%" };
                }
            },
            {
                dataField: 'pmsId',
                text: 'PMS',
                sort: true,
                headerStyle: () => {
                    return { width: "13%" };
                }
            },
            {
                text: this.props.intl.formatMessage({ id: "ManageChannelConfig.ConfiguredEndpoints" }),
                sort: true,
                formatter: (_, row) => 
                    <div>
                        {row.urlavailability ? 
                        <Row>
                            <Col className="col-3"><b><FormattedMessage id="ManageChannelConfig.Availability" /></b></Col>
                            <Col className="col-9"> {row.urlavailability}</Col>
                            </Row>
                            : ''}
                        {row.urlprices ? 
                        <Row>
                            <Col className="col-3"><b><FormattedMessage id="ManageChannelConfig.Prices" /></b></Col>
                            <Col className="col-9"> {row.urlprices}</Col>
                            </Row>
                            : ''}
                        {row.urlreservations ? 
                        <Row>
                            <Col className="col-3"><b><FormattedMessage id="ManageChannelConfig.Reservations" /></b></Col>
                            <Col className="col-9"> {row.urlreservations}</Col>
                            </Row>
                            : ''}
                        {row.urlreservationsModif ? 
                        <Row>
                                <Col className="col-3"><b><FormattedMessage id="ManageChannelConfig.ReservationsModifification" /></b></Col>
                            <Col className="col-9"> {row.urlreservationsModif}</Col>
                            </Row>
                            : ''}
                        {row.extraUrl1 ? 
                        <Row>
                                <Col className="col-3"><b><FormattedMessage id="ManageChannelConfig.Extra1" /></b></Col>
                            <Col className="col-9"> {row.extraUrl1}</Col>
                            </Row>
                        : ''}
                        {row.extraUrl2 ? 
                            <Row>
                                <Col className="col-3"><b><FormattedMessage id="ManageChannelConfig.Extra2" /></b></Col>
                                <Col className="col-9"> {row.extraUrl2}</Col>
                            </Row>
                            : ''}
                        {row.extraUrl3 ?
                            <Row>
                                <Col className="col-3"><b><FormattedMessage id="ManageChannelConfig.Extra3" /></b></Col>
                                <Col className="col-9"> {row.extraUrl3}</Col>
                            </Row>
                        : ''}
                        
                    </div>
                
            },           
            {
                dataField: 'maxDays',
                text: this.props.intl.formatMessage({ id: "ManageChannelConfig.MaxDays" }),
                sort: true,
                headerStyle: () => {
                    return { width: "6%" };
                }
            },
            {
                dataField: 'limitMaxStays',
                text: this.props.intl.formatMessage({ id: "ManageChannelConfig.LimitMaxStays" }),
                sort: true,
                headerStyle: () => {
                    return { width: "11%" };
                }
            },
            {
                dataField: 'isCreatable',
                text: this.props.intl.formatMessage({ id: "ManageChannelConfig.IsCreatable" }),
                sort: true,
                headerStyle: () => {
                    return { width: "8%" };
                },
                formatter: cell => <FormattedMessage id={`ManageChannelConfig.${cell}`} />
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, modalData: row, title: <FormattedMessage id="ChannelConfigDetail.TitleEdit" /> })
            }
        };

        const isCreatableOptions = [
            {
                value: true,
                label: <FormattedMessage id="ManageChannelConfig.true" />
            },
            {
                value: false,
                label: <FormattedMessage id="ManageChannelConfig.false" />
            }
        ];

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'far fa-clipboard'} title={<FormattedMessage id="ManageChannelConfig.Title" />} help={helpContent}>
                {
                    this.state.modal ?
                        <ChannelConfigEdit modal={this.state.modal} modalData={this.state.modalData} toggleModal={this.toggleModal} title={this.state.title} addOrUpdateChannelConfig={this.addOrUpdateChannelConfig} />
                        :
                        <div />
                }
                <Row>
                    <Col className="col-2">
                        <CustomSelect icon={'fa fa-check'} options={isCreatableOptions} placeholder={<FormattedMessage id="ManageChannelConfig.IsCreatable" />} value={isCreatableOptions.find(opt => opt.value === isCreatable)} onChange={this.setComboStatus.bind(this, 'isCreatable')} isClearable isSearchable />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn-host btn-sm mr-1 float-right" onClick={this.createChannelConfig}>
                            <i className="fas fa-plus-square" />
                        </Button>
                        <Button className="btn-host btn-sm mr-1 float-right" onClick={this.getChannelConfigList.bind(this)}>
                            <i className="fas fa-sync-alt" />
                        </Button>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">
                            <CustomTable
                                data={this.state.channelConfgList ? this.state.channelConfgList : []}
                                columns={columns}
                                page={this.state.currentPage}
                                sizePerPage={this.state.resultsPerPage}
                                totalSize={this.state.channelConfgList && this.state.channelConfgList.length}
                                shadow={false}
                                search={true}
                                remote={false}
                                showTotal={true}
                                selectRow={selectRow}
                            />
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        );
    }

}
export default injectIntl(ManageChannelConfig)