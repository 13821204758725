import React, { Component } from 'react';
import CustomToolTip from '../../../Utils/CustomToolTip';

export class CustomExportCSV extends Component {

    handleClick() {
        const { handleOnClick } = this.props;
        handleOnClick((cb) => {
            this.props.onExport();
            cb();
        });
    }

    render() {
        const { children, tooltipMessage } = this.props;

        return (
            <div>
                <button id="exportCsvButton" className="btn btn-host btn-sm float-right mb-2" onClick={this.handleClick.bind(this)}>
                    {children}
                </button>
                <CustomToolTip placement="left" target="exportCsvButton">
                    {tooltipMessage}
                </CustomToolTip>
            </div>
        );
    }
}