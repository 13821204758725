import React, { Component } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import classnames from 'classnames';
import { EntitySyncDashboard } from './EntitySyncDashboard';
import { GlobalDashboard } from './GlobalDashboard';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { getAPI } from "../../Base/API"


export class AdminHome extends Component {
 

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            blockRate: true,
            blockHotel: true,
            blockPrice: true,

        };
        this.toggleBlocking = this.toggleBlocking.bind(this);
    }

    toggleBlocking() {
        this.setState({ blocking: !this.state.blocking });
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount() {


       /* getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ syncEvents: data, block: false });
            }
        }, '/api/hotel/v1/Dashboard/SyncEvents');*/

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockPrice: false });

                return;
            }
            if (data) {

                this.setState({ PricingEvents: data, blockPrice: false });
            }
        }, '/api/Price/V1/Dashboard/receivedRecordCount');

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockHotel: false });

                return;
            }
            if (data) {

                this.setState({ HotelDataEvents: data, blockHotel: false });
            }
        }, '/api/hotel/v1/Dashboard/receivedRecordCount');

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockRate: false });

                return;
            }
            if (data) {

                this.setState({ RateData: data, blockRate: false });
            }
        }, '/api/Rate/DashBoard/receivedRecordCount');
        
    }


    render() {
        return (
            <Card className="border-0">
            <BlockUi tag="div" blocking={this.state.blocking}>
                <div className="position-top" >



                    <Nav tabs className="position-top">
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}>
                                    <FormattedMessage id="AdminHome.Home" />
            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                            >
                                    <FormattedMessage id="AdminHome.HotelConfig" />
            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '3' })}
                                onClick={() => { this.toggle('3'); }}
                            >
                                    <FormattedMessage id="AdminHome.Pricing" />
            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '4' })}
                                onClick={() => { this.toggle('4'); }}
                            >
                                    <FormattedMessage id="AdminHome.Rate" />
            </NavLink>
                        </NavItem>
                       
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} className="border-left: 1px solid #ddd;">
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12">
                                    <GlobalDashboard
                                        //syncEvents={this.state.syncEvents}
                                        HotelDataEvents={this.state.HotelDataEvents}
                                        PricingEvents={this.state.PricingEvents}
                                        RateData={this.state.RateData}
                                        blockHotel={this.state.blockHotel}
                                        blockPrice={this.state.blockPrice}
                                        blockRate={this.state.blockRate}
                                    />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col sm="12">
                                    <EntitySyncDashboard EntityEvents={this.state.HotelDataEvents} block={this.state.blockHotel} />
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="3">
                            <Row>
                                <Col sm="12">
                                    <EntitySyncDashboard EntityEvents={this.state.PricingEvents} block={this.state.blockPrice}/>

                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="4">
                            <Row>
                                <Col sm="12">
                                    <EntitySyncDashboard EntityEvents={this.state.RateData} block={this.state.blockRate}/>
      
                          </Col>
                            </Row>
                        </TabPane>
                       
                        
                    </TabContent>


                </div>
                </BlockUi>
            </Card>
        );
    }
}
