import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { injectIntl } from 'react-intl';
import moment from 'moment';

export class CalendarBox extends Component {

    render() {
        const { newCol, day, intl, colKey } = this.props;

        var classCol = "position-top ";

        if (day) {
            if (day.isoWeekday() === 6 || day.isoWeekday() === 7) {
                classCol += "bg-light-dark text-secondary"//"bg-secondary text-white"
            } else {
                classCol += "bg-light text-secondary"
            }
        }

        if(newCol !== colKey){
            classCol += " border";
        }
        else{
            classCol += " inventorySelectedCollum start";
        }

        return (
            <Col className={classCol} style={{ minWidth: '60px', minHeight: '65px' }}>
                <div className="text-center font_size_xxxs">{day ? moment(day).locale(intl.locale).format('ddd') : ""}</div>
                <strong><div className="text-center text-xs font-weight-bold  text-uppercase ">{day ? day.date() : ""}</div></strong>
                <div className="text-center font_size_xxxs">{day ? moment(day).locale(intl.locale).format('MMM') : ""}</div>
            </Col>
        );
    }
};

export default injectIntl(CalendarBox);