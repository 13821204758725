import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import {CustomPopover} from '../Common/CommonUIComponents';

class DashboardCard extends Component {
    

    
    render() {
        const {
            children,
            header,
            block,
            help,
            id
        } = this.props;


        return (
            <Card className=" shadow h-100 my-0 py-0">
                <BlockUi tag="div" blocking={block}>
                    <CardBody>
                        <Row>
                            <Col><p className="text-xs font-weight-bold text-secondary text-uppercase my-0 py-0"> <FormattedMessage id={header} /> </p> </Col>

                            {help ?
                                <Col className="text-right col-1">
                                    <span id={id} className="btn-sm btn-link" style={{ fontSize: 'large' }}>
                                        <i className="text-secondary fas fa-question-circle"></i>
                                    </span>
                                    <CustomPopover target={id}>
                                        {help}
                                    </CustomPopover>
                                </Col>
                            : ''}
                        </Row>
                    <hr />
                    <Row>
                        <Col>{children}</Col>

                    </Row>
                    </CardBody>
                </BlockUi>
            </Card>     
        );
    }
}

DashboardCard.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    header: PropTypes.string
};

DashboardCard.defaultProps = {
    
};

export default DashboardCard;