import React, { Component } from 'react'
import { Popover } from 'reactstrap';

export default class CustomPopOver extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isTargetValid: false
        };
    }

    componentDidMount() {
        this.checkIsTargetValid();
    }

    componentWillReceiveProps() {
        this.checkIsTargetValid();
    }

    checkIsTargetValid = () => {
        if (this.state.isTargetValid) return;
        const isTargetValid = document.getElementById(this.props.target) ? true : false;
        if (isTargetValid !== this.state.isTargetValid) this.setState({ isTargetValid });
    }
    
    render() {
        const  { children } = this.props;
        const { isTargetValid } = this.state;
        
        return (
            isTargetValid ?
                <Popover {...this.props} flip={false}>
                    {children}
                </Popover>
            :''
        )
    }
}
