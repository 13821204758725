import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StyledCard } from "../../Base/Common/CommonUIComponents";
import { getAPI } from "../../Base/API";
import { CustomTable } from '../../Base/Common/CustomTable';
import { ConfigLicenseDetail } from './ConfigLicenseDetail';

export class ConfigLicenses extends Component {

    constructor(props) {
        super(props);
        this.createLicense = this.createLicense.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.addOrUpdateLicense = this.addOrUpdateLicense.bind(this);
        this.state = {
            block: true,
            modal: false,
            modalData: {},
            licenses: []            
        }
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ block: false, licenses: data.licenses });
            }
        }, '/api/hotel/License/v1/license');
    }

    createLicense() {
        this.setState(prevstate => ({
            modal: !prevstate.modal,
            modalData: !prevstate.modalData,
            title: <FormattedMessage id="ConfigLicense.Create" />
        }));
    }

    toggleModal() {
        this.setState(prevstate => ({
            modal: !prevstate.modal,
            modalData: !prevstate.modalData,
            title: <FormattedMessage id="ConfigLicense.Edit" />
        }));
    }    

    addOrUpdateLicense(newLicense) {
        const idx = this.state.licenses && this.state.licenses.indexOf(this.state.licenses.find(license => license.id == newLicense.id));
        if (idx == -1) {
            //Add new
            this.setState(prevState => ({ licenses: [...prevState.licenses, newLicense] }));
        }
        else {
            //Update
            const licenses = this.state.licenses;
            licenses[idx] = newLicense;
            this.setState(({ licenses: licenses }));
        }
    }

    render() {
        const licenseLevels = [
            {
                value: 'ChannelExtras',
                label: <FormattedMessage id="HotelLicenseDetail.ChannelExtras" />
            },
            {
                value: 'Connector',
                label: <FormattedMessage id="HotelLicenseDetail.Connector" />
            }
        ]

        const columns = [
            {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "ConfigLicense.Id" }),
                sort: true
            },
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "ConfigLicense.Name" }),
                sort: true
            },
            {
                dataField: 'licenseLevel',
                text: this.props.intl.formatMessage({ id: "ConfigLicense.LicenseLevel" }),
                sort: true,
                formatter: (cell) => cell ? licenseLevels.find(level => level.value === cell).label : ''
            },
            {
                dataField: 'numChannels',
                text: this.props.intl.formatMessage({ id: "ConfigLicense.NumChannels" }),
                sort: true
            },
            {
                dataField: 'numRooms',
                text: this.props.intl.formatMessage({ id: "ConfigLicense.NumRooms" }),
                sort: true
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, modalData: row })
            }
        };

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-file-alt'} title={<FormattedMessage id="ConfigLicense.Title" />}>
                {
                    this.state.modal ?
                        <ConfigLicenseDetail modal={this.state.modal} modalData={this.state.modalData} toggleModal={this.toggleModal} title={this.state.title} addOrUpdateLicense={this.addOrUpdateLicense} licenseLevels={licenseLevels} />
                        :
                        <div/>
                }

                <Row className="pb-2">
                    <Col>
                        <Button color=" btn-host btn-sm float-right" onClick={this.createLicense}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomTable
                            data={this.state.licenses}
                            columns={columns}
                            shadow={false}
                            exportCSV={false}
                            selectRow={selectRow}
                            search={false}
                            remote={false}
                        />
                    </Col>
                </Row>
            </StyledCard>
        )
    }
}
export default injectIntl(ConfigLicenses);