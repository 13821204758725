import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { prettifyXml } from './../../Base/Common/CommonUIComponents'
import { FormattedMessage } from 'react-intl';

export class LogsRequestModal extends Component {

    downloadContent = () => {
        const bt = document.getElementById('downloadbtn');
        const name = `${this.props.docTitle}${this.props.requestType ? this.props.requestType.charAt(0).toUpperCase() + this.props.requestType.slice(1) : ''}`;

        if (this.props.requestContent.charAt(0) === '<' ) {
            bt.download = `${name}.xml`;
        }
        else if (this.props.requestContent.charAt(0) === '{' ) {
            bt.download = `${name}.json`;
        }
        else {
            bt.download = `${name}.txt`;
        }
    }

    getContent = (requestContent) => {
        if (this.props.requestContent?.charAt(0) === '<') {
            if (this.props.docTitle && this.props.docTitle === "Dnata") {
                requestContent = prettifyXml(requestContent) 
                return requestContent.replaceAll('&lt;', '<').replaceAll('&gt;', '>')
            }
            else {
                if (this.props.docTitle && (this.props.docTitle === 'Hotel Beds' || this.props.docTitle && this.props.docTitle === "Dnata")) {
                    requestContent = requestContent.replaceAll('&lt;', '<').replaceAll('&gt;', '>')
                }

                return prettifyXml(requestContent)
            }
        }

        return requestContent
    }

    render() {
        const { modal, toggle, requestType, requestContent } = this.props;

        return (
            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id={`LogsRequestModal.${requestType}Content`} />
                </ModalHeader>
                <ModalBody>
                    {requestContent && requestContent.length > 0 ?
                        <div className="text-right">
                            <a href={"data:text/plain;charset=utf-8, " + encodeURIComponent(this.getContent(requestContent))} onClick={this.downloadContent} className="btn btn-host btn-sm text-white mr-2" id="downloadbtn" >
                                <i className="fas fa-file-download "></i>
                            </a>
                        </div>
                    : ''}
                    <div className="overflow-auto" style={{ 'height': '70vh' }}>
                        <pre>
                            <code>
                                { this.getContent(requestContent) }
                            </code>
                        </pre>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        <FormattedMessage id="LogsRequestModal.Close" />
                    </Button>
                </ModalFooter>
            </Modal>            
        );
    }
}