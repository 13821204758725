import React, { Component } from 'react';
import { Button, Card, Col, Row, CardBody, Form, Collapse } from 'reactstrap';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import ReactHtmlParser from 'react-html-parser';

class OfferCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
    }

    toggleDesc = () => {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

    render() {
        const { item, hasChildren, intl, uploadedImg } = this.props;
        const { isOpen } = this.state;
        
        const descriptions = item.descriptions?.find(({culture}) => culture?.includes(intl.locale))
        ? item.descriptions?.find(({culture}) => culture.includes(intl.locale))
        : item.descriptions?.find(({culture}) => culture === "en-GB");
        
        return (
            <Card className="shadow border-0"
                style={{
                    width: isOpen ? '250px' : '240px',
                    minHeight: isOpen ? "275px" : "240px",
                    backgroundImage: uploadedImg ? `url("${uploadedImg}")` : `url("${item.imageUrl}")`,
                    color: 'white',
                    backgroundSize: 'cover',
                    transition: 'all .2s ease-out',
                    borderRadius: '8px'
                }}>
                <CardBody style={{ backgroundColor: '#0000007d', borderRadius: '8px' }}>
                    <Form className="h-100">
                        <div style={{ minHeight: isOpen ? '18%' : hasChildren && item.feeChildAmount && item.feeChildAmount > 0 ? '55%' : '50%', transition: 'all .2s ease-out'  }}>
                            <Row>
                                <Col>
                                    <div style={{ fontSize: isOpen ? '1.3em' : '1.1em' }}>{descriptions?.title ? descriptions.title : item.serviceCode}</div>
                                    <div style={{ fontSize: isOpen ? '0.9em' : '0.8em' }}>
                                        <FormattedMessage id={`ReferenceDataFunctions.${item.servicePricingType}`}/>
                                    </div>
                                </Col>
                            </Row>

                            {!isOpen ?
                                <Row>
                                    <Col className='pr-0'>
                                        <div style={{ display: 'flex', alignItems: 'center', fontSize:"18px" }} className="mt-2">
                                            <div>
                                                <FormattedNumber value={item.feeAmount} style="currency" currency="EUR" minimumFractionDigits="2"/>
                                            </div>
                                        </div>
                                        {hasChildren && item.feeChildAmount && item.feeChildAmount > 0 ?
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div style={{ fontSize: '0.8em',  verticalAlign: 'middle', height:'100%', display: 'flex', alignItems: 'center' }}>
                                                    <div className='mr-1'><FormattedMessage id="Offers.Children"/></div>
                                                    <FormattedNumber value={item.feeChildAmount} style="currency" currency="EUR" minimumFractionDigits="2"/>
                                                </div>
                                            </div>
                                        :''}
                                    </Col>
                                </Row>
                            : ''}
                        </div>
                        
                        {descriptions?.description ?
                            <Row className="pb-4">
                                <Col className='cursor-pointer col-12' onClick={this.toggleDesc}>
                                    <span className="font_size_xxs">
                                        <u><FormattedMessage id="Offers.Description" /></u>
                                    </span>
                                </Col>
                                <Col className='my-1' style={{ maxHeight: '120px', overflow: 'auto' }}>
                                    <Collapse className="font_size_xxs mt-2 conditionsDiv" isOpen={isOpen}>
                                        <div>{ReactHtmlParser(descriptions.description)}</div>
                                    </Collapse>
                                </Col>
                            </Row>
                        :''}


                        <div style={{
                            position: 'absolute',
                            bottom: '15px',
                            left: '0',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Button type="button" className="btn-sm px-4 font_size_xxs border-0"
                            style={{
                                position: "relative",
                                minWidth: "130px",
                                fontWeight: '500',
                                color: 'black',
                                backgroundColor: 'white',
                                height: '25px',
                                padding: '0.25rem 0.5rem',
                                borderRadius: '0.2rem',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <FormattedMessage id="Offers.AddToCart"/>
                            </Button>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        );
    }
}

export default injectIntl(OfferCard)