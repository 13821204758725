import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, Form, Label, Input } from 'reactstrap';
import CustomSelect from '../../Base/Common/CustomSelect';
import { putAPI } from "../../Base/API"
import { ErrorAlert } from "../../Common/ErrorAlert";
import BlockUi from 'react-block-ui'
import { handleNotification } from "../../Base/Notification";
import { FormattedMessage } from 'react-intl';

class ApplyAirbnbOpportunity extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: false,
            selectedOpportunity: null,
            listingOptions: [],
            selectedListings: [],
            inputFields: {},
            comboOptions: {}
        };
    }

    componentDidMount() {
        //List of applicable_listing_ids
        if (this.props.listings && this.props.selectedOpportunity && this.props.selectedOpportunity.applicable_listing_ids) {
            var list = this.props.listings.filter(l => this.props.selectedOpportunity.applicable_listing_ids.some(al => al === l.value.toString()));

            this.setState({ listingOptions: list });
        }

        //Combo options
        if (this.props.selectedOpportunity.input_fields && this.props.selectedOpportunity.input_fields.some(el => el.value_constraint.value_type === 'OPTION')) {
            const { comboOptions } = this.state;

            this.props.selectedOpportunity.input_fields.forEach(field => {
                if (field.value_constraint.value_type === 'OPTION') {
                    comboOptions[field.key] = [];

                    field.value_constraint.options.forEach(option => {
                        comboOptions[field.key].push({ value: option, label: option });
                    })
                }
            });

            this.setState({ comboOptions });
        }
    }

    applyOpportunity = (evt) => {
        evt.preventDefault();
        this.setState({ block: true });

        var body = {
            type: this.props.selectedOpportunity.type,
            opportunityId: this.props.selectedOpportunity.id,
            listingIds: this.state.selectedListings
        };

        if (this.state.inputFields && Object.keys(this.state.inputFields).length > 0) {
            body.fields = this.state.inputFields;
        }


        putAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockUpdate: false, block: false });

                return;
            }
            if (data) {
                if (data.error && data.error.error_id) {
                    handleNotification(data);
                }
                if (data.response && data.response.success) {
                    handleNotification(
                        data,
                        <FormattedMessage id="AirbnbOportunityCard.OpportunityApplied" />,
                        <FormattedMessage id="General.Success" />
                    );
                    this.props.getOpportunities();
                    this.props.toggleModal();
                }

                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/Rate/Oportunity/v1/channelInstance/' + this.props.channelInstanceId + '/airbnb', body);
    }

    changeListings = (combo) => {
        this.setState({
            selectedListings: combo ? combo.map(opt => opt.value) : null
        });
    }

    handleInputFields = (value, name) => {
        const { inputFields } = this.state;
        inputFields[name] = value ? value.value : null;

        this.setState({ inputFields });
    }

    render() {
        const { modal, toggleModal, selectedOpportunity } = this.props;

        return (
            <Modal isOpen={modal} toggle={toggleModal} style={{ minWidth: 0 }} className="modal-lg">
                <ModalHeader toggle={toggleModal}>
                    <FormattedMessage id="AirbnbOportunityCard.ApplyOpportunity" />
                </ModalHeader>

                <ModalBody className="p-0 modal-lg">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={(e) => this.applyOpportunity(e)}>
                            <Card className="shadow border-0 p-0">
                                <CardBody className="m-0">
                                    <Row>
                                        <Col className="text-right">
                                            <Button type="submit" className="btn btn-sm btn-host float-right">
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row className="my-2">
                                        <Label sm={3}> Listings </Label>
                                        <Col className="col-lg-9 col-12">
                                            <CustomSelect
                                                isSearchable
                                                placeholder={"Listings"}
                                                options={this.state.listingOptions}
                                                onChange={this.changeListings.bind(this)}
                                                isMulti={true}
                                                required={true}
                                            />
                                        </Col>
                                    </Row>

                                    {selectedOpportunity && selectedOpportunity.input_fields && selectedOpportunity.input_fields.map((field, key) => field.value_constraint ?
                                        <Row key={key} className="mb-2">
                                            <Label sm={3}> {field.key_label ? field.key_label : field.key} </Label>
                                            <Col>
                                                {field.value_constraint.value_type === 'OPTION' ?
                                                    <CustomSelect
                                                        isSearchable
                                                        placeholder={""}
                                                        options={this.state.comboOptions[field.key]}
                                                        onChange={(combo) => this.handleInputFields(combo, field.key)}
                                                        required={true}
                                                    />
                                                :
                                                    <Input
                                                        type="number"
                                                        className="text-sm"
                                                        onChange={(e) => this.handleInputFields(e.target, field.key)}
                                                        step={field.value_constraint.value_type === "DOUBLE" ? 0.01 : 1}
                                                        min={field.value_constraint.min_value}
                                                        max={field.value_constraint.max_value}
                                                        required
                                                    />
                                                }

                                            </Col>
                                        </Row>
                                    : '')}
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default ApplyAirbnbOpportunity;