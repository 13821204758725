import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../../Base/Notification";
import { getAPI } from "../../Base/API";
import { StyledCard } from "../../Base/Common/CommonUIComponents";

class ResetCache extends Component {
	constructor(props) {
		super(props);

		this.state = {
			block: false,
			cacheOptions: [
				{
					label: 'Reset Channel Instance Cache',
					url: '/api/Rate/ChannelConfig/v1/resetUserCache?cacheKey=channelInstance'
				},
				{
					label: 'Reset Room Category Cache',
					url: '/api/hotel/RoomCategory/v1/resetUserCache?cacheKey=roomCategories'
				}
			]
		};
	}

	resetCache = (e, url) => {
		e.preventDefault();
		this.setState({ block: true });

		getAPI(result => {
			const { data, error } = result;
			var errorMessage = [];

			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0) {
					handleNotification(data);
					this.setState(({ error: errorMessage, block: false }));
				}
			}
			else {
				handleNotification(data, <FormattedMessage id="ResetCache.CacheResetSuccessfully" />, <FormattedMessage id="General.Success" />);
				this.setState({ block: false });
			}
		}, url);
	}

	render() {
		const { cacheOptions } = this.state;

		return (
			<StyledCard block={this.state.block} error={this.state.error} icon='fas fa-database' title='navbar.ResetCache' >
				{cacheOptions.map((cache, key) =>
					<Row className="mx-3 mt-3" key={key}>
						<Col>
							{cache.label}
						</Col>
						<Col className="text-right">
							<Button color=" btn-host btn-sm mr-1 " onClick={(e) => this.resetCache(e, cache.url)} >
								<i className="fas fa-sync" />
							</Button>
						</Col>
					</Row>
				)}
			</StyledCard>
		);
	}
}

export default injectIntl(ResetCache);