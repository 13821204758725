import React, { Component } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { Button, Col, Input, InputGroup, InputGroupAddon, Row, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';
import { getAPI, postAPI } from "../../Base/API";
import { StyledCard, ReservationStatusBadge } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Notification";


export class ConfirmReservationPayment extends Component {

    state = {
        activeTab: '1',
        activeAddressTab: '1',
        reservationId : null,
        HotelData: [],
        error: null,
        block: true,
    };
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

    }

    componentDidMount() {
        this.getPaymentConfirmationList();

    }

    getPaymentConfirmationList() {

        this.setState({ block: true });
        let param = '';
        if (this.state.reservationId)
            param += '?reservationNumber=' + this.state.reservationId

        getAPI(result => {
            const { data, error, isLoading } = result;
           
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ paymentConfirmationList: data.reservationAccountings, block: false });
            }
        }, '/api/Price/Reservation/v1/ConfirmPayment' + param);
    }



    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleAddress(tab) {
        if (this.state.activeAddressTab !== tab) {
            this.setState({
                activeAddressTab: tab
            });
        }
    }

    paymentAction(action, id, evt) {

        postAPI(result => {
            const { data, error, isLoading } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="ConfirmReservationPayment.ReservationUpdated" />, <FormattedMessage id="General.Success" />);
                this.setState({ block: false });
            }
        }, '/api/Price/Reservation/v1/'+id+ '/ConfirmPayment/'+ action);


    }

    render() {


        const helpContent =
            <div>
                TODO
                <hr />
            </div>


        const columns = [
            {
                dataField: 'reservationId',
                text: this.props.intl.formatMessage({ id: "ConfirmReservationPayment.Reservation" }),
                sort: true,
                headerStyle: () => {
                    return { width: "5%" };
                }
            }, {
                dataField: 'checkIn',
                text: this.props.intl.formatMessage({ id: "ConfirmReservationPayment.CheckIn" }),
                sort: true,
                formatter: (cell, row) => <FormattedDate value={cell} /> 
            },
            {
                dataField: 'checkOut',
                text: this.props.intl.formatMessage({ id: "ConfirmReservationPayment.CheckOut" }),
                sort: true,
                formatter: (cell, row) => <FormattedDate value={cell} /> 

            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "ConfirmReservationPayment.Status" }),
                sort: true,
                formatter: (cell, row) => <ReservationStatusBadge status={cell} />

            },
            
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "ConfirmReservationPayment.Name" }),
                sort: true
               
            },
            {
                dataField: 'amount',
                text: this.props.intl.formatMessage({ id: "ConfirmReservationPayment.ReservationValue" }),
                sort: true
                
            },
           
            {
                dataField: 'Accao',
                text: this.props.intl.formatMessage({ id: "ConfirmReservationPayment.Action" }),
                sort: true,
                headerStyle: () => {
                    return { width: "5%" };
                },
                formatter: (cell, row) => <UncontrolledButtonDropdown size="sm" >
                    <DropdownToggle caret className="btn-host" >
                        <FormattedMessage id="ConfirmReservationPayment.Action" />
      </DropdownToggle>
                    <DropdownMenu>

                        <DropdownItem onClick={this.paymentAction.bind(this, 'Cancel', row.id)}><FormattedMessage id="ConfirmReservationPayment.Cancel" /></DropdownItem>
                        <DropdownItem onClick={this.paymentAction.bind(this, 'Confirm', row.id)}><FormattedMessage id="ConfirmReservationPayment.Confirm" /></DropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            }];

        const selectRow = {

            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, modalData: row })
            }
        };

        return (
          

            <StyledCard title="ConfirmReservationPayment.ConfirmPayment" help={helpContent} block={this.state.block} error={this.state.error} icon={'far fa-credit-card'}>
                            <Row>
                                <Col className="col-2">
                                    <InputGroup size="sm text-right " >
                                        <InputGroupAddon addonType="prepend"><span className="input-group-text"><span className="fas fa-key fa-fw"></span></span></InputGroupAddon>
                            <FormattedMessage id="ReservationList.ReservationId">{placeholder =>
                                <Input className="border-left-0" type="text" name="reservationid" maxLength="20" id="reservationid" placeholder={placeholder} value={this.state.reservationId} onChange={(evt) => this.setState({ reservationId: evt.target.value })} >
                                            </ Input>
                                        }</FormattedMessage>
                                    </InputGroup>
                    </Col>
                    <Col><Button className="btn btn-sm btn-host float-right" onClick={this.getPaymentConfirmationList.bind(this)}><i className="fas fa-search"></i></Button></Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <CustomTable
                            data={this.state.paymentConfirmationList ? this.state.paymentConfirmationList : []}
                                        columns={columns}
                                        page={this.state.currentPage}
                                        sizePerPage={this.state.resultsPerPage}
                            totalSize={this.state.paymentConfirmationList && this.state.paymentConfirmationList.length}
                                        shadow={false}
                                        search={true}
                                        remote={false}
                                        selectRow={selectRow}
                                    />
                                </Col>
                            </Row>

                        </StyledCard>
                   


        );
    }
}