import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { getAPI, putAPI, deleteAPI } from "../../Base/API";
import { ErrorAlert } from "../../Common/ErrorAlert";
import { handleNotification } from "../../Base/Notification";
import { FormattedMessage } from 'react-intl';
import BookingMailBoxMessages from './BookingMailBoxMessages';
import BookingMailBoxMessagesMobile from './BookingMailBoxMessagesMobile';


export class BookingMailBoxCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            modal: false,
            channelInstanceList: [],
            selectedConnection: this.props.channelInstance || '',
            threads: null,
            selectedConversation: null,
            reservationData: null,
            reservationId: null
        };
    }

    getConversations = (event) => { 
        if (event) 
            event.preventDefault();

        this.setState({ block: true, threads: null}, () => {

            getAPI(result => {
                const { data, error } = result;
                var errorMessage = []
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0 || data.warnings && data.warnings.length > 0) {
                        handleNotification(data);
                        this.setState(({ block: false, error: errorMessage }));
                        return;
                    }

                    if (data.data) {
                        this.setState({ threads: data.data, block: false, error: errorMessage });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/Rate/MailBox/v1/messages/${this.state.selectedConnection}/conversation`);
        });
    }

    getConversationByReservation = (event) => {
        if (event) event.preventDefault();
        this.setState({ block: true, threads: null }, () => {

            getAPI(result => {
                const { data, error } = result;
                var errorMessage = []
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0 || data.warnings && data.warnings.length > 0) {
                        handleNotification(data);
                        this.setState(({ block: false, error: errorMessage }));
                        return;
                    }

                    if (data.data) {
                        this.setState({
                            selectedConversation: data.data,
                            block: false,
                            error: errorMessage
                        });
                        return;
                    }
                }
                this.setState({ block: false });
            }, `/api/Rate/MailBox/v1/messages/${this.state.selectedConnection}/reservation/${this.state.reservationId}`);
        });
    }

    selectConversation = (conversation_id) => {
        if(conversation_id){
            this.setState({ block: true }, () => {
                getAPI(result => {
                    const { data, error } = result;
                    var errorMessage = []
                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                            handleNotification(data);
                            this.setState(({ block: false, error: errorMessage }));
                            return;
                        }

                        if (data.data) {
                            this.setState({
                                selectedConversation: data.data,
                                block: false,
                                error: errorMessage
                            }, () => this.getReservationDetails(data.data.conversation.conversation_reference));
                            return;
                        }
                    }
                    this.setState({ block: false });
                }, `/api/Rate/MailBox/v1/messages/${this.state.selectedConnection}/conversation/${conversation_id}`)
            });
        }else{
            this.setState({ selectedConversation: null });
        }
    }

    getReservationDetails = (reservationId) => {
        const { selectedConnection } = this.state;
        this.setState({ block: true });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.data && data.data.length > 0) {
                this.setState({ block: false, reservationData: data.data[0] });
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Price/Reservation/v1/channelReserveId?channelInstanceId=${selectedConnection}&resId=${reservationId}`);
    }
    
    setComboStatus = (field, combo) => {
        this.setState({
            [field]: combo ? combo.value : null
        })
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState({ [name]: value })
    }

    doSearch = (evt) => {
        evt.preventDefault();
        this.setState({
            selectedConversation: null
        }, () => this.state.reservationId ? this.getConversationByReservation() : this.getConversations())
    }

    markAsNoReplyNeeded = (e, conversationId, index) => {
        e.preventDefault();
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            var errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.data && data.data.ok === true) {
                    handleNotification(data, <FormattedMessage id="BookingChatWindow.MarkedAsNoReplyNeeded" />, <FormattedMessage id="General.Success" />);

                    const { threads } = this.state;
                    threads.conversations[index] = {
                        ...threads.conversations[index],
                        tags: {
                            no_reply_needed: { set: true }
                        }
                    };
                    this.setState({ block: false, error: errorMessage, message: '', threads });
                    return;
                }
            }
            this.setState({ block: false });
        }, `/api/Rate/MailBox/v1/messages/${this.state.selectedConnection}/conversation/${conversationId}/MarkAsNoReply`);
    }

    removeNoReplyNeededTag = (e, conversationId, index) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            var errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.data && data.data.ok === true) {
                    handleNotification(data, <FormattedMessage id="BookingChatWindow.NoReplyNeededRemoved" />, <FormattedMessage id="General.Success" />);

                    const { threads } = this.state;
                    if (threads.conversations[index].tags) {
                        delete threads.conversations[index].tags.no_reply_needed;
                    }
                    this.setState({ block: false, error: errorMessage, message: '', threads });
                    return;
                }
            }
            this.setState({ block: false });
        }, `/api/Rate/MailBox/v1/messages/${this.state.selectedConnection}/conversation/${conversationId}/MarkAsNoReply`);
    }

    backgroundColor = (item) => {
        if (this.state.selectedConversation && this.state.selectedConversation.conversation.conversation_id === item.conversation_id) {
            return 'bg-disabled';
        }
        else {
            return 'text-secondary';
        }
    }

    render() {
        const { reservationData } = this.state;

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    {!global.isMobile ? 
                        <BookingMailBoxMessages
                            reservationData={reservationData}
                            threads={this.state.threads}
                            backgroundColor={this.backgroundColor}
                            selectConversation={this.selectConversation}
                            removeNoReplyNeededTag={this.removeNoReplyNeededTag}
                            markAsNoReplyNeeded={this.markAsNoReplyNeeded}
                            selectedConversation={this.state.selectedConversation}
                            selectedThread={this.state.selectedThread}
                            selectedConnection={this.state.selectedConnection}
                            setComboStatus={this.setComboStatus}
                            reservationId={this.state.reservationId}
                            doSearch={this.doSearch}
                            handleChange={this.handleChange}
                        />
                    : 
                        <BookingMailBoxMessagesMobile
                            reservationData={reservationData}
                            threads={this.state.threads}
                            backgroundColor={this.backgroundColor}
                            selectConversation={this.selectConversation}
                            removeNoReplyNeededTag={this.removeNoReplyNeededTag}
                            markAsNoReplyNeeded={this.markAsNoReplyNeeded}
                            selectedConversation={this.state.selectedConversation}
                            selectedThread={this.state.selectedThread}
                            selectedConnection={this.state.selectedConnection}
                            setComboStatus={this.setComboStatus}
                            reservationId={this.state.reservationId}
                            doSearch={this.doSearch}
                            handleChange={this.handleChange}
                        />
                    }
                </BlockUi> 
            </div>
        );
    }
}