import React, { Component } from 'react';
import { Badge, Col, DropdownToggle, Input, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledButtonDropdown } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { BlankCard, EmptyCard } from '../../../Base/Common/CommonUIComponents';
import CustomSelect from '../../../Base/Common/CustomSelect';

export class BookingConnectionDetail extends Component {

    render() {
        const { block, children, disablePricingModelChange, error, getConnectionTypes, handleChangePrincingModel, isOpen, selectedProperty, selectedPropertyPricingModel, toggle } = this.props;

        const pricingModels = [
            {
                value: 'Standard',
                label: 'Standard'
            },
            {
                value: 'OBP',
                label: 'OBP'
            }
        ]

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {selectedProperty.property.name}
                </ModalHeader>
                <ModalBody>
                    <BlankCard block={block} error={error}>
                        <Row>
                            <Col className="text-right">
                                <UncontrolledButtonDropdown>
                                    <DropdownToggle tag="div" className="cursor-pointer py-2 pl-2">
                                        <i className="fas fa-ellipsis-v" />
                                    </DropdownToggle>
                                    {
                                        children
                                    }
                                </UncontrolledButtonDropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Row className="mb-2">
                                    <Col>
                                        <EmptyCard>
                                            <h6>
                                                <FormattedMessage id="BookingConnections.ContactDetails" />
                                            </h6>
                                            <Row>
                                                <Col className="col-12 px-0 px-lg-3">
                                                    <Row className="pb-0">
                                                        <Col className="mt-2">
                                                            <Label for="name">
                                                                <FormattedMessage id="BookingConnections.Name" />
                                                            </Label>
                                                            <Input type="text" name="name" id="name" bsSize="sm" className="text-sm" disabled defaultValue={selectedProperty.property.name} />
                                                        </Col>
                                                    </Row>
                                                    <Row className="pb-0">
                                                        <Col className="mt-2">
                                                            <Label for="address">
                                                                <FormattedMessage id="BookingConnections.Address" />
                                                            </Label>
                                                            <Input type="text" name="address" id="address" bsSize="sm" className="text-sm" disabled defaultValue={selectedProperty.property.address} />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </EmptyCard>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col>
                                        <EmptyCard>
                                            <h6>
                                                <FormattedMessage id="BookingConnections.PropertyDetails" />
                                            </h6>
                                            <Row>
                                                <Col className="col-12 px-0 px-lg-3">
                                                    <Row className="pb-0">
                                                        <Col className="mt-2">
                                                            <Label for="propertyId">
                                                                <FormattedMessage id="BookingConnections.PropertyId" />
                                                            </Label>
                                                            <Input type="text" name="propertyId" id="propertyId" bsSize="sm" className="text-sm" disabled defaultValue={selectedProperty.property.id} />
                                                        </Col>
                                                    </Row>
                                                    {
                                                        selectedProperty.pricing ?
                                                            <div>
                                                                <Row className="pb-0">
                                                                    <Col className="mt-2">
                                                                        <Label for="currency">
                                                                            <FormattedMessage id="BookingConnections.Currency" />
                                                                        </Label>
                                                                        <Input type="text" name="currency" id="currency" bsSize="sm" className="text-sm" disabled defaultValue={selectedProperty.pricing.currency_code} />
                                                                    </Col>
                                                                </Row>
                                                                <Row className="pb-0">
                                                                    <Col className="mt-2">
                                                                        <Label for="model">
                                                                            <FormattedMessage id="BookingConnections.Model" />
                                                                        </Label>
                                                                        <CustomSelect options={pricingModels} value={pricingModels.find(prc => prc.value === selectedPropertyPricingModel)} isDisabled={disablePricingModelChange} onChange={handleChangePrincingModel} />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            :
                                                            ''
                                                    }
                                                </Col>
                                            </Row>
                                        </EmptyCard>
                                    </Col>
                                </Row>
                            </Col>
                            <Col>
                                <Row className="mb-2">
                                    <Col>
                                        <EmptyCard>
                                            <h6>
                                                <FormattedMessage id="BookingConnections.ConnectionDetails" />
                                            </h6>
                                            <Row className="pb-0">
                                                <Col className="mt-2">
                                                    <Label className="mb-0" for="name">
                                                        <FormattedMessage id="BookingConnections.ConnetionTypes" />
                                                    </Label>
                                                </Col>
                                            </Row>
                                            <Row className="pt-0">
                                                <Col className="mt-2">
                                                    {
                                                        getConnectionTypes(selectedProperty.connection_types).map((conn, key) => <Badge key={key} className="text-white mr-1" color="primary">{conn}</Badge>)
                                                    }
                                                </Col>
                                            </Row>
                                        </EmptyCard>
                                    </Col>
                                </Row>
                                {
                                    selectedProperty.legal_entity ?
                                        <Row className="mb-2">
                                            <Col>
                                                <EmptyCard>
                                                    <h6>
                                                        <FormattedMessage id="BookingConnections.PmcDetails" />
                                                    </h6>
                                                    <Row>
                                                        <Col className="col-12 px-0 px-lg-3">
                                                            <Row className="pb-0">
                                                                <Col className="mt-2">
                                                                    <Label for="legalEntityId">
                                                                        <FormattedMessage id="BookingConnections.LegalEntityId" />
                                                                    </Label>
                                                                    <Input type="text" name="legalEntityId" id="legalEntityId" bsSize="sm" className="text-sm" disabled defaultValue={selectedProperty.legal_entity.id} />
                                                                </Col>
                                                            </Row>
                                                            <Row className="pb-0">
                                                                <Col className="mt-2">
                                                                    <Label for="company_name">
                                                                        <FormattedMessage id="BookingConnections.CompanyName" />
                                                                    </Label>
                                                                    <Input type="text" name="company_name" id="company_name" bsSize="sm" className="text-sm" disabled defaultValue={selectedProperty.legal_entity.company_name} />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </EmptyCard>
                                            </Col>
                                        </Row>
                                        :
                                        ''
                                }
                            </Col>
                        </Row>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}