import React, { Component } from 'react';
import { Button, Col, Row, Card } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CreatableSelect from 'react-select/creatable';
import { BlankCard } from '../../../Base/Common/CommonUIComponents';
import { handleNotification } from '../../../Base/Notification';
import { getAPI, postAPI } from '../../../Base/API';
import ClearAvailabilityModal from '../ConfigCategoriesAndAvailability/ClearAvailabilityModal';

export class StepRoomCategories extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            pmsRoomCategories: [],
            roomCategories: [],
            modal: false
        }
    }

    componentDidMount() {
        this.getRoomCategories();
    }

    getRoomCategories = () => {
        const { selectedHotel } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }

            if (data) {
                let roomCategories = [];

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    roomCategories = data.response.map(r => {
                        return {
                            value: r.id,
                            label: `${r.code} (${r.description})`,
                            code: r.code,
                            description: r.description,
                            pmsId: r.pmsId,
                            deleted: r.deleted
                        }
                    });
                }

                this.setState({ roomCategories }, this.getPMSRoomCategories);
            }
            else {
                this.setState({ block: false, error: errorMessage, roomCategories: [] });
            }
        }, `/api/hotel/RoomCategory/v1/Info?hotelId=${selectedHotel.value}`);
    }

    getPMSRoomCategories = () => {
        const { selectedHotel } = this.props;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockPms: false });
                return;
            }
            if (data) {
                var pmsRoomCategories = [];

                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                if (data.response && data.response.length > 0) {
                    pmsRoomCategories = data.response.map(r => {
                        return {
                            value: r.id.toString(),
                            label: `${r.categoryCode} (PMS Id: ${r.id})`,
                            isDisabled: r.isUsed
                        }
                    });
                }

                this.setState({ error: errorMessage, block: false, pmsRoomCategories });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/hotel/v1/pmsInvokeAction/${selectedHotel.value}/roomCategories`);
    }

    associatePmsId = (combo, index) => {
        if (isNaN(parseInt(combo.value))) {
            const data = { response: null, errors: [{ code: "400", message: <FormattedMessage id="ConfigRoomCatRate.NewPmsIdErr" /> }], warnings: [] };
            handleNotification(data);
        }
        else {
            const { roomCategories } = this.state;
            roomCategories[index].pmsId = combo.value;
            this.setState({ roomCategories });
        }
    }

    toggle = () => {
        this.setState(prevState => ({ modal: !prevState.modal }));
    }

    save = () => {
        const { roomCategories } = this.state;

        const request = {
            request: {
                roomCategoryPmsIds: roomCategories
                    .filter(roomCategory => roomCategory.pmsId !== null)
                    .map(roomCategory => {
                        const pair = {
                            roomCategoryId: roomCategory.value,
                            pmsId: roomCategory.pmsId
                        };
                        return pair;
                    })
            }
        };

        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else if (data.warnings && data.warnings.length > 0) {
                    handleNotification(data);
                }
                else {
                    if (data.warnings && data.warnings.length > 0) {
                        handleNotification(data);
                        handleNotification({}, <FormattedMessage id="ConfigCategoriesAndAvailability.PmsIdAssociated" />, <FormattedMessage id="General.Success" />);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="ConfigCategoriesAndAvailability.PmsIdAssociated" />, <FormattedMessage id="General.Success" />);
                    }
                }
            }
            
            this.setState({ error: errorMessage, block: false });
        }, `/api/hotel/RoomCategory/v1/roomCategory/PostPmsWithRoomCategoryAsync`, request);
    }

    render() {
        const { block, error, modal, pmsRoomCategories, roomCategories } = this.state;

        return (
            <BlankCard block={block} error={error}>
                {
                    modal ?
                        <ClearAvailabilityModal
                            modal={modal}
                            toggleModal={this.toggle}
                            roomCategories={roomCategories}
                        />
                        :
                        ''
                }
                <Row className="mb-2">
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm mr-2" onClick={this.toggle} title="Clear Availability">
                            <span className="fas fa-bed" />
                        </Button>
                        <Button className="btn btn-host btn-sm" onClick={this.save}>
                            <span className="fas fa-save" />
                        </Button>
                    </Col>
                </Row>
                <div>
                    <Card body className="mb-3 shadow py-2 text-muted ">
                        <Row>
                            <Col> <FormattedMessage id="ConfigCategoriesAndAvailability.RoomCategoryCode" /></Col>
                            <Col sm={6}> <FormattedMessage id="RoomCategorySetupDetails.Description" /> </Col>
                            <Col> PMS Id</Col>
                            <Col className="col-1 text-center "> <FormattedMessage id="generic.Status" /> </Col>
                        </Row>
                    </Card>
                </div>
                {
                    roomCategories.map((cat, key) =>
                        <Card body className="mb-2 py-2 shadow overbglight" key={key}>
                            <Row key={key} className="align-items-center">
                                <Col> {cat.code} </Col>
                                <Col sm={6}> {cat.description} </Col>
                                <Col>
                                    <CreatableSelect
                                        options={pmsRoomCategories}
                                        value={pmsRoomCategories.find(rc => rc.value == cat.pmsId)}
                                        onChange={(combo) => this.associatePmsId(combo, key)}
                                    />                                    
                                </Col>
                                <Col className="col-1 d-flex justify-content-center">
                                    <span className="d-flex align-items-center justify-content-center" style={{ backgroundColor: cat.pmsId ? 'green' : 'red', height: '25px', width: '25px', color: 'white', borderRadius: '20px' }}>
                                        <i className={`fas ${cat.pmsId ? `fa-check` : `fa-times`} fa-xs`} />
                                    </span>
                                </Col>
                            </Row>
                        </Card>
                    )
                }
            </BlankCard>
        );
    }
}