import classnames from 'classnames';
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Form, Row, CardHeader, Card, CardBody } from 'reactstrap';
import '../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { TitleAndEditorText, TitleMultiLang } from '../../../Base/Common/SupportedMultiLang';
import { getAPI, postAPI } from "../../../Base/API"; 
import { handleNotification } from "../../../Base/Notification";
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { CommonHelper } from '../../../Base/Common/CommonUIComponents';

export class PropertyDescription extends Component {   

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.form = React.createRef();
        this.state = {
            activeTab: 0,
            activeAddressTab: '1',
            hotelDescription: [],
            error: null,
            block: true,
            lang: 'en-GB',
            generateDescriptionCollapse: false,
            elementDescription: {
                descriptions: []
            },
            emptyItem: {
                culture: null,
                description: null,
                descriptionId: null,
                titleDescription: null,
                titleId: null,
            }
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.url !== this.props.url) {
            this.getDescriptions(nextProps.url);
        }
    }

    componentDidMount() {
        if (this.props.selectedEntity) {
            this.getDescriptions(this.props.url);
        }
    }

    getDescriptions = (url) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.descriptions) {
                data.descriptions.map(description => {
                    const blocksFromHtml = htmlToDraft(description.description);
                    const { contentBlocks, entityMap } = blocksFromHtml;
                    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                    description.editorState = EditorState.createWithContent(contentState);
                });
                this.setState({ elementDescription: data, block: false });
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, url);
    }

    handleSubmit(e) {
        e.preventDefault();
        const descriptions = [];
        this.state.elementDescription.descriptions.map(description => {
            const desc = description.editorState && description.editorState.getCurrentContent() ? draftToHtml(convertToRaw(description.editorState.getCurrentContent())) : '';
            const newDescription = {
                culture: description.culture,
                titleId: description.titleId,
                titleDescription: description.titleDescription,
                descriptionId: description.descriptionId,
                description: (desc && desc.toLocaleLowerCase() !== "<p></p>" && desc.toLocaleLowerCase() !== "\n<p></p>" && desc.toLocaleLowerCase() !== "<p></p>\n") ? desc : null,
                type: description.type ? description.type : 'Description' 
            };
            descriptions.push(newDescription);
        });
        
        const englishTexts = descriptions.find(({culture}) => culture === "en-GB");
        if(!englishTexts || !englishTexts.titleDescription) {
            handleNotification({warnings:[{message: this.props.intl.formatMessage({id: "RatesIntegrations.TitleDescriptionRequired"})}]});
            
            return;
        }
        

        //const descriptionsTextEditor = document.getElementById('descriptionSelectNewCountryId');
        //let found = false;
        //for (let i = 0; i < descriptions.length; i++) {
        //    if (descriptions[i].description.length == 0) {                
        //        descriptionsTextEditor.setCustomValidity(this.props.intl.formatMessage({ id: "ComplexDescription.EmptyMessage" }));
        //        found = true;
        //        break;                
        //    }
        //    if (descriptions[i].description.length > 1000) {                
        //        descriptionsTextEditor.setCustomValidity(this.props.intl.formatMessage({ id: "ComplexDescription.LengthOverOneThousand" }));
        //        found = true;
        //        break;                
        //    }
        //}
        //if (!found) {
        //    descriptionsTextEditor.setCustomValidity("");
        //}

        if (this.form.current.reportValidity()) {
            this.setState({ block: true });            

            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    const errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.descriptions) {
                    data.descriptions.map(description => {
                        const blocksFromHtml = htmlToDraft(description.description);
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        description.editorState = EditorState.createWithContent(contentState);
                    });
                    this.setState({ elementDescription: data });
                }
                handleNotification(data, <FormattedMessage id="ComplexDescription.SuccessMessageSave" />, <FormattedMessage id="General.Success" />);
                this.setState({ block: false });
                
            }, '/api/hotel/' + this.props.entity + '/v1/' + this.props.selectedEntity.id + '/Language', JSON.stringify(descriptions));
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab.key
            });
        }
    }

    handleTitleChange = (evt, lang) => {
        let value = evt.target.value;
        let elementDescription = { ...this.state.elementDescription };
        let selectedText = elementDescription.descriptions && elementDescription.descriptions.find(el => el.culture === lang);

        if (selectedText) {
            selectedText.titleDescription = value;
        }
        else {
            let newItem = { ...this.state.emptyItem };
            newItem.culture = lang;
            newItem.titleDescription = value;
            elementDescription.descriptions.push(newItem);
        }

        this.setState({ elementDescription });

    }

    onEditorStateChange = (newEditorState, lang) => {
        const elementDescription = { ...this.state.elementDescription };
        let text = elementDescription.descriptions && elementDescription.descriptions.find(el => el.culture === lang);

        if (text) {
            text.editorState = newEditorState;
        }
        else {
            let newItem = { ...this.state.emptyItem };
            newItem.culture = lang;
            newItem.editorState = newEditorState;
            elementDescription.descriptions.push(newItem);
        }

        this.setState({ elementDescription });
    };

    handleChangeMultiLang = (e, lang) => {
        const { name, value } = e.target;
        let descriptions = this.state.elementDescription.descriptions ? [...this.state.elementDescription.descriptions] : [];
        const index = descriptions.findIndex(el => el.culture === lang);

        if (index !== -1) {
            descriptions[index][name] = value;
        }
        else {
            const newDescription = { culture: lang, description: "", title: "" };
            newDescription[name] = value;

            descriptions.push(newDescription);
        }

        this.setState({
            elementDescription: {
                ...this.state.elementDescription,
                descriptions: descriptions
            }
        });
    }

    changeLanguage = (lang) => {
        this.setState({ lang });
    }

    toggleConfig = () => {
        this.setState({ generateDescriptionCollapse: !this.state.generateDescriptionCollapse });
    }

    render() {
        const currentlang = [];
        this.state.elementDescription.descriptions && this.state.elementDescription.descriptions.map((cdlang, key) => {
            return currentlang.push(cdlang.culture)
        });

        return (
            <div>
                <form ref={this.form}>
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.handleSubmit}>
                            <Card className="small shadow  border-0">
                                <CardHeader className="bg-white border-bottom-1" >
                                    <div>
                                        <i className="fas fa-file-alt mr-1"></i>
                                        <strong>{this.props.selectedEntity.description} <FormattedMessage id="RoomCategorySetupDetails.Description" /> </strong>

                                        {this.props.entity === 'RoomCategory' ?
                                            <CommonHelper help={<FormattedMessage id="RoomCategoryDescription.Help" />} id={'RoomCategoryDescHelp'} />
                                            :
                                            <CommonHelper help={<FormattedMessage id="ComplexDescription.Help" />} id={'ComplexDescriptionHelp'} />
                                        }

                                        <Button color=" btn-host btn-sm mr-1 float-right" >
                                            <i className="fas fa-save" />
                                        </Button>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col className="text-right">
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12">
                                            <TitleAndEditorText
                                                customToolbar={this.props.customToolbar}
                                                data={{
                                                    texts: this.state.elementDescription ? this.state.elementDescription.descriptions : [],
                                                    handleChange: this.handleTitleChange,
                                                    onEditorStateChange: this.onEditorStateChange
                                                }}
                                                changeLanguage={this.changeLanguage}
                                                hasGpt={true}
                                                toggleGptConfig={this.toggleConfig}
                                                currentLang={this.state.lang}
                                                elementDescription={this.state.elementDescription ?? {}}
                                                intl={this.props.intl}
                                                page={this.props.entity === 'RoomCategory' ? 'Room' : 'Hotel'}
                                                isChatGptOpen={this.state.generateDescriptionCollapse}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>

                    </BlockUi>
                </form>
            </div>
        );
    }
}
export default injectIntl(PropertyDescription)