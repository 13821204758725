import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Form, Input, Modal, ModalBody, Nav, NavItem, Row } from 'reactstrap';
import { deleteAPI, getAPI, postAPI } from '../../../Base/API';
import { CommonHelper, ConfirmActionModal, Pagination, StyledCard } from '../../../Base/Common/CommonUIComponents';
import CustomSelect from '../../../Base/Common/CustomSelect';
import { handleNotification } from '../../../Base/Notification';
import TemplatePreview from './TemplatePreview';

class CreateEmail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            error: [],
            templatesMaster: [],
            pageIndex: 0,
            pageSize: 10,
            count: 0,
            modal: false,
            newTemplateName: '',
            newTemplateEventType: null,
            notificationType: [],
            eventTypeList: [],
            copyTemplateId: null,
            hotelId: null,
            hotelList: []
        }
    }

    componentDidMount(){
        this.getHotels();
    }

    getHotels = () => {
        var combo = [];

        getAPI(result => {
            const { data, error } = result;
            if(error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if(data) {
                data.data && data.data.map((item) =>
                    combo.push({ value: item.hotelId, label: item.name2 })
                );
                const defaultHotel = combo.findIndex(h => h.value === '00000000-0000-0000-0000-000000000000');

                if (defaultHotel > -1) {
                    combo.splice(defaultHotel, 1);
                };
            };

            this.setState({ hotelList: combo }, () => this.getMasters());
        }, '/api/hotel/v1?pageSize=2000&pageIndex=0');
    }

    getEventTypes() {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block : false });
                return;
            }
            if (data.response) {
                const selectListEvents = [];

                data.response.map(el => {
                    el.events.map(ev => {
                        selectListEvents.push({ 'value': ev.id, "label": el.type + ' ' + ev.eventType });
                    })
                });

                this.setState({ notificationType: data.response.map(({ type }) => {
                    return({
                        value: type,
                        label: type
                    });
                }), eventTypeList: selectListEvents, block : false });
            }
        }, '/api/notification/Template/v1/NotifyEvents');
    }

    getMasters = () => {
        const { pageIndex, eventTypeList, hotelId } = this.state;

        getAPI(result => {
            const { data, error } = result;
            
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            };

            if(!eventTypeList || eventTypeList.length === 0) {
                this.setState({ templatesMaster: data.response, count: data.count, pageSize: data.pageSize }, () => this.getEventTypes());
                return;
            };
            
            this.setState({ templatesMaster: data.response, block: false });
            return;

        }, `/api/hotel/Stripo/v1/templates/master?${hotelId ? `hotelId=${hotelId}&` : ''}pageIndex=${pageIndex}`);
    }

    toggleModal = (e, copyTemplateId) => {
        this.setState(prev => ({ modal: !prev.modal, copyTemplateId: copyTemplateId }));
    }

    newTemplate = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        const { history } = this.props;
        const { newTemplateName, copyTemplateId, newTemplateEventType } = this.state;

        const body = {
            request:{
                name: newTemplateName,
                eventTypeId: newTemplateEventType
            }
        };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.Errors && data.Errors.length > 0) {
                    handleNotification(data);
                };

                history.push({ pathname: `/EmailCreation/${data.response ? data.response[0]?.id : ''}${copyTemplateId ? `/${copyTemplateId}` : ''}` });
            };
        }, `/api/hotel/Stripo/v1/templates/master`, body);
    }

    handleInput = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e?.target?.value });
    }

    handleSelect = (inputValue, name) => {
        if(name === 'hotelId' && this.state.hotelId !== inputValue?.value){
            this.setState({ hotelId: inputValue?.value, block: true }, () => this.getMasters());
        };
        if (inputValue && this.state[name] !== inputValue.value) {
            this.setState({ [name]: inputValue.value });
        };
    }

    saveTemplateName = (e, id, templateName, callback, eventType) => {
        e.preventDefault();
        
        const copy = [...this.state.templatesMaster];
        const curr = copy.find((t) => t.id === id);

        if(curr.name !== templateName || curr.eventTypeId !== eventType){
            this.setState({ block: true }, () => {
                curr.name = templateName;
                curr.eventTypeId = eventType;

                const body = {
                    request:{
                        id: id,
                        name: templateName,
                        notificationType: curr.notificationType,
                        eventTypeId: eventType
                    }
                };

                postAPI(result => {
                    const { data, error } = result;
                    const errorMessage = [];

                    if (error) {
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ error: errorMessage, block: false }, () => callback());
                        return;
                    }
                    if (data) {
                        if (data.Errors && data.Errors.length > 0) {
                            this.setState({ block: false }, () => {
                                handleNotification(data);
                                callback();
                            });
                        }
                        else{
                            this.setState({ block: false, templatesMaster: copy }, () => {
                                callback();
                                handleNotification(data, null, <FormattedMessage id="AdminUserManagement.Success" />)
                            });
                        }
                    };
                }, '/api/hotel/Stripo/v1/templates/master', body);
            });
        }
        else{
            callback();
        }
    }

    openDeleteTemplateModal = (id) => {
        this.setState({ deleteTemplateModal: id });
    }
    
    toggleDeleteTemplateModal = () => {
        this.setState(prev => ({ deleteTemplateModal: !isNaN(prev.deleteModal) }));;
    }

    deleteTemplate = () => {
        this.setState({ block: true }, () =>{
            const { deleteTemplateModal, templatesMaster } = this.state;

            const copy = [...templatesMaster];

            deleteAPI(result =>{
                const { data, error } = result;
                const errorMessage = [];
    
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                };
                
                this.setState({ error: errorMessage, deleteTemplateModal: false, pageIndex: 0 },
                    () => {
                        handleNotification(data, null, <FormattedMessage id="AdminUserManagement.Success" />);
                        this.getMasters();
                    }
                );

            },`/api/hotel/Stripo/v1/templates/master/${deleteTemplateModal}`);
        });
    }
    
    handlePagination = (value) => {
        const { pageIndex } = this.state;

        this.setState({ pageIndex: pageIndex + value }, () => this.getMasters());
    }

    render() {
        const { block, error, templatesMaster, modal, newTemplateName, newTemplateEventType, eventTypeList, deleteTemplateModal, hotelList, hotelId, count, pageIndex, pageSize } = this.state;
        const { intl } = this.props;
        
        return (
            <StyledCard block={block} error={error} icon="far fa-envelope" title="Stripo.EmailTemplate" help={<FormattedMessage id="Stripo.templateHelp"/>}>
                {deleteTemplateModal ? 
                    <ConfirmActionModal
                        block={block}
                        error={error}
                        text={intl.formatMessage({ id: "Stripo.deleteEmailText" })}
                        toggleModal={this.toggleDeleteTemplateModal}
                        modal={deleteTemplateModal}
                        actionFunction={this.deleteTemplate}
                    />
                :''}
                <Row className="mb-3">
                    <Col className="col-4">
                        <Row>
                            <Col>
                                <FormattedMessage id="HotelLicenses.Hotel"/>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <CustomSelect
                                    isClearable
                                    isSearchable
                                    options={hotelList}
                                    onChange={(e) => this.handleSelect(e, 'hotelId')}
                                    value={hotelId ? hotelList.find(h => h.value === hotelId) : ''}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col className="text-right">
                        <button className="btn btn-host btn-sm mb-1 mr-2" onClick={this.toggleModal}>
                            <i className="fas fa-plus"/>
                        </button>
                    </Col>
                </Row>
                <Modal isOpen={modal} toggle={this.toggleModal} style={{ display: 'flex', height: '90%', alignItems: 'center', justifyContent: 'center', padding: '0 20rem' }} >
                    <ModalBody className='p-4' style={{ borderRadius: '8px' }}>
                        <Form onSubmit={this.newTemplate}>
                            <Row>
                                <Col className="text-right">
                                    <Button type="submit" className="btn btn-sm btn-host">
                                        <i className="fas fa-save"/>
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <b><FormattedMessage id="Stripo.name" /></b>
                                </Col>
                            </Row>
                            <Row className='mt-1'>
                                <Col className='text-center'>
                                    <Input
                                        type='text'
                                        value={newTemplateName}
                                        name="newTemplateName"
                                        required
                                        autoFocus
                                        onChange={this.handleInput}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Row>
                                        <Col>
                                            <b><FormattedMessage id="Stripo.eventType"/></b>
                                        </Col>
                                    </Row>
                                    <Row className='mt-1'>
                                        <Col>
                                            <CustomSelect
                                                required={true}
                                                value={newTemplateEventType ? eventTypeList.find(el => el.value === newTemplateEventType) : null}
                                                options={eventTypeList}
                                                onChange={e => this.handleSelect(e, 'newTemplateEventType')}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
                {templatesMaster?.map((email, k) =>
                    <Row key={k}>
                        <Col className='my-2'>
                            <TemplatePreview
                                history={this.props.history}
                                templateName={email.name}
                                id={email.id}
                                eventTypeList={eventTypeList}
                                eventType={email.eventTypeId}
                                languages={email.templates?.map(({ language }) => language?.split('-')[1]?.toLowerCase())}
                                openDeleteTemplateModal={this.openDeleteTemplateModal}
                                saveTemplateName={this.saveTemplateName}
                                toggleModal={this.toggleModal}
                                intl={intl}
                            />
                        </Col>
                    </Row>
                )}
                <>
                {pageSize < count ?
                    <Row className="my-4">
                        <Col className="text-right mr-3">
                            <Pagination
                                isPrevDisabled={pageIndex === 0}
                                isNextDisabled={(pageIndex * pageSize) >= count}
                                currentPage={pageIndex + 1}
                                handlePrevButton={() => this.handlePagination(-1)}
                                handleNextButton={() => this.handlePagination(1)}
                            />
                        </Col>
                    </Row>
                :''}
                </>
            </StyledCard>
        )
  }
}

export default injectIntl(CreateEmail);