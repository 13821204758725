import React, { Component } from 'react';
import { Badge, Button, Col, Row } from 'reactstrap';
import { postAPI, getAPI } from '../Base/API';
import { StyledCard } from '../Base/Common/CommonUIComponents';
import * as qs from 'query-string';
import { handleNotification } from "../Base/Notification";
import stripebutton from '../../img/light-on-light@2x.png';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { getCurrencyCode, getPaymentGatewayType, getPaymentModes } from '../Base/Common/ReferenceDataFunctions';
import { RenderPaymentGatewayValidPaymentModes } from './RenderPaymentGatewayValidPaymentModes';
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import CustomToolTip from '../../Utils/CustomToolTip';
import PaymentGatewayDesk from './PaymentGatewayDesk'
import PaymentGatewayMobile from './PaymentGatewayMobile';

export class PaymentGateway extends Component {
    static displayName = PaymentGateway.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        const paymentGatewayTypes = getPaymentGatewayType().map(pg => pg.value);
        this.state = {
            block: false,
            paymentGatewayTypes: paymentGatewayTypes,
            paymentGatewayLogos: getPaymentGatewayType().map(pg => pg.logo),
            paymentGateways: paymentGatewayTypes.map(this.getEmptyPaymentGateway),
            stripePaymentModes: getPaymentModes().filter(pm => pm.value === 'CreditCard'),
            unicrePaymentModes: getPaymentModes().filter(pm => pm.value === 'CreditCard' || pm.value === 'MbWay' || pm.value === 'SibsMultibanco'),
            currency: 'EUR',
            ifThenPayValidCurrencies: [getCurrencyCode()[0].value],
            ifThenPayIsDisplay: 'initial',
            isMbEntityRequiredOnUnicre: false,
        };
        this.savePaymentGateway.bind(this);
        this.setPaymentStripePaymentStatus.bind(this);
        this.paymentGatewayForm = React.createRef();
    }

    componentDidMount() {
        const parsed = qs.parse(this.props.location.search);
        let code = parsed.code;
        let scope = parsed.scope;

        if (code != null && scope != null) {
            this.setState({ block: true });

            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="PaymentGateway.StripeConnected" />, <FormattedMessage id="General.Success" />);
                    }
                }
                this.getPaymentGateways();
            }, '/api/Rate/Payment/v1/CompleteAccountCreation?code=' + code + '&scope=' + scope);
        }
        else {
            this.getPaymentGateways();
        }
        this.getHotelData();
    }

    getPaymentGateways() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const { paymentGatewayTypes } = this.state;
                const paymentGateways = []

                paymentGatewayTypes.forEach(pg => {
                    const paymentGatewayResponse = data.response.find(resp => resp.gatewayType === pg);
                    if (paymentGatewayResponse) {
                        if (!paymentGatewayResponse.validPaymentModes) {
                            paymentGatewayResponse.validPaymentModes = [];
                        }
                        if (!paymentGatewayResponse.paymentGatewayMultilanguals) {
                            paymentGatewayResponse.paymentGatewayMultilanguals = [];
                        }
                        paymentGatewayResponse.paymentGatewayMultilanguals.forEach(pm => {
                            const blocksFromHtml = htmlToDraft(pm.paymentDesc);
                            const { contentBlocks, entityMap } = blocksFromHtml;
                            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                            pm.editorState = EditorState.createWithContent(contentState);
                        });
                        paymentGateways.push(paymentGatewayResponse);
                    }
                    else {
                        paymentGateways.push(this.getEmptyPaymentGateway(pg));
                    }
                });

                const unicreIdx = 2;
                this.setState({ error: errorMessage, block: false, paymentGateways: paymentGateways, isMbEntityRequiredOnUnicre: this.getIsMbEntityRequiredOnUnicre(paymentGateways, unicreIdx) });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/Rate/Payment/v1/payment/gateway');
    }

    getHotelData = () => {
        getAPI(result => {
            const { data } = result;
            if (data) {
                if (this.state.ifThenPayValidCurrencies.some(curr => curr === data.defaultCurrencyCode)) {
                    this.setState({ currencyCode: data.defaultCurrencyCode, ifThenPayIsDisplay: 'initial' });
                }
                else {
                    this.setState(prevState => ({ currencyCode: data.defaultCurrencyCode, ifThenPayIsDisplay: 'none', unicrePaymentModes: prevState.stripePaymentModes }));
                }
            }
        }, '/api/hotel/v1/GetUserHotel');
    }

    getEmptyPaymentGateway = (pg) => {
        const paymentGateway = {
            active: false,
            gatewayType: pg,
            code: pg,
            validPaymentModes: [],
            paymentGatewayMultilanguals: []
        }
        return paymentGateway;
    }

    disconnectStripe = () => {
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                if (data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="PaymentGateway.StripeDisconnected" />, <FormattedMessage id="General.Success" />);

                    const { paymentGateways } = this.state;
                    paymentGateways[0] = data.response[0];
                    this.setState({ paymentGateways, block: false });
                }
                else {
                    this.setState({ block: false });
                }
            }
            else {
                this.setState({ block: false });
            }
            this.getPaymentGateways();
        }, '/api/Rate/Payment/v1/RevokeAccountConnection');
    }

    setPaymentStripePaymentStatus = (status, idx) =>  {
        const { paymentGateways } = this.state;
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="PaymentGateway.StatusUpdated" />, <FormattedMessage id="General.Success" />);
                    if (!data.response[0].validPaymentModes) {
                        data.response[0].validPaymentModes = [];
                    }
                    if (!data.response[0].paymentGatewayMultilanguals) {
                        data.response[0].paymentGatewayMultilanguals = [];
                    }
                    data.response[0].paymentGatewayMultilanguals.forEach(pm => {
                        const blocksFromHtml = htmlToDraft(pm.paymentDesc);
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        pm.editorState = EditorState.createWithContent(contentState);
                    });

                    paymentGateways[idx] = data.response[0];
                    this.setState({ paymentGateways, error: errorMessage, block: false }, _ => {
                        //Update gateways when is active and check if is different of iban and custom
                        if (status && paymentGateways[idx].gatewayType !== getPaymentGatewayType()[1].value && paymentGateways[idx].gatewayType !== getPaymentGatewayType()[4].value) {
                            this.getPaymentGateways()
                        }
                    });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/Payment/v1/Status?status=${status}&code=${paymentGateways[idx].gatewayType}`);

    }

    savePaymentGateway = (idx) => {
        const { paymentGateways } = this.state;
        if (!this.paymentGatewayForm.current.reportValidity()) {
            return;
        }

        paymentGateways[idx].paymentGatewayMultilanguals.forEach(ml => {
            ml.paymentDesc = draftToHtml(convertToRaw(ml.editorState.getCurrentContent()));
        });

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false, paymentGateways });
                }
                else if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="PaymentGateway.SuccessSavePaymentGateway" />, <FormattedMessage id="General.Success" />);
                    const { paymentGateways } = this.state;
                    if (!data.response[0].validPaymentModes) {
                        data.response[0].validPaymentModes = [];
                    }
                    if (!data.response[0].paymentGatewayMultilanguals) {
                        data.response[0].paymentGatewayMultilanguals = [];
                    }
                    data.response[0].paymentGatewayMultilanguals.forEach(pm => {
                        const blocksFromHtml = htmlToDraft(pm.paymentDesc);
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        pm.editorState = EditorState.createWithContent(contentState);
                    });
                    paymentGateways[idx] = data.response[0];

                    this.setState({ error: errorMessage, block: false, paymentGateways });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/Rate/Payment/v1/payment/gateway', paymentGateways[idx]);
    }

    handleData = (evt, idx) => {
        const { paymentGateways } = this.state;
        if (evt && evt.target) {
            const { name, value } = evt.target;
            paymentGateways[idx][name] = value;
            this.setState({ paymentGateways });
        }
    }

    handleComboData = (combo, idx, name) => {
        const { paymentGateways } = this.state;
        paymentGateways[idx][name] = combo && combo.value;
        this.setState({ paymentGateways });
    }

    updateValidPaymentModes = (evt, idx) => {
        const { paymentGateways } = this.state;

        if (evt && evt.target) {
            this.manageValidPaymentModes(evt, idx, paymentGateways);

            this.setState({ paymentGateways });
        }
    }

    updateUnicreValidPaymentModes = (evt, idx) => {
        const { paymentGateways } = this.state;

        if (evt && evt.target) {
            this.manageValidPaymentModes(evt, idx, paymentGateways);

            this.setState({ paymentGateways, isMbEntityRequiredOnUnicre: this.getIsMbEntityRequiredOnUnicre(paymentGateways, idx) });
        }
    }

    getIsMbEntityRequiredOnUnicre = (paymentGateways, unicreIdx) => {
        const { unicrePaymentModes } = this.state;

        //unicrePaymentModes[2].value = 'SibsMultibanco'
        return paymentGateways[unicreIdx].validPaymentModes.some(pmd => pmd === unicrePaymentModes[2]?.value);
    }

    manageValidPaymentModes = (evt, idx, paymentGateways) => {
        const paymentMode = evt.target.name;
        if (evt.target.checked) {
            //push
            paymentGateways[idx].validPaymentModes.push(paymentMode);
        }
        else {
            //pop
            paymentGateways[idx].validPaymentModes.splice(paymentGateways[idx].validPaymentModes.indexOf(paymentGateways[idx].validPaymentModes.find(pm => pm === paymentMode)), 1);
        }
    }

    handleTextChange = (evt, idx, lang) => {
        if (evt && evt.target) {
            const { paymentGateways } = this.state;
            const { name, value } = evt.target;
            const text = paymentGateways[idx].paymentGatewayMultilanguals.find(el => el.cultureCode === lang);

            if (text) {
                text[name] = value;
            }
            else {
                const newText = { cultureCode: lang, buttonDesc: "", paymentDesc: "" };
                newText[name] = value;

                paymentGateways[idx].paymentGatewayMultilanguals.push(newText);
            }

            this.setState({ paymentGateways });
        }
    }

    handleEditorStateChange = (newEditorState, idx, lang) => {
        const { paymentGateways } = this.state;
        const text = paymentGateways[idx].paymentGatewayMultilanguals.find(el => el.cultureCode === lang);

        if (text) {
            text.editorState = newEditorState;
        }
        else {
            const newText = { cultureCode: lang, buttonDesc: "", paymentDesc: "", editorState: newEditorState };

            paymentGateways[idx].paymentGatewayMultilanguals.push(newText);
        }

        this.setState({ paymentGateways });
    };

    onlyHasBankTransfer = () => {
        const { paymentGateways } = this.state;
        return !paymentGateways.some(pg => pg.code !== getPaymentGatewayType()[1].value && pg.active);
    }

    changePaymentMode = (paymentMode) => {
        if(this.state.paymentMode === paymentMode){
            this.setState({ paymentMode: null });
        }else{
            this.setState({ paymentMode });
        }
        
    }

    render() {
        const { paymentGatewayTypes, paymentGatewayLogos, paymentGateways, stripePaymentModes, unicrePaymentModes, ifThenPayIsDisplay, isMbEntityRequiredOnUnicre, currency } = this.state;

        const stripeIdx = 0;
        const bankTransfeIdx = 1;
        const unicreIdx = 2;
        const paybyrdIdx = 3;
        const customIdx = 4;
        const ifThenPayIdx = 5;
        const redsysIdx = 6;
        const sibsIdx = 7;
        const heyCardIdx = 8;

        const unicreProviders = [
            {
                value: 'UNICRE',
                label: <FormattedMessage id="PaymentGateway.Unicre" />
            },
            {
                value: 'CGD',
                label: <FormattedMessage id="PaymentGateway.Cgd" />
            }
        ]

        const mbValidatyDaysOptions = [
            {
                value: 1,
                label: '1'
            },
            {
                value: 2,
                label: '2'
            },
            {
                value: 3,
                label: '3'
            },
            {
                value: 4,
                label: '4'
            },
            {
                value: 5,
                label: '5'
            },
            {
                value: 6,
                label: '6'
            },
            {
                value: 7,
                label: '7'
            }
        ];

        function RenderPaymentStatus(props) {
            const p = props.paymentGateways && props.paymentGateways.find(el => el.gatewayType === props.channel);

            return (
                <>
                    {
                        p !== undefined && p !== null && p.id !== undefined && p.id !== null ?
                            <>
                                <i className="color-host fas fa-check" />
                                <br />
                                {
                                    p.active === true ?
                                        <Badge className="btn-host ">
                                            <FormattedMessage id="PaymentGateway.Active" />
                                        </Badge>
                                        :
                                        <Badge color="secondary">
                                            <FormattedMessage id="PaymentGateway.Disabled" />
                                        </Badge>
                                }
                            </>
                            :
                            <>
                                <span />
                                <br />
                            </>
                    }
                </>
            )
        }

        function RenderStripeDetails(props) {
            let p = props.paymentGateways && props.paymentGateways.find(el => el.gatewayType === paymentGatewayTypes[0]);
            
            return (
                <div className={`${global.isMobile ? 'mt-2' : ''}`}>
                    {p === undefined || p === null || !p.userId ?
                        <>
                            <Row>
                                {!global.isMobile ?
                                    <Col>
                                        <h5><FormattedMessage id="PaymentGateway.StripeConnection" /></h5>
                                    </Col>
                                :
                                    <Col className="d-flex justify-content-between align-items-center mb-2">
                                        <h5><FormattedMessage id="PaymentGateway.StripeConnection" /></h5>
                                        <div className='d-flex flex-row'>
                                            <span>
                                                <Button className="btn btn-host btn-sm mr-2" onClick={props.disconnectStripe} id="revokeTooltip">
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                                <CustomToolTip placement="bottom" target="revokeTooltip">
                                                    <FormattedMessage id="PaymentGateway.RevokeConnection" />
                                                </CustomToolTip>
                                            </span>
                                            <Button className="btn btn-host btn-sm mr-2" onClick={() => props.savePaymentGateway(stripeIdx)}>
                                                <i className="fas fa-save" />
                                            </Button>
                                            <Button className="btn btn-host btn-sm" onClick={() => props.setPaymentStripePaymentStatus(!p.active, stripeIdx)}>
                                                <i className="fas fa-power-off" />
                                            </Button>
                                        </div>
                                    </Col>
                                }
                            </Row>
                            <hr className="my-1" />
                            <Row className="mb-2">
                                <Col>
                                    <a href={`https://dashboard.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENTID}&scope=read_write&redirect_uri=${window.location.origin}/PaymentGateway`}><img height="30px" src={stripebutton}></img></a>
                                </Col>
                            </Row>
                        </>
                        :
                        <>
                            <Row>
                                {!global.isMobile ?
                                    <Col>
                                        <h5><FormattedMessage id="PaymentGateway.StripeConnection" /></h5>
                                    </Col>
                                :
                                    <Col className="d-flex justify-content-between align-items-center mb-2">
                                        <h5><FormattedMessage id="PaymentGateway.StripeConnection" /></h5>
                                        <div className='d-flex flex-row'>
                                            <span>
                                                <Button className="btn btn-host btn-sm mr-2" onClick={props.disconnectStripe} id="revokeTooltip">
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                                <CustomToolTip placement="bottom" target="revokeTooltip">
                                                    <FormattedMessage id="PaymentGateway.RevokeConnection" />
                                                </CustomToolTip>
                                            </span>
                                            <Button className="btn btn-host btn-sm mr-2" onClick={() => props.savePaymentGateway(stripeIdx)}>
                                                <i className="fas fa-save" />
                                            </Button>
                                            <Button className="btn btn-host btn-sm" onClick={() => props.setPaymentStripePaymentStatus(!p.active, stripeIdx)}>
                                                <i className="fas fa-power-off" />
                                            </Button>
                                        </div>
                                    </Col>
                                }
                            </Row>
                            <hr className="my-1" />
                            <Row className="mb-2">
                                <Col className="col-4">
                                    <FormattedMessage id="PaymentGateway.StripeUserId" />
                                </Col>
                                <Col className="col-8">
                                    {p.userId}
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-4">
                                    <FormattedMessage id="PaymentGateway.Status" />
                                </Col>
                                <Col className="col-8">
                                    {p.active === true ? <FormattedMessage id="generic.active" /> : <FormattedMessage id="generic.inactive" />}
                                </Col>
                            </Row>
                            <RenderPaymentGatewayValidPaymentModes paymentMode={props.paymentMode} validPaymentModes={stripePaymentModes} paymentGateway={p} idx={stripeIdx} updateValidPaymentModes={props.updateValidPaymentModes} />
                            {!global.isMobile &&
                                <Row className="mb-2">
                                    <Col>
                                        <span>
                                            <Button className="btn btn-host btn-sm mr-2" onClick={props.disconnectStripe} id="revokeTooltip">
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                            <CustomToolTip placement="bottom" target="revokeTooltip">
                                                <FormattedMessage id="PaymentGateway.RevokeConnection" />
                                            </CustomToolTip>
                                        </span>
                                        <Button className="btn btn-host btn-sm mr-2" onClick={() => props.savePaymentGateway(stripeIdx)}>
                                            <i className="fas fa-save" />
                                        </Button>
                                        <Button className="btn btn-host btn-sm" onClick={() => props.setPaymentStripePaymentStatus(!p.active, stripeIdx)}>
                                            <i className="fas fa-power-off" />
                                        </Button>
                                    </Col>
                                </Row>
                            }
                        </>
                    }
                </div>
            )
        }

        const help = <div><FormattedHTMLMessage id="PaymentGateway.Help" /></div>

        return (
            <StyledCard icon={'fas fa-credit-card'}  values={{ currency: currency }} title={"PaymentGateway.PaymentGateways"} id="PaymentGatewaysHelp" help={help} error={this.state.error} block={this.state.block} >
                {!global.isMobile ?
                    <PaymentGatewayDesk
                        paymentMode={this.state.paymentMode}
                        paymentGatewayTypes={paymentGatewayTypes}
                        paymentGatewayLogos={paymentGatewayLogos}
                        paymentGateways={paymentGateways}
                        stripePaymentModes={stripePaymentModes}
                        unicrePaymentModes={unicrePaymentModes}
                        ifThenPayIsDisplay = {ifThenPayIsDisplay}
                        isMbEntityRequiredOnUnicre={isMbEntityRequiredOnUnicre}
                        changePaymentMode={this.changePaymentMode}
                        bankTransfeIdx={bankTransfeIdx}
                        unicreIdx={unicreIdx}
                        stripeIdx={stripeIdx}
                        paybyrdIdx={paybyrdIdx}
                        customIdx={customIdx}
                        ifThenPayIdx={ifThenPayIdx}
                        redsysIdx={redsysIdx}
                        sibsIdx={sibsIdx}
                        heyCardIdx={heyCardIdx}
                        disconnectStripe={this.disconnectStripe}
                        setPaymentStripePaymentStatus={this.setPaymentStripePaymentStatus}
                        savePaymentGateway={this.savePaymentGateway}
                        updateValidPaymentModes={this.updateValidPaymentModes}
                        paymentGatewayForm={this.paymentGatewayForm}
                        onlyHasBankTransfer={this.onlyHasBankTransfer}
                        handleData={this.handleData}
                        handleComboData={this.handleComboData}
                        mbValidatyDaysOptions={mbValidatyDaysOptions}
                        unicreProviders={unicreProviders}
                        updateUnicreValidPaymentModes={this.updateUnicreValidPaymentModes}
                        handleTextChange={this.handleTextChange}
                        handleEditorStateChange={this.handleEditorStateChange}
                        RenderStripeDetails={RenderStripeDetails}
                        RenderPaymentStatus={RenderPaymentStatus}
                    />
                :
                    <PaymentGatewayMobile
                        paymentMode={this.state.paymentMode}
                        paymentGatewayTypes={paymentGatewayTypes}
                        paymentGatewayLogos={paymentGatewayLogos}
                        paymentGateways={paymentGateways}
                        stripePaymentModes={stripePaymentModes}
                        unicrePaymentModes={unicrePaymentModes}
                        ifThenPayIsDisplay = {ifThenPayIsDisplay}
                        isMbEntityRequiredOnUnicre={isMbEntityRequiredOnUnicre}
                        changePaymentMode={this.changePaymentMode}
                        bankTransfeIdx={bankTransfeIdx}
                        unicreIdx={unicreIdx}
                        stripeIdx={stripeIdx}
                        paybyrdIdx={paybyrdIdx}
                        customIdx={customIdx}
                        ifThenPayIdx={ifThenPayIdx}
                        redsysIdx={redsysIdx}
                        sibsIdx={sibsIdx}
                        heyCardIdx={heyCardIdx}
                        disconnectStripe={this.disconnectStripe}
                        setPaymentStripePaymentStatus={this.setPaymentStripePaymentStatus}
                        savePaymentGateway={this.savePaymentGateway}
                        updateValidPaymentModes={this.updateValidPaymentModes}
                        paymentGatewayForm={this.paymentGatewayForm}
                        onlyHasBankTransfer={this.onlyHasBankTransfer}
                        handleData={this.handleData}
                        handleComboData={this.handleComboData}
                        mbValidatyDaysOptions={mbValidatyDaysOptions}
                        unicreProviders={unicreProviders}
                        updateUnicreValidPaymentModes={this.updateUnicreValidPaymentModes}
                        handleTextChange={this.handleTextChange}
                        handleEditorStateChange={this.handleEditorStateChange}
                        RenderStripeDetails={RenderStripeDetails}
                        RenderPaymentStatus={RenderPaymentStatus}
                    />
                }
            </StyledCard>
        );
    }
}
export default injectIntl(PaymentGateway);