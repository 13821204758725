import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col, Input, Label, CardHeader, Button } from 'reactstrap';
import { postAPI, putAPI } from '../../Base/API';
import { SelectHotel } from "../../Base/Common/CommonUIComponents";
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from "../../Base/Notification";
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";
import moment from 'moment';

export class UpgradeRequestsDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            selectedRequest: this.props.requestSelected ? this.props.requestSelected : {}
        }
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => (
            {
                selectedRequest: {
                    ...prevState.selectedRequest,
                    [name]: combo ? combo.value : null
                }
            })
        );
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            selectedRequest: {
                ...this.state.selectedRequest,
                [name]: value
            }
        })
    }

    handleSave = () => {
        this.setState({ block: true })
        
        putAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="UpgradeRequests.RequestUpdated" />, <FormattedMessage id="General.Success" />);
                    this.props.updateTable(data.response[0]);
                    this.props.toggleModal();
                }
            }
        }, '/api/hotel/License/v1/upgradeAccountRequest', JSON.stringify({ request: { ...this.state.selectedRequest } }));
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} fade={false} className="modal-lg">
                <ModalHeader toggle={this.props.toggleModal}>
                    {this.props.editMode ? <FormattedMessage id="UpgradeRequests.EditRequest" /> : <FormattedMessage id="UpgradeRequests.CreateRequest" />}
                </ModalHeader>
                <ModalBody className="modal-lg px-0 pb-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Card className="shadow border-0 h-100">
                            <CardHeader>
                                <Button color=" btn-host btn-sm float-right" onClick={this.handleSave}>
                                    <i className="fas fa-save" />
                                </Button>
                            </CardHeader>
                            <CardBody>
                                <form ref={this.hotelLicenseForm}>
                                    <Row className="pt-2">
                                        <Col className="col-2">
                                            <Label for="hotelId">
                                                <FormattedMessage id="UpgradeRequests.Hotel" />
                                            </Label>
                                        </Col>
                                        <Col className="col-4">
                                            <SelectHotel
                                                name={'hotelId'}
                                                icon={'fas fa-hotel'}
                                                onChangeFunc={this.handleSelect.bind(this, 'hotelId')}
                                                value={this.state.selectedRequest.hotelId}
                                                isDisabled={this.props.editMode}
                                            />
                                        </Col>
                                        <Col className="col-2">
                                            <Label for="operationMode">
                                                <FormattedMessage id="UpgradeRequests.Status" />
                                            </Label>
                                        </Col>
                                        <Col className="col-4">
                                            <CustomSelect
                                                name={'status'}
                                                options={this.props.statusOptions}
                                                icon={'fas fa-cog'}
                                                onChange={this.handleSelect.bind(this, 'status')}
                                                value={this.props.statusOptions && this.props.statusOptions.find(status => status.value == this.state.selectedRequest.status)}
                                                isClearable
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="pt-2">
                                        <Col className="col-2">
                                            <Label for="hotelId">
                                                <FormattedMessage id="UpgradeRequests.RequestedUser" />
                                            </Label>
                                        </Col>
                                        <Col className="col-4">
                                            <Input
                                                type="text"
                                                readOnly
                                                value={this.state.selectedRequest.requestedUser}
                                            />
                                        </Col>
                                        <Col className="col-2">
                                            <Label for="operationMode">
                                                <FormattedMessage id="UpgradeRequests.RequestedAt" />
                                            </Label>
                                        </Col>
                                        <Col className="col-4">
                                            <Input
                                                type="text"
                                                readOnly
                                                value={moment(this.state.selectedRequest.requestedAt).format("YYYY-MM-DD")}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="pt-2">
                                        <Col className="col-2">
                                            <Label for="hotelId">
                                                <FormattedMessage id="UpgradeRequests.Notes" />
                                            </Label>
                                        </Col>
                                        <Col className="col-10">
                                            <Input
                                                type="textarea"
                                                name="notes"
                                                onChange={(e) => this.handleChange(e)}
                                                value={this.state.selectedRequest.notes}
                                            />
                                        </Col>
                                    </Row>
                                </form>
                            </CardBody>
                        </Card>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(UpgradeRequestsDetails)