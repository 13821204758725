import React, { Component } from 'react';
import { Row, Col, Button, Card, CardBody, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { handleNotification } from '../../Base/Notification';
import CustomSelect from '../../Base/Common/CustomSelect';
import { postAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";

export class ConfigLicenseDetail extends Component {

    constructor(props) {
        super(props);
        this.handleSave = this.handleSave.bind(this);
        this.licenseForm = React.createRef();
        this.state = {
            block: false,
            modalData: this.props.modalData
        };
    }

    handleFieldChange = (name, evt) => {
        const value = evt ? evt.target.value : null;
        this.setState(prevState => (
            {
                modalData: {
                    ...prevState.modalData,
                    [name]: value
                }
            }));
    }

    handleIntFieldChange = (name, evt) => {
        const value = evt ? parseInt(evt.target.value): null;
        this.setState(prevState => (
            {
                modalData: {
                    ...prevState.modalData,
                    [name]: value
                }
            }));
    }

    handleSave() {        
        if (this.licenseForm.current.reportValidity()) {
            postAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ block: isLoading });
                if (error) {
                    const errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="ConfigLicenses.SaveMessageSuccess" />, <FormattedMessage id="General.Success" />);
                    this.props.addOrUpdateLicense(data.license);
                    this.props.toggleModal();
                }
            }, '/api/hotel/License/v1/license', JSON.stringify(this.state.modalData));
        }        
    }

    handleSelectChange = (name, combo) => {
        this.setState(prevState => ( {
            modalData: {
                ...prevState.modalData,
                [name]: combo ? combo.value : null
            }
        }));
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} fade={false} className="modal-lg">
                <ModalHeader toggle={this.props.toggleModal}>
                    {this.props.title}
                </ModalHeader>
                <ModalBody className="modal-lg p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Card className="shadow border-0 h-100">
                            <CardBody>
                                <form ref={this.licenseForm}>
                                    <Row>
                                        <Col className="text-right">
                                            <Button color=" btn-host btn-sm float-right" onClick={this.handleSave}>
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="py-2">
                                        <Col className="col-2">
                                            <Label for="name">
                                                <FormattedMessage id="ConfigLicense.Name" />
                                            </Label>
                                        </Col>
                                        <Col className="col-4">
                                            <FormattedMessage id="ConfigLicense.EnterName">
                                                {
                                                    placeholder => <Input type="Text" name="name" className="text-sm " required placeholder={placeholder} value={this.state.modalData.name} onChange={this.handleFieldChange.bind(this, 'name')} />
                                                }
                                            </FormattedMessage>
                                        </Col>
                                        <Col className="col-3">
                                            <Label for="numChannels">
                                                <FormattedMessage id="ConfigLicense.NumChannels" />
                                            </Label>
                                        </Col>
                                        <Col className="col-3">
                                            <Input type="number" name="numChannels" id="numChannels" min="0" className="text-sm " required value={this.state.modalData.numChannels} onChange={this.handleIntFieldChange.bind(this, 'numChannels')} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-2">
                                            <Label>
                                                <FormattedMessage id="ConfigLicense.LicenseLevel" />
                                            </Label>
                                        </Col>
                                        <Col className="col-4">
                                            <CustomSelect name="licenseLevel" className="text-sm" required options={this.props.licenseLevels} placeholder={<FormattedMessage id="ConfigLicense.LicenseLevel" />} value={this.state.modalData.licenseLevel && this.props.licenseLevels.find(level => level.value === this.state.modalData.licenseLevel)} onChange={this.handleSelectChange.bind(this, 'licenseLevel')} />
                                        </Col>
                                        <Col className="col-3">
                                            <Label for="numRooms">
                                                <FormattedMessage id="ConfigLicense.NumRooms" />
                                            </Label>
                                        </Col>
                                        <Col className="col-3">
                                            <Input type="number" name="numRooms" id="numRooms" min="0" className="text-sm " required value={this.state.modalData.numRooms} onChange={this.handleIntFieldChange.bind(this, 'numRooms')} />
                                        </Col>
                                    </Row>
                                </form>
                            </CardBody>
                            </Card>
                    </BlockUi>
                </ModalBody>
            </Modal>
        )
    }
}