import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Card, CardBody, Row, Col, FormGroup, Label, Input, Button, FormFeedback, FormText, CardHeader, Form } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { ErrorAlert } from "../Common/ErrorAlert";
import { putAPI } from "../Base/API";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';
import { reservationStatus } from '../Base/Common/CommonUIComponents';

class UpdateProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            firstName: this.props.data.reservationProfile[0].givenName,
            lastName: this.props.data.reservationProfile[0].surname,
            email: this.props.data.reservationProfile[0].email,
        };
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }
    
    updateProfile = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var body = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email
        }

        putAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    var reservation = { ...this.props.data };
                    reservation.reservationProfile[0].email = data.response[0].email;
                    reservation.reservationProfile[0].givenName = data.response[0].givenName;
                    reservation.reservationProfile[0].surname = data.response[0].surname;
                    if (reservation.status === reservationStatus[0].value) {
                        reservation.status = reservationStatus[1].value;
                    }
                    if (this.props.data.channelConfigName.toUpperCase() === 'HEYBE') {
                        reservation.communicationStatus = null;
                    }

                    this.props.updateTable && this.props.updateTable(reservation, 'profile');
                    this.props.updateData(reservation);
                    handleNotification(data, <FormattedMessage id="ReservationDetail.ProfileUpdated" />, <FormattedMessage id="General.Success" />);
                    this.props.toggle('updateProfileModal');
                }
                this.setState(({ block: false }));
            }
        }, `/api/Price/Reservation/v1/reservation/${this.props.data.id}/${this.props.data.channelConfigName.toUpperCase()}/${this.props.data.reservationProfile[0].id}/contacts`, body);
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={() => this.props.toggle('updateProfileModal')} className="modal-lg">
                <ModalHeader toggle={() => this.props.toggle('updateProfileModal')}>
                    <FormattedMessage id="ReservationDetail.UpdateProfile" />
                </ModalHeader>

                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.updateProfile}>
                            <Card className="shadow border-0 p-0">
                                <CardBody className="m-0">
                                    <Row className="bg-white mb-2">
                                        <Col className="text-right">
                                            <Button type="submit" className="btn btn-sm btn-host float-right" >
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="firstName" sm={4}> <FormattedMessage id="UpdateProfile.FirstName" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="text"
                                                        name="firstName"
                                                        maxLength="50"
                                                        value={this.state.firstName}
                                                        onChange={(e) => this.handleChange(e)}
                                                        placeholder={this.props.intl.formatMessage({ id: "UpdateProfile.FirstName" })}
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="lastName" sm={4}> <FormattedMessage id="UpdateProfile.LastName" /> </Label>
                                                <Col sm={8}>
                                                    <Input
                                                        type="text"
                                                        name="lastName"
                                                        maxLength="50"
                                                        value={this.state.lastName}
                                                        onChange={(e) => this.handleChange(e)}
                                                        placeholder={this.props.intl.formatMessage({ id: "UpdateProfile.LastName" })}
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label for="email" sm={2}> <FormattedMessage id="UpdateProfile.Email" /> </Label>
                                                <Col sm={10}>
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        maxLength="200"
                                                        value={this.state.email}
                                                        onChange={(e) => this.handleChange(e)}
                                                        placeholder={this.props.intl.formatMessage({ id: "UpdateProfile.Email" })}
                                                        required
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(UpdateProfile);