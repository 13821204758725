import React, { Component } from 'react';
import { Button, Col, Label, Form, Row, FormGroup, Input, Modal, ModalBody } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { BlankCard } from '../Base/Common/CommonUIComponents';
import { handleNotification } from '../Base/Notification';
import { getAPI, postAPI } from '../Base/API';
import { ErrorAlert } from "../Common/ErrorAlert";
import Authorization from '../../components/Base/Authorization';
import CustomSelect from '../Base/Common/CustomSelect';

class EmailReservationWhiteListModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            prewhitelist: {
                id: this.props.selectedwhiteList?.id,
                source: this.props.selectedwhiteList?.source,
                prompt: this.props.selectedwhiteList?.prompt,
                email: this.props.selectedwhiteList?.email,
                mailExtractionTemplateId: this.props.selectedwhiteList?.mailExtractionTemplateId
            }
        }
    }

    componentDidMount() {
        this.getMailExtraction();
    }

    getMailExtraction = () => {
        this.setState({ blockExtractor: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockExtractor: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ blockExtractor: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    const extractors = data.response.map(e => ({ value: e.id, label: e.code }));

                    this.setState({ extractorList: extractors, blockExtractor: false, error: errorMessage });
                    return;
                };

                this.setState(({ blockExtractor: false }));
            }
            else this.setState(({ blockExtractor: false }));
        }, `/api/Price/EmailReservation/EmailReservation/v1/Extractor`);
    }

    createEditWhiteList = (e) => {
        e.preventDefault();
        const { prewhitelist } = this.state;

        this.setState({ block: true }, () =>
            postAPI(result => {
                const { data, error } = result;
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ block: false });
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="EmailResWhiteList.WhiteListSavedSuccessfully" />, <FormattedMessage id="generic.success" />);

                        this.props.toggleModal();
                    }
                }
            }, `/api/hotel/EmailReservation/v1/Config`, { request: prewhitelist }));

    }

    handleSelect = (name, combo) => {
        this.setState({
            prewhitelist: {
                ...this.state.prewhitelist,
                [name]: combo ? combo.value : null
            }
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            prewhitelist: {
                ...this.state.prewhitelist,
                [name]: value
            }
        });
    }

    handleEmailChange = (e) => {
        const { prewhitelist } = this.state;
        const { name, value } = e.target;

        if (prewhitelist.email && prewhitelist.email.length > 0) {
            prewhitelist.email[name] = value;
        }
        else {
            prewhitelist.email = [value];
        }

        this.setState({ prewhitelist });
    }

    addNewEmail = () => {
        const { prewhitelist } = this.state;

        if (prewhitelist.email && prewhitelist.email.length > 0) {
            prewhitelist.email[prewhitelist.email.length] = "";
        }
        else {
            prewhitelist.email =  [""]
        }

        this.setState({ prewhitelist });
    }

    removeEmail = (idx) => {
        const { prewhitelist } = this.state;

        prewhitelist.email.splice(idx, 1);

        this.setState({ prewhitelist });
    }

    render() {
        const { isOpen, toggleModal } = this.props;
        const { block, error, prewhitelist, extractorList } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggleModal} fade={false} className="modal-medium">
                <Button onClick={this.props.toggleModal} className="closeModal">
                    <i className="fas fa-times fa-sm" />
                </Button>
                <ErrorAlert error={error} />
                <ModalBody>
                    <BlankCard block={block}>
                        <Form onSubmit={this.createEditWhiteList}>
                            <FormGroup row>
                                <Col style={{ fontWeight: "500" }}>
                                    {prewhitelist.id ? <FormattedMessage id="EmailResWhiteList.Editwhitelist" /> : <FormattedMessage id="EmailResWhiteList.Createwhitelist" />}
                                </Col>
                                <Col className="text-right">
                                    <Button className="btn-sm btn-host" type="submit">
                                        <i className="fas fa-save" />
                                    </Button>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={6}>
                                    <Label><FormattedMessage id="EmailResWhiteList.source" /></Label>
                                    <Input
                                        type="text"
                                        name="source"
                                        value={prewhitelist.source || ''}
                                        onChange={(e) => this.handleChange(e)}
                                        required
                                    />
                                </Col>
                                <Authorization
                                    perform="admin:view"
                                    yes={() => (
                                        <Col sm={6}>
                                            <Label><FormattedMessage id="EmailResWhiteList.Extractor" /></Label>

                                            <CustomSelect
                                                isSearchable isClearable
                                                placeholder={''}
                                                options={extractorList}
                                                value={extractorList?.find(el => el.value === prewhitelist.mailExtractionTemplateId) || ''}
                                                onChange={(combo) => this.handleSelect('mailExtractionTemplateId', combo)}
                                                isLoading={this.state.blockExtractor}
                                            />
                                        </Col>
                                    )}
                                    no={() => ( "" )}
                                />
                            </FormGroup>
                            <Authorization
                                perform="emailReservationWebHook:edit"
                                yes={() => (
                                    <FormGroup row>
                                        <Col sm={12}>
                                            <Label><FormattedMessage id="EmailResWhiteList.prompt" /></Label>
                                            <Input
                                                type="textarea"
                                                name="prompt"
                                                value={prewhitelist.prompt || ''}
                                                onChange={(e) => this.handleChange(e)}
                                            />
                                        </Col>
                                    </FormGroup>
                                )}
                                no={() => (
                                    ""
                                )}
                            />
                            <hr />
                            <FormGroup row>
                                <Col>
                                    <Row>
                                        <Col className="col-1">
                                            <Label><FormattedMessage id="EmailResWhiteList.emails" /></Label>
                                        </Col>
                                        <Col className="text-right">
                                            <Button className="btn btn-sm btn-host" onClick={() => this.addNewEmail()}>
                                                <i className="fa fa-plus"></i>
                                            </Button>
                                        </Col>
                                    </Row>
                                    {prewhitelist.email && prewhitelist.email.length > 0 ?
                                        <Row>
                                            {prewhitelist.email.map((email, idx) =>
                                                <Col className="col-6 mt-2">
                                                    <Row>
                                                        <Col className="col-10">
                                                            <Input
                                                                type="email"
                                                                maxLength="200"
                                                                name={`${idx}`}
                                                                value={email || ''}
                                                                onChange={(e) => this.handleEmailChange(e)}
                                                                required
                                                            />
                                                        </Col>
                                                        <div className={`hoverEdit`} style={{ alignItems: "center", display: "flex" }} onClick={() => this.removeEmail(idx)}>
                                                            <i className="far fa-trash-alt" />
                                                        </div>
                                                    </Row>
                                                </Col>
                                            )}
                                        </Row>
                                        :
                                        <Row>
                                            <Col className="col-6 mt-2">
                                                <Row>
                                                    <Col className="col-10">
                                                        <Input
                                                            type="email"
                                                            maxLength="200"
                                                            name={`email`}
                                                            value={''}
                                                            onChange={(e) => this.handleEmailChange(e)}
                                                            required
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            </FormGroup>
                        </Form>
                    </BlankCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(EmailReservationWhiteListModal);