import React, { Component } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export class RenderPaybyrdForm extends Component {

    render() {
        const { paymentGateway, handleData, idx } = this.props;

        return (
            <FormGroup row>
                <Label sm={2}>
                    <FormattedMessage id="PaymentGateway.ApiKey" />
                </Label>
                <Col sm={10}>
                    <Input type="text" maxLength="100" name="accessToken" id="accessToken" value={paymentGateway.accessToken ? paymentGateway.accessToken : ''} onChange={evt => handleData(evt, idx)} required />
                </Col>
            </FormGroup>
        );
    }
}