import React, { Component } from 'react';
import { injectIntl } from "react-intl";
import { Row, Col, Badge, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { handleNotification } from "./Base/Notification";
import { getAPI, postAPI } from "./Base/API";
import BlockUi from 'react-block-ui';
import moment from 'moment';

class SubscribeToCM extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            requestedUpdate: false,
            requestedAt: null
        }
    }

    componentDidMount() {
        this.getRequests();
    }

    getRequests = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data.response) {
                data.response.forEach(el => {
                    if (el.status === 'New' || el.status === 'Working') {
                        this.setState({ requestedUpdate: true, requestedAt: el.requestedAt });
                    }
                })
            }
            this.setState({ block: false });
        }, '/api/hotel/License/v1/upgradeAccountRequests');
    }

    createRequest = (e) => {
        e.preventDefault();
        this.setState({ block: true });
        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }

            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="UpgradePage.SuccessfulUpdateRequest" />, <FormattedMessage id="General.Success" />);
                    this.getRequests();
                    this.setState({ block: false });
                }
            }
        }, '/api/hotel/License/v1/upgradeAccountRequest');
    }

    render() {
        return (
            <div className="p-4">
                <BlockUi tag="div" blocking={this.state.block}>
                    <Row className="my-4">
                        <Col className="text-center">
                            <h3>Hey! Channel Manager</h3>
                        </Col>
                    </Row>

                    <Row className="my-3">
                        <Col>
                            <p className="lead">
                                <FormattedMessage id="SubscribeToCM.CentraliseAllYourInventory" />
                            </p>
                        </Col>
                    </Row>

                    <hr />

                    <Row className="my-4">
                        <Col>
                            <Row className="mt-2">
                                <Col className="col-1 text-center">
                                    <Badge className="bg-host p-2"><i className="fas fa-2x fa-hotel"></i></Badge>
                                </Col>
                                <Col className="pl-0">
                                    <h5><FormattedMessage id="SubscribeToCM.MaximiseOccupancy" /></h5>
                                    <div className="text-muted"><FormattedMessage id="SubscribeToCM.EnjoyAFullSync" /></div>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col className="col-1 text-center">
                                    <Badge className="bg-host p-2"><i className="fas fa-2x fa-sync-alt"></i></Badge>
                                </Col>
                                <Col className="pl-0">
                                    <h5><FormattedMessage id="SubscribeToCM.RealTimeSync" /></h5>
                                    <div className="text-muted"><FormattedMessage id="SubscribeToCM.ManageAllYourChannels" /></div>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col className="col-1 text-center">
                                    <Badge className="bg-host p-2"><i className="fas fa-2x fa-comment"></i></Badge>
                                </Col>
                                <Col className="pl-0">
                                    <h5><FormattedMessage id="SubscribeToCM.CentralisedCommunication" /></h5>
                                    <div className="text-muted"><FormattedMessage id="SubscribeToCM.KeepingTrack" /></div>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col className="col-1 text-center">
                                    <Badge className="bg-host p-2"><i className="fas fa-2x fa-star"></i></Badge>
                                </Col>
                                <Col className="pl-0">
                                    <h5><FormattedMessage id="SubscribeToCM.ReviewsManagement" /></h5>
                                    <div className="text-muted"><FormattedMessage id="SubscribeToCM.WithHey!Travel" /></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="my-5 text-center">
                        <Col>
                            <p className="lead">
                                <FormattedMessage id="SubscribeToCM.SaveTime" />
                            </p>
                            <p className="lead">
                                <FormattedMessage id="SubscribeToCM.ManageYourProperties" />
                            </p>
                        </Col>
                    </Row>


                    <Row>
                        <Col className="text-center mt-2">
                            {this.state.requestedUpdate ?
                                <b> <FormattedMessage id="UpgradePage.RequestMade" /> {moment(this.state.requestedAt).format("DD-MM-YYYY")}  </b>
                                :
                                <Button type="submit" className="btn btn-sm btn-host" onClick={(e) => this.createRequest(e)}>
                                    <FormattedMessage id="SubscribeToCM.ContactUs" />
                                </Button>
                            }
                        </Col>
                    </Row>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(SubscribeToCM);