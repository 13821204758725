import React, { Component } from 'react'
import { UncontrolledTooltip } from 'reactstrap';
import TooltipErrorBoundary from '../components/TooltipErrorBoundary';

export default class CustomToolTip extends Component {    
    
    render() {
        const { target, placement, defaultOpen, trigger, autohide, placementPrefix, children, targetRef } = this.props;
        
        return (
            <TooltipErrorBoundary>
                <ErrorFreeToolTip placementPrefix={placementPrefix} targetRef={targetRef} defaultOpen={defaultOpen} placement={placement} target={target} trigger={trigger} autohide={autohide} children={children} />
            </TooltipErrorBoundary>
        )
    }
}

class ErrorFreeToolTip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isTargetValid: false
        };
    }

    componentDidMount() {
        this.checkIsTargetValid();
    }

    componentWillReceiveProps() {
        this.checkIsTargetValid();
    }

    checkIsTargetValid = () => {
        const isTargetValid = document.getElementById(this.props.target) ? true : false;
        if (isTargetValid !== this.state.isTargetValid) this.setState({ isTargetValid });
    }

    render() {
        const { target, placement, children, defaultOpen, trigger, autohide, placementPrefix, targetRef } = this.props;
        const { isTargetValid } = this.state;

        return (
            isTargetValid ?
                <UncontrolledTooltip placementPrefix={placementPrefix} defaultOpen={defaultOpen} placement={placement} target={targetRef || target} trigger={trigger} autohide={autohide} flip={false}>
                    {children}
                </UncontrolledTooltip>
                : ''
        )
    }
}
