import React, { Component } from 'react';
import { Card, CardBody, Col, Input, InputGroup, InputGroupAddon, Row, Form, Button, Badge } from 'reactstrap';
import { ReservationStatusBadge, SelectChannelInstanceByChannelCode } from '../../Base/Common/CommonUIComponents';
import { BookingChatWindow } from "./BookingChatWindow";
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

export default class BookingMailBoxMessages extends Component {
    render() {
        const { reservationData } = this.props;

        const LastMsgCard = ({ item, index }) => {
            const message = item.messages[0];
            const participant = message ? item.participants.find(p => p.participant_id === message.sender_id) : null;
            const noReplyNeeded = item.tags && item.tags.no_reply_needed && item.tags.no_reply_needed.set === true ? true : false;

            return <Card key={index} className={"w-100 mb-1 mt-1 px-2 py-3 pointer " + this.props.backgroundColor(item)} onClick={() => this.props.selectConversation(item.conversation_id)}>
                <Row>
                    <Col className="col-8 text-truncate">
                        <b>{participant && participant.metadata?.type}</b> ({ item.conversation_reference})
                    </Col>
                    <Col className="col-4 text-right">
                        {message && message.timestamp ?
                            moment(message.timestamp).format("YYYY-MM-DD") :
                            <Badge color={noReplyNeeded ? 'primary' : ''} onClick={(e) => noReplyNeeded ? this.props.removeNoReplyNeededTag(e, item.conversation_id, index) : this.props.markAsNoReplyNeeded(e, item.conversation_id, index)}  className={`p-1 ${noReplyNeeded ? '' : ' text-muted'}` } style={noReplyNeeded ? { fontSize: '10.5px' } : { fontSize: '10.5px', backgroundColor: '#e7e8e8' }}>
                                No Reply
                            </Badge>
                        }
                    </Col>
                </Row>
                {message && message.content ?
                    <Row className="mt-2">
                        <Col className="col-8 text-truncate">
                            {message.content}
                        </Col>
                        <Col className="col-4 text-truncate d-flex align-items-center justify-content-end">
                            {participant?.metadata?.type === 'guest' && noReplyNeeded === false &&
                                <div style={{ height: '10px', width: '10px', backgroundColor: 'red', borderRadius: '10px' }}>  </div>
                            }
                            <Badge color={noReplyNeeded ? 'primary' : ''} onClick={(e) => noReplyNeeded ? this.props.removeNoReplyNeededTag(e, item.conversation_id, index) : this.props.markAsNoReplyNeeded(e, item.conversation_id, index)} className={`p-1 ml-2 ${noReplyNeeded ? '' : ' text-muted'}`} style={noReplyNeeded ? { fontSize: '10.5px' } : { fontSize: '10.5px', backgroundColor: '#e7e8e8' }}>
                                No Reply
                            </Badge>
                        </Col>
                    </Row>
                : ''}
            </Card>
        }

        return (
            <>
                <Card className="bg-white mb-1 border-0">
                    <Form>
                        <CardBody className="small border-bottom">
                            <Row>
                                <Col className="col-lg-3 pl-0">
                                    <SelectChannelInstanceByChannelCode
                                        name={'selectedConnection'}W
                                        icon={'fa fa-plug fa-fw'}
                                        channelCode={'Booking.com'}
                                        onChangeFunc={this.props.setComboStatus}
                                        value={this.props.selectedConnection}
                                    />
                                </Col>
                                <Col className="col-lg-3 pl-3">
                                    <InputGroup size="sm text-right">
                                        <InputGroupAddon addonType="prepend"><span className="input-group-text border-right-0"><span className="fas fa-key fa-fw"></span></span></InputGroupAddon>
                                        <FormattedMessage id="ReservationList.ReservationId">
                                            {placeholder =>
                                                <Input className="border-left-0"
                                                    type="text"
                                                    name="reservationId"
                                                    placeholder={placeholder}
                                                    value={this.props.reservationId || ''}
                                                    onChange={(e) => this.props.handleChange(e)}
                                                />
                                            }
                                        </FormattedMessage>
                                    </InputGroup>
                                </Col>
                                <Col className="col-6 text-right">
                                    <Button className="btn btn-host shadow btn-sm" onClick={(e) => this.props.doSearch(e)} ><span className="fas fa-search"></span></Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Form>
                </Card>
                <Row>
                    <Col className="col-3 border-right scrollableDiv" style={{ overflowY: 'auto', maxHeight: '77vh' }}>
                        <div>
                            {this.props.threads && this.props.threads.conversations && this.props.threads.conversations.map((item, key) => item.access !== 'blocked' ?
                                <Row key={key} >
                                    <Col className="nav-stacked col-12 ">
                                        <LastMsgCard item={item} index={key} />
                                    </Col>
                                </Row>
                            : '')}
                        </div>
                    </Col>

                    <Col className="col-6 px-0" >
                        {this.props.selectedConversation ?
                            <BookingChatWindow
                                selectedConversation={this.props.selectedConversation}
                                selectedThread={this.props.selectedThread}
                                selectedConnection={this.props.selectedConnection}
                                selectConversation={this.props.selectConversation}
                            />
                        :
                            <h6 style={{ textAlign: 'center' }}> <span className="ml-2 far fa-comments"> <FormattedMessage id="BookingMailBoxCard.SelectAConversation" /> </span > </h6>
                        }                                
                    </Col>

                    <Col className="col-3 border-left">
                        {this.props.selectedConversation ?
                            <Card body>
                                <b className="text-center mb-2"> <FormattedMessage id="BookingMailBoxCard.Reservation" /> {this.props.selectedConversation.conversation.conversation_reference} </b>
                                {reservationData ?
                                    <div>
                                        <Row>
                                            <Col>
                                                <b><FormattedMessage id="ManualReservation.MainGuest" />:</b> {reservationData.reservationProfile[0]?.givenName} {reservationData.reservationProfile[0].surname}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <b><FormattedMessage id="ReservationDashboard.Status" />:</b> <ReservationStatusBadge status={reservationData.status} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <b><FormattedMessage id="ManualReservation.CheckIn" />:</b> {reservationData.hotelReservation[0]?.checkIn ? moment(reservationData.hotelReservation[0].checkIn).format("YYYY-MM-DD") : ''}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <b><FormattedMessage id="ManualReservation.CheckOut" />:</b> {reservationData.hotelReservation[0]?.checkOut ? moment(reservationData.hotelReservation[0].checkOut).format("YYYY-MM-DD") : ''}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <b><FormattedMessage id="ReservationDetail.Amount" />:</b> {reservationData.amountAfterTax}
                                            </Col>
                                        </Row>
                                    </div>
                                : ''}
                            </Card>
                        : ''}
                    </Col>
                </Row>
            </>
        )
    }
}
