import React, { Component } from 'react';
import { EmptyCard, ReservationStatusBadge } from '../Base/Common/CommonUIComponents';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { getAPI } from "../Base/API";
import moment from "moment";
import { CustomTable } from '../Base/Common/CustomTable';
import { getConvertedDateToHotelTimezone } from '../Base/Common/ReferenceDataFunctions';

class ReservationsPromotions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            roomStays: [],
            pageSize: 10,
            pageIndex: 1,
            totalResults: 0
        };
    }

    componentDidMount() {
        this.getRoomStaysWithPromotions();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.search === true && nextProps.search !== this.props.search) {
            this.setState({ pageIndex: 1, block: true }, this.getRoomStaysWithPromotions);
        }
    }

    getRoomStaysWithPromotions = (cb) => {
        const { startDate, endDate, selectedPromo, selectedPromoCode } = this.props;
        const { pageIndex, pageSize } = this.state;

        const param = `${selectedPromo ? `&promotionId=${selectedPromo}` : ``}${selectedPromoCode ? `&promoCode=${selectedPromoCode}` : ``}`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, block: false, roomStays: data.data, totalResults: data.count }, () => {
                    if (cb) {
                        cb();
                    }
                });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Price/Reservation/v1/Reservations/BE/Promotions?startDate=${moment(startDate).format("YYYY-MM-DD")}&endDate=${moment(endDate).format("YYYY-MM-DD")}&pageSize=${pageSize}&pageIndex=${pageIndex - 1}` + param)
    }


    handleTableChange = (_, { page, sizePerPage }) => {
        this.setState({ pageIndex: page, pageSize: sizePerPage, block: true }, this.getRoomStaysWithPromotions);
    }

    handleOnClick(cb) {
        const { pageIndex, pageSize } = this.state;
        const totalResults = 1000;

        this.setState({ pageIndex: 1, pageSize: totalResults, block: true }, () => this.getRoomStaysWithPromotions(() => cb(() => this.setState({ pageIndex: pageIndex, pageSize: pageSize }, () => this.getRoomStaysWithPromotions()))));
    }

    render() {
        const { block, error, roomStays, pageIndex, pageSize, totalResults } = this.state;

        const columns = [
            {
                dataField: 'resIdValue',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationId" }),
                sort: true
            },
            {
                dataField: 'resIdDate',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationDate" }),
                formatter: (cell, row) => cell ? <div>{moment(cell).format("YYYY-MM-DD HH:mm:ss")}</div> : '',
                sort: true,
                formatter: (cell) => cell ? getConvertedDateToHotelTimezone(cell).format("YYYY-MM-DD HH:mm:ss") : ''
            },
            {
                dataField: 'resStatus',
                text: this.props.intl.formatMessage({ id: "ReservationList.Status" }),
                formatter: (cell) => <ReservationStatusBadge status={cell}></ReservationStatusBadge>,
                sort: true
            },
            {
                dataField: 'checkIn',
                text: this.props.intl.formatMessage({ id: "ReservationDetail.Arrival" }),
                formatter: (cell) => <div>{cell ? moment(cell).format("YYYY-MM-DD") : ''}</div>,
                sort: true,
                csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'nights',
                text: this.props.intl.formatMessage({ id: "ReservationList.numNights" }),
                sort: true
            },
            {
                dataField: 'checkOut',
                text: this.props.intl.formatMessage({ id: "ReservationDetail.Departure" }),
                formatter: (cell) => <div>{cell ? moment(cell).format("YYYY-MM-DD") : ''}</div>,
                sort: true,
                csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'guests',
                text: this.props.intl.formatMessage({ id: "ReservationList.NumberOfGuests" }),
                sort: true
            },
            {
                dataField: 'rooms',
                text: this.props.intl.formatMessage({ id: "ReservationList.numRooms" }),
                sort: true
            },
            {
                dataField: 'amountAfterTax',
                text: this.props.intl.formatMessage({ id: "ReservationList.Amount" }),
                formatter: (cell, row) => cell !== null ? <FormattedNumber value={cell} currency={row.currencyCode ? row.currencyCode : global.hotelCurrency} style="currency" maximumFractionDigits={2} /> : '',
                sort: true
            },
            {
                dataField: 'channelRoomTypeCode',
                text: this.props.intl.formatMessage({ id: "ReservationDetail.RoomCatCode" }),
                sort: true
            },
            {
                dataField: 'ratePlanCode',
                text: this.props.intl.formatMessage({ id: "ReservationDetail.RatePlanCode" }),
                sort: true
            },
            {
                dataField: 'promotionName',
                text: this.props.intl.formatMessage({ id: "bookingPromotion.promoname" }),
                sort: true
            }
        ];

        return (
            <EmptyCard block={block} error={error}>
                <Row>
                    <Col className="col-12">
                        <CustomTable
                            data={roomStays}
                            columns={columns}
                            page={pageIndex}
                            sizePerPage={pageSize}
                            totalSize={totalResults}
                            onTableChange={this.handleTableChange}
                            shadow={false}
                            exportCSV={true}
                            customExportCsv={true}
                            search={true}
                            remote={true}
                            showTotal={true}
                            hideSizePerPage={false}
                            handleOnClick={this.handleOnClick.bind(this)}
                            tooltipMessage={<FormattedMessage id="generic.table.customexportcsv" values={{ value: '1000' }} />}
                        />
                    </Col>
                </Row>
            </EmptyCard>
        );
    }
}


export default injectIntl(ReservationsPromotions);