import React, { Component } from 'react';
import { Row, Col, Card, Button } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ConfirmActionModal, KebabMenu, StyledCard } from '../../Base/Common/CommonUIComponents';
import { deleteAPI, getAPI } from '../../Base/API';
import { handleNotification } from '../../Base/Notification';
import AiPromptEditModal from './AiPromptEditModal'

class AIPrompts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            deleteModal: false,
            editModal: false,
            selectedPrompt: null
        };
    }

    componentDidMount() {
        this.getPrompt();
    }

    toggleModal = (modal, prompt) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedPrompt: prompt
        }), () => this.getPrompt());
    }

    getPrompt = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true }, 
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response) {
                    this.setState({ prompts: data.response, block: false, error: errorMessage });
                    return;
                };

                this.setState(({ block: false }));
            }
            else this.setState(({ block: false }));
        }, `/api/hotel/OpenAI/v1/PromptConfig`));
    }

    deletePrompt = (e, id) => {
        if (e) e.preventDefault();

        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="AiPrompt.PromptRemoved" />, <FormattedMessage id="generic.success" />);
                    }

                    this.setState({ deleteModal: false }, () => this.getPrompt());
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/hotel/OpenAI/v1/PromptConfig/${id}`));
    }

    render() {
        const { block, error, prompts, editModal, deleteModal, selectedPrompt } = this.state;

        return (
            <>
                {editModal ?
                    <AiPromptEditModal
                        isOpen={editModal}
                        toggleModal={() => this.toggleModal("editModal")}
                        selectedPrompt={selectedPrompt}
                        getPrompt={this.getPrompt}
                    />
                    : ''}
                {deleteModal ?
                    <ConfirmActionModal
                        modal={deleteModal}
                        toggleModal={() => this.toggleModal('deleteModal')}
                        actionFunction={(e) => this.deletePrompt(e, selectedPrompt.id)}
                        text={<FormattedMessage id="AiPrompt.ConfirmDeleteText" values={{ name: selectedPrompt.name }} />}
                        block={block}
                    />
                    : ''}
                <StyledCard block={block} error={error} title="navbar.AIPrompts">
                    <Row>
                        <Col className="d-flex justify-content-end align-items-center mb-3">
                            <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal("editModal")}>
                                <FormattedMessage id="AiPrompt.NewPrompt" />
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        {prompts && prompts.map((prompt, key) =>
                            <Col key={key} className="col-6">
                                <Card className='py-2 px-3 w-100 bg-white mb-3' style={{ border: '1px solid #bbc1c9ab', borderRadius: '5px', boxShadow: '0px 3px 15px #6F73881A', maxHeight: '75px' }} body>
                                    <Row>
                                        <Col id="overflowAiPrompt" style={{ fontWeight: "500", alignItems: "center", display: "flex" }}>
                                            {prompt.name}
                                        </Col>
                                        <Col id="overflowAiPrompt" className="d-flex h-100 col-5" style={{ overflowX: 'none' }}>
                                            {prompt.type}
                                        </Col>
                                        <Col className="text-right d-flex justify-content-end col-1" style={{ alignItems: "center" }}>
                                            <KebabMenu
                                                editFunction={() => this.toggleModal('editModal', prompt)}
                                                deleteFunction={() => this.toggleModal('deleteModal', prompt)}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </StyledCard>
            </>
        );
    }
}

export default injectIntl(AIPrompts);