import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row, TabPane } from 'reactstrap';
import PlatformDeepDiveSideMenu from './PlatformDeepDiveSideMenu';
import BlockUi from 'react-block-ui';
import Dashboard from './Dashboard';
import Helpers from './Helpers';
import Events from './Events';
import ExceedTimeAPIs from './ExceedTimeAPIs';
import moment from 'moment';

class PlatformDeepDive extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            activeTab: 'Dashboard',
            usageType: 'Admin',
            productType: 'HCM',
            startDate: moment().subtract(1, 'week'),
            endDate: moment(),
        }
    }

    toggleTab = (tabName) => {
        if (this.state.activeTab !== tabName) {
            this.setState({
                activeTab: tabName
            })
        }
    }

    isOutsideDate = (day) => {
        return day < moment("2024-03-15") || day > moment().endOf('day');
    }

    handleDates = (startDate, endDate) => {
        this.setState({
            startDate, endDate
        })
    }

    setProductType = (combo) => {
        const productType = combo && combo.value;
        this.setState({ productType });
    }

    hideBEFilters = (obj) => {
        const { productType } = this.state;
        return (productType === 'HCM') || (productType === 'BE' && obj && (obj.label === 'UI Errors' || obj.label === 'API Errors' || obj.label === 'Page View'))
    }

    render() {
        const { activeTab } = this.state;

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <Row>
                    <Col className="col-2 pr-1">
                        <PlatformDeepDiveSideMenu
                            activeTab={this.state.activeTab}
                            toggleTab={this.toggleTab}
                            productType={this.state.productType}
                            setProductType={this.setProductType}
                        />
                    </Col>
                    <Col className="col-10" style={{ overflowX: 'hidden', overflowY: 'auto', height: '100%' }}>
                        {activeTab === 'Dashboard' ?
                            <TabPane tabId='Dashboard' className="border-0 my-0 p-0 h-100">
                                <Dashboard
                                    usageType={this.state.usageType}
                                    productType={this.state.productType}
                                    isOutsideDate={this.isOutsideDate}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    handleDates={this.handleDates}
                                    hideBEFilters={this.hideBEFilters}
                                />
                            </TabPane>
                        : ''}

                        {activeTab === 'Helpers' ?
                            <TabPane tabId='Helpers' className="border-0 my-0 p-0 h-100">
                                <Helpers
                                    usageType={this.state.usageType}
                                    productType={this.state.productType}
                                    isOutsideDate={this.isOutsideDate}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    handleDates={this.handleDates}
                                />
                            </TabPane>
                            : ''}

                        {activeTab === 'Events' ?
                            <TabPane tabId='Events' className="border-0 my-0 p-0 h-100">
                                <Events
                                    usageType={this.state.usageType}
                                    productType={this.state.productType}
                                    isOutsideDate={this.isOutsideDate}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    handleDates={this.handleDates}
                                    hideBEFilters={this.hideBEFilters}
                                />
                            </TabPane>
                            : ''}

                        {activeTab === 'ExceedTimeAPIs' ?
                            <TabPane tabId='ExceedTimeAPIs' className="border-0 my-0 p-0 h-100">
                                <ExceedTimeAPIs
                                    usageType={this.state.usageType}
                                    productType={this.state.productType}
                                    isOutsideDate={this.isOutsideDate}
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    handleDates={this.handleDates}
                                />
                            </TabPane>
                            : ''}
                    </Col>
                </Row>
            </BlockUi>
        );
    }

};
export default injectIntl(PlatformDeepDive);