import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import AvailabilityHistory from '../../Admin/HealthCheck/PMSHistory/AvailabilityHistory';
import RestrictionHistory from '../../Admin/HealthCheck/PMSHistory/RestrictionHistory';
import PriceHistory from '../../Admin/HealthCheck/PMSHistory/PriceHistory';
import { getAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';
import classnames from 'classnames';
import { CommonHelper } from '../../Base/Common/CommonUIComponents';

class PMSHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            hotelList: [],
            activeTab: '1',
            isOpModeNone: null
        }
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({
                    isOpModeNone: global.operationMode === 'None'
                });
            }, global.operationMode || 2500
        );

        this.getRates();
    }

    getRates = () => {
        this.setState({ block: true });
        let rates = [];

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                data.response.map((item) =>
                    rates.push({ value: item.id, label: `${item.name} ${item.pmsId ? '(Pms Id: ' + item.pmsId  + ')': ''}` })
                )
            }

            this.setState({ block: false });
        }, `/api/Rate/Rate/v1/Info`);

        return rates;
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { block, isOpModeNone } = this.state;

        return (
            <BlockUi tag="div" blocking={block}>
                <Card className="border-0 shadow">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Nav tabs >
                            <NavItem className="text-sm">
                                <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                    <FormattedMessage id="PMSHistory.AvailabilityHistory" />
                                </NavLink>
                            </NavItem>
                            <NavItem className="text-sm">
                                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                    <FormattedMessage id="PMSHistory.RestrictionHistory" />
                                </NavLink>
                            </NavItem>
                            <NavItem className="text-sm">
                                <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                    <FormattedMessage id="PMSHistory.PriceHistory" />
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <div>
                            <CommonHelper help={<FormattedMessage id="PMSHistory.Help" />} id={'InternalChangesHelp'}/>
                        </div>
                    </div>

                    <TabContent activeTab={this.state.activeTab}>
                        {this.state.activeTab === '1' && this.state.isOpModeNone !== null ?
                            <TabPane tabId="1">
                                <AvailabilityHistory
                                    isAdminPage={false}
                                    isOpModeNone={isOpModeNone}
                                />
                            </TabPane>
                        : ''}


                        {this.state.activeTab === '2' ?
                            <TabPane tabId="2">
                                <RestrictionHistory
                                    isAdminPage={false}
                                    getRates={this.getRates}
                                    isOpModeNone={isOpModeNone}
                                />
                            </TabPane>
                        : ''}

                        {this.state.activeTab === '3' ?
                            <TabPane tabId="3">
                                <PriceHistory
                                    isAdminPage={false}
                                    getRates={this.getRates}
                                    isOpModeNone={isOpModeNone}
                                />
                            </TabPane>
                        : ''}
                    </TabContent>
                </Card>
            </BlockUi>
        );
    }

};
export default injectIntl(PMSHistory);