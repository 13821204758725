import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Badge, Button, Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import CustomToolTip from '../../../Utils/CustomToolTip';
import { postAPI } from "../../Base/API";
import { StyledCard, SelectChannelConfig } from "../../Base/Common/CommonUIComponents";
import { handleNotification } from "../../Base/Notification";


class ManageChannelInstance extends Component {

    state = {
        block: true,
        status: { value: false },
        channelInstance: {
            "id": null,
            "propertyCode": null,
            "user": null,
            "password": null,
            "secondaryUser": null,
            "secondaryPassword": null,
            "name": null,
            "channelConfigId": null,
            "active": false,
            "maxInventoryDays": 0,
            "SendAvailability": true,
            "SendPrice": true,
            "SendStopSales": true,
            "SendCta": true,
            "SendCtd": true,
            "SendMinStay": true,
            "SendMaxStay": true
        }
    }

    constructor(props) {
        super(props);

        this.form = React.createRef();
        this.changeSendInfoState = this.changeSendInfoState.bind(this);
        this.changeState = this.changeState.bind(this);
        
    }

    componentDidMount() {
        this.setState({ block: false });

    }
    addChannelInstance(e) {
        e.preventDefault();

        if (this.form.current.reportValidity()) {
            this.setState({
                block: true
            });

            // call API
            postAPI(result => {
                const { data, error } = result;
                const errorMessage = []
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="ManageChannelInstance.ChannelInstanceUpdated" />, <FormattedMessage id="General.Success" />);
                    this.setState({ block: false });
                }
            }, "/api/Rate/ChannelConfig/v1/ChannelInstance", this.state.channelInstance);   

        }
    }

    changeState(evt) {
        let name = evt.target.name;
        let value = evt.target.value;


        this.setState(prevState => ({
            ...prevState,
            channelInstance: {
                ...prevState.channelInstance,
                [name]: value
            }
        }));

    }

    changeSendInfoState(evt) {
        let name = evt.currentTarget.id;
        let value = evt.target.value;

       
            this.setState(prevState => ({
                ...prevState,
                channelInstance: {
                    ...prevState.channelInstance,
                    [name]: !prevState.channelInstance[name]
                }
            }));

    }

    setChannelConfigDetailsFilterState = (name, combo) => {
        this.setState(prevState => (
            {
             
                channelInstance: {
                    ...prevState.channelInstance,
                        [name]: combo ? combo.value : null
                    }
                
            }))
    }

    


    render() {

        const helpContent =
            <div>
                TODO
                <hr />
            </div>


     
        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-plug'} title={'ManageChannelInstance.title'} help={helpContent}>
                
                <Row>
                    <Col className="h-100">
                        

                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-sm btn-host" onClick={this.addChannelInstance.bind(this)}><i className="fas fa-save"></i></Button>
                    </Col>

                </Row>
                <form ref={this.form}>
                  

                    <Card className=" shadow border-0 mb-2">
                        <CardHeader className="bg-white border-bottom" >   <strong></strong>
                            <strong> <FormattedMessage id="ManageChannelInstance.ChannelInstanceBasicInfo" /> </strong>
                        </CardHeader>
                        <CardBody>
                           
                            
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="name" sm={4}><FormattedMessage id="ChannelList.name" /></Label>
                                        <Col sm={8}>
                                            <SelectChannelConfig name={'channelConfigId'} onChangeFunc={this.setChannelConfigDetailsFilterState} required={true} placeholder={<FormattedMessage id="Announcement.ChooseChannel" />} value={this.state.channelInstance.channelConfigId} />                                                    

                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="channelName" sm={4}><FormattedMessage id="ChannelList.channelName" /></Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ChannelList.channelName">{placeholder =>
                                                    <Input name="name" value={this.state.channelInstance.name} onChange={this.changeState.bind(this)} placeholder={placeholder} required  />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="user" sm={4}><FormattedMessage id="ChannelList.User" /></Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ChannelList.User">{placeholder =>
                                                    <Input name="user" value={this.state.channelInstance.user} onChange={this.changeState.bind(this)} placeholder={placeholder} required  />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="password" sm={4}><FormattedMessage id="ChannelList.Password" /></Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ChannelList.Password">{placeholder =>
                                                    <Input type="password" name="password" value={this.state.channelInstance.password} onChange={this.changeState.bind(this)} placeholder={placeholder} required  />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="secondaryuser" sm={4}><FormattedMessage id="ChannelList.User" /></Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ChannelList.User">{placeholder =>
                                                    <Input name="secondaryUser" value={this.state.channelInstance.secondaryUser} onChange={this.changeState.bind(this)} placeholder={placeholder}  />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="secondarypassword" sm={4}><FormattedMessage id="ChannelList.Password" /></Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ChannelList.Password">{placeholder =>
                                                    <Input type="password" name="secondaryPassword" value={this.state.channelInstance.secondaryPassword} onChange={this.changeState.bind(this)}  placeholder={placeholder}   />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="user" sm={4}><FormattedMessage id="ChannelList.propertyCode" /></Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ChannelList.propertyCode">{placeholder =>
                                                    <Input name="propertyCode" value={this.state.channelInstance.propertyCode} onChange={this.changeState.bind(this)}  placeholder={placeholder} required  />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="maxInventoryDays" sm={4}><FormattedMessage id="ChannelList.maxInventoryDays" /></Label>
                                        <Col sm={8}>
                                            <InputGroup size="sm">
                                                <FormattedMessage id="ChannelList.maxInventoryDays">{placeholder =>
                                                    <Input name="maxInventoryDays" type="number" min="125" max="720" value={this.state.channelInstance.maxInventoryDays} onChange={this.changeState.bind(this)} placeholder={placeholder} required />
                                                }</FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-6">
                                    <FormGroup row>
                                        <Label for="user" sm={4}><FormattedMessage id="ChannelList.status" /></Label>
                                        <Col sm={8}>
                                            <Badge className="mr-2 p-2" color={this.state.channelInstance.active ? 'primary' : 'secondary'}  ><div id="active" onClick={this.changeSendInfoState.bind(this)}>{this.state.channelInstance.active ? <FormattedMessage id="generic.active" /> : <FormattedMessage id="generic.inactive" />} </div></Badge>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col className="col-6">
                                   
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>

                    <Card className=" shadow border-0 mb-2">
                        <CardHeader className="bg-white border-bottom" >   <strong><FormattedMessage id="ManageChannelInstance.InformationSentToChannel" /></strong>
                            <div className="float-right">
                                <a id="SentToChannelTooltip"><i className="fas fa-question-circle"></i></a>


                                <CustomToolTip placement="bottom" target="SentToChannelTooltip">
                                    <Badge color="primary" className="p-1">Active</Badge><br />
                                    <Badge color="secondary">Inactive</Badge>
                                </CustomToolTip>
                            </div>

                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    {['SendAvailability', 'SendPrice', 'SendCta', 'SendCtd', 'SendMaxStay', 'SendMinStay', 'SendStopSales'].map((el, key) =>
                                        <Badge className="mr-2 p-2" color={this.state.channelInstance[el] ? 'primary' : 'secondary'}  ><div id={el} onClick={this.changeSendInfoState.bind(this)}> <FormattedMessage id={"ChannelList." + el} /></div></Badge>)
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>


                </form>



            </StyledCard>

        );
    }

};
export default injectIntl(ManageChannelInstance)
