import React, { Component } from 'react';
import { FormattedMessage, injectIntl, FormattedHTMLMessage  } from 'react-intl';
import { Col, Row, Card, Nav, NavItem, NavLink, TabContent, TabPane, Collapse, BreadcrumbItem, Breadcrumb, Input, Button } from 'reactstrap';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { RoomCategorySetupDetails } from '../../HotelSetting/Property/Room/RoomCategorySetupDetails';
import CancelationPolicy from '../../HotelSetting/CancelationPolicy/CancelationPolicy';
import BEPromotionsDashboard from '../../AddOns/Promotions/BEPromotionsDashboard';
import { ComplexSetup } from '../../HotelSetting/Property/Complex/ComplexSetup';
import RatesIntegrationsModal from '../../Setup/Room/RatesIntegrationsModal';
import PromoCodeDetails from '../../HotelSetting/PromoCodes/PromoCodeDetails';
import PromoCodes from '../../HotelSetting/PromoCodes/PromoCodes';
import PaymentGateway from '../../Payment/PaymentGateway';
import ConfigWidget from '../ConfigWidget/ConfigWidget';
import ChannelConf from '../../Channel/ChannelConf';
import GeneralSettings from './GeneralSettings';
import { getAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';
import MainPage from './MainPage';
import CreateURL from '../CreateURL';
import Authorization from '../../Base/Authorization';
import Offers from '../../HotelSetting/Offers/Offers';
import OfferDetails from '../../HotelSetting/Offers/OfferDetails';
import Experiences from '../../HotelSetting/Experiences/Experiences';
import ConfirmationLetters from '../../Booking Engine/ConfirmationLetter/ConfirmationLetters';

class BEConfigDashboard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: false,
            blockProperties: false,
            blockRateMapping: false,
            blockMetaSeachChannels: false,
            activeTab: '0',
            channelId: null,
            rates: [],
            rooms: [],
            properties: [],
            selectedRate: null,
            selectedRoom: null,
            selectedProperty: null,
            showOfferDetails: false,
            offerId: null,
            showPromoCodeDetails: false,
            promoCodeId: null,
            roomValidations: [],
            propertyValidations: [],
            rateValidations: [],
            channelConfigId: null,
            metaSearchChannels: [
                'GOOGLEHOTELADS'
            ],
            metaSearchChannelsCreated: []
        }
    }

    componentDidMount() {
        this.getHeyBEChannel();
        this.getProperties();
        this.getMetaSeachChannels();
    }

    getHeyBEChannel = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data && data.length > 0) {
                const orderedChannels = data.sort((chn1, chn2) => chn1.id - chn2.id);
                const activeChannels = orderedChannels.filter(chn => chn.active);
                const channel = activeChannels.length > 0 ? activeChannels[0] : orderedChannels[0];
                
                if(channel){
                    channel.hasChannelData = true;
                }

                this.setState({
                    channel: channel,
                    channelConfigId: channel.channelConfigId
                },() => this.getRateMapping());
            }
            this.setState(({ block: false }));
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance?channelCode=HEYBE`);
    }

    getRateMapping = () => {
        this.setState({ blockRateMapping: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockRateMapping: false });
                return;
            }
            if (data) {
                if (data.response && data.response.length > 0) {
                    var rooms = [], rates = [];

                    data.response.forEach(room => {
                        room.rateList.forEach(rate => {
                            if (rate.mappingList && rate.mappingList.length > 0 && rate.mappingList[0].id !== 0) {
                                rates.push({ id: rate.id, code: rate.code, active: rate.active && rate.mappingList[0].active });

                                if (!rooms.some(r => r.id === room.id)) {
                                    rooms.push({ id: room.id, code: room.code });
                                }
                            }
                        })
                    })
                }
                
                this.setState({ rates, rooms, blockRateMapping: false });
            }
        }, '/api/Rate/Rate/v1/RateMapping/' + this.state.channel.id);
    }

    getProperties = () => {
        this.setState({ blockProperties: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockProperties: false });

                return;
            }
            if (data) {
                this.setState({ properties: data, blockProperties: false });
            }
            else {
                this.setState({ blockProperties: false });
            }
        }, '/api/hotel/Complexes/v1/BeConfiuration');
    }

    getMetaSeachChannels = () => {
        const { metaSearchChannels } = this.state;
        this.setState({ blockMetaSeachChannels: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({
                    message: error.message,
                    stack: error.stack,
                    messageType: "danger"
                });
                this.setState({ error: errorMessage, blockMetaSeachChannels: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, blockMetaSeachChannels: false, metaSearchChannelsCreated: data.filter(resp => metaSearchChannels.find(meta => meta === resp.code) !== undefined).map(resp => resp.code) });
            }
            else {
                this.setState({ error: errorMessage, blockMetaSeachChannels: false });
            }
        }, "/api/Rate/ChannelConfig/v1/ChannelInstance");
    }

    toggle = (id) => {
        this.setState({ activeTab: id })
    }

    toggleCollapse = (openMenu, collapse2, collapse3) => {
        let closeSearch = openMenu === 'isRoomsOpen' ? 'openSearchRate' : 'openSearchRoom';
        this.setState({
            [openMenu]: true,
            [collapse2]: false,
            [collapse3]: false,
            search: null,
            [closeSearch] : false
        })
    }

    closeCollapse = () => {
        this.setState({
            isRatesOpen: false,
            isRoomsOpen: false,
            isPropertiesOpen: false,
            search: null,
            openSearchRate: false,
            openSearchRoom: false
        })
    }

    changeSelected = (name, value) => {
        this.setState(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    toggleDetails = (name, idName, id) => {
        this.setState(prevState => ({
            [name]: !prevState[name],
            [idName]: id
        }))
    }

    toggleSearch = (os) => {

        this.setState(prevState => ({
            [os]: !prevState[os]
        }))
    }

    goToMissingField = (tab, selectedName, selectedValue, openMenu, collapse2, collapse3) => {
        let value = selectedValue;

        if (selectedName === 'selectedProperty') {
            value = this.state.properties.find(el => el.id === value.id);
        }

        this.setState({
            activeTab: tab,
            [selectedName]: value
        })

        this.toggleCollapse(openMenu, collapse2, collapse3);
    }

    setValidations = (property, room, rate) => {
        this.setState({
            roomValidations: room,
            propertyValidations: property,
            rateValidations: rate
        })
    }

    changeSearch = (e) => {
        this.setState({ search: e.target.value })
    }

    updateComplex = (complex) => {
        const complexList = [...this.state.properties];
        const idx = complexList.findIndex(c => c.id === complex.id);

        if (idx >= 0) {
            complexList[idx] = complex;
        }

        this.setState({ properties: complexList });
    }

    render() {
        const { isRoomsOpen, isRatesOpen, isPropertiesOpen, channel, selectedRoom, selectedRate, selectedProperty, activeTab, metaSearchChannelsCreated, properties, search, openSearchRoom, openSearchRate } = this.state;

        
        return (
            <BlockUi tag="div" blocking={this.state.block || this.state.blockRateMapping || this.state.blockProperties || this.state.blockMetaSeachChannels}>
                <Row>
                    <Col className="col-2 pr-1">
                        <Nav tabs vertical className="h-100 border-0">
                            <Card className="bg-white p-2 border-0 mb-3 shadow w-100" body>
                                <h5 className='pl-3 mb-3'>
                                    <FormattedMessage id={`BEConfigDashboard.Configuration`} />
                                </h5>

                                <NavMenu title="Validate&Publish" tab="0" icon={<i className="fas fa-cloud-upload-alt mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse} />

                                <NavMenu title="Property" tab="1" icon={<i className="fas fa-hotel mr-2" />} toggle={this.toggle} activeTab={activeTab}
                                    toggleCollapse={() => this.toggleCollapse("isPropertiesOpen", "isRoomsOpen", "isRatesOpen")}
                                    validations={this.state.propertyValidations && this.state.propertyValidations.length > 0 ? true : false}
                                />
                                <SubMenu isOpen={isPropertiesOpen} list={this.state.properties}
                                    selectedProp={this.state.selectedProperty} selectedPropName="selectedProperty"
                                    validations={this.state.propertyValidations} changeSelected={this.changeSelected}
                                />

                                <NavMenu title="Mapping" tab="2" icon={<i className="fas fa-random mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse} />

                                <NavMenu title="Room" tab="3" icon={<i className="fas fa-bed mr-2" />} toggle={this.toggle} activeTab={activeTab}
                                    toggleCollapse={() => this.toggleCollapse("isRoomsOpen", "isRatesOpen", "isPropertiesOpen")}
                                    validations={this.state.roomValidations && this.state.roomValidations.length > 0 ? true : false}
                                    isOpen={isRoomsOpen}
                                    toggleSearch={() => this.toggleSearch("openSearchRoom")}
                                />

                                <SubMenu isOpen={isRoomsOpen} list={this.state.rooms}
                                    selectedProp={this.state.selectedRoom} selectedPropName="selectedRoom"
                                    validations={this.state.roomValidations} changeSelected={this.changeSelected}
                                    changeSearch={this.changeSearch}
                                    search={search}
                                    openSearch={openSearchRoom}
                                />

                                <NavMenu title="Rate" tab="4" icon={<i className="fas fa-gift mr-2" />} toggle={this.toggle} activeTab={activeTab}
                                    toggleCollapse={() => this.toggleCollapse("isRatesOpen", "isRoomsOpen", "isPropertiesOpen")}
                                    validations={this.state.rateValidations && this.state.rateValidations.length > 0 ? true : false}
                                    isOpen={isRatesOpen}
                                    toggleSearch={() => this.toggleSearch("openSearchRate")}
                                />
                                <SubMenu isOpen={isRatesOpen} list={this.state.rates}
                                    selectedProp={this.state.selectedRate} selectedPropName="selectedRate"
                                    validations={this.state.rateValidations} changeSelected={this.changeSelected}
                                    changeSearch={this.changeSearch}
                                    search={search}
                                    openSearch={openSearchRate}
                                />

                                <NavMenu title="Promotions" tab="5" icon={<i className="fas fa-tag mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse} />
                                <NavMenu title="PromoCodes" tab="6" icon={<i className="fas fa-percent mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse} />

                                <NavMenu title="Offers" tab="13" icon={<i className="fas fa-gift mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse} />

                                <NavMenu title="CancelPolicies" tab="8" icon={<i className="fas fa-ban mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse} />
                                
                                
                                <NavMenu title="Experiences" tab="14" icon={<i className="fas fa-umbrella-beach mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse}/>
                                    
                                <Authorization
                                    perform="paymentGateway:view"
                                    yes={() => (
                                        <NavMenu title="Payment" tab="9" icon={<i className="fas fa-credit-card mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse} />
                                    )}
                                    no={() => <div></div>}
                                />

                                <NavMenu title="Widget" tab="10" icon={<i className="fas fa-cog mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse} />
                                <NavMenu title="CreateURL" tab="11" icon={<i className="fas fa-link mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse} />
                                <NavMenu title="ConfirmationLetter" tab="15" icon={<i className="fas fa-envelope mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse} />
                                <NavMenu title="GeneralSettings" tab="12" icon={<i className="fas fa-cogs mr-2" />} toggle={this.toggle} activeTab={activeTab} toggleCollapse={this.closeCollapse} />
                            </Card>
                        </Nav>
                    </Col>
                    <Col className="col-10">
                        <Row>
                            <Col>
                                <TabContent activeTab={this.state.activeTab} className="h-100">
                                    <TabPane tabId='0' className="border-0 my-0 p-0 h-100">
                                        <MainPage
                                            goToMissingField={this.goToMissingField}
                                            setValidations={this.setValidations}
                                        />
                                    </TabPane>
                                        
                                    <TabPane tabId='1' className="border-0 my-0 p-0 h-100">
                                        {selectedProperty && activeTab === '1' ?
                                            <ComplexSetup
                                                selectedComplex={selectedProperty}
                                                creationMode={false}
                                                modal={true}
                                                updateComplexs={this.updateComplex}
                                                fromBEConfig={true}
                                            />
                                        : ''}

                                    </TabPane>

                                    <TabPane tabId="2" className="border-0 my-0 p-0 h-100">
                                        {channel && activeTab === '2' ?
                                            <StyledCard icon="fas fa-random" title="BEConfigDashboard.Mapping" help={<FormattedMessage id="BEConfigDashboard.MappingHelp"/>} id="MappingHelp">
                                                <ChannelConf
                                                    selectedChannel={channel}
                                                    //refreshData={this.props.refreshData}
                                                    hotelId={global.hotel.id}
                                                    getRateMappingFromBEConfig={this.getRateMapping}
                                                    fromBEConfig={true} //indica se é um canal interno
                                                    metaSearchChannelsCreated={metaSearchChannelsCreated}
                                                />
                                            </StyledCard>
                                        : ''}
                                    </TabPane>

                                    <TabPane tabId="3" className="border-0 my-0 p-0 h-100">
                                        {selectedRoom && activeTab === '3' ?
                                            <RoomCategorySetupDetails
                                                selectedRoom={this.state.selectedRoom}
                                                creationMode={false}
                                                modal={true}
                                                fromBEConfig={true}
                                            />
                                        : ''}

                                    </TabPane>

                                    <TabPane tabId="4" className="border-0 my-0 p-0 h-100">
                                        {selectedRate && activeTab === '4' ?
                                            <RatesIntegrationsModal
                                                rateCodeDetail={{ rateCodeId: selectedRate }}
                                                fromBEConfig={true}
                                            />
                                        : ''}
                                    </TabPane>

                                    <TabPane tabId="5" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '5' ?
                                            <StyledCard icon="fas fa-tag" title="BEConfigDashboard.Promotions" help={<FormattedHTMLMessage id="BEPromotions.Help" />} id={'BEPromotionsHelp'} >
                                                <BEPromotionsDashboard
                                                    isFromBE={true}
                                                />
                                            </StyledCard>
                                        : ''}
                                    </TabPane>

                                    <TabPane tabId="6" className="border-0 my-0 p-0 h-100">
                                        { activeTab === '6' ?
                                        !this.state.showPromoCodeDetails ?
                                            <PromoCodes
                                                fromBEConfigDashboard={true}
                                                toggleDetails={this.toggleDetails}
                                            />
                                            :
                                            <div>
                                                <div className="mb-2">
                                                    <Breadcrumb>
                                                        <BreadcrumbItem> <a href="javascript:void(0);" onClick={() => this.toggleDetails('showPromoCodeDetails', 'PromoCodeId', null)}> <FormattedMessage id="BEConfigDashboard.PromoCodes" /> </a></BreadcrumbItem>
                                                        <BreadcrumbItem active> <FormattedMessage id="PromoCodeDetails.PromoCodeDetails" /> </BreadcrumbItem>
                                                    </Breadcrumb>
                                                </div>
                                                <PromoCodeDetails
                                                    id={this.state.promoCodeId}
                                                    fromBEConfigDashboard={true}
                                                    toggleDetails={this.toggleDetails}
                                                />
                                            </div>
                                        :''}
                                    </TabPane>

                                    <TabPane tabId="8" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '8' ?
                                            <CancelationPolicy
                                                fromBEConfigDashboard={true}
                                                channelConfigId={this.state.channelConfigId}
                                                mappedRates={this.state.rates}
                                            />
                                        : ''}
                                    </TabPane>

                                    <Authorization
                                        perform="paymentGateway:view"
                                        yes={() => (
                                            <TabPane tabId="9" className="border-0 my-0 p-0 h-100">
                                                {activeTab === '9' ?
                                                    <PaymentGateway
                                                        location={window.location}
                                                    />
                                                : ''}
                                            </TabPane>
                                        )}
                                        no={() => <div></div>}
                                    />

                                    <TabPane tabId="10" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '10' ?
                                            <ConfigWidget
                                                properties={this.state.properties}
                                                categories={this.state.rooms}
                                            />
                                        : ''}
                                    </TabPane>

                                    <TabPane tabId="11" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '11' ?
                                            <CreateURL/>
                                        : ''}
                                    </TabPane>

                                    <TabPane tabId="12" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '12' ?
                                            <GeneralSettings
                                                complexId={properties[0]?.id}
                                            />
                                        : ''}
                                    </TabPane>

                                    <TabPane tabId="13" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '13' ?
                                            !this.state.showOfferDetails ?
                                                <Offers
                                                    rates={this.state.rates}
                                                    fromBEConfigDashboard={true}
                                                    toggleDetails={this.toggleDetails}
                                                />
                                                :
                                                <div>
                                                    <div className="mb-2">
                                                        <Breadcrumb>
                                                            <BreadcrumbItem> <a href="javascript:void(0);" onClick={() => this.toggleDetails('showOfferDetails', 'offerId', null)}> <FormattedMessage id="BEConfigDashboard.Offers" /> </a></BreadcrumbItem>
                                                            <BreadcrumbItem active> <FormattedMessage id="Offers.OfferDetails" /> </BreadcrumbItem>
                                                        </Breadcrumb>
                                                    </div>

                                                    <OfferDetails
                                                        id={this.state.offerId}
                                                        isChild={true}
                                                        fromBEConfigDashboard={true}
                                                        toggleDetails={this.toggleDetails}
                                                        rates={this.state.rates}
                                                    />
                                                </div>
                                            : ''}
                                    </TabPane>

                                    <TabPane tabId="14" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '14' ?
                                            <Experiences
                                                rates={this.state.rates}
                                            />
                                        : ''}
                                    </TabPane>

                                    <TabPane tabId="15" className="border-0 my-0 p-0 h-100">
                                        {activeTab === '15' ?
                                            <ConfirmationLetters />
                                        : ''}
                                    </TabPane>

                                    
                                </TabContent>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </BlockUi>
        )
    }
}

export default injectIntl(BEConfigDashboard);


export const NavMenu = ({ title, tab, icon, toggle, toggleCollapse, validations, activeTab, perform, isOpen, toggleSearch }) => {
    return (
        perform ?
            <Authorization
                perform={perform}
                yes={() => (
                    <NavItem className="pb-2">
                        <NavLink className={activeTab === tab ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'}
                            onClick={() => { toggle(tab); if (toggleCollapse) toggleCollapse(); }} >
                            <Row>
                                <Col>{icon} <FormattedMessage id={`BEConfigDashboard.${title}`} /></Col>
                                {validations === true ? <Col className="col-1 p-0"><i className="fas fa-exclamation-triangle fa-xs text-warning" /></Col> : ''}
                                <i className="fas fa-exclamation-circle p-1" style={{ color: 'red' }} />
                            </Row>
                        </NavLink>
                    </NavItem>
                )}
                no={() => <div></div>}
            />
        :
        
            <NavItem className="pb-2">
                <NavLink className={activeTab === tab ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer'}
                    onClick={() => { toggle(tab); if (toggleCollapse) toggleCollapse(); }} >
                    <Row>
                        <Col>{icon} <FormattedMessage id={`BEConfigDashboard.${title}`} /></Col>
                        {validations === true ? <Col className="col-1 p-0"><i className="fas fa-exclamation-triangle fa-xs text-warning" /></Col> : ''}
                        {isOpen ?
                            <Button className="btn btn-sm invertbtn" style={{ fontSize: '11px' }} onClick={() => toggleSearch()}><i class="fas fa-search"></i></Button>
                            : ''}
                    </Row>
                </NavLink>
            </NavItem>
    )
}


export const SubMenu = ({ isOpen, list, selectedPropName, validations, changeSelected, selectedProp, search, changeSearch, openSearch }) => {
    const onlyId = selectedPropName === 'selectedRate' ? true : false;
    const hasErrors = validations && validations.length > 0 ? true : false;

    return(
        <>
            {
                openSearch ?
                    <Row>
                        <Col className="mt-1 mb-2 mr-2 ml-4 h-90">
                            <FormattedMessage id="BEConfigDashboard.Search">{placeholder =>
                                <Input
                                    onChange={(e) => changeSearch(e)}
                                    value={search || ''}
                                    placeholder={placeholder}
                                    bsSize="sm"
                                    id='SearchSubMenu'
                                />
                            }
                            </FormattedMessage>
                        </Col>
                    </Row>
                    : ''
            }
            <Collapse isOpen={isOpen} className="verticalScroll" style={{ maxHeight: '230px', overflowX: 'hidden' }}>
                {list && list.filter(x => !search || x.code.toUpperCase().includes(search.toUpperCase())).map((el, key) =>
                    <div className={"pl-4 pb-1 pointer overbglight" + ((onlyId ? selectedProp === el.id : selectedProp && selectedProp.id === el.id) ? ' bg-light-dark' : '')} key={key}
                        onClick={() => changeSelected(selectedPropName, (onlyId ? el.id : el))}>
                        <Row>
                            <Col style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={el.code}><i className="fas fa-xs fa-caret-right mr-2" /> {el.code} </Col>
                            {hasErrors && validations.some(valId => valId === el.id) ?
                                <Col className="col-2 p-0"> <i className="fas fa-exclamation-triangle fa-xs text-warning" /></Col>
                                : ''}
                        </Row>
                    </div>
                )}
            </Collapse>
        </>
    )
};