import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { BlankCard } from '../Base/Common/CommonUIComponents';

export class ExpandInfoPaymentDetails extends Component {

    render() {
        const { isOpen, toggle, infoTitle, info } = this.props;

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {infoTitle}
                </ModalHeader>
                <ModalBody>
                    <BlankCard>
                        <div className='d-flex align-items-center justify-content-center'>
                            <span className='text-break'>{info}</span>
                        </div>
                    </BlankCard>
                </ModalBody>
            </Modal>    
        );
    }
}