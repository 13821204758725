import React, { Component } from 'react'
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { PaymentGatewayMultiLang } from '../Base/Common/SupportedMultiLang';
import { FormattedMessage } from 'react-intl';

export default class HeyCardPaymentGatewayForm extends Component {
    render() {
        const { paymentGateway, handleData, handleEditorStateChange, handleTextChange, idx } = this.props;

        return (
            <div className='my-2'>
                <FormGroup row>
                    <Label sm={2}>
                        <FormattedMessage id="PaymentGateway.ApiKey"/>
                    </Label>
                    <Col sm={10}>
                        <Input
                            type="text"
                            maxLength="700"
                            name="accessToken"
                            id="accessToken"
                            value={paymentGateway.accessToken??''}
                            onChange={evt => handleData(evt, idx)}
                            required
                        />
                    </Col>
                </FormGroup>
                <div className='mt-2'>
                    <PaymentGatewayMultiLang
                        noButton={true}
                        data={{
                            texts: paymentGateway.paymentGatewayMultilanguals,
                            handleChange: handleTextChange,
                            handleEditorStateChange: handleEditorStateChange,
                            idx: idx
                        }}
                    />
                </div>
            </div>
        );
    }
}
