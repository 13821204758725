import React, { Component } from 'react';
import { Card, Col, Row, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { KebabMenu, ConfirmActionModal } from '../Base/Common/CommonUIComponents';
import { deleteAPI, getAPI } from '../Base/API';
import { handleNotification } from "../Base/Notification"
import EmailReservationWebhookModal from './EmailResWebhookModal';

class EmailReservationWebHooks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            webhooks: [],
            webhookModal: false
        };
    }

    componentDidMount() {
        this.getWebhooks();
    }

    getWebhooks = () => {
        this.setState({ block: true, webhookModal: false }, () =>
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });

                    }
                    else if (data.response && data.response[0].webhooks) {
                        this.setState({ error: errorMessage, block: false, webhooks: data.response[0].webhooks });
                    }
                    else {
                        this.setState({ error: errorMessage, block: false });
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, `/api/hotel/EmailReservation/v1/webhooks`));
    } 

    deleteSender = (e, id) => {
        if (e) e.preventDefault();

        this.setState({ block: true }, () => 
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="EmailRes.WebhookRemoved" />, <FormattedMessage id="generic.success" />);
                    }

                    this.setState({ deleteModal: false }, () => this.getWebhooks());
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/hotel/EmailReservation/v1/webhooks/${id}`));
    }

    toggleModal = (modal, webhook) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedWebhook: webhook
        }));
    } 

    render() {
        const { block, error, webhooks, deleteModal, selectedWebhook, webhookModal } = this.state;
        const { domains } = this.props;
        return (
            <>
                <Row className="mb-3 py-1">
                    <Col className="ml-2" style={{ fontWeight: "400" }}>
                        <FormattedMessage id="EmailResWebhooks.Webhook" />
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                        <Button className="btn btn-sm btn-host mr-2" onClick={() => this.toggleModal("webhookModal")}>
                            <i className="fa fa-plus"></i>
                        </Button>
                    </Col>
                </Row>
                <Row block={block} error={error}>
                    {webhookModal ?
                        <EmailReservationWebhookModal
                            isOpen={webhookModal}
                            toggleModal={() => this.toggleModal("webhookModal")}
                            selectedWebhook={selectedWebhook}
                            getWebhooks={this.getWebhooks}
                            domains={domains}
                        />
                        : ''}
                    {deleteModal ?
                        <ConfirmActionModal
                            modal={deleteModal}
                            toggleModal={() => this.toggleModal('deleteModal')}
                            actionFunction={(e) => this.deleteSender(e, selectedWebhook.hostname)}
                            text={<FormattedMessage id="EmailRes.ConfirmDeleteText" values={{ name: selectedWebhook.hostname }} />}
                            block={block}
                        />
                        : ''}
                    {webhooks && webhooks.length > 0 ?
                        <Col className="pt-1">
                            {webhooks.map((webhook, key) =>
                                <Card className='py-2 px-2 w-100 bg-white mb-3' style={{ border: '1px solid #bbc1c9ab', borderRadius: '5px', boxShadow: '0px 3px 15px #6F73881A' }} key={key}>
                                    <Row>
                                        <Col className="ml-2">
                                            <div style={{ height: '25%' }} className="title-sm"><FormattedMessage id="EmailResWebhooks.domain" /></div>
                                            <div style={{ fontWeight: "500" }}>{webhook.hostname} </div>
                                        </Col>
                                        <Col className="col-2 text-right d-flex justify-content-end mr-4" style={{ alignItems: "center" }}>
                                            <KebabMenu
                                                deleteFunction={() => this.toggleModal('deleteModal', webhook)}
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            )}
                        </Col>
                        : ''}
                </Row>
            </>                         
        );
    }
}

export default injectIntl(EmailReservationWebHooks)