import React, { Component } from 'react';
import { Card, CardHeader, Col, CardBody, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ActiveInactiveStatusBadge } from '../../Base/Common/CommonUIComponents';
import CustomSelect from '../../Base/Common/CustomSelect';
import BlockUi from 'react-block-ui';
import moment from 'moment';

export class RulesByRate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            rulesByRate: [],
            rulesOptions: [],
            ratesOptions: []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.yieldRules !== nextProps.yieldRules) {
            this.rulesByRate(nextProps.yieldRules);
        }
    }

    componentDidMount() {
        if (this.props.yieldRules) {
            this.rulesByRate(this.props.yieldRules);
        }
    }

    rulesByRate = (yieldRules) => {
        let rulesOptions = [], ratesOptions = [];

        const rulesByRate = yieldRules.reduce((acc, cur) => {
            if (!rulesOptions.some(el => el.value === cur.id)) {
                rulesOptions.push({ value: cur.id, label: cur.name });
            }

            cur.rateCodeIds && cur.rateCodeIds.forEach(rate => {
                const rateIndex = acc.findIndex(el => el.id === rate.code);
                const rule = (({ rateCodeIds, ...o }) => o)(cur);

                if (rateIndex === -1) {
                    ratesOptions.push({ value: rate.code, label: rate.name });

                    acc.push({
                        id: rate.code, name: rate.name,
                        rules: [rule]
                    });
                }
                else {
                    acc[rateIndex].rules.push(rule);
                }
            })
            return acc;
        }, []);

        this.setState({ rulesByRate, rulesOptions, ratesOptions });
    }

    handleRateFilter = (combo) => {
        this.setState({ block: true });
        let { rulesByRate } = this.state;

        if (combo) {
            rulesByRate.forEach(rate => {
                if (rate.id !== combo.value) {
                    rate.isVisible = false;
                }
                else rate.isVisible = true;
            })
        }
        else {
            rulesByRate = rulesByRate.map(el => ({ ...el, isVisible: true }));
        }

        this.setState({ rulesByRate, block: false });
    }

    handleRuleFilter = (combo) => {
        this.setState({ block: true });
        let { rulesByRate } = this.state;

        if (combo) {
            rulesByRate.forEach(rate => {
                rate.rules && rate.rules.forEach(rule => {
                    if (rule.id !== combo.value) {
                        rule.isVisible = false;
                    }
                    else rule.isVisible = true;
                })
            })
        }
        else {
            rulesByRate = rulesByRate.map(rate => ({ ...rate, rules: [ ...rate.rules.map(rule => ({ ...rule, isVisible: true })) ] }));
        }

        this.setState({ rulesByRate, block: false });
    }


    render() {
        const { rulesByRate, ratesOptions, rulesOptions } = this.state;

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <Row className="mb-3">
                    <Col className="col-3">
                        <CustomSelect
                            placeholder={<FormattedMessage id="RuleConfig.Rates" />}
                            options={ratesOptions}
                            icon={'fas fa-gift'}
                            onChange={(combo) => this.handleRateFilter(combo)}
                            isClearable
                        />
                    </Col>
                    <Col className="col-3 px-1">
                        <CustomSelect
                            placeholder={<FormattedMessage id="RuleConfig.Rules" />}
                            options={rulesOptions}
                            icon={'fas fa-list-ol'}
                            onChange={(combo) => this.handleRuleFilter(combo)}
                            isClearable
                        />
                    </Col>
                </Row>

                {rulesByRate && rulesByRate.map((rate, key) =>
                    rate.isVisible !== false &&
                    <Card className="shadow mb-2 p-2 border-0" key={key} >
                        <CardHeader className="card-header-gridview border-bottom-1 border-host mb-1 bg-white pb-2 pl-0">
                            <span className="border-bottom-1 border-host">
                                <i className="fas fa-gift" /> {rate.name}
                            </span>
                        </CardHeader>

                        <CardBody className="p-0 px-3">
                            {rate.rules && rate.rules.map((rule, idx) =>
                                rule.isVisible !== false &&
                                <div key={idx}>
                                    <Row className="overbglight small py-2">
                                        <Col>
                                            <div> <b> <FormattedMessage id="RuleConfig.Rule" />: </b> {rule.name} </div>
                                            <div> <b> <FormattedMessage id="RuleConfig.Type" />: </b> {this.props.getRuleType(rule)} </div>
                                        </Col>
                                        <Col>
                                            <b><FormattedMessage id="RuleConfig.Channels" />:</b>
                                            <ul className="scrollableDiv pl-3 mb-2" style={{ maxHeight: '92px', overflowY: 'auto', overflowX: 'hidden' }}>
                                                <Row>
                                                    {rule.channelInstanceIds && rule.channelInstanceIds.map((el, k) =>
                                                        <Col sm={6} key={k}>
                                                            <li> {el.name} </li>
                                                        </Col>
                                                    )}
                                                </Row>
                                            </ul>
                                        </Col>
                                        <Col className="scrollableDiv col-2" style={{ maxHeight: '110px', overflowY: 'auto' }}>
                                            {rule.dateFrom && rule.dateTo ?
                                                <>
                                                    <b><FormattedMessage id="AddRule.YieldRuleFrom" /></b> {moment(rule.dateFrom).format("YYYY-MM-DD")} <b><FormattedMessage id="AddRule.YieldRuleTo" /></b> {moment(rule.dateTo).format("YYYY-MM-DD")}
                                                </>
                                                : rule.yieldRuleDates && rule.yieldRuleDates.map((date, idx) =>
                                                <div key={idx}>
                                                    <b><FormattedMessage id="AddRule.YieldRuleFrom" /></b> {moment(date.dateFrom).format("YYYY-MM-DD")} <b><FormattedMessage id="AddRule.YieldRuleTo" /></b> {moment(date.dateTo).format("YYYY-MM-DD")}
                                                </div>
                                            )}
                                        </Col>
                                        <Col>
                                            {rule.ruleType === 'Automatic Closure' ? <div><FormattedMessage id="AddRule.AutomaticClosureMessage" /> <b>{rule.availabilityValue} {rule.availabilityType == 'Percent' ? '%' : ''}</b> <FormattedMessage id="AddRule.Rooms" /></div> : ''}
                                            {rule.ruleType === 'Availability Limit' ? <div><FormattedMessage id="AddRule.AvailabilityLimitMessage1" /> <b>{rule.applyValue}</b> <FormattedMessage id="AddRule.AvailabilityLimitMessage2" /> <b>{rule.availabilityValue} {rule.applyType == 'Percent' ? '%' : ''}</b></div> : ''}
                                            {rule.ruleType === 'Price Change' ? <div><FormattedMessage id={rule.availabilityType === "Percent" ? "AddRule.PriceChangeAvailabilityMessage" : 'AddRule.PriceChangeAvailabilityMessageAmount'} /> <b>{rule.availabilityValue} {rule.availabilityType == 'Percent' ? '%' : ''}</b> <FormattedMessage id="AddRule.PriceChangeIncrementMessage" /> <b>{rule.applyValue} {rule.applyType == 'Percent' ? '%' : ''}</b></div> : ''}
                                            {rule.ruleType === 'Release Days' ? <div><FormattedMessage id="AddRule.SellingLimit" /> <b>{rule.releaseDays}</b>  <FormattedMessage id="AddRule.BeforeCheckIn" /></div> : ''}
                                            {rule.ruleType === 'Price Override' ? <div><FormattedMessage id="AddRule.PriceOverrideReviewMessage" /> <b>{rule.applyValue} {rule.applyType == 'Percent' ? '%' : ''}</b></div> : ''}
                                        </Col>
                                        <Col className="col-1 text-right">
                                            <ActiveInactiveStatusBadge status={rule.active}/>
                                        </Col>
                                    </Row>
                                    <hr className="my-0" />
                                </div>
                            )}
                        </CardBody>
                    </Card>
                )}
            </BlockUi>
        );
    }
}

export default injectIntl(RulesByRate)