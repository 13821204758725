import React, { Component } from 'react';
import {  Col, Row, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { BlankCard, FormatAmountNumber, PaymentStatusBadge } from '../Base/Common/CommonUIComponents';
import { PaymentRefundModal } from './PaymentRefundModal';
import { PaymentCancelModal } from './PaymentCancelModal';
import { PaymentRefundsMobile } from './PaymentRefundsMobile';
import { CreatePaymentModal } from './CreatePaymentModal';
import { ExpandInfoPaymentDetails } from './ExpandInfoPaymentDetails';
import { CheckAuthorization } from '../Base/Authorization';

class DetailsMobile extends Component {

    goToVoucher = () => {
        const url = process.env.REACT_APP_HEYCARD_URL + '/VoucherOperationDetails/' + this.props.payment.paymentIntentId;

        window.open(url, '_blank');
    }

    render() {
        const { payment, updatePayment, updateRefunds, addPayment, 
            refundsTotalAmount, block, error, paymentRefundModal, paymentCancelModal, isHeyCard,
            createPaymentModal, isBankTransferOrCustom, isStripe, isIfThenPay, isRedsys, 
            isCreditCard, isMultibanco, logo, canPerformRecurringPayment, paymentStatusOptions,
            expandableEmail, expandableIntentId, expandableReciptUrl, expandableSessionId, expandableNotes } = this.props;

            const reservationActions = (
                <div>
                    <UncontrolledButtonDropdown>
                        <DropdownToggle tag="div" className="cursor-pointer py-2 pl-2">
                            <i className="fas fa-ellipsis-v"/>
                        </DropdownToggle>

                        <DropdownMenu right>
                            {isBankTransferOrCustom ?
                                <DropdownItem onClick={this.props.confirmPayment} disabled={payment.paymentStatus !== paymentStatusOptions[0].value}>
                                    <FormattedMessage id="Payments.ConfirmPayment" />
                                </DropdownItem>
                                :
                                <>
                                    {
                                        canPerformRecurringPayment ?
                                            <DropdownItem onClick={this.props.toggleCreatePaymentModal} disabled={payment.merchantIdentifier === null && payment.merchantCofTxnid === null && payment.last4 === null}>
                                                <FormattedMessage id="Payments.CreatePayment" />
                                            </DropdownItem>
                                        :
                                            ''
                                    }
                                    {
                                        isStripe ?
                                            <DropdownItem onClick={this.props.togglePaymentCancelModal} disabled={this.props.isCancelBtnDisabled(payment)}>
                                                <FormattedMessage id="Payments.Cancel" />
                                            </DropdownItem>
                                        :
                                            ''
                                    }
                                        <DropdownItem onClick={this.props.togglePaymentRefundModal} disabled={payment.paymentStatus !== paymentStatusOptions[3].value || payment.amount === refundsTotalAmount || (isIfThenPay && isMultibanco)}>
                                            <FormattedMessage id="Payments.Refund" />
                                        </DropdownItem>
                                        <DropdownItem onClick={this.props.checkPayment} disabled={isRedsys || isIfThenPay && (isCreditCard || isMultibanco) || paymentStatusOptions.some(opt => opt.isFinalSate && opt.value === payment.paymentStatus)}>
                                            <FormattedMessage id="Payments.CheckPayment" />
                                        </DropdownItem>
                                </>
                            }
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            );

        return (
            <BlankCard block={block} error={error} >
                {
                    paymentRefundModal ?
                        <PaymentRefundModal isOpen={paymentRefundModal} toggle={this.props.togglePaymentRefundModal} payment={payment} updateRefunds={updateRefunds}/>
                        :
                        <div />
                }
                {
                    paymentCancelModal ?
                        <PaymentCancelModal isOpen={paymentCancelModal} toggle={this.props.togglePaymentCancelModal} payment={payment} updatePayment={updatePayment} />
                        :
                        <div />
                }
                {
                    createPaymentModal ?
                        <CreatePaymentModal isOpen={createPaymentModal} toggle={this.props.toggleCreatePaymentModal} payment={payment} addPayment={addPayment} />
                        :
                        <div />
                }
                {
                    expandableEmail ?
                        <ExpandInfoPaymentDetails isOpen={expandableEmail} toggle={() => this.props.toggleExpandable('expandableEmail')} infoTitle={<FormattedMessage id="Payments.Email" />} info={payment.email}  />
                    :
                    <div />
                }
                {
                    expandableIntentId ?
                        <ExpandInfoPaymentDetails isOpen={expandableIntentId} toggle={() => this.props.toggleExpandable('expandableIntentId')} infoTitle={<FormattedMessage id="Payments.IntentId" />} info={payment.paymentIntentId}  />
                    :
                    <div />
                }
                {
                    expandableReciptUrl ?
                        <ExpandInfoPaymentDetails isOpen={expandableReciptUrl} toggle={() => this.props.toggleExpandable('expandableReciptUrl')} infoTitle={<FormattedMessage id="Payments.ReciptUrl" />} info={payment.receiptUrl}  />
                    :
                    <div />
                }
                {
                    expandableSessionId ?
                        <ExpandInfoPaymentDetails isOpen={expandableSessionId} toggle={() => this.props.toggleExpandable('expandableSessionId')} infoTitle={<FormattedMessage id="Payments.SessionId" />} info={payment.sessionId}  />
                    :
                    <div />
                }
                {
                    expandableNotes ?
                        <ExpandInfoPaymentDetails isOpen={expandableNotes} toggle={() => this.props.toggleExpandable('expandableNotes')} infoTitle={<FormattedMessage id="Payments.Notes" />} info={payment.notes}  />
                    :
                    <div />
                }
                <Row className="mb-3 d-flex align-items-center">
                    <Col className='col-10 pr-0 d-flex align-items-center'>
                        {logo}
                        <span className={`${isBankTransferOrCustom ? 'mb-2' : ''} ml-3`}>
                            <PaymentStatusBadge status={payment.paymentStatus} />
                        </span>
                    </Col>
                    <Col className='col-2 d-flex justify-content-end'>

                        {CheckAuthorization("payments:actions") ?
                            isHeyCard ?
                                reservationActions
                            :
                                <Button className="btn btn-sm btn-host mr-1" onClick={this.goToVoucher}>
                                    <FormattedMessage id="Payments.Voucher" />
                                </Button>
                        : ''}
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-6 d-flex flex-column mb-3">
                        <b className='mb-1'>
                            <FormattedMessage id="Payments.Reservation" />
                        </b>
                        <span>
                            {payment.resIdValue ?? '-'}
                        </span>
                    </Col>
                    <Col className="d-flex flex-column mb-3">
                        <b className='mb-1'>
                            <FormattedMessage id="Payments.Channel" />
                        </b>
                        <span>
                            {payment.channelName ? payment.channelName : payment.channelInstanceId}
                        </span>
                    </Col>
                    <Col className="col-6 d-flex flex-column mb-3">
                        <b className='mb-1'>
                            <FormattedMessage id="Payments.Amount" />
                        </b>
                        <span>
                            <FormatAmountNumber value={payment.amount} currency={payment.currencyCode} />
                        </span>
                    </Col>
                    <Col className="col-6 d-flex flex-column mb-3">
                        <b className='mb-1'>
                            <FormattedMessage id="Payments.Name" />
                        </b>
                        <span>
                            {payment.name ?? '-'}
                        </span>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-6 d-flex flex-column mb-3">
                        <b className='mb-1'>
                            <FormattedMessage id="Payments.Email" />{payment.email ? <i onClick={() => this.props.toggleExpandable('expandableEmail')} className="fas fa-expand-alt pl-2"></i> :''}
                        </b>
                        <span style={{maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            {payment.email ?? '-'}
                        </span>
                    </Col>
                    <Col className="col-6 d-flex flex-column mb-3">
                        <b className='mb-1'>
                            {isHeyCard ?
                                <FormattedMessage id="Payments.VoucherId"/>
                            :
                                <FormattedMessage id="Payments.IntentId"/>
                            }
                            {payment.paymentIntentId ? <i onClick={() => this.props.toggleExpandable('expandableIntentId')} className="fas fa-expand-alt pl-2"></i> : ''}
                        </b>
                        <span style={{maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                            {payment.paymentIntentId ?? '-'}
                        </span>
                    </Col>
                    <Col className="d-flex flex-column mb-3">
                        <b className='mb-1'>
                            <FormattedMessage id="Payments.DateCreated" />
                        </b>
                        <span>
                            {payment.createdAt ? moment(payment.createdAt).format("YYYY-MM-DD HH:mm:ss") : ''}
                        </span>
                    </Col>
                    <Col className="d-flex flex-column mb-3">
                        <b className='mb-1'>
                            <FormattedMessage id="Payments.DateUpdated" />
                        </b>
                        <span>
                            {payment.updatedAt ? moment(payment.updatedAt).format("YYYY-MM-DD HH:mm:ss") : ''}
                        </span>
                    </Col>
                    {!isBankTransferOrCustom && !isHeyCard ?
                        <Col className="col-6">
                            <div className='d-flex flex-column'>
                                <b>
                                    <FormattedMessage id="Payments.ReciptUrl" /> {payment.receiptUrl ? <i onClick={() => this.props.toggleExpandable('expandableReciptUrl')} className="fas fa-expand-alt pl-2"></i> : ''}
                                </b>
                                <Col className="col-12 p-0" style={{maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                    <span>
                                        <a href={`${payment.receiptUrl}`}>{payment.receiptUrl ?? '-'}</a>
                                    </span>
                                </Col>
                            </div>
                        </Col>
                    :''}
                </Row>
                {
                    isBankTransferOrCustom || isHeyCard ?
                        <div />
                        :
                        <div>
                            {
                                isCreditCard ?
                                    <Row className="mb-2">
                                        <Col className="col-6 d-flex flex-column mb-3">
                                            <b className='mb-1'>
                                                <FormattedMessage id="Payments.Brand" />
                                            </b>
                                            <span>
                                                {payment.brand ?? '-'}
                                            </span>
                                        </Col>
                                        <Col className="col-6 d-flex flex-column mb-3">
                                            <b className='mb-1'>
                                                <FormattedMessage id="Payments.Last4" />
                                            </b>
                                            <span>
                                                {payment.last4 ?? '-'}
                                            </span>
                                        </Col>
                                        <Col className="col-6 d-flex flex-column mb-3">
                                            <b className='mb-1'>
                                                <FormattedMessage id="Payments.ExpMonth" />
                                            </b>
                                            <span>
                                                {payment.expMonth ?? '-'}
                                            </span>
                                        </Col>
                                        <Col className="col-6 d-flex flex-column mb-3">
                                            <b className='mb-1'>
                                                <FormattedMessage id="Payments.ExpYear" />
                                            </b>
                                            <span>
                                                {payment.expYear ?? '-'}
                                            </span>
                                        </Col>
                                    </Row>
                                    :
                                    isMultibanco ?
                                        <Row className="mb-2">
                                            <Col className="col-6 d-flex flex-column mb-3">
                                                <b className='mb-1'>
                                                    <FormattedMessage id="Payments.Entity" />
                                                </b>
                                                <span>
                                                    {payment.mbEntity ?? '-'}
                                                </span>
                                            </Col>
                                            <Col className="col-6 d-flex flex-column mb-3">
                                                <b className='mb-1'>
                                                    <FormattedMessage id="Payments.Reference" />
                                                </b>
                                                <span>
                                                    {payment.mbReference ?? '-'}
                                                </span>
                                            </Col>
                                            <Col className="col-6 d-flex flex-column mb-3">
                                                <b className='mb-1'>
                                                    <FormattedMessage id="Payments.MbStartDate" />
                                                </b>
                                                <span>
                                                    {moment(payment.mbStartDate).format("YYYY-MM-DD HH:mm:ss") ?? '-'}
                                                </span>
                                            </Col>
                                            <Col className="col-6 d-flex flex-column mb-3">
                                                <b className='mb-1'>
                                                    <FormattedMessage id="Payments.MbEndDate" />
                                                </b>
                                                <span>
                                                    {moment(payment.mbEndDate).format("YYYY-MM-DD HH:mm:ss") ?? '-'}
                                                </span>
                                            </Col>
                                        </Row>
                                        :
                                        <div />
                            }
                            {payment.paymentStatus === "Rejected" || payment.paymentStatus === "requires_payment_method" ?
                                <Row className="mb-2">
                                    <Col className="col-6 d-flex flex-column mb-3">
                                        <b className='mb-1'>
                                            <FormattedMessage id="Payments.FailedReason" />
                                        </b>
                                        <span>
                                            {this.props.getErrorMessage(payment)}
                                        </span>
                                    </Col>
                                </Row>
                            : ''}
                            {payment.sessionId ?
                                <Row className="mb-3">
                                    <Col className={`${payment.notes ? 'col-6' : 'col-12'}`}>
                                        <div className='d-flex flex-column'>
                                            <b>
                                                <FormattedMessage id="Payments.SessionId" /> <i onClick={() => this.props.toggleExpandable('expandableSessionId')} className="fas fa-expand-alt pl-2"></i>
                                            </b>
                                            <Col className="col-12 p-0" style={{maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                                <span>
                                                    {payment.sessionId ?? '-'}
                                                </span>
                                            </Col>
                                        </div>
                                    </Col>
                                    {payment.notes ?
                                        <Col className="col-6 d-flex flex-column">
                                            <b>
                                                <FormattedMessage id="Payments.Notes" /> <i onClick={() => this.props.toggleExpandable('expandableNotes')} className="fas fa-expand-alt pl-2"></i>
                                            </b>
                                            <span style={{maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                                                {payment.notes}
                                            </span>
                                        </Col>
                                    : ''}
                                </Row>
                            : ''}
                            
                            
                            {payment && payment.reservationPaymentRefund && payment.reservationPaymentRefund.length > 0 &&
                                <>
                                    <Row className="mb-2">
                                        <Col>
                                            <h5 className='font-weight-bold'>
                                                <FormattedMessage id="Payments.Refunds" />
                                            </h5>
                                        </Col>
                                    </Row>
                                    <PaymentRefundsMobile reservationPaymentRefund={payment.reservationPaymentRefund} />
                                </>
                            }
                        </div>
                }
            </BlankCard>
        )
    }
}
export default injectIntl(DetailsMobile)