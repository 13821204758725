import React, { Component, useState } from 'react';
import { Row, Col, Button, Input, CustomInput, Badge } from 'reactstrap';
import { getToneOptions } from './ReferenceDataFunctions';
import AiSparkles from '../../../img/AiSparkles.png';
import { handleNotification } from '../Notification';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { postAPI } from '../API';


export const TextAreaAI = ({ inputName, required, value, handleChange, disabled, maxLength, onKeyPress, isReview, reviewText, conversation, openAiWidth = 98 }) => {
    const hasChatGpt = global.modules.some(m => m === 'OpenAI');

    const textAreaWidth = document.getElementById('textareaAI')?.offsetWidth;
    const isSmallTextArea = textAreaWidth < 700;

    const [block, setBlock] = useState(false);
    const [showOpenAI, setShowOpenAI] = useState(false);
    const [answerSize, setAnswerSize] = useState(50);
    const [tone, setTone] = useState("Neutral");


    const generateResponse = () => {
        setBlock(true);

        var chatCompletion;

        if (isReview) {
            chatCompletion = {
                input: reviewText,
                max_tokens: answerSize * 3, // one word is around 3 tokens
                tone: tone,
            };
        }
        else {
            const finalMessage = conversation?.reverse().find(obj => obj.role === 'user')?.content ?? '';

            chatCompletion = {
                input: finalMessage,
                conversation: conversation?.splice(-5, 5),
                max_tokens: answerSize * 3, // one word is around 3 tokens
                tone: tone,
            };
        }

        
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data?.errors && data.errors.length > 0) {
                handleNotification(data);
                setBlock(false);
                return;
            };

            const gptReply = data?.response && data?.response[0];

            if (gptReply) {
                const e = { target: { value: gptReply, name: inputName } }

                handleChange(e);
                setBlock(false);
            }
            else {
                handleNotification(data);
                setBlock(false);
            }
        }, `/api/hotel/FaqsManagement/v1/${isReview ? 'AnswerToReview' : 'CreateChat'}`, chatCompletion);
    }

    return (
        <BlockUi blocking={block} tag="div">
            <div className="position-relative w-100" id="textareaAI">
                <Input
                    style={{ display: 'block', minHeight: `${isSmallTextArea ? 180 : 120 }px`, paddingBottom: (showOpenAI ? '60px' : '') }}
                    type="textarea"
                    bsSize="sm"
                    className="text-sm pr-4"
                    name={inputName}
                    required={required}
                    value={value}
                    onChange={handleChange}
                    disabled={disabled}
                    onKeyPress={onKeyPress}
                    maxLength={maxLength}
                />

                {hasChatGpt && !disabled ?
                    <div className="position-absolute" id="aiCollapse" style={{ right: '0.5rem', bottom: '2px', width: (showOpenAI ? `${openAiWidth}%` : '10%') }}>
                        <Row className={(showOpenAI ? 'border-top pt-3  bg-white' : '') + ' w-100 align-items-end position-relative'} style={{ paddingBottom: '0.7rem', right: '-0.5rem' }}>
                            {showOpenAI ?
                                <Col className="col-12 col-sm-9 d-flex align-items-center pr-0">
                                    <Row className="w-100 align-items-center">
                                        <Col className={(isSmallTextArea ? 'col-12 ' : ' pr-3')} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '1rem' }}>
                                            {getToneOptions().map((option, key) =>
                                                <Badge key={key} className={tone === option.value ? "activeTemp" : "inactiveTemp"} onClick={_ => setTone(option.value)} style={{ padding: '0.30rem 1rem' }}>
                                                    <FormattedMessage id={`OpenAI.${option.label}`} />
                                                </Badge>
                                            )}
                                        </Col>
                                        <Col className={'d-flex px-2' + (isSmallTextArea ? ' pt-2 col-12' : '')}>
                                            <CustomInput
                                                type="range"
                                                name="answerSize"
                                                onChange={(e) => setAnswerSize(e.target.value)}
                                                id="answerSize"
                                                className='gptRange'
                                                min="10"
                                                max="1000"
                                                step="10"
                                                value={answerSize}
                                            />
                                            <div className='ml-2 text-muted' style={{ fontSize: 'smaller' }}>
                                                <span className='mr-1'>{answerSize}</span>
                                                <FormattedMessage id="GPT.Words" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                : ''}
                            <Col className="text-right pointer pr-0" onClick={_ => setShowOpenAI(!showOpenAI)}>
                                    {showOpenAI ?
                                        <GenerateButton
                                            generateResponse={generateResponse}
                                            isSmallButton={global.isMobile || isSmallTextArea}
                                        />
                                        : ''}

                                        <img
                                            src={AiSparkles}
                                            alt="Chat GPT"
                                            className="p-1"
                                            style={{ width: '28px', borderRadius: '5px', opacity: '90%' }}
                                        />
                            </Col>
                        </Row>

                    </div>
                : ''}
            </div>
        </BlockUi>
    )
}


const GenerateButton = ({ generateResponse, isSmallButton }) => {

    return (
        isSmallButton ?
            <Button onClick={generateResponse} className="btn-host btn-sm mr-2" style={{ fontSize: '12px', borderRadius: '50px' }}>
                <i className="fas fa-keyboard" />
            </Button>
        :
        <Button onClick={generateResponse} className="btn-host btn-sm mr-3" style={{ fontSize: '12px' }}>
            <i className="fas fa-keyboard pr-2" />
            <FormattedMessage id="GPT.Generate" />
        </Button>
    )
}