import React, { Component } from 'react';
import { Row, Col, CardHeader, Card, CardBody, Input, ButtonGroup, FormGroup, Label, UncontrolledTooltip, Button } from 'reactstrap';
import { ErrorAlert } from "../../Common/ErrorAlert";
import { getAPI, putAPI } from "../../Base/API"
import { handleNotification } from "../../Base/Notification"
import BlockUi from 'react-block-ui'
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../Utils/CustomToolTip';


export class AirbnbAvailabilitySettings extends Component {

    state = {
        error: null,
        block: false,
        blockUpdate: true,
        availabilityData: {
            /*default_min_nights: 1,
            default_max_nights: 1125,
            booking_lead_time:
                { hours: 24, allow_request_to_book: 1 },
            max_days_notice: { days: -1 },
            seasonal_min_nights: null,
            turnover_days: { days: 0 },
            day_of_week_check_in:
                [{ day_of_week: 0 }, { day_of_week: 1 }, { day_of_week: 2 }, { day_of_week: 3 }, { day_of_week: 4 }, { day_of_week: 5 }, { day_of_week: 6 }],
            day_of_week_check_out: [{ day_of_week: 0 }, { day_of_week: 1 }, { day_of_week: 2 }, { day_of_week: 3 }, { day_of_week: 4 }, { day_of_week: 5 }, { day_of_week: 6 }],
            day_of_week_min_nights: [] 
            */
        }
    };
    constructor(props) {
        super(props);
        this.formAvailability = React.createRef();
    }

    componentDidMount() {

    }

    submitReservationPreferences(evt) {
        evt.preventDefault();
        if (!this.formAvailability.current.reportValidity()) {
            return;
        }

        var hours = null;
        if (this.state.availabilityData.booking_lead_time.hours)
            hours = parseInt(this.state.availabilityData.booking_lead_time.hours);
        
        let data = {
            availability_rule: {
                turnover_days: { days: this.state.availabilityData.turnover_days.days },
                booking_lead_time: {
                    hours: hours//,
                    //allow_request_to_book: this.state.availabilityData.booking_lead_time.allow_request_to_book
                },
                max_days_notice: { days: this.state.availabilityData.max_days_notice.days }
            }

            
        };
        
        this.sendAvailabilityInfo(data);
    }

    submittripdetails(evt) {
    evt.preventDefault();
    if (!this.formAvailability.current.reportValidity()) {
        return;
    }


    let data = {
        availability_rule: {
            default_min_nights:  this.state.availabilityData.default_min_nights,
            default_max_nights: this.state.availabilityData.default_max_nights
        }
    };

    this.sendAvailabilityInfo(data);
}

    submicheckinout(evt) {
        evt.preventDefault();
        if (!this.formAvailability.current.reportValidity()) {
            return;
        }
        

        let data = {
            availability_rule: {
                day_of_week_check_in: this.state.availabilityData.day_of_week_check_in,
                day_of_week_check_out: this.state.availabilityData.day_of_week_check_out
            }
        };

        this.sendAvailabilityInfo(data);
    }

    updateForm(event){
        let name = event.target.name;
        let val = event.target.value;

        var jsonnames = name.split('.');

        if (jsonnames.length === 2) {
            this.setState(prevState => ({
                availabilityData: {
                    ...prevState.availabilityData,
                    [jsonnames[0]]: {
                        ...[jsonnames[0]],
                        [jsonnames[1]] : val
                    }
                }
            }));
        }
        else {
            this.setState(prevState => ({
                availabilityData: {
                    ...prevState.availabilityData,
                    [name]: val
                }
            }));
        }


    };

    dayofweekclick(val, arrName, fieldName, event) {
        if (this.state.availabilityData[arrName].indexOf(this.state.availabilityData[arrName].find(el => el[fieldName] === val)) >= 0) {
            this.setState(prevState => ({
                availabilityData: {
                    ...prevState.availabilityData,
                    [arrName]: this.state.availabilityData[arrName].filter(el => el[fieldName] !== val)
                }
            }));
        }
        else {
            let check = this.state.availabilityData[arrName];
            check.push({ [fieldName]: val })
            this.setState(prevState => ({
                availabilityData: {
                    ...prevState.availabilityData,
                    [arrName]: check
                }
            }));
        }
    }

    getAvailabilityInfo() {
        this.setState({ block: true }, () => 
            getAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ block: isLoading });
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data && data.response) {

                    this.setState({ availabilityData: data.response.availability_rule, blockUpdate: false  ,block: false });
                }else{
                    this.setState({ block: false });
                }
            }, '/api/Rate/Listing/v1/airbnb/' + this.props.channelInstanceId + '/availability/' + this.props.roomCategoryId)
        );
    }

    sendAvailabilityInfo(dataToSend) {
        dataToSend = { ...dataToSend.availability_rule };
        
        this.setState({ block: true }, () => 
            putAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ block: isLoading });
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockUpdate: false, block: false });

                    return;
                }
                if (data) {
                    if (data.error ) {
                        handleNotification(data);
                    }
                    else {
                        handleNotification(
                            data,
                            <FormattedMessage id="AirbnbAvailabilitySettings.AvailailitySettingsUpdatedSuccessfully" />,
                            <FormattedMessage id="AirbnbAvailabilitySettings.Success" />
                        );
                    }
                    this.setState({ priceData: data.response?.pricing_setting, block: false });
                }else{
                    this.setState({ block: false });
                }
            }, '/api/Rate/Listing/v1/airbnb/' + this.props.channelInstanceId + '/availability/' + this.props.roomCategoryId, dataToSend)
        );

    }

    render() {
        const daysOfWeekLabels = [
            <FormattedMessage id="AirbnbAvailabilitySettings.Sun" />,
            <FormattedMessage id="AirbnbAvailabilitySettings.Mon" />,
            <FormattedMessage id="AirbnbAvailabilitySettings.Tue" />,
            <FormattedMessage id="AirbnbAvailabilitySettings.Wed" />,
            <FormattedMessage id="AirbnbAvailabilitySettings.Thu" />,
            <FormattedMessage id="AirbnbAvailabilitySettings.Fry" />,
            <FormattedMessage id="AirbnbAvailabilitySettings.Sat" />
        ];

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Card className="border-0  shadow">
                    <CardHeader className="bg-white border-bottom-1">
                        <i className="fas fa-calendar-check"></i> <FormattedMessage id="AirbnbAvailabilitySettings.AirbnbAvailabilitySettings" /> {this.props.roomCategoryId}
                        <Button className=" btn btn-host  btn-sm float-right" onClick={this.getAvailabilityInfo.bind(this)}><FormattedMessage id="AirbnbAvailabilitySettings.Get" /></Button>

                    </CardHeader>
                    <CardBody>
                        <form ref={this.formAvailability} >
                            <Row className="border-bottom my-2">
                                <Col>
                                    <h6><FormattedMessage id="AirbnbAvailabilitySettings.reservationpreferences" /></h6>
                                </Col>
                                <Col>
                                    <Button disabled={this.state.blockUpdate} className=" btn btn-host  btn-sm float-right" key="availabilitySubmitbutton" onClick={this.submitReservationPreferences.bind(this)}><FormattedMessage id="AirbnbAvailabilitySettings.Save" /></Button>

                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup row>
                                        <Label for="currency" sm={4}>  <FormattedMessage id="AirbnbAvailabilitySettings.bookingleadtime" />  </Label>
                                        <Col sm={5}>
                                            <Input type="number" name="max_days_notice.days" id="maxdaysnotice" bsSize="sm" className="text-sm" value={this.state.availabilityData.max_days_notice ? this.state.availabilityData.max_days_notice.days : null} onChange={this.updateForm.bind(this)} />

                                        </Col>
                                        <Col className="col-2" >
                                            <span href="#" id="maxdaysnoticetooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                            <CustomToolTip placement="right" target="maxdaysnoticetooptip">
                                                <FormattedMessage id="AirbnbAvailabilitySettings.maxdaysnoticetooptip" />
                                            </CustomToolTip>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup row>
                                        <Label for="maxdaysnotice" sm={4}>  <FormattedMessage id="AirbnbAvailabilitySettings.turnoverdays" />  </Label>
                                        <Col sm={5}>
                                            <Input type="number" name="turnover_days.days" id="turnoverdays" bsSize="sm" className="text-sm" value={this.state.availabilityData.turnover_days ? this.state.availabilityData.turnover_days.days : null} onChange={this.updateForm.bind(this)} min="0" max="2"/>
                                                
                                        </Col>
                                        <Col className="col-2" >
                                            <span href="#" id="turnoverdaystooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                            <CustomToolTip placement="right" target="turnoverdaystooptip">
                                                <FormattedMessage id="AirbnbAvailabilitySettings.turnoverdaystooptip" />
                                                
                                    </CustomToolTip>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup row>
                                        <Label for="currency" sm={4}>  <FormattedMessage id="AirbnbAvailabilitySettings.maxdaysnotice" />  </Label>
                                        <Col sm={5}>
                                            <Input type="number" name="booking_lead_time.hours" id="bookingleadtime" pattern="([1-9]|[1][0-9]|[2][0-4]|48|72|168)" bsSize="sm" className="text-sm" value={this.state.availabilityData.booking_lead_time ? this.state.availabilityData.booking_lead_time.hours :null} onChange={this.updateForm.bind(this)} />

                                        </Col>
                                        <Col className="col-2" >
                                            <span href="#" id="bookingleadtimetooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                            <CustomToolTip placement="right" target="bookingleadtimetooptip">
                                                <FormattedMessage id="AirbnbAvailabilitySettings.bookingleadtimetooptip" />
                                    </CustomToolTip>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col className="col-12">
                                    <FormGroup row>
                                        <Label for="currency" sm={4}>  <FormattedMessage id="AirbnbAvailabilitySettings.allowrequesttobook" />  </Label>
                                        <Col sm={5}>
                                            <CustomInput type="select" name="booking_lead_time.allow_request_to_book" id="bookingleadtimeRequesttobook" pattern="([1-9]|[1][0-9]|[2][0-4]|48|72|168)" bsSize="sm" className="text-sm" value={this.state.availabilityData.booking_lead_time ? this.state.availabilityData.booking_lead_time.allow_request_to_book : null} onChange={this.updateForm.bind(this)} >
                                                <option value="0">True</option>
                                                <option value="1">False</option>
                                            </CustomInput>

                                        </Col>
                                        <Col className="col-2" >
                                            <span href="#" id="allowrequesttobooktooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                            <CustomToolTip placement="right" target="allowrequesttobooktooptip">
                                                <FormattedMessage id="AirbnbAvailabilitySettings.allowrequesttobooktooptip" />
                                            </CustomToolTip>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                */}
                            </Row>
                            
                            <Row className="border-bottom my-2">
                                <Col>
                                    <h6><FormattedMessage id="AirbnbAvailabilitySettings.tripdetails" /> </h6>
                                </Col>
                                <Col>
                                    <Button disabled={this.state.blockUpdate} className=" btn btn-host  btn-sm float-right" key="availabilitySubmitbutton" onClick={this.submittripdetails.bind(this)}><FormattedMessage id="AirbnbAvailabilitySettings.Save" /></Button>

                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup row>
                                        <Label for="currency" sm={4}>  <FormattedMessage id="AirbnbAvailabilitySettings.defaultminnights" />  </Label>
                                        <Col sm={5}>
                                            <Input type="number" name="default_min_nights" id="defaultminnights" bsSize="sm" className="text-sm" value={this.state.availabilityData.default_min_nights} onChange={this.updateForm.bind(this)} min="1" max="1125" />
                                              
                                        </Col>
                                        <Col className="col-2" >
                                            <span href="#" id="defaultminnightstooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                            <CustomToolTip placement="right" target="defaultminnightstooptip">
                                                <FormattedMessage id="AirbnbAvailabilitySettings.defaultminnightstooptip" />
                                    </CustomToolTip>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup row>
                                        <Label for="currency" sm={4}>  <FormattedMessage id="AirbnbAvailabilitySettings.defaultmaxnights" />  </Label>
                                        <Col sm={5}>
                                            <Input type="number" name="default_max_nights" id="defaultmaxnights" bsSize="sm" className="text-sm" value={this.state.availabilityData.default_max_nights} onChange={this.updateForm.bind(this)} min="1" max="1125" />

                                        </Col>
                                        <Col className="col-2" >
                                            <span href="#" id="defaultmaxnightstooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                            <CustomToolTip placement="right" target="defaultmaxnightstooptip">
                                                <FormattedMessage id="AirbnbAvailabilitySettings.defaultmaxnightstooptip" />
                                    </CustomToolTip>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="border-bottom my-2">
                                <Col>
                                    <h6><FormattedMessage id="AirbnbAvailabilitySettings.checkinout" /></h6>
                                </Col>
                                <Col>
                                    <Button disabled={this.state.blockUpdate} className=" btn btn-host  btn-sm float-right" key="availabilitySubmitbutton" onClick={this.submicheckinout.bind(this)}><FormattedMessage id="AirbnbAvailabilitySettings.Save" /></Button>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup row>
                                        <Label for="currency" sm={4}>  <FormattedMessage id="AirbnbAvailabilitySettings.dayofweekcheckin" />  </Label>
                                        <Col sm={5}>
                                            <ButtonGroup size="sm">
                                                {
                                                    daysOfWeekLabels.map((label, key) =>
                                                        <Button
                                                            key={key}
                                                            name="day_of_week_check_in.day_of_week"
                                                            disabled={this.state.blockUpdate}
                                                            color={this.state.availabilityData.day_of_week_check_in && this.state.availabilityData.day_of_week_check_in.filter(el => el.day_of_week === key).length > 0 ? 'primary' : 'light'}
                                                            onClick={this.dayofweekclick.bind(this, key, 'day_of_week_check_in', 'day_of_week')}
                                                        >
                                                            {label}
                                                        </Button>
                                                    )
                                                }
                                            </ButtonGroup>
                                        </Col>
                                        <Col className="col-2" >
                                            <span href="#" id="dayofweekcheckintooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                            <CustomToolTip placement="right" target="dayofweekcheckintooptip">
                                                <FormattedMessage id="AirbnbAvailabilitySettings.dayofweekcheckintooptip" />
                                            </CustomToolTip>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-12">
                                    <FormGroup row>
                                        <Label for="currency" sm={4}>  <FormattedMessage id="AirbnbAvailabilitySettings.dayofweekcheckout" />  </Label>
                                        <Col sm={5}>
                                            <ButtonGroup size="sm">
                                                {
                                                    daysOfWeekLabels.map((label, key) => 
                                                        <Button
                                                            key={key}
                                                            name="day_of_week_check_out.day_of_week"
                                                            disabled={this.state.blockUpdate}
                                                            color={this.state.availabilityData.day_of_week_check_out && this.state.availabilityData.day_of_week_check_out.filter(el => el.day_of_week === key).length > 0 ? 'primary' : 'light'}
                                                            onClick={this.dayofweekclick.bind(this, key, 'day_of_week_check_out', 'day_of_week')}
                                                        >
                                                            {label}
                                                        </Button>
                                                    )
                                                }
                                            </ButtonGroup>
                                        </Col>
                                        <Col className="col-2" >
                                            <span href="#" id="dayofweekcheckouttooptip"><div className="text-secondary fas fa-question-circle mt-2"></div></span>
                                            <CustomToolTip placement="right" target="dayofweekcheckouttooptip">
                                                <FormattedMessage id="AirbnbAvailabilitySettings.dayofweekcheckouttooptip" />
                                            </CustomToolTip>
                                        </Col>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Card>
            </BlockUi>
        );
    }
}