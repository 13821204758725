import React, { Component } from 'react';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import PromotionDashboard from './PromotionDashboard';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import ExpediaPromotionDashboard from './ExpediaPromotionDashboard';
import BEPromotionsDashboard from './BEPromotionsDashboard';

export class Promotions extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            hasExpedia: false,
            hasBE: false
        };
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({
                    hasExpedia: global.channelList.includes('EXPEDIA') && global.licenseLevel === 'ChannelExtras',
                    hasBE: global.channelList.includes('HEYBE') && global.modules && global.modules.some(m => m === 'BookingEngine')
                });
            },
            global.licenseLevel && global.modules ? 0 : 2500
        );
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {
        const { activeTab, hasExpedia, hasBE } = this.state;

        return (
            <Card className="border-0">
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="Promotions.Booking" />
                        </NavLink>
                    </NavItem>
                    { hasExpedia ?
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                <FormattedMessage id="Promotions.Expedia" />
                            </NavLink>
                        </NavItem>
                    : <div />}
                    {hasBE ?
                        <NavItem>
                            <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                <FormattedMessage id="Promotions.BookingEngine" />
                            </NavLink>
                        </NavItem>
                    : ''}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        {
                            <PromotionDashboard />
                        }
                    </TabPane>
                    {hasExpedia ?
                        <TabPane tabId="2">
                            <ExpediaPromotionDashboard />
                        </TabPane>
                    : ''}
                    {hasBE ?
                        <TabPane tabId="3">
                            <BEPromotionsDashboard />
                        </TabPane>
                    : ''}
                </TabContent>
            </Card>    
        );
    }
}