import React, { Component, useState } from 'react';
import { Form, Row, Col, Button } from 'reactstrap';
import { getAPI } from "../../../Base/API"
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { handleNotification } from "../../../Base/Notification";
import CustomSelect from '../../../Base/Common/CustomSelect';
import { CustomTable } from '../../../Base/Common/CustomTable';
import ExpediaReviewDetail from './ExpediaReviewDetail';
import { DateRangePicker } from 'react-dates';
import { ErrorAlert } from '../../../Common/ErrorAlert';
import BlockUi from 'react-block-ui';
import { MobileFilters } from '../../../Base/Common/MobileComponents';

class ExpediaReviewCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            reviews: null,
            block: true,
            modal: false,
            fromDate: moment().subtract(7, 'days'),
            toDate: moment(),
            selectedChannelInstance: null,
            channelInstanceList: [],
            pageSize: 10,
            hasResponseOptions: [
                { 'label': <FormattedMessage id="BookingReviewCard.AllReplies" />, 'value': null },
                { 'label': <FormattedMessage id="BookingReviewCard.Replied" />, 'value': true },
                { 'label': <FormattedMessage id="BookingReviewCard.NotReplied" />, 'value': false },
            ],
            hasResponse: null
        };

    }

    componentDidMount() {
        this.getExpediaChannelInstance();
    }

    getExpediaChannelInstance = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.length > 0) {
                    var array = [
                        { label: this.props.intl.formatMessage({ id: "generic.active" }), options: [] },
                        { label: this.props.intl.formatMessage({ id: "generic.inactive" }), options: [] },
                    ];

                    data.forEach((item) => {
                        array[item.active ? 0 : 1].options.push({ 'label': item.name + ' (' + item.propertyCode + ')', 'value': item.id })
                    });

                    const defaultOption = array[0].options[0] ?? array[1].options[1];

                    this.setState(
                        { channelInstanceList: array, selectedChannelInstance: defaultOption?.value ?? null, error: errorMessage },
                        this.getReviews
                    );
                }
            }
            else this.setState({ block: false });
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/Expedia');
    }

    getReviews = (event) => {
        if (event) event.preventDefault();

        this.setState({ block: true });

        let params = '';
        if (this.state.fromDate !== null) params += `fromDate=${moment(this.state.fromDate).format("YYYY-MM-DD")}&`;
        if (this.state.toDate !== null) params += `toDate=${moment(this.state.toDate).format("YYYY-MM-DD")}&`;
        if (this.state.hasResponse !== null) params += `hasOwnerResponse=${this.state.hasResponse}&`;

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                }

                this.setState({
                    reviews: data.response,
                    error: errorMessage,
                    block: false
                });
            }
            else this.setState({ error: errorMessage, block: false });
        }, '/api/Rate/Review/v1/expediaReviews/' + this.state.selectedChannelInstance + '/property?' + params);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    getCategoryRating = (row, category) => {
        const categoryRating = row.starRatings?.find(sr => sr.category === category);

        if (categoryRating) {
            return categoryRating.value;
        }

        return '';
    }

    selectRow = () => {
        return {
            mode: 'checkbox',
            clickToSelect: true,
            hideSelectColumn: true,
            onSelect: (row, key, rowIndex) => {
                if (row != null) {
                    this.setState(prevState => ({
                        modalData: this.state.reviews[rowIndex], modal: !prevState.modal
                    }));
                } else {
                    this.setState(prevState => ({
                        modalData: {}, key: {}, modal: !prevState.modal
                    }));
                }
            }
        };
    }

    renderSecFilters = () => {
        return (
            <Row>
                <CommonFilters
                    handleSelect={this.handleSelect}
                    hasResponseOptions={this.state.hasResponseOptions}
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    intl={this.props.intl}
                    updateDates={(startDate, endDate) => this.setState({ fromDate: startDate, toDate: endDate })}
                />
            </Row>
        )
    }

    renderMainFilter = () => {
        return (
            <CustomSelect
                icon={'fa fa-plug fa-fw'}
                isClearable isSearchable
                placeholder={<FormattedMessage id="AirbnbReviewCard.SelectChannel" />}
                value={this.state.channelInstanceList?.flatMap(ci => ci.options).find(ci => ci.value === this.state.selectedChannelInstance) || ''}
                options={this.state.channelInstanceList || []}
                onChange={(combo) => this.handleSelect('selectedChannelInstance', combo)}
                required
            />
        )
    }


    render() {
        const { fromDate, toDate, hasResponseOptions } = this.state;

        const columns = [
            {
                dataField: 'reservation.reservationIds[0].id',
                text: this.props.intl.formatMessage({ id: "BookingReviewCard.ReservationId" }),
                sort: true
            },
            {
                dataField: 'ecoFriendliness',
                text: this.props.intl.formatMessage({ id: "ExpediaReviewCard.ecoFriendliness" }),
                sort: true,
                formatter: (cell, row) => <div>{this.getCategoryRating(row, 'ecoFriendliness')}</div>
            },
            {
                dataField: 'roomComfort',
                text: this.props.intl.formatMessage({ id: "ExpediaReviewCard.roomComfort" }),
                sort: true,
                formatter: (cell, row) => <div>{this.getCategoryRating(row, 'roomComfort')}</div>
            },
            {
                dataField: 'roomCleanliness',
                text: this.props.intl.formatMessage({ id: "ExpediaReviewCard.roomCleanliness" }),
                sort: true,
                formatter: (cell, row) => <div>{this.getCategoryRating(row, 'roomCleanliness')}</div>
            },
            {
                dataField: 'service',
                text: this.props.intl.formatMessage({ id: "ExpediaReviewCard.service" }),
                sort: true,
                formatter: (cell, row) => <div>{this.getCategoryRating(row, 'service')}</div>
            },
            {
                dataField: 'overall',
                text: this.props.intl.formatMessage({ id: "ExpediaReviewCard.overall" }),
                sort: true,
                formatter: (cell, row) => <div>{this.getCategoryRating(row, 'overall')}</div>
            },
            {
                dataField: 'hotelCondition',
                text: this.props.intl.formatMessage({ id: "ExpediaReviewCard.hotelCondition" }),
                sort: true,
                formatter: (cell, row) => <div>{this.getCategoryRating(row, 'hotelCondition')}</div>
            },
            {
                dataField: 'roomAmenitiesScore',
                text: this.props.intl.formatMessage({ id: "ExpediaReviewCard.roomAmenitiesScore" }),
                sort: true,
                formatter: (cell, row) => <div>{this.getCategoryRating(row, 'roomAmenitiesScore')}</div>
            },
            {
                dataField: 'areaAroundHotel',
                text: this.props.intl.formatMessage({ id: "ExpediaReviewCard.areaAroundHotel" }),
                sort: true,
                formatter: (cell, row) => <div>{this.getCategoryRating(row, 'areaAroundHotel')}</div>
            },
            {
                dataField: 'response.status',
                text: this.props.intl.formatMessage({ id: "BookingReviewCard.Replied" }),
                sort: true,
                //formatter: (cell, row) => row.content !== null ? <CustomInput type="checkbox" id={Math.random()} disabled={true} checked={row.reply != null ? true : false} /> : ''
            }
        ]

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <div className="small">
                    <Form onSubmit={this.getReviews}>
                        {global.isMobile ?
                            <MobileFilters
                                doSearch={this.getReviews}
                                renderMainFilter={this.renderMainFilter}
                                renderSecFilters={this.renderSecFilters}
                            />
                            :
                            <Row className="align-items-center">
                                <Col className="mb-2 col-2">
                                    <CustomSelect
                                        icon={'fa fa-plug fa-fw'}
                                        isClearable isSearchable
                                        placeholder={<FormattedMessage id="AirbnbReviewCard.SelectChannel" />}
                                        value={this.state.channelInstanceList?.flatMap(ci => ci.options).find(ci => ci.value === this.state.selectedChannelInstance) || ''}
                                        options={this.state.channelInstanceList || []}
                                        onChange={(combo) => this.handleSelect('selectedChannelInstance', combo)}
                                        required
                                    />
                                </Col>

                                <CommonFilters
                                    handleSelect={this.handleSelect}
                                    hasResponseOptions={hasResponseOptions}
                                    fromDate={fromDate}
                                    toDate={toDate}
                                    intl={this.props.intl}
                                    updateDates={(startDate, endDate) => this.setState({ fromDate: startDate, toDate: endDate })}
                                />

                                <Col className="text-right">
                                    <Button className="btn btn-host shadow btn-sm" type="submit">
                                        <span className="fas fa-search"></span>
                                    </Button>
                                </Col>
                            </Row>
                        }
                    </Form>

                    <div className="mt-3">
                        <CustomTable
                            data={this.state.reviews || []}
                            columns={columns}
                            sizePerPage={this.state.pageSize}
                            shadow={false}
                            exportCSV={false}
                            selectRow={this.selectRow()}
                        />
                    </div>

                    {this.state.modal ?
                        <ExpediaReviewDetail
                            modal={this.state.modal}
                            review={this.state.modalData}
                            toggleModal={() => this.setState({ modal: false })}
                            channelInstance={this.state.selectedChannelInstance}
                            getReviews={this.getReviews}
                        />
                        : ''}
                </div>
            </BlockUi>
        );
    }
}

export default injectIntl(ExpediaReviewCard)


const CommonFilters = ({ handleSelect, hasResponseOptions, fromDate, toDate, intl, updateDates }) => {
    const [focused, setFocused] = useState(false);

    return (
        <>
            <Col className="mb-2 col-12 col-lg-2">
                <CustomSelect
                    icon={'far fa-comment'}
                    isClearable isSearchable
                    placeholder={<FormattedMessage id="AirbnbReviewCard.SelectStatus" />}
                    options={hasResponseOptions}
                    onChange={(combo) => handleSelect('hasResponse', combo)}
                />
            </Col>
            <Col className="mb-2 col-12 col-lg-3">
                <DateRangePicker
                    startDate={fromDate ? moment(fromDate) : null}
                    startDateId="fromDate"
                    isOutsideRange={day => day > moment()}
                    endDate={toDate ? moment(toDate) : null}
                    endDateId="toDate"
                    onDatesChange={({ startDate, endDate }) => updateDates(startDate, endDate)}
                    focusedInput={focused}
                    onFocusChange={focusedInput => setFocused(focusedInput)}
                    small={true}
                    numberOfMonths={1}
                    showDefaultInputIcon={true}
                    renderMonthElement={({ month }) => moment(month).locale(intl.locale).format('MMMM YYYY')}
                />
            </Col>
        </>
    )
}