import React, { Component } from "react";
import { Row, Col, Button, Form } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { postAPI } from "../../Base/API";
import { CommonHelper, SelectRate, StyledCard } from "../../Base/Common/CommonUIComponents";
import { handleNotification } from "../../Base/Notification";
import { DateRangePicker } from 'react-dates';
import moment from 'moment';
import Calendar from "./CalendarPms";
import CustomToolTip from '../../../Utils/CustomToolTip';

export class ComparePmsData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			block: false,
			startDate: moment(),
			endDate: moment().add(15, 'day'),
			rateId: null,
			disableButtons: true,
			occupationSelect: [],
			totalMonths: this.getMonths(moment(), moment().add(2, 'year')),
			selectedOcc: null
		};
	}

	getInventory = (e) => {
		if (e) e.preventDefault();
		const { endDate, rateId } = this.state;
		const startDate = moment(this.state.startDate).format("YYYY-MM-DD");

		var params = `&rateCodeId=${rateId}`;

		this.setState({ block: true }, () =>
			postAPI(result => {
				const { data, error } = result;
				const errorMessage = [];
				let occupationSelect = [];
				if (error) {
					errorMessage.push({
						message: error.message,
						stack: error.stack,
						messageType: "danger"
					});
					this.setState({ error: errorMessage, block: false });
					return;
				}
				if (data) {
					var totalMonths = this.getMonths(startDate, endDate);

					if (data.errors && data.errors.length > 0) {
						handleNotification(data);
						this.setState(({ block: false, error: errorMessage, inventory: [], firstDay: startDate, totalMonths }));
						return;
					}
					if (data.response && data.response.length > 0 && data.response[0].inventory && data.response[0].inventory.length > 0
						&& data.response[0].inventory[0].availability && data.response[0].inventory[0].availability.length > 0 && data.response[0].inventory[0].availability[0].cmPrice && data.response[0].inventory[0].availability[0].cmPrice.length > 0) {
						data.response[0].inventory[0].availability[0].cmPrice.forEach((dt) => 
							occupationSelect.push({
								value: `${dt.position}`,
								label: <div>
									<span className='mr-2' id='ocuppationAdults'>{dt.adults}</span>
									<span className='mr-2'><i className="fas fa-user" /></span>
									<span className='mr-2' id='ocuppationChildren'>{dt.children}</span>
									<span><i className="fas fa-child" /></span>
								</div>
							})
						);
						var diffPriceList = []; 
						var diffAvailList = [];
						data.response[0].inventory[0].availability.forEach((av) => {
							occupationSelect.forEach(({ value }) => {
								var price = av.cmPrice.filter(a => a.position == value)[0].price;
								var pricepms = av.pmsPrice.filter(a => a.position == value)[0].price;
								if (price !== pricepms) {
									diffPriceList.push({ value, hotelDate: moment(av.hotelDate).format("DD/MM/YYYY"), price, pricepms })
								}
							})
							if (av.cmAvailability !== av.pmsAvailability) {
								diffAvailList.push({ hotelDate: moment(av.hotelDate).format("DD/MM/YYYY"), cmAvailability: av.cmAvailability, pmsAvailability: av.pmsAvailability })
							}
						})
						diffPriceList = [...new Set(diffPriceList.map((item) => item.hotelDate))];

						this.setState({
							error: errorMessage, block: false, inventory: data.response[0].inventory[0], firstDay: startDate, totalMonths, occupationSelect, disableButtons: false,
							diffPriceList, diffAvailList, roomCat: data.response[0].roomCategoryId
						});
					}
					else {
						this.setState({ block: false, inventory: [], firstDay: startDate, selectedChannel: null, totalMonths })
					}
				}
				else this.setState({ error: errorMessage, block: false });
			}, `/api/hotel/v1/pmsInvokeAction/Inventory?fromDate=${moment(startDate).format("MM/DD/YYYY")}&toDate=${moment(endDate).format("MM/DD/YYYY")}` + params)
		)
	}

	getMonths = (startDate, endDate) => {
		const months = [];
		var dateStart = moment(startDate);

		while (endDate > dateStart || dateStart.format('M') === endDate.format('M')) {
			months.push(dateStart.format('YYYY-MM'));
			dateStart.add(1, 'month');
		}

		return months;
	}

	handleSelect = (name, combo) => {
		this.setState({
			[name]: combo ? combo.value : null, selectedOcc: null
		}, () => {
			if (this.state.endDate != null && this.state.startDate != null && combo != null) this.getInventory();
		})
	}

	refreshPms = (e) => {
		if (e) e.preventDefault();
		const { roomCat, startDate, endDate } = this.state;
		this.setState({ block: true }, () =>

			postAPI(result => {
				const { data, error } = result;
				const errorMessage = [];

				if (error) {
					errorMessage.push({
						message: error.message,
						stack: error.stack,
						messageType: "danger"
					});
					this.setState({ error: errorMessage, block: false });
					return;
				}
				if (data) {
					if (data.errors && data.errors.length > 0) {
						handleNotification(data);
						this.setState(({ block: false, error: errorMessage }));
						return;
					}
					else {
						handleNotification(data, <FormattedMessage id="CalendarPms.SuccessRefresh" />, <FormattedMessage id="General.Success" />);
						this.setState({ error: errorMessage, block: false });
					}
				}
				else this.setState({ error: errorMessage, block: false });
			}, `/api/hotel/v1/pmsInvokeAction/category/${roomCat}?fromDate=${moment(startDate).format("YYYY/MM/DD")}&toDate=${moment(endDate).format("YYYY/MM/DD")}`)
		)
	}

	render() {
		const { block, error, inventory, occupationSelect, disableButtons, endDate, startDate, rateId, selectedOcc, diffAvailList, diffPriceList } = this.state;

		return (
			<StyledCard block={block} error={error} icon="fas fa-balance-scale" title="navbar.ComparePmsData">
				<Form onSubmit={this.getInventory}>
					<Row className="mb-2">
						<Col className="col-3">
							<SelectRate
								icon={'fa fa-bed fa-fw'}
								name={'rateId'}
								isMulti={false}
								onChangeFunc={this.handleSelect}
								placeholder={<FormattedMessage id="AnalyticsDashboard.SelectRate" />}
								value={rateId}
								required
							/>
						</Col>
						<Col className="col-3">
							<DateRangePicker
								startDate={startDate ? moment(startDate).locale(moment.locale()) : startDate}
								startDateId="startDate"
								isOutsideRange={day => day > moment().add(1, 'year') || day.isBefore(moment(),'day')
									|| (this.state.focusedInput == "endDate" && day > moment(startDate).add(6, 'month'))}
								endDate={endDate ? moment(endDate).locale(moment.locale()) : endDate}
								endDateId="endDate"
								onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate }, () => { if (endDate != null && startDate != null && rateId != null && this.state.focusedInput == null) this.getInventory(); })}
								focusedInput={this.state.focusedInput}
								onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
								small={true}
								numberOfMonths={2}
								showDefaultInputIcon={true}
								renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
								required
							/>
						</Col>
						<Col className="text-right">
							{diffAvailList && diffAvailList.length > 0 ?
								<>
									<Button className="btn btn-sm btn-host mr-3" id="refreshPmsButton" onClick={this.refreshPms} >
										<i className="fas fa-upload"></i>
									</Button>
									<CustomToolTip placement="bottom" target={"refreshPmsButton"}>
										<FormattedMessage id="CalendarPms.RefreshPmsInfo" />
									</CustomToolTip>
								</>
								: ''
							}
							<Button className="btn btn-sm btn-host" type="submit">
								<i className="fa fa-search" />
							</Button>
						</Col>
						<CommonHelper help={<FormattedMessage id="CalendarPms.Help" />} id={'ComparePmsHelp'} />
					</Row>
				</Form>

				<Row>
					<Col>
						<Calendar
							inventory={inventory}
							firstDay={this.state.firstDay}
							totalMonths={this.state.totalMonths}
							occupationSelect={occupationSelect}
							disableButtons={disableButtons}
							startDate={startDate}
							endDate={endDate}
							selectedOcc={selectedOcc}
							diffPriceList={diffPriceList}
							diffAvailList={diffAvailList}
						/>
					</Col>
				</Row>

			</StyledCard>
		)
	}
}

export default injectIntl(ComparePmsData);