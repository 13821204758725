import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import { postAPI } from '../../../Base/API';
import { DateRangePicker } from 'react-dates';
import ChartCard from '../../../Booking Engine/Common/ChartCard';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { CustomTable } from '../../../Base/Common/CustomTable';


class Helpers extends Component {

    constructor(props) {
        super(props);

        this.state = {
        }
    }

    componentDidMount() {
        this.getEventsOverTime();
    }

    componentDidUpdate(prevProps){
        if ( prevProps.productType !== this.props.productType ) {
            this.getEventsOverTime();
        }
    }

    getEventsOverTime = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });

        const body = {
            usageType: this.props.usageType,
            productType: this.props.productType,
            startDate: moment(this.props.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
            dimensions: ["eventName", "pagePath", "customEvent:help_id"],
            metrics: ["eventCount"],
            aggregation: [],
            dimensionFilter: {
                filter: {
                    fieldName: "eventName",
                    inListFilter: {
                        values: ["help", "help_click"]
                    }
                }
            }
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const json = JSON.parse(data);
                this.createChartData(json);
            }
            else this.setState({ block: false });
        }, `/api/hotel/v1/googleanalytics`, body);
    }

    createChartData = (json) => {
        const tableData = [];

        var arrays =
        {
            helpChart: [["Page Path"]],
            helpClickChart: [["Page Path"]]
        }


        json.rows && json.rows.forEach((row) => {
            const arrayName = row.dimensionValues[0].value === 'help' ? 'helpChart' : 'helpClickChart'
            const chart = arrays[arrayName];
            const helpId = row.dimensionValues[2].value;
            const pagePath = row.dimensionValues[1].value;
            const totalClicks = parseInt(row.metricValues[0].value);

            const pageIndex = chart.findIndex(c => c[0] === pagePath);
            let helpIndex = chart[0].findIndex(column => column === helpId);


            //adiciona helpId (section)
            if (helpIndex === -1) {
                chart.forEach((row, idx) => {
                    if (idx === 0) row.push(helpId);
                    else row.push(0); //adicionar valor em todas as linhas para que tenham todas o mesmo length
                })

                helpIndex = chart[0].length - 1;
            }

            //adiciona page path (column)
            if (pageIndex === -1) {
                const help = new Array(chart && chart[0] ? chart[0].length : 1).fill(0);
                help[0] = row.dimensionValues[1].value;
                help[helpIndex] = totalClicks;

                chart.push(help);
            }
            else {
                chart[pageIndex][helpIndex] += parseInt(totalClicks);
            }



            //Table data
            const tableIndex = tableData.findIndex(r => r.pagePath === pagePath && r.helpId === helpId);

            if (tableIndex !== -1) {
                if (row.dimensionValues[0].value === 'help') {
                    tableData[tableIndex].helpClicks += totalClicks;
                }
                else tableData[tableIndex].seeMoreClicks += totalClicks;
            }
            else {
                tableData.push({
                    pagePath: pagePath,
                    helpId: helpId,
                    helpClicks: row.dimensionValues[0].value === 'help' ? totalClicks : 0,
                    seeMoreClicks: row.dimensionValues[0].value === 'help_click' ? totalClicks : 0
                });
            }
        })


        this.setState({
            helpChart: arrays.helpChart,
            helpClickChart: arrays.helpClickChart,
            tableData,
            block: false
        });
    }


    render() {
        const { helpChart, helpClickChart, tableData } = this.state;

        const columns = [
            {
                dataField: 'pagePath',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.PagePath" }),
                sort: true
            },
            {
                dataField: 'helpId',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.HelpId" }),
                sort: true
            },
            {
                dataField: 'helpClicks',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.HelpClicks" }),
                sort: true
            },
            {
                dataField: 'seeMoreClicks',
                text: this.props.intl.formatMessage({ id: "PlatformDeepDive.SeeMoreClicks" }),
                sort: true
            },
        ];

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Row className="align-items-center mb-4 pt-1">
                    <Col>
                        <DateRangePicker
                            startDate={this.props.startDate}
                            startDateId="your_unique_start_date_id_announcement"
                            isOutsideRange={(day) => this.props.isOutsideDate(day)}
                            endDate={this.props.endDate}
                            endDateId="your_unique_end_date_id_announcement"
                            onDatesChange={({ startDate, endDate }) => this.props.handleDates(startDate, endDate)}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            numberOfMonths={2}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col className="text-right col-1">
                        <Button className="float-right  btn-sm btn-host btn" onClick={this.getEventsOverTime}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>


                <Row>
                    <Col>
                        <ChartCard
                            chartType='ColumnChart'
                            data={helpChart}
                            options={{
                                legend: "none",
                                hAxis: { minValue: 0, viewWindow: { min: 0 }, viewWindowMode: "explicit" },
                                isStacked: true,
                            }}
                            title={<FormattedMessage id="PlatformDeepDive.EventCountByHelp" />}
                            height="30vh"
                        />
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col>
                        <ChartCard
                            chartType='ColumnChart'
                            data={helpClickChart}
                            options={{
                                legend: "none",
                                hAxis: { minValue: 0, viewWindow: { min: 0 }, viewWindowMode: "explicit" },
                                isStacked: true,
                            }}
                            title={<FormattedMessage id="PlatformDeepDive.EventCountByHelpClick" />}
                            height="30vh"
                        />
                    </Col>
                </Row>

                <Card body className="shadow border-0 h-100" >
                    <Row>
                        <Col className="text-xs font-weight-bold text-secondary text-uppercase mb-3">
                            <FormattedMessage id="PlatformDeepDive.TotalClicks" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CustomTable
                                data={tableData || []}
                                columns={columns}
                                showTotal={true}
                                remote={false}
                                search={true}
                                hideSizePerPage={false}
                            />
                        </Col>
                    </Row>
                </Card>
            </BlockUi>
        );
    }

};
export default injectIntl(Helpers);