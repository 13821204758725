import React, { Component } from 'react';
import { Button, Col, CustomInput, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export class RatesPanel extends Component {

    render() {
        const { rc, selectRatesByRoomCat, handleRateChange, rateCodeIds } = this.props;
        const areAllSelected = rc.rates?.filter(rate => rate.hasOwnProperty('hidden') ? rate.hidden === false ? true : false : true).every(rate => rateCodeIds.some(el => el === rate.id));


        return (
            <Row className="mb-1">
                <Col>
                    <Row className="mb-1 pl-2 overbglight">
                        <Col>
                            <span>
                                <b>
                                    {rc.label}
                                </b>
                                <Button className="btn btn-sm btn-host float-right" onClick={() => selectRatesByRoomCat(rc, areAllSelected)}>
                                    {areAllSelected ? <FormattedMessage id="MajorBulkUpdateModal.RemoveAll" /> : <FormattedMessage id="MajorBulkUpdateModal.SelectAll" />}
                                </Button>
                            </span>
                        </Col>
                    </Row>
                    {rc.rates && rc.rates.map((r, key2) => {
                        const isSelected = areAllSelected || rateCodeIds.some(rateCodeId => rateCodeId === r.value);

                        return !r.hidden && <Row key={key2} className="mb-1 px-2 overbglight">
                            <Col className="col-6">
                                <CustomInput
                                    type="checkbox"
                                    id={`${r.value}`}
                                    name={`${r.value}`}
                                    onChange={(e) => handleRateChange(e, false)}
                                    checked={isSelected}
                                >
                                    {r.label}
                                </CustomInput>
                            </Col>
                        </Row>
                    })}
                </Col>
            </Row>    
        );
    }
}