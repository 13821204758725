import React, { Component } from 'react';
import { Row, Col, CardHeader, Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Input, InputGroup, FormGroup, Label } from 'reactstrap';
import classnames from 'classnames';
import { LicenseForm } from './LicenseForm';
import { HotelConfigForm } from './HotelConfigForm';
import { HotelInformationForm } from './HotelInformationForm';
import { HotelMediaForm } from './HotelMediaForm';
import { HotelFacilitiesAndPolicies } from './HotelFacilitiesAndPolicies';
import Authorization from '../../Base/Authorization';
import { getAPI, putAPI } from "../../Base/API"
import { ErrorAlert } from "../../Common/ErrorAlert";
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { handleNotification } from "../../Base/Notification"

export class PropertySetup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            activeAddressTab: '1',
            HotelData: {},
            error: null,
            block: true,
            email: ''
        };
        this.toggle = this.toggle.bind(this);
        this.updateHotel = this.updateHotel.bind(this);
        this.handleIntField = this.handleIntField.bind(this);
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ HotelData: data });
            }
            this.setState({ block: false });
        }, '/api/hotel/v1/GetUserHotel');
    }

    updateHotel() {
        this.setState({ block: true });

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="PropertySetup.SaveSuccessMessage" />, <FormattedMessage id="General.Success"/>);
                    this.setState({ HotelData: data.response[0] });
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/hotel/v1/UpsertHotelDetails', JSON.stringify(this.state.HotelData));
    }

    handleIntField(evt) {
        if (evt && evt.target) {
            const { HotelData } = this.state;
            HotelData[evt.target.name] = evt.target.value ? parseFloat(evt.target.value) : null;
            this.setState({ HotelData });
        }
    }

    handleSwitch = (evt) => {
        const { name, checked } = evt.target;
        const { HotelData } = this.state;

        HotelData[name] = checked;
        this.setState({ HotelData });
    }

    handleEmails = (evt, type, email, property) => {
        evt.preventDefault();
        var emails = this.state.HotelData[property];
        var array = emails ? emails.split(';') : [];

        if (type === "add") {
            if (this.state.email && array.indexOf(this.state.email) < 0) {
                array.push(this.state.email);
            }
        }
        else {
            array = array.filter(e => e !== email)
        }
        emails = array.length > 0 ? array.join(';') : '';

        this.setState({
            HotelData: {
                ...this.state.HotelData,
                [property]: emails
            }
        });
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState({
            [name]: value
        });
    }

    handleText = (evt) => {
        if (evt && evt.target) {
            const { HotelData } = this.state;
            const { name, value } = evt.target;

            HotelData[name] = value;
            this.setState({ HotelData });
        }
    }

    handleCombo = (name, combo) => {
        this.setState({
            HotelData: {
                ...this.state.HotelData,
                [name]: combo ? combo.value : null
            }
        });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleAddress(tab) {
        if (this.state.activeAddressTab !== tab) {
            this.setState({
                activeAddressTab: tab
            });
        }
    }

    render() {
        return (
            <Card>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                <FormattedMessage id="PropertySetup.BasicHotelInformation" /> 
                            </NavLink>
                        </NavItem>
                        {/*
                        <Authorization
                            perform="HotelConfig:facilitiesPolicies:view"
                            yes={() => (
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}
                                    >
                                        <FormattedMessage id="PropertySetup.Facilities&policies" />
                                    </NavLink>
                                </NavItem>

                            )}
                            no={() => <div></div>}
                        />
                        */}
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                <FormattedMessage id="PropertySetup.Media" /> 
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                                <FormattedMessage id="PropertySetup.License" /> 
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                                <FormattedMessage id="PropertySetup.Configuration" /> 
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <Row>
                                <Col sm="12" >
                                    <Card className="small shadow border-0">
                                        <CardHeader className="bg-white border-bottom"><div><span className="fas fa-hotel"> </span> <FormattedMessage id="PropertySetup.HotelInformation" /> </div></CardHeader>
                                        <CardBody className="pb-0">
                                            <HotelInformationForm hoteldata={this.state.HotelData} />
                                        </CardBody>
                                    </Card>
                                    <br />
                                    <Card className="small shadow border-0">
                                        <CardHeader className="bg-white border-bottom">
                                            <div><span className="fas fa-address-book"> </span> <FormattedMessage id="PropertySetup.ContactInformation" /> </div>
                                        </CardHeader>
                                        <CardBody className="pb-1">
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row >
                                                        <Label for="email" sm={4}> <FormattedMessage id="PropertySetup.Email" /> </Label>
                                                        <Col sm={8}>
                                                            <InputGroup size="sm">
                                                                <FormattedMessage id="PropertySetup.Email">{placeholder =>
                                                                    <Input name="email" placeholder={placeholder} readOnly defaultValue={this.state.HotelData.email} />
                                                                }</FormattedMessage>
                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup row >
                                                        <Label for="phone" sm={4}> <FormattedMessage id="PropertySetup.Phone" /> </Label>
                                                        <Col sm={8}>
                                                            <InputGroup size="sm">
                                                                <FormattedMessage id="PropertySetup.Phone">{placeholder =>
                                                                    <Input name="phone" placeholder={placeholder} readOnly defaultValue={this.state.HotelData.phone} />
                                                                }</FormattedMessage>
                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-12">
                                                    <FormGroup row >
                                                        <Label for="AddressLine" sm={2}><FormattedMessage id="AddressForm.AddressLine" /> </Label>
                                                        <Col sm={10}>
                                                            <InputGroup size="sm">
                                                                <FormattedMessage id="AddressForm.AddressLine">{placeholder =>
                                                                    <Input name="AddressLine" placeholder={placeholder} readOnly defaultValue={this.state.HotelData.address1} />
                                                                }</FormattedMessage>
                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup row>
                                                        <Label for="CityName" sm={4}> <FormattedMessage id="AddressForm.CityName" /> </Label>
                                                        <Col sm={8}>
                                                            <InputGroup size="sm">
                                                                <FormattedMessage id="AddressForm.CityName">{placeholder =>
                                                                    <Input name="CityName" placeholder={placeholder} readOnly defaultValue={this.state.HotelData.city} />
                                                                }</FormattedMessage>
                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-6">
                                                    <FormGroup  row>
                                                        <Label for="PostalCode" sm={4}> <FormattedMessage id="AddressForm.PostalCode" /> </Label>
                                                        <Col sm={8}>
                                                            <InputGroup size="sm">
                                                                <FormattedMessage id="AddressForm.PostalCode">{placeholder =>
                                                                    <Input name="PostalCode" placeholder={placeholder} readOnly defaultValue={this.state.HotelData.zipCode} />
                                                                }</FormattedMessage>
                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-6">
                                                    <FormGroup  row>
                                                        <Label for="CountryName" sm={4}> <FormattedMessage id="AddressForm.CountryName" /> </Label>
                                                        <Col sm={8}>
                                                            <InputGroup size="sm">
                                                                <FormattedMessage id="AddressForm.CountryName">{placeholder =>
                                                                    <Input name="CountryName" placeholder={placeholder} readOnly defaultValue={this.state.HotelData.countryCode} />
                                                                }</FormattedMessage>
                                                            </InputGroup>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col></Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                    
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col>
                                    {/*<button className="btn btn-host btn-sm text-white float-right "  > <FormattedMessage id="PropertySetup.Save" /> </button> */} 
                                </Col>
                            </Row>
                        </TabPane>
                        <Authorization
                            perform="HotelConfig:facilitiesPolicies:view"
                            yes={() => (
                                <TabPane tabId="2">
                                    <HotelFacilitiesAndPolicies />
                                </TabPane>
                            )}
                            no={() => <div></div>}
                        />
                        <TabPane tabId="3">
                            <Card className="small shadow border-0">
                                <CardHeader className="border-bottom bg-white" ><div><span className="fas fa-sitemap"> </span> <FormattedMessage id="PropertySetup.MultimediaDescription" /> </div></CardHeader>
                                
                                <CardBody><HotelMediaForm hoteldata={this.state.HotelData}/></CardBody>
                            </Card>
                        </TabPane>
                        <TabPane tabId="4">
                            <Card className="small shadow border-0">
                                <CardHeader className="border-bottom bg-white">
                                    <div>
                                        <span className="fas fa-file-signature" />
                                        <FormattedMessage id="PropertySetup.LicenseInformation" />
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <LicenseForm hoteldata={this.state.HotelData} />
                                </CardBody>
                            </Card>
                        </TabPane>
                        <TabPane tabId="5">
                            <HotelConfigForm hoteldata={this.state.HotelData} updateHotel={this.updateHotel} handleIntField={this.handleIntField} handleSwitch={this.handleSwitch}
                                handleEmails={this.handleEmails} handleChange={this.handleChange} handleCombo={this.handleCombo} handleText={this.handleText} />
                        </TabPane>
                    </TabContent>
                </BlockUi>
            </Card>
        );
    }
}