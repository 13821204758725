import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../../../Base/Common/CustomSelect';

export class Step1 extends Component {

    render() {
        const { handleHotel, handleOperationMode, hotels, operationMode, operationModes, selectedHotel } = this.props;

        return (
            <Row className="mb-2">
                <Col className="col-2">
                    <FormattedMessage id="ChangeOperationMode.Hotel" />
                </Col>
                <Col className="col-4">
                    <CustomSelect options={hotels} onChange={handleHotel} value={hotels.find(htl => htl.value === selectedHotel?.hotelId)} />
                </Col>
                <Col className="col-2">
                    <FormattedMessage id="ChangeOperationMode.OperationMode" />
                </Col>
                <Col className="col-4">
                    <CustomSelect options={operationModes} onChange={handleOperationMode} value={operationModes.find(md => md.value === operationMode)} />
                </Col>
            </Row>
        );
    }
}