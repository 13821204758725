import moment from 'moment';
import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { injectIntl } from 'react-intl';
import { Button, Card, Col, Row } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard, SelectChannelConfig, SelectHotel, ActiveInactiveStatusBadge } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from "../../Base/Notification";
import { HotelChannelInfoDetail } from "./HotelChannelInfoDetail";
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';

class HotelChannelInfo extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.getHotelListFiltered = this.getHotelListFiltered.bind(this);
        this.updateChannelStatus = this.updateChannelStatus.bind(this);
        this.state = {
            block: true,
            status: null,
            hotelList: [],
            startDate: moment().subtract(7, 'day'),
            endDate: moment(),
            channelCode: null,
            modal: false,
            modalData: null,
            hotelId: null,
            channelConfigState: null
        }
    }    

    componentDidMount() {
        this.getHotelList('/api/hotel/v1/ChannelList');
    }

    getHotelListFiltered() {
        const { channelCode, hotelId, channelConfigState, status } = this.state;

        this.getHotelList(`/api/hotel/v1/ChannelList?channelCode=${channelCode !== null ? channelCode : ''}&hotelId=${hotelId !== null ? hotelId : ''}${channelConfigState !== null ? `&channelConfigState=${channelConfigState}` : ''}&status=${status !== null ? status : ''}`);
    }

    getHotelList(url) {
        this.setState({ block: true });                
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors.length > 0) {
                    handleNotification(data, "search", 'Success');
                }
                else {
                    this.setState({ hotelList: data.data, block: false });
                }
            }
        }, url);
    }

    setFilterSelectedChannel = (name, combo) => {        
        const val = combo ? combo.value : null
        this.setState({ [name]: val });
    }

    toggleModal() {
        this.setState(prevstate => ({
            modal: !prevstate.modal,
            modalData: null
        }));
    }

    updateChannelStatus(channelInstance) {
        const { hotelList } = this.state;
        const idx = channelInstance ? hotelList.indexOf(hotelList.find(h => h.channelInstanceId === channelInstance.id)) : -1;
        if (idx >= 0) {
            hotelList[idx].status = channelInstance.active;
            this.setState({ hotelList });
        }
    }

    render() {
        const { status } = this.state;

        const helpContent =
            <div>
                TODO
                <hr />
            </div>

        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "HotelChannelInfo.Hotel" }),
                sort: true
            },
            {
                dataField: 'commercialName',
                text: this.props.intl.formatMessage({ id: "HotelChannelInfo.CommercialName" }),
                sort: true
            },
            {
                dataField: 'channelCode',
                text: this.props.intl.formatMessage({ id: "HotelChannelInfo.ChannelCode" }),
                sort: true
            },
            {
                dataField: 'propertyCode',
                text: this.props.intl.formatMessage({ id: "HotelChannelInfo.PropertyCode" }),
                sort: true
            },
            {
                dataField: 'channelConfigState',
                text: this.props.intl.formatMessage({ id: "HotelChannelInfo.State" }),
                sort: true,
                formatter: (cell, row) => {
                    const option = stateOptions.find(option => option.value === row?.channelConfigState);
                    return option ? option.label : row?.channelConfigState
                },
                csvFormatter: (cell, row) => {
                    const option = stateOptions.find(option => option.value === row?.channelConfigState);
                    return option ? option.label : row?.channelConfigState ? row?.channelConfigState : ''
                },
            },
            {
                dataField: 'updatedAt',
                text: this.props.intl.formatMessage({ id: "HotelChannelInfo.UpdatedAt" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm") : '',
                csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm") : ''
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "home.status" }),
                sort: true,
                formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell} />,
                csvFormatter: (cell) => cell ? this.props.intl.formatMessage({ id: "generic.active" }) : this.props.intl.formatMessage({ id: "generic.inactive" })
            }
        ];

        const stateOptions = [
            {
                value: 'Pending Channel Verification',
                label: this.props.intl.formatMessage({ id: "ChannelConfigState.PendingChannelVerification" })
            },
            {
                value: 'Pending Mapping Verification',
                label: this.props.intl.formatMessage({ id: "ChannelConfigState.PendingMappingVerification" })
            },
            {
                value: 'Verified',
                label: this.props.intl.formatMessage({ id: "ChannelConfigState.Verified" })
            },
            {
                value: 'Decommissioned',
                label: this.props.intl.formatMessage({ id: "ChannelConfigState.Decommissioned" })
            }
        ];

        const statusOptions = [
            {
                value: true,
                label: <FormattedMessage id="ChannelConfigState.Active" />
            },
            {
                value: false,
                label: <FormattedMessage id="ChannelConfigState.Inactive" />
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row) => this.setState({ modal: true, modalData: row })
        };

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-exclamation-circle'} title={<FormattedMessage id="HotelChannelInfo.Title" />} help={helpContent}>
                {
                    this.state.modal ?
                        <HotelChannelInfoDetail
                            modal={this.state.modal}
                            modalData={this.state.modalData}
                            toggleModal={this.toggleModal}
                            availableOptions={this.state.availableOptions}
                            stateOptions={stateOptions}
                            updateChannelStatus={this.updateChannelStatus}
                        />
                        :
                        <div/>
                }
                <Row>
                    <Col>
                        <SelectChannelConfig   required={true} name={'channelCode'} icon={'fas fa-plug'} value={this.state.channelCode} onChangeFunc={this.setFilterSelectedChannel.bind(this)} />
                    </Col>
                    <Col>
                        <SelectHotel required={true} name={'hotelId'} icon={'fas fa-hotel'} value={this.state.hotelId} onChangeFunc={this.setFilterSelectedChannel.bind(this)}/>
                    </Col>
                    <Col>
                        <CustomSelect icon={'fas fa-cog'} name="channelConfigState" options={stateOptions} onChange={this.setFilterSelectedChannel.bind(this, 'channelConfigState')} value={stateOptions && stateOptions.find(s => s.value === this.state.channelConfigState)} isClearable/>
                    </Col>
                    <Col>
                        <CustomSelect icon={'fas fa-power-off'} name="status" options={statusOptions} onChange={this.setFilterSelectedChannel.bind(this, 'status')} value={statusOptions && statusOptions.find(s => s.value === status)} isClearable/>
                    </Col>
                    <Col>
                        <Button className="float-right  btn-sm btn-host btn" onClick={this.getHotelListFiltered}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>                    
                </Row>
                <hr />
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">
                            <CustomTable
                                data={this.state.hotelList ? this.state.hotelList : []}
                                columns={columns}
                                page={this.state.currentPage}
                                sizePerPage={this.state.resultsPerPage}
                                totalSize={this.state.hotelList && this.state.hotelList.length}
                                shadow={false}
                                search={true}
                                exportCSV={true}
                                showTotal={true}
                                selectRow={selectRow}
                                hideSizePerPage={false}
                            />
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        );
    }
};
export default injectIntl(HotelChannelInfo)