import React, { Component } from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { RoomCategorySetupDetails } from '../../HotelSetting/Property/Room/RoomCategorySetupDetails';
import Authorization from '../../Base/Authorization';

export class RoomBar extends Component {

    constructor(props) {
        super(props);

        this.toggleModalRoom = this.toggleModalRoom.bind(this);

        this.state = {
            modalRoom: false
        };
    }

    
        
    componentDidMount() {

    }

    toggleModalRoom() {
        this.setState(prevState => ({
            modalRoom: !prevState.modalRoom
        }));
    }
    editRoom() {
        this.setState({ modalRoom: true  });
    }

    render() {

        return (
            <div>
                <Modal isOpen={this.state.modalRoom} toggle={this.toggleModalRoom} fade={false} className="modal-lg " >
                    <ModalHeader toggle={this.toggleModalRoom}  > <FormattedMessage id="RoomSetup.AddRoom" /> </ModalHeader>
                    <ModalBody className="modal-lg px-0 pb-0">

                       
                            <RoomCategorySetupDetails selectedRoom={this.props.roomType} />
                          

                    </ModalBody>
                   
                </Modal>


                <Row className="d-flex flex-row">
                    <Col>
                        <Row>
                            <Col className=" pl-0">
                                <div className="d-inline-block">
                                    <span className="fas fa-bed ml-2" /> <span> <b> {this.props.roomType.code} </b> - {this.props.roomType.description}</span>                                
                                </div>                            
                            </Col>                       
                        </Row>
                    </Col>

                    <Col className="float-right pr-1 col-1">
                        <Authorization
                            perform="category:edit"
                            yes={() => (
                                <Button className=" float-right btn-sm btn-host " onClick={this.editRoom.bind(this)}><i className="fas fa-edit"></i></Button>

                            )}
                            no={() => <div></div>}
                        />                    
                    </Col>
                
                </Row>
            </div>
        );

    }
};