import React, { Component } from 'react';
import classnames from 'classnames';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card, Col, Row, ModalHeader, Modal, ModalBody, CardBody, Input } from 'reactstrap';
import { getAPI, postAPI } from "../../Base/API";
import { StyledCard, ActiveInactiveStatusBadge, SelectChannelConfig } from "../../Base/Common/CommonUIComponents";
import { CustomTable } from '../../Base/Common/CustomTable';
import CustomSelect from '../../Base/Common/CustomSelect';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment from "moment";
import { handleNotification } from "../../Base/Notification";

class OTAManagement extends Component {

    state = {
        block: true,
        modal: false,
        activeValues: [{ value: true, label: 'Enabled' }, { value: false, label: 'Disabled' }],
        managedHotels: [],
        selectedHotel: {}
    }

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.form = React.createRef();
    }

    componentDidMount() {
        this.getManagedHotel();

    }
    toggleModal() {
        this.setState(prevstate => ({
            modal: !prevstate.modal
        }));
    }
    getManagedHotel() {
        this.setState({ block: true });


        var param = '';

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ managedHotels: data.hotels, block: false });
            }
        }, '/api/hotel/OtaManagement/v1/getHotelOTAs' + param);
        
    }

    save(evt) {
        
        evt.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }
        this.setState({ block: true });
        let req = {
            "hotelId": this.state.selectedHotel.id,
            "channelId": this.state.channelId,
            "active": this.state.active,
            "fromDate": this.state.date,
            "percentage": parseFloat(this.state.commission)
        }

        postAPI(result => {
            const { data, error, isLoading } = result;
            
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="OTAManagement.ChannelUpdated" />, <FormattedMessage id="General.Success" />);
                if (data.errors && data.errors.length > 0)
                    this.setState({block: false });
                else
                    this.setState((prevState) => ({ channelId: null, active: null, date: null, commission: '', managedHotels: [...prevState.managedHotels.filter(el => el.id !== data.hotels[0].id), data.hotels[0]], selectedHotel: data.hotels[0],  block: false }));
            }
        }, '/api/hotel/OtaManagement/v1/updateHotelOTAs', req);
        
        
    }
    setFilterSelectedChannel = (name,  combo, evt) => {
        var label = combo ? combo.label : evt ? evt.label : null
        var val = combo ? combo.value : evt ? evt.value : null

        this.setState({
                [name]: val
            }
        );
    }
    selectChannel(el) {

        this.setState({
            "channelId": el.id,
            "active": el.active,
            "fromDate": null,
            "percentage": null
        })
        
            
    }
    render() {

        const helpContent =
            <div>
                TODO
                <hr />
            </div>


        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "OTAManagement.HotelName" }),
                sort: true,
                headerStyle: () => {
                    return { width: "30%" };
                }
            },
            {
                dataField: 'otAs',
                text: this.props.intl.formatMessage({ id: "OTAManagement.Channel" }),
                sort: true,
                formatter: (cell, row) => <div> {cell && cell.map((el, key) =>
                    <div>
                        <Row >
                            <Col><h6>{el.code}</h6></Col>
                            <Col><ActiveInactiveStatusBadge status={el.active} /></Col>
                        </Row>
                        <Row>
                            <Col className="col-12">
                                {
                                    el.commissions.map((el2, k) =>
                                        <Row key={k} className="mb-1">
                                            <Col>
                                                <b>From : </b>{this.props.intl.formatDate(el2.fromDate)} {el2.toDate ? <span><b>to :</b>{` ${this.props.intl.formatDate(el2.toDate)}`}</span> : ``} Commission is {el2.percentage} %
                                            </Col>
                                        </Row>
                                    )
                                }
                            </Col>
                        </Row>
                        <hr/>
                    </div>)}
                    </div>,
               
            }];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ selectedHotel: row , modal : true})
            }
        };


        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-frog'} title={'OTAManagement.title'} help={helpContent} error={this.state.error}>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} className={classnames}>
                    <ModalHeader toggle={this.toggleModal}>{this.state.modalData && this.state.modalData.name}</ModalHeader>
                    <ModalBody>
                        <Card>
                            <CardBody>
                              
                                <Row>
                                    <Col className="col-4">
                                        <h5> {this.state.selectedHotel.name}</h5>
                                    </Col>
                                    <Col>
                                        
                                    </Col>
                                </Row>
                                <hr />
                                <form ref={this.form}>
                                    <Row >

                                        <Col><SelectChannelConfig required={true} icon={'fas fa-hotel'} value={this.state.channelId} onChangeFunc={this.setFilterSelectedChannel.bind(this, 'channelId')} /> </Col>
                                        <Col>
                                            <CustomSelect required options={this.state.activeValues} placeholder={<FormattedMessage id="DirtyDataDashboard.Selecttatus"/>} value={this.state.activeValues.find(el => el.value === this.state.active)} onChange={this.setFilterSelectedChannel.bind(this, 'active')} />
                                            
                                        </Col>
                                    <Col>
                                        <SingleDatePicker
                                            id="Date"
                                            isOutsideRange={day => day <= moment() || day > moment().add(3, 'month')}
                                            date={this.state.date}
                                            onDateChange={date => this.setState({ date })}
                                            focused={this.state.focused}
                                            onFocusChange={({ focused }) => this.setState({ focused })}
                                            small={true}
                                            numberOfMonths={1}
                                            showDefaultInputIcon={true}
                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                        />
                                    </Col>
                                        <Col>
                                            <Input placeholder="Commission" type="number" min="1" max="60" value={this.state.commission} id='commission' onChange={(evt) => this.setState({ commission: evt.target.value })} />
                                    </Col>
                                    <Col className="col-1">
                                        <Button className="btn btn-sm btn-host float-right mr-1" onClick={this.save.bind(this)}><i className="fas fa-save"></i>  </Button>
                                        </Col>
                                   
                                    </Row>
                                </form>
                                <hr/>
                                <Row>
                                    <Col>
                                        <h6> Subscribe channels</h6>
                                    </Col>
                                    <Col>
                                        <h6> Commissions</h6>
                                    </Col>
                                </Row>
                                <hr />
                                {this.state.selectedHotel.otAs && this.state.selectedHotel.otAs.map((item, key) =>
                                    <Row onClick={this.selectChannel.bind(this, item)}>
                                        <Col>
                                            <Row>
                                                <Col className="col-4">
                                                    <Row>
                                                        <Col><b> {item.code}</b></Col>
                                                        <Col><ActiveInactiveStatusBadge status={item.active} /></Col>
                                                    </Row>
                                                </Col>
                                                <Col className="col-7">
                                                    {item.commissions.map((el, k) =>

                                                        <Row className="mb-1">
                                                            <Col>
                                                                <b>From : </b>{this.props.intl.formatDate(el.fromDate)}  <span>{el.toDate ? <span><b>to : </b>{this.props.intl.formatDate(el.toDate)}</span> : ''}</span> Commission is {el.percentage} %
                                                            </Col>
                                                        </Row>
                                                    )}
                                                   
                                                </Col>
                                                
                                            </Row>
                                            
                                            <hr />
                                          

                                        </Col>
                                    </Row>
                                )}
                                
                            </CardBody>
                        </Card>
                    </ModalBody>
                </Modal>
                

                <Row>
                    <Col className="mb-1">
                        <Button className="btn btn-sm btn-host float-right" onClick={this.getManagedHotel.bind(this)}><i className="fas fa-sync"></i>  </Button>
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">
                            <CustomTable
                                data={this.state.managedHotels ? this.state.managedHotels : []}
                                columns={columns}
                                page={this.state.currentPage}
                                sizePerPage={this.state.resultsPerPage}
                                totalSize={this.state.managedHotels && this.state.managedHotels.length}
                                shadow={false}
                                search={true}
                                exportCSV={false}
                                selectRow={selectRow}
                                remote={false}
                            />
                        </Card>

                    </Col>

                </Row>


            </StyledCard>


        );
    }

};
export default injectIntl(OTAManagement)