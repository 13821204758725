import React, { Component } from 'react';
import { CardBody, CardHeader, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';

export class MajorBulkUpdateModalConfirm extends Component {

    render() {
        const { roomCategories, rateCodeIds, channelInstances, channels, stopSales, closedToArrival, closedToDeparture, minimumStayLen, maximumStayLen, minAdvancedBookingOffset, maxAdvancedBookingOffset } = this.props;

        return (
            <div>
                <Row>
                    <Col>
                        <CardHeader className="bg-white pb-3">
                            <FormattedMessage id="MajorBulkUpdateModal.Rates" />
                        </CardHeader>
                    </Col>
                    {
                        channels.length > 0 ?
                            <Col>
                                <CardHeader className="bg-white pb-3">
                                    <FormattedMessage id="MajorBulkUpdateModal.Channels" />
                                </CardHeader>
                            </Col>
                            :
                            <div />
                    }
                    <Col>
                        <CardHeader className="bg-white pb-3">
                            <FormattedMessage id="BulkUpdate.Restrictions" />
                        </CardHeader>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CardBody>
                            <Row>
                                <Col>
                                    {
                                        roomCategories
                                            .filter(rc => rc.rates.find(r => rateCodeIds.find(id => r.value === id) !== undefined))
                                            .map((rc, key) =>
                                                <div key={key}>
                                                    <Row className="mb-1 px-2">
                                                        <Col>
                                                            <span>
                                                                <b>
                                                                    {rc.label}
                                                                </b>
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                    { rc.rates && rc.rates.filter(r => rateCodeIds.find(id => r.value === id) !== undefined).map((r, key2) =>
                                                        <Row key={key2} className="mb-1 pl-3">
                                                            <Col>
                                                                {r.label}
                                                            </Col>
                                                        </Row>
                                                    ) }
                                                </div>
                                            )
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                    {
                        channels.length > 0 ?
                            <Col>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            {
                                                channelInstances
                                                    .filter(ci => channels.find(c => c === ci.value) !== undefined)
                                                    .map((ci, key) =>
                                                        <Row key={key} className="mb-1 px-2">
                                                            <Col>
                                                                {ci.label}
                                                            </Col>
                                                        </Row>    
                                                    )
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Col>
                            :
                            <div />
                    }
                    <Col>
                        <CardBody>
                            {
                                stopSales !== null && stopSales !== undefined ?
                                    <Row className="mb-1 px-2">
                                        <Col>
                                            <span>
                                                <FormattedMessage id="BulkUpdate.StopSales" />{" "}
                                                {
                                                    stopSales ?
                                                        <i className="fas fa-check-circle" />
                                                        :
                                                        <i className="fas fa-times-circle" />
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                    :
                                    <div />
                            }
                            {
                                closedToArrival !== null && closedToArrival !== undefined ?
                                    <Row className="mb-1 px-2">
                                        <Col>
                                            <span>
                                                <FormattedMessage id="BulkUpdate.CloseToArrival" />{" "}
                                                {
                                                    closedToArrival ?
                                                        <i className="fas fa-check-circle" />
                                                        :
                                                        <i className="fas fa-times-circle" />
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                    :
                                    <div />
                            }
                            {
                                closedToDeparture !== null && closedToDeparture !== undefined ?
                                    <Row className="mb-1 px-2">
                                        <Col>
                                            <span>
                                                <FormattedMessage id="BulkUpdate.CloseToDeparture" />{" "}
                                                {
                                                    closedToDeparture ?
                                                        <i className="fas fa-check-circle" />
                                                        :
                                                        <i className="fas fa-times-circle" />
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                    :
                                    <div />
                            }
                            {
                                minimumStayLen !== null && minimumStayLen !== undefined ?
                                    <Row className="mb-1 px-2">
                                        <Col>
                                            <span>
                                                {`${this.props.intl.formatMessage({ id: "BulkUpdate.MinStay" })} ${minimumStayLen}`}
                                            </span>
                                        </Col>
                                    </Row>
                                    :
                                    <div />
                            }
                            {
                                maximumStayLen !== null && maximumStayLen !== undefined ?
                                    <Row className="mb-1 px-2">
                                        <Col>
                                            <span>
                                                {`${this.props.intl.formatMessage({ id: "BulkUpdate.MaxStay" })} ${maximumStayLen}`}
                                            </span>
                                        </Col>
                                    </Row>
                                    :
                                    <div />
                            }
                            {
                                minAdvancedBookingOffset !== null && minAdvancedBookingOffset !== undefined ?
                                    <Row className="mb-1 px-2">
                                        <Col>
                                            <span>
                                                {`${this.props.intl.formatMessage({ id: "BulkUpdate.MinAdvancedBookingOffset" })} ${minAdvancedBookingOffset}`}
                                            </span>
                                        </Col>
                                    </Row>
                                    :
                                    <div />
                            }
                            {
                                maxAdvancedBookingOffset !== null && maxAdvancedBookingOffset !== undefined ?
                                    <Row className="mb-1 px-2">
                                        <Col>
                                            <span>
                                                {`${this.props.intl.formatMessage({ id: "BulkUpdate.MaxAdvancedBookingOffset" })} ${maxAdvancedBookingOffset}`}
                                            </span>
                                        </Col>
                                    </Row>
                                    :
                                    <div />
                            }
                        </CardBody>
                    </Col>
                </Row>
            </div>    
        );
    }
}
export default injectIntl(MajorBulkUpdateModalConfirm);