import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { getAPI, postAPI } from "../../../Base/API"
import { ErrorAlert } from "../../../Common/ErrorAlert";
import BlockUi from 'react-block-ui';
import AmenityType from '../Common/AmenityType';
import { handleNotification } from "../../../Base/Notification"
import { FormattedMessage } from 'react-intl';
import { CommonHelper } from '../../../Base/Common/CommonUIComponents';

export class Amenity extends Component {    

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            error: [],
            amenities: [],
            block: true,
            url: this.props.url,
            title: this.props.title,
            amenityTypes: this.props.amenityTypes
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.url !== this.props.url) {
            this.getAmenities(nextProps.url);
            this.setState({ url: nextProps.url })
        }
    }

    componentDidMount() {
        this.getAmenities(this.props.url);
    }

    getAmenities = (url) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ blocking: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.length > 0) {
                const ha = [];
                data.forEach(function (item) {
                    ha.push(item.id);
                });
                this.setState({ block: false, amenities: ha });
            }
            else {
                this.setState({ block: false, amenities: [] });
            }
        }, url);
    }

    handleChange(e) {
        const ha = this.state.amenities;
        const index = parseInt(e.target.id, 0);
        if (ha.includes(index) && !e.target.checked) {
            ha.splice(ha.indexOf(index), 1);
        }
        else if (!ha.includes(index) && e.target.checked) {
            ha.push(index);
        }
        this.setState({ amenities: ha });
    }

    handleSubmit(e) {
        e.preventDefault();

        this.setState({
            block: true
        });

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="Amenity.SuccessMessageSave" />, <FormattedMessage id="General.Success" />);
                this.setState({ block: false, error: errorMessage });
            }
        }, this.state.url, this.state.amenities);
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Card className="small border-0 shadow">
                        <CardHeader className="border-bottom bg-white" >
                            <Row>
                                <Col>
                                    <span className="fas fa-wifi mr-2" />
                                    {this.state.title}
                                </Col>
                                <Col className="text-right col-6 col-md-4 col-lg-2">
                                    <Button color="primary btn-host btn-sm" onClick={this.handleSubmit}>
                                        <i className="fas fa-save" />
                                    </Button>
                                    {this.props.fromPropertyPage ?
                                        <CommonHelper help={<FormattedMessage id="Amenity.PropertyHelp" /> } id={'AmenitiesHelp'} />
                                    :
                                        <CommonHelper help={<FormattedMessage id="Amenity.RoomHelp" />} id={'RoomCategoryAmenitiesHelp'} />
                                    }
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            {this.state.amenityTypes && this.state.amenityTypes.map((amenityType, key) =>
                                <AmenityType key={key} componentAmenities={this.state.amenities} onChange={this.handleChange} url={amenityType.url} title={amenityType.title} />
                            )}
                        </CardBody>
                    </Card>
                </BlockUi>
            </div>
        );
    }
}