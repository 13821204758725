import React, { Component } from 'react';
import { DropdownItem, DropdownMenu } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { BlankCard } from '../../../Base/Common/CommonUIComponents';
import { deleteAPI, getAPI } from '../../../Base/API';
import { BookingConnectionDetail } from './BookingConnectionDetail';
import { handleNotification } from '../../../Base/Notification';
import BookingConnectionsList from './BookingConnectionsList';
import PropertyHotel from './PropertyHotel';

export class BookingConnectionsActiveList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            properties: [],
            modal: false,
            selectedProperty: null,
            selectedPropertyPricingModel: null,
            page: 1,
            pageSize: 10,
            cursors: [null],
            totalItems: 0,
            propertyId: null
        };
    }

    componentDidMount() {
        this.getConnections();
    }

    getConnections = () => {
        const { url } = this.props;
        const { cursors, page, pageSize, endDate, startDate } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (Array.isArray(data.data)) {
                    if (cursors[page] === undefined) {
                        if (data.meta.next_page) {
                            cursors.push(new URLSearchParams(new URL(data.meta.next_page).search).get("cursor"));
                        }
                        else {
                            cursors.push(null);
                        }
                    }

                    const totalItems = page === 1 ? (data.data.length + data.meta.next_count) : this.state.totalItems;
                    this.setState({ error: errorMessage, block: false, properties: data.data, cursors, totalItems });
                }
                else {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `${url}?pageSize=${pageSize}${cursors[page - 1] ? `&cursor=${cursors[page - 1]}` : ``}${startDate ? `&startDate=${moment(startDate).format("YYYY-MM-DD")}` : ``}${endDate ? `&endDate=${moment(endDate).format("YYYY-MM-DD")}` : ``}`);
    }

    getSearchConnections = () => {
        this.setState({ block: true, page: 1, cursors: [null] }, _ => {
            if (this.state.propertyId) {
                this.getConnection()
            }
            else {
                this.getConnections();
            }
        });
    }

    getConnection = () => {
        const { cursors, propertyId } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else {
                    cursors.push(null);
                    const properties = Object.keys(data.data).length === 0 ? [] : [data.data];

                    this.setState({ error: errorMessage, block: false, properties, totalItems: properties.length, cursors });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/ConnectionManagement/v1/ConnectionRequest/${propertyId}/active`);
    }

    toggleModal = (_, property) => {
        this.setState(prevState => ({ modal: !prevState.modal, selectedProperty: property, selectedPropertyPricingModel: property && property.pricing.model }));
    }

    deactivateConnection = () => {
        const { selectedProperty } = this.state;

        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (!data.data) {
                    handleNotification(data, <FormattedMessage id="BookingConnections.SuccessDeactivateConnetion" />, <FormattedMessage id="General.Success" />);
                    this.toggleModal();
                    this.getConnections();
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/ConnectionManagement/v1/ConnectionRequest/${selectedProperty.property.id}/deactivate`);
    }

    handlePropertyId = (evt) => {
        if (evt && evt.target) {
            const value = evt.target.value;
            this.setState({ propertyId: value });
        }
    }

    render() {
        const { block, cursors, endDate, error, focusedInput, modal, page, properties, propertyId, selectedProperty, selectedPropertyPricingModel, startDate, totalItems } = this.state;

        function getConnectionTypes(connection_types) {
            return Object.keys(connection_types);
        }

        return (
            <BlankCard block={block} error={error}>
                {
                    modal ?
                        <BookingConnectionDetail block={block} disablePricingModelChange={true} error={error} getConnectionTypes={getConnectionTypes} isOpen={modal} toggle={this.toggleModal} selectedProperty={selectedProperty} selectedPropertyPricingModel={selectedPropertyPricingModel}>
                            <DropdownMenu right>
                                <DropdownItem onClick={this.deactivateConnection}>
                                    <FormattedMessage id="BookingConnections.DeactivateConnection" />
                                </DropdownItem>
                            </DropdownMenu>
                        </BookingConnectionDetail>
                        :
                        ''
                }
                <BookingConnectionsList
                    cursors={cursors}
                    getSearchConnections={this.getSearchConnections}
                    handleNextButton={_ => this.setState({ page: page + 1, block: true }, this.getConnections)}
                    handlePrevButton={_ => this.setState({ page: page - 1, block: true }, this.getConnections)}
                    page={page}
                    properties={properties}
                    startDate={startDate}
                    startDateId="activeStartDate"
                    endDate={endDate}
                    endDateId="activeEndDate"
                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                    focusedInput={focusedInput}
                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                    total={totalItems}
                    changePropertyId={this.handlePropertyId}
                    propertyId={propertyId}
                    disablePropertyIdFilter={false}
                >
                    <PropertyHotel getConnectionTypes={getConnectionTypes} getDateAt={prp => prp.connected_at} properties={properties} toggleModal={this.toggleModal} />
                </BookingConnectionsList>
            </BlankCard>
        );
    }
}

export default injectIntl(BookingConnectionsActiveList)
