import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, CustomInput, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../Base/Notification";
import { ErrorAlert } from "../Common/ErrorAlert";
import { putAPI } from "../Base/API";
import BlockUi from 'react-block-ui';
import CustomSelect from '../Base/Common/CustomSelect';

class ChangePricingModal extends Component {

	constructor(props) {
		super(props);
		this.form = React.createRef();

		this.state = {
			block: false,
			validModes: [
				{
					value: 'STANDARD',
					label: <FormattedMessage id="ChannelList.Standard" />
				},
				{
					value: 'RATE_PLAN',
					label: <FormattedMessage id="ChannelList.RatePlan" />
				}
			],
			mode: this.props.mode,
			clearIncompatibleSettings: null,
			inModelTransition: null
		};
	}

	handleCheck = (evt) => {
		if (evt && evt.target) {
			const { name, checked } = evt.target;

			this.setState({ [name]: checked });
		}
	}

	handleMode = (combo, evt) => {
		if (evt) {
			this.setState({ mode: combo && combo.value });
		}
	}

	saveMode = (evt) => {
		evt.preventDefault();
		const { channelInstanceId, toggleModal, toggleModalSuccess } = this.props;
		const { clearIncompatibleSettings, inModelTransition, mode } = this.state;

		if (this.form.current.reportValidity()) {
			const request = {
				clearIncompatibleSettings: clearIncompatibleSettings,
				inModelTransition: inModelTransition,
				mode: mode,
			};

			this.setState({ block: true });

			putAPI(result => {
				const { data, error } = result;
				const errorMessage = [];

				if (error) {
					errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
					this.setState({ error: errorMessage, block: false });
					return;
				}
				if (data) {
					if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
						handleNotification(data);
						this.setState({ error: errorMessage, block: false });
					}
					else {
						handleNotification(data, <FormattedMessage id="ChannelList.ModeSetSuccess" />, <FormattedMessage id="ChannelConf.Success" />);
						this.setState({ error: errorMessage, block: false }, _ => { toggleModal(); toggleModalSuccess(); });
					}
				}
				else {
					this.setState({ error: errorMessage, block: false });
				}
			}, `/api/Rate/ChannelConfig/v1/ChannelInstance/${channelInstanceId}/Mode`, request);
		}
	}

	render() {
		const { modal, toggleModal } = this.props;
		const { block, error, validModes, mode, clearIncompatibleSettings, inModelTransition } = this.state;

        return (
			<Modal isOpen={modal} toggle={toggleModal} className="modal-lg" style={{ width: "100%" }}>
				<ModalHeader toggle={toggleModal}>
					<FormattedMessage id="ChangePricing.Title" />
				</ModalHeader>
				<ModalBody>
					<BlockUi tag="div" blocking={block}>
						<ErrorAlert error={error} />
						<form ref={this.form}>
							<Row className="mb-2">
								<Col className="text-right">
									<Button className="btn btn-host btn-sm" onClick={this.saveMode}>
										<span className="fas fa-save" />
									</Button>
								</Col>
							</Row>
							<Row className="mb-2">
								<Col className="col-1">
									<FormattedMessage id="ChangePricing.Mode" />
								</Col>
								<Col className="col-3">
									<CustomSelect options={validModes} value={validModes.find(opt => opt.value === mode)} onChange={this.handleMode} required/>
								</Col>
								<Col className="col-3">
									<FormattedMessage id="ChangePricing.InModelTransition" />
								</Col>
								<Col className="col-1">
									<CustomInput type="switch" name="inModelTransition" id="inModelTransition" onChange={this.handleCheck} checked={inModelTransition} />
								</Col>
								<Col className="col-3">
									<FormattedMessage id="ChangePricing.ClearIncompatibleSettings" />
								</Col>
								<Col className="col-1">
									<CustomInput type="switch" name="clearIncompatibleSettings" id="clearIncompatibleSettings" onChange={this.handleCheck} checked={clearIncompatibleSettings} />
								</Col>
							</Row>
						</form>
					</BlockUi>
				</ModalBody>
			</Modal>    
        );
    }
}

export default injectIntl(ChangePricingModal);