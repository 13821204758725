import React, { Component } from 'react';
import { injectIntl } from "react-intl";
import { Row, Col, Badge, Button, Card, InputGroup } from 'reactstrap';
import { StyledCard, SelectHotel } from '../../Base/Common/CommonUIComponents';
import UpgradeRequestsDetails from './UpgradeRequestsDetails';
import { handleNotification } from "../../Base/Notification";
import { CustomTable } from '../../Base/Common/CustomTable';
import CustomSelect from '../../Base/Common/CustomSelect';
import { getAPI, postAPI } from "../../Base/API";
import { SingleDatePicker } from 'react-dates';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

export class UpgradeRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            upgradeRequests: false,
            requestedAt: null,
            requestSelected: {},
            status: null,
            hotelId: null,
            fromDate: null,
            editMode: false
        }
    }

    componentDidMount() {
        this.getRequests();
    }

    getRequests = () => {
        this.setState({ block: true });
        var params = '';
        params += this.state.status ? `&status=${this.state.status}` : '';
        params += this.state.fromDate ? `&fromDate=${this.state.fromDate.format("YYYY-MM-DD")}` : '';
        params += this.state.hotelId ? `&hotelId=${this.state.hotelId}` : '';

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                this.setState({ upgradeRequests: data.response, block: false });
            }
        }, `/api/hotel/License/v1/allUpgradeAccountRequests?` + params);
    }
    
    updateTable = (request) => {
        this.setState({ block: true });
        const index = this.state.upgradeRequests && this.state.upgradeRequests.indexOf(this.state.upgradeRequests.find(up => up.id == request.id));

        if (index == -1) {
            this.setState({
                upgradeRequests: [
                    ...this.state.upgradeRequests,
                    request
                ]
            });
        }
        else {
            var upgradeRequests = [...this.state.upgradeRequests];
            upgradeRequests[index] = request;

            this.setState({ upgradeRequests });
        }
        this.setState({ block: false });
    }

    toggleModal = () => {
        this.setState(prevstate => ({
            modal: !prevstate.modal,
            editMode: false,
            requestSelected: null
        }));
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    render() {
        const statusOptions = [
            {
                value: 'New',
                label: this.props.intl.formatMessage({ id: "UpgradeRequests.New" })
            },
            {
                value: 'Working',
                label: this.props.intl.formatMessage({ id: "UpgradeRequests.Working" })
            },
            {
                value: 'Closed',
                label: this.props.intl.formatMessage({ id: "UpgradeRequests.Closed" })
            }
        ]

        const columns = [
            {
                dataField: 'hotel',
                text: this.props.intl.formatMessage({ id: "UpgradeRequests.Hotel" }),
                sort: true
            },
            {
                dataField: 'requestedUser',
                text: this.props.intl.formatMessage({ id: "UpgradeRequests.RequestedUser" }),
                sort: true
            },
            {
                dataField: 'requestedAt',
                text: this.props.intl.formatMessage({ id: "UpgradeRequests.RequestedAt" }),
                sort: true,
                formatter: (cell, row) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'notes',
                text: this.props.intl.formatMessage({ id: "UpgradeRequests.Notes" }),
                sort: true
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "UpgradeRequests.Status" }),
                sort: true,
                formatter: (cell, row) => cell ? cell === 'Working' ? <Badge color='success'> <FormattedMessage id="UpgradeRequests.Working" /> </Badge> : cell === 'New' ? <Badge color='primary'> <FormattedMessage id="UpgradeRequests.New" /> </Badge> : <Badge color='danger'> <FormattedMessage id="UpgradeRequests.Closed" /> </Badge> : ''
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, requestSelected: row, editMode: true })
            }
        };
        
        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-arrow-alt-circle-up'} title={'UpgradeRequests.Title'} help={'TODO'}>
                <Row>
                    <Col className="col-2 pr-1">
                        <SelectHotel
                            name={'hotelId'}
                            icon={'fas fa-hotel'}
                            onChangeFunc={this.handleSelect}
                            placeholder={<FormattedMessage id="UpgradeRequests.Hotel" />}
                            value={this.state.hotelId}
                        />
                    </Col>
                    <Col className="col-2 px-1">
                        <CustomSelect
                            icon={'fas fa-cog'}
                            isClearable isSearchable
                            placeholder={<FormattedMessage id="UpgradeRequests.Status" />}
                            options={statusOptions}
                            onChange={this.handleSelect.bind(this, 'status')} />
                    </Col>
                    <Col className="col-3">
                        <InputGroup size="sm text-right" >
                            <SingleDatePicker
                                id="Date"
                                isOutsideRange={day => false}
                                date={this.state.fromDate}
                                onDateChange={date => this.setState({ fromDate: date })}
                                focused={this.state.focused}
                                onFocusChange={({ focused }) => this.setState({ focused })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                        </InputGroup>
                    </Col>
                    <Col className="text-right">
                        <button className="btn btn-host btn-sm" onClick={this.getRequests}>
                            <span className="fas fa-search" />
                        </button>
                    </Col>
                </Row>
                <hr className="border-host" />
                <Row>
                    <Col className="col-12">
                        <Card className=" border-0 ">
                            <CustomTable
                                data={this.state.upgradeRequests ? this.state.upgradeRequests : []}
                                columns={columns}
                                totalSize={this.state.upgradeRequests && this.state.upgradeRequests.length}
                                shadow={false}
                                selectRow={selectRow}
                                search={true}
                                remote={false}
                                showTotal={true}
                                hideSizePerPage={false}
                            />
                        </Card>
                    </Col>
                </Row>

                {this.state.modal ?
                    <UpgradeRequestsDetails
                        statusOptions={statusOptions}
                        modal={this.state.modal}
                        toggleModal={this.toggleModal}
                        requestSelected={this.state.requestSelected}
                        editMode={this.state.editMode}
                        updateTable={this.updateTable}
                    />
                : ''}
            </StyledCard> 
        );
    }
}

export default injectIntl(UpgradeRequests);