import React, { Component } from 'react';
import { Row, Col, Badge, Collapse, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { ChannelBar } from './ChannelBar';
import { ActiveInactiveStatusBadge, IcalUrlButton, KebabMenu } from "../../Base/Common/CommonUIComponents";
import Authorization, { CheckAuthorization } from '../../Base/Authorization';
import EditRate from '../Rate/EditRate';
import { getAPI } from "../../Base/API";
import RateHistory from './RateHistory';
import CustomToolTip from '../../../Utils/CustomToolTip';



export const RoomRateRow = ({ roomId, rate, channels, changeChannelVisibility, channelvisible, applicablepricelist, changePaxVisibility, applicableratecodepax, paxVisibility, dependencyRate, getiCalSyncUrls, icalUrl, copyRate, toggleModal }) => {
    return (
        <Row className="d-flex flex-row border-0 overbglight align-items-center" >
            <Col className="col-4 border-right ">
                <Row>
                    <Col className=" pl-2">
                        <div>
                            <span>
                                {rate.code}
                                <span className="float-right">

                                    {channels.length > 0 ?
                                        <Badge color="secondary" className="mr-1 p-1 pointer" onClick={changeChannelVisibility}>
                                            {channels.length} <FormattedMessage id="RoomRateBar.Channels" />
                                            <span className={channelvisible ? 'fas fa-angle-down ml-2' : 'fas fa-angle-up ml-2'} />
                                        </Badge>
                                        : ''}
                                    {rate.active ?
                                        <Badge color="primary" className="mr-1 p-1" >
                                            <FormattedMessage id="RateBar.Active" />
                                        </Badge>

                                        :
                                        <Badge color="secondary" className="mr-1 p-1" >
                                            <FormattedMessage id="RateBar.Inactive" />
                                        </Badge>
                                    }
                                </span>
                            </span>
                        </div>

                    </Col>
                </Row>
            </Col>
            <Col>
                <Row className="align-items-center">
                    <Col className="col-1">
                        <a className="font_size_xs inline pointer" onClick={changePaxVisibility}>
                            <Badge color={applicableratecodepax && applicableratecodepax.length === 0 ? 'warning' : paxVisibility ? 'primary' : 'secondary'} style={{ color: 'white' }} className="mt-1">
                                <span className="fas fa-user-cog" id={"occupation" + rate.id}></span>
                            </Badge>

                        </a>
                        {applicableratecodepax && applicableratecodepax.length === 0 ?
                            <CustomToolTip placement="bottom" target={'occupation' + rate.id}>
                                <FormattedMessage id="RoomRateBar.MissingOccupation" />
                            </CustomToolTip>
                            : ''}
                    </Col>
                    <Col className="col-2 px-0">
                        <div>
                            <strong> <FormattedMessage id="RoomRateBar.InventoryMode" /> </strong>{rate.blockId === 0 ? 'Real' : 'Virtual'}
                        </div>
                    </Col>
                    {applicablepricelist.length > 0 ?
                        <Col>
                            <Row>
                                {global.operationMode === 'PmsFull' ?
                                    <Col >
                                        <div>
                                            <strong> <FormattedMessage id="RoomRateBar.PriceList" /> </strong> ({applicablepricelist[0].code}) {applicablepricelist[0].description} <b>in</b> {applicablepricelist[0].currency}
                                        </div>
                                    </Col>
                                    : ''}
                                <Col>
                                    {applicablepricelist[0].dependencyMasterId !== null ? <span><FormattedMessage id="RoomRateConfig.DerivedPrice" />{" "}</span> : ''}
                                    {applicablepricelist[0].dependecyOrder == 'amount' ?
                                        <strong>
                                            {`${applicablepricelist[0].dependencyAmount} + ${applicablepricelist[0].dependencyPercentage}%`}
                                        </strong>
                                    :
                                        applicablepricelist[0].dependecyOrder == 'percentage' ?
                                            <span>
                                                <strong>
                                                    {`${applicablepricelist[0].dependencyPercentage}% + ${applicablepricelist[0].dependencyAmount}`}
                                                </strong>
                                            </span>
                                        :
                                            <span>
                                                <strong>{applicablepricelist[0].dependencyAmount !== 0 ? applicablepricelist[0].dependencyAmount : ''}</strong>
                                                <strong>{applicablepricelist[0].dependencyPercentage !== 0 ? applicablepricelist[0].dependencyPercentage + ' %' : ''}</strong>
                                            </span>
                                    }
                                    <strong>{dependencyRate && applicablepricelist[0].dependencyMasterId !== null ? ' From ' + dependencyRate.code : ''}</strong>
                                </Col>
                            </Row>
                        </Col>
                        : <Col />}

                    <Col className="col-3 text-right p-1 pl-0">

                        <Authorization
                            perform="rate:add"
                            yes={() => (
                                <Button className="btn btn-host btn-sm mr-2" onClick={() => copyRate(rate)}>
                                    <i className="fas fa-copy" />
                                </Button>
                            )}
                            no={() => ''}
                        />

                        <Authorization
                            perform="rate:history"
                            yes={() => (
                                <Button className="btn-sm btn-host mr-2" onClick={() => toggleModal("historyModal")}>
                                    <i className="fas fa-history" />
                                </Button>
                            )}
                            no={() => ''}
                        />

                        <IcalUrlButton
                            id={`UncontrolledPopover${roomId}-${rate.id}`}
                            rateId={rate.id}
                            getiCalSyncUrls={getiCalSyncUrls}
                            url={icalUrl ? icalUrl.url : null}
                        />

                        <Authorization
                            perform="rate:edit"
                            yes={() => (
                                <Button className=" float-right btn-sm btn-host ml-2" onClick={() => toggleModal("modalRate")}>
                                    <i className="fas fa-edit" />
                                </Button>
                            )}
                            no={() => ''}
                        />
                    </Col>
                </Row>
                <Collapse isOpen={paxVisibility} className="shadow pb-1 mb-2">
                    <Row className="pl-3">
                        <Col></Col>
                        <Col><strong># <FormattedMessage id="RoomRateBar.Adults" /> </strong></Col>
                        <Col><strong># <FormattedMessage id="RoomRateBar.Children" /> </strong></Col>
                        <Col><strong> <FormattedMessage id="RoomRateBar.Position" /> </strong></Col>
                        <Col><strong> <FormattedMessage id="RoomRateBar.Status" /></strong> </Col>
                    </Row>
                    {applicableratecodepax && applicableratecodepax.map((item, key) =>
                        <Row className={item.active !== true ? 'text-secondary pl-3 ' : 'pl-3 '} key={key}>
                            <Col >{item.description}</Col>
                            <Col>{item.adults}</Col>
                            <Col>{item.children}</Col>
                            <Col>{item.position}</Col>
                            <Col>
                                <ActiveInactiveStatusBadge status={item.active} />
                            </Col>
                        </Row>
                    )}

                </Collapse>
            </Col>
        </Row>
    )
};



export const RoomRateRowMobile = ({ roomId, rate, channels, changeChannelVisibility, channelvisible, applicablepricelist, changePaxVisibility, applicableratecodepax, paxVisibility, dependencyRate, getiCalSyncUrls, icalUrl, getExtraFields, toggleModal }) => {
    return (
        <div className="border-bottom py-2">
            <Row>
                <Col>
                    <b>{rate.code}</b>
                </Col>
                <Col className="col-1">
                    <KebabMenu
                        editFunction={() => { if (CheckAuthorization("rate:edit")) toggleModal("modalRate"); }}
                        extraFields={getExtraFields(rate)}
                        icalUrl={{ url: (icalUrl ? icalUrl.url : null), getiCalSyncUrls: () => getiCalSyncUrls(rate.id), id: `UncontrolledPopover${roomId}-${rate.id}` }}
                    />
                </Col>
            </Row>

            <Row className="mb-2 mt-1">
                <Col>
                    {rate.active ?
                        <Badge color="primary" className="mr-1 p-1" >
                            <FormattedMessage id="RateBar.Active" />
                        </Badge>
                        :
                        <Badge color="secondary" className="mr-1 p-1" >
                            <FormattedMessage id="RateBar.Inactive" />
                        </Badge>
                    }
                    {channels.length > 0 ?
                        <Badge color="secondary" className="mr-2 p-1 pointer" onClick={changeChannelVisibility}>
                            {channels.length} <FormattedMessage id="RoomRateBar.Channels" />
                            <span className={channelvisible ? 'fas fa-angle-down ml-2' : 'fas fa-angle-up ml-2'} />
                        </Badge>
                        : ''}

                    <a className="font_size_xs inline pointer mr-2" onClick={changePaxVisibility}>
                        <Badge color={applicableratecodepax && applicableratecodepax.length === 0 ? 'warning' : paxVisibility ? 'primary' : 'secondary'} style={{ color: 'white' }} className="mt-1 mr-2">
                            <i className="fas fa-user-cog px-1" ></i>
                        </Badge>
                        {applicableratecodepax && applicableratecodepax.length === 0 ?
                            <FormattedMessage id="RoomRateBar.MissingOccupation" />
                        : ''}
                    </a>

                </Col>
            </Row>
            <Row className="text-muted">
                <Col>
                    <span>
                        <FormattedMessage id="RoomRateRow.Inventory" values={{ value: rate.blockId === 0 ? 'Real' : 'Virtual' }} />

                        {global.operationMode === 'PmsFull' &&
                            <>
                            <FormattedMessage id="RoomRateRow.withPriceList" /> ({applicablepricelist[0].code}) {applicablepricelist[0].description} <FormattedMessage id="RoomRateRow.in" /> {applicablepricelist[0].currency}
                        </>
                        }

                        {global.operationMode === 'PmsFull' && applicablepricelist[0].dependencyMasterId !== null ? <FormattedMessage id="RoomRateRow.and" /> : ''}

                        {applicablepricelist[0].dependencyMasterId !== null &&
                            <>
                            {applicablepricelist[0].dependencyMasterId !== null ? <span><FormattedMessage id="RoomRateRow.withDerivedPrice" />{" "}</span> : ''}
                                {
                                    applicablepricelist[0].dependecyOrder == 'amount' ?
                                        <span>
                                            {`${applicablepricelist[0].dependencyAmount} + ${applicablepricelist[0].dependencyPercentage}%`}
                                        </span>
                                        :
                                        applicablepricelist[0].dependecyOrder == 'percentage' ?
                                            <span>
                                                    {`${applicablepricelist[0].dependencyPercentage}% + ${applicablepricelist[0].dependencyAmount}`}
                                            </span>
                                            :
                                            <span>
                                            <span>{applicablepricelist[0].dependencyAmount !== 0 ? applicablepricelist[0].dependencyAmount : ''}</span>
                                            <span>{applicablepricelist[0].dependencyPercentage !== 0 ? applicablepricelist[0].dependencyPercentage + ' %' : ''}</span>
                                            </span>
                                }
                                {dependencyRate && applicablepricelist[0].dependencyMasterId !== null ?
                                    <span>
                                        <FormattedMessage id="RoomRateRow.from" /> {dependencyRate.code}
                                    </span>
                                : ''}
                            </>
                        }
                    </span>

                </Col>
            </Row>

            <Collapse isOpen={paxVisibility} className="shadow py-2 my-2 border" style={{ overflowX: 'auto' }}>
                <Row className="pl-3" style={global.isMobile ? { width: 'max-content' } : {}}>
                    <Col style={{ minWidth: '180px' }}></Col>
                    <Col style={{ minWidth: '95px' }}><strong># <FormattedMessage id="RoomRateBar.Adults" /> </strong></Col>
                    <Col style={{ minWidth: '95px' }}><strong># <FormattedMessage id="RoomRateBar.Children" /> </strong></Col>
                    <Col style={{ minWidth: '95px' }}><strong> <FormattedMessage id="RoomRateBar.Position" /> </strong></Col>
                    <Col style={{ minWidth: '95px' }}><strong> <FormattedMessage id="RoomRateBar.Status" /></strong> </Col>
                </Row>
                {applicableratecodepax && applicableratecodepax.map((item, key) =>
                    <Row className={item.active !== true ? 'text-secondary pl-3' : 'pl-3'} key={key} style={global.isMobile ? { width: 'max-content' } : {}}>
                        <Col style={{ minWidth: '180px' }} >{item.description}</Col>
                        <Col style={{ minWidth: '95px' }}>{item.adults}</Col>
                        <Col style={{ minWidth: '95px' }}>{item.children}</Col>
                        <Col style={{ minWidth: '95px' }}>{item.position}</Col>
                        <Col style={{ minWidth: '95px' }}>
                            <ActiveInactiveStatusBadge status={item.active} />
                        </Col>
                    </Row>
                )}

            </Collapse>
        </div>
    )
};
