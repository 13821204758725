import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Button, Modal, ModalHeader } from 'reactstrap';
import { StyledCard, ActiveInactiveStatusBadge } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import { DateRangePicker } from 'react-dates';
import { getAPI } from "../../Base/API";
import moment from 'moment';

class RateHistory extends Component {

    constructor(props) {

        super(props);
        this.state = {
            error: [],
            block: true,
            historyList: null,
            pageSize: 10,
            pageIndex: 0,
            totalItems: 0,
            startDate: null,
            endDate: null
        };
    }

    componentDidMount() {
        if (this.props.rateId) {
            this.getRateCodeHistory();
        }
    }

    getRateCodeHistory = () => {
        var params = '';

        if (this.state.startDate && this.state.endDate) {
            params += `&startDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&endDate=${moment(this.state.endDate).format("YYYY-MM-DD")}`
        }

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }

                this.setState({ historyList: data.data, totalItems: data.count, block: false });
            }
            else this.setState({ block: false });
        }, `/api/Rate/Rate/v1/RateCode/${this.props.rateId}/History?pageIndex=${this.state.pageIndex}&pageSize=${this.state.pageSize}` + params);
    }

    handleTableChange = (_, { page, sizePerPage }) => {
        this.setState({
            block: true,
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, this.getRateCodeHistory);
    }

    doSearch = () => {
        this.setState({
            block: true,
            pageIndex: 0,
        }, this.getRateCodeHistory);
    }

    render() {
        const { modal, toggleModal } = this.props;
        const { historyList, block, error } = this.state;

        const columns = [
            {
                dataField: 'updatedAt',
                text: this.props.intl.formatMessage({ id: "RateHistory.UpdatedAt" }),
                formatter: (cell) => cell ? <span> {moment(cell).format("YYYY-MM-DD HH:mm")} </span> : '',
                headerStyle: { minWidth: "90px" }
            },
            {
                dataField: 'user',
                text: this.props.intl.formatMessage({ id: "RateHistory.UpdatedBy" })
            },
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "RateHistory.Name" }),
                headerStyle: { minWidth: "200px" }
            },
            {
                dataField: 'description',
                text: this.props.intl.formatMessage({ id: "RateHistory.Description" }),
                headerStyle: { minWidth: "200px" }
            },
            {
                dataField: 'blockId',
                text: this.props.intl.formatMessage({ id: "RateHistory.BlockId" }),
                headerStyle: { textWrap: "nowrap" }
            },
            {
                dataField: 'categoryId',
                text: this.props.intl.formatMessage({ id: "RateHistory.CategoryId" }),
                headerStyle: { textWrap: "nowrap" }
            },
            {
                dataField: 'inventoryMode',
                text: this.props.intl.formatMessage({ id: "RateHistory.InventoryMode" })
            },
            {
                dataField: 'inventoryParent',
                text: this.props.intl.formatMessage({ id: "RateHistory.InventoryParent" })
            },
            {
                dataField: 'packageId',
                text: this.props.intl.formatMessage({ id: "RateHistory.PackageId" }),
                headerStyle: { textWrap: "nowrap" }
            },
            {
                dataField: 'sortOrder',
                text: this.props.intl.formatMessage({ id: "RateHistory.SortOrder" }),
                headerStyle: { textWrap: "nowrap" }
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "RateHistory.Active" }),
                formatter: (cell, row) => cell ? <ActiveInactiveStatusBadge status={cell} /> : ''
            }
        ];

        return (
            <Modal isOpen={modal} toggle={toggleModal} fade={false} className="modal-lg" >
                <ModalHeader toggle={toggleModal}  >
                    <FormattedMessage id="RateHistory.RateHistory" />
                </ModalHeader>
                <StyledCard block={block} error={error} >
                    <Row className="mb-3 align-items-center">
                        <Col className="col-10">
                            <DateRangePicker
                                startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                startDateId="startDate"
                                isOutsideRange={day => day >= moment().add(1, 'days')}
                                endDate={this.state.endDate}
                                endDateId="endDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                small={true}
                                numberOfMonths={1}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                        </Col>
                        <Col className="col-2 pl-0">
                            <Button className="float-right btn btn-sm btn-host" onClick={this.doSearch}>
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <CustomTable
                                data={historyList || []}
                                columns={columns}
                                showTotal={true}
                                page={this.state.pageIndex + 1}
                                totalSize={this.state.totalItems}
                                remote={true}
                                sizePerPage={this.state.pageSize}
                                onTableChange={this.handleTableChange}
                                keyField='updatedAt'
                            />
                        </Col>
                    </Row>
                </StyledCard>
            </Modal>
        );
    }
}

export default injectIntl(RateHistory);