import React, { Component } from 'react'
import { Button, Card, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ErrorAlert } from '../Common/ErrorAlert';
import { getAPI } from '../Base/API';
import CustomToolTip from '../../Utils/CustomToolTip';
import BlockUi from 'react-block-ui';

export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            block: true,
            apikeyStatus: false,
            domainStatus: false,
            webHookStatus: false,
            whiteListStatus: false,
        }
    }

    componentDidMount(){
        this.getIntegrationStatus();
    }

    getIntegrationStatus = () => {
        this.setState({ block: true }, () => {
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger"
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if(data){
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach(err => errorMessage.push({ message: err.message, stack: err.stack, messageType: 'danger' }));
                        this.setState({ block: false, error: errorMessage });
                        return;
                    }
                    else if (data.response && data.response.length > 0) {
                        this.setState({ 
                            apikeyStatus: data.response[0].apiKeyStatus, 
                            domainStatus: data.response[0].domainStatus, 
                            webHookStatus: data.response[0].webHookStatus, 
                            whiteListStatus: data.response[0].whiteListStatus,
                            block: false
                        });
                    }
                }
            }, `/api/hotel/EmailReservation/v1/IntegrationStatus`)
        });
    }
    
    goTo = (tab) => {
        this.props.toggleTab(tab);
    }

    render() {
        const { error, block, apikeyStatus, domainStatus, webHookStatus, whiteListStatus } = this.state;
        
        return (
            <>
                <ErrorAlert error={error} />
                <BlockUi tag="div" blocking={block} className="h-100">
                    {!block ?
                        apikeyStatus && domainStatus && webHookStatus && whiteListStatus ?
                            <div className="text-center d-flex align-items-center flex-column justify-content-center" style={{ height: "60vh" }}>
                                <span className="mb-3"> <i className="far fa-check-circle " style={{ fontSize: "60px", color: '#14ad14' }} /> </span>
                                <h5><FormattedMessage id="EmailReservation.AllSet" /></h5>
                                <p><FormattedMessage id="EmailReservation.AllSetDescription" /></p>
                            </div>
                        :
                            <div>
                                <Row>
                                    <Col className="col-6">
                                        <Card className="shadow border-0 h-100 p-2" style={{ minHeight: '100px' }}>
                                            {!block ?
                                                <div>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div style={{ fontWeight: '500' }}>
                                                            <FormattedMessage id={`EmailReservation.KeyConfig`}/>
                                                        </div>
                                                        <div>
                                                            {apikeyStatus ?
                                                                <i style={{ color: '#14ad14' }} className="far fa-check-circle"/>
                                                            :
                                                                <>
                                                                    <i style={{ color: '#ffaf16' }} id="KeyConfigHelp" className="fas fa-exclamation-triangle"></i>
                                                                    <CustomToolTip placement={'left'} placementPrefix={"coolTooltip bs-tooltip"} target={"KeyConfigHelp"}>
                                                                        <FormattedMessage id="EmailReservation.KeyConfigHelper"/>
                                                                    </CustomToolTip>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: '0.8em' }}>
                                                        <FormattedMessage id={`EmailReservation.KeyConfigDescription`}/>
                                                    </div>
                                                    <div className='d-flex align-items-end justify-content-end mt-2'>
                                                        {!apikeyStatus ?
                                                            <Button className="btn-sm btn-host p-2" onClick={_ => this.goTo('KeyConfig')}>
                                                                <FormattedMessage id={`EmailReservation.Config`}/>
                                                            </Button>
                                                        :
                                                        ''}
                                                    </div>
                                                </div>
                                            :''}
                                        </Card>
                                    </Col>
                                    <Col className="col-6">
                                        <Card className="shadow border-0 h-100 p-2" style={{ minHeight: '100px' }}>
                                            {!block ?
                                                <div>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div style={{ fontWeight: '500' }}>
                                                            <FormattedMessage id="EmailReservation.MailBoxConfigDomainAuth"/>
                                                        </div>
                                                        <div>
                                                            {domainStatus ?
                                                                <i style={{ color: '#14ad14' }} className="far fa-check-circle"/>
                                                            :
                                                                <>
                                                                    <i style={{ color: '#ffaf16' }} id="DomainAuthConfigHelp" className="fas fa-exclamation-triangle"></i>
                                                                    <CustomToolTip placement={'left'} placementPrefix={"coolTooltip bs-tooltip"} target={"DomainAuthConfigHelp"}>
                                                                        <FormattedMessage id="EmailReservation.MailBoxConfigDomainAuthHelper"/>
                                                                    </CustomToolTip>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: '0.8em' }}>
                                                        <FormattedMessage id="EmailReservation.MailBoxConfigDescription"/>
                                                    </div>
                                                    <div className='d-flex align-items-end justify-content-end mt-2'>
                                                        {!domainStatus ?
                                                            <Button className="btn-sm btn-host p-2" onClick={_ => this.goTo('MailBoxConfig')}>
                                                                    <FormattedMessage id={`EmailReservation.Config`}/>
                                                            </Button>
                                                        :
                                                        ''}
                                                    </div>
                                                </div>
                                            :''}
                                        </Card>
                                    </Col>
                                    <Col className="col-6 mt-4">
                                        <Card className="shadow border-0 h-100 p-2" style={{ minHeight: '100px' }}>
                                            {!block ?
                                                <div>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div style={{ fontWeight: '500' }}>
                                                            <FormattedMessage id="EmailReservation.MailBoxConfigWebHooks"/>
                                                        </div>
                                                        <div>
                                                            {webHookStatus ?
                                                                <i style={{ color: '#14ad14' }} className="far fa-check-circle"/>
                                                            :
                                                                <>
                                                                    <i style={{ color: '#ffaf16' }} id="WebHooksHelp" className="fas fa-exclamation-triangle"></i>
                                                                    <CustomToolTip placement={'left'} placementPrefix={"coolTooltip bs-tooltip"} target={"WebHooksHelp"}>
                                                                        <FormattedMessage id="EmailReservation.MailBoxConfigWebHooksHelper"/>
                                                                    </CustomToolTip>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: '0.8em' }}>
                                                        <FormattedMessage id="EmailReservation.MailBoxConfigDescription" />
                                                    </div>
                                                    <div className='d-flex align-items-end justify-content-end mt-2'>
                                                        {!webHookStatus ?
                                                            <Button className="btn-sm btn-host p-2" onClick={_ => this.goTo('MailBoxConfig')}>
                                                                    <FormattedMessage id={`EmailReservation.Config`}/>
                                                            </Button>
                                                        :
                                                        ''}
                                                    </div>
                                                </div>
                                            :''}
                                        </Card>
                                    </Col>
                                    <Col className="col-6 mt-4">
                                        <Card className="shadow border-0 h-100 p-2" style={{ minHeight: '100px' }}>
                                            {!block ?
                                                <div>
                                                    <div className='d-flex align-items-center justify-content-between'>
                                                        <div style={{ fontWeight: '500' }}>
                                                            <FormattedMessage id="EmailReservation.WhiteList"/>
                                                        </div>
                                                        <div>
                                                            {whiteListStatus ?
                                                                <i style={{ color: '#14ad14' }} className="far fa-check-circle"/>
                                                            :
                                                                <>
                                                                    <i style={{ color: '#ffaf16' }} id="WhiteListConfigHelp" className="fas fa-exclamation-triangle"></i>
                                                                    <CustomToolTip placement={'left'} placementPrefix={"coolTooltip bs-tooltip"} target={"WhiteListConfigHelp"}>
                                                                        <FormattedMessage id="EmailReservation.WhiteListHelper"/>
                                                                    </CustomToolTip>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div style={{ fontSize: '0.8em' }}>
                                                        <FormattedMessage id="EmailReservation.WhiteListDescription" />
                                                    </div>
                                                    <div className='d-flex align-items-end justify-content-end mt-2'>
                                                        {!whiteListStatus ?
                                                            <Button className="btn-sm btn-host p-2" onClick={_ => this.goTo('WhiteList')}>
                                                                    <FormattedMessage id={`EmailReservation.Config`}/>
                                                            </Button>
                                                        :
                                                        ''}
                                                    </div>
                                                </div>
                                            :''}
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                    :''}
                </BlockUi>
            </>
        )
    }
}
