import React, { Component } from 'react';
import { FormattedMessage, FormattedNumber, FormattedHTMLMessage, injectIntl } from 'react-intl';
import { ErrorAlert } from "../Common/ErrorAlert";
import ChartCard from "./Common/ChartCard";
import { Col, Row } from 'reactstrap';
import { postAPI } from "../Base/API";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import moment from 'moment';

class TechDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: [],
            block: false,
            usersByBrowser: [],
            usersByOS: [],
            usersByDevice: [],
            usersByPlataform: [],
            usersByScreenResolution: [],
            usersByDeviceModel: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.search === true && nextProps.search !== this.props.search) {
            this.getData();
            this.props.toggleSearch();
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ block: true });

        const body = {
            startDate: moment(this.props.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
            dimensions: ["operatingSystem", "deviceCategory", "browser", "platform", "screenResolution", "mobileDeviceModel"],
            metrics: ["activeUsers"],
            aggregation: []
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const json = JSON.parse(data);

                let usersByOS = [[this.props.intl.formatMessage({ id: "BEAnalitics.OS" }), this.props.intl.formatMessage({ id: "BEAnalitics.Users" })]];
                let usersByDevice = [[this.props.intl.formatMessage({ id: "BEAnalitics.Device" }), this.props.intl.formatMessage({ id: "BEAnalitics.Users" })]];
                let usersByBrowser = [[this.props.intl.formatMessage({ id: "BEAnalitics.Browser" }), this.props.intl.formatMessage({ id: "BEAnalitics.Users" }), { role: 'style' }]];
                let usersByPlataform = [[this.props.intl.formatMessage({ id: "BEAnalitics.Plataform" }), this.props.intl.formatMessage({ id: "BEAnalitics.Users" })]];
                let usersByScreenResolution = [[this.props.intl.formatMessage({ id: "BEAnalitics.ScreenResolution" }), this.props.intl.formatMessage({ id: "BEAnalitics.Users" }), { role: 'style' }]];
                let usersByDeviceModel = [[this.props.intl.formatMessage({ id: "BEAnalitics.DeviceModel" }), this.props.intl.formatMessage({ id: "BEAnalitics.Users" })]];


                json.rows && json.rows.forEach((row) => {
                    usersByOS = this.props.updateChartArray(usersByOS, row, 0, 0);
                    usersByDevice = this.props.updateChartArray(usersByDevice, row, 1, 0);
                    usersByBrowser = this.props.updateChartArray(usersByBrowser, row, 2, 0, 'BarChart');
                    usersByPlataform = this.props.updateChartArray(usersByPlataform, row, 3, 0);
                    usersByScreenResolution = this.props.updateChartArray(usersByScreenResolution, row, 4, 0, 'BarChart');
                    usersByDeviceModel = this.props.updateChartArray(usersByDeviceModel, row, 5, 0);
                });

                this.setState({ usersByBrowser, usersByOS, usersByDevice, usersByPlataform, usersByScreenResolution, usersByDeviceModel, block: false });
            }
        }, `/api/hotel/v1/googleanalytics`, body);
    }


    render() {
        const { block, usersByBrowser, usersByOS, usersByDevice, usersByPlataform, usersByScreenResolution, usersByDeviceModel } = this.state;

        const barOptions = {
            pieSliceText: 'none',
            legend: { position: "none" }
        };

        const pieOptions = {
            pieHole: 0.6,
            pieSliceText: 'none',
            slices: [{ color: '#12b5cb' }, { color: '#ffb703' }, { color: '#7dcfb6' }, { color: '#023047' }, { color: '#fb8500' }]
        };

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={this.state.error} />
                <Row>
                    <Col className="col-6">
                        {usersByDevice && usersByDevice.length > 1 ?
                            <ChartCard
                                chartType='PieChart'
                                data={usersByDevice}
                                options={pieOptions}
                                title={<FormattedMessage id="BEAnalitics.UsersByDeviceCategory" />}
                            />
                        :''}
                    </Col>
                    <Col className="col-6">
                        {usersByDeviceModel && usersByDeviceModel.length > 1 ?
                            <ChartCard
                                chartType='PieChart'
                                data={usersByDeviceModel}
                                options={pieOptions}
                                title={<FormattedMessage id="BEAnalitics.UsersByDeviceModel" />}
                            />
                        :''}
                    </Col>
                </Row>
                <Row className="my-3">
                    <Col className="col-6">
                        {usersByScreenResolution && usersByScreenResolution.length > 1 ?
                            <ChartCard
                                chartType='BarChart'
                                data={usersByScreenResolution}
                                options={barOptions}
                                title={<FormattedMessage id="BEAnalitics.UsersByScreenResolution" />}
                            />
                        :''}
                    </Col>
                    <Col className="col-6">
                        {usersByBrowser && usersByBrowser.length > 1 ?
                            <ChartCard
                                chartType='BarChart'
                                data={usersByBrowser}
                                options={barOptions}
                                title={<FormattedMessage id="BEAnalitics.UsersByBrowser" />}
                            />
                        :''}
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        {usersByPlataform && usersByPlataform.length > 1 ?
                            <ChartCard
                                chartType='PieChart'
                                data={usersByPlataform}
                                options={pieOptions}
                                title={<FormattedMessage id="BEAnalitics.UsersByPlataform" />}
                            />
                        :''}
                    </Col>
                    <Col className="col-6">
                        {usersByOS && usersByOS.length > 1 ?
                            <ChartCard
                                chartType='PieChart'
                                data={usersByOS}
                                options={pieOptions}
                                title={<FormattedMessage id="BEAnalitics.UsersByOperatingSystem" />}
                            />
                        :''}
                    </Col>
                </Row>
            </BlockUi>
        )
    }
}

export default injectIntl(TechDashboard);
