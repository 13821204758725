import React, { Component } from 'react';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { Button, Card, Row, Col } from 'reactstrap';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import { handleNotification } from '../../Base/Notification';
import CustomSelect from '../../Base/Common/CustomSelect';
import countryList from "react-select-country-list";
import Chart from "react-google-charts";
import { Bar } from 'react-chartjs-2';
import { getAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';

class ReservationByNationality extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            startDate: moment().subtract(7, 'days'),
            endDate: moment(),
            reservationsByNacionality: {},
            treeMapNacionalities: [],
            treeMapChannels: [],
            totalAmount: 0
        };
    }

    componentDidMount() {
        this.getReservationByNacionality();
    }

    getReservationByNacionality = () => {
        this.setState({ block: true });

        var param = this.state.country ? `&hotelCountry=${this.state.country}` : '';

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    this.setState({
                        reservations: data.response,
                        error: data.errors
                    }, () => this.calculateData(data.response));
                }
                this.setState(({ block: false }));
            }
        }, `/api/Rate/DashBoard/ReservationsByNationalitySummary?fromDate=${moment(this.state.startDate).format('YYYY-MM-DD')}&toDate=${moment(this.state.endDate).format('YYYY-MM-DD')}` + param);
    }

    calculateData = (data) => {
        var totalReservations = 0, totalAmount = 0, avgAmount = 0; 
        var colorPalette = ["#0099ff", "#00d6ff", "#00b1db", "#028db6", "#026c91", "#004c6d", "#196666", "#008081", "#009999", "#02c5c5", "#66d9dc", "#79a3f9", "#6699ff", "#6666ff"];
        var treeMapNacionalities = [["Location", "Parent", "Market trade volume (size)", "Market increase/decrease (color)"], ["Nationalities", null, 0, 0]];
        var treeMapChannels = [["Location", "Parent", "Market trade volume (size)", "Market increase/decrease (color)"], ["Channels", null, 0, 0]];
        
        //dates
        var dates = [moment(this.state.startDate).format('YYYY-MM-DD')];
        var currDate = moment(this.state.startDate);
        while (currDate.add(1, 'days').diff(this.state.endDate) < 0) {
            dates.push(moment(currDate).format('YYYY-MM-DD'));
        }

        var reservationsByNacionality = { labels: dates, datasets: [] };

                
        data && data.forEach((date, i) => {
            const dateIndex = dates.indexOf(dates.find(el => el === moment(date.date).format('YYYY-MM-DD')));

            date.dateReservationsSummary && date.dateReservationsSummary.forEach(channel => {

                channel.channelReservationsSummary && channel.channelReservationsSummary.forEach(reservation => {
                    //Cards 
                    totalAmount += reservation.totalReservationsAmount;
                    totalReservations += reservation.totalReservations;

                    //Reservations by Nacionality
                    if (!reservationsByNacionality.datasets.some(el => el.label === reservation.nationality)) {
                        reservationsByNacionality.datasets.push(
                            { label: reservation.nationality, data: Array(dates.length).fill(0), backgroundColor: colorPalette[reservationsByNacionality.datasets.length] }
                        );

                        reservationsByNacionality.datasets[reservationsByNacionality.datasets.length - 1].data[dateIndex] = reservation.totalReservations;
                    }
                    else {
                        const datasetIndex = reservationsByNacionality.datasets.indexOf(reservationsByNacionality.datasets.find(el => el.label === reservation.nationality));
                        reservationsByNacionality.datasets[datasetIndex].data[dateIndex] = reservationsByNacionality.datasets[datasetIndex].data[dateIndex] ? reservationsByNacionality.datasets[datasetIndex].data[dateIndex] + reservation.totalReservations : 0 + reservation.totalReservations;
                    }

                    //TreeMap Nacionalities
                    if (!treeMapNacionalities.find(el => el[0] === reservation.nationality)) {
                        treeMapNacionalities.push(
                            [reservation.nationality, 'Nationalities', reservation.totalReservationsAmount, reservation.totalReservationsAmount]
                        );
                    }
                    else {
                        const index = treeMapNacionalities.findIndex(el => el[0] === reservation.nationality);
                        treeMapNacionalities[index][2] = treeMapNacionalities[index][2] ? treeMapNacionalities[index][2] + reservation.totalReservationsAmount : 0 + reservation.totalReservationsAmount;
                        treeMapNacionalities[index][3] = treeMapNacionalities[index][3] ? treeMapNacionalities[index][3] + reservation.totalReservationsAmount : 0 + reservation.totalReservationsAmount;
                    }

                    //TreeMap Channels
                    if (!treeMapChannels.find(el => el[0] === channel.channel)) {
                        treeMapChannels.push(
                            [channel.channel, 'Channels', reservation.totalReservationsAmount, reservation.totalReservationsAmount]
                        );
                    }
                    else {
                        const index = treeMapChannels.findIndex(el => el[0] === channel.channel);
                        treeMapChannels[index][2] = treeMapChannels[index][2] ? treeMapChannels[index][2] + reservation.totalReservationsAmount : 0 + reservation.totalReservationsAmount;
                        treeMapChannels[index][3] = treeMapChannels[index][3] ? treeMapChannels[index][3] + reservation.totalReservationsAmount : 0 + reservation.totalReservationsAmount;
                    }
                })
            })
        })

        avgAmount = totalAmount / totalReservations;

        this.setState({
            totalReservations, totalAmount, avgAmount,
            reservationsByNacionality, treeMapNacionalities, treeMapChannels
        });
    }
    
    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    render() {
        const { totalAmount, totalReservations, avgAmount } = this.state;

        const Crt = ({ text, value }) => {
            return <Card className="shadow" body>
                <Row className="row no-gutters align-items-center">
                    <Col>
                        <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                            {text}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <h3 className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                            {value}
                        </h3>
                    </Col>
                </Row>
            </Card>
        }

        const options = {
            elements: {
                point: {
                    radius: 0
                }
            },
            scales: {
                yAxes: [
                    {
                        display: true,
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
                xAxes: [
                    {

                        stacked: true,
                    },
                ],
            },
        }
        
        const treeMapOptions = {
            minColor: "#e87f7f",
            midColor: "#e8c37f",
            maxColor: "#a9e87f",
            headerHeight: 15,
            fontColor: "black",
            showScale: true,
            generateTooltip: (row, size, value) => {
                return (
                    '<div style="background:#fd9; padding:10px; border-style:solid"> Total Amount: ' + parseFloat(size).toFixed(2) + '</div>'
                )
            },
        };

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <Row className="mt-1">
                        <Col>
                            <Card className="shadow" body>
                                <Row className="mb-2">
                                    <Col sm={3}>
                                        <CustomSelect
                                            icon={"fas fa-globe"}
                                            options={countryList().getData()}
                                            isClearable
                                            isSearchable
                                            onChange={this.handleChangeSelect.bind(this, 'country')}
                                            placeholder={<FormattedMessage id="ReservationsGlobalData.HotelCountry" />}
                                        />
                                    </Col>
                                    <Col className="px-1">
                                        <DateRangePicker
                                            startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                            startDateId="your_unique_start_date_id"
                                            endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                            endDateId="your_unique_end_date_id"
                                            isOutsideRange={day => false}
                                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            small={true}
                                            showDefaultInputIcon={true}
                                        />
                                    </Col>
                                    <Col className="text-right">
                                        <Button className="btn btn-sm btn-host" onClick={this.getReservationByNacionality}>
                                            <i className="fas fa-search" />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col sm={4} className="pr-1">
                            <Crt text={<FormattedMessage id="ReservationsGlobalData.TotalReservations" />} value={totalReservations} />
                        </Col>
                        <Col sm={4} className="px-1">
                            <Crt text={<FormattedMessage id="ReservationsGlobalData.TotalReservationsAmount" />} value={<FormattedNumber value={totalAmount} maximumFractionDigits={2} />} />
                        </Col>
                        <Col sm={4} className="pl-1">
                            <Crt text={<FormattedMessage id="ReservationsGlobalData.AvgReservaionsAmount" />} value={avgAmount ? <FormattedNumber value={avgAmount} maximumFractionDigits={2} /> : 0} />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={6} className="pr-1">
                            <Card body className="mt-2">
                                <Row className="row no-gutters align-items-center">
                                    <Col>
                                        <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                            <FormattedMessage id="ReservationByNationality.AmountPerNationality" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Chart
                                            chartType="TreeMap"
                                            width="100%"
                                            height="400px"
                                            data={this.state.treeMapNacionalities}
                                            options={treeMapOptions}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col sm={6} className="pl-1">
                            <Card body className="mt-2">
                                <Row className="row no-gutters align-items-center">
                                    <Col>
                                        <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                            <FormattedMessage id="ReservationByNationality.AmountPerChannel" />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Chart
                                            chartType="TreeMap"
                                            width="100%"
                                            height="400px"
                                            data={this.state.treeMapChannels}
                                            options={treeMapOptions}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Card body className="mt-2">
                        <Row className="row no-gutters align-items-center">
                            <Col>
                                <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                    <FormattedMessage id="ReservationByNationality.NumberOfReservationsPerNationality" />
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Bar data={this.state.reservationsByNacionality} options={options} height={60} />
                            </Col>
                        </Row>
                    </Card>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(ReservationByNationality)