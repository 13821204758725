import React, { Component } from 'react'
import { Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import UsageTracking from './UsageTracking';
import HeyTravelAISideMenu from './HeyTravelAISideMenu';
import FAQConfig from './FAQConfig';
import Playground from './Playground';

class HeyTravelAI extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: 'FaqConfig',
            block: false,
        };
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { intl } = this.props;
        return (
            <div id="HeyTravelAI" className='d-flex'>
                {!global.isMobile ?
                    <>
                        <div className="mt-2 mb-3 border-0" style={{ maxWidth: '20%', minWidth: '15%' }}>
                            <HeyTravelAISideMenu activeTab={this.state.activeTab} toggleTab={this.toggleTab} />
                        </div>
                        <div className="px-0 h-100 mt-2 mb-3 w-100" style={{maxWidth: '85%'}}>
                            <TabContent activeTab={this.state.activeTab}>
                                {this.state.activeTab === 'FaqConfig' ?
                                    <TabPane tabId="FaqConfig" className="border-0 p-0 h-100 pl-3">
                                        <FAQConfig 
                                            intl={intl}
                                        />
                                    </TabPane>
                                : ""}
                                {this.state.activeTab === 'UsageTracking' ?
                                    <TabPane tabId="UsageTracking" className="border-0 p-0 h-100 pl-3">
                                        <UsageTracking 
                                            intl={intl}
                                        />
                                    </TabPane>
                                    : ""}
                                {this.state.activeTab === 'Playground' ?
                                    <TabPane tabId="Playground" className="border-0 p-0 h-100 pl-3">
                                        <Playground />
                                    </TabPane>
                                    : ""}
                            </TabContent>
                        </div>
                    </>
                :
                    <Col className='col-12 p-0'>
                        <Col className='px-3'>
                            <Nav tabs className='border-0'>
                                <NavItem className='pointer' style={{ borderBottom: this.state.activeTab === 'FaqConfig' ? '3px solid rgb(22, 92, 125)' : '' }}>
                                    <NavLink className='bg-white pointer'
                                        onClick={() => this.toggleTab('FaqConfig')}>
                                            <i className="far fa-comments pr-2"></i>
                                        <FormattedMessage id="HeyTravelAI.FaqConfig"/>
                                    </NavLink>
                                </NavItem>
                                <NavItem className='pointer' style={{ borderBottom: this.state.activeTab === 'UsageTracking' ? '3px solid rgb(22, 92, 125)' : '' }}>
                                    <NavLink className='bg-white pointer'
                                        onClick={() => this.toggleTab('UsageTracking')}>
                                        <i className="far fa-chart-bar pr-2"></i>
                                        <FormattedMessage id="HeyTravelAI.UsageTracking" />
                                    </NavLink>
                                </NavItem>
                                <NavItem className='pointer' style={{ borderBottom: this.state.activeTab === 'Playground' ? '3px solid rgb(22, 92, 125)' : '' }}>
                                    <NavLink className='bg-white pointer'
                                        onClick={() => this.toggleTab('Playground')}>
                                        <i className="fas fa-brain pr-2"></i>
                                        <FormattedMessage id="HeyTravelAI.Playground" />
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <TabContent activeTab={this.state.activeTab}>
                            {this.state.activeTab === 'FaqConfig' ?
                                <TabPane tabId="FaqConfig" className="border-0  p-0 h-100 px-3">
                                    <FAQConfig 
                                        intl={intl}
                                    />
                                </TabPane>
                                : ""}
                            {this.state.activeTab === 'UsageTracking' ?
                                <TabPane tabId="UsageTracking" className="border-0 p-0 h-100 px-3">
                                    <UsageTracking
                                        intl={intl}
                                    />
                                </TabPane>
                                : ""}
                            {this.state.activeTab === 'Playground' ?
                                <TabPane tabId="Playground" className="border-0 p-0 h-100 px-3">
                                    <Playground />
                                </TabPane>
                                : ""}
                        </TabContent>
                    </Col>
                }
            </div>
        )
    }
}

export default injectIntl(HeyTravelAI)