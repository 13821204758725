import classnames from 'classnames';
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import moment from 'moment';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ReactHtmlParser from 'react-html-parser';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, CardBody, CardHeader, Col, InputGroup, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { deleteAPI, getAPI, postAPI, putAPI } from "../../Base/API";
import { StyledCard, SelectChannelConfig } from "../../Base/Common/CommonUIComponents";
import CustomSelect from '../../Base/Common/CustomSelect';
import { CustomTable } from '../../Base/Common/CustomTable';
import EditorText from "../../Base/Common/EditorText";
import { handleNotification } from "../../Base/Notification";

export class Announcement extends Component {

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.toggleTabText = this.toggleTabText.bind(this);
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.getBody = this.getBody.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleDeleteText = this.handleDeleteText.bind(this);
        this.addNewLanguage = this.addNewLanguage.bind(this);
        this.checkAnnouncementTextLength = this.checkAnnouncementTextLength.bind(this);
        this.toggleModalToAddAnnouncement = this.toggleModalToAddAnnouncement.bind(this);
        this.getModalAttributes = this.getModalAttributes.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.setFilterState = this.setFilterState.bind(this);
        this.setChannelConfigDetailsFilterState = this.setChannelConfigDetailsFilterState.bind(this);
        this.createAnnouncementForm = React.createRef();
        this.state = {
            activeTab: '1',
            activeTabText: 0,
            block: false,
            configActions: [],
            modal: false,
            configActionsActive: [],
            configActionsInactive: [],
            date: null,
            modalData: {
                channelConfigDetails: {}
            },
            toCreate: false,
            type: '',
            channelConfigId: ''
        }
    }

    componentDidMount() {
        this.getAnnouncements('/api/User/v1/Announcement');    
    }
    
    toggleModal() {
        this.setState(prevstate => ({
            modal: !prevstate.modal,
            toCreate: false,
            activeTabText: 0
        }));
    }

    setChannelConfigDetailsFilterState = (name, combo) => {
        this.setState(prevState => (
        {
            modalData: {
                ...prevState.modalData,
                channelConfigDetails: {
                    ...prevState.modalData.channelConfigDetails,
                    [name]: combo ? combo.value : null
                }
            }
        }))              
    }

    setModalDataFilterState = (name, combo) => {
        this.setState(prevState => (
        {
            modalData: {
                ...prevState.modalData,
                [name]: combo ? combo.value : null                
            }
        }))              
    }

    setFilterState = (name, combo) => {
        this.setState(({ [name]: combo ? combo.value : null }))              
    }

    doSearch = (evt) => {
        const type = this.state.type ? this.state.type : '';
        const channelConfigId = this.state.channelConfigId ? this.state.channelConfigId : '';
        const date = this.state.date ? this.state.date.format("YYYY-MM-DD") : '';
        this.getAnnouncements(`/api/User/v1/Announcement?date=${date}&type=${type}&channelConfigId=${channelConfigId}`);
    }

    getAnnouncements(url) {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                data.apiAnnouncements.map(announcement => {
                    announcement.announcementText.map(announcementText => {
                        const blocksFromHtml = htmlToDraft(announcementText.text);
                        const { contentBlocks, entityMap } = blocksFromHtml;
                        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                        announcementText.editorState = EditorState.createWithContent(contentState);
                        return null;
                    })
                    return null;
                });
                this.setState({
                    block: false, configActionsActive: data.apiAnnouncements.filter(ann => ann.active).sort(function (a, b) {
                        return moment(b.startDate) - moment(a.startDate);
                    })
                    , configActionsInactive: data.apiAnnouncements.filter(ann => !ann.active).sort(function (a, b) {
                        return moment(b.startDate) - moment(a.startDate);
                    })
                });
            }
        }, url);
    }

    toggleTabText(tab) {
        if (this.state.activeTabText !== tab) {
            this.setState({
                activeTabText: tab.key
            });
        }
    }

    onEditorStateChange(newEditorState, text) {
        const modalData = { ...this.state.modalData };        
        text.editorState = newEditorState;
        this.setState({ modalData });
    };

    handleSave() {
        const newAnnouncement = this.getBody();      
        const body = {
            apiAnnouncement: newAnnouncement
        };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const { modalData, configActionsActive, configActionsInactive } = this.state;
                if (modalData.active) {
                    this.updateAnnouncements(configActionsActive, modalData);
                }
                else {
                    this.updateAnnouncements(configActionsInactive, modalData);
                }

                handleNotification(data, <FormattedMessage id="Announcement.SuccessMessageUpdate" />, <FormattedMessage id="General.Success" />);
                this.setState({ block: false, modal: false, configActionsActive, configActionsInactive });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/User/v1/Announcement/${body.apiAnnouncement.id}`, JSON.stringify(body));        
    }

    getBody() {
        const newAnnouncement = {
            active: this.state.modalData.active,
            id: this.state.modalData.id,
            channelConfigDetails: {
                id: this.state.modalData.channelConfigDetails.id,
                channelCode: this.state.modalData.channelConfigDetails.channelCode
            },
            type: this.state.modalData.type,
            startDate: this.state.modalData.startDate,
            endDate: this.state.modalData.endDate,
            announcementText: [],
            highlighted: this.state.modalData.highlighted
        };

        this.state.modalData.announcementText.map(announcementText => {
            const textConverted = draftToHtml(convertToRaw(announcementText.editorState.getCurrentContent()));
            const newText = {
                language: announcementText.language,
                text: textConverted
            };
            if (announcementText.id) {
                newText.id = announcementText.id;
            }
            newAnnouncement.announcementText.push(newText);
            return null;
        });
        
        return newAnnouncement;
    }

    updateAnnouncements = (configActions, modalData) => {
        const currentAnnouncement = configActions.find(ann => ann.id === modalData.id);
        if (currentAnnouncement) {
            const announcementIdx = configActions.indexOf(currentAnnouncement);
            configActions[announcementIdx] = modalData;
        }
        else {
            configActions.push(modalData);
        }
    }

    handleDelete() {
        deleteAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            handleNotification(data, <FormattedMessage id="Announcement.SuccessMessageDelete" />, 'Success');
            const newAnnouncements = this.state.configActions;            
            const elementsToRemove = 1;
            newAnnouncements.splice(this.state.configActions.indexOf(this.state.configActions.find(item => item.id === this.state.modalData.id)), elementsToRemove);
            this.setState({ modal: false, configActions: newAnnouncements }, () => this.doSearch());
        }, `/api/User/v1/Announcement/${this.state.modalData.id}`);
    }

    addNewLanguage() {
        const languages = document.getElementById("addNewAnnouncementText");
        const selectedLanguage = languages.options[languages.selectedIndex].value;
        const newAnnouncementText = {
            language: selectedLanguage,
            editorState: EditorState.createEmpty()
        };
        this.setState(prevState => (
        {
            activeTabText: prevState.modalData.announcementText.length,
            modalData: {
                ...prevState.modalData,
                announcementText: [...prevState.modalData.announcementText, newAnnouncementText]
            }
        }))
    }

    handleDeleteText() {
        const newAnnouncementTexts = this.state.modalData.announcementText;
        const elementsToRemove = 1;
        newAnnouncementTexts.splice(this.state.activeTabText, elementsToRemove);
        this.setState(prevState => (
            {
                activeTabText: prevState.activeTabText === 0 ? 0 : prevState.activeTabText - 1,
                modalData: {
                    ...prevState.modalData,
                    announcementText: newAnnouncementTexts
                }
            }))
    }

    checkAnnouncementTextLength() {
        if (this.state.modalData.announcementText) {
            const elementsToRemove = 1;
            return this.state.modalData.announcementText.length <= elementsToRemove;
        }
        return false;
    }

    toggleModalToAddAnnouncement() {
        const newModalData = {
            active: true,
            channelConfigDetails: {},
            type: null,
            startDate: moment(),
            endDate: moment(),
            announcementText: []
        };
        this.setState({ modal: true, modalData: newModalData, toCreate: true });
    }

    getModalAttributes() {
        if (this.state.toCreate) {
            const modalAttributes = {
                title: <FormattedMessage id="Announcement.Create" />,
                header: <div className="mb-2">
                    <strong><FormattedMessage id="Announcement.New" /></strong>
                    <Button color=" btn-host btn-sm float-right" onClick={this.handleCreate} > <FormattedMessage id="Announcement.Create" /> </Button>
                </div>,
                required: true,
                value: this.state.modalData.channelConfigDetails ? this.state.modalData.channelConfigDetails.id : null
            }
            return modalAttributes;
        }
        else {
            const modalAttributes = {
                title: <FormattedMessage id="Announcement.Details" />,
                header: <div className="mb-2">
                    <strong>{this.state.modalData.channelConfigDetails.channelCode}</strong>
                    <Button color=" btn-host  btn-sm float-right mx-1" onClick={this.handleDelete} > <i className="fas fa-trash-alt"></i> </Button>
                    <Button color=" btn-host btn-sm float-right mx-1" onClick={this.handleSave} > <i className="fas fa-save"></i> </Button>
                    <Button color=" btn-host btn-sm float-right mx-1" onClick={this.handleSetStatus} > <i className="fas fa-power-off"></i> </Button>
                </div>,
                required: false,
                value: this.state.modalData.channelConfigDetails.id,
                type: this.state.modalData.type,
                highlighted: this.state.modalData.highlighted
            }
            return modalAttributes;            
        }
    }

    handleCreate() {
        const announcementTextEditor = document.getElementById('addNewAnnouncementText');        
        if (this.state.modalData && this.state.modalData.announcementText.length === 0) {
            announcementTextEditor.setCustomValidity(this.props.intl.formatMessage({ id: "AnnouncementText.IsEmpty" }));
        } else {
            announcementTextEditor.setCustomValidity("");
        }

        if (this.createAnnouncementForm.current.reportValidity()) {
            const newAnnouncement = this.getBody();
            const body = {
                apiAnnouncements: [ newAnnouncement ]
            };
            this.setState(({ block: true }));
            postAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ block: isLoading });
                if (error) {
                    const errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState(prevState => ({ block: false, modalData: { ...prevState.modalData, error: errorMessage } }));
                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="Announcement.SuccessMessagePost" />, 'Success');
                    data.apiAnnouncements[0].announcementText.map(announcementText => {
                            const blocksFromHtml = htmlToDraft(announcementText.text);
                            const { contentBlocks, entityMap } = blocksFromHtml;
                            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                            announcementText.editorState = EditorState.createWithContent(contentState);
                    });
                    this.setState(prevState => ({ modal: false, toCreate: false, configActions: [...prevState.configActions, data.apiAnnouncements[0]] }), () => this.doSearch());
                }
            }, '/api/User/v1/Announcement', JSON.stringify(body));
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    handleSetStatus = () => {
        const newAnnouncement = this.getBody();
        newAnnouncement.active = !newAnnouncement.active
        const body = {
            apiAnnouncement: newAnnouncement
        };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const { modalData, configActionsActive, configActionsInactive } = this.state;
                if (newAnnouncement.active) {
                    const announcement = configActionsInactive.splice(configActionsInactive.indexOf(modalData), 1);
                    configActionsActive.push(announcement[0]);
                }
                else {
                    const announcement = configActionsActive.splice(configActionsActive.indexOf(modalData), 1);
                    configActionsInactive.push(announcement[0]);
                }

                handleNotification(data, <FormattedMessage id="Announcement.SuccessSetStatus" />, <FormattedMessage id="General.Success" />);
                this.setState({ block: false, modal: false, configActionsActive, configActionsInactive });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/User/v1/Announcement/${body.apiAnnouncement.id}`, JSON.stringify(body));
    }

    render() {

        const columns = [
            {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "Announcement.ID" }),
                sort: true
            },
            {
                dataField: 'channelConfigDetails.channelCode',
                text: this.props.intl.formatMessage({ id: "Announcement.ChannelCode" }),
                sort: true
            },
            {
                dataField: 'type',
                text: this.props.intl.formatMessage({ id: "Announcement.Type" }),
                sort: true
            },
            {
                dataField: 'startDate',
                text: this.props.intl.formatMessage({ id: "Announcement.StartDate" }),
                sort: true,
                formatter: (cell) => moment(cell).format('YYYY-MM-DD')
            },
            {
                dataField: 'endDate',
                text: this.props.intl.formatMessage({ id: "Announcement.EndDate" }),
                sort: true,
                formatter: (cell) => moment(cell).format('YYYY-MM-DD')
            },
            {
                dataField: 'highlighted',
                text: this.props.intl.formatMessage({ id: "Announcement.Highlighted" }),
                sort: true,
                formatter: (cell, row) => cell ? <i style={{ color: 'green' }} className="fas fa-check pr-3 w-100 text-center" /> : <i className="fas fa-times pr-3 w-100 text-center" />
            },
            {
                dataField: 'announcementText',
                text: this.props.intl.formatMessage({ id: "Announcement.msg" }),
                sort: true,
                formatter: (cell, row) => <Card><CardBody>{ReactHtmlParser(cell[0].text)}</CardBody></Card>
            }
        ]

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, modalData: row })
            }
        };

        const types = [
            {
                'label': 'General',
                'value': 'General'
            },{
                'label': 'Channel Dedicated',
                'value': 'Channel Dedicated'
            }
        ]

        const highlightedOptions = [
            {
                'label': <FormattedMessage id="Announcement.Yes" />,
                'value': true
            }, {
                'label': <FormattedMessage id="Announcement.No" />,
                'value': false
            }
        ]

        const texts = this.state.modalData.announcementText === undefined ? [] : this.state.modalData.announcementText; 
       
        const supportedLanguages = ['af-ZA', 'ar-SA', 'cs-CZ', 'da-DK', 'de-DE', 'el-GR', 'en-GB', 'es-ES', 'fi-FI', 'fr-FR', 'it-IT', 'he-IL', 'hu-HU', 'ja-JP', 'nb-NO', 'nl-NL', 'pt-PT', 'ru-RU', 'sv-SE', 'sv-FI', 'zh-TW'];

        const modalAttributes = this.getModalAttributes();

        const help = <div>TODO</div>

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-bullhorn'} title={'Announcement.title'} help={help}>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                    <ModalHeader toggle={this.toggleModal}  > {modalAttributes.title} </ModalHeader>
                    <ModalBody className="modal-lg px-0 pb-0">
                        <div>
                            <div>
                                <div className="bg-white border-bottom card-header">
                                    {modalAttributes.header}                                    
                                </div>
                                <div className="card-body">
                                    <form ref={this.createAnnouncementForm}>
                                        <div className="row">
                                            <div className="col-3 col">
                                                <div className="row form-group">
                                                    <label for="type" className="col-sm-4 col-form-label">
                                                        <span><FormattedMessage id="Announcement.Type" /></span>
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <BlockUi tag="div" blocking={this.state.block}>
                                                            <CustomSelect name={'type'} options={types} isSearchable placeholder={<FormattedMessage id="Announcement.ChooseType" />} value={types.find(el => el.value === modalAttributes.type)} required={modalAttributes.required} onChange={this.setModalDataFilterState.bind(this, 'type')} />
                                                        </BlockUi>                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3 col">
                                                <div className="row form-group">
                                                    <label for="channelCode" className="col-sm-4 col-form-label">
                                                        <span><FormattedMessage id="Announcement.ChannelCode" /></span>
                                                    </label>
                                                    <div className="col-sm-8" style={{ zIndex: '999' }}>
                                                        <SelectChannelConfig name={'id'} onChangeFunc={this.setChannelConfigDetailsFilterState} required={modalAttributes.required} placeholder={<FormattedMessage id="Announcement.ChooseChannel" />} value={modalAttributes.value} />                                                    
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3 col">
                                                <div className="row form-group">
                                                    <label for="highlighted" className="col-sm-5 col-form-label">
                                                        <span><FormattedMessage id="Announcement.Highlighted" /></span>
                                                    </label>
                                                    <div className="col-sm-7">
                                                        <CustomSelect options={highlightedOptions} isSearchable placeholder={<FormattedMessage id="Announcement.Highlighted" />} value={highlightedOptions.find(el => el.value === modalAttributes.highlighted)} onChange={this.setModalDataFilterState.bind(this, 'highlighted')} required />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3 col">
                                                <div className="row form-group">
                                                    <label for="StartDate" className="col-sm-2 col-form-label">
                                                        <span><FormattedMessage id="Announcement.ValidDate" /></span>
                                                    </label>
                                                    <div className="col-sm-10 px-1" style={{ zIndex: '999' }}>
                                                        <InputGroup size="sm text-right" >
                                                            <DateRangePicker
                                                                startDate={this.state.modalData.startDate ? moment(this.state.modalData.startDate).locale(moment.locale()) : this.state.modalData.startDate}
                                                                startDateId="your_unique_start_date_id_announcement"
                                                                isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment().add(6, 'month')}
                                                                endDate={this.state.modalData.endDate ? moment(this.state.modalData.endDate).locale(moment.locale()) : this.state.modalData.endDate}
                                                                endDateId="your_unique_end_date_id_announcement"
                                                                onDatesChange={({ startDate, endDate }) => this.setState(prevState => ({ modalData: { ...prevState.modalData, startDate: startDate, endDate: endDate } }))}
                                                                focusedInput={this.state.modalData.focusedInput}
                                                                onFocusChange={focusedInput => this.setState(prevState => ({ modalData: { ...prevState.modalData, focusedInput: focusedInput } }))}
                                                                small={true}
                                                                numberOfMonths={1}
                                                                showDefaultInputIcon={true}
                                                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                                            />
                                                        </InputGroup>                                                   
                                                    </div>
                                                </div>
                                            </div>
                                        </div>      
                                        <div>
                                            <Card className=" border-0 shadow mb-2">
                                                <CardHeader className="border-bottom bg-white" >
                                                    <div>
                                                        <span className="fas fa-bullhorn mr-1"><FormattedMessage id="AnnouncementText.Texts"/></span>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <Nav tabs>
                                                        {texts.map((text, key) =>
                                                            <NavItem key={key}>
                                                                <NavLink key={key} onClick={() => { this.toggleTabText({ key }); }} className={classnames({ active: this.state.activeTabText === key })}>
                                                                    <span className={'flag-icon flag-icon-' + text.language.substring(3).toLowerCase()} /> {text.language.substring(3).toUpperCase()}
                                                                </NavLink>
                                                            </NavItem>
                                                        )}
                                                        <NavItem >
                                                            <NavLink >
                                                                <select id="addNewAnnouncementText" className="mr-2" required={this.state.toCreate}>
                                                                    {supportedLanguages.map((item, key) =>
                                                                        <option key={key} value={item}>{item.substring(3)}</option>
                                                                    )}
                                                                </select>
                                                                <Button onClick={() => { this.addNewLanguage() }} className=" btn-host btn-sm " style={{ 'fontSize': '0.35rem' }}  ><i className="fas fa-plus"></i> </Button>
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem className="ml-auto">
                                                            <Button color=" btn-host btn-sm " onClick={this.handleDeleteText} disabled={this.checkAnnouncementTextLength()}> <i className="fas fa-trash-alt"></i> </Button>
                                                        </NavItem>
                                                    </Nav>
                                                    <TabContent activeTab={this.state.activeTabText}>
                                                        {texts.map((text, key) =>
                                                            <TabPane tabId={key} key={key}>
                                                                <Row className="mb-2">
                                                                    <Col className="col-12">                                                                    
                                                                        <div>
                                                                            <EditorText editorState={text.editorState} onEditorStateChange={(newEditorState) => { this.onEditorStateChange(newEditorState, text) }} />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </TabPane>
                                                        )}
                                                    </TabContent>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </ModalBody>

                </Modal>

                <Row>                    
                    <Col>
                        <BlockUi tag="div" blocking={this.state.block}>
                            <CustomSelect name={'type'} options={types} isSearchable onChange={this.setFilterState.bind(this, 'type')} placeholder={<FormattedMessage id="Announcement.ChooseType" />} />
                        </BlockUi>
                    </Col>
                    <Col>
                        <SelectChannelConfig name={'channelConfigId'} onChangeFunc={this.setFilterState} placeholder={<FormattedMessage id="Announcement.ChooseChannel" />} value={this.state.channelConfigId} />                       
                    </Col>
                    <Col>
                        <SingleDatePicker
                            id="AnnouncementValidDate"
                            isOutsideRange={_ => false}
                            date={this.state.date}
                            onDateChange={date => this.setState({ date })}
                            focused={this.state.focused}
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            small={true}
                            search={true}
                            numberOfMonths={1}
                            required={true}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col>
                        <Button className="btn btn-host btn-sm float-right mx-1" onClick={this.doSearch.bind(this)}><i className="fas fa-search"></i></Button>
                        <Button className="btn btn-host btn-sm text-white float-right" onClick={this.toggleModalToAddAnnouncement} > <i className="fas fa-plus"></i> </Button>
                    </Col>                    
                </Row>
                <br />
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="generic.active" />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                            <FormattedMessage id="generic.inactive" />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <Row>
                            <Col>
                                <CustomTable
                                    data={this.state.configActionsActive}
                                    columns={columns}
                                    shadow={false}
                                    exportCSV={false}
                                    selectRow={selectRow}
                                    search={false}
                                    remote={false}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="2">
                        <Row>
                            <Col>
                                <CustomTable
                                    data={this.state.configActionsInactive}
                                    columns={columns}
                                    shadow={false}
                                    exportCSV={false}
                                    selectRow={selectRow}
                                    search={false}
                                    remote={false}
                                />
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>                                
            </StyledCard>
        );
    }

}
export default injectIntl(Announcement)