import React, { Component } from 'react';
import { Row, Col, Card, CardBody, InputGroup, Badge, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from "../Base/API"
import { DateRangePicker } from 'react-dates';
import moment from "moment";
import { CustomTable } from '../Base/Common/CustomTable'
import { ReservationStatusBadge, StyledCard, SelectHotel, FormatAmountNumber, reservationStatus } from '../Base/Common/CommonUIComponents'
import classnames from "classnames";

class SAPOBilling extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            block: true,
            activeTab: "1",
            reservationsList: [],
            startDate: moment().subtract(15, 'days'),
            endDate: moment(),
            cancelDeposit: 0,
            comissionAmount: Number(0).toFixed(2),
            noshowDeposit: 0,
            resValue: 0
        }
    }

    componentDidMount() {
        this.getRates();
    }

    getRates = () => {
        this.setState({ block: true });
        var params = this.state.hotel ? `&hotelId=${this.state.hotel}` : '';
        const startDate = this.state.startDate ? this.state.startDate.format("YYYY-MM-DD") : '';
        const endDate = this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : '';

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }

                const resValue = data.response.length > 0 ? Number(data.response.reduce((acc, cur) => cur.status !== 'Canceled' ? acc + cur.criteriaAmount : acc, 0)).toFixed(2) : 0
                const cancelDeposit = Number(data.response.filter(r => r.status === 'Canceled' && r.deposit && r.deposit.depositAmount > 0 && r.deposit.isReturned != true).reduce((acc, cur) => acc + cur.deposit.depositAmount, 0)).toFixed(2)
                const noshowDeposit = Number(data.response.filter(r => r.status !== 'Canceled' && r.deposit && r.deposit.depositAmount > 0 && r.deposit.isReturned != true && r.noShow === true).reduce((acc, cur) => acc + cur.deposit.depositAmount, 0)).toFixed(2) 

                //reservationStatus[0] - created
                //reservationStatus[1] - modified
                const comissionAmount = data.response.length > 0 ? Number(data.response.reduce((acc, cur) => (cur.status === reservationStatus[0].value || cur.status === reservationStatus[1].value) && !cur.noShow && cur.amountCommission > 0 ? acc + cur.amountCommission : acc, 0)).toFixed(2) : 0

                this.setState({ reservationsList: data.response, block: false, error: data.erros, cancelDeposit, comissionAmount, noshowDeposit, resValue });
            }
        }, `/api/Price/Reservation/v1/reservation/sapo/rates?startDate=${startDate}&endDate=${endDate}` + params);
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    getDepositStatus = (deposit) => {
        if (deposit && deposit.isReturned) {
            return (
                <Badge className='p-1 px-2 shadow' color='danger' >{this.props.intl.formatMessage({ id: "ReservationList.Returned" })}</Badge>
            )
        }

        if (deposit && !deposit.isReturned) {
            return (
                <Badge className='p-1 px-2 shadow' color='success' >{this.props.intl.formatMessage({ id: "ReservationList.Effected" })}</Badge>
            )
        }

        if (deposit === null) {
            return (
                <Badge className='p-1 px-2 shadow' color='secondary' >{this.props.intl.formatMessage({ id: "ReservationList.NotEffected" })}</Badge>
            )
        }
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { cancelDeposit, comissionAmount, noshowDeposit, resValue } = this.state

        const columns = [
            {
                dataField: 'resIdValue',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationId" }),
                sort: true
            }, {
                dataField: 'resIdDate',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationDate" }),
                formatter: (cell, row) => cell ? <div>{moment(cell).format("YYYY-MM-DD HH:mm:ss")}</div> : '',
                sort: true
            }, {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "SAPOBilling.HotelName" }),
                sort: true
            }, {
                dataField: 'rooms.length',
                text: this.props.intl.formatMessage({ id: "SAPOBilling.RoomStays" }),
                formatter: (cell, row) => row.rooms ? <div>{row.rooms.length}</div> : '',
                csvFormatter: (cell, row, rowIndex) => row.rooms.length,
                sort: true
            }, {
                dataField: 'rooms[0].chenckIn',
                text: this.props.intl.formatMessage({ id: "SAPOBilling.NumDays" }),
                formatter: (cell, row) => row.rooms ? <div>{moment(moment(row.rooms[0].checkOut).format("YYYY-MM-DD")).diff(moment(row.rooms[0].checkIn).format("YYYY-MM-DD"), 'days')}</div> : '',
                csvFormatter: (cell, row, rowIndex) => `${ moment(moment(row.rooms[0].checkOut).format("YYYY-MM-DD")).diff(moment(row.rooms[0].checkIn).format("YYYY-MM-DD"), 'days') }`,
                sort: true
            }, {
                dataField: 'criteriaAmount',
                text: this.props.intl.formatMessage({ id: "SAPOBilling.TotalAmount" }),
                formatter: (cell, row) => cell ? <div>{Number(cell).toFixed(2)} €</div> : '',
                sort: true
            },
            {
                dataField: 'amountCommission',
                text: this.props.intl.formatMessage({ id: "ReservationList.Commission" }),
                formatter: (cell) => cell ? <div>{Number(cell).toFixed(2)} €</div> : '',
                sort: true
            },
            {
                dataField: 'deposit',
                text: this.props.intl.formatMessage({ id: "ReservationList.Deposit" }),
                formatter: (cell, row) => row.deposit ? this.getDepositStatus(row.deposit) : this.getDepositStatus(null),
                csvFormatter: (cell, row) => `${row.deposit ? row.deposit.isReturned ? this.props.intl.formatMessage({ id: "ReservationList.Returned" }) : this.props.intl.formatMessage({ id: "ReservationList.Effected" }) : this.props.intl.formatMessage({ id: "ReservationList.NotEffected" })}`,
                sort: true
            }, {
                dataField: 'deposit.depositAmount',
                text: this.props.intl.formatMessage({ id: "ReservationList.DepositAmount" }),
                formatter: (cell, row) => row.deposit ? <div className="ml-4"> {Number(row.deposit.depositAmount).toFixed(2)} € </div> : '',
                csvFormatter: (cell, row, rowIndex) => row.deposit && row.deposit.depositAmount ? row.deposit.depositAmount : '',
                sort: true
            }, {
                dataField: 'noShow',
                text: this.props.intl.formatMessage({ id: "SAPOBilling.NoShow" }),
                formatter: (cell, row) => cell ? <div className="ml-3">{cell === true ? <i className="fas fa-check"></i> : ''}</div> : '',
                sort: true
            }, {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "SAPOBilling.Status" }),
                formatter: (cell, row) => <ReservationStatusBadge status={cell}></ReservationStatusBadge>,
                sort: true
            }
        ];

        function Crt(props) {
            return <Card className="shadow">
                <CardBody className="py-2">
                    <Row>
                        <Col className="text-center mb-2">
                            <div className="text-uppercase ">{props.text}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <h3 className="pb-1"><div className="text-value-xl color-host"> {props.value}</div></h3>
                        </Col>
                        <Col className="text-center">
                            <h3 className="pb-1"><div className="text-value-xl color-host"> {props.amount}</div></h3>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        }

        return (


            <div>

                <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-file-invoice'} title={'SAPOBilling.SAPOBilling'} help={'TODO'}>

                    <Row>
                        <Col className="col-12">
                            <Row>
                                <Col className="col-2 pr-1">
                                    <SelectHotel
                                        name={'hotel'}
                                        icon={'fas fa-hotel'}
                                        onChangeFunc={this.handleChangeSelect}
                                        placeholder={<FormattedMessage id="SAPOReservations.Hotel" />}
                                        value={this.state.hotel}
                                    />
                                </Col>
                                <Col className="col-3 px-1">
                                    <InputGroup size="sm text-right" >
                                        <DateRangePicker
                                            startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                            startDateId="your_unique_start_date_id"
                                            isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment().add(6, 'month')}
                                            endDate={this.state.startDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.startDate}
                                            endDateId="your_unique_end_date_id"
                                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            small={true}
                                            numberOfMonths={1}
                                            showDefaultInputIcon={true}
                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col className="text-right">
                                    <button className="btn btn-host btn-sm" onClick={this.getRates}>
                                        <span className="fas fa-search" />
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr className="border-host" />
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                            >
                                Account Summary
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggle('2'); }}
                            >
                                Reservation Details
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <CardBody className="shadow mb-2" >

                                <Row className="border-bottom border-host mb-1">
                                    <Col><b>Account Summary</b></Col>
                                    <Col>#</Col>
                                    <Col>Deposit</Col>
                                    <Col className="col-2 text-right">Value</Col>
                                    <Col className="col-2" />
                                </Row>
                                <Row className="mb-1">
                                    <Col>Reservations</Col>
                                    <Col>{this.state.reservationsList.filter(r => r.status !== 'Canceled' && r.noShow !== true).length}</Col>
                                    <Col></Col>
                                    <Col className="col-2 text-right"><b><FormatAmountNumber value={resValue} currency={global.hotelCurrency ? global.hotelCurrency : 'EUR'} /></b></Col>
                                    <Col className="col-2" />
                                </Row>
                                <Row className="mb-1">
                                    <Col>Reservations Canceled With Deposit</Col>
                                    <Col>{this.state.reservationsList.filter(r => r.status === 'Canceled' && r.deposit && r.deposit.depositAmount > 0 && r.deposit.isReturned != true).length}</Col>
                                    <Col><b>{cancelDeposit} €</b></Col>
                                    <Col className="col-2 text-right"><FormatAmountNumber value={Number(this.state.reservationsList.filter(r => r.status === 'Canceled' && r.deposit && r.deposit.depositAmount > 0 && r.deposit.isReturned != true).reduce((acc, cur) => acc + cur.criteriaAmount, 0)).toFixed(2)} currency={global.hotelCurrency ? global.hotelCurrency : 'EUR'} /></Col>
                                    <Col className="col-2"/>
                                </Row>
                                <Row className="mb-1">
                                    <Col>Reservations Canceled No deposit</Col>
                                    <Col>{this.state.reservationsList.filter(r => r.status === 'Canceled' && (r.deposit === undefined || r.deposit.isReturned === true)).length}</Col>
                                    <Col></Col>
                                    <Col className="col-2 text-right"><FormatAmountNumber value={Number(this.state.reservationsList.filter(r => r.status === 'Canceled' && (r.deposit === undefined || r.deposit.isReturned === true)).reduce((acc, cur) => acc + cur.criteriaAmount, 0)).toFixed(2)} currency={global.hotelCurrency ? global.hotelCurrency : 'EUR'} /></Col>
                                    <Col className="col-2"/>
                                </Row>
                                <Row className="mb-1">
                                    <Col>No Show with Deposit</Col>
                                    <Col>{this.state.reservationsList.filter(r => r.status !== 'Canceled' && r.deposit && r.deposit.depositAmount > 0 && r.deposit.isReturned != true && r.noShow === true).length}</Col>
                                    <Col><b>{noshowDeposit} €</b></Col>
                                    <Col className="col-2 text-right"><FormatAmountNumber value={Number(this.state.reservationsList.filter(r => r.status !== 'Canceled' && r.deposit && r.deposit.depositAmount > 0 && r.deposit.isReturned != true && r.noShow === true).reduce((acc, cur) => acc + cur.criteriaAmount, 0)).toFixed(2)} currency={global.hotelCurrency ? global.hotelCurrency : 'EUR'} /></Col>
                                    <Col className="col-2"/>
                                </Row>
                                <Row className="mb-1">
                                    <Col>No Show with No Deposit</Col>
                                    <Col>{this.state.reservationsList.filter(r => r.status !== 'Canceled' && (r.deposit === undefined || r.deposit.isReturned === true) && r.noShow === true).length}</Col>
                                    <Col className="border-bottom"></Col>
                                    <Col className="border-bottom col-2 text-right"><FormatAmountNumber value={Number(this.state.reservationsList.filter(r => r.status !== 'Canceled' && (r.deposit === undefined || r.deposit.isReturned === true) && r.noShow === true).reduce((acc, cur) => acc + cur.criteriaAmount, 0)).toFixed(2)} currency={global.hotelCurrency ? global.hotelCurrency : 'EUR'} /></Col>
                                    <Col className="border-bottom col-2" />
                                </Row>

                                <Row className="mb-1">
                                    <Col></Col>
                                    <Col></Col>
                                    <Col><b>Amount Subject to Commission</b></Col>
                                    <Col className="color-host col-2 text-right"><h5><FormatAmountNumber value={Number(resValue) + Number(cancelDeposit) + Number(noshowDeposit)} currency={global.hotelCurrency ? global.hotelCurrency : 'EUR'} /></h5></Col>
                                    <Col className="col-2"/>
                                </Row>
                                <Row className="mb-1">
                                    <Col></Col>
                                    <Col></Col>
                                    <Col><b>Total Commission</b></Col>
                                    <Col className="color-host col-2 text-right"><h5><FormatAmountNumber value={comissionAmount} currency={global.hotelCurrency ? global.hotelCurrency : 'EUR'} /></h5></Col>
                                    <Col className="col-2" />
                                </Row>

                                <Row className="border-bottom border-host mb-1 mt-4">
                                    <Col><b>Statistics</b></Col>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col ></Col>
                                </Row>
                                <Row className="mb-1">
                                    <Col>Canceled Reservations</Col>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col className="col-2 text-right">{Number(this.state.reservationsList.filter(r => r.status === 'Canceled').length / this.state.reservationsList.length * 100).toFixed(2)} %</Col>
                                    <Col className="col-2"/>
                                </Row>
                                <Row className="mb-1">
                                    <Col>No Shows </Col>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col className="col-2 text-right">{Number(this.state.reservationsList.filter(r => r.noShow === true).length / this.state.reservationsList.length * 100).toFixed(2)} %</Col>
                                    <Col className="col-2"/>
                                </Row>
                                <Row className="mb-1">
                                    <Col>Average Reservation Value </Col>
                                    <Col></Col>
                                    <Col></Col>
                                    <Col className="col-2 text-right"><FormatAmountNumber value={this.state.reservationsList.length > 0 ? Number(this.state.reservationsList.reduce((acc, cur) => acc + cur.reserveAmount, 0) / this.state.reservationsList.length) : 0} currency={global.hotelCurrency ? global.hotelCurrency : 'EUR'} /></Col>
                                    <Col className="col-2" />
                                </Row>
                            </CardBody>
                        </TabPane>
                        <TabPane tabId="2">
                            <Row>
                                <Col className="col-12">
                                    <Card className=" border-0 ">
                                        <CustomTable
                                            data={this.state.reservationsList ? this.state.reservationsList : []}
                                            columns={columns}
                                            page={this.state.currentPage}
                                            sizePerPage={this.state.resultsPerPage}
                                            totalSize={this.state.channelData && this.state.channelData.length}
                                            onTableChange={this.handleTableChange}
                                            shadow={false}
                                            exportCSV={true}
                                            search={true}
                                            remote={false}
                                            keyField={'resIdValue'}
                                        />
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                    {/*
                    <Row className="my-2 mb-4">
                        <Col className="pr-1" >
                            <Crt
                                text={<FormattedMessage id="SAPOBilling.Reservations" />}
                                value={this.state.reservationsList.filter(r => r.status !== 'Canceled').length}
                                amount={this.state.reservationsList.length > 0 ? Number(this.state.reservationsList.reduce((acc, cur) => cur.status !== 'Canceled' ? acc + cur.criteriaAmount : acc, 0)).toFixed(2) + "€" : 0 + "€"}
                            />
                        </Col>
                        <Col className="px-1">
                            <Crt
                                text={<FormattedMessage id="SAPOBilling.Canceled" />}
                                value={this.state.reservationsList.filter(r => r.status === 'Canceled').length}
                                amount={this.state.reservationsList.length > 0 ? Number(this.state.reservationsList.reduce((acc, cur) => cur.status === 'Canceled' ? acc + cur.criteriaAmount : acc, 0)).toFixed(2) + "€" : 0 + "€"}
                            />
                        </Col>
                        <Col className="px-1">
                            <Crt
                                text={<FormattedMessage id="SAPOBilling.NoShowReservations" />}
                                value={this.state.reservationsList.filter(r => r.noShow === true).length}
                                amount={this.state.reservationsList.length > 0 ? Number(this.state.reservationsList.reduce((acc, cur) => cur.noShow === true ? acc + cur.criteriaAmount : acc, 0)).toFixed(2) + "€" : 0 + "€"}
                            />
                        </Col>
                    </Row>
                   */}
                </StyledCard>
            </div>
        );
    }
}

export default injectIntl(SAPOBilling)