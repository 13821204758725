import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Button, Card, CardBody, Col, Row, Table, UncontrolledCollapse, UncontrolledTooltip} from 'reactstrap';
import { getAPI, postAPI } from "../../Base/API";
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from "../../Base/Notification";
import Authorization from '../../Base/Authorization';
import { FormattedMessage } from "react-intl";
import { getQueueTypesOptions } from '../../Base/Common/ReferenceDataFunctions';
import CustomToolTip from '../../../Utils/CustomToolTip';


export class QueueStatusDashboard extends Component {

    state = {
        block: true,
        selectedMessages: [],
        isDeadletter: false,
        viewtype: "msg",
        hotelOptions: [],
        typeOptions: [],
        hotelIdFilter: null,
        typeFilter: null,
        isInbound: getQueueTypesOptions()[0].value
    }
    
    componentDidMount() {
        this.getQueueData();
    }

    getQueueData() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ QueueData: data, block: false });
            }
        }, `/api/User/Dashboard/v1/Queue${this.state.isInbound !== null ? `?isInbound=${this.state.isInbound}` : ``}`);
    }

    peekQueue(name, isDeadletter) {
        this.setState({ block: true, queueName: name });
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false});

                return;
            }
            if (data) {
                let hotelOptions = [], typeOptions = [];

                data.queueMessage && data.queueMessage.forEach(item => {
                    const body = JSON.parse(item.body);

                    if (!hotelOptions.some(el => el.value === item.hotelId)) {
                        hotelOptions.push({ value: item.hotelId, label: item.hotelId });
                    }
                    if (body && !typeOptions.some(el => el.value === body.Tag)) {
                        typeOptions.push({ value: body.Tag, label: body.Tag });
                    }

                    item.tag = body ? body.Tag : '';
                    item.visible = true;
                });

                this.setState({
                    PeekQueueData: data, selectedMessages: [], block: false, isDeadletter: isDeadletter,
                    hotelOptions, typeOptions, hotelIdFilter: null, typeFilter: null
                });
            }
        }, '/api/User/Dashboard/v1/Queue/'+name+'?isDeadLetter=' + isDeadletter);
    }

    submitMessage(msgId, evt) {
        let req = this.state.selectedMessages;

        this.setState({ block: true });
        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message,  stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="QueueStatusDashboard.DeadLetterConfirm" values={{ messages: data.count }} />, <FormattedMessage id="General.Success" />);
                this.setState({ PeekQueueData: data, PeekQueueData: null, block: false }, () => this.getQueueData());
            }
        }, '/api/User/Dashboard/v1/resubmitDeadLetterMessages/'+ this.state.queueName, req);

    }

    deleteMessage() {
        let body = [...this.state.selectedMessages];

        this.setState({ block: true });
        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.success) {
                    handleNotification(data, <FormattedMessage id="QueueStatusDashboard.DeleteMessage" values={{ messages: data.count }} />, <FormattedMessage id="General.Success" />);
                    this.setState({ PeekQueueData: null, selectedMessages: [], block: false }, () => this.getQueueData());
                }
            }
        }, `/api/User/Dashboard/v1/${this.state.isDeadletter ? 'RemoveDeadLetterMessages' : 'RemoveMessages'}/${this.state.queueName}`, body);
    }

    handleMsgClick(key) {
        
        if (this.state.selectedMessages && this.state.selectedMessages.find(el => el == key) != undefined) {
            this.setState(prevState => ({ selectedMessages: [...prevState.selectedMessages.filter(el => el != key)] }))
        } else { 
            this.setState(prevState => ({ selectedMessages: [...prevState.selectedMessages, key] }))
        }
    }

    handleAllMsgSelection = (action) => {
        let messages = [];

        if (action === 'selectAll') {
            messages = this.state.PeekQueueData.queueMessage.reduce((acc, cur) => {
                if (cur.visible) {
                    acc.push(cur.messageId);
                }
                return acc;
            }, []);
        }

        this.setState({ selectedMessages: messages });        
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        }, () => {
            if (name === "hotelIdFilter" || name === "typeFilter"){
                this.setVisible();
            }
        });
    };

    handleChangeSelectType = (combo, evt) => {
        if (evt) {
            const { name } = evt;
            this.setState({ [name]: combo && combo.value });
        }        
    }

    setVisible = () => {
        const data = { ...this.state.PeekQueueData };

        if (this.state.hotelIdFilter || this.state.typeFilter) {
            data.queueMessage && data.queueMessage.forEach(item => {
                if ((this.state.hotelIdFilter ? item.hotelId === this.state.hotelIdFilter : true) && (this.state.typeFilter ? item.tag === this.state.typeFilter : true)) {
                    item.visible = true;
                }
                else {
                    item.visible = false;
                }
            })
        }
        else {
            if (this.state.hotelIdFilter === null && this.state.typeFilter === null) {
                data.queueMessage && data.queueMessage.map(item => item.visible = true);
            }
        }

        this.setState({ PeekQueueData: data, selectedMessages: [] });
    }

    render() {
        const viewOptions = [
            {
                value: 'all',
                label: "All Messages"
            },
            {
                value: 'msg',
                label: "With Messages"
            },
            {
                value: 'dlq',
                label: "With DLQ Messages"
            },
            {
                value: 'active',
                label: "With Active Messages"
            }
        ];

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <Card>
                    <CardBody>
                        <Row className="mb-1">
                            <Col className="col-3 small">
                                <CustomSelect
                                    name="status"
                                    small="true"
                                    icon={'fas fa-cog'}
                                    value={viewOptions.find(el => el.value === this.state.viewtype)}
                                    placeholder={"Search Type"}
                                    options={viewOptions}
                                    onChange={this.handleChangeSelect.bind(this, 'viewtype')}
                                    isClearable isSearchable
                                />
                            </Col>
                            <Col className="col-3 small">
                                <CustomSelect
                                    name="isInbound"
                                    small="true"
                                    value={getQueueTypesOptions().find(el => el.value === this.state.isInbound)}
                                    placeholder={"Queue Type"}
                                    options={getQueueTypesOptions()}
                                    onChange={this.handleChangeSelectType}
                                    isClearable
                                    isSearchable
                                />
                            </Col>
                            <Col className="text-right">
                                <Authorization
                                    perform="deadLetter:delete"
                                    yes={() => (
                                        <>
                                            <Button id="deleteDeadLetter" className="btn btn-host btn-sm mr-2" disabled={this.state.selectedMessages.length === 0} onClick={this.deleteMessage.bind(this)}>
                                                <i className="fas fa-trash"></i>
                                            </Button>
                                            <CustomToolTip placement="bottom" target="deleteDeadLetter" >
                                                Delete selected messages
                                            </CustomToolTip >
                                        </>
                                    )}
                                    no={() => <div></div>}
                                />     
                                <Button id="Tooltipsendquque" className="btn btn-host btn-sm mr-2" disabled={this.state.selectedMessages.length === 0 || this.state.isDeadletter === false} onClick={this.submitMessage.bind(this)}><i className="fas fa-share"></i></Button>
                                <CustomToolTip placement="bottom" target="Tooltipsendquque" >
                                    Send selected messages to Active Messages queue
                                </CustomToolTip >
                            <Button className="btn btn-sm btn-host" onClick={this.getQueueData.bind(this)}><i className="fas fa-sync"></i></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="h-100">
                            <Card className="border-0">
                                <Table size="sm" className="small">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th >Inbound Hotel Information Queues</th>
                                            <th>Active Messages</th>
                                            <th>DeadLetter Messages</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                                
                                    {this.state.QueueData && this.state.QueueData.queuestatus && this.state.QueueData.queuestatus.filter(el => el.pathInfo === null &&
                                        (
                                        (this.state.viewtype === 'msg' && (el.activeMessages > 0 || el.deadLetterMessages > 0)) ||
                                        (this.state.viewtype === 'dlq' && el.deadLetterMessages > 0) ||
                                        (this.state.viewtype === 'active' && el.activeMessages > 0 ) ||
                                        (this.state.viewtype === 'all')
                                        )
                                    ).map((item, key) =>

                                        <tr key={key}>
                                                                                   
                                            <th scope="row">{key}</th>
                                            <td>{item.pathInfo ? item.pathInfo : item.path }</td>
                                                        <td className="text-center">{item.activeMessages} <Button disabled={item.activeMessages < 1} className="btn-sm btn-host float-right" onClick={this.peekQueue.bind(this, item.path, false)}><i className="far fa-eye"></i></Button></td>
                                                        <td className="text-center">{item.deadLetterMessages} <Button disabled={item.deadLetterMessages < 1} className="btn-sm btn-host float-right" onClick={this.peekQueue.bind(this, item.path, true)}><i className="far fa-eye"></i></Button></td>
                                                </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                        <Table size="sm" className="small">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th >Outbound Hotel Reservation Queues</th>
                                                    <th>Active Messages</th>
                                                    <th>DeadLetter Messages</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                              
                                                {this.state.QueueData && this.state.QueueData.queuestatus && this.state.QueueData.queuestatus.filter(el => el.pathInfo !== null &&
                                                    (
                                                        (this.state.viewtype === 'msg' && (el.activeMessages > 0 || el.deadLetterMessages > 0)) ||
                                                        (this.state.viewtype === 'dlq' && el.deadLetterMessages > 0) ||
                                                        (this.state.viewtype === 'active' && el.activeMessages > 0) ||
                                                        (this.state.viewtype === 'all')
                                                    )
                                                ).map((item, key) =>

                                                    <tr key={key}>


                                                        <th scope="row">{key}</th>
                                                        <td>{item.pathInfo ? item.pathInfo : item.path}</td>
                                                        <td className="text-center">{item.activeMessages} <Button disabled={item.activeMessages<1} className="btn-sm btn-host float-right" onClick={this.peekQueue.bind(this, item.path, false)}><i className="far fa-eye"></i></Button></td>
                                                        <td className="text-center">{item.deadLetterMessages} <Button disabled={item.deadLetterMessages < 1} className="btn-sm btn-host float-right" onClick={this.peekQueue.bind(this, item.path, true)}><i className="far fa-eye"></i></Button></td>
                                                    </tr>
                                                )}
                            </tbody>
                        </Table>
                    </Card>
                        </Col>
                        <Col>
                            <Card className="small">
                                {this.state.PeekQueueData && this.state.PeekQueueData.queueMessage ?
                                    <Row className="p-3">
                                        <Col className="col-4">
                                            <CustomSelect
                                                name="hotelId"
                                                small="true"
                                                placeholder={"Hotel"}
                                                options={this.state.hotelOptions}
                                                onChange={this.handleChangeSelect.bind(this, 'hotelIdFilter')}
                                                value={this.state.hotelIdFilter ? this.state.hotelOptions.find(el => el.value === this.state.hotelIdFilter) : ''}
                                                isClearable isSearchable
                                            />
                                        </Col>
                                        <Col className="col-3 pl-2">
                                            <CustomSelect
                                                name="status"
                                                small="true" 
                                                placeholder={< FormattedMessage id="SystemEvents.Type" />}
                                                options={this.state.typeOptions}
                                                onChange={this.handleChangeSelect.bind(this, 'typeFilter')}
                                                value={this.state.typeFilter ? this.state.hotelOptions.find(el => el.value === this.state.typeFilter) : ''}
                                                isClearable isSearchable
                                            />
                                        </Col>

                                        <Col className="mb-2 mt-1 d-flex align-items-center justify-content-end">
                                            <div>
                                                {this.state.PeekQueueData && this.state.PeekQueueData.queueMessage && this.state.PeekQueueData.queueMessage.filter(el => el.visible).length === this.state.selectedMessages.length ?
                                                    <span onClick={() => this.handleAllMsgSelection('removeAll')} style={{ color: 'blue', cursor: 'pointer' }}>
                                                        <FormattedMessage id="QueueStatusDashboard.RemoveSelection" />
                                                    </span>
                                                    :
                                                    <span onClick={() => this.handleAllMsgSelection('selectAll')} style={{ color: 'blue', cursor: 'pointer' }}>
                                                        <FormattedMessage id="QueueStatusDashboard.SelectAll" />
                                                    </span>
                                                }
                                                <b> ({this.state.selectedMessages.length}) </b>
                                            </div>
                                        </Col>
                                    </Row>
                                : ''}

                                {this.state.PeekQueueData && this.state.PeekQueueData.queueMessage.map((item, key) =>
                                    <Col className="col-12" key={key} style={item.visible ? {} : { display: 'none' }}>
                                        <Row onClick={this.handleMsgClick.bind(this, item.messageId)} className={this.state.selectedMessages.find(el => el === item.messageId) != undefined ? 'bg-light' : ''}>
                                            <Col>    
                                                <span className="float-left ml-2"> {item.hotelId}</span>
                                            </Col>
                                                <Col className="col-2 pr-0">
                                                <span className="float-center"> <strong>{item.tag} </strong></span>
                                            </Col>
                                            <Col>
                                                <span className="float-right">{item.receiveDate}</span>
                                            </Col>
                                            <Col className="col-2">
                                                <Button id={'toggle' + key} className="btn btn-host btn-sm float-right m-1"><i className="fas fa-caret-down "></i></Button>
                                            </Col>
                                        </Row>

                                        <UncontrolledCollapse toggler={'#toggle' + key} >
                                            <Card>
                                                <CardBody>
                                                    <pre>
                                                        <code>
                                                            {item.body}
                                                        </code>
                                                    </pre>
                                                </CardBody>
                                            </Card>
                                        </UncontrolledCollapse>
                                    </Col>
                                )}
                            </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </BlockUi>
        );
    }
};