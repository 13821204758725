import React, { Component } from 'react';
import { CardBody, Card, CardHeader } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CustomTable } from '../Base/Common/CustomTable'

 class ChannelCommonData extends Component {

    state = {
        error: [],
        block: true
    };

     componentWillUnmount() {
         this._isMounted = false;
     }
     componentDidMount() {
         this._isMounted = true;
     }


    render() {


        const columns = [
            {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "ChannelList.Id" }),
                sort: true
            },
            {
                dataField: 'mapType',
                text: this.props.intl.formatMessage({ id: "ChannelList.mapType" }),
                sort: true,
                formatter: (cell, row) => <FormattedMessage id={row.mapType ? 'ChanneCommonDataType_' + row.mapType : ''} />
            }
            ,
            {
                dataField: 'code',
                text: this.props.intl.formatMessage({ id: "ChannelList.code" }),
                sort: true
            },
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "ChannelList.name" }),
                sort: true
            },
            {
                dataField: 'otaCode',
                text: this.props.intl.formatMessage({ id: "ChannelList.otacode" }),
                sort: true
            },
        ];

        return (
            <div>
                <Card className="border-0 py-1 bg-white shadow">
                    <CardHeader className="bg-white border-0"></CardHeader>
                    <CardBody>

                        <CustomTable
                            data={this.props.commonData ? this.props.commonData : []}
                            columns={columns}
                            //page={this.state.currentPage}
                            //sizePerPage={this.state.resultsPerPage}
                            //totalSize={this.state.ChannelData && this.state.ChannelData.length}
                            //onTableChange={this.handleTableChange}
                            shadow={false}
                            exportCSV={true}
                            search={true}
                        //selectRow={selectRow}
                        />
                      

                    </CardBody>
                </Card>


            </div>
        );
    }
}
export default injectIntl(ChannelCommonData)
