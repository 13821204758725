import React, { Component } from 'react';
import { Col, CustomInput, Row, Button, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../Base/Common/CustomSelect';

export class MajorBulkUpdateModalChooseRatesByChannels extends Component {

    render() {
        const { channelInstances, handleChangeChannel, rateCodeIds, handleRateChange, selectAllChannels, selectRatesByRates, selectedChannels, searchRates, roomCategoriesFiltered, rates, areAllRatesSelected, labels, hideLabels } = this.props;
        
        return (
            <div>
                <Row className="mb-2">
                    <Col className="col-10">
                        <CustomSelect options={channelInstances} isMulti={true} value={selectedChannels} onChange={handleChangeChannel} placeholder={<FormattedMessage id="MajorBulkUpdateModal.ChooseChannel" />}/>
                    </Col>
                    <Col className="col-2 text-right pt-1">
                        <Button className="btn btn-sm btn-host" onClick={selectAllChannels}>
                            {channelInstances.length === selectedChannels.length ? <FormattedMessage id="MajorBulkUpdateModal.RemoveAllChannels" /> : <FormattedMessage id="MajorBulkUpdateModal.SelectAllChannels" />}
                        </Button>
                    </Col>
                </Row>

                {roomCategoriesFiltered?.length > 0 ?
                    <Row className="align-items-center mb-2">
                        <Col className="col-3 d-flex align-items-center">
                            <Input
                                type="text"
                                placeholder={this.props.intl.formatMessage({ id: "MajorBulkUpdateModal.SearchRate" })}
                                onChange={(e) => searchRates(e, true)}
                                style={{ paddingRight: '35px' }}
                            />
                            <i className="fas fa-search text-muted" style={{ position: 'absolute', right: '28px' }} />
                        </Col>
                        {global.operationMode !== 'PmsFull' && !hideLabels ?
                            <Col className="col-3 align-items-center">
                                <CustomSelect
                                    icon={'fas fa-tag'}
                                    isMulti
                                    isClearable={false}
                                    isSearchable
                                    placeholder={<FormattedMessage id="Inventory.SelectRateLabel" />}
                                    options={labels}
                                    onChange={evt => searchRates(evt, false, true) }
                                />
                            </Col>
                            : ''}
                        <Col className="text-right">
                            <Button className="btn btn-sm btn-host" onClick={() => selectRatesByRates(rates, areAllRatesSelected)}>
                                {areAllRatesSelected ? <FormattedMessage id="MajorBulkUpdateModal.RemoveAllRates" /> : <FormattedMessage id="MajorBulkUpdateModal.SelectAllRates" />}
                            </Button>
                        </Col>
                    </Row>
                : ''}

                {roomCategoriesFiltered?.map((rc, key) => {
                    const ratesByCategory = rc.rates.filter(r => selectedChannels.find(c => c.rateCodeChannels.find(rcc => rcc.rateCodeId === r.value) !== undefined) !== undefined);
                    const areAllSelected = ratesByCategory.filter(rate => rate.hasOwnProperty('hidden') ? rate.hidden === false ? true : false : true).every(rate => rateCodeIds.some(el => el === rate.id));

                    return !rc.hidden && <Row key={key} className="mb-1">
                        <Col>
                            <Row className="mb-1 px-2 overbglight">
                                <Col>
                                    <b> {rc.label} </b>
                                    {
                                        ratesByCategory.every(r => r.hidden) ?
                                            <div />
                                            :
                                            <Button className="btn btn-sm btn-host float-right" onClick={() => selectRatesByRates(ratesByCategory, areAllSelected)}>
                                                {
                                                    areAllSelected ? <FormattedMessage id="MajorBulkUpdateModal.RemoveAll" /> : <FormattedMessage id="MajorBulkUpdateModal.SelectAll" />
                                                }
                                            </Button>
                                    }
                                </Col>
                            </Row>
                            {
                                ratesByCategory.map((r, key2) =>
                                    <Row key={key2} className="mb-1 px-2 overbglight" style={r.hidden ? { display: 'none' } : {}}>
                                        <Col className="col-3">
                                            <CustomInput
                                                type="checkbox"
                                                id={`${r.value}`}
                                                name={`${r.value}`}
                                                onChange={(e) => handleRateChange(e, true)}
                                                checked={areAllSelected || rateCodeIds.some(rateCodeId => rateCodeId === r.value)}
                                            >
                                                {r.label}
                                            </CustomInput>
                                        </Col>
                                    </Row>
                                )
                            }
                        </Col>
                    </Row>
                }) }
            </div>    
        );
    }
}