import React, { Component } from 'react';
import { Row, Col, Label, Card, CardBody, Form, Input, Button } from 'reactstrap';
import { getPostTypeList, getPriceTypeList } from '../../../Base/Common/ReferenceDataFunctions';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../../Base/Common/CustomSelect';

class PackageComponentDetailDesk extends Component {
    render() {
        return (
            <Card className="shadow border-0 h-100">
                <CardBody>
                    <Form onSubmit={this.props.savePackageComponent}>
                        {global.operationMode === 'PmsLimited' || global.operationMode === 'None' ?
                            <Row>
                                <Col className="text-right">
                                    <Button type="submit" className="btn-sm btn-host float-right">
                                        <i className="fas fa-save"></i>
                                    </Button>
                                </Col>
                            </Row>
                        : ''}

                        <Row className="pt-2">
                            <Col className="col-2">
                                <Label> <FormattedMessage id="PackageSetup.ChargeCode" /> </Label>
                            </Col>
                            <Col className="col-4">
                                <Input
                                    type="text"
                                    name="chargeCode"
                                    onChange={(e) => this.props.handleChange(e)}
                                    value={this.props.packageComponent.chargeCode ? this.props.packageComponent.chargeCode : ''}		
                                    required
                                />
                            </Col>

                            <Col className="col-2">
                                <Label><FormattedMessage id="PackageSetup.ChargeDescription" /></Label>
                            </Col>
                            <Col className="col-4">
                                <Input
                                    type="text"
                                    name="chargeDescription"
                                    onChange={(e) => this.props.handleChange(e)}
                                    value={this.props.packageComponent.chargeDescription ? this.props.packageComponent.chargeDescription : ''}
                                    required
                                />
                            </Col>
                        </Row>

                        <Row className="pt-2">
                            <Col className="col-2">
                                <Label> <FormattedMessage id="PackageSetup.ComponentType" /> </Label>
                            </Col>
                            <Col className="col-4">
                                <CustomSelect
                                    required
                                    name="componentType"
                                    options={getPriceTypeList()}
                                    value={getPriceTypeList().filter(el => el.value == this.props.packageComponent.componentType)}
                                    onChange={this.props.handleChangeSelect.bind(this, 'componentType')}
                                    placeholder=""
                                />
                            </Col>

                            <Col className="col-2">
                                <Label><FormattedMessage id="PackageSetup.ComponentPostType" /></Label>
                            </Col>
                            <Col className="col-4">
                                <CustomSelect
                                    required
                                    name="componentType"
                                    options={getPostTypeList()}
                                    value={getPostTypeList().filter(el => el.value == this.props.packageComponent.componentPostType)}
                                    onChange={this.props.handleChangeSelect.bind(this, 'componentPostType')}
                                    placeholder=""
                                />
                            </Col>
                        </Row>

                        <Row className="pt-2">
                            <Col className="col-2">
                                <Label> <FormattedMessage id="PackageSetup.Quantity" /> </Label>
                            </Col>
                            <Col className="col-4">
                                <Input
                                    type="number"
                                    name="quantity"
                                    onChange={(e) => this.props.handleChange(e)}
                                    value={this.props.packageComponent.quantity !== null ? this.props.packageComponent.quantity : ''}
                                    min="0"
                                    required
                                />
                            </Col>

                            <Col className="col-2">
                                <Label><FormattedMessage id="PackageSetup.ComponentPrice" /></Label>
                            </Col>
                            <Col className="col-4">
                                <Input
                                    type="number"
                                    name="componentPrice"
                                    onChange={(e) => this.props.handleChange(e)}
                                    value={this.props.packageComponent.componentPrice !== null ? this.props.packageComponent.componentPrice : ''}
                                    step=".01"
                                    min="0"
                                    required
                                />
                            </Col>
                        </Row>

                        <Row className="pt-2">
                            <Col className="col-2">
                                <Label><FormattedMessage id="PackageSetup.ComponentPriceChildrenGroup2" /></Label>
                            </Col>
                            <Col className="col-4">
                                <Input
                                    type="number"
                                    name="componentPriceChildrenGroup2"
                                    onChange={(e) => this.props.handleChange(e)}
                                    value={this.props.packageComponent.componentPriceChildrenGroup2 !== null ? this.props.packageComponent.componentPriceChildrenGroup2 : ''}
                                    step=".01"
                                    min="0"
                                />
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        )
    }
}

export default injectIntl(PackageComponentDetailDesk)
