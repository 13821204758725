import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { getAPI } from "../../Base/API";
import { StyledCard, SelectHotel } from "../../Base/Common/CommonUIComponents";
import { getisLocalPms, getOperationModes, getModules } from "../../Base/Common/ReferenceDataFunctions";
import { CustomTable } from '../../Base/Common/CustomTable';
import CustomSelect from '../../Base/Common/CustomSelect';
import HotelLicenseDetail from "./HotelLicenseDetail";


class HotelLicenses extends Component {

    constructor(props) {
        super(props);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.getHotelLicenses = this.getHotelLicenses.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.updateHotelLicense = this.updateHotelLicense.bind(this);
        this.state = {
            block: true,
            hotelLicenses: [],
            licenses: [],
            hotelId: null,
            operationMode: null,
            licenseId: null,
            isLocalPms: null,
            modules: [],
            sendCMRoomRate: null,
            modal: false,
            modalData: null,
            hotelChains: [],
            hotelChain: null
        }
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const licenses = data.licenses.map(item => {
                    const license = {
                        value: item.id,
                        label: item.name,
                        numChannels: item.numChannels,
                        numRooms: item.numRooms,
                        licenseLevel: item.licenseLevel
                    };
                    return license;
                })
                this.setState({ licenses: licenses }, () => this.getHotelChains());
            }
        }, '/api/hotel/License/v1/license');
    }

    getHotelChains = () => {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                const hotelChains = data?.map(item => ({ value: item.id, label: item.name }));
                this.setState({ hotelChains, block: false }, () => this.getHotelLicenses('/api/hotel/v1/hotelLicense'));
            }
        }, '/api/hotel/v1/HotelChains');
    }

    handleFieldChange = (name, combo) => {
        const value = combo ? combo.value : null;
        this.setState(({ [name]: value }));
    }

    handleFieldArrayChange = (name, combo) => {
        this.setState(({ [name]: combo ? combo.map(c => c.value) : [] }));
    }

    doSearch() {
        const hotelId = this.state.hotelId ? this.state.hotelId : '';
        const operationMode = this.state.operationMode ? this.state.operationMode : '';
        const licenseId = this.state.licenseId ? this.state.licenseId : '';
        const isLocalPms = this.state.isLocalPms != null ? this.state.isLocalPms : '';
        const pmsType = this.state.pmsType != null ? this.state.pmsType : '';
        const rmsType = this.state.rmsType != null ? this.state.rmsType : '';
        const modules = this.state.modules.length > 0 ? this.state.modules.reduce((acc, curr) => `${acc}&modules=${curr}`, ``) : '';
        const sendCMRoomRate = this.state.sendCMRoomRate !== null ? `&sendCMRoomRate=${this.state.sendCMRoomRate}` : '';
        const hotelChain = this.state.hotelChain ? `&hotelChain=${this.state.hotelChain}` : '';

        this.getHotelLicenses(`/api/hotel/v1/hotelLicense?hotelId=${hotelId}&operationMode=${operationMode}&licenseId=${licenseId}&isLocalPms=${isLocalPms}&pmsType=${pmsType}&rmsType=${rmsType}${modules}${sendCMRoomRate}${hotelChain}`);
    }

    getHotelLicenses(url) {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ error: errorMessage, block: false, hotelLicenses: data.hotelLicenses });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, url);
    }

    updateHotelLicense(newHotelLicense) {
        const idx = this.state.hotelLicenses && this.state.hotelLicenses.indexOf(this.state.hotelLicenses.find(hotelLicense => hotelLicense.id == newHotelLicense.id));
        const hotelLicenses = this.state.hotelLicenses;
        hotelLicenses[idx] = newHotelLicense;
        this.setState({ hotelLicenses });
    }

    toggleModal() {
        this.setState(prevstate => ({
            modal: !prevstate.modal
        }));
    }

    render() {
        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row) => {
                this.setState({ modal: true, modalData: row })
            }
        };

        const pmsTypes = [
            {
                value: 'Host',
                label: this.props.intl.formatMessage({ id: "HotelLicenses.Host" })
            },
            {
                value: 'None',
                label: this.props.intl.formatMessage({ id: "HotelLicenses.None" })
            },
            {
                value: 'BookingSpaceBeds',
                label: this.props.intl.formatMessage({ id: "HotelLicenses.BookingSpaceBeds" })
            },
            {
                value: 'NewHotel',
                label: "NewHotel"
            }
        ];

        const rmsTypes = [
            {
                value: 'None',
                label: this.props.intl.formatMessage({ id: "HotelLicenses.None" })
            },
            {
                value: 'Xlr8',
                label: this.props.intl.formatMessage({ id: "HotelLicenses.Xlr8" })
            },
            
            {
                value: 'Climber',
                label: this.props.intl.formatMessage({ id: "HotelLicenses.Climber" })
            }
        ];

        const reservationDeliveries = [
            {
                value: 'Push',
                label: this.props.intl.formatMessage({ id: "HotelLicenses.Push" })
            },
            {
                value: 'Pull',
                label: this.props.intl.formatMessage({ id: "HotelLicenses.Pull" })
            },
            {
                value: 'None',
                label: this.props.intl.formatMessage({ id: "HotelLicenses.None" })
            }
        ];

        function getPmsType(pmsType) {
            const pmsTypeItem = pmsTypes.find(p => p.value === pmsType);
            return pmsTypeItem ? pmsTypeItem.label : pmsType
        }

        function getReservationDelivery(reservationDelivery) {
            const reservationDeliveryItem = reservationDeliveries.find(p => p.value === reservationDelivery);
            return reservationDeliveryItem ? reservationDeliveryItem.label : reservationDelivery
        }

        const columns = [
            {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.Id" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : '-'
            },
            {
                dataField: 'hotel.name2',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.HotelId" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : '-'
            },
            {
                dataField: 'operationMode',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.OperationMode" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : '-',
                formatter: (cell) => <div> {getLabel(cell, getOperationModes())} </div>
            },
            {
                dataField: 'isLocalPms',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.IsLocalPms" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : '-',
                formatter: (cell) => <div> {getLabel(cell, getisLocalPms())} </div>
            },
            {
                dataField: 'pmsType',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.PmsType" }),
                sort: true,
                formatter: cell => getPmsType(cell),
                csvFormatter: (cell) => getPmsType(cell)
            },
            {
                dataField: 'license.name',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.License" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : '-'
            },
            {
                dataField: 'license.numChannels',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.NumChannels" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : '-'
            },
            {
                dataField: 'license.numRooms',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.NumRooms" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : '-'
            },
            {
                dataField: 'licensedRooms',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.LicensedRooms" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : '-'
            },
            {
                dataField: 'reservationDelivery',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.ReservationDelivery" }),
                sort: true,
                formatter: cell => getReservationDelivery(cell),
                csvFormatter: (cell) => getReservationDelivery(cell)
            },
            {
                dataField: 'sendCMRoomRate',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.sendCMRoomRate" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : '-',
                formatter: (cell) => <div> {getLabel(cell, getisLocalPms())} </div>
            },
            {
                dataField: 'modules',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.Modules" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : '-',
                formatter: (cell) => cell ?
                    cell.map((m, k) => {
                        const module = getModules(this.props.intl).find(lm => lm.value === m);
                        return module ? module.label : ''
                    }).join(', ')
                    : ''
            }
        ];   

        function getLabel(cell, array) {
            const item = array.find(item => item.value == cell);
            return item ? item.label : '';
        }

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-hotel'} title={<FormattedMessage id="HotelLicenses.Title" />}>
                {
                    this.state.modal ?
                        <HotelLicenseDetail
                            modal={this.state.modal}
                            modalData={this.state.modalData}
                            toggleModal={this.toggleModal}
                            title={<FormattedMessage id="HotelLicenseDetail.Title" />}
                            updateHotelLicense={this.updateHotelLicense}
                            operationModes={getOperationModes()}
                            isLocalPms={getisLocalPms()}
                            licenses={this.state.licenses}
                            pmsTypes={pmsTypes}
                            rmsTypes={rmsTypes}
                            reservationDeliveries={reservationDeliveries}
                            hotelChains={this.state.hotelChains}
                        />
                        :
                        <div />
                }

                <Row className="align-items-center">
                    <Col className="pr-0 col-3">
                        <SelectHotel name={'hotelId'} icon={'fas fa-hotel'} onChangeFunc={this.handleFieldChange} placeholder={<FormattedMessage id="HotelLicenses.Hotel" />} value={this.state.hotelId} />
                    </Col>
                    <Col className="pr-0 col-3">
                        <CustomSelect name={'operationMode'} options={getOperationModes()} icon={'fas fa-wrench'} onChange={this.handleFieldChange.bind(this, 'operationMode')} placeholder={<FormattedMessage id="HotelLicenses.OperationMode" />} isClearable />
                    </Col>
                    <Col className="pr-0 col-3">
                        <CustomSelect name={'licenseId'} options={this.state.licenses} icon={'fas fa-file-alt'} onChange={this.handleFieldChange.bind(this, 'licenseId')} placeholder={<FormattedMessage id="HotelLicenses.License" />} isClearable/>
                    </Col>
                    <Col className="pr-0 col-2">
                        <CustomSelect name={'isLocalPms'} options={getisLocalPms()} icon={'fas fa-check'} onChange={this.handleFieldChange.bind(this, 'isLocalPms')} placeholder={<FormattedMessage id="HotelLicenses.IsLocalPms" />} isClearable />
                    </Col>
                    <Col className="col-1">
                        <Button className="float-right  btn-sm btn-host btn" onClick={this.doSearch}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                    <Col className="pr-0 col-3 mt-2">
                        <CustomSelect name={'pmsType'} options={pmsTypes} onChange={this.handleFieldChange.bind(this, 'pmsType')} placeholder={<FormattedMessage id="HotelLicenses.PmsType" />} isClearable />
                    </Col>
                    <Col className="pr-0 col-3 mt-2">
                        <CustomSelect name={'sendCMRoomRate'} options={getisLocalPms()} onChange={this.handleFieldChange.bind(this, 'sendCMRoomRate')} placeholder={<FormattedMessage id="HotelLicenses.sendCMRoomRate" />} isClearable />
                    </Col>
                    <Col className="pr-0 col-3 mt-2">
                        <CustomSelect name={'modules'} options={getModules(this.props.intl)} isMulti={true} onChange={this.handleFieldArrayChange.bind(this, 'modules')} placeholder={<FormattedMessage id="HotelLicenses.Modules" />} isClearable />
                    </Col>
                    <Col className="pr-0 col-2 mt-2">
                        <CustomSelect name={'hotelChain'} options={this.state.hotelChains} onChange={this.handleFieldChange.bind(this, 'hotelChain')} placeholder={<FormattedMessage id="HotelLicenses.hotelChain" />} isClearable />
                    </Col>
                    <Col className="pr-0 col-3 mt-2">
                        <CustomSelect name={'rmsType'} options={rmsTypes} onChange={this.handleFieldChange.bind(this, 'rmsType')} placeholder={<FormattedMessage id="HotelLicenses.RmsType" />} isClearable />
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="h-100">
                        <CustomTable
                            data={this.state.hotelLicenses ? this.state.hotelLicenses : []}
                            columns={columns}
                            shadow={false}
                            exportCSV={true}
                            showTotal={true}
                            selectRow={selectRow}
                            search={false}
                            remote={false}
                            keyField={'hotel.hotelId'}         
                            hideSizePerPage={false}
                        />
                    </Col>
                </Row>
            </StyledCard>
        );
    }

};
export default injectIntl(HotelLicenses);