import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import Progress from 'reactstrap/lib/Progress';
import BulkPricesCell from './BulkPricesCell';
import CustomToolTip from '../../Utils/CustomToolTip';

export class AvailabilityCalendarMonth extends Component {

    constructor(props) {
        super(props);
        this.toolTip = React.createRef();
        this.state = {
            currAvailabilities: this.props.currAvailabilities,
            ignorePercentage: this.props.ignorePercentage
        };
    }

    shouldComponentUpdate(nextProps){
        let month;
        let day = nextProps.calendarRows?.find((week) => week.find((day) => day?.moment))?.find((day) => day?.moment);
        if(day && day.moment){
            month = day.moment.format('MMMM');
        }

        const res = (nextProps.alwaysUpdate || nextProps.currAvailabilities !== this.props.currAvailabilities || (month && nextProps.focusedMonth === month));

        if(this.state.currAvailabilities && nextProps.currAvailabilities && this.state.currAvailabilities !== nextProps.currAvailabilities){
            this.setState({ currAvailabilities: nextProps.currAvailabilities })
        }

        return res ? true : false;
    }

    render() {
        const { currAvailabilities, ignorePercentage } = this.state;
        const { startDate, blockedWeekdays, selectedMapping, findRule, calendarRows, renderCell, selectDay, isHost, getPercentage, handleSelectDay, cellHeight,
            fromAvailability, getFreeRooms, isNavigable, isFirst, changeMonth } = this.props;
        const weekDaysValue = [
            {
                value: "Sun",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSun" />,
                key: 0
            },
            {
                value: "Mon",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysMon" />,
                key: 1
            },
            {
                value: "Tue",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysTue" />,
                key: 2
            },
            {
                value: "Wed",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysWed" />,
                key: 3
            },
            {
                value: "Thu",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysThu" />,
                key: 4
            },
            {
                value: "Fri",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysFri" />,
                key: 5
            },
            {
                value: "Sat",
                label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSat" />,
                key: 6
            }
        ];

        return (
            <div>
                <Row>
                    {ignorePercentage ?
                        <Col className="col-2">
                            {isNavigable && isFirst && currAvailabilities.availability && currAvailabilities.availability.length > 0
                                && moment(currAvailabilities.availability[0].hotelDate).locale(this.props.intl.locale).format('MMMM YYYY') !== moment(startDate).locale(this.props.intl.locale).format('MMMM YYYY') ?
                                <div className='cursor-pointer h-100 d-flex align-items-center' onClick={e => changeMonth(-1)} >
                                    <i className="fas fa-chevron-left"></i>
                                </div>
                                : ''}

                        </Col>
                        :
                        <Col className="col-2">
                            {isNavigable && isFirst ?
                                <div className='cursor-pointer h-100 d-flex align-items-center' onClick={e => changeMonth(-1)} >
                                    <i className="fas fa-chevron-left"></i>
                                </div>
                                : ''}

                        </Col>
                    }
                    <Col className="text-center">
                        <h6>{moment(startDate).locale(this.props.intl.locale).format('MMMM YYYY')}</h6>
                    </Col>
                    {ignorePercentage ?
                        <Col className="col-2">
                            {isNavigable && !isFirst && currAvailabilities.availability && currAvailabilities.availability.length > 0
                                && moment(currAvailabilities.availability[currAvailabilities.availability.length - 1].hotelDate).isAfter(moment(startDate), "month") ?
                                <div className='cursor-pointer h-100 d-flex align-items-center justify-content-end' onClick={e => changeMonth(1)}>
                                    <i className="fas fa-chevron-right"></i>
                                </div>
                                : ''}
                        </Col>
                        :
                        <Col className="col-2">
                            {isNavigable && !isFirst ?
                                <div className='cursor-pointer h-100 d-flex align-items-center justify-content-end' onClick={e => changeMonth(1)}>
                                    <i className="fas fa-chevron-right"></i>
                                </div>
                                : ''}
                        </Col>
                    }
                </Row>
                <Row className="pl-4 align-items-center">
                    {weekDaysValue.map((day, key) =>
                        <Col key={key} style={{ color: '#bec1d0', fontSize: '0.8em' }} className="week-day py-0 px-1 text-center">
                            {day.label}
                        </Col>
                    )}
                </Row>
                {calendarRows && calendarRows.filter(w => w.length > 0).map((week, i) =>
                    <Row className="ml-2" key={i} style={{
                        overflow: 'hidden', borderTop: i === 0 && '2px solid rgb(201, 222, 254)',
                        borderBottom: '2px solid rgb(201, 222, 254)'
                    }}>
                        {week.map((d, key) => {

                            if (ignorePercentage != true) {
                                var blockedWeekday = blockedWeekdays.filter(b => b === weekDaysValue.find(d => d.key === key).value).length === 0; 
                            }
                            else {
                                var blockedWeekday = false;
                            }


                            var percentage = null;
                            var color = {};

                            const dayAvailability = currAvailabilities.availability && d.moment && !blockedWeekday &&
                                currAvailabilities.availability.find(av => (moment(av.hotelDate).format('DD-MM-YYYY') === d.moment.format('DD-MM-YYYY')));

                            if (ignorePercentage != true) {
                                percentage = getPercentage(dayAvailability, isHost);

                                if (0 <= percentage && percentage < 33) {
                                    color.color = 'success';
                                    color.hex = '#5cb85c';
                                }
                                else if (33 <= percentage && percentage < 66) {
                                    color.color = 'warning';
                                    color.hex = '#f0ad4e';
                                }
                                else if (66 <= percentage && percentage <= 100) {
                                    color.color = 'danger';
                                    color.hex = '#d9534f';
                                }
                            } 

                            return (
                                <Col key={`CalendarDay${i}week${key}`} onClick={() => handleSelectDay(d, dayAvailability, key)} className={`calendar-border px-1 ${ignorePercentage ? '' : 'cursor-pointer'} overflow-hidden`}
                                    style={{ borderLeft: '1px solid #c9defe', borderRight: '1px solid #c9defe', height: cellHeight,
                                        backgroundColor: selectDay !== d?.moment?.format('YYYY/MM/DD') ? key === 0 || key === 6 ? '#165C7D11' : '#FAFCFF' : '#cbe0ff'
                                    }}>
                                    <div className="w-100 h-100 d-flex align-items-center">
                                        <div className='w-100 h-100'>
                                            {dayAvailability && ignorePercentage != true ?
                                                <div className="p-1" style={{ marginTop: '0.1rem' }}>
                                                    {isHost ?
                                                        <div>
                                                            <Progress id={`availabilityPercentage${dayAvailability.id}`} color={color.color} value={percentage + 1} style={{ maxHeight: '5px' }} />
                                                            <CustomToolTip placement="right" target={`availabilityPercentage${dayAvailability.id}`}>
                                                                <i className={`far fa-square mr-2`} style={{ backgroundColor: color.hex }} />
                                                                {percentage}%
                                                            </CustomToolTip>
                                                        </div>
                                                    :''}
                                                </div>
                                            :''}
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ fontSize: '0.8em', color: dayAvailability ? '#313131' : '#bec1d0' }}>
                                                    {d.day}
                                                </div>
                                                {dayAvailability && ignorePercentage == true ?
                                                    <div id={`SpanCalendarDay${moment(dayAvailability.hotelDate).format("DD-MM")}`}>
                                                        {(dayAvailability.cmAvailability !== dayAvailability.pmsAvailability ) ?
                                                            <>
                                                                <div className="mr-1 d-flex float-right justify-content-end">
                                                                    <span style={{ fontSize: '13px', color: 'rgb(220, 53, 69)' }} >
                                                                        <b>{dayAvailability.cmAvailability} | {dayAvailability.pmsAvailability}</b>
                                                                    </span>
                                                                </div>
                                                                <CustomToolTip placement="bottom" className="align-items-left" target={`SpanCalendarDay${moment(dayAvailability.hotelDate).format("DD-MM")}`}>
                                                                    <Row>
                                                                        <Col>
                                                                            <FormattedMessage id="CalendarPms.AvailabilityDifferences" />
                                                                        </Col>
                                                                    </Row>
                                                                </CustomToolTip>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="d-flex float-right justify-content-end">
                                                                    <span style={{ fontSize: '13px', color: '#17a2b8' }} >
                                                                        <b>{dayAvailability.pmsAvailability}</b>
                                                                    </span>
                                                                </div>
                                                                <CustomToolTip placement="bottom" className="align-items-left" target={`SpanCalendarDay${moment(dayAvailability.hotelDate).format("DD-MM")}`}>
                                                                    <Row>
                                                                        <Col>
                                                                            <FormattedMessage id="CalendarPms.OkCompareAvailability" />
                                                                        </Col>
                                                                    </Row>
                                                                </CustomToolTip>
                                                            </>
                                                        }
                                                    </div>
                                                    : ''}
                                                {selectedMapping && dayAvailability && selectedMapping.priceMode !== -1 ?
                                                    <BulkPricesCell
                                                        d={d}
                                                        findRule={findRule}
                                                        ruleKey={key}
                                                        mapping={selectedMapping}
                                                        avail={dayAvailability}
                                                        getPercentage={getPercentage}
                                                    />
                                                    : ''}
                                                {(dayAvailability && ignorePercentage != true) && (fromAvailability ? dayAvailability.freeRoomsChanged ? true : false : true) ?
                                                    <div>
                                                        <span style={{ fontSize: '0.8em' }} ref={this.toolTip} id={`FreeRooms${dayAvailability.id}`} className={`mb-0 px-1 ${getFreeRooms(dayAvailability) < 0 ? `text-danger` : `text-info`}`}>
                                                            <b>{getFreeRooms(dayAvailability)}</b>
                                                        </span>
                                                        <CustomToolTip placement="right" targetRef={this.toolTip} target={`FreeRooms${dayAvailability.id}`}>
                                                            <FormattedMessage id="BulkUpdate.Availability" />
                                                        </CustomToolTip>
                                                    </div>
                                                : ''} 
                                            </div>
                                            {
                                                dayAvailability && renderCell ?
                                                    renderCell(dayAvailability, key/*, color, percentage*/)
                                                    :
                                                    ''
                                            }
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                        )}
                    </Row>
                )}
            </div>
        );
    }
}

export default injectIntl(AvailabilityCalendarMonth);