import React, { Component } from 'react';
import { Col, Row, Card } from 'reactstrap';
import moment from 'moment';

export class AirbnbChatWindow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: true,
            modal: false,
            replyOptions: null,
            message: null,
            radioOption: false,
            textArea: true,
            threadID: null
        }
        
    }
    componentDidMount() {
        this.scrollToLastMessage();
    }

    componentWillMount() {
        this.setState({ noReply: false })
    }   

    componentDidUpdate() {
        this.scrollToLastMessage()
    }

    scrollToLastMessage = () => {
        const chatWindow = document.getElementById("chatWindow");

        if (chatWindow) {
            chatWindow.scrollTo(0, chatWindow.scrollHeight);
        }
    }

    render() {
        var viewThread = [];
        var owner = [];

        if (this.props.threadDetails && this.props.threadDetails.thread) {
            viewThread = this.props.threadDetails.thread;
            if (viewThread.attachment && viewThread['attachment'] !== undefined) {
                owner = viewThread.attachment.roles.filter(el => el && el.role === 'owner')
                if (owner.length > 0) {
                    owner = ''+owner[0].user_ids;
                }
            }
        }

        return (
            <div>
                {global.isMobile ?
                    <div className='pt-2 px-1'>
                        <span className="fas fa-arrow-left pointer" onClick={() => this.props.changethreadDetails()}></span>
                    </div>
                    : ''}
                <div id="chatWindow" className="small scrollableDiv pb-3 px-3" style={{ overflowY: 'scroll', overflowX: 'hidden', maxHeight: '49vh', backgroundColor: 'whitesmoke' }}>
                    {viewThread.messages && viewThread.messages.map((item, key) =>
                        <div key={key}>
                            <Row >
                                <Col className="col-12 text-center text-secondary mt-3 mb-2">{moment(item.created_at).fromNow() + " , " + moment(item.created_at).format("YYYY-MM-DD HH:mm")}</Col>
                            </Row>

                            <Row >
                                <Col className="col-12">
                                    <Card body style={{ width: 'fit-content', borderRadius: '20px' }} className={(item.user_id && owner.indexOf(item.user_id) >= 0 ? "float-right" : "float-left bg-soft-airbnb") + " py-2 px-3 shadow"}>
                                        <div className="mailboxballon"> {item.message}
                                            {item.attachment_images && item.attachment_images.length > 0 && item.attachment_images.map((image) =>
                                                <img key={key} src={image.url} style={{ 'maxHeight': '250px' }} alt="Preview" />
                                            )}
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    )}

                </div>
            </div>
        )
    }
}