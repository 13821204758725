import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Form, Input, Row, UncontrolledDropdown } from 'reactstrap';
import CustomSelect from '../../../Base/Common/CustomSelect';

export default class TemplatePreview extends Component {

    constructor (props) {
        super(props);
        this.state = {
            editTemplate: false,
            templateName: '',
            eventType: ''
        };
    }

    componentDidMount(){
        this.setState({ templateName: this.props.templateName, eventType: this.props.eventType });
    }

    editTemplate = () => {
        const { id, history } = this.props;
        const { editTemplate } = this.state;
        if(!editTemplate) history.push({ pathname: "/EmailCreation/" + id });
    }

    editTemplateName = () => {
        this.setState(prev => ({ editTemplate: !prev.editTemplate }));
    }

    handleNameChange = (e) => {
        this.setState({ templateName: e?.target?.value });
    }

    handleSelect = (inputValue, name) => {
        if (inputValue && this.state[name] !== inputValue.value) {
            this.setState({ [name]: inputValue.value });
        };
    }
    

    render() {
        const { id, languages, openDeleteTemplateModal, toggleModal, saveTemplateName, intl, eventTypeList } = this.props;
        const { templateName, editTemplate, eventType } = this.state;

        return (
            <Card className="border-0 p-3 h-100 shadow d-flex justify-content-between">
                <Form onSubmit={(e) => saveTemplateName(e, id, templateName, this.editTemplateName, eventType)}>
                    <Row style={{ display:'flex', alignItems:'center', justifyContent:'center' }}>
                        <Col style={{ overflow: 'hidden', height: '100%', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={this.editTemplate}>
                            {editTemplate ?
                                <Input
                                    type='text'
                                    value={templateName}
                                    autoFocus
                                    required={true}
                                    onChange={this.handleNameChange}
                                />
                            :
                                <h5 title={templateName} style={{ width: '100%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} className="m-0">{templateName}</h5>
                            }
                        </Col>
                        <Col className="col-2">
                            {editTemplate ? 
                                <CustomSelect
                                    required={true}
                                    value={eventType ? eventTypeList.find(el => el.value === eventType) : ''}
                                    options={eventTypeList}
                                    onChange={e => this.handleSelect(e, 'eventType')}
                                />
                            :
                                <div>
                                    {eventType ? eventTypeList.find(el => el.value === eventType)?.label : ''}
                                </div>
                            }
                        </Col>
                        <Col className={`text-right col-2 pl-0`}>
                            {languages?.map((lang, k) =>
                                lang ? <span key={k} className={`flag-icon flag-icon-${lang} px-2 mr-2`}/> : ''
                            )}
                        </Col>
                        <Col className={`text-right col-2 pl-0`}>
                            <Button style={{ visibility: editTemplate ? 'unset' : 'hidden' }} title={intl.formatMessage({ id: "Stripo.save" })} className="btn btn-sm btn-host" type="submit">
                                <i className="fas fa-save"/>
                            </Button>
                            {!editTemplate ?
                                <>
                                    <Button title={intl.formatMessage({ id: "Stripo.edit" })} className="btn btn-sm btn-host mr-2" onClick={this.editTemplateName} type="button">
                                        <i className="fas fa-edit"/>
                                    </Button>
                                    <Button title={intl.formatMessage({ id: "Stripo.copy" })} className="btn btn-sm btn-host mr-2" onClick={e => toggleModal(e, id, false)} type="button">
                                        <i className="fas fa-copy"/>
                                    </Button>
                                    <Button title={intl.formatMessage({ id: "Stripo.deleteEmail" })} className="btn btn-sm btn-host" onClick={() => openDeleteTemplateModal(id)} type="button">
                                        <i className="far fa-trash-alt"/>
                                    </Button>
                                </>
                            :''}
                        </Col>
                    </Row>
                </Form>
            </Card>
        )
    }
}