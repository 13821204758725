import React, { Component } from 'react';



export class NotificationAlert extends Component {
   
    render() {

        var cls = 'alert';
        if (this.props.notification && this.props.notification.type === 'Success')
            cls += " alert-success";
        else
            cls +=" alert-danger";
    
        return (
            <div className={cls} style={{ display: this.props.notification.message ? 'block' : 'none' }} >
                 {this.props.notification ? this.props.notification.message : ''}
            </div>
           );
    }
}