import React, { Component } from 'react'
import CustomToolTip from '../../../Utils/CustomToolTip';

export default class RulesTooltip extends Component {
    
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { item, id } = this.props;

        return (
            item ?
                <>
                    <div id={'Tooltip' + id}>
                        <i className="fas fa-info-circle"/>
                    </div>
                    <CustomToolTip placement="bottom" target={'Tooltip' + id}>
                        {item.rules.map((i, k1) =>
                            <div key={k1} style={{ display: 'flex' }}>
                                {i.name}
                            </div>
                        )}
                    </CustomToolTip>
                </>
            :''
        )
    }
}
