import React, { Component } from 'react';
import { Col, Row, Collapse } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { RenderPaymentGatewayDetails } from './RenderPaymentGatewayDetails';
import { RenderPaymentGatewayForm } from './RenderPaymentGatewayForm';
import { RenderBankTransferForm } from './RenderBankTransferForm';
import { RenderPaybyrdForm } from './RenderPaybyrdForm';
import { RenderPaymentGatewayValidPaymentModes } from './RenderPaymentGatewayValidPaymentModes';
import { RenderCustomForm } from './RenderCustomForm';
import { RenderIfThenPayForm } from './RenderIfThenPayForm';
import { RenderRedsysForm } from './RenderRedsysForm';
import { RenderSibsPaymentForm } from './RenderSibsPaymentForm';

class PaymentGatewayMobile extends Component {
    render() {
        const { paymentGatewayTypes, paymentGatewayLogos, paymentGateways, stripePaymentModes, unicrePaymentModes, 
            ifThenPayIsDisplay, isMbEntityRequiredOnUnicre, bankTransfeIdx, unicreIdx, stripeIdx, paybyrdIdx, customIdx, 
            ifThenPayIdx, redsysIdx, sibsIdx, heyCardIdx, RenderPaymentStatus, unicreProviders, mbValidatyDaysOptions, RenderStripeDetails} = this.props;

        return (
            <Row>
                <Col className='bg-light'>
                    <hr className="m-0" />
                    <Row onClick={() => this.props.changePaymentMode( paymentGatewayTypes[stripeIdx])} className={this.props.paymentMode === paymentGatewayTypes[stripeIdx] ? 'bg-light py-3' : 'bg-white py-3'} >
                        <Col>
                            <a style={{ cursor: 'pointer' }}>
                                <img height="40" className="pr-1" src={paymentGatewayLogos[stripeIdx]} />
                            </a>
                        </Col>
                        <Col className="text-right">
                            <RenderPaymentStatus channel={paymentGatewayTypes[stripeIdx]} paymentGateways={this.props.paymentGateways} />
                        </Col>
                    </Row>

                    <hr className="m-0" />
                    
                    <Collapse isOpen={this.props.paymentMode === paymentGatewayTypes[stripeIdx]}>
                        <RenderStripeDetails paymentMode={this.props.paymentMode} paymentGateways={this.props.paymentGateways} disconnectStripe={this.props.disconnectStripe.bind(this)} setPaymentStripePaymentStatus={this.props.setPaymentStripePaymentStatus} savePaymentGateway={this.props.savePaymentGateway} updateValidPaymentModes={this.props.updateValidPaymentModes}/>
                    </Collapse>

                    <hr className="m-0" />

                    <Row onClick={() => this.props.changePaymentMode(paymentGatewayTypes[bankTransfeIdx])} className={this.props.paymentMode === paymentGatewayTypes[bankTransfeIdx] ? 'bg-light py-3' : 'bg-white py-3'} >
                        <Col className="mx-1">
                            <a style={{ cursor: 'pointer' }}>
                                <h4>{paymentGatewayLogos[bankTransfeIdx]}</h4>
                            </a>
                        </Col>
                        <Col className="text-right">
                            <RenderPaymentStatus channel={paymentGatewayTypes[bankTransfeIdx]} paymentGateways={this.props.paymentGateways} />
                        </Col>
                    </Row>

                    <hr className="m-0" />

                    <Collapse isOpen={this.props.paymentMode === paymentGatewayTypes[bankTransfeIdx]}>
                        <form className='mt-3'  ref={this.props.paymentGatewayForm}>
                            <RenderPaymentGatewayDetails paymentGateway={paymentGateways[bankTransfeIdx]} idx={bankTransfeIdx} title={<FormattedMessage id="PaymentGateway.BankTransferSettings" />} saveData={this.props.savePaymentGateway} setPaymentStripePaymentStatus={this.props.setPaymentStripePaymentStatus}>
                                <RenderBankTransferForm onlyHasBankTransfer={this.props.onlyHasBankTransfer()} paymentGateway={paymentGateways[bankTransfeIdx]} handleData={this.props.handleData} idx={bankTransfeIdx} />
                            </RenderPaymentGatewayDetails>
                        </form>
                    </Collapse>

                    <hr className="m-0" />

                    <Row onClick={() => this.props.changePaymentMode(paymentGatewayTypes[unicreIdx])} className={this.props.paymentMode === paymentGatewayTypes[unicreIdx] ? 'bg-light py-3' : 'bg-white py-3'} >
                        <Col className="mx-1">
                            <a style={{ cursor: 'pointer' }}>
                                <img height="40" className="pr-1" src={paymentGatewayLogos[unicreIdx]} />
                            </a>
                        </Col>
                        <Col className="text-right">
                            <RenderPaymentStatus channel={paymentGatewayTypes[unicreIdx]} paymentGateways={this.props.paymentGateways} />
                        </Col>
                    </Row>
                    
                    <hr className="m-0" />
                    
                    <Collapse isOpen={this.props.paymentMode === paymentGatewayTypes[unicreIdx]}>
                        <form className='mt-3'  ref={this.props.paymentGatewayForm}>
                            <RenderPaymentGatewayDetails paymentGateway={paymentGateways[unicreIdx]} idx={unicreIdx} title={<FormattedMessage id="PaymentGateway.UnicreSettings" />} saveData={this.props.savePaymentGateway} setPaymentStripePaymentStatus={this.props.setPaymentStripePaymentStatus}>
                                <RenderPaymentGatewayForm paymentGateway={paymentGateways[unicreIdx]} handleData={this.props.handleData} idx={unicreIdx} providers={unicreProviders} handleComboData={this.props.handleComboData} mbValidatyDaysOptions={mbValidatyDaysOptions} isMbEntityRequired={isMbEntityRequiredOnUnicre} />
                                <RenderPaymentGatewayValidPaymentModes paymentMode={this.props.paymentMode} validPaymentModes={unicrePaymentModes} paymentGateway={paymentGateways[unicreIdx]} idx={unicreIdx} updateValidPaymentModes={this.props.updateUnicreValidPaymentModes} />
                            </RenderPaymentGatewayDetails>
                        </form>
                    </Collapse>

                    <hr className="m-0" />

                    <Row onClick={() => this.props.changePaymentMode(paymentGatewayTypes[paybyrdIdx])} className={this.props.paymentMode === paymentGatewayTypes[paybyrdIdx] ? 'bg-light py-3' : 'bg-white py-3'} >
                        <Col className="mx-1">
                            <a style={{ cursor: 'pointer' }}>
                                <img height="40" className="pr-1" src={paymentGatewayLogos[paybyrdIdx]} />
                            </a>
                        </Col>
                        <Col className="text-right">
                            <RenderPaymentStatus channel={paymentGatewayTypes[paybyrdIdx]} paymentGateways={this.props.paymentGateways} />
                        </Col>
                    </Row>
                    
                    <hr className="m-0" />

                    <Collapse isOpen={this.props.paymentMode === paymentGatewayTypes[paybyrdIdx]}>
                        <form className='mt-3'  ref={this.props.paymentGatewayForm}>
                            <RenderPaymentGatewayDetails paymentGateway={paymentGateways[paybyrdIdx]} idx={paybyrdIdx} title={<FormattedMessage id="PaymentGateway.PaybyrdSettings" />} saveData={this.props.savePaymentGateway} setPaymentStripePaymentStatus={this.props.setPaymentStripePaymentStatus}>
                                <RenderPaybyrdForm paymentGateway={paymentGateways[paybyrdIdx]} handleData={this.props.handleData} idx={paybyrdIdx} />
                                <RenderPaymentGatewayValidPaymentModes paymentMode={this.props.paymentMode} validPaymentModes={stripePaymentModes} paymentGateway={paymentGateways[paybyrdIdx]} idx={paybyrdIdx} updateValidPaymentModes={this.props.updateValidPaymentModes} />
                            </RenderPaymentGatewayDetails>
                        </form>
                    </Collapse>

                    <hr className="m-0" />

                    <Row onClick={() => this.props.changePaymentMode(paymentGatewayTypes[customIdx])} className={this.props.paymentMode === paymentGatewayTypes[customIdx] ? 'bg-light py-3' : 'bg-white py-3'} >
                        <Col className="mx-1">
                            <a style={{ cursor: 'pointer' }}>
                                <h4>{paymentGatewayLogos[customIdx]}</h4>
                            </a>
                        </Col>
                        <Col className="text-right">
                            <RenderPaymentStatus channel={paymentGatewayTypes[customIdx]} paymentGateways={this.props.paymentGateways} />
                        </Col>
                    </Row>
                    
                    <hr className="m-0" />

                    <Collapse isOpen={this.props.paymentMode === paymentGatewayTypes[customIdx]}>
                        <form className='mt-3'  ref={this.props.paymentGatewayForm}>
                            <RenderPaymentGatewayDetails paymentGateway={paymentGateways[customIdx]} idx={customIdx} title={<FormattedMessage id="PaymentGateway.CustomSettings" />} saveData={this.props.savePaymentGateway} setPaymentStripePaymentStatus={this.props.setPaymentStripePaymentStatus}>
                                <RenderCustomForm paymentGateway={paymentGateways[customIdx]} handleData={this.props.handleTextChange.bind(this)} handleEditorStateChange={this.props.handleEditorStateChange} idx={customIdx} />
                            </RenderPaymentGatewayDetails>
                        </form>
                    </Collapse>

                    <hr className="m-0" />

                    <div style={{ display: ifThenPayIsDisplay }}>
                        <Row onClick={() => this.props.changePaymentMode(paymentGatewayTypes[ifThenPayIdx])} className={this.props.paymentMode === paymentGatewayTypes[ifThenPayIdx] ? 'bg-light py-3' : 'bg-white py-3'} >
                            <Col className="mx-1">
                                <a style={{ cursor: 'pointer' }}>
                                    <img height="40" className="pr-1" src={paymentGatewayLogos[ifThenPayIdx]} />
                                </a>
                            </Col>
                            <Col className="text-right">
                                <RenderPaymentStatus channel={paymentGatewayTypes[ifThenPayIdx]} paymentGateways={this.props.paymentGateways} />
                            </Col>
                        </Row>
                        <hr className="m-0" />
                    </div>
                    
                    <hr className="m-0" />

                    <Collapse isOpen={this.props.paymentMode === paymentGatewayTypes[ifThenPayIdx]}>
                        <form className='mt-3'  ref={this.props.paymentGatewayForm}>
                            <RenderPaymentGatewayDetails paymentGateway={paymentGateways[ifThenPayIdx]} idx={ifThenPayIdx} title={<FormattedMessage id="PaymentGateway.IfThenPaySettings" />} saveData={this.props.savePaymentGateway} setPaymentStripePaymentStatus={this.props.setPaymentStripePaymentStatus}>
                                <RenderIfThenPayForm paymentGateway={paymentGateways[ifThenPayIdx]} handleData={this.props.handleData} idx={ifThenPayIdx} handleComboData={this.props.handleComboData} mbValidatyDaysOptions={mbValidatyDaysOptions} />
                                <RenderPaymentGatewayValidPaymentModes paymentMode={this.props.paymentMode} validPaymentModes={unicrePaymentModes} paymentGateway={paymentGateways[ifThenPayIdx]} idx={ifThenPayIdx} updateValidPaymentModes={this.props.updateValidPaymentModes} />
                            </RenderPaymentGatewayDetails>
                        </form>
                    </Collapse>

                    <hr className="m-0" />

                    <Row onClick={() => this.props.changePaymentMode(paymentGatewayTypes[redsysIdx])} className={this.props.paymentMode === paymentGatewayTypes[redsysIdx] ? 'bg-light py-3' : 'bg-white py-3'} >
                        <Col className="mx-1">
                            <a style={{ cursor: 'pointer' }}>
                                <img height="40" className="pr-1" src={paymentGatewayLogos[redsysIdx]} />
                            </a>
                        </Col>
                        <Col className="text-right">
                            <RenderPaymentStatus channel={paymentGatewayTypes[redsysIdx]} paymentGateways={this.props.paymentGateways} />
                        </Col>
                    </Row>
                    
                    <hr className="m-0" />
                    
                    <Collapse isOpen={this.props.paymentMode === paymentGatewayTypes[redsysIdx]}>
                        <form className='mt-3'  ref={this.props.paymentGatewayForm}>
                            <RenderPaymentGatewayDetails paymentGateway={paymentGateways[redsysIdx]} idx={redsysIdx} title={<FormattedMessage id="PaymentGateway.RedsysSettings" />} saveData={this.props.savePaymentGateway} setPaymentStripePaymentStatus={this.props.setPaymentStripePaymentStatus}>
                                <RenderRedsysForm paymentGateway={paymentGateways[redsysIdx]} handleData={this.props.handleData} idx={redsysIdx} />
                                <RenderPaymentGatewayValidPaymentModes paymentMode={this.props.paymentMode} validPaymentModes={stripePaymentModes} paymentGateway={paymentGateways[redsysIdx]} idx={redsysIdx} updateValidPaymentModes={this.props.updateValidPaymentModes} />
                            </RenderPaymentGatewayDetails>
                        </form>
                    </Collapse>

                    <hr className="m-0" />

                    <Row onClick={() => this.props.changePaymentMode(paymentGatewayTypes[sibsIdx])} className={this.props.paymentMode === paymentGatewayTypes[sibsIdx] ? 'bg-light py-3' : 'bg-white py-3'} >
                        <Col className="mx-1">
                            <a style={{ cursor: 'pointer' }}>
                                <img height="40" className="pr-1" src={paymentGatewayLogos[sibsIdx]} />
                            </a>
                        </Col>
                        <Col className="text-right">
                            <RenderPaymentStatus channel={paymentGatewayTypes[sibsIdx]} paymentGateways={this.props.paymentGateways} />
                        </Col>
                    </Row>
                    
                    <hr className="m-0" />
                    
                    <Collapse isOpen={this.props.paymentMode === paymentGatewayTypes[sibsIdx]}>
                        <form className='mt-3'  ref={this.props.paymentGatewayForm}>
                            <RenderPaymentGatewayDetails paymentGateway={paymentGateways[sibsIdx]} idx={sibsIdx} title={<FormattedMessage id="PaymentGateway.SibsSettings" />} saveData={this.props.savePaymentGateway} setPaymentStripePaymentStatus={this.props.setPaymentStripePaymentStatus}>
                                <RenderSibsPaymentForm paymentGateway={paymentGateways[sibsIdx]} handleData={this.props.handleData} idx={sibsIdx} mbValidatyDaysOptions={mbValidatyDaysOptions} isMbEntityRequired={isMbEntityRequiredOnUnicre} />
                                <RenderPaymentGatewayValidPaymentModes paymentMode={this.props.paymentMode} validPaymentModes={unicrePaymentModes} paymentGateway={paymentGateways[sibsIdx]} idx={sibsIdx} updateValidPaymentModes={this.props.updateUnicreValidPaymentModes} />
                            </RenderPaymentGatewayDetails>
                        </form>
                    </Collapse>

                    <hr className="m-0" />
                    
                    {global?.modules.some(m => m === 'HeyCard') ?
                        <>
                            <Row onClick={() => this.props.changePaymentMode(paymentGatewayTypes[heyCardIdx])} className={this.props.paymentMode === paymentGatewayTypes[heyCardIdx] ? 'bg-light py-3' : 'bg-white py-3'} >
                                <Col className="mx-1">
                                    <a style={{ cursor: 'pointer' }}>
                                        <img height="40" className="pr-1" src={paymentGatewayLogos[heyCardIdx]} />
                                    </a>
                                </Col>
                                <Col className="text-right">
                                    <RenderPaymentStatus channel={paymentGatewayTypes[heyCardIdx]} paymentGateways={this.props.paymentGateways} />
                                </Col>
                            </Row>
                            <hr className="m-0" />
                            <Collapse isOpen={this.props.paymentMode === paymentGatewayTypes[heyCardIdx]}>
                                <form className='mt-3'  ref={this.props.paymentGatewayForm}>
                                    <RenderPaymentGatewayDetails paymentGateway={paymentGateways[sibsIdx]} idx={sibsIdx} title={<FormattedMessage id="PaymentGateway.HeyCardSettings" />} saveData={this.props.savePaymentGateway} setPaymentStripePaymentStatus={this.props.setPaymentStripePaymentStatus}>
                                        <RenderSibsPaymentForm paymentGateway={paymentGateways[sibsIdx]} handleData={this.props.handleData} idx={sibsIdx} mbValidatyDaysOptions={mbValidatyDaysOptions} isMbEntityRequired={isMbEntityRequiredOnUnicre} />
                                        <RenderPaymentGatewayValidPaymentModes paymentMode={this.props.paymentMode} validPaymentModes={unicrePaymentModes} paymentGateway={paymentGateways[sibsIdx]} idx={sibsIdx} updateValidPaymentModes={this.props.updateUnicreValidPaymentModes} />
                                    </RenderPaymentGatewayDetails>
                                </form>
                            </Collapse>
                            <hr className="m-0" />
                        </>
                    :''}
                </Col>
            </Row>
        )
    }
}

export default injectIntl(PaymentGatewayMobile);
