import React, { Component } from 'react';
import { Row, Col, Card, Badge, Button, Form } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ConfirmActionModal, KebabMenu, StyledCard } from '../../Base/Common/CommonUIComponents';
import { deleteAPI, getAPI } from '../../Base/API';
import { getExtractorType } from '../../Base/Common/ReferenceDataFunctions';
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from '../../Base/Notification';

class MailExtractorTemplates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            extractorList: [],
            extractorTypes: getExtractorType(this.props.intl)
        };
    }

    componentDidMount() {
        this.getExtractor();
    }

    getExtractor = (e) => {
        if (e) e.preventDefault();

        this.setState({ block: true });
        var params = this.state.type ? `?type=${this.state.type}` : '';
        

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response) {
                    this.setState({ extractorList: data.response, block: false, error: errorMessage });
                    return;
                };

                this.setState(({ block: false }));
            }
            else this.setState(({ block: false }));
        }, `/api/Price/EmailReservation/EmailReservation/v1/Extractor` + params);
    }

    deleteExtractor = (e, id) => {
        if (e) e.preventDefault();

        this.setState({ block: true }, () =>
            deleteAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    else {
                        handleNotification(data, <FormattedMessage id="MailExtractor.ExtractorRemoved" />, <FormattedMessage id="generic.success" />);

                        this.updateList(id);
                    }

                    this.setState({ deleteModal: false, block: false });
                }
                else this.setState({ error: errorMessage, block: false });
            }, `/api/Price/EmailReservation/EmailReservation/v1/Extractor/${id}`));
    }

    updateList = (extractorId) => {
        const { extractorList } = this.state;

        const index = extractorList.findIndex(e => e.id === extractorId);

        if (index !== -1) {
            extractorList.splice(index, 1);

            this.setState({ extractorList })
        }
    }

    handleSelectChange = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        })
    }

    toggleModal = (modal, extractor) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedExtractor: extractor
        }))
    }


    render() {
        const { block, error, extractorList, extractorTypes } = this.state;
        

        return (
            <StyledCard block={block} error={error} title="navbar.MailExtractorTemplate">
                <Form onSubmit={this.getExtractor}>
                    <Row className="mb-3">
                        <Col sm={3}>
                            <CustomSelect
                                isSearchable
                                isClearable
                                placeholder={<FormattedMessage id="MailExtractor.Type"/>}
                                options={extractorTypes}
                                value={extractorTypes.find(el => el.value === this.state.type) || ''}
                                onChange={(combo) => this.handleSelectChange(combo, 'type')}
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn-sm btn-host" onClick={() => this.props.history.push(`/MailExtractorDetails`)}>
                                <i class="fas fa-plus"></i>
                            </Button>
                            <Button className="btn-sm btn-host ml-2" type="submit">
                                <i class="fas fa-search"></i>
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <Row>
                    {extractorList.map((extractor, key) =>
                        <Col sm={6} key={key} className="mb-3">
                            <Card className="border-0 shadow pointer" body>
                                <Row>
                                    <Col>
                                        {extractor.code}
                                    </Col>
                                    <Col className="text-right col-2">
                                        <Badge color="primary">
                                            <FormattedMessage id={`ReferenceDataFunctions.${extractor.type}`} />
                                        </Badge>                                        
                                    </Col>
                                    <Col sm={1} className="text-right">
                                        <KebabMenu
                                            editFunction={() => this.props.history.push(`/MailExtractorDetails/${extractor.id}`)}
                                            deleteFunction={_ => this.toggleModal('deleteModal', extractor)}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    )}
                </Row>


                {this.state.deleteModal ?
                    <ConfirmActionModal
                        block={this.state.block}
                        modal={this.state.deleteModal}
                        toggleModal={_ => this.toggleModal('deleteModal')}
                        actionFunction={(e) => this.deleteExtractor(e, this.state.selectedExtractor.id)}
                        text={<FormattedMessage id="MailExtractor.ConfirmRemoveExtractor" values={{ extractor: this.state.selectedExtractor.code }} />}
                    />
                    : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(MailExtractorTemplates);