import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Line } from "react-chartjs-2";
import { DashBoardCard } from "./DashBoardCard"
import BlockUi from 'react-block-ui';


export class EntitySyncDashboard extends Component {
    _isMounted = false;

    state = {
        PriceDataEvents: [],
        dataLine: {
            labels: ["0 h", "1 h", "2 h", "3 h", "4 h", "5 h", "6 h", "7 h", "8 h", "9 h", "10 h", "11 h", "12 h", "13 h", "14 h", "15 h", "16 h", "17 h", "18 h", "19 h", "20 h", "21 h", "22 h", "23 h"],
            datasets: [
                {
                    label: "",
                    fill: false,
                    borderColor: "#FFEE58",
                    data: []
                },
                {
                    label: "",
                    fill: false,
                    borderColor: "#F9A825",
                    data: []
                },
                {
                    label: "",
                    fill: false,
                    borderColor: "#FFCA28",
                    data: []
                },
                {
                    label: "",
                    fill: false,
                    borderColor: "#EF6C00",
                    data: []
                }
                
            ]
        }
    }


    componentDidMount() {
        this._isMounted = true;
    }

    getFormatedDate(updatedate) {
        if (updatedate != null) {
            var d = new Date(updatedate);
            return d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds()
        } else { return "" }
    }

    sumTotals(recordList) {
        var total = 0;
        if (recordList != null) {
            for (var i = 0; i < recordList.length; i++) {
                total += parseInt(recordList[i].totals, 10);
            }
            return total;
        } else {
            return "-"
        }
    }

    sumGraphicValues(recordList, label) {
        var total = 0;
        var result = [];

        

        for (var i = 0; i < this.state.dataLine.labels.length; i++) {
            var tot = 0;
            if (recordList != null && recordList.recordHour != null) {
                for (var z = 0; z < recordList.recordHour.length; z++) {
                    if (this.state.dataLine.labels[i] === recordList.recordHour[z].onHour + " h") {
                         tot = recordList.recordHour[z].totals;
                        break;
                    }

                }
                result.push(tot);
            }
        }

        var copy = Object.assign({}, this.state.dataLine);
        if (recordList) {
            for (var e = 0; e < copy.datasets.length; e++) {
                if (copy.datasets[e].label === "") {

                    copy.datasets[e].data = result;
                    copy.datasets[e].label = recordList.objName;
                    break;
                }
            }
        }
        return total;

    }

    getLine(value, label) {
        var formated_date = this.getFormatedDate(value.lastUpdate);
        var sum = this.sumTotals(value.recordHour)
        var line = { label: label, lastRecordDate: formated_date, total: sum };
        this.sumGraphicValues(value, label)
        return line;
    }

    render() {

        var price = [];
        var arr = this.props.EntityEvents;
        if (arr != null && arr.length > 1 ) {

            for (var z = 0; z < arr.length; z++) {
                price.push(this.getLine(arr[z], arr[z].objName));
            }
            
            return (
                <BlockUi tag="div" blocking={this.state.block}>
                <div className="wrapper">
                   

                        {price.map((item,key) =>
                                   
                            <DashBoardCard key={key} name={item.label} formatedDate={item.lastRecordDate} total={item.total} />
                                 
                                    
                                )}
                              
                            </div>
 <br />
                    <Row>
                        <Col>
                            <Line data={this.state.dataLine} height={50} options={{ responsive: true }} />
                        </Col>
                    </Row>

                </BlockUi>


            );
        } else {
            return (<br />);
        }
    }
};