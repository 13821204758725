import React, { Component } from 'react'
import { getAPI } from "../Base/API";
import { injectIntl } from 'react-intl';
import EmailReservationChannelConf from "./EmailReservationChannelConf";

class KeyConfig extends Component {

    constructor(props) {
		super(props);
		this.state = {
			error: null,
			block: true,
			channelData: [],
		};
	}

    componentDidMount() {
        this.getChannelInstances();
    }

    getChannelInstances = () => {
		this.setState({ block: true }, () =>
            getAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({
                        message: error.message,
                        stack: error.stack,
                        messageType: "danger"
                    });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.length > 0) {
                    this.setState({ error: errorMessage, block: false, channelData: data });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, "/api/Rate/ChannelConfig/v1/ChannelInstance?channelCode=MAILRES")
        );
	}

    render() {
        return (
            <div className='my-2 p-3 w-100 bg-white' >
            {
                this.state.channelData && this.state.channelData.length > 0 && this.state.channelData.map(channel =>
                    <EmailReservationChannelConf
                        selectedChannel={channel}
                    />
                )}
            </div>
        );
    }
}

export default injectIntl(KeyConfig);