
import axios from 'axios';
import { getToken, adalConfig, authContext } from '../../Utils/AdalConfig';
import { HandleAPIError, HandleExceededTimeAPIError } from './Common/EventGA';
import moment from 'moment';



// Add a request interceptor
const instance = axios.create(adalConfig)
instance.interceptors.request.use((config) => {
    // Check and acquire a token before the request is sent
    return new Promise((resolve, reject) => {
        authContext.acquireToken(adalConfig.endpoints.api, (message, token, msg) => {
            if (!!token) {
                config.headers.Authorization = `bearer ${token}`;
                resolve(config)
            } else {
                reject(config)
            }
        })
    })
}, function (error) {

    return Promise.reject(error)
})

export function getAPI(callback, url) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;
    
    instance.get(url)
        .then(res => {
            callback({ data: res.data , isLoading : false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}


export function postAPI(callback, url, body) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;
    
    instance.post(url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken(), 'Content-Type': 'application/json' } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}

export function putAPI(callback, url, body) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;

    instance.put(url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken(), 'Content-Type': 'application/json' } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}


export function patchAPI(callback, url, body) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;

    instance.patch(url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken() } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}

export function postMultiPartAPI(callback, url, body) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;
    
    instance.post(url, body, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken(), 'Content-Type': 'multipart/form-data' } })
        .then(res => {
            callback({ data: res.data, isLoading: false });

            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}

export function deleteAPI(callback, url) {
    const timerStart = moment();
    const currentPath = window?.location?.pathname;
    const currentLocation = window?.location?.href;

    instance.delete(url, { withCredentials: true, headers: { 'Authorization': 'bearer ' + getToken() } })
        .then(res => {
            callback({ data: res.data, isLoading: false });
            
            const timerEnd = moment();
            const diff = timerEnd.diff(timerStart, 'seconds');
            if(diff > 20)
                HandleExceededTimeAPIError(diff, url);
        })
        .catch(err => {
            HandleAPIError(err?.message, url, currentPath, currentLocation);
            callback({ error: err, isLoading: false });
        });
}

