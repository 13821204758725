import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { deleteAPI, getAPI } from '../../Base/API';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { CustomTable } from '../../Base/Common/CustomTable';
import { handleNotification } from '../../Base/Notification';
import { BeDataDetail } from './BeDataDetail';

export class ManageBeData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            hotels: []
        }
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return 
            }
            if (data) {
                this.setState({ error: errorMessage, block: false, hotels: data.response, seePrices: data.response.map(_ => false) });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/hotel/Complexes/v1/Hotel/BE`)
    }

    deleteHotelData = (hotelId) => {
        this.setState({ block: true });
        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return
            }
            if (data.errors && data.errors.length > 0) {
                handleNotification(data);
                this.setState({ error: errorMessage, block: false });
            }
            else {
                handleNotification(data, <FormattedMessage id="ManageBeData.DeleteHotelData" />, <FormattedMessage id="General.Success" />);
                this.setState({ error: errorMessage, block: false});
            }
        }, `/api/hotel/Complexes/v1/Hotel/${hotelId}/BE`)
    }

    render() {
        const { block, error, hotels } = this.state;

        const columns = [
            {
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "ManageBeData.Id" })
            },
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "ManageBeData.Hotel" })
            },
            {
                dataField: 'complexList',
                text: this.props.intl.formatMessage({ id: "ManageBeData.Complexes" }),
                formatter: (cell) => cell.map((cmp, key) =>
                    <Row key={key}>
                        <Col>
                            <FormattedMessage id="ManageBeData.ComplexesColumn" values={{ name: <b>{cmp.name}</b>, id: <b>{cmp.complexId}</b> }} />
                        </Col>
                    </Row>
                )
            },
            {
                dataField: 'hotelId',
                text: this.props.intl.formatMessage({ id: "ManageBeData.Remove" }),
                formatter: cell => <Button className="btn btn-host btn-sm" onClick={() => this.deleteHotelData(cell)}> <span className="fas fa-trash-alt" /> </Button>
            },
            {
                text: this.props.intl.formatMessage({ id: "ManageBeData.Prices" }),
                formatter: (_, row) => <BeDataDetail hotelId={row.hotelId} />
            }
        ];

        return (
            <StyledCard block={block} error={error} icon="fas fa-wrench" title="navbar.ManageBeData">
                <CustomTable
                    data={hotels}
                    columns={columns}
                    search={true}
                    showTotal={true}
                />
            </StyledCard>
        );
    }
}

export default injectIntl(ManageBeData)