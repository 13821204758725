import classnames from 'classnames';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import  NotificationTemplate  from './NotificationTemplate';
import NotificationConfiguration from './NotificationConfiguration';
import NotificationTestEmail from './NotificationTestEmail';


export class Notification extends Component {

    state = {
        activeTab: '1',
        error: null,
        block: true,
    };



    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

    }

    componentDidMount() {

    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }


    render() {

        return (
            <Card className="border-0">


                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            <FormattedMessage id="Notification.Template" />
                        </NavLink>
                    </NavItem>


                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                        >
                            <FormattedMessage id="Notification.Configuration" />
                        </NavLink>
                    </NavItem>

                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggle('3'); }}
                        >
                            <FormattedMessage id="Notification.testEmail" />
                        </NavLink>
                    </NavItem>





                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">

                        <NotificationTemplate />
                    </TabPane>



                    <TabPane tabId="2">
                        <NotificationConfiguration />

                    </TabPane>

                    <TabPane tabId="3">
                        <NotificationTestEmail/>
                    </TabPane>

                </TabContent>


            </Card>


        );
    }
}