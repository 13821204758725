import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardHeader, CardTitle, Badge, Row, Col, Table, Button } from 'reactstrap';
import classNames from 'classnames';
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { LogsRequestModal } from '../../Channel/Logs/LogsRequestModal';
import InfiniteScroll from 'react-infinite-scroller';
import RulesTooltip from './RulesTooltip';
import TimelineEventDetails from './TimelineEventDetails';

class   Timeline extends Component {
    //const Timeline = ({ children, className, layout, selectedEvent }) => (

    state = {
        error: null,
        block: this.props.block,
        requestContent: '',
        requestType: '',
        modal: false,
        errorDetailList: [
            'Booking.com.MISSING_RATEAMOUNTMESSAGES_ELEMENT',
            'Airbnb.7', 'Airbnb.400',
            'Expedia.3108', 'Expedia.3142', 'Expedia.7023', 'Expedia.7024', 'Expedia.3202', 'Expedia.1001', 'Expedia.7009', 'Expedia.7022', 'Expedia.3090', 'Expedia.7019',
            'Hostelworld.1016', 'Hostelworld.1062',
            'Siteminder.402',
            'Guest Centric.783'
        ]
    }


    render() {
        const {
            children,
            className,
            layout,
            selectedChannel,
            loadMoreFunc,
            displayChannelDataEntries,
            supportMobile
        } = this.props;


        return (
            <Row>
                <Col className="col-lg-4 col-12 left border-right ">
                    <div
                        className={classNames(className, 'timeline ', {
                            'timeline--two-columns': layout === '2-columns',
                            'timeline--one-column': layout === '1-column',
                        })}
                    >
                        {children}
                    </div>
                </Col>
                {!supportMobile ?
                    <Col className="col-lg-8 col-12 left">
                        <TimelineEventDetails
                            selectedEvent={this.props.selectedEvent}
                            errorDetailList={this.state.errorDetailList}
                            toggle={this.toggle}
                            block={this.props.block}
                            selectedChannel={selectedChannel}
                            loadMoreFunc={loadMoreFunc}
                            displayChannelDataEntries={displayChannelDataEntries}
                        />
                    </Col>
                : ''}
            </Row>
        );
    }
}


Timeline.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    animate: PropTypes.bool,
    selectedEvent: PropTypes.object,
    selectedChannel: PropTypes.string,
    layout: PropTypes.oneOf(['1-column', '2-columns']),
};

Timeline.defaultProps = {
    animate: true,
    className: '',
    layout: '2-columns',
};

export default Timeline;