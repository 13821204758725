import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Badge, Card, CardBody, Col, Form, Row, CardHeader, Button, ButtonGroup, Popover, PopoverBody } from 'reactstrap';
import moment from 'moment';
import { AirbnbChatWindow } from "./AirbnbChatWindow";
import { FormattedMessage, injectIntl } from 'react-intl';
import { SelectChannelInstanceByChannelCode } from '../../Base/Common/CommonUIComponents';
import { TextAreaAI } from '../../Base/Common/AiComponents';


class AirbnbMailBoxMessages extends Component {
    render() {
        return (
            <>
                <Row className="small">
                    <Col className="col-12 ">
                        <Card className=" bg-white mb-1 border-0">
                            <Form onSubmit={this.props.reloadMailBoxInfo}>
                                <CardBody className=" border-bottom">
                                    <Row>
                                        <Col className="col-3 pl-0">
                                            <SelectChannelInstanceByChannelCode
                                                name={'selectedConnection'}
                                                icon={'fa fa-plug fa-fw'}
                                                channelCode={'Airbnb'}
                                                placeholder={<FormattedMessage id="AirbnbMailBoxCard.SelectChannelInstanceByChannelCode" />}
                                                onChangeFunc={this.props.setselectedConnection}
                                                activeOnly={true}
                                                value={this.props.selectedConnection}
                                            />
                                        </Col>
                                        <Col className="text-right">
                                            <button className="btn btn-host shadow btn-sm" type="submit">
                                                <span className="fas fa-search" />
                                            </button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Form>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <Col className="col-3 border-right scrollableDiv" style={{ overflowY: 'auto', maxHeight: '78vh' }}>
                        <div>
                            {this.props.threadData && this.props.threadData.threads && this.props.threadData.threads.map((item, key) =>
                                <Row key={key} >
                                    <Col className="nav-stacked col-12 ">
                                        <Card key={key} onClick={this.props.changeThread.bind(this, item.id)} className={"mb-1 mt-1 p-2 shadow text-secondary " + (this.props.selectedThread === item.id ? 'bg-disabled text-white' : '')}>
                                            <Row  >
                                                <Col className="col-8 text-truncate">
                                                    <span className={'mr-2 flag-icon flag-icon-' + item.users[0].preferred_locale} /> {item.users[0].first_name}  
                                                </Col>
                                                <Col className="col-4">
                                                    {
                                                        item.last_message_sent_at ? moment(item.last_message_sent_at).format("YYYY-MM-DD") : ''
                                                    }
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-truncate">
                                                    {item.messages && item.messages.length > 0 ? item.messages[0].message : ''}                                                            
                                                </Col>
                                            </Row>
                                        </Card>                                         
                                    </Col>
                                </Row>
                            )}
                        </div>
                    </Col>
                    <Col className="col-6 px-0" style={this.props.selectedThread > 0 ? { backgroundColor: 'whitesmoke' } : {}}>
                        {this.props.threadDetails && Object.keys(this.props.threadDetails).length > 0 ?
                            <AirbnbChatWindow
                                threadDetails={this.props.threadDetails}
                            />
                        :''}    
                        <Row className="px-3 mt-2 pb-2">
                            <Col>
                                {this.props.selectedThread > 0 ?
                                    <Form onSubmit={(e) => this.props.submitPostMessage(e)}>
                                        <Row>
                                            <Col className="col-lg-10 col-9 pr-0">
                                                <TextAreaAI
                                                    inputName="message"
                                                    required={this.props.imageContent ? false : true}
                                                    value={this.props.messageToSend || ''}
                                                    handleChange={this.props.changeMsg}
                                                    isReview={false}
                                                    conversation={this.props.gptConversation}
                                                    openAiWidth={96}
                                                />
                                            </Col>
                                            <Col className="col-lg-2 col-3 text-right">
                                                <ButtonGroup>
                                                    <Button className=" btn btn-host  btn-sm mr-1" key="msgSubmitbutton" type="submit">
                                                        <span className="fas fa-play"> </span>
                                                    </Button>
                                                    <Button
                                                        className=" btn btn-host  btn-sm mr-1" key="msgSubmitImgbutton" id="msgSubmitImgbutton"
                                                        onClick={() => document.getElementById("selectImage").click()}>
                                                        <span className="far fa-image"> </span>
                                                    </Button>

                                                    <input className=" btn btn-host  btn-sm" key="msgSubmitPhotobutton" hidden type="file" id='selectImage' onChange={this.props.previewFile.bind(this)}></input>
                                                </ButtonGroup>
                                            </Col>
                                        </Row>
                                        <Popover placement="top" isOpen={this.props.popoverOpen} target="msgSubmitImgbutton" toggle={() => this.props.togglePopover()}>                                           
                                            <PopoverBody>
                                                <div style={{ 'height': '5px' }} onClick={() => this.props.resetImgPopover()}><i className=" float-right fas fa-times"></i></div>
                                                {this.props.imageContent && this.props.imageContent != '' ?
                                                    <img src={this.props.imageContent} id="imagePreview" style={{'max-height': '50px'}} alt="Preview" />
                                                    : <i className="fas fa-image fa-3x"></i>}
                                            </PopoverBody>
                                        </Popover>
                                    </Form>
                                :
                                    <h6 style={{ textAlign: 'center' }}> <span className="mt-2 ml-2 far fa-comments"> <FormattedMessage id="BookingMailBoxCard.SelectAConversation" /> </span > </h6>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col className="col-3 border-left ">
                        <BlockUi tag="div" blocking={this.props.blockSpecialOffer}>
                            { this.props.viewThread.attachment ?
                                <Card className="border-0" >
                                    <CardHeader className="bg-white">
                                        {
                                            this.props.viewThread.attachment.type !== 'Reservation' && this.props.specialOffer ?
                                                <Row>
                                                    <Col>
                                                        <strong className="text-center"> <span className="mr-2">{this.props.specialOffer && this.props.specialOffer.offerType}</span>
                                                            {this.props.specialOffer.specialOfferId}
                                                        </strong>
                                                    </Col>
                                                    <Col className="text-right">
                                                        <Badge >
                                                            {this.props.specialOffer.rescindedAt != null ? 'Rejected' : 'live'}
                                                        </Badge>
                                                    </Col>
                                                </Row>
                                            :
                                            <Row>
                                                <Col>
                                                    <strong className="text-center"> <span className="mr-2">{this.props.viewThread.attachment && this.props.viewThread.attachment.type}</span>
                                                            {this.props.viewThread.attachment.booking_details && this.props.viewThread.attachment.booking_details.reservation_confirmation_code}
                                                    </strong>
                                                </Col>
                                                <Col className="text-right">
                                                    <Badge >
                                                        {this.props.viewThread.attachment.status}
                                                    </Badge>
                                                </Col>
                                            </Row>
                                        }
                                    </CardHeader>
                                    <CardBody className="border-1 small">
                                        <Row>
                                            <Col className="col-6">
                                                <FormattedMessage id="AirbnbMailBoxCard.Checkin" />
                                            </Col>
                                            <Col>{this.props.viewThread.attachment.booking_details && this.props.viewThread.attachment.booking_details.checkin_date}</Col>
                                        </Row>                                      
                                        <Row>
                                            <Col>
                                                <FormattedMessage id="AirbnbMailBoxCard.Checkout" />
                                            </Col>
                                            <Col>
                                                {this.props.viewThread.attachment.booking_details && this.props.viewThread.attachment.booking_details.checkout_date}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormattedMessage id="AirbnbMailBoxCard.NumberOfGuests" />
                                            </Col>
                                            <Col>
                                                {this.props.viewThread.attachment.booking_details && this.props.viewThread.attachment.booking_details.number_of_guests}
                                            </Col>
                                        </Row>
                                        <Row className="mb-2">
                                            <Col>
                                                <FormattedMessage id="AirbnbMailBoxCard.ExpectedAmount" />
                                            </Col>
                                            <Col>
                                                {this.props.viewThread.attachment.booking_details && this.props.viewThread.attachment.booking_details.expected_payout_amount_accurate}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col colSpan="2">
                                                <BlockUi tag="div" blocking={this.props.actionBlock}>
                                                    {
                                                        this.props.viewThread.attachment.type === 'Reservation' && this.props.viewThread.attachment.status === 'pending' ?
                                                        <Row>
                                                            <Col className="col-6">
                                                                <Button className="btn-host btn-sm shadow btn-block text-center pr-2" onClick={() => this.props.setAction('AcceptReservation')}>
                                                                    <FormattedMessage id="AirbnbMailBoxCard.Accept" />
                                                                </Button>
                                                            </Col>
                                                            <Col>
                                                                <Button className="btn-host btn-sm shadow btn-block text-center " onClick={() => this.props.setAction('RejectReservation')}>
                                                                        <FormattedMessage id="AirbnbMailBoxCard.Decline" />
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                            : ''
                                                    }
                                                    {
                                                        this.props.viewThread.attachment.type === 'Reservation' && (this.props.viewThread.attachment.status === 'declined' || this.props.viewThread.attachment.status === 'canceled') ?
                                                            <Button className="btn-host btn-sm shadow btn-block text-center pr-2" onClick={() => this.props.setAction('AddSpecialoffer')}>
                                                                <FormattedMessage id="AirbnbMailBoxCard.SpecialOffer" />
                                                            </Button>
                                                            : ''
                                                    }
                                                    {
                                                        (this.props.viewThread.attachment.type !== 'Reservation' &&
                                                        ((this.props.specialOffer && this.props.specialOffer.offerType === 'special_offer') || (this.props.specialOffer && this.props.specialOffer.offerType === 'preapproval')) &&
                                                        this.props.specialOffer.rescindedAt === null)  ?
                                                            <Button className="btn-host btn-sm shadow text-center" onClick={() => this.props.setAction('withdraw')}>
                                                                <FormattedMessage id="AirbnbMailBoxCard.RemoveSpecialOffer" />
                                                            </Button>
                                                            : ''
                                                    }
                                                    {
                                                        (this.props.viewThread.attachment.type !== 'Reservation' && ((this.props.specialOffer && this.props.specialOffer.offerType === 'special_offer') || (this.props.specialOffer && this.props.specialOffer.offerType === 'preapproval')) && this.props.specialOffer.rescindedAt !== null) ?
                                                            <Row>
                                                                <Col>
                                                                    <Button className="btn-host btn-sm shadow btn-block text-center pr-2" onClick={() => this.props.setAction('AddSpecialoffer')}>
                                                                        <FormattedMessage id="AirbnbMailBoxCard.SpecialOffer" />
                                                                    </Button>
                                                                </Col>
                                                                <Col>
                                                                    <Button className="btn-host btn-sm shadow btn-block text-center pr-2" onClick={() => this.props.setAction('preapproval')}>
                                                                        <FormattedMessage id="AirbnbMailBoxCard.PreAprove" />
                                                                    </Button>
                                                                </Col>
                                                                {/*<Col>
                                                                    <Button className="btn-host btn-sm shadow btn-block text-center pr-2" onClick={() => this.props.setAction('Refuse')} disabled >
                                                                        <FormattedMessage id="AirbnbMailBoxCard.Decline" />
                                                                    </Button>
                                                                </Col>*/}
                                                            </Row>
                                                            : ''
                                                    }
                                            </BlockUi>
                                        </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            : ''}
                        </BlockUi>
                    </Col>
                </Row>
            </>
        )
    }
}

export default injectIntl(AirbnbMailBoxMessages);
