import React, { useEffect, useState, Component } from 'react';
import { CSVLink } from 'react-csv';
import { Button, Collapse } from 'reactstrap';
import Authorization from "../../Base/Authorization";


export class MobileFilters extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showFilters: false
        };
    }

    toggleCollapse = () => {
        this.setState({ showFilters: !this.state.showFilters })
    }
    
    render() {
        const { showCollapse = true, renderMainFilter, renderSecFilters, children, doSearch, addFunction, csvExport, searchType, authorizationCsv, performCsv, authorizationAdd, performAdd, authorizationSearch, performSearch, otherButtons = [] } = this.props;

        const totalButtons = (doSearch && 1 || 0) + (addFunction && 1 || 0) + (csvExport && 1 || 0);

        return (
        <div>
            <div className="py-2 d-flex align-items-center">
                {showCollapse &&
                    <div onClick={this.toggleCollapse} style={{ width: '10%' }} className='d-flex align-items-center'>
                        <i className="fas fa-ellipsis-v pl-3" />
                    </div>
                }
                <div style={{ width: (showCollapse ? '80%' : '90%') }} className='px-2 py-1 h-100'>
                    {renderMainFilter && renderMainFilter()}
                </div>
                <div style={totalButtons === 1 ? { width: '10%' } : { width: '20%' }} className='d-flex align-items-center justify-content-end'>

                    {otherButtons.map((button, key) => button.isVisible &&
                        <Button className="btn btn-host shadow btn-sm mr-2" onClick={button.onClickFunc} key={key}>
                            <i className={button.icon} ></i>
                        </Button>
                    )}

                    {csvExport ?
                        authorizationCsv && performCsv ?
                            <Authorization
                                perform={performCsv}
                                yes={() => (
                                    <CSVLink className="btn btn-sm btn-host" data={csvExport.csvData} filename={csvExport.fileName}>
                                        <i className="fas fa-file-download" />
                                    </CSVLink>
                                )}
                                no={() => ""}
                            />
                        :
                            <CSVLink className="btn btn-sm btn-host" data={csvExport.csvData} filename={csvExport.fileName}>
                                <i className="fas fa-file-download" />
                            </CSVLink>
                    :''}
                    {addFunction ?
                        authorizationAdd && performAdd ?
                            <Authorization
                                perform={performAdd}
                                yes={() => (
                                    <Button className="btn btn-host shadow btn-sm ml-2" onClick={addFunction}>
                                        <span className="fas fa-plus"></span>
                                    </Button>
                                )}
                                no={() => ""}
                            />
                        :
                            <Button className="btn btn-host shadow btn-sm ml-2" onClick={addFunction}>
                                <span className="fas fa-plus"></span>
                            </Button>
                    :''}
                    {(doSearch || searchType) ?
                        authorizationSearch && performSearch ?
                            <Authorization
                                perform={performSearch}
                                yes={() => (
                                    <Button className="btn btn-host shadow btn-sm ml-2" onClick={doSearch}>
                                        <span className="fas fa-search"></span>
                                    </Button>
                                )}
                                no={() => ""}
                            />
                        :
                            <Button className="btn btn-host shadow btn-sm ml-2" onClick={doSearch} type={searchType}>
                                <span className="fas fa-search"></span>
                            </Button>
                            : ''}
                </div>
            </div>

            {renderSecFilters && renderSecFilters()}

            <Collapse isOpen={this.state.showFilters}>
                {children}
            </Collapse>
        </div>
        );
    }
}
