import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from "../../Base/API"
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";
import moment from 'moment';


class AdminDashboard extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            blockMessages: true,
            blockAtentionRequired: true,
            blockDataToBeSent: true,
            blockInvalidReservations: true,
            blockSystemsEvents: true,
            blockReservationsRejected: true,
            blockAutomatedActions: true,
            error: null,
            startDate: moment().subtract(1, 'days'),
            endDate: moment(),
            systemEvents: 0,
            invalidReservation: 0,
            dataToBeSent: 0,
            attentionRequired: 0,
            inboundMessages: 0,
            inboundDeadLetter: 0,
            reservationMessages: 0,
            reservationDeadLetter: 0,
            disabledMappings: 0,
            disabledChannels: 0
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;

        this.getSystemEvents();
        this.getInvalidReservation();
        this.getDirtyData();
        this.getAttentionRequiredChannels();
        this.getQueueData();
        this.getErrorReservation();
        this.getPendingReservations();
        this.getAutomatedActions();
    }

    getQueueData() {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockMessages: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockMessages: false });
                    return;
                }
                if (data.queuestatus && data.queuestatus.length > 0) {
                    var inboundMsg = 0;
                    var inboundDL = 0;
                    var reservationMsg = 0;
                    var reservationDL = 0;

                    data.queuestatus.forEach((f) => {
                        if (f.path === 'rate' || f.path === 'price' || f.path === 'hotel' || f.path === 'notification') {
                            inboundMsg += f.activeMessages;
                            inboundDL += f.deadLetterMessages;
                        }
                        else {
                            reservationMsg += f.activeMessages;
                            reservationDL += f.deadLetterMessages;
                        }
                    })

                    this.setState({ inboundMessages: inboundMsg, inboundDeadLetter: inboundDL, reservationMessages: reservationMsg, reservationDeadLetter: reservationDL });
                }
                this.setState({ blockMessages: false });
            }
        }, '/api/User/Dashboard/v1/Queue');
    }

    getAttentionRequiredChannels() {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockAtentionRequired: false});
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockAtentionRequired: false});
                    return;
                }
                else {
                    this.setState({ attentionRequired: data.data.length, blockAtentionRequired: false });
                }
            }
        }, `/api/hotel/v1/ChannelList?channelConfigState=Pending%20Mapping%20Verification&channelConfigState=Pending%20Channel%20Verification`);
    }

    getDirtyData() {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockDataToBeSent: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockDataToBeSent: false });
                    return;
                }
                if (Array.isArray(data) && data.length > 0) {
                    var count = 0;
                    
                    data.forEach((d) => {
                        if (moment(d.availabilityLastSendDate).isBefore(moment(d.availabilityLastUpdate)) ||
                            moment(d.priceLastSendDate).isBefore(moment(d.priceLastUpdate)) ||
                            moment(d.restrictionsLastSendDate).isBefore(moment(d.ate))) {

                            count++;
                        }
                    })

                    this.setState({ dataToBeSent: count });
                }
                this.setState({ blockDataToBeSent: false });
            }
        }, `/api/Rate/DashBoard/GetHotelDirtyData?OnlyActiveChannelInstances=true`);
    }

    getInvalidReservation() {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockInvalidReservations: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockInvalidReservations: false });
                    return;
                }
                if (data.data) {
                    this.setState({ invalidReservation: data.data.length });
                }

                this.setState({ blockInvalidReservations: false })
            }
        }, `/api/Price/Reservation/v1/invalidReservations?StartDate=${this.state.startDate.format('YYYY-MM-DD')}&EndDate=${this.state.endDate.format('YYYY-MM-DD')}`);
    }
    
    getSystemEvents() {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockSystemsEvents: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockSystemsEvents: false });
                    return;
                }
                else {
                    this.setState({ systemEvents: data.count, blockSystemsEvents: false });
                }
            }
        }, `/api/User/v1/SystemEvents?StartDate=${this.state.startDate.format('YYYY-MM-DD')}&EndDate=${this.state.endDate.format('YYYY-MM-DD')}`);
    }

    getErrorReservation() {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage });
                    return;
                }
                else {
                    this.setState({ errorReservations: data.totalItems });
                }
            }
        }, `/api/Price/Reservation/v1/reservation/AllHotels?pmsStatus=Error&pageSize=100&fromReservUpdateDate=${this.state.startDate.format('YYYY-MM-DD')}&toReservUpdateDate=${this.state.endDate.format('YYYY-MM-DD')}`);
    }

    getPendingReservations() {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockReservationsRejected: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, blockReservationsRejected: false });
                    return;
                }
                else {
                    this.setState({ pendingReservations: data.totalItems, blockReservationsRejected: false });
                }
            }
        }, `/api/Price/Reservation/v1/reservation/AllHotels?pmsStatus=Sent&pageSize=100&fromReservUpdateDate=${this.state.startDate.format('YYYY-MM-DD')}&toReservUpdateDate=${this.state.endDate.format('YYYY-MM-DD')}`);
    }
    
    getAutomatedActions = () => {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockAutomatedActions: false });
                return;
            }
            if (data) {
                var disabledMappings = 0, disabledChannels = 0;

                if (data.response && data.response.length > 0) {
                    data.response.forEach(el => {
                        if (el.entity.includes('ChannelInstance')) {
                            disabledChannels++;
                        }
                        else {
                            disabledMappings++;
                        }
                    })
                }

                this.setState({ blockAutomatedActions: false, disabledMappings, disabledChannels });
            }
        }, `/api/Rate/ChannelConfig/v1/mapping/inactive?pageSize=1000&pageIndex=0&fromDate=${this.state.startDate.format('YYYY-MM-DD')}&toDate=${this.state.endDate.format('YYYY-MM-DD')}`);
    }

    render() {

        return (
            <div className="bg-light">
                <ErrorAlert error={this.state.error} />
                <Row className="mb-4 mt-4">
                    <Col>
                        <Card className={this.state.inboundDeadLetter && this.state.inboundDeadLetter > 0 ? "border-left-danger shadow" : "border-left-primary shadow"} onClick={() => this.props.history.push('/QueueStatusDashboard')} style={{ cursor: 'pointer', height: '140px' }}>
                            <CardBody>
                                <BlockUi tag="div" blocking={this.state.blockMessages}>
                                    <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        <FormattedMessage id="AdminDashboard.InboundMessagesToProcessed" />
                                    </p>

                                    <div className="d-flex align-items-center justify-content-end">
                                        <Col className="col-5 text-right">
                                            <p className="mb-0 text-black-50"> <FormattedMessage id="AdminDashboard.ActiveMessages" /> </p>
                                        </Col>
                                        <Col className="col-2 pr-0 text-right"> <h2 className="text-right"> {this.state.inboundMessages} </h2> </Col>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-end">
                                        <Col className="col-5 text-right">
                                            <p className="mb-0 text-black-50"> <FormattedMessage id="AdminDashboard.DeadLetterMessages" /> </p>
                                        </Col>

                                        <Col className="col-2 pr-0 text-right"> <h2 className="text-right"> {this.state.inboundDeadLetter} </h2></Col>                                        
                                    </div>
                                </BlockUi>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className={this.state.reservationDeadLetter && this.state.reservationDeadLetter > 0 ? "border-left-danger shadow" : "border-left-primary shadow"} onClick={() => this.props.history.push('/QueueStatusDashboard')} style={{ cursor: 'pointer', height: '140px' }}>
                            <CardBody>
                                <BlockUi tag="div" blocking={this.state.blockMessages}>
                                    <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        <FormattedMessage id="AdminDashboard.ReservationMessagesToProcessed" />
                                    </p>

                                    <div className="d-flex align-items-center justify-content-end">
                                        <Col className="col-5 text-right">
                                            <p className="mb-0 text-black-50"><FormattedMessage id="AdminDashboard.ActiveMessages" /> </p>
                                        </Col>
                                        <Col className="col-2 pr-0 text-right"><h2> {this.state.reservationMessages} </h2></Col>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-end">
                                        <Col className="col-5 text-right">
                                            <p className="mb-0 text-black-50"> <FormattedMessage id="AdminDashboard.DeadLetterMessages" /> </p>
                                        </Col>
                                        <Col className="col-2 pr-0 text-right"><h2 className="text-right"> {this.state.reservationDeadLetter} </h2></Col>
                                    </div>
                                </BlockUi>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <Card className={this.state.attentionRequired && this.state.attentionRequired > 0 ? "border-left-danger shadow" : "border-left-primary shadow"} onClick={() => this.props.history.push('/HotelChannelInfo')} style={{ cursor: 'pointer', height: '140px' }}>
                            <CardBody>                                
                                <BlockUi tag="div" blocking={this.state.blockAtentionRequired}>
                                    <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        <FormattedMessage id="AdminDashboard.ChannelsRequiringAttention" />
                                    </p>

                                    <h2 className="text-right h-100 d-flex align-items-center justify-content-end"> {this.state.attentionRequired} </h2>
                                </BlockUi>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className={this.state.dataToBeSent && this.state.dataToBeSent > 0 ? "border-left-danger shadow" : "border-left-primary shadow"}  onClick={() => this.props.history.push('/DirtyDataDashboard')} style={{ cursor: 'pointer', height: '140px' }}>
                            <CardBody>
                                <BlockUi tag="div" blocking={this.state.blockDataToBeSent}>
                                    <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        <FormattedMessage id="AdminDashboard.ActiveChannelsDataToBeSent" />
                                    </p>

                                    <h2 className="text-right h-100 d-flex align-items-center justify-content-end"> {this.state.dataToBeSent} </h2>
                                </BlockUi>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <Card className={this.state.invalidReservation && this.state.invalidReservation > 0 ? "border-left-danger shadow" : "border-left-primary shadow"}  onClick={() => this.props.history.push('/InvalidReservationDashboard')} style={{ cursor: 'pointer', height: '140px' }}>
                            <CardBody>
                                <BlockUi tag="div" blocking={this.state.blockInvalidReservations}>
                                    <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        <FormattedMessage id="AdminDashboard.InvalidReservations" />
                                    </p>

                                    <h2 className="text-right h-100 d-flex align-items-center justify-content-end"> {this.state.invalidReservation} </h2>
                                </BlockUi>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className={this.state.systemEvents && this.state.systemEvents > 0 ? "border-left-danger shadow" : "border-left-primary shadow"} onClick={() => this.props.history.push('/SystemEvents')} style={{ cursor: 'pointer', height: '140px' }}>
                            <CardBody>
                                <BlockUi tag="div" blocking={this.state.blockSystemsEvents}>
                                    <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        <FormattedMessage id="AdminDashboard.SystemsEvents" />
                                    </p>

                                    <h2 className="text-right h-100 d-flex align-items-center justify-content-end"> {this.state.systemEvents} </h2>
                                </BlockUi>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <Card className={this.state.errorReservations && this.state.errorReservations > 0 ? "border-left-danger shadow" : "border-left-primary shadow"}  onClick={() => this.props.history.push('/ReservationStatusDashboard')} style={{ cursor: 'pointer', height: '140px' }}>
                            <CardBody>
                                <BlockUi tag="div" blocking={this.state.blockReservationsRejected}>
                                    <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        <FormattedMessage id="AdminDashboard.ReservationsRejected" />
                                    </p>

                                    <div className="d-flex align-items-center justify-content-end">
                                        <Col className="col-5 text-right">
                                            <p className="mb-0 text-black-50"> <FormattedMessage id="AdminDashboard.PendingReservations" /> </p>
                                        </Col>
                                        <Col className="col-2 pr-0 text-right"> <h2 className="text-right"> {this.state.pendingReservations} </h2> </Col>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-end">
                                        <Col className="col-5 text-right">
                                            <p className="mb-0 text-black-50"> <FormattedMessage id="AdminDashboard.ErrorReservations" /> </p>
                                        </Col>

                                        <Col className="col-2 pr-0 text-right"> <h2 className="text-right"> {this.state.errorReservations} </h2></Col>
                                    </div>
                                </BlockUi>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card className={(this.state.disabledChannels && this.state.disabledChannels > 0) || (this.state.disabledMappings && this.state.disabledMappings > 0) ? "border-left-danger shadow" : "border-left-primary shadow"} onClick={() => this.props.history.push('/AutomatedActions')} style={{ cursor: 'pointer', height: '140px' }}>
                            <CardBody>
                                <BlockUi tag="div" blocking={this.state.blockAutomatedActions}>
                                    <p className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                                        <FormattedMessage id="AdminDashboard.AutomatedActions" />
                                    </p>

                                    <div className="d-flex align-items-center justify-content-end">
                                        <Col className="col-5 text-right">
                                            <p className="mb-0 text-black-50"> <FormattedMessage id="AdminDashboard.MappingsDisabled" /> </p>
                                        </Col>
                                        <Col className="col-2 pr-0 text-right"> <h2 className="text-right"> {this.state.disabledMappings} </h2> </Col>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-end">
                                        <Col className="col-5 text-right">
                                            <p className="mb-0 text-black-50"> <FormattedMessage id="AdminDashboard.ChannelDisabled" /> </p>
                                        </Col>

                                        <Col className="col-2 pr-0 text-right"> <h2 className="text-right"> {this.state.disabledChannels} </h2></Col>
                                    </div>
                                </BlockUi>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default AdminDashboard;