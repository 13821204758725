import React, { Component } from 'react';
import { Card, Row, Col, Button  } from 'reactstrap';
import '../../../Base/Common/Timeline.css';
import '../../../Base/Common/TimelineElement.css';
import { getConvertedDateToHotelTimezone } from '../../../Base/Common/ReferenceDataFunctions';
import { SelectChannelInstance, SelectRate } from "../../../Base/Common/CommonUIComponents";
import { CustomSingleDatePicker } from '../../../Base/Common/CustomReactDates';
import { CustomTable } from '../../../Base/Common/CustomTable';
import CustomSelect from '../../../Base/Common/CustomSelect';
import RulesTooltip from '../../../Base/Common/RulesTooltip';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { LogsRequestModal } from '../LogsRequestModal';
import { getAPI } from "../../../Base/API";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import LogsAvailabilityPriceFilters from './LogsAvailabilityPriceFilters';

class LogsAvailabilityPrice extends Component {

    state = {
        error: null,
        block: false,
        blockRooms: true,
        detailBlock: false,
        modal: false,
        // Search Criteria
        startDate: moment().subtract(2, "days"),
        endDate: moment().add(1, "days"),
        selectedErrorType: "",
        selectedServiceType: "",
        // pagination control
        resultsPerPage: 10,
        currentPage: 1,
        defCon1: moment('2024-03-22'),
        defaultResultsPerPage: 10,
        totalResults: 0,
        // Combobox information
        events: [],
        roomCategoryList: null,
        selectedRoom: '',
        selectedRate: '',
        selectedChannel: '',
        selectedData: '',
        detailChannel: null,
        channelinstanceList: [],
        selectedEvent: [],
        ariDate: moment(),
        sendDate: moment()
    };

    constructor(props) {
        super(props);
        this.searchEvents = this.searchEvents.bind(this);
        this.clearFilter = this.clearFilter.bind(this);
        this.search = this.search.bind(this);
    }
    
    getRoomCategoryList() {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockRooms: false });

                return;
            }
            if (data) {
                var arr = [];

                data.response && data.response.forEach(item => {
                    arr.push({ value: item.id, label: `(${item.code}) ${item.description}` });
                })

                this.setState({ roomCategoryList: arr, blockRooms: false });
            }
        }, '/api/hotel/RoomCategory/v1/Info');
    }
    
    componentDidMount() {
        this.getRoomCategoryList();
        //this.searchEvents();
    }

    clearFilter() {
        this.setState({
            startDate: moment().subtract(15, "days"),
            endDate: moment(),
            selectedChannel: null,
            selectedErrorType: null,
            selectedServiceType: null
        });
    }

    search() {
        this.setState({ block: true, currentPage: 1 }, () => this.searchEvents());
    }

    searchEvents() {
        const { currentPage, resultsPerPage, selectedChannel, selectedData, selectedRate, selectedRoom, ariDate, sendDate } = this.state;

        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ selectedEvent: [], totalResults: data.count, block: false },
                    () => this.setState({ selectedEvent: data.data || [] }));

                return;
            }
            this.setState({ block: false });
        }, `/api/User/Events/v1/EventDetails?pageIndex=${currentPage}&pageSize=${resultsPerPage}&channel=${selectedChannel}&data=${selectedData}&rate=${selectedRate}&room=${selectedRoom}&ariDate=${moment(ariDate).format('YYYY-MM-DD')}${sendDate ? `&sendDate=${moment(sendDate).format('YYYY-MM-DD')}` : ``}&archive=${moment(sendDate) < this.state.defCon1}`);
    }
   
    setComboStatus = (evt, inputValue, actionMeta) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value).join(',');
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }
        this.setState({ [evt]: targetValue })
    }

    handleTableChange = (_, { page, sizePerPage }) => {
        this.setState({ currentPage: page, resultsPerPage: sizePerPage }, this.searchEvents);
    }

    handleDate = (name, date) => {
        this.setState({
            [name]: date
        })
    }

    toggle(type, body, docTitle) {
        var content = '';
        var contentType = '';
        if (type && body !== undefined) {
            contentType = type;
            try {
                content = JSON.stringify(JSON.parse(body), null, 2)
            } catch (e) {
                content = body;
            }
        }

        this.setState(prevState => ({
            requestType: contentType,
            requestContent: content,
            modal: !prevState.modal,
            docTitle
        }));
    }

    render() {
        const { modal, requestType, requestContent, selectedEvent, resultsPerPage, currentPage, blockRooms } = this.state;
        
        const columns = [
            {
                dataField: 'channelName',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.ChannelName" })
            },
            {
                dataField: 'channelRateCode',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.ChannelRateCode" })
            },
            {
                dataField: 'pmsRateCode',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.LocalRateCode" })
            },
            {
                dataField: 'channelRoomTypeCode',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.ChannelRoomCode" }),
                sort: true
            },
            {
                dataField: 'pmsRoomTypeCode',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.PmsRoomCode" }),
                sort: true
            },
            {
                dataField: 'from',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.From" }),
                formatter: cell => cell ? moment(cell).format("YYYY-MM-DD") : ""
            },
            {
                dataField: 'to',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.To" }),
                formatter: cell => cell ? moment(cell).format("YYYY-MM-DD") : ""
            },
            {
                dataField: 'freeRooms',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.FreeRooms" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('freerooms') >= 0 ? 'bg-secondary text-white' : '' ,
                classes: this.state.selectedData && this.state.selectedData.indexOf('freerooms') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'maxStay',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.MaxStay" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('maxStay') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('maxStay') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'minStay',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.MinStay" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('minStay') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('minStay') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'cta',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.CTA" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('CTA') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('CTA') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'ctd',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.CTD" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('CTD') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('CTD') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'stopSales',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.StopSales" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('stopSales') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('stopSales') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'minAdvancedBookingOffset',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.MinAdvancedBookingOffset" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('minAdvancedBookingOffset') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('minAdvancedBookingOffset') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'maxAdvancedBookingOffset',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.MaxAdvancedBookingOffset" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('maxAdvancedBookingOffset') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('maxAdvancedBookingOffset') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'prices',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.Price" }),
                formatter: (cell) => cell && cell.length > 0 ? cell.join(' / ') : '',
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('price') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('price') >= 0 ? 'border border-secondary' : ''
            },
            {
                dataField: 'rules',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.Rules" }),
                headerClasses: this.state.selectedData && this.state.selectedData.indexOf('rules') >= 0 ? 'bg-secondary text-white' : '',
                classes: this.state.selectedData && this.state.selectedData.indexOf('rules') >= 0 ? 'border border-secondary' : '',
                formatter: (cell, row) => {
                    const item = {
                        rules: cell
                    };
                    return(
                        cell?.length > 0 ?
                            <RulesTooltip
                              item={item}
                                id={row?.id}
                            />
                        :''
                    )
                }
            },
            {
                dataField: 'updatedAt',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.UpdateDate" }),
                formatter: (cell, row) => cell ? getConvertedDateToHotelTimezone(cell).format("YYYY-MM-DD HH:mm:ss") : '',
            },
            {
                dataField: 'result',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.Result" }),
                formatter: cell => <span className={`badge ${cell === 2 ? `badge-danger` : (cell === 3 ? `badge-warning text-white` : `badge-success`)}`} ><i className={`fas ${cell === 1 ? `fa-check` : `fa-exclamation-triangle`}`} /></span>
            },
            {
                dataField: 'request',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.Request" }),
                formatter: (cell, row) => <Button className="btn btn-host btn-sm" onClick={this.toggle.bind(this, 'request', cell, row ? row.channelName : '')}><i className="fas fa-file-import" /></Button>
            },
            {
                dataField: 'response',
                text: this.props.intl.formatMessage({ id: "LogsAvailabilityPrice.Response" }),
                formatter: (cell, row) => <Button className="btn btn-host btn-sm" onClick={this.toggle.bind(this, 'response', cell, row ? row.channelName : '')}><i className="fas fa-file-export" /></Button>
            }
        ];
                  
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <LogsRequestModal modal={modal} toggle={this.toggle.bind(this, '')} requestType={requestType} requestContent={requestContent} docTitle={this.state.docTitle} />

                    <ErrorAlert error={this.state.error} />
                    <Card className="border-0 mb-4">

                        <LogsAvailabilityPriceFilters
                            doSearch={this.search}
                            setComboStatus={this.setComboStatus}
                            handleDate={this.handleDate}
                            roomCategoryList={this.state.roomCategoryList}
                            ariDate={this.state.ariDate}
                            sendDate={this.state.sendDate}
                            blockRooms={blockRooms}
                            selectedData={this.state.selectedData}
                            selectedRoom={this.state.selectedRoom}
                            selectedRate={this.state.selectedRate}
                            selectedChannel={this.state.selectedChannel}
                        />

                        <Row className="mb-2 mt-4">
                            <Col>
                                <CustomTable
                                    data={selectedEvent}
                                    columns={columns}
                                    sizePerPage={this.state.resultsPerPage}
                                    totalSize={this.state.totalResults}
                                    search={true}
                                    exportCSV={true}
                                    hasPagination={false}
                                />                           
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-3">
                                <Button className="btn-host shadow btn-sm mr-1" style={{ opacity: '1' }} disabled={!currentPage || currentPage <= 1} onClick={() => this.handleTableChange(null, { page: parseInt(currentPage) - 1, sizePerPage: resultsPerPage })}>
                                    <i className="fas fa-angle-left" />
                                </Button>
                            </Col>
                            <Col className="text-center px-0" style={{ fontSize: '20px' }}>
                                <FormattedMessage id="LogsAvailabilityPrice.Page" values={{ page: currentPage }} />
                            </Col>
                            <Col className="col-3 text-right">
                                <Button className="btn-host btn-sm shadow" disabled={selectedEvent?.length < resultsPerPage} onClick={() => this.handleTableChange(null, { page: parseInt(currentPage) + 1, sizePerPage: resultsPerPage })}>
                                    <i className="fas fa-angle-right" />
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(LogsAvailabilityPrice)