import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Form, FormFeedback } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../../Base/Notification";
import { postAPI, getAPI } from "../../Base/API";
import { StyledCard } from "../../Base/Common/CommonUIComponents";
import CustomSelect from '../../Base/Common/CustomSelect'

class NewChannelPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			block: false,
			channelList: [],
			request: {
				oldUser: null,
				newUser: null,
				oldPassword: null,
				newPassword: null
			},
			isSamePassword: null,
			hotelCode: null,
			code: null
		};
	}

	handleChangeRequest = (e) => {
		const { name, value } = e.target;
		this.setState({
			request: {
				...this.state.request,
				[name]: value
			}
		})
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		})
	}

	changePassword = (e) => {
		e.preventDefault();
		this.setState({ block: true });

		var body = { ...this.state.request }

		postAPI(result => {
			const { data, error } = result;
			var errorMessage = [];

			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0 || data.warnings && data.warnings.length > 0) {
					handleNotification(data);
					this.setState(({ block: false, error: errorMessage }));
					return;
				}
				if (data.status && data.status === 'Success') {
					handleNotification(data, <FormattedMessage id="ChangePassword.PasswordChanged" />, <FormattedMessage id="General.Success" />);
				}
				this.setState(({ invalidPassword: false, block: false }));
			}
		}, `/api/Rate/ChannelConfig/v1/Channel/${this.state.code}/hotel/${this.state.hotelCode}/ChangePassword`, JSON.stringify(body));		
	}

	validatePassword = () => {
		var notValidChar = [':', '/', '?', '#', '[', ']', '@', '&', "'", '|', ';', '%', '=', '"', '<', '>', '&'];
		const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{12,}$/i;

		if (this.state.request.newPassword && (notValidChar.some(c => this.state.request.newPassword.includes(c)) || !regex.test(this.state.request.newPassword))) {
			this.setState({ invalidPassword: true, block: false });
			return;
		}
		else {
			this.setState({ invalidPassword: false, block: false });
		}

		this.confirmPassword();
	}

	confirmPassword = () => {
		let isSame = false;

		if (this.state.request.newPassword === this.state.confirmPassword) {
			isSame = true;
		}

		this.setState({ isSamePassword: isSame });
	}
	
	handleSwitch = (evt) => {
		const { name, checked } = evt.target;

		this.setState({ [name]: checked });
	}

	handleChannel = (combo) => {
		this.setState({
			code: combo ? combo.value : null
		})
    }

	render() {
		const channelList = [
			{ label: 'AVAILPRO', value: 'AVAILPRO' },
			{ label: 'D-Edge', value: 'DEDGE' }
		];

		return (
			<StyledCard block={this.state.block} error={this.state.error} icon='fas fa-key' title='navbar.NewChannelPassword' >
				<Form onSubmit={this.changePassword}>
					<Row className="mb-3">
						<Col className="col-3">
							<CustomSelect
								icon={'fa fa-plug fa-fw'}
								placeholder={<FormattedMessage id="ChannelList.Channel" />}
								options={channelList}
								onChange={this.handleChannel.bind(this)}
								isClearable isSearchable required
							/>
						</Col>
						<Col className="col-3">
							<Input
								type="text"
								name="hotelCode"
								onChange={(e) => this.handleChange(e)}
								placeholder={this.props.intl.formatMessage({ id: "ChannelList.PropertyCode" })}
								required
							/>
						</Col>
						<Col className="text-right">
							<Button className="btn btn-host btn-sm mr-1" type="submit" disabled={this.state.invalidPassword === true || this.state.isSamePassword !== true}>
								<span className="fas fa-save" />
							</Button>
						</Col>
					</Row>
					<Row> 
						<Col> <FormattedMessage id="ChangePassword.CurrentCredentials" /> <hr className="mt-1 mb-3" /></Col>
						<Col> <FormattedMessage id="ChangePassword.NewCredentials" /> <hr className="mt-1 mb-3" /> </Col>
					</Row>					

					<FormGroup row>
						<Label sm={2}><FormattedMessage id="ChangePassword.OldUser" /></Label>
						<Col sm={4}>
							<Input
								type="text"
								name="oldUser"
								onChange={e => this.handleChangeRequest(e)}
								required
							/>
						</Col>
						<Label sm={2}><FormattedMessage id="ChangePassword.NewUser" /></Label>
						<Col sm={4}>
							<Input
								type="text"
								name="newUser"
								onChange={e => this.handleChangeRequest(e)}
								required
							/>
						</Col>
					</FormGroup>

					<FormGroup row>
						<Label sm={2}><FormattedMessage id="ChangePassword.OldPassword" /></Label>
						<Col sm={4}>
							<Input
								type="password"
								name="oldPassword"
								onChange={e => this.handleChangeRequest(e)}
								required
							/>
						</Col>
						<Label sm={2}><FormattedMessage id="ChangePassword.NewPassword" /></Label>
						<Col sm={4}>
							<Input
								type="password"
								name="newPassword"
								id="newPassword"
								onChange={e => this.handleChangeRequest(e)}
								required
								invalid={this.state.invalidPassword ? true : false}
								onBlur={this.validatePassword}
							/>
							<FormFeedback><FormattedMessage id="ChangePassword.InvalidPassword" values={{ differentThan: `:/?#[]@&'|;%="<>'&` }}/></FormFeedback>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Col sm={6}> </Col>
						<Label sm={2}><FormattedMessage id="ChangePassword.ConfirmPassword" /></Label>
						<Col sm={4}>
							<Input
								type="password"
								name="confirmPassword"
								onChange={e => this.setState({ confirmPassword: e.target.value})}
								required
								invalid={this.state.isSamePassword === false ? true : false}
								onBlur={this.confirmPassword}
							/>
							<FormFeedback><FormattedMessage id="ChangePassword.PasswordsDoNotMatch" /></FormFeedback>
						</Col>
					</FormGroup>
				</Form>
			</StyledCard>
		);
	}
}

export default injectIntl(NewChannelPassword);