import React, { Component } from 'react';
import classnames from 'classnames';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import RateCodeTextDescription from './RateCodeTextDescription';
import { PolicyDescriptions } from '../../HotelSetting/Property/Room/PolicyDescriptions';

export class RateCodeTexts extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1'
        }
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { fromBEConfig, rateCodes, rateCodeDetail, rateCodeId, updateTable } = this.props;
        const { activeTab } = this.state;

        return (
            <div>
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="RoomCategorySetupDetails.Description" />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                            <FormattedMessage id="RoomCategorySetupDetails.Policies" />
                        </NavLink>
                    </NavItem>
                </Nav>
                {rateCodeId ?
                    <TabContent activeTab={activeTab}>
                        {activeTab === "1" ?
                            <TabPane tabId="1">
                                <RateCodeTextDescription fromBEConfig={fromBEConfig} rateCodes={rateCodes} rateCodeDetail={rateCodeDetail} rateCodeId={rateCodeId} updateTable={updateTable} />
                            </TabPane>
                        : activeTab === "2" ?
                        <TabPane tabId="2">
                            <PolicyDescriptions policyType='RateCode' url={`/api/Rate/Rate/v1/rateCode/${rateCodeId}/Policy/Language`} helperText={<FormattedHTMLMessage id="RatesIntegrations.Help" />} helperTextId={'RatesIntegrationsPolicyHelp'} />
                        </TabPane>
                        : ''}
                    </TabContent>
                :''}
            </div>
        );
    }
}