import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';

export class RenderPaymentGatewayDetails extends Component {

    render() {
        const { children, paymentGateway, idx, title, saveData, setPaymentStripePaymentStatus } = this.props;

        return (
            <div>
                <Row>
                    {!global.isMobile ?
                        <Col>
                            <h5>
                                {title}
                            </h5>
                        </Col>
                    
                    :
                        <Col className="d-flex justify-content-between align-items-center mb-2">
                            <h5>
                                {title}
                            </h5>
                            <div className='d-flex flex-row'>
                                <Button className="btn btn-host btn-sm mr-2" onClick={() => saveData(idx)}>
                                    <i className="fas fa-save" />
                                </Button>
                                <Button className="btn btn-host btn-sm" onClick={() => setPaymentStripePaymentStatus(!paymentGateway.active, idx)} disabled={paymentGateway.id === undefined || (paymentGateway.userId === undefined || paymentGateway.accessToken === undefined || paymentGateway.refreshToken === undefined)}>
                                    <i className="fas fa-power-off" />
                                </Button>
                            </div>
                        </Col>
                    }
                </Row>
                <hr className="my-1" />
                {children}
                {!global.isMobile &&
                    <Row className="mb-2">
                        <Col className={`d-flex ${global.isMobile ? 'justify-content-end' : ''}`}>
                            <Button className="btn btn-host btn-sm mr-2" onClick={() => saveData(idx)}>
                                <i className="fas fa-save" />
                            </Button>
                            <Button className="btn btn-host btn-sm" onClick={() => setPaymentStripePaymentStatus(!paymentGateway.active, idx)} disabled={paymentGateway.id === undefined || (paymentGateway.userId === undefined || paymentGateway.accessToken === undefined || paymentGateway.refreshToken === undefined)}>
                                <i className="fas fa-power-off" />
                            </Button>
                        </Col>
                    </Row>
                }
            </div>
        );
    }
}