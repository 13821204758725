import React, { Component } from 'react';
import { Col, Card, CardBody, Row, Button, InputGroup } from 'reactstrap';
import { getAPI, postAPI, deleteAPI } from "../Base/API";
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Common/ErrorAlert";
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CustomTable } from '../Base/Common/CustomTable'
import CustomSelect from '../Base/Common/CustomSelect';


 class Security extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hotelId: this.props.hotelId,
            block: true,
            blockCombo: true,
            startDate: moment().subtract(7, 'day'),
            endDate: moment(),
            userManagedList: [],
            listUsersLogs: [],
            selecedUser: null
        };
       
    }

    componentDidMount() {

        this.setState({ block: false });
        this.getUserLogs();
        this.getManagedUsers();
       
    }

     getUserLogs() {
         this.setState({ block: true });
         var param = '';
         if (this.state.startDate)
             param += '?fromDate=' + this.state.startDate.format('YYYY-MM-DD')
         if (this.state.endDate)
             param += '&toDate=' + this.state.endDate.format('YYYY-MM-DD')
         if (this.state.selecedUser)
             param += '&userId=' + this.state.selecedUser.value

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
              
                this.setState({ listUsersLogs: data.userEvents, property: data.defaultProperty, userDetails: {}, block: false });
            }
        }, '/api/User/v1/User/getUserLog'+param);

     }

     getManagedUsers() {
         this.setState({ blockCombo: true });
         getAPI(result => {
             const { data, error, isLoading } = result;
             
             if (error) {
                 var errorMessage = [];
                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errorMessage, blockCombo: false });

                 return;
             }
             if (data) {
                 let userManagedList = [];
                 data.users.map((map, k) => userManagedList.push({ value: map.id, label: map.displayName}))
                 
                 this.setState({ listUsers: data.users, property: data.defaultProperty, userDetails: {}, userManagedList: userManagedList, blockCombo: false});
             }
         }, '/api/User/v1/User/getUsers');
         
     }


     setComboStatus = (field ) => {
         
         this.setState({ selecedUser: field })
     }

    render() {

        const columns = [
            
            {
                dataField: 'adUserId',
                text: this.props.intl.formatMessage({ id: "Security.userId" }),
                sort: true,
                formatter: (cell, row) => <div>{row.userName} {row.userSurname}</div>
            },
            {
                dataField: 'targetId',
                text: this.props.intl.formatMessage({ id: "Security.targetId" }),
                sort: true,
                formatter: (cell, row) => <div>{row.targetName} {row.targetSurname}</div>
            },
            {
                dataField: 'operationType',
                text: this.props.intl.formatMessage({ id: "Security.operationType" }),
                sort: true
            }, {
                dataField: 'property',
                text: this.props.intl.formatMessage({ id: "Security.property" }),
                sort: true
            }, {
                dataField: 'propertyValue',
                text: this.props.intl.formatMessage({ id: "Security.propertyValue" }),
                sort: true,
                formatter: (cell, row) => <div>{row.property === 'Group' ? this.props.intl.formatMessage({ id: "generic.accessGroup."+cell })  : cell}</div>
            }, {
                dataField: 'timestamp',
                text: this.props.intl.formatMessage({ id: "Security.timestamp" }),
                sort: true
            }
        ]
       
        return (
            
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                <Card className="mb-2">
                                
                                <CardBody className="pb-0">
                                <Row>
                                <Col><h6><span className="fas fa-laptop mr-1"></span> <FormattedMessage id="Security.UsedDevices" /> </h6><hr /></Col>
                            </Row>
                            <Row>
                                <Col><h6><span className="fas fa-fingerprint mr-1"></span> <FormattedMessage id="Security.Activity" /> </h6><hr /></Col>                                
                            </Row>
                            <Row>
                                <Col>
                                    
                <BlockUi tag="div" blocking={this.state.blockCombo}>
                                        <CustomSelect isClearable name={'selectedUser'} placeholder={<FormattedMessage id="Security.selectedUser" />} value={this.state.selecedUser} options={this.state.userManagedList} onChange={this.setComboStatus.bind(this)} /> 
                                        </BlockUi>
                                        </Col>

                                <Col>
                                    <InputGroup size="sm text-right" >
                                        <DateRangePicker
                                            startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                            startDateId="your_unique_start_date_id_announcement"
                                            isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                                            endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                            endDateId="your_unique_end_date_id_announcement"
                                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate: startDate, endDate: endDate })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput: focusedInput })}
                                            small={true}
                                            numberOfMonths={1}
                                            showDefaultInputIcon={true}
                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <Button className="float-right mr-2 btn-sm btn-host btn" onClick={this.getUserLogs.bind(this)}><i className="fas fa-search"></i></Button>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col>
                                    <CustomTable
                                        data={this.state.listUsersLogs ? this.state.listUsersLogs : []}
                                        columns={columns}
                                        page={this.state.currentPage}
                                        totalSize={this.state.listUsersLogs && this.state.listUsersLogs.length}
                                        onTableChange={this.handleTableChange}
                                        shadow={false}
                                        exportCSV={false}
                                        search={true}
                                        remote={false}
                                    />
                                </Col>
                            </Row>
                    </CardBody></Card>
                </BlockUi>
            </div>
        );
    }
};

export default injectIntl(Security)