import React, { Component } from 'react';
import { Row, Col, Card, Progress } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';
import { ErrorAlert } from "../../Common/ErrorAlert";
import { getAPI } from "../../Base/API"
import BlockUi from 'react-block-ui';

class BookingMarketInsights extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            channelinstanceList: [],
            selectedConnection: '',
            insights: {}
        }
    }

    componentDidMount() {
        this.getChannelInstance();
    }

    getChannelInstance = () => {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var id = '';
                var list = [];

                data && data.filter(c => c.active).forEach(item => {
                    list.push({ value: item.id, label: `${item.name} (${item.propertyCode})` });
                })

                if (list && list.length > 0) id = list[0].value;

                this.setState({ channelinstanceList: list, selectedConnection: id, error: errorMessage }, this.getMarketInsights);
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/Booking.com');
    }

    getMarketInsights = () => {
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if(data.errors && data.errors.length > 0) {
                    for (var i = 0; i < data.errors.length; i++) {
                        errorMessage.push({ message: data.errors[i].message, messageType: 'danger' });
                    }
                }

                if(data.warnings){
                    for (i = 0; i < data.warnings.length; i++) {
                        errorMessage.push({ message: data.warnings[i].message, messageType: 'warning' });
                    }
                }

                const insights = data.data ? data.data : {};
                this.setState({ error: errorMessage, block: false, insights });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/MarketInsight/v1/booking?channelInstanceId=${this.state.selectedConnection}`);
    }

    changeChannelConnection(combo) {
        this.setState({
            selectedConnection: combo ? combo.value : null,
            block: true
        }, () => this.getMarketInsights());
    }


    render() {
        const { insights } = this.state;

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Row className="mb-3 align-items-center">
                    <Col className="col-12 col-md-6 col-lg-3">
                        <CustomSelect
                            icon={'fa fa-plug fa-fw'}
                            isSearchable
                            placeholder={<FormattedMessage id="AirbnbReviewCard.SelectChannel" />}
                            options={this.state.channelinstanceList}
                            onChange={this.changeChannelConnection.bind(this)}
                            value={this.state.selectedConnection ? this.state.channelinstanceList.find(el => el.value === this.state.selectedConnection) : ''}
                        />
                    </Col>

                    {insights?.report_area ?
                        <Col className="text-center text-md-right mt-3 mt-md-0 col-12 col-md-6 col-lg-9 pl-4">
                            <b>{insights.report_area}</b> ({insights.report_date})
                        </Col>
                    : ''}
                </Row>

                <Row>
                    {insights && insights.demand_data?.map((data, key) =>
                        <Col className="col-12 col-lg-6 mb-3" key={key}>
                            <Card className="shadow h-100 border-left-primary py-3" body>
                                <div className="mb-3">
                                    <h6 style={{ fontSize: '18px' }} className="mb-1">{data.title}</h6>
                                    <span className="text-muted">{data.category_summary}</span>
                                </div>

                                {data.stats?.map((stats, idx) => 
                                    <Row key={idx} className="overbglight py-1 align-items-center">
                                        <Col className="col-4 pr-0">
                                            <i className={data.category_name === "rank" ? `mr-1 flag-icon flag-icon-${stats.key}` : ""} /> {stats.title}
                                        </Col>
                                        <Col className="col-5 col-sm-6 pr-0 pr-md-3">
                                            <Progress value={stats.formatted_score.replace('%', '')} >  </Progress>
                                        </Col>
                                        <Col className="text-right pl-3 pl-lg-0">
                                            {stats.formatted_score}
                                        </Col>
                                    </Row>
                                )}
                            </Card>
                        </Col>  
                    )}
                </Row>
            </BlockUi>
        );
    }
}

export default injectIntl(BookingMarketInsights)