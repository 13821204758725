import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { getAPI } from "../Base/API";
import EmailReservationChannelMapping from "./EmailReservationChannelMapping";
import EmailReservationChannelInstanceDetail from "./EmailReservationChannelInstanceDetail";


export class EmailReservationChannelConf extends Component {
	constructor(props) {
		super(props);
		this.getRates = this.getRates.bind(this);
		this.getChannelConfigState = this.getChannelConfigState.bind(this);
		this.state = {
			rateList: [],
			error: [],
			block: true,
			supported: [],
		};
	}

	componentDidMount() {
		this.getRates();
	}

	reloadChannelData = () => {
		this.setState({ blockReloadChannelData: true });

		getAPI(result => {
			const { data, error } = result;
			if (error) {
				var errorMessage = [];
				errorMessage.push({ message: error.message, stack: error.stack, messageType: "danger" });
				this.setState({ error: errorMessage, blockReloadChannelData: false }, this.getChannelConfigState);
				return;
			}
			if (data) {
				this.setState({ ratecodechannel: data.response ? data.response : [], blockReloadChannelData: false }, this.getChannelConfigState);
			}
		}, "/api/Rate/RateCodeChannel/v1/ChannelInstance/" + this.props.selectedChannel.id + "");
	}

	getRates() {
		const { selectedChannel } = this.props;
		this.setState({ block: true });
		getAPI(result => {
			const { data, error } = result;

			if (error) {
				var errorMessage = [];
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false }, this.reloadChannelData);
				return;
			}
			if (data) {
				data.response && data.response.forEach(rate => {
					rate.isDisabledToAdd = false;
					rate.isDisabledToEdit = false;
					return rate;
				});
				this.setState({ rateList: data.response, block: false }, this.reloadChannelData);
			}
			else {
				this.setState({ block: false }, this.reloadChannelData);
			}
		}, '/api/Rate/Rate/v1/RateMapping/' + selectedChannel.id);
	}

	getChannelConfigState() {
		getAPI(result => {
			const { data, error, isLoading } = result;
			this.setState({ blocking: isLoading });
			const errorMessage = [];
			if (error) {
				errorMessage.push({
					message: error.message,
					stack: error.stack,
					messageType: "danger"
				});
				this.setState({ error: errorMessage, blocking: false });
				return;
			}
			if (data && data.response && data.response.channelConfig) {
				this.setState({ supported: data.response.channelConfig.supported });
			}
			else {
				this.setState({ error: errorMessage, blocking: false });
			}
		}, `/api/Rate/ChannelConfig/v1/ChannelConfigState/${this.props.selectedChannel.channelConfigId}`);
	}

	render() {
		const { selectedChannel, user } = this.props;
		const { supported, ratecodechannel, rateList } = this.state;

		return (
			<div>
				<EmailReservationChannelInstanceDetail
					selectedChannel={selectedChannel}
					user={user}
					supportedData={supported}
				/>
				<EmailReservationChannelMapping
					ratecodechannel={ratecodechannel}
					rateList={rateList}
				/>
			</div>
		);
	}
}

export default injectIntl(EmailReservationChannelConf)