import React, { Component } from 'react';
import { injectIntl } from "react-intl";
import { Row, Col, Badge, Button } from 'reactstrap';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { handleNotification } from "./Base/Notification";
import { getAPI, postAPI } from "./Base/API";
import BlockUi from 'react-block-ui';
import moment from 'moment';

export class UpgradePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            block: false,
            modal: false,
            requestedUpdate: false,
            requestedAt: null
        }
    }


    componentDidMount() {
        this.getRequests();
    }

    getRequests = () => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data.response) {
                data.response.forEach(el => {
                    if (el.status === 'New' || el.status === 'Working') {
                        this.setState({ requestedUpdate: true, requestedAt: el.requestedAt });
                    }
                })
            }
            this.setState({ block: false });
        }, '/api/hotel/License/v1/upgradeAccountRequests');
    }

    createRequest = (e) => {
        e.preventDefault();
        this.setState({ block: true });
        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }

            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="UpgradePage.SuccessfulUpdateRequest" />, <FormattedMessage id="General.Success" />);
                    this.getRequests();
                    this.setState({ block: false});
                }
            }
        }, '/api/hotel/License/v1/upgradeAccountRequest');
    }

    render() {
        return (
            <div className="m-4">
                <BlockUi tag="div" blocking={this.state.block}>
                    <Row className="my-4">
                        <Col className="text-center">
                            <h3><FormattedMessage id="UpgradePage.UpgradeAccount"/></h3>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col>
                            <p className="lead">
                                <FormattedMessage id="UpgradePage.GainAccessTo" />
                            </p>
                            <p className="lead">
                                <FormattedMessage id="UpgradePage.BoostYourReservations" />
                            </p>
                        </Col>
                    </Row>

                    <hr/>
                    <Row className="my-3">
                        <Col>
                            <Row>
                                <Col className="col-1">
                                    <Badge className="bg-host p-2"><i className="fas fa-2x fa-percentage px-1"></i></Badge>
                                </Col>
                                <Col>
                                    <h5><FormattedMessage id="UpgradePage.Promotions" /></h5>
                                    <div className="text-muted"><FormattedHTMLMessage id="UpgradePage.PromotionsText" /></div>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col className="col-1">
                                    <Badge className="bg-host p-2"><i className="far fa-2x fa-comment"></i></Badge>
                                </Col>
                                <Col>
                                    <h5><FormattedMessage id="UpgradePage.Messaging" /></h5>
                                    <div className="text-muted"> <FormattedMessage id="UpgradePage.ImproveCommunication" /></div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col>
                            <Row>
                                <Col className="col-1">
                                    <Badge className="bg-host p-2"><i className="fas fa-2x fa-list-ol"></i></Badge>
                                </Col>
                                <Col>
                                    <h5><FormattedMessage id="UpgradePage.Rules" /></h5>
                                    <p className="text-muted"><FormattedMessage id="UpgradePage.CreateAutomaticRules" /></p>
                                    <p className="text-muted"><FormattedHTMLMessage id="UpgradePage.CloseSales" /></p>
                                    <p className="text-muted"><FormattedHTMLMessage id="UpgradePage.RestrictAvailability" /> </p>
                                    <p className="text-muted"><FormattedHTMLMessage id="UpgradePage.ChangePrice" /> </p>
                                    <p className="text-muted"><FormattedHTMLMessage id="UpgradePage.CloseBasedOnReleaseDays" /> </p>
                                    <p className="text-muted"><FormattedHTMLMessage id="UpgradePage.PriceOverride" /> </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col className="col-1">
                                    <Badge className="bg-host p-2"><i className="fas fa-2x fa-medal"></i></Badge>
                                </Col>
                                <Col>
                                    <h5><FormattedMessage id="UpgradePage.Reviews" /></h5>
                                    <p className="text-muted"><FormattedMessage id="UpgradePage.CollectOpinions" /> </p>
                                    <p className="text-muted"><FormattedMessage id="UpgradePage.GetAnOverview" /> </p>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col className="col-1">
                                    <Badge className="bg-host p-2"><i className="fas fa-2x fa-star"></i></Badge>
                                </Col>
                                <Col>
                                    <h5><FormattedMessage id="UpgradePage.PropertyScore" /></h5>
                                    <div className="text-muted"><FormattedMessage id="UpgradePage.StayAhead" /></div>
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col className="col-1">
                                    <Badge className="bg-host p-2"><i className="fas fa-2x fa-bed "></i></Badge>
                                </Col>
                                <Col>
                                    <h5><FormattedHTMLMessage id="UpgradePage.ManualReservations" /></h5>
                                    <p className="text-muted"><FormattedHTMLMessage id="UpgradePage.ManualReservationsText" /></p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="my-3">
                        <Col>
                            <Row>
                                <Col className="col-1">
                                    <Badge className="bg-host p-2"><i className="far fa-2x fa-check-circle"></i></Badge>
                                </Col>
                                <Col>
                                    <h5><FormattedHTMLMessage id="UpgradePage.Opportunities" /></h5>
                                    <p className="text-muted"><FormattedHTMLMessage id="UpgradePage.DataDrivenPerformance" /></p>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            {/*<Row>
                                <Col className="col-1">
                                    <Badge className="bg-host p-2"><i className="fas fa-2x fa-bed"></i></Badge>
                                </Col>
                                <Col>
                                    <h5><FormattedHTMLMessage id="UpgradePage.ReservationReporting" /></h5>
                                    <p className="text-muted"><FormattedHTMLMessage id="UpgradePage.BeMoreEffective" /></p>
                                </Col>
                            </Row>*/}
                        </Col>
                    </Row>
                    {/*<Row className="my-3">
                        <Col>
                            <Row>
                                <Col className="col-1">
                                    <Badge className="bg-host p-2"><i className="fas fa-2x fa-chart-line"></i></Badge>
                                </Col>
                                <Col>
                                    <h5><FormattedHTMLMessage id="UpgradePage.Revenue" /></h5>
                                    <p className="text-muted"><FormattedMessage id="UpgradePage.AnalyzeAndExport" /></p>
                                </Col>
                            </Row>
                        </Col>

                        <Col>
                        </Col>
                    </Row>*/}
                    <Row>
                        <Col className="text-center mt-2">
                            {this.state.requestedUpdate ?
                                <b> <FormattedMessage id="UpgradePage.RequestMade" /> {moment(this.state.requestedAt).format("DD-MM-YYYY")}  </b>
                                :
                                <Button type="submit" className="btn btn-sm btn-host" onClick={(e) => this.createRequest(e)}>
                                    <FormattedMessage id="UpgradePage.Upgrade" /><i className="fas fa-info-circle ml-2" />
                                </Button>
                            }
                        </Col>
                    </Row>
                </BlockUi>
            </div>
        );
    }
}

export default injectIntl(UpgradePage);