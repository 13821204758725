import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { StyledCard } from '../../../Base/Common/CommonUIComponents';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';
import BookingConnectionsActiveList from './BookingConnectionsActiveList';
import BookingConnectionsPendingList from './BookingConnectionsPendingList';
import BookingDisconnetedConnectionsList from './BookingDisconnetedConnectionsList';

export class BookingConnections extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1'
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { activeTab } = this.state;

        return (
            <StyledCard title="BookingConnections.Title">
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="BookingConnections.Active" />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                            <FormattedMessage id="BookingConnections.Pending" />
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink className={classnames({ active: activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                            <FormattedMessage id="BookingConnections.Disconnected" />
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <BookingConnectionsActiveList url={`/api/Rate/ConnectionManagement/v1/ConnectionRequest/active`} />
                    </TabPane>
                    <TabPane tabId="2">
                        <BookingConnectionsPendingList url={`/api/Rate/ConnectionManagement/v1/ConnectionRequest`} />
                    </TabPane>
                    <TabPane tabId="3">
                        <BookingDisconnetedConnectionsList url={`/api/Rate/ConnectionManagement/v1/ConnectionRequest/disconnection`} />
                    </TabPane>
                </TabContent>
            </StyledCard>
        );
    }
}