import React, { Component } from 'react';
import { Row, Col, CardBody } from 'reactstrap';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import CalendarBoxFlex from './CalendarBoxFlex';

export class CalendarBarFlex extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { currentDay, next14Days, action } = this.props;

        return (
            <div className="shadow">
                <CardBody className="card-header-gridview border-0 pb-0 ">
                    <Row className="d-flex flex-row flex-nowrap">
                        <Col className="col-3  position-sticky bg-white" style={{ zIndex: '1000' }}>
                            <div className="d-inline-block">
                                <button type="button" className="btn btn-link d-inline-block text-secondary" onClick={() => action(this, moment(currentDay).add(-14, 'day'))}>
                                    <span className="fas fa-angle-double-left ml-2" />
                                </button>
                                <div className="font-weight-bold text-secondary d-inline-block">
                                    <SingleDatePicker
                                        id="Date"
                                        isOutsideRange={day => day.isBefore(moment(), 'day') || day > moment().add(12, 'month')}
                                        date={currentDay}
                                        onDateChange={date => action(this, date)}
                                        focused={this.state.focused}
                                        onFocusChange={({ focused }) => this.setState({ focused })}
                                        small={true}
                                        numberOfMonths={1}
                                        required={true}
                                        noBorder
                                        block
                                        displayFormat="DD, MMMM, YYYY"
                                        showDefaultInputIcon={false}
                                    />
                                </div>
                                <button type="button" className="btn btn-link d-inline-block text-secondary px-0" onClick={() => action(this, moment(currentDay).add(14, 'day'))}>
                                    <span className="fas fa-angle-double-right ml-2" />
                                </button>
                            </div>
                        </Col>
                        <Col>
                            <Row className="flex-nowrap">
                                {
                                    next14Days.map((item, key) => <CalendarBoxFlex day={item} key={key} />)
                                }
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </div>    
        );
    }
}