import React, { Component } from 'react';
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import CustomSelect from '../../Base/Common/CustomSelect';
import { getAPI } from "../../Base/API"
import { ErrorAlert } from "../../Common/ErrorAlert";
import BlockUi from 'react-block-ui'
import { handleNotification } from "../../Base/Notification";
import { FormattedMessage } from 'react-intl';
import ApplyAirbnbOpportunity from './ApplyAirbnbOpportunity';
import { MobileFilters } from '../../Base/Common/MobileComponents';

export class AirbnbOportunityCard extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: true,
            modal: false,
            selectedConnection: '',
            channelinstanceList: [],
            opportunities: null,
            allOpportunities: [],
            selectedListings: '',
            listings: [],
            selectedOpportunity: null
        };
    }

    componentDidMount() {
        this.getChannelInstance();
    }

    getChannelInstance = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.length > 0) {
                var id = '';
                var list = data.filter(c => c.active);
                var channels = [];

                list && list.forEach(el => {
                    channels.push({ value: el.id, label: `${el.name} (${el.propertyCode})` })
                })

                if (channels.length > 0)
                    id = channels[0].value;

                this.setState({
                    channelinstanceList: channels,
                    selectedConnection: id,
                    block: false, error: errorMessage
                }, () => { this.getOpportunities(); this.getListings(); });
            }
            this.setState({ block: false });
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/Airbnb');
    }

    getOpportunities = (evt, next_cursor, changeConnection) => {
        if (evt) evt.preventDefault();
        
        if(!this.state.selectedConnection)
            return;
        
        this.setState({ block: true });

        var params = `?listingIds=${this.state.selectedListings}`;
        if (next_cursor) params += `&cursor=${next_cursor}`;

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.error && data.error.error_id) {
                    handleNotification(data);
                    this.setState({ opportunities: null, allOpportunities: [], block: false, error: errorMessage });
                    return;
                }

                const allOp = (changeConnection !== true || (changeConnection && (!this.state.allOpportunities || this.state.allOpportunities.length === 0))) && data.response && data.response.available_opportunities ?
                    this.state.allOpportunities.concat(data.response.available_opportunities) : this.state.allOpportunities;

                this.setState({
                    opportunities: data.response,
                    allOpportunities: allOp,
                    block: false,
                    error: errorMessage
                });
            }
            this.setState({ block: false });
        }, `/api/Rate/Oportunity/v1/channelInstance/${this.state.selectedConnection}/airbnb` + params);
    }

    getListings = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var list = [];

                if (data.response && data.response.listings) {
                    data.response.listings.forEach(el => {
                        list.push({ value: el.id, label: el.name});
                    })
                }

                this.setState({ listings: list });
            }
        }, `/api/Rate/Listing/v1/airbnb/${this.state.selectedConnection}/listingAll`);
    }

    changeChannelConnection = (combo) => {
        if (combo && this.state.selectedConnection !== combo.value) {
            this.setState({
                selectedConnection: combo ? combo.value : null,
                allOpportunities: [],
                block: true
            }, (e) => { this.getOpportunities(e, null, true); this.getListings(); });
        }
    }

    handleSelect = (combo) => {
        this.setState({
            selectedListings: combo ? combo.map(opt => opt.value).join(';') : ''
        });
    }

    toggleModal = (data) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedOpportunity: data ? data : null
        }))
    }

    renderMainFilter = () => {
        return(
            <CustomSelect
                icon={'fa fa-plug'}
                isSearchable
                placeholder={<FormattedMessage id="BookingPromotion.channelInstance" />}
                value={this.state.channelinstanceList.find(el => el.value === this.state.selectedConnection) || ''}
                options={this.state.channelinstanceList}
                onChange={(combo) => this.changeChannelConnection(combo)}
            />
        )
    }

    render() {
        const { opportunities, listings, allOpportunities, selectedOpportunity } = this.state;

        const Crt = ({ text, value }) => {
            return <Card className="shadow" body>
                <Row className="row no-gutters align-items-center">
                    <Col>
                        <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                            {text}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <h3 className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                            {value} %
                        </h3>
                    </Col>
                </Row>
            </Card>
        }

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <CardBody className="small">
                        {!global.isMobile ? 
                            <Row className="mb-3">
                                <Col className="col-2">
                                    <CustomSelect
                                        icon={'fa fa-plug'}
                                        isSearchable
                                        placeholder={<FormattedMessage id="BookingPromotion.channelInstance" />}
                                        value={this.state.channelinstanceList.find(el => el.value === this.state.selectedConnection) || ''}
                                        options={this.state.channelinstanceList}
                                        onChange={(combo) => this.changeChannelConnection(combo)}
                                    />
                                </Col>
                                <Col className="col-2 pl-1">
                                    <CustomSelect
                                        icon={'fas fa-filter'}
                                        options={listings}
                                        isMulti={true}
                                        onChange={this.handleSelect.bind(this)}
                                        placeholder={"Listings"}
                                    />
                                </Col>
                                <Col className="text-right">
                                    <Button color=" btn-host btn-sm" onClick={this.getOpportunities}>
                                        <i className="fas fa-search" />
                                    </Button>
                                </Col>
                            </Row>
                        :
                            <MobileFilters 
                                renderMainFilter={this.renderMainFilter}
                                doSearch={this.getOpportunities}
                            >
                                <CustomSelect
                                        icon={'fas fa-filter'}
                                        options={listings}
                                        isMulti={true}
                                        onChange={this.handleSelect.bind(this)}
                                        placeholder={"Listings"}
                                        className="mb-2"
                                    />
                            </MobileFilters>
                        }

                        {opportunities ?
                            <Row className="mb-3">
                                <Col sm={4} className={global.isMobile ? 'mb-3' : ''}>
                                    <Crt text={<FormattedMessage id="AirbnbOportunityCard.CurrentlyApplied" />} value={opportunities.current_host_completion_percentage} />
                                </Col>
                                <Col sm={4} className={global.isMobile ? 'mb-3' : ''}>
                                    <Crt text={<FormattedMessage id="AirbnbOportunityCard.AVGAirbnbHasCurrentlyApplied" />} value={opportunities.airbnb_host_completion_percentage} />
                                </Col>
                                <Col sm={4}>
                                    <Crt text={<FormattedMessage id="AirbnbOportunityCard.AVGYourAreaHaveCurrentlyApplied" />} value={opportunities.similar_host_completion_percentage} />
                                </Col>
                            </Row>
                        : ''}


                        <Row>
                            {allOpportunities.map((item, key) =>
                                <Col key={key} className="col-lg-4 col-12 pb-3">
                                    <Card key={key} className="border-left-primary shadow h-100 p-0">
                                        <CardBody className="d-flex flex-column justify-content-between py-3">
                                            <div>
                                                <Row className="mb-2">
                                                    <Col className="text-secondary"><strong>{item.title}</strong></Col>
                                                </Row>

                                                <Row>
                                                    <Col> {item.description} </Col>
                                                </Row>
                                            </div>

                                            <Row className={global.isMobile ? 'mt-2' : ''}>
                                                <Col className="d-flex align-items-center">
                                                    <a href={"https://" + item.activation_url} target="_blank" ><FormattedMessage id="RuleConfig.Activate" /> </a>
                                                </Col>
                                                {item.activation_modes && item.activation_modes.includes('API') ?
                                                    <Col className="text-right col-lg-2 col-md-3 col-6">
                                                        <Button color=" btn-host btn-sm" onClick={() => this.toggleModal(item)}>
                                                            <FormattedMessage id="AirbnbOportunityCard.Apply" />
                                                        </Button>
                                                    </Col>
                                                : ''}
                                            </Row>
                                        </CardBody>

                                    </Card>
                                </Col>
                            )}
                        </Row>

                        {opportunities && opportunities.next_cursor ?
                            <Row>
                                <Col className="text-center">
                                    <Button color=" btn-host btn-sm" onClick={(e) => this.getOpportunities(e, opportunities.next_cursor)}>
                                        <FormattedMessage id="AirbnbOportunityCard.LoadMore" />
                                    </Button>
                                </Col>
                            </Row>
                        : ''}

                    </CardBody>

                    {this.state.modal ?
                        <ApplyAirbnbOpportunity
                            modal={this.state.modal}
                            toggleModal={this.toggleModal}
                            selectedOpportunity={selectedOpportunity}
                            listings={this.state.listings}
                            channelInstanceId={this.state.selectedConnection}
                            getOpportunities={this.getOpportunities}
                        />
                    :''}
                </BlockUi>
            </div>
        );
    }
}