import React, { Component } from 'react';
import { Row, Col, Input, InputGroup, FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';



export class HotelMediaForm extends Component {
   


    render() {
        return (
            <div>
                <Row>
                    <Col className="col-12">
                        <FormGroup row >
                            <Label for="webSite" sm={2}> <FormattedMessage id="HotelMediaForm.WebSite" /> </Label>
                            <Col sm={10}>
                                <InputGroup size="sm">

                                    <Input name="webSite" placeholder="WebSite" readOnly  defaultValue={this.props.hoteldata.webSite} />

                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                
            </div>
        );
    }
}