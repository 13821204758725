import React, { Component, createRef } from 'react'
import { Button, CardBody, Col, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { FormattedMessage } from 'react-intl';
import ImageGallery from 'react-image-gallery';
import { handleNotification } from '../../Base/Notification';
import { deleteAPI } from '../../Base/API';
import CustomToolTip from '../../../Utils/CustomToolTip';

export default class ExperienceImageModal extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            error: null,
            selectedImage: this.props.selectedImage,
            imagePreviewUrl: null,
            file: null,
            images: [],
            currImgIdx: 0
        }
    }

    validateField(fieldId, message) {
        const field = document.getElementById(fieldId);
        if (field) {
            field.setCustomValidity(this.props.intl.formatMessage({ id: message }));
            field.reportValidity();
        }
    }
    
    clearFieldValidity(fieldId) {
        const field = document.getElementById(fieldId);
        if (field) {
            field.setCustomValidity("");
            field.reportValidity();
        }
    }

    saveImage = (e, deleteImage) => {
        if(e) e.preventDefault();
        const { file, selectedImage, imagePreviewUrl, images } = this.state;
        if (images && images.length > 0) {
            const invalidImage = images.find(i => !i.description || !i.order);
            
            if (invalidImage) {
                const invalidIndex = images.findIndex(i => !i.description || !i.order);
                if(invalidIndex < 0) invalidIndex = this.state.images.length - 1;
                if(invalidIndex >= this.state.images.length) invalidIndex = 0;
                this.setState({ currImgIdx: invalidIndex, imagePreviewUrl: this.state.images[invalidIndex]?.imagePreviewUrl, file: this.state.images[invalidIndex]?.file, selectedImage: this.state.images[invalidIndex]}, () => {
                    
                    if (!invalidImage.order) {
                        this.validateField(`order-${invalidIndex}`, "Experiences.FillField");
                    } else {
                        this.clearFieldValidity(`order-${invalidIndex}`);
                    }

                    if (!invalidImage.description) {
                        this.validateField(`description-${invalidIndex}`, "Experiences.FillField");
                    } else {
                        this.clearFieldValidity(`description-${invalidIndex}`);
                    }
                });
    
                return;
            }
    
            // Clear custom validity messages if all fields are valid
            images.forEach((image, index) => {
                this.clearFieldValidity(`description-${index}`);
                this.clearFieldValidity(`order-${index}`);
            });
        }

        if(images && images.length > 1){
            if(selectedImage?.id){
                img = selectedImage;
                this.props.toggle(img, deleteImage);
            }else{  
                this.props.addImagesBulk(images);
            }
        }else{
            var img = null;
            if(selectedImage?.id){
                img = selectedImage;
            }else{
                img = {'order': selectedImage.order, 'description': selectedImage.description, 'file': file ?? selectedImage.file, 'imagePreviewUrl': imagePreviewUrl ?? selectedImage.imagePreviewUrl};
            }
            this.props.toggle(img, deleteImage);
        }
    }

    deleteImage = () => {
        if(this.state.selectedImage && this.state.selectedImage.id){
            this.setState({ block: true }, () => {
                
                deleteAPI(result => {
                    const { data, error } = result;
                    var errorMessage = []
                    if (error) {
        
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
        
                        this.setState({ errorModal: errorMessage, block: false });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState(({ block: false, error: errorMessage }));
                        }
                        else {
                            handleNotification(data, <FormattedMessage id="ComplexPhotos.PhotoDeleted" />, <FormattedMessage id="General.Success" />);
                            if(this.state.selectedImage && this.state.selectedImage.size && !isNaN(this.state.selectedImage.size)){
                                this.props.handleChangeImageGallerySize(this.props.totalImagesSizes - this.state.selectedImage.size);
                            }
                            this.setState({ errorModal: errorMessage, block: false }, () => this.saveImage(null, true));
                        }
                    }
                },`/api/hotel/Experience/v1/${this.props.experienceId}/${this.state.selectedImage.id}/delete`);
            });
        }else{
            handleNotification(this.state.selectedImage, <FormattedMessage id="ComplexPhotos.PhotoDeleted" />, <FormattedMessage id="General.Success" />);
            if(this.state.selectedImage && this.state.selectedImage.file && this.state.selectedImage.file.size && !isNaN(this.state.selectedImage.file.size)){
                this.props.handleChangeImageGallerySize(this.props.totalImagesSizes - this.state.selectedImage.file.size);
            }
            this.saveImage(null, true);
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        let { images, selectedImage, currImgIdx } = this.state;
    
        const updatedImages = [...images];
        const updatedSelectedImage = { ...selectedImage, [name]: value };
        if (updatedImages[currImgIdx]) {
            updatedImages[currImgIdx] = {
                ...updatedImages[currImgIdx],
                [name]: value
            };
        } else {
            updatedImages.push(updatedSelectedImage);
        }
    
        this.setState({
            selectedImage: updatedSelectedImage,
            images: updatedImages
        });
    }

    handleImageChange = (e) => {
        let { images } = this.state;
        const { selectedImage } = this.state;
        e.preventDefault();

        let files = Array.from(e.target.files);
        let file = files[0];
        if(files && files.length > 0){
            images = [];
            if(files.some(f => f.size > 2097152)){ //2MB
                document.getElementById('image').value = "";
                const data = { warnings: [{ message: <FormattedMessage id="BookingChatWindow.PleaseSelectAFileLessThan2MB" /> }] };
                handleNotification(data);
                this.setState({
                    file: null,
                    imagePreviewUrl: null,
                    images: []
                });
                return;
            }
            if(files.length > 1){
                let completedReads = 0;

                files.forEach(f => {
                    let reader = new FileReader();
                    reader.onloadend = () => {
                        const fileIdx = images.findIndex(i => i.file === f);
                        if (fileIdx !== -1) {
                            images[fileIdx].file = f;
                            images[fileIdx].imagePreviewUrl = reader.result;
                        } else {
                            images.push({ file: f, imagePreviewUrl: reader.result, description: selectedImage?.description, order: selectedImage?.order});
                        }

                        completedReads += 1;
                        if (completedReads === files.length) {
                            this.setState({ images });
                        }
                    };
                    reader.readAsDataURL(f);
                });
            }else{
                if (file) {
                    let reader = new FileReader();

                    reader.onloadend = () => {
                        this.setState({
                            file: file,
                            imagePreviewUrl: reader.result,
                            images: [{ file: file, imagePreviewUrl: reader.result, description: selectedImage?.description, order: selectedImage?.order}],
                            selectedImage: { file: file, imagePreviewUrl: null, description: selectedImage?.description, order: selectedImage?.order},
                            currImgIdx: 0
                        });
                    }

                    if (file) {
                        reader.readAsDataURL(file);
                    }
        
                    this.props.handleChangeImageGallerySize(file.size + this.props.totalImagesSizes);
                }
                else {
                    document.getElementById('image').value = "";
                    this.setState({
                        file: null,
                        imagePreviewUrl: null,
                        images: []
                    });
                }
            }
        }
    }

    changeImgIdx = (idx) => {
        if(idx < 0) idx = this.state.images.length - 1;
        if(idx >= this.state.images.length) idx = 0;
        this.setState({ currImgIdx: idx, imagePreviewUrl: this.state.images[idx]?.imagePreviewUrl, file: this.state.images[idx]?.file, selectedImage: this.state.images[idx]});
    }

    renderLeftNav = () => {
        return (
            <button
                type="button"
                className="image-gallery-left-nav"
                onClick={() => {
                    this.changeImgIdx(this.state.currImgIdx - 1);
                }}
            >
            </button>
        );
    }

    renderRightNav = () => {
        return (
            <button
                type="button"
                className="image-gallery-right-nav"
                onClick={() => {
                    this.changeImgIdx(this.state.currImgIdx + 1);
                }}
            >
            </button>
        );
    }

    render() {
        const { toggle } = this.props;
        const { block, error, selectedImage, imagePreviewUrl, currImgIdx, images } = this.state;
        
        return (
            <Modal isOpen={true} toggle={() => toggle(null)}>
                <div ref={this.form}>
                    <ModalHeader toggle={() => toggle(null)}>
                        <FormattedMessage id="Experiences.ManagePhotos"/>
                    </ModalHeader>
                    <ModalBody>
                        <StyledCard icon="fas fa-upload" title="ComplexPhotos.CreateUpdatePhoto" block={block} error={error} className="bg-white small">
                            <CardBody>
                                <Row>
                                    <Col>
                                        <Label>
                                            <FormattedMessage id='Experiences.Description'/>
                                        </Label>
                                        <Input
                                            required={true}
                                            type="text"
                                            placeholder={''}
                                            value={images && images[currImgIdx] && images[currImgIdx].description ? images[currImgIdx].description : selectedImage?.description ?? ''}
                                            onChange={this.handleChange}
                                            maxLength='50'
                                            name="description"
                                            id={`description-${currImgIdx}`}
                                        />
                                    </Col>
                                    <Col>
                                        <Label>
                                            <FormattedMessage id='Experiences.Order'/>
                                        </Label>
                                        <Input
                                            required={true}
                                            type="number"
                                            placeholder={''}
                                            value={images && images[currImgIdx] && images[currImgIdx].order ? images[currImgIdx].order : selectedImage?.order ?? ''}
                                            onChange={this.handleChange}
                                            min="0"
                                            max='99'
                                            name="order"
                                            id={`order-${currImgIdx}`}
                                        />
                                    </Col>
                                </Row>
                                <div className="mt-2">
                                    <div className='mb-2'>
                                        <FormattedMessage id='Experiences.Image'/>
                                    </div>
                                    {(selectedImage?.imagePreviewUrl || selectedImage?.id) && (images && (images.length === 0 || images.length === 1)) ? 
                                        <div className='d-flex justify-content-center'>
                                            <div style={{ maxWidth: '70%', minWidth: '65%' }}>
                                                <ImageGallery
                                                    showFullscreenButton={false}
                                                    showPlayButton={false}
                                                    showBullets={false}
                                                    showThumbnails={false}
                                                    items={[{ original: selectedImage.url ?? selectedImage.imagePreviewUrl, alt: selectedImage.description, thumbnail: selectedImage.url ?? selectedImage.imagePreviewUrl }]}
                                                />
                                            </div>
                                        </div>
                                    :
                                        <>
                                            <div className='mb-2'>
                                                <Input type="file" multiple required={true} name="image" id="image" accept="image/*" onChange={this.handleImageChange}/>
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                {images && images.length > 1 ?
                                                        <div className='d-flex align-items-center justify-content-center w-100'>
                                                            <div className='d-flex align-items-center justify-content-between' style={{ maxWidth: '70%', minWidth: '65%' }}>
                                                                {this.renderLeftNav()}
                                                                <img src={images[currImgIdx]?.imagePreviewUrl} alt={images[currImgIdx]?.description} style={{ width: '100%', height: 'auto' }} />
                                                                {this.renderRightNav()}
                                                            </div>
                                                        </div>
                                                :
                                                imagePreviewUrl ?
                                                        <div style={{ maxWidth: '70%', minWidth: '65%' }}>
                                                            <ImageGallery
                                                                showFullscreenButton={false}
                                                                showPlayButton={false}
                                                                showBullets={false}
                                                                showThumbnails={false}
                                                                items={[{ original: imagePreviewUrl, alt: selectedImage?.description, thumbnail: imagePreviewUrl }]}
                                                            />
                                                        </div>
                                                :''}
                                            </div>
                                        </>
                                    }
                                </div>
                            </CardBody>
                        </StyledCard>
                    </ModalBody>
                    <ModalFooter>
                        {(selectedImage?.imagePreviewUrl || selectedImage?.id) && (images && (images.length === 0 || images.length === 1)) ?
                            <Button color="primary btn-host" onClick={this.deleteImage}> <i className="fas fa-trash-alt"></i> </Button>
                        : ''}

                        <Button 
                            color="primary btn-host" 
                            type="submit" 
                            id={'saveImgsButton'}
                            onClick={this.saveImage}
                        >
                            <i className="fas fa-save"></i> 
                        </Button>
                        {(!selectedImage?.description) || (!selectedImage?.order) || images && images.some(i => (!i.description) || (!i.order)) ?
                            <CustomToolTip placement="top" target={'saveImgsButton'} >
                                <FormattedMessage id='Experiences.SaveImageToolTip' />
                            </CustomToolTip>
                        :''}
                        <Button 
                            color="secondary" 
                            onClick={() => toggle(null)}
                        > 
                            <FormattedMessage id="ComplexPhotos.Cancel" /> 
                        </Button>
                    </ModalFooter>
                </div>
            </Modal>
        )
    }
}