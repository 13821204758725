import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Button } from 'reactstrap';
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl';
import { StyledCard, ActiveInactiveStatusBadge } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Notification";
import { getAPI } from "../../Base/API";
import moment from 'moment';


class PromoCodes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            block: true,
            promoCodes: [],
            rateList: []
        }
    }

    componentDidMount() {
        this.getPromoCodes();
        this.getRates();
    }

    getPromoCodes = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ promoCodes: data.response });
            }
            this.setState(({ block: false }));
        }, `/api/Rate/Promotion/v1/promoCode`);
    }

    getRates = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    let list = [];
                    data.response.forEach((item) =>
                        list.push({ value: item.id, label: item.name })
                    )
                    this.setState({ block: false, rateList: list });
                }
                this.setState(({ block: false }));
            }
        }, '/api/Rate/Rate/v1/Info');
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    render() {
        const { promoCodes, rateList } = this.state;

        return (
            <div>
                <StyledCard block={this.state.block} error={this.state.error} icon={'fas f a-percent'} title={'navbar.PromoCodes'} help={<FormattedHTMLMessage id="PromoCodes.Help" />} id='PromoCodes'>
                    <Row className="mb-4">
                        <Col className="text-right">
                            <Button className="btn btn-host btn-sm text-white" onClick={() => { this.props.fromBEConfigDashboard ? this.props.toggleDetails('showPromoCodeDetails', 'promoCodeId', null) : this.props.history.push('/PromoCodeDetails') }} >
                                <i className="fas fa-plus"></i>
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        {promoCodes ? promoCodes.map((code, key) =>
                            <Col key={key} className="col-6 mb-3">
                                <Card className="border-0 shadow pointer h-100" onClick={() => { this.props.fromBEConfigDashboard ? this.props.toggleDetails('showPromoCodeDetails', 'promoCodeId', code.id) : this.props.history.push('/PromoCodeDetails/' + code.id) }}>
                                    <CardHeader className="border-bottom-1 border-host ">
                                        <Row>
                                            <Col className={global.isMobile ? 'col-7' : ''}>
                                                 <h6 className={global.isMobile ? 'text-truncate w-100' : ''}><i className="fa-xs fas fa-percent" /> {code.code} </h6>
                                            </Col>
                                            <Col className="col-lg-2 col-5 text-right">
                                                <ActiveInactiveStatusBadge status={code.active} />
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody className={global.isMobile ? 'verticalScroll' : ''} style={{maxHeight: global.isMobile ? '160px' : '', overflow: global.isMobile ? 'auto' : ''}}>
                                        <span><FormattedMessage id="PromoCodes.ValidTo" values={{ start: moment(code.bookDateStart).format('YYYY-MM-DD'), end: moment(code.bookDateEnd).format('YYYY-MM-DD') }} /> </span>

                                        {code.rateIds ?
                                            <>
                                                <h6 className="mt-3"> Rates </h6>
                                                <hr className="m-0" />
                                                {code.rateIds.map((rateId, key2) => {
                                                    const rate = rateList.find(r => r.value === rateId);

                                                    return rate ?
                                                        <Row key={key2} className="py-1">
                                                            <Col>
                                                                <i className="fas fa-gift mr-2" />{rate.label}
                                                            </Col>
                                                        </Row>
                                                    : ''
                                                })}
                                            </>
                                        : ''}
                                    </CardBody>
                                </Card>
                            </Col>
                        ) : <Col className='d-flex align-items-center justify-content-center'>There is no promo codes</Col >}
                    </Row>

                </StyledCard>
            </div>
        );
    }
}

export default injectIntl(PromoCodes)