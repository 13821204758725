import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { Button, CardBody, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { postAPI } from "../../Base/API";
import { ErrorAlert } from "../../Common/ErrorAlert";
import { FormattedMessage } from 'react-intl';
import CustomToolTip from '../../../Utils/CustomToolTip';

export class PromotionSync extends Component {
    constructor(props) {
        super(props);
        this.submitSync = this.submitSync.bind(this);
        this.state = {
            block: false,
            Alerts: [],
            channelinstanceList: this.props.channelInstances,
            selectedConnection: this.props.channelInstances && this.props.channelInstances.length > 0 ? this.props.channelInstances[0].id : null
        };
    }

    changeChannelConnection(evt) {
        this.setState({
            selectedConnection: evt.target.value
        });
    }

    submitSync() {
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                errorMessage.push({ message: <FormattedMessage id="PromotionSync.PromotionSyncSuccessful" />, stack: null, messageType: 'success' });
                this.setState({ block: false, error: errorMessage });
            }
        }, '/api/Price/Promotion/v1/sync/' + this.state.selectedConnection);

    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <CardBody className="small">
                    <Row>
                        <Col className={global.isMobile ? 'col-12 pb-2' : 'col-8'}>
                            <FormattedMessage id="PromotionSync.SyncPromotionWithBookingPropertyCode" />:
                        </Col>
                        <Col className="col-lg-3 col-10">
                            <InputGroup size="sm text-right" >
                                <InputGroupAddon addonType="prepend">
                                    <span className="input-group-text">
                                        <span className="fa fa-plug fa-fw"/>
                                    </span>
                                </InputGroupAddon>
                                <Input type="select" name="bconnection" id="bconnection" value={this.state.selectedConnection} onChange={this.changeChannelConnection.bind(this)}>
                                    {this.state.channelinstanceList.map((item, key) => <option key={key} value={item.id} > {item.name} ( {item.propertyCode} )</option>)}
                                </ Input>
                            </InputGroup>
                        </Col>
                        <Col className={!global.isMobile ? "col-lg-1" : "col-2 d-flex align-items-center"}>
                            <Button className="btn-host btn-sm mr-1 shadow float-right" id="syncpromotiontooptip" disabled={!this.state.selectedConnection}>
                                <i className="fas fa-sync" onClick={this.submitSync} />
                            </Button>
                            <CustomToolTip placement="bottom" target="syncpromotiontooptip">
                                <FormattedMessage id="PromotionSync.GetPromotionsFromChannel" />
                            </CustomToolTip>                            
                        </Col>
                    </Row>
                </CardBody>           
            </BlockUi>
        );
    }
}