import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button, Form, Input } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import { ErrorAlert } from "../../Common/ErrorAlert";
import { deleteAPI, getAPI } from "../../Base/API";
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';
import BlockUi from 'react-block-ui';

class CancelAirbnbResModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            reasonOptions: [
                {
                    value: 'DECLINE_REASON_HOST_DOUBLE',
                    label: <FormattedMessage id="CancelAirbnbResModal.Double" />,
                    requiredMsgToAirbnb: false,
                    subReasons: [
                        {
                            value: 'DECLINE_REASON_HOST_EMERGENCY',
                            label: <FormattedMessage id="CancelAirbnbResModal.EMERGENCY" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_HOST_UNAVAILABLE',
                            label: <FormattedMessage id="CancelAirbnbResModal.HOST_UNAVAILABLE" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_DOUBLE_BOOKED',
                            label: <FormattedMessage id="CancelAirbnbResModal.DOUBLE_BOOKED" />,
                        }
                    ]
                },
                {
                    value: 'DECLINE_REASON_HOST_CHANGE',
                    label: <FormattedMessage id="CancelAirbnbResModal.Change" />,
                    requiredMsgToAirbnb: false,
                    subReasons: [
                        {
                            value: 'DECLINE_REASON_HOST_RESERVATION_LENGTH',
                            label: <FormattedMessage id="CancelAirbnbResModal.RESERVATION_LENGTH" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_DIFFERENT_PRICE',
                            label: <FormattedMessage id="CancelAirbnbResModal.DIFFERENT_PRICE" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_CHANGE_RESERVATION',
                            label: <FormattedMessage id="CancelAirbnbResModal.CHANGE_RESERVATION" />,
                        }
                    ]
                },
                {
                    value: 'DECLINE_REASON_HOST_BAD_REVIEWS_SPARSE_PROFILE',
                    label: <FormattedMessage id="CancelAirbnbResModal.BadReviews" />,
                    requiredMsgToAirbnb: true,
                    description: [{ value: 0, label: <FormattedMessage id="CancelAirbnbResModal.BadReviewsDescription" /> }],
                    subReasons: []
                },
                {
                    value: 'DECLINE_REASON_COVID19_HOST',
                    label: <FormattedMessage id="CancelAirbnbResModal.Covid19" />,
                    requiredMsgToAirbnb: false,
                    description: [{ value: 0, label: <FormattedMessage id="CancelAirbnbResModal.Covid19Description" /> }],
                    subReasons: []
                },
                {
                    value: 'DECLINE_REASON_HOST_UNAUTHORIZED_PARTY',
                    label: <FormattedMessage id="CancelAirbnbResModal.UnauthorizedParty" />,
                    requiredMsgToAirbnb: true,
                    subReasons: [
                        {
                            value: 'DECLINE_REASON_HOST_PARTY_REVIEWS',
                            label: <FormattedMessage id="CancelAirbnbResModal.PARTY_REVIEWS" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_PARTY_INDICATION',
                            label: <FormattedMessage id="CancelAirbnbResModal.PARTY_INDICATION" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_PARTY_UNRESPONSIVE',
                            label: <FormattedMessage id="CancelAirbnbResModal.PARTY_UNRESPONSIVE" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_PARTY_AD',
                            label: <FormattedMessage id="CancelAirbnbResModal.PARTY_AD" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_PARTY_OTHER',
                            label: <FormattedMessage id="CancelAirbnbResModal.PARTY_OTHER" />,
                        }
                    ]
                },
                {
                    value: 'DECLINE_REASON_HOST_BEHAVIOR',
                    label: <FormattedMessage id="CancelAirbnbResModal.Behavior" />,
                    requiredMsgToAirbnb: true,
                    subReasons: [
                        {
                            value: 'DECLINE_REASON_HOST_BEHAVIOR_REVIEWS',
                            label: <FormattedMessage id="CancelAirbnbResModal.BEHAVIOR_REVIEWS" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_BEHAVIOR_INDICATION',
                            label: <FormattedMessage id="CancelAirbnbResModal.BEHAVIOR_INDICATION" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_BEHAVIOR_UNRESPONSIVE',
                            label: <FormattedMessage id="CancelAirbnbResModal.BEHAVIOR_UNRESPONSIVE" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_BEHAVIOR_OTHER',
                            label: <FormattedMessage id="CancelAirbnbResModal.BEHAVIOR_OTHER" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_GUEST_PROFILE',
                            label: <FormattedMessage id="CancelAirbnbResModal.GUEST_PROFILE" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_NOT_FIT',
                            label: <FormattedMessage id="CancelAirbnbResModal.NOT_FIT" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_OTHER_CONCERN',
                            label: <FormattedMessage id="CancelAirbnbResModal.OTHER_CONCERN" />,
                        }
                    ]
                },
                {
                    value: 'DECLINE_REASON_HOST_ASKED',
                    label: <FormattedMessage id="CancelAirbnbResModal.Asked" />,
                    requiredMsgToAirbnb: false,
                    subReasons: [
                        {
                            value: 'DECLINE_REASON_HOST_GUEST_WRONG_BOOKING',
                            label: <FormattedMessage id="CancelAirbnbResModal.WRONG_BOOKING" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_GUEST_PERSONAL_REASON',
                            label: <FormattedMessage id="CancelAirbnbResModal.PERSONAL_REASON" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_GUEST_EXTENUATING_REASON',
                            label: <FormattedMessage id="CancelAirbnbResModal.EXTENUATING_REASON" />,
                        },
                        {
                            value: 'DECLINE_REASON_HOST_GUEST_OTHER_REASON',
                            label: <FormattedMessage id="CancelAirbnbResModal.OTHER_REASON" />,
                        }
                    ]
                },
                {
                    value: 'DECLINE_REASON_HOST_GUEST_MUTUAL_CANCELLATION',
                    label: <FormattedMessage id="CancelAirbnbResModal.MutualCancellation" />,
                    description: [{ value: 0, label: <FormattedMessage id="CancelAirbnbResModal.MutualCancellationDescription" /> }],
                    requiredMsgToAirbnb: false,
                    subReasons: []
                },
                {
                    value: 'DECLINE_REASON_HOST_OTHER',
                    label: <FormattedMessage id="CancelAirbnbResModal.Other" />,
                    description: [{ value: 0, label: <FormattedMessage id="CancelAirbnbResModal.OtherDescription" /> }],
                    requiredMsgToAirbnb: true,
                    subReasons: []
                }
            ],
            applicableReasons: []
        };
    }

    componentDidMount() {
        this.checkReservation();
    }

    checkReservation = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.props.toggle('cancelAirbnbResModal', null);
                }
                else {
                    if (data.cancellation_by_host && data.cancellation_by_host.applicable_reasons) {
                        var applicableReasons = [];

                        data.cancellation_by_host.applicable_reasons.forEach(el => {
                            let reason = this.state.reasonOptions.find(ro => ro.value === el.reason);
                            reason.subReasons = reason.subReasons.filter(sr => el.sub_reasons.some(subR => subR === sr.value) === true);
                        })

                        this.setState(({ applicableReasons }));
                    }
                    else {
                        handleNotification({ errors: [{ message: <FormattedMessage id="CancelAirbnbResModal.CantCancelAirbnbRes" /> }]});
                        this.props.toggle('cancelAirbnbResModal', null);
                    }
                }
                this.setState(({ block: false }));
            }
        }, `/api/Rate/Reservation/v1/reservation/${this.props.reservationId}/airbnb/retrieve`);
    }

    cancelReservation = (e) => {
        e.preventDefault();
        //this.setState({ block: true });

        //deleteAPI(result => {
        //    const { data, error } = result;
        //    var errorMessage = [];

        //    if (error) {
        //        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
        //        this.setState({ error: errorMessage, block: false });

        //        return;
        //    }
        //    if (data) {
        //        if (data.errors && data.errors.length > 0) {
        //            handleNotification(data);
        //        }
        //        else {
        //            handleNotification(data, <FormattedMessage id="ConfirmCancellation.ReservationCanceled" />, <FormattedMessage id="General.Success" />);
        //            this.props.toggle('cancellationModal', null);
        //            this.props.updateReservationStatus('Canceled');
        //        }
        //        this.setState(({ block: false }));
        //    }
        //}, `/api/Rate/Reservation/v1/reservation/${this.props.reservationId}/airbnb/cancel/`);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });

        if (name === "reason") {
            this.setState({ subReason: null })
        }
    }

    handleChange = (evt) => {
        const { value, name } = evt.target;

        this.setState({
            [name]: value
        });
    }

    getSubReasons = (getOptions) => {
        let options = []

        if (this.state.reason) {
            const reason = this.state.applicableReasons.find(el => el.value === this.state.reason);

            if (reason.subReasons && reason.subReasons.length > 0) {
                options = reason.subReasons;

                return getOptions === true ? options : this.state.subReason ? options.find(el => el.value === this.state.subReason) : '';
            }
            else {
                options = reason.description;

                return getOptions === true ? options : options[0];
            }
        }

        return options;
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={() => this.props.toggle('cancelAirbnbResModal', null)} size="modal-xl" >
                <ModalHeader toggle={this.toggleModal}>
                    <FormattedMessage id="CancelAirbnbResModal.Title" />
                </ModalHeader>
                <ModalBody className="modal-xl">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.cancelReservation}>
                            <Row className="mb-2">
                                <Col className="col-3">
                                    <FormattedMessage id="CancelAirbnbResModal.Reason" />
                                </Col>
                                <Col>
                                    <CustomSelect
                                        placeholder={<FormattedMessage id="CancelAirbnbResModal.Reason" />}
                                        options={this.state.applicableReasons}
                                        required isSearchable
                                        onChange={this.handleSelect.bind(this, 'reason')}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3">
                                    <FormattedMessage id="CancelAirbnbResModal.SubReason" />
                                </Col>
                                <Col>
                                    <CustomSelect
                                        isClearable isSearchable
                                        placeholder={<FormattedMessage id="CancelAirbnbResModal.SubReason" />}
                                        required={this.state.reason ? this.state.applicableReasons.find(el => el.value === this.state.reason).requiredMsgToAirbnb : false}
                                        options={this.getSubReasons(true)}
                                        value={this.getSubReasons(false)}
                                        onChange={this.handleSelect.bind(this, 'subReason')}
                                        isDisabled={this.state.reason && this.state.applicableReasons.find(el => el.value === this.state.reason).subReasons.length > 0 ? false : true}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-3">
                                    <FormattedMessage id="CancelAirbnbResModal.MessageToGuest" />
                                </Col>
                                <Col>
                                    <Input
                                        type="text"
                                        placeholder={this.props.intl.formatMessage({ id:"CancelAirbnbResModal.MessageToGuest" })}
                                        required
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="col-3">
                                    <FormattedMessage id="CancelAirbnbResModal.MessageToAirbnb" />
                                </Col>
                                <Col>
                                    <Input
                                        type="text"
                                        placeholder={this.props.intl.formatMessage({ id:"CancelAirbnbResModal.MessageToAirbnb" })}
                                        required
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-right">
                                    <Button color="secondary" onClick={() => this.props.toggle('cancelAirbnbResModal', null)} className="mr-2">
                                        <FormattedMessage id="ReservationDetail.Close" />
                                    </Button>
                                    <Button color="secondary" type="submit" className="btn-host">
                                        <FormattedMessage id="ReservationDetail.Confirm" />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(CancelAirbnbResModal);