import React, { Component } from 'react';
import { Row, Col, CustomInput, Card, CardBody, CardHeader } from 'reactstrap';
import { getAPI } from "../../../Base/API"
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';

class AmenityType extends Component {   

    constructor(props) {
        super(props);
        this.state = {
            url: this.props.url,
            title: this.props.title,
            error: [],
            amenities: [],
            block: false
        };
    }

    componentDidMount() {
        if (this.state.url) {
            getAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ blocking: isLoading });
                if (error) {
                    const errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.length > 0) {
                    this.setState({ block: false, amenities: data }, () => this.sortAmenities());
                }
                else {
                    this.setState({ block: false });
                }
            }, this.state.url);
        }
    }

    sortAmenities = () => {
        const { amenities } = this.state
        const { intl } = this.props
        const ha = [];
        amenities.forEach(function (item) {
            ha.push({
                type: item.type, code: item.code, label: intl.formatMessage({ id: `Amenities.${item.type}.${item.code}` }), id: item.id
            });
        });
        ha.sort((a, b) => a.label.localeCompare(b.label))

        this.setState({amenities: ha})
    }

    render() {
        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Card className="border-0">
                        {this.state.url ?
                            <div>
                                <CardHeader className="border-bottom bg-white">{this.state.title}</CardHeader>
                                <CardBody>
                                    <Row>
                                        {
                                            this.state.amenities && this.state.amenities.map((item, key) =>
                                                <Col key={key} className="col-12 col-lg-4 col-xl-3 pb-2">
                                                    <CustomInput id={item.id} type="checkbox" checked={this.props.componentAmenities.includes(item.id)} onChange={this.props.onChange}>
                                                        <FormattedMessage id={`Amenities.${item.type}.${item.code}`}/>
                                                    </CustomInput>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </CardBody>
                            </div>
                            :
                            <CardHeader className="bg-white">{this.state.title}</CardHeader>
                        }
                    </Card>
                </BlockUi>
            </div>            
        )
    }
}

export default injectIntl(AmenityType)