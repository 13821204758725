import React, { Component } from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import { getAPI } from '../Base/API';
import { SelectHotel, StyledCard } from '../Base/Common/CommonUIComponents';
import { CustomTable } from '../Base/Common/CustomTable';
import CustomSelect from '../Base/Common/CustomSelect';
import countryList from "react-select-country-list";

export class AdminUserEmailReport extends Component {

    constructor(props) {
        super(props);
        this.getUserEmails = this.getUserEmails.bind(this);
        this.setHotel = this.setHotel.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.state = {
            block: false,
            data: [],
            hotelId: null,
            email: null,
            hasLicense: null,
            hotelActive: null,
            userActive: null,
            hotelCountry: null
        };
    }

    componentDidMount() {
        this.getUserEmails();
    }

    getUserEmails() {
        const { hotelId, email, hasLicense, hotelActive, userActive, hotelCountry } = this.state;

        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                this.setState({ data: data.response, error: errorMessage, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/User/v1/User/getUserEmailReport?hotelId=${hotelId !== null ? `${hotelId}` : ``}&email=${email !== null ? `${email}` : ``}&hasLicenses=${hasLicense !== null ? `${hasLicense}` : ``}&hotelActive=${hotelActive !== null ? `${hotelActive}` : ``}&userActive=${userActive !== null ? `${userActive}` : ``}&hotelCountry=${hotelCountry !== null ? `${hotelCountry}` : ``}`);
    }

    setHotel(_, combo) {
        this.setState({ hotelId: combo && combo.value });
    }

    handleTextChange(evt) {
        if (evt && evt.target) {
            const { name, value } = evt.target;
            this.setState({ [name]: value });
        }
    }

    setCombobox(name, combo) {
        this.setState({ [name]: combo && combo.value });
    }

    render() {
        const { block, error, data, hotelId, email, hasLicense, hotelActive, userActive } = this.state;

        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "AdminUserEmailReport.Name" }),
                sort: true,
                formatter: cell => cell ? cell : ``
            },
            {
                dataField: 'surname',
                text: this.props.intl.formatMessage({ id: "AdminUserEmailReport.Surname" }),
                sort: true,
                formatter: cell => cell ? cell : ``
            },
            {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "AdminUserEmailReport.Email" }),
                sort: true
            },
            {
                dataField: 'name2',
                text: this.props.intl.formatMessage({ id: "AdminUserEmailReport.HotelName" }),
                sort: true,
                formatter: (cell, row) => cell ? cell : row.hotelId
            },
            {
                dataField: 'hotelCountry',
                text: this.props.intl.formatMessage({ id: "AdminUserEmailReport.HotelCountry" }),
                sort: true,
                formatter: (cell) => cell ? <div><span className={' mb-1 mr-1 flag-icon flag-icon-' + cell.toLowerCase()} /> {cell} </div> : ''
            }
        ];

        const booleanOptions = [
            {
                value: true,
                label: this.props.intl.formatMessage({ id: "AdminUserEmailReport.Yes" })
            },
            {
                value: false,
                label: this.props.intl.formatMessage({ id: "AdminUserEmailReport.No" })
            },
        ];

        return (
            <StyledCard block={block} error={error} icon="fas fa-at" title="navbar.AdminUserEmailReport">
                <Row className="mb-1">
                    <Col className="col-2 pr-1">
                        <SelectHotel icon="fas fa-hotel" onChangeFunc={this.setHotel} value={hotelId} />
                    </Col>
                    <Col className="col-2 px-1">
                        <CustomSelect
                            icon="fas fa-hotel"
                            options={booleanOptions}
                            value={booleanOptions.find(opt => opt.value === hotelActive)}
                            onChange={this.setCombobox.bind(this, 'hotelActive')}
                            placeholder={this.props.intl.formatMessage({ id: "AdminUserEmailReport.IsActive" })}
                            isClearable
                        />
                    </Col>
                    <Col className="col-2 px-1">
                        <CustomSelect
                            icon="fas fa-hotel"
                            options={countryList().getData()}
                            onChange={this.setCombobox.bind(this, 'hotelCountry')}
                            placeholder={this.props.intl.formatMessage({ id: "AdminUserEmailReport.HotelCountry" })}
                            isClearable
                        />
                    </Col>
                    <Col className="col-2 px-1">
                        <CustomSelect
                            options={booleanOptions}
                            value={booleanOptions.find(opt => opt.value === hasLicense)}
                            onChange={this.setCombobox.bind(this, 'hasLicense')}
                            placeholder={this.props.intl.formatMessage({ id: "AdminUserEmailReport.HasLicenses" })}
                            isClearable
                        />
                    </Col>
                    <Col className="col-2 px-1">
                        <Input type="text" id="email" name="email" value={email} onChange={this.handleTextChange} placeholder={this.props.intl.formatMessage({ id: "AdminUserEmailReport.Email" })} />
                    </Col>
                    <Col className="col-1 px-1">
                        <CustomSelect
                            icon="fas fa-user"
                            options={booleanOptions}
                            value={booleanOptions.find(opt => opt.value === userActive)}
                            onChange={this.setCombobox.bind(this, 'userActive')}
                            placeholder={this.props.intl.formatMessage({ id: "AdminUserEmailReport.IsActive" })}
                            isClearable
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="float-right btn btn-sm btn-host" onClick={this.getUserEmails}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomTable
                            data={data}
                            columns={columns}
                            exportCSV={true}
                            showTotal={true}
                            remote={false}
                            search={true}
                            hideSizePerPage={false}
                        />
                    </Col>
                </Row>
            </StyledCard>    
        );
    }
}
export default injectIntl(AdminUserEmailReport);
