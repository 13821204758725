import * as moment from 'moment';
import React, { Component } from 'react'
import { Col, Row } from 'reactstrap';
import AvailabilityCalendarMonth from './AvailabilityCalendarMonth';

export default class AvailabilityCalendar extends Component {

    shouldComponentUpdate(nextProps){
        const res = (nextProps.alwaysUpdate || nextProps.currAvailabilities !== this.props.currAvailabilities || nextProps.calendarMonths !== this.props.calendarMonths || nextProps.focusedMonth);
        return res ? true : false;
    }
    
    constructor(props){
        super(props);
        this.state = {
            selectDay: null
        }
    }

    handleSelectDay = (d, dayAvailability, k) => {
        const { checkSelectDay } = this.props;
        if (checkSelectDay && checkSelectDay(dayAvailability, d, k)) {
            this.setState(prev => ({ selectDay: prev.selectDay === d.moment.format('YYYY/MM/DD') ? null : d.moment.format('YYYY/MM/DD') }));
        }
    }

    changeMonth = (value) =>{
        const { startDate, buildCalendarStruct } = this.props;
        buildCalendarStruct(moment(startDate).add(value, 'months'));
    }
    
    render() {
        const { currAvailabilities, startDate, blockedWeekdays, selectedMapping, findRule, calendarMonths, renderCell, renderSelectDay, cellHeight, fromAvailability, getFreeRooms, getPercentage,
            alwaysUpdate, focusedMonth, isNavigable, ignorePercentage, dataType } = this.props;
        const { selectDay } = this.state;
        
        const isHost = global.PMSType === 'Host' || global.PMSType === 'None';

        return (
            <div>
                <Row className="scrollableDiv pb-3 ml-1">
                    {
                        calendarMonths
                            .map((month, key) =>
                                <Col key={key} className={calendarMonths.length > 1 ? "col-6" : "col-12"} style={{ display: 'inline-block', height: '100%', verticalAlign: 'top' }}>
                                    <AvailabilityCalendarMonth
                                        isNavigable={isNavigable}
                                        isFirst={key === 0}
                                        changeMonth={this.changeMonth}
                                        currAvailabilities={currAvailabilities}
                                        startDate={moment(startDate).add(key, 'months')}
                                        blockedWeekdays={blockedWeekdays}
                                        focusedMonth={focusedMonth}
                                        selectedMapping={selectedMapping}
                                        findRule={findRule}
                                        calendarRows={month}
                                        renderCell={renderCell}
                                        renderSelectDay={renderSelectDay}
                                        isHost={isHost}
                                        getPercentage={getPercentage}
                                        selectDay={selectDay}
                                        alwaysUpdate={alwaysUpdate}
                                        handleSelectDay={this.handleSelectDay}
                                        cellHeight={cellHeight}
                                        fromAvailability={fromAvailability}
                                        getFreeRooms={getFreeRooms}
                                        ignorePercentage={ignorePercentage}
                                    />
                                </Col>
                            )
                    }
                </Row>
                {
                    selectDay && renderSelectDay ?
                        renderSelectDay(selectDay)
                        :
                        ''
                }
            </div>
        )
    }
}