import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Badge, Card, CardBody, CardHeader, Row, Col } from 'reactstrap';
import { ActiveInactiveStatusBadge } from '../Base/Common/CommonUIComponents';
import { getActive } from '../Base/Common/ReferenceDataFunctions';
import CustomSelect from "../Base/Common/CustomSelect";
import CustomToolTip from '../../Utils/CustomToolTip';
import InfiniteScroll from 'react-infinite-scroller';
import BlockUi from 'react-block-ui';

class EmailReservationMappings extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            mappings: [],
            roomOptions: [],
            rateOptions: [],
            rateLabelOptions: [],
            filterRooms: null,
            filterRates: null,
            rateLabels: null,
            mapStatusFilter: null,
            idx: 0,
            idxAdder: 1,
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;

        this.getMappings(this.props.rateList);
    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.rateList) !== JSON.stringify(nextProps.rateList)) {
            this.getMappings(nextProps.rateList);
        }
    }

    getMappings = (data) => {
        const { filterRooms, filterRates, mapStatusFilter, rateLabels } = this.state;

        let rateCodeChannelList = [];
        let roomOptions = [];
        let rateOptions = [];
        let rateLabelOptions = [];

        data && data.forEach(el => {
            let newRateOptions = [];
            el.rateCodeChannelList = [];

            el.rateList && el.rateList.forEach(rate => {
                if (rate.mappingList.length > 0 && rate.mappingList.find(map => map.roomCode)) {
                    el.rateCodeChannelList.push(rate)

                    //rates
                    newRateOptions.push({ value: rate.id, label: rate.description });
                }
            })

            if (el.rateCodeChannelList.length > 0) {
                //rooms
                roomOptions.push({ value: el.id, label: el.description });

                //rates
                rateOptions = rateOptions.concat(newRateOptions);

                const rateLabel = this.props.ratecodechannel && this.props.ratecodechannel.find(
                    rcc => rcc.localRoomCode === el.code) ?
                    this.props.ratecodechannel.find(rcc => rcc.localRoomCode === el.code).rateLabel
                    :
                    null;

                const rateLabelId = this.props.ratecodechannel && this.props.ratecodechannel.find(
                    rcc => rcc.localRoomCode === el.code) ?
                    this.props.ratecodechannel.find(rcc => rcc.localRoomCode === el.code).rateLabelId
                    :
                    null;

                el.rateLabel = rateLabel;
                el.rateLabelId = rateLabelId;

                if (rateLabelId && rateLabel && !rateLabelOptions.some(rl => rl.value === rateLabelId)) {
                    rateLabelOptions.push({ value: rateLabelId, label: rateLabel });
                }

                rateCodeChannelList.push(el);
            }
        })

        if (filterRooms) {
            this.filterRoomByCategory(filterRooms, rateCodeChannelList);
        }
        if (filterRates) {
            this.filterRateByRateCode(filterRates, rateCodeChannelList);
        }
        if (rateLabels) {
            this.filterRatesByRateLabel(rateLabels, rateCodeChannelList);
        }
        if (mapStatusFilter !== null) {
            this.handleMapStatusByStatus(mapStatusFilter, rateCodeChannelList);
        }

        this.setState({ mappings: rateCodeChannelList, roomOptions, rateOptions, rateLabelOptions });
    }

    filterRoom = (combo) => {
        let { mappings } = this.state;
        this.filterRoomByCategory(combo, mappings);
        this.setState({ filterRooms: combo, mappings });
    }

    filterRoomByCategory = (combo, mappings) => {
        if (combo && combo.length > 0) {
            mappings.forEach(el => {
                if (combo.some(c => el.id === c.value)) {
                    el.isVisible = true;
                    el.showRoom = this.state.mapStatusFilter !== null ? el.rateCodeChannelList.some(rate => {
                        return rate.mappingList.some(ml => ml.active === this.state.mapStatusFilter);
                    }) : true;
                }
                else {
                    el.isVisible = false;
                }
            })
        }
        else {
            mappings.forEach(el => {
                el.isVisible = true;
                el.showRoom =
                    el.rateCodeChannelList.some(rate => {
                        return rate.isVisible !== false && (this.state.mapStatusFilter !== null ? rate.mappingList.some(ml => ml.active === this.state.mapStatusFilter) : true);
                    })

            })
        }
    }

    filterRate = (combo) => {
        let { mappings } = this.state;
        this.filterRateByRateCode(combo, mappings);
        this.setState({ filterRates: combo, mappings });
    }

    filterRateByRateCode = (combo, mappings) => {
        if (combo && combo.length > 0) {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.isVisible = combo.some(c => rate.id === c.value);
                })

                el.showRoom = el.rateCodeChannelList.some(ml => ml.isVisible !== false);
            })
        }
        else {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.isVisible = true;
                })

                el.showRoom = true;
            })
        }
    }

    filterRateLabel = (combo) => {
        let { mappings } = this.state;
        this.filterRatesByRateLabel(combo, mappings);
        this.setState({ rateLabels: combo, mappings });
    }

    filterRatesByRateLabel = (combo, mappings) => {
        if (combo && combo.length > 0) {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.isVisible = combo.some(c => el.rateLabelId === c.value);
                })

                el.showRoom = el.rateCodeChannelList.some(ml => ml.isVisible !== false);
            })
        }
        else {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.isVisible = true;
                })

                el.showRoom = true;
            })
        }
    }

    handleMapStatus = (combo) => {
        let { mappings } = this.state;
        const status = combo ? combo.value : null;
        this.handleMapStatusByStatus(status, mappings);
        this.setState({ mapStatusFilter: status, mappings })
    }

    handleMapStatusByStatus = (status, mappings) => {
        if (status !== null) {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.showRate = rate.mappingList.some(ml => ml.active === status);
                })

                el.showRoom = el.isVisible !== false && el.rateCodeChannelList.some(rate => rate.showRate !== false && rate.isVisible !== false);
            })
        }
        else {
            mappings.forEach(el => {
                el.rateCodeChannelList.forEach(rate => {
                    rate.showRate = true;
                })

                el.showRoom = el.isVisible !== false && el.rateCodeChannelList.some(rate => rate.isVisible !== false);
            })
        }
    }

    canLoad = () => {
        const { idx, idxAdder } = this.state;
        this.setState({ idx: idx + idxAdder });
    }

    render() {
        const { idx, mappings } = this.state;

        const hasRateLabel = this.state.mappings ? this.state.mappings.some(el => el.rateLabel) : false;

        const maxRooms = mappings?.filter(({ isVisible, showRoom, rateCodeChannelList }) => isVisible !== false && showRoom !== false && rateCodeChannelList?.filter((rate) => rate.showRate !== false && rate.isVisible))?.length;

        return (
            <div>
                {mappings && mappings.length === 0 ?
                    <CardBody className="shadow">
                        <Row>
                            <Col>
                                <i className="fas fa-exclamation-triangle text-danger mr-1" />
                                <FormattedMessage id={'ChannelList.NoChannelData'} />
                            </Col>
                        </Row>
                    </CardBody>
                    :
                    <Row className="mb-2 align-items-center">
                        <Col className={`${hasRateLabel ? 'col-3' : 'col-4'} small pr-0`}>
                            <FormattedMessage id="ChannelMapping.CMRoom" >{option =>
                                <CustomSelect
                                    isClearable
                                    options={this.state.roomOptions}
                                    onChange={(e) => this.filterRoom(e)}
                                    placeholder={option}
                                    isMulti
                                />
                            }</FormattedMessage>
                        </Col>
                        <Col className={`${hasRateLabel ? 'col-3' : 'col-4'} small pr-0`}>
                            <FormattedMessage id="ChannelMapping.CMRate" >{option =>
                                <CustomSelect
                                    isClearable
                                    options={this.state.rateOptions}
                                    onChange={(e) => this.filterRate(e)}
                                    placeholder={option}
                                    isMulti
                                />
                            }</FormattedMessage>
                        </Col>
                        {hasRateLabel && global.operationMode !== 'PmsFull' &&
                            <Col className="col-3 small">
                                <FormattedMessage id="ChannelMapping.RateLabel" >{option =>
                                    <CustomSelect
                                        isClearable
                                        options={this.state.rateLabelOptions}
                                        onChange={(e) => this.filterRateLabel(e)}
                                        placeholder={option}
                                        isMulti
                                    />
                                }</FormattedMessage>
                            </Col>
                        }

                        <Col className="col-3 small">
                            <FormattedMessage id="ChannelMapping.MapStatus" >{option =>
                                <CustomSelect
                                    isClearable
                                    options={getActive(this.props.intl)}
                                    onChange={this.handleMapStatus}
                                    placeholder={option}
                                />
                            }</FormattedMessage>
                        </Col>
                    </Row>
                }

                <div style={{ overflow: 'auto', height: '40vh' }}>
                    <InfiniteScroll
                        pageStart={0}
                        useWindow={false}
                        loadMore={this.canLoad}
                        element='div'
                        hasMore={idx < (maxRooms - 1)}
                        loader={<div style={{ width: '100%' }} key="loader"><BlockUi tag="div" blocking={true} /></div>}
                        threshold={100}
                    >
                        {mappings?.filter(({ isVisible, showRoom, rateCodeChannelList }) => isVisible !== false && showRoom !== false && rateCodeChannelList?.filter((rate) => rate.showRate !== false && rate.isVisible))
                            .filter((el, k) => idx >= k).map((el, k) =>
                                <Card className="bg-white shadow border-0 mb-2" key={k}>
                                    <CardHeader style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} className="card-header-gridview border-bottom-1 border-host bg-white pb-1">
                                        <span><b>{el.code}: </b> {el.description}</span>
                                    </CardHeader>
                                    <CardBody className="border-0 py-0" style={{ fontSize: '13px' }}>
                                        {el.rateCodeChannelList?.map((rate, rate_key) => {
                                            return rate.showRate !== false && rate.isVisible !== false && (
                                                <Row className="overbglight" key={rate_key} style={{ borderBottom: '2px solid #dee2e6' }}>
                                                    <Col className="border-right py-2 col-4">
                                                        <Row>
                                                            <Col className="col-9">
                                                                <div className="mb-2"> <b>{rate.code} </b> </div>
                                                                <div> {rate.description} </div>
                                                            </Col>
                                                            <Col className="text-right col-3">
                                                                <div className="d-flex justify-content-end align-items-end">
                                                                    {rate.occupancyList ?
                                                                        <div>
                                                                            <Badge color="primary" className="py-1 mr-2" id={'pax' + rate.id}>
                                                                                <div>{rate.occupancyList.reduce((max, el) => el.adults > max ? el.adults : max, 0)} <i className="fas fa-male"></i>  {rate.occupancyList.length > 1 ? '+' : <span className="ml-2" />} </div>
                                                                            </Badge>
                                                                            <CustomToolTip placement="left" target={'pax' + rate.id}>
                                                                                {rate.occupancyList.sort(function (a, b) { return a.adults === b.adults ? (a.adults + a.children) - (b.adults + b.children) : a.adults - b.adults }).map((p, k3) =>
                                                                                    <Row key={k3}>
                                                                                        <Col>
                                                                                            <div className="text-left">
                                                                                                {p.adults} <i className="fas fa-user mr-1 ml-1"></i>
                                                                                                {p.childrens > 0 ? <span> + {p.childrens} <i className="fas fa-child mx-1"></i></span> : ''}
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                )}
                                                                            </CustomToolTip>
                                                                            {rate.isDerived ? <Badge color="primary">Derived</Badge> : ''}
                                                                        </div>
                                                                        : ''}

                                                                    <ActiveInactiveStatusBadge status={rate.active} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className="pt-1 pb-2">
                                                        {rate.mappingList.map((map, map_key) => {
                                                            return (this.state.mapStatusFilter !== null ? this.state.mapStatusFilter === map.active : true) && map.roomCode && (
                                                                <Row key={map_key} className={(map_key + 1 === rate.mappingList.length ? '' : "border-bottom ") + " pt-1 pb-2"} id={`map${map.id}`}>
                                                                    <Col>
                                                                        <Row>
                                                                            <Col sm={6}>
                                                                                <b><FormattedMessage id="ChannelMapping.Rate" />:</b> {map.rateCode}
                                                                            </Col>
                                                                            <Col sm={6}>
                                                                                <b><FormattedMessage id="ChannelMapping.Room" />:</b> {map.roomCode}
                                                                            </Col>
                                                                            <Col sm={6}>
                                                                                <b><FormattedMessage id="ChannelMapping.Contract" /></b> {map.contract}
                                                                            </Col>
                                                                            <Col sm={6}>
                                                                                <b><FormattedMessage id="ChannelMapping.MealPlan" /></b> {map.mealPlan}
                                                                            </Col>
                                                                            <Col sm={6}>
                                                                                <b><FormattedMessage id="ChannelMapping.CancelPolicy" />:</b> {map.cancellationPolicy}
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            );
                                                        })}
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </CardBody>
                                </Card>
                            )}
                    </InfiniteScroll>
                </div>
            </div>
        );
    }
}

export default injectIntl(EmailReservationMappings);