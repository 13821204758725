import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import EmailReservationMappings from './EmailReservationMappings';

class EmailReservationChannelMapping extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            error: [],
            block: false,
        };
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
    }

    render() {

        return (
            <div>
                <div className="mb-2">
                    <Card block={this.state.block} className="shadow border-0">
                        <CardHeader>
                            <Row className="mb-2">
                                <Col>
                                    <strong><FormattedMessage id="ChannelList.Mapping" /></strong>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <EmailReservationMappings
                                rateList={this.props.rateList}
                                ratecodechannel={this.props.ratecodechannel}
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        );
    }
}

export default injectIntl(EmailReservationChannelMapping);