import React, { Component } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, FormattedDate } from 'react-intl';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { getAPI } from "../../../Base/API"

import { CustomTable } from '../../../Base/Common/CustomTable'

export class RoomSetup extends Component {

    state = {
        error: null,
        block: true,
        activeTab: '1',
        activeAddressTab: '1',
        RoomData: []
    };

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                this.setState({ RoomData: data });
            }
        }, '/api/hotel/Room/v1?pageSize=300&pageIndex=0');

    }


    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    render() {

        const columns = [{
            dataField: 'id',
            text: <FormattedMessage id="hotelconf.room.RoomID" />,
            sort: true
        }, {
            dataField: 'code',
                text: <FormattedMessage id="hotelconf.room.Code" />,
            sort: true
        }, {
            dataField: 'description',
                text: <FormattedMessage id="hotelconf.room.Description" />,
            sort: true
        }, {
                dataField: 'Floor',
                text: <FormattedMessage id="hotelconf.room.Floor" />,
            sort: true
        }, {
                dataField: 'addBeds',
                text: <FormattedMessage id="hotelconf.room.AddBeds" />,
            sort: true
        }, {
                dataField: 'area',
                text: <FormattedMessage id="hotelconf.room.Area" />,
            sort: true
        }, {
                dataField: 'inventoryStart',
                text: <FormattedMessage id="hotelconf.room.InventoryStart" />,
                formatter: (cell) => {
                    return cell ? <FormattedDate value={cell} /> : ''
                },
                sort: true
            }, {
                dataField: 'inventoryEnd',
                text: <FormattedMessage id="hotelconf.room.InventoryEnd" />,
                formatter: (cell) => {
                    return cell ? <FormattedDate value={cell} /> : ''
                },
            sort: true
        }];
           

        if (this.state.RoomData.data) {
            return (
                <div>
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                   
                        <Card className="border-0 shadow  ">
                            <CardHeader className="border-bottom bg-white"><div><span className="fas fa-bed"> </span> <FormattedMessage id="RoomSetup.RoomConfiguration" /> </div></CardHeader>
                            <CardBody>
                                <CustomTable
                                    data={this.state.RoomData.data}
                                    columns={columns}
                                    page={this.state.currentPage}
                                    sizePerPage={this.state.resultsPerPage}
                                    totalSize={this.state.RoomData && this.state.RoomData.data.length}
                                   
                                    shadow={false}
                                    search={true}
                                    remote={false}
                                />
                                
                            </CardBody>
                        </Card>
                    
                    </BlockUi>

                </div>

            );
        } else {
            return (<BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} /></BlockUi>)
        }
    }
}