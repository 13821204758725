import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { SelectHotel, StyledCard } from '../../Base/Common/CommonUIComponents';
import CustomSelect from '../../Base/Common/CustomSelect';
import { getAPI } from '../../Base/API';

export class CheckPasswords extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            channelInstanceId: null,
            channels: [],
            hotelId: null,
            password: null,
            secondaryPassword: null
        };
    }

    handleHotel = (_, combo) => {
        const hotelId = combo && combo.value;
        if (hotelId) {
            this.setState({ hotelId, block: true, channelInstanceId: null, channels: [] }, this.getChannelInstances);
        }
        else {
            this.setState({ hotelId, channelInstanceId: null, channels: [], password: null, secondaryPassword: null });
        }
    }

    getChannelInstances = () => {
        const { hotelId } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });

                return;
            }
            if (data) {
                const channels = data
                    .map(resp => {
                        const option = {
                            value: resp.id,
                            label: `${resp.channelName} - ${resp.name}(${resp.propertyCode})`
                        };
                        return option;
                    });

                this.setState({ block: false, error: errorMessage, channels });
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance?hotelId=${hotelId}`);
    }

    handleChannel = (combo) => {
        this.setState({ channelInstanceId: combo && combo.value });
    }

    getPassword = () => {
        this.setState({ block: true });

        const { channelInstanceId } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });

                return;
            }
            if (data) {
                if (data.length > 0) {
                    const { password, secondaryPassword } = data[0];
                    this.setState({ block: false, error: errorMessage, password, secondaryPassword });
                }
                else {
                    this.setState({ block: false, error: errorMessage });
                }
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, `/api/Rate/ChannelConfig/v1/ChannelInstance/${channelInstanceId}`);
    }

    render() {
        const { block, channelInstanceId, channels, error, hotelId, password, secondaryPassword } = this.state;

        return (
            <StyledCard block={block} error={error} title="navbar.CheckPasswords">
                <Row className="mb-3">
                    <Col>
                        <SelectHotel name="hotelId" onChangeFunc={this.handleHotel} value={hotelId} placeholder={<FormattedMessage id="HotelFullPush.SelectHotel" /> } />
                    </Col>
                    <Col>
                        <CustomSelect options={channels} onChange={this.handleChannel} value={channelInstanceId && channels.find(chn => chn.value === channelInstanceId)} placeholder={<FormattedMessage id="HotelFullPush.SelectChannel" />} />
                    </Col>
                    <Col className="col-1 text-right">
                        <Button className="btn btn-host btn-sm" onClick={this.getPassword}>
                            <span className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                {
                    password ?
                        <Row className="pl-2">
                            <Col className="col-2">
                                <h5><FormattedMessage id="ChannelConfigState.Password" /></h5>
                            </Col>
                            <Col className="col-10">
                                {password}
                            </Col>
                        </Row>
                        :
                        ''
                }
                {
                    secondaryPassword ?
                        <Row className="pl-2">
                            <Col className="col-2">
                                <h5><FormattedMessage id="ChannelConfigState.SecondaryPassword" /></h5>
                            </Col>
                            <Col className="col-10">
                                {secondaryPassword}
                            </Col>
                        </Row>
                        :
                        ''
                }
            </StyledCard>
        );
    }
}