import React, { Component } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export class RenderRedsysForm extends Component {

    render() {
        const { paymentGateway, handleData, idx } = this.props;

        return (
            <div>
                <FormGroup row>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.EntityId" />
                    </Label>
                    <Col sm={9}>
                        <Input type="text" maxLength="50" name="userId" id="userId" value={paymentGateway.userId ? paymentGateway.userId : ''} onChange={evt => handleData(evt, idx)} required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.Key" />
                    </Label>
                    <Col sm={9}>
                        <Input type="text" maxLength="100" name="accessToken" id="accessToken" value={paymentGateway.accessToken ? paymentGateway.accessToken : ''} onChange={evt => handleData(evt, idx)} required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.Terminal" />
                    </Label>
                    <Col sm={9}>
                        <Input type="text" maxLength="10" name="ccKey" id="ccKey" value={paymentGateway.ccKey ? paymentGateway.ccKey : ''} onChange={evt => handleData(evt, idx)} required />
                    </Col>
                </FormGroup>
            </div>
        );
    }
}