import React, { Component } from 'react';
import { Row, Col, Button, Card, CardHeader, CardBody, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { handleNotification } from '../../Base/Notification';
import { postAPI, getAPI } from '../../Base/API';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../Common/ErrorAlert";
import CustomSelect from '../../Base/Common/CustomSelect';
import Authorization from '../../Base/Authorization';

export class ChannelConfigState extends Component {

    constructor(props) {
        super(props);
        this.removeState = this.removeState.bind(this);
        this.removeMandatoryField = this.removeMandatoryField.bind(this);
        this.removePriceMode = this.removePriceMode.bind(this);
        this.removeElement = this.removeElement.bind(this);
        this.addMandatoryField = this.addMandatoryField.bind(this);
        this.addState = this.addState.bind(this);
        this.addPriceMode = this.addPriceMode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.state = {
            block: true,
            mandatoryField: null,
            channelConfigState: {
                state: [],
                mandatoryFields: [],
                priceModes: []
            }
        }
    }

    componentDidMount() {
        if (this.props.modalData && this.props.modalData.id) {
            getAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ block: isLoading });
                if (error) {
                    const errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data && data.response) {
                    if (!data.response.state) {
                        data.response.state = []
                    }
                    if (!data.response.mandatoryFields) {
                        data.response.mandatoryFields = []
                    }
                    if (!data.response.priceModes) {
                        data.response.priceModes = []
                    }
                    this.setState({ channelConfigState: data.response, block: false });
                }
            }, `/api/Rate/ChannelConfig/v1/ChannelConfigState/${this.props.modalData.id}`);
        }
        else {
            this.setState({ block: false });
        }
    }

    removeState(state) {
        this.removeElement(state, this.state.channelConfigState.state, 'state');
    }

    removeMandatoryField(mandatoryField) {
        this.removeElement(mandatoryField, this.state.channelConfigState.mandatoryFields, 'mandatoryFields');
    }

    removePriceMode(priceMode) {
        this.removeElement(priceMode, this.state.channelConfigState.priceModes, 'priceModes');
    }

    removeElement(element, array, name) {
        const idxToRemove = array && array.indexOf(element);
        if (idxToRemove > -1) {
            const elementsToRemove = 1;
            array.splice(idxToRemove, elementsToRemove);
            this.setState(prevState => ({
                channelConfigState: {
                    ...prevState.channelConfigState,
                    [name]: array
                }
            }));
        }
    }

    addMandatoryField(mandatoryField) {
        this.setState(prevState => ({
            channelConfigState: {
                ...prevState.channelConfigState,
                mandatoryFields: [...prevState.channelConfigState.mandatoryFields, mandatoryField]
            },
            mandatoryField: null
        }));
    }

    addState(state) {
        this.setState(prevState => ({
            channelConfigState: {
                ...prevState.channelConfigState,
                state: [...prevState.channelConfigState.state, state]
            },
            state: null
        }));
    }

    addPriceMode(priceMode) {
        this.setState(prevState => ({
            channelConfigState: {
                ...prevState.channelConfigState,
                priceModes: [...prevState.channelConfigState.priceModes, priceMode]
            },
            priceMode: null
        }));
    }

    handleChange(name, combo) {
        const value = combo ? combo.value : null;
        this.setState(({ [name]: value }));
    }

    handleSave() {
        this.setState({ block: true });
        const body = {
            request: this.state.channelConfigState
        };
        body.request.channelConfigId = this.props.modalData && this.props.modalData.id;

        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data && data.errors && data.errors.length > 0) {
                data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                this.setState(({ block: false, error: errorMessage }));
                return;
            }
            if (data && data.response) {
                handleNotification(data, <FormattedMessage id="ChannelConfigState.SaveMessageSuccess" />, <FormattedMessage id="General.Success" />);
                this.setState(({ block: false, channelConfigState: data.response, error: errorMessage  }));
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelConfigState', JSON.stringify(body));        
    }

    render() {
        const stateOptions = [
            {
                value: 'Pending Channel Verification',
                label: <FormattedMessage id="ChannelConfigState.PendingChannelVerification" />
            },
            {
                value: 'Pending Mapping Verification',
                label: <FormattedMessage id="ChannelConfigState.PendingMappingVerification" />
            },
            {
                value: 'Verified',
                label: <FormattedMessage id="ChannelConfigState.Verified" />
            }
        ];

        const stateOptionsFiltered = stateOptions.filter(stateOption => (this.state.channelConfigState.state && this.state.channelConfigState.state.find(s => stateOption.value === s)) == null)

        const mandatoryFieldsOptions = [
            {
                value: 'PropertyCode',
                label: <FormattedMessage id="ChannelConfigState.PropertyCode" />
            },
            {
                value: 'User',
                label: <FormattedMessage id="ChannelConfigState.User" />
            },
            {
                value: 'Password',
                label: <FormattedMessage id="ChannelConfigState.Password" />
            },
            {
                value: 'SecondaryUser',
                label: <FormattedMessage id="ChannelConfigState.SecondaryUser" />
            },
            {
                value: 'SecondaryPassword',
                label: <FormattedMessage id="ChannelConfigState.SecondaryPassword" />
            },
            {
                value: 'MaxInventoryDays',
                label: <FormattedMessage id="ChannelConfigState.MaxDays" />
            }
        ];

        const mandatoryFieldsOptionsFiltered = mandatoryFieldsOptions.filter(mandatoryFieldsOption => (this.state.channelConfigState.mandatoryFields && this.state.channelConfigState.mandatoryFields.find(mandatoryField => mandatoryFieldsOption.value === mandatoryField)) == null)

        const priceModesOptions = [
            {
                value: '0',
                label: <FormattedMessage id="ChannelConfigState.Occupancy" />
            },
            {
                value: '1',
                label: <FormattedMessage id="ChannelConfigState.Room" />
            },
            {
                value: '2',
                label: <FormattedMessage id="ChannelConfigState.Person" />
            }
        ];

        const priceModesOptionsFiltered = priceModesOptions.filter(priceModesOption => (this.state.channelConfigState.priceModes && this.state.channelConfigState.priceModes.find(priceMode => priceModesOption.value === priceMode)) == null)

        function getLabel(item, array) {
            const option = array.find(option => option.value === item);
            return option ? option.label : ''
        }

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Card className="shadow border-0 h-100">
                    <CardHeader>
                        <FormattedMessage id="ChannelConfigState.Intro" />
                        <strong>
                            {this.props.modalData && this.props.modalData.code}
                        </strong>
                        <Authorization
                            perform="channelConfigState:save"
                            yes={() => (
                                <Button color=" btn-host btn-sm float-right" onClick={this.handleSave}>
                                    <i className="fas fa-save" />
                                </Button>
                            )}
                            no={() => <div></div>}
                        />        
                    </CardHeader>
                    <CardBody>
                        <Row className="pt-2">
                            <Col className="col-1">
                                <Label for="state">
                                    <FormattedMessage id="ChannelConfigState.States" />
                                </Label>
                            </Col>
                            <Col className="col-3">      
                                <Row className="pt-2">
                                    <Col className="col-10">
                                        <CustomSelect name="state" options={stateOptionsFiltered} onChange={this.handleChange.bind(this, 'state')} placeholder={<FormattedMessage id="ChannelConfigState.States" />} value={stateOptions.find(s => s.value === this.state.state)} isClearable />
                                    </Col>
                                    <Col className="col-2">
                                        <Button color=" btn-host btn-sm float-right" onClick={() => this.addState(this.state.state)} disabled={!this.state.state}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    </Col>
                                </Row>
                                {
                                    this.state.channelConfigState.state && this.state.channelConfigState.state.map((state, key) =>
                                        <Row className="pt-2" key={key}>
                                            <Col className="col-10">
                                                {getLabel(state, stateOptions)}
                                            </Col>
                                            <Col className="col-2">
                                                <Button className="btn btn-sm btn-host float-right" onClick={() => this.removeState(state)}>
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </Col>
                            <Col className="col-1">
                                <Label for="mandatoryFields">
                                    <FormattedMessage id="ChannelConfigState.MandatoryFields" />
                                </Label>
                            </Col>
                            <Col className="col-3">
                                <Row className="pt-2">
                                    <Col className="col-10">
                                        <CustomSelect name="mandatoryFields" options={mandatoryFieldsOptionsFiltered} onChange={this.handleChange.bind(this, 'mandatoryField')} placeholder={<FormattedMessage id="ChannelConfigState.MandatoryFields" />} value={mandatoryFieldsOptions.find(mandatoryField => mandatoryField.value === this.state.mandatoryField)} isClearable />
                                    </Col>
                                    <Col className="col-2">
                                        <Button color=" btn-host btn-sm float-right" onClick={() => this.addMandatoryField(this.state.mandatoryField)} disabled={!this.state.mandatoryField}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    </Col>
                                </Row>
                                {
                                    this.state.channelConfigState.mandatoryFields && this.state.channelConfigState.mandatoryFields.map((mandatoryField, key) =>
                                        <Row className="pt-2" key={key}>
                                            <Col className="col-10">
                                                {getLabel(mandatoryField, mandatoryFieldsOptions)}
                                            </Col>
                                            <Col className="col-2">
                                                <Button className="btn btn-sm btn-host float-right" onClick={() => this.removeMandatoryField(mandatoryField)}>
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </Col>
                            <Col className="col-1">
                                <Label for="priceModes">
                                    <FormattedMessage id="ChannelConfigState.PriceModes" />
                                </Label>
                            </Col>
                            <Col className="col-3">
                                <Row className="pt-2">
                                    <Col className="col-10">
                                        <CustomSelect name="priceModes" options={priceModesOptionsFiltered} onChange={this.handleChange.bind(this, 'priceMode')} placeholder={<FormattedMessage id="ChannelConfigState.PriceModes" />} value={priceModesOptions.find(priceMode => priceMode.value === this.state.priceMode)} isClearable />
                                    </Col>
                                    <Col className="col-2">
                                        <Button color=" btn-host btn-sm float-right" onClick={() => this.addPriceMode(this.state.priceMode)} disabled={!this.state.priceMode}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    </Col>
                                </Row>
                                {
                                    this.state.channelConfigState.priceModes && this.state.channelConfigState.priceModes.map((priceMode, key) =>
                                        <Row className="pt-2" key={key}>
                                            <Col className="col-10">
                                                {getLabel(priceMode, priceModesOptions)}
                                            </Col>
                                            <Col className="col-2">
                                                <Button className="btn btn-sm btn-host float-right" onClick={() => this.removePriceMode(priceMode)}>
                                                    <i className="fas fa-trash-alt" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </Col>
                        </Row>                            
                    </CardBody>
                </Card>
            </BlockUi>
        )
    }
}