import BootstrapTable from 'react-bootstrap-table-next';
import React from 'react';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import {  Row, Col } from 'reactstrap';

import { FormattedMessage } from 'react-intl';
import { CustomExportCSV } from './CustomExportCSV';
const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
}) => (
        <div className="btn-group" role="group">
            {
                options.map(option => (
                    <button
                        key={option.text}
                        type="button"
                        onClick={() => onSizePerPageChange(option.page)}
                        className={`page-link  ${currSizePerPage === `${option.page}` ? 'bg-host text-white' : 'text-secondary'}`}
                        
        >
                        {option.text}
                    </button>
                ))
            }
        </div>
    );

const paginationTotalRenderer = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
        <FormattedMessage id="generic.table.totalRows" values={{ from, to, size }} />
    </span>
);

export const CustomTable = ({ keyField, data, page, sizePerPage, onTableChange, hidePageListOnlyOnePage, hideSizePerPage = true, totalSize, columns, shadow, selectRow, showTotal, search, remote, exportCSV, customExportCsv, handleOnClick, tooltipMessage, hasPagination = true, expandRow, tableButtons, className, wrapperClasses="table-responsive" }) => (
    <FormattedMessage id="generic.table.nodata" defaultMessage="No Data Available">
        {placeholder =>
            <FormattedMessage id="generic.table.search" defaultMessage="Search">
                {searchPlaceholder =>
                    
                    <ToolkitProvider
                        keyField={keyField ? keyField : 'id'}
                        data={data}
                        columns={columns}
                        search={search}
                        bootstrap4={true}
                    >
                        {
                            props => (

                                <div className={className ? className : shadow ? 'small shadow' : 'small'}>
                                    {search || exportCSV ?
                                        <Row>
                                            {tableButtons}
                                            <Col>
                                                {search ?
                                                    <SearchBar className="form-control-sm" {...props.searchProps} placeholder={searchPlaceholder} />
                                                : ''}
                                            </Col>
                                            <Col className="d-flex align-items-center justify-content-end">
                                                {
                                                    exportCSV ?
                                                        customExportCsv ?
                                                            <CustomExportCSV className="btn btn-host btn-sm float-right shadow" {...props.csvProps} handleOnClick={handleOnClick} tooltipMessage={tooltipMessage}>
                                                                <i className="fas fa-file-excel" />
                                                            </CustomExportCSV>
                                                            :
                                                            <ExportCSVButton className="btn btn-host btn-sm float-right shadow mb-2" {...props.csvProps}>
                                                                <i className="fas fa-file-excel"></i>
                                                            </ExportCSVButton>
                                                        :
                                                        ''
                                                }
                                            </Col>
                                        </Row>
                                        : ''}
                                    <div>
                                        <BootstrapTable 
                                            {...props.baseProps}
                                            wrapperClasses={wrapperClasses}
                                            noDataIndication={placeholder}
                                            condensed={true}
                                            bordered={false}
                                            exportCSV={exportCSV}
                                            pagination={hasPagination ? paginationFactory({ hidePageListOnlyOnePage: true, hideSizePerPage: hideSizePerPage, page, sizePerPage, showTotal: showTotal, totalSize, sizePerPageRenderer: sizePerPageRenderer, paginationTotalRenderer }) : null}
                                            onTableChange={onTableChange}
                                            remote={remote}
                                            totalSize={totalSize}
                                            hover
                                            selectRow={selectRow}
                                            expandRow={expandRow}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    </ToolkitProvider>
                }
            </FormattedMessage>
                }
   </FormattedMessage>
);
