import classnames from 'classnames';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import { ConfirmReservationPayment } from './ConfirmReservationPayment';
import { ValidateReservations } from './ValidateReservations';


export class SapoAccounting extends Component {

    state = {
        activeTab: '1',
        activeAddressTab: '1',
        HotelData: [],
        error: null,
        block: true,
    };



    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

    }

    componentDidMount() {
      
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }


    render() {

        return (
            <Card className="border-0">
              

                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggle('1'); }}
                            >
                                <FormattedMessage id="Accounting.ConfirmPayment" />
                            </NavLink>
                        </NavItem>

                        
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: this.state.activeTab === '2' })}
                                        onClick={() => { this.toggle('2'); }}
                                    >
                                <FormattedMessage id="Accounting.ReviewAccounting" />
                                    </NavLink>
                                </NavItem>



                        

                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">

                        <ConfirmReservationPayment />
                        </TabPane>

                     

                    <TabPane tabId="2">
                        <ValidateReservations/>
                           
                        </TabPane>

                    </TabContent>


            </Card>


        );
    }
}