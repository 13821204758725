import React, { Component } from 'react';
import { Card, Col, Row, Button, Form, Input, ButtonGroup, Popover, PopoverBody, Modal, ModalBody } from 'reactstrap';
import { getMimeTypeFromExtension } from '../../../Base/Common/ReferenceDataFunctions';
import { postAPI, postMultiPartAPI, getAPI } from "../../../Base/API";
import { handleNotification } from "../../../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import BlockUi from 'react-block-ui';
import moment from 'moment';
import { TextAreaAI } from '../../../Base/Common/AiComponents';


class ExpediaChatWindow extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            message: null,
            conversation: null,
            popoverOpen: false,
            fileContent: '',
            file: null,
            conversationId: null,
            modal: false,
            attachment: null,
            openGpt: false,
            senderList: [],
            fileType: null,
            attachments: null
        }
    }

    componentDidMount() {
        const messages = this.props.selectedConversation.messages?.elements;
        const canReply = this.canReply(this.props.selectedConversation.creationDateTimeUtc, this.props.selectedConversation.reservationSummary?.checkOutDate);


        this.setState({
            conversation: messages,
            conversationId: this.props.selectedConversation.id,
            canReply
        }, this.scrollToLastMessage)
    }

    componentWillReceiveProps(nextProps) {
        if ((this.props && this.props.selectedConversation) !== (nextProps && nextProps.selectedConversation)) {
            const messages = nextProps.selectedConversation.messages?.elements;
            const canReply = this.canReply(nextProps.selectedConversation.creationDateTimeUtc, nextProps.selectedConversation.reservationSummary?.checkOutDate);

            this.setState({
                conversation: messages,
                conversationId: nextProps.selectedConversation.id,
                fileContent: '',
                file: null,
                popoverOpen: false,
                openGpt: false,
                message: null,
                canReply,
                fileType: null,
                attachments: null
            }, this.scrollToLastMessage)
        }
    }

    canReply = (creationDateTimeUtc, checkOutDate) => {
        if (creationDateTimeUtc && checkOutDate) {
            return moment().diff(moment(creationDateTimeUtc), 'year') < 3 || moment().diff(moment(checkOutDate), 'year') < 1;
        }

        return false;
    }

    scrollToLastMessage = () => {
        const chatWindow = document.getElementById("chatWindow");

        if (chatWindow) {
            chatWindow.scrollTo(0, chatWindow.scrollHeight);
        }
    }

    submitMessage = (e, threadId) => {
        e.preventDefault();
        this.setState({ block: true });

        const body = { message: this.state.message };

        if (this.state.attachments?.length > 0) {
            body.attachmentIds = this.state.attachments.map(el => el.id);
        }

        postAPI(result => {
            const { data, error } = result;
            var errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage, popoverOpen: false }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="BookingChatWindow.MessageSent" />, <FormattedMessage id="General.Success" />);

                    this.props.updateThread(data.response[0], this.state.message, this.state.attachments)

                    this.setState({ block: false, error: errorMessage, message: '', attachments: null }, this.scrollToLastMessage);
                }
            }
            this.setState({ block: false });
        }, `/api/Rate/MailBox/v1/expediaMessaging/${this.props.selectedConnection}/message/${threadId}`, body);
    }

    getAttachment = (e, msgId, attach) => {
        e.preventDefault();
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response?.length > 0) {
                    var contentType = getMimeTypeFromExtension(attach.name.split('.').pop());

                    this.setState({
                        modal: true,
                        attachment: {
                            ...this.state.attachment,
                            content: data.response[0],
                            type: contentType
                        },
                        block: false
                    });
                    return;
                }
            }
            this.setState({ block: false });
        }, `/api/Rate/MailBox/v1/expediaMessaging/${this.props.selectedConnection}/message/${msgId}/attachment/${attach.id}`);
    }

    uploadFile = (file) => {
        this.setState({ block: true });

        const formData = new FormData();
        formData.append('mediaFile', file);

        postMultiPartAPI(result => {
            const { data, error } = result;
            var errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if ((data.errors && data.errors.length > 0) || (data.warnings && data.warnings.length > 0)) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response?.length > 0) {
                    handleNotification(data, <FormattedMessage id="ExpediaChatWindow.FileUploaded" />, <FormattedMessage id="General.Success" />);
                    
                    const reader = new FileReader();

                    reader.addEventListener("load", () => {
                        this.setState({
                            fileContent: reader.result,
                            fileType: file.type
                        })
                    }, false);

                    if (file) {
                        reader.readAsDataURL(file);
                    }


                    this.setState({
                        attachments: [{ id: data.response[0].id, name: file.name }],
                        block: false
                    });
                }
                return;
            }
            this.setState({ block: false });
        }, `/api/Rate/MailBox/v1/expediaMessaging/${this.props.selectedConnection}/messageThread/${this.props.selectedConversation.id}/attachment`, formData);
    }

    addAttach = () => {
        var file = document.querySelector('input[type=file]').files[0];

        if (file) {
            if (file.size <= 5242880) { //5MB
                this.uploadFile(file);
            }
            else {
                handleNotification('', <FormattedMessage id="ExpediaChatWindow.PleaseSelectAFileLessThan" />, <FormattedMessage id="BookingChatWindow.SelectedFileIsTooBig" />, 'info');
            }
        }
        else {
            this.setState({ fileContent: '' })
        }
    }

    getGptConversation = () => {
        const { conversation } = this.state;

        return conversation && conversation.length > 0 ?
            conversation.reduce((acc, cur) => {
                if (cur.body?.value) {
                    acc.push({ content: cur.body.value, role: cur.fromRole == "TRAVELER" ? 'user' : 'assistant' });
                }
                return acc;
            }, [])
        : [];
    }

    togglePopover = () => {
        this.setState(prevState => ({
            popoverOpen: !prevState.popoverOpen
        }));
    }


    changeMessage = (e) => {
        this.setState({ message: e.target.value })
    }

    render() {
        const { conversation, message, canReply } = this.state;
        
        return (
            <div style={{ backgroundColor: 'whitesmoke' }}>
                {global.isMobile ?
                    <Col className='pt-2'>
                        <span className="fas fa-arrow-left pointer" onClick={() => this.props.selectConversation(null)}></span>
                    </Col>
                    : ''}
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <div id="chatWindow" className="scrollableDiv px-3" style={{ overflowY: 'auto', overflowX: 'hidden', height: (canReply ? '58vh' : '67vh') }}>
                        {conversation?.map((msg, key) => {
                            const isMsgFromGuest = msg.fromRole.toLowerCase() === 'traveler';

                            return <div key={key}>
                                <Row>
                                    <Col className="col-12 text-center text-secondary mt-3 mb-2">{moment(msg.creationDateTimeUtc).fromNow() + " , " + moment(msg.creationDateTimeUtc).format("YYYY-MM-DD HH:mm")}</Col>
                                </Row>
                                <Row>
                                    {msg.body || msg.attachments ?
                                        <Col>
                                            <Card body style={{ width: 'fit-content', borderRadius: '20px' }} className={(isMsgFromGuest ? "float-left bg-soft-blue" : "float-right") + " py-2 px-3 shadow"}>
                                                <div className="mailboxballon">
                                                    {msg.body.value ?
                                                        <span>{msg.body.value}</span>
                                                        :
                                                        <i> Automatic message of {msg.type} </i>
                                                    }
                                                    {msg.attachments?.length > 0 ?
                                                        <i className="fa-sm fas fa-paperclip ml-2 pointer" onClick={(e) => this.getAttachment(e, msg.id, msg.attachments[0])} />
                                                    : ''}
                                                </div>
                                            </Card>
                                        </Col>
                                    : ''}
                                </Row>
                            </div>
                        } )}
                    </div>

                    <div className="px-3">
                        {canReply ?
                            <Form onSubmit={(e) => this.submitMessage(e, this.props.selectedConversation.id)}>
                                <Row className="mt-3 pb-3">
                                    <Col className="col-lg-10 col-9 pr-0">
                                        <TextAreaAI
                                            inputName="message"
                                            required={true}
                                            value={message || ''}
                                            handleChange={this.changeMessage}
                                            isReview={false}
                                            conversation={this.getGptConversation()}
                                            openAiWidth={96}
                                        />
                                    </Col>
                                    <Col className="col-lg-2 col-3 text-right">
                                        <ButtonGroup>
                                            <Button className=" btn btn-host  btn-sm mr-1" type="submit">
                                                <span className="fas fa-play"> </span>
                                            </Button>
                                            <Button className=" btn btn-host  btn-sm mr-1" id="addAttach" onClick={() => document.getElementById("selectFile").click()}>
                                                <span className="fas fa-paperclip"> </span>
                                            </Button>
                                            <Input
                                                type="file"
                                                className=" btn btn-host  btn-sm"
                                                hidden
                                                id='selectFile'
                                                onChange={this.addAttach.bind(this)}
                                                accept="application/pdf, image/jpeg, image/png, image/gif"
                                            />
                                        </ButtonGroup>
                                        <Popover placement="top" isOpen={this.state.popoverOpen} target="addAttach" toggle={this.togglePopover}>
                                            <PopoverBody>
                                                <div style={{ 'height': '5px' }} onClick={() => this.setState({ fileContent: '', popoverOpen: false, file: null, attachmentId: null, fileType: null })}>
                                                    <i className=" float-right fas fa-times"></i>
                                                </div>
                                                {this.state.fileContent && this.state.fileContent != '' ?
                                                    this.state.fileType === 'application/pdf' ?
                                                        <embed type={this.state.fileType} src={this.state.fileContent} width={global.isMobile ? '40' : '100' } height="100" />
                                                    :
                                                    <img src={this.state.fileContent} id="imagePreview" style={{ 'maxHeight': '50px', 'maxWidth': global.isMobile ? '40px' : '' }} alt="Preview" />
                                                    : <i className="fas fa-image fa-3x"></i>}

                                            </PopoverBody>
                                        </Popover>
                                    </Col>
                                </Row>
                            </Form>
                            : ''}
                    </div>
                </BlockUi>

                {this.state.attachment && this.state.attachment.content && this.state.attachment.type ?
                    <Modal isOpen={this.state.modal} toggle={() => this.setState({ modal: false, attachment: null })} fade={false} className="modal-xl " style={{ minWidth: 0 }}>
                        <ModalBody className="modal-xl">
                            <Row>
                                <Col className="text-center">
                                    <embed
                                        type={this.state.attachment.type}
                                        src={`data:${this.state.attachment.type};base64,${this.state.attachment.content}`}
                                        style={{ width: '100%', height: '100%', maxHeight: '80vh' }}
                                        alt={this.state.attachment.name}
                                    />
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                : ''}
            </div>
        )
    }
}

export default injectIntl(ExpediaChatWindow)