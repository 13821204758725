import React, { Component } from 'react';
import { Row, Col, Card, InputGroup, InputGroupAddon, Input, Badge } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from "../Base/API"
import { DateRangePicker } from 'react-dates';
import moment from "moment";
import ReservationDetail from "../Reservations/ReservationDetail";
import { CustomTable } from '../Base/Common/CustomTable'
import CustomSelect from '../Base/Common/CustomSelect';
import { ReservationStatusBadge, StyledCard, SelectHotel, FormatAmountNumber } from '../Base/Common/CommonUIComponents';


class SAPOReservations extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            block: true,
            reservationList: [],
            startDate: moment().subtract(15, 'days'),
            endDate: moment(),
            modal: false,
            reservationSelected: null,
            guestName: '',
            email: '',
            status: '',
            reservationId: '',
            hotel: ''
        }
    }

    componentDidMount() {
        this.getReservations();
    }

    getReservations = () => {
        this.setState({ block: true });
        var params = "";

        params += this.state.reservationId ? `&reservationId=${this.state.reservationId}` : '';
        params += this.state.guestName ? `&guestName=${this.state.guestName}` : '';
        params += this.state.email ? `&mail=${this.state.email}` : '';
        params += this.state.hotel ? `&hotelId=${this.state.hotel}` : '';
        params += this.state.status ? `&status=${this.state.status}` : '';
        const startDate = this.state.startDate ? this.state.startDate.format("YYYY-MM-DD") : '';
        const endDate = this.state.endDate ? this.state.endDate.format("YYYY-MM-DD") : '';

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                this.setState({ reservationList: data.response, block: false, error: data.erros });
            }
        }, `/api/Price/Reservation/v1/reservation/sapo?startDate=${startDate}&endDate=${endDate}` + params);
    }

    getReservationDetail = (id) => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.data) {
                    this.setState({ reservationSelected: data.data, modal: true, error: data.erros })
                }
                this.setState(({ block: false }));
            }
        }, '/api/Price/Reservation/v1/' + id);
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })        
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    toggleModal() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    updateTable = (data, type) => {
        this.setState({ block: true });
        var list = [...this.state.reservationList];

        if (type === "profile") {
            const index = this.state.reservationList && this.state.reservationList.indexOf(this.state.reservationList.find(r => r.id === this.state.reservationSelected.id));
            list[index].profile[0].email = data.reservationProfile[0].email;
            list[index].profile[0].givenName = data.reservationProfile[0].givenName;
            list[index].profile[0].surname = data.reservationProfile[0].surname;
        }
        
        if (type === "reservation") {
            const index = this.state.reservationList && this.state.reservationList.indexOf(this.state.reservationList.find(r => r.id === data.id));

            list[index].status = "Canceled";
        }

        if (type === "deposit") {
            const index = this.state.reservationList && this.state.reservationList.indexOf(this.state.reservationList.find(r => r.id === this.state.reservationSelected.id));

            list[index].deposit = data;
        }

        this.setState({ block: false, reservationList: list });
    }

    getDepositStatus = (deposit) => {
        if (deposit && deposit.isReturned) {
            return (
                <Badge className='p-1 px-2 shadow' color='danger' >{this.props.intl.formatMessage({ id: "ReservationList.Returned" })}</Badge>
            )
        }

        if (deposit && !deposit.isReturned) {
            return (
                <Badge className='p-1 px-2 shadow' color='success' >{this.props.intl.formatMessage({ id: "ReservationList.Effected" })}</Badge>
            )
        }

        if (deposit === null) {
            return (
                <Badge className='p-1 px-2 shadow' color='secondary' >{this.props.intl.formatMessage({ id: "ReservationList.NotEffected" })}</Badge>
            )
        }
    }

    render() {
        const columns = [
            {
                dataField: 'resIdValue',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationId" }),
                sort: true
            }, {
                dataField: 'resIdDate',
                text: this.props.intl.formatMessage({ id: "ReservationList.ReservationDate" }),
                formatter: (cell, row) => cell ? <div>{moment(cell).format("YYYY-MM-DD HH:mm:ss")}</div> : '',
                sort: true
            }, {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "SAPOReservations.Hotel" }),
                sort: true
            }, {
                dataField: 'profile[0].givenName',
                text: this.props.intl.formatMessage({ id: "SAPOReservations.GuestName" }),
                formatter: (cell, row) => row.profile && row.profile.length > 0 ? <div> {row.profile[0].givenName} {row.profile[0].surname} </div> : '',
                csvFormatter: (cell, row) => row.profile && row.profile.length > 0 ? `${row.profile[0].givenName} ${row.profile[0].surname}` : '',
                formatExtraData: (cell, row) => row.profile[0].surname,
                sort: true
            },{
                dataField: 'profile[0].email',
                text: this.props.intl.formatMessage({ id: "SAPOReservations.Email" }),
                formatter: (cell, row) => row.profile && row.profile.length > 0 ? <div> {row.profile[0].email}</div> : '',
                sort: true
            }, {
                dataField: 'deposit',
                text: this.props.intl.formatMessage({ id: "ReservationList.Deposit" }),
                formatter: (cell, row) => row.deposit ? this.getDepositStatus(row.deposit) : this.getDepositStatus(null),
                formatExtraData: (cell, row) => row.deposit.isReturned,
                csvFormatter: (cell, row) => `${row.deposit ? row.deposit.isReturned ? this.props.intl.formatMessage({ id: "ReservationList.Returned" }) : this.props.intl.formatMessage({ id: "ReservationList.Effected" }) : this.props.intl.formatMessage({ id: "ReservationList.NotEffected" })}`,
                sort: true
            }, {
                dataField: 'deposit.depositAmount',
                text: this.props.intl.formatMessage({ id: "ReservationList.DepositAmount" }),
                formatter: (cell, row) => row.deposit ? <div className="ml-4"> {Number(row.deposit.depositAmount).toFixed(2)} € </div> : '',
                csvFormatter: (cell, row, rowIndex) => row.deposit && row.deposit.depositAmount ? row.deposit.depositAmount : '',
                sort: true
            },
            {
                dataField: 'amountCommission',
                text: this.props.intl.formatMessage({ id: "ReservationList.Commission" }),
                formatter: (cell, row) => cell ? <FormatAmountNumber value={cell} currency={row.currencyCode ? row.currencyCode : global.hotelCurrency} /> : '',
                sort: true
            },
            {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "ReservationList.Status" }),
                formatter: (cell, row) => <ReservationStatusBadge status={cell}></ReservationStatusBadge>,
                sort: true
            }
        ];

        const statusOptions = [
            {
                value: 'Created',
                label: this.props.intl.formatMessage({ id: "ReservationList.Created" })
            }, {
                value: 'Pending',
                label: this.props.intl.formatMessage({ id: "ReservationList.Pending" })
            }, {
                value: 'Canceled',
                label: this.props.intl.formatMessage({ id: "ReservationList.Canceled" })
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.getReservationDetail(row.id);
            }
        };

        return (
            <div>
                {this.state.modal ?
                    <ReservationDetail
                        modal={this.state.modal}
                        data={this.state.reservationSelected}
                        action={this.toggleModal.bind(this)}
                        updateTable={this.updateTable}
                    />
                :''}
                <StyledCard block={this.state.block} error={this.state.error} icon={'far fa-calendar-alt'} title={'SAPOReservations.Reservations'} help={'TODO'}>
                    <Row>
                        <Col className="pr-1">
                            <SelectHotel
                                name={'hotel'}
                                icon={'fas fa-hotel'}
                                onChangeFunc={this.handleChangeSelect}
                                placeholder={<FormattedMessage id="SAPOReservations.Hotel" />}
                                value={this.state.hotel}
                            />
                        </Col>
                        <Col className="px-1 small">
                            <CustomSelect
                                name="status"
                                small="true"
                                icon={'fas fa-cog'}
                                value={statusOptions.find(el => el.value === this.state.status)}
                                placeholder={<FormattedMessage id="ReservationList.Status" />}
                                options={statusOptions}
                                onChange={this.handleChangeSelect.bind(this, 'status')}
                                isClearable isSearchable
                            />
                        </Col>
                        <Col className="px-1">
                            <InputGroup size="sm text-right " >
                                <InputGroupAddon addonType="prepend"><span className="input-group-text"><span className="fas fa-key fa-fw"></span></span></InputGroupAddon>
                                <FormattedMessage id="ReservationList.ReservationId">
                                    {
                                        placeholder => <Input className="border-left-0" type="text" name="reservationId" maxLength="50" placeholder={placeholder} value={this.state.reservationId} onChange={this.handleChange} />
                                    }
                                </FormattedMessage>
                            </InputGroup>
                        </Col>
                        <Col className="px-1">
                            <InputGroup size="sm text-right" >
                                <InputGroupAddon addonType="prepend"><span className="input-group-text"><span className="fa fa-id-card fa-fw"></span></span></InputGroupAddon>
                                <FormattedMessage id="SAPOReservations.GuestName">
                                    {
                                        placeholder => <Input className="border-left-0" type="text" name="guestName" maxLength="50" placeholder={placeholder} value={this.state.guestName} onChange={this.handleChange} />
                                    }
                                </FormattedMessage>
                            </InputGroup>
                        </Col>
                        <Col className="px-1">
                            <InputGroup size="sm text-right" >
                                <InputGroupAddon addonType="prepend"><span className="input-group-text"><span className="fas fa-at fa-fw"></span></span></InputGroupAddon>
                                <FormattedMessage id="SAPOReservations.Email">
                                    {
                                        placeholder => <Input className="border-left-0" type="text" name="email" maxLength="200" placeholder={placeholder} value={this.state.email} onChange={this.handleChange} />
                                    }
                                </FormattedMessage>
                            </InputGroup>
                        </Col>
                        <Col className="col-3 px-1">
                            <InputGroup size="sm text-right" >
                                <DateRangePicker
                                    startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                    startDateId="your_unique_start_date_id"
                                    isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment().add(6, 'month')}
                                    endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                    endDateId="your_unique_end_date_id"
                                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => this.setState({ focusedInput })}
                                    small={true}
                                    numberOfMonths={1}
                                    showDefaultInputIcon={true}
                                    renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                />
                            </InputGroup>
                        </Col>
                        <Col className="text-right col-1">
                            <button className="btn btn-host btn-sm" onClick={this.getReservations}>
                                <span className="fas fa-search" />
                            </button>
                        </Col>
                    </Row>
                    <hr className="border-host" />
                    <Row>
                        <Col className="col-12">
                            <Card className=" border-0 ">
                                <CustomTable
                                    data={this.state.reservationList ? this.state.reservationList : []}
                                    columns={columns}
                                    totalSize={this.state.reservationList && this.state.reservationList.length}
                                    shadow={false}
                                    exportCSV={true}
                                    selectRow={selectRow}
                                    search={true}
                                    remote={false}
                                />
                            </Card>
                        </Col>
                    </Row>

                </StyledCard>
            </div>
        );
    }
}

export default injectIntl(SAPOReservations)