import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from "../../Base/Notification";
import { getAPI } from "../../Base/API";
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import moment from 'moment';

class PropertyDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			block: false,
			property: null,
		};
	}

	componentDidMount() {
		if (this.props.match.params.id) {
			this.getPropertyDetails();
		}
	}

	getPropertyDetails = () => {
		this.setState({ block: true });
		getAPI(result => {
			const { data, error } = result;

			if (error) {
				var errorMessage = [];
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0) {
					handleNotification(data);
					this.setState(({ block: false, error: errorMessage }));
					return;
				}
				if (data.response && data.response.length > 0) {
					this.setState({ property: data.response[0], block: false });
				}
			}
		}, `/api/hotel/v1/tprnt/${this.props.match.params.id}`);
	}


	render() {
		const { property } = this.state;

		return (
			<div>
				<StyledCard block={this.state.block} title={'PropertyDetails.Title'} error={this.state.error} icon="fas fa-building" help={'TODO'} >
					{property ?
						<>
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="RNTProperties.NrRegisto" />: </b>
								</Col>
								<Col>
									{property.externalId}
								</Col>
							</Row>
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="RNTProperties.Name" />: </b>
								</Col>
								<Col>
									{property.nome}
								</Col>
								<Col className="col-2">
									<b><FormattedMessage id="RNTProperties.Typology" />: </b>
								</Col>
								<Col>
									{property.tipologia}
								</Col>
							</Row>
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="RNTProperties.Brand" />: </b>
								</Col>
								<Col> {property.marca}</Col>
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.EntidadeExploradora" />: </b> 
								</Col>
								<Col> {property.entidadeExploradora} </Col>
							</Row>
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.DataRegisto" />: </b> 
								</Col>
								<Col> {property.dataRegisto ? moment(property.dataRegisto).format('YYYY-MM-DD') : ''} </Col>
								<Col className="col-2">
									<b>ertDrt:</b> 
								</Col>
								<Col> {property.ertDrt}</Col>
							</Row>
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.EstadoClassificacao" />: </b> 
								</Col>
								<Col>{property.estadoClassificacao}</Col>
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.ImovelClassificadoInteresse" />: </b> 
								</Col>
								<Col>{property.imovelClassificadoInteresse} </Col>
							</Row>
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="RNTProperties.Category" />:</b> 
								</Col>
								<Col> {property.categoria} </Col>
							</Row>

							<h5 className="mt-4"> <i className="fas fa-map-marker-alt" /> <FormattedMessage id="PropertyDetails.Address" /> </h5>
							<hr className="mt-1 border-host" />
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.Locality" />: </b> 
								</Col>
								<Col> {property.localidade} </Col>
								<Col className="col-2">
									<b><FormattedMessage id="RNTProperties.District" />: </b> 
								</Col>
								<Col> {property.distrito} </Col>
							</Row>
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="RNTProperties.Concelho" />: </b> 
								</Col>
								<Col> {property.concelho} </Col>
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.PostalCode" />: </b>
								</Col>
								<Col> {property.codPostal} </Col>
							</Row>

							<Row>
								<Col className="col-2">
									<b>Nut II: </b>
								</Col>
								<Col> {property.nutii} </Col>
								<Col className="col-2">
									<b>Nut III: </b>
								</Col>
								<Col> {property.nutiii} </Col>
							</Row>


							<h5 className="mt-4"> <i className="fas fa-phone" /> <FormattedMessage id="PropertyDetails.Contacts" />  </h5>
							<hr className="mt-1 border-host" />
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.MobilePhone" />: </b> 
								</Col>
								<Col> {property.telemovel} </Col>
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.Email" />: </b>
								</Col>
								<Col>  {property.email} </Col>
							</Row>
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.Telephone" />: </b> 
								</Col>
								<Col> {property.telefone} </Col>
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.Fax" />: </b>
								</Col>
								<Col>  {property.fax} </Col>
							</Row>


							<h5 className="mt-4"> <i className="fas fa-building" /> <FormattedMessage id="PropertyDetails.Capacity" />  </h5>
							<hr className="mt-1 border-host" />
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.Capacity" />: </b> 
								</Col>
								<Col> {property.capacidade} </Col>
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.CapacidadeSalasReuniao" />: </b> 
								</Col>
								<Col>{property.capacidadeSalasReuniao} </Col>
							</Row>
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.NrUnidadesAlojamento" />: </b>
								</Col>
								<Col>  {property.nrUnidadesAlojamento} </Col>
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.NrRestaurantes" />: </b>
								</Col>
								<Col> {property.nrRestaurantes} </Col>
							</Row>
							<Row>
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.NrUnidadesAlojamentoMobilidadeReduzida" />: </b> 
								</Col>
								<Col> {property.nrUnidadesAlojamentoMobilidadeReduzida} </Col>
							</Row>


							<h5 className="mt-4"> <i className="fas fa-spa" /> <FormattedMessage id="PropertyDetails.Amenities" /> </h5>
							<hr className="mt-1 border-host" />
							<Row className="mb-2">
								<Col>
									{property.ginasio ? <i style={{ color: "green" }} className="fas fa-check" /> : <i className="fas fa-times" />} Ginásio
								</Col>
								<Col>
									{property.golfe ? <i style={{ color: "green" }} className="fas fa-check" /> : <i className="fas fa-times" />} Golfe
								</Col>
								<Col>
									{property.spa ? <i style={{ color: "green" }} className="fas fa-check" /> : <i className="fas fa-times" />} SPA
								</Col>
							</Row>
							<Row className="mb-2">
								<Col>
									{property.tenis ? <i style={{ color: "green" }} className="fas fa-check" /> : <i className="fas fa-times" />} Tenis
								</Col>
								<Col>
									{property.piscinasExteriores ? <i style={{ color: "green" }} className="fas fa-check" /> : <i className="fas fa-times" />} Piscinas Exteriores
								</Col>
								<Col>
									{property.piscinasInteriores ? <i style={{ color: "green" }} className="fas fa-check" /> : <i className="fas fa-times" />} Piscinas Interiores
								</Col>
							</Row>
							<Row className="mb-2">
								<Col>
									{property.salasReuniao ? <i style={{ color: "green" }} className="fas fa-check" /> : <i className="fas fa-times" />} Salas Reunião
								</Col>
								<Col>
									{property.utilidadeTuristica ? <i style={{ color: "green" }} className="fas fa-check" /> : <i className="fas fa-times" />} Utilidade Turística
								</Col>
								<Col>
									{property.estabSaudavelSeguro ? <i style={{ color: "green" }} className="fas fa-check" /> : <i className="fas fa-times" />} Estab Saudável Seguro
								</Col>
							</Row>
							<Row className="mb-2">
								<Col>
									{property.outras ? <i style={{ color: "green" }} className="fas fa-check" /> : <i className="fas fa-times" />} outras
								</Col>
							</Row>


							<h5 className="mt-4"> <i className="fas fa-certificate" /> <FormattedMessage id="PropertyDetails.Certificates" /> </h5>
							<hr className="mt-1 border-host" />
							<Row className="mb-2">
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.TipoCertificacaoAmbiental" />: </b> 
								</Col>
								<Col> {property.tipoCertificacaoAmbiental} </Col>
								<Col className="col-2">
									<b><FormattedMessage id="PropertyDetails.TipoCertificacaoQualidade" />: </b> 
								</Col>
								<Col> {property.tipoCertificacaoQualidade} </Col>
							</Row>

						</>
					: ''}
				</StyledCard>
			</div>
		);
	}
}

export default injectIntl(PropertyDetails);