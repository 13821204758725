import React, { Component } from 'react';
import { Row, Col, FormGroup, Label, Input, Button, Card, CardBody, CardHeader } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';

export class AddOccupancy extends Component {
    constructor(props) {
        super(props);
        this.setName = this.setName.bind(this);
        this.checkSelectChildren = this.checkSelectChildren.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.form = React.createRef();
        this.state = {
            adults: ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen', 'Twenty'],
            occupancy: {
                name: null,
                adults: null,
                childrens: 0,
                isBaseOccupancy: false,
                derivedType: null,
                derivedAmount: null
            }
        };
    }

    setName(evt) {
        const newName = evt.target && evt.target.value
        this.setState(prevState => (
        {
            occupancy: {
                ...prevState.occupancy,
                name: newName
            }
        }));   
    }

    setField(name, combo) {
        const occName = combo ? `${this.state.adults[this.state.occupancy.adults - 1]} Adults + ${combo.value} Children` : `${this.state.adults[this.state.occupancy.adults - 1]} Adults`;

        this.setState(prevState => (
        {
            occupancy: {
                ...prevState.occupancy,
                [name]: combo ? combo.value : null,
                name: occName
            }
        }));             
    }

    setAdults(name, combo) {
        const adults = combo ? combo.value : 1;
        const childrens = this.getValidChildren(adults);
        const occName = childrens.length > 0 && childrens[0].value !== 0 ? `${this.state.adults[adults - 1]} Adults + ${childrens[0].value} Children` : `${this.state.adults[adults - 1]} Adults`;

        this.setState(prevState => (
        {
            occupancy: {
                ...prevState.occupancy,
                adults: adults,
                childrens: childrens.length > 0 ? childrens[0].value : null,
                name: occName
            }
        }));
    }

    getOptionToSelect(number) {
        return {
            value: number,
            label: number
        };
    }

    getValidPaxs(adults) {
        return this.props.defaultPax && this.props.defaultPax.filter(defaultPax => defaultPax.adults === adults);
    }

    getValidChildren(adults) {
        const paxs = this.getValidPaxs(adults);
        const maxChildren = 6;
        return Array.from({ length: maxChildren }, (v, i) => this.getOptionToSelect(i)).filter(item => paxs && paxs.find(pax => pax.childrens === item.value) == null);
    }

    getValidAdults() {
        const { defaultPax } = this.props;

        const maxAdults = 10;
        const defaultAdults = Array.from({ length: maxAdults }, (_, i) => this.getOptionToSelect(i + 1)).filter(adult => this.getValidChildren(adult.value).length > 0);
        const overTenAdults = Array.from({ length: maxAdults }, (_, i) => this.getOptionToSelect(i + 1 + maxAdults)).filter(adult => {
            const defaultPaxOverTenAdults = defaultPax.filter(pax => pax.adults > maxAdults);
            return (defaultPaxOverTenAdults.length === 0 || defaultPaxOverTenAdults.some(pax => pax.adults === adult.value)) && this.getValidChildren(adult.value).length > 0;
        });

        return defaultAdults.concat(overTenAdults);
    }

    checkSelectChildren() {
        return this.props.defaultPax.find(defaultPax => defaultPax.adults === this.state.occupancy.adults) == null;
    }

    handleCreate(event) {
        event.preventDefault();        
        if (this.form.current.reportValidity()) {
            this.props.addNewOccupancy(this.state.occupancy);
        }
    }

    render() {
        return (
            <Card className="border-0 shadow mb-2">
                <CardHeader className="bg-white border-bottom ">
                    <strong><FormattedMessage id="EditRate.NewOccupancy" /></strong>
                    <Button color=" btn-host btn-sm float-right" onClick={this.handleCreate} >
                        <i className="fas fa-plus" />
                    </Button>
                </CardHeader>
                <CardBody>
                    <form ref={this.form} >
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label for="name" sm={2}>
                                        <FormattedMessage id="EditRate.Name" />
                                    </Label>
                                    <Col sm={10}>
                                        <FormattedMessage id="EditRate.Name">
                                            {placeholder => <Input type="text" required name="name" id="occupancyName" placeholder={placeholder} onChange={this.setName.bind(this)} value={this.state.occupancy.name || ''} />}
                                        </FormattedMessage>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label for="adults" sm={4}>
                                        <FormattedMessage id="EditRate.Adults" />
                                    </Label>
                                    <Col sm={8}>
                                        <CustomSelect required name="adults" id="occupancyAdults" options={this.getValidAdults()} placeholder={<FormattedMessage id="EditRate.Adults" />} onChange={this.setAdults.bind(this, 'adults')} />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup row>
                                    <Label for="childrens" sm={4}>
                                        <FormattedMessage id="EditRate.Children" />
                                    </Label>
                                    <Col sm={8}>
                                        <CustomSelect required name="childrens" id="occupancyChildrens" options={this.getValidChildren(this.state.occupancy.adults)} value={this.getOptionToSelect(this.state.occupancy.childrens)} placeholder={<FormattedMessage id="EditRate.Children" />} onChange={this.setField.bind(this, 'childrens')} isDisabled={this.checkSelectChildren()} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Card>    
        );
    }
}
export default injectIntl(AddOccupancy)