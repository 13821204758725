import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';

export default class NewPageComponent extends Component {
    render() {
        const { handleChangeNewBulkVersion } = this.props;
        return (
            <div className="px-4 pb-2">
                <div onClick={handleChangeNewBulkVersion} id="bulkUpdateHelp" style={{ fontSize: '0.8em' }} className="text-right">
                    <i style={{ color: '#ffd968' }} className="fas fa-star mr-1"/>
                    <FormattedMessage id="Inventory.NewVersion"/>
                    <span style={{ color: '#007bff', cursor: 'pointer', textDecoration: 'underline' }} className="ml-1">
                        <FormattedMessage id="Inventory.TryNewVersion"/>
                    </span>
                </div>
            </div>
        )
    }
}