import React, { Component } from 'react'
import { Col, Progress } from 'reactstrap';
import CustomToolTip from '../../../Utils/CustomToolTip';

export default class EditableRoomAvailabilityBox extends Component {
    
    constructor(props) {
        super(props);
        this.toolTip = React.createRef();
        this.state = {
            changedData: false,
            value: this.props.dayAvailability?.freeRooms
        };
    }

    componentWillReceiveProps(newProps) {
        const { dayAvailability, newInv } = this.props;
        let { value, changedData } = this.state;
        if(value !== newProps.dayAvailability?.freeRooms && changedData === false && (newProps.newInv !== newInv || (!newProps.newInv && !newInv))){
            const newValue = newProps.dayAvailability?.freeRooms;
            this.setState({ value: newValue });
        }
        if(parseInt(value) !== dayAvailability?.freeRooms && parseInt(value) === newProps.dayAvailability?.freeRooms){
            this.setState({ changedData: false });
        }
    }

    dragEnter = () => {
        const { dragObj, rowkey } = this.props;
        if(!dragObj) return;
        if(dragObj.rowkey !== rowkey) return;
        if(!dragObj.isPrice) return;

        this.onInputChange({target:{value: dragObj.value }}, true);
    }

    dragOver = (e) => {
        const { dragObj, rowkey } = this.props;
        if(!dragObj) return;
        if(dragObj.rowkey !== rowkey) return;
        if(!dragObj.isPrice) return;
        e.preventDefault();
    }
    
    dragEnd = (e) => {
        const { dragObj, rowkey, dragStart } = this.props;
        const { value } = this.state

        if(!dragObj) return;
        if(dragObj.rowkey !== rowkey) return;
        if(!dragObj.isPrice) return;

        this.onInputChange({target:{ value: value }}, true);
        dragStart();
    }

    handleChange = (e) => {
        const { roomCatId, dayAvailability, handleInventoryChange } = this.props;

        const newValue = !e.target.value ? '0' : e.target.value;

        this.setState({ value: newValue }, () => {
            handleInventoryChange(newValue, dayAvailability?.hotelDate, roomCatId)
        });
    }

    numbersonly(value){
        if((value && isNaN(value) && value !== '-') || (value % 1 !== 0 && value !== '-') || (typeof value === "string" && (value?.includes('.') || value?.includes(',')))) return false;
        return true;
    }

    onInputChange = (e) => {
        const { dayAvailability, handleInventoryChange, roomCatId, day } = this.props;
        const value = e?.target?.value;
        if(this.numbersonly(value)){
            const newValue = !value ? '0' : value;
            this.setState({ value: newValue, changedData: value !== dayAvailability?.freeRooms }, () => handleInventoryChange(newValue, day.format('YYYY-MM-DD'), roomCatId));
        }
    }

    keyDown = (e) => {
        const { value } = this.state;

        if(!e?.key) return;
        if(e.key === "Tab") return;

        let keyValue = -1;

        if(e.key === "ArrowUp") keyValue = 1;
        else if(e.key !== "ArrowDown") return;

        e.preventDefault();
        
        const newValue = parseInt(value??'0') + parseInt(keyValue);
        this.onInputChange({ target: { value : newValue } });
        return;
    }

    render() {
        const { calendar, dayAvailability, day, isHost, dragStart, rowkey, colkey } = this.props;
        const { value, changedData } = this.state;
        
        var classCol = "position-top px-2 border-left ";
        
        if(!calendar){
            // classCol += " max-with-14Col ";
        }

        if (day) {
            if (day.isoWeekday() === 6 || day.isoWeekday() === 7) {
                if (this.state.availability !== this.state.availabilityOrig) {
                    classCol += !changedData ? "bg-primary" : "";
                } else {
                    classCol += `${!changedData ? "bg-light-dark" : ""} text-secondary`;
                }

            }
            else {
                if (this.state.availability !== this.state.availabilityOrig) {
                    classCol += `${!changedData ? "bg-primary" : ""}`
                } else {
                    classCol += `${!changedData ? "bg-light" : ""} text-secondary`;
                }
            }
        }

        let percentage = 100, inventory = 0, freeRooms = 0;
        const color = {};
        const overBookingGradient = 'linear-gradient(35deg, rgba(217,83,79,1) 0%, rgba(0,0,0,1) 5%, rgba(217,83,79,1) 10%, rgba(0,0,0,1) 15%, rgba(217,83,79,1) 20%, rgba(0,0,0,1) 25%, rgba(217,83,79,1) 30%, rgba(0,0,0,1) 35%, rgba(217,83,79,1) 40%, rgba(0,0,0,1) 45%, rgba(217,83,79,1) 50%, rgba(0,0,0,1) 55%, rgba(217,83,79,1) 60%, rgba(0,0,0,1) 65%, rgba(217,83,79,1) 70%, rgba(0,0,0,1) 75%, rgba(217,83,79,1) 80%, rgba(0,0,0,1) 85%, rgba(217,83,79,1) 90%, rgba(0,0,0,1) 95%, rgba(217,83,79,1) 100%)';

        if (isHost && dayAvailability) {
            inventory = dayAvailability?.inventory - dayAvailability?.outOfInventory - dayAvailability?.outOfOrder;
            freeRooms = inventory - dayAvailability?.freeRooms;
            if (dayAvailability && dayAvailability.inventory > 0) {
                percentage = parseInt((freeRooms / inventory) * 100);
            }
        }
        if (0 <= percentage && percentage < 33) {
            color.color = 'success';
            color.hex = '#5cb85c';
        }
        else if (33 <= percentage && percentage < 66) {
            color.color = 'warning';
            color.hex = '#f0ad4e';
        }
        else if (66 <= percentage && percentage <= 100) {
            color.color = 'danger';
            color.hex = '#d9534f';
        }
        else if (percentage > 100) {
            color.hex = overBookingGradient;
        }

        return (
            <Col className={classCol} style={{ minWidth: '60px', background: changedData ? '#ddeeff' : '' }}>
                <div className="text-center font-weight-bold text-uppercase ">
                <span>
                    {global.operationMode === 'None' || (global.operationMode === 'PmsLimited' && global.modules.includes('UpdateAvail')) ?
                        <input
                            className={`hide-input-arrows text-center border-0 ${value && parseInt(value) < 0 ? "text-danger" : ''}`}
                            value={value?.toString() || '-'}
                            onChange={this.onInputChange}
                            onKeyDown={this.keyDown}
                            type="text"
                            inputMode="numeric"
                            max={'999'}
                            min={'-999'}
                            style={{
                                width: "100%",
                                outline: 'none',
                                background: 'transparent'
                            }}
                            onDragEnter={this.dragEnter}
                            onDragStart={e => dragStart(e, value, rowkey, colkey, false, true)}
                            onDragOver={this.dragOver}
                            onDragEnd={this.dragEnd}
                        />
                    :
                        <h6 className={`cursor-pointer mb-0 ${dayAvailability?.freeRooms && dayAvailability?.freeRooms < 0 ? `text-danger` : ``}`}>{dayAvailability?.freeRooms??'-'}</h6>
                    }
                    {isHost && dayAvailability?.freeRooms ?
                        <div>
                            <div ref={this.toolTip} id={`availabilityPercentage${dayAvailability?.id}`}>
                                {percentage > 100 ?
                                    <div
                                        style={{
                                            background: overBookingGradient,
                                            width: '100%',
                                            height: '5px',
                                            borderRadius: '10px',
                                        }}
                                    />
                                :
                                    <Progress
                                        color={color.color}
                                        value={percentage + 1}
                                        style={{
                                            maxHeight: '5px',
                                        }}
                                    />
                                }
                            </div>
                            <CustomToolTip targetRef={this.toolTip} placement="right" target={`availabilityPercentage${dayAvailability?.id}`}>
                                {inventory > 0 ?
                                    <>
                                        <i className={`far fa-square mr-2`} style={{ background: color.hex }} /> {percentage}%
                                    </>
                                :''}
                                <div className="d-flex align-items-center"> <i className="fas fa-hashtag fa-xs mr-2 pr-1 ml-1" /> {freeRooms}/{inventory} </div>
                            </CustomToolTip>
                        </div>
                    :<div/>
                    }
                </span>
                </div>
            </Col>
        );

    }
}
