import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, ModalHeader, Form, Card, CardBody, Button, Label, FormGroup } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from "../../Base/API"
import BlockUi from 'react-block-ui';
import CustomSelect from '../../Base/Common/CustomSelect';
import { ErrorAlert } from "../../Common/ErrorAlert";


class AddRate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: {},
            error: null,
            block: true,
            ratesList: [],
            rate: [],
            areAllSelected: false
        }
    }

    componentDidMount() {
        this.getRates();
    }

    getRates = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    if (this.props.mappedRates) { //Quando vem da pág be config, mostrar apenas as rates que estão mapeadas
                        data.response = data.response.filter(el => this.props.mappedRates.some(mr => mr.id === el.id));
                    }

                    const active = [];
                    const inactive = [];

                    data.response
                        .filter(item => !this.props.usedRates.some(usedRate => usedRate.value === item.id))
                        .sort((a, b) => a.name > b.name ? 1 : -1)
                        .forEach(item => {
                            if (item.active) {
                                active.push({ 'label': `${item.name}`, 'value': item.id, active: true });
                            }
                            else {
                                inactive.push({ 'label': `${item.name}`, 'value': item.id, active: false });
                            }
                        }
                    )

                    const combo = [
                        {
                            label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="generic.active" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{active.length}</span> </div >,
                            options: active
                        },
                        {
                            label: <div className="d-flex justify-content-between" > <span><FormattedMessage id="generic.inactive" /></span> <span className="px-2" style={{ backgroundColor: '#EBECF0', borderRadius: '20px' }}>{inactive.length}</span> </div >,
                            options: inactive
                        },
                    ];

                    this.setState({ block: false, ratesList: combo });
                }
                else {
                    this.setState(({ block: false }));
                }
            }
        }, '/api/Rate/Rate/v1');
    }

    handleAllRates = (isToRemove) => {
        const { ratesList } = this.state;

        let rates = [], areAllSelected = false;

        if (!isToRemove) {
            rates = ratesList.flatMap(rl => rl.options);
            areAllSelected = true;
        }

        this.setState({ rate: rates, areAllSelected });
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo : []
        });
    };

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>
                    <FormattedMessage id="AddRate.AssociateRate" />
                </ModalHeader>
                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={(e) => this.props.addRate(e, null, this.state.rate, true)}>
                            <Card className="shadow border-0 p-0">
                                <CardBody className="m-0">
                                    <Row className="mb-2">
                                        <Col className="text-right">
                                            <Button type="submit" className="btn btn-sm btn-host float-right">
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup sm="true" row>
                                                <Label className="col-12 col-xl-2">
                                                    <FormattedMessage id="AddRate.Rate" />
                                                </Label>
                                                <Col className="col-12 col-xl-8 mb-2">
                                                    <CustomSelect
                                                        options={this.state.ratesList}
                                                        placeholder={<FormattedMessage id="AddRate.ChooseRate" />}
                                                        onChange={this.handleChangeSelect.bind(this, 'rate')}
                                                        value={this.state.rate}
                                                        isClearable
                                                        isSearchable
                                                        required
                                                        isMulti={true}
                                                    />
                                                </Col>
                                                <Col className="col-6 col-xl-2">
                                                    <Button className="btn btn-host btn-sm btn-block" style={{ height: '35px' }} onClick={() => this.handleAllRates(this.state.areAllSelected)}>
                                                        {this.state.areAllSelected ? <FormattedMessage id="generic.RemoveAll" /> : <FormattedMessage id="AddRule.SelectAll" />}
                                                    </Button>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(AddRate)