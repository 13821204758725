import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';
import { DropdownItem } from 'reactstrap';
import Authorization from '../Base/Authorization';

export default class MenuEntry extends Component {
    render() {
        const { id, isNewPage, isAdminOnly, Link, to, toggleNavbar } = this.props;

        return (
            isAdminOnly ?
                <Authorization
                    perform="admin:view"
                    yes={() => (
                        <DropdownItem onClick={toggleNavbar} tag={Link} to={to}>
                            <div className='d-flex align-items-center justify-content-space-between'></div>
                            <FormattedMessage id={id}/>
                            <i className="fas fa-exclamation-circle p-1" style={{ color: 'red' }}/>
                        </DropdownItem>
                    )}
                />
            :
                <DropdownItem onClick={toggleNavbar} tag={Link} to={to}>
                    <div className='d-flex align-items-start' id="newFeature">
                        <FormattedMessage id={id}/>
                        {isNewPage ?
                            <div>
                                <div style={{ fontSize: '0.6em', fontWeight: 'bold', letterSpacing: '-1px', position: 'absolute', marginLeft: '-5px', color: '#165C7D', marginTop: '-5px' }}>
                                    New
                                </div>
                            </div>
                        :''}
                    </div>
                </DropdownItem>
        )
    }
}
