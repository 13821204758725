import React, { Component } from 'react';
import { Row, Col, Form, Button, Input, Tooltip, ButtonGroup, Modal, ModalBody } from 'reactstrap';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { getAPI, putAPI } from "../Base/API";
import { handleNotification } from "../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import { SelectRoomCategory, StyledCard } from '../Base/Common/CommonUIComponents';
import AvailabilityCalendar from './AvailabilityCalendar';
import { getPercentage } from '../Base/Common/ReferenceDataFunctions';


export class BulkUpdateAvailability extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            modal: false,
            selectedRate: null,
            startDate: null,
            endDate: null,
            weekdays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            rateCodes: [],
            rates: [],
            focusedMonth: null,
            currAvailabilities: undefined,
            calendarMonths: [],
            availabilityChanges: [],
            numberOfRooms: null,
            validWeekDays: [
                {
                    value: "Mon",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysMon" />,
                    weekday: 0
                },
                {
                    value: "Tue",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysTue" />,
                    weekday: 1
                },
                {
                    value: "Wed",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysWed" />,
                    weekday: 2
                },
                {
                    value: "Thu",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysThu" />,
                    weekday: 3
                },
                {
                    value: "Fri",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysFri" />,
                    weekday: 4
                },
                {
                    value: "Sat",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSat" />,
                    weekday: 5
                },
                {
                    value: "Sun",
                    label: <FormattedMessage id="BookingPromotionCard.AvailableWeekDaysSun" />,
                    weekday: 6
                }
            ]
        }
    }

    componentDidMount() {
        this.getQueryStringValues();
    }


    getAvailability = () => {
        this.setState({ block: true });
        const { startDate, endDate, selectedRoom } = this.state;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data[0] && data[0].availability) {
                    data[0].availability = data[0].availability.sort((a, b) => moment(a.hotelDate).isBefore(moment(b.hotelDate)) ? -1 : 1).map((av, key) => {
                        const ava = av;
                        ava.blockId = data[0].roomBlock;
                        ava.id = key;
                        return (ava);
                    });

                    this.setState({ currAvailabilities: data[0], availabilityChanges: [], block: false }, () => this.updateQueryString());

                    this.buildCalendarStruct(startDate, endDate);
                }
                else {
                    this.setState({ block: false, currAvailabilities: {} });
                }
            }
            else {
                this.setState({ block: false });
            }
        }, `/api/Price/Availability/v1/Availability/RoomCategory/${selectedRoom}?startDate=${startDate.format("YYYY-MM-DD")}&endDate=${endDate.format("YYYY-MM-DD")}`);
    }

    saveAvailability = (event) => {
        event.preventDefault();
        this.setState({ block: true });

        let req = {
            roomCategoryId: this.state.selectedRoom,
            blockId: 0,
            weekdays: this.state.weekdays,
            dates: this.state.availabilityChanges,
        };

        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="BulkUpdate.AvailabilityUpdatedSucessfuly" />, <FormattedMessage id="General.Success" />);
                    this.updateDayAvailability(this.state.availabilityChanges[0].startDate, this.state.availabilityChanges[0].endDate, this.state.availabilityChanges[0].freeRooms);
                    this.getAvailability();
                }
            }
            this.setState({ error: errorMessage, block: false });
        }, '/api/Price/Availability/v1/AvailabilityNDays', req);
    }

    doSearch = () => {
        if (this.state.availabilityChanges && this.state.availabilityChanges.length > 0) {
            this.toggleModal();
        }
        else this.getAvailability();
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleRoomChange = (name, combo) => {
        this.setState({
            selectedRoom: combo ? combo.value : null,
            numberOfRooms: combo && combo.limitAvailability ? combo.numberOfRooms : null
        });
    }

    getDays = () => {
        let startDate = this.state.startDate;
        const endDate = this.state.endDate;

        const numberOfDays = moment(endDate).diff(moment(), 'days') <= 30 ? moment(endDate).diff(moment(), 'days') : 30;

        var days = [];

        for (var i = 0; i < numberOfDays; i++) {
            days.push(moment(startDate).add(i, 'days'));
        }

        return (days);
    }

    renderCell = (dayAvailability, key) => {
        const { numberOfRooms } = this.state;
        const id = `editAvailability${dayAvailability.id}`;
        const state = this.state[id];

        return (
            <div className="text-center h-75" onClick={() => this.selectCalendarDay(id, dayAvailability)} style={{ padding: '10% 10%' }} key={key}>
                <div>
                    {!state && dayAvailability.freeRoomsChanged ?
                        <div style={{backgroundColor: '#17a2b8', color: 'white', borderRadius: '20px'}}>
                             <b>{dayAvailability.freeRoomsChanged}</b>
                        </div>
                    : state ?
                        <input
                            type="number"
                            id={id}
                            className="hide-input-arrows"
                            style={{ height: '50%', width: '70%', textAlign: 'center', border: '2px solid #165C7D11', borderRadius: '5px !important' }}
                            onBlur={(e) => this.handleFreeRoms(e, id, dayAvailability)}
                            onKeyDown={(e) => this.onKeyDown(e, id, dayAvailability)}
                            min={0}
                            max={numberOfRooms}
                        />
                        :
                        <span style={{ fontSize: '17px' }} id={`FreeRooms${dayAvailability.id}`} className={`mb-0 px-1 ${dayAvailability.freeRooms && dayAvailability.freeRooms < 0 ? `text-danger` : `text-info`}`}>
                            <b>{dayAvailability.freeRooms}</b>
                        </span>
                    }
                </div>
            </div>
        );
    }

    selectCalendarDay = (id, dayAvailability) => {
        if (!dayAvailability.freeRoomsChanged) {
            this.setState({ [id]: true, focusedMonth: moment(dayAvailability.hotelDate).format('MMMM') }, () => {
                if (document.getElementById(id)) {
                    document.getElementById(id).focus();
                }
            });
        }
    }

    onKeyDown = (e, id, dayAvailability) => {
        if (e.which === 13)
            this.handleFreeRoms(e, id, dayAvailability);


        if (e.which === 9) {
            this.handleFreeRoms(e, id, dayAvailability)
            this.setState({
                [id]: false,
                [`editAvailability${dayAvailability.id + 1}`]: true,
                focusedMonth: moment(dayAvailability.hotelDate).format('MMMM')
            });
        }
    }

    handleFreeRoms = (e, id, dayAvailability) => {
        if (e.target && e.target.value >= 0 && (this.state.numberOfRooms ? e.target.value <= this.state.numberOfRooms : true)) {
            this.setState({
                [id]: false,
                bulkStartDate: moment(dayAvailability.hotelDate),
                bulkAvail: e.target.value
            }, this.addAvailChanges);
        }
        else {
            this.setState({ [id]: false  });
        }
    }

    buildCalendarStruct = (startDate, endDate) => {
        var months = [startDate.format("YYYY-MM-DD")];
        var calendarMonths = [];

        let start = moment(startDate).add(1, 'month');
        while (moment(start).isSameOrBefore(endDate, 'month')) {
            months.push(start.format("YYYY-MM-DD"));
            start = start.add(1, 'month');
        }

        months.forEach(month => {
            let blank = this.getBlankCells(month);
            let daysInMonth = this.getDaysInMonth(month);
            let totalSlots = [...blank, ...daysInMonth];
            let cells = [], rows = [];

            totalSlots.forEach((row, i) => {
                if (i % 7 !== 0) {
                    cells.push(row); // if index not equal 7 that means not go to next week
                } else {
                    rows.push(cells); // when reach next week we contain all td in last week to rows 
                    cells = []; // empty container 
                    cells.push(row); // in current loop we still push current row to new container
                }
                if (i === totalSlots.length - 1) { // when end loop we add remain date
                    while (cells.length < 7) {
                        cells.push({ day: "" })
                    }
                    rows.push(cells);
                }
            });

            calendarMonths.push(rows);
        })

        this.setState({ calendarMonths });
    }

    getFirstDayOfMonth = (month) => {
        let dateObject = month;

        let firstDay = moment(dateObject)
            .startOf("month")
            .format("d");
        return firstDay;
    };

    getDaysInMonth = (month) => {
        let daysInMonth = [];

        for (let d = 1; d <= moment(month).daysInMonth(); d++) {
            let date = `${moment(month).format('YYYY')}-${moment(month).format('MM')}-${d}`;
            daysInMonth.push({
                day: d,
                moment: moment(date)
            });
        }

        return daysInMonth;
    }

    getBlankCells = (month) => {
        let blanks = [];

        for (let i = 0; i < this.getFirstDayOfMonth(month); i++) {
            blanks.push({
                day: ""
            });
        }

        return blanks
    }

    addAvailChanges = (e) => {
        if (e) e.preventDefault();

        const { availabilityChanges, bulkStartDate, bulkEndDate, bulkAvail } = this.state;

        if (bulkAvail) {
            const formattedStartDate = bulkStartDate?.format("YYYY-MM-DD");

            availabilityChanges.push({
                startDate: formattedStartDate,
                endDate: bulkEndDate ? bulkEndDate.format("YYYY-MM-DD") : formattedStartDate,
                freeRooms: bulkAvail
            });

            this.updateDayAvailability(bulkStartDate, bulkEndDate, bulkAvail);

            this.setState({ availabilityChanges, bulkStartDate: null, bulkEndDate: null, bulkAvail: null, firstAvailableDate: this.state.startDate, lastAvailableDate: this.state.endDate });
        }
    }

    updateDayAvailability = (startDate, endDate, avail) => {
        const { currAvailabilities } = this.state;
        const index = currAvailabilities.availability ? currAvailabilities.availability.findIndex(el => moment(el.hotelDate).format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD")) : -1;

        if (index !== -1) {
            currAvailabilities.availability[index].freeRoomsChanged = avail;
        } else {
            let newAvail = {
                freeRooms: parseInt(avail),
                hotelDate: moment(startDate).format("YYYY-MM-DD"),
                inventory: parseInt(avail),
            }
            currAvailabilities.availability.push(newAvail);
        }

        if (endDate) {
            var start = moment(startDate);

            while (start.add(1, 'day').diff(endDate, 'days') <= 0) {
                const index = currAvailabilities.availability ? currAvailabilities.availability.findIndex(el => moment(el.hotelDate).format("YYYY-MM-DD") === start.format("YYYY-MM-DD")) : -1;
                
                if (index !== -1) {
                    currAvailabilities.availability[index].freeRoomsChanged = avail;
                } else {
                    let newAvail = {
                        freeRooms: parseInt(avail),
                        hotelDate: start.format("YYYY-MM-DD"),
                        inventory: parseInt(avail),
                    }
                    currAvailabilities.availability.push(newAvail);
                }
            };
        }

        this.setState({ currAvailabilities });
    }

    isOutsideRange = (day) => {
        const { firstAvailableDate, lastAvailableDate } = this.state;

        return day < firstAvailableDate || day > lastAvailableDate;
    }

    isDayBlocked = (day) => {
        const { currAvailabilities, availabilityChanges } = this.state;

        if(!availabilityChanges || availabilityChanges.length === 0) return false;

        if(!currAvailabilities.availability)
            return availabilityChanges?.find(({startDate, endDate}) => 
                day.isSameOrBefore(endDate) && day.isSameOrAfter(startDate)
            );

        return availabilityChanges.find(({startDate, endDate}) => day.isSameOrAfter(startDate, 'day') && day.isSameOrBefore(endDate, 'day'));
    }

    handleBulkDates = (startDate, endDate) => {
        let firstAvailableDate =this.state.startDate;
        let lastAvailableDate = this.state.endDate
        const availability = this.state.currAvailabilities?.availability ? [ ...this.state.currAvailabilities.availability ] : [];

        if (startDate /*&& !endDate*/) {
            const firstBlockedDay = availability.find(av => av.freeRoomsChanged);

            if (startDate < firstBlockedDay) { //startDate antes do 1º dia bloqueado
                lastAvailableDate = moment(firstBlockedDay.hotelDate);
            }
            else {
                const firstBlockedBeforeStartDate = [...availability].reverse().find(av => moment(av.hotelDate).format('YYYY-MM-DD') < startDate.format('YYYY-MM-DD') && av.freeRoomsChanged);
                const firstBlockedAfterStartDate = availability.find(av => moment(av.hotelDate).format('YYYY-MM-DD') > startDate.format('YYYY-MM-DD') && av.freeRoomsChanged);


                if (firstBlockedBeforeStartDate) {
                    firstAvailableDate = moment(firstBlockedBeforeStartDate.hotelDate);
                }

                if (firstBlockedAfterStartDate) {
                    lastAvailableDate = moment(firstBlockedAfterStartDate.hotelDate);
                }
            }
        }

        this.setState({ bulkStartDate: startDate, bulkEndDate: endDate, firstAvailableDate, lastAvailableDate });
    }

    removeChanges = (index, startDate, endDate) => {
        const { availabilityChanges } = this.state;

        availabilityChanges.splice(index, 1);

        this.setState({ availabilityChanges }, () => this.updateDayAvailability(startDate, endDate, null));
    }

    onClickWeekDays = (weekDay) => {
        const weekdays = [...this.state.weekdays];
        const index = weekdays.findIndex(wd => wd === weekDay);

        if (index !== -1) {
            weekdays.splice(index, 1);
        }
        else {
            weekdays.push(weekDay);
        }

        this.setState(({ weekdays }));
    }

    updateQueryString = () => {
        const { selectedRoom, startDate, endDate } = this.state;

        var queryString = "?";
        

        if (selectedRoom && !queryString.includes('roomCategoryId')) {
            queryString+=`roomCategoryId=${selectedRoom}&`
        }

        if (startDate && endDate && !queryString.includes('startDate') && !queryString.includes('endDate')) {
            if(startDate.isAfter(moment(), 'day') ){
                queryString+=`startDate=${startDate.format("YYYY-MM-DD")}&`;
            }else{
                queryString+=`startDate=${moment().format("YYYY-MM-DD")}&`;
            }
            
            if(moment() <= moment().add(1, 'years')){
                queryString+=`endDate=${endDate.format("YYYY-MM-DD")}`;
            }else{
                queryString+=`endDate=${ moment().add(1, 'years').format("YYYY-MM-DD")}`;
            }
        }

        if(queryString !== "?"){
            this.props.history.push(queryString);
        }
    }

    getQueryStringValues = () => {
        const params = new URLSearchParams(this.props.location.search);
    
        const selectedRoom = params.get('roomCategoryId');
        let startDate = params.get('startDate') ? moment(params.get('startDate')) : null;
        let endDate = params.get('endDate') ? moment(params.get('endDate')) : null;
        
        const today = moment();
        const oneYearFromNow = moment().add(1, 'years');
    
        if (startDate && startDate.isValid()) {
            if (startDate.isBefore(today, 'day')) {
                startDate = today;
            }
        } else {
            startDate = null;
        }
    
        if (endDate && endDate.isValid()) {
            if (endDate.isAfter(oneYearFromNow, 'day')) {
                endDate = oneYearFromNow.subtract(1, 'day');
            }
        } else {
            endDate = null;
        }
    
        if (selectedRoom && !isNaN(selectedRoom)) {
            if (startDate && endDate) {
                this.setState({ selectedRoom, startDate, endDate }, () => { this.getAvailability() });
            } else {
                this.setState({ selectedRoom });
            }
        }else if(startDate && endDate){
            this.setState({ startDate, endDate });
        }
    }

    render() {
        const { block, error, selectedRoom, currAvailabilities, startDate, weekdays, endDate, selectdayAvailability, selectedk, calendarMonths, availabilityChanges, validWeekDays, focusedMonth } = this.state;
        
        return (
            <StyledCard block={block} error={error} title="navbar.BulkUpdateAvailability">
                <form ref={this.formCommon} >
                    <Row className="align-items-center text-muted small">
                        <Col sm={3}>
                            <span> <FormattedMessage id="MajorBulkUpdateModal.Category" /> </span>
                        </Col>
                        <Col className="ml-5 pl-5 col-3">
                            <span> <FormattedMessage id="BulkUpdate.dates" /> </span>
                        </Col>
                        <Col sm={3}>
                            <span> <FormattedMessage id="MajorBulkUpdateModal.Weekdays" /> </span>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col className="col-3">
                            <SelectRoomCategory
                                icon={'fa fa-bed fa-fw'}
                                name={'roomCategoryId'}
                                isMulti={false}
                                onChangeFunc={this.handleRoomChange}
                                placeholder={<FormattedMessage id="AnalyticsDashboard.SelectRoomCategory" />}
                                value={selectedRoom}
                            />
                        </Col>
                        <Col className="ml-5 pl-5 col-3">
                            <DateRangePicker
                                required
                                startDate={startDate ? moment(startDate).locale(moment.locale()) : startDate}
                                startDateId="reststartDate"
                                isOutsideRange={day => day.isBefore(moment(), 'day') || day > moment().add(1, 'years')}
                                endDate={endDate ? moment(endDate).locale(moment.locale()) : endDate}
                                endDateId="restsendDate"
                                onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate, firstAvailableDate: startDate, lastAvailableDate: endDate })}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                small={true}
                                numberOfMonths={2}
                                showDefaultInputIcon={true}
                                renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                            />
                        </Col>
                        <Col className="col-3" style={{ display: 'flex', alignItems: 'center' }}>
                            <ButtonGroup name="weekdays" size="sm">
                                {validWeekDays && validWeekDays.map((weekDay, key) =>
                                    <Button key={key} className={this.state.weekdays.find(el => el === weekDay.value) ? 'btn btn-host' : 'btn'} onClick={() => this.onClickWeekDays(weekDay.value)}>
                                        {weekDay.label}
                                    </Button>
                                )}
                            </ButtonGroup>
                        </Col>
                        <Col className="col">
                            <Button className="btn btn-sm btn-host float-right shadow" onClick={() => this.doSearch()} disabled={!selectedRoom || !startDate || !endDate}>
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </form>
                <hr />
                {currAvailabilities ?
                    <Row>
                        <Col className="col-4">
                            <Form className='mt-2' onSubmit={this.addAvailChanges}>
                                <Row>
                                    <Col>
                                        Bulk Update
                                    </Col>
                                    {this.state.numberOfRooms ?
                                        <Col className="text-right">
                                            <i className="fas fa-info-circle pr-2 text-muted" id="maxAvailability" />

                                            <Tooltip isOpen={this.state.tooltipOpen} placement="right" target="maxAvailability" toggle={() => this.setState(prevState => ({ tooltipOpen: !prevState.tooltipOpen }))}>
                                                <FormattedMessage id="BulkUpdate.AvailabilityLimitedTo" values={{ numberOfRooms: this.state.numberOfRooms }} />
                                            </Tooltip>
                                        </Col>
                                    : ''}
                                </Row>
                                <hr className="mb-2" />
                                <Row className="align-items-center">
                                    <Col>
                                        <span className="text-muted small"> <FormattedMessage id="BulkUpdate.dates" /> </span>
                                    </Col>
                                    <Col className="col-3">
                                        <span className="text-muted small"> <FormattedMessage id="BulkUpdate.Availability" /> </span>
                                    </Col>
                                    <Col className="col-2 text-right">
                                    </Col>
                                </Row>
                                <Row className="align-items-center">
                                    <Col>
                                        <DateRangePicker
                                            startDate={this.state.bulkStartDate ? moment(this.state.bulkStartDate).locale(moment.locale()) : this.state.bulkStartDate}
                                            startDateId="bulkStartDate"
                                            isOutsideRange={this.isOutsideRange}
                                            endDate={this.state.bulkEndDate ? moment(this.state.bulkEndDate).locale(moment.locale()) : this.state.bulkEndDate}
                                            endDateId="bulkEndDate"
                                            onDatesChange={({ startDate, endDate }) => this.handleBulkDates(startDate, endDate)}
                                            focusedInput={this.state.focusedInput2}
                                            onFocusChange={focusedInput => this.setState(({ focusedInput2: focusedInput }))}
                                            small={true}
                                            required
                                            minimumNights={0}
                                            numberOfMonths={2}
                                            showDefaultInputIcon={true}
                                            isDayBlocked={this.isDayBlocked}
                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                        />
                                    </Col>
                                    <Col className="col-3">
                                        <Input
                                            type="number"
                                            onChange={(e) => this.setState({ bulkAvail: e.target?.value })}
                                            value={this.state.bulkAvail || ''}
                                            required
                                            min={0}
                                            max={this.state.numberOfRooms}
                                        />
                                    </Col>
                                    <Col className="col-2 text-right">
                                        <Button className="btn-host btn-sm" type="submit">
                                            <i className="fas fa-plus"/>
                                        </Button>
                                    </Col>
                                </Row>                                
                            </Form>

                            {availabilityChanges ?
                                <Form className='mt-5'>
                                    <Row className="align-items-center">
                                        <Col> <FormattedMessage id="BulkUpdate.AvailabilityChanges" /> </Col>
                                        <Col className="col-3 text-right">
                                            <Button className="btn-host btn-sm" disabled={!availabilityChanges || availabilityChanges.length === 0} onClick={this.saveAvailability}>
                                                <i className="fas fa-save color-danger" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <hr className="mb-2" />
                                    <Row className="text-muted small">
                                        <Col>
                                            <FormattedMessage id="BulkUpdate.dates" />
                                        </Col>
                                        <Col className="col-4 text-center">
                                            <FormattedMessage id="BulkUpdate.FreeRooms" />
                                        </Col>
                                        <Col className="col-2 text-right">
                                            <FormattedMessage id="BulkUpdate.Remove" />
                                        </Col>
                                    </Row>
                                    {availabilityChanges.map((avail, key) =>
                                        <Row key={key} className="overbglight py-1">
                                            {avail.startDate !== avail.endDate ?
                                                <Col> De <b>{avail.startDate}</b> a <b>{avail.endDate}</b> </Col>
                                                :
                                                <Col> Dia <b>{avail.startDate}</b> </Col>
                                            }

                                            <Col className="col-4 text-center">
                                                {avail.freeRooms}
                                            </Col>
                                            <Col className="col-2 text-right">
                                                <i className="fas fa-trash-alt pr-2 text-danger pointer" onClick={() => this.removeChanges(key, avail.startDate, avail.endDate)} />
                                            </Col>
                                        </Row>
                                    )}
                                </Form>
                                : ''}
                        </Col>
                        <Col className="pl-0">
                            {!block && currAvailabilities ?
                                <AvailabilityCalendar
                                    blockedWeekdays={weekdays}
                                    currAvailabilities={currAvailabilities}
                                    startDate={(startDate)}
                                    renderCell={this.renderCell}
                                    focusedMonth={focusedMonth}
                                    selectdayAvailability={selectdayAvailability}
                                    selectedk={selectedk}
                                    calendarMonths={calendarMonths}
                                    cellHeight="10vh"
                                    fromAvailability={true}
                                    getFreeRooms={day => day.freeRooms}
                                    getPercentage={getPercentage}
                                />
                            :''}
                        </Col>
                    </Row>
                    :''}


                {this.state.modal &&
                    <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-md " style={{ minWidth: 0 }} body>
                        <ModalBody className="text-center">
                            <FormattedMessage id="BulkUpdate.ConfirmText1" />
                            <br />
                            <FormattedMessage id="BulkUpdate.ConfirmText2" />
                            <br /><br />

                            <div className="text-right mt-3">
                            <Button className="btn btn-host btn-sm mr-2" onClick={() => { this.toggleModal(); this.getAvailability(); }} >
                                    <FormattedMessage id="generic.confirm" />
                                </Button>
                                <Button className="btn btn-sm" onClick={this.toggleModal} >
                                    <FormattedMessage id="generic.cancel" />
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>
                }
            </StyledCard>
        );
    }
}
export default injectIntl(BulkUpdateAvailability);