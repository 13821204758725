import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { getAPI, postAPI } from "../../Base/API";
import { StyledCard } from "../../Base/Common/CommonUIComponents";
import { Input, CustomInput, Row, Col, Button } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";


class DataClean extends Component {
    constructor(props) {
        super(props);
        this.form= React.createRef(); 
        this.state = {
            block: true,
            status: { value: false },
            retentioPolList: [],
            channelCode: null
        }
    }

    componentDidMount() {
        this.getRetentionPolicyList();

    }

    getRetentionPolicyList() {
        this.setState({ block: true });



        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0)
                    handleNotification(data, "search", 'Success');
                else
                    this.setState({ retentioPolList: data.response, block: false });
            }
        }, '/api/hotel/v1/retentionPolicy' );
    }

    changeVal(evt, type) {
        let val = evt.target.value === 'on' ? evt.target.checked : Number(evt.target.value);
        let name = evt.target.name;

        let element = this.state.retentioPolList.find(el => el.type === type) === undefined ? {
            "type": type,
            "months": null,
            "active": false
        } : this.state.retentioPolList.find(el => el.type === type);

        element[name] = val;

        this.setState(prevState =>
            ({ retentioPolList: [...prevState.retentioPolList.filter(el => el.type !== type), element] }))

    }

    saveDataClean(evt) {
        evt.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }
        this.setState({ block: true });

        let req = { 'request': this.state.retentioPolList };

        postAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id= "DataClean.PoliciesSaved" />, <FormattedMessage id="General.Success" />);
                this.setState({  block: false });
            }
        }, '/api/hotel/v1/retentionPolicy', req);

    }


    render() {

        const helpContent =
            <div>
                TODO
                <hr />
            </div>



        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-trash'} title={'DataClean.title'} help={helpContent}>
                <form ref={this.form}>
                    <Row>
                        <Col></Col>
                        <Col className="text-right">
                            <Button className="float-right mr-2 btn-sm btn-host btn" onClick={this.saveDataClean.bind(this)}><i className="fas fa-save"></i></Button>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col className="col-4">
                            <FormattedHTMLMessage id="DataClean.Price" />
                        </Col>
                        <Col className="col-2">
                            <Input name="months" type="number" required
                                value={this.state.retentioPolList.find(el => el.type === 'Price') ? this.state.retentioPolList.find(el => el.type === 'Price').months : undefined}
                                onChange={(evt) => this.changeVal(evt, 'Price')} >
                            </Input>
                        </Col>
                        <Col className="col-1"><FormattedMessage id="DataClean.Month" /></Col>
                        <Col className="col-4">
                            <CustomInput name="active" type="switch" id="PriceActive" 
                                checked={this.state.retentioPolList.find(el => el.type === 'Price') ? this.state.retentioPolList.find(el => el.type === 'Price').active : undefined}
                                onChange={(evt) => this.changeVal(evt, 'Price')}>Active
                                </CustomInput></Col>
                    </Row>
                    <Row className="mb-1">
                        <Col className="col-4">
                            <FormattedHTMLMessage id="DataClean.Availability" />
                        </Col>
                        <Col className="col-2">
                            <Input name="months" type="number" required
                                value={this.state.retentioPolList.find(el => el.type === 'Availability') ? this.state.retentioPolList.find(el => el.type === 'Availability').months : undefined}
                                onChange={(evt) => this.changeVal(evt, 'Availability')} >
                            </Input>
                        </Col>
                        <Col className="col-1"><FormattedMessage id="DataClean.Month" /></Col>
                        <Col className="col-4">
                            <CustomInput name="active" type="switch" id="AvailabilityActive" 
                                checked={this.state.retentioPolList.find(el => el.type === 'Availability') ? this.state.retentioPolList.find(el => el.type === 'Availability').active : undefined}
                                    onChange={(evt) => this.changeVal(evt, 'Availability')}>Active
                                </CustomInput></Col>
                    </Row>
                    <Row className="mb-1">
                        <Col className="col-4">
                            <FormattedHTMLMessage id="DataClean.Restrictions" />
                        </Col>
                        <Col className="col-2">
                            <Input name="months" type="number" required
                                value={this.state.retentioPolList.find(el => el.type === 'Restriction') ? this.state.retentioPolList.find(el => el.type === 'Restriction').months : undefined}
                                onChange={(evt) => this.changeVal(evt, 'Restriction')} >
                            </Input>
                        </Col>
                        <Col className="col-1"><FormattedMessage id="DataClean.Month" /></Col>
                        <Col className="col-4">
                            <CustomInput name="active" type="switch" id="RestrictionActive" 
                                checked={this.state.retentioPolList.find(el => el.type === 'Restriction') ? this.state.retentioPolList.find(el => el.type === 'Restriction').active : undefined}
                                    onChange={(evt) => this.changeVal(evt, 'Restriction')}>Active
                                </CustomInput></Col>
                    </Row>
                    <Row className="mb-1">
                        <Col className="col-4">
                            <FormattedHTMLMessage id="DataClean.Audit" />
                        </Col>
                        <Col className="col-2">
                            <Input name="months" type="number" required
                                value={this.state.retentioPolList.find(el => el.type === 'Audit') ? this.state.retentioPolList.find(el => el.type === 'Audit').months : undefined}
                                onChange={(evt) => this.changeVal(evt, 'Audit')} >
                            </Input>
                        </Col>
                        <Col className="col-1"><FormattedMessage id="DataClean.Month" /></Col>
                        <Col className="col-4"> <CustomInput name="active" type="switch" id="AuditActive" 
                            checked={this.state.retentioPolList.find(el => el.type === 'Audit') ? this.state.retentioPolList.find(el => el.type === 'Audit').active : undefined}
                            onChange={(evt) => this.changeVal(evt, 'Audit')}>Active
                            </CustomInput>
                        </Col>
                    </Row>

                    <Row className="mb-1">
                        <Col className="col-4">
                            <FormattedHTMLMessage id="DataClean.SystemEvents" />
                        </Col>
                        <Col className="col-2">
                            <Input name="months" type="number" required
                                value={this.state.retentioPolList.find(el => el.type === 'SystemEvents') ? this.state.retentioPolList.find(el => el.type === 'SystemEvents').months : undefined}
                                onChange={(evt) => this.changeVal(evt, 'SystemEvents')} >
                            </Input>
                        </Col>
                        <Col className="col-1"><FormattedMessage id="DataClean.Month" /></Col>
                        <Col className="col-4"> <CustomInput name="active" type="switch" id="SystemEventsActive"
                            checked={this.state.retentioPolList.find(el => el.type === 'SystemEvents') ? this.state.retentioPolList.find(el => el.type === 'SystemEvents').active : undefined}
                            onChange={(evt) => this.changeVal(evt, 'SystemEvents')}>Active
                            </CustomInput>
                        </Col>
                    </Row>
                    <Row className="mb-1">
                        <Col className="col-4">
                            <FormattedHTMLMessage id="DataClean.SyncEvents" />
                        </Col>
                        <Col className="col-2">
                            <Input name="months" type="number" required
                                value={this.state.retentioPolList.find(el => el.type === 'SyncEvents') ? this.state.retentioPolList.find(el => el.type === 'SyncEvents').months : undefined}
                                onChange={(evt) => this.changeVal(evt, 'SyncEvents')} >
                            </Input>
                        </Col>
                        <Col className="col-1"><FormattedMessage id="DataClean.Month" /></Col>
                        <Col className="col-4"> <CustomInput name="active" type="switch" id="SyncEventsActive"
                            checked={this.state.retentioPolList.find(el => el.type === 'SyncEvents') ? this.state.retentioPolList.find(el => el.type === 'SyncEvents').active : undefined}
                            onChange={(evt) => this.changeVal(evt, 'SyncEvents')}>Active
                            </CustomInput>
                        </Col>
                    </Row>
                </form>
    
            </StyledCard>


        );
    }

};
export default injectIntl(DataClean)