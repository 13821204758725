import React, { Component } from 'react';
import { Button, Card, CardBody, CardHeader, Col, InputGroup, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { deleteAPI, getAPI, postAPI, putAPI } from "../../../Base/API";
import { StyledCard, } from "../../../Base/Common/CommonUIComponents";
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../../../Base/Notification';
import moment from 'moment';
import CityTaxModal from './CityTaxModal';
import { getMonths } from '../../../Base/Common/ReferenceDataFunctions';

export class CityTaxConfig extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            block: false,
            error: null,
            cityTaxList: []
        }
    }

    componentDidMount() {
        this.getCityTaxList();
    }

    getCityTaxList = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ error: errorMessage, block: false, cityTaxList: data.response || [] });
            }
            else this.setState({ error: errorMessage, block: false });
        }, `/api/hotel/v1/GetCityTaxes`);
    }

    toggleModal = (config) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedConfig: config
        }));
    }

    updateTable = (config) => {
        const { cityTaxList } = this.state;
        const index = cityTaxList.findIndex(el => el.id === config.id);

        if (index !== -1) {
            cityTaxList[index] = config;
        }
        else {
            cityTaxList.push(config);
        }

        this.setState({ cityTaxList, modal: false });
    }

    render() {
        const { block, error, cityTaxList, modal } = this.state;

        return (
            <StyledCard block={block} error={error} icon={'fas fa-hand-holding-usd'} title={'navbar.CityTaxConfig'} >
                <Row className="mb-3">
                    <Col className="text-right">
                        <Button color="btn btn-sm btn-host " onClick={() => this.toggleModal(null)}>
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>

                <Row>
                    {cityTaxList?.map((cityTax, key) =>
                        <Col className="col-6 h-100 pointer mb-3" key={key} onClick={() => this.toggleModal(cityTax)}>
                            <Card className="shadow p-2 h-100">
                                <CardHeader className="card-header-gridview border-bottom-1 border-host pb-2 px-2">
                                    <Row>
                                        <Col>
                                            <i className={`fa-sm mr-1 flag-icon flag-icon-${cityTax.country.toLowerCase()}`} /><b>{cityTax.city}, {cityTax.country}</b>
                                        </Col>
                                        <Col className="text-right">
                                            <b>From </b> {cityTax.dateFrom ? moment(cityTax.dateFrom).format("YYYY-MM-DD") : ''}
                                            <b> to </b> {cityTax.dateTo ? moment(cityTax.dateTo).format("YYYY-MM-DD") : ''}
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody >
                                    <Row>
                                        <Col> <b>Cobrar</b> nas idades compreendidas entre<b> {cityTax.minAge} </b> e <b> {cityTax.maxAge}</b></Col>
                                        <Col className="text-right col-3">
                                            <b><FormattedMessage id="CityTaxConfig.MaxNights" />:</b> {cityTax.maxNights}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col> <b>Descontos</b> nas idades compreendidas entre<b> {cityTax.descMinAge || '-'} </b> e <b> {cityTax.descMaxAge || '-'}</b></Col>
                                    </Row>


                                    <Row className="mt-3 border-bottom pb-2 align-items-center">
                                        <Col>
                                            <b> <FormattedMessage id="CityTaxConfig.Periods" /></b>
                                        </Col>
                                    </Row>

                                    <Row className="text-muted mt-2">
                                        <Col sm={5}> <FormattedMessage id="CityTaxConfig.From" /> </Col>
                                        <Col sm={5}> <FormattedMessage id="CityTaxConfig.To" /> </Col>
                                        <Col className="col-2 pl-0"> <FormattedMessage id="CityTaxConfig.Amount" /> </Col>
                                    </Row>

                                    {cityTax.cityTaxDetails?.map((period, idx) => {
                                        return <Row key={idx} className="overbglight py-1">
                                            <Col sm={5}> {period.fromDay} {getMonths(this.props.intl)[period.fromMonth - 1]?.label || ''} </Col>
                                            <Col sm={5}> {period.toDay} {getMonths(this.props.intl)[period.toMonth - 1]?.label || ''} </Col>
                                            <Col className="col-2 pl-0"> {period.amount} </Col>
                                        </Row>
                                    } )}
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>

                {modal ?
                    <CityTaxModal
                        modal={modal}
                        toggleModal={() => this.toggleModal(null)}
                        selectedConfig={this.state.selectedConfig}
                        updateTable={this.updateTable}
                    />    
                : ''}
            </StyledCard>
        );
    }

}
export default injectIntl(CityTaxConfig)