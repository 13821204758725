import React, { Component } from 'react';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../Base/Common/CustomSelect';

export class RenderPaymentGatewayForm extends Component {

    render() {
        const { paymentGateway, handleData, handleComboData, idx, isMbEntityRequired, providers } = this.props;

        return (
            <div>
                <FormGroup row>
                    <Label sm={2}>
                        <FormattedMessage id="PaymentGateway.EntityId" />
                    </Label>
                    <Col sm={10}>
                        <Input type="text" maxLength="50" name="userId" id="userId" value={paymentGateway.userId ? paymentGateway.userId : ''} onChange={evt => handleData(evt, idx)} required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={2}>
                        <FormattedMessage id="PaymentGateway.AuthToken" />
                    </Label>
                    <Col sm={10}>
                        <Input type="text" maxLength="100" name="accessToken" id="accessToken" value={paymentGateway.accessToken ? paymentGateway.accessToken : ''} onChange={evt => handleData(evt, idx)} required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={2}>
                        <FormattedMessage id="PaymentGateway.SecretId" />
                    </Label>
                    <Col sm={10}>
                        <Input type="text" maxLength="100" name="refreshToken" id="refreshToken" value={paymentGateway.refreshToken ? paymentGateway.refreshToken : ''} onChange={evt => handleData(evt, idx)} required />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={2}>
                        <FormattedMessage id="PaymentGateway.MbEntity" />
                    </Label>
                    <Col sm={4}>
                        <Input type="text" maxLength="5" name="mbEntity" id="mbEntity" value={paymentGateway.mbEntity ? paymentGateway.mbEntity : ''} onChange={evt => handleData(evt, idx)} required={isMbEntityRequired} />
                    </Col>
                    <Label sm={3}>
                        <FormattedMessage id="PaymentGateway.MbValidityHours" />
                    </Label>
                    <Col sm={3}>
                        <Input type="number" max="240" name="mbValidatyDays" id="mbValidatyDays" value={paymentGateway.mbValidatyDays ? paymentGateway.mbValidatyDays : ''} onChange={evt => handleData(evt, idx)} required={paymentGateway.validPaymentModes.some(md => md === 'SibsMultibanco')} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={2}>
                        <FormattedMessage id="PaymentGateway.Provider" />
                    </Label>
                    <Col sm={10}>
                        <CustomSelect options={providers} value={providers.find(prv => prv.value === paymentGateway.provider)} onChange={evt => handleComboData(evt, idx, 'provider')} />
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label sm={2}>
                        <FormattedMessage id="PaymentGateway.DaysBeforeCheckIn" />
                    </Label>
                    <Col sm={10}>
                        <Input disabled={paymentGateway && paymentGateway.validPaymentModes && !paymentGateway.validPaymentModes.some(md => md === 'SibsMultibanco')} type="number" max="240" min="0" name="daysBeforeCheckIn" id="daysBeforeCheckIn" value={paymentGateway.daysBeforeCheckIn ? paymentGateway.daysBeforeCheckIn : ''} onChange={evt => handleData(evt, idx)} required={paymentGateway.validPaymentModes.some(md => md === 'SibsMultibanco')} />
                    </Col>
                </FormGroup>
            </div>
        );
    }
}