import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { BlankCard, FormatAmountNumber, PaymentStatusBadge } from '../Base/Common/CommonUIComponents';
import { PaymentRefundModal } from './PaymentRefundModal';
import { PaymentCancelModal } from './PaymentCancelModal';
import { PaymentRefunds } from './PaymentRefunds';
import { CreatePaymentModal } from './CreatePaymentModal';
import { CheckAuthorization } from '../Base/Authorization';

class Details extends Component {

    goToVoucher = () => {
        const url = process.env.REACT_APP_HEYCARD_URL + '/VoucherOperationDetails/' + this.props.payment.paymentIntentId;

        window.open(url, '_blank');
    }

    render() {
        const { payment, updatePayment, updateRefunds, addPayment, 
            refundsTotalAmount, block, error, paymentRefundModal, paymentCancelModal, 
            createPaymentModal, isBankTransferOrCustom, isStripe, isIfThenPay, isRedsys, isHeyCard,
            isCreditCard, isMultibanco, logo, canPerformRecurringPayment, paymentStatusOptions } = this.props;

        return (
            <BlankCard block={block} error={error}>
                {paymentRefundModal ?
                    <PaymentRefundModal isOpen={paymentRefundModal} toggle={this.props.togglePaymentRefundModal} payment={payment} updateRefunds={updateRefunds}/>
                :''}
                {paymentCancelModal ?
                    <PaymentCancelModal isOpen={paymentCancelModal} toggle={this.props.togglePaymentCancelModal} payment={payment} updatePayment={updatePayment} />
                :''}
                {createPaymentModal ?
                    <CreatePaymentModal isOpen={createPaymentModal} toggle={this.props.toggleCreatePaymentModal} payment={payment} addPayment={addPayment} />
                :''}
                <Row className="mb-2">
                    <Col>
                        {logo}
                    </Col>
                    <Col className="text-right">
                        {CheckAuthorization("payments:actions") ?
                            isBankTransferOrCustom ?
                                <Button className="btn btn-sm btn-host mr-1" onClick={this.props.confirmPayment} disabled={payment.paymentStatus !== paymentStatusOptions[0].value}>
                                    <FormattedMessage id="Payments.ConfirmPayment" />
                                </Button>
                            :
                                <span>
                                    {canPerformRecurringPayment ?
                                        <Button className="btn btn-sm btn-host mr-1" onClick={this.props.toggleCreatePaymentModal} disabled={payment.merchantIdentifier === null && payment.merchantCofTxnid === null && payment.last4 === null}>
                                            <FormattedMessage id="Payments.CreatePayment" />
                                        </Button>
                                    :''}
                                    {isStripe ?
                                            <Button className="btn btn-sm btn-host mr-1" onClick={this.props.togglePaymentCancelModal} disabled={this.props.isCancelBtnDisabled(payment)}>
                                                <FormattedMessage id="Payments.Cancel" />
                                            </Button>
                                    :''}
                                    {!isHeyCard ?
                                        <>
                                            <Button className="btn btn-sm btn-host mr-1" onClick={this.props.togglePaymentRefundModal} disabled={payment.paymentStatus !== paymentStatusOptions[3].value || payment.amount === refundsTotalAmount || (isIfThenPay && isMultibanco)}>
                                                <FormattedMessage id="Payments.Refund" />
                                            </Button>
                                            <Button className="btn btn-sm btn-host" onClick={this.props.checkPayment} disabled={isRedsys || isIfThenPay && (isCreditCard || isMultibanco) || paymentStatusOptions.some(opt => opt.isFinalSate && opt.value === payment.paymentStatus)}>
                                                <FormattedMessage id="Payments.CheckPayment" />
                                            </Button>
                                        </>
                                    :
                                        <Button className="btn btn-sm btn-host mr-1" onClick={this.goToVoucher}>
                                            <FormattedMessage id="Payments.Voucher" />
                                        </Button>
                                    }
                                </span>
                    : ''}
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-2">
                        <b>
                            <FormattedMessage id="Payments.Reservation" />
                        </b>
                    </Col>
                    <Col className="col-4">
                        <span>
                            {payment.resIdValue ?? '-'}
                        </span>
                    </Col>
                    <Col className="col-2">
                        <b>
                            <FormattedMessage id="Payments.Channel" />
                        </b>
                    </Col>
                    <Col className="col-4">
                        <span>
                            {payment.channelName ? payment.channelName : payment.channelInstanceId}
                        </span>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-2">
                        <b>
                            <FormattedMessage id="Payments.Status" />
                        </b>
                    </Col>
                    <Col className="col-4">
                        <span>
                            <PaymentStatusBadge status={payment.paymentStatus ?? '-'} />
                        </span>
                    </Col>
                    <Col className="col-2">
                        <b>
                            <FormattedMessage id="Payments.Amount" />
                        </b>
                    </Col>
                    <Col className="col-4">
                        <span>
                            <FormatAmountNumber value={payment.amount} currency={payment.currencyCode} />
                        </span>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-2">
                        <b>
                            <FormattedMessage id="Payments.Name" />
                        </b>
                    </Col>
                    <Col className="col-4">
                        <span>
                            {payment.name ?? '-'}
                        </span>
                    </Col>
                    <Col className="col-2">
                        <b>
                            <FormattedMessage id="Payments.Email" />
                        </b>
                    </Col>
                    <Col className="col-4">
                        <span>
                            {payment.email ?? '-'}
                        </span>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-2">
                        <b>
                            <FormattedMessage id="Payments.DateCreated" />
                        </b>
                    </Col>
                    <Col className="col-4">
                        <span>
                            {payment.createdAt ? moment(payment.createdAt).format("YYYY-MM-DD HH:mm:ss") : ''}
                        </span>
                    </Col>
                    <Col className="col-2">
                        <b>
                            <FormattedMessage id="Payments.DateUpdated" />
                        </b>
                    </Col>
                    <Col className="col-4">
                        <span>
                            {payment.updatedAt ? moment(payment.updatedAt).format("YYYY-MM-DD HH:mm:ss") : ''}
                        </span>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col className="col-2">
                        <b>
                            {isHeyCard ?
                                <FormattedMessage id="Payments.VoucherId" />
                                :
                                <FormattedMessage id="Payments.IntentId" />
                            }
                        </b>
                    </Col>
                    <Col className="col-4">
                        <span>
                            {payment.paymentIntentId ?? '-'}
                        </span>
                    </Col>
                </Row>
                {isBankTransferOrCustom || isHeyCard ?
                    <div />
                :
                    <div>
                        {isCreditCard ?
                            <div>
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <b>
                                            <FormattedMessage id="Payments.Brand" />
                                        </b>
                                    </Col>
                                    <Col className="col-4">
                                        <span>
                                            {payment.brand ?? '-'}
                                        </span>
                                    </Col>
                                    <Col className="col-2">
                                        <b>
                                            <FormattedMessage id="Payments.Last4" />
                                        </b>
                                    </Col>
                                    <Col className="col-4">
                                        <span>
                                            {payment.last4 ?? '-'}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-2">
                                        <b>
                                            <FormattedMessage id="Payments.ExpMonth" />
                                        </b>
                                    </Col>
                                    <Col className="col-4">
                                        <span>
                                            {payment.expMonth ?? '-'}
                                        </span>
                                    </Col>
                                    <Col className="col-2">
                                        <b>
                                            <FormattedMessage id="Payments.ExpYear" />
                                        </b>
                                    </Col>
                                    <Col className="col-4">
                                        <span>
                                            {payment.expYear ?? '-'}
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        :
                            isMultibanco ?
                                <div>
                                    <Row className="mb-2">
                                        <Col className="col-2">
                                            <b>
                                                <FormattedMessage id="Payments.Entity" />
                                            </b>
                                        </Col>
                                        <Col className="col-4">
                                            <span>
                                                {payment.mbEntity ?? '-'}
                                            </span>
                                        </Col>
                                        <Col className="col-2">
                                            <b>
                                                <FormattedMessage id="Payments.Reference" />
                                            </b>
                                        </Col>
                                        <Col className="col-4">
                                            <span>
                                                {payment.mbReference ?? '-'}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row className="mb-2">
                                        <Col className="col-2">
                                            <b>
                                                <FormattedMessage id="Payments.MbStartDate" />
                                            </b>
                                        </Col>
                                        <Col className="col-4">
                                            <span>
                                                {moment(payment.mbStartDate).format("YYYY-MM-DD HH:mm:ss") ?? '-'}
                                            </span>
                                        </Col>
                                        <Col className="col-2">
                                            <b>
                                                <FormattedMessage id="Payments.MbEndDate" />
                                            </b>
                                        </Col>
                                        <Col className="col-4">
                                            <span>
                                                {moment(payment.mbEndDate).format("YYYY-MM-DD HH:mm:ss") ?? '-'}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            :
                                <div />
                        }
                        {payment.paymentStatus === "Rejected" || payment.paymentStatus === "requires_payment_method" ?
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <b>
                                        <FormattedMessage id="Payments.FailedReason" />
                                    </b>
                                </Col>
                                <Col className="col-4">
                                    <span>
                                        {this.props.getErrorMessage(payment) ?? '-'}
                                    </span>
                                </Col>
                            </Row>  
                        : ''}
                        {payment.sessionId ?
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <b>
                                        <FormattedMessage id="Payments.SessionId" />
                                    </b>
                                </Col>
                                <Col className="col-10">
                                    <span>
                                        {payment.sessionId ?? '-'}
                                    </span>
                                </Col>
                            </Row>
                        : ''}
                        <Row className="mb-2">
                            <Col className="col-2">
                                <b>
                                    <FormattedMessage id="Payments.ReciptUrl" />
                                </b>
                            </Col>
                            <Col className="col-10">
                                <span>
                                    <a href={`${payment.receiptUrl}`}>{payment.receiptUrl ?? '-'}</a>
                                </span>
                            </Col>
                        </Row>
                        {payment.notes ?
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <b>
                                        <FormattedMessage id="Payments.Notes" />
                                    </b>
                                </Col>
                                <Col className="col-10">
                                    <span>
                                        {payment.notes ?? '-'}
                                    </span>
                                </Col>
                            </Row>
                        : ''}

                        {payment && payment.reservationPaymentRefund && payment.reservationPaymentRefund.length > 0 &&
                            <>
                                <Row className="mb-2">
                                    <Col>
                                        <h5>
                                            <FormattedMessage id="Payments.Refunds" />
                                        </h5>
                                    </Col>
                                </Row>
                                <PaymentRefunds reservationPaymentRefund={payment.reservationPaymentRefund} />
                            </>
                        }
                    </div>
                }
            </BlankCard>
        )
    }
}
export default injectIntl(Details)