import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Modal, ModalHeader, ModalBody, ListGroup, ListGroupItem, Row, Col, Button } from 'reactstrap';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { getAPI } from "../Base/API"
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Common/ErrorAlert";
import { FormatAmountNumber, ReservationStatusBadge } from "../Base/Common/CommonUIComponents";
import { getConvertedDateToHotelTimezone } from "../Base/Common/ReferenceDataFunctions";
import { ReservationView } from './ReservationView';

export class ReservationHist extends Component {
    constructor(props) {
        super(props);
        this.toggleReservationView = this.toggleReservationView.bind(this);
        this.state = {
            blockReservationHist: true,
            blockEmailNotification: true,
            reservationViewModal: false,
            selectedReservation: {},
            reservationHist: [],
            emailNotifications: []
        };
    }

    componentDidMount() {
        if (this.props.reservation) {
            getAPI(result => {
                const { data, error, isLoading } = result;
                this.setState({ blockReservationHist: isLoading });
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage });
                }
                if (data) {
                    this.setState({ reservationHist: data.data});
                }
                this.setState({ blockReservationHist: false });
            }, `/api/Price/Reservation/v1/${this.props.reservation.id}/hist`);
        }
        else {
            this.setState({ blockReservationHist: false, blockEmailNotification: false });
        }
    }
        
    toggleReservationView(reservation) {
        this.setState(prevState => ({ reservationViewModal: !prevState.reservationViewModal, selectedReservation: reservation }));
    }

    render() {
        const reservationStatus = [
            {
                value: 'Created',
                label: <FormattedMessage id="ReservationList.Created" />
            },
            {
                value: 'Canceled',
                label: <FormattedMessage id="ReservationList.Cancel" />
            },
            {
                value: 'Modified',
                label: <FormattedMessage id="ReservationList.Modified" />
            },
            {
                value: 'Pending',
                label: <FormattedMessage id="ReservationList.Pending" />
            }
        ];

        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.action} size="modal-sm">
                    <ModalHeader toggle={this.props.action}>
                        <FormattedMessage id="ReservationDetail.HistoricReservation" />
                    </ModalHeader>
                    <ModalBody>
                        <ErrorAlert error={this.state.error} />
                        {this.state.reservationViewModal ?
                            <ReservationView
                                modal={this.state.reservationViewModal}
                                data={this.state.selectedReservation}
                                action={this.toggleReservationView}
                                hidePmsData={this.props.hidePmsData}
                            />
                        :''}
                        <div>
                            <div>
                                <h6>
                                    <i className="fas fa-history mr-1"/>
                                    <FormattedMessage id="ReservationHist.Reservations" /> {this.props.reservation && this.props.reservation.resIdValue}
                                </h6>
                            </div>
                            <div>
                                <BlockUi tag="div" blocking={this.state.blockReservationHist}>
                                    {this.state.reservationHist.map((item, key) => {
                                        const date = item.createdAt ? getConvertedDateToHotelTimezone(item.createdAt).format("YYYY-MM-DD HH:mm:ss") : '';
                                        return (
                                            <div key={key}>
                                                <hr/>
                                                <Row>
                                                    <Col>
                                                        <span className='mr-1'><ReservationStatusBadge status={item.status}/></span>
                                                        <FormattedMessage id="ReservationHist.On"/>
                                                        <span className='mx-1'>{date}</span>
                                                        <FormattedMessage id="ReservationHist.For"/>
                                                        <b className='mx-1'>
                                                            {item.reservationProfile.length > 0 ?
                                                                <span>{item.reservationProfile[0].givenName}  {item.reservationProfile[0].surname}</span>
                                                            : ''}
                                                        </b>
                                                        <FormattedMessage id="ReservationHist.With"/>
                                                        <b className='mx-1'>{item.guestCount}</b>
                                                        <FormattedMessage id="ReservationHist.GuestAndValue"/>
                                                        <b className='mx-1'>
                                                            <FormattedNumber value={item.amountAfterTax} style="currency" currency={item.currencyCode ?? global.hotelCurrency} minimumFractionDigits={2}/>
                                                        </b>
                                                        <FormattedMessage id="ReservationHist.PMSIntegrationStatus"/>
                                                        <b className='ml-1'>{item.communicationStatus}</b>
                                                    </Col>
                                                    <Col className="col-1 text-right">
                                                        <Button className="btn-sm btn-host" onClick={() => this.toggleReservationView(item)}><i className="far fa-list-alt"></i></Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    })}
                                    
                                </BlockUi>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
