import React, { Component } from 'react';
import { Row, Col, Card, InputGroup, InputGroupAddon, Input, Badge } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getAPI } from "../Base/API"
import { DateRangePicker } from 'react-dates';
import moment from "moment";
import ClientDetails from "./ClientDetails";
import CustomSelect from '../Base/Common/CustomSelect';
import { CustomTable } from '../Base/Common/CustomTable';
import { StyledCard } from '../Base/Common/CommonUIComponents';


class SAPOClients extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: true,
            clientsList: [],
            name: '',
            modal: false,
            clientSelected: null,
            startDate: moment().subtract(15, 'days'),
            endDate: moment(),
        }
    }

    componentDidMount() {
        this.getClients();
    }

    getClients = () => {
        this.setState({ block: true });
        var params = '';
        params += this.state.startDate ? `&startDate=${this.state.startDate.format("YYYY-MM-DD")}` : '';
        params += this.state.endDate ? `&endDate=${this.state.endDate.format("YYYY-MM-DD")}` : '';
        params += this.state.status ? `&status=${this.state.status}` : '';

        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                this.setState({ clientsList: data.response, block: false, error: data.erros });
            }
        }, `/api/Price/Reservation/v1/prospects?name=${this.state.name}` + params);
    }
    
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({
            [name]: value
        })
    }

    toggleModal = (client) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            clientSelected: client
        }));
    }

    updateTable = (client) => {
        this.setState({ block: true });
        const index = this.state.clientsList && this.state.clientsList.indexOf(this.state.clientsList.find(c => c.id === client.id));

        if (index !== -1) {
            var clientsList = [...this.state.clientsList];
            clientsList[index] = client;

            this.setState({ clientsList });
        }
        this.setState({ block: false });
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    render() { 
        const statusOptions = [
            {
                value: 'Created',
                label: <FormattedMessage id="SAPOClient.Created" />,
                badge: 'primary'
            },
            {
                value: 'FollowUp',
                label: <FormattedMessage id="SAPOClient.FollowUp" />,
                badge: 'success'
            },
            {
                value: 'Close',
                label: <FormattedMessage id="SAPOClient.Closed" />,
                badge: 'secondary'
            }
        ];       

        function StatusBadge ({ status }) {
            const currentStatus = statusOptions.find(item => item.value === status);
            return (
                <Badge className={'p-1 px-2 shadow text-white'} color={currentStatus != null ? currentStatus.badge : 'danger'} >{currentStatus != null ? currentStatus.label : ''}</Badge>
            )
        };

        const columns = [
            {
                dataField: 'contact',
                text: this.props.intl.formatMessage({ id: "SAPOClients.Contact" }),
                sort: true
            }, {
                dataField: 'createdAt',
                text: this.props.intl.formatMessage({ id: "SAPOClients.CreatedAt" }),
                formatter: (cell, row) => cell ? <div>{moment(cell).format("YYYY-MM-DD HH:mm:ss")}</div> : '',
                sort: true
            }, {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "SAPOClients.HotelName" }),
                sort: true
            }, {
                dataField: 'email',
                text: this.props.intl.formatMessage({ id: "SAPOClients.Email" }),
                sort: true
            }, {
                dataField: 'phone',
                text: this.props.intl.formatMessage({ id: "SAPOClients.Phone" }),
                sort: true
            }, {
                dataField: 'status',
                text: this.props.intl.formatMessage({ id: "SAPOClients.Status" }),
                formatter: (cell, row) => cell ? <div>{<StatusBadge status={cell} />}</div> : '',
                sort: true
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.toggleModal(this.state.clientsList[rowIndex]);
            }
        };
        
        return (
            <div>
                {this.state.modal ?
                    <ClientDetails
                        modal={this.state.modal}
                        client={this.state.clientSelected}
                        updateTable={this.updateTable}
                        toggle={this.toggleModal}
                    />
                : ''}
                <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-users'} title={'SAPOClients.Clients'} help={'TODO'}>
                    <Row>
                        <Col className="col-12">
                            <Row>
                                <Col className="col-2 pr-1">
                                    <InputGroup size="sm text-right" >
                                        <InputGroupAddon addonType="prepend"><span className="input-group-text"><span className="fa fa-id-card fa-fw"></span></span></InputGroupAddon>
                                        <FormattedMessage id="SAPOClients.HotelOrContact">
                                            {
                                                placeholder => <Input className="border-left-0" type="text" name="name" maxLength="50" placeholder={placeholder} value={this.state.guestName} onChange={this.handleChange} />
                                            }
                                        </FormattedMessage>
                                    </InputGroup>
                                </Col>
                                <Col className="col-2 px-1">
                                    <CustomSelect
                                        name="status"
                                        icon={'fas fa-cog'}
                                        value={statusOptions.find(el => el.value === this.state.status)}
                                        placeholder={<FormattedMessage id="SAPOClients.Status" />}
                                        options={statusOptions}
                                        onChange={this.handleChangeSelect.bind(this, 'status')}
                                        isClearable isSearchable
                                    />
                                </Col>
                                <Col className="col-3 px-1">
                                    <InputGroup size="sm text-right" >
                                        <DateRangePicker
                                            startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                                            startDateId="your_unique_start_date_id"
                                            isOutsideRange={_ => false}
                                            endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                                            endDateId="your_unique_end_date_id"
                                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            small={true}
                                            numberOfMonths={1}
                                            showDefaultInputIcon={true}
                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col className="text-right">
                                    <button className="btn btn-host btn-sm" onClick={this.getClients}>
                                        <span className="fas fa-search" />
                                    </button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr className="border-host" />
                    <Row>
                        <Col className="col-12">
                            <Card className=" border-0 ">
                                <CustomTable
                                    data={this.state.clientsList ? this.state.clientsList : []}
                                    columns={columns}
                                    page={this.state.currentPage}
                                    sizePerPage={this.state.resultsPerPage}
                                    totalSize={this.state.clientsList && this.state.clientsList.length}
                                    shadow={false}
                                    exportCSV={true}
                                    selectRow={selectRow}
                                    search={true}
                                    remote={false}
                                />
                            </Card>
                        </Col>
                    </Row>

                </StyledCard>
            </div>
        );
    }
}

export default injectIntl(SAPOClients)