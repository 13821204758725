import React, { Component } from 'react';
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody, Row, Col, InputGroup, InputGroupAddon, Input, Collapse } from 'reactstrap';
import { CustomDateRangePicker } from '../../../Base/Common/CustomReactDates';
import CustomSelect from '../../../Base/Common/CustomSelect';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import { SelectChannelInstance } from '../../../Base/Common/CommonUIComponents';
import { MobileFilters } from '../../../Base/Common/MobileComponents';
import { DateRangePicker } from 'react-dates';


export class LogsEventsFiltersMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }
    
    renderMainFilter = () => {
        const { defCon1, filters, handleDates } = this.props;
        return (
            <CustomDateRangePicker
                startDate={filters.startDate}
                startDateId="startDateId"
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                endDate={filters.endDate}
                endDateId="endDate"
                isOutsideRange={day => (this.state.focusedInput === "startDate" && day.isSame(moment().add(1, 'days'), 'd')) || day.isAfter(moment().add(1, 'd'), 'd') || day.isBefore(moment().subtract(6, 'month'), 'd')}
                onDatesChange={(startDate, endDate) => handleDates(startDate, endDate)}
                isDayBlocked={d => this.state.focusedInput === "endDate" && filters.startDate?.endOf('d') <= defCon1 && moment(d).startOf('d') >= defCon1}
                small={true}
                numberOfMonths={1}
                showYearOptions={{ pastYears: true, futureYears: false }}
            />
        )
    }


    render() {
        const { filters, errors, services, hasAutoErrors } = this.props;

        return (
            <div>
                <MobileFilters doSearch={this.props.search} renderMainFilter={this.renderMainFilter}>
                    <Row>
                        <Col className="col-12 mb-2">
                            <CustomSelect
                                icon={'fa fa-exclamation-triangle fa-fw'}
                                isClearable isSearchable
                                placeholder={<FormattedMessage id="LogsEvents.Status" />}
                                options={errors}
                                value={errors.filter(el => el.value == filters.selectedErrorType)}
                                onChange={this.props.setComboStatus.bind(this, 'selectedErrorType')}
                            />
                        </Col>
                        <Col className="col-12 mb-2">
                            <SelectChannelInstance
                                icon={'fa fa-plug fa-fw'}
                                isMulti={false}
                                placeholder={<FormattedMessage id="LogsEvents.SelectChannel" />}
                                onChangeFunc={this.props.setChannel.bind(this, 'selectedChannel')}
                                value={filters.selectedChannel}
                            />
                        </Col>
                        <Col className="col-12 mb-2">
                            <CustomSelect
                                icon={'fa fa-filter fa-fw'}
                                isClearable
                                isSearchable
                                placeholder={<FormattedMessage id="LogsEvents.AutoAction" />}
                                options={hasAutoErrors}
                                value={hasAutoErrors.filter(el => el.value == filters.selectedhasAutoActions)}
                                onChange={this.props.setComboStatus.bind(this, 'selectedhasAutoActions')}
                            />
                        </Col>
                        <Col className="col-12 mb-2">
                            <div>
                                <CustomSelect
                                    icon={'fa fa-filter fa-fw'}
                                    isClearable
                                    isMulti
                                    isSearchable
                                    placeholder={<FormattedMessage id="LogsEvents.EventType" />}
                                    value={services.filter(el => filters.selectedServiceType != '' && filters.selectedServiceType.includes(el.value))}
                                    options={services}
                                    onChange={this.props.setComboStatus.bind(this, 'selectedServiceType')}
                                />

                                {filters.selectedServiceType && (filters.selectedServiceType.includes(services[5].value) || filters.selectedServiceType.includes(services[10].value)) ?
                                    <InputGroup size="sm text-right " className="mt-2">
                                        <InputGroupAddon addonType="prepend">
                                            <span className="input-group-text"><i className="fas fa-key fa-fw"></i></span>
                                        </InputGroupAddon>
                                        <FormattedMessage id="ReservationList.ReservationId">
                                            { placeholder =>
                                                <Input
                                                    className="border-left-0"
                                                    type="text"
                                                    name="reservationid"
                                                    maxLength="50"
                                                    id="reservationid"
                                                    placeholder={placeholder}
                                                    value={filters.reservationid}
                                                    onChange={(e) => this.props.handleChangeFilter(e)}
                                                />
                                            }
                                        </FormattedMessage>
                                    </InputGroup>
                                : ''}
                            </div>
                        </Col>
                    </Row>
                </MobileFilters>
            </div>
        );
    }
}

export default injectIntl(LogsEventsFiltersMobile);
