import React, { Component } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import {  Card, Col, Row } from 'reactstrap';
import { getAPI } from "../../../Base/API";
import moment from "moment";
import BlockUi from 'react-block-ui';
import { handleNotification } from '../../../Base/Notification';

class HotelsSummary extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            summary: null,
            size: 10
        }
    }

    componentDidMount() {
        this.getGoogleSummary();
    }

    getGoogleSummary = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ errorModal: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.rootElement && data.rootElement.error) {
                    handleNotification({ errors: [data.rootElement] });
                }

                this.setState({ summary: data.rootElement, block: false });
            }
            else this.setState({ block: false });
        }, '/api/hotel/MetaSearch/v1/google/summarize');
    }

    toggleModal = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }))
    }

    render() {
        const { summary } = this.state;

        const Crt = ({ text, value, isDate }) => {
            return <Card className="shadow p-3 h-100" body>
                <Row className="row no-gutters align-items-center">
                    <Col sm={isDate ? 7 : 8}>
                        <div className="text-xs font-weight-bold text-secondary text-uppercase mb-1">
                            {text}
                        </div>
                    </Col>
                    <Col className="text-right text-secondary" sm={isDate ? 5 : 4} style={{ fontSize: '18px' }}>
                        {value}
                    </Col>
                </Row>
            </Card>
        }

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                {summary ?
                    <>
                        <Row>
                            <Col>
                                <Crt text={"# Matched Properties"} value={summary.matchedPropertyCount || 0} />
                            </Col>
                            <Col>
                                <Crt text={"# Live On Google"} value={summary.liveOnGooglePropertyCount || 0} />
                            </Col>
                            <Col>
                                <Crt text={"# Overclustered"} value={summary.overclusteredPropertyCount || 0} />
                            </Col>
                            <Col>
                                <Crt text={"# Last Manifest Update"} value={summary.lastManifestUpdateTime ? moment(summary.lastManifestUpdateTime).format("YYYY-MM-DD HH:mm") : '0000-00-00'} isDate={true}/>
                            </Col>
                        </Row>
                        <Row className="mt-2 mb-4">
                            <Col>
                                <Crt text={"# Unmatched Properties"} value={summary.unmatchedPropertyCount || 0} />
                            </Col>
                            <Col>
                                <Crt text={"# Unmatched With Errors"} value={summary.unmatchedPropertyWithErrorsCount || 0} />
                            </Col>
                            <Col>
                                <Crt text={"# Overclustered With Errors"} value={summary.overclusteredPropertyWithErrorsCount || 0} />
                            </Col>
                            <Col>
                                <Crt text={"# Last Feed Submission"} value={summary.lastFeedSubmissionTime ? moment(summary.lastFeedSubmissionTime).format("YYYY-MM-DD HH:mm") : '0000-00-00'} isDate={true}/>
                            </Col>
                        </Row>
                    </>
                : ''}
            </BlockUi>
        );
    }

};
export default injectIntl(HotelsSummary)