import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Nav, NavItem, NavLink } from 'reactstrap';

class HeyTravelAISideMenu extends Component {
    getNavLinkClass = (tabName) => {
        return this.props.activeTab === tabName ? 'text-primary  border-0 pointer border-left-primary' : 'bg-white border-0 pointer';
    }

    render() {
        return (
            <Nav tabs vertical className="h-100 border-0">
                <div className='d-flex p-3 align-items-center justify-content-start w-100 bg-white mb-2' style={{  border: '1px solid #bbc1c9ab', borderRadius: '5px', boxShadow: '0px 3px 15px #6F73881A', maxHeight: '90%' }}>
                    <h5 className='m-0'>
                        <FormattedMessage id={`HeyTravelAI.Title`} />
                    </h5>
                </div>

                <Card className="bg-white p-2 w-100" body style={{ border: '1px solid #bbc1c9ab', borderRadius: '5px', boxShadow: '0px 3px 15px #6F73881A' }}>
                    <NavItem className="pb-2">
                        <NavLink className={this.getNavLinkClass('FaqConfig')}
                            onClick={() => this.props.toggleTab('FaqConfig')}>
                                <i className="far fa-comments pr-2"></i>
                            <FormattedMessage id="HeyTravelAI.FaqConfig"/>
                        </NavLink>
                    </NavItem>
                    <NavItem className="pb-2">
                        <NavLink className={this.getNavLinkClass('UsageTracking')}
                            onClick={() => this.props.toggleTab('UsageTracking')}>
                            <i className="far fa-chart-bar pr-2"></i>
                            <FormattedMessage id="HeyTravelAI.UsageTracking"/>
                        </NavLink>
                    </NavItem>
                    <NavItem className="pb-2">
                        <NavLink className={this.getNavLinkClass('Playground')}
                            onClick={() => this.props.toggleTab('Playground')}>
                            <i className="fas fa-brain pr-2"></i>
                            <FormattedMessage id="HeyTravelAI.Playground" />
                        </NavLink>
                    </NavItem>
                </Card>
            </Nav>
        )
    }
}

export default injectIntl(HeyTravelAISideMenu);