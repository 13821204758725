import React, { Component } from 'react';
import { CardBody, Col, Row, Button, Card, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { ManualReservationCategoriesPrices } from './ManualReservationCategoriesPrices';
import ManualReservationGuests from './ManualReservationGuests';
import { ManualReservationOthers } from './ManualReservationOthers';
import { ManualReservationConfirm } from './ManualReservationConfirm';
import { handleNotification } from "../Base/Notification";
import { ErrorAlert } from "../Common/ErrorAlert";
import { getAPI, postAPI } from "../Base/API";
import BlockUi from 'react-block-ui';
import moment from "moment";
import { CommonHelper } from '../Base/Common/CommonUIComponents';

export class ManualReservation extends Component {

    constructor(props) {
        super(props);
        this.getReservation = this.getReservation.bind(this);
        this.getCalendar = this.getCalendar.bind(this);
        this.updateCheckInAndCheckout = this.updateCheckInAndCheckout.bind(this);
        this.next = this.next.bind(this); 
        this.handleAddDay = this.handleAddDay.bind(this); 
        this.confirmRoomStay = this.confirmRoomStay.bind(this); 
        this.removeRoomStay = this.removeRoomStay.bind(this); 
        this.setCombo = this.setCombo.bind(this);
        this.revertPercetageDiscount = this.revertPercetageDiscount.bind(this);
        this.revertNumberDiscount = this.revertNumberDiscount.bind(this);
        this.state = {
            block: false,
            steps: this.getInitialSteps(),
            reservation: this.getNewReservation(null),
            reservationCreated: false,
            checkin: moment(),
            checkout: moment().add(14, 'days'),
            roomCategories: [],
            roomCategoriesOptions: [],
            roomCategory: null,
            colors: [
                {
                    color: '#165C7D',
                    value: 'host'
                },
                {
                    color: '#58D68D',
                    value: 'green'
                },
                {
                    color: '#BEDB39',
                    value: 'light-green'
                },
                {
                    color: '#33691E',
                    value: 'dark-green'
                },
                {
                    color: '#B1FF91',
                    value: 'light-blue'
                },
                {
                    color: '#00ABD8',
                    value: 'blue'
                },
                {
                    color: '#F4511E',
                    value: 'dark-orange'
                },
                {
                    color: '#FF5722',
                    value: 'orange'
                }
            ],
            discount: null,
            discountType: null,
            totalNoDiscount: 0,
            totalWithDiscount: 0
        };
    }

    getNewReservation(hotelCode) {
        const reservation = {
            reservationId: null,
            creationDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            modificationDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            reservationStatus: 'Created',
            source: 'Email',
            hotelCode: hotelCode,
            totalAmount: 0,
            deposit: null,
            roomStays: [],
            profiles: [],
            comments: [],
        };
        return reservation;
    }

    getInitialSteps() {
        const steps = [
            { step: 1, active: true, enabled: true },
            { step: 2, active: false, enabled: false },
            { step: 3, active: false, enabled: false },
            { step: 4, active: false, enabled: false },
        ];
        return steps;
    }

    getEmptyRateData(day) {
        const rateData = {
            day: day,
            inventory: 0,
            freeRooms: 0,
            prices: []
        }
        return rateData;
    }

    componentDidMount() {
        if (this.props.match.params.id !== undefined) {
            this.getReservation();
        }
        else {
            this.getCalendar();
        }
    }

    getReservation() {
        const { reservation, colors } = this.state;
        this.setState({ block: true });
        getAPI(result => {
            const { error, data } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                Object.assign(reservation, data.response[0]);
                reservation.roomStays.forEach((roomStay, roomStayIdx) => {
                    if (!roomStay.specialRequests) {
                        roomStay.specialRequests = [];
                    }
                    if (!roomStay.taxCharges) {
                        roomStay.taxCharges = [];
                    }
                    const color = colors[roomStayIdx % colors.length];
                    roomStay.color = color.color;
                    roomStay.colorValue = color.value;
                    roomStay.colorIdx = 0;
                    for (let i = 1; i < reservation.roomStays.length;i++) {
                        const room = reservation.roomStays[i];
                        if (room.roomCode === roomStay.roomCode && moment(room.roomRates[room.roomRates.length - 1].expireDate).isSameOrAfter(roomStay.roomRates[0].effectiveDate) && moment(room.roomRates[0].effectiveDate).isSameOrBefore(roomStay.roomRates[roomStay.roomRates.length - 1].expireDate) && room.colorIdx >= 0) {
                            roomStay.colorIdx = room.colorIdx + 1;
                        }
                    }
                });
                this.setState({ reservation, reservationCreated: true }, this.getCalendar);
            }
            else {
                this.setState({ error: errorMessage, block: false }, this.getCalendar);
            }
        }, `/api/Rate/Reservation/v1/${this.props.match.params.id}`);
    }

    getCalendar() {
        const { block, checkin, checkout, roomCategory, roomCategoriesOptions, reservation } = this.state;
        
        if(!checkin || !checkout)
            return;

        if (!block) {
            this.setState({ block: true });
        }
        getAPI(result => {
            const { error, data } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response && data.response.length > 0) {
                const days = moment(checkout?.format("YYYY-MM-DD")).diff(moment(checkin?.format("YYYY-MM-DD")), 'days') + 1;
                data.response.sort((cat1, cat2) => cat1.categoryId - cat2.categoryId);
                data.response.forEach(resp => {
                    if (!roomCategoriesOptions.find(rc => rc.value === resp.categoryId)) {
                        roomCategoriesOptions.push({ value: resp.categoryId, label: resp.categoryDescription });
                    }
                    resp.rates.forEach(rate => {
                        rate.occupancies.sort((occ1, occ2) => occ1.adults - occ2.adults === 0 ? occ1.children - occ2.children : occ1.adults - occ2.adults);
                        rate.rateDetails.forEach(rateDetail => {
                            rateDetail.rateData.sort((rData1, rData2) => moment(moment(rData1.day)?.format("YYYY-MM-DD")).diff(moment(moment(rData2.day)?.format("YYYY-MM-DD")), 'days'));
                            const missingRateDataCount = days - rateDetail.rateData.length;
                            if (missingRateDataCount > 0) {
                                for (let i = 0; i < days; i++) {
                                    const currDay = moment(checkin?.format("YYYY-MM-DD")).add(i, 'days').format("YYYY-MM-DDT00:00:00");
                                    if (i < rateDetail.rateData.length) {
                                        if (rateDetail.rateData[i].day !== currDay) {
                                            rateDetail.rateData.splice(i, 0, this.getEmptyRateData(currDay));
                                        }
                                    }
                                    else {
                                        rateDetail.rateData.splice(i, 0, this.getEmptyRateData(currDay));
                                    }
                                }
                            }
                        });
                        reservation.roomStays
                            .filter(roomStay => roomStay.roomCode === rate.rateDetails[0].roomMappingCode && roomStay.roomRates && roomStay.roomRates.find(roomRate => roomRate.rateCode === rate.rateDetails[0].rateMappingCode) !== null)
                            .forEach(roomStay => {
                                roomStay.roomCategoryDesc = resp.categoryDescription;
                                roomStay.rateDesc = rate.rateCode;
                            });
                    });
                });
                reservation.hotelCode = data.response[0].propertyCode;
                this.setState({ error: errorMessage, block: false, roomCategories: data.response, roomCategoriesOptions, reservation });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Price/Price/Price/v1/CalendarInformation?channelCode=HEYTRAVEL&fromDate=${checkin?.format("YYYY-MM-DD")}&toDate=${checkout?.format("YYYY-MM-DD")}${roomCategory ? `&roomCategoryId=${roomCategory}` : ``}`);
    }

    updateCheckInAndCheckout(checkIn, checkOut, getCalendar) {
        this.setState({ checkin: checkIn, checkout: checkOut }, _ => {
            if (getCalendar) {
                getCalendar();
            }
        });
    }

    next(val) {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.steps = stateCopy.steps.slice();
        for (var i = 0; i < stateCopy.steps.length; i++) {
            if (stateCopy.steps[i].step === val) {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = true;
                stateCopy.steps[i].enabled = true;
            }
            else {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = false;
            }
        }
        this.setState(stateCopy);
    }

    movePos(val) {
        this.next(this.state.steps.find(el => el.active === true).step + val); 
    }

    // Comments
    addComment = (text) => {
        this.setState(prevState => ({
            ...prevState,
            reservation: {
                ...prevState.reservation,
                comments: [
                    ...prevState.reservation.comments,text
                ]
            }
        }));
    }

    removeComment = (id) => {
        this.setState(prevState => ({
            ...prevState,
            reservation: {
                ...prevState.reservation,
                comments: [
                    ...prevState.reservation.comments.filter((el,idx ) => idx !== id)
                ]
            }
        }));
    }

    updateComment = (text, id) =>{

        let newVal = this.state.reservation.comments;
        newVal[id] = text;
        
        this.setState(prevState => ({
            ...prevState,
            reservation: {
                ...prevState.reservation,
                comments: 
                    newVal
                
            }
        }));

    }

    setSource = (combo) => {
        this.setState({
            reservation: {
                ...this.state.reservation,
                source: combo ? combo.value : null
            }
        })
    }

    // Guests
    addGuest = (guest) => {
        this.setState(prevState => ({
            ...prevState,
            reservation: {
                ...prevState.reservation,
                profiles: [
                    ...prevState.reservation.profiles,
                    guest
                ]
            }
        }));
    }

    removeGuest = (id) => {
        this.setState(prevState => ({
            ...prevState,
            reservation: {
                ...prevState.reservation,
                profiles: [
                    ...prevState.reservation.profiles.filter((el, idx) => idx !== id)
                ]
            }
        }));
    }

    updateGuest = (text, id) => {
        let newVal = this.state.reservation.profiles;
        newVal[id] = text;

        this.setState(prevState => ({
            ...prevState,
            reservation: {
                ...prevState.reservation,
                profiles:
                    newVal

            }
        }));
    }

    handleAddDay(day, price, roomMappingCode, rateMappingCode, roomCategoryDesc, rateDesc, color, colorValue, isEditable, checkAvailability, minStay, maxStay, priceIsDifferent) {
        const { reservation } = this.state;
        const idx = reservation.roomStays.indexOf(reservation.roomStays.find(room => room.roomCode === roomMappingCode && room.isPending));
        if (idx >= 0) {
            const roomStay = reservation.roomStays[idx];
            let mDate = moment(day);
            let mCheckin = moment(roomStay.roomRates[0].effectiveDate);
            let diff = moment(moment(mDate).format("YYYY-MM-DD")).diff(moment(moment(mCheckin).format("YYYY-MM-DD")), 'days');
            const hasMaxStay = roomStay.maxStay !== undefined && roomStay.maxStay !== null && roomStay.maxStay > 0;
            if (diff >= 0) {
                let roomRate = roomStay.roomRates[0];
                roomRate.expireDate = moment(mCheckin).add(diff, 'day').format("YYYY-MM-DD");
                if (roomStay.roomRates.length > 1) {
                    //Room rates are being recalculated so need to be removed
                    roomStay.roomRates.splice(1, roomStay.roomRates.length - 1);
                }

                while (diff > 0) {
                    diff--;
                    mCheckin = moment(mCheckin).add(1, 'day').format("YYYY-MM-DD");
                    if (!roomStay.roomRates.find(rate => rate.effectiveDate === mCheckin)) {
                        const dailyPrice = priceIsDifferent(mCheckin, roomRate.dailyAmount, roomStay.adults, roomStay.children);
                        if (dailyPrice.isPriceDifferent) {
                            //Update room rate expireDate
                            roomRate.expireDate = moment(mCheckin).subtract(1, 'day').format("YYYY-MM-DD");
                            //Update price with new value
                            price.totalDailyPrice = dailyPrice.price;                            
                            roomRate = {
                                currencyCode: 'EUR',
                                rateCode: rateMappingCode,
                                dailyAmount: price.totalDailyPrice,
                                effectiveDate: mCheckin,
                                expireDate: moment(mCheckin).add(diff, 'day').format("YYYY-MM-DD")
                            };
                            roomStay.roomRates.push(roomRate);
                        }
                        if (!checkAvailability(mCheckin, idx) || (hasMaxStay && roomStay.roomRates.length >= roomStay.maxStay)) {
                            roomRate.expireDate = moment(mCheckin).subtract(1, 'day').format("YYYY-MM-DD");
                            break;
                        }                        
                    }
                }
            }
            
            roomStay.roomRates.sort((rate1, rate2) => moment(moment(rate1.effectiveDate).format("YYYY-MM-DD")).diff(moment(moment(rate2.effectiveDate).format("YYYY-MM-DD")), 'days'));
            roomStay.totalAmount = this.getRoomStayTotalAmount(roomStay);
            this.determineReservationTotalAmout(reservation);
            this.setState({ reservation });
        }
        else if (!isEditable) {
            const newRoomStay = {
                manualPrices: false,
                roomStayId: null,
                numberOfRooms: 1,
                adults: price.adults,
                children: price.children,
                roomCode: roomMappingCode,
                guestsRPHs: [],
                totalAmount: minStay ? parseFloat(parseFloat(minStay * price.totalDailyPrice).toFixed(2)) : price.totalDailyPrice,
                taxCharges: [],
                specialRequests: [],
                roomRates: [],
                roomCategoryDesc: roomCategoryDesc,
                rateDesc: rateDesc,
                color: color,
                colorValue: colorValue,
                isPending: true,
                maxStay: maxStay,
                minStay: minStay
            };
            let mCheckin = moment(day).format("YYYY-MM-DD");
            const newRoomRate = {
                currencyCode: 'EUR',
                rateCode: rateMappingCode,
                dailyAmount: price.totalDailyPrice,
                effectiveDate: mCheckin,
                expireDate: moment(mCheckin).add(minStay > 0 ? minStay - 1 : minStay, 'day').format("YYYY-MM-DD")
            };
            newRoomStay.roomRates.push(newRoomRate);
            reservation.roomStays.push(newRoomStay);
            this.determineReservationTotalAmout(reservation);
            this.setState({ reservation });
        }
    }

    getRoomStayTotalAmount(roomStay) {
        return parseFloat((roomStay.roomRates.reduce((acc, cur) => acc + (cur.dailyAmount * (moment(moment(cur.expireDate).format("YYYY-MM-DD")).diff(moment(moment(cur.effectiveDate).format("YYYY-MM-DD")), 'days') + 1)), 0) * roomStay.numberOfRooms).toFixed(2));
    }

    confirmRoomStay(roomStayIdx) {
        const { discount, discountType, reservation} = this.state;
        const roomStay = reservation.roomStays[roomStayIdx];
        roomStay.isPending = false;
        let colorIdx = 0;
        reservation.roomStays
            .forEach(room => {
                if (room.roomCode === roomStay.roomCode && moment(room.roomRates[room.roomRates.length - 1].expireDate).isSameOrAfter(roomStay.roomRates[0].effectiveDate) && moment(room.roomRates[0].effectiveDate).isSameOrBefore(roomStay.roomRates[roomStay.roomRates.length - 1].expireDate) && room.colorIdx >= 0) {
                    colorIdx = room.colorIdx + 1;
                }
                if (room.manualPrices) {
                    if (discountType === 'percentage') {
                        this.revertPercetageDiscount(room);
                    }
                    else {
                        const discountToApplyToRoomStay = parseFloat(parseFloat(discount / (reservation.roomStays.length - 1)).toFixed(2));
                        this.revertNumberDiscount(room, discountToApplyToRoomStay);
                    }
                }
            });
        reservation.roomStays[roomStayIdx].colorIdx = colorIdx;
        this.determineReservationTotalAmout(reservation);
        this.setState({ reservation, totalNoDiscount: reservation.totalAmount, totalWithDiscount: reservation.totalAmount, discount: null, discountType: null });
    }

    changeValsIntoRoomStay(name, idx, evt) {
        if (evt && evt.target) {
            const { reservation, roomCategories } = this.state;
            const { value } = evt.target;
            const roomStay = Object.assign({}, reservation.roomStays[idx])
            roomStay[name] = parseInt(value);
            
            let diff = moment(moment(roomStay.roomRates[roomStay.roomRates.length - 1].expireDate).format("YYYY-MM-DD")).diff(moment(moment(roomStay.roomRates[0].effectiveDate).format("YYYY-MM-DD")), 'days');
            let roomRate = {
                currencyCode: 'EUR',
                rateCode: roomStay.roomRates[0].rateCode,
                dailyAmount: 0,
                effectiveDate: moment(roomStay.roomRates[0].effectiveDate).format("YYYY-MM-DD"),
                expireDate: moment(roomStay.roomRates[0].effectiveDate).add(diff, 'day').format("YYYY-MM-DD")
            };
            const roomRates = [];
            roomCategories.find(rc => {
                rc.rates.find(rate => {
                    if (roomStay.roomCode === rate.rateDetails[0].roomMappingCode && roomStay.roomRates[0].rateCode === rate.rateDetails[0].rateMappingCode) {
                        const componentPrice = rate.components.reduce((acc, curr) => acc + curr.componentPrice, 0);
                        const componentPriceChildren = rate.components.reduce((acc, curr) => acc + curr.componentPriceChildren, 0);

                        rate
                            .rateDetails[0]
                            .rateData
                            .forEach(rData => {
                                if (moment(rData.day).isSameOrAfter(moment(roomRate.effectiveDate)) && moment(rData.day).isSameOrBefore(moment(roomRate.expireDate))) {
                                    const price = rData.prices.find(p => p.adults == roomStay.adults && p.children == roomStay.children);
                                    if (price) {
                                        const totalDailyPrice = this.getTotalDailyPrice(price.price, componentPrice, roomStay.adults, componentPriceChildren, roomStay.children);
                                        if (totalDailyPrice !== roomRate.dailyAmount) {
                                            roomRate.expireDate = moment(rData.day).subtract(1, 'day').format("YYYY-MM-DD");
                                            roomRate = {
                                                currencyCode: 'EUR',
                                                rateCode: roomRate.rateCode,
                                                dailyAmount: totalDailyPrice,
                                                effectiveDate: moment(rData.day).format("YYYY-MM-DD"),
                                                expireDate: moment(rData.day).add(diff, 'day').format("YYYY-MM-DD")
                                            };
                                            roomRates.push(roomRate);
                                        }
                                    }
                                    diff--;
                                }
                            });
                    }
                });
            });
            if (roomRates.length > 0) {
                roomStay.roomRates = roomRates;
                roomStay.totalAmount = this.getRoomStayTotalAmount(roomStay);
                reservation.roomStays[idx] = roomStay;
                reservation.totalAmount = parseFloat(reservation.roomStays.reduce((acc, cur) => acc + cur.totalAmount, 0).toFixed(2));
            }
            this.setState({ reservation, totalNoDiscount: reservation.totalAmount, totalWithDiscount: reservation.totalAmount });
        }
    }

    getTotalDailyPrice(price, componentPrice, adults, componentPriceChildren, children) {
        return price + (componentPrice * adults) + (componentPriceChildren * children);
    }

    changeRooms(name, idx, evt) {
        if (evt && evt.target && evt.target.value) {
            const { reservation } = this.state;
            const { value } = evt.target;
            const roomStay = reservation.roomStays[idx];
            roomStay[name] = parseInt(value);
            roomStay.totalAmount = parseFloat((roomStay.roomRates.reduce((acc, cur) => acc + (cur.dailyAmount * (moment(moment(cur.expireDate).format("YYYY-MM-DD")).diff(moment(moment(cur.effectiveDate).format("YYYY-MM-DD")), 'days') + 1)), 0) * roomStay.numberOfRooms).toFixed(2));
            reservation.totalAmount = parseFloat(reservation.roomStays.reduce((acc, cur) => acc + cur.totalAmount, 0).toFixed(2));
            this.setState({ reservation, totalNoDiscount: reservation.totalAmount, totalWithDiscount: reservation.totalAmount });
        }
    }

    removeRoomStay(idx) {
        const { reservation } = this.state;
        reservation.roomStays.splice(idx, 1);
        this.determineReservationTotalAmout(reservation);
        this.setState({ reservation, totalNoDiscount: reservation.totalAmount, totalWithDiscount: reservation.totalAmount });
    }

    editRoomStayRPH = (rph, index, add) => {
        const { reservation } = this.state;
        if (add) {
            reservation.roomStays[index].guestsRPHs.push(rph);
        }
        else {
            reservation.roomStays[index].guestsRPHs = reservation.roomStays[index].guestsRPHs.filter(el => el !== rph);
        }
        this.setState({ reservation });
    }

    finishReservation = (evt) => {
        evt.preventDefault();
        this.setState({ block: true });

        let body = { ...this.state.reservation };

        postAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="ManualReservation.ReservationCreated" />, <FormattedMessage id="General.Success" />);
                    this.setState({ block: false, error: errorMessage, reservation: this.getNewReservation(this.state.reservation.hotelCode), steps: this.getInitialSteps() });
                }
            }
            else {
                this.setState({ block: false, error: errorMessage });
            }
        }, '/api/Rate/Reservation/v1/InternalReservation', JSON.stringify({ request: body }));
    }

    setCombo(combo, evt) {
        if (evt) {
            const { name } = evt;
            const value = combo && combo.value;
            this.setState({ [name]: value });
        }
    }

    handleDiscount = (event) => {
        if (event) {
            const { reservation } = this.state;
            const { name, value } = event.target;
            let totalWithDiscount = 0;
            if (name === 'percentage') {
                totalWithDiscount = parseFloat(reservation.totalAmount * ((100 - value) / 100)).toFixed(2);
            }
            else {
                totalWithDiscount = parseFloat(reservation.totalAmount - value);
            }

            this.setState({
                discount: value,
                discountType: name,
                totalWithDiscount: totalWithDiscount
            });
        }
    }

    applyDiscount = () => {
        const { reservation, discountType, discount } = this.state;

        if (discount) {
            if (discountType === 'percentage') {
                reservation.roomStays.forEach(roomStay => {
                    roomStay.roomRates.forEach(rate => {
                        rate.dailyAmount *= ((100 - discount) / 100);
                    });
                    roomStay.manualPrices = true;
                    roomStay.totalAmount = parseFloat((roomStay.roomRates.reduce((acc, cur) => acc + (cur.dailyAmount * (moment(moment(cur.expireDate).format("YYYY-MM-DD")).diff(moment(moment(cur.effectiveDate).format("YYYY-MM-DD")), 'days') + 1)), 0) * roomStay.numberOfRooms).toFixed(2));
                });
            }
            else {
                if (reservation.roomStays.length > 0) {
                    const discountToApplyToRoomStay = parseFloat(discount / reservation.roomStays.length).toFixed(2);
                    reservation.roomStays.forEach(roomStay => {
                        const days = moment(moment(roomStay.roomRates[roomStay.roomRates.length - 1].expireDate).format("YYYY-MM-DD")).diff(moment(moment(roomStay.roomRates[0].effectiveDate).format("YYYY-MM-DD")), 'days') + 1;
                        const discountToApplyToRoomRate = parseFloat(parseFloat(discountToApplyToRoomStay / days).toFixed(2));
                        roomStay.roomRates.forEach(rate => {
                            rate.dailyAmount = parseFloat(parseFloat(rate.dailyAmount - discountToApplyToRoomRate).toFixed(2));
                        });
                        roomStay.manualPrices = true;
                        let totalAmount = this.getRoomStayTotalAmount(roomStay);
                        const diff = parseFloat((roomStay.totalAmount - discountToApplyToRoomStay) - totalAmount).toFixed(2);
                        if (diff != 0) {
                            const positiveDiff = Math.abs(diff);
                            const roomRate = roomStay.roomRates[roomStay.roomRates.length - 1];
                            const date = roomRate.expireDate;
                            roomRate.expireDate = moment(roomRate.expireDate).subtract(1, 'day').format("YYYY-MM-DD");
                            const roomRateWithDiff = {
                                currencyCode: 'EUR',
                                rateCode: roomRate.rateCode,
                                dailyAmount: diff < 0 ? parseFloat(parseFloat(parseFloat(roomRate.dailyAmount) - positiveDiff).toFixed(2)) : parseFloat(parseFloat(parseFloat(roomRate.dailyAmount) + positiveDiff).toFixed(2)),
                                effectiveDate: date,
                                expireDate: date
                            };
                            roomStay.roomRates.push(roomRateWithDiff);
                            totalAmount = this.getRoomStayTotalAmount(roomStay);
                        }
                        roomStay.totalAmount = totalAmount;
                    });
                }
            }
            this.determineReservationTotalAmout(reservation);
            this.setState({ reservation });
        }
    }

    getRoomStayTotalAmount(roomStay) {
        return parseFloat((roomStay.roomRates.reduce((acc, cur) => acc + (cur.dailyAmount * (moment(moment(cur.expireDate).format("YYYY-MM-DD")).diff(moment(moment(cur.effectiveDate).format("YYYY-MM-DD")), 'days') + 1)), 0) * roomStay.numberOfRooms).toFixed(2))
    }

    determineReservationTotalAmout(reservation) {
        reservation.totalAmount = parseFloat(reservation.roomStays.reduce((acc, cur) => acc + cur.totalAmount, 0).toFixed(2));
    }

    revertDiscount = (evt) => {
        if (evt) {
            const { discount, discountType, reservation } = this.state;
            if (discountType === 'percentage') {
                reservation.roomStays.forEach(this.revertPercetageDiscount);
            }
            else {
                const discountToApplyToRoomStay = parseFloat(parseFloat(discount / reservation.roomStays.length).toFixed(2));
                reservation.roomStays.forEach(roomStay => this.revertNumberDiscount(roomStay, discountToApplyToRoomStay));
            }
            this.determineReservationTotalAmout(reservation);
            this.setState({ reservation, discount: null, discountType: null });
        }
    }

    revertPercetageDiscount(roomStay) {
        const { discount } = this.state;

        roomStay.roomRates.forEach(rate => {
            rate.dailyAmount /= ((100 - discount) / 100);
        });
        roomStay.manualPrices = false;
        roomStay.totalAmount = parseFloat((roomStay.roomRates.reduce((acc, cur) => acc + (cur.dailyAmount * (moment(moment(cur.expireDate).format("YYYY-MM-DD")).diff(moment(moment(cur.effectiveDate).format("YYYY-MM-DD")), 'days') + 1)), 0) * roomStay.numberOfRooms).toFixed(2));
    }

    revertNumberDiscount(roomStay, discountToApplyToRoomStay) {
        const days = moment(moment(roomStay.roomRates[roomStay.roomRates.length - 1].expireDate).format("YYYY-MM-DD")).diff(moment(moment(roomStay.roomRates[0].effectiveDate).format("YYYY-MM-DD")), 'days') + 1;
        const discountToApplyToRoomRate = parseFloat(discountToApplyToRoomStay / days).toFixed(2);
        roomStay.roomRates.forEach(rate => {
            rate.dailyAmount = parseFloat(parseFloat(rate.dailyAmount) + parseFloat(discountToApplyToRoomRate)).toFixed(2);
        });
        roomStay.manualPrices = false;
        let totalAmount = this.getRoomStayTotalAmount(roomStay);
        const diff = parseFloat(roomStay.totalAmount + discountToApplyToRoomStay - totalAmount).toFixed(2);
        if (diff != 0) {
            const roomRate = roomStay.roomRates[roomStay.roomRates.length - 2];
            roomRate.expireDate = moment(roomRate.expireDate).add(1, 'day').format("YYYY-MM-DD");
            roomStay.roomRates.splice(roomStay.roomRates.length - 1, 1);
            totalAmount = this.getRoomStayTotalAmount(roomStay);
        }
        roomStay.totalAmount = totalAmount;
    }

    render() {
        const { steps, reservation, block, roomCategories, checkin, checkout, roomCategory, roomCategoriesOptions, colors, discount, discountType, totalNoDiscount, totalWithDiscount } = this.state;
        const isEditable = reservation.roomStays.find(room => room.isPending) !== undefined;

        function getAdults(roomCode, rateCode, children, isMax) {
            let adults = 0;
            roomCategories.find(rc => {
                rc.rates.find(rate => {
                    if (roomCode === rate.rateDetails[0].roomMappingCode && rateCode === rate.rateDetails[0].rateMappingCode) {
                        const occupancies = rate.occupancies.filter(occ => occ.children === children);
                        if (occupancies.length > 0) {
                            adults = occupancies[isMax ? occupancies.length - 1 : 0].adults;
                        }
                    }
                });
            });
            return adults;
        }

        function getMaxAdults(roomCode, rateCode, children) {
            return getAdults(roomCode, rateCode, children, true);
        }

        function getMinAdults(roomCode, rateCode, children) {
            return getAdults(roomCode, rateCode, children, false);
        }

        function getChildren(roomCode, rateCode, adults, isMax) {
            let children = 0;
            roomCategories.find(rc => {
                rc.rates.find(rate => {
                    if (roomCode === rate.rateDetails[0].roomMappingCode && rateCode === rate.rateDetails[0].rateMappingCode) {
                        const occupancies = rate.occupancies.filter(occ => occ.adults === adults);
                        if (occupancies.length > 0) {
                            children = occupancies[isMax ? occupancies.length - 1 : 0].children;
                        }
                    }
                });
            });
            return children;
        }

        function getMaxChildren(roomCode, rateCode, adults) {
            return getChildren(roomCode, rateCode, adults, true);
        }

        function getMinChildren(roomCode, rateCode, adults) {
            return getChildren(roomCode, rateCode, adults, false);
        }

        function getMaxRooms(roomCode, roomRates, roomStayIdx) {
            let rooms = Number.MAX_VALUE;
            roomCategories.find(rc => {
                rc.rates.find(rate => {
                    if (roomCode === rate.rateDetails[0].roomMappingCode) {
                        rate.rateDetails[0].rateData.forEach(rData => {
                            const mDay = moment(rData.day);
                            if (moment(mDay).isSameOrAfter(moment(roomRates[0].effectiveDate)) && moment(mDay).isSameOrBefore(moment(roomRates[roomRates.length -1].expireDate))) {
                                let freeRooms = rData.freeRooms;
                                reservation.roomStays
                                    .forEach((room, idx) => {
                                        if (room.roomCode === roomCode && room.roomRates[0].rateCode === roomRates[0].rateCode && roomStayIdx !== idx) {
                                            room.roomRates.forEach(rate => {
                                                if (moment(rate.effectiveDate).isSameOrBefore(mDay) && mDay.isSameOrBefore(moment(rate.expireDate))) {
                                                    freeRooms -= room.numberOfRooms;
                                                }
                                            })
                                        }
                                    }
                                );
                                if (freeRooms < rooms) {
                                    rooms = freeRooms;
                                }
                            }
                        });
                    }
                });
            });
            return rooms;
        }

        return (
            <BlockUi tag="div" blocking={block}>
                <div>
                    <Row className="mb-4 align-items-center">
                        {
                            steps[3].active === true ?
                                ''
                                :
                                <Col className="col-2 ">
                                    <CardBody className="pr-0">
                                        <Row style={{ marginLeft: '-20px' }}>
                                            <Col>
                                                <h5>
                                                    <FormattedMessage id="ManualReservation.Reservation" />
                                                </h5>
                                            </Col>
                                            <Col className="text-right pr-0">
                                                <CommonHelper help={<FormattedMessage id="ManualReservation.HelpText" />} id="ManualReservation" placement="right"/>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Col>
                        }
                        <Col className="col-1 text-left" style={{ 'display': 'flex', 'alignItems': 'center' }}>
                            {
                                steps[0].active === true ?
                                    ''
                                    :
                                    <Button className="btn- btn-host btn-small mr-4" style={{ 'flex': '1' }} onClick={this.movePos.bind(this, -1)}>
                                        <i className="fas fa-angle-left" />
                                    </Button>
                            }
                        </Col>
                        <Col className="py-1">
                            <ul className="steps">
                                <li className={'step ' + (steps[0].active === true ? ' step-active' : '')} style={{ cursor: 'pointer' }}>
                                    <div className="step-content">
                                        <span className="step-text small">
                                            <FormattedMessage id="ManualReservation.Step1" />
                                        </span>
                                        <span className="step-circle" onClick={() => this.next(1)} />
                                        <span className="step-text small">
                                            <FormattedMessage id="ManualReservation.CategoriesAndPrices" />
                                        </span>
                                    </div>
                                </li>
                                <li className={'step ' + (steps[1].active === true ? ' step-active' : '')} style={{ cursor: 'pointer' }}>
                                    <div className="step-content">
                                        <span className="step-text small">
                                            <FormattedMessage id="ManualReservation.Step2" />
                                        </span>
                                        <span className="step-circle" onClick={() => { if (reservation.roomStays.length !== 0) this.next(2); }} />
                                        <span className="step-text small">
                                            <FormattedMessage id="ManualReservation.Guests" />
                                        </span>
                                    </div>
                                </li>
                                <li className={'step ' + (steps[2].active === true ? ' step-active' : '')} style={{ cursor: 'pointer' }}>
                                    <div className="step-content">
                                        <span className="step-text small">
                                            <FormattedMessage id="ManualReservation.Step3" />
                                        </span>
                                        <span className="step-circle" onClick={() => { if (reservation.roomStays.length !== 0 && reservation.profiles.length !== 0) this.next(3); }} />
                                        <span className="step-text small">
                                            <FormattedMessage id="ManualReservation.Others" />
                                        </span>
                                    </div>
                                </li>
                                <li className={'step ' + (steps[3].active === true ? ' step-active' : '')} style={{ cursor: 'pointer' }}>
                                    <div className="step-content">
                                        <span className="step-text small">
                                            <FormattedMessage id="ManualReservation.Step4" />
                                        </span>
                                        <span className="step-circle" onClick={() => { if (reservation.roomStays.length !== 0 && reservation.profiles.length !== 0) this.next(4); }} />
                                        <span className="step-text small">
                                            <FormattedMessage id="ManualReservation.Confirm" />
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </Col>
                        <Col className="col-1 text-right" style={{ 'display': 'flex', 'alignItems': 'center' }}>
                            {
                                steps[3].active === true ?
                                    <Button className="btn- btn-host btn-small mr-4" style={{ 'flex': '1' }} onClick={(e) => this.finishReservation(e)}>
                                        <FormattedMessage id="ManualReservation.Finish" />
                                    </Button>
                                    :
                                    <Button className="btn- btn-host btn-small ml-4" style={{ 'flex': '1' }} onClick={this.movePos.bind(this, 1)} disabled={steps[0].active && reservation.roomStays.find(rs => !rs.isPending) === undefined || steps[1].active && reservation.profiles.length === 0}>
                                        <i className="fas fa-angle-right" />
                                    </Button>
                            }
                        </Col>
                    </Row>
                    <Row>
                        {
                            steps[3].active === true ?
                                ''
                                :
                                reservation.roomStays.length > 0 ?
                                    <Col className="col-2 pr-0">
                                        {
                                            reservation.roomStays.map((roomStay, key) =>
                                                <Card key={key} className="my-1 small mr-1 shadow border-0">
                                                    <CardBody className="m-1 py-1">
                                                        <Row>
                                                            <Col className="px-1">
                                                                <h6 className="pb-1" style={{ borderBottom: '2px solid ' + roomStay.color }}>
                                                                    {roomStay.roomCategoryDesc}
                                                                </h6>
                                                            </Col>
                                                            <Col className=" text-right col-1 px-1 py-1">
                                                                <a className="pointer" size="sm" onClick={_ => this.removeRoomStay(key)}>
                                                                    <i className="fas fa-times text-danger" />
                                                                </a>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-1">
                                                            <Col className="px-1">
                                                                <span className="text-host ">
                                                                    <b>
                                                                        {`${moment(roomStay.roomRates[0].effectiveDate).format('ddd')} ${moment(roomStay.roomRates[0].effectiveDate).date()} ${moment(roomStay.roomRates[0].effectiveDate).format('MMM')} - ${moment(roomStay.roomRates[roomStay.roomRates.length - 1].expireDate).add(1, 'day').format('ddd')} ${moment(roomStay.roomRates[roomStay.roomRates.length - 1].expireDate).add(1, 'day').date()} ${moment(roomStay.roomRates[roomStay.roomRates.length - 1].expireDate).add(1, 'day').format('MMM')}`}
                                                                    </b>
                                                                </span>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-1">
                                                            <Col className="col-5 px-1">
                                                                <p>
                                                                    <FormattedMessage id="ManualReservation.Rooms" />
                                                                </p>
                                                            </Col>
                                                            <Col className="col-7">
                                                                <Input type="number" name="numberOfRooms" id={`numberOfRooms${roomStay.roomCode}${roomStay.roomRates[0].rateCode}`} bsSize="sm" className="text-sm" min="1" max={getMaxRooms(roomStay.roomCode, roomStay.roomRates, key)} value={roomStay.numberOfRooms} onChange={this.changeRooms.bind(this, 'numberOfRooms', key)} disabled={roomStay.id !== undefined} />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-1">
                                                            <Col className="col-5 px-1">
                                                                <p>
                                                                    <FormattedMessage id="ManualReservation.Adults" />
                                                                </p>
                                                            </Col>
                                                            <Col className="col-7">
                                                                <Input type="number" name="adults" id={`adults${roomStay.roomCode}${roomStay.roomRates[0].rateCode}`} bsSize="sm" className="text-sm" min={getMinAdults(roomStay.roomCode, roomStay.roomRates[0].rateCode, roomStay.children)} max={getMaxAdults(roomStay.roomCode, roomStay.roomRates[0].rateCode, roomStay.children)} value={roomStay.adults} onChange={this.changeValsIntoRoomStay.bind(this, 'adults', key)} disabled={roomStay.id !== undefined} />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-1">
                                                            <Col className="col-5 px-1">
                                                                <p>
                                                                    <FormattedMessage id="ManualReservation.Children" />
                                                                </p>
                                                            </Col>
                                                            <Col className="col-7">
                                                                <Input type="number" name="children" id={`children${roomStay.roomCode}${roomStay.roomRates[0].rateCode}`} bsSize="sm" className="text-sm" min={getMinChildren(roomStay.roomCode, roomStay.roomRates[0].rateCode, roomStay.adults)} max={getMaxChildren(roomStay.roomCode, roomStay.roomRates[0].rateCode, roomStay.adults)} value={roomStay.children} onChange={this.changeValsIntoRoomStay.bind(this, 'children', key)} disabled={roomStay.id !== undefined} />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-1">
                                                            <Col className="col-5 px-1">
                                                                <h6>
                                                                    <FormattedMessage id="ManualReservation.SubTotal" />
                                                                </h6>
                                                            </Col>
                                                            <Col className="col-7">
                                                                <h6>
                                                                    {`${roomStay.totalAmount} €`}
                                                                </h6>
                                                            </Col>
                                                        </Row>
                                                        <Row className="mb-1">
                                                            <Col className="px-1">
                                                                {roomStay.rateDesc}
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            )
                                        }
                                    </Col>
                                    :
                                    <Col className="col-2 pr-0" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className="text-center">
                                            <Row>
                                                <Col>
                                                    <i className="fas fa-2x fa-info-circle" />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="text-muted m-3 ">
                                                    <FormattedMessage id="ManualReservation.StartReservation" />
                                                </Col>
                                            </Row>
                                        </div>    
                                    </Col>
                        }
                        <Col className={`col-${steps[3].active ? `12` : `10`} pt-0 row-eq-height`}>
                            <ErrorAlert error={this.state.error} />
                            <div className="border-0 h-100">
                                <div style={{ display: steps[0].active === true ? 'block' : 'none', height: '100%' }}>
                                    <ManualReservationCategoriesPrices
                                        reservation={reservation}
                                        handleAddDay={this.handleAddDay}
                                        confirmRoomStay={this.confirmRoomStay}
                                        isEditable={isEditable}
                                        block={block}
                                        roomCategories={roomCategories}
                                        getCalendar={this.getCalendar}
                                        checkin={checkin}
                                        checkout={checkout}
                                        updateCheckInAndCheckout={this.updateCheckInAndCheckout}
                                        roomCategory={roomCategory}
                                        setCombo={this.setCombo}
                                        roomCategoriesOptions={roomCategoriesOptions}
                                        colors={colors}
                                        getTotalDailyPrice={this.getTotalDailyPrice}
                                    />
                                </div>
                                <div style={{ display: steps[1].active === true ? 'block' : 'none', height: '100%' }}>
                                    <ManualReservationGuests
                                        reservation={reservation}
                                        addGuest={this.addGuest}
                                        updateGuest={this.updateGuest}
                                        removeGuest={this.removeGuest}
                                        editRoomStayRPH={this.editRoomStayRPH}
                                    />
                                </div>
                                <div style={{ display: steps[2].active === true ? 'block' : 'none', height: '100%' }}>
                                    <ManualReservationOthers
                                        reservation={reservation}
                                        addComment={this.addComment}
                                        removeComment={this.removeComment}
                                        updateComment={this.updateComment}
                                        setSource={this.setSource}
                                    />
                                </div>
                                <div style={{ display: steps[3].active === true ? 'block' : 'none', height: '100%' }}>
                                    <ManualReservationConfirm
                                        reservation={reservation}
                                        reservationCreated={this.state.reservationCreated}
                                        handleDiscount={this.handleDiscount}
                                        discount={discount}
                                        discountType={discountType}
                                        applyDiscount={this.applyDiscount}
                                        revertDiscount={this.revertDiscount}
                                        totalNoDiscount={totalNoDiscount}
                                        totalWithDiscount={totalWithDiscount}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>    
            </BlockUi>
        );
    }
}