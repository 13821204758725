import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import ReservationDetailView from './ReservationDetailView';

export class ReservationView extends Component {
    render() {
        return (
            <div>
                <Modal isOpen={this.props.modal} toggle={this.props.action} size="modal-sm">
                    <ModalHeader toggle={this.props.action}>
                        <FormattedMessage id="ReservationDetail.ReservationDetails" />
                    </ModalHeader>
                    <ModalBody>
                        <ReservationDetailView data={this.props.data} showReservationLogs={true} hidePmsData={this.props.hidePmsData} />
                    </ModalBody>
                </Modal>
            </div>    
        );
    }
}