import React, { Component } from 'react';
import { TabContent, TabPane, NavLink, NavItem, Nav, Card } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import classnames from 'classnames';
import BookingMarketInsights from './BookingMarketInsights';

class MarketInsights extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            channelinstanceList: [],
            selectedConnection: '',
            propertyScore: [],
            activeTab: '1',
            hasAirBnb: false
        }
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <Card className="border-0">
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="MarketInsights.BookingMarketInsights" />
                        </NavLink>
                    </NavItem>
                    {/*<NavItem className="ml-auto border-bottom mt-2 mr-2">
                        <CommonHelper help={<FormattedMessage id="MarketInsights.Help" />} id={'MarketInsights'} />
                    </NavItem>*/}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <BookingMarketInsights />
                    </TabPane>
                </TabContent>
            </Card>
        );
    }
}

export default injectIntl(MarketInsights)