import React, { Component } from 'react';
import { Table, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { FormattedMessage, injectIntl, FormattedNumber } from 'react-intl';
import { getChannelConfig } from "../Base/Common/ReferenceDataFunctions";
import DashboardCard from "../Base/Common/DashboardCard";
import { Pie } from 'react-chartjs-2';
import classnames from 'classnames';
import { CSVLink } from 'react-csv';
import BlockUi from 'react-block-ui';
import { CustomPopover } from '../Base/Common/CommonUIComponents';


class DataByChannel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            activeTab: '1'
        };
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { reservationSummary, nightsPerChannel, dataToExport } = this.props;

        let optionsPie = {
            legend: {
                position: "right"
            }
        }

        return (
            <Row className="mb-2">
                <div className="container-fluid d-xl-flex p-0">
                    <Col className="col-xl-4 mb-2 mb-xl-0">
                        <DashboardCard header={<FormattedMessage id="AnalyticsDashboard.ReservationByChannel" />} block={this.props.blockChannel} help={<FormattedMessage id="AnalyticsDashboard.PieChartHelp" />} id="PieChartHelp" className="pt-2">
                            <Pie data={nightsPerChannel} height={'100px'} options={optionsPie} />
                        </DashboardCard>
                    </Col>
                    <Col className="col-xl-8 mt-2 mt-xl-0">
                        <BlockUi tag="div" blocking={this.props.blockChannel}>
                            <Nav tabs className="position-top">
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }} style={{ paddingTop: '17px', paddingBottom: '17px' }}>
                                        <span className="text-xs font-weight-bold text-secondary text-uppercase"><FormattedMessage id="AnalyticsDashboard.CancelByChannel" /></span>

                                        <span className="btn-sm btn-link" style={{ fontSize: 'large' }} id="TableHelp">
                                            <i className="text-secondary fas fa-question-circle"></i>
                                        </span>
                                        <CustomPopover target="TableHelp">
                                                <FormattedMessage id="AnalyticsDashboard.TableHelp" />
                                        </CustomPopover>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }} style={{ paddingTop: '17px', paddingBottom: '17px' }}>
                                        <span className="text-xs font-weight-bold text-secondary text-uppercase"><FormattedMessage id="AnalyticsDashboard.RevenueByChannel" /></span>
                                    </NavLink>
                                </NavItem>

                                <NavItem className="ml-auto border-bottom mt-2 mr-2" style={{ paddingTop: '5px' }}>
                                    { dataToExport && dataToExport.length > 1 ?
                                        <CSVLink className="btn btn-sm btn-host mr-1" data={dataToExport} filename={"DataByChannel.csv"}>
                                            <i className="fas fa-file-download" />
                                        </CSVLink>
                                    : ''  }
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={this.state.activeTab} className="border-left: 1px solid #ddd;">
                                <TabPane tabId="1">
                                    <Row>
                                        <Col sm="12">
                                            <Table size="sm">
                                                <thead>
                                                    <tr style={{ height: '59px' }}>
                                                        <th style={{ verticalAlign: 'middle', borderTop: '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.Channel" })}
                                                        </th>
                                                        <th style={{ verticalAlign: 'middle', borderTop: '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.TotalRoomNight" })}
                                                        </th>
                                                        <th style={{ verticalAlign: 'middle', borderTop: '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.CancelRoomNights" })}
                                                        </th>
                                                        <th style={{ verticalAlign: 'middle', borderTop: '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.CancelationInChannel" })}
                                                        </th>
                                                        <th style={{ verticalAlign: 'middle', borderTop: '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.CancelationTotal" })}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {reservationSummary?.map((el, key) => {
                                                        const channel = getChannelConfig(el.channelCode);
                                                        const totalNights = el.totalNights + el.canceledNights;
                                                        const totalCanceledNights = reservationSummary.reduce((a, b) => a + b.canceledNights, 0);

                                                        return (
                                                            <tr key={key}>
                                                                <td>{channel ? <img alt="" src={channel.image} height={channel.iconSize}></img> : ''} <strong> {el.channelCode} </strong></td>
                                                                <td>{totalNights}</td>
                                                                <td>{el.canceledNights}</td>
                                                                <td >{parseFloat((el.canceledNights / totalNights) * 100).toFixed(2)} %</td>
                                                                <td >{parseFloat(totalCanceledNights === 0 ? 0 : (el.canceledNights / reservationSummary.reduce((a, b) => a + b.canceledNights, 0)) * 100).toFixed(2)} %</td>
                                                            </tr>
                                                        )
                                                    } )}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Row>
                                        <Col sm="12">
                                            <Table size="sm">
                                                <thead>
                                                    <tr style={{ height: '59px' }}>
                                                        <th style={{ verticalAlign: 'middle', borderTop:  '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.Channel" })}<br />
                                                        </th>
                                                        <th style={{ verticalAlign: 'middle', borderTop:  '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.TotalReservas" })}<br />
                                                        </th>
                                                        <th style={{ verticalAlign: 'middle', borderTop:  '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.Reservations" })}<br/>
                                                            <span className="small text-muted"> ({this.props.intl.formatMessage({id: "AnalyticsDashboard.NonCanceled" })}) </span>
                                                        </th>
                                                        <th style={{ verticalAlign: 'middle', borderTop:  '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.RoomRevenue" })}<br />
                                                        </th>
                                                        <th style={{ verticalAlign: 'middle', borderTop:  '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.AvgLenStay" })}<br />
                                                        </th>
                                                        <th style={{ verticalAlign: 'middle', borderTop:  '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.ADR" })}<br />
                                                        </th>
                                                        <th style={{ verticalAlign: 'middle', borderTop:  '0' }}>
                                                            {this.props.intl.formatMessage({ id: "AnalyticsDashboard.AvgLedStay" })}<br />
                                                            <span className="small text-muted"> ({this.props.intl.formatMessage({ id: "AnalyticsDashboard.Days" })}) </span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {reservationSummary?.map((el, key) =>
                                                        <tr key={key}>
                                                            <td>{getChannelConfig(el.channelCode) ? <img alt="" src={getChannelConfig(el.channelCode).image} height={getChannelConfig(el.channelCode).iconSize}></img> : ''}<strong> {el.channelCode} </strong></td>
                                                            <td>{el.totalReservations}</td>
                                                            <td>{el.totalNonCanceledReservations}</td>
                                                            <td><FormattedNumber value={el.roomRevenue} style="currency" currency={global.hotelCurrency} minimumFractionDigits={2} /></td>
                                                            <td>{el.avgLenStay}</td>
                                                            <td><FormattedNumber value={el.adr} style="currency" currency={global.hotelCurrency} minimumFractionDigits={2} /></td>
                                                            <td>{el.avgLedTime} </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row>
                                </TabPane>  
                            </TabContent>
                        </BlockUi>
                    </Col>
                </div>
            </Row>
        );
    }
}
export default injectIntl(DataByChannel)