import React, { Component } from 'react'
import BlockUi from 'react-block-ui'
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { Card, CardBody, Col, FormGroup, Input, Label, Modal, ModalBody, Row, ModalHeader } from 'reactstrap'
import { postAPI } from '../../Base/API';
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from '../../Base/Notification';
import { ErrorAlert } from '../../Common/ErrorAlert';
import Authorization, { CheckAuthorization } from '../../Base/Authorization';

class NewCancelPolicy extends Component {

    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            block: false,
            newPolicy: {
                id: null,
                active: false,
                cancelUntil: 0,
                noShowPenalty: 0,
                daysBefore: 0,
                percentageAfter: 0,
                percentageBefore: 0
            }
        }
    }

    componentDidMount(){
        const { customPolicy } = this.props;
        
        if(customPolicy){
            this.setState({ newPolicy: customPolicy });
        }
    }

    generateNewCancellationPolicy = (e) => {
        e.preventDefault();
        const { newPolicy } = this.state;

        const percentageBeforeFlexibleComplete = document.getElementById('percentageBeforeFlexibleComplete');
        if (percentageBeforeFlexibleComplete) {
            if ((100 - newPolicy.percentageAfter) < newPolicy.percentageBefore) {
                percentageBeforeFlexibleComplete.setCustomValidity(this.props.intl.formatMessage({ id: "CustomPolicy.FlexibleCompleteInvalidPecentage" }, { value: newPolicy.percentageBefore }));
            }
            else {
                percentageBeforeFlexibleComplete.setCustomValidity("");
            }
        }

        if (this.form.current.reportValidity() && (parseInt(newPolicy.percentageBefore) + parseInt(newPolicy.percentageAfter) < 101)) {
            this.setState({ block: true });
            postAPI(result => {
                const { data, error } = result;
            
                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="CancelationPolicy.SuccessMessage" />, <FormattedMessage id="General.Success" />);
                        this.setState({ block: false }, () => {
                            if (data.response) {
                                this.props.updateCancelPolicy(data.response[0])
                            }
                            this.props.toggle();
                        });
                    }
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }, '/api/Rate/Rate/v1/cancelPolicy', newPolicy);
        }
    }

    handleSelect = (combo, e) => {
        if(combo && e){
            const { newPolicy } = this.state;

            newPolicy[e.name] = combo.value;


            this.setState({ newPolicy });
        }
    }

    handleFormChange = (e) => {
        if(e && e.currentTarget && e.currentTarget.name){

            const { newPolicy } = this.state;

            newPolicy[e.currentTarget.name] = e.currentTarget.value;


            this.setState({ newPolicy });
        };
    }

    render() {
        const { modal, toggle, intl } = this.props;
        const { block, newPolicy, error } = this.state;

        const typeOptions = [
            {
                label: intl.formatMessage({ id: "CustomPolicy.NonRef" }),
                value: 'NonRefundable'
            },
            {
                label: intl.formatMessage({ id: "CustomPolicy.Flex" }),
                value: 'Flexible'
            },
            {
                label: intl.formatMessage({ id: "CustomPolicy.FlexibleDaily" }),
                value: 'FlexibleDaily',
                isDisabled: true
            },
            {
                label: intl.formatMessage({ id: "CustomPolicy.FlexComplete" }),
                value: 'FlexibleComplete'
            },
            {
                label: intl.formatMessage({ id: "CustomPolicy.NonRefundableComplete" }),
                value: 'NonRefundableComplete'
            },
            {
                label: intl.formatMessage({ id: "CustomPolicy.DepositOnly" }),
                value: 'DepositOnly'
            },
            CheckAuthorization('admin:view') ?
                {
                    label: intl.formatMessage({ id: "CustomPolicy.FlexibleDailyComplete" }),
                    value: 'FlexibleDailyComplete',
                }
            :
                ''
        ];
        
        const activeOptions = [
            {
                label: intl.formatMessage({ id: "CustomPolicy.true" }),
                value: true
            },
            {
                label: intl.formatMessage({ id: "CustomPolicy.false" }),
                value: false
            }
        ];

        const daysBeforeOptions = [
            {
                label: `8 ${intl.formatMessage({ id: "CustomPolicy.days" })}`,
                value: '8'
            },
            {
                label: `15 ${intl.formatMessage({ id: "CustomPolicy.days" })}`,
                value: '15'
            },
            {
                label: `30 ${intl.formatMessage({ id: "CustomPolicy.days" })}`,
                value: '30'
            },
            {
                label: `2 ${intl.formatMessage({ id: "CustomPolicy.months" })}`,
                value: '60'
            },
            {
                label: `3 ${intl.formatMessage({ id: "CustomPolicy.months" })}`,
                value: '90'
            },
            {
                label: `6 ${intl.formatMessage({ id: "CustomPolicy.months" })}`,
                value: '180'
            },
            {
                label: `9 ${intl.formatMessage({ id: "CustomPolicy.months" })}`,
                value: '270'
            },
            {
                label: `12 ${intl.formatMessage({ id: "CustomPolicy.months" })}`,
                value: '360'
            }
        ];
        
        return (
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="AddRate.AssociateRate" />
                </ModalHeader>
                <ModalBody className="p-0">
                <ErrorAlert error={error} icon="fas fa-times-circle" />
                    <BlockUi tag="div" blocking={block}>
                        <form ref={this.form}>
                        {/*<Form onSubmit={this.generateNewCancellationPolicy}>*/}
                            <Card className="shadow border-0 p-0">
                                <CardBody className="m-0">
                                    <Row>
                                        <Col className="text-right">
                                            <button
                                                className="btn btn-host btn-sm ml-1"
                                                //type='submit'
                                                onClick={this.generateNewCancellationPolicy}
                                                disabled={!newPolicy || !newPolicy.name || !newPolicy.type}
                                            >
                                                <span className="fas fa-save" />
                                            </button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12 col-lg-9">
                                            <div>
                                                <Label><FormattedMessage id="CustomPolicy.Name" /></Label>
                                            </div>
                                            <Input
                                                onChange={this.handleFormChange}
                                                name='name'
                                                value={newPolicy.name || ''}
                                                required
                                            />
                                        </Col>
                                        <Col className="col-12 col-lg-3">
                                            <div className='mt-2'>
                                                <Label><FormattedMessage id="CustomPolicy.Active" /></Label>
                                            </div>
                                            <div style={{ textAlign: 'left' }}>
                                                <CustomSelect
                                                    onChange={this.handleSelect}
                                                    name='active'
                                                    options={activeOptions}
                                                    value={activeOptions.find(op => op.value === newPolicy.active)}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="col-12 col-lg-3">
                                            <div className='mt-2'>
                                                <Label><FormattedMessage id="CustomPolicy.Type"/></Label>
                                            </div>
                                            <div>
                                                <CustomSelect
                                                    onChange={this.handleSelect}
                                                    name='type'
                                                    options={typeOptions}
                                                    value={typeOptions.find(op => op.value === newPolicy.type) || ''}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ marginTop: '2rem', width: '100%' }}>
                                            {newPolicy.type === typeOptions[1].value ?
                                                <div>
                                                    <FormattedMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.FlexiblePart1"
                                                    />
                                                    <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }} className="customSelectContainer">
                                                        <Input
                                                            onChange={this.handleFormChange}
                                                            placeholder={''}
                                                            name='daysBefore'
                                                            value={newPolicy.daysBefore}
                                                            type="number"
                                                            max="365"
                                                            min="0"
                                                            required
                                                        />
                                                    </div>
                                                    <FormattedMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.FlexiblePart2"
                                                    />
                                                    <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }}>
                                                        <Input
                                                            onChange={this.handleFormChange}
                                                            placeholder={''}
                                                            name='percentageAfter'
                                                            value={newPolicy.percentageAfter}
                                                            type="number"
                                                            max="100"
                                                            min="0"
                                                            required
                                                        />
                                                    </div>
                                                    <FormattedHTMLMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.FlexiblePart3"
                                                    />
                                                </div>
                                            :
                                                newPolicy.type === typeOptions[2].value ?
                                                <div>
                                                    <FormattedMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.FlexibleDailyPart1"
                                                    />
                                                        <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }}>
                                                        <Input
                                                            onChange={this.handleFormChange}
                                                            placeholder={''}
                                                            name='percentageAfter'
                                                            value={newPolicy.percentageAfter}
                                                            type="number"
                                                            max="365"
                                                            min="0"
                                                            required
                                                        />
                                                    </div>
                                                    %
                                                </div>
                                            :
                                                newPolicy.type === typeOptions[3].value ?
                                                <div>
                                                    <FormattedMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.FlexibleCompletePart1"
                                                    />
                                                        <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }} className="customSelectContainer">
                                                        <Input
                                                            onChange={this.handleFormChange}
                                                            placeholder={''}
                                                            name='cancelUntil'
                                                            value={newPolicy.cancelUntil}
                                                            type="number"
                                                            max="365"
                                                            min="0"
                                                            required
                                                        />
                                                    </div>
                                                    <FormattedMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.FlexibleCompletePart2"
                                                    />
                                                    <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }}>
                                                        <Input
                                                            id="percentageBeforeFlexibleComplete"
                                                            onChange={this.handleFormChange}
                                                            placeholder={''}
                                                            name='percentageBefore'
                                                            value={newPolicy.percentageBefore}
                                                            type="number"
                                                            max={100 - newPolicy.percentageAfter}
                                                            min="0"
                                                            required
                                                        />
                                                    </div>
                                                    <FormattedHTMLMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.FlexibleCompletePart3"
                                                    />
                                                    <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }}>
                                                        <Input
                                                            onChange={this.handleFormChange}
                                                            placeholder={''}
                                                            name='percentageAfter'
                                                            value={newPolicy.percentageAfter}
                                                            type="number"
                                                            max={100 - newPolicy.percentageBefore}
                                                            min="0"
                                                            required
                                                        />
                                                    </div>
                                                    <FormattedHTMLMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.FlexibleCompletePart4"
                                                    />
                                                         <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }}>
                                                        <Input
                                                            onChange={this.handleFormChange}
                                                            placeholder={''}
                                                            name='daysBefore'
                                                            value={newPolicy.daysBefore}
                                                            type="number"
                                                            max="365"
                                                            min="0"
                                                            required
                                                        />
                                                    </div>
                                                    <FormattedHTMLMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.FlexibleCompletePart5"
                                                    />
                                                </div>
                                            :
                                                newPolicy.type === typeOptions[4].value ?
                                                <div>
                                                    <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }}>
                                                        <Input
                                                            onChange={this.handleFormChange}
                                                            placeholder={''}
                                                            name='percentageBefore'
                                                            value={newPolicy.percentageBefore}
                                                            type="number"
                                                            max={100 - newPolicy.percentageAfter}
                                                            min="0"
                                                            required
                                                        />
                                                    </div>
                                                    <FormattedHTMLMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.NonRefundableCompletePart1"
                                                    />
                                                        <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }}>
                                                        <Input
                                                            onChange={this.handleFormChange}
                                                            placeholder={''}
                                                            name='percentageAfter'
                                                            value={newPolicy.percentageAfter}
                                                            type="number"
                                                            max={100 - newPolicy.percentageBefore}
                                                            min="0"
                                                            required
                                                        />
                                                    </div>
                                                    <FormattedHTMLMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.NonRefundableCompletePart2"
                                                    />
                                                        <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }}>
                                                        <Input
                                                            onChange={this.handleFormChange}
                                                            placeholder={''}
                                                            name='daysBefore'
                                                            value={newPolicy.daysBefore}
                                                            type="number"
                                                            max="365"
                                                            min="0"
                                                            required
                                                        />
                                                    </div>
                                                    <FormattedHTMLMessage
                                                        style={{ whiteSpace: 'nowrap' }}
                                                        id="CustomPolicy.NonRefundableCompletePart3"
                                                    />
                                                </div>
                                            :
                                                newPolicy.type === typeOptions[0].value ?
                                                    <div>
                                                        <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }}>
                                                            <Input
                                                                onChange={this.handleFormChange}
                                                                placeholder={''}
                                                                name='noShowPenalty'
                                                                value={newPolicy.noShowPenalty}
                                                                type="number"
                                                                max="100"
                                                                min="0"
                                                                required
                                                            />
                                                        </div>
                                                        <FormattedMessage
                                                            style={{ whiteSpace: 'nowrap' }}
                                                            id="CustomPolicy.NonRefundable"
                                                        />
                                                    </div>
                                            :
                                                newPolicy.type === typeOptions[5].value ?
                                                    <div>
                                                        <FormattedMessage
                                                            style={{ whiteSpace: 'nowrap' }}
                                                            id="CustomPolicy.DepositOnlyPart1"
                                                        />
                                                        <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }}>
                                                            <Input
                                                                onChange={this.handleFormChange}
                                                                placeholder={''}
                                                                name='percentageBefore'
                                                                value={newPolicy.percentageBefore}
                                                                type="number"
                                                                max="100"
                                                                min="0"
                                                                required
                                                            />
                                                        </div>
                                                        <FormattedMessage
                                                            style={{ whiteSpace: 'nowrap' }}
                                                            id="%"
                                                        />
                                                    </div>
                                            :
                                            newPolicy.type === typeOptions[6].value ?
                                                <Authorization
                                                    perform="admin:view"
                                                    yes={() => (
                                                        <div>
                                                                <FormattedMessage
                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                    id="CustomPolicy.FlexibleDailyCompletePart1"
                                                                />

                                                                <div style={{ width: (global.isMobile ? '15%' : '8%'), display: 'inline-block' }}>
                                                                    <Input
                                                                        onChange={this.handleFormChange}
                                                                        placeholder={''}
                                                                        name='daysBefore'
                                                                        value={newPolicy.daysBefore}
                                                                        type="number"
                                                                        max="365"
                                                                        min="0"
                                                                        required
                                                                    />
                                                                </div>

                                                                <FormattedHTMLMessage
                                                                    style={{ whiteSpace: 'nowrap' }}
                                                                    id="CustomPolicy.FlexibleDailyCompletePart2"
                                                                />
                                                            </div>
                                                    )}
                                                    no={() => <div></div>}
                                                />     
                                            :
                                                ''
                                            }
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        {/*</Form>*/}
                        </form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        )
    }
}
export default injectIntl(NewCancelPolicy)