import React, { Component } from 'react';
import { Card, CardHeader, CardBody, FormGroup, Label, Col, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import Select from 'react-select'
import { getCheckInTime, getCurrency} from '../../Base/Common/ReferenceDataFunctions'


export class HotelFacilitiesAndPolicies extends Component {

    state = {
        error: null,
        block: false

    };


    render() {
        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <Card className="small shadow border-0">
                    <CardHeader className="bg-white border-bottom"><div><span className="fas fa-coffee"> </span> <FormattedMessage id="HotelFacilitiesPolicies.FacilityInfo" /> </div></CardHeader>

                <CardBody></CardBody>
            </Card>
            <br />
                <Card className="small shadow border-0">
                    <CardHeader className="bg-white border-bottom"><div><span className="fas fa-gavel"> </span> <FormattedMessage id="HotelFacilitiesPolicies.Policies" /> </div></CardHeader>
                    <CardBody>
                        <Col className="col-12">
                            <FormGroup className="form-control-sm" row>
                                <Label for="companyname" sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.CheckinBetween" /> </Label>
                                <Col sm={4}>
                                    <FormattedMessage id="HotelFacilitiesPolicies.checkinfrom">{placeholder =>
                                        <Select options={getCheckInTime()} />
                                    }</FormattedMessage>
                                </Col>
                                <Col sm={2} className="text-center">and</Col>
                                <Col sm={4}>
                                    <FormattedMessage id="HotelFacilitiesPolicies.checkinto">{placeholder =>
                                        <Select options={getCheckInTime()} />
                                    }</FormattedMessage>
                                </Col>

                            </FormGroup>
                        </Col>
                        <Col className="col-12">
                            <FormGroup className="form-control-sm" row>
                                <Label for="companyname" sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.CheckoutBetween" /> </Label>
                                <Col sm={4}>
                                    <Select options={getCheckInTime()} />
                                </Col>
                                <Col sm={2} className="text-center">and</Col>
                                <Col sm={4}>
                                    <Select options={getCheckInTime()} />
                                </Col>

                            </FormGroup>
                        </Col>
                        <Col className="col-12">
                            <FormGroup className="form-control-sm" row>
                                <Label for="companyname" sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.PaymentMethod" /> </Label>
                                <Col sm={10}>
                                    <FormattedMessage id="HotelFacilitiesPolicies.PaymentMethod">{placeholder =>


                                        <Select options={getCurrency()} isMulti/>
                                    }</FormattedMessage>
                                </Col>


                            </FormGroup>

                        </Col>
                        <Col className="col-12">
                            <FormGroup className="form-control-sm h-100" row>
                                <Label for="paymentpolicies" sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.PaymentPolicies" /> </Label>
                                <Col sm={10}>
                                    <FormattedMessage id="HotelFacilitiesPolicies.PaymentPolicies">{placeholder =>
                                        <Input type="textarea" id="paymentpolicies" rows="2" />
                                    }</FormattedMessage>
                                </Col>


                            </FormGroup>

                        </Col>

                        <Col className="col-12">
                            <FormGroup className="form-control-sm h-100" row>
                                <Label for="paymentterms" sm={2}> <FormattedMessage id="HotelFacilitiesPolicies.PaymentTermsAndConditions" /> </Label>
                                <Col sm={10}>
                                    <FormattedMessage id="HotelFacilitiesPolicies.PaymentTermsAndConditions">{placeholder =>
                                        <Input type="textarea" id="paymentterms" rows="2" />
                                    }</FormattedMessage>
                                </Col>


                            </FormGroup>

                        </Col>

                    </CardBody>
                </Card>
            </BlockUi>
            )
    }
}