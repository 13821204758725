import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col, Button, Input, CustomInput, Form } from "reactstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { BlankCard } from "../../Base/Common/CommonUIComponents";
import { getAPI, putAPI } from "../../Base/API";
import { handleNotification } from "../../Base/Notification";

export class AirbnbCheckOutTasks extends Component {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			block: true,
			configuredTasks: {},
			checkoutTasksList: [
				{ value: 'return_keys', label: <FormattedMessage id="AirbnbCheckoutTasks.ReturnKeys" />, description: <FormattedMessage id="AirbnbCheckoutTasks.ReturnKeysDesc" /> },
				{ value: 'turn_things_off', label: <FormattedMessage id="AirbnbCheckoutTasks.TurnThingsOff" />, description: <FormattedMessage id="AirbnbCheckoutTasks.TurnThingsOffDesc" /> },
				{ value: 'throw_trash', label: <FormattedMessage id="AirbnbCheckoutTasks.ThrowTrash" />, description: <FormattedMessage id="AirbnbCheckoutTasks.ThrowTrashDesc" /> },
				{ value: 'lock_up', label: <FormattedMessage id="AirbnbCheckoutTasks.LockUp" />, description: <FormattedMessage id="AirbnbCheckoutTasks.LockUpDesc" /> },
				{ value: 'gather_towels', label: <FormattedMessage id="AirbnbCheckoutTasks.GatherTowels" />, description: <FormattedMessage id="AirbnbCheckoutTasks.GatherTowelsDesc" /> },
				{ value: 'additional_requests', label: <FormattedMessage id="AirbnbCheckoutTasks.AdditionalRequests" />, description: <FormattedMessage id="AirbnbCheckoutTasks.AdditionalRequestsDesc" /> }
			]
		};
	}
	
	componentDidMount() {
		this.getListingDetails();
	}

	getListingDetails = () => {
		getAPI(result => {
			const { data, error } = result;

			if (error) {
				var errorMessage = [];
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, blockPromotions: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0) {
					handleNotification(data);
					this.setState({ block: false });
					return;
				}
				else if (data.response?.listing) {
					this.setState({
						listingDetails: data.response.listing,
						configuredTasks: data.response.listing.check_out_tasks || {},
						block: false
					});
                }

				this.setState({ block: false });
			}
			else this.setState({ block: false });
		}, `/api/Rate/Listing/v1/airbnb/${this.props.channelInstanceId}/listing/${this.props.listingId}`);
	}

	saveCheckoutTasks = (e) => {
		e.preventDefault();
		this.setState({ block: true });

		var body = { ...this.state.configuredTasks };

		putAPI(result => {
			const { data, error } = result;

			if (error) {
				var errorMessage = [];
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.error) {
					handleNotification(data);
				}
				else {
					handleNotification(data, <FormattedMessage id="AirbnbCheckoutTasks.CheckoutTasksUpdatedSucessfully" />, <FormattedMessage id="General.Success" />);
				}

				this.setState({ priceData: data.response?.pricing_setting, block: false });
			}
			else this.setState({ block: false });
		}, `/api/Rate/Listing/v1/airbnb/${this.props.channelInstanceId}/checkoutTasks/${this.props.listingId}`, body);
	}

	handleSwitch = (event) => {
		const { id, checked } = event.target;

		this.setState({
			configuredTasks: {
				...this.state.configuredTasks,
				[id]: {
					...this.state.configuredTasks[id],
					is_present: checked
				}
			}
		})
	}

	handleChange = (event, propName) => {
		const { name, value } = event.target;

		this.setState({
			configuredTasks: {
				...this.state.configuredTasks,
				[propName]: {
					...this.state.configuredTasks[propName],
					[name]: value
				}
			}
		})
	}

	render() {
		const { configuredTasks, checkoutTasksList } = this.state;

		return (
			<Modal isOpen={this.props.modal} toggle={this.props.toggleModal} className="modal-lg " style={{ minWidth: 0 }}>
				<ModalHeader toggle={this.props.toggleModal}>
					<FormattedMessage id="AirbnbCheckoutTasks.CheckoutTasks" />
				</ModalHeader>
				<ModalBody className="modal-lg p-0">
					<BlankCard block={this.state.block} error={this.state.error}>
						<Form onSubmit={this.saveCheckoutTasks}>
							<Row>
								<Col className="text-right">
									<Button className="btn btn-host btn-sm" type="submit">
										<i className="fas fa-save" />
									</Button>
								</Col>
							</Row>

							{checkoutTasksList.map((task, key) => {
								const configuredTask = configuredTasks[task.value] || {};

								return <div key={key} className="mb-2 pb-4">
									<Row className="align-items-center">
										<Col className="d-flex align-items-center">
											<CustomInput
												id={task.value}
												type="switch"
												onChange={(e) => this.handleSwitch(e)}
												checked={configuredTask.is_present}
												className="mr-2"
											/>
											<div>
												<b>{task.label}</b>
												<div className="text-muted" style={{ fontSize: '12px' }}>{task.description}</div>
											</div>
										</Col>
									</Row>
									{configuredTask.is_present ?
										<div className="mt-2">
											<Input
												required={task.value === 'additional_requests'}
												type="textarea"
												name="task_detail"
												maxLength={task.value === 'additional_requests' ? 400 : 140}
												onChange={(event) => this.handleChange(event, task.value)}
												value={configuredTask.task_detail || ''}
												placeholder={this.props.intl.formatMessage({ id: "AirbnbCheckoutTasks.Details" })}
											/>
										</div>
										: ''}
								</div>
							})}
						</Form>
					</BlankCard>
				</ModalBody>
			</Modal>
		)
	}
}

export default injectIntl(AirbnbCheckOutTasks);