import React,  { Component } from 'react';
import { CardBody, Card, CardHeader, Col, Badge, Modal, ModalHeader, ModalBody, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Toast, ToastHeader, ToastBody, UncontrolledTooltip, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import {  postAPI } from "../Base/API"
import { CustomTable } from '../Base/Common/CustomTable'
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../Common/ErrorAlert";
import { AirbnbPriceSettings } from "./Airbnb/AirbnbPriceSettings"
import { AirbnbAvailabilitySettings } from "./Airbnb/AirbnbAvailabilitySettings"
import ReactHtmlParser from 'react-html-parser';
import { handleNotification } from "../Base/Notification"
import { getIsChildRateLabel, CustomPopover } from '../Base/Common/CommonUIComponents';
import moment from "moment";
import CustomToolTip from '../../Utils/CustomToolTip';
import { CheckAuthorization } from '../Base/Authorization';
import { getMapStatus } from './ChannelFunctions';
import { copyToClipboard } from '../Base/Common/ReferenceDataFunctions';
import AirbnbCheckOutTasks from './Airbnb/AirbnbCheckOutTasks';

 class ChannelRoomRate extends Component {
     _isMounted = false;    

     constructor(props) {
         super(props);
         this.toggleModal = this.toggleModal.bind(this);
         this.toggleToast = this.toggleToast.bind(this);
         this.state = {
             error: [],
             block: true,
             blockAction: false,
             modal: false,
             roomId: null,
             type: null,
             // toast
             actionShow: false,
             action: 'Review',
             actionMessage: '',
             markMapAsNotNeeded: [],
             checkoutTasksModal: false
         };
     }

     componentWillUnmount() {
         this._isMounted = false;
     }

     componentDidMount() {
         this._isMounted = true;
     }

     toggleModal(eventType, room, evt) {
         const t = eventType;
         const r = room;
         this.setState(prevState => ({
             modal: !prevState.modal,
             roomId: r,
             type: t
         }));
     }

     toggleCheckoutTasks = (listingId) => {
         this.setState(prevState => ({
             checkoutTasksModal: !prevState.checkoutTasksModal,
             listingId
         }));
     }


    airBnbActions(cell, row) {
        return <UncontrolledButtonDropdown>
            <DropdownToggle caret size="sm" className="btn-host">
                <FormattedMessage id="ChannelRoomRate.listing" />
            </DropdownToggle>
            <DropdownMenu>
                {row.syncType === 'sync_rates_and_availability' ?
                    <DropdownItem className="btn btn-sm mr-1 " disabled={!this.props.selectedChannel.hasPassword} onClick={this.manageListing.bind(this, 'UnmanageListing', row.roomCode, "ChannelRoomRate.UnmanagedSuccess")} ><i className="fas fa-user-slash mr-2"></i><FormattedMessage id="ChannelRoomRate.Unmanaged" /></DropdownItem>
                    :
                    <DropdownItem className="btn btn-sm mr-1 " disabled={!this.props.selectedChannel.hasPassword} onClick={this.manageListing.bind(this, 'ManageListing', row.roomCode, "ChannelRoomRate.ManagedSuccess")}><i className="fas fa-user mr-2"></i><FormattedMessage id="ChannelRoomRate.Managed" /></DropdownItem>
                }
                <DropdownItem divider />
                <DropdownItem className="btn btn-sm mr-1 " disabled={!this.props.selectedChannel.hasPassword || row.syncType !== 'sync_rates_and_availability'} onClick={this.manageListing.bind(this, 'Review', row.roomCode, "ChannelRoomRate.ReviewSuccess")}><i className="fas fa-check-double mr-2"></i><FormattedMessage id="ChannelRoomRate.review" /></DropdownItem>
                {row.active === true ?
                    <DropdownItem className="btn btn-sm mr-1 " disabled={!this.props.selectedChannel.hasPassword} onClick={this.manageListing.bind(this, 'Unlist', row.roomCode, "ChannelRoomRate.UnpublishSuccess")} ><i className="fas fa-unlink mr-2"></i><FormattedMessage id="ChannelRoomRate.unpublish" /></DropdownItem>
                    :
                    <DropdownItem className="btn btn-sm mr-1 " disabled={!this.props.selectedChannel.hasPassword} onClick={this.manageListing.bind(this, 'List', row.roomCode, "ChannelRoomRate.RepublishSuccess")}><i className="fas fa-link mr-2"></i><FormattedMessage id="ChannelRoomRate.republish" /></DropdownItem>
                }
                <DropdownItem divider />
                <DropdownItem className="btn btn-sm mr-1 " disabled={!this.props.selectedChannel.hasPassword || row.syncType !== 'sync_rates_and_availability'} onClick={this.toggleModal.bind(this, 'price', row.roomCode)} ><i className="fas fa-dollar-sign mr-2"></i><FormattedMessage id="ChannelRoomRate.airbnbpricesettings" /></DropdownItem>
                <DropdownItem className="btn btn-sm mr-1 " disabled={!this.props.selectedChannel.hasPassword || row.syncType !== 'sync_rates_and_availability'} onClick={this.toggleModal.bind(this, 'availability', row.roomCode)} ><i className="fas fa-calendar-alt  mr-2"></i><FormattedMessage id="ChannelRoomRate.airbnbavailabilityrules" /></DropdownItem>
                {/*<DropdownItem className="btn btn-sm mr-1 " disabled={!this.props.selectedChannel.hasPassword || row.syncType !== 'sync_rates_and_availability'} onClick={this.toggleModal.bind(this, 'booking', row.roomCode)} ><i className="fas fas fa-sliders-h  mr-2"></i><FormattedMessage id="ChannelRoomRate.airbnbbookingsettings" /></DropdownItem>*/}
                <DropdownItem className="btn btn-sm mr-1 " disabled={!this.props.selectedChannel.hasPassword} onClick={() => this.toggleCheckoutTasks(row.roomCode)} >
                    <i className="fas fa-tasks  mr-2"></i><FormattedMessage id="ChannelRoomRate.CheckOutTasks" />
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem className="btn btn-sm mr-1 " disabled={!this.props.selectedChannel.hasPassword || row.syncType !== 'sync_rates_and_availability'} onClick={this.manageListing.bind(this, 'delete', row.roomCode, "ChannelRoomRate.DeleteSuccess")}><i className="far fa-trash-alt mr-2"></i><FormattedMessage id="ChannelRoomRate.ActionDelete" /></DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>    
    }

     manageListing(endpoint, listingId, msg) {

        this.setState({ blockAction: true });
        postAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, messageType: 'danger' });
                this.setState({ error: errorMessage, blockAction: false });
                return;
            }
            if (data) {
                if (data.text) {
                    this.setState({ blockAction: false, actionMessage: data.text, action: endpoint, actionShow : true}, this.props.getChannelData());
                } else {
                    handleNotification(
                        data,
                        <FormattedMessage id={msg} />,
                        <FormattedMessage id="ChannelRoomRate.Success" />
                    );
                    this.setState({ blockAction: false }, this.props.getChannelData());
                }                
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance/' + this.props.selectedChannel.id + '/' + endpoint + '/' + listingId);
    }
     
    toggleToast() {
         this.setState(prevState => ({
             actionShow: !prevState.actionShow
         }));
     }

     toggleSelectMode = () => {
         this.setState(prevState => ({
             selectableRows: !prevState.selectableRows,
             markMapAsNotNeeded: []
         }))
     }

     updateMappingNotNeeded = (mappingNotNeeded) => {
         this.setState({ block: true });

         postAPI(result => {
             const { data, error } = result;
             const errorMessage = [];

             if (error) {
                 errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                 this.setState({ error: errorMessage, block: false });
                 return;
             }
             if (data) {
                 if (data.errors && data.errors.length > 0) {
                     handleNotification(data);
                     this.setState({ error: errorMessage, block: false });
                 }
                 else {
                     let arrayToUpdate = 'channelDataMap';

                     if (this.props.selectedChannel.code.toUpperCase() === 'AIRBNB') {
                         arrayToUpdate = 'channelDataMapAirbnb';
                     }

                     this.props.updateChannelMap(data.response, arrayToUpdate);
                     handleNotification(data, <FormattedMessage id="ChannelList.MappingNotNeededChanged" />, <FormattedMessage id="ChannelConf.Success" />);
                 }

             }
             else {
                 this.setState({ error: errorMessage, block: false });
             }
         }, `/api/Rate/ChannelConfig/v1/ChannelInstance/ChannelData?mappingNotNeeded=${mappingNotNeeded}`, this.state.markMapAsNotNeeded);
     }

     getMapAsNotNeededBadge = () => {
         return <Badge color="secondary">
             <i className="fas fa-times text-white" style={{ padding: '0 2px' }}></i>
         </Badge>
     }

     isChildRate = (row) => {
         let label = getIsChildRateLabel(row.isChildRate);

         if (label !== this.props.intl.formatMessage({ id: "ChannelRoomRate.IsChildRate" }) && row.rateType && (row.rateType === 'AvailabilityOnly' || row.rateType === 'PriceOnly')) {
             return this.props.intl.formatMessage({ id: `ChannelRoomRate.${row.rateType}` });
         }

         return label
     }

     renderCopiableCell = (cell) => {
         return <div className="copiableCell w-100 h-100" title="copy">
             {cell}
             <i className="fas fa-copy fa-xs" />
         </div>
     }

     render() {
         const { hasHotelBedsSpecific, hasDates, mapIncommingOffice } = this.props;

         function contractNameFormatter(row) {
             return hasHotelBedsSpecific ? `${row.hotelBedsSpecific.contractName}/${row.hotelBedsSpecific.incommingOffice}/${row.hotelBedsSpecific.sequence}` : '';
         }

        const columnsAirbnb = [
            /*{
                dataField: 'id',
                text: this.props.intl.formatMessage({ id: "ChannelList.Id" }),
                sort: true
            },*/
            {
                dataField: 'roomCode',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.roomCode" }),
                sort: true,
                formatter: (cell, row) => this.renderCopiableCell(`(${cell}) ${row.roomDescription}`),
                csvFormatter: (cell, row) =>`(${cell}) ${row.roomDescription}`,
                headerStyle: {
                    backgroundColor: '#e4f6ff'
                },
                style: { backgroundColor: '#e4f6ff', cursor: 'pointer' },
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        copyToClipboard(`(${row.roomCode}) ${row.roomDescription}`)
                    },
                },
                filterValue: (cell, row) => `(${cell}) ${row.roomDescription} ${row.channelData?.map((cd, key) => cd.rateCode)}`,
            },
            {
                dataField: 'maxPersons',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.maxPersons" }),
                sort: true
            }
            /*,
            {
                dataField: 'rateCode',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.rateCode" }),
                sort: true,
                formatter: (cell, row) => <div>{row.rateCode} {row.rateDescription}</div>
            }*/,
            {
                dataField: 'syncType',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.syncType" }),
                sort: true,
                formatter: (cell, row) => this.props.intl.formatMessage({ id: "AirbnbData.synchronization_category." + cell }),
                csvFormatter: (cell) => cell ? cell : ''
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.status" }),
                sort: true,
                formatter: (cell, row) => row.active ? <Badge color="primary" pill><FormattedMessage id="ChannelRoomRate.Enabled" /></Badge> : <Badge color="secondary" pill><FormattedMessage id="ChannelRoomRate.Disabled" /></Badge>,
                csvFormatter: (cell) => cell ? this.props.intl.formatMessage({ id: "ChannelDashboard.Active" }) : this.props.intl.formatMessage({ id: "ChannelDashboard.Inactive" })
            },
            {
                dataField: 'a1',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.action" }),
                sort: true,
                formatter: (cell, row) => this.airBnbActions(cell, row),
                csvExport: false,
                hidden: !CheckAuthorization("channelInfo:airbnb:actions", null)
            }
        ];

        const columnAirbnbMapStatus = [
            {
                dataField: 'mapStatus',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.mapStatus" }),
                sort: true,
                formatter: (cell, row) => getMapStatus(this.props.intl, cell, row)?.airbnbIcon,
                csvFormatter: (cell, row) => getMapStatus(this.props.intl, cell, row)?.label
            }
         ];

         const highlightExtraMapping = this.props.hasAvailabilityGroupMapping;

         const columns1 = [
            {
                dataField: 'rateCode',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.rateCode" }),
                sort: true,
                headerStyle: { backgroundColor: '#e4f6ff' },
                style: { backgroundColor: '#f6fcff', cursor: 'pointer' },
                formatter: (cell) => this.renderCopiableCell(cell),
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        copyToClipboard(row.rateCode)
                    },
                 }
            },
            {
                dataField: 'rateDescription',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.rateDescription" }),
                sort: true,
                hidden: this.props.selectedChannel && (this.props.selectedChannel.code === 'HOTUSA' || this.props.selectedChannel.code === 'KEYTEL') ? true : false
            },
            {
                dataField: 'extraMapping',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.extraMapping" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : '',
                headerStyle: highlightExtraMapping ? { backgroundColor: '#e4f6ff' } : {},
                style: highlightExtraMapping ? { backgroundColor: '#f6fcff', cursor: 'pointer' } : {},
                formatter: (cell) => highlightExtraMapping ? this.renderCopiableCell(cell) : cell,
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        if (highlightExtraMapping) {
                            copyToClipboard(row.extraMapping)
                        }
                    },
                }
            },
            {
                dataField: 'roomCode',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.roomCode" }),
                sort: true,
                headerStyle: { backgroundColor: '#e4f6ff' },
                style: { backgroundColor: '#f6fcff', cursor: 'pointer' },
                formatter: (cell) => this.renderCopiableCell(cell),
                events: {
                    onClick: (e, column, columnIndex, row, rowIndex) => {
                        copyToClipboard(row.roomCode)
                    },
                }
            },
            {
                dataField: 'roomDescription',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.roomDescription" }),
                sort: true
            },
            {
                dataField: 'maxPersons',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.maxPersons" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : ''
            },
            {
                dataField: 'maxChildren',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.maxChildren" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : ''
            },
            {
                dataField: 'baseOccupancy',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.baseOccupancy" }),
                sort: true,
                csvFormatter: (cell) => cell ? cell : ''
            },
            {
                dataField: 'isChildRate',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.isChildRate" }),
                sort: true,
                formatter: (cell, row) => this.isChildRate(row),
                csvFormatter: (cell) => cell ? cell : ''
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "channelList.channeldatamap.status" }),
                sort: true,
                formatter: (cell, row) => row.active ? <Badge color="primary" pill><FormattedMessage id="ChannelDashboard.Active" /></Badge> : <Badge color="secondary" pill><FormattedMessage id="ChannelDashboard.Inactive" /></Badge>,
                csvFormatter: (cell) => cell ? this.props.intl.formatMessage({ id: "ChannelDashboard.Active" }) : this.props.intl.formatMessage({ id: "ChannelDashboard.Inactive" })
            },
            {
                dataField: 'mapStatus',
                text: this.props.intl.formatMessage({ id: "ChannelList.CodeType" }),
                sort: true,
                formatter: (cell, row) => getMapStatus(this.props.intl, cell, row)?.icon,
                csvFormatter: (cell, row) => getMapStatus(this.props.intl, cell, null)?.label
            }
         ];

         const contractColumns = [
             {
                 dataField: 'hotelBedsSpecific.contractName',
                 text: this.props.intl.formatMessage({ id: "ChannelList.ContractNameAndId" }),
                 sort: true,
                 formatter: (_, row) => this.renderCopiableCell(contractNameFormatter(row)),
                 csvFormatter: (_, row) => contractNameFormatter(row),
                 headerStyle: { backgroundColor: '#e4f6ff' },
                 style: { backgroundColor: '#f6fcff', cursor: 'pointer' },
                 events: {
                     onClick: (_, __, ___, row) => {
                         copyToClipboard(contractNameFormatter(row))
                     },
                 }
             },
            {
                dataField: 'hotelBedsSpecific.dateFrom',
                text: this.props.intl.formatMessage({ id: "ChannelList.DateFrom" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : '',
                csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            },
            {
                dataField: 'hotelBedsSpecific.dateTo',
                text: this.props.intl.formatMessage({ id: "ChannelList.DateTo" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : '',
                csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
            }
         ];

         const mapIncommingOfficeColumns = [
             {
                 dataField: 'hotelBedsSpecific.contractName',
                 text: this.props.intl.formatMessage({ id: "ChannelList.ContractName" }),
                 sort: true
             },
             {
                 dataField: 'hotelBedsSpecific.incommingOffice',
                 text: this.props.intl.formatMessage({ id: "ChannelList.ContractId" }),
                 sort: true,
                 headerStyle: { backgroundColor: '#e4f6ff' },
                 style: { backgroundColor: '#f6fcff', cursor: 'pointer' },
                 formatter: (cell) => this.renderCopiableCell(cell),
                 events: {
                     onClick: (e, column, columnIndex, row, rowIndex) => {
                         copyToClipboard(row.hotelBedsSpecific.incommingOffice)
                     },
                 }
             },
             {
                 dataField: 'hotelBedsSpecific.dateFrom',
                 text: this.props.intl.formatMessage({ id: "ChannelList.DateFrom" }),
                 sort: true,
                 formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : '',
                 csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
             },
             {
                 dataField: 'hotelBedsSpecific.dateTo',
                 text: this.props.intl.formatMessage({ id: "ChannelList.DateTo" }),
                 sort: true,
                 formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : '',
                 csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : ''
             }
         ];

         const columns3 = [
            {
                 dataField: 'startingDate',
                text: this.props.intl.formatMessage({ id: "ChannelList.DateFrom" }),
                 sort: true,
                 formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : '',
                 csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : '',
            },
            {
                dataField: 'endingDate',
                text: this.props.intl.formatMessage({ id: "ChannelList.DateTo" }),
                sort: true,
                formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : '',
                csvFormatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD") : '',
            }
        ];

         const columnsAux = hasHotelBedsSpecific ? mapIncommingOffice ? columns1.concat(mapIncommingOfficeColumns) : columns1.concat(contractColumns) : columns1;
         const columns = hasDates ? columnsAux.concat(columns3) : columnsAux;

         const multiSelectRow = {
             mode: 'checkbox',
             bgColor: '#f8f9fa',
             clickToSelect: false,
             selected: [...this.state.markMapAsNotNeeded],
             onSelect: (row, isSelect, rowIndex, e) => {
                 let list = [...this.state.markMapAsNotNeeded];

                 if (isSelect) {
                     list.push(row.id);
                 }
                 else {
                     list = list.filter(el => el !== row.id);
                 }

                 this.setState({ markMapAsNotNeeded: list });
             },
             onSelectAll: (isSelect, rows, e) => {
                 if (isSelect) {
                     this.setState({ markMapAsNotNeeded: rows.map(r => r.id) });
                 }
                 else {
                     this.setState({ markMapAsNotNeeded: [] });
                 }
             }
         };


         return (
            <div>
                <CustomPopover  placement="right" target="airbnbsynctype">
                            <strong><FormattedMessage id="ChannelRoomRate.MapStatus" /></strong>
                            <hr />
                            <Badge color="success"><i className="fas fa-check"></i></Badge><FormattedMessage id="ChannelRoomRate.MappedSucessfully" /> <br />
                            <Badge color="warning"><i className="fas fa-exclamation-triangle text-white"></i></Badge> <FormattedMessage id="ChannelRoomRate.MappedWithIssues" /><br />
                            <Badge color="danger"><i className="fas fa-exclamation-triangle"></i></Badge> <FormattedMessage id="ChannelRoomRate.MappingDoesNotMatchPMSCodes" /><br />
                            {this.getMapAsNotNeededBadge()} <span style={{ paddingTop: '1px' }}><FormattedMessage id="ChannelRoomRate.MappingNotNeeded" /></span>
                            <hr />
                            <Badge className="mr-1" style={{ backgroundColor: '#e4f6ff', height: '15px', width: '20px' }} > </Badge><FormattedMessage id="ChannelRoomRate.FieldsForPMSMapping" /><br />
                            {
                                this.props.selectedChannel && this.props.selectedChannel.code.toUpperCase() === 'AIRBNB' ?
                                    <div>
                                        <strong><FormattedMessage id="ChannelRoomRate.SyncType" /></strong>
                                        <hr />
                                        <strong><FormattedMessage id="ChannelRoomRate.Limited" /></strong><FormattedMessage id="ChannelRoomRate.LimitedDescription" /> <br />
                                        <strong><FormattedMessage id="ChannelRoomRate.Undecided" /> </strong><FormattedMessage id="ChannelRoomRate.UndecidedDescription" /> 
                                    </div>
                                    : ''
                            }
                </CustomPopover>
                <div className="position-relative" style={{ zIndex : 1,  left : '50%' , top : '50px'  }}>
                    <Toast isOpen={this.state.actionShow} className="position-absolute shadow">
                        <ToastHeader toggle={this.toggleToast}>
                            {this.state.action}
                        </ToastHeader>
                        <ToastBody>
                            {ReactHtmlParser(this.state.actionMessage)}                                
                        </ToastBody>
                    </Toast>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggleModal.bind(this,"","")} fade={false} className="modal-lg " >
                    <ModalHeader toggle={this.toggleModal.bind(this, "", "")}>
                        <FormattedMessage id="ChannelList.Channel" />
                    </ModalHeader>
                    <ModalBody className="modal-lg px-0 pb-0">                        
                        {
                            this.state.type === 'price' ? 
                                <AirbnbPriceSettings channelInstanceId={this.props.selectedChannel.id} roomCategoryId={this.state.roomId} />
                                : ''
                        }
                        {
                            this.state.type === 'availability' ?
                                <AirbnbAvailabilitySettings channelInstanceId={this.props.selectedChannel.id} roomCategoryId={this.state.roomId} />
                                : ''
                        }
                        {
                            /*this.state.type === 'booking' ?
                            <AirbnbBookingSettings channelInstanceId={this.props.selectedChannel.id} roomCategoryId={this.state.roomId} />
                            : ''*/
                        }
                    </ModalBody>
                 </Modal>   

                 {this.state.checkoutTasksModal ?
                     <AirbnbCheckOutTasks
                         modal={this.state.checkoutTasksModal}
                         channelInstanceId={this.props.selectedChannel.id}
                         listingId={this.state.listingId}
                         toggleModal={this.toggleCheckoutTasks}
                     />
                    : ''}

                <Card className=" mb-2 bg-white border-0 shadow mb-3">
                    <ErrorAlert error={this.state.error} />
                    <CardHeader className="bg-white border-bottom" >
                        <i className="fas fa-random mr-2"></i> 
                        <FormattedMessage id="ChannelList.Mapping" />
                        <span className="float-right" href="#" id="airbnbsynctype">
                            <i className="fas fa-question-circle mr-2" />
                        </span>
                    </CardHeader>
                    <CardBody >
                        <BlockUi tag="div" blocking={this.state.blockAction && this.props.block}>
                            {CheckAuthorization("channelInfo:mapNeeded:edit", null) ?
                                <div style={{ position: 'absolute', right: '4%', zIndex: '999' }}>
                                    <Button className="btn-sm btn-host btn mr-2" onClick={() => this.updateMappingNotNeeded(true)} disabled={!this.state.markMapAsNotNeeded || this.state.markMapAsNotNeeded?.length === 0}>
                                        <span> <FormattedMessage id="ChannelList.MapNotNeeded" /> </span>
                                    </Button>
                                    <Button className="btn-sm btn-host btn mr-2" onClick={() => this.updateMappingNotNeeded(false)} disabled={!this.state.markMapAsNotNeeded || this.state.markMapAsNotNeeded?.length === 0}>
                                        <span> <FormattedMessage id="ChannelList.MapNeeded" />  </span>
                                    </Button>
                                </div>
                            : ''}

                            {
                                this.props.selectedChannel && this.props.channelDataMap && this.props.selectedChannel.code.toUpperCase() === 'AIRBNB' && this.props.ratecodechannel ?
                                    this.props.channelDataMapAirbnb?.reduce((acc, curr) => acc + curr.channelData.length, 0) === 0 ?

                                         <CustomTable
                                             wrapperClasses=""
                                            data={this.props.channelDataMapAirbnb ? this.props.channelDataMapAirbnb : []}
                                            columns={columnsAirbnb.concat(columnAirbnbMapStatus)}
                                            shadow={false}
                                            exportCSV={true}
                                            search={true}
                                            selectRow={ multiSelectRow }
                                        />
                                        :
                                         <CustomTable
                                             wrapperClasses=""
                                            data={this.props.channelDataMapAirbnb ? this.props.channelDataMapAirbnb : []}
                                            columns={columnsAirbnb}
                                            shadow={false}
                                            exportCSV={true}
                                            search={true}
                                            expandRow={{
                                                showExpandColumn: true,
                                                renderer: row => (
                                                    <Card className="border-0 shadow mb-2">
                                                        <Row  className="text-center py-1 align-items-center">
                                                            <Col sm={1} />
                                                            <Col style={{ backgroundColor: '#e4f6ff' }}>
                                                                <b>
                                                                    <FormattedMessage id="channelList.channeldatamap.rateCode" />
                                                                </b>
                                                            </Col>
                                                            <Col>
                                                                <b>
                                                                    <FormattedMessage id="channelList.channeldatamap.rateDescription" />
                                                                </b>
                                                            </Col>
                                                            <Col>
                                                                <b>
                                                                    <FormattedMessage id="channelList.channeldatamap.mapStatus" />
                                                                </b>
                                                            </Col>
                                                        </Row>
                                                        {
                                                            row.channelData.map((cd, key) =>
                                                                <Row key={key} className="text-center py-1 align-items-center overbglight">
                                                                    <Col sm={1} />
                                                                    <Col style={{ backgroundColor: '#e4f6ff', cursor: 'pointer' }} onClick={copyToClipboard(cd.rateCode) }>
                                                                        {this.renderCopiableCell(cd.rateCode)}
                                                                    </Col>
                                                                    <Col>
                                                                        {cd.rateDescription}
                                                                    </Col>
                                                                    <Col>
                                                                        {getMapStatus(this.props.intl, row.mapStatus, null)?.airbnbIcon}
                                                                    </Col>
                                                                </Row>
                                                            )
                                                        }
                                                    </Card>
                                                )
                                            }}
                                            selectRow={ multiSelectRow }
                                        />
                                    : ''
                            }

                            {
                                this.props.selectedChannel && this.props.channelDataMap && this.props.selectedChannel.code.toUpperCase() !== 'AIRBNB' && this.props.ratecodechannel ?
                                    <CustomTable
                                        data={this.props.channelDataMap ? this.props.channelDataMap : []}
                                        columns={columns}
                                        //page={this.state.currentPage}
                                        //sizePerPage={this.state.resultsPerPage}
                                        //totalSize={this.state.ChannelData && this.state.ChannelData.length}
                                        //onTableChange={this.handleTableChange}
                                        shadow={false}
                                        exportCSV={true}
                                        search={true}
                                        selectRow={multiSelectRow}
                                    />
                                    : ''
                            }
                        </BlockUi>
                    </CardBody>
                </Card>
            </div>
        );
    }
}
export default injectIntl(ChannelRoomRate)