import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import CustomToolTip from '../../Utils/CustomToolTip'
import { FormattedMessage } from 'react-intl'

export default class BulkPricesCellExplanation extends Component {

    render() {
        return (
            <Row className="mb-1 mt-5 justify-content-center" id="BulkPricesCellExplanation">
                <Col className="col-2 p-0 ml-2 text-center">
                    <div className="calendar-border px-1 cursor-pointer overflow-hidden  col"
                        style={{ border: '1px solid rgb(201, 222, 254)', height: '11vh', width: '9vh', backgroundColor: 'rgb(250, 252, 255)' }}>
                        <div className="w-100 h-100 d-flex align-items-center">
                            <div className="w-100 h-100">
                                <div className="p-1" style={{ marginTop: "0.1rem" }}>
                                    <div>
                                        <div id="exampleAvailabilityPercentage" className="progress" style={{ maxHeight: "5px"}}>
                                            <div className="progress-bar bg-warning" role="progressbar" aria-valuenow="51" aria-valuemin="0" aria-valuemax="100" style={{width: "51%"}}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <CustomToolTip placementPrefix="white-bg bs-tooltip" placement="top" trigger={'focus'} autohide={false} defaultOpen={true} target={`exampleAvailabilityPercentage`}>
                                    <FormattedMessage id="BulkUpdate.Availability" />
                                </CustomToolTip>
                                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div id="exampleDay" style={{fontSize: "0.8em", color: "rgb(49, 49, 49)"}}>
                                        21
                                    </div>
                                    <CustomToolTip placementPrefix="white-bg bs-tooltip" placement="left" trigger={'focus'} autohide={false} defaultOpen={true} target={`exampleDay`}>
                                        <FormattedMessage id="BulkUpdate.Day" />
                                    </CustomToolTip>
                                    <div>
                                        <span id="exampleFreeRooms" className="mb-0 px-1 text-info" style={{fontSize: "0.8em"}}>
                                            <b>5</b>
                                        </span>
                                        <CustomToolTip placementPrefix="white-bg bs-tooltip" placement="right" trigger={'focus'} autohide={false} defaultOpen={true} target={`exampleFreeRooms`}>
                                            <FormattedMessage id="BulkUpdate.FreeRooms" />
                                        </CustomToolTip>
                                    </div>
                                </div>
                                <div className="text-center mt-2" style={{ width: "100%", fontSize: "14px", fontWeight: "500", padding: "0.5vh 0px", alignItems: "center", justifyContent: "center"}}>
                                    <div className="mb-1" id="exampleOriginalValue" style={{fontSize: "0.9em"}}>
                                        <span>€&nbsp;100,00</span>
                                    </div>
                                    <CustomToolTip placementPrefix="white-bg bs-tooltip" placement="right" trigger={'focus'} autohide={false} defaultOpen={true} target={`exampleOriginalValue`}>
                                        <FormattedMessage id="BulkUpdate.OriginalValue" />
                                    </CustomToolTip>
                                    <div id="exampleNewValue" style={{ fontSize: "0.8em", color: "rgb(22, 92, 125)"}}>
                                        <span>€&nbsp;475,97</span>
                                    </div>
                                    <CustomToolTip placementPrefix="white-bg bs-tooltip" placement="left" trigger={'focus'} autohide={false} defaultOpen={true} target={`exampleNewValue`}>
                                        <FormattedMessage id="BulkUpdate.NewValue" />
                                    </CustomToolTip>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}
