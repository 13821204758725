import React, { Component } from 'react';
import { Modal, ModalBody, Row, Col, Button, Form } from 'reactstrap';
import { handleNotification } from "../Base/Notification";
import { ErrorAlert } from "../Common/ErrorAlert";
import { deleteAPI } from "../Base/API";
import { FormattedMessage, injectIntl } from 'react-intl';
import BlockUi from 'react-block-ui';

class ConfirmCancellation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            block: false
        };
    }

    cancelReservation = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                else {
                    handleNotification(data, <FormattedMessage id="ConfirmCancellation.ReservationCanceled" />, <FormattedMessage id="General.Success" />);
                    this.props.toggle('cancellationModal', null);
                    this.props.updateReservationStatus('Canceled');
                }
                this.setState(({ block: false }));
            }
        }, `/api/Rate/Reservation/v1/CancelInternalReservation/${this.props.reservationId}`);
    }

    render() {
        return (
            <Modal isOpen={this.props.modal} toggle={() => this.props.toggle('cancellationModal', null)} size="modal-sm" style={{ minWidth: 0 }}>
                <ModalBody>
                    <BlockUi tag="div" blocking={this.state.block}>
                        <ErrorAlert error={this.state.error} />
                        <Form onSubmit={this.cancelReservation}>
                            <Row className="mb-4">
                                <Col> <FormattedMessage id="ConfirmCancellation.Text" /></Col>
                            </Row>
                            <Row>
                                <Col className="text-right">
                                    <Button color="secondary" onClick={() => this.props.toggle('cancellationModal', null)} className="mr-2">
                                        <FormattedMessage id="ReservationDetail.Close" />
                                    </Button>
                                    <Button color="secondary" type="submit" className="btn-host">
                                        <FormattedMessage id="ReservationDetail.Confirm" />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(ConfirmCancellation);