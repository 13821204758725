import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row, CustomInput } from 'reactstrap';
import { getAPI, postAPI } from "../../../Base/API";
import CustomSelect from '../../../Base/Common/CustomSelect';
import { handleNotification } from "../../../Base/Notification";
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { CommonHelper } from '../../../Base/Common/CommonUIComponents';
import CreatableSelect from 'react-select/creatable';
import CustomToolTip from '../../../../Utils/CustomToolTip';
import Authorization, { CheckAuthorization } from '../../../Base/Authorization';


export class RoomCategoryConfig extends Component {    

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.form = React.createRef();
        this.state = {
            block: false,
            roomCategory: {},
            complexList: [],
            selectedTags: [],
            inputValue: '',
            cityTaxList: []
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedEntity !== this.props.selectedEntity) {
            if (!this.props.creationMode) {
                this.getRoomCategory(nextProps.selectedEntity.id);
            }
        }
    }

    componentDidMount() {
        if (!this.props.creationMode) {
            this.getRoomCategory(this.props.selectedEntity.id);
            this.getCityTaxList();
        }

        this.getComplexList();
    }

    getRoomCategory = (id) => {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ roomCategory: data, selectedTags: data.tags ? data.tags.map((tag => {return({label:tag, value: tag})})) : [] });
            }
            this.setState({ block: false });
        }, `/api/hotel/RoomCategory/v1/${id}`);
    }

    getComplexList = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                if (data.length > 0) {
                    const complex = data.length === 1 && !this.state.roomCategory.complexId && this.props.creationMode ? data[0].id : this.state.roomCategory.complexId ? this.state.roomCategory.complexId : null

                    let list = [];
                    data.map((item) => list.push({ value: item.id, label: item.description }))
                    this.setState({
                        complexList: list,
                        roomCategory: {
                            ...this.state.roomCategory,
                            complexId: complex
                        }
                    });
                }
            }
            this.setState({ block: false });
        }, `/api/hotel/Complexes/v1`);
    }

    getCityTaxList = () => {
        this.setState({ blockCityTax: true });

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockCityTax: false });

                return;
            }
            if (data) {
                let cityTaxList = [];

                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState({ blockCityTax: false, error: errorMessage });
                    return;
                }

                cityTaxList = data.response?.map(el => ({ value: el.id, label: `${el.city}, ${el.country}` }));

                this.setState({ blockCityTax: false, cityTaxList });
            }
            else this.setState({ blockCityTax: false });
        }, `/api/hotel/v1/GetCityTaxes`);

    }

    setFilterState = (name, combo) => {
        const val = combo ? combo.value : null;
        this.setState(prevState => ({
            ...prevState,
            roomCategory: {
                ...prevState.roomCategory,
                [name]: val
            }
        }));
    }

    changeValueToInt = (name, evt) => {
        const value = evt.target ? evt.target.value : null;
        this.setState(prevState => ({
            ...prevState,
            roomCategory: {
                ...prevState.roomCategory,
                [name]: value ? parseInt(value) : value
            }
        }));
    }

    changeValueToFloat = (name, evt) => {
        const value = evt.target ? evt.target.value : null;
        this.setState(prevState => ({
            ...prevState,
            roomCategory: {
                ...prevState.roomCategory,
                [name]: value ? parseFloat(value) : value
            }
        }));
    }

    handleSubmit(e) {
        e.preventDefault();

        const maxOccupancy = document.getElementById('RoomCategorySetupConfigMaxOccupancy');
        const roomSize = document.getElementById('RoomCategorySetupConfigSize');
        const minimumSellingPrice = document.getElementById('RoomCategorySetupConfigMinimumSellingPrice');
        if (this.state.roomCategory.maxOccupancy < 0) {
            maxOccupancy.setCustomValidity(this.props.intl.formatMessage({ id: "RoomCategorySetupConfig.InvalidMaxOccupancy" }));
        } else if (this.state.roomCategory.roomSize < 0) {
            roomSize.setCustomValidity(this.props.intl.formatMessage({ id: "RoomCategorySetupConfig.InvalidRoomSize" }));
        } else if (this.state.roomCategory.minimumSellingPrice < 0) {
            minimumSellingPrice.setCustomValidity(this.props.intl.formatMessage({ id: "RoomCategorySetupConfig.InvalidMinimumSellingPrice" }));
        }
        else {
            maxOccupancy.setCustomValidity("");
            roomSize.setCustomValidity("");
            minimumSellingPrice.setCustomValidity("");
        }

        if (this.form.current.reportValidity()) {
            
            this.setState({
                block: true
            });

            const body = {
                "bathroomType": this.state.roomCategory.bathroomType,
                "maxOccupancy": this.state.roomCategory.maxOccupancy,
                "minimumSellingPrice": this.state.roomCategory.minimumSellingPrice,
                "roomCategoryId": this.props.selectedEntity.id,
                "roomSize": this.state.roomCategory.roomSize,
                "roomType": this.state.roomCategory.roomType,
                "tags": this.state.selectedTags.map(tag => tag.value),
                "numberOfRooms": this.state.roomCategory.numberOfRooms || null,
                "limitAvailability": this.state.roomCategory.limitAvailability || false,
                "cityTaxId": this.state.roomCategory.cityTaxId
            };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = []
                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (!this.props.fromBEConfig) {
                        this.saveCategory(false, this.props.selectedEntity.id);
                    }

                    handleNotification(data, <FormattedMessage id="RoomCategorySetupConfig.SaveSuccess" />, <FormattedMessage id="General.Success" />);
                }
                this.setState({ block: false });
            }, `/api/hotel/RoomCategory/v1/${this.props.selectedEntity.id}/RoomCategoryDetails`, JSON.stringify(body));
        }
    }

    saveCategory = (create, complexId) => {
        this.setState({ block: true });

        const body = {
            request: {
                id: complexId ? complexId : null,
                code: this.state.roomCategory.code,
                description: this.state.roomCategory.description,
                complexId: this.state.roomCategory.complexId,
                sortOrder: this.state.roomCategory.sortOrder,
                Tags: this.state.selectedTags.map(tag => tag.value)
            }
        };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = []

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                if (data.response) {
                    if (this.props.updateRoom) {
                        this.props.updateRoom(data.response[0]);
                    }

                    if (create) {
                        handleNotification(data, <FormattedMessage id="RoomCategorySetupConfig.SaveSuccess" />, <FormattedMessage id="General.Success" />);
                    }
                }
            }
            this.setState({ block: false });
        }, `/api/hotel/RoomCategory/v1/roomCategory`, JSON.stringify(body));
    }

    createCategory = (e) => {
        e.preventDefault();

        if (this.form.current.reportValidity()) {
            this.saveCategory(true);
        }
    }

    createCatDetails = (category) => {
        this.setState({ block: true })

        const body = {
            bathroomType: this.state.roomCategory.bathroomType,
            maxOccupancy: this.state.roomCategory.maxOccupancy,
            minimumSellingPrice: this.state.roomCategory.minimumSellingPrice,
            roomCategoryId: category.id,
            roomSize: this.state.roomCategory.roomSize,
            roomType: this.state.roomCategory.roomType,
            Tags: this.state.selectedTags.map(tag => tag.value),
            numberOfRooms: this.state.roomCategory.numberOfRooms || null,
            limitAvailability: this.state.roomCategory.limitAvailability || false,
            cityTaxId: this.state.roomCategory.cityTaxId
        };

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState({ block: false, error: errorMessage });
                    return;
                }
                if (data.response) {
                    handleNotification(data, <FormattedMessage id="ComplexClassification.ComplexCreated" />, <FormattedMessage id="General.Success" />);
                    this.props.toggle();
                }
            }
            this.setState({ block: false });
        }, `/api/hotel/RoomCategory/v1/${category.id}/RoomCategoryDetails`, JSON.stringify(body));
    }
    
    selectTags = (value) => {
        this.setState({
            selectedTags: value
        });
    }

    createOption = (label) => ({
      label: label,
      value: label,
    });

    handleInputChange = (inputValue) => {
        this.setState({ inputValue });
    }

    handleKeyDown = (event) => {
        let { inputValue, selectedTags } = this.state;

        if (!selectedTags) {
            selectedTags = []
        }

        if (!inputValue) return;
        if(inputValue.includes(',')) return;
        switch (event.key) {
        case 'Enter':
        case 'Tab':
            this.setState({
            inputValue: '',
            selectedTags: [...selectedTags, this.createOption(inputValue)],
            });
            event.preventDefault();
        }
    };

    handleSwitch = (evt) => {
        const { id, checked } = evt.target;

        this.setState(prevState => ({
            ...prevState,
            roomCategory: {
                ...prevState.roomCategory,
                [id]: checked
            }
        }));
    }

    render() {
        const { inputValue, selectedTags, cityTaxList } = this.state;

        const requiredFieldForBe = this.props.fromBEConfig || this.props.hasBeModule;

        const disableBasedOnHotel = !CheckAuthorization("roomCategorySetup:add");

        const roomTypes = [
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeRoom" />,
                value: 'Room',
                id: "RoomCategorySetupConfig.RoomTypeRoom"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeDouble" />,
                value: 'Double',
                id: "RoomCategorySetupConfig.RoomTypeDouble"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeTwin" />,
                value: 'Twin',
                id: "RoomCategorySetupConfig.RoomTypeTwin"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeIndividual" />,
                value: 'Individual',
                id: "RoomCategorySetupConfig.RoomTypeIndividual"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeTriple" />,
                value: 'Triple',
                id: "RoomCategorySetupConfig.RoomTypeTriple"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeFamiliar" />,
                value: 'Familiar',
                id: "RoomCategorySetupConfig.RoomTypeFamiliar"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeSuperior" />,
                value: 'Superior',
                id: "RoomCategorySetupConfig.RoomTypeSuperior"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeDeluxe" />,
                value: 'Deluxe',
                id: "RoomCategorySetupConfig.RoomTypeDeluxe"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypePremium" />,
                value: 'Premium',
                id: "RoomCategorySetupConfig.RoomTypePremium"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeStudio" />,
                value: 'Studio',
                id: "RoomCategorySetupConfig.RoomTypeStudio"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeApartmentT1" />,
                value: 'ApartmentT1',
                id: "RoomCategorySetupConfig.RoomTypeApartmentT1"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeApartmentT2" />,
                value: 'ApartmentT2',
                id: "RoomCategorySetupConfig.RoomTypeApartmentT2"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeApartmentT3" />,
                value: 'ApartmentT3',
                id: "RoomCategorySetupConfig.RoomTypeApartmentT3"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeApartmentT4" />,
                value: 'ApartmentT4',
                id: "RoomCategorySetupConfig.RoomTypeApartmentT4"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeApartmentT5" />,
                value: 'ApartmentT5',
                id: "RoomCategorySetupConfig.RoomTypeApartmentT5"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeVillage" />,
                value: 'Village',
                id: "RoomCategorySetupConfig.RoomTypeVillage"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeSuite" />,
                value: 'Suite',
                id: "RoomCategorySetupConfig.RoomTypeSuite"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeMixDormitory" />,
                value: 'MixDormitory',
                id: "RoomCategorySetupConfig.RoomTypeMixDormitory"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeMaleDormitory" />,
                value: 'MaleDormitory',
                id: "RoomCategorySetupConfig.RoomTypeMaleDormitory"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.RoomTypeFemaleDormitory" />,
                value: 'FemaleDormitory',
                id: "RoomCategorySetupConfig.RoomTypeFemaleDormitory"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.HouseT1" />,
                value: 'HouseT1',
                id: "RoomCategorySetupConfig.HouseT1"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.HouseT2" />,
                value: 'HouseT2',
                id: "RoomCategorySetupConfig.HouseT2"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.HouseT3" />,
                value: 'HouseT3',
                id: "RoomCategorySetupConfig.HouseT3"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.HouseT4" />,
                value: 'HouseT4',
                id: "RoomCategorySetupConfig.HouseT4"
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.HouseT5" />,
                value: 'HouseT5',
                id: "RoomCategorySetupConfig.HouseT5"
            }
        ];

        const bathroomTypes = [
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.BathroomPrivate" />,
                value: 'Private'
            },
            {
                label: <FormattedMessage id="RoomCategorySetupConfig.BathroomShared" />,
                value: 'Shared'
            }
        ];

        const roomTypeSelected = roomTypes.find(el => el.value === this.state.roomCategory.roomType);

        return (
            <div>
                <BlockUi tag="div" blocking={this.state.block}>
                    <ErrorAlert error={this.state.error} />
                    <Card className="small border-0 shadow mb-2">
                        <CardHeader className="bg-white border-bottom-1" >
                            <Row>
                                <Col>
                                    <i className="fas fa-sliders-h mr-1"/>
                                    <strong>
                                        <FormattedMessage id="RoomCategorySetupConfig.Config" />
                                    </strong>
                                </Col>
                                <Col className="text-right">
                                    <Button color="primary btn-host btn-sm" onClick={this.props.creationMode ? this.createCategory :  this.handleSubmit}>
                                        <i className="fas fa-save" />
                                    </Button>

                                    <CommonHelper help={<FormattedMessage id="RoomCategorySetupConfig.Help" />} id={'RoomCategoryConfigHelp'} />
                                </Col>
                            </Row>
                        </CardHeader>                        
                        <CardBody>
                            <form ref={this.form}>
                                <Row>
                                    <Col className="col-12 col-lg-6">
                                        <FormGroup row>
                                            <Label for="complex" className="col-12 col-lg-4"> <FormattedMessage id="RoomCategorySetupConfig.Complex" /> </Label>
                                            <Col className="col-12 col-lg-8">
                                                <CustomSelect
                                                    options={this.state.complexList}
                                                    placeholder={<FormattedMessage id="RoomCategorySetupConfig.Complex" />}
                                                    value={this.state.roomCategory.complexId ? this.state.complexList.find(c => c.value === this.state.roomCategory.complexId) : null}
                                                    required
                                                    onChange={this.setFilterState.bind(this, 'complexId')}
                                                    isDisabled={this.props.fromBEConfig === true ? true : false}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-12 col-lg-6">
                                        <FormGroup row>
                                            <Label for="complex" className="col-12 col-lg-4"><FormattedMessage id="hotelconf.room.RoomID"/></Label>
                                            <Col className="col-12 col-lg-8">
                                                <Input
                                                    type="text"
                                                    value={this.state.roomCategory.id ? this.state.roomCategory.id : ''}
                                                    disabled={true}
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    {global.operationMode === 'None' ?
                                        <Col className="col-12 col-lg-6">
                                            <FormGroup row className="align-items-center">
                                                <Label className="col-12 col-lg-4"> <FormattedMessage id="RoomCategorySetupConfig.sortOrder" /> </Label>
                                                <Col className="col-12 col-lg-8">
                                                    <FormattedMessage id="RoomCategorySetupConfig.sortOrder">{placeholder =>
                                                        <Input
                                                            type="number"
                                                            min="0"
                                                            defaultValue="0"
                                                            step="any"
                                                            name="sortOrder"
                                                            placeholder={placeholder}
                                                            value={this.state.roomCategory.sortOrder ? this.state.roomCategory.sortOrder : ''}
                                                            onChange={this.changeValueToFloat.bind(this, 'sortOrder')}
                                                        />
                                                    }
                                                    </FormattedMessage>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    :''}
                                </Row>
                                <Row>
                                    <Col className="col-12 col-lg-6">
                                        <FormGroup row>
                                            <Label for="code" className="col-12 col-lg-4"> <FormattedMessage id="ComplexClassification.PropertyCode" /> </Label>
                                            <Col className="col-12 col-lg-8">
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ComplexClassification.PropertyCode">{placeholder =>
                                                        <Input
                                                            type="text"
                                                            id="code"
                                                            name="code"
                                                            placeholder={placeholder}
                                                            value={this.state.roomCategory.code ? this.state.roomCategory.code : ''}
                                                            onChange={(e) => this.setState({ roomCategory: { ...this.state.roomCategory, code: e.target.value } })}
                                                            disabled={disableBasedOnHotel}
                                                            maxLength="50"
                                                            required
                                                        />
                                                    }</FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-12 col-lg-6">
                                        <FormGroup row>
                                            <Label for="description" className="col-12 col-lg-4"> <FormattedMessage id="ComplexClassification.PropertyDescription" /> </Label>
                                            <Col className="col-12 col-lg-8">
                                                <InputGroup size="sm">
                                                    <FormattedMessage id="ComplexClassification.PropertyDescription">{placeholder =>
                                                        <Input
                                                            type="text"
                                                            id="description"
                                                            name="description"
                                                            placeholder={placeholder}
                                                            value={this.state.roomCategory.description ? this.state.roomCategory.description : ''}
                                                            onChange={(e) => this.setState({ roomCategory: { ...this.state.roomCategory, description: e.target.value } })}
                                                            disabled={disableBasedOnHotel}
                                                            maxLength="1000"
                                                            required
                                                        />
                                                    }</FormattedMessage>
                                                </InputGroup>
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                {!this.props.creationMode ?
                                    <div>
                                        <Row>
                                            <Col className="col-12 col-lg-6">
                                                <FormGroup row>
                                                    <Label for="RoomType" className="col-12 col-lg-4"> <FormattedMessage id="RoomCategorySetupConfig.RoomType" /> </Label>
                                                    <Col className="col-12 col-lg-8">
                                                    <CustomSelect
                                                        name={'roomType'}
                                                        options={roomTypes}
                                                        placeholder={<FormattedMessage id="RoomCategorySetupConfig.RoomType" />}
                                                        value={roomTypeSelected || ''}
                                                        required={requiredFieldForBe}
                                                        onChange={this.setFilterState.bind(this, 'roomType')}
                                                    />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col className="col-12 col-lg-6">
                                                <FormGroup row>
                                                    <Label for="BathroomType" className="col-12 col-lg-4"> <FormattedMessage id="RoomCategorySetupConfig.BathroomType" /> </Label>
                                                    <Col className="col-12 col-lg-8">
                                                        <CustomSelect name={'bathroomType'} options={bathroomTypes} placeholder={<FormattedMessage id="RoomCategorySetupConfig.BathroomType" />} value={bathroomTypes.filter(el => el.value === this.state.roomCategory.bathroomType)} onChange={this.setFilterState.bind(this, 'bathroomType')}/>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-12 col-lg-6">
                                                <FormGroup row>
                                                    <Label for="maxOccupancy" className="col-12 col-lg-4"> <FormattedMessage id="RoomCategorySetupConfig.MaxOccupancy" /> </Label>
                                                    <Col className="col-12 col-lg-8">
                                                        <InputGroup size="sm">
                                                            <FormattedMessage id="RoomCategorySetupConfig.MaxOccupancy">{placeholder =>
                                                            <Input id="RoomCategorySetupConfigMaxOccupancy" type="number" min="1" max="20" maxLength="1" name="maxOccupancy" placeholder={placeholder} value={this.state.roomCategory.maxOccupancy ? this.state.roomCategory.maxOccupancy : ''} required={requiredFieldForBe} onChange={this.changeValueToInt.bind(this, 'maxOccupancy')} />
                                                            }</FormattedMessage>
                                                        </InputGroup>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col className="col-12 col-lg-6">
                                                <FormGroup row>
                                                    <Label for="roomSize" className="col-12 col-lg-4"> <FormattedMessage id="RoomCategorySetupConfig.Size" /> </Label>
                                                    <Col className="col-12 col-lg-8">
                                                        <FormattedMessage id="RoomCategorySetupConfig.Size">{placeholder =>
                                                            <InputGroup size="sm">
                                                                <Input id="RoomCategorySetupConfigSize" type="number" min="1" name="roomSize" placeholder={placeholder} value={this.state.roomCategory.roomSize ? this.state.roomCategory.roomSize : ''} onChange={this.changeValueToInt.bind(this, 'roomSize')} />
                                                                <InputGroupAddon addonType="prepend">m2</InputGroupAddon>
                                                            </InputGroup>
                                                        }
                                                        </FormattedMessage>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-12 col-lg-6">
                                                <FormGroup row>
                                                    <Label for="minimumSellingPrice" className="col-12 col-lg-4"> <FormattedMessage id="RoomCategorySetupConfig.MinimumSellingPrice" /> </Label>
                                                    <Col className="col-12 col-lg-8">
                                                        <FormattedMessage id="RoomCategorySetupConfig.MinimumSellingPrice">{placeholder =>
                                                            <InputGroup size="sm">
                                                                <Input id="RoomCategorySetupConfigMinimumSellingPrice"
                                                                    type="number" min="0" step="any" name="minimumSellingPrice"
                                                                    placeholder={placeholder}
                                                                    value={this.state.roomCategory.minimumSellingPrice ? this.state.roomCategory.minimumSellingPrice : ''}
                                                                    onChange={this.changeValueToFloat.bind(this, 'minimumSellingPrice')}
                                                                    style={{ paddingRight: '30px' }}
                                                                />
                                                            </InputGroup>
                                                        }
                                                        </FormattedMessage>

                                                        <i className="fas fa-info-circle text-muted pointer" style={{ position: 'absolute', top: '13px', right: '25px' }} id="minSellPriceInfo"/>

                                                        <CustomToolTip target="minSellPriceInfo">
                                                            <FormattedMessage id="RoomCategorySetupConfig.MinSellingPriceInfoText" />
                                                        </CustomToolTip>
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col className="col-12 col-lg-6">
                                                <FormGroup row>
                                                    <Label className="col-12 col-lg-4"> <FormattedMessage id="ConfigWidget.Tags"/></Label>
                                                    <Col className="col-12 col-lg-8">
                                                        <CreatableSelect
                                                            components={{DropdownIndicator: null}}
                                                            inputValue={inputValue}
                                                            isClearable
                                                            isMulti
                                                            menuIsOpen={false}
                                                            onChange={this.selectTags}
                                                            onInputChange={this.handleInputChange}
                                                            onKeyDown={this.handleKeyDown}
                                                            placeholder="Type something and press enter..."
                                                            value={selectedTags}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        {global.operationMode === 'None' ?
                                            <Row>
                                                <Col className="col-12 col-lg-6">
                                                    <FormGroup row className="align-items-center">
                                                        <Label className="col-12 col-lg-4"> <FormattedMessage id="RoomCategorySetupConfig.LimitAvailability" /> </Label>
                                                        <Col className="col-12 col-lg-8">
                                                            <CustomInput
                                                                type="switch"
                                                                id="limitAvailability"
                                                                checked={this.state.roomCategory.limitAvailability}
                                                                onChange={this.handleSwitch}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                                <Col className="col-12 col-lg-6">
                                                    <FormGroup row>
                                                        <Label className="col-12 col-lg-4"> <FormattedMessage id="RoomCategorySetupConfig.NumberOfRooms" /> </Label>
                                                        <Col className="col-12 col-lg-8">
                                                            <FormattedMessage id="RoomCategorySetupConfig.NumberOfRooms">{placeholder =>
                                                                <InputGroup size="sm">
                                                                    <Input
                                                                        type="number"
                                                                        min="0"
                                                                        step="any"
                                                                        name="numberOfRooms"
                                                                        placeholder={placeholder}
                                                                        value={this.state.roomCategory.numberOfRooms ? this.state.roomCategory.numberOfRooms : ''}
                                                                        onChange={this.changeValueToFloat.bind(this, 'numberOfRooms')}
                                                                        required={this.state.roomCategory.limitAvailability}
                                                                    />
                                                                </InputGroup>
                                                            }
                                                            </FormattedMessage>
                                                        </Col>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        : ''}
                                        <Authorization
                                            perform="roomCategoryConfig:CityTax"
                                            yes={() => (
                                                <FormGroup row>
                                                    <Label className="col-12 col-lg-2"> <FormattedMessage id="RoomCategorySetupConfig.CityTax" /> </Label>
                                                    <Col className="col-12 col-lg-4">
                                                        <CustomSelect
                                                            name={'cityTaxId'}
                                                            options={cityTaxList}
                                                            placeholder={<FormattedMessage id="RoomCategorySetupConfig.CityTax" />}
                                                            value={cityTaxList.find(ct => ct.value === this.state.roomCategory.cityTaxId) || ''}
                                                            onChange={this.setFilterState.bind(this, 'cityTaxId')}
                                                            isLoading={this.state.blockCityTax}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            )}
                                            no={() => <div></div>}
                                        />
                                    </div>
                                : ''}
                            </form>
                        </CardBody>
                    </Card>
                </BlockUi>
            </div>
        );
    }
}
export default injectIntl(RoomCategoryConfig)