import classnames from 'classnames';
import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import ReactHtmlParser from 'react-html-parser';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Badge, Button, Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { deleteAPI, getAPI, postAPI } from "../../../Base/API";
import { StyledCard } from "../../../Base/Common/CommonUIComponents";
import CustomSelect from '../../../Base/Common/CustomSelect';
import { CustomTable } from '../../../Base/Common/CustomTable';
import { handleNotification } from "../../../Base/Notification";
import CopyTemplateModal from './CopyTemplateModal';


class NotificationTemplate extends Component {

    state = {
        block: false,
        blockList: true,
        activeTab: 0,
        templateList: [],
        supportedLanguages: ['af-ZA', 'ar-SA', 'cs-CZ', 'da-DK', 'de-DE', 'el-GR', 'en-GB', 'es-ES', 'fi-FI', 'fr-FR', 'he-IL', 'hu-HU', 'it-IT', 'ja-JP', 'nb-NO', 'nl-NL', 'pl-PL', 'pt-PT', 'ru-RU', 'sv-SE', 'sv-FI', 'zh-TW'],
        modal: false,
        copyModal: false,
        templateText: {},
        eventTypeList: [],
        selectListEvents: [],
        hotelList: []
    }

    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.form = React.createRef();
    }

    componentDidMount() {
        this.getHotels();
        this.getEventTypes();
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }


    getEventTypes() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage});

                return;
            }
            if (data.response) {


                let selectListEvents = [];
                data.response.map((el, k) => {
                    el.events.map((ev, k2) => {
                        selectListEvents.push({ 'value': ev.id, "label": el.type + ' ' + ev.eventType });
                
                    })})
            

                this.setState({ eventTypeList: data.response, selectListEvents: selectListEvents, blockList : false });
            }
        }, '/api/notification/Template/v1/NotifyEvents');
        
    }

    getNotifyEvent = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data.response) {
                var templateOptions = [];

                if (data.response.length > 0) {
                    data.response.forEach(el => {
                        templateOptions.push({ value: el.id, label: el.name });
                    })
                }

                this.setState({ templateList: data.response, templateOptions, block: false });
            }
            else this.setState({ block: false });
        }, '/api/notification/Template/v1');
    }

    getHotels = () => {
        this.setState({ block: true });
        var combo = [];

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                data.data && data.data.map((item) =>
                    combo.push({ value: item.hotelId, label: item.name2 })
                )

                const defaultHotel = combo.findIndex(h => h.value === '00000000-0000-0000-0000-000000000000');

                if (defaultHotel > -1) {
                    combo[defaultHotel].label = 'Default';
                }
                else {
                    combo.push({ value: '00000000-0000-0000-0000-000000000000', label: 'Default' });
                }

            }

            this.setState({ hotelList: combo }, () => this.getNotifyEvent());
        }, '/api/hotel/v1?pageSize=2000&pageIndex=0');
    }

    setFilterState = (name, combo) => {
        this.setState({ [name]: combo });
    }

    toggleModal(modal) {
        this.setState(prevstate => ({
            [modal]: !prevstate[modal]
        }));
    }

    addNewLanguage(evt) {
        const e = document.getElementById("descriptionSelectNewCountryId");
        const strCountry = e.options[e.selectedIndex].value;
        
        this.setState(prevState => ({
            ...prevState,
            modalData: {
                ...prevState.modalData,
                templates: [...prevState.modalData.templates, { language: strCountry , html : ''}]
            }
        }));


    }

    changeState(evt) {
        let name = evt.target.name;
        let value = evt.target.value;

        this.setState(prevState => ({
            ...prevState,
            modalData: {
                ...prevState.modalData,
                [name]: value
            }
        }));
    }

    updateStateHTML(lang , evt) {

        if (evt && evt.target) {
            const { name, value } = evt.target;
            const t = this.state.modalData.templates;
            t.find(el => el.language === lang)[name] = value;

            this.setState(prevState => ({
                ...prevState,
                modalData: {
                    ...prevState.modalData,
                    templates: t
                }
            }));
        }
    }

    deleteTemplate() {
        this.setState({ block: true });
        deleteAPI(result => {
            const { data, error } = result;
            
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="NotificationTemplate.TemplateDeleted" />, <FormattedMessage id="NotificationTemplate.Success" />);
                this.setState({ block: false , modal: false }, () => this.getNotifyEvent());
            }
        }, '/api/notification/Template/v1/' + this.state.modalData.id);
    }

    deleteLanguage() {
        var lang = this.state.modalData.templates[this.state.activeTab].language;
        this.setState({ block: true });
        deleteAPI(result => {
            const { data, error } = result;
           
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="NotificationTemplate.LanguageDeleted" />, <FormattedMessage id="NotificationTemplate.Success" />);
                this.setState({ block: false });
            }
        }, '/api/notification/Template/v1/' + this.state.modalData.id + '/language/' + lang);

    }

    updateTemplate(evt) {
        const { activeTab, modalData } = this.state;

        evt.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        const req = {
            "id": modalData.id,
            "name": modalData.name,
            "hotelId": modalData.hotelId,
            "notifyGenericEventId": modalData.notifyGenericEvent.events[0].id,
            "language": modalData.templates[activeTab].language,
            "subject": modalData.templates[activeTab].subject,
            "templatehtml": modalData.templates[activeTab].html
        };

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="NotificationTemplate.TemplateSaved" />, <FormattedMessage id="NotificationTemplate.Success" />);
                modalData.id = data.response && data.response.id;
                this.setState({ block: false, modalData }, this.getNotifyEvent);
            }
        }, '/api/notification/Template/v1',req);
        
    }    

    updateSelectedEvent(val,action ) {

        if (val != undefined) {
            var el = this.state.eventTypeList.find(element => element.events.find(it => it.id === val.value) != null);

            if (el != undefined) {

                let objCopy = Object.assign({}, el);
                objCopy.events = el.events.filter(it => it.id === val.value);
                

                this.setState(prevState => ({
                    ...prevState,
                    modalData: {
                        ...prevState.modalData,
                        notifyGenericEvent: objCopy
                    }
                }));                
            }
        }            

        return;
    }

    addTemplate() {

        let req = {
            "id": null,
            "name": null,
            "notifyGenericEvent": [],
            "templates" : []
        }

        this.setState({ modal: true, modalData: req })
    }

    handleChangeSelect = (name, combo) => {
        this.setState(prevState => ({
            modalData: {
                ...prevState.modalData,
                [name]: combo ? combo.value : null
            }
        }));
    }

    render() {
        const helpContent =
            <div>
                TODO
                <hr />
            </div>


        const columns = [
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "NotificationTemplate.name" }),
                sort: true
            },
            {
                dataField: 'hotelId',
                text: this.props.intl.formatMessage({ id: "HotelLicenses.Hotel" }),
                sort: true,
                formatter: (cell) => cell ? <div>{this.state.hotelList && this.state.hotelList.some(h => h.value === cell) ? this.state.hotelList.find(h => h.value === cell).label : cell}</div> : ''
            },
            {
                dataField: 'notifyGenericEvent',
                text: this.props.intl.formatMessage({ id: "NotificationTemplate.NotificationType" }),
                sort: true,
                formatter: (cell, row) => <div>{row.notifyGenericEvent.type}</div>
            },
            {
                dataField: 'notifyGenericEvent2',
                text: this.props.intl.formatMessage({ id: "NotificationTemplate.EventType" }),
                sort: true,
                formatter: (cell, row) => <div>{row.notifyGenericEvent.events[0].eventType }</div>
                   
            },
            {
                dataField: 'active',
                text: this.props.intl.formatMessage({ id: "NotificationTemplate.Active" }),
                sort: true,
            }, 
            {
                dataField: 'language',
                text: this.props.intl.formatMessage({ id: "NotificationTemplate.Language" }),
                sort: true,
                formatter: (cell, row) => <div>{row.templates.map((el, k) => <div>{el.language}</div>)}</div>,
                headerStyle: () => {
                    return { width: "5%" };
                }
            }];

        const selectRow = {
            
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,
            onSelect: (row, isSelect, rowIndex, e) => {
                this.setState({ modal: true, modalData: row })
            }
        };

        const currentlang = [];
        this.state.modalData && this.state.modalData.templates && this.state.modalData.templates.map((cdlang, key) => {
            return currentlang.push(cdlang.language)
        });

        const filtersupportedLanguages = this.state.supportedLanguages.filter(item => currentlang.indexOf(item) < 0)

        return (

            <StyledCard block={this.state.block} error={this.state.error} icon={'far fa-envelope'} title={'NotificationTemplate.title'} help={helpContent}>
                {this.state.copyModal ?
                    <CopyTemplateModal
                        modal={this.state.copyModal}
                        toggle={() => this.toggleModal('copyModal')}
                        hotelList={this.state.hotelList}
                        templateOptions={this.state.templateOptions}
                        getNotifyEvent={this.getNotifyEvent}
                    />
                : ''}

                {this.state.modalData ?
                    <Modal isOpen={this.state.modal} toggle={() => this.toggleModal('modal')} className={classnames}>
                        <ModalHeader toggle={() => this.toggleModal('modal')}>{this.state.modalData && this.state.modalData.name}</ModalHeader>
                            <ModalBody>
                                <BlockUi tag="div" blocking={this.state.block}>
                                <form ref={this.form}>
                                    {this.state.modalData && this.state.modalData.id ?
                                        <Row>
                                            <Col className="text-right">
                                                <Button color=" btn-host btn-sm mb-1 " onClick={this.deleteTemplate.bind(this)} ><i className="fas fa-trash-alt" /></Button>
                                            </Col>
                                        </Row>
                                    : ''}
                                    <Row>
                                        <Col>
                                            <FormGroup row>
                                                <Label for="name" sm={4}><FormattedMessage id="NotificationTemplate.name" /></Label>
                                                <Col sm={8}>
                                                    <InputGroup size="sm">
                                                        <FormattedMessage id="NotificationTemplate.name">{placeholder =>
                                                            <Input name="name" value={this.state.modalData.name} required options={this.state.selectListEvents} onChange={this.changeState.bind(this)} placeholder={placeholder}  />
                                                        }</FormattedMessage>
                                                    </InputGroup>
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <BlockUi tag="div" blocking={this.state.blockList}> 
                                                <FormGroup row>
                                                    <Label for="name" sm={4}><FormattedMessage id="ChannelList.name" /></Label>
                                                    <Col sm={8}>
                                                        <CustomSelect name={'channelConfigId'} required={true} placeholder={<FormattedMessage id="NotificationTemplate.event" />} value={this.state.selectListEvents.find(el => el.value === (this.state.modalData.notifyGenericEvent.events && this.state.modalData.notifyGenericEvent.events.length > 0 ? this.state.modalData.notifyGenericEvent.events[0].id : ''))} options={this.state.selectListEvents} onChange={this.updateSelectedEvent.bind(this)} />
                                                    </Col>
                                                </FormGroup>
                                            </BlockUi>
                                        </Col>
                                        <Col>
                                            <FormGroup row>
                                                <Label sm={4}><FormattedMessage id="HotelLicenses.Hotel" /></Label>
                                                <Col sm={8}>
                                                    <CustomSelect
                                                        name={'hotelId'} required
                                                        placeholder={<FormattedMessage id="HotelLicenses.Hotel" />}
                                                        options={this.state.hotelList}
                                                        onChange={this.handleChangeSelect.bind(this, 'hotelId')}
                                                        value={this.state.modalData.hotelId ? this.state.hotelList.find(h => h.value === this.state.modalData.hotelId) : ''}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                            </Row>

                            {this.state.modalData && this.state.modalData.notifyGenericEvent && this.state.modalData.notifyGenericEvent.dataStructure ?
                                <Row>
                                    <Col className=" pb-2">
                                        
                                        
                                        <Card className="shadow border-0 ">
                                            <CardHeader className="bg-white border-bottom"> Available Template Parameters</CardHeader>
                                            <CardBody>
                                        {this.state.modalData && this.state.modalData.notifyGenericEvent && this.state.modalData.notifyGenericEvent.dataStructure && Object.keys(JSON.parse(this.state.modalData.notifyGenericEvent.dataStructure)).map(key => {

                                            var elName = key;
                                            var json = JSON.parse(this.state.modalData.notifyGenericEvent.dataStructure);
                                            var startDrag = (val, e) => {
                                                e.dataTransfer.dropEffect = 'move';
                                                e.dataTransfer.setData("text", '{{' + val + '}}');
                                            }
                                            return (
                                                <span key={key}>
                                                    {typeof (json[key]) === 'string' || typeof (json[key]) === 'number' || (typeof (json[key]) === 'object' && json[key] === null) ?
                                                        <span className="m-2" draggable="true" onDragStart={startDrag.bind(this, elName)} style={{ cursor: "move" }}>
                                                            <Badge className="p-2 m-1"> {elName} </Badge>
                                                        </span>
                                                        : Array.isArray(json[key]) ?
                                                            <span>
                                                                <br />
                                                                <span className="m-2" draggable="true" onDragStart={startDrag.bind(this, elName)} style={{ cursor: "move" }}>
                                                                    List of : <Badge color="primary" className="p-2 m-1"> {elName} </Badge>
                                                                </span>
                                                                <br />
                                                                {Object.keys(json[key][0]).map((el, k) =>
                                                                    <span key={k} className="m-2" draggable="true" onDragStart={startDrag.bind(this, el)} style={{ cursor: "move" }}>
                                                                        <Badge className="p-2 m-1"> {el} </Badge>
                                                                    </span>
                                                                )}


                                                            </span>
                                                            :
                                                            <span >
                                                                {Object.keys(json[key]).map((el, k) =>
                                                                    <span key={k} className="m-2" draggable="true" onDragStart={startDrag.bind(this, key + '.' + el)} style={{ cursor: "move" }}>
                                                                        <Badge className="p-2 m-1"> {key + '.' + el} </Badge>
                                                                    </span>
                                                                )}
                                                            </span>}
                                                </span>
                                            )
                                        })}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                : ''}

                            <Nav tabs>
                                {this.state.modalData && this.state.modalData.templates.map((el, key) =>
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: this.state.activeTab === key })}
                                            onClick={() => { this.toggle(key); }}
                                        >
                                            {el.language}
                                        </NavLink>
                                    </NavItem>
                                )}
                                <NavItem >
                                    <NavLink className="m-0 " >
                                        <select id="descriptionSelectNewCountryId" className="mr-2" required={true}>
                                            {filtersupportedLanguages.map((item, key) =>
                                                <option key={key} value={item}>{item.substring(3)}</option>
                                            )}
                                        </select>
                                        <Button onClick={() => { this.addNewLanguage() }} className=" btn-host btn-sm " style={{ 'fontSize': '0.35rem' }}  ><i className="fas fa-plus"></i> </Button>
                                    </NavLink>
                                    </NavItem>
                                    {this.state.modalData && this.state.modalData.templates.length > 0 ?
                                        <NavItem className="ml-auto" >

                                            <Button color=" btn-host btn-sm mr-1 " disabled={this.state.modalData && this.state.modalData.id === null} onClick={this.deleteLanguage.bind(this)} ><i className="fas fa-trash-alt" /></Button>
                                            <Button className="btn btn-host btn-sm"  onClick={this.updateTemplate.bind(this)}><i className="fas fa-save"></i> </Button>
                                        </NavItem>
                                        : ''}
                            </Nav>

                            {this.state.modalData && this.state.modalData.templates.map((el, key) =>
                                <TabContent key={key} activeTab={this.state.activeTab}>
                                    <TabPane tabId={key}>
                                        <Row className="h-100 mb-3">
                                            <Col className="col-2">
                                                <FormattedMessage id="NotificationTemplate.Subject" />
                                            </Col>
                                            <Col className="col-10">
                                                <Input required type="text" id="subject" name="subject" value={el.subject} onChange={this.updateStateHTML.bind(this, el.language)} />
                                            </Col>
                                        </Row>
                                        <Row className="h-100">
                                            <Col className="h-100 border-right">
                                                <Input type="textarea" required name="html" id="html" value={el.html} style={{ minHeight: '700px' }} onChange={this.updateStateHTML.bind(this, el.language)} />
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        {ReactHtmlParser(el.html)}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            )}
                        </form>
                            </BlockUi>
                        </ModalBody>
                    </Modal>
                : ''}
                <Row className="mb-3">
                    <Col className="text-right">
                        <button className="btn btn-host btn-sm mb-1 mr-2" onClick={() => this.toggleModal('copyModal')}><i className="fas fa-copy"></i></button>
                        <button className="btn btn-host btn-sm mb-1 mr-2" onClick={this.addTemplate.bind(this)}><i className="fas fa-plus"></i></button>
                        <button className="btn btn-host btn-sm mb-1" onClick={this.getNotifyEvent.bind(this)}><i className="fas fa-sync-alt"></i></button>
                    </Col>
                </Row>
                <Card className="border-0">

                    <CustomTable
                        data={this.state.templateList ? this.state.templateList : []}
                        columns={columns}
                        page={this.state.currentPage}
                        sizePerPage={this.state.resultsPerPage}
                        totalSize={this.state.templateList && this.state.templateList.length}
                        shadow={false}
                        search={{
                            searchFormatted: true
                        }}
                        remote={false}
                        selectRow={selectRow}
                    />

                </Card>
            </StyledCard>

        );
    }

}
export default injectIntl(NotificationTemplate)