import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { RoomAvailabilityBar } from './RoomAvailabilityBar';
import { RateAvailabilityBar } from './RateAvailabilityBar';
import CalendarBar from './CalendarBar';
import { FormattedMessage } from 'react-intl';
import { getAPI } from "../Base/API"
import { ErrorAlert } from "../Common/ErrorAlert";
import BlockUi from 'react-block-ui'
import moment from 'moment';
import { CheckAuthorization } from '../Base/Authorization';
import CustomSelect from '../Base/Common/CustomSelect'
import BulkUpdate from './BulkUpdate';
import { CommonHelper } from '../Base/Common/CommonUIComponents';
import MajorBulkUpdateModal from './MajorBulkUpdateModal';
import InfiniteScroll from 'react-infinite-scroller';
import NewPageComponent from '../Common/NewPageComponent';

export class Inventory extends Component {

    state = {
        error: null,
        modal: false,
        majorBulkUpdateModal: false,
        currentDay: this.props.history && this.props.history.location && this.props.history.location.state && this.props.history.location.state.startDate ? moment(this.props.history.location.state.startDate) : moment(),
        next14Days: [],
        availability: [],
        rates: [],
        ratecode: [],
        channels: [],
        inventoryData: [],
        alert: {},
        channelinstanceList: [],
        block: true,
        roomSelected: [],
        rateSelected: [],
        channelSelected: [],
        selectedRecord: {},
        selectAvailability: '',
        selectRate: '',
        selectedBlockId: '',
        rateState: true,
        days: [14, 30],
        daysIdx: 0,
        idx: 0,
        idxAdder: 20,
        toltipIsOpens: null,
        updatedInventory: false,
        isHost: false,
        hideLabels: true,
        somador: 0,
        Filters: {
            Room: [],
            Rate: [],
            Channel: [],
            RateState: null,
            Complex: null,
            Label: []
        },
        newBulkVersion: false,
        tooltip: false,
        labels: [],
        hasFlexModule: false
    };

    constructor(props) {
        super(props);
        this.changeDate = this.changeDate.bind(this);
        this.selectRow = this.selectRow.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getRoomCategoryList = this.getRoomCategoryList.bind(this);
        this.getRateList = this.getRateList.bind(this);
        this.toggleMajorBulkUpdateModal = this.toggleMajorBulkUpdateModal.bind(this);

        const days = [];
        for (var i = 0; i < 14; i++) {
            days.push(this.state.currentDay.clone().add(i, 'days'));
        }
        this.state.next14Days = days;
    }

    componentDidMount() {
        getAPI(result => {
            const { data, error } = result;

            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage }, this.changeDate('', undefined, this.channelCode));
                return;
            }
            if (data) {
                var id = '';
                if (data.length > 0) {
                    id = data[0].id;
                }
                const newBulkVersion = localStorage.getItem('newBulkVersion');
                this.setState({ channelinstanceList: data, selectedConnection: id, newBulkVersion: newBulkVersion && newBulkVersion === "true" }, this.changeDate('', undefined, this.channelCode));
            }
        }, '/api/Rate/ChannelConfig/v1/ChannelInstance');

        window.setTimeout(
            () => {
                const hasFlexModule = global.modules.some(mdl => mdl === 'FlexChildPrice');
                this.setState({
                    isHost: global.PMSType === 'Host' || global.PMSType === 'None',
                    hasFlexModule: hasFlexModule
                });
            },
            2500
        );
    }

    compare(i, el) {
        return el.value === i
    }

    SaveFilter = (name, val) => {
        const { Filters, inventoryData } = this.state;

        Filters[name] = val;

        inventoryData.roomcategory.map(cat => cat.visible = true);
        inventoryData.ratecode.map(rc => rc.visible = true);

        if (name === "RateState" && val === null) {
            this.setState({ Filters, rateState: val }, this.RunFilters());
        }
        else {
            this.setState({ Filters }, this.RunFilters());
        }
    }

    RunFilters = () => {
        const { inventoryData, Filters, rateSelected } = this.state;

        var rateState = this.state.rateState;
        var evt;
        var ratecodechannel = inventoryData.ratecodechannel.slice();

        inventoryData.roomcategory = inventoryData.roomcategory.slice();
        inventoryData.ratecode = inventoryData.ratecode.slice();

        if (Filters.Channel && Filters.Channel.length > 0) {
            evt = Filters.Channel;
            const ratecodechannel = [...inventoryData.ratecodechannel];

            inventoryData.ratecode.filter(el => {
                if ((Filters.Rate && Filters.Rate.length > 0) || (Filters.Room && Filters.Room.length > 0) || (Filters.RateState)) {
                    return (el.visible)
                }
                else {
                    return (true)
                }
            }).map(rc => {
                const rcc = ratecodechannel.filter(el => evt.filter(op => op.value === el.channelInstanceId).length > 0 && el.rateCodeMapping !== null && el.roomTypeMapping !== null);
                if (rcc && rcc.length > 0) {
                    if (rcc.filter(el => el.rateCodeId === rc.id).length > 0) {
                        rc.visible = true;
                    }
                    else {
                        rc.visible = false;
                    }
                }
                else {
                    rc.visible = false;
                }
                return rc
            });

            var channelSelected = evt;
        }
        if (Filters.Rate && Filters.Rate.length > 0) {
            evt = Filters.Rate;
            inventoryData.ratecode.filter(el => {
                if ((Filters.Channel && Filters.Channel.length > 0) || (Filters.Room && Filters.Room.length > 0) || (Filters.RateState)) {
                    return (el.visible)
                }
                else {
                    return (true)
                }
            }).map(rc => {
                if (evt.filter((op) => op.value === rc.id).length > 0) {
                    if (rateState) {
                        rc.visible = rateState === rc.active;
                    }
                    else {
                        rc.visible = true;
                    }
                }
                else {
                    rc.visible = false;
                }
                return rc
            });

            var rateSelectedEvt = evt;
        }
        if (Filters.Room && Filters.Room.length > 0) {
            evt = Filters.Room;

            for (var i = 0; i < inventoryData.roomcategory.length; i++) {
                if (evt.filter(this.compare.bind(this, inventoryData.roomcategory[i].id)).length > 0 || evt.length === 0) {
                    inventoryData.roomcategory[i] = Object.assign({}, inventoryData.roomcategory[i]);
                    inventoryData.roomcategory[i].visible = true;
                }
                else {
                    inventoryData.roomcategory[i] = Object.assign({}, inventoryData.roomcategory[i]);
                    inventoryData.roomcategory[i].visible = false;
                };
            };
            var roomSelected = evt;
        }
        if (Filters.RateState) {
            rateState = Filters.RateState?.value;
            rateSelectedEvt = rateSelected;

            inventoryData.ratecode.filter(el => {
                if ((Filters.Rate && Filters.Rate.length > 0) || (Filters.Room && Filters.Room.length > 0) || (Filters.Channel && Filters.Channel.length > 0)) {
                    return (el.visible);
                }
                else {
                    return (true);
                }
            }).map(rc => {
                if (rateSelectedEvt?.filter(r => r.value === rc.id)?.length > 0 || !rateSelectedEvt || rateSelectedEvt.length === 0) {
                    rc.visible = rateState === rc.active;
                }
                else {
                    rc.visible = false;
                }
                return rc;
            });
        }
        if (Filters.Complex) {
            const complex = Filters.Complex?.value;

            inventoryData.roomcategory.filter(el => {
                if ((Filters.Rate && Filters.Rate.length > 0) || (Filters.Room && Filters.Room.length > 0) || (Filters.Channel && Filters.Channel.length > 0) || Filters.RateState) {
                    return (el.visible);
                }
                else {
                    return (true);
                }
            }).map(c => {
                
                if (this.state.inventoryData.roomcategory?.some(r => r.complexId === complex)) {
                    c.visible = complex === c.complexId;
                }
                else {
                    c.visible = false;
                }
                return c;
            });
        }
        if (Filters.Label && Filters.Label.length > 0)  {
            const label = Filters.Label.map(({value}) => value);
            inventoryData.ratecode.filter(el => 
                !label.find(l => l === el.rateLabelId)
            ).map(rc => rc.visible = false);
        }

        if ((!Filters.Room || Filters.Room.length === 0) && (!Filters.Label || Filters.Label.length === 0) && (!Filters.Rate || Filters.Rate.length === 0) && (!Filters.Channel || Filters.Channel.length === 0) && (!Filters.RateState) && !Filters.Complex) {
            inventoryData.roomcategory.map(rc => rc.visible = true);
            inventoryData.ratecode.map(rc => rc.visible = true);
        }

        this.setState({ inventoryData, ratecodechannel: ratecodechannel, channelSelected: channelSelected, roomSelected: roomSelected, rateSelected: rateSelectedEvt, rateState: rateState });
    }

    channelCode = (aux) => {

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                var array1 = [];

                if (data.ratecodechannel) {
                    Object.keys(data.ratecodechannel).map(function (key, index) {
                        data.ratecodechannel[key].visible = false;
                        array1.push(data.ratecodechannel[key])
                        return array1;
                    });
                }
                aux.ratecodechannel = array1;


                if (!this.state.inventoryData.roomcategory) {
                    const array2 = [];
                    let rs = this.state.roomSelected;
                    Object.keys(data.roomcategory).map(function (key, index) {
                        if ((rs.filter(el => el.value === data.roomcategory[key].id).length > 0) || rs.length === 0) {
                            data.roomcategory[key].visible = true;
                        } else {
                            data.roomcategory[key].visible = false;
                        }
                        array2.push(data.roomcategory[key]);
                        return array2;
                    });
                    aux.roomcategory = array2;
                }
                else {
                    aux.roomcategory = this.state.inventoryData.roomcategory;
                }
                aux.roomcategory.sort((rc1, rc2) => rc1.sortOrder - rc2.sortOrder);

                const toltipIsOpens = [];
                if (!this.state.inventoryData.ratecode) {
                    var array3 = [];
                    let ras = this.state.rateSelected;
                    let cs = this.state.channelSelected;
                    const rateState = this.state.rateState;
                    let getRateText = rate => rate.code;
                    if (global.PMSType === 'BookingSpaceBeds') {
                        getRateText = rate => `${rate.description} (${rate.code})`;
                    }

                    Object.keys(data.ratecode).map(function (key, index) {
                        if ((ras.filter(el => el.value === data.ratecode[key].id).length > 0) || ras.length === 0) {
                            if (cs.filter(el => el.value === data.ratecode[key].id).length > 0 || cs.length === 0) {
                                data.ratecode[key].visible = rateState === data.ratecode[key].active || rateState === null;
                            }
                        } else {
                            data.ratecode[key].visible = false;
                        }
                        data.ratecode[key].rateText = getRateText(data.ratecode[key]);
                        array3.push(data.ratecode[key])
                        return array3;
                    });
                    aux.ratecode = array3.sort((rc1, rc2) => rc1.sortOrder - rc2.sortOrder);

                    array3.forEach(_ => {
                        const isOpens = [];
                        this.state.next14Days.forEach(_ => {
                            isOpens.push(false);
                        });
                        toltipIsOpens.push(isOpens);
                    });
                }
                else {
                    aux.ratecode = this.state.inventoryData.ratecode.sort((rc1, rc2) => rc1.sortOrder - rc2.sortOrder);
                }
                aux.roomcategory.sort((rc1, rc2) => rc1.sortOrder - rc2.sortOrder);


                var array5 = [];
                Object.keys(data.pricelist).map(function (key, index) {
                    array5.push(data.pricelist[key])
                    return array5;
                });
                aux.pricelist = array5;

                var array6 = [];
                Object.keys(data.channelInstance).map(function (key, index) {
                    array6.push(data.channelInstance[key])
                    return array6;
                });
                aux.channelInstance = array6;

                var array7 = [];
                Object.keys(data.rateCodePax).map(function (key, index) {
                    array7.push(data.rateCodePax[key])
                    return array7;
                });
                aux.rateCodePax = array7;

                var complex = [];
                data.complex && Object.keys(data.complex).map(function (key, index) {
                    complex.push({ value: data.complex[key].id, label: data.complex[key].description });
                    return complex;
                });
                aux.complex = complex;

                this.setState({ toltipIsOpens, inventoryData: aux }, () => {
                    if (this.props.history?.location?.state?.rateId) {
                        this.SaveFilter("Rate", this.state.Filters.Rate.concat([this.props.history?.location?.state?.rateId]));
                    } else {
                        this.getLabels()
                    }
                });
            }
        }, '/api/Price/Price/Price/v1/channelCode/static');
    }

    changeDate(evt, date, cb) {

        var datai = (date !== undefined ? date : this.state.currentDay.clone());
        
        if ((datai && datai?.isBefore(moment(), 'day')) || !datai) {
            this.setState({ block: false });
            return;
        }

        const days = [];

        const numberOfDays = this.state.days[this.state.daysIdx];
        var dataf = datai.clone().add(numberOfDays, 'days');
        for (var i = 0; i < numberOfDays; i++) {
            days.push(datai.clone().add(i, 'days'));
        }

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false, currentDay: datai, next14Days: days });
                return;
            }
            if (data) {
                var aux = data;
                var array = [];
                Object.keys(data.pricetemplate).map(function (key, index) {
                    array.push(data.pricetemplate[key])
                    return array;
                });

                array.sort(function (x, y) {
                    return x.hotelDate > y.hotelDate ? 1 : -1;
                });
                aux.pricetemplate = array;

                var arrayPMS = [];
                Object.keys(data.externalPmsAvailabilityList).map(function (key, index) {
                    arrayPMS.push(data.externalPmsAvailabilityList[key])
                    return arrayPMS;
                });

                arrayPMS.sort(function (x, y) {
                    return x.hotelDate > y.hotelDate ? 1 : -1;
                });
                aux.externalPmsAvailabilityList = arrayPMS;

                var array4 = [];
                Object.keys(data.externalPmsRestriction).map(function (key, index) {
                    data.externalPmsRestriction[key].visible = true;
                    array4.push(data.externalPmsRestriction[key])
                    return array4;
                });
                aux.externalPmsRestriction = array4;

                if (cb) {
                    this.setState({ block: false, currentDay: datai, next14Days: days }, cb(aux));
                }
                else {
                    const { inventoryData } = this.state;


                    aux.rateCodePax = inventoryData.rateCodePax;
                    aux.channelInstance = inventoryData.channelInstance;
                    aux.roomcategory = inventoryData.roomcategory;
                    aux.pricelist = inventoryData.pricelist;
                    aux.ratecode = inventoryData.ratecode;
                    aux.ratecodechannel = inventoryData.ratecodechannel;
                    aux.complex = inventoryData.complex;

                    this.setState({ block: false, currentDay: datai, next14Days: days, inventoryData: aux });
                }
            }

        }, '/api/Price/Price/Price/v1/channelCode/prices?startDate=' + datai.format("YYYY-MM-DD") + '&endDate=' + dataf.format("YYYY-MM-DD"));
    }

    getRoomCategoryList() {
        var arr = [];
        this.state.inventoryData.roomcategory && Object.keys(this.state.inventoryData.roomcategory).map(key =>
            arr.push({ 'value': this.state.inventoryData.roomcategory[key].id, 'label': this.state.inventoryData.roomcategory[key].description })
        )
        return arr;
    }

    getRateList() {
        var arr = [];
        this.state.inventoryData.ratecode && Object.keys(this.state.inventoryData.ratecode).map(key =>
            arr.push({
                'value': this.state.inventoryData.ratecode[key].id, 'label': this.state.inventoryData.ratecode[key].rateText,
                'roomCategoryId': this.state.inventoryData.ratecode[key].roomCategoryId, 'labelId': this.state.inventoryData.ratecode[key].rateLabelId
            })
        )
        return arr.sort((rate1, rate2) => {
            const label1 = rate1.label.toUpperCase();
            const label2 = rate2.label.toUpperCase();
            if (label1 < label2) {
                return -1;
            }
            if (label1 > label2) {
                return 1;
            }
            return 0;
        });
    }

    getRateCodeChannelList() {
        const arr = [];
        this.state.inventoryData.ratecodechannel && Object.keys(this.state.inventoryData.ratecodechannel).map(key =>
            arr.push({ 'id': this.state.inventoryData.ratecodechannel[key].id, 'channelInstanceId': this.state.inventoryData.ratecodechannel[key].channelInstanceId, 'rateCodeId': this.state.inventoryData.ratecodechannel[key].rateCodeId })
        );
        return arr;
    }

    getChannelInstanceComboValues() {
        var array = [];
        if(this.state.channelinstanceList && this.state.channelinstanceList.length > 0){
            this.state.channelinstanceList.map((item, key) => {
                if (item.active) {
                    array.push({ 'label': item.name + ' (' + item.propertyCode + ')', 'value': item.id })
                }
                return null;
            });
        }

        return array
    }

    getStatesComboValues() {
        const stateOptions = [
            {
                value: true,
                label: <FormattedMessage id="Inventory.Active" />
            },
            {
                value: false,
                label: <FormattedMessage id="Inventory.Inactive" />
            }
        ];
        return stateOptions;
    }

    setComboStatus = (evt, inputValue, actionMeta) => {
        var targetValue = '';
        if (Array.isArray(inputValue)) {
            targetValue = inputValue.map(el => el.value).join(',');
        } else {
            targetValue = inputValue ? inputValue.value : '';
        }

        this.setState({ [evt]: targetValue })
    }

    selectRow(evt, rateCodeId, roomCategoryId, blockId, dependencyMasterId, datai, datef) {
        const { currentDay, daysIdx, days, newBulkVersion } = this.state;
        // TODO add codition to view operationmode
        if (CheckAuthorization("Inventory:ARI:Update", null)) {
            if(newBulkVersion) this.props.history.push(`/MicroInventoryUpdate?rateId=${rateCodeId}&currentDay=${moment(currentDay).format('MM-DD-YYYY')}&inventorySeeOneMonth=${daysIdx === 1}`);
            else {
                let di = datai ? datai : this.state.currentDay.format('YYYY-MM-DD');
                let df = datef ? datai : this.state.currentDay.clone().add(days[daysIdx], 'days').format('YYYY-MM-DD');
                this.setState({ block: true, modal: true, selectedRecord: { selectAvailability: roomCategoryId, selectRate: rateCodeId, selectedBlockId: blockId, dependencyMasterId: dependencyMasterId, startDate: di, endDate: df } })
            }
        }
    }

    handleChangeNewBulkVersion = () => {
        this.setState({ newBulkVersion: true }, () => {
            localStorage.setItem('newBulkVersion', true);
            this.selectRow(null, this.state.selectedRecord?.selectRate);
        });
    }

    toggleModal() {
        const { modal, toltipIsOpens, updatedInventory } = this.state;
        if (!modal) {
            this.setState({ toltipIsOpens: toltipIsOpens.map(el => el.map(_ => false)) }, () => {
                this.setState(prevState => ({ modal: !prevState.modal, block: true }));
            });
        }
        else {
            if (updatedInventory) {
                this.setState(prevState => ({ modal: !prevState.modal, block: true }), () => this.changeDate(''));
            }
            else {
                this.setState(prevState => ({ modal: !prevState.modal, block: false }));
            }
        }
    }

    toggleMajorBulkUpdateModal() {
        const { majorBulkUpdateModal, toltipIsOpens } = this.state;
        if (!majorBulkUpdateModal) {
            this.setState({ toltipIsOpens: toltipIsOpens && toltipIsOpens.map(el => el.map(_ => false)) }, () => {
                this.setState(prevState => ({
                    majorBulkUpdateModal: !prevState.majorBulkUpdateModal
                }));
            });
        }
        else {
            this.setState(prevState => ({
                majorBulkUpdateModal: !prevState.majorBulkUpdateModal, block: true
            }), () => this.changeDate(''));
        }
    }

    handleSeeOneMonth = (evt) => {
        if (evt) {
            this.setState(prevState => ({ daysIdx: prevState.daysIdx === 0 ? 1 : 0, block: true, idx: this.state.idxAdder }), () => this.changeDate(''));
        }
    }

    onScroll = (event) => {
        if (event && event.target) {
            const { scrollTop, scrollLeft } = event.target;

            const iventoryDaysScroll = document.getElementById("IventoryDaysScroll");
            const iventoryDataScroll = document.getElementById("IventoryDataScroll");

            if (iventoryDaysScroll && iventoryDataScroll) {
                iventoryDaysScroll.scrollTo(scrollLeft, scrollTop);
                iventoryDataScroll.scrollTo(scrollLeft, scrollTop);
            }
        }
    }

    updatedInventory = () => {
        this.setState({ updatedInventory: true });
    }

    canLoad = () => {
        const { idx, inventoryData, idxAdder } = this.state;
        let toShowRates = idxAdder;
        let catToLoad = idx;

        inventoryData.roomcategory.filter(cat => cat.visible).slice(idx + 1, inventoryData.roomcategory.filter(cat => cat.visible).length).forEach((cat, k) => {
            const rates = inventoryData.ratecode.filter(x => x.roomCategoryId === cat.id && x.visible).length;

            if(rates <= toShowRates){
                toShowRates = toShowRates - rates;
                catToLoad = k;
            }

            else if(toShowRates > -1){
                toShowRates = -1;
                catToLoad = k;
            }
        });

        this.setState({ idx: catToLoad + idx + 1 });
    }
    
    toogleToolTip = () => {
        this.setState(prev => ({ tooltip: !prev.tooltip }))
    }

    getLabels() {
        getAPI(result => {
            const { data, error } = result;

            const { inventoryData } = this.state;
            var apiLabels = [];
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data && data.response) {
                const { labels } = this.state;
                data.response.forEach((item) => {
                    var hasAssociatedRate = inventoryData.ratecode.find(rate => rate.rateLabelId === item.id);
                    if (hasAssociatedRate) {
                        const label = { 'value': item.id, 'label': item.label };
                        apiLabels.push(label);
                    }
                });
                this.setState({ labels: labels.concat(apiLabels), hideLabels: !(apiLabels.length > 0) });
            }
        }, '/api/Rate/Rate/v1/RateLabel');
    }

    getChildrenAmountNormal = (children, childrenPrices, componentChildrenGroup2) => {
        return children * (childrenPrices[1] + componentChildrenGroup2);
    }

    getChildrenAmountFlex = (children, childrenPrices, componentChildrenGroup2) => {
        let childrenAmount = 0;

        if (children > 0) {
            if (children > 1) {
                //If D3 is 0 use D2 on every child
                childrenAmount = childrenPrices[2] == 0 ? (childrenPrices[1] * children) : (childrenPrices[1] + (childrenPrices[2] * (children - 1)));
            }
            else {
                childrenAmount = childrenPrices[1];
            }
        }

        return childrenAmount + (children * componentChildrenGroup2);
    }

    render() {
        const { block, error, majorBulkUpdateModal, currentDay, idx, inventoryData, isHost, channelinstanceList, labels, hideLabels,  hasFlexModule } = this.state;
        
        const help = <div>
            <strong>
                <FormattedMessage id="Inventory.RateInformation" />
            </strong>
            <hr />
            <i className="fas fa-random mr-2" /><FormattedMessage id="Inventory.ChannelMapping" /><br />
            <i className="fas fa-ban mr-2" /><FormattedMessage id="Inventory.RestrictionsDetails" /><br />
            <i className="fas fa-user mr-2" /><FormattedMessage id="Inventory.MaxPersonsAllowedInRate" /><br />
            <i className="fas fa-times-circle text-warning mr-2" /><FormattedMessage id="Inventory.DayHasRestrictions" /><br />
            <i className="fas fa-times-circle text-danger mr-2" /><FormattedMessage id="Inventory.StopSales" /><br />
            <i className="fas fa-lock mr-2" /><FormattedMessage id="Inventory.RateIsDerived" /><br />
            <i className="far fa-square mr-2" style={{ backgroundColor: '#28a74526' }} /><FormattedMessage id="Inventory.ExistsRestrictionsForChannels" /><br />
            <i className="fas fa-caret-left mr-2 ml-1 pr-1" /><FormattedMessage id="Inventory.DisplayedPrice" /><br />
            <i className="fas fa-hashtag mr-1" /> <FormattedMessage id="Inventory.ReservedAndTotalRooms" /><br />
            <br /><b><FormattedMessage id="Inventory.Restrictions" /></b><br />
            <div className='d-flex align-items-center'>
                <div style={{ backgroundColor: '#007bff', borderColor: '#007bff', borderWidth: '2px', borderRadius: '5px', height: '16px', width: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='mr-1'>
                    <i style={{ color: 'white', fontSize: '0.6em' }} className="fas fa-check"/>
                </div>
                <span className='mr-1'
                    style={{ border: '1px solid #dee2e6', padding: '2px', display: 'flex', alignItems: 'center', width: '16px', justifyContent: 'center', height: '16px', fontSize: '0.8em' }}>
                        3
                </span>
                <FormattedMessage id="Inventory.CurrentRestriction" />
                <br/>
            </div>
            <div className='d-flex align-items-center'>
                <div style={{ backgroundColor: '#007bff96', borderColor: '#007bff2e', borderWidth: '2px', borderRadius: '5px', height: '16px', width: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} className='mr-1'>
                    <i style={{ color: 'white', fontSize: '0.6em' }} className="fas fa-check"/>
                </div>
                <span className='mr-1'
                    style={{ color: '#8f8f8f', border: '1px solid #dee2e6', padding: '2px', display: 'flex', alignItems: 'center', width: '16px', justifyContent: 'center', height: '16px', fontSize: '0.8em' }}>
                        3
                </span>
                <FormattedMessage id="Inventory.InheritedRestriction" />
                <br/>
            </div>
            <br /><b><FormattedMessage id="Inventory.AvailabilityRate" /></b><br />
            <i className="fas fa-square mr-2 text-success" /><FormattedMessage id="Inventory.LowOccupancy" /><br />
            <i className="fas fa-square mr-2 text-warning" /><FormattedMessage id="Inventory.MediumOccupancy" /><br />
            <i className="fas fa-square mr-2 text-danger" /><FormattedMessage id="Inventory.HighOccupancy" /><br />
            <i className="fas fa-square mr-2" style={{ color: 'transparent', background: 'linear-gradient(35deg, rgba(217,83,79,1) 0%, rgba(0,0,0,1) 5%, rgba(217,83,79,1) 10%, rgba(0,0,0,1) 15%, rgba(217,83,79,1) 20%, rgba(0,0,0,1) 25%, rgba(217,83,79,1) 30%, rgba(0,0,0,1) 35%, rgba(217,83,79,1) 40%, rgba(0,0,0,1) 45%, rgba(217,83,79,1) 50%, rgba(0,0,0,1) 55%, rgba(217,83,79,1) 60%, rgba(0,0,0,1) 65%, rgba(217,83,79,1) 70%, rgba(0,0,0,1) 75%, rgba(217,83,79,1) 80%, rgba(0,0,0,1) 85%, rgba(217,83,79,1) 90%, rgba(0,0,0,1) 95%, rgba(217,83,79,1) 100%)' }} /><FormattedMessage id="Inventory.Overbooking"/><br/>
        </div>

        return (
            <div>
                <BlockUi tag="div" blocking={block}>
                    <ErrorAlert error={error} />
                    <Card className="border-0" >
                        <Modal isOpen={this.state.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >
                            <ModalHeader toggle={this.toggleModal}>
                                <FormattedMessage id="Inventory.BulkUpdate" />
                            </ModalHeader>
                            <ModalBody className="modal-lg px-0 pb-0">
                                <BulkUpdate
                                    selectAvailability={this.state.selectAvailability}
                                    selectRate={this.state.selectRate}
                                    selectedRecord={this.state.selectedRecord}
                                    updatedInventory={this.updatedInventory}
                                    selectedBlockId={this.state.selectedBlockId}
                                    inventoryData={inventoryData}
                                    getRoomCategoryList={this.getRoomCategoryList}
                                    getRateList={this.getRateList}
                                    channelinstanceList={channelinstanceList}
                                    hasFlexModule={hasFlexModule}
                                />
                                <NewPageComponent
                                    handleChangeNewBulkVersion={this.handleChangeNewBulkVersion}
                                />
                            </ModalBody>
                        </Modal>
                        {majorBulkUpdateModal ?
                            <MajorBulkUpdateModal
                                isOpen={majorBulkUpdateModal}
                                currentDay={currentDay}
                                toggle={this.toggleMajorBulkUpdateModal}
                                getRoomCategoryList={this.getRoomCategoryList}
                                getRateList={this.getRateList}
                                channelinstanceList={channelinstanceList}
                                rateCodeChannels={this.getRateCodeChannelList.bind(this)}
                                labels={labels}
                            />
                        :
                            <div/>
                        }
                        <CalendarBar currentDay={this.state.currentDay} next14Days={this.state.next14Days} action={(e, date) => this.setState({ block: true, idx: 1 }, _ => this.changeDate(e, date)) } inventorySeeOneMonth={this.state.daysIdx === 1} days={this.state.days[this.state.daysIdx]} handleSeeOneMonth={this.handleSeeOneMonth} handleScroll={this.onScroll} />
                        <Card className="border-0 py-1 bg-white my-1" >
                            <CardHeader className="card-header-gridview bg-white border-0">
                                <Row className="align-items-center">
                                    {this.state.inventoryData.complex?.length > 1 ?
                                        <Col className="col-2 pl-0 pr-2">
                                            <CustomSelect
                                                icon={'fas fa-hotel'}
                                                isClearable isSearchable
                                                placeholder={<FormattedMessage id="Inventory.SelectComplex" />}
                                                options={this.state.inventoryData.complex}
                                                onChange={evt => this.SaveFilter("Complex", evt)}
                                            />
                                        </Col>
                                    : ''}
                                    <Col className="pl-0 pr-2" style={{ zIndex: '2' }}>
                                        <CustomSelect icon={'fas fa-bed'} isClearable isMulti isSearchable placeholder={<FormattedMessage id="Inventory.ChooseRoom" />} options={this.getRoomCategoryList()} onChange={evt => this.SaveFilter("Room", evt)} />
                                    </Col>
                                    <Col className="pl-0 pr-2">
                                        <CustomSelect icon={'fas fa-gift'} isClearable isMulti isSearchable placeholder={<FormattedMessage id="Inventory.ChooseRate" />} options={this.getRateList()} onChange={evt => this.SaveFilter("Rate", evt)} value={this.state.Filters.Rate} />
                                    </Col>
                                    <Col className="pl-0 pr-2">
                                        <CustomSelect icon={'fa fa-plug fa-fw'} isClearable isMulti isSearchable placeholder={<FormattedMessage id="Inventory.SelectChannel" />} options={this.getChannelInstanceComboValues()} onChange={evt => this.SaveFilter("Channel", evt)} />
                                    </Col>
                                    {global.operationMode !== 'PmsFull' && !hideLabels ?
                                        <Col className="pl-0 pr-2">
                                            <CustomSelect
                                                icon={'fas fa-tag'}
                                                isClearable
                                                isSearchable
                                                placeholder={<FormattedMessage id="Inventory.SelectRateLabel"/>}
                                                options={labels}
                                                isMulti={true}
                                                onChange={evt => this.SaveFilter("Label", evt)}
                                            />
                                        </Col>
                                    :''}
                                    <Col className="pl-0 pr-2">
                                        <CustomSelect icon={'fa fa-power-off'} isClearable isSearchable placeholder={<FormattedMessage id="Inventory.SelectState" />} options={this.getStatesComboValues()} value={this.getStatesComboValues().find(val => val.value === this.state.rateState)} onChange={evt => this.SaveFilter("RateState", evt)} />
                                    </Col>
                                    <Col className="col-1 p-0 pr-2">
                                        <CommonHelper help={help} id={'InventoryHelp'}/>
                                        <button className="btn btn-host btn-sm text-white mr-2 shadow float-right " onClick={(evt) => { this.changeDate(evt); this.setState({ block: true }) }}  >  <i className="fas fa-sync-alt"></i></button>

                                        { global.operationMode !== 'PmsFull' ?
                                            <button className="btn btn-host btn-sm text-white mr-2 shadow float-right" onClick={this.toggleMajorBulkUpdateModal} disabled={!CheckAuthorization("inventory:majorBulkUpdate", null) }>
                                                <i className="fas fa-edit" />
                                            </button>
                                        :<span />  }
                                    </Col>
                                </Row>
                            </CardHeader>
                        </Card>
                        <div id="IventoryDataScroll" onScroll={this.onScroll} style={{ overflowX: 'auto', overflow: 'hidden' }}>
                            {inventoryData.roomcategory &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.canLoad}
                                    hasMore={idx < (inventoryData.roomcategory.filter(cat => cat.visible).length - 1)}
                                    loader={<div style={{ width: '100%' }} key="loader"><BlockUi tag="div" blocking={true} /></div>}
                                >
                                    {Object.keys(inventoryData.roomcategory.filter(cat => cat.visible)).map(key => {
                                        return (
                                            parseInt(key) <= this.state.idx &&
                                            [... new Set(inventoryData.ratecode.filter(x => x.roomCategoryId === inventoryData.roomcategory.filter(cat => cat.visible)[key].id && x.roomCategoryId === inventoryData.roomcategory.filter(cat => cat.visible)[key].id && x.visible)
                                                .map(x => x.blockId))].map((el, k) =>
                                                    <div className="pb-2 bg-light" key={`${k}Container`} >
                                                        <Card className="border-0 my-1">
                                                            <CardHeader className="card-header-gridview border-0 pb-0 ">
                                                                <RoomAvailabilityBar
                                                                    roomCategory={inventoryData.roomcategory.filter(cat => cat.visible)[key]}
                                                                    hotelAvailability={inventoryData.externalPmsAvailabilityList.filter((rest) => inventoryData.roomcategory.filter(cat => cat.visible)[key].id === rest.roomCategoryId)}
                                                                    next14Days={this.state.next14Days}
                                                                    blockId={el}
                                                                    isHost={isHost}
                                                                />
                                                            </CardHeader>
                                                            <CardBody className="card-header-gridview border-0 pt-0" >
                                                                {Object.keys(inventoryData.ratecode).filter(k2 =>
                                                                    inventoryData.ratecode[k2].roomCategoryId === inventoryData.roomcategory.filter(cat => cat.visible)[key].id
                                                                    && inventoryData.ratecode[k2].blockId === el && inventoryData.ratecode[k2].visible).map(key2 => {
                                                                        const paxList = inventoryData.rateCodePax.filter((px) => inventoryData.ratecode[key2].id === px.rateCodeId).sort(function (a, b) { return a.adults === b.adults ? (a.adults + a.children) - (b.adults + b.children) : a.adults - b.adults });
                                                                        const maxpaxListpos = paxList.reduce((max, el) => el.adults > max ? el.adults : max, 0);
                                                                        const positionToDisplay = inventoryData.ratecode[key2].displayPricesFrom ? inventoryData.ratecode[key2].displayPricesFrom : maxpaxListpos;

                                                                        return (
                                                                            <RateAvailabilityBar
                                                                                next14Days={this.state.next14Days}
                                                                                rateCode={inventoryData.ratecode[key2]}
                                                                                inventoryData={inventoryData}
                                                                                category={inventoryData.roomcategory.filter(cat => cat.visible)[key]}
                                                                                key={key2}
                                                                                selectRow={this.selectRow}
                                                                                selectedRecord={this.state.selectedRecord}
                                                                                blockId={el}
                                                                                index={key2}
                                                                                paxList={paxList}
                                                                                maxpaxListpos={maxpaxListpos}
                                                                                positionToDisplay={positionToDisplay}
                                                                                getChildrenAmount={hasFlexModule ? this.getChildrenAmountFlex : this.getChildrenAmountNormal}
                                                                            />    
                                                                        );
                                                                    }
                                                                    )}
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                        )
                                    }
                                    )}
                                </InfiniteScroll>
                            }
                        </div>
                    </Card>
                </BlockUi>
            </div>
        );
    }
};