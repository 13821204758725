import React, { Component } from 'react';
import { Row, Col, Input, Button, Label, FormGroup, Card, Form } from 'reactstrap';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ConfirmActionModal, KebabMenu, StyledCard } from '../../Base/Common/CommonUIComponents';
import { getAPI, postAPI } from '../../Base/API';
import CustomSelect from '../../Base/Common/CustomSelect';
import { getExtractorLinesField, getExtractorLinesType, getExtractorType } from '../../Base/Common/ReferenceDataFunctions';
import MailExtractorLines from './MailExtractorLines';
import { handleNotification } from '../../Base/Notification';

class MailExtractorDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            extractor: { mailExtractionTemplateLines: [] },
            extractorList: [],
            extractorTypes: getExtractorType(this.props.intl),
            extractorLinesFields: getExtractorLinesField(),
            extractorLinesTypes: getExtractorLinesType(this.props.intl)
        };
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.getExtractorDetails(this.props.match.params.id);
        }
    }

    getExtractorDetails = (id) => {
        this.setState({ block: true });
        
        getAPI(result => {
            const { data, error } = result;
            var errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response) {
                    data.response[0].mailExtractionTemplateLines.sort((a, b) => this.state.extractorLinesFields.findIndex(elf => elf.value === a.field) - this.state.extractorLinesFields.findIndex(elf => elf.value === b.field));

                    this.setState({ extractor: data.response[0], block: false, error: errorMessage });
                    return;
                };

                this.setState(({ block: false }));
            }
            else this.setState(({ block: false }));
        }, `/api/Price/EmailReservation/EmailReservation/v1/Extractor/${id}`);
    }

    saveExtractor = (e) => {
        e.preventDefault();
                
        this.setState({ block: true }, () => {
            const body = { ...this.state.extractor };

            postAPI(result => {
                const { data, error } = result;
                const errorMessage = [];

                if (error) {
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });
                    return;
                }
                if (data) {
                    if (data.errors && data.errors.length > 0) {
                        handleNotification(data);
                        this.setState({ error: errorMessage, block: false });
                        return;
                    }
                    if (data.response && data.response.length > 0) {
                        handleNotification(data, <FormattedMessage id="MailExtractor.ExtractorSaved" />, <FormattedMessage id="generic.success" />);

                        if (!this.props.match.params.id) this.props.history.push(`/MailExtractorDetails/${data.response[0].id}`);

                        this.setState({ extractor: {} }, () =>  this.getExtractorDetails(data.response[0].id));
                    }
                    else {
                        this.setState({ block: false });
                    }
                }
                else this.setState({ block: false });
            }, `/api/Price/EmailReservation/EmailReservation/v1/Extractor`, body);
        });
    }

    getAvailableFields = () => {
        const fields = [...this.state.extractorLinesFields];

        fields.forEach(field => {
            field.disabled = this.state.extractor.mailExtractionTemplateLines.some(el => el.field === field.value) || (field.dependent ? !this.state.extractor.mailExtractionTemplateLines.some(el => el.field === field.dependent) : false);
        })

        return fields;
    }

    updateExtractorLines = (line, isToRemove) => {
        const { extractor } = this.state;

        const index = extractor.mailExtractionTemplateLines?.findIndex(metl => metl.field === line.field);

        if (index !== -1) {
            if (isToRemove) {
                extractor.mailExtractionTemplateLines.splice(index, 1);
            }
            else {
                extractor.mailExtractionTemplateLines[index] = line;
            }
        }
        else {
            extractor.mailExtractionTemplateLines.push(line);
        }

        this.setState({ extractor, deleteModal: false });
    }

    toggleModal = (modal, line) => {
        this.setState(prevState => ({
            [modal]: !prevState[modal],
            selectedLine: line
        }))
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            extractor: {
                ...this.state.extractor,
                [name]: value
            }
        })
    }

    handleSelectChange = (combo, name) => {
        this.setState({
            extractor: {
                ...this.state.extractor,
                [name]: combo ? combo.value : null
            }
        })
    }
    

    render() {
        const { block, error, extractor, extractorTypes } = this.state;

        return (
            <StyledCard block={block} error={error} title="MailExtractor.MailExtractorDetails">
                <Form onSubmit={this.saveExtractor}>
                    <Row className="mb-3">
                        <Col className="text-right">
                            <Button className="btn-sm btn-host ml-2" type="submit">
                                <i class="fas fa-save"></i>
                            </Button>
                        </Col>
                    </Row>
                    <div>
                        <FormGroup row className="mb-5">
                            <Label sm={2}><FormattedMessage id="MailExtractor.Code" /></Label>
                            <Col>
                                <Input
                                    type="text"
                                    name="code"
                                    value={extractor.code || ''}
                                    onChange={this.handleChange}
                                    required
                                    maxLength={100}
                                />
                            </Col>
                            <Label sm={2}><FormattedMessage id="MailExtractor.Type" /></Label>
                            <Col>
                                <CustomSelect
                                    isSearchable
                                    placeholder={""}
                                    options={extractorTypes}
                                    value={extractorTypes.find(el => el.value === extractor.type) || ''}
                                    onChange={(combo) => this.handleSelectChange(combo, 'type')}
                                    required
                                />
                            </Col>
                        </FormGroup>

                        <Row className="align-items-center">
                            <Col>
                                <FormattedMessage id="MailExtractor.Lines" />
                            </Col>
                            <Col className="text-right">
                                <Button className="btn-sm btn-host" onClick={() => this.toggleModal('modal')}>
                                    <i class="fas fa-plus"></i>
                                </Button>
                            </Col>
                        </Row>

                        <hr className="border-host" />

                        <Card className="border-0 shadow py-2 text-muted mb-3" body>
                            <Row>
                                <Col sm={2}><FormattedMessage id="MailExtractor.Field" /></Col>
                                <Col sm={1}><FormattedMessage id="MailExtractor.Type" /></Col>
                                <Col sm={1}><FormattedMessage id="MailExtractor.Group" /></Col>
                                <Col><FormattedMessage id="MailExtractor.RegexString" /></Col>
                                <Col sm={1} />
                            </Row>
                        </Card>

                        {extractor.mailExtractionTemplateLines?.map((line, key) => {
                            const field = this.state.extractorLinesFields.find(el => el.value === line.field);

                            return <Card className="border-0 shadow mb-2 overbglight" body key={key}>
                                <Row>
                                    <Col sm={2}>{field?.label || line.field}</Col>
                                    <Col sm={1}><FormattedMessage id={`ReferenceDataFunctions.${line.type}`} /></Col>
                                    <Col sm={1}>{line.group}</Col>
                                    <Col>{line.regexString}</Col>
                                    <Col sm={1} className="text-right">
                                        <KebabMenu
                                            editFunction={() => this.toggleModal('modal', line)}
                                            deleteFunction={_ => this.toggleModal('deleteModal', line)}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        } )}
                    </div>
                </Form>

                {this.state.modal ?
                    <MailExtractorLines
                        isOpen={this.state.modal}
                        toggleModal={() => this.toggleModal('modal')}
                        updateExtractorLines={this.updateExtractorLines}
                        selectedLine={this.state.selectedLine}
                        extractorLinesFields={this.getAvailableFields()}
                        extractorLinesTypes={this.state.extractorLinesTypes}
                    />
                : ''}



                {this.state.deleteModal ?
                    <ConfirmActionModal
                        modal={this.state.deleteModal}
                        toggleModal={_ => this.toggleModal('deleteModal')}
                        actionFunction={() => this.updateExtractorLines(this.state.selectedLine, true)}
                        text={<FormattedMessage id="MailExtractor.ConfirmRemoveLine" values={{ field: this.state.selectedLine.field }} />}
                    />
                : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(MailExtractorDetails);