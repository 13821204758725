import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { PaymentGatewayMultiLang } from '../Base/Common/SupportedMultiLang';

export class RenderCustomForm extends Component {

    render() {
        const { paymentGateway, handleData, handleEditorStateChange, idx } = this.props;

        return (
            <Row className="mb-2">
                <Col>
                    <PaymentGatewayMultiLang
                        data={{
                            texts: paymentGateway.paymentGatewayMultilanguals,
                            handleChange: handleData,
                            handleEditorStateChange: handleEditorStateChange,
                            idx: idx
                        }}
                    />
                </Col>
            </Row>
        );
    }
}