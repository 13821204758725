import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, FormGroup, Modal, ModalHeader, ModalBody, Badge, ModalFooter, Button, CardHeader, Label, Input } from 'reactstrap';
import BlockUi from 'react-block-ui'
import { postAPI } from "../../Base/API"
import { ErrorAlert } from "../../Common/ErrorAlert";
import { handleNotification } from "../../Base/Notification"
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { getAirbnbReviewCategoryTags } from '../../Base/Common/ReferenceDataFunctions';
import CustomSelect from '../../Base/Common/CustomSelect';
import { StarRating } from '../../Base/Common/CommonUIComponents';
import { TextAreaAI } from '../../Base/Common/AiComponents';



export class AirbnbReviewDetail extends Component {
    constructor(props) {
        super(props);
        this.toggleModal = this.toggleModal.bind(this);
        this.form = React.createRef();


        this.state = {
            error: [],
            evaluation: ['cleanliness', 'communication', 'respect_house_rules'],
            activeTab: '1',
            Alerts: [],
            tags: getAirbnbReviewCategoryTags(this.props.selectedReview?.reviewer_role === 'guest'),
            reviewerCollapse: false,
            scoringCollapse: true,
            reviewCollapse: true
        };

    }

    toggleModal() {
        this.props.action();
        this.setState({ error: [] })
        
    }


    submitReview = (e, isHostReviewGuest) => {
        e.preventDefault();
        if (!this.form.current.reportValidity()) {
            return;
        }

        this.setState({ block: true });
        
        let req = isHostReviewGuest ?
            {
                review_id: this.props.selectedReview.id,
                is_reviewee_recommended: this.props.selectedReview.is_reviewee_recommended,
                private_feedback: this.props.selectedReview.private_feedback,
                public_review: this.props.selectedReview.public_review,
                category_ratings: this.props.selectedReview.category_ratings
            }
            :
            {
                review_id: this.props.selectedReview.id,
                reviewee_response: this.props.selectedReview.reviewee_response
            }
        ;

        postAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ block: false });
                
                handleNotification(data, <FormattedMessage id="AirbnbReviewDetail.ReviewSubmited" />, <FormattedMessage id="AirbnbReviewDetail.Success" />);

                this.props.reloadReview();
                this.toggleModal();
                return;   
                
            }
        }, '/api/Rate/Review/v1/airbnb/' + this.props.selectedConnection + '/review/write', req);

    }

    toggleCard = (cardName) => {
        this.setState(prevState => ({
            [cardName]: !prevState[cardName]
        }))
    }
    
    render() {
        const isExpired = this.props.selectedReview && moment(this.props.selectedReview.expires_at.substring(0, 19), 'YYYY-MM-DDTHH:mm:ss').isBefore(moment());
        const editHostReviewGuest = this.props.selectedReview && this.props.selectedReview.reviewer_role === "host" && !this.props.selectedReview.submitted 
        const editGuestReviewHost = this.props.selectedReview && this.props.selectedReview.reviewer_role === "guest" && this.props.selectedReview.submitted

        return (
            <div>
                {this.props.selectedReview ?
                    <Modal isOpen={this.props.modal} toggle={this.toggleModal} fade={false} className="modal-lg " >

                        <ModalHeader toggle={this.toggleModal} >

                            <FormattedMessage id="BookingReviewDetail.ReviewDetails" />  {this.props.selectedReview.reservation_confirmation_code}
                            {isExpired ? <Badge color="danger" className="mx-4">Expired </Badge> : ''}

                        </ModalHeader>
                        <ErrorAlert error={this.state.error} />
                        <ModalBody className="modal-lg px-2 pb-2">
                            <BlockUi tag="div" blocking={this.state.block}>
                                <ErrorAlert error={this.state.error} />
                                <form ref={this.form}>
                                    <Card className="mb-2 border-0 shadow">

                                        <CardHeader className="small bg-white pointer" onClick={() => this.toggleCard('reviewerCollapse')}>
                                            <div className="inline">
                                                <strong> <span className="far fa-id-card"></span> <FormattedMessage id="BookingReviewDetail.Reviewer" /> </strong>
                                                <a className="float-right">
                                                    <span className="fas fa-angle-down"></span>
                                                </a>
                                            </div>
                                        </CardHeader>
                                        {this.state.reviewerCollapse ?
                                            <CardBody className="pb-0 small border-1">
                                                <Row>
                                                    <Col className="col-6">
                                                        <FormGroup sm="true" row>
                                                            <Label for="Headline" sm={4}> <FormattedMessage id="BookingReviewDetail.Name" /> </Label>
                                                            <Col sm={8}>
                                                                <Input type="text" name="Headline" id="Headline" bsSize="sm" className="text-sm" disabled value={this.props.selectedReview.reviewee_id} />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className="col-6">
                                                        <FormGroup sm="true" row>

                                                            <Label for="Country" sm={4}> <FormattedMessage id="BookingReviewDetail.overallRating" /> </Label>
                                                            <Col sm={8}>
                                                                <Input type="text" name="Country" id="Headline" bsSize="sm" className="text-sm" disabled value={this.props.selectedReview.overall_rating || ''} />
                                                            </Col>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                            : ''}
                                    </Card>

                                    <Card className="mb-2  border-0 shadow pb-3">
                                        <CardHeader className="small bg-white pointer" onClick={() => this.toggleCard('scoringCollapse')}>
                                            <div className="inline">
                                                <strong> <span className="fas fa-award"> </span> <FormattedMessage id="BookingReviewDetail.Scoring" /></strong>
                                                <a className="float-right">
                                                    <span className="fas fa-angle-down"></span>
                                                </a>
                                            </div>
                                        </CardHeader>
                                        {this.state.scoringCollapse ?
                                            <CardBody className="pb-0 small border-1">
                                                <Row>
                                                    {this.props.selectedReview.submitted === true && this.props.selectedReview.category_ratings && this.props.selectedReview.category_ratings.map((item, key) =>
                                                        <Col className="col-12 col-lg-6 mb-3" key={key}>
                                                            <ReviewCard
                                                                review={item}
                                                                tags={this.state.tags}
                                                            />
                                                        </Col>
                                                    )}
                                                </Row>
                                                {this.props.selectedReview.submitted === false && this.props.selectedReview.reviewer_role === "host" /*&& moment(this.props.selectedReview.expires_at.substring(0, 19),'YYYY-MM-DDTHH:mm:ss') > moment()*/ ?
                                                    <HostReviewGuestCategories
                                                        evaluation={this.state.evaluation}
                                                        reviewCategories={this.props.selectedReview.category_ratings}
                                                        tags={this.state.tags}
                                                        handleChange={(event) => this.props.updateReview('category_ratings', event)}
                                                        isHostReviewGuest={editHostReviewGuest && !isExpired}
                                                    />
                                                    : ''}
                                            </CardBody>
                                        : ''}
                                    </Card>


                                    <Card className="mb-2 border-0 shadow">
                                        <CardHeader className="small bg-white pointer" onClick={() => this.toggleCard('reviewCollapse')}>
                                            <div className="inline">
                                                <strong> <span className="far fa-edit"></span> <FormattedMessage id="BookingReviewDetail.ReviewContent" /></strong>
                                                <a className="float-right">
                                                    <span className="fas fa-angle-down"></span>
                                                </a>
                                            </div>
                                        </CardHeader>
                                        {this.state.reviewCollapse ?
                                            <CardBody className="pb-0 small border-1 px-4">
                                                {this.props.selectedReview.reviewer_role === "host" ?
                                                    <FormGroup sm="true" row>
                                                        <Label for="Headline" sm={12}> <FormattedMessage id="AirbnbReviewCard.RecommendedGuest" /></Label>
                                                        <Col sm={12}>
                                                            <ButtonGroup size="sm">
                                                                <Button color="secondary" outline onClick={() => !(isExpired || this.props.selectedReview.submitted) && this.props.updateReview('', { target: { value: false, name: 'is_reviewee_recommended' } })} active={this.props.selectedReview.is_reviewee_recommended === false} >
                                                                    <FormattedMessage id="ReferenceDataFunctions.No" />
                                                                </Button>
                                                                <Button color="secondary" outline onClick={() => !(isExpired || this.props.selectedReview.submitted) && this.props.updateReview('', { target: { value: true, name: 'is_reviewee_recommended' } })} active={this.props.selectedReview.is_reviewee_recommended} >
                                                                    <FormattedMessage id="ReferenceDataFunctions.Yes" />
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Col>
                                                    </FormGroup>
                                                    : ''}

                                                <FormGroup sm="true" row>
                                                    <Label for="Headline" sm={12}> <FormattedMessage id="BookingReviewDetail.PrivateFeedback" /></Label>
                                                    <Col sm={12}>
                                                        <Input
                                                            type="textarea" id="Headline" bsSize="sm"
                                                            name="private_feedback"
                                                            className="text-sm"
                                                            disabled={!editHostReviewGuest || isExpired || this.props.selectedReview.submitted}
                                                            value={this.props.selectedReview.private_feedback || ""}
                                                            onChange={(e) => this.props.updateReview('', e)}
                                                            maxLength={1000}
                                                        />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup sm="true" row>
                                                    <Label for="positive" sm={12}> <FormattedMessage id="BookingReviewDetail.PublicReview" /></Label>
                                                    <Col sm={12}>
                                                        <Input type="textarea" id="positive" name="public_review" bsSize="sm" className="text-sm"
                                                            required
                                                            disabled={!editHostReviewGuest || isExpired || this.props.selectedReview.submitted}
                                                            value={this.props.selectedReview.public_review ? this.props.selectedReview.public_review : ''}
                                                            onChange={(e) => this.props.updateReview('', e)}
                                                            maxLength={1000}
                                                        />
                                                    </Col>
                                                </FormGroup>

                                                <FormGroup sm="true" row >
                                                    <Label sm={12}> <FormattedMessage id="BookingReviewDetail.RevieweeResponse" /></Label >
                                                    <Col sm={12}>
                                                        <TextAreaAI
                                                            inputName="reviewee_response"
                                                            reviewText={this.props.selectedReview.public_review || ''}
                                                            value={this.props.selectedReview.reviewee_response || ''}
                                                            handleChange={(e) => this.props.updateReview('', e)}
                                                            disabled={!editGuestReviewHost || isExpired || this.props.selectedReview.responded_at}
                                                            isReview={true}
                                                            maxLength={1000}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </CardBody>
                                        : ''}
                                    </Card>
                                </form>
                            </BlockUi>
                        </ModalBody>
                    <ModalFooter>
                        {!isExpired && (this.props.selectedReview.reviewer_role === "host" ? !this.props.selectedReview.submitted : (!this.props.selectedReview.responded_at && this.props.selectedReview.submitted) ) ?
                            <Button className="btn-host" onClick={(e) => this.submitReview(e, editHostReviewGuest)}> <FormattedMessage id="BookingReviewDetail.Send" /></Button>
                            : ''}
                        <Button color="secondary" onClick={this.toggleModal}> <FormattedMessage id="BookingReviewDetail.Cancel" /></Button>
                    </ModalFooter>
                       
            </Modal>

                : ''}
            </div>
        );
    }
}

const HostReviewGuestCategories = ({ evaluation, reviewCategories, tags, handleChange, isHostReviewGuest }) => {
    return (
        <div>
            {evaluation.map((item, key) => {
                const review = reviewCategories?.find(el => el.category === item);

                return (
                    <div key={key} className="mb-3">
                        <Row>
                            <Col sm={2}>
                                <strong><FormattedMessage id={'AirbnbReviewCard.' + item} /></strong>
                            </Col>
                            <Col sm={3}>
                                <StarRating
                                    value={review?.rating}
                                    isHostReviewGuest={isHostReviewGuest}
                                    handleChange={(ratingValue) => handleChange({ target: { name: 'rating', value: ratingValue, categoryName: item } })}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <span className="text-muted"> <FormattedMessage id='AirbnbReviewCard.Comment' /></span>
                                <Input
                                    type="text"
                                    name="comment"
                                    value={review?.comment || ''}
                                    disabled={(!review?.rating || review?.rating === 5) && (!review?.review_category_tags || review?.review_category_tags?.some(el => el.includes("_other")) === false)}
                                    maxLength="50"
                                    onChange={(e) => handleChange({ target: { ...e.target, name: 'comment', categoryName: item } })}
                                />
                            </Col>
                            <Col>
                                <div className="text-muted"> Tags</div>
                                <CustomSelect
                                    isSearchable
                                    isMulti
                                    options={tags.filter(tag => tag.category === item)}
                                    placeholder=""
                                    isDisabled={!isHostReviewGuest}
                                    onChange={(combo) => handleChange({ target: { name: 'review_category_tags', value: (combo ? combo.map(el => el.value) : []), categoryName: item } })}
                                />
                            </Col>
                        </Row>
                    </div>
                )
            } )}
        </div>
    )
}


const ReviewCard = ({ review, tags }) => {
    return (
        <Card body className="py-2 h-100 border-0">
            <Row>
                <Col className={`col-12`}>
                    <strong className="mr-4"><FormattedMessage id={'AirbnbReviewCard.' + review.category} /></strong>
                    <StarRating value={review.rating} />
                </Col>
            </Row>

            <div className="text-muted mt-1 pl-1">
                <FormattedMessage id='AirbnbReviewCard.Comment' />: {review.comment}
            </div>

            {review.review_category_tags?.length > 0 ?
                <div className="mt-2 d-flex flex-wrap ">
                    {review.review_category_tags.map((tag, index) => {
                        const selectedTag = tags.find(el => el.value === tag.toLowerCase());
                        
                        return <CategoryTag tag={selectedTag || { label: tag, value: tag }} key={index} /> 
                    })}
                </div>
                : ''}
        </Card>
    )
}

const CategoryTag = ({ tag }) => {
    const color = tag.value.includes('negative') ? '#dc354580' : tag.value.includes('positive') ? '#19875480' : '#6c757d80';

    return <div className="px-2 py-1 mr-2 mb-2" style={{ border: `1px solid ${color}`, borderRadius: '30px', fontSize: '12px' }}>
        {tag.label}
    </div>
}
