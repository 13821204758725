import React, { Component } from 'react';
import 'react-dates/initialize';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Card, CardBody, Col, Progress, Row } from 'reactstrap';



export class AirbnbGlobalReviewCardMobile extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };

    }
    
    componentDidMount() {
    }
    render() {
        return (
            <div>
                { this.props.reviews ?
                    <Row>
                        <Col className="col-12">
                            <Card className="border-left-primary shadow my-2">
                                <CardBody className="py-4">
                                    <Row className="mb-3 d-flex align-items-center">
                                        <Col className="col-12 mb-1">
                                            <b> <FormattedMessage id="AirbnbReviewCard.GlobalReview" /></b>
                                        </Col>
                                        <Col className="col-10">
                                            <Progress striped color={(this.props.reviews.rating) >= 3.5 ? "success" : ((this.props.reviews.rating) > 2.5 && (this.props.reviews.rating) < 3.5) ? "warning" : "danger"} value={this.props.reviews.rating} max="5"/>
                                        </Col>
                                        <Col className="col-2 p-0">
                                            <FormattedNumber value={this.props.reviews.rating} maximumFractionDigits={2} />
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col className="col-12">
                                            <b> <FormattedMessage id="AirbnbReviewCard.CountOfEntitysReviews" /></b>
                                        </Col>
                                        <Col>
                                            <FormattedNumber value={this.props.reviews.count} maximumFractionDigits={2} />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                : ''
            }
            </div>
        );
    }
}