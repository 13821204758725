import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card} from 'reactstrap';
import classnames from 'classnames';
import { RoomSetup } from './RoomSetup';
import { FormattedMessage } from 'react-intl';
import { getAPI, deleteAPI } from "../../Base/API"
import { ErrorAlert } from "../../Common/ErrorAlert";
import BlockUi from 'react-block-ui'
import { RatesIntegrations } from './RatesIntegrations';
import Authorization from '../../Base/Authorization';
import { handleNotification } from "../../Base/Notification";
import { CommonHelper } from '../../Base/Common/CommonUIComponents';

export class RoomRateConfig extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.addRoomRate = this.addRoomRate.bind(this);
        this.getData = this.getData.bind(this);
        this.state = {
            error: null,
            block: true,
            blockLabels: true,
            activeTab: '1',
            modal: false,
            Alerts: [], 
            RoomCategories: [],
            Rates: [],
            hotelData: [],
            labels: [{ value: "_NEW", label: <b><FormattedMessage id="EditRate.AddNew" /></b>, isToCreate: true }],
            deleteLabelModal: false,
            selectedLabelDelete: null,
            hideLabels: true,
        };
        
    }

    componentDidMount() {
        this.getData();
    }

    getData(filterResults, selectedRates, selectedChannel, selectedInv, selectedStatus) {
        this.setState({ block: true, blockFilterRooms: filterResults, blockFilterRates: filterResults });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = []; 
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                var array1 = [];

                if(data.ratecodechannel)
                    Object.keys(data.ratecodechannel).map(function (key, index) {
                        data.ratecodechannel[key].visible = false;
                        array1.push(data.ratecodechannel[key]);
                        return true;
                    });

                data.ratecodechannel = array1;

                var array2 = [];

                if(data.roomcategory)
                    Object.keys(data.roomcategory).map(function (key, index) {
                        data.roomcategory[key].visible = true;
                        array2.push(data.roomcategory[key])
                        return true;
                    });

                data.roomcategory = array2;
                this.sortBySortOrder(data.roomcategory);

                var array3 = [];

                if(data.ratecode)
                    Object.keys(data.ratecode).map(function (key, index) {
                        data.ratecode[key].visible = true;
                        data.ratecode[key].channels = [];

                        Object.keys(array1).forEach((key2) => {
                            if (array1[key2].rateCodeId === data.ratecode[key].id) {
                                const channel = array1[key2];
                                channel.id = key2;

                                data.ratecode[key].channels.push(channel)
                            }
                        })

                        array3.push(data.ratecode[key]);
                        return true;
                    });

                data.ratecode = array3;
                this.sortBySortOrder(data.ratecode);


                var array4 = [];

                if(data.pricelist)
                    Object.keys(data.pricelist).map(function (key, index) {

                        array4.push(data.pricelist[key]);
                        return true;
                    });
                data.pricelist = array4;


                var array5 = [];
                
                if(data.ratecodepax)
                    Object.keys(data.ratecodepax).map(function (key, index) {

                        array5.push(data.ratecodepax[key]);
                        return true;
                    });
                data.ratecodepax = array5;

                this.setState({ hotelData: data, block: false }, () => {
                    if (filterResults) {
                        this.filterHotelData(selectedRates, selectedChannel, selectedInv, selectedStatus);
                    }
                    this.getLabels();
                });
            }
            else this.setState({ block: false });
        }, '/api/Rate/Rate/v1/RoomRateChannel');
    }

    deleteLabel = (e) => {
        e.preventDefault();

        if(this.state.selectedLabelDelete){
            this.setState({ block: true }, () => {
                deleteAPI(result => {
                    const { data, error } = result;
                    if (error) {
                        const errorMessage = [];
                        errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                        this.setState({ block: false, error: errorMessage });
                        return;
                    }
                    if (data) {
                        if (data.errors && data.errors.length > 0) {
                            handleNotification(data);
                            this.setState(({ block: false }));
                            return;
                        }
                        else {
                            var { labels, hotelData } = this.state;
                            handleNotification(data, <FormattedMessage id="EditRate.RateRemoved" />, <FormattedMessage id="General.Success" />);

                            labels = [{ value: "_NEW", label: <b><FormattedMessage id="EditRate.AddNew" /></b>, isToCreate: true }];

                            data.response.forEach((item) => {
                                var hasAssociatedRate = hotelData.ratecode.find(rate => rate.rateLabelId === item.id);
            
                                if(!labels.some(l => l.value === item.id)){
                                    
                                    const label = {
                                        'value': item.id,
                                        'label': hasAssociatedRate ? 
                                            item.label
                                            : (
                                                <div className='d-flex justify-content-between'>
                                                    <div>
                                                        {item.label} 
                                                    </div>
                                                    <div className='pointer' onClick={() => this.toggleDeleteLabelModal(item)}>
                                                        <i className="fas fa-times"></i>
                                                    </div>
                                                </div>
                                            ),
                                        'htmlLabel': (
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    {item.label} 
                                                </div>
                                                <div className='pointer' onClick={() => this.toggleDeleteLabelModal(item)}>
                                                    <i className="fas fa-times"></i>
                                                </div>
                                            </div>
                                        ),
                                        'labelCpy': item.label
                                    };
            
                                    labels.push(label);
                                }
                            })

                            this.setState({block: false, labels }, () => this.toggleDeleteLabelModal(null));
                            
                        }
                        
                    }
                    else this.setState({ block: false });
                }, `/api/Rate/Rate/v1/RateLabel?id=${this.state.selectedLabelDelete.id}`);
            
            });
        }
    }

    toggleDeleteLabelModal = (rateLabel) => {
        this.setState({ deleteLabelModal: !this.state.deleteLabelModal, selectedLabelDelete: rateLabel });
    }

    getLabels = (isToDelete) => {
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ blockLabels: false, error: errorMessage });
                return;
            }
            if (data && data.response) {
                var { labels, hotelData } = this.state;
                var filteredLabels = [];
                if(isToDelete){
                    labels = [{ value: "_NEW", label: <b><FormattedMessage id="EditRate.AddNew" /></b>, isToCreate: true }];
                }
                
                data.response.forEach((item) => {
                    var hasAssociatedRate = hotelData.ratecode.find(rate => rate.rateLabelId === item.id);

                    const label = {
                        'value': item.id,
                        'label': item.label,
                        'htmlLabel': (
                            <div className='d-flex justify-content-between'>
                                <div>
                                    {item.label}
                                </div>
                                <div className='pointer' onClick={() => this.toggleDeleteLabelModal(item)}>
                                    <i className="fas fa-times"></i>
                                </div>
                            </div>
                        ),
                        'labelCpy': item.label
                    };

                    if (!labels.some(l => l.value === item.id) && hasAssociatedRate){                     
                        filteredLabels.push(label);
                    }
                    labels.push(label);
                })
                this.setState({ blockLabels: false, labels, filteredLabels, hideLabels: !(filteredLabels.length > 0) });
            }
            else {
                this.setState({ blockLabels: false });
            }
        }, '/api/Rate/Rate/v1/RateLabel');
    }

    updateLabels = (newLabel) => {
        const { labels } = this.state;

            const label = { 
                'value': newLabel.id,
                'label': newLabel.label,
                'htmlLabel': (
                    <div className='d-flex justify-content-between'>
                        <div>
                            {newLabel.label} 
                        </div>
                        <div className='pointer' onClick={() => this.toggleDeleteLabelModal(newLabel)}>
                            <i className="fas fa-times"></i>
                        </div>
                    </div>
                ),
                'labelCpy': newLabel.label
            };

            labels.push(label);

        this.setState({ labels });
    }

    updateLabelsOnChangeSelect = (selectedRate) => {
        const { labels, hotelData } = this.state;

        labels.forEach(l => {

            if(!l.isToCreate){
                const associatedRate = hotelData.ratecode.find(rc => rc.rateLabelId === l.value);

                if (l.value === selectedRate?.rateLabelId || associatedRate) {
                    l.label = l.labelCpy;
                }else{
                    l.label = l.htmlLabel;
                }
            }
        });

        this.setState({ labels });
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleModal(tab) {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    filterHotelData = (selectedRates, selectedChannel, selectedInv, selectedStatus) => {
        this.filterRoom(this.state.roomFilter);
        this.filterRate(selectedRates, selectedChannel, selectedInv, selectedStatus);
    }

    filterRoom = (inputValue, actionMeta) => {
        let { hotelData } = this.state;

        hotelData.roomcategory.forEach(el => {
            if (!inputValue || inputValue.length === 0 || inputValue.some(c => el.id === c.value)) {
                el.visible = true;
            }
            else {
                el.visible = false;
            }
        })

        this.setState({ hotelData, roomFilter: inputValue, blockFilterRooms: false });

    }

    filterRate = (selectedRates, selectedChannel, selectedInv, selectedStatus, selectedLabel) => {
        let { hotelData } = this.state;

        hotelData.ratecode.forEach(el => {  
            if ((selectedRates?.length === 0 || selectedRates?.some(c => el.id === c.value)) &&
                (!selectedChannel || el.channels?.some(c => c.channelInstanceId === selectedChannel.value)) &&
                (!selectedInv || el.inventoryMode === selectedInv.value) &&
                (!selectedStatus || el.active === selectedStatus.value) &&
                (!selectedLabel || el.rateLabelId === selectedLabel.value)
            ) {
                el.visible = true;
            }
            else {
                el.visible = false;
            }
        })

        this.setState({ hotelData, blockFilterRates: false });
    }

    addRoomRate(selectedRates, selectedChannel, selectedInv, selectedStatus) {
        this.getData(true, selectedRates, selectedChannel, selectedInv, selectedStatus);              
    }

    updateRateCode = (ratecode, isToRemove) => {
        const { hotelData } = this.state;
        if (isToRemove) {
            hotelData.ratecode = hotelData.ratecode.filter(el => el.id !== ratecode.id);
        }
        else {
            const priceList = {
                id: ratecode.pricelistId,
                dependencyPercentage: ratecode.dependencyPercent,
                dependencyRoundDecimalPlaces: ratecode.dependencyRound,
                dependencyAmount: ratecode.dependencyValue,
                dependencyMasterId: ratecode.dependencyMasterId,
                dependencyReferenceRoomCategory: ratecode.dependencyRoomCategory,
                dependencyReferencePackage: ratecode.dependencyPackage,
                dependecyOrder: ratecode.dependecyOrder
            }

            const idx = hotelData.ratecode.indexOf(hotelData.ratecode.find(rc => rc.id === ratecode.id));
            const priceListIdx = hotelData.pricelist ? hotelData.pricelist.findIndex(rc => rc.id === ratecode.pricelistId) : -1;

            if (idx === -1) {
                hotelData.ratecode.push(ratecode);
            }
            else {
                Object.assign(hotelData.ratecode[idx], ratecode);

                if(!ratecode.rateLabelId){
                    hotelData.ratecode[idx].rateLabelId = null;
                }
            }

            if (priceListIdx === -1) {
                hotelData.pricelist.push(priceList);
            }
            else if (hotelData && hotelData.pricelist && hotelData.pricelist[priceListIdx] && hotelData.pricelist[priceListIdx].code && hotelData.pricelist[priceListIdx].code.includes("Dependent")) {
                Object.assign(hotelData.pricelist[priceListIdx], priceList);
            }

            this.sortBySortOrder(hotelData.ratecode);
        }

        this.setState({ hotelData }, () => this.updateLabelsOnChangeSelect());
    }

    updateRateCodeInBulk = (payload) => {
        let updatedRateCodes = [...this.state.hotelData.ratecode];
        
        if (payload.deleteRates) {
            updatedRateCodes = updatedRateCodes.filter(rc => payload.rateCodeIds.some(prc => prc !== rc.id));
        }
        else {
            payload.rateCodeIds.forEach(rateCodeId => {
                const index = updatedRateCodes.findIndex(rc => rc.id === rateCodeId);

                if (index !== -1) {
                    if (payload.changeStatus) {
                        updatedRateCodes[index].active = payload.active;
                    }

                    if (payload.associateRate) {
                        updatedRateCodes[index].rateLabelId = payload.rateLabelId;
                    }
                }
            })

        }

        this.setState({
            hotelData: {
                ...this.state.hotelData,
                ratecode: updatedRateCodes
            }
        });
    }

    sortBySortOrder(arr) {
        arr.sort((rc1, rc2) => rc1.sortOrder - rc2.sortOrder);
    }

    render() {
        return (
            <Card className="border-0">
                <BlockUi tag="div" blocking={this.state.block || this.state.blockFilterRooms || this.state.blockFilterRates || this.state.blockLabels}>
                    <ErrorAlert error={this.state.error} />
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                <FormattedMessage id="RoomRateConfig.Rates" />
                            </NavLink>
                        </NavItem>
                        {/*<NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                <FormattedMessage id="RoomRateConfig.Rates" /> 
                            </NavLink>
                        </NavItem>*/}
                        <Authorization
                            perform="rateCodeDetails:addAndEdit"
                            yes={() => (
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <FormattedMessage id="RoomRateConfig.Integrations" />
                                    </NavLink>
                                </NavItem>
                            )}
                            no={() => <div></div>}
                        />

                        <span className="ml-auto mt-2 mr-3">
                            <CommonHelper help={<FormattedMessage id="RoomRateConfig.HelpText" />} id="RoomRateConfig" />
                        </span>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                            <RoomSetup
                                RoomCategories={this.state.hotelData.roomcategory}
                                ratecode={this.state.hotelData.ratecode}
                                filterRoom={this.filterRoom}
                                filterRate={this.filterRate}
                                RateCodeChannel={this.state.hotelData.ratecodechannel}
                                pricelist={this.state.hotelData.pricelist}
                                channelinstance={this.state.hotelData.channelinstance}
                                ratecodepax={this.state.hotelData.ratecodepax}
                                onCreateRoomRate={this.addRoomRate}
                                updateRateCode={this.updateRateCode}
                                updateRateCodeInBulk={this.updateRateCodeInBulk}
                                update={this.getData}
                                labels={this.state.labels}
                                filteredLabels={this.state.filteredLabels}
                                hideLabels={this.state.hideLabels}
                                updateLabels={this.updateLabels}
                                getLabels={this.getLabels}
                                deleteLabelModal={this.state.deleteLabelModal}
                                selectedLabelDelete={this.state.selectedLabelDelete}
                                toggleDeleteLabelModal={this.toggleDeleteLabelModal}
                                deleteLabel={this.deleteLabel}
                                updateLabelsOnChangeSelect={this.updateLabelsOnChangeSelect}
                            />
                        </TabPane>
                        {/*<TabPane tabId="2">
                            <RateSetup
                                RoomCategories={this.state.hotelData.roomcategory}
                                ratecode={this.state.hotelData.ratecode}
                                filterRoom={this.filterRoom}
                                filterRate={this.filterRate}
                                filterRateByInventoryMode={this.filterRateByInventoryMode}
                                RateCodeChannel={this.state.hotelData.ratecodechannel}
                                onCreateRoomRate={this.addRoomRate}
                                pricelist={this.state.hotelData.pricelist}
                                updateRateCode={this.updateRateCode}
                            />
                        </TabPane>*/}
                        <TabPane tabId="3">
                            <RatesIntegrations />
                        </TabPane>
                    </TabContent>
                </BlockUi>
            </Card>
        );
    }
}