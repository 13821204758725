import React, { Component } from 'react';
import { Row, Col, Modal, ModalBody, Input, Form, Button, Label, FormGroup } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { postAPI } from "../../../Base/API";
import CustomSelect from '../../../Base/Common/CustomSelect';
import { handleNotification } from "../../../Base/Notification";
import { StyledCard } from '../../../Base/Common/CommonUIComponents';


class CopyTemplateModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            templateCopy: {}
        }
    }

    copyTemplate = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        postAPI(result => {
            const { data, error } = result;
            this.setState({ block: true });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false });
                    return;
                }
                else {
                    handleNotification(data, <FormattedMessage id="CopyTemplateModal.TemplateCopied" />, <FormattedMessage id="General.Success" />);
                    this.props.getNotifyEvent();
                    this.props.toggle();
                }

                this.setState({ block: false });
            }
        }, `/api/notification/Template/v1/copy`, JSON.stringify({ ...this.state.templateCopy }));
    }

    handleChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            templateCopy: {
                ...this.state.templateCopy,
                [name]: value
            }
        });
    };

    handleChangeSelect = (name, combo) => {
        this.setState({
            templateCopy: {
                ...this.state.templateCopy,
                [name]: combo ? combo.value : null
            }
        });
    };

    render() {
        const { templateCopy } = this.state;
        const { hotelList, templateOptions } = this.props;

        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggle} size={"lg"} style={{ minWidth: 0 }}>
                <ModalBody className="p-0">
                    <StyledCard block={this.state.block} error={this.state.error} title={'CopyTemplateModal.CopyTemplate'}>
                        <Form onSubmit={this.copyTemplate}>
                            <Row className="mb-3">
                                <Col className="text-right">
                                    <Button className="btn btn-host btn-sm" type="submit">
                                        <i className="fas fa-save"></i>
                                    </Button>
                                </Col>
                            </Row>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Label><FormattedMessage id="CopyTemplateModal.TemplateToCopy"/></Label>
                                </Col>
                                <Col>
                                    <CustomSelect
                                        options={templateOptions}
                                        placeholder={<FormattedMessage id="CopyTemplateModal.TemplateToCopy" />}
                                        onChange={(combo) => this.handleChangeSelect('notifyGenericTemplateId', combo)}
                                        value={templateOptions.find(r => r.value === templateCopy.notifyGenericTemplateId) || ''}
                                        isClearable isSearchable
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Label><FormattedMessage id="CopyTemplateModal.Target" /></Label>
                                </Col>
                                <Col>
                                    <CustomSelect
                                        options={hotelList}
                                        placeholder={<FormattedMessage id="CopyTemplateModal.Target" />}
                                        onChange={(combo) => this.handleChangeSelect('targetId', combo)}
                                        value={hotelList.find(r => r.value === templateCopy.targetId) || ''}
                                        isClearable isSearchable
                                        required
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Col sm={3}>
                                    <Label><FormattedMessage id="CopyTemplateModal.TemplateName" /></Label>
                                </Col>
                                <Col>
                                    <Input
                                        name="templateName"
                                        value={templateCopy.templateName || ''}
                                        required
                                        onChange={(e) => this.handleChange(e)}
                                        placeholder={this.props.intl.formatMessage({ id: "CopyTemplateModal.TemplateName" })}
                                    />
                                </Col>
                            </FormGroup>
                        </Form>
                    </StyledCard>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(CopyTemplateModal)