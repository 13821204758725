import React, { Component } from 'react';
import { Row, Col, Input, InputGroup, FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';



export class LicenseForm extends Component {
    


    render() {
        return (
            <div>
                
                
                <Row>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="LilicenseCode" sm={4}> <FormattedMessage id="LicenseForm.LicenseCode" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LicenseCode">{placeholder =>
                                        <Input name="licenseCode" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.licenseCode}/>
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="licenseCode2" sm={4}> <FormattedMessage id="LicenseForm.LicenseCode2" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LicenseCode2">{placeholder =>
                                        <Input name="licenseCode2" placeholder={placeholder}  readOnly defaultValue={this.props.hoteldata.licenseCode2}/>
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup  row>
                            <Label for="licenseFlag1" sm={4}> <FormattedMessage id="LicenseForm.LicenseFlag" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LicenseFlag">{placeholder =>
                                        <Input name="licenseFlag1" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.licenseFlag1} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup  row>
                            <Label for="licenseValidUntil" sm={4}> <FormattedMessage id="LicenseForm.LicenseValidUntil" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LicenseValidUntil">{placeholder =>
                                        <Input name="licenseValidUntil" placeholder={placeholder}  readOnly defaultValue={this.props.hoteldata.licenseValidUntil} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup  row>
                            <Label for="licenseFeatures" sm={4}> <FormattedMessage id="LicenseForm.LicenseFeatures" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LicenseFeatures">{placeholder =>
                                        <Input name="licenseFeatures" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.licenseFeatures} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup  row>
                            <Label for="licensePosLocation" sm={4}> <FormattedMessage id="LicenseForm.LicensePosLocation" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LicensePosLocation">{placeholder =>
                                        <Input name="licensePosLocation" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.licensePosLocation} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup  row>
                            <Label for="licensedRooms" sm={4}> <FormattedMessage id="LicenseForm.LicensedRooms" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LicensedRooms">{placeholder =>
                                        <Input name="licensedRooms" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.licensedRooms} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup  row>
                            <Label for="licensedPos" sm={4}> <FormattedMessage id="LicenseForm.LicensedPos" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LicensedPos">{placeholder =>
                                        <Input name="licensedPos" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.licensedPos} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="pmsType" sm={4}> <FormattedMessage id="LicenseForm.PmsType" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.PmsType">{placeholder =>
                                        <Input name="pmsType" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.pmsType} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="currentVersion" sm={4}> <FormattedMessage id="LicenseForm.CurrentVersion" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.CurrentVersion">{placeholder =>
                                        <Input name="currentVersion" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.currentVersion} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="lastServicePack_File_Ordinal" sm={4}> <FormattedMessage id="LicenseForm.LastServicePackFileOrdinal" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LastServicePackFileOrdinal">{placeholder =>
                                        <Input name="lastServicePack_File_Ordinal" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.lastServicePack_File_Ordinal} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="lastServicePack_File_Timestamp" sm={4}> <FormattedMessage id="LicenseForm.LastServicePackFileTimestamp" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LastServicePackFileTimestamp">{placeholder =>
                                        <Input name="lastServicePack_File_Timestamp" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.lastServicePack_File_Timestamp} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="lastServicePack_Sql_Ordinal" sm={4}> <FormattedMessage id="LicenseForm.LastServicePackSqlOrdinal" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LastServicePackSqlOrdinal">{placeholder =>
                                        <Input name="lastServicePack_Sql_Ordinal" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.lastServicePack_Sql_Ordinal} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="lastServicePack_Sql_Timestamp" sm={4}> <FormattedMessage id="LicenseForm.LastServicePackSqlTimestamp" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.LastServicePackSqlTimestamp">{placeholder =>
                                        <Input name="lastServicePack_Sql_Timestamp" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.lastServicePack_Sql_Timestamp} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        <FormGroup row>
                            <Label for="apiEndPoint" sm={4}> <FormattedMessage id="LicenseForm.ApiEndPoint" /> </Label>
                            <Col sm={8}>
                                <InputGroup size="sm">
                                    <FormattedMessage id="LicenseForm.ApiEndPoint">{placeholder =>
                                        <Input name="apiEndPoint" placeholder={placeholder} readOnly defaultValue={this.props.hoteldata.apiEndPoint} />
                                    }</FormattedMessage>
                                </InputGroup>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
            </div>
        );
    }
}