import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Label, Input, Button, InputGroup, CustomInput, Form } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';
import { handleNotification } from "../../Base/Notification";
import { postAPI, putAPI, getAPI } from "../../Base/API";
import BlockUi from 'react-block-ui';
import { getCurrencyCode, getLanguages } from "../../Base/Common/ReferenceDataFunctions";
import Authorization from '../../Base/Authorization';
import countryList from "react-select-country-list";
import { StyledCard, ActiveInactiveStatusBadge } from '../../Base/Common/CommonUIComponents';
import ResendAvailability from './ResendAvailability';
import CustomToolTip from '../../../Utils/CustomToolTip';

class HotelDetails extends Component {
	constructor(props) {
		super(props);

		this.state = {
			block: false,
			hotel: this.props.hotel ? this.props.hotel : {},
			hotelGroups: [],
			resendDataModal: false,
			pullReservations: false,
			sendAvailability: false,
			scanConfigChanges: false
		};
		this.setHotelStatus = this.setHotelStatus.bind(this);
	}

	componentDidMount() {
		this.getHotelGroups();
	}

	handleChangeSelect = (name, combo) => {
		this.setState({
			hotel: {
				...this.state.hotel,
				[name]: combo ? combo.value : null
			}
		});
	};

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({
			hotel: {
				...this.state.hotel,
				[name]: value
			}
		})
	}

	getHotelGroups = () => {
		this.setState({ block: true });
		getAPI(result => {
			const { data, error } = result;

			if (error) {
				var errorMessage = [];
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0) {
					handleNotification(data);
					this.setState(({ block: false, error: errorMessage }));
					return;
				}
				if (data.data && data.data.length > 0) {
					var groups = [];
					data.data.forEach((g) => {
						var obj = {
							value: g.id,
							label: g.name
						}
						groups.push(obj);
					})
					this.setState({ hotelGroups: groups, block: false, error: data.errors });
				}
			}
		}, '/api/hotel/HotelGroup/v1');
	}

	updateHotel = (e) => {
		e.preventDefault();
		this.setState({ block: true });
		var body = { request: { ...this.state.hotel } };

		putAPI(result => {
			const { data, error } = result;
			var errorMessage = [];

			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0) {
					handleNotification(data);
					this.setState(({ block: false, error: errorMessage }));
					return;
				}
				if (data.response && data.response.length > 0) {
					handleNotification(data, <FormattedMessage id="HotelDetails.HotelUpdated" />, <FormattedMessage id="General.Success" />);
					this.props.updateTable(data.response[0]);
				}
				this.setState(({ block: false }));
			}
		}, `/api/hotel/v1/Hotel`, body);
	}

	createHotel = (e) => {
		e.preventDefault();
		this.setState({ block: true });
		var body = { ...this.state.hotel };

		const regex = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;
		const hotelId = document.getElementById('hotelId');

		if (this.state.hotel.hotelId && !regex.test(this.state.hotel.hotelId)) {
			hotelId.setCustomValidity(this.props.intl.formatMessage({ id: "hotel.hotelIdInvalidFormat" }));
			this.setState({ block: false });
			return;
		}

		postAPI(result => {
			const { data, error } = result;
			var errorMessage = [];

			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0) {
					handleNotification(data);
					this.setState(({ block: false, error: errorMessage }));
					return;
				}
				if (data.response && data.response.length > 0) {
					handleNotification(data, <FormattedMessage id="HotelDetails.HotelCreated" />, <FormattedMessage id="General.Success" />);
					this.props.updateTable(data.response[0]);
				}
				this.setState(({ block: false }));
			}
		}, `/api/hotel/v1/Hotel`, JSON.stringify({ request: body }));
	}
	
	resendToPMS = () => {
		this.setState(({ block: true }));

		const body = {
			pullReservations: this.state.pullReservations,
			sendAvailability: this.state.sendAvailability,
			scanConfigChanges: this.state.scanConfigChanges
		}

		postAPI(result => {
			const { data, error } = result;
			var errorMessage = [];

			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });

				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0) {
					handleNotification(data);
					this.setState(({ block: false, error: errorMessage }));
					return;
				}
				else {
					handleNotification(data, <FormattedMessage id="HotelDetails.DataSentToPMS" />, <FormattedMessage id="General.Success" />);
				}
				this.setState(({ block: false }));
			}
		}, `/api/hotel/v1/pmsInvokeAction/${this.state.hotel.hotelId}`, JSON.stringify( body ));
	}

	toggleModal = (modal) => {
		this.setState(prevState => ({
			[modal]: !prevState[modal]
		}));
	}

	handleSwitch = (evt) => {
		const { name, checked } = evt.target;

		this.setState({ [name]: checked });
	}

	setHotelStatus() {
		const { hotel } = this.state;
		const status = !hotel.active;

		this.setState({ block: true });
		putAPI(result => {
			const { data, error } = result;
			const errorMessage = [];
			if (error) {
				errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
				this.setState({ error: errorMessage, block: false });
				return;
			}
			if (data) {
				if (data.errors && data.errors.length > 0) {
					handleNotification(data);
				}
				if (data.response && data.response.length > 0) {
					handleNotification(data, <FormattedMessage id="PropertySetup.SetStatusSuccessMessage" />, <FormattedMessage id="General.Success" />);
					hotel.active = status;
					this.setState({ hotel }, () => this.props.updateTable(this.state.hotel));
				}
			}
			this.setState({ error: errorMessage, block: false });
		}, `/api/hotel/v1/HotelStatus?hotelId=${hotel.hotelId}&active=${status}`);
	}

	render() {
		const { hotel } = this.state;

		return (
			<div>
				<Modal isOpen={this.props.modal} toggle={this.props.toggle} className="modal-lg">
					<ModalHeader toggle={this.props.toggle}>
						{this.props.modalType === 'edit' ? <FormattedMessage id="HotelDetails.EditHotel" /> : <FormattedMessage id="HotelDetails.CreateHotel" />}
					</ModalHeader>

					<ModalBody>
						<BlockUi tag="div" blocking={this.state.block}>
							<Form onSubmit={this.props.modalType === "edit" ? this.updateHotel : this.createHotel}>
								<Row className="bg-white mb-2">
									<Col>
										<ActiveInactiveStatusBadge status={hotel.active} />
									</Col>
									<Col className="text-right">
										{hotel.apiEndPoint && hotel.pmsId ?
											<span>
												<Button className="btn btn-host btn-sm mr-1" id="resendAvailability" onClick={() => this.toggleModal('resendAvailability')}>
													<i className="far fa-calendar-check" />
												</Button>
												<CustomToolTip placement="bottom" target="resendAvailability">
													<FormattedMessage id="HotelDetails.ResendAvailability" />
												</CustomToolTip>
											</span>
										: ''}

										<Authorization
											perform="hotelDetail:setHotelStatus"
											yes={() => (
												<span>
													<Button className="btn btn-host btn-sm mr-1" id="DisableHotelTooltip" onClick={this.setHotelStatus}>
														<i className="fas fa-power-off" />
													</Button>
													<CustomToolTip placement="bottom" target="DisableHotelTooltip">
														{
															hotel.active ?
																<FormattedMessage id="HotelConfigForm.DisableHotel" />
																:
																<FormattedMessage id="HotelConfigForm.EnableHotel" />
														}
													</CustomToolTip>
												</span>
											)}
											no={() => <div></div>}
										/>
										<Authorization
											perform="configAndAvailability:resendToPMS"
											yes={() => (
												<>
													<Button className="btn btn-host btn-sm mr-1" onClick={() => this.toggleModal('resendDataModal')} id="resendDataToPMS">
														<span className="fas fa-share" />
													</Button>

													<CustomToolTip placement="bottom" target="resendDataToPMS">
														<FormattedMessage id="HotelDetails.ResendDataToPMS" />
													</CustomToolTip>
												</>
											)}
											no={() => <div></div>}
										/>
										<Authorization
											perform="hotelDetails:save"
											yes={() => (
												<Button type="submit" className="btn btn-sm btn-host" >
													<i className="fas fa-save" />
												</Button>
											)}
											no={() => <div></div>}
										/>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col>
										<StyledCard block={this.state.block} error={this.state.error} icon="far fa-address-card" help={'TODO'} >
											<FormGroup row>
												<Label for="hotelId" sm={2}><FormattedMessage id="HotelDetails.Id" /></Label>
												<Col sm={4}>
													<Input
														type="text"
														name="hotelId"
														id="hotelId"
														value={this.state.hotel ? this.state.hotel.hotelId : ''}
														onChange={e => this.handleChange(e)}
														required
														disabled={this.props.modalType === "edit"}
													/>
												</Col>

												<Label for="hotelGroupId" sm={2}><FormattedMessage id="HotelDetails.HotelGroup" /> </Label>
												<Col sm={4}>
													<CustomSelect
														options={this.state.hotelGroups}
														required
														isClearable
														isMulti={false}
														value={this.state.hotelGroups.find(group => group.value === this.state.hotel.hotelGroupId)}
														isSearchable
														onChange={this.handleChangeSelect.bind(this, 'hotelGroupId')}
														isDisabled={this.props.modalType === "edit"}
														placeholder={<FormattedMessage id="HotelDetails.HotelGroup" />}
													/>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label for="name1" sm={2}><FormattedMessage id="HotelDetails.Name1" /></Label>
												<Col sm={4}>
													<Input
														type="text"
														name="name1"
														value={this.state.hotel ? this.state.hotel.name1 : ''}
														onChange={e => this.handleChange(e)}
														maxLength="100"
														required
													/>
												</Col>

												<Label for="name2" sm={2}><FormattedMessage id="HotelDetails.Name2" /> </Label>
												<Col sm={4}>
													<Input
														type="text"
														name="name2"
														maxLength="50"
														value={this.state.hotel ? this.state.hotel.name2 : ''}
														onChange={e => this.handleChange(e)}
													/>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label for="fiscalNumber" sm={2}><FormattedMessage id="HotelDetails.FiscalNumber" /> </Label>
												<Col sm={4}>
													<Input
														type="text"
														name="fiscalNumber"
														value={this.state.hotel ? this.state.hotel.fiscalNumber : ''}
														onChange={e => this.handleChange(e)}
														maxLength="20"
														required
													/>
												</Col>
												<Label for="defaultCultureCode" sm={2}><FormattedMessage id="HotelConfigForm.CultureCode" /> </Label>
												<Col sm={4}>
													<CustomSelect
														isSearchable isClearable
														placeholder={<FormattedMessage id="HotelConfigForm.CultureCode" />}
														options={getLanguages()}
														onChange={this.handleChangeSelect.bind(this, 'defaultCultureCode')}
														value={this.state.hotel ? getLanguages().find(el => el.value === this.state.hotel.defaultCultureCode) : ''}
													/>
												</Col>
											</FormGroup>
										</StyledCard>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col>
										<StyledCard block={this.state.block} icon="fas fa-at" help={'TODO'}>
											<FormGroup row>
												<Label for="phone" sm={2}><FormattedMessage id="HotelDetails.Phone" /></Label>
												<Col sm={4}>
													<Input
														type="text"
														name="phone"
														bsSize="sm"
														value={this.state.hotel ? this.state.hotel.phone : ''}
														onChange={e => this.handleChange(e)}
														maxLength="50"
													/>
												</Col>

												<Label for="email" sm={2}><FormattedMessage id="HotelDetails.Email" /></Label>
												<Col sm={4}>
													<Input
														type="text"
														name="email"
														bsSize="sm"
														value={this.state.hotel ? this.state.hotel.email : ''}
														onChange={e => this.handleChange(e)}
														maxLength="100"
													/>
												</Col>
											</FormGroup>

											<FormGroup row>
												<Label for="currencyCode" sm={2}><FormattedMessage id="HotelDetails.CurrencyCode" /> </Label>
												<Col sm={4}>
													<CustomSelect
														icon={"fas fa-coins"}
														options={getCurrencyCode()}
														required={true}
														isClearable
														isMulti={false}
														value={getCurrencyCode().find(currencyCode => currencyCode.value === this.state.hotel.defaultCurrencyCode)}
														isSearchable
														onChange={this.handleChangeSelect.bind(this, 'defaultCurrencyCode')}
														placeholder={<FormattedMessage id="HotelDetails.CurrencyCode" />}
													/>
												</Col>

												<Label for="webSite" sm={2}><FormattedMessage id="HotelDetails.WebSite" /></Label>
												<Col sm={4}>
													<Input
														type="text"
														name="webSite"
														value={this.state.hotel && this.state.hotel.webSite ? this.state.hotel.webSite : ''}
														onChange={e => this.handleChange(e)}
														maxLength="500"
													/>
												</Col>
											</FormGroup>
										</StyledCard>
									</Col>
								</Row>
								<Row className="mb-2">
									<Col>
										<StyledCard block={this.state.block} icon="far fa-map" help={'TODO'} >
											<FormGroup row>
												<Label for="contact" sm={2}><FormattedMessage id="HotelDetails.Contact" /></Label>
												<Col sm={10}>
													<Input
														type="text"
														name="contact"
														value={this.state.hotel ? this.state.hotel.contact : ''}
														onChange={e => this.handleChange(e)}
														maxLength="50"
													/>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label for="address1" sm={2}><FormattedMessage id="HotelDetails.Address1" /></Label>
												<Col sm={10}>
													<Input
														type="text"
														name="address1"
														value={this.state.hotel ? this.state.hotel.address1 : ''}
														onChange={e => this.handleChange(e)}
														maxLength="50"
													/>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label for="address2" sm={2}><FormattedMessage id="HotelDetails.Address2" /></Label>
												<Col sm={10}>
													<Input
														type="text"
														name="address2"
														value={this.state.hotel ? this.state.hotel.address2 : ''}
														onChange={e => this.handleChange(e)}
														maxLength="50"
													/>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label for="city" sm={2}><FormattedMessage id="HotelDetails.City" /></Label>
												<Col sm={4}>
													<Input
														type="text"
														name="city"
														value={this.state.hotel ? this.state.hotel.city : ''}
														onChange={e => this.handleChange(e)}
														maxLength="50"
													/>
												</Col>
											</FormGroup>
											<FormGroup row>
												<Label for="zipCode" sm={2}><FormattedMessage id="HotelDetails.ZipCode" /></Label>
												<Col sm={4}>
													<Input
														type="text"
														name="zipCode"
														value={this.state.hotel ? this.state.hotel.zipCode : ''}
														onChange={e => this.handleChange(e)}
														maxLength="10"
													/>
												</Col>

												<Label for="country" sm={2}><FormattedMessage id="HotelDetails.Country" /></Label>
												<Col sm={4}>
													<CustomSelect
														icon={"fas fa-globe"}
														options={countryList().getData()}
														required={true}
														isClearable
														isMulti={false}
														value={countryList().getData().find(countryCode => countryCode.value === this.state.hotel.countryCode)}
														isSearchable
														onChange={this.handleChangeSelect.bind(this, 'countryCode')}
														placeholder={<FormattedMessage id="HotelDetails.Country" />}
													/>
												</Col>
											</FormGroup>
										</StyledCard>
									</Col>
								</Row>
							</Form>
						</BlockUi>
					</ModalBody>
				</Modal>

				<Modal isOpen={this.state.resendDataModal} toggle={() => this.toggleModal('resendDataModal')} size={"md"} style={{ minWidth: 0 }}>
					<ModalHeader toggle={() => this.toggleModal('resendDataModal')}>
						<FormattedMessage id="HotelDetails.ResendDataToPMS" />
					</ModalHeader>
					<ModalBody>
						<BlockUi tag="div" blocking={this.state.block}>
							<Form>
								<Row className="bg-white mb-3">
									<Col className="text-right">
										<Button className="btn btn-host btn-sm mr-4" onClick={this.resendToPMS}>
											<span className="fas fa-save" />
										</Button>
									</Col>
								</Row>
								<FormGroup row>
									<Label for="pullReservations" sm={10}>
										<FormattedMessage id="HotelDetails.PullReservations" />
									</Label>
									<Col sm={2} className="d-flex align-items-center">
										<InputGroup size="sm">
											<CustomInput
												name="pullReservations"
												type="switch"
												id="pullReservations"
												checked={this.state.pullReservations}
												onChange={(e) => this.handleSwitch(e)}
											>
											</CustomInput>
										</InputGroup>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="sendAvailability" sm={10}>
										<FormattedMessage id="HotelDetails.SendAvailability" />
									</Label>
									<Col sm={2} className="d-flex align-items-center">
										<InputGroup size="sm">
											<CustomInput
												name="sendAvailability"
												type="switch"
												id="sendAvailability"
												checked={this.state.sendAvailability}
												onChange={(e) => this.handleSwitch(e)}
											>
											</CustomInput>
										</InputGroup>
									</Col>
								</FormGroup>
								<FormGroup row>
									<Label for="scanConfigChanges" sm={10}>
										<FormattedMessage id="HotelDetails.ScanConfigChanges" />
									</Label>
									<Col sm={2} className="d-flex align-items-center">
										<InputGroup size="sm">
											<CustomInput
												name="scanConfigChanges"
												type="switch"
												id="scanConfigChanges"
												checked={this.state.scanConfigChanges}
												onChange={(e) => this.handleSwitch(e)}
											>
											</CustomInput>
										</InputGroup>
									</Col>
								</FormGroup>
							</Form>
						</BlockUi>
					</ModalBody>
				</Modal>

				{this.state.resendAvailability ?
					<ResendAvailability
						modal={this.state.resendAvailability}
						toggleModal={() => this.toggleModal('resendAvailability')}
						hotelId={hotel.hotelId}
					/>
				: ''}
			</div>
		);
	}
}

export default injectIntl(HotelDetails);