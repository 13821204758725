import React, { Component } from 'react';
import { Button, Card, Form, Col, Modal, ModalBody, ModalHeader, Row, Input, Label, FormGroup } from 'reactstrap';
import { deleteAPI, getAPI, postAPI, putAPI } from "../../../Base/API";
import { StyledCard, } from "../../../Base/Common/CommonUIComponents";
import { FormattedMessage, injectIntl } from 'react-intl';
import { handleNotification } from '../../../Base/Notification';
import countryList from "react-select-country-list";
import moment from 'moment';
import BlockUi from 'react-block-ui';
import { ErrorAlert } from '../../../Common/ErrorAlert';
import CustomSelect from '../../../Base/Common/CustomSelect';
import { DateRangePicker } from 'react-dates';
import { getMonths } from '../../../Base/Common/ReferenceDataFunctions';

export class CityTaxModal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            block: false,
            error: null,
            cityTaxConfig: this.props.selectedConfig || { cityTaxDetails: [{}] }
        }
    }

    componentDidMount() {
    }

    saveConfig = (e) => {
        e.preventDefault();
        this.setState(({ block: true }));

        const body = { ...this.state.cityTaxConfig };


        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ block: false, error: errorMessage });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                }
                else if (data.response) {
                    handleNotification(data, <FormattedMessage id="CityTaxConfig.ConfigSaved" />, <FormattedMessage id="General.Success" />);

                    this.props.updateTable(data.response[0]);
                    this.setState(({ block: false, error: errorMessage }));
                }
            }
            else {
                this.setState(({ block: false, error: errorMessage }));
            }
        }, '/api/hotel/v1/CreateCityTaxes', JSON.stringify({ request: body }));
    }

    handleSelect = (name, combo) => {
        this.setState(prevState => ({
            cityTaxConfig: {
                ...prevState.cityTaxConfig,
                [name]: combo && combo.value
            }
        }));
    }

    handleChange = (evt) => {
        const { name, value } = evt.target;

        this.setState(prevState => ({
            cityTaxConfig: {
                ...prevState.cityTaxConfig,
                [name]: value
            }
        }));
    }

    handleInt = (evt) => {
        const { name, value } = evt.target;

        const targetValue = value ? parseInt(value) : null;

        this.setState(prevState => ({
            cityTaxConfig: {
                ...prevState.cityTaxConfig,
                [name]: targetValue
            }
        }));
    }

    handleDates = (startDate, endDate) => {
        const { cityTaxConfig } = this.state;

        if (startDate) {
            cityTaxConfig.dateFrom = startDate.format("YYYY-MM-DD");
        }
        if (endDate) {
            cityTaxConfig.dateTo = endDate.format("YYYY-MM-DD");
        }

        this.setState({ cityTaxConfig });
    }

    addPeriod = () => {
        const { cityTaxConfig } = this.state;

        cityTaxConfig.cityTaxDetails.push({ });

        this.setState({ cityTaxConfig });
    }

    removePeriod = (index) => {
        const { cityTaxConfig } = this.state;

        cityTaxConfig.cityTaxDetails.splice(index, 1);

        this.setState({ cityTaxConfig });
    }

    handleChangePeriod = (name, value, index) => {
        const { cityTaxConfig } = this.state;
        const targetValue = value ? parseInt(value) : null;

        cityTaxConfig.cityTaxDetails[index][name] = targetValue;

        this.setState({ cityTaxConfig });
    }

    render() {
        const { block, error, cityTaxConfig } = this.state;

        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal}>
                <ModalBody className="p-0">
                    <BlockUi tag="div" blocking={block}>
                        <ErrorAlert error={error} />
                        <Form onSubmit={this.saveConfig}>
                            <Card className="shadow border-0" body>
                                <Row className="mb-3">
                                    <Col>
                                        <h5>{cityTaxConfig.id ? <FormattedMessage id="CityTaxConfig.EditConfig" /> : <FormattedMessage id="CityTaxConfig.CreateConfig" />} </h5>
                                    </Col>
                                    <Col className="text-right">
                                        <Button type="submit" className="btn btn-sm btn-host float-right">
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>

                                <FormGroup row>
                                    <Label sm={2}>
                                        <FormattedMessage id="CityTaxConfig.Country" />
                                    </Label>
                                    <Col sm={4}>
                                        <CustomSelect
                                            options={countryList().getData()}
                                            onChange={(combo) => this.handleSelect("country", combo)}
                                            placeholder={this.props.intl.formatMessage({ id: "CityTaxConfig.Country" })}
                                            value={countryList().getData().find(el => el.value === cityTaxConfig.country) || ''}
                                            isClearable
                                            required
                                        />
                                    </Col>
                                    <Label sm={2}>
                                        <FormattedMessage id="CityTaxConfig.City" />
                                    </Label>
                                    <Col sm={4}>
                                        <Input
                                            type="text"
                                            name="city"
                                            required
                                            maxLength={50}
                                            onChange={this.handleChange}
                                            value={cityTaxConfig.city || ''}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={2}>
                                        <FormattedMessage id="CityTaxConfig.MaxNights" />
                                    </Label>
                                    <Col sm={4}>
                                        <Input
                                            type="number"
                                            name="maxNights"
                                            required
                                            minLength={0}
                                            onChange={this.handleInt}
                                            value={cityTaxConfig.maxNights || ''}
                                        />
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        Cobrar nas idades compreendidas entre
                                        <Input
                                            className="mx-2"
                                            style={{ width: '50px' }}
                                            type="number"
                                            name="minAge"
                                            required
                                            minLength={1}
                                            maxLength={150}
                                            onChange={this.handleInt}
                                            value={cityTaxConfig.minAge || ''}
                                        />
                                        e
                                        <Input
                                            className="mx-2"
                                            style={{ width: '50px' }}
                                            type="number"
                                            name="maxAge"
                                            required
                                            minLength={cityTaxConfig.minAge || 1}
                                            maxLength={150}
                                            onChange={this.handleInt}
                                            value={cityTaxConfig.maxAge || ''}
                                        />
                                    </Col>
                                </FormGroup>


                                <FormGroup row>
                                    <Label sm={2}>
                                        <FormattedMessage id="CityTaxConfig.Validity" />
                                    </Label>
                                    <Col sm={4}>
                                        <DateRangePicker
                                            startDate={cityTaxConfig.dateFrom ? moment(cityTaxConfig.dateFrom) : null}
                                            startDateId="dateFrom"
                                            isOutsideRange={day => day <= moment().subtract(1, 'day')}
                                            endDate={cityTaxConfig.dateTo ? moment(cityTaxConfig.dateTo) : null}
                                            endDateId="dateTo"
                                            onDatesChange={({ startDate, endDate }) => this.handleDates(startDate, endDate)}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                                            small={true}
                                            numberOfMonths={2}
                                            showDefaultInputIcon={true}
                                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                                        />
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        Desconto nas idades compreendidadas entre
                                        <Input
                                            className="mx-2"
                                            style={{ width: '50px' }}
                                            type="number"
                                            name="descMinAge"
                                            min={1}
                                            max={cityTaxConfig.descMaxAge || cityTaxConfig.minAge || 1}
                                            onChange={this.handleInt}
                                            value={cityTaxConfig.descMinAge || ''}
                                        />
                                        e
                                        <Input
                                            className="mx-2"
                                            style={{ width: '50px' }}
                                            type="number"
                                            name="descMaxAge"
                                            min={cityTaxConfig.descMinAge}
                                            max={cityTaxConfig.maxAge || 1}
                                            onChange={this.handleInt}
                                            value={cityTaxConfig.descMaxAge || ''}
                                        />
                                    </Col>
                                </FormGroup>


                                <Row className="mt-3 border-bottom pb-2 align-items-center">
                                    <Col>
                                        <b> <FormattedMessage id="CityTaxConfig.Periods" /></b>
                                    </Col>
                                    <Col className="text-right">
                                        <Button color="btn btn-sm btn-host " onClick={this.addPeriod}>
                                            <i className="fas fa-plus" />
                                        </Button>
                                    </Col>
                                </Row>


                                <FormGroup row className="text-muted my-2">
                                    <Col sm={4}> <FormattedMessage id="CityTaxConfig.From" /> </Col>
                                    <Col sm={4}> <FormattedMessage id="CityTaxConfig.To" /> </Col>
                                    <Col sm={2}> <FormattedMessage id="CityTaxConfig.Amount" /> </Col>
                                    <Col className="col-2 text-center"> <FormattedMessage id="CityTaxConfig.Remove" /> </Col>
                                </FormGroup>

                                {cityTaxConfig.cityTaxDetails?.map((period, key) =>
                                    <Row key={key} className="overbglight py-2">
                                        <Col className="d-flex col-4">
                                            <Input
                                                type="number"
                                                name="fromDay"
                                                value={period.fromDay || ''}
                                                onChange={(e) => this.handleChangePeriod("fromDay", e.target?.value, key)}
                                                placeholder={this.props.intl.formatMessage({ id: "CityTaxConfig.Day" })}
                                                style={{ width: '50px' }}
                                                required
                                                min={1}
                                                max={period.fromMonth ? moment(`2024-${period.fromMonth}`, "YYYY-M").daysInMonth() : 31}
                                            />

                                            <div className="ml-2" style={{ width: '150px' }}>
                                                <CustomSelect
                                                    options={getMonths(this.props.intl)}
                                                    onChange={(combo) => this.handleChangePeriod("fromMonth", combo?.value, key)}
                                                    value={getMonths(this.props.intl).find(el => el.value === period.fromMonth) || ''}
                                                    placeholder={this.props.intl.formatMessage({ id: "CityTaxConfig.Month" })}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col className="d-flex col-4">
                                            <Input
                                                type="number"
                                                name="toDay"
                                                value={period.toDay || ''}
                                                onChange={(e) => this.handleChangePeriod("toDay", e.target?.value, key)}
                                                placeholder={this.props.intl.formatMessage({ id: "CityTaxConfig.Day" })}
                                                style={{ width: '50px' }}
                                                required
                                                min={1}
                                                max={period.fromMonth ? moment(`2024-${period.toMonth}`, "YYYY-M").daysInMonth() : 31}
                                            />

                                            <div className="ml-2" style={{ width: '150px' }}>
                                                <CustomSelect
                                                    style={{ width: '100px' }}
                                                    options={getMonths(this.props.intl)}
                                                    onChange={(combo) => this.handleChangePeriod("toMonth", combo?.value, key)}
                                                    value={getMonths(this.props.intl).find(el => el.value === period.toMonth) || ''}
                                                    placeholder={this.props.intl.formatMessage({ id: "CityTaxConfig.Month" })}
                                                    required
                                                />
                                            </div>
                                        </Col>
                                        <Col sm={2}>
                                            <Input
                                                type="number"
                                                min={1} max={999999999}
                                                required
                                                name="amount"
                                                style={{ width: '100px' }}
                                                value={period.amount || ''}
                                                onChange={(e) => this.handleChangePeriod("amount", e.target?.value, key)}
                                                placeholder={this.props.intl.formatMessage({ id: "CityTaxConfig.Amount" })}
                                            />
                                        </Col>
                                        <Col className="col-2 text-center">
                                            <Button color="btn btn-sm btn-host " onClick={() => this.removePeriod(key)}>
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </Card>
                        </Form>
                    </BlockUi>
                </ModalBody>
            </Modal>
        );
    }
}

export default injectIntl(CityTaxModal)