import React from 'react';
import { FormattedMessage } from 'react-intl';
import BlockUi from 'react-block-ui';
import { Editor } from "react-draft-wysiwyg";

export default function EditorText(props) {
    const defaultToolbar = {
        inline: { inDropdown: true },
        list: { inDropdown: true },
        textAlign: { inDropdown: true },
        link: { inDropdown: true },
        history: { inDropdown: true },
        fontFamily: { options: [ 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'Roboto', 'Poppins', 'Playfair Display', 'Nunito' ],}
    };
    const toolbar = props.customToolbar ? props.customToolbar : defaultToolbar;

    const getValue = () => {
        if (props?.editorState) return props?.editorState;
        return "";
    };

    return (
        <BlockUi tag="div" blocking={false}>
            {<Editor
                editorState={props.editorState}
                onEditorStateChange={props.onEditorStateChange}
                toolbar={toolbar}
                stripPastedStyles={true}
                placeholder={<FormattedMessage id="Editor.Placeholder"/>}
            />}
            {props.required ?
                <input
                    autoComplete="off"
                    style={{ opacity: 0, width: "95%", height: 0, position: 'absolute' }}
                    required
                    value={getValue()}
                />
            :''}
        </BlockUi>
    )
};
