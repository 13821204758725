import React, { Component } from 'react';
import { Row, Col, Button, Form, Card, CardBody, CardHeader, UncontrolledCollapse } from 'reactstrap';
import { getAPI } from '../../Base/API';
import { StyledCard, SelectHotel, ActiveInactiveStatusCombo, ActiveInactiveStatusBadge } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Notification";
import { FormattedMessage, injectIntl } from 'react-intl';
import { getPaymentGatewayType, getPaymentModes } from '../../Base/Common/ReferenceDataFunctions';
import CustomSelect from '../../Base/Common/CustomSelect';


class GatewaysByHotel extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            block: false,
            active: null,
            type: null,
            hotelId: null
        }
    }

    componentDidMount() {
        this.getAllGateways();
    }

    getAllGateways = (e) => {
        if (e) e.preventDefault();
        
        this.setState({ block: true });
        const { hotelId, type, active } = this.state;

        let params = `?`;
        if (hotelId) params += `hotelId=${hotelId}&`;
        if (type) params += `type=${type}&`;
        if (active !== null) params += `active=${active}&`;

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors?.length > 0) {
                    handleNotification(data);
                }

                this.setState({ gatewaysByHotel: data.response, error: errorMessage, block: false });
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/Rate/Payment/v1/payment/allGateways` + params);
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    render() {
        const { block, error, hotelId, type, active, gatewaysByHotel } = this.state;
        

        return (
            <StyledCard icon={'fas fa-credit-card'} title={'navbar.PaymentGatewaysByHotel'} error={error} block={block}>
                <Form onSubmit={this.getAllGateways}>
                    <Row className="mb-3 align-iems-center">
                        <Col>
                            <SelectHotel
                                name="hotelId"
                                icon="fas fa-hotel"
                                onChangeFunc={this.handleSelect}
                                value={hotelId || ''}
                                placeholder={<FormattedMessage id="HotelLicenses.Hotel" />}
                            />
                        </Col>
                        <Col>
                            <CustomSelect
                                options={getPaymentGatewayType()}
                                value={getPaymentGatewayType().find(opt => opt.value === type)}
                                onChange={(e) => this.handleSelect('type', e)}
                                placeholder={<FormattedMessage id="ManagePayments.GatewayType" />}
                                isClearable
                            />
                        </Col>
                        <Col>
                            <ActiveInactiveStatusCombo
                                name="active"
                                value={active === null ? '' : active}
                                onChangeFunc={this.handleSelect}
                                placeholder={<FormattedMessage id="generic.Status" />}
                                required={false}
                                isClearable
                            />
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-host btn-sm mr-2" type="submit">
                                <i className="fas fa-search" />
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {gatewaysByHotel?.length > 0 ?
                    gatewaysByHotel.map((hotel, key) =>
                        <Card key={key} body className="py-0 mb-3 border-0 shadow">
                            <CardHeader className="border-bottom-1 border-host mb-1 bg-white py-2 pl-0 pointer" id={`toggler${key}`}>
                                <Row>
                                    <Col> <i className="fas fa-hotel" /> {hotel.hotelName}</Col>
                                    <Col className="text-right" sm={2}><i className="fas fa-chevron-down" /></Col>
                                </Row>
                            </CardHeader>

                            <UncontrolledCollapse toggler={`#toggler${key}`} defaultOpen={true}>
                            <CardBody className="p-0">
                                {hotel.paymentGateways?.map((gateway, index) => {
                                    const gatewayType = getPaymentGatewayType().find(el => el.value === gateway.gatewayType);
                                    
                                    if (gatewayType && typeof gatewayType.logo !== 'string') {
                                        gatewayType.logo = gateway.gatewayType === 'BankTransfer' ? <i className="fas fa-exchange-alt mr-2 ml-1" /> : <i className="far fa-credit-card mr-2 ml-1" />;
                                    }

                                    return (
                                        <div className="p-3 overbglight align-items-center border-bottom" key={index}>
                                            <Row className="align-items-center">
                                                <Col>
                                                    {gatewayType ? typeof gatewayType?.logo === 'string' ? <img height="25" className="pr-1" src={gatewayType.logo} /> : gatewayType.logo : ''}
                                                    <b>{gatewayType?.label || gateway.gatewayType}</b>
                                                </Col>
                                                <Col className="text-right col-2">
                                                    <ActiveInactiveStatusBadge status={gateway.active} />
                                                </Col>
                                            </Row>
                                            <Row className="ml-2 mt-2">
                                                {gateway.gatewayType === 'Stripe' ?
                                                    <Col>
                                                        <div className="title-sm"><FormattedMessage id="PaymentGateway.StripeUserId" /></div>
                                                        {gateway.userId || '-'}
                                                    </Col>
                                                    : gateway.gatewayType === 'BankTransfer' ?
                                                        <>
                                                            <Col>
                                                                <div className="title-sm"><FormattedMessage id="PaymentGateway.AccountName" /></div>
                                                                {gateway.accountName || '-'}
                                                            </Col>
                                                            <Col>
                                                                <div className="title-sm"><FormattedMessage id="PaymentGateway.Iban" /></div>
                                                                {gateway.iban || '-'}
                                                            </Col>
                                                            <Col>
                                                                <div className="title-sm"><FormattedMessage id="PaymentGateway.Swift" /></div>
                                                                {gateway.swift || '-'}
                                                            </Col>
                                                            <Col>
                                                                <div className="title-sm"><FormattedMessage id="PaymentGateway.BankTransferValidaty" /></div>
                                                                {gateway.mbValidatyDays || '-'}
                                                            </Col>
                                                        </>
                                                        : gateway.gatewayType === 'Unicre' ? 
                                                            <>
                                                                <Col>
                                                                    <div className="title-sm"><FormattedMessage id="PaymentGateway.EntityId" /></div>
                                                                    {gateway.userId || '-'}
                                                                </Col>
                                                                <Col>
                                                                    <div className="title-sm"><FormattedMessage id="PaymentGateway.AuthToken" /></div>
                                                                    {gateway.accessToken || '-'}
                                                                </Col>
                                                                <Col>
                                                                    <div className="title-sm"><FormattedMessage id="PaymentGateway.SecretId" /></div>
                                                                    {gateway.refreshToken || '-'}
                                                                </Col>
                                                                <Col>
                                                                    <div className="title-sm"><FormattedMessage id="PaymentGateway.MbEntity" /></div>
                                                                    {gateway.mbEntity || '-'}
                                                                </Col>
                                                                <Col>
                                                                    <div className="title-sm"><FormattedMessage id="PaymentGateway.MbValidityHours" /></div>
                                                                    {gateway.mbValidatyDays || '-'}
                                                                </Col>
                                                                <Col>
                                                                    <div className="title-sm"><FormattedMessage id="PaymentGateway.Provider" /></div>
                                                                    {gateway.provider || '-'}
                                                                </Col>
                                                                <Col>
                                                                    <div className="title-sm"><FormattedMessage id="PaymentGateway.DaysBeforeCheckIn" /></div>
                                                                    {gateway.daysBeforeCheckIn || '-'}
                                                                </Col>
                                                            </>
                                                            : gateway.gatewayType === 'Paybyrd' ?
                                                                <Col>
                                                                    <div className="title-sm"><FormattedMessage id="PaymentGateway.ApiKey" /></div>
                                                                    {gateway.accessToken || '-'}
                                                                </Col>
                                                                /*: gateway.gatewayType === 'Custom' ?
                                                                    <>
                                                                        <Col>
                                                                            <div className="title-sm"><FormattedMessage id="CommonUIComponents.Button" /></div>
                                                                            {gateway.paymentGatewayMultilanguals[0].buttonDesc || '-'}
                                                                        </Col>
                                                                        <Col>
                                                                            <div className="title-sm"><FormattedMessage id="CommonUIComponents.Payment" /></div>
                                                                            {gateway.paymentGatewayMultilanguals[0].paymentDesc || '-'}
                                                                        </Col>
                                                                    </>*/
                                                                    : gateway.gatewayType === 'IfThenPay' ?
                                                                        <>
                                                                            <Col>
                                                                                <div className="title-sm"><FormattedMessage id="PaymentGateway.BackOfficeKey" /></div>
                                                                                {gateway.accessToken || '-'}
                                                                            </Col>
                                                                            <Col>
                                                                                <div className="title-sm"><FormattedMessage id="PaymentGateway.CcKey" /></div>
                                                                                {gateway.ccKey || '-'}
                                                                            </Col>
                                                                            <Col>
                                                                                <div className="title-sm"><FormattedMessage id="PaymentGateway.MbwayKey" /></div>
                                                                                {gateway.mbwayKey || '-'}
                                                                            </Col>
                                                                            <Col>
                                                                                <div className="title-sm"><FormattedMessage id="PaymentGateway.MbKey" /></div>
                                                                                {gateway.mbKey || '-'}
                                                                            </Col>
                                                                            <Col>
                                                                                <div className="title-sm"><FormattedMessage id="PaymentGateway.MbValidityDays" /></div>
                                                                                {gateway.mbValidatyDays || '-'}
                                                                            </Col>
                                                                            <Col>
                                                                                <div className="title-sm"><FormattedMessage id="PaymentGateway.DaysBeforeCheckIn" /></div>
                                                                                {gateway.daysBeforeCheckIn || '-'}
                                                                            </Col>
                                                                        </>
                                                                        : gateway.gatewayType === 'Redsys' ?
                                                                            <>
                                                                                <Col>
                                                                                    <div className="title-sm"><FormattedMessage id="PaymentGateway.EntityId" /></div>
                                                                                    {gateway.userId || '-'}
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className="title-sm"><FormattedMessage id="PaymentGateway.Key" /></div>
                                                                                    {gateway.accessToken || '-'}
                                                                                </Col>
                                                                                <Col>
                                                                                    <div className="title-sm"><FormattedMessage id="PaymentGateway.Terminal" /></div>
                                                                                    {gateway.ccKey || '-'}
                                                                                </Col>
                                                                            </>
                                                                            : gateway.gatewayType === 'Sibs' ?
                                                                                <>
                                                                                    <Col>
                                                                                        <div className="title-sm"><FormattedMessage id="PaymentGateway.Terminal" /></div>
                                                                                        {gateway.userId || '-'}
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <div className="title-sm"><FormattedMessage id="PaymentGateway.AuthToken" /></div>
                                                                                        {gateway.accessToken || '-'}
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <div className="title-sm"><FormattedMessage id="PaymentGateway.ClientId" /></div>
                                                                                        {gateway.refreshToken || '-'}
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <div className="title-sm"><FormattedMessage id="PaymentGateway.SecretId" /></div>
                                                                                        {gateway.publishableKey || '-'}
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <div className="title-sm"><FormattedMessage id="PaymentGateway.MbEntity" /></div>
                                                                                        {gateway.mbEntity || '-'}
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <div className="title-sm"><FormattedMessage id="PaymentGateway.MbValidityHours" /></div>
                                                                                        {gateway.mbValidatyDays || '-'}
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <div className="title-sm"><FormattedMessage id="PaymentGateway.DaysBeforeCheckIn" /></div>
                                                                                        {gateway.daysBeforeCheckIn || '-'}
                                                                                    </Col>
                                                                                </>
                                                                                : gateway.gatewayType === 'HeyCard' ?
                                                                                        <Col>
                                                                                            <div className="title-sm"><FormattedMessage id="PaymentGateway.ApiKey" /></div>
                                                                                            {gateway.accessToken || '-'}
                                                                                        </Col>
                                                                                    : ''}

                                                {gateway.paymentGatewayMultilanguals?.length > 0 ? 
                                                    <>
                                                        {gateway.paymentGatewayMultilanguals[0].buttonDesc &&
                                                            <Col>
                                                                <div className="title-sm"><FormattedMessage id="CommonUIComponents.Button" /></div>
                                                                {gateway.paymentGatewayMultilanguals[0].buttonDesc || '-'}
                                                            </Col>
                                                        }

                                                        {gateway.paymentGatewayMultilanguals[0].paymentDesc &&
                                                            <Col>
                                                                <div className="title-sm"><FormattedMessage id="CommonUIComponents.Payment" /></div>
                                                                <div dangerouslySetInnerHTML={{ __html: gateway.paymentGatewayMultilanguals[0].paymentDesc }} />
                                                            </Col>
                                                        }
                                                    </>
                                                : ''}

                                                {gateway.validPaymentModes ?
                                                    <Col>
                                                        <div className="title-sm"><FormattedMessage id="PaymentGateway.ValidPaymentModes" /></div>
                                                        {gateway.validPaymentModes?.map((el, i) => <span key={i} className="mr-3">{getPaymentModes().find(pm => pm.value === el)?.img(20)} </span>) || ''}
                                                    </Col>
                                                : ''}
                                            </Row>
                                        </div>
                                    )
                                })}
                                </CardBody>
                            </UncontrolledCollapse>
                        </Card>
                    )
                : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(GatewaysByHotel);