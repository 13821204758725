import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ActiveInactiveStatusBadge } from "../../../Base/Common/CommonUIComponents";
import { Col, Row, Card } from 'reactstrap';


class CompareRoomRate extends Component {

    render() {
        const { allMappings } = this.props;

        return (
            allMappings.map((mapping, key) =>
                <Row key={key} className="mb-4">
                    <Col>
                        {mapping.pms ?
                            <Card className="h-100 border-0 shadow p-3">
                                <Row className="mb-2">
                                    <Col><b>{mapping.pms.rateCodeName}</b></Col>
                                    <Col className="col-2 text-right"> <ActiveInactiveStatusBadge status={mapping.pms.active} /> </Col>
                                </Row>

                                <Row>
                                    <Col sm={6}><b> <FormattedMessage id="CheckMapping.Room" />:</b> </Col>
                                    <Col sm={6}><b> <FormattedMessage id="CheckMapping.Rate" />:</b></Col>
                                </Row>
                                <Row>
                                    <Col sm={6}> {mapping.pms.roomTypeMapping}</Col>
                                    <Col sm={6}> {mapping.pms.rateCodeMapping}</Col>
                                </Row>
                            </Card>
                            : ''}
                    </Col>
                    <Col className="col-1 d-flex align-items-center justify-content-center">
                        {mapping.pms && mapping.cm ?
                            <i className="color-success fas fa-check fa-lg" style={{ color: 'green' }} />
                            : <i className="color-danger fas fa-times fa-lg" style={{ color: 'red' }} />
                        }
                    </Col>
                    <Col>
                        {mapping.cm ?
                            <Card className="h-100 border-0 shadow p-3 ">
                                <Row className="mb-2">
                                    <Col><b>{mapping.cm.rateDescription}</b></Col>
                                    <Col className="col-3 text-right"> <ActiveInactiveStatusBadge status={mapping.cm.rateActive} /> </Col>
                                </Row>

                                <Row>
                                    <Col sm={6}><b> <FormattedMessage id="CheckMapping.Room" />:</b> </Col>
                                    <Col sm={6}><b> <FormattedMessage id="CheckMapping.Rate" />:</b></Col>
                                </Row>
                                <Row>
                                    <Col sm={6}> {mapping.cm.roomCategoryCode}</Col>
                                    <Col sm={6}> {mapping.cm.rateCode}</Col>
                                </Row>
                            </Card>
                        : ''}
                    </Col>
                </Row>
            )
        );
    }
};
export default injectIntl(CompareRoomRate)