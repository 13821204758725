import React, { Component } from 'react';
import { CardBody, Col, Row } from 'reactstrap';
import moment from "moment";

export class RoomCategoryBar extends Component {

    render() {
        const { roomCategory, roomStays } = this.props;

        return (
            <div>
                <CardBody className="card-header-gridview border-0 py-0">
                    <Row className="d-flex flex-row flex-nowrap  py-0 ">
                        <Col className="col-3 position-sticky bg-white   py-1">
                            <span className="my-3 text-host" style={{ 'fontWeight': '500' }}>
                                {roomCategory.categoryDescription}
                            </span>
                        </Col>
                        <Col>
                            <Row className="flex-nowrap">
                                {
                                    roomCategory
                                        .rates[0]
                                        .rateDetails[0]
                                        .rateData
                                        .map((rData, key) => {
                                            const day = moment(rData.day).format("YYYY-MM-DD");
                                            let freeRooms = rData.freeRooms;
                                            roomStays
                                                .forEach(room => {
                                                    roomCategory.rates.forEach(rate => {
                                                        if (room.roomCode === rate.rateDetails[0].roomMappingCode && room.roomRates[0].rateCode === rate.rateDetails[0].rateMappingCode) {
                                                            room.roomRates.forEach(roomRate => {
                                                                if (moment(roomRate.effectiveDate).isSameOrBefore(moment(day)) && moment(day).isSameOrBefore(moment(roomRate.expireDate))) {
                                                                    freeRooms -= room.numberOfRooms;
                                                                }
                                                            })
                                                        }
                                                    });
                                                }
                                            );
                                            return (
                                                <Col style={{ minWidth: '60px', textAlign: 'center' }} className={` px-1 py-1 ${moment(rData.day).isoWeekday() === 6 || moment(rData.day).isoWeekday() === 7 ? `bg-light-dark` : `${rData.freeRooms <= 0 ? `bg-white` : `bg-white`}`}`} key={key}>
                                                    <div className="text-host" style={{ 'fontWeight': '500' }}>
                                                        {freeRooms}
                                                    </div>
                                                </Col>    
                                            );
                                        }
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </div>    
        );
    }
}