import React, { Component } from 'react';
import { CardBody, CardHeader, Col, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import CustomSelect from '../Base/Common/CustomSelect';

export class MajorBulkUpdateModalChooseRestrictions extends Component {

    render() {
        const { children, setComboBox, setMinimumStayLen, setMaximumStayLen, setMinAdvancedBookingOffset, setMaxAdvancedBookingOffset, stopSales, closedToArrival, closedToDeparture, minimumStayLen, maximumStayLen, minAdvancedBookingOffset, maxAdvancedBookingOffset } = this.props;

        const booleanOptions = [
            {
                value: true,
                label: <FormattedMessage id="BulkUpdate.True" />
            },
            {
                value: false,
                label: <FormattedMessage id="BulkUpdate.False" />
            }
        ];

        return (
            <div>
                <Row>
                    <Col className="col-3" />
                    <Col className="col-6">
                        <CardHeader className="bg-white pb-3">
                            <i className="fas fa-times-circle mr-2" /> <FormattedMessage id="BulkUpdate.Restrictions" />
                        </CardHeader>
                        <CardBody>
                            <input tabIndex={-1} id="ratesFormMessage" name="ratesFormMessage" autoComplete="off"
                                style={{
                                    opacity: 0,
                                    width: "100%",
                                    height: 0,
                                    position: "absolute"
                                }}
                            />
                            <input tabIndex={-1} id="priceCard" name="priceCard" autoComplete="off"
                                style={{
                                    opacity: 0,
                                    width: "100%",
                                    height: 0,
                                    position: "absolute"
                                }} />
                            <Row className="mb-1">
                                <Col>
                                    <CustomSelect id="customSelectStopSales" icon={'fas fa-times-circle'} isClearable placeholder={<FormattedMessage id="BulkUpdate.StopSales" />} options={booleanOptions} value={booleanOptions.filter(opt => opt.value === stopSales)} onChange={setComboBox.bind(this, 'stopSales')} />
                                </Col>
                            </Row>
                            <Row className="mb-1" >
                                <Col>
                                    <CustomSelect id="customSelectCloseToArrival" icon={'fas fa-walking'} isClearable placeholder={<FormattedMessage id="BulkUpdate.CloseToArrival" />} options={booleanOptions} value={booleanOptions.filter(opt => opt.value === closedToArrival)} onChange={setComboBox.bind(this, 'closedToArrival')} />
                                </Col>
                            </Row>
                            <Row className="mb-1" >
                                <Col>
                                    <CustomSelect id="customSelectCloseToDeparture" icon={'fas fa-walking fa-flip-horizontal'} isClearable placeholder={<FormattedMessage id="BulkUpdate.CloseToDeparture" />} options={booleanOptions} value={booleanOptions.filter(opt => opt.value === closedToDeparture)} onChange={setComboBox.bind(this, 'closedToDeparture')} />
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <InputGroup size="sm" >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-calendar-minus mr-2 " />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <FormattedMessage id="BulkUpdate.MinStay">{placeholder => <Input className="border-left-0" type="number" min='0' placeholder={placeholder} value={minimumStayLen ? minimumStayLen : ''} onChange={setMinimumStayLen} />}</FormattedMessage>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <InputGroup size="sm" >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-calendar-plus mr-2 " />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <FormattedMessage id="BulkUpdate.MaxStay">{placeholder => <Input className="border-left-0" type="number" min='0' placeholder={placeholder} value={maximumStayLen ? maximumStayLen : ''} max='999' onChange={setMaximumStayLen} />}</FormattedMessage>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <InputGroup size="sm" >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-clock mr-2 " />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <FormattedMessage id="BulkUpdate.MinAdvancedBookingOffset">{placeholder => <Input className="border-left-0" type="number" min='0' placeholder={placeholder} value={minAdvancedBookingOffset ? minAdvancedBookingOffset : ''} max='9999' onChange={setMinAdvancedBookingOffset} />}</FormattedMessage>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="mb-1">
                                <Col>
                                    <InputGroup size="sm" >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-clock mr-2 " />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <FormattedMessage id="BulkUpdate.MaxAdvancedBookingOffset">{placeholder => <Input className="border-left-0" type="number" min='0' placeholder={placeholder} value={maxAdvancedBookingOffset ? maxAdvancedBookingOffset : ''} max='9999' onChange={setMaxAdvancedBookingOffset} />}</FormattedMessage>
                                    </InputGroup>
                                </Col>
                            </Row>
                            {
                                children
                            }
                        </CardBody>
                    </Col>
                </Row>
            </div>    
        );
    }
}