import React, { Component } from 'react';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { getAPI } from "../Base/API";
import { ErrorAlert } from "../Common/ErrorAlert";
import { getConvertedDateToHotelTimezone } from "../Base/Common/ReferenceDataFunctions";


class ReservationLog extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            reservationLog: [],
            error: null,
            block: true
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }
    componentDidMount() {
        this._isMounted = true;
        this.submitRequest();

    }

 
    submitRequest() {
        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });


                return;
            }
            if (data) {
                this.setState({ reservationLog: data.data });
            }
        }, this.props.url);
    }


    render() {
        const { children } = this.props;
        const { reservationLog, block, error } = this.state;

        return (
            reservationLog && reservationLog.length > 0 ?
                <div className='mt-4'>
                    <BlockUi tag="div" blocking={block}>
                        <ErrorAlert error={error}/>
                        <div>
                            <h6>
                                <i className="fas fa-tasks mr-1"></i>
                                {children}
                            </h6>
                        </div>
                        <Card className="border-0 shadow mt-3" style={{ fontSize: '1em' }}>
                            <CardBody>
                                <div className='small' style={{ maxHeight: '200px', overflow: 'auto' }}>
                                    <Row className="text-muted w-100 mb-2">
                                        <Col>
                                            <FormattedMessage id="ReservationDetail.Message"/>
                                        </Col>
                                        <Col className="col-3">
                                            <FormattedMessage id="ReservationDetail.Date"/>
                                        </Col>
                                    </Row>
                                    {reservationLog.map((item, key) => {
                                        const updatedAt = item.updatedAt ? getConvertedDateToHotelTimezone(item.updatedAt, true).format("YYYY-MM-DD HH:mm:ss") : '';

                                        return <Row className="mb-1 pb-1 w-100" key={key} style={{ borderBottom: '1px solid #e9ecef' }}>
                                            <Col className="pr-0">
                                                {item.logMessage}
                                                <span className='ml-1'>
                                                    {item.isError ?
                                                        item.isError === true ?
                                                            <i className="fas fa-exclamation-triangle text-danger"/>
                                                        :
                                                        item.isError
                                                    : item.isWarning ?
                                                        item.isWarning === true ?
                                                            <i className="fas fa-exclamation-triangle text-warning"></i>
                                                        : item.isWarning
                                                    :''}
                                                </span>
                                            </Col>
                                            <Col className="col-3 px-0">{updatedAt}</Col>
                                        </Row>
                                    })}
                                </div>
                            </CardBody>
                        </Card>
                    </BlockUi>
                </div>
            :''
        );
    }
}

export default injectIntl(ReservationLog)