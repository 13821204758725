import React, { Component } from 'react';
import { Input, Form, Row, Col, Button } from 'reactstrap';
import { handleNotification } from "../../Base/Notification";
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import CustomSelect from "../../Base/Common/CustomSelect";
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SingleDatePicker } from 'react-dates';
import { getAPI, postAPI } from '../../Base/API';
import moment from "moment";

export class ImportHotelConfig extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hotelData: [],
            tableData: [],
            resultsPerPage: 10,
            channelInstance: null,
            channelInstanceList: []
        }

    }

    componentDidMount() {
        this.getChannelInstance();
    }

    getChannelInstance = () => {
        this.setState(({ block: true }));
        var name = "External Pms"

        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    data.errors.forEach(error => errorMessage.push({ message: error.message, messageType: 'danger' }));
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }

                if (data.length > 0) {
                    var list = [];

                    data.forEach((channel) => {
                        var obj = { label: 'External PMS', value: channel.id };
                        list.push(obj);
                    })
                    this.setState(({ channelInstanceList: list, channelInstance: list[0].value, block: false }));
                }
                this.setState(({ block: false }));
            }
        }, `api/Rate/ChannelConfig/v1/ChannelInstance/ChannelConfig/${name}`)
    }

    saveData = (e) => {
        e.preventDefault();
        this.setState({ block: true });
        var body = [...this.state.hotelData];

        postAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            else {
                handleNotification(
                    data,
                    <FormattedMessage id="ImportHotelConfig.SaveMessageSuccess" />,
                    <FormattedMessage id="ImportHotelConfig.Success" />
                );
                this.setState(({ block: false }));
            }
        }, `/heycm/api/ExternalPmsIntegration/ExternalPms/v1/hotelInformationTabular/${this.state.channelInstance}`, body)
    }

    handleFiles = (event) => {
        var files = event.target.files;

        if (window.FileReader) {
            this.getAsText(files[0]);
        }
        else {
            var errorMessage = [];
            errorMessage.push({ message: "FileReader are not supported in this browser.", messageType: 'danger' });
            this.setState({ error: errorMessage });
        }
    }

    getAsText = (fileToRead) => {
        var reader = new FileReader();
        
        reader.readAsText(fileToRead);

        reader.onload = (e) => {
            var csv = e.target.result;
            this.processData(csv);
        }
        reader.onerror = this.errorHandler;
    }

    processData = (csv) => {
        var allTextLines = csv.split(/\r\n|\n/);
        var lines = allTextLines.map(data => data.split(/,|;/));
        var list = [];

        if (lines.find(l => l.length !== 7)) {
            var errorMessage = [];
            errorMessage.push({ message: <FormattedMessage id="ImportHotelConfig.MustHave7Fields" />, messageType: 'danger' });
            this.setState({ error: errorMessage, block: false });
        }
        else {
            lines.forEach((line) => {
                var object = {};

                object.roomCode = line[0];
                object.roomDescription = line[1];
                object.rateCode = line[2];
                object.rateDescription = line[3];
                object.Adults = line[4];
                object.Childs = line[5];
                object.Currency = line[6];

                list.push(object);
            })

            this.setState({
                tableData: list,
                hotelData: allTextLines,
                error: null
            })
        }
    }
    
    errorHandler = (event) => {
        if (event.target.error.name === "NotReadableError") {
            var errorMessage = [];
            errorMessage.push({ message: "Cannot read file!", messageType: 'danger' });
            this.setState({ error: errorMessage });
        }
    }

    handleSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    fileChanged = (e) => {
        e.target.value = null;
    }

    render() {
        const help = <div>TODO</div>

        const columns = [
            {
                dataField: 'roomCode',
                text: <FormattedMessage id="ImportHotelConfig.roomCode" />,
                sort: true
            },
            {
                dataField: 'roomDescription',
                text: <FormattedMessage id="ImportHotelConfig.roomDescription" />,
                sort: true
            },
            {
                dataField: 'rateCode',
                text: <FormattedMessage id="ImportHotelConfig.rateCode" />,
                sort: true
            },
            {
                dataField: 'rateDescription',
                text: <FormattedMessage id="ImportHotelConfig.rateDescription" />,
                sort: true
            },
            {
                dataField: 'Adults',
                text: <FormattedMessage id="ImportHotelConfig.Adults" />,
                sort: true
            },
            {
                dataField: 'Childs',
                text: <FormattedMessage id="ImportHotelConfig.Childs" />,
                sort: true
            },
            {
                dataField: 'Currency',
                text: <FormattedMessage id="ImportHotelConfig.Currency" />,
                sort: true
            },
        ]
        
        return (
            <div>
                <StyledCard icon="fas fa-upload" block={this.state.block} error={this.state.error} help={help} title="ImportHotelConfig.Title">
                    <Form onSubmit={this.saveData}>
                        <Row className="mb-3 d-flex align-items-center">
                            <Col className="col-2">
                                <CustomSelect
                                    icon={'fas fa-tasks'}
                                    options={this.state.channelInstanceList}
                                    placeholder={<FormattedMessage id="ImportHotelConfig.ChannelInstance" />}
                                    onChange={this.handleSelect.bind(this, 'channelInstance')}
                                    value={this.state.channelInstance ? this.state.channelInstanceList.find(c => c.value === this.state.channelInstance) : ''}
                                    isClearable isSearchable required
                                />
                            </Col>
                            <Col>
                                <Input
                                    type="file"
                                    onChange={(e) => this.handleFiles(e)}
                                    accept=".csv"
                                    required
                                    onClick={(e) => this.fileChanged(e)}
                                />
                            </Col>
                            <Col className="text-right">
                                <Button type="submit" className="btn btn-sm btn-host" onClick={this.toggleModal}>
                                    <i className="fas fa-save" />
                                </Button>
                            </Col>
                         </Row>
                        <Row>
                            <Col>
                                <CustomTable
                                    data={this.state.tableData ? this.state.tableData : []}
                                    columns={columns}
                                    page={this.state.currentPage}
                                    sizePerPage={this.state.resultsPerPage}
                                    totalSize={this.state.tableData && this.state.tableData.length}
                                    showTotal={true}
                                    remote={false}
                                />
                            </Col>
                        </Row>
                    </Form>
                </StyledCard>
            </div>
        );
    }
}
export default injectIntl(ImportHotelConfig)