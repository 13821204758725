import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import classnames from 'classnames';
import  BookingReviewCard  from './BookingReviewCard';
import AirbnbReviewCard from './AirbnbReviewCard';
import Authorization from '../../Base/Authorization';
import { FormattedMessage } from 'react-intl';
import { CommonHelper } from '../../Base/Common/CommonUIComponents'
import ExpediaReviewCard from './Expedia/ExpediaReviewCard';


export class OnlineRatesDashboard extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        
        this.state = {
            activeTab: '1',
            modal: false,
            Alerts: []
        };
    }

    componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const channel = params.get('channel');
        if (channel === 'AIRBNB') {
            this.setState({ activeTab: '2' });
        }
        else if (channel === 'EXPEDIA') {
            this.setState({ activeTab: '3' });
        }
    }
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    
    render() {
        return (
            <Card>
                <Nav tabs>
                    {global.channelList.find(el => el === 'BOOKING') != undefined ?
                        <Authorization
                            perform="booking:reviews:view"
                            yes={() => (
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                                        <FormattedMessage id="OnlineRatesDashboard.BookingReviews" />
                                    </NavLink>
                                </NavItem>
                            )}
                            no={() => <div></div>}
                        />
                        : ''}
                    {global.channelList.find(el => el === 'AIRBNB') != undefined ?
                    <Authorization
                        perform="airbnb:reviews:view"
                        yes={() => (
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                    <FormattedMessage id="OnlineRatesDashboard.AirBnBReviews" />
                                </NavLink>
                            </NavItem>
                        )}
                        no={() => <div></div>}
                        />
                        : ''}

                    {global.channelList.find(el => el === 'EXPEDIA') != undefined ?
                        <Authorization
                            perform="admin:view"//"expedia:reviews:view"
                            yes={() => (
                                <NavItem>
                                    <NavLink className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                                        <FormattedMessage id="OnlineRatesDashboard.ExpediaReviews" />
                                        <i className="fas fa-exclamation-circle p-1" style={{ color: 'red' }} id="EmailReservationDashboard" />
                                    </NavLink>
                                </NavItem>
                            )}
                            no={() => <div></div>}
                            />
                    : ''}

                    <NavItem className="ml-auto border-bottom mt-2 mr-2">
                        <CommonHelper placement={global.isMobile ? 'bottom' : 'left'}  help={<FormattedMessage id="OnlineRatesDashboard.Help" />} id={'onlineratesdashboardhelp'} /> 
                    </NavItem>

                            
                       
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    {global.channelList.find(el => el === 'BOOKING') != undefined ?
                    <Authorization
                        perform="booking:reviews:view"
                        yes={() => (
                            <TabPane tabId="1">
                                <BookingReviewCard />
                            </TabPane>
                        )}
                        no={() => <div></div>}
                        />
                        : ''}
                    {global.channelList.find(el => el === 'AIRBNB') != undefined ?
                        <Authorization
                            perform="airbnb:reviews:view"
                            yes={() => (
                                <TabPane tabId="2">
                                    <AirbnbReviewCard />
                                </TabPane>
                            )}
                            no={() => <div></div>}
                        />
                        : ''}

                    {global.channelList.some(el => el === 'EXPEDIA') && this.state.activeTab === '3' ?
                        <Authorization
                            perform="expedia:reviews:view"
                            yes={() => (
                                <TabPane tabId="3">
                                    <ExpediaReviewCard />
                                </TabPane>
                            )}
                            no={() => <div></div>}
                        />
                        : ''}
                </TabContent>
            </Card>
        );
    }
}