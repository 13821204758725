import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { postAPI } from "../Base/API";
import { ErrorAlert } from "../Common/ErrorAlert";
import ChartCard from "./Common/ChartCard";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import moment from 'moment';

class Engagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: false,
            eventCountByEventName: [],
            viewsByPage: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.search === true && nextProps.search !== this.props.search) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({ block: true });

        const body = {
            startDate: moment(this.props.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
            dimensions: ["eventName", "unifiedScreenClass"],
            metrics: ["eventCount", "screenPageViews"],
            aggregation: []
        }

        const body2 = {
            startDate: moment(this.props.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
            dimensions: ["date"],
            metrics: ["eventCount", "screenPageViews", "activeUsers", "engagedSessions", "sessions", "userEngagementDuration"],
            aggregation: []
        }

        postAPI(result => {
            const { data, error } = result;
            
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const json = JSON.parse(data);

                let eventCountByEventName = [[this.props.intl.formatMessage({ id: "BEAnalitics.EventCount" }), this.props.intl.formatMessage({ id: "BEAnalitics.EventName" }), { role: 'style' }]];
                let viewsByPage = [[this.props.intl.formatMessage({ id: "BEAnalitics.Page" }), this.props.intl.formatMessage({ id: "BEAnalitics.Views" }), { role: 'style' }]];

                json.rows && json.rows.forEach((row) => {
                    eventCountByEventName = this.props.updateChartArray(eventCountByEventName, row, 0, 0, 'BarChart');
                    viewsByPage = this.props.updateChartArray(viewsByPage, row, 1, 1, 'BarChart');
                });

                this.setState({ eventCountByEventName, viewsByPage });
            }
        }, `/api/hotel/v1/googleanalytics`, body);


        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const json = JSON.parse(data);

                let eventCount = 0, totalEngTime = 0, totalUsers = 0;
                let avgEngTimePerDay = [[this.props.intl.formatMessage({ id: "BEAnalitics.Date" }), this.props.intl.formatMessage({ id: "BEAnalitics.AvgEngagementTimePerActiveUser" })]];
                let eventCountPerDay = [[this.props.intl.formatMessage({ id: "BEAnalitics.Date" }), this.props.intl.formatMessage({ id: "BEAnalitics.EventCount" })]];
                let avgEngTimePerSession = [[this.props.intl.formatMessage({ id: "BEAnalitics.Date" }), this.props.intl.formatMessage({ id: "BEAnalitics.AvgEngagementTimePerSession" })]];

                //Chart dates
                avgEngTimePerDay.push([moment(this.props.startDate).format('YYYY-MM-DD'), [0, 0, 0]]);
                eventCountPerDay.push([moment(this.props.startDate).format('YYYY-MM-DD'), 0]);
                avgEngTimePerSession.push([moment(this.props.startDate).format('YYYY-MM-DD'), [0, 0, 0]]);

                var currDate = moment(this.props.startDate);
                while (currDate.add(1, 'days').diff(this.props.endDate) < 0) {
                    avgEngTimePerDay.push([moment(currDate).format('YYYY-MM-DD'), [0, 0, 0]]);
                    eventCountPerDay.push([moment(currDate).format('YYYY-MM-DD'), 0]);
                    avgEngTimePerSession.push([moment(currDate).format('YYYY-MM-DD'), [0, 0, 0]]);
                }

                json.rows && json.rows.forEach((row) => {
                    avgEngTimePerDay = this.setValuesByDay(avgEngTimePerDay, row, 5, 2, true);
                    eventCountPerDay = this.setValuesByDay(eventCountPerDay, row, 0, null, false);
                    avgEngTimePerSession = this.setValuesByDay(avgEngTimePerSession, row, 5, 4, true);

                    eventCount += parseInt(row.metricValues[0].value);
                    totalEngTime += parseInt(row.metricValues[5].value);
                    totalUsers += parseInt(row.metricValues[2].value);
                });

                this.setState({ avgEngTimePerDay, eventCountPerDay, avgEngTimePerSession, eventCount, avgEngTime: (totalEngTime/totalUsers), block: false });
            }
        }, `/api/hotel/v1/googleanalytics`, body2);
    }

    setValuesByDay = (array, row, metric1, metric2, time) => {
        const index = array.findIndex(el => moment(el[0]).isSame(row.dimensionValues[0].value));

        if (index !== -1) {
            if (time) {
                const time = moment.utc(parseInt(row.metricValues[metric1].value) / parseInt(row.metricValues[metric2].value) * 1000);
                array[index][1] = [moment(time).format('HH'), moment(time).format('mm'), moment(time).format('ss'),];
            }
            else {
                array[index][1] += parseInt(row.metricValues[metric1].value);
            }
        }

        return array;
    }


    render() {
        const { block, eventCount, viewsByPage, avgEngTimePerDay, avgEngTimePerSession, avgEngTime } = this.state;
        const { valuesCard } = this.props;

        const barOptions = {
            legend: { position: "none" }
        };

        const lineOptions = {
            legend: "none",
            vAxis: {
                minValue: 0,
                format: 'HH \'h\' mm \'m\' ss \'s\''
            }
        };

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={this.state.error} />
                <Row>
                    <Col>
                        {valuesCard(<FormattedMessage id="BEAnalitics.AverageEngagementTime"/>, avgEngTime && !isNaN(avgEngTime) ? moment.utc(avgEngTime * 1000).format('mm:ss') : 0)}
                    </Col>
                    <Col>
                        {valuesCard(<FormattedMessage id="BEAnalitics.EventCount" />, eventCount)}
                    </Col>
                </Row>

                <Row className="my-3">
                    <Col>
                        {avgEngTimePerDay && avgEngTimePerDay.length > 1 ?
                            <ChartCard
                                chartType='LineChart'
                                data={avgEngTimePerDay}
                                options={lineOptions}
                                title={<FormattedMessage id="BEAnalitics.AverageEngagementTimePerUser" />}
                            />
                        :''}
                    </Col>
                </Row>
                <Row>
                    <Col className="col-6">
                        {avgEngTimePerSession && avgEngTimePerSession.length > 1 ?
                            <ChartCard
                                chartType='LineChart'
                                data={avgEngTimePerSession}
                                options={lineOptions}
                                title={<FormattedMessage id="BEAnalitics.AvgEngagementTimePerSession" />}
                            />
                        :''}
                    </Col>
                    <Col className="col-6">
                        {viewsByPage && viewsByPage.length > 1 ?
                            <ChartCard
                                chartType='ColumnChart'
                                data={viewsByPage}
                                options={barOptions}
                                title={<FormattedMessage id="BEAnalitics.ViewsByPageTitleAndScreenClass" />}
                            />
                        :''}
                    </Col>
                </Row>
            </BlockUi>
        )
    }
}

export default injectIntl(Engagement);
