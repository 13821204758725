import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Badge } from 'reactstrap';

export class BadgeWithRestriction extends Component {

    render() {
        const { configCredentials, data, el, idx, handleChangeOnConfigInfo } = this.props;
        const isFieldDisabled = configCredentials[data[idx]];

        return (
            <Badge className="mr-4 p-2" style={{ cursor: isFieldDisabled ? 'no-drop' : 'pointer' }} color={configCredentials[el] ? "primary" : "secondary"} onClick={() => { if (!isFieldDisabled) handleChangeOnConfigInfo(el) }}>
                <FormattedMessage id={`HotelLicenses.${el}`} />
            </Badge>
        );
    }
}