import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Row, CardHeader, CardBody, UncontrolledCollapse } from 'reactstrap';
import { StyledCard, SelectHotel, SelectChannelConfig, ActiveInactiveStatusBadge } from "../../Base/Common/CommonUIComponents";
import { getChannelConfig } from "../../Base/Common/ReferenceDataFunctions";
import { CustomTable } from '../../Base/Common/CustomTable';
import { DateRangePicker } from 'react-dates';
import { getAPI } from "../../Base/API";
import moment from 'moment';

class AutomatedActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            automatedActions: [],
            hotelId: null,
            startDate: moment().subtract(15, 'days'),
            endDate: moment(),
            pageIndex: 0,
            pageSize: 10,
            totalItems: 0
        }
    }

    componentDidMount() {
        this.getAutomatedActions();
    }

    getAutomatedActions = () => {
        this.setState({ block: true });
        var params = '';
        if (this.state.hotelId)
            params += '&hotelId=' + this.state.hotelId
        if (this.state.channelConfigId)
            params += '&channelId=' + this.state.channelConfigId

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ block: isLoading });
            if (error) {
                const errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ block: false, automatedActions: data.data, totalItems: data.count });
            }
        }, `/api/Rate/ChannelConfig/v1/mapping/inactive?pageSize=${this.state.pageSize}&pageIndex=${this.state.pageIndex}&fromDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.endDate).format("YYYY-MM-DD")}` + params);
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    handleTableChange = (type, { page, sizePerPage }) => {
        this.setState({
            pageIndex: page - 1,
            pageSize: sizePerPage,
        }, () => { this.getAutomatedActions() });
    }

    search = () => {
        this.setState({
            pageIndex: 0
        }, () => this.getAutomatedActions());
    }

    render() {
        const columns = [
            {
                dataField: 'hotelName',
                text: this.props.intl.formatMessage({ id: "AutomatedActions.HotelName" })
            },
            {
                dataField: 'channelInstanceId',
                text: this.props.intl.formatMessage({ id: "AutomatedActions.ChannelInstanceId" })
            },
            {
                dataField: 'name',
                text: this.props.intl.formatMessage({ id: "AutomatedActions.Name" })
            }, 
            {
                dataField: 'channelInstanceActive',
                text: this.props.intl.formatMessage({ id: "AutomatedActions.ChannelStatus" }),
                formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell}/>
            },
            {
                dataField: 'channelErrorCodes',
                text: this.props.intl.formatMessage({ id: "AutomatedActions.ChannelErrorCodes" }),
                formatter: (cell, row) => <div>{cell ? cell.join(', ') : ''}</div>
            },
            {
                dataField: 'rateCodeChannelId',
                text: this.props.intl.formatMessage({ id: "AutomatedActions.RateCodeChannelId" })
            },
            {
                dataField: 'rateCodeMapping',
                text: this.props.intl.formatMessage({ id: "AutomatedActions.RateCodeMapping" })
            },
            {
                dataField: 'rateCodeChannelActive',
                text: this.props.intl.formatMessage({ id: "AutomatedActions.RateCodeStatus" }),
                formatter: (cell, row) => <ActiveInactiveStatusBadge status={cell} />
            },
            {
                dataField: 'actionToPerform',
                text: this.props.intl.formatMessage({ id: "AutomatedActions.Action" })
            },
            {
                dataField: 'reason',
                text: this.props.intl.formatMessage({ id: "AutomatedActions.Reason" })
            }
        ];

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-robot'} title={"AutomatedActions.Title"}>
                <Row>
                    <Col>
                        <SelectHotel name={'hotelId'} icon={'fas fa-hotel'} onChangeFunc={this.handleChangeSelect} value={this.state.hotelId} placeholder={<FormattedMessage id="HotelLicenses.Hotel" />} />
                    </Col>
                    <Col>
                        <SelectChannelConfig name={'channelConfigId'} icon={'fas fa-plug'} onChangeFunc={this.handleChangeSelect} value={this.state.channelConfigId} placeholder={<FormattedMessage id="ReservationList.SelectChannel" />} />
                    </Col>
                    <Col>
                        <DateRangePicker
                            startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                            startDateId="your_unique_start_date_id_announcement"
                            isOutsideRange={() => false}
                            endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                            endDateId="your_unique_end_date_id_announcement"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col>
                        <Button className="float-right  btn-sm btn-host btn" onClick={this.search}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col className="h-100">
                        <Card className="border-0">
                            <CustomTable
                                data={this.state.automatedActions ? this.state.automatedActions : []}
                                columns={columns}
                                page={this.state.pageIndex + 1}
                                sizePerPage={this.state.pageSize}
                                totalSize={this.state.totalItems}
                                onTableChange={this.handleTableChange}
                                shadow={false}
                                search={false}
                                showTotal={true}
                                remote={true}
                            />
                        </Card>
                    </Col>
                </Row>
            </StyledCard>
        );
    }

};
export default injectIntl(AutomatedActions);