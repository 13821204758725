import React, { Component } from 'react';
import { TabContent, TabPane, NavLink, NavItem, Nav, Card } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CommonHelper } from '../../Base/Common/CommonUIComponents'
import BookingPropScore from './BookingPropScore';
import AirbnbPropScore from './AirbnbPropScore';
import classnames from 'classnames';
import { CheckAuthorization } from '../../Base/Authorization';

class PropertyScore extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);

        this.state = {
            channelinstanceList: [],
            selectedConnection: '',
            propertyScore: [],
            activeTab: '1',
            hasAirBnb: false
        }
    }

    componentDidMount() {
        window.setTimeout(
            () => {
                this.setState({ hasAirBnb: CheckAuthorization("propertyScore:airbnb") });
            },
            2500
        );
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        const { hasAirBnb } = this.state;

        return (
            <Card className="border-0">
                <Nav tabs>
                    <NavItem>
                        <NavLink className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            <FormattedMessage id="PropertyScore.BookingPropScore" />
                        </NavLink>
                    </NavItem>
                    {
                        hasAirBnb ?
                            <NavItem>
                                <NavLink className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                                    <FormattedMessage id="PropertyScore.AirbnbPropScore" />
                                </NavLink>
                            </NavItem>
                            :
                            <div />
                    }
                    <NavItem className="ml-auto border-bottom mt-2 mr-2">
                        <CommonHelper help={<FormattedMessage id="PropertyScore.Help" />} id={'PropertyScore'} />
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <BookingPropScore />
                    </TabPane>
                    <TabPane tabId="2">
                        <AirbnbPropScore />
                    </TabPane>
                </TabContent>
            </Card>
        );
    }
}

export default injectIntl(PropertyScore)