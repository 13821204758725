import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader, Row, Col, Button, Form, Label, FormGroup, Input } from 'reactstrap';
import { getAPI, postAPI, putAPI } from '../../Base/API';
import { getPaymentGatewayType } from '../../Base/Common/ReferenceDataFunctions';
import { StyledCard } from '../../Base/Common/CommonUIComponents';
import { handleNotification } from "../../Base/Notification";
import { CustomTable } from '../../Base/Common/CustomTable';
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../Base/Common/CustomSelect';
import { ErrorAlert } from "../../Common/ErrorAlert";
import BlockUi from 'react-block-ui';

class ManagePayments extends Component {
    static displayName = ManagePayments.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            block: true,
            modal: false,
            selectedData: null
        }
    }


    componentDidMount() {
        this.getHostPaymentInformation();
    }

    getHostPaymentInformation = () => {
        this.setState({ block: true });
        var param = this.state.gatewayType ? `?gatewayType=${this.state.gatewayType}` : '';

        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }

                this.setState({ keyList: data.response, block: false });
            }
        }, '/api/Rate/Payment/v1/payment/hostinformation' + param);
    }

    createHostPaymentInformation = (e) => {
        e.preventDefault();
        this.setState({ block: true });

        var body = { ...this.state.selectedData };

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                }
                if (data.response && data.response.length > 0) {
                    handleNotification(data, <FormattedMessage id="ManagePayments.HostPaymentInformationSaved" />, <FormattedMessage id="General.Success" />);
                    this.getHostPaymentInformation();
                    this.toggleModal(null);
                }

                this.setState({ block: false });
            }
        }, '/api/Rate/Payment/v1/payment/hostinformation', body);
    }

    handleSelect = (combo, name) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    }

    handleSelectModal = (combo, name) => {
        this.setState({
            selectedData: {
                ...this.state.selectedData,
                [name]: combo ? combo.value : null
            }
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            selectedData: {
                ...this.state.selectedData,
                [name]: value
            }
        })
    }

    toggleModal = (data) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedData: data
        }));
    }

    render() {

        const columns = [
            {
                dataField: 'code',
                text: <FormattedMessage id="ManagePayments.Code" />,
                sort: true
            },
            {
                dataField: 'gatewayType',
                text: <FormattedMessage id="ManagePayments.GatewayType" />,
                sort: true
            },
            {
                dataField: 'sharedKey',
                text: <FormattedMessage id="ManagePayments.SharedKey" />,
                sort: true
            },
            {
                dataField: 'clientId',
                text: <FormattedMessage id="ManagePayments.ClientId" />,
                sort: true
            }
        ];

        const selectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            bgColor: '#f8f9fa',
            clickToSelect: true,

            onSelect: (row, isSelect, rowIndex, e) => {
                this.toggleModal(row);
            }
        };


        return (
            <StyledCard icon={'fas fa-credit-card'} title={'navbar.ManagePayments'} error={this.state.error} block={this.state.block}>
                <Row className="mb-3">
                    <Col className="col-3">
                        <CustomSelect
                            isSearchable isClearable
                            placeholder={<FormattedMessage id="ManagePayments.GatewayType" />}
                            options={getPaymentGatewayType()}
                            onChange={(combo) => this.handleSelect(combo, 'gatewayType')}
                        />
                    </Col>
                    <Col className="text-right">
                        <Button className="btn btn-host btn-sm mr-2" onClick={this.getHostPaymentInformation} >
                            <i className="fas fa-search" />
                        </Button>
                        <Button className="btn btn-host btn-sm" onClick={() => this.toggleModal(null)} >
                            <i className="fas fa-plus" />
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <CustomTable
                            data={this.state.keyList ? this.state.keyList : []}
                            columns={columns}
                            shadow={false}
                            showTotal={true}
                            selectRow={selectRow}
                        />
                    </Col>
                </Row>

                {this.state.modal ?

                    <Modal isOpen={this.toggleModal} toggle={this.toggleModal}>
                        <ModalHeader toggle={this.props.toggle}>
                            {this.state.selectedData ? <FormattedMessage id="ManagePayments.EditPayment" /> : <FormattedMessage id="ManagePayments.CreatePayment" />}
                        </ModalHeader>

                        <ModalBody>
                            <BlockUi tag="div" blocking={this.state.block}>
                                <ErrorAlert error={this.state.error} />
                                <Form onSubmit={(e) => this.createHostPaymentInformation(e)}>
                                    <Row>
                                        <Col className="text-right mb-3">
                                            <Button className="btn btn-host btn-sm" type="submit" >
                                                <i className="fas fa-save" />
                                            </Button>
                                        </Col>
                                    </Row>
                                    <FormGroup row>
                                        <Label sm={2}><FormattedMessage id="ManagePayments.Code" /></Label>
                                        <Col sm={10}>
                                            <Input
                                                type="text"
                                                name="code"
                                                value={this.state.selectedData && this.state.selectedData.code ? this.state.selectedData.code : ''}
                                                onChange={e => this.handleChange(e)}
                                                required
                                                max="100"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={2}><FormattedMessage id="ManagePayments.GatewayType" /></Label>
                                        <Col sm={10}>
                                            <CustomSelect
                                                isSearchable
                                                placeholder={""}
                                                options={getPaymentGatewayType()}
                                                onChange={(combo) => this.handleSelectModal(combo, 'gatewayType')}
                                                value={this.state.selectedData && this.state.selectedData.gatewayType ? getPaymentGatewayType().find(el => el.value === this.state.selectedData.gatewayType) : ''}
                                                required
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={2}><FormattedMessage id="ManagePayments.SharedKey" /></Label>
                                        <Col sm={10}>
                                            <Input
                                                type="text"
                                                name="sharedKey"
                                                value={this.state.selectedData && this.state.selectedData.sharedKey ? this.state.selectedData.sharedKey : ''}
                                                onChange={e => this.handleChange(e)}
                                                required
                                                max="100"
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label sm={2}><FormattedMessage id="ManagePayments.ClientId" /></Label>
                                        <Col sm={10}>
                                            <Input
                                                type="text"
                                                name="clientId"
                                                name="clientId"
                                                value={this.state.selectedData && this.state.selectedData.clientId ? this.state.selectedData.clientId : ''}
                                                onChange={e => this.handleChange(e)}
                                                max="100"
                                            />
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </BlockUi>
                        </ModalBody>
                    </Modal>
                : ''}
            </StyledCard>
        );
    }
}

export default injectIntl(ManagePayments);