import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import MajorBulkUpdate from "./MajorBulkUpdate";

export class MajorBulkUpdateModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: false,
            error: null,
            startDate: moment(this.props.currentDay, 'YYYY-MM-DD'),
            endDate: moment(moment(this.props.currentDay).add(13, 'days'), 'YYYY-MM-DD'),
            roomCategories: this.props.getRoomCategoryList(this),
            channelInstances: [],
            heyBeAndGoogleChannels: []
        };
    }

    componentDidMount() {
        const { getRateList, channelinstanceList, rateCodeChannels } = this.props;
        const { roomCategories } = this.state;
        roomCategories.forEach(rc => {
            rc.rates = getRateList().filter(r => r.roomCategoryId === rc.value);
        });
        const channelInstances = channelinstanceList
            .map(ci => {
                const rcc = rateCodeChannels().filter(rcc => rcc.channelInstanceId === ci.id);
                const opt = {
                    value: ci.id,
                    label: `${ci.name}(${ci.propertyCode})`,
                    rateCodeChannels: rcc
                };
                return opt;
            })
            .filter(ci => ci.rateCodeChannels.length > 0);

        const beChannels = ['HEYBE', 'GOOGLEHOTELADS'];
        const heyBeAndGoogleChannels = channelinstanceList
            .filter(ci => beChannels.some(be => ci.code === be))
            .map(ci => ci.id);

        this.setState({ roomCategories, channelInstances: channelInstances, heyBeAndGoogleChannels: heyBeAndGoogleChannels });
    }

    onDatesChange = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    }


    clearRatesSearch = () => {
        let { roomCategories } = this.state;

        roomCategories && roomCategories.forEach(rc => {
            rc.rates && rc.rates.forEach(r => {
                r.hidden = false;
            });
        })

        this.setState({ roomCategories });
    }


    searchRates = (value, isLabel) => {

        let { roomCategories } = this.state;
        var labelIds = this.state.labelIds ? this.state.labelIds : [];
        var text = this.state.text ? this.state.text : null;

        roomCategories && roomCategories.forEach(rc => {
            rc.rates && rc.rates.forEach(r => {
                var i = 0;
                if (!value) {
                    if (!isLabel) {
                        if (labelIds && !labelIds.includes(r.labelId) && labelIds.length != 0) {
                            r.hidden = true;
                        } else {
                            r.hidden = false;
                        }
                        text = null;
                    }
                    else {
                        labelIds = [];
                        if (text && !r.label.toLowerCase().includes(text.toLowerCase())) {
                            r.hidden = true;
                        } else {
                            r.hidden = false;
                        }
                    }
                }
                else {
                    if (!isLabel && value && r.label.toLowerCase().includes(value.toLowerCase())) {
                        if (labelIds && !labelIds.includes(r.labelId) && labelIds.length != 0) {
                            r.hidden = true;
                        } else {
                            r.hidden = false;
                        }
                    }
                    else if (isLabel && value) {      
                        value.forEach(x => {
                            if (x.value == r.labelId || (i != 0 && r.hidden == false)) {
                                if (text && !r.label.toLowerCase().includes(text.toLowerCase())) {
                                    r.hidden = true;
                                } else {
                                    r.hidden = false;
                                }
                            } else {
                                r.hidden = true;
                            }
                            labelIds.push(x.value)
                            i++;
                        })
                    }
                    else {
                        r.hidden = true;
                        text = value;
                    }
                }
            });
        })
       
        this.setState({ roomCategories, labelIds: labelIds.filter((value, index, array) => array.indexOf(value) === index), text });
    }

    render() {
        const { isOpen, toggle, labels } = this.props;
        const { startDate, endDate, roomCategories, channelInstances, heyBeAndGoogleChannels } = this.state;

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    <FormattedMessage id="MajorBulkUpdateModal.Title"/>
                </ModalHeader>
                <ModalBody>
                    <MajorBulkUpdate
                        startDate={startDate}
                        endDate={endDate}
                        roomCategories={roomCategories}
                        clearRatesSearch={this.clearRatesSearch}
                        channelInstances={channelInstances}
                        heyBeAndGoogleChannels={heyBeAndGoogleChannels}
                        onDatesChange={this.onDatesChange}
                        searchRates={this.searchRates}
                        labels={labels}
                    />
                </ModalBody>
            </Modal>    
        );
    }
}
export default injectIntl(MajorBulkUpdateModal);