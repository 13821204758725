import moment from 'moment';
import React, { Component } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Row, Col } from 'reactstrap';
import CustomToolTip from '../../../Utils/CustomToolTip';
import { EditableChannelBar } from './EditableChannelBar';
import { EditableRateAvailabilityBox } from './EditableRateAvailabilityBox';

export class EditableRateAvailabilityBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dragObj: null,
            currentRow: null
        }
    }

    dragStart = (e, value, rowkey, colkey, isRule, isPrice) => {
        const dragObj = { value, rowkey, colkey, isRule, isPrice };
        this.setState({ dragObj });
    }

    changeRow = (row) => {
        const { currentRow } = this.state;
        if(currentRow !== row) this.setState({ currentRow: row });
    }

    render() {
        const { rateCode, showDerived, next14Days, availability, showRestrictions, focusedInput, handleDataChange, newData, renderPax, changeCol, wasSaved, onScroll,
        changeSelectedRate, selectedRateId, hasFlexChildPrices } = this.props;
        const { dragObj, currentRow } = this.state;
        
        const lastKey = renderPax?.filter(({isBaseOccupancy, isChildren}) => showDerived || ((isBaseOccupancy || isChildren) && rateCode?.isOccupancyDerived)).length - 1;

        return (
            <form autoComplete="off">
                <div id="NavigateCells">
                    {!global.isMobile ?
                        <div className="border-primary" id="YourPricesByOcc">
                            {renderPax?.filter(({isBaseOccupancy, isChildren}) => showDerived || !rateCode.isOccupancyDerived || ((isBaseOccupancy || isChildren) && rateCode.isOccupancyDerived)).map((el, rowkey) =>
                                <Row key={rowkey} className="d-flex flex-row flex-nowrap">
                                    <Col className={`${currentRow === rowkey && selectedRateId === rateCode?.id ? 'inventorySelectedCollum start' : ''} ${rowkey === 0 && rowkey !== lastKey ? 'row0 border-bottom' : rowkey === lastKey ? '' : 'border-bottom'} col-3 border-right pl-2 position-sticky`}
                                    style={{ left: '0', zIndex: '1', background: 'white' }}>
                                        {el.isChildren ?
                                            <Row style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                <Col className="col-1"/>
                                                <Col>
                                                    <div style={rowkey ? {} : { display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                        {!rowkey ?
                                                            <div className='text-secondary ' style={{ fontSize: '0.8em' }}>
                                                                <span style={{ fontWeight: '500' }}>{rateCode.name}</span>
                                                                {rateCode.dependencyParent > 0 ? //isDependent
                                                                    <>
                                                                        <span id={`priceLock` + rateCode.id} className='ml-2'>
                                                                            <i className="fas fa-lock mr-2" />
                                                                        </span>
                                                                        <CustomToolTip placement="right" target={`priceLock` + rateCode.id}>
                                                                            {rateCode.dependencyPercent == 0 && rateCode.dependencyValue == 0 ?
                                                                                'Same price as '
                                                                            : ''}
                                                                            {rateCode.dependencyPercent != 0 ?
                                                                                rateCode.dependencyPercent + ' % of '
                                                                            : ''}
                                                                            {rateCode.dependencyValue != 0 ?
                                                                                rateCode.dependencyValue + ' of '
                                                                            : ''}
                                                                            {rateCode.dependencyParentCode}
                                                                        </CustomToolTip>
                                                                    </>
                                                                :''}
                                                            </div>
                                                        :''}
                                                        <div className='text-right'>
                                                            {!hasFlexChildPrices && rateCode.canD3 ?
                                                                <FormattedMessage id="Inventory.Children"/>
                                                            : el.isD3 ?
                                                                <FormattedMessage id="Inventory.OtherChildren"/>
                                                            :
                                                                <FormattedMessage id="Inventory.FirstChildren"/>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        :
                                            <Row style={{ display: 'flex', alignItems: 'center', height: '100%', color: rateCode.isOccupancyDerived && !el.isBaseOccupancy && !el.childrens ?  '#878787' : '' }}>
                                                <Col className="col-1"/>
                                                <Col>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: rowkey === 0 ? 'space-between' : 'end' }}>
                                                        {rowkey === 0 ?
                                                            <div className='text-secondary ' style={{ fontSize: '0.8em' }}>
                                                                <span style={{ fontWeight: '500' }}>{rateCode.name}</span>
                                                                {rateCode.dependencyParent > 0 ? //isDependent
                                                                    <>
                                                                        <span id={`priceLock` + rateCode.id} className='ml-2'>
                                                                            <i className="fas fa-lock mr-2" />
                                                                        </span>
                                                                        <CustomToolTip placement="right" target={`priceLock` + rateCode.id}>
                                                                            {rateCode.dependencyPercent == 0 && rateCode.dependencyValue == 0 ?
                                                                                'Same price as '
                                                                            : ''}
                                                                            {rateCode.dependencyPercent != 0 ?
                                                                                rateCode.dependencyPercent + ' % of '
                                                                            : ''}
                                                                            {rateCode.dependencyValue != 0 ?
                                                                                <>
                                                                                    <FormattedNumber
                                                                                        value={rateCode.dependencyValue}
                                                                                        style="currency"
                                                                                        currency={global.hotelCurrency}
                                                                                        minimumFractionDigits={2}
                                                                                    /><span className='mx-1'>of</span>
                                                                                </>
                                                                            : ''}
                                                                            {rateCode.dependencyParentCode}
                                                                        </CustomToolTip>
                                                                    </>
                                                                :''}
                                                            </div>
                                                        :''}
                                                        <div className='text-right'>
                                                            <span>{el.name}</span>
                                                            {rateCode.isOccupancyDerived && !el.isBaseOccupancy ?
                                                                <span className='ml-2 text-right'>
                                                                    {el.derivedType === "amount" ?
                                                                        <FormattedNumber value={el.derivedAmount} style="currency" currency={global.hotelCurrency} minimumFractionDigits={2}/>
                                                                    :
                                                                        <span className='ml-1'>
                                                                            {el.derivedAmount}
                                                                            <span>%</span>
                                                                        </span>
                                                                    }
                                                                </span>
                                                            :''}
                                                            <span>
                                                                {el.isBaseOccupancy && !el.childrens ?
                                                                    <i className="fas fa-caret-left ml-2"/>
                                                                :''}
                                                                {rateCode.isOccupancyDerived && !el.isBaseOccupancy && !el.childrens ?
                                                                    <i className="fas fa-lock ml-2"/>
                                                                :''}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        }
                                    </Col>
                                    <div className="IventoryDataScroll" onScroll={onScroll} style={{ width: '100%', display: 'flex', overflow: 'auto' }}>
                                        {next14Days.map((item, k) =>{
                                            const newDataDay = newData?.find(({day}) => item.isSame(moment(day), 'date'));
                                            return(
                                                <EditableRateAvailabilityBox
                                                    key={k}
                                                    rowkey={rowkey}
                                                    changeSelectedRate={changeSelectedRate}
                                                    colkey={k}
                                                    day={item}
                                                    availability={availability}
                                                    draggable={true}
                                                    dragObj={dragObj}
                                                    dragStart={this.dragStart}
                                                    focusedInput={focusedInput}
                                                    rateCode={rateCode}
                                                    position={el.adults}
                                                    children={el.children}
                                                    isChildren={el.isChildren}
                                                    isD3={el.isD3}
                                                    isDependent={rateCode.dependencyParent}
                                                    handleDataChange={handleDataChange}
                                                    isEditable={true}
                                                    isLastRow={rowkey === lastKey}
                                                    isDerived={rateCode.isOccupancyDerived && !el.isChildren}
                                                    isBaseOccupancy={el.isBaseOccupancy}
                                                    newPrice={el.isChildren ? newDataDay?.prices?.childPrices?.find(({position}) => position === (el.isD3 ? 3 : 2))?.adultPrice : newDataDay?.prices?.adultPrices?.find(({position}) => position === el?.position)?.adultPrice}
                                                    isInvalid={newDataDay?.prices?.adultPrices?.find(({position}) => position === el?.position)?.isInvalid}
                                                    wasSaved={wasSaved}
                                                    changeRow={this.changeRow}
                                                    changeCol={changeCol}
                                                />
                                            )
                                        })}
                                    </div>
                                </Row>
                            )}
                        </div>
                    :
                        <div className="border-primary" id="YourPricesByOcc">
                            <Row>
                                <Col className="text-secondary text-center py-1">
                                    <span style={{ fontWeight: '500' }}>{rateCode.name}</span>
                                    {rateCode.dependencyParent > 0 ? //isDependent
                                        <span id={`priceLock` + rateCode.id} className='ml-2'>
                                            <i className="fas fa-lock mr-2" />
                                        </span>
                                    :''}
                                </Col>
                            </Row>
                            {renderPax?.filter(({isBaseOccupancy, isChildren}) => showDerived || !rateCode.isOccupancyDerived || ((isBaseOccupancy || isChildren) && rateCode.isOccupancyDerived)).map((el, rowkey) =>
                                <Row key={rowkey} className="d-flex flex-row flex-nowrap IventoryDataScroll" onScroll={onScroll} style={{ display: 'flex', overflow: 'auto' }}>
                                    <Col
                                        className={`${currentRow === rowkey && selectedRateId === rateCode?.id ?
                                            'inventorySelectedCollum start'
                                        :
                                            'd-flex align-items-center'}
                                        ${rowkey === 0 && rowkey !== lastKey ?
                                            'row0 border-bottom'
                                        :
                                            rowkey === lastKey ?
                                                ''
                                            :
                                                'border-bottom'
                                        } col-3 border-right pl-2 d-flex align-items-center`}
                                        style={{ left: '0', zIndex: '1', background: 'white' }}>
                                        {el.isChildren ?
                                            <FormattedMessage id="Inventory.Children"/>
                                        :
                                            <span style={{ color: rateCode.isOccupancyDerived && !el.isBaseOccupancy && !el.childrens ?  '#878787' : '' }}>{el.name}</span>
                                        }
                                    </Col>
                                    <div style={{ width: '100%', display: 'flex' }}>
                                        {next14Days.map((item, k) =>{
                                            const newDataDay = newData?.find(({day}) => item.isSame(moment(day), 'date'));
                                            return(
                                                <EditableRateAvailabilityBox
                                                    key={k}
                                                    rowkey={rowkey}
                                                    changeSelectedRate={changeSelectedRate}
                                                    colkey={k}
                                                    day={item}
                                                    availability={availability}
                                                    draggable={true}
                                                    dragObj={dragObj}
                                                    dragStart={this.dragStart}
                                                    focusedInput={focusedInput}
                                                    rateCode={rateCode}
                                                    position={el.adults}
                                                    children={el.children}
                                                    isChildren={el.isChildren}
                                                    isDependent={rateCode.dependencyParent}
                                                    handleDataChange={handleDataChange}
                                                    isEditable={true}
                                                    isLastRow={rowkey === lastKey}
                                                    isDerived={rateCode.isOccupancyDerived && !el.isChildren}
                                                    isBaseOccupancy={el.isBaseOccupancy}
                                                    newPrice={el.isChildren ? newDataDay?.prices?.childPrice : newDataDay?.prices?.adultPrices?.find(({position}) => position === el?.position)?.adultPrice}
                                                    isInvalid={newDataDay?.prices?.adultPrices?.find(({position}) => position === el?.position)?.isInvalid}
                                                    wasSaved={wasSaved}
                                                    changeRow={this.changeRow}
                                                    changeCol={changeCol}
                                                />
                                            )
                                        })}
                                    </div>
                                </Row>
                            )}
                        </div>
                    }
                </div>
                <div id="YourRestrictions">
                    {showRestrictions ?
                        <EditableChannelBar
                            dragStart={this.dragStart}
                            focusedInput={focusedInput}
                            dragObj={dragObj}
                            onScroll={onScroll}
                            selectedRateId={selectedRateId}
                            handleDataChange={handleDataChange}
                            rateId={rateCode.id}
                            availability={availability}
                            next14Days={next14Days}
                            changeSelectedRate={changeSelectedRate}
                            newData={newData}
                            changeCol={changeCol}
                            wasSaved={wasSaved}
                        />
                    :''}
                </div>
            </form>
        );

    }
};