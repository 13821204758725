import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import ReactHtmlParser from 'react-html-parser';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, CardBody, Col, Row, Progress } from 'reactstrap';
import countryList from "react-select-country-list";
import { postAPI } from "../Base/API";
import { ErrorAlert } from "../Common/ErrorAlert";
import Chart from "react-google-charts";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import moment from 'moment';

class Demographics extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: [],
            block: false,
            usersByCountry: [],
            usersByCity: [],
            usersByLanguage: [],
            countries: [],
            total: 0,
            countryList: countryList().getData()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.search === true && nextProps.search !== this.props.search) {
            this.getData();
        }
    }

    componentDidMount() {
        this.getData()
    }

    getData = () => {
        this.setState({ block: true });

        const body = {
            startDate: moment(this.props.startDate).format("YYYY-MM-DD"),
            endDate: moment(this.props.endDate).format("YYYY-MM-DD"),
            dimensions: [ "country", "city", "language", "countryId" ],
            metrics: [ "totalUsers" ],
            aggregation: [ ]
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                const json = JSON.parse(data);

                let usersByCountry = [];
                let usersByCity = [];
                let usersByLanguage = [];
                let countries = [];
                let total = 0;

                const headers = [this.props.intl.formatMessage({ id: "BEAnalitics.Country" }), this.props.intl.formatMessage({ id: "BEAnalitics.Users" })]
                usersByCountry.push(headers)

                json.rows && json.rows.forEach((row) => {
                    //usersByCity
                    const index = usersByCity.findIndex(el => el.city === row.dimensionValues[1].value);

                    if (index !== -1) {
                        usersByCity[index].total += parseInt(row.metricValues[0].value);
                    }
                    else {
                        usersByCity.push({ city: row.dimensionValues[1].value, total: parseInt(row.metricValues[0].value) });
                    }

                    //usersByLanguage
                    const index2 = usersByLanguage.findIndex(el => el.language === row.dimensionValues[2].value);

                    if (index2 !== -1) {
                        usersByLanguage[index2].total += parseInt(row.metricValues[0].value);
                    }
                    else {
                        usersByLanguage.push({ language: row.dimensionValues[2].value, total: parseInt(row.metricValues[0].value) });
                    }

                    //usersByCountry
                    const index3 = usersByCountry.findIndex(el => el[0] === row.dimensionValues[0].value);

                    if (index3 !== -1) {
                        usersByCountry[index3][1] += parseInt(row.metricValues[0].value);
                    }
                    else {
                        const arr = [row.dimensionValues[0].value, parseInt(row.metricValues[0].value)]
                        usersByCountry.push(arr);
                    }

                    if (!countries.some(el => el.country === row.dimensionValues[0].value)) {
                        const countryTranslation = this.state.countryList.find(el => el.value === row.dimensionValues[3].value);

                        countries.push({ country: row.dimensionValues[0].value, code: row.dimensionValues[3].value, countryTranslation: countryTranslation ? countryTranslation.label : row.dimensionValues[0].value });
                    }

                    total += parseInt(row.metricValues[0].value);
                });

                usersByCountry = usersByCountry.sort((a, b) => b[1] - a[1] );
                usersByCity = usersByCity.sort((a, b) => b.total - a.total);
                usersByLanguage = usersByLanguage.sort((a, b) => b.total - a.total);

                this.setState({ usersByCountry, usersByLanguage, usersByCity, countries, total, block: false });
            }
        }, `/api/hotel/v1/googleanalytics`, body);
    }


    render() {
        const { block, usersByCity, usersByLanguage, usersByCountry, countries, total } = this.state;

        var worldMapoptions = {
            region: this.state.region,
            colorAxis: { colors: ['#00c4b3', '#007bff'] }
        };

        return (
            <BlockUi tag="div" blocking={block}>
                <ErrorAlert error={this.state.error} />
                <Row className="mb-3">
                    <div className="container-fluid d-flex p-0">
                        <Col className="col-12" >
                            <Card className="shadow h-100 my-0 py-0 border-0">
                                <CardBody>
                                    <span className="text-xs font-weight-bold text-secondary text-uppercase mb-1"> <FormattedMessage id="BEAnalitics.UsersByCountry" /> </span>
                                    <Row>
                                        <Col className="col-8">
                                            {this.state.usersByCountry && this.state.usersByCountry.length > 1 ?
                                                <Chart id="mapChart"
                                                    chartType="GeoChart"
                                                    width="100%"
                                                    height="350px"
                                                    data={this.state.usersByCountry}
                                                    options={worldMapoptions}
                                                />
                                            :''}
                                                <div ref={this.element} />
                                        </Col>
                                        <Col className="col-4">
                                            <Row>
                                                <Col>
                                                    <FormattedMessage id="BEAnalitics.Country" />
                                                </Col>
                                                <Col>
                                                    <FormattedMessage id="BEAnalitics.Users" />
                                                </Col>
                                            </Row>
                                            <hr className="my-2" />
                                            <div className=" scrollableDiv beListByCountry">
                                                {usersByCountry && usersByCountry.map((arr, key) => {
                                                    const country = countries.find(el => el.country === arr[0]);

                                                    return key > 0 ?
                                                        <Row className="mb-2" key={key}>
                                                            <Col>
                                                                <span className={' mb-1 mr-1 flag-icon flag-icon-' + (country ? country.code.toLowerCase() : '')} />
                                                                {country && country.countryTranslation}
                                                            </Col>
                                                            <Col>
                                                                {arr[1]}
                                                            </Col>
                                                        </Row>
                                                    : ''
                                                })}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col >
                        <Card body className="shadow border-0 h-100">
                            <span className="text-xs font-weight-bold text-secondary text-uppercase mb-1"> <FormattedMessage id="BEAnalitics.UsersByCity" /></span>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <FormattedMessage id="BEAnalitics.City" />
                                        </Col>
                                        <Col className="text-right">
                                            <FormattedMessage id="BEAnalitics.Users" />
                                        </Col>
                                    </Row>
                                    <hr className="my-2" />
                                    {usersByCity && usersByCity.map((city, key) => 
                                        <Row className="pb-2" key={key}>
                                            <Col>
                                                {city.city}
                                            </Col>
                                            <Col className="text-center">
                                                <span className="text-muted small"> {Math.round((city.total / total) * 100)} % </span>
                                                <Progress value={Math.round((city.total / total) * 100)} style={{ height: '5px' }} />
                                            </Col>
                                            <Col className="col-2">
                                                {city.total}
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col>
                        <Card body className="shadow border-0 h-100">
                            <span className="text-xs font-weight-bold text-secondary text-uppercase mb-1"> <FormattedMessage id="BEAnalitics.UsersByLanguage" /> </span>
                            <Row>
                                <Col>
                                    <Row>
                                        <Col>
                                            <FormattedMessage id="BEAnalitics.Language" />
                                        </Col>
                                        <Col className="text-right">
                                            <FormattedMessage id="BEAnalitics.Users" />
                                        </Col>
                                    </Row>
                                    <hr className="my-2" />
                                    {usersByLanguage && usersByLanguage.map((lg, key) =>
                                        <Row className="pb-2" key={key}>
                                            <Col className="col-5">
                                                {lg.language}
                                            </Col>
                                            <Col className="text-center">
                                                <span className="text-muted small text-center"> {Math.round((lg.total / total) * 100)} % </span>
                                                <Progress value={Math.round((lg.total / total) * 100)} style={{ height: '5px' }} />
                                            </Col>
                                            <Col className="col-2">
                                                {lg.total}
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </BlockUi>
        )
    }
}

export default injectIntl(Demographics);
