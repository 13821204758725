import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, Card, Col, Row, CardHeader, CardBody, UncontrolledCollapse } from 'reactstrap';
import { StyledCard, SelectHotel, SelectChannelConfig } from "../../Base/Common/CommonUIComponents";
import { getChannelConfig } from "../../Base/Common/ReferenceDataFunctions";
import { DateRangePicker } from 'react-dates';
import { Bar } from 'react-chartjs-2';
import { getAPI } from "../../Base/API";
import moment from 'moment';

class ErrorEvents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            block: true,
            errorEvents: [],
            hotelId: null,
            channelConfigId: null,
            startDate: moment().subtract(1, 'days'),
            endDate: moment(),
            channelBar: {},
            hotelBar: {},
            colorPalette: ["#0099ff", "#00d6ff", "#00b1db", "#028db6", "#026c91", "#004c6d", "#196666", "#008081", "#009999", "#02c5c5", "#66d9dc", "#79a3f9", "#6699ff", "#6666ff"]
        }
    }

    componentDidMount() {
        this.getErrorEvents();
    }

    getErrorEvents = () => {
        this.setState({ block: true });
        var params = '';
        params += this.state.hotelId ? `&hotelId=${this.state.hotelId}` : '';
        params += this.state.channelConfigId ? `&channelConfigId=${this.state.channelConfigId}` : '';

        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }

            if (data && Array.isArray(data)) {
                this.setState({ error: errorMessage, block: false, errorEvents: data }, () => this.calculateChartsData());
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, `/api/User/Events/v1/TotalErrors?fromDate=${moment(this.state.startDate).format("YYYY-MM-DD")}&toDate=${moment(this.state.endDate).format("YYYY-MM-DD")}` + params);
    }

    calculateChartsData = () => {
        const list = [...this.state.errorEvents];
        var channels = { labels: [], datasets: [] };
        var hotels = { labels: [], datasets: [{ label: 'Num de erros', data: [], backgroundColor: [], borderWidth: 1 }] };

        if (list.length > 0) {
            list.map((hotel, hotelIdx) => {
                if (!hotels.labels.includes(hotel.hotelName)) {
                    hotels.labels.push(hotel.hotelName);
                    hotels.datasets[0].backgroundColor.push("#00d6ff");
                }

                var totalErrors = 0;

                hotel && hotel.channelTotalErrors.map((channel, idx) => {
                    if (!channels.labels.includes(channel.channel)) {
                        channels.labels.push(channel.channel);
                    }

                    channel && channel.channelEventTotalErrors.map((error) => {
                        totalErrors += error.totalErrors;

                        if (!channels.datasets.some(el => el.label === error.eventType)) {
                            channels.datasets.push(
                                { label: error.eventType, data: [], backgroundColor: this.state.colorPalette[channels.datasets.length] }
                            );

                            const channelIdx = channels.labels.indexOf(channels.labels.find(el => el === channel.channel));
                            channels.datasets[channels.datasets.length - 1].data[channelIdx] = error.totalErrors;
                        }
                        else {
                            const eventType = channels.datasets.indexOf(channels.datasets.find(el => el.label === error.eventType));
                            const channelIdx = channels.labels.indexOf(channels.labels.find(el => el === channel.channel));

                            channels.datasets[eventType].data[channelIdx] = channels.datasets[eventType].data[channelIdx] ? channels.datasets[eventType].data[channelIdx] + error.totalErrors : 0 + error.totalErrors;
                        }
                    })
                })

                hotels.datasets[0].data[hotelIdx] = totalErrors;
            });
        }

        channels.datasets.forEach((el, k) => {
            el.data = Array.from(el.data, item => item || 0);
        })

        this.setState({
            channelBar: channels,
            hotelBar: hotels
        })
    }
    
    handleChangeSelect = (name, combo) => {
        this.setState({
            [name]: combo ? combo.value : null
        });
    };

    render() {
        const options = {
            elements: {
                point: {
                    radius: 0
                }
            },
           scales: {
                yAxes: [
                    {
                        display: false,
                        stacked: true,
                        ticks: {
                            beginAtZero: true,
                        },
                    },
                ],
                xAxes: [
                    {
                       
                        stacked: true,
                    },
                ],
            },
        }

        return (
            <StyledCard block={this.state.block} error={this.state.error} icon={'fas fa-exclamation-triangle'} title={"ErrorEvents.Title"}>
                <Row>
                    <Col>
                        <SelectHotel name={'hotelId'} icon={'fas fa-hotel'} onChangeFunc={this.handleChangeSelect} value={this.state.hotelId} placeholder={<FormattedMessage id="HotelLicenses.Hotel" />}  />
                    </Col>
                    <Col>
                        <SelectChannelConfig name={'channelConfigId'} icon={'fas fa-plug'} onChangeFunc={this.handleChangeSelect} value={this.state.channelConfigId} placeholder={<FormattedMessage id="ReservationList.SelectChannel" />}/>
                    </Col>
                    <Col>
                        <DateRangePicker
                            startDate={this.state.startDate ? moment(this.state.startDate).locale(moment.locale()) : this.state.startDate}
                            startDateId="your_unique_start_date_id_announcement"
                            isOutsideRange={() => false}
                            endDate={this.state.endDate ? moment(this.state.endDate).locale(moment.locale()) : this.state.endDate}
                            endDateId="your_unique_end_date_id_announcement"
                            onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                            small={true}
                            numberOfMonths={1}
                            showDefaultInputIcon={true}
                            renderMonthElement={({ month }) => moment(month).locale(this.props.intl.locale).format('MMMM YYYY')}
                        />
                    </Col>
                    <Col>
                        <Button className="float-right  btn-sm btn-host btn" onClick={this.getErrorEvents}>
                            <i className="fas fa-search" />
                        </Button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col >
                        <Bar data={this.state.channelBar} options={options} height='60px'/>
                    </Col>
                </Row>
                <Row>
                    <Col className="h-100">
                        {this.state.errorEvents?.length > 0 && this.state.errorEvents.map((hotel, key) =>
                            <Card className="mb-2">
                                <CardHeader>
                                    <Row id={"hotel" + key} >
                                        <Col><i className="fas fa-hotel mr-2" />{hotel.hotelName}</Col>
                                        <Col >
                                            <b> {hotel.channelTotalErrors && hotel.channelTotalErrors.reduce((a, b) => a + (b.channelEventTotalErrors && b.channelEventTotalErrors.reduce((x, y) => x + y.totalErrors, 0)), 0)}</b>
                                            <i className="fas fa-chevron-down float-right" />
                                        </Col>
                                    </Row>
                                    
                                </CardHeader>
                                <UncontrolledCollapse toggler={"hotel" + key }>
                                {hotel.channelTotalErrors && hotel.channelTotalErrors.map((channel, idx) =>
                                    <CardBody className="py-2 ml-2">
                                        <Row className="border-bottom border-host" id={"channel" + key + idx}>
                                            <Col> {getChannelConfig(channel.channel) ? <img alt="" src={getChannelConfig(channel.channel).image} height={getChannelConfig(channel.channel).iconSize} className="mr-2"></img> : ''}  {channel.channel} </Col>
                                            <Col >{channel.channelEventTotalErrors && channel.channelEventTotalErrors.reduce((a, b) => a + b.totalErrors , 0)}  <i className="fas fa-chevron-down float-right"/></Col>
                                        </Row>
                                        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
                                            {channel.channelEventTotalErrors && channel.channelEventTotalErrors.map((event, i) =>
                                                <UncontrolledCollapse toggler={"channel" + key + idx} key={i}>
                                                    <Row>
                                                        <Col className="col-4"> {event.eventType} </Col>
                                                        <Col className="col-4"> {event.totalErrors} Erros </Col>
                                                    </Row>
                                                </UncontrolledCollapse>
                                            )}
                                        </div>
                                    </CardBody>
                                    )}
                                </UncontrolledCollapse>
                            </Card>
                        )}
                    </Col>
                </Row>
            </StyledCard>
        );
    }

};
export default injectIntl(ErrorEvents);