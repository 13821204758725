import React, { Component } from 'react';
import { Row, Col, Button, Card, CardBody, CardHeader, Label, Input, Modal, ModalHeader, ModalBody, Form } from 'reactstrap';
import { getAPI, postAPI, deleteAPI } from "../../../Base/API";
import BlockUi from 'react-block-ui';
import { ErrorAlert } from "../../../Common/ErrorAlert";
import { FormattedMessage, injectIntl } from 'react-intl';
import CustomSelect from '../../../Base/Common/CustomSelect';
import { handleNotification } from "../../../Base/Notification";
import { getSocialMediaType } from '../../../Base/Common/ReferenceDataFunctions';
import { CommonHelper } from '../../../Base/Common/CommonUIComponents';

class ComplexSocialMedia extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            block: false,
            modal: false,
            editMode: false,
            selectedSocialMedia: null,
            socialMediaList: [],
            selectedComplex: this.props.selectedComplex ? this.props.selectedComplex : {}
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedComplex !== this.props.selectedComplex) {
            this.setState({
                selectedComplex: nextProps.selectedComplex
            }, () => this.getSocialMedia())
        }
    }

    componentDidMount() {
        this.getSocialMedia();
    }

    getSocialMedia = () => {
        this.setState({ block: true });

        getAPI(result => {
            const { data, error, isLoading } = result;
            this.setState({ blocking: isLoading });
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ blocking: false, error: errorMessage, block: false });
                return;
            }
            if (data) {
                this.setState({ socialMediaList: data.response });
            }
            this.setState({ block: false });
        }, `/api/hotel/Complexes/v1/${this.state.selectedComplex.id}/socialmedia`);
    }

    isValidUrl = urlString => {
        try { 
            return Boolean(new URL(urlString)); 
        }
        catch(e){ 
            return false; 
        }
    }

    saveSocialMedia = (e) => {
        e.preventDefault();
   
        const currentURL = document.querySelector('[name="url"]');
        if(!this.isValidUrl(currentURL?.value)){
            currentURL.setCustomValidity(this.props.intl.formatMessage({ id: "ComplexSocialMedia.InvalidURL" }));
            currentURL.reportValidity();
            return;
        }


        this.setState({ block: true });

        const body =  [{ ...this.state.selectedSocialMedia }];

        postAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ modalError: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, modalError: errorMessage });
                    return;
                }
                if (data.response) {
                    handleNotification(data, <FormattedMessage id="ComplexSocialMedia.SocialMediaSaved" />, <FormattedMessage id="General.Success" />);

                    this.setState({ socialMediaList: data.response });
                    this.toggleModal();
                }
            }
            this.setState({ block: false, modalError: errorMessage });
        }, `/api/hotel/Complexes/v1/${this.state.selectedComplex.id}/socialmedia`, body);
    }

    deleteSocialMedia = (e, id) => {
        e.preventDefault();
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;
            const errorMessage = []
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ modalError: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ block: false, modalError: errorMessage });
                    return;
                }
                if (data.success && data.success === 'OK') {
                    handleNotification(data, <FormattedMessage id="ComplexSocialMedia.SocialMediaDeleted" />, <FormattedMessage id="General.Success" />);

                    let list = [...this.state.socialMediaList];
                    list = list.filter(el => el.id !== id);
                    this.setState({ socialMediaList: list });
                }
            }
            this.setState({ block: false, modalError: errorMessage });
        }, `/api/hotel/Complexes/v1/socialmedia/${id}`);
    }

    toggleModal = (socialMedia) => {
        this.setState(prevState => ({
            modal: !prevState.modal,
            selectedSocialMedia: socialMedia,
            editMode: socialMedia ? true : false
        }));
    }

    handleChangeSelect = (name, combo) => {
        this.setState({
            selectedSocialMedia: {
                ...this.state.selectedSocialMedia,
                [name]: combo ? combo.value : null
            }
        });
    };

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState({
            selectedSocialMedia: {
                ...this.state.selectedSocialMedia,
                [name]: value
            }
        });
    }

    render() {
        const { selectedSocialMedia, editMode, socialMediaList } = this.state;
        const socialMediaType = getSocialMediaType();

        return (
            <BlockUi tag="div" blocking={this.state.block}>
                <ErrorAlert error={this.state.error} />
                <Card className="border-0 shadow mb-2">
                    <CardHeader className="border-bottom bg-white" >
                        <Row>
                            <Col> <span className="far fa-comments "> </span> <FormattedMessage id="ComplexSetup.SocialMedia" /></Col>
                            <Col className="text-right pr-0">
                                <Button color="primary btn-host btn-sm" onClick={() => this.toggleModal(null)}>
                                    <i className="fas fa-plus" />
                                </Button>

                                <CommonHelper help={<FormattedMessage id="ComplexSocialMedia.Help" />} id="ComplexSocialMediaHelp" />
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {socialMediaList && socialMediaList.map((el, key) => {
                            const media = socialMediaType.find(sm => sm.value === el.type);

                            return (
                                <Card className="border-0 shadow mb-2 p-2" key={key}>
                                    <Row>
                                        <Col className="pointer" onClick={() => this.toggleModal(el)}>
                                            {media ? media.icon : ''} {media ? media.label : media}
                                        </Col>
                                        <Col>
                                            <a href={el.url} target="_blank"> {el.url} </a>
                                        </Col>
                                        <Col className="col-1">
                                            <Button color=" btn-host  btn-sm float-right mx-2" onClick={(e) => this.deleteSocialMedia(e, el.id)} >
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card>
                            )
                        })}
                    </CardBody>
                </Card>

                {this.state.modal ?
                    <Modal isOpen={this.state.modal} toggle={() => this.toggleModal(null)} fade={false} className="modal-lg" >
                        <ModalHeader toggle={() => this.toggleModal(null)}> {editMode ? <FormattedMessage id="ComplexSocialMedia.EditSocialMedia" /> : <FormattedMessage id="ComplexSocialMedia.AddSocialMedia" />} </ModalHeader>
                        <ModalBody className="modal-lg">
                            <ErrorAlert error={this.state.modalError} />
                            <Form onSubmit={(e) => this.saveSocialMedia(e)}>
                                <Row>
                                    <Col>
                                        <Button color="primary btn-host btn-sm float-right mb-2" type="submit">
                                            <i className="fas fa-save" />
                                        </Button>
                                    </Col>
                                </Row>
                                <Row className="mb-2">
                                    <Col className="col-4">
                                        <Label> <FormattedMessage id="ComplexSocialMedia.SocialMedia" /> </Label>
                                    </Col>
                                    <Col>
                                        <CustomSelect
                                            name={'type'} isSearchable
                                            options={socialMediaList ? socialMediaType.filter(sc => socialMediaList.some(el => el.type === sc.value) === false) : socialMediaType}
                                            placeholder={<FormattedMessage id="ComplexSocialMedia.SocialMedia" />}
                                            value={selectedSocialMedia ? socialMediaType.find(el => el.value === selectedSocialMedia.type) : ''}
                                            required
                                            onChange={this.handleChangeSelect.bind(this, 'type')}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-4">
                                        <Label> URL </Label>
                                    </Col>
                                    <Col>
                                        <Input type="text" name="url"
                                            placeholder='URL'
                                            required
                                            value={selectedSocialMedia ? selectedSocialMedia.url : ''}
                                            onChange={this.handleChange}
                                        />
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                : ''}
            </BlockUi>
        );
    }
}
export default injectIntl(ComplexSocialMedia)