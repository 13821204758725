import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import Authorization, { CheckAuthorization } from '../../Base/Authorization'
import CustomSelect from '../../Base/Common/CustomSelect'
import { CSVLink } from "react-csv";
import { MobileFilters } from '../../Base/Common/MobileComponents';

class RoomSetupFilters extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fileName: "RoomRateConfig.csv"
        };
    }

    renderMainFilter = () => {
        return <CustomSelect
            isClearable
            options={this.props.getRoomCategoriesList()}
            onChange={this.props.filterRoom.bind(this)}
            placeholder={<FormattedMessage id="RoomSetup.RoomCM" />}
            isMulti
        />
    }

    renderSecFilters = () => {
        return <>
            <div className="mb-2">
                <CustomSelect
                    options={this.props.rateStatusOptions}
                    onChange={(e) => this.props.handleRateFilters(e, 'selectedStatus')}
                    placeholder={<FormattedMessage id="RateSetup.RateStatus" />}
                    isClearable
                />
            </div>
            <div className="mb-2">
                <CustomSelect
                    isClearable
                    options={this.props.labelOptions}
                    onChange={(e) => this.props.handleRateFilters(e, 'selectedLabel')}
                    placeholder={<FormattedMessage id="EditRate.Label" />}
                />
            </div>
        </>
    }

    getButtons = () => {
        return [
            {
                onClickFunc: this.props.toggleBulkOperations,
                icon: 'fas fa-database',
                isVisible: CheckAuthorization("admin:view")
            }
        ]
    }

    render() {
        const { getRoomCategoriesList, filterRoom, getRateCodeList, handleRateFilters, getChannelsList, inventoryList, csvData, addFunction, labelOptions, rateStatusOptions, hideLabels } = this.props;
        
        return (
            global.isMobile ?
                <MobileFilters csvExport={{ csvData: csvData, fileName: this.state.fileName }} addFunction={addFunction} renderMainFilter={this.renderMainFilter} renderSecFilters={this.renderSecFilters} otherButtons={this.getButtons()}>
                    <Row>
                        <CommonFilters
                            getRateCodeList={getRateCodeList}
                            getChannelsList={getChannelsList}
                            inventoryList={inventoryList}
                            handleRateFilters={handleRateFilters}
                        />
                    </Row>
                </MobileFilters>
                :
                <div>
                    <Row className="mb-2 align-items-center">
                        <Col className="col-2 pr-0">
                            <CustomSelect
                                isClearable
                                options={getRoomCategoriesList()}
                                onChange={filterRoom.bind(this)}
                                placeholder={<FormattedMessage id="RoomSetup.RoomCM" />}
                                isMulti
                            />
                        </Col>

                        <CommonFilters
                            getRateCodeList={getRateCodeList}
                            getChannelsList={getChannelsList}
                            inventoryList={inventoryList}
                            handleRateFilters={handleRateFilters}
                        />

                        <Col className="col-2 pr-0">
                            <CustomSelect
                                options={rateStatusOptions}
                                onChange={(e) => handleRateFilters(e, 'selectedStatus')}
                                placeholder={<FormattedMessage id="RateSetup.RateStatus" />}
                                isClearable
                            />
                        </Col>
                        <Col className="pr-1 text-right">
                            <Authorization
                                perform="admin:view"
                                yes={() => (
                                    <Button className="btn btn-host btn-sm mr-2" onClick={this.props.toggleBulkOperations} title="Bulk Operations">
                                        <i className="fas fa-database"></i>
                                    </Button>
                                )}
                                no={() => <div></div>}
                            />
                            <CSVLink className="btn btn-sm btn-host mr-2" data={csvData} filename={this.state.fileName}>
                                <i className="fas fa-file-download" />
                            </CSVLink>
                            <Authorization
                                perform="rate:add"
                                yes={() => (
                                    <button className="btn btn-host btn-sm text-white mr-3" onClick={addFunction} >
                                        <i className="fas fa-plus" />
                                    </button>
                                )}
                                no={() => <div></div>}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-2 align-items-center">
                        {!hideLabels ? 
                            <Col className="col-2 pr-0">
                                <CustomSelect
                                    isClearable
                                    options={labelOptions}
                                    onChange={(e) => handleRateFilters(e, 'selectedLabel')}
                                    placeholder={<FormattedMessage id="EditRate.Label" />}
                                />
                            </Col>
                        : ''}
                    </Row>
                </div>
        );
    }
}

export default injectIntl(RoomSetupFilters);

const CommonFilters = ({ getRateCodeList, getChannelsList, inventoryList, handleRateFilters }) => {
    return (
        <>
            <Col className="col-12 col-lg-2 mb-2">
                <FormattedMessage id="RoomSetup.RateCM" >{option =>
                    <CustomSelect
                        isClearable
                        options={getRateCodeList()}
                        onChange={(e) => handleRateFilters(e, 'selectedRates')}
                        placeholder={option}
                        isMulti
                    />
                }</FormattedMessage>
            </Col>
            <Col className="col-12 col-lg-2 mb-2">
                <CustomSelect
                    isClearable
                    options={getChannelsList()}
                    onChange={(e) => handleRateFilters(e, 'selectedChannel')}
                    placeholder={<FormattedMessage id="RoomSetup.ChannelCM" />}
                />
            </Col>
            <Col className="col-12 col-lg-2 mb-2">
                <CustomSelect
                    isClearable
                    options={inventoryList}
                    onChange={(e) => handleRateFilters(e, 'selectedInv')}
                    placeholder={<FormattedMessage id="EditRate.InventoryType" />}
                />
            </Col>
        </>
    )
}