
import React, { Component } from 'react';
import 'react-dates/initialize';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Card, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import CustomSelect from '../../Base/Common/CustomSelect';
import { AirbnbGlobalReviewCardMobile } from './AirbnbGlobalReviewCardMobile';
import { MobileFilters } from '../../Base/Common/MobileComponents';

class AirbnbReviewMobile extends Component {

    renderMainFilter = () => {
        return (
            <>
                {this.props.getChannelInstanceComboValues().length > 0 ?
                    <CustomSelect
                        icon={'fa fa-plug fa-fw'}
                        isSearchable
                        placeholder={<FormattedMessage id="AirbnbReviewCard.SelectChannel"/>}
                        defaultValue={this.props.getChannelInstanceComboValues()[0]}
                        options={this.props.getChannelInstanceComboValues()}
                        onChange={this.props.setComboStatus.bind(this, 'selectedConnection')}
                    />
                : ''}
            </>
        )
    }

    renderSecFilters = () => {
        return (
            <CustomSelect
                isClearable
                icon={'fas fa-user-tag'}
                isSearchable
                placeholder={<FormattedMessage id="AirbnbReviewCard.SelectRole"/>}
                defaultValue={this.props.getReviewerRoleComboValues()[0]}
                options={this.props.getReviewerRoleComboValues()}
                onChange={this.props.setComboStatus.bind(this, 'reviwerRole')}
            />

        )
    }

    render() {
        return (
            <>
                <Row>
                    <Col className="col-12  ">
                        <Card className=" bg-white border-0 mb-1 small">
                            <form ref={this.props.form}>

                                <MobileFilters
                                    doSearch={this.props.reloadReviewInfo.bind(this)}
                                    renderMainFilter={this.renderMainFilter}
                                    renderSecFilters={this.renderSecFilters}
                                >
                                    <Row className='py-2'>
                                        <Col>
                                            <InputGroup size="sm text-right shadow" >
                                                <InputGroupAddon addonType="prepend"><span className="input-group-text"><span className="fas fa-key fa-fw"></span></span></InputGroupAddon>
                                                <FormattedMessage id="ReservationList.ReservationId">{placeholder =>
                                                    <Input 
                                                        className="border-left-0" 
                                                        type="text"
                                                        name="reservationId" 
                                                        maxLength="20" 
                                                        id="reservationId" 
                                                        placeholder={placeholder} 
                                                        value={this.props.reservationId || ''} 
                                                        onChange={(e) => this.props.changeState(e)}
                                                    />
                                                    
                                                }
                                                </FormattedMessage>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </MobileFilters>

                                {/*<Row>
                                    <Col className="col-3">
                                         <InputGroup size="sm text-right" >
                                            <SingleDatePicker
                                                id="Date"
                                                isOutsideRange={day => day <= moment().subtract(6, 'month') || day > moment()}
                                                date={this.props.date}
                                                onDateChange={date => this.props.setState({ date })}
                                                focused={this.props.focused}
                                                onFocusChange={({ focused }) => this.props.setState({ focused })}
                                                small={true}
                                                numberOfMonths={1}
                                                required={true}
                                                showDefaultInputIcon={true}
                                            />
                                        </InputGroup>
                                    </Col>
                                </Row>*/}
                            </form>
                        </Card>
                    </Col>
                </Row>

                <AirbnbGlobalReviewCardMobile reviews={this.props.globalReviews} />
            </>
        )
    }
}

export default injectIntl(AirbnbReviewMobile);
