import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { getChannelConfig, getMarkupType } from "../../Base/Common/ReferenceDataFunctions";
import { ActiveInactiveStatusBadge } from "../../Base/Common/CommonUIComponents";


export class ChannelBar extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        
        this.state = {
            activeTab: '1',
            modal: false,
            markupTypes: getMarkupType()
        };
    }
    
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    toggleModal(tab) {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }




    render() {

        //const room = this.props.room;
        const rate = this.props.rate;
        const ratecodechannel = this.props.RateCodeChannel;
        const channelinstance = this.props.channelinstance;

        var channels = ratecodechannel.filter(function (rcc) {
            return rcc.rateCodeId === rate.id            
        })

        return <div className="m-lg-2 shadow" style={{ overflowX: 'auto' }} >
            {channels.map((channel, key) => {
                const markupType = this.state.markupTypes.find(el => el.value === channel.priceMarkupType);
                const channelConfig = getChannelConfig(channelinstance[channel.channelInstanceId] && channelinstance[channel.channelInstanceId].channelConfigCode);


                return <div key={key} >
                    <Row className="d-flex flex-row border-bottom align-items-center py-2 mx-2 overbglight" style={{ flexFlow: 'nowrap' }} >
                        <Col className=" border-right " style={{ flex: '0 0 32.8%' }}>
                            <Row>
                                <Col className="d-inline-block " >
                                    {channelinstance[channel.channelInstanceId] && channelConfig ?
                                        <img alt="" src={channelConfig.image} height={channelConfig.iconSize}></img>
                                        : ''}
                                    <strong> {channelinstance[channel.channelInstanceId] && channelinstance[channel.channelInstanceId].channelConfigCode} </strong>
                                </Col>
                                <Col className=" text-md-right col-12 col-md-2">
                                    <ActiveInactiveStatusBadge status={channel.active} />
                                </Col>
                            </Row>
                        </Col>

                        <Col className={"border-0 " + (channel.active ? '' : 'text-secondary')} style={{ flex: '0 0 67.2 %' }}>
                            <Row className="border-0 align-items-center" style={{ flexFlow: 'nowrap' }} >
                                <Col className="col-3 pr-0 ">
                                    <div>
                                        <strong> <FormattedMessage id="ChannelBar.ChannelRateCode" /> </strong> <span>{channel.rateCodeMapping}</span>
                                    </div>
                                </Col>
                                <Col className="col-3 ">
                                    <div>
                                        <strong><FormattedMessage id="ChannelBar.ChannelRoomCode" /> </strong> <span>{channel.roomTypeMapping}</span>
                                    </div>
                                </Col>
                                <Col className="col-2 px-0" >
                                    <div>
                                        <strong> <FormattedMessage id="ChannelBar.Interface" />: </strong>
                                        {channel.interfaceMode >= 0 ? <span><FormattedMessage id={`generic.inventoryMode${channel.interfaceMode}`} /></span> : ''}
                                    </div>
                                </Col>
                                <Col className="col-2 ">
                                    <div>
                                        <strong> <FormattedMessage id="ChannelBar.Markup" />: </strong>
                                        {channel.priceMarkupValue ? <span> {channel.priceMarkupValue > 0 && markupType ? markupType.icon : ''} {channel.priceMarkupValue}</span> : <span>  N/A </span>}
                                    </div>
                                </Col>
                                <Col className="col-2 px-0 ">
                                    <div>
                                        <strong> <FormattedMessage id="ChannelBar.PricePosition" />: </strong>
                                        <span>{channel.pricePosition || 'N/A'}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                })}
        </div>;        
    }
}



export class ChannelBarMobile extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggleModal = this.toggleModal.bind(this);

        this.state = {
            activeTab: '1',
            modal: false,
            markupTypes: getMarkupType()
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    toggleModal(tab) {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }




    render() {

        //const room = this.props.room;
        const rate = this.props.rate;
        const ratecodechannel = this.props.RateCodeChannel;
        const channelinstance = this.props.channelinstance;

        var channels = ratecodechannel.filter(function (rcc) {
            return rcc.rateCodeId === rate.id
        })

        return <div className="shadow" style={{ overflowX: 'auto', display: 'ruby-text' }} >
            {channels.map((channel, key) => {
                const markupType = this.state.markupTypes.find(el => el.value === channel.priceMarkupType);
                const channelConfig = getChannelConfig(channelinstance[channel.channelInstanceId] && channelinstance[channel.channelInstanceId].channelConfigCode);

                return <div key={key} >
                    <Row className="d-flex flex-row border-bottom align-items-center py-2 mx-2 overbglight" style={{ flexFlow: 'nowrap' }} >
                        <Col className=" border-right " style={{ flex: '0 0 25ch' }}>
                            <Row className="fitColumnContent">
                                <Col className="d-inline-block " >
                                    {channelinstance[channel.channelInstanceId] && channelConfig ?
                                        <img alt="" src={channelConfig.image} height={channelConfig.iconSize}></img>
                                        : ''}
                                    <strong> {channelinstance[channel.channelInstanceId] && channelinstance[channel.channelInstanceId].name} </strong>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <ActiveInactiveStatusBadge status={channel.active} />
                                </Col>
                            </Row>
                        </Col>

                        <Col className={"border-0 px-0 " + (channel.active ? '' : 'text-secondary')} >
                            <div className="border-0 d-flex align-items-center" >
                                <div className="border-right px-3" style={{ minWidth: '25ch' }}>
                                    <div className="d-flex flex-column flex-wrap">
                                        <strong> <FormattedMessage id="ChannelBar.ChannelRateCode" /> </strong> <span>{channel.rateCodeMapping}</span>
                                    </div>
                                </div>
                                <div className="border-right px-3" style={{ minWidth: '25ch' }}>
                                    <div className="d-flex flex-column flex-wrap">
                                        <strong><FormattedMessage id="ChannelBar.ChannelRoomCode" /> </strong> <span>{channel.roomTypeMapping}</span>
                                    </div>
                                </div>
                                <div className="border-right px-3" style={{ minWidth: '25ch' }}>
                                    <div className="d-flex flex-column flex-wrap">
                                        <strong> <FormattedMessage id="ChannelBar.Interface" />: </strong>
                                        {channel.interfaceMode >= 0 ? <span><FormattedMessage id={`generic.inventoryMode${channel.interfaceMode}`} /></span> : ''}
                                    </div>
                                </div>
                                <div className="border-right px-3" style={{ minWidth: '25ch' }}>
                                    <div className="d-flex flex-column flex-wrap">
                                        <strong> <FormattedMessage id="ChannelBar.Markup" />: </strong>
                                        {channel.priceMarkupValue ? <span> {channel.priceMarkupValue > 0 && markupType ? markupType.icon : ''} {channel.priceMarkupValue}</span> : <span>  N/A </span>}
                                    </div>
                                </div>
                                <div className="border-right px-3" style={{ minWidth: '30ch' }}>
                                    <div className="d-flex flex-column flex-wrap">
                                        <strong> <FormattedMessage id="ChannelBar.PricePosition" />: </strong>
                                        <span>{channel.pricePosition || 'N/A'}</span>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            })}
        </div>;
    }
}