import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Row, Modal, ModalBody } from 'reactstrap';
import { SupportedMultiLang } from '../../Base/Common/SupportedMultiLang';

class PreviewTemplates extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang: null,
            availableLanguages: null
        }
    }

    componentDidMount() {
        if (this.props.selectedEvent?.templates?.length > 0) {
            this.setState({
                lang: this.props.selectedEvent.templates[0].language,
                availableLanguages: this.props.selectedEvent.templates.map(el => el.language)
            })
        }
    }

    toggleLang = (selectedLang) => {
        if (this.state.lang !== selectedLang) {
            this.setState({ lang: selectedLang });
        };
    }


    render() {
        const { lang, availableLanguages } = this.state;
        const { selectedEvent } = this.props;

        const selectedTemplate = selectedEvent?.templates.find(t => t.language === lang) || {};

        return (
            <Modal isOpen={this.props.modal} toggle={this.props.toggleModal} fade={false} size={"xl"} style={{ minWidth: '80%' }}>
                <ModalBody>
                    <Row className="mb-4">
                        <Col>
                            <i className="far fa-envelope" /> {selectedEvent.name}
                        </Col>
                    </Row>
                    {availableLanguages ?
                        <SupportedMultiLang toggle={this.toggleLang} lang={lang} tabPaneBorder='border-0' availableLanguages={availableLanguages}>
                            <Row className="mb-3">
                                <Col className="ml-2">
                                    <b><FormattedMessage id="NotificationTemplate.Subject" /></b>: {selectedTemplate.subject?.replace("{0}", "Reservation Id")}
                                </Col>
                            </Row>

                            <div style={{ height: '70vh' }}>
                                <iframe
                                    srcDoc={selectedTemplate?.previewHtml || ""}
                                    height="100%"
                                    width="100%"
                                    style={{ border: '0' }}
                                />
                            </div>
                        </SupportedMultiLang>
                        : ''}
                </ModalBody>
            </Modal>
        )
    }
}

export default injectIntl(PreviewTemplates);
