import React, { Component } from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { injectIntl } from 'react-intl';
import moment from 'moment';

export class PropertyHotel extends Component {

    render() {
        const { getConnectionTypes, getDateAt, properties, toggleModal } = this.props;

        return (
            <Row>
                <Col>
                    {properties.map((prp, key) =>
                        <div key={key}>
                            <Row onClick={_ => toggleModal(_, prp)}>
                                <Col>
                                    <div>
                                        <h5 style={{ display: "inline-block" }}>{prp.property.name}</h5>
                                        <span className="ml-2">{prp.property.id}</span>
                                    </div>
                                    {
                                        getConnectionTypes(prp.connection_types).map((conn, key) => <Badge key={key} className="text-white mr-1" color="primary">{conn}</Badge>)
                                    }
                                    {
                                        prp.pricing ?
                                            <div>
                                                {`${this.props.intl.formatMessage({ id: "BookingConnections.Currency" })}: ${prp.pricing.currency_code} | ${this.props.intl.formatMessage({ id: "BookingConnections.Model" })}: ${prp.pricing.model}`}
                                            </div>
                                            :
                                            ''
                                    }
                                </Col>
                                <Col className="col-2 text-right">
                                    {moment(getDateAt(prp)).format("YYYY-MM-DD")}
                                </Col>
                            </Row>
                            <hr />
                        </div>
                    )}
                </Col>
            </Row>
        );
    }
}

export default injectIntl(PropertyHotel);
